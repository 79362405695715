import { MouseEvent } from 'react';
import { SecondaryButton } from '@ardoq/button';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { clearPerspectiveEditor } from '../../../perspective/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import perspectiveWindowManager from 'perspective/perspectiveEditor/perspectiveWindowManager';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { INCLUDE_ALL_DESCENDANTS } from '@ardoq/global-consts';

type NoDataWithFiltersDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
  canApplyIncludeAllDescendantsFilter?: boolean;
};

const NoDataWithFiltersDoq = ({
  doqType,
  includeActions = true,
  canApplyIncludeAllDescendantsFilter = false,
}: NoDataWithFiltersDoqProps) => {
  const onOpenPerspectivesClick = (event: MouseEvent) => {
    perspectiveWindowManager.open({
      event,
      focusPerspectiveName: false,
    });
  };
  return (
    <EmptyStatesDoqContainer
      data-testid={`${doqType}-emptystate__no-data-with-filters`}
    >
      <ResponsiveDoqWithSpeechBubble
        doqType={doqType}
        title="There is no data to show"
        message={
          <Stack gap="xlarge">
            {canApplyIncludeAllDescendantsFilter ? (
              <FlexBox gap="medium">
                <span style={{ display: 'inline' }}>
                  To show data in this view, try activating view modifier &quot;
                  <Icon
                    iconName={IconName.BLUR_ON}
                    iconSize={IconSize.SMALL}
                  />{' '}
                  {INCLUDE_ALL_DESCENDANTS}&quot; to show descendants or modify
                  the filter settings in Perspectives.
                </span>
              </FlexBox>
            ) : (
              <FlexBox gap="medium">
                To show more data, clear filters or modify the settings in
                Perspectives.
              </FlexBox>
            )}
          </Stack>
        }
        buttonLabel={includeActions ? 'Clear filters' : undefined}
        onButtonClick={
          includeActions
            ? () => {
                dispatchAction(clearPerspectiveEditor());
              }
            : undefined
        }
        extraButton={
          includeActions ? (
            <SecondaryButton onClick={onOpenPerspectivesClick}>
              Open Perspectives
            </SecondaryButton>
          ) : undefined
        }
      />
    </EmptyStatesDoqContainer>
  );
};
export default NoDataWithFiltersDoq;
