import { DoqType } from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import cantManageUsers from 'components/AppMainSidebar/CantManageUsers';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

interface UnknownDocumentArchiveErrorDialogProps {
  workspaceName: string | null;
}

const UnknownDocumentArchiveErrorDialog = (
  props: UnknownDocumentArchiveErrorDialogProps
) => {
  return (
    <>
      <div>Workspace: {props.workspaceName}</div>
      <div>
        <Link typography="text2" hideIcon onClick={() => cantManageUsers()}>
          Contact your admin
        </Link>{' '}
        to get Write access.
      </div>
    </>
  );
};

export const unknownDocumentArchiveErrorDialog = (
  workspaceId: string | null
) => {
  const workspaceName = workspaceId
    ? workspaceInterface.getWorkspaceName(workspaceId)
    : '';
  return {
    title: 'An unexpected error occurred',
    subtitle:
      'Please try again, or contact support if the problem persists. If you are trying to archive a document, you need Write access to the workspace.',
    body: <UnknownDocumentArchiveErrorDialog workspaceName={workspaceName} />,
    doqType: DoqType.LOCKED,
  };
};
