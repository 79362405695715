import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { CHECKBOX_ICON_SELECTOR, CheckboxIcon, IconName } from '@ardoq/icons';
import {
  CellTypes,
  ComponentProps,
  TableViewRow,
  UriData,
  WorkspaceProps,
} from './types';
import ExpandToggleIcon from 'atomicComponents/ExpandToggleIcon';
import { formatDateOnly, formatDateTime } from '@ardoq/date-time';
import { getCellValue } from './utils';
import {
  COMPONENT_PATH_LIST_ITEM_BULLET_SIZE,
  COMPONENT_PATH_LIST_ITEM_MAX_WIDTH,
  EXPAND_ICON_SIZE,
  REFERENCE_LIST_CELL_ITEM_COMMA_LEFT_MARGIN,
  REFERENCE_LIST_CELL_ITEM_COMMA_RIGHT_MARGIN,
  TEXT_CELL_TEXT_MAX_WIDTH,
  UNSET_CHECKBOX_LABEL,
} from './consts';
import { format } from 'utils/numberUtils';
import { logError } from '@ardoq/logging';
import { colors, s8 } from '@ardoq/design-tokens';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { TextOverflow } from '@ardoq/popovers';
import { ensureContrast } from '@ardoq/color-helpers';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { getCurrentLocale } from '@ardoq/locale';
import Markdown from 'atomicComponents/Markdown';

const componentTextColor = (componentId: string, rowIndex: number) =>
  ensureContrast(
    rowIndex % 2 === 0 ? colors.grey95 : colors.white,
    getComponentCssColors(componentId, { useAsBackgroundStyle: false })
      ?.stroke || colors.black
  );

const StyledExpandToggleIcon = styled(ExpandToggleIcon)`
  align-self: center;
  cursor: pointer;
  &.material-icons-round {
    font-size: ${EXPAND_ICON_SIZE}px;
  }
`;

const TableCell = styled.td<{ $isTextSelectable?: boolean }>`
  white-space: pre-wrap;
  word-break: break-word;
  ${({ $isTextSelectable = true }) =>
    $isTextSelectable ? undefined : 'user-select: none;'};
`;

const CheckboxStyledCell = styled.span<{ $unset?: boolean }>`
  display: inline-flex;
  line-height: 24px;

  ${props =>
    props.$unset &&
    css`
      color: ${colors.grey80};
      & > ${CHECKBOX_ICON_SELECTOR} {
        margin-right: ${s8};
      }
    `}
`;

const ReferenceList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
  width: 100%;
  li {
    display: inline-block;
    max-width: 100%;
    &:not(:last-child):before {
      float: right;
      cursor: default;
      content: ',';
      color: gray;
      margin: 0 ${REFERENCE_LIST_CELL_ITEM_COMMA_RIGHT_MARGIN}px 0
        ${REFERENCE_LIST_CELL_ITEM_COMMA_LEFT_MARGIN}px;
    }
  }
`;

const TextCellText = styled.span`
  flex: 1;
  max-width: ${TEXT_CELL_TEXT_MAX_WIDTH}px;
  white-space: pre-wrap;
  word-break: break-word;
`;

type ExpandControllerProps = {
  model: TableViewRow;
};
const ExpandController = ({
  model: { hasDescription, expandDescription, entityType, cid },
}: ExpandControllerProps) =>
  hasDescription ? (
    <StyledExpandToggleIcon
      expanded={expandDescription}
      data-dispatch="expand-description"
      data-reference-cid={(entityType === 'reference' && cid) || undefined}
      data-component-id={(entityType === 'component' && cid) || undefined}
    />
  ) : null;

type TableCellProps = {
  model: TableViewRow;
  isExpandController: boolean;
  children: ReactNode;
  isTextSelectable?: boolean;
};
const TableCellWithExpandController = ({
  model,
  isExpandController,
  children,
  isTextSelectable = true,
}: TableCellProps) => (
  <TableCell $isTextSelectable={isTextSelectable}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {children}
      {isExpandController && <ExpandController model={model} />}
    </div>
  </TableCell>
);

type NameCellProps = {
  attribute: string;
  isExpandController: boolean;
  component: TableViewRow;
  color: string;
};
const NameCell = ({
  attribute,
  isExpandController,
  component,
  color,
}: NameCellProps) => {
  const target = attribute === 'name' ? component : component[attribute];

  const contextMenuAttribute = COMPONENT_ID_ATTRIBUTE;

  return (
    <TableCellWithExpandController
      model={component}
      isExpandController={isExpandController}
      isTextSelectable={false}
    >
      <TextOverflow lineClamp={3} popoverFollowsMouse>
        <span
          style={{ flex: 1, color }}
          className="component"
          {...{ [contextMenuAttribute]: target.id }}
        >
          {target.name}
        </span>
      </TextOverflow>
    </TableCellWithExpandController>
  );
};

type TextCellProps = {
  text: string;
  isExpandController: boolean;
  model: TableViewRow;
  isMarkdown?: boolean;
};
const TextCell = ({
  text,
  isExpandController,
  model,
  isMarkdown,
}: TextCellProps) => {
  return (
    <TableCellWithExpandController
      model={model}
      isExpandController={isExpandController}
    >
      <TextCellText>
        <TextOverflow lineClamp={3} popoverFollowsMouse>
          {isMarkdown ? <Markdown>{text}</Markdown> : text}
        </TextOverflow>
      </TextCellText>
    </TableCellWithExpandController>
  );
};
type URICellProps = { uriData: UriData };
const URICell = ({ uriData }: URICellProps) => (
  <TableCell>
    <TextOverflow lineClamp={3}>
      {uriData.uri && (
        <a href={uriData.uri} rel="noopener noreferrer" target="_blank">
          {uriData.label}
        </a>
      )}
    </TextOverflow>
    {!uriData.uri && uriData.label}
  </TableCell>
);

const DisabledCell = () => <TableCell className="disabled" />;
type CheckboxCellProps = { value: any };
const CheckboxCell = ({ value }: CheckboxCellProps) => {
  // A bug in @ardoq/icons is causing undefined values to be styled in a
  // black color as opposed to a grey color. Coerce undefined to null.
  const definedValue = value ?? null;
  return (
    <TableCell>
      <CheckboxStyledCell $unset={definedValue === null}>
        <CheckboxIcon checked={definedValue} />
        {definedValue === null ? UNSET_CHECKBOX_LABEL : ''}
      </CheckboxStyledCell>
    </TableCell>
  );
};
type WorkspacePathProps = { workspace: WorkspaceProps };
const WorkspacePath = ({ workspace }: WorkspacePathProps) => (
  <li className="workspace">
    <TextOverflow lineClamp={3} popoverFollowsMouse>
      {workspace.name}
    </TextOverflow>
  </li>
);
type ComponentPathProps = { component: ComponentProps; color: string };
const ComponentPath = ({ component, color }: ComponentPathProps) => (
  <li
    key={component.cid}
    style={{ color }}
    className="component"
    {...{ [COMPONENT_ID_ATTRIBUTE]: component.id }}
  >
    <TextOverflow lineClamp={3} popoverFollowsMouse>
      {component.name}
    </TextOverflow>
  </li>
);
type PathElementProps = {
  element: WorkspaceProps | ComponentProps;
  rowIndex: number;
};
const PathElement = ({ element, rowIndex }: PathElementProps) => {
  if (element.type === 'workspace') {
    return <WorkspacePath workspace={element as WorkspaceProps} />;
  }
  const component = element as ComponentProps;
  return (
    <ComponentPath
      component={component}
      color={componentTextColor(component.id, rowIndex)}
    />
  );
};
const ComponentPathList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
  li {
    max-width: ${COMPONENT_PATH_LIST_ITEM_MAX_WIDTH}px;
    &:not(:first-child):before {
      /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
      font-family: 'Material Icons Round';
      font-size: ${COMPONENT_PATH_LIST_ITEM_BULLET_SIZE}px;
      line-height: 0;
      vertical-align: middle;
      cursor: auto;
      content: '${IconName.ARROW_RIGHT}';
      color: ${colors.grey60};
    }
  }
`;
type PathCellProps = {
  path: (WorkspaceProps | ComponentProps)[] | null;
  rowIndex: number;
};
const PathCell = ({ path, rowIndex }: PathCellProps) => (
  <TableCell $isTextSelectable={false}>
    {path && (
      <ComponentPathList>
        {path.map((element, ii) => (
          <PathElement key={ii} element={element} rowIndex={rowIndex} />
        ))}
      </ComponentPathList>
    )}
  </TableCell>
);
type ReferenceListCellProps = {
  referenceList: ComponentProps[];
  rowIndex: number;
};
const ReferenceListCell = ({
  referenceList = [],
  rowIndex,
}: ReferenceListCellProps) => (
  <TableCell $isTextSelectable={false}>
    <ReferenceList>
      {referenceList.map((element, ii) => (
        <PathElement key={ii} element={element} rowIndex={rowIndex} />
      ))}
    </ReferenceList>
  </TableCell>
);
type ParentCellProps = { parent: ComponentProps; color: string };
const ParentCell = ({ parent, color }: ParentCellProps) => (
  <TableCell
    $isTextSelectable={false}
    style={{ display: 'table-cell', color }}
    {...{ [COMPONENT_ID_ATTRIBUTE]: parent.id }}
    className="component"
  >
    <TextOverflow lineClamp={3} popoverFollowsMouse>
      <span>{parent.name}</span>
    </TextOverflow>
  </TableCell>
);

type CellProps = {
  type: CellTypes;
  attribute: string;
  isExpandController: boolean;
  model: TableViewRow;
  numberFormatOptions?: string;
  rowIndex: number;
};

const Cell = ({
  type,
  attribute,
  isExpandController,
  model,
  numberFormatOptions,
  rowIndex,
}: CellProps) => {
  const field = model[attribute];

  if (field?.isDisabled) {
    return <DisabledCell />;
  }

  const locale = getCurrentLocale();
  const value = getCellValue(field);

  if (type === CellTypes.NAME) {
    return (
      <NameCell
        color={componentTextColor(model.id, rowIndex)}
        component={model}
        attribute={attribute}
        isExpandController={isExpandController}
      />
    );
  }
  if (type === CellTypes.PATH) {
    if (!value) {
      logError(Error('Path not found in table view row.'));
    }
    return <PathCell rowIndex={rowIndex} path={value} />;
  }
  if (type === CellTypes.PARENT && model.parent) {
    return (
      <ParentCell
        parent={model.parent}
        color={componentTextColor(model.parent.id, rowIndex)}
      />
    );
  }
  if (type === CellTypes.TEXT) {
    return (
      <TextCell
        text={value}
        isExpandController={isExpandController}
        model={model}
      />
    );
  }
  if (type === CellTypes.NUMBER) {
    return (
      <TextCell
        text={format(value, numberFormatOptions)}
        isExpandController={isExpandController}
        model={model}
      />
    );
  }
  if (type === CellTypes.CHECKBOX) {
    return <CheckboxCell value={value} />;
  }
  if (type === CellTypes.URI) {
    return <URICell uriData={value} />;
  }
  if (type === CellTypes.REFERENCE_LIST) {
    return <ReferenceListCell referenceList={value} rowIndex={rowIndex} />;
  }
  if (type === CellTypes.ARDOQ_OID) {
    return (
      <TextCell
        text={value}
        isExpandController={isExpandController}
        model={model}
      />
    );
  }
  if (type === CellTypes.DATE_ONLY) {
    return (
      <TextCell
        text={formatDateOnly(value, locale)}
        isExpandController={isExpandController}
        model={model}
      />
    );
  }
  if (type === CellTypes.DATE_TIME) {
    return (
      <TextCell
        text={formatDateTime(value, locale)}
        isExpandController={isExpandController}
        model={model}
      />
    );
  }
  if (type === CellTypes.TEXT_AREA) {
    return (
      <TextCell
        text={value}
        isExpandController={isExpandController}
        model={model}
        isMarkdown
      />
    );
  }
  return <DisabledCell />;
};

export default Cell;
