import { ArdoqId } from '@ardoq/api-types';
import { Node } from 'graph/node';
import Components from 'collections/components';
import References from 'collections/references';
import { Edge } from 'graph/edge';
import NodeCollection from 'graph/NodeCollection';
import GraphCollection from './GraphCollection';
import GraphUtil from 'graph/graphUtil';
import * as profiling from '@ardoq/profiling';
import { notifyReferencesRemoved } from 'streams/references/ReferenceActions';
import { notifyComponentsRemoved } from 'streams/components/ComponentActions';
import { action$, ofType } from '@ardoq/rxbeach';
import {
  notifyFiltersChanged,
  setActivePerspective,
} from 'streams/filters/FilterActions';
import { backboneModelComparator } from 'utils/compareUtils';
import {
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
} from 'streams/context/ContextActions';
import { BackboneEvents } from 'BackboneEvents';
import { ComponentBackboneModel } from 'aqTypes';
import { throttle } from 'lodash';

export type HierarchicGraphShape = ReturnType<typeof EmptyGraph>;

function BuildGraph(
  rootNodeCollection: NodeCollection,
  nodeCollection: NodeCollection,
  edgeCollection: GraphCollection<Edge>
) {
  const compMap = new Map();
  const edges: Edge[] = [];
  const workspaceMap = new Map();

  // clean up old nodes/edges
  edgeCollection.clear({ ignoreNodeUpdates: true });
  rootNodeCollection.clear({ ignoreNodeUpdates: true });
  nodeCollection.clear({ ignoreNodeUpdates: true });

  Components.collection.each(function (comp) {
    if (!comp.getWorkspace()) {
      return;
    }

    if (!comp.isIncludedInContextByFilter()) {
      return;
    }

    const workspace = comp.getWorkspace()!;
    const workspaceId = workspace.id;
    if (!workspaceMap.get(workspaceId)) {
      workspaceMap.set(
        workspaceId,
        new Node({
          dataModel: workspace,
        })
      );
    }

    compMap.set(
      comp.id,
      new Node({
        dataModel: comp,
      })
    );
  });

  References.collection.each(function (reference) {
    const sourceNode = compMap.get(reference.get('source'));
    const targetNode = compMap.get(reference.get('target'));
    if (sourceNode && targetNode && reference.isIncludedInContextByFilter()) {
      edges.push(new Edge({ reference, sourceNode, targetNode }));
    }
  });

  // building hierarchy
  const sortedComponents = Array.from(compMap.values()).sort((a, b) =>
    backboneModelComparator(a.dataModel, b.dataModel)
  );
  sortedComponents.forEach(comp => {
    const parentId =
      comp.dataModel.getParent() && comp.dataModel.getParent().id;
    const parent = parentId && compMap.get(parentId);
    if (parent) {
      parent.children.add(comp);
      comp.setParent(parent);
    } else if (!parentId) {
      const workspaceNode = workspaceMap.get(comp.dataModel.getWorkspace().id);
      workspaceNode.children.add(comp);
      comp.setParent(workspaceNode);
    }
  });

  rootNodeCollection.add(workspaceMap);
  nodeCollection.add(compMap);
  nodeCollection.add(workspaceMap);

  edgeCollection.add(edges);

  return compMap;
}

function EmptyGraph() {
  return {
    rootNodes: new NodeCollection(),
    nodes: new NodeCollection(),
    edges: new GraphCollection<Edge>(),
    activeNodes: {},
  };
}

type GetGraphBaseArgs = {
  maxDegreesIncoming?: number;
  maxDegreesOutgoing?: number;
  includeParents?: boolean;
};
type GetGraphByWorkspaceArgs = GetGraphBaseArgs & { workspaceId: ArdoqId };
type GetGraphByComponentArgs = GetGraphBaseArgs & {
  component: ComponentBackboneModel;
};
type Options = { degreesOfRelationsship: number; types?: unknown };
class HierarchicGraph extends BackboneEvents {
  rootNodeCollection = new NodeCollection();
  nodeCollection = new NodeCollection();
  edgeCollection = new GraphCollection<Edge>();

  dirty = true;
  compMap = new Map();

  _options: Options = { degreesOfRelationsship: 3 };

  rebuild = profiling.functionWithTransaction(
    'hierarchic graph rebuild',
    500,
    () => {
      // Garbage collect old compMap
      this.compMap.clear();
      this.compMap = BuildGraph(
        this.rootNodeCollection,
        this.nodeCollection,
        this.edgeCollection
      );
      this.dirty = false;
    },
    profiling.Team.INSIGHT
  );
  unthrottledRebuild = this.rebuild;

  throttledRebuild = throttle(
    () => {
      if (this.dirty) {
        this.rebuild();
      }
    },
    150,
    {
      leading: true,
    }
  );

  types = this._options.types;

  constructor() {
    super();
    this.listenTo(
      References.collection,
      'add change:source change:type change:target',
      this.invalidateGraph
    );
    this.listenTo(
      Components.collection,
      'add change:parent change:_order',
      this.invalidateGraph
    );
    action$
      .pipe(
        ofType(
          notifyReferencesRemoved,
          notifyComponentsRemoved,
          setActivePerspective,
          notifyFiltersChanged,
          notifyWorkspaceClosed,
          notifyWorkspaceOpened
        )
      )
      .subscribe(this.invalidateGraph);
  }

  invalidateGraph = () => {
    this.dirty = true;
  };

  // Public API

  getFullGraph = () => {
    this.throttledRebuild();
    return {
      rootNodes: this.rootNodeCollection,
      nodes: this.nodeCollection,
      edges: this.edgeCollection,
      activeNodes: {},
    };
  };

  getGraphByWorkspace = ({
    workspaceId,
    maxDegreesIncoming = 3,
    maxDegreesOutgoing = 3,
    includeParents = true,
  }: GetGraphByWorkspaceArgs) => {
    const allRootNodes = new NodeCollection();
    const allNodes = new NodeCollection();
    const allEdges = new GraphCollection<Edge>();
    const activeNodes = {};

    if (!workspaceId) {
      return EmptyGraph();
    }
    this.throttledRebuild();

    Components.collection
      .filter(component => {
        return (
          !component.getParent() &&
          component.get('rootWorkspace') === workspaceId &&
          component.isIncludedInContextByFilter()
        );
      })
      .forEach(component => {
        const { rootNodes, nodes, edges } = this.getGraphByComponent({
          component,
          maxDegreesIncoming,
          maxDegreesOutgoing,
          includeParents,
        });

        allRootNodes.add(rootNodes);
        allNodes.add(nodes);
        allEdges.add(edges);
      });

    return {
      rootNodes: allRootNodes,
      nodes: allNodes,
      edges: allEdges,
      activeNodes,
    };
  };

  getGraphByComponent = ({
    component,
    maxDegreesIncoming = 3,
    maxDegreesOutgoing = 3,
    includeParents = true,
  }: GetGraphByComponentArgs) => {
    this.throttledRebuild();
    const rootNode = this.compMap.get(component && component.id);
    const activeNodes: Record<string, boolean> = {};
    const includedNodes = new Set<Node>();
    const includedEdges = new Set<Edge>();

    if (!component || !rootNode) {
      return EmptyGraph();
    }

    activeNodes[rootNode.id] = true;
    if (maxDegreesIncoming !== maxDegreesOutgoing) {
      GraphUtil.getOutgoingGraphSubset({
        node: rootNode,
        nodeSet: includedNodes,
        edgeSet: includedEdges,
        maxDegrees: maxDegreesOutgoing,
        includeParents: includeParents,
      });
      // Get incoming nodes on the outgoing subset
      const incomingNodes = new Set<Node>();
      includedNodes.forEach(node => {
        GraphUtil.getIncomingGraphSubset({
          node,
          nodeSet: incomingNodes,
          edgeSet: includedEdges,
          maxDegrees: maxDegreesIncoming,
          includeParents: includeParents,
        });
      });
      incomingNodes.forEach(node => includedNodes.add(node));
    } else {
      GraphUtil.getGraphSubset({
        node: rootNode,
        nodeSet: includedNodes,
        edgeSet: includedEdges,
        maxDegreesIncoming,
        maxDegreesOutgoing: maxDegreesIncoming,
        includeParents: includeParents,
      });
    }

    GraphUtil.ensureAllEdgesAreIncluded({
      nodeSet: includedNodes,
      edgeSet: includedEdges,
      allEdges: this.edgeCollection,
    });

    return {
      rootNodes: new NodeCollection([rootNode]),
      nodes: new NodeCollection(Array.from(includedNodes)),
      edges: new GraphCollection<Edge>(Array.from(includedEdges)),
      activeNodes: activeNodes,
    };
  };
}

export const hierarchicGraphInstance = new HierarchicGraph();
