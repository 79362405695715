import { createElement } from 'react';
import {
  Class,
  EdgeStyleBase,
  GeneralPath,
  IEdge,
  IInputModeContext,
  INode,
  IObstacleProvider,
  IRenderContext,
  Point,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import { createSvgElement } from '@ardoq/dom-utils';
import { DiffType } from '@ardoq/data-model';
import { createPath } from 'yfilesExtensions/edgeUtil';
import SparkMD5 from 'spark-md5';
import { DATA_RENDER_HASH } from 'yfilesExtensions/styles/consts';
import {
  AggregatedGraphEdge,
  GraphEdge,
  GraphNode,
  ParentChildGraphEdge,
  type RelationshipDiagramGraphEdgeType,
  disposeCallback,
  ensureRoot,
} from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import AggregatedEdge from '../AggregatedEdge';
import BasicEdgeObstacleProvider from 'yfilesExtensions/BasicEdgeObstacleProvider';
import { classes } from '@ardoq/common-helpers';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { MODEL_ID_ATTRIBUTE } from 'consts';
import { SMOOTH_PATH_OPTIONS } from 'tabview/relationshipDiagrams/consts';
import { Root } from 'react-dom/client';

const createRenderDataCache = (
  edge: RelationshipDiagramGraphEdgeType,
  pathData: string,
  hoverPathData: string,
  sourceNode: INode | null,
  targetNode: INode | null
) =>
  SparkMD5.hash(
    `${edge.getLineBeginning()},${edge.getLineEnding()},${edge.getCSS({
      useAsBackgroundStyle: false,
    })},${pathData},${hoverPathData},${edge.getVisualDiffType()},${String(
      isGhostNode(targetNode) || isGhostNode(sourceNode)
    )},${edge.isTransparentized}`
  );
const validateGraphEdge = (
  graphEdge: any
): graphEdge is RelationshipDiagramGraphEdgeType => {
  if (
    !(
      graphEdge instanceof GraphEdge ||
      graphEdge instanceof AggregatedGraphEdge ||
      graphEdge instanceof ParentChildGraphEdge
    )
  ) {
    logError(Error('Unexpected edge type.'));
    return false;
  }
  return true;
};
const isNode = (node: any): node is GraphNode => node instanceof GraphNode;
const isGhostNode = (node: any) => isNode(node) && node.isGhost;

const getModelIds = (graphEdge: RelationshipDiagramGraphEdgeType) =>
  graphEdge instanceof ParentChildGraphEdge
    ? []
    : graphEdge instanceof AggregatedGraphEdge
      ? graphEdge.modelIds
      : [graphEdge.modelId];

const render = (
  root: Root,
  edge: IEdge,
  container: SVGElement,
  path: GeneralPath,
  hoverPath: GeneralPath,
  cache: string
) => {
  container.setAttribute(DATA_RENDER_HASH, cache);
  const { sourceNode, targetNode, tag: graphEdge } = edge;
  if (!sourceNode || !targetNode || !validateGraphEdge(graphEdge)) {
    logError(Error('Invalid edge.'));
    return;
  }
  const { tag: sourceGraphNode } = sourceNode;
  const { tag: targetGraphNode } = targetNode;

  const isGhostReference =
    isGhostNode(sourceGraphNode) || isGhostNode(targetGraphNode);

  const diffType = isGhostReference
    ? DiffType.UNCHANGED
    : graphEdge.getVisualDiffType();
  const visualDiffClassName = getCssClassFromDiffType(diffType);
  container.setAttribute('class', classes('integration', visualDiffClassName));
  const modelIds = getModelIds(graphEdge);
  const tooltipAttributes = Object.entries(
    modelIds.length === 1 ? getChangedPopover(modelIds[0], diffType) : {}
  );

  if (tooltipAttributes.length > 0) {
    tooltipAttributes.forEach(([key, value]) =>
      container.setAttribute(key, value)
    );
  } else {
    [POPOVER_ID_ATTR, MODEL_ID_ATTRIBUTE].forEach(key =>
      container.removeAttribute(key)
    );
  }

  container.dataset.referenceModelIds = modelIds.join(',');
  const pathData = path
    .createSmoothedPath(SMOOTH_PATH_OPTIONS)
    .createSvgPathData();
  const hoverPathData = hoverPath
    .createSmoothedPath(SMOOTH_PATH_OPTIONS)
    .createSvgPathData();

  root.render(
    createElement(AggregatedEdge, {
      edgeSourceLocation: [
        edge.sourcePort!.location.x,
        edge.sourcePort!.location.y,
      ],
      className: graphEdge.getCSS({ useAsBackgroundStyle: false }),
      diffType,
      pathData,
      hoverPathData,
      isParentChildReference: graphEdge instanceof ParentChildGraphEdge,
      lineBeginning: graphEdge.getLineBeginning(),
      lineEnding: graphEdge.getLineEnding(),
      isTransparentized: graphEdge.isTransparentized,
    })
  );
};

class AggregateEdgeStyle extends EdgeStyleBase {
  override createVisual(context: IRenderContext, edge: IEdge) {
    const g = createSvgElement('g');
    const { sourceNode, targetNode, tag: graphEdge } = edge;
    if (!validateGraphEdge(graphEdge)) {
      logError(Error('Unexpected edge type.'));
      return new SvgVisual(g);
    }
    const path = createPath(edge, false);
    const hoverPath = createPath(edge, true);
    const cache = createRenderDataCache(
      graphEdge,
      path.createSvgPathData(),
      hoverPath.createSvgPathData(),
      sourceNode,
      targetNode
    );
    render(ensureRoot(g), edge, g, path, hoverPath, cache);
    const result = new SvgVisual(g);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    edge: IEdge
  ) {
    if (!(oldVisual instanceof SvgVisual)) {
      logError(Error('Invalid visual in AggregateEdgeStyle updateVisual.'));
      return oldVisual;
    }
    const { sourceNode, targetNode, tag: graphEdge } = edge;
    if (!validateGraphEdge(graphEdge)) {
      return oldVisual;
    }
    const container = oldVisual.svgElement;

    const oldCache = container.getAttribute(DATA_RENDER_HASH);
    const path = createPath(edge, false);
    const hoverPath = createPath(edge, true);
    const newCache = createRenderDataCache(
      graphEdge,
      path.createSvgPathData(),
      hoverPath.createSvgPathData(),
      sourceNode,
      targetNode
    );

    if (oldCache === newCache) {
      return oldVisual;
    }

    render(ensureRoot(container), edge, container, path, hoverPath, newCache);
    return oldVisual;
  }

  isHit(canvasContext: IInputModeContext, location: Point, edge: IEdge) {
    const hitTestRadius = 13; // the hover path is 25px wide, set in CSS.  if the hover path is showing, this should be a hit.
    return this.getPath(edge).pathContains(location, hitTestRadius);
  }

  override getPath(edge: IEdge) {
    return createPath(edge, true);
  }

  lookup(edge: IEdge, type: Class) {
    if (type === IObstacleProvider.$class) {
      return new BasicEdgeObstacleProvider(edge);
    }
    return super.lookup(edge, type);
  }
}

export default AggregateEdgeStyle;
