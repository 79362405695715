import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ArdoqGraphComponent,
  ArdoqGraphComponentForwardRefType,
  canBypassLimit,
  isAboveItemLimit,
  PerformanceTrackedGraphView,
} from 'tabview/graphComponent/ArdoqGraphComponent';
import SettingsBar from './view/ScenarioSyncedSettingsBar';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { ViewIds } from '@ardoq/api-types';
import {
  type GraphComponent,
  GraphInputMode,
  GraphViewerInputMode,
  ICommand,
  IGraph,
} from '@ardoq/yfiles';
import { getViewModel$ } from './viewModel$';
import type {
  BlockDiagramViewProperties,
  BlockDiagramViewSettings,
} from './types';
import CollapsibleGroupsYGraphBuilder from './view/yFilesExtensions/CollapsibleGroupsYGraphBuilder';
import { isInScopeDiffMode, isLoadingScope } from 'scope/scopeDiff';
import onLayoutGraphHandler from './view/yFilesExtensions/onLayoutGraph';
import configureLayout from './view/configureLayout';
import { ZoomControl } from 'atomicComponents/Zoomable/ZoomControls';
import { IconName } from '@ardoq/icons';
import { connectInstance, dispatchAction } from '@ardoq/rxbeach';
import { relationshipDiagramReset } from 'tabview/relationshipDiagrams/actions';
import BlockDiagramVisualDiffSwitcher from './view/BlockDiagramVisualDiffSwitcher';
import { getNodeSize, getStyle } from 'yfilesExtensions/styles/styleLoader';
import { MainPaneLoader } from 'streams/views/mainContent/MainPaneLoader';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import { notifyViewLoading } from 'tabview/actions';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import ViewAndLegendContainer from 'tabview/ViewAndLegendContainer';
import { defaultLegendOnClick } from 'viewSettings/getRightMenuConfig';
import EmptyState from './EmptyState';
import {
  addedAndUpdatedGraphEdges,
  addedAndUpdatedGraphGroups,
  addedAndUpdatedGraphNodes,
  getGraphNodeCount,
} from '@ardoq/graph';
import { trackNodeCountWithPresentationMeta } from 'tracking/events/visualizations';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { notifyExportFailure } from '@ardoq/export';
import type { OnLayoutGraphArgs } from 'tabview/graphComponent/onLayoutGraph';
import {
  DisabledZoomControls,
  updateViewContext,
  type ViewContext,
} from '@ardoq/view-settings';
import { isPresentationMode } from 'appConfig';
import EphemeralNotification from 'ephemeralNotification/EphemeralNotification';
import { EphemeralNotificationKeys } from 'ephemeralNotification/types';
import { zIndex } from '@ardoq/design-tokens';

const VIEW_ID = ViewIds.BLOCK_DIAGRAM;

const getGraphBuilder = (graph: IGraph) =>
  new CollapsibleGroupsYGraphBuilder(VIEW_ID, graph, getStyle, getNodeSize);

const configureRendering = (graphComponent: GraphComponent) => {
  const inputMode = graphComponent.inputMode as GraphViewerInputMode;
  inputMode.navigationInputMode.enabled = false;
  graphComponent.maximumZoom = 1.6;
  graphComponent.minimumZoom = 0.01;
  graphComponent.mouseWheelZoomFactor = 1.1;
};

const BlockDiagramView = (props: BlockDiagramViewProperties) => {
  const ardoqGraphComponentRef =
    useRef<ArdoqGraphComponentForwardRefType>(null);

  const graphBuilder = useRef<CollapsibleGroupsYGraphBuilder | null>(null);

  const {
    viewModel,
    viewInstanceId,
    embeddableViewConfig,
    viewSettings,
    scenarioName,
    noContext,
    isViewpointMode,
    clearEdges,
  } = props;
  const buildGraph = useCallback(
    (
      graphComponent: GraphComponent,
      bypassLimit: boolean,
      clearEdges: boolean
    ) => {
      graphBuilder.current =
        graphBuilder.current || getGraphBuilder(graphComponent.graph);

      setTimeout(() =>
        // This call dispatches a reducer action which accordingly
        // triggers a render in the navigator component which results in this kind
        // of warning:
        //
        // createActionStream.ts:18 Warning: Cannot update a component (`Connect`)
        // while rendering a different component (`ForwardRef`). To locate the bad
        // setState() call inside `ForwardRef`, follow the stack trace as described
        // in https://reactjs.org/link/setstate-in-render
        // at eval (webpack-internal:///./tabview/graphComponent/ArdoqGraphComponent.tsx:93:11)
        //
        // The timeout is more a workaround than a proper fix.
        (graphComponent.inputMode as GraphInputMode).clearSelection()
      );
      if (clearEdges) {
        graphBuilder.current.clearEdges();
      }
      const groups = addedAndUpdatedGraphGroups(viewModel);

      const nodes = addedAndUpdatedGraphNodes(viewModel);
      const edges = addedAndUpdatedGraphEdges(viewModel);

      const aboveLimit = isAboveItemLimit(nodes.length, edges.length);
      const canBypass = canBypassLimit(nodes.length, edges.length);
      const isEmpty = !nodes.length && !edges.length && !groups.length;
      const hasLayoutUpdate = graphBuilder.current.hasLayoutUpdate({
        groups,
        nodes,
        edges,
      });
      if ((!bypassLimit && aboveLimit) || isEmpty) {
        graphBuilder.current.groupsSource = [];
        graphBuilder.current.nodesSource = [];
        graphBuilder.current.edgesSource = [];
        graphBuilder.current.clear();
      } else {
        graphBuilder.current.groupsSource = groups;
        graphBuilder.current.nodesSource = nodes;
        graphBuilder.current.edgesSource = edges;
        graphBuilder.current.updateGraph();
      }

      return { isAboveLimit: aboveLimit, isEmpty, canBypass, hasLayoutUpdate };
    },
    [viewModel]
  );
  const getContainer = () =>
    ardoqGraphComponentRef.current?.graphComponentContainer.current ?? null;
  const getGraphComponentRef = () =>
    ardoqGraphComponentRef.current?.graphComponent ?? null;
  const getGraphComponent = () => getGraphComponentRef()?.current ?? null;
  const optimizeLayout = () => dispatchAction(relationshipDiagramReset());
  const toggleLegend = () => {
    isLegendActive = !isLegendActive;
    defaultLegendOnClick({
      isLegendActive,
      viewId: VIEW_ID,
    });
    dispatchAction(
      updateViewContext({
        ...renderingParameters.current,
        isLegendActive,
      })
    );
  };

  useEffect(() => {
    if (!getGraphComponentRef()) {
      graphBuilder.current = null;
    }

    const currentGraphNodeCount = getGraphNodeCount(viewModel);
    trackNodeCountWithPresentationMeta(VIEW_ID, currentGraphNodeCount);

    if (!currentGraphNodeCount || viewModel.noConnectedComponents) {
      // normally isBusy=false is dispatched in the graph layout callback, but in this case we have no graph, so we must dispatch isBusy=false
      setTimeout(() =>
        dispatchAction(
          notifyViewLoading({
            viewInstanceId,
            isBusy: false,
          })
        )
      );
    }
  }, [viewInstanceId, viewModel]);

  // let isLegendActive holds the "active" value of the legend and is temorarely detached from
  // the state due to getFilteredViewSettings$.
  // This value & the viewstate are synchronized as soon an update passes the filter.
  let isLegendActive = viewSettings.isLegendActive ?? false;
  const renderingParameters = useRef({
    getContainer,
    getGraphComponent,
    optimizeLayout,
    zoomIn: () => ICommand.INCREASE_ZOOM.execute(1.2, getGraphComponent()),
    zoomOut: () => ICommand.DECREASE_ZOOM.execute(1.2, getGraphComponent()),
    toggleLegend,
    isLegendActive,
    zoomControls: null,
    zoomControlsDisabledState: DisabledZoomControls.NONE,
  } satisfies ViewContext);
  useEffect(() => {
    dispatchAction(updateViewContext(renderingParameters.current));
    const graphComponent = getGraphComponent();
    if (!graphComponent) {
      return;
    }
    const updateZoomControlsDisabledState = () => {
      const { zoom, minimumZoom, maximumZoom } = graphComponent;
      const isZoomOutDisabled = zoom - Number.EPSILON <= minimumZoom;
      const isZoomInDisabled = zoom + Number.EPSILON >= maximumZoom;
      const zoomControlsDisabledState =
        (Number(isZoomOutDisabled) && DisabledZoomControls.ZOOM_OUT) |
        (Number(isZoomInDisabled) && DisabledZoomControls.ZOOM_IN);

      dispatchAction(
        updateViewContext({
          ...renderingParameters.current,
          zoomControlsDisabledState,
        })
      );
    };
    graphComponent.addViewportChangedListener(updateZoomControlsDisabledState);

    return () =>
      graphComponent.removeViewportChangedListener(
        updateZoomControlsDisabledState
      );
  });
  const {
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
    noConnectedComponents,
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
    expandedStateChangingGroupId,
    hasClones,
    errors,
  } = viewModel;
  const showLeftSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showLeftSettingsSection;
  const showRightSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showRightSettingsSection;
  const activeGroups = viewModel.groups.add.concat(viewModel.groups.update);

  const isEmptyView =
    !getGraphNodeCount(viewModel) || noConnectedComponents || noContext;

  const isScopeLoading = isLoadingScope();

  const [clearedErrors, setClearedErrors] = useState(false);
  const [clearedHasClones, setClearedHasClones] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    ViewIds.BLOCK_DIAGRAM,
    NEVER_SHOW_AGAIN
  );

  const onLayoutGraph = (args: OnLayoutGraphArgs) => {
    onLayoutGraphHandler(expandedStateChangingGroupId)(args);
    const { layoutDescriptor } = args;
    const isFreshLayout =
      layoutDescriptor.name === 'HierarchicLayout' &&
      layoutDescriptor.properties?.layoutMode === 'from-scratch';
    if (isFreshLayout) {
      dispatchAction(
        updateViewContext({
          ...renderingParameters.current,
          zoomControlsDisabledState: DisabledZoomControls.FIT_WINDOW,
        })
      );
    }
  };

  return (
    <div className={`tab-pane ${VIEW_ID}Tab active`}>
      {isViewpointMode ? null : (
        <div className="menuContainer">
          <SettingsBar
            viewId={VIEW_ID}
            getLeftMenuArgs={{
              traversedOutgoingReferenceTypes,
              traversedIncomingReferenceTypes,
              referenceTypes,
              viewId: VIEW_ID,
              onViewSettingsUpdate,
              activeGroups,
              isViewpointMode,
            }}
            getRightMenuArgs={{
              getContainer,
              getGraphComponent,
              viewId: VIEW_ID,
              legendOnClick: toggleLegend,
              onViewSettingsUpdate,
              isEmptyView,
              showExportFailureModal: notifyExportFailure,
            }}
            showLeftSettingsSection={showLeftSettingsSection}
            showRightSettingsSection={showRightSettingsSection}
            viewInstanceId={viewInstanceId}
          />
        </div>
      )}
      <ViewAndLegendContainer>
        {isEmptyView ? (
          <EmptyState
            noConnectedComponents={noConnectedComponents}
            noContext={noContext}
          />
        ) : (
          <>
            {isViewpointMode && props.viewId === ViewIds.BLOCK_DIAGRAM ? (
              <EphemeralNotification
                wrapperPositionStyle={{
                  position: 'absolute',
                  top: 16,
                  zIndex: zIndex.VIEW_LEGEND,
                  justifyContent: 'center',
                }}
                ephemeralNotificationKey={
                  EphemeralNotificationKeys.SWITCH_TO_THE_NEW_BLOCK_DIAGARAM
                }
              />
            ) : null}
            <MainPaneLoader isLoading={isScopeLoading} />
            <ArdoqGraphComponent
              viewId={VIEW_ID}
              viewInstanceId={viewInstanceId}
              ref={ardoqGraphComponentRef}
              viewModel={viewModel}
              isLegendActive={viewSettings.isLegendActive}
              buildGraph={(graphComponent, bypassLimit) =>
                buildGraph(graphComponent, bypassLimit, clearEdges)
              }
              enableStyles={true}
              configureLayout={graphComponent =>
                configureLayout(graphComponent, viewModel, viewSettings)
              }
              configureRendering={configureRendering}
              onLayoutGraph={onLayoutGraph}
              useHoverDecorator={false}
              hideZoomControls={isViewpointMode && !isPresentationMode()}
              additionalZoomControls={
                <ZoomControl
                  iconName={IconName.OPTIMIZE_LAYOUT}
                  onClick={optimizeLayout}
                  label="Optimize layout"
                />
              }
              urlFieldValuesByComponentId={urlFieldValuesByComponentId}
              urlFieldValuesByReferenceId={urlFieldValuesByReferenceId}
              isViewpointMode={isViewpointMode}
            />
            <ErrorInfoBox
              errors={clearedErrors ? [] : errors}
              hasClones={!neverShowAgain && !clearedHasClones && hasClones}
              clearHasClones={() => setClearedHasClones(true)}
              clearErrors={() => setClearedErrors(true)}
              isShowNeverAgainSet={neverShowAgain}
              toggleNeverShowAgain={toggleNeverShowAgain}
              isPresentationMode={isPresentationMode()}
            />
            {(isInScopeDiffMode() || isScopeLoading) && (
              <BlockDiagramVisualDiffSwitcher
                viewId={ViewIds.BLOCK_DIAGRAM}
                scenarioName={scenarioName}
                activeDiffMode={viewSettings.activeDiffMode}
              />
            )}
          </>
        )}
      </ViewAndLegendContainer>
    </div>
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<BlockDiagramViewSettings>(VIEW_ID);

const PerformanceTrackedBlockDiagram = (props: BlockDiagramViewProperties) =>
  PerformanceTrackedGraphView(BlockDiagramView, props, VIEW_ID);

const BlockDiagramViewWithLoadingIndicator = (
  props: BlockDiagramViewProperties
) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedBlockDiagram,
    wrappedProps: props,
    showContentWhileLoading: true,
  });

export default connectInstance(
  BlockDiagramViewWithLoadingIndicator,
  getViewModel$({
    viewId: VIEW_ID,
    viewSettings$,
  })
);
