import { ChevronIcon } from '@ardoq/icons';
import styled from 'styled-components';
import { GremlinObjectType } from '../types';

const PathWrapper = styled.span`
  line-height: 25px;

  strong {
    padding-left: 5px;
  }

  i {
    padding: 0 5px;
    font-size: 20px;
    transform: translateY(5px);
  }
`;

const ArrowSign = (props: { printable?: boolean }) =>
  props.printable ? <>{' -> '}</> : <ChevronIcon direction="right" />;

interface GremlinPathProps {
  pathLabels: { name: string; label: string; type: GremlinObjectType }[];
  printable?: boolean;
}
const GremlinPath = (props: GremlinPathProps) => (
  <PathWrapper>
    {props.pathLabels.map(({ name, label, type }, index: number) => {
      const showArrow = index < props.pathLabels.length - 1;

      if (type === GremlinObjectType.EDGE) {
        return (
          <span key={index}>
            <em>{label}</em>
            {showArrow && <ArrowSign printable={props.printable} />}
          </span>
        );
      }
      return (
        <span key={index}>
          {label}:<strong>{name}</strong>
          {showArrow && <ArrowSign printable={props.printable} />}
        </span>
      );
    })}
  </PathWrapper>
);

export default GremlinPath;
