export const MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT = 120;
export const MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH = 158;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING = 10;
const SHAPE_MARGIN_TOP = 14;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_SHAPE_Y =
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING + SHAPE_MARGIN_TOP;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_SHAPE_Y_COMPACT =
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT = 47;
export const MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE = 16;
export const MODERNIZED_BLOCK_DIAGRAM_CORNER_RADIUS = 8;
export const MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_INNER = 1.2;
export const MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_OUTER = 5;
