import * as React from 'react';
import { type ArdoqId, APIEntityType } from '@ardoq/api-types';
import { ComponentRepresentation } from '@ardoq/renderers';
import { IconSize } from '@ardoq/icons';
import {
  COMPONENT_ID_ATTRIBUTE,
  EXPORT_AUTOSIZE_CLASS_NAME,
} from '@ardoq/global-consts';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import {
  ComponentTreeLabel,
  LabelTextOverflow,
  RepresentationWrapper,
  RowContent,
  WorkspaceIcon,
} from '../atoms';
import { Expander } from 'components/Expander/Expander';
import { ComponentItemData } from '../types';
import { WithPopover } from '@ardoq/popovers';
import { componentTreeCommands } from '../commands';

type ComponentRowProps = {
  componentId: ArdoqId;
  hasChildren: boolean;
  isCollapsed: boolean;
  isSelected: boolean;
  isFocused: boolean;
  isHovered: boolean;
  depth: number;
  itemData: ComponentItemData;
  isFilteredOut: boolean;
};
export const ComponentRow = React.memo(
  ({
    componentId,
    hasChildren,
    isCollapsed,
    isSelected,
    isFocused,
    isHovered,
    depth,
    itemData,
    isFilteredOut,
  }: ComponentRowProps) => {
    const {
      name,
      displayLabel,
      fullLabel,
      type,
      representationData,
      iconStyle,
      componentStyle,
      classNames,
    } = itemData;
    const contextMenuAttribute =
      type === APIEntityType.COMPONENT
        ? COMPONENT_ID_ATTRIBUTE
        : GLOBAL_HANDLER_ID_ATTRIBUTE;

    const handleExpanderClick = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        componentTreeCommands.toggleCollapseHierarchy(componentId);
      },
      [componentId]
    );

    return (
      <RowContent
        className={classNames}
        style={componentStyle}
        $depth={depth}
        onMouseEnter={() =>
          componentTreeCommands.toggleHoveredStateForComponent(
            componentId,
            true,
            type
          )
        }
        onMouseLeave={() =>
          componentTreeCommands.toggleHoveredStateForComponent(
            componentId,
            false,
            type
          )
        }
        onClick={e => {
          e.stopPropagation();
          componentTreeCommands.toggleFocusedStateForComponent(
            componentId,
            isFocused,
            type
          );
        }}
        onDoubleClick={() =>
          componentTreeCommands.toggleSelectedStateForComponent(
            componentId,
            type
          )
        }
        $isSelected={isSelected}
        $isFilteredOut={isFilteredOut}
        $isFocused={isFocused}
        $isHovered={isHovered}
        {...{ [contextMenuAttribute]: componentId }}
      >
        <Expander
          hasChildren={hasChildren}
          isCollapsed={isCollapsed}
          onClick={handleExpanderClick}
          size={IconSize.SMALL}
        />
        {type === APIEntityType.COMPONENT && (
          <>
            <RepresentationWrapper style={iconStyle}>
              {representationData && (
                <ComponentRepresentation
                  iconSize={IconSize.SMALL}
                  {...representationData}
                />
              )}
            </RepresentationWrapper>
            {displayLabel === fullLabel ? (
              <ComponentTreeLabel className={EXPORT_AUTOSIZE_CLASS_NAME}>
                {displayLabel}
              </ComponentTreeLabel>
            ) : (
              <WithPopover content={fullLabel}>
                <ComponentTreeLabel className={EXPORT_AUTOSIZE_CLASS_NAME}>
                  {displayLabel}
                </ComponentTreeLabel>
              </WithPopover>
            )}
          </>
        )}
        {type === APIEntityType.WORKSPACE && (
          <>
            <WorkspaceIcon />
            <LabelTextOverflow>{name}</LabelTextOverflow>
          </>
        )}
      </RowContent>
    );
  }
);
