import { ModalTemplateProps, modal } from '@ardoq/modal';
import DeleteAssetDialog from './DeleteAssetDialog';
import { DeleteAssetProps } from './types';

export const confirmDeleteAsset = (
  confirmDialogConfig: DeleteAssetProps &
    Pick<ModalTemplateProps, 'inputDataTestId'>
) => {
  return modal<boolean>(resolve => (
    <DeleteAssetDialog
      {...confirmDialogConfig}
      onConfirm={() => resolve(true)}
      onCancel={() => resolve(false)}
    />
  ));
};
