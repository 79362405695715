import { startAction } from 'actions/utils';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { action$, ofType } from '@ardoq/rxbeach';
import { startWith } from 'rxjs';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
} from 'streams/fields/FieldActions';

const bubbleChartModelUpdateNotification$ = action$.pipe(
  ofType(
    notifyComponentsUpdated,
    notifyComponentsAdded,
    notifyComponentsRemoved,
    notifyFiltersChanged,
    notifyFieldAdded,
    notifyFieldRemoved
  ),
  startWith(startAction())
);
export default bubbleChartModelUpdateNotification$;
