import { filterInterface } from '@ardoq/filter-interface';
import { componentInterface } from '@ardoq/component-interface';
import { ensureContrast } from '@ardoq/color-helpers';

import { ArdoqId } from '@ardoq/api-types';
import { GetCssClassNamesOption } from '@ardoq/data-model';
import { colors } from '@ardoq/design-tokens';

/**
 * @deprecated use `componentInterface.getComponentDisplayColorAsSVGAttributes` instead
 */
export const getComponentCssColors = (
  componentId: ArdoqId,
  options: GetCssClassNamesOption
) =>
  componentInterface.isComponent(componentId)
    ? componentInterface.getComponentDisplayColorAsSVGAttributes(
        componentId,
        options
      )
    : {
        fill: undefined,
        stroke: undefined,
      };

export const contrastEnsuredComponentFill = (
  componentId: ArdoqId,
  backgroundColor = colors.white,
  defaultFill = colors.white
) =>
  ensureContrast(
    backgroundColor,
    getComponentCssColors(componentId, { useAsBackgroundStyle: false })?.fill ??
      defaultFill
  );

export const isConditionalFormattingForComponentsActive = () =>
  Boolean(
    filterInterface
      .getFormattingFilters()
      .filter(({ color, affectComponent }) => color && affectComponent).length
  );

export const getActiveConditionalFormattingColorForComponent = (
  componentId: string
) =>
  componentId && isConditionalFormattingForComponentsActive()
    ? getComponentCssColors(componentId, {
        useAsBackgroundStyle: false,
      })?.fill
    : undefined;
