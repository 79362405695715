import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { CONTEXT_HIGHLIGHT_STROKE_WIDTH } from './consts';

export const AxisLine = styled.path`
  fill: none;
  stroke: ${colors.grey80};
`;

export const AxisTick = styled.line`
  stroke: ${colors.grey80};
`;

const axisLabel = css`
  color: ${colors.grey25};
`;

export const XAxisLabel = styled.text`
  ${axisLabel}
  text-anchor: middle;
`;

export const YAxisLabel = styled.text`
  ${axisLabel}
  text-anchor: end;
`;

export const AxisTitle = styled.text`
  text-anchor: middle;
  dominant-baseline: middle;
`;

export const BubbleChartViewContainer = styled.div`
  & .rotate-270 {
    transform: rotate(270deg);
  }
`;
export const BubbleChartContainer = styled.div`
  height: 100%;
  position: relative;
  & svg:not(.forExport) text.dataPointLabel {
    @keyframes pointerEventsDelay {
      0% {
        pointer-events: none;
        opacity: 0;
      }
      100% {
        pointer-events: auto;
        opacity: 1;
      }
    }
    animation: 500ms linear 0s pointerEventsDelay;
  }
`;
export const BubbleChartSvgElement = styled.svg`
  display: block;
`;

export const DataPointGElement = styled.g`
  transition-property: opacity;
  transition-duration: 100ms;
`;
export const DataPointContextHighlight = styled.circle`
  stroke-width: ${CONTEXT_HIGHLIGHT_STROKE_WIDTH};
  stroke: ${colors.blue60};
  fill: none;
`;
export const DataPointCircleElement = styled.circle<{ $isBlurred: boolean }>`
  opacity: 0.5;
  stroke-opacity: 0.7;
  ${props => props.$isBlurred && 'opacity: 0.1'}
`;

export const LabelTextElement = styled.text`
  user-select: none;
`;

export const QuadrantLabelText = styled.text`
  text-anchor: middle;
  dominant-baseline: middle;
  fill: ${colors.grey60};
  font-size: 32px;
  opacity: 0.5;
`;
export const TimeLine = styled.line`
  stroke-width: 1px;
  clip-path: url(#chartAreaClipPath);
  stroke: ${colors.grey80};
`;
