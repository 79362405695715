import { subscribeToAction } from 'streams/utils/streamUtils';
import { debounce, isEqual, omit } from 'lodash';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { getExportsForHtmlView } from '@ardoq/export';
import { StreamedProps, viewModel$ } from './viewModel$';
import { GRID_WIDTH } from './consts';
import { ViewContainer } from './atoms';
import { CapabilityMapContentContainer } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { ViewStreamShape } from './types';
import { zoomCenter } from 'atomicComponents/Zoomable/actions';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { defaultLegendOnClick } from 'viewSettings/getRightMenuConfig';
import LayoutBox from './LayoutBox';
import { MouseEvent, RefObject, createRef, Component } from 'react';
import { ViewIds } from '@ardoq/api-types';
import Zoomable from 'atomicComponents/Zoomable/Zoomable';
import { selectComponent } from 'streams/components/ComponentActions';
import { isClick } from './utils';
import { logError } from '@ardoq/logging';
import { ZoomableViewLegendContainerForwardRef } from 'tabview/relationshipDiagrams/atoms';
import GraphViewLegend from 'tabview/graphViews/graphViewLegend/GraphViewLegend';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import { COMPONENT_ID_SELECTOR } from 'consts';
import { Subscription } from 'rxjs';
import { toggleLegend } from 'presentation/viewPane/actions';
import {
  EmbeddableViewConfig,
  InstantiableReactViewProps,
} from 'tabview/types';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import CapabilityMapSettingsBar from './CapabilityMapSettingsBar';
import EmptyState from './EmptyState';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';

const VIEW_ID = ViewIds.CAPABILITY_MAP;

type TempStruct = {
  height: number;
  node?: HTMLElement;
};

const getMaxLineCount = (container: HTMLElement, selector: string) => {
  const { node: highestNode } = Array.from<HTMLElement>(
    container.querySelectorAll(selector)
  )
    .map<TempStruct>(node => ({
      node,
      height: node.offsetHeight,
    }))
    .reduce(
      (highest, candidate) =>
        candidate.height > highest.height ? candidate : highest,
      { height: 0 }
    );
  return highestNode ? highestNode.getClientRects().length : 1;
};

const onMouseOver = (event: MouseEvent) => {
  const componentId = getComponentIdFromTarget(event);
  dispatchAction(hoveredComponentChanged(componentId || null), VIEW_ID);
};
const onMouseLeave = () => {
  dispatchAction(hoveredComponentChanged(null), VIEW_ID);
};

type OwnProps = {
  embeddableViewConfig?: EmbeddableViewConfig;
};

type CapabilityMapProps = StreamedProps & OwnProps;

type CapabilityMapState = {
  maxLineCount: number;
  maxTitleLineCount: number;
};

const getComponentIdFromTarget = (event: MouseEvent) => {
  const target = (event.target as Element).closest<HTMLElement>(
    COMPONENT_ID_SELECTOR
  );
  return target ? target.dataset.componentId : '';
};

const hasNoComponents = (viewModel: ViewStreamShape) =>
  !viewModel.componentTypes.length;
class CapabilityMap extends Component<CapabilityMapProps, CapabilityMapState> {
  containerRef: RefObject<HTMLDivElement> = createRef();
  mouseDownPosition: Point = [0, 0];
  state = {
    maxLineCount: 1,
    maxTitleLineCount: 1,
  };
  isRequestCenter = false;
  lastViewModel: ViewStreamShape | null = null;
  toggleLegendSubscription: Subscription | null = null;

  ZoomableViewLegendContainerForwardRef =
    createRef<React.ElementRef<typeof ZoomableViewLegendContainerForwardRef>>();
  checkAndSetRowCountDebounced = debounce(
    () => this.checkAndSetRowCount(),
    100
  );
  onNavigate = (event: MouseEvent) => {
    const componentId = getComponentIdFromTarget(event);
    if (!isClick(event, this.mouseDownPosition) || !componentId) {
      return;
    }
    dispatchAction(selectComponent({ cid: componentId }));
  };
  onMouseDown = (event: MouseEvent) => {
    const { clientX, clientY } = event;
    this.mouseDownPosition = [clientX, clientY];
  };
  onClick = (event: MouseEvent) => {
    event.stopPropagation();

    const componentId = getComponentIdFromTarget(event);
    if (
      !componentId ||
      componentId === this.props.viewModel.focusedComponentId
    ) {
      dispatchAction(hoveredComponentChanged(null), VIEW_ID);
      dispatchAction(focusedComponentChanged(null), VIEW_ID);
      return;
    }
    dispatchAction(focusedComponentChanged(componentId), VIEW_ID);
  };
  checkAndSetRowCount = (isRequestCenter = false) => {
    if (!this.containerRef.current) {
      return false;
    }
    this.isRequestCenter = isRequestCenter;
    const maxLineCount = getMaxLineCount(
      this.containerRef.current,
      '.leaf-node .leaf-name'
    );
    const maxTitleLineCount = getMaxLineCount(
      this.containerRef.current,
      '.container-title .container-name'
    );

    if (
      this.state.maxLineCount !== maxLineCount ||
      this.state.maxTitleLineCount !== maxTitleLineCount
    ) {
      this.setState({ maxLineCount, maxTitleLineCount });
      this.isRequestCenter = true;
      return true;
    }
    return false;
  };
  centerOrCheckHeights = () => {
    const willSetState = this.checkAndSetRowCount(this.isRequestCenter);
    if (this.isRequestCenter && !willSetState) {
      this.isRequestCenter = false;
      dispatchAction(zoomCenter({ viewId: VIEW_ID, duration: 0 }));
    }
  };

  render() {
    const { viewModel, viewState, embeddableViewConfig } = this.props;
    // let isLegendActive holds the "active" value of the legend and is temorarely detached from
    // the state due to getFilteredViewSettings$.
    // This value & the viewstate are synchronized as soon an update passes the filter.
    let isLegendActive = viewState.isLegendActive ?? false;
    if (!viewModel?.rootLayoutBox) {
      return null;
    }

    this.isRequestCenter =
      this.isRequestCenter ||
      !isEqual(
        omit(this.lastViewModel, ['focusedComponentId', 'hoveredComponentId']),
        omit(this.props.viewModel, ['focusedComponentId', 'hoveredComponentId'])
      );
    this.lastViewModel = this.props.viewModel;

    const {
      rootLayoutBox,
      legendComponentTypes,
      focusedComponentId,
      hoveredComponentId,
    } = viewModel;
    const style = {
      width: `${
        Math.max(
          rootLayoutBox.calculatedGridWidth,
          rootLayoutBox.targetGridWidth
        ) * GRID_WIDTH
      }px`,
    };

    const showLeftSettingsSection =
      !embeddableViewConfig || embeddableViewConfig.showLeftSettingsSection;
    const showRightSettingsSection =
      !embeddableViewConfig || embeddableViewConfig.showRightSettingsSection;

    const activeDiffMode = getActiveDiffMode();
    const isScopeDiffMode = isInScopeDiffMode();

    const isEmptyView = hasNoComponents(viewModel);

    return (
      <div
        className="tab-pane active"
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
      >
        <CapabilityMapSettingsBar
          viewModel={viewModel}
          showLeftSettingsSection={showLeftSettingsSection}
          showRightSettingsSection={showRightSettingsSection}
          exports={{
            ...getExportsForHtmlView({
              container: () => this.containerRef.current,
              exportedViewMetadata: {
                name: VIEW_ID,
              },
              ...getSharedExportFunctions(),
            }),
            isDisabled: isEmptyView,
          }}
          legendOnClick={() => {
            isLegendActive = !isLegendActive;
            defaultLegendOnClick({
              isLegendActive: isLegendActive,
              viewId: VIEW_ID,
            });
          }}
        />

        <ViewContainer ref={this.containerRef} onClick={this.onClick}>
          {isEmptyView ? (
            <EmptyState />
          ) : (
            <>
              <Zoomable viewId={VIEW_ID}>
                <CapabilityMapContentContainer
                  style={style}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                  onClick={this.onClick}
                  onDoubleClick={this.onNavigate}
                  onMouseDown={this.onMouseDown}
                  className="react-view"
                >
                  <LayoutBox
                    layoutBox={rootLayoutBox}
                    hoveredComponentId={hoveredComponentId}
                    focusedComponentId={focusedComponentId}
                  />
                </CapabilityMapContentContainer>
              </Zoomable>
              <HeightOffset>
                {heightOffset => (
                  <ZoomableViewLegendContainerForwardRef
                    initiallyVisible={isLegendActive}
                    ref={this.ZoomableViewLegendContainerForwardRef}
                    heightOffset={heightOffset}
                  >
                    <GraphViewLegend
                      componentTypes={legendComponentTypes}
                      referenceTypes={[]}
                      hasCollapsedNodes={false}
                      hasReferenceParents={false}
                      hasNonComponentNodes={false}
                      isUserDefinedGrouping={false}
                      showComponentSwatches={true}
                      showReferenceConditionalFormatting={false}
                      showComponentShapes={false}
                      showReferencesAsLines={false}
                      activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
                    />
                  </ZoomableViewLegendContainerForwardRef>
                )}
              </HeightOffset>
            </>
          )}
        </ViewContainer>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(this.centerOrCheckHeights);
    window.addEventListener('resize', this.checkAndSetRowCountDebounced);
    this.toggleLegendSubscription = subscribeToAction(
      toggleLegend,
      ({ legendActive }) => {
        this.ZoomableViewLegendContainerForwardRef.current?.setIsVisible(
          legendActive
        );
      },
      VIEW_ID
    );
  }

  componentDidUpdate() {
    setTimeout(this.centerOrCheckHeights);
  }

  componentWillUnmount() {
    this.checkAndSetRowCountDebounced.cancel();
    window.removeEventListener('resize', this.checkAndSetRowCountDebounced);
    this.toggleLegendSubscription?.unsubscribe();
  }
}

const ConnectedCapabilityMap = connect(CapabilityMap, viewModel$);

const WithErrorBoundary = ({
  embeddableViewConfig,
}: InstantiableReactViewProps) => (
  <ErrorBoundary
    logError={logError}
    errorContextDescription="Failed to render capability map"
  >
    <ConnectedCapabilityMap embeddableViewConfig={embeddableViewConfig} />
  </ErrorBoundary>
);

export default WithErrorBoundary;
