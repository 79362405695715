import { createRef, Component } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { ComponentMatrixGroup, ComponentMatrixViewModel } from '../types';
import ComponentMatrixHeaderCell from './componentMatrixHeaderCell';
import {
  augmentRowWithGridColumnInfo,
  columnHeadersAreaGridTemplateRows,
} from '../util';
import { getScrollbarWidth } from '@ardoq/common-helpers';

const ComponentMatrixColumnHeadersAreaContainer = styled.div`
  grid-row: 1;
  grid-column: 2;
  display: grid;
  overflow: hidden;
  margin-right: ${getScrollbarWidth()}px;
`;
interface ComponentMatrixColumnHeadersAreaProperties {
  viewModel: ComponentMatrixViewModel;
  columnWidths: number[];
}

const isColumnEffectivelyEqual = (
  a: ComponentMatrixGroup,
  b: ComponentMatrixGroup
) =>
  a.label === b.label &&
  a.isExpanded === b.isExpanded &&
  a.isHidden === b.isHidden;

const isColumnLevelEffectivelyEqual = (
  a: ComponentMatrixGroup[],
  b: ComponentMatrixGroup[]
) =>
  a.length === b.length &&
  a.every((column, columnIndex) =>
    isColumnEffectivelyEqual(column, b[columnIndex])
  );

const hasComponentId = (
  columns: ComponentMatrixGroup[],
  componentId: string | null
): boolean =>
  componentId !== null &&
  columns.some(
    column =>
      column.label === componentId ||
      hasComponentId([...column.children.values()], componentId)
  );

class ComponentMatrixColumnHeadersArea extends Component<ComponentMatrixColumnHeadersAreaProperties> {
  private containerReference = createRef<HTMLDivElement>();
  shouldComponentUpdate(nextProps: ComponentMatrixColumnHeadersAreaProperties) {
    const columnWidthsChanged = !isEqual(
      this.props.columnWidths,
      nextProps.columnWidths
    );
    if (columnWidthsChanged) {
      return true;
    }
    const { viewModel } = this.props;
    const { viewModel: nextViewModel } = nextProps;

    const hoveredComponentChanged =
      viewModel.hoveredComponentId !== nextViewModel.hoveredComponentId;
    if (hoveredComponentChanged) {
      const wasPreviousHoveredComponentInView = hasComponentId(
        [...viewModel.columns.values()],
        viewModel.hoveredComponentId
      );
      if (wasPreviousHoveredComponentInView) {
        return true;
      }
      const isNewHoveredComponentInView = hasComponentId(
        [...nextProps.viewModel.columns.values()],
        nextViewModel.hoveredComponentId
      );
      if (isNewHoveredComponentInView) {
        return true;
      }
    }

    const focusedComponentChanged =
      viewModel.focusedComponentId !== nextViewModel.focusedComponentId;
    if (focusedComponentChanged) {
      const wasPreviousFocusedComponentInView = hasComponentId(
        [...viewModel.columns.values()],
        viewModel.focusedComponentId
      );
      if (wasPreviousFocusedComponentInView) {
        return true;
      }
      const isNewFocusedComponentInView = hasComponentId(
        [...nextProps.viewModel.columns.values()],
        nextViewModel.focusedComponentId
      );
      if (isNewFocusedComponentInView) {
        return true;
      }
    }

    return (
      viewModel.columnsByDepth.length !== nextViewModel.columnsByDepth.length ||
      !viewModel.columnsByDepth.every((columnLevel, columnLevelIndex) =>
        isColumnLevelEffectivelyEqual(
          columnLevel,
          nextViewModel.columnsByDepth[columnLevelIndex]
        )
      )
    );
  }
  setScrollLeft(value: number) {
    if (!this.containerReference.current) {
      return;
    }
    this.containerReference.current.scrollLeft = value;
  }
  render() {
    const { viewModel, columnWidths } = this.props;
    const {
      measurements: { columnHeaderWidths },
      columnsByDepth,
      hoveredComponentId,
      focusedComponentId,
    } = viewModel;
    const noHeaders =
      columnHeaderWidths.length === 1 && columnHeaderWidths[0] === 0;
    if (noHeaders) {
      return null;
    }
    const columnDepth = columnsByDepth.length;
    return (
      <ComponentMatrixColumnHeadersAreaContainer
        ref={this.containerReference}
        style={{
          gridTemplateColumns: columnWidths
            .map(width => `${width}px`)
            .join(' '),
          gridTemplateRows: columnHeadersAreaGridTemplateRows(columnsByDepth),
        }}
      >
        {viewModel.columnsByDepth.map(columns =>
          augmentRowWithGridColumnInfo(columns).map((column, index) => (
            <ComponentMatrixHeaderCell
              column={column}
              key={index}
              columnDepth={columnDepth}
              gridColumn={column.gridColumn}
              colSpan={column.colSpan}
              hoveredComponentId={hoveredComponentId}
              focusedComponentId={focusedComponentId}
            />
          ))
        )}
      </ComponentMatrixColumnHeadersAreaContainer>
    );
  }
}
export default ComponentMatrixColumnHeadersArea;
