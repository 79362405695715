import type { RelationshipsNode } from '../types';

export const SYNTHETIC_NODE_DEFAULTS: Omit<
  RelationshipsNode,
  'id' | 'parent' | 'children'
> = {
  x: 0,
  y: 0,
  r: 60,
  layouter: null,
  vx: undefined,
  vy: undefined,
  packed: false,
  open: true,
  className: '',
  label: '',
  subLabel: null,
  representationData: null,
  modelId: null,
  links: null,
  descendantCount: 0,
  isContext: false,
  isSynthetic: true,
  matchingFilterIds: null,
};
