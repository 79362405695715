import {
  INode,
  IRenderContext,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  DATA_RENDER_HASH,
  NODE_HEIGHT,
  NODE_WIDTH,
} from './consts';
import { isContextNode } from './nodeDecorator';
import { createSvgElement } from '@ardoq/dom-utils';

const shouldSkipRerender = (node: INode) => {
  const { height, width } = node.layout;
  const padding = 2 * CONTEXT_HIGHLIGHT_PADDING;

  const isNodeContextNode = isContextNode(node);
  if (isNodeContextNode && height === NODE_HEIGHT) return true;
  if (isNodeContextNode && width === NODE_WIDTH) return true;
  if (!isNodeContextNode && height === NODE_HEIGHT + padding) return true;
  if (!isNodeContextNode && width === NODE_WIDTH + padding) return true;

  return false;
};

export default abstract class ArdoqNodeStyleBase extends NodeStyleBase {
  protected constructor(private isModern: boolean) {
    super();
  }
  protected abstract getHash(node: INode): string;
  protected abstract render(container: SVGElement, node: INode): void;
  override createVisual(_: IRenderContext, node: INode) {
    const hash = this.getHash(node);
    const container = createSvgElement('g');
    container.setAttribute(DATA_RENDER_HASH, hash);
    this.render(container, node);
    SvgVisual.setTranslate(container, node.layout.x, node.layout.y);
    return new SvgVisual(container);
  }

  override updateVisual(_: IRenderContext, oldVisual: Visual, node: INode) {
    const container = (oldVisual as SvgVisual).svgElement;
    const oldHash = container.getAttribute(DATA_RENDER_HASH);
    const newHash = this.getHash(node);
    if (!this.isModern && shouldSkipRerender(node)) return oldVisual;
    if (!oldHash || oldHash !== newHash) {
      container.setAttribute(DATA_RENDER_HASH, newHash);
      this.render(container, node);
    }
    SvgVisual.setTranslate(container, node.layout.x, node.layout.y);
    return oldVisual;
  }
}
