import { DoqType } from '@ardoq/doq';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoConnectedComponentsContextDoq from 'tabview/graphViews/emptyStates/NoConnectedComponentsContextDoq';

type EmptyStateProps = {
  noConnectedComponents: boolean;
  hasActiveFilter: boolean;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
};

const EmptyState = ({
  hasActiveFilter,
  noConnectedComponents,
  isPresentationMode,
  isWorkspaceEmpty,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.DEPENDENCY_WHEEL_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.DEPENDENCY_WHEEL_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (noConnectedComponents) {
    return (
      <NoConnectedComponentsContextDoq
        doqType={DoqType.DEPENDENCY_WHEEL_ERROR}
      />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
