import { HighlightState } from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setFocusState, setHoverState } from './actions';

export const updateHoverState = (
  hoverState: HighlightState,
  viewInstanceId: string
) => {
  dispatchAction(setHoverState(hoverState), viewInstanceId);
};

export const updateFocusState = (
  focusState: HighlightState,
  viewInstanceId: string
) => {
  dispatchAction(setFocusState(focusState), viewInstanceId);
};

export const bubbleChartCommands = {
  updateHoverState,
  updateFocusState,
};
