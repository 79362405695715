import { DoqType } from '@ardoq/doq';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoConnectedComponentsWorkspaceDoq from 'tabview/graphViews/emptyStates/NoConnectedComponentsWorkspaceDoq';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoDataWithZeroDegreesDoq from 'tabview/graphViews/emptyStates/NoDataWithZeroDegreesDoq';
import { Notifier } from '@ardoq/dropdown-menu';

type EmptyStateProps = {
  noConnectedComponents: boolean;
  hasActiveFilter: boolean;
  isZeroDegrees: boolean;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
  openDegreesViewSetting: Notifier;
};

const EmptyState = ({
  hasActiveFilter,
  noConnectedComponents,
  isZeroDegrees,
  isPresentationMode,
  isWorkspaceEmpty,
  openDegreesViewSetting,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.SWIMLANE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.SWIMLANE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (isZeroDegrees) {
    return (
      <NoDataWithZeroDegreesDoq
        doqType={DoqType.SWIMLANE_ERROR}
        includeActions={!isPresentationMode}
        changeSettingAction={openDegreesViewSetting}
      />
    );
  }

  if (noConnectedComponents) {
    return (
      <NoConnectedComponentsWorkspaceDoq doqType={DoqType.SWIMLANE_ERROR} />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
