import { namespacedActionCreator } from '@ardoq/rxbeach';
import { EphemeralNotificationKeys } from './types';

export const addDismisssedEphemeralNotification =
  namespacedActionCreator<EphemeralNotificationKeys>(
    '[EPHEMERAL_NOTIFICATION] ADD_DISMISSED_NOTIFICATION'
  );
export const ephemeralNotificationRendered =
  namespacedActionCreator<EphemeralNotificationKeys>(
    '[EPHEMERAL_NOTIFICATION] NOTIFICATUION_RENDERED'
  );
