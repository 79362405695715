import { memoize } from 'lodash';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { GetLabelTextParams, GetTruncatedStringParams } from './types';
import { NO_BREAK_SPACE } from '../consts';
import { HORIZONTAL_ELLIPSIS } from '@ardoq/global-consts';

const PREFERABLE_NAME_TRUNCATION_LIMIT = 6;
const textMeasureContext = document.createElement('canvas').getContext('2d')!;
textMeasureContext.font = `1px ${ARDOQ_DEFAULT_FONT_FAMILY}`;

const getStringWidth = memoize((value: string, fontSize: number) => {
  const stringMetrics = textMeasureContext.measureText(value);

  return Math.ceil(stringMetrics.width * fontSize);
});

export const clearStringWidthCache = () => getStringWidth.cache.clear?.();

export const getLabelText = ({
  maxLabelWidth,
  iconWidth,
  componentValue,
  componentName,
  fontSize,
}: GetLabelTextParams) => {
  const spaceWidth = getStringWidth(NO_BREAK_SPACE, fontSize);
  const ellipsisWidth = getStringWidth(HORIZONTAL_ELLIPSIS, fontSize);
  const componentValueWidth = getStringWidth(
    `${NO_BREAK_SPACE}(${componentValue})`,
    fontSize
  );
  const componentNameWidth = getStringWidth(componentName, fontSize);
  const minComponentNameWidth = getStringWidth(
    `${componentName.slice(
      0,
      PREFERABLE_NAME_TRUNCATION_LIMIT
    )}${HORIZONTAL_ELLIPSIS}${NO_BREAK_SPACE}`,
    fontSize
  );
  const minValueWidth = getStringWidth(
    `(${componentValue[0]}${HORIZONTAL_ELLIPSIS})`,
    fontSize
  );

  const hasSpaceForText =
    maxLabelWidth > iconWidth + spaceWidth + ellipsisWidth;
  if (!hasSpaceForText) {
    return {
      labelText: '',
      isTruncated: true,
    };
  }

  const textAvailableSpace = maxLabelWidth - iconWidth;
  const valueAvailableSpace =
    textAvailableSpace - minComponentNameWidth - spaceWidth;

  const textFullyFits =
    textAvailableSpace > componentNameWidth + componentValueWidth;

  if (textFullyFits) {
    return {
      labelText: `${componentName} (${componentValue})`,
      isTruncated: false,
    };
  }

  const showValue = minValueWidth < valueAvailableSpace + spaceWidth;
  const defaultTruncateArguments = {
    value: componentName,
    ellipsisWidth,
    fontSize,
  };

  if (!showValue) {
    return {
      labelText: getTruncatedString({
        ...defaultTruncateArguments,
        availableSpace: textAvailableSpace,
      }),
      isTruncated: true,
    };
  }

  const maxNameWidth = textAvailableSpace - componentValueWidth - spaceWidth;
  const isValueFullFits = componentValueWidth < valueAvailableSpace;

  const nameText = getTruncatedString({
    ...defaultTruncateArguments,
    availableSpace: maxNameWidth,
    minWidth: minComponentNameWidth,
  });

  const valueText = isValueFullFits
    ? componentValue
    : getTruncatedString({
        ...defaultTruncateArguments,
        value: componentValue,
        availableSpace: valueAvailableSpace,
      });

  return {
    labelText: `${nameText}${NO_BREAK_SPACE}(${valueText})`,
    isTruncated: true,
  };
};

const getTruncatedString = ({
  value,
  availableSpace,
  fontSize,
  ellipsisWidth,
  minWidth = 0,
}: GetTruncatedStringParams) => {
  let width = ellipsisWidth;
  let result = '';

  for (const char of value) {
    const charWidth = getStringWidth(char, fontSize);

    width += charWidth;
    if (width >= availableSpace && minWidth < width) {
      break;
    }

    result += char;
  }

  const shouldAddEllipsis = Boolean(result && result !== value);

  return `${result}${shouldAddEllipsis ? HORIZONTAL_ELLIPSIS : ''}`;
};
