import Components from 'collections/components';
import References from 'collections/references';
import GroupByCollection from 'collections/groupByCollection';
import NodeCollection from 'graph/NodeCollection';
import GraphCollection from './GraphCollection';
import AggregatedGraphBuilder, {
  AggregatedGraphBuilderNode,
} from 'graph/aggregatedGraphBuilder';
import { BackboneEvents } from 'BackboneEvents';
import GraphUtil from 'graph/graphUtil';
import { Node } from './node';
import { ChildGraphInclusionPolicy } from './ChildGraphInclusionPolicy';
import * as profiling from '@ardoq/profiling';
import { action$, ofType } from '@ardoq/rxbeach';
import { notifyReferencesRemoved } from 'streams/references/ReferenceActions';
import { notifyComponentsRemoved } from 'streams/components/ComponentActions';
import {
  notifyFiltersChanged,
  setActivePerspective,
} from 'streams/filters/FilterActions';
import { GraphItemModel } from './GraphItem';
import type { Edge } from './types';
import { ComponentBackboneModel } from 'aqTypes';
import {
  AggregatedGraphByComponentArgs,
  AggregatedGraphByWorkspaceArgs,
} from './types';
import { subscribeToAction } from 'streams/utils/streamUtils';
import {
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
} from 'streams/context/ContextActions';

interface TraverseLogEntry {
  maxIncoming: number;
  maxOutgoing: number;
}
interface EnsureAtLeastOneDegreeForNodeInContextArgs {
  aggNode: Partial<Pick<AggregatedGraphBuilderNode, 'nodes'>>;
  node: Node;
  maxDegrees: number;
}
interface IncludeNodesAndEdgesParam {
  nodeSet: Set<Node<GraphItemModel>>;
  edgeSet: Set<Edge>;
  maxDegrees?: number;
  maxDegreesIncoming: number;
  maxDegreesOutgoing: number;
  getAggregated: boolean;
  traverseLog: Map<string, TraverseLogEntry>;
}
interface AddChildGraphsArgs {
  component?: ComponentBackboneModel | null;
  maxDegreesIncoming: number;
  maxDegreesOutgoing: number;
  isProcess?: boolean;
  getAggregated: boolean;
  includeChildGraphs: ChildGraphInclusionPolicy;
  includedNodes: Set<Node<GraphItemModel>>;
  includedEdges: Set<Edge>;
  traverseLog: Map<string, TraverseLogEntry>;
}
const ensureAtLeastOneDegreeForNodeInContext = ({
  aggNode,
  node,
  maxDegrees,
}: EnsureAtLeastOneDegreeForNodeInContextArgs) =>
  maxDegrees > 0
    ? maxDegrees
    : (aggNode?.nodes?.indexOf(node) ?? -1) >= 0
      ? 1
      : 0;

const includeNodeAndParents = (
  nodes: Set<Node<GraphItemModel>>,
  node: Node<GraphItemModel>
) => {
  nodes.add(node);
  if (node.parent) {
    includeNodeAndParents(nodes, node.parent);
  }
};

const emptyGraph = () => ({
  rootNodes: new NodeCollection(),
  edges: new GraphCollection<Edge>(),
  activeNodes: {},
});

class AggregatedGraph extends BackboneEvents {
  private dirty = true;
  private compMap = new Map<string, AggregatedGraphBuilderNode>();
  private rootNodeCollection = new NodeCollection();
  private edgeMap = new GraphCollection<Edge>();
  unthrottledRebuild: VoidFunction;
  constructor() {
    super();

    subscribeToAction(notifyWorkspaceOpened, this.invalidateGraph);
    subscribeToAction(notifyWorkspaceClosed, this.invalidateGraph);

    this.listenTo(
      References.collection,
      'add change:source change:type change:target',
      this.invalidateGraph
    );
    this.listenTo(
      Components.collection,
      'add change:parent change:_order',
      this.invalidateGraph
    );

    action$
      .pipe(
        ofType(
          notifyFiltersChanged,
          notifyReferencesRemoved,
          notifyComponentsRemoved,
          setActivePerspective
        )
      )
      .subscribe(this.invalidateGraph);

    this.listenTo(GroupByCollection, 'add change remove', this.invalidateGraph);
    this.unthrottledRebuild = this.rebuild;
  }
  rebuild = () => {
    // Garbage collect old compMap
    const transaction = profiling.startTransaction(
      'aggregated graph rebuild',
      500,
      profiling.Team.INSIGHT
    );
    this.compMap.clear();
    this.compMap = AggregatedGraphBuilder(
      this.rootNodeCollection,
      this.edgeMap,
      GroupByCollection
    );
    this.dirty = false;
    profiling.endTransaction(transaction);
  };

  rebuildIfDirty = () => {
    if (this.dirty) {
      this.rebuild();
    }
  };

  invalidateGraph = () => {
    this.dirty = true;
  };

  getFullGraph() {
    this.rebuildIfDirty();
    return {
      rootNodes: this.rootNodeCollection,
      edges: this.edgeMap,
      activeNodes: {},
    };
  }
  includeNodesAndEdges(
    nodes: GraphCollection<Node<GraphItemModel>>,
    {
      nodeSet,
      edgeSet,
      maxDegrees,
      maxDegreesIncoming,
      maxDegreesOutgoing,
      getAggregated,
      traverseLog,
    }: IncludeNodesAndEdgesParam
  ) {
    nodes.forEach(node => {
      GraphUtil.getGraphSubset({
        node,
        nodeSet,
        edgeSet,
        maxDegreesIncoming: maxDegreesIncoming ?? maxDegrees,
        maxDegreesOutgoing: maxDegreesOutgoing ?? maxDegrees,
        getAggregated,
        traverseLog,
      });
      this.includeNodesAndEdges(node.children, {
        nodeSet,
        edgeSet,
        maxDegrees,
        maxDegreesIncoming,
        maxDegreesOutgoing,
        getAggregated,
        traverseLog,
      });
    });
  }
  addChildGraphs({
    component,
    maxDegreesIncoming,
    maxDegreesOutgoing,
    isProcess,
    getAggregated,
    includeChildGraphs,
    includedNodes,
    includedEdges,
    traverseLog,
  }: AddChildGraphsArgs) {
    if (component) {
      component
        .getChildren()
        .filter(childComponent => childComponent.isIncludedInContextByFilter())
        .forEach(childComponent => {
          const { edges, rootNodes } = this.getGraphByComponent({
            component: childComponent,
            maxDegreesIncoming,
            maxDegreesOutgoing,
            getAggregated,
            isProcess,
            includedNodes,
            includedEdges,
            includeChildGraphs,
            traverseLog,
          });

          rootNodes.forEach(node => includedNodes.add(node));
          edges.forEach(edge => includedEdges.add(edge));
          if (includeChildGraphs === ChildGraphInclusionPolicy.RECURSIVE) {
            this.addChildGraphs({
              component: childComponent,
              maxDegreesIncoming,
              maxDegreesOutgoing,
              isProcess,
              getAggregated,
              includeChildGraphs,
              includedNodes,
              includedEdges,
              traverseLog,
            });
          }
        });
    }
  }
  getGraphByWorkspace({
    workspaceId,
    maxDegrees,
    maxDegreesIncoming,
    maxDegreesOutgoing,
    isProcess = false,
    getAggregated = false,
    excludeRootNodesWithoutChildren = false,
    includeChildGraphs,
  }: AggregatedGraphByWorkspaceArgs) {
    const includedNodes = new Set<Node<GraphItemModel>>();
    const includedEdges = new Set<Edge>();

    if (!workspaceId) {
      return emptyGraph();
    }
    this.rebuildIfDirty();

    const rootComponentsOfWorkspace = Components.collection.filter(
      component => {
        return (
          !component.getParent() &&
          component.get('rootWorkspace') === workspaceId &&
          component.isIncludedInContextByFilter()
        );
      }
    );
    const traverseLog = new Map();
    rootComponentsOfWorkspace.forEach(component => {
      const { rootNodes, edges } = this.getGraphByComponent({
        component,
        maxDegrees,
        maxDegreesIncoming,
        maxDegreesOutgoing,
        isProcess,
        getAggregated,
        includeChildGraphs,
        traverseLog,
      });

      rootNodes
        .filter(node => {
          if (excludeRootNodesWithoutChildren) return node.hasChildren();
          return true;
        })
        .forEach(node => includedNodes.add(node));
      edges.forEach(edge => includedEdges.add(edge));
    });

    GraphUtil.ensureAllEdgesAreIncluded({
      nodeSet: includedNodes,
      edgeSet: includedEdges,
      allEdges: this.edgeMap,
      getAggregated,
    });

    return {
      rootNodes: new NodeCollection(includedNodes),
      edges: new GraphCollection<Edge>(includedEdges),
      activeNodes: {},
    };
  }
  getGraphByComponent({
    component,
    isProcess = false,
    maxDegrees = 3,
    maxDegreesIncoming = maxDegrees,
    maxDegreesOutgoing = maxDegrees,
    getAggregated = false,
    includeChildGraphs = ChildGraphInclusionPolicy.NONE,
    includedNodes = new Set(),
    includedEdges = new Set(),
    traverseLog = new Map(),
  }: AggregatedGraphByComponentArgs) {
    this.rebuildIfDirty();
    const aggNode = this.compMap.get(component && component.id);
    const rootNodes = aggNode && aggNode.rootNodes;
    const activeNodes: Record<string, boolean> = {};
    const isContextRootNode = aggNode && aggNode.aggregators.length === 0;

    if (
      [
        ChildGraphInclusionPolicy.RECURSIVE,
        ChildGraphInclusionPolicy.ONE_LEVEL,
      ].includes(includeChildGraphs)
    ) {
      this.addChildGraphs({
        component,
        maxDegreesIncoming,
        maxDegreesOutgoing,
        getAggregated,
        includeChildGraphs,
        includedNodes,
        includedEdges,
        traverseLog,
      });
    }

    if (!component || !rootNodes) {
      // Make sure the component is included
      if (
        component &&
        !Array.from(includedNodes).find(node => node.dataModel === component)
      ) {
        includedNodes.add(
          new Node({
            dataModel: component,
          })
        );
      }
      return {
        rootNodes: new NodeCollection(includedNodes),
        edges: new GraphCollection<Edge>(includedEdges),
        activeNodes: {},
      };
    }

    // Add edges from actual context nodes
    if (!getAggregated) {
      aggNode.nodes.forEach(node => {
        if (isProcess) {
          GraphUtil.getOutgoingGraphSubset({
            node,
            nodeSet: includedNodes,
            edgeSet: includedEdges,
            maxDegrees: maxDegreesOutgoing,
            getAggregated,
            traverseLog,
          });
          // Get incoming nodes on the outgoing subset
          const incomingNodes = new Set<Node<GraphItemModel>>();
          includedNodes.forEach(node => {
            GraphUtil.getIncomingGraphSubset({
              node,
              nodeSet: incomingNodes,
              edgeSet: includedEdges,
              maxDegrees: ensureAtLeastOneDegreeForNodeInContext({
                aggNode,
                node,
                maxDegrees: maxDegreesIncoming,
              }),
              traverseLog,
            });
          });
          incomingNodes.forEach(node => includedNodes.add(node));
        } else {
          GraphUtil.getGraphSubset({
            node,
            nodeSet: includedNodes,
            edgeSet: includedEdges,
            maxDegreesIncoming: maxDegreesIncoming ?? maxDegrees,
            maxDegreesOutgoing: maxDegreesOutgoing ?? maxDegrees,
            getAggregated,
            traverseLog,
          });
        }
      });
    }

    // Add edges from aggregated root nodes
    rootNodes.forEach(rootNode => {
      activeNodes[rootNode.id] = true;
      if (getAggregated) {
        GraphUtil.getGraphSubset({
          node: rootNode,
          nodeSet: includedNodes,
          edgeSet: includedEdges,
          maxDegreesIncoming,
          maxDegreesOutgoing,
          getAggregated,
          traverseLog,
        });
      } else if (isContextRootNode) {
        // Include edges from children of rootNode (recursively)
        this.includeNodesAndEdges(rootNode.children, {
          nodeSet: includedNodes,
          edgeSet: includedEdges,
          maxDegreesIncoming,
          maxDegreesOutgoing,
          getAggregated,
          traverseLog,
        });
      }
    });

    // Add parents of 'lonely' children
    includedNodes.forEach(function (node) {
      if (node.parent && !includedNodes.has(node.parent)) {
        includeNodeAndParents(includedNodes, node.parent);
      }
    });

    GraphUtil.ensureAllEdgesAreIncluded({
      nodeSet: includedNodes,
      edgeSet: includedEdges,
      allEdges: this.edgeMap,
      getAggregated,
    });

    if (getAggregated) {
      const edgesToDelete: Edge[] = [];

      includedEdges.forEach(function (edge) {
        if (edge.aggregatedTarget === edge.aggregatedSource) {
          // Remove edges within aggregators
          edgesToDelete.push(edge);
        }
      });
      edgesToDelete.forEach(function (edge) {
        includedEdges.delete(edge);
      });
    }

    return {
      rootNodes: new NodeCollection(includedNodes),
      edges: new GraphCollection<Edge>(includedEdges),
      activeNodes: activeNodes,
    };
  }
}

export const aggregatedGraphInstance = new AggregatedGraph();
