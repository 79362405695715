import type { FieldBackboneModel } from 'aqTypes';
import { DiffType } from '@ardoq/data-model';
import type { OldGraphItemDataModel } from './legacyTypes';

export default class FieldValue implements OldGraphItemDataModel {
  cid: string;
  field: FieldBackboneModel;
  value: any;
  constructor(args: { cid: string; field: FieldBackboneModel; value: any }) {
    const { cid, field, value } = args;
    this.cid = cid;
    this.field = field;
    this.value = value;
  }

  isIncludedInContextByFilter(): boolean {
    return true;
  }

  name(): string {
    return `${this.field.getRawLabel()} = ${this.value}`;
  }

  getValue(): any {
    return this.value;
  }

  getCSS(): string {
    return '';
  }

  isReference(): boolean {
    return false;
  }

  get() {
    return undefined;
  }

  getVisualDiffType() {
    return DiffType.NONE;
  }
}
