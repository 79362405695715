export const META_MODEL_NAME = 'MetaModelTriples';
// Type id 2 is reserved for 'Implicit' reference types, most models also have
// type id 4 for 'Depends on'. To be on the safe side let's start with 5.
export const START_OFFSET_REF_TYPE_ID = 5;
// Representing the meta model as workspace data is a frontend only concept.
// It has the advantage that we can use exiting visualizations to show it.
// On the backend it's pure date, not related to a workspace or an according
// model. So it is safe to hardcode these ids on the frontend.
export const META_MODEL_MODEL_ID = '83ebd5f8-031e-47f7-9273-27d745eca36b';
export const META_MODEL_WORKSPACE_ID = '3240c5f0-186c-4077-8570-82e0aa2731fc';
export const META_MODEL_PERMISSION_ID = 'f24dcc05-44ab-4d29-b86e-eca5836829ab';
export const NULL_TYPE_NAME = 'Missing Type Name';
