import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import viewInterface from 'streams/views/mainContent/viewInterface';
import { ViewInterface } from 'streams/views/mainContent/types';
import { ViewIds } from '@ardoq/api-types';
import { MaximumPathLengths } from 'aqTypes';

export default class PaneViewInterface implements ViewInterface {
  name?: string | undefined;
  _viewId: ViewIds;
  _viewstate: unknown = {};
  _subscription = { unsubscribe: () => null };

  constructor(viewId: ViewIds) {
    this._viewId = viewId;
    this._viewstate = {};
    this._subscription = (getViewSettingsStream(viewId) as any).subscribe(
      (viewstate: unknown) => (this._viewstate = viewstate)
    );
  }

  get id() {
    return this._viewId;
  }

  isVisible() {
    return viewInterface.getActiveViewIds().includes(this._viewId);
  }

  getViewstate() {
    return this._viewstate;
  }

  remove() {
    this._subscription.unsubscribe();

    this._viewstate = {};
  }

  debouncedRender() {}

  deselected() {}

  getPathLengths(): MaximumPathLengths {
    return {
      maxIncomingPathLength: 0,
      maxOutgoingPathLength: 0,
    };
  }
}
