import {
  GraphComponent,
  ILabel,
  IRenderContext,
  LabelStyleBase,
  OrientedRectangle,
  Rect,
  Size,
  SvgVisual,
  TextRenderSupport,
  Visual,
} from '@ardoq/yfiles';
import { edgeLabelFont, nodeLabelFont, showLabels } from './explorerViewUtil';
import { createSvgElement } from '@ardoq/dom-utils';

const isComponent = (label: ILabel) => {
  return (
    label.owner && label.owner.tag.isComponent && label.owner.tag.isComponent()
  );
};

const getFont = (label: ILabel) => {
  return isComponent(label) ? nodeLabelFont : edgeLabelFont;
};

export class ExplorerLabelStyle extends LabelStyleBase {
  graphComponent: GraphComponent;
  constructor(graphComponent: GraphComponent) {
    super();
    this.graphComponent = graphComponent;
  }
  createVisual(context: IRenderContext, label: ILabel) {
    if (!showLabels(this.graphComponent.zoom)) {
      return null;
    }
    return new SvgVisual(this.render(null, label));
  }
  render(container: SVGElement | null, label: ILabel) {
    const g = container ?? createSvgElement('g');
    const rect = container
      ? (container.childNodes.item(0) as SVGRectElement)
      : createSvgElement('rect');

    const size = this.getPreferredSize(label);
    rect.setAttribute('width', size.width.toString());
    rect.setAttribute('height', size.height.toString());
    rect.setAttribute('stroke', 'transparent');
    rect.setAttribute('fill', 'white');
    rect.setAttribute('fill-opacity', '0.75');

    const text = container
      ? (container.childNodes.item(1) as SVGTextElement)
      : createSvgElement('text');

    text.textContent = label.text;
    const labelFont = getFont(label);

    text.setAttribute('font-family', labelFont.fontFamily);
    text.setAttribute('font-size', `${labelFont.fontSize}px`);
    text.setAttribute('font-weight', labelFont.fontWeight.toString());
    text.setAttribute('class', label.owner && label.owner.tag.getCSS());
    text.setAttribute('x', (size.width / 2).toString());
    text.setAttribute('y', (size.height / 2).toString());
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');

    if (!container) {
      g.appendChild(rect);
      g.appendChild(text);
    }

    const transformRect = new OrientedRectangle(
      new Rect(label.layout.anchorX, label.layout.anchorY - size.height, 0, 0)
    );
    const transform = LabelStyleBase.createLayoutTransform(transformRect, true);
    transform.applyTo(g);
    g.style.pointerEvents = 'none';
    return g;
  }
  updateVisual(context: IRenderContext, oldVisual: Visual, label: ILabel) {
    this.render((oldVisual as SvgVisual).svgElement, label);
    return oldVisual;
  }
  override getPreferredSize(label: ILabel) {
    if (!label.text) return new Size(0, 0);
    const rectMargin = 3;
    const textSize = TextRenderSupport.measureText(label.text, getFont(label));
    return new Size(textSize.width + rectMargin, textSize.height + rectMargin);
  }

  getBounds(context: IRenderContext, label: ILabel) {
    if (!label.owner) {
      // avoid an exception in yFiles internal code invoking the label.layout getter.
      // this is probably getting called on a disconnected graph when switching views.
      return Rect.EMPTY;
    }
    const size = this.getPreferredSize(label);
    const layout = label.layout;
    return new Rect(layout.anchorX, layout.anchorY, size.width, size.height);
  }
}
