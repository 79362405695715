import { ArdoqId } from '@ardoq/api-types';
import { SpiderChartViewModel, SpiderChartViewSettings } from './types';
import { getComponentNumericFieldsOfWorkspace } from 'utils/getComponentNumericFieldsOfWorkspace';
import { getChartPoints, getChildComponentIds, getLegendRows } from './utils';
import { componentInterface } from '@ardoq/component-interface';
import { ItemsType, nodeLimitError } from '@ardoq/error-info-box';
import { format } from 'utils/numberUtils';
import { dispatchAction } from '@ardoq/rxbeach';
import { bypassLimitAction } from './actions';

const COMPONENTS_LIMIT = 4000;

type BuildViewModelArgs = {
  componentId: ArdoqId;
  workspaceId: ArdoqId;
  focusedComponentId: ArdoqId | null;
  viewSettings: SpiderChartViewSettings;
  bypassLimit: boolean;
};

export const buildViewModel = ({
  componentId,
  workspaceId,
  focusedComponentId,
  viewSettings,
  bypassLimit,
}: BuildViewModelArgs): SpiderChartViewModel => {
  const numericFields = getComponentNumericFieldsOfWorkspace(workspaceId);

  const selectedFields = numericFields.filter(
    field => viewSettings.selectedFieldNames[field.name]
  );

  const sideways = selectedFields.length > 2 ? viewSettings.sideways : false;
  const allComponentIds = getChildComponentIds({
    workspaceId,
    selectedComponentId: componentId,
    includeAllDescendants: viewSettings.includeAllDescendants,
  });

  if (
    componentId &&
    componentInterface.isIncludedInContextByFilter(componentId)
  ) {
    // To keep the order similar to Navigator.
    allComponentIds.unshift(componentId);
  }

  const errors =
    allComponentIds.length > COMPONENTS_LIMIT && !bypassLimit
      ? [
          nodeLimitError({
            itemCount: allComponentIds.length,
            limit: COMPONENTS_LIMIT,
            itemsType: ItemsType.COMPONENTS,
            formatNumber: format,
            onProceedAnyway: () => dispatchAction(bypassLimitAction()),
          }),
        ]
      : [];

  const relevantComponentIds = errors.length
    ? allComponentIds.slice(0, COMPONENTS_LIMIT)
    : allComponentIds;

  const legendRows = getLegendRows({
    sideways,
    relevantComponentIds,
    workspaceId,
    selectedFields,
  });

  const childAggregationFunction =
    // @ts-expect-error TS2368 -- aggregateChildValues used to be a boolean. we convert it here to handle boolean legacy values.
    viewSettings.aggregateChildValues === true
      ? 'sum'
      : !viewSettings.aggregateChildValues
        ? 'zero'
        : viewSettings.aggregateChildValues;
  const chartPoints = getChartPoints({
    relevantComponentIds,
    numericFields,
    isFlipped: sideways,
    childAggregationFunction,
    selectedFieldNames: viewSettings.selectedFieldNames,
  });

  return {
    sideways,
    numericFields,
    selectedFields,
    componentId,
    workspaceId,
    chartPoints,
    legendRows,
    componentsCount: relevantComponentIds.length,
    focusedComponentId,
    errors,
  };
};
