import { ArdoqId } from '@ardoq/api-types';
import { GraphModelShape } from '@ardoq/data-model';
import {
  getOnlyConnectedComponents,
  GraphInterface,
  GraphTraversalResult,
  traverseGraph,
  TraverseOptions,
} from '@ardoq/graph';
import { uniq } from 'lodash';

type SequentialTraversalReducerAggregate = {
  componentIds: ArdoqId[];
  traversedReferenceIds: ArdoqId[];
  parentReferenceIds: Set<string>;
  previousTraversedComponentIds: Set<ArdoqId>;
};
const sequentialTraversalReducerCurry =
  (graphInterface: GraphInterface, graph: GraphModelShape) =>
  (
    {
      componentIds,
      traversedReferenceIds,
      parentReferenceIds,
      previousTraversedComponentIds,
    }: SequentialTraversalReducerAggregate,
    sequentialTraverseOptions: TraverseOptions
  ) => {
    const {
      componentIds: newComponentIds,
      traversedReferenceIds: newTraversedReferenceIds,
      parentReferenceIds: newParentReferenceIds,
    } = traverseGraph(
      graphInterface,
      componentIds.filter(
        componentId => !previousTraversedComponentIds.has(componentId)
      ),
      graph,
      sequentialTraverseOptions
    );

    return {
      componentIds: uniq([...componentIds, ...newComponentIds]),
      traversedReferenceIds: [
        ...traversedReferenceIds,
        ...newTraversedReferenceIds,
      ],
      parentReferenceIds: new Set([
        ...parentReferenceIds,
        ...newParentReferenceIds,
      ]),
      previousTraversedComponentIds: new Set([
        ...previousTraversedComponentIds,
        ...componentIds,
      ]),
    };
  };

type GetComponentAndreferenceIdsForWorkspaceModeArgs = {
  graphInterface: GraphInterface;
  startSet: ArdoqId[];
  graph: GraphModelShape;
  traverseOptions: TraverseOptions;
  sequentialTraverseOptions: TraverseOptions[];
  includeOnlyConnectedComponents: boolean;
  hasAddAllReferencesAfterTraversalFeature: boolean;
};

export const getTraversalResultForWorkspaceMode = ({
  graphInterface,
  startSet,
  graph,
  includeOnlyConnectedComponents,
  traverseOptions,
  sequentialTraverseOptions,
  hasAddAllReferencesAfterTraversalFeature,
}: GetComponentAndreferenceIdsForWorkspaceModeArgs): GraphTraversalResult => {
  const traverseResult = traverseGraph(
    graphInterface,
    startSet,
    graph,
    traverseOptions
  );

  const {
    componentIds: allComponentIds,
    traversedReferenceIds,
    parentReferenceIds,
  } = sequentialTraverseOptions.reduce<SequentialTraversalReducerAggregate>(
    sequentialTraversalReducerCurry(graphInterface, graph),
    { ...traverseResult, previousTraversedComponentIds: new Set() }
  );

  if (hasAddAllReferencesAfterTraversalFeature) {
    const referenceIdsSet = new Set(traversedReferenceIds);
    const componentIdsSet = new Set(allComponentIds);
    graph.referenceMap.forEach(({ sourceId, targetId }, referenceId) => {
      if (
        componentIdsSet.has(sourceId) &&
        componentIdsSet.has(targetId) &&
        !referenceIdsSet.has(referenceId) &&
        graphInterface.isReferenceIncludedInContextByFilter(referenceId)
      ) {
        traversedReferenceIds.push(referenceId);
      }
    });
  }

  const componentIds = includeOnlyConnectedComponents
    ? getOnlyConnectedComponents(
        allComponentIds,
        traversedReferenceIds,
        parentReferenceIds,
        graph.referenceMap
      )
    : allComponentIds;

  return { componentIds, traversedReferenceIds, parentReferenceIds };
};
