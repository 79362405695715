import { componentInterface } from '@ardoq/component-interface';

/**
 * Checks if the Ids set contains components from any workspaces other than the one provided.
 * Navigator Ids payload may contain not only component ids but also Workspace id
 * so this function will filter out only component ids and check if they are from another workspace.
 */
export const hasComponentsFromOtherWorkspace = (
  workspaceId: string,
  ids: Set<string>
) =>
  [...ids].some(
    id =>
      componentInterface.isComponent(id) &&
      componentInterface.getWorkspaceId(id) !== workspaceId
  );
