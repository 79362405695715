import { EntityIcon } from '@ardoq/decorators';
import { PlainTextPopover } from '@ardoq/popovers';
import { TreemapData } from '../types';
import { ICON_MARGIN } from '../constants';
import { getIconWidth } from '../getIconWidth';
import { PopoverContentWrapper } from './atoms';

type PopoverContentProps = {
  component: TreemapData;
};

export const PopoverContent = ({ component }: PopoverContentProps) => {
  const label = `${component.label} (${component.value})`;
  const { color, componentDecoratorImageProps } = component.displayOptions;
  const iconWidth = getIconWidth(componentDecoratorImageProps);

  return (
    <PopoverContentWrapper>
      {iconWidth ? (
        <EntityIcon
          {...componentDecoratorImageProps}
          color={color}
          style={{ marginRight: ICON_MARGIN }}
        />
      ) : null}
      <PlainTextPopover>{label}</PlainTextPopover>
    </PopoverContentWrapper>
  );
};
