import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

type SetContextToEntityPayload = {
  id: ArdoqId;
  workspaceId: ArdoqId;
};
export const setContextToEntity = actionCreator<SetContextToEntityPayload>(
  '[searchUtil] SET_CONTEXT_TO_ENTITY'
);
