import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoNumericFieldsDoq from 'tabview/graphViews/emptyStates/NoNumericFieldsDoq';
import { DoqType } from '@ardoq/doq';
import { NoSelectedFields, NotEnoughComponentsDoq } from './NoDataDoqys';

type EmptyStateProps = {
  isWorkspaceEmpty: boolean;
  hasActiveFilter: boolean;
  allDescendantsIncluded: boolean;
  noNumberFields: boolean;
  notEnoughComponents: boolean;
  noSelectedFields: boolean;
  isPresentationMode: boolean;
};

const EmptyState = ({
  isWorkspaceEmpty,
  hasActiveFilter,
  allDescendantsIncluded,
  noNumberFields,
  notEnoughComponents,
  noSelectedFields,
  isPresentationMode,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.SPIDER_CHART}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (noNumberFields) {
    return <NoNumericFieldsDoq doqType={DoqType.SPIDER_CHART} />;
  }

  if (noSelectedFields) {
    return <NoSelectedFields includeActions={!isPresentationMode} />;
  }

  if (hasActiveFilter || !allDescendantsIncluded) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.SPIDER_CHART}
        canApplyIncludeAllDescendantsFilter
        includeActions={false}
      />
    );
  }

  if (notEnoughComponents) {
    return <NotEnoughComponentsDoq />;
  }

  return null;
};

export default connect(EmptyState, emptyView$);
