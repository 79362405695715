import { CarouselFeaturesProps } from '@ardoq/onboarding-ui';
import { InteractiveNotificationConfig } from '@ardoq/status-ui';
import { CurrentUserState } from 'streams/currentUser/currentUser$';

/**
 * All keys that are not in the RELEVANT_NOTIFICATION_KEYS will be removed from the user
 * settings when we add a new dismissed banner.
 */
export enum EphemeralNotificationKeys {
  SWITCH_TO_THE_NEW_BLOCK_DIAGARAM = 'switch-to-the-new-block-diagram',
  TIMELINE_MULTI_LABEL_PROMOTION = 'timeline-multi-label-promotion',
  BLOCKS_VIEW_INTRODUCTION_MODAL = 'blocks-view-introduction-modal',
}
export type EphemeralConfig = {
  /**
   * The id will be a row value in the column in the tracking table. Choose a unique, short and descriptiove one.
   */
  id: EphemeralNotificationKeys;
  onActionButtonClick?: VoidFunction;
  /**
   * The banner will be visible only between or on these dates
   */
  relevantFromDate: Date;
  relevantUntilDate: Date;
  /**
   * The banner state provides currentUser$ state as arg for isEphemeralNotificationVisible,
   * you can use it to determine if the banner is relevant for the current user.
   */
  isRelevantForCurrentUser: (currentUser: CurrentUserState) => boolean;
};

export type EphemeralNotificationConfig = InteractiveNotificationConfig &
  EphemeralConfig;

export type EphemeralFeaturesCarouselConfig = EphemeralConfig &
  Omit<CarouselFeaturesProps, 'onClose'>;

export type EphemeralNotificationStreamedProps = {
  notificationConfig:
    | EphemeralNotificationConfig
    | EphemeralFeaturesCarouselConfig
    | null;
  isVisible: boolean;
  instanceId: string;
};
