import { trackEvent } from 'tracking/tracking';
import activeView$ from 'streams/views/mainContent/activeView$';
import { getViewIdOfClickedElement } from 'tabview/graphViews/util';

const trackURLlinkClicked = (anchorElement: Element | null) => {
  trackEvent('Clicked URL link', {
    activeView: anchorElement
      ? getViewIdOfClickedElement(anchorElement)
      : activeView$.state.mainViewId,
  });
};

export default trackURLlinkClicked;
