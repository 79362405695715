import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';

const VIEW_ID = ViewIds.COMPONENT_MATRIX;

const setHoveredItemState = (itemId: ArdoqId | null) => {
  dispatchAction(hoveredComponentChanged(itemId), VIEW_ID);
};

const setFocusedItemState = (itemId: ArdoqId | null) => {
  dispatchAction(focusedComponentChanged(itemId), VIEW_ID);
};

export const componentMatrixCommands = {
  setHoveredItemState,
  setFocusedItemState,
};
