import Component from 'models/component';
import type { ComponentBackboneModel } from 'aqTypes';
import { APIComponentAttributes } from '@ardoq/api-types';
import { metaModelOverwrites } from './metaModelOverwrites';

type MetaModelComponentType = ComponentBackboneModel & {
  new (attrs: Partial<APIComponentAttributes>): ComponentBackboneModel;
};

export const MetaModelComponent: MetaModelComponentType =
  Component.model.extend(metaModelOverwrites);
