import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import type { Notifier } from '@ardoq/dropdown-menu';

type NoDateRangeTimeFieldsSelectedDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
  selectFieldAction?: Notifier;
};

const NoDateRangeTimeFieldsSelectedDoq = ({
  doqType,
  includeActions = true,
  selectFieldAction,
}: NoDateRangeTimeFieldsSelectedDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            All date range or time fields are currently hidden.
          </FlexBox>
          <FlexBox gap="medium">
            To show more data, select at least one date time or time range
            field.
          </FlexBox>
        </Stack>
      }
      buttonLabel={includeActions ? 'Select field' : undefined}
      onButtonClick={
        includeActions && selectFieldAction
          ? () => selectFieldAction.notify()
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoDateRangeTimeFieldsSelectedDoq;
