import { useState } from 'react';
import { compact, intersection, toString } from 'lodash';
import {
  AssetsGroups,
  DeleteAssetProps,
  DeleteBookmarkProps,
  DeleteBroadcastProps,
  DeleteDashboardProps,
  DeleteDynamicFilterProps,
  DeleteFieldProps,
  DeleteFolderProps,
  DeleteMetamodelProps,
  DeleteMultipleAssetsProps,
  DeletePresentationProps,
  DeleteReferenceTypeProps,
  DeleteReportProps,
  DeleteScenarioProps,
  DeleteSurveyProps,
  DeleteTraversalProps,
  DeleteType,
  DeleteViewpointProps,
  DeleteWorkspaceProps,
  ScenarioBasicDetails,
} from './types';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { useEffectOnce, useOnMount } from '@ardoq/hooks';
import { DialogBaseProps, ModalListOption } from '@ardoq/modal';
import { IconName } from '@ardoq/icons';
import { slideInterface } from '../../modelInterface/presentations/slideInterface';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { ArdoqId, AssetType } from '@ardoq/api-types';
import { flow } from 'lodash/fp';
import {
  isArdoqError,
  pluralize,
  unpluralizeVerb,
} from '@ardoq/common-helpers';
import { reportApi, workspaceApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import AffectedEntitiesTable from './AffectedEntityTable';
import presentations$ from 'streams/presentations/presentations$';

type ModalText = Partial<
  Pick<
    DialogBaseProps<any>,
    | 'title'
    | 'subtitle'
    | 'listOptions'
    | 'warningBlockMessage'
    | 'confirmButtonTitle'
    | 'text'
  >
>;

const getItemsListed = (assetProps: AssetsGroups) => {
  return [
    ...(assetProps.folders || []).map(folder => ({
      iconName: IconName.FOLDER,
      strongText: folder.name,
      text: 'folder',
    })),

    ...(assetProps.workspaces || []).map(workspace => ({
      iconName: IconName.WORKSPACE,
      strongText: workspace.name,
      text: 'workspace',
    })),

    ...(assetProps.presentations || []).map(presentation => ({
      iconName: IconName.PRESENTATION,
      strongText: presentation.name,
      text: 'presentation',
    })),

    ...(assetProps.surveys || []).map(survey => ({
      iconName: IconName.SURVEYS,
      strongText: survey.name,
      text: 'survey',
    })),

    ...(assetProps.metamodels || []).map(metamodel => ({
      iconName: IconName.METAMODEL,
      strongText: metamodel.name,
      text: 'metamodel',
    })),

    ...(assetProps.scenarios || []).map(scenario => ({
      iconName: IconName.SCENARIO,
      strongText: scenario.name,
      text: 'scenario',
    })),

    ...(assetProps.reports || []).map(report => ({
      iconName: IconName.DESCRIPTION,
      strongText: report.name,
      text: 'report',
    })),

    ...(assetProps.dashboards || []).map(dashboard => ({
      iconName: IconName.PIE_CHART_FILLED,
      strongText: dashboard.name,
      text: 'dashboard',
    })),

    ...(assetProps.viewpoints || []).map(viewpoint => ({
      iconName: IconName.VIEWPOINT,
      strongText: viewpoint.name,
      text: 'Discover viewpoint',
    })),

    ...(assetProps.traversals || []).map(traversal => ({
      iconName: IconName.ROCKET,
      strongText: traversal.name,
      text: 'viewpoint',
    })),

    ...(assetProps.broadcasts || []).map(broadcast => ({
      iconName: IconName.BROADCAST,
      strongText: broadcast.name,
      text: 'broadcast',
    })),

    ...(assetProps.bookmarks || []).map(bookmark => ({
      iconName: IconName.BOOKMARK,
      strongText: bookmark.name,
      text: 'bookmark',
    })),
  ] as ModalListOption[];
};

const getWarningBlockMessage = (
  numberOfAffectedAssets: number,
  affectedAssetname: string,
  assetName: string
) => {
  const baseWarning = `Once deleted, the ${assetName} can't be recovered.`;
  const usagePhrase =
    assetName === AssetType.WORKSPACE
      ? `${numberOfAffectedAssets > 1 ? 'are' : 'is'} based on`
      : unpluralizeVerb('use', numberOfAffectedAssets);
  const affectedAssetsWarning = `${numberOfAffectedAssets} ${pluralize(
    affectedAssetname,
    numberOfAffectedAssets
  )} ${usagePhrase} this
      ${assetName}. If the ${assetName} is deleted, the ${pluralize(
        affectedAssetname,
        numberOfAffectedAssets
      )} may no longer
      work.`;
  return (
    <>
      {numberOfAffectedAssets > 0 && <p>{affectedAssetsWarning}</p>}
      {baseWarning}
    </>
  );
};

const getFolderModalContentProps = (assetProps: DeleteFolderProps) => {
  const { name, assets } = assetProps;
  return {
    title: 'Delete folder',
    subtitle: `Are you sure you want to delete "${name}" folder and all its content?`,
    listOptions: getItemsListed(assets),
  };
};

const getWorkspaceModalContentProps = (assetProps: DeleteWorkspaceProps) => {
  return {
    title: `Delete workspace`,
    subtitle: `Are you sure you want to delete "${assetProps.name}" workspace?`,
    confirmButtonTitle: 'Delete workspace',
    warningBlockMessage: getWarningBlockMessage(
      assetProps.affectedSurveys.length,
      AssetType.SURVEY,
      AssetType.WORKSPACE
    ),
    text: assetProps.affectedSurveys.length ? (
      <AffectedEntitiesTable
        affectedEntities={assetProps.affectedSurveys}
        affectedEntityType={AssetType.SURVEY}
      />
    ) : undefined,
  };
};

const getTraversalModalContentProps = (assetProps: DeleteTraversalProps) => {
  return {
    title: 'Delete viewpoint',
    subtitle: `Are you sure you want to delete the "${assetProps.name}" viewpoint?`,
    warningBlockMessage: `This viewpoint can't be recovered once it is deleted. This will not affect the component and reference types used in the viewpoint. Do you want to continue with this?`,
  };
};

const getBookmarkModalContentProps = (assetProps: DeleteBookmarkProps) => {
  return {
    title: `Delete bookmark?`,
    subtitle: `This bookmark "${assetProps.name}" will be deleted permanently and cannot be recovered. Do you want to continue with this?`,
  };
};

const getScenarioModalContentProps = (assetProps: DeleteScenarioProps) => {
  const presentationsState = presentations$.state;
  const presentationsContainingScenario = compact(
    slideInterface
      .getSlidesThatContainScenario(assetProps.id)
      .map(slideId =>
        presentationOperations.getPresentationThatContainsSlide(
          presentationsState,
          slideId
        )
      )
  );

  return presentationsContainingScenario.length
    ? {
        title: 'Delete scenario',
        subtitle:
          'The scenario will be deleted from the following presentation(s):',
        listOptions: presentationsContainingScenario.map(presentation => ({
          text: presentation.name,
          iconName: IconName.PRESENTATION,
        })),
        warningBlockMessage: 'This cannot be undone',
      }
    : {
        title: 'Delete scenario',
        subtitle: `Are you sure you want to delete "${assetProps.name}" scenario?`,
      };
};

const getPresentationModalContentProps = (
  assetProps: DeletePresentationProps
) => {
  return {
    title: 'Delete presentation',
    subtitle: `Are you sure you want to delete "${assetProps.name}" presentation?`,
  };
};

const getSurveyModalContentProps = (assetProps: DeleteSurveyProps) => {
  return {
    title: 'Delete survey',
    subtitle: `Are you sure you want to delete "${assetProps.name}" survey?`,
  };
};

const getViewpointModalContentProps = (assetProps: DeleteViewpointProps) => {
  const warningMessage =
    assetProps.affectedBroadcasts > 0 ? (
      <>
        {assetProps.affectedBroadcasts} running{' '}
        {pluralize('broadcast', assetProps.affectedBroadcasts)}{' '}
        {unpluralizeVerb('use', assetProps.affectedBroadcasts)} this viewpoint
        to share surveys. If the viewpoint is deleted, users will still be able
        to access the surveys from the Survey overview page.
        <br />
        <br />
      </>
    ) : undefined;
  return {
    title: 'Delete viewpoint',
    subtitle: `Are you sure you want to delete the "${assetProps.name}" viewpoint?`,
    warningBlockMessage: (
      <>{warningMessage}The viewpoint can&apos;t be recovered.</>
    ),
  };
};

const getBroadcastModalContentProps = (assetProps: DeleteBroadcastProps) => {
  return {
    title: 'Delete broadcast',
    subtitle: `Are you sure you want to delete the "${assetProps.name}" broadcast?`,
    warningBlockMessage: 'This cannot be undone',
  };
};

const getDashboardModalContentProps = (assetProps: DeleteDashboardProps) => {
  return {
    title: 'Delete dashboard',
    subtitle: `Are you sure you want to delete the "${assetProps.name}" dashboard?`,
    warningBlockMessage: 'This cannot be undone',
  };
};

const getMetamodelModalContentProps = (assetProps: DeleteMetamodelProps) => {
  return {
    title: 'Delete metamodel',
    subtitle: `Are you sure you want to delete "${assetProps.name}" metamodel?`,
  };
};

const getReportModalContentProps = (assetProps: DeleteReportProps) => {
  return {
    title: 'Delete report',
    subtitle: `Are you sure you want to delete "${assetProps.name}" report?`,
  };
};
const getQueryModalContentProps = ({
  name,
  affectedPerspectives,
}: DeleteDynamicFilterProps) => ({
  title: `Delete graph filter?`,
  subtitle: `The graph filter "${name}" will be deleted permanently.`,
  text: affectedPerspectives?.length
    ? `${affectedPerspectives.length} saved ${pluralize(
        'perspective',
        affectedPerspectives.length
      )} ${unpluralizeVerb(
        'use',
        affectedPerspectives.length
      )} this graph filter:`
    : undefined,
  listOptions: affectedPerspectives.map(({ name }) => ({
    strongText: name,
  })),
  warningBlockMessage: affectedPerspectives?.length
    ? 'These perspectives will display incorrect results if the graph filter is deleted.'
    : undefined,
});

const getFieldModalContentProps = ({
  name,
  affectedEntities,
}: DeleteFieldProps) => {
  return {
    title: `Delete field from the organization`,
    subtitle: `Are you sure you want to delete the field "${name}" from the organization?`,
    text: 'This will affect:',
    listOptions: [
      {
        iconName: IconName.WORKSPACE,
        strongText: `${affectedEntities.workspaceCount}`,
        text: 'workspaces',
      },
      {
        iconName: IconName.SURVEYS,
        strongText: affectedEntities.surveyCount,
        text: 'surveys',
      },
      {
        iconName: IconName.COMPONENT,
        strongText: affectedEntities.componentCount,
        text: 'components',
      },
      {
        iconName: IconName.REFERENCE,
        strongText: affectedEntities.referenceCount,
        text: 'references',
      },
    ] as ModalListOption[],
  };
};

const getReferenceTypeModalContentProps = (
  assetProps: DeleteReferenceTypeProps
) => {
  const hasAffectedAssets = assetProps.affectedSurveys.length > 0;
  return {
    title: `Delete "${assetProps.name}" reference type permanently?`,
    confirmButtonTitle: 'Delete reference type',
    warningBlockMessage: getWarningBlockMessage(
      assetProps.affectedSurveys.length,
      AssetType.SURVEY,
      'reference type'
    ),
    text: hasAffectedAssets ? (
      <AffectedEntitiesTable
        affectedEntities={assetProps.affectedSurveys}
        affectedEntityType={AssetType.SURVEY}
      />
    ) : undefined,
  };
};

const getMultipleAssetsModalContentProps = (
  assetProps: DeleteMultipleAssetsProps
) => {
  const assetsCount: number = Object.values(assetProps.assets).reduce(
    (acc, assets) => acc + assets.length,
    0
  );
  const thisOrThese = assetsCount > 1 ? 'these' : 'this';
  const assetOrAssets = assetsCount > 1 ? 'assets' : 'asset';
  return {
    title: `Delete ${assetsCount} ${assetOrAssets}`,
    subtitle: `Are you sure you want to delete ${thisOrThese} ${assetsCount} selected ${assetOrAssets}?`,
    listOptions: getItemsListed(assetProps.assets),
  };
};

export const getModalContentProps = (
  assetProps: DeleteAssetProps
): ModalText => {
  if (assetProps.deleteType === DeleteType.FOLDER)
    return getFolderModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.WORKSPACE)
    return getWorkspaceModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.SCENARIO)
    return getScenarioModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.PRESENTATION)
    return getPresentationModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.MULTIPLE)
    return getMultipleAssetsModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.FIELD)
    return getFieldModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.SURVEY)
    return getSurveyModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.BROADCAST)
    return getBroadcastModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.DASHBOARD)
    return getDashboardModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.METAMODEL)
    return getMetamodelModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.REPORT)
    return getReportModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.VIEWPOINT)
    return getViewpointModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.TRAVERSAL)
    return getTraversalModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.REFERENCE_TYPE)
    return getReferenceTypeModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.BOOKMARK)
    return getBookmarkModalContentProps(assetProps);
  if (assetProps.deleteType === DeleteType.QUERY)
    return getQueryModalContentProps(assetProps);

  // ensure we exhaust all possibilities of event-type
  assetProps satisfies never;
  return { title: '' };
};

const collectWorkspacesIds = (deleteAssetProps: DeleteAssetProps) => {
  if (deleteAssetProps.deleteType === DeleteType.MULTIPLE) {
    return deleteAssetProps.assets.workspaces.map(({ id }) => id);
  }
  if (deleteAssetProps.deleteType === DeleteType.WORKSPACE) {
    return [deleteAssetProps.id];
  }
  return [];
};

const collectScenarioIds = (deleteAssetProps: DeleteAssetProps) => {
  if (deleteAssetProps.deleteType === DeleteType.MULTIPLE) {
    return deleteAssetProps.assets.scenarios.map(({ id }) => id);
  }
  return [];
};

const collectReportIds = (deleteAssetProps: DeleteAssetProps) => {
  if (deleteAssetProps.deleteType === DeleteType.MULTIPLE) {
    return deleteAssetProps.assets.reports.map(({ id }) => id);
  }
  if (deleteAssetProps.deleteType === DeleteType.REPORT) {
    return [deleteAssetProps.id];
  }
  return [];
};

const getSafeScenarioNameById: (_id: ArdoqId) => string = flow([
  scenarioInterface.getScenarioNameById,
  toString,
]);

export const useCheckIfWsInUse = (deleteAssetProps: DeleteAssetProps) => {
  const wsIds = collectWorkspacesIds(deleteAssetProps);
  const scenarioIds = new Set(collectScenarioIds(deleteAssetProps));
  const hasWorkspaces = Boolean(wsIds.length);
  const [{ loading, workspaceNamesInUse, scenariosUsingSelectedWs }, setState] =
    useState<{
      loading: boolean;
      workspaceNamesInUse: string[];
      scenariosUsingSelectedWs: ScenarioBasicDetails[];
    }>({
      loading: hasWorkspaces,
      workspaceNamesInUse: [],
      scenariosUsingSelectedWs: [],
    });

  useOnMount(() => {
    (async () => {
      if (!hasWorkspaces) return;
      const validIds = wsIds.filter(id => id !== undefined) as ArdoqId[];
      const result = await workspaceApi.usage(validIds);
      if (isArdoqError(result)) {
        // TODO[ARD-22935]: Perhaps there should be some error handling here
        logError(result);
        return;
      }
      const processedResult =
        deleteAssetProps.deleteType === DeleteType.MULTIPLE
          ? result.filter(({ _id }) => !scenarioIds.has(_id))
          : result;
      const allWsIdsInUse = processedResult.flatMap(
        ({ workspaceIds }) => workspaceIds
      );

      setState({
        loading: false,
        workspaceNamesInUse: intersection(validIds, allWsIdsInUse).map(
          wsId => workspaceInterface.getWorkspaceName(wsId!) ?? ''
        ),
        scenariosUsingSelectedWs: processedResult.map<ScenarioBasicDetails>(
          ({ _id }) => ({
            name: getSafeScenarioNameById(_id),
            _id,
          })
        ),
      });
    })();
  });
  return {
    loading,
    workspaceNamesInUse,
    scenariosUsingSelectedWs,
    canShowDeleteDialog: !loading && workspaceNamesInUse.length === 0,
  };
};

export const useCheckIfReportInUse = (deleteAssetProps: DeleteAssetProps) => {
  const reportIds = collectReportIds(deleteAssetProps);
  const hasReports = Boolean(reportIds.length);
  const [
    {
      dashboardsUsingSelectedReports,
      totalDashboardsUsingSelectedReports,
      loading,
    },
    setState,
  ] = useState<{
    loading: boolean;
    totalDashboardsUsingSelectedReports: number;
    dashboardsUsingSelectedReports: Array<{ _id: ArdoqId; name: string }>;
  }>({
    loading: hasReports,
    totalDashboardsUsingSelectedReports: 0,
    dashboardsUsingSelectedReports: [],
  });
  useEffectOnce(() => {
    (async () => {
      if (!hasReports) return;
      const result = await reportApi.usage(reportIds);
      if (isArdoqError(result)) {
        logError(result);
        return;
      }
      setState({
        loading: false,
        totalDashboardsUsingSelectedReports: result.totalUseCount,
        dashboardsUsingSelectedReports: result.dashboards,
      });
    })();
  });
  return {
    loading,
    dashboardsUsingSelectedReports,
    totalDashboardsUsingSelectedReports,
  };
};
