import {
  IColumn,
  IRenderContext,
  IStripe,
  Insets,
  StripeStyleBase,
  SvgVisual,
} from '@ardoq/yfiles';
import { createSvgElement } from '@ardoq/dom-utils';

export default class ArdoqSwimlaneStripeStyle extends StripeStyleBase {
  override createVisual(context: IRenderContext, stripe: IStripe) {
    if (stripe === null) {
      return null;
    }
    const layout = stripe.layout;
    const hasNoChildren = !stripe.childStripes.getEnumerator().moveNext();

    let stripeInsets;
    let isFirst;
    const isVertical = Boolean(stripe.lookup(IColumn.$class));
    if (hasNoChildren) {
      const actualInsets = stripe.actualInsets;
      stripeInsets = new Insets(
        isVertical ? stripe.insets.left : actualInsets.left,
        isVertical ? actualInsets.top : stripe.insets.top,
        actualInsets.right,
        0
      );
      let walker = stripe.root;
      while (walker !== null && walker !== stripe) {
        const enumerator = walker.childStripes.getEnumerator();
        walker = enumerator.moveNext() ? enumerator.current : null;
      }
      isFirst = walker === stripe;
    } else {
      stripeInsets = stripe.insets;
      isFirst = false;
    }

    const g = createSvgElement('g');

    let x = 1.0;
    let y = 1.0;
    let w = layout.width - 2;
    let h = layout.height - 2;

    const hasStripeInset = isVertical
      ? stripeInsets.top > 2
      : stripeInsets.left > 2;
    if (hasStripeInset) {
      const stripeInsetWidth = isVertical ? w : stripeInsets.left - 2;
      const stripeInsetHeight = isVertical ? stripeInsets.top - 2 : h;
      createInnerRect(
        g,
        x,
        y,
        stripeInsetWidth,
        stripeInsetHeight,
        'stripe-inset'
      );
    }
    if (isVertical) {
      y += stripeInsets.top;
      h -= stripeInsets.top;
    } else {
      x += stripeInsets.left;
      w -= stripeInsets.left;
    }

    if (!isFirst && hasNoChildren) {
      const tableLineX = isVertical ? -1 : x;
      const tableLineY = isVertical ? y : -1;
      const tableLineWidth = isVertical ? 2 : w - 1;
      const tableLineHeight = isVertical ? h - 1 : 2;
      createInnerRect(
        g,
        tableLineX,
        tableLineY,
        tableLineWidth,
        tableLineHeight,
        'table-line'
      );
    }

    g.setAttribute('transform', `translate(${layout.x} ${layout.y})`);

    const result = new SvgVisual(g);

    (result as any).cache = {
      x: layout.x,
      y: layout.y,
      w: layout.width,
      h: layout.height,
      top: stripeInsets.top,
      left: stripeInsets.left,
      right: stripeInsets.right,
      bottom: stripeInsets.bottom,
    };
    return result;
  }
}

/** Helper function to create the inner rectangles for the headings. */
const createInnerRect = (
  g: SVGGElement,
  x: number,
  y: number,
  w: number,
  h: number,
  cssClass: string
) => {
  const rect = createSvgElement('rect', {
    class: cssClass,
    x: `${x}`,
    y: `${y}`,
    width: `${w}`,
    height: `${h}`,
  });
  g.appendChild(rect);
  (rect as any).cache = { x, y, w, h, cl: cssClass };
};
