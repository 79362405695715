import { ViewIds } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

type TrackNodeClickOnCanvasMetadata = {
  viewId: ViewIds;
  nodeType: 'group' | 'component';
};

export const trackNodeClickOnCanvas = ({
  nodeType,
  viewId,
}: TrackNodeClickOnCanvasMetadata) =>
  trackEvent(`Clicked ${nodeType}`, { viewId });
