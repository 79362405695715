import {
  ExtractPayload,
  RegisteredReducer,
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { constant, omit } from 'lodash';

export type SelectionState = {
  current: string | null;
  byId: Record<string, boolean>;
  selectionCache: Record<string, string[]> | null;
};

const defaultState: SelectionState = {
  current: null,
  byId: {},
  selectionCache: null,
};

type StateStreamExtension = {
  reducers?: RegisteredReducer<SelectionState, any>[];
  defaultState?: Record<string, any>;
};

/*
 * Creates stream state that handles selection of items in a list
 * Supports single, meta/ctr, and shift click out of the box
 * Can be extended by providing the extensions parameter
 */
export const setupSelection$ = (
  namespace: string,
  extensions?: StateStreamExtension
) => {
  const selectClick = actionCreator<string>(
    `[${namespace}] selection selectClick`
  );
  const selectMeta = actionCreator<string>(
    `[${namespace}] selection selectMeta`
  );
  const selectShift = actionCreator<{ id: string; dataSource: any[] }>(
    `[${namespace}] selection selectShift`
  );
  const resetSelection = actionCreator(`[${namespace}] selection reset`);

  const removeSelection = actionCreator<string[]>(
    `[${namespace}] selection remove`
  );

  const handleClickSelected = (
    _: SelectionState,
    id: string
  ): SelectionState => ({
    current: id,
    byId: { [id]: true },
    selectionCache: null,
  });

  const handleMetaSelected = (
    state: SelectionState,
    id: string
  ): SelectionState => ({
    current: !state.byId[id] ? id : null,
    byId: { ...state.byId, [id]: !state.byId[id] },
    selectionCache: null,
  });

  const handleShiftSelected = (
    state: SelectionState,
    { id, dataSource }: ExtractPayload<typeof selectShift>
  ) => {
    if (!state.current) return state;
    const [a, b] = [
      dataSource.findIndex(e => e.id === id),
      dataSource.findIndex(e => e.id === state.current),
    ].sort((a, b) => b - a);
    const ids = dataSource.splice(b, a - b + 1).map(e => e.id);
    return {
      ...state,
      byId: {
        ...ids.reduce(
          (acc, id) => ({ ...acc, [id]: true }),
          (state.current &&
            state.selectionCache?.[state.current]?.reduce(
              (acc, id) => ({ ...acc, [id]: false }),
              state.byId
            )) ||
            state.byId
        ),
      },
      selectionCache: state.current ? { [state.current]: ids } : null,
    };
  };

  const handleRemoveSelection = (state: SelectionState, ids: string[]) => ({
    ...state,
    byId: omit(state.byId, ids),
  });

  const selection$ = persistentReducedStream<SelectionState>(
    `${namespace} selection$`,
    { ...defaultState, ...extensions?.defaultState },
    [
      reducer(selectClick, handleClickSelected),
      reducer(selectMeta, handleMetaSelected),
      reducer(selectShift, handleShiftSelected),
      reducer(resetSelection, constant(defaultState)),
      reducer(removeSelection, handleRemoveSelection),
      ...(extensions?.reducers || []),
    ]
  );

  return {
    selection$,
    selectClick,
    selectMeta,
    selectShift,
    resetSelection,
    removeSelection,
  };
};
