import styled from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { ArdoqId } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { MouseEvent } from 'react';
import { CUSTOM_SVG_ICON_SELECTOR, Icon, IconName } from '@ardoq/icons';
import {
  CONTENT_MAX_WIDTH,
  ICON_SIZE,
  horizontalSpacing,
} from 'tabview/pagesView/constants';
import { SmallComponentPath } from 'tabview/pagesView/ComponentPath';
import { ComponentRepresentation } from '@ardoq/renderers';
import { isPresentationMode } from 'appConfig';
import { colors, s16 } from '@ardoq/design-tokens';
import { contrastEnsuredComponentFill } from 'utils/modelCssManager/getCssColors';
import { contextMenuOnClick } from './contextMenu';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import { returnZero } from '@ardoq/common-helpers';
import { FlexBox } from '@ardoq/layout';
import ExternalDocumentActions from './ExternalDocumentsActions';

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${horizontalSpacing.SMALL};
  user-select: none;
  gap: ${s16};
`;
const ComponentName = styled.span`
  ${fontSizeDefinitions_DEPRECATED.LARGE}
  color: ${colors.grey15};
  font-weight: bold;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const RepresentationContainer = styled.div`
  display: flex;
  margin-right: ${horizontalSpacing.MEDIUM};
  min-width: ${ICON_SIZE};
  text-align: center;

  && > ${CUSTOM_SVG_ICON_SELECTOR} {
    min-width: ${ICON_SIZE};
    max-width: ${ICON_SIZE};
  }
`;

const ComponentButton = styled(Icon)`
  color: ${colors.grey15};
  cursor: pointer;
`;

const LockIcon = styled(Icon)`
  margin-left: ${horizontalSpacing.SMALL};
`;

const HeaderContainer = styled.div`
  break-inside: avoid;
  break-before: auto;
  max-width: ${CONTENT_MAX_WIDTH};
`;

const ComponentActionsContainer = styled(FlexBox).attrs({
  flex: 1,
  align: 'center',
  justify: 'flex-end',
  gap: 'medium',
})``;

type ComponentHeaderProperties = {
  componentId: ArdoqId;
  bypassRenderLimit: boolean;
  isViewpointMode: boolean;
};
const ComponentHeader = ({
  componentId,
  bypassRenderLimit,
  isViewpointMode,
}: ComponentHeaderProperties) => {
  const componentRepresentationData = componentInterface.getRepresentationData(
    componentId
  ) ?? {
    isImage: false,
    value: null,
  };
  const componentExternalDocumentData =
    componentInterface.getExternalDocumentData(componentId);

  return (
    <HeaderContainer>
      <HeaderLayout data-click-namespace="pages view component header">
        <RepresentationContainer>
          <ComponentRepresentation
            isImage={componentRepresentationData.isImage}
            value={componentRepresentationData.value}
            icon={componentRepresentationData.icon}
            // fontSize needed to override fontSize on material icons error icon
            style={{
              fontSize: 24,
              color: contrastEnsuredComponentFill(componentId),
            }}
          />
        </RepresentationContainer>
        <ComponentName
          className="component"
          data-global-handler-id={componentId}
        >
          {truncateComponentLabel({
            ...getComponentLabelParts(componentId),
            width: Infinity,
            measure: returnZero,
          })}
        </ComponentName>
        {componentInterface.isLocked(componentId) && (
          <LockIcon iconName={IconName.LOCK} />
        )}
        <ComponentActionsContainer>
          {componentExternalDocumentData.length > 0 && (
            <ExternalDocumentActions
              componentId={componentId}
              externalDocuments={componentExternalDocumentData}
            />
          )}
          {!bypassRenderLimit && !isPresentationMode() && (
            <ComponentButton
              data-tooltip-text="Show component context menu"
              data-click-id="edit-component-button"
              onClick={(event: MouseEvent) =>
                contextMenuOnClick(
                  event.nativeEvent,
                  {
                    componentIds: [componentId],
                  },
                  isViewpointMode
                )
              }
              iconName={IconName.MORE_VERT}
            />
          )}
        </ComponentActionsContainer>
      </HeaderLayout>
      <SmallComponentPath componentId={componentId} />
    </HeaderContainer>
  );
};

export default ComponentHeader;
