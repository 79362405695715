import getStyles, { GetStylesArgs } from './getStyles';

const updateStyles = (args: GetStylesArgs) => {
  const {
    labelStyle,
    labelLayoutParameter,
    nodeStyle,
    edgeStyle,
    groupStyle,
    edgeLabelStyle,
  } = getStyles(args);
  const {
    graph: { nodeDefaults, edgeDefaults, groupNodeDefaults },
  } = args.state.graphComponent.current!;
  nodeDefaults.labels.style = labelStyle;
  nodeDefaults.labels.layoutParameter = labelLayoutParameter;
  nodeDefaults.style = nodeStyle;
  edgeDefaults.style = edgeStyle;
  edgeDefaults.labels.style = edgeLabelStyle;
  groupNodeDefaults.style = groupStyle;
};
export default updateStyles;
