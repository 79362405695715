import {
  GlobalReferenceTypeId,
  GlobalReferenceTypes,
  RepresentationData,
} from '@ardoq/data-model';
import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';
import { LegendComponentType } from '@ardoq/graph';
import { DependencyMapComponentType } from 'tabview/dependencyMaps/types';

export type NameLabelDict = {
  name: string;
  label: string;
};

export type ViewStreamShape = {
  listFields: NameLabelDict[];
  listValues: NameLabelDict[];
  componentTypes: DependencyMapComponentType[];
  legendComponentTypes: LegendComponentType[];
  supportingValue: string;
  focusedComponentId: ArdoqId | null;
  hoveredComponentId: ArdoqId | null;
  rootLayoutBox: LayoutBoxType;
  referenceTypes: GlobalReferenceTypes;
  maxTreeDepth: number;
  workspaceId: ArdoqId;
};

export type ListValuesMap = {
  [key: string]: number;
};

export type ViewSettingsShape = {
  selectedFieldName: string;
  supportingValue: string;
  groupingTypeId: string;
  isLayoutDirectionAnyVertical: boolean;
  includeIncomingReferenceTypes: GlobalReferenceTypeId[];
  includeOutgoingReferenceTypes: GlobalReferenceTypeId[];
  treeDepth: number;
  isLegendActive: boolean;
};

export enum LayoutBoxTypes {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
  CONTAINER = 'CONTAINER',
  GROUP = 'GROUP',
  LEAF = 'LEAF',
  LEAF_REFERENCED = 'LEAF_REFERENCED',
}

export type TemporaryDict = {
  id: string;
  name: string;
  classNames: string;
  representationData: RepresentationData;
  priorityValue?: string;
  isGrouping?: boolean;
  priority?: number;
  navigatorOrder: number;
  layoutType: string; // not needed anymore?
  leavesCount: number;
  minGridWidth: number;
  children: TemporaryDict[];
  collapsedDescendants: TemporaryDict[];
  isCollapsed: boolean;
  isCollapsedReference: boolean;
  supportingColumns: TemporaryDict[];
  priorityRows: TemporaryDict[][];
  anyRow: TemporaryDict[];
  referencedDirection: ReferenceDirection;
  referenceLabel: string;
  referenceFieldValue: string;
  referenceFieldLabel: string;
  iconColor?: string;
};

type DefaultLayoutArgs = {
  targetGridWidth: number;
  isLayoutDirectionAnyVertical: boolean;
};

type LayoutNodeArgs = {
  node: TemporaryDict;
} & DefaultLayoutArgs;

type LayoutPriorityRowArgs = {
  priorityRow: TemporaryDict[];
} & DefaultLayoutArgs;

type LayoutAnyRowArgs = {
  anyRow: TemporaryDict[];
} & DefaultLayoutArgs;

type LayoutCenterColumnArgs = {
  priorityRows: TemporaryDict[][];
} & LayoutAnyRowArgs;

export type LayoutBoxType = {
  layoutType: LayoutBoxTypes;
  layoutBoxes: LayoutBoxType[];
  targetGridWidth: number;
  hasPriorityValue: boolean;
  calculatedGridWidth: number;
  hasCollapsedNodes?: boolean;
  isCollapsedReference?: boolean;
  isGrouping?: boolean;
  id?: string;
  classNames?: string;
  representationData?: RepresentationData;
  leavesCount?: number;
  referencedDirection?: ReferenceDirection;
  referenceLabel?: string;
  referenceFieldValue?: string;
  referenceFieldLabel?: string;
  iconColor?: string;
};

export type LayoutPriorityRow = (
  layoutPriorityRowArgs: LayoutPriorityRowArgs
) => LayoutBoxType;
export type LayoutAnyRow = (
  layoutAnyRowArgs: LayoutAnyRowArgs
) => LayoutBoxType;
export type LayoutCenterColumn = (
  layoutCenterColumnArgs: LayoutCenterColumnArgs
) => LayoutBoxType;

export type LayoutNode = (layoutNodeArgs: LayoutNodeArgs) => LayoutBoxType;

export enum RefTypeIds {
  ALL = '@@all@@',
  NONE = '@@none@@',
}
