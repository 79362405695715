import { s24, s32 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import {
  WorkspaceDigestType,
  WorkspaceDigestChange,
  WorkspaceDigestContributor,
} from '@ardoq/api-types';
import { ArrowRightIcon } from '@ardoq/icons';
import { DatasourceTable } from '@ardoq/table';
import { getStatsTypeText, getTableData } from './utils';
import { createStatTableHeader } from './consts';
import { Tab, TabsGroup } from '@ardoq/tabs';
import { Header4, InlineText, Paragraph } from '@ardoq/typography';
import { DefaultNotification } from '@ardoq/status-ui';
import { Box } from '@ardoq/layout';

export const Container = styled.div`
  height: calc(90vh - 56px);
  padding: ${s24} ${s32};
`;

interface TopContributorsProps {
  contributors: WorkspaceDigestContributor[];
}
export function TopContributors({ contributors }: TopContributorsProps) {
  return (
    <>
      <Header4>Top Contributors</Header4>
      {contributors.map(contributor => (
        <InlineText variant="text2" key={contributor.name}>
          <ArrowRightIcon /> {` ${contributor.name}`} -{` `}
          <InlineText variant="text2Bold">
            {contributor.changes}
          </InlineText>{' '}
          changes.
        </InlineText>
      ))}
      {contributors.length === 0 ? 'No contributors' : null}
    </>
  );
}

interface StatsDetailsProps {
  workspaceDigest: WorkspaceDigestType;
}
export function StatsDetails({ workspaceDigest }: StatsDetailsProps) {
  const tabs = workspaceDigest.stats;
  return (
    <>
      <Paragraph variant="text1Bold">Changes</Paragraph>
      {tabs.length !== 0 ? (
        <TabsGroup>
          {tabs.map((stat, index) => (
            <Tab
              key={index}
              label={getStatsTypeText(stat.type)}
              tabId={stat.type}
            >
              <Box paddingY="medium">
                <InlineText variant="text2Bold">
                  {getStatsTypeText(stat.type)} :
                </InlineText>
                <InlineText variant="text2">
                  {' '}
                  New: {stat.new}, Updated:{' '}
                </InlineText>
                {stat.updated}
              </Box>
              <DatasourceTable
                columns={createStatTableHeader()}
                dataSource={
                  getTableData(
                    workspaceDigest.changes,
                    stat.type
                    // Not exactly correct, but
                    // the types here were fairly broken
                    // it was discovered only after the
                    // Datasourcetable got new better TS notation
                  ) as WorkspaceDigestChange[]
                }
                renderEmptyTable={() => (
                  <DefaultNotification>No changes</DefaultNotification>
                )}
              />
            </Tab>
          ))}
        </TabsGroup>
      ) : null}
    </>
  );
}
