import { MutableRefObject } from 'react';
import { ViewIds } from '@ardoq/api-types';
import type { ExportConfigParams } from '@ardoq/view-settings';
import { getExportsForHtmlView } from '@ardoq/export';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { addToPresentation as addToPresentationFn } from 'viewSettings/exportHandlers';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';

const getExportSettings = (
  viewId: ViewIds,
  containerRef: MutableRefObject<HTMLElement | null>
): ExportConfigParams => {
  const { exportToPng, addToPresentation } = getExportsForHtmlView({
    container: () => containerRef.current,
    exportedViewMetadata: {
      name: viewId,
    },
    trackingFunction: trackExportingVisualization,
    addToPresentation: addToPresentationFn,
    getStyleSheets,
  });

  return {
    addToPresentation,
    exportToPng,
  };
};

export default getExportSettings;
