import { map } from 'rxjs/operators';
import { mapValues } from 'lodash';
import { DEFAULT_FIELDS } from './defaultFields';
import { rawFields$ } from './fields$';
import { APIFieldType } from '@ardoq/api-types';
import { getAllDefaultAttributes } from '@ardoq/renderers';

export type AdvancedSearchFieldsHashState = {
  [fieldName: string]: {
    name: string;
    label: string;
    type?: APIFieldType;
  };
};

export const advancedSearchFieldsHash$ = rawFields$.pipe(
  map(fieldsState => {
    const defaultAttributes = getAllDefaultAttributes();
    return Object.fromEntries(
      [
        ...DEFAULT_FIELDS,
        ...defaultAttributes,
        ...Object.values(fieldsState.byId).map(field => ({
          name: field.name,
          label: field.label,
          type: field.type,
        })),
      ].map(field => [field.name, field])
    );
  })
);

export const searchFieldLabels$ = advancedSearchFieldsHash$.pipe(
  map(fieldsHashState => {
    const fieldsLabels = mapValues(fieldsHashState, ({ label }) => label);
    return { fieldsLabels };
  })
);
