import { returnPayload } from '@ardoq/common-helpers';
import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

type FilteringState = string[] | null;

const defaultState: FilteringState = [];

/*
 * Creates stream state that handles filtering
 * It has three states
 *  - state = null implies the filter is disabled
 *  - state = [] empty filter, nothing is passed through
 *  - state = [...] filter through the entries
 */
export const setupFiltering$ = (namespace: string) => {
  const setFiltered = actionCreator<FilteringState>(
    `[${namespace}] filtered setFiltered`
  );

  const filtered$ = persistentReducedStream<FilteringState>(
    `${namespace} filtered$`,
    defaultState,
    [reducer(setFiltered, returnPayload)]
  );

  return { filtered$, setFiltered };
};
