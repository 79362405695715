import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  ChartComponentProperties,
  ChartDimensions,
  DEFAULT_MARGIN,
  LABEL_MARGIN,
  MAX_RADIUS_FACTOR,
  MOUSE_MOVE_EVENT_THROTTLE,
  Margin,
  NumericRange,
  NumericScalingFunction,
  Rect,
  Size,
  TICK_LENGTH,
  areaOfCircle,
  getDimensions,
  getTarget,
  labelHeight,
  panAndZoom,
  radiusOfCircle,
  radiusScaler,
  radiusUnscaler,
  rect,
  xScaler,
  xUnscaler,
  yScaler,
  yUnscaler,
} from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import QuadrantOverlay from './quadrantOverlay';
import { ticks } from './ticks';
import { isEqual, throttle } from 'lodash';
import { ChartArea } from './chartArea';
import { YAxis } from './yAxis';
import { XAxis } from './xAxis';
import { TITLE_MARGIN } from 'utils/charting/consts';
import {
  BubbleChartDataPointModel,
  BubbleChartDomains,
  BubbleChartGridMode,
  BubbleChartQuadrantSettings,
  BubbleChartScalers,
  HighlightState,
} from './types';
import {
  BUBBLE_SLIDER_OPTIONS,
  BUBBLE_SLIDER_RANGE,
  DISPLAYED_LABELS_LIMIT,
  EMPTY_HIGHLIGHT_STATE,
  ZERO_BUBBLE,
  ZERO_BUBBLE_SIZE,
} from './consts';
import { BubbleChartContainer, BubbleChartSvgElement } from './atoms';
import { bubbleAreaScaleFactory } from './util';
import getBubbleLegendRow from './legend/getBubbleLegendRow';
import { formatAxisLabel } from './util';
import { MAIN_GROUP_CONTAINER_CLASSNAME } from '@ardoq/global-consts';
import { type HasViewInstanceId } from '@ardoq/graph';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import { toggleLegend } from 'presentation/viewPane/actions';
import {
  ZoomableViewLegendContainerForwardRef,
  ZoomableViewLegendContainerForwardRefType,
} from 'tabview/relationshipDiagrams/atoms';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { ViewIds } from '@ardoq/api-types';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend,
} from '@ardoq/view-legend';
import { useResizeObserver } from '@ardoq/hooks';
import { Features, hasFeature } from '@ardoq/features';
import { MeasureStyledText } from '@ardoq/dom-utils';
import { bubbleChartCommands } from './commands';

const bubbleAreaScale = bubbleAreaScaleFactory({
  domain: BUBBLE_SLIDER_OPTIONS,
  range: BUBBLE_SLIDER_RANGE,
});

const FULLY_OPAQUE_LABELS = 20;

const longestYTickLabel = (
  windowCenter: Point,
  windowScale: number,
  clientRect: Pick<Rect, 'width' | 'height'>,
  yDomain: NumericRange
): number => {
  const windowRect = rect(windowCenter, windowScale);

  const yUnscalerForMeasuring = yUnscaler([0, clientRect.height], yDomain, [
    windowRect.top,
    windowRect.bottom,
  ]);
  const yVisibleRangeForMeasuring: NumericRange = [
    yUnscalerForMeasuring(clientRect.height),
    yUnscalerForMeasuring(0),
  ];
  const yTicksForMeasuring = ticks(
    yDomain,
    clientRect.height,
    yVisibleRangeForMeasuring
  );

  return yTicksForMeasuring.reduce(
    (longest, tick) =>
      Math.max(longest, measureText({ text: formatAxisLabel(tick) })),
    0
  );
};

const measureText = new MeasureStyledText().getTextWidth;

// regarding transition-duration: the labels fade in slower than the bubbles. otherwise, labels will suddenly appear under the mouse and steal the user's attention by triggering the hover/focus effect.

interface BubbleChartRenderingParameters {
  scaleX: NumericScalingFunction;
  scaleY: NumericScalingFunction;
  visibleRangeX: NumericRange;
  visibleRangeY: NumericRange;
  scalers: BubbleChartScalers;
}
const createRenderingParameters = (
  dimensions: ChartDimensions,
  domain: BubbleChartDomains,
  center: Point,
  scale: number,
  bubbleAreaScaleFactor: number,
  data: BubbleChartDataPointModel[]
): BubbleChartRenderingParameters => {
  const { left, right, top, bottom } = rect(center, scale);

  const minDimension = Math.min(
    dimensions.chart.height,
    dimensions.chart.width
  );
  const zeroRadiusDomain = domain.radius[0] === domain.radius[1];
  const maxRadius = zeroRadiusDomain
    ? minDimension * 0.01 // bubbles should be small if there's no Z field.
    : minDimension * MAX_RADIUS_FACTOR;
  const maxArea = areaOfCircle(maxRadius);
  const minArea = zeroRadiusDomain
    ? maxArea
    : maxArea * Math.max(0, domain.radius[0] / domain.radius[1]); // bubbles can't be < 0px
  const minRadius = radiusOfCircle(minArea);

  const axisMargin = Math.ceil(maxRadius);
  const xAxisPixelRange: NumericRange = [
    axisMargin,
    dimensions.chart.width - axisMargin,
  ];
  const yAxisPixelRange: NumericRange = [
    axisMargin,
    dimensions.chart.height - axisMargin,
  ];

  const scaleX = xScaler(xAxisPixelRange, domain.x, [left, right]);
  const scaleY = yScaler(yAxisPixelRange, domain.y, [top, bottom]);
  const unscaleX = xUnscaler(xAxisPixelRange, domain.x, [left, right]);
  const unscaleY = yUnscaler(yAxisPixelRange, domain.y, [top, bottom]);

  const maxRadiusScale = bubbleAreaScale(bubbleAreaScaleFactor);

  const radiusScale = scale / maxRadiusScale;
  const scaleRadius = radiusScaler(
    [minRadius, maxRadius],
    domain.radius,
    radiusScale
  );
  const unscaleRadius = radiusUnscaler(
    [minRadius, maxRadius],
    domain.radius,
    radiusScale
  );

  const MIN_FONT_SIZE = 10;
  const MAX_FONT_SIZE = 24;
  const scaleFontSize = radiusScaler(
    [MIN_FONT_SIZE, MAX_FONT_SIZE],
    domain.labeledBubblesRadius
  );

  const MIN_OPACITY = 0.05;
  const scaleFontOpacity = radiusScaler(
    [MIN_OPACITY, 1],
    data.length <= FULLY_OPAQUE_LABELS
      ? [-1, 0]
      : [0, Math.min(DISPLAYED_LABELS_LIMIT, data.length) - FULLY_OPAQUE_LABELS]
  );

  const visibleRangeX: NumericRange = [
    unscaleX(xAxisPixelRange[0]),
    unscaleX(xAxisPixelRange[1]),
  ];
  const visibleRangeY: NumericRange = [
    unscaleY(yAxisPixelRange[1]),
    unscaleY(yAxisPixelRange[0]),
  ];

  const scalers: BubbleChartScalers = {
    fontSize: scaleFontSize,
    fontOpacity: scaleFontOpacity,
    radius: !hasFeature(Features.SIZE_ZERO_BUBBLES)
      ? scaleRadius
      : value => (value ? scaleRadius(value) : 0),
    unscaleRadius,
    y: scaleY,
    x: scaleX,
  };
  return { scaleX, scaleY, visibleRangeX, visibleRangeY, scalers };
};

const offset = (
  clientX: number,
  clientY: number,
  bbox: DOMRect,
  margin: Margin
): Point => [
  clientX - bbox.left - margin.left,
  clientY - bbox.top - margin.top,
];
const getHighlightState = (target: SVGElement | null): HighlightState =>
  target?.dataset
    ? {
        target: target,
        id: target.dataset.globalHandlerId ?? '',
        labelPosition: target.matches('.dataPointLabel')
          ? [
              parseFloat(target.getAttribute('x') || 'NaN'),
              parseFloat(target.getAttribute('y') || 'NaN'),
            ]
          : null,
      }
    : { ...EMPTY_HIGHLIGHT_STATE, target: target };

type BubbleChartProperties = ChartComponentProperties &
  HasViewInstanceId & {
    data: BubbleChartDataPointModel[];
    domain: BubbleChartDomains;
    background: BubbleChartGridMode;
    xAxisTitle: string;
    yAxisTitle: string;
    isLegendActive: boolean;
    customQuadrantSettings: BubbleChartQuadrantSettings | null;
    bubbleAreaScaleFactor: number;
    selectedFieldNameRadius: string;
    showBubblesWithZeroValue: boolean;
    hoverState: HighlightState;
    focusState: HighlightState;
  };

type GetMarginArgs = Pick<
  BubbleChartProperties,
  'windowCenter' | 'windowScale' | 'domain'
> & { clientRect: Size };
const getMargin = ({
  windowCenter,
  windowScale,
  clientRect,
  domain,
}: GetMarginArgs) => ({
  left:
    longestYTickLabel(windowCenter, windowScale, clientRect, domain.y) +
    TICK_LENGTH +
    2 * LABEL_MARGIN +
    2 * TITLE_MARGIN +
    labelHeight,
  top: DEFAULT_MARGIN.top,
  right: DEFAULT_MARGIN.right,
  bottom: 2 * labelHeight + TICK_LENGTH + 2 * LABEL_MARGIN + 2 * TITLE_MARGIN,
});

const BubbleChart = ({
  viewInstanceId,
  data,
  isLegendActive,
  domain,
  background,
  windowCenter,
  windowScale,
  xAxisTitle,
  yAxisTitle,
  bubbleAreaScaleFactor,
  selectedFieldNameRadius,
  customQuadrantSettings,
  selectComponent,
  isPanning,
  registerPanZoomClient,
  showBubblesWithZeroValue,
  hoverState,
  focusState,
}: BubbleChartProperties) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const clientSize = useResizeObserver(containerRef);
  const clientRect = {
    width: clientSize.width ?? 0,
    height: clientSize.height ?? 0,
  };
  const margin = getMargin({ windowCenter, windowScale, clientRect, domain });
  const dimensions = getDimensions(clientRect, margin);
  const zoomableViewLegendContainerForwardRef =
    useRef<ZoomableViewLegendContainerForwardRefType>(null);
  useEffect(() => {
    const toggleLegendSubscription = subscribeToAction(
      toggleLegend,
      ({ legendActive }) => {
        zoomableViewLegendContainerForwardRef.current?.setIsVisible(
          legendActive
        );
      },
      ViewIds.BUBBLE
    );
    return () => toggleLegendSubscription.unsubscribe();
  }, [viewInstanceId]);
  const hoverBubble = useMemo(
    () =>
      throttle((e: MouseEvent) => {
        if (isPanning()) {
          return;
        }
        const target = getTarget(e.nativeEvent.target);
        const newHighlightState =
          target instanceof SVGElement
            ? getHighlightState(target)
            : EMPTY_HIGHLIGHT_STATE;

        if (!isEqual(newHighlightState, hoverState)) {
          bubbleChartCommands.updateHoverState(
            newHighlightState,
            viewInstanceId
          );
        }
      }, MOUSE_MOVE_EVENT_THROTTLE),
    [isPanning, hoverState, viewInstanceId]
  );
  const focusBubble = useCallback(
    (e: MouseEvent) => {
      if (isPanning()) {
        return;
      }
      const target = getTarget(e.nativeEvent.target);
      const newHighlightState =
        target instanceof SVGElement
          ? getHighlightState(target)
          : EMPTY_HIGHLIGHT_STATE;

      if (!isEqual(newHighlightState, focusState)) {
        bubbleChartCommands.updateFocusState(newHighlightState, viewInstanceId);
      }
    },
    [isPanning, focusState, viewInstanceId]
  );

  registerPanZoomClient({
    getDimensions: () => dimensions,
    offsetToAxes: (e: WheelEvent) => {
      const targetElement = e.currentTarget;
      if (!(targetElement instanceof Element)) {
        return [0, 0];
      }
      return offset(
        e.clientX,
        e.clientY,
        targetElement.getBoundingClientRect(),
        dimensions.margin
      );
    },
    onWindowChanged: () =>
      bubbleChartCommands.updateHoverState(
        EMPTY_HIGHLIGHT_STATE,
        viewInstanceId
      ),
    getMouseWheelElement: () => containerRef.current!,
    selectComponent: args => {
      bubbleChartCommands.updateHoverState(
        EMPTY_HIGHLIGHT_STATE,
        viewInstanceId
      );
      selectComponent?.(args);
    },
  });

  const quadrantSettings: BubbleChartQuadrantSettings | null =
    background === BubbleChartGridMode.TIME
      ? {
          xMinimum: 1,
          xMaximum: 5,
          yMinimum: 1,
          yMaximum: 5,
          labels: ['tolerate', 'invest', 'eliminate', 'migrate'],
        }
      : background === BubbleChartGridMode.CUSTOM
        ? customQuadrantSettings
        : null;

  const { scaleX, scaleY, visibleRangeX, visibleRangeY, scalers } =
    createRenderingParameters(
      dimensions,
      domain,
      windowCenter,
      windowScale,
      bubbleAreaScaleFactor,
      data
    );

  const xTicks = ticks(domain.x, dimensions.chart.width, visibleRangeX);
  const yTicks = ticks(domain.y, dimensions.chart.height, visibleRangeY);

  const clipPathUrl = `chartAreaClipPath-${viewInstanceId}`;
  const containerClipPathUrl = `containerClipPath-${viewInstanceId}`;
  const activeDiffMode = getActiveDiffMode();
  const isScopeDiffMode = isInScopeDiffMode();
  return (
    <BubbleChartContainer
      ref={containerRef}
      onContextMenu={e => e.preventDefault()}
    >
      <BubbleChartSvgElement
        height={dimensions.container.height}
        width={dimensions.container.width}
        onMouseMove={hoverBubble}
        onClick={focusBubble}
      >
        <defs>
          <symbol
            id={ZERO_BUBBLE}
            width={ZERO_BUBBLE_SIZE}
            height={ZERO_BUBBLE_SIZE}
            viewBox="0 0 6 6"
          >
            <path
              d="M4.99994 5L0.999972 1.00003"
              stroke="#6F7D90"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1.00006 5L5.00003 1.00003"
              stroke="#6F7D90"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </symbol>
        </defs>
        <clipPath id={clipPathUrl}>
          <rect
            height={Math.max(0, dimensions.chart.height)}
            width={Math.max(0, dimensions.chart.width)}
          />
        </clipPath>

        <g
          clipPath={`url(#${containerClipPathUrl})`}
          className={`${MAIN_GROUP_CONTAINER_CLASSNAME}`}
        >
          <clipPath id={containerClipPathUrl}>
            <rect
              height={dimensions.container.height}
              width={dimensions.container.width}
            />
          </clipPath>
          {quadrantSettings && (
            <QuadrantOverlay
              width={dimensions.chart.width}
              height={dimensions.chart.height}
              margin={dimensions.margin}
              scaleX={scaleX}
              scaleY={scaleY}
              viewInstanceId={viewInstanceId}
              xRange={domain.x}
              yRange={domain.y}
              quadrantSettings={quadrantSettings}
              clipPathUrl={clipPathUrl}
            />
          )}
          <XAxis
            margin={margin}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
            ticks={xTicks}
            scaler={scaleX}
            grid={background === 'grid'}
            title={xAxisTitle}
          />
          <YAxis
            margin={margin}
            ticks={yTicks}
            scaler={scaleY}
            height={dimensions.chart.height}
            width={dimensions.chart.width}
            grid={background === 'grid'}
            title={yAxisTitle}
          />
          <ChartArea
            dimensions={dimensions}
            margin={margin}
            data={data}
            scalers={scalers}
            showLabels={!isPanning()}
            hoverId={hoverState.id}
            focusId={focusState.id}
            hoverLabelPosition={hoverState.labelPosition}
            focusLabelPosition={focusState.labelPosition}
            clipPathUrl={clipPathUrl}
            showBubblesWithZeroValue={showBubblesWithZeroValue}
          />
        </g>
      </BubbleChartSvgElement>
      <HeightOffset>
        {heightOffset => (
          <ZoomableViewLegendContainerForwardRef
            initiallyVisible={isLegendActive}
            ref={zoomableViewLegendContainerForwardRef}
            heightOffset={heightOffset}
          >
            <ViewLegend
              componentTypes={getComponentTypesForLegend(
                data.map(data => data.cid)
              )}
              activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
              additionalRows={getBubbleLegendRow(
                selectedFieldNameRadius,
                data,
                domain.radius,
                scalers.radius,
                scalers.unscaleRadius,
                showBubblesWithZeroValue
              )}
              activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
            />
          </ZoomableViewLegendContainerForwardRef>
        )}
      </HeightOffset>
    </BubbleChartContainer>
  );
};

export default panAndZoom(BubbleChart);
