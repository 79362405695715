import { Node } from './node';
import type { Edge } from './types';
import GraphCollection from './GraphCollection';

interface NodeAndEdgeSetArgs {
  nodeSet: Set<Node>;
  edgeSet: Set<Edge>;
}
interface GraphSubsetArgs extends NodeAndEdgeSetArgs {
  node: Node;
  maxDegreesIncoming?: number;
  maxDegreesOutgoing?: number;
  getAggregated?: boolean;
  includeParents?: boolean;
  traverseLog?: Map<string, { maxIncoming: number; maxOutgoing: number }>;
}
interface EnsureAllEdgesAreIncludedArgs extends NodeAndEdgeSetArgs {
  allEdges: GraphCollection<Edge>;
  getAggregated?: boolean;
}
interface GetSingleDirectionGraphSubsetArgs
  extends Omit<GraphSubsetArgs, 'maxDegreesIncoming' | 'maxDegreesOutgoing'> {
  maxDegrees: number;
}
interface BreadthFirstEdgeSearchArgs extends GraphSubsetArgs {
  incoming?: boolean;
  outgoing?: boolean;
}

const DEFAULT_TRAVERSE_LOG = { maxIncoming: 0, maxOutgoing: 0 };
const addParents = (node: Node, nodeSet: Set<Node>) => {
  if (!node.parent || nodeSet.has(node.parent)) {
    return;
  }
  nodeSet.add(node.parent);
  addParents(node.parent, nodeSet);
};

const compareOrder = (a: Edge, b: Edge) =>
  -(a.dataModel.get('order') - b.dataModel.get('order'));

const stepOutwards = ({
  node,
  nodeSet,
  edgeSet,
  maxDegreesIncoming,
  maxDegreesOutgoing,
  getAggregated,
  includeParents,
  traverseLog,
}: Required<GraphSubsetArgs>) => {
  const queue: Node[] = [];

  const { id } = node;
  const { maxIncoming, maxOutgoing } =
    traverseLog.get(id) || DEFAULT_TRAVERSE_LOG;
  if (
    traverseLog.has(id) &&
    maxDegreesIncoming <= maxIncoming &&
    maxDegreesOutgoing <= maxOutgoing
  ) {
    return queue;
  }
  traverseLog.set(id, {
    maxIncoming: Math.max(maxIncoming, maxDegreesIncoming),
    maxOutgoing: Math.max(maxOutgoing, maxDegreesOutgoing),
  });

  if (includeParents) {
    addParents(node, nodeSet);
  }

  nodeSet.add(node);

  if (maxDegreesIncoming > 0) {
    const incomingEdges = getAggregated
      ? node.getAggregatedIncomingEdges()
      : node.getIncomingEdges();

    incomingEdges.sort(compareOrder).forEach(edge => {
      if (edgeSet.has(edge)) {
        return;
      }
      const source =
        getAggregated && edge.aggregatedSourceNode
          ? edge.aggregatedSourceNode
          : edge.sourceNode;

      edgeSet.add(edge);
      if (!nodeSet.has(source)) {
        queue.push(source);
      }
    });
  }

  if (maxDegreesOutgoing > 0) {
    const outgoingEdges = getAggregated
      ? node.getAggregatedOutgoingEdges()
      : node.getOutgoingEdges();

    outgoingEdges.sort(compareOrder).forEach(edge => {
      if (edgeSet.has(edge)) {
        return;
      }
      const target =
        getAggregated && edge.aggregatedTargetNode
          ? edge.aggregatedTargetNode
          : edge.targetNode;

      edgeSet.add(edge);
      if (!nodeSet.has(target)) {
        queue.push(target);
      }
    });
  }

  return queue;
};
const breadthFirstEdgeSearch = ({
  node,
  nodeSet,
  edgeSet,
  maxDegreesIncoming = 0,
  maxDegreesOutgoing = 0,
  getAggregated = false,
  includeParents = false,
  traverseLog = new Map(),
}: BreadthFirstEdgeSearchArgs) => {
  let queue = [node];
  let currentMaxDegreesIncoming = maxDegreesIncoming;
  let currentMaxDegreesOutgoing = maxDegreesOutgoing;
  while (queue.length > 0) {
    queue = queue.flatMap(node =>
      stepOutwards({
        node,
        nodeSet,
        edgeSet,
        maxDegreesIncoming: currentMaxDegreesIncoming,
        maxDegreesOutgoing: currentMaxDegreesOutgoing,
        getAggregated,
        includeParents,
        traverseLog,
      })
    );
    currentMaxDegreesIncoming -= 1;
    currentMaxDegreesOutgoing -= 1;
  }
};

export default {
  ensureAllEdgesAreIncluded: ({
    nodeSet,
    edgeSet,
    allEdges,
    getAggregated = false,
  }: EnsureAllEdgesAreIncludedArgs) =>
    // making sure all between added components actually are included
    allEdges.forEach(edge => {
      const source =
        getAggregated && edge.aggregatedSourceNode
          ? edge.aggregatedSourceNode
          : edge.sourceNode;
      const target =
        getAggregated && edge.aggregatedTargetNode
          ? edge.aggregatedTargetNode
          : edge.targetNode;

      if (nodeSet.has(source) && nodeSet.has(target) && !edgeSet.has(edge)) {
        edgeSet.add(edge);
      }
    }),
  getGraphSubset: ({
    node,
    nodeSet,
    edgeSet,
    maxDegreesIncoming,
    maxDegreesOutgoing,
    getAggregated = false,
    includeParents = false,
    traverseLog = new Map(),
  }: GraphSubsetArgs &
    Required<
      Pick<GraphSubsetArgs, 'maxDegreesIncoming' | 'maxDegreesOutgoing'>
    >) =>
    breadthFirstEdgeSearch({
      node,
      nodeSet,
      edgeSet,
      maxDegreesIncoming,
      maxDegreesOutgoing,
      includeParents,
      getAggregated,
      traverseLog,
    }),
  getIncomingGraphSubset: ({
    node,
    nodeSet,
    edgeSet,
    maxDegrees,
    getAggregated = false,
    includeParents = false,
    traverseLog = new Map(),
  }: GetSingleDirectionGraphSubsetArgs) =>
    breadthFirstEdgeSearch({
      node,
      nodeSet,
      edgeSet,
      maxDegreesIncoming: maxDegrees,
      includeParents,
      getAggregated,
      traverseLog,
    }),
  getOutgoingGraphSubset: ({
    node,
    nodeSet,
    edgeSet,
    maxDegrees,
    getAggregated = false,
    includeParents = false,
    traverseLog = new Map(),
  }: GetSingleDirectionGraphSubsetArgs) =>
    breadthFirstEdgeSearch({
      node,
      nodeSet,
      edgeSet,
      maxDegreesOutgoing: maxDegrees,
      includeParents,
      getAggregated,
      traverseLog,
    }),
};
