import { actionCreator } from '@ardoq/rxbeach';
import { CssEntry } from './types';

type AddToStyleSheetPayload = Record<string, CssEntry[]>;

export const addToStyleSheet = actionCreator<AddToStyleSheetPayload>(
  '[modelCssManager] ADD_TO_STYLESHEET'
);
export const addToTempStyleSheet = actionCreator<AddToStyleSheetPayload>(
  '[modelCssManager] ADD_TEMP_TO_STYLESHEET'
);
interface ClearStylesPayload {
  keys: string[];
}
export const clearStyles = actionCreator<ClearStylesPayload>(
  '[modelCssManager] CLEAR_STYLES'
);

export const clearTempStyles = actionCreator(
  '[modelCssManager] Clear temp styles'
);
