import { DiffMode, ViewIds } from '@ardoq/api-types';
import { useEffectOnce } from '@ardoq/hooks';
import {
  ComponentProps,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  graphViewLegendSetSelectedComponentTypes,
  graphViewLegendSetSelectedConditionalFormatting,
  graphViewLegendSetSelectedReferenceTypes,
} from 'tabview/graphViews/graphViewLegend/actions';
import GraphViewLegend from 'tabview/graphViews/graphViewLegend/GraphViewLegend';
import { ZoomableViewLegendContainer } from 'tabview/relationshipDiagrams/atoms';
import { trackInteractiveLegendItemClick } from 'views/viewLegend/tracking';

type RelationshipsViewLegendProps = ComponentProps<typeof GraphViewLegend> & {
  initiallyVisible: boolean;
  heightOffset?: number;
  activeDiffMode: DiffMode | null;
};
interface RelationshipsViewLegendRef {
  setIsVisible: (isVisible: boolean) => void;
  getIsVisible: () => boolean;
}
const RelationshipsViewLegend = forwardRef<
  RelationshipsViewLegendRef,
  RelationshipsViewLegendProps
>((props, ref) => {
  const [isVisible, setIsVisible] = useState(props.initiallyVisible);
  useImperativeHandle(ref, () => ({
    setIsVisible,
    getIsVisible: () => isVisible,
  }));
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<
    string[]
  >([]);
  const [selectedReferenceTypes, setSelectedReferenceTypes] = useState<
    string[]
  >([]);
  const [
    selectedConditionalFormattingIds,
    setSelectedConditionalFormattingIds,
  ] = useState<string[]>([]);
  useEffectOnce(() => {
    const subscriptions = [
      subscribeToAction(
        graphViewLegendSetSelectedComponentTypes,
        ({ componentTypes }) =>
          setSelectedComponentTypes(
            componentTypes.map(({ ids }) => ids.join(','))
          )
      ),
      subscribeToAction(
        graphViewLegendSetSelectedReferenceTypes,
        ({ referenceTypes }) =>
          setSelectedReferenceTypes(
            referenceTypes.flatMap(({ globalTypeIds }) =>
              globalTypeIds.join(',')
            )
          )
      ),
      subscribeToAction(
        graphViewLegendSetSelectedConditionalFormatting,
        ({ ids }) => setSelectedConditionalFormattingIds(ids)
      ),
    ];
    return () => {
      clearSubscriptions(subscriptions);
    };
  });

  const {
    componentTypes: initialComponentTypes,
    referenceTypes: initialReferenceTypes,
    ...originalProps
  } = props;
  const selectedComponentTypeIds = new Set(selectedComponentTypes);
  const selectedReferenceTypeIds = new Set(selectedReferenceTypes);
  const componentTypes = initialComponentTypes.map(componentType => ({
    ...componentType,
    isSelected: selectedComponentTypeIds.has(componentType.ids.join(',')),
  }));

  const referenceTypes = initialReferenceTypes.map(referenceType => ({
    ...referenceType,
    isSelected: selectedReferenceTypeIds.has(
      referenceType.globalTypeIds.join(',')
    ),
  }));
  const itemClickTrackingFunction = (legendRowLabel: string) => {
    trackInteractiveLegendItemClick({
      viewId: ViewIds.RELATIONSHIPS_3,
      legendRow: legendRowLabel,
    });
  };
  return (
    <ZoomableViewLegendContainer
      heightOffset={props.heightOffset || 0}
      visible={isVisible}
    >
      <GraphViewLegend
        {...originalProps}
        componentTypes={componentTypes}
        referenceTypes={referenceTypes}
        selectedConditionalFormattingIds={selectedConditionalFormattingIds}
        itemClickTrackingFunction={itemClickTrackingFunction}
      />
    </ZoomableViewLegendContainer>
  );
});
export default RelationshipsViewLegend;
