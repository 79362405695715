import { ArdoqId } from '@ardoq/api-types';

type ComposeComponentsIdsArgs = {
  hierarchy: Record<ArdoqId, { isFilteredOut: boolean; children?: ArdoqId[] }>;
  children: ArdoqId[];
  limit?: number;
};

export const composeComponentsIds = ({
  hierarchy,
  children,
  limit = Infinity,
}: ComposeComponentsIdsArgs): ArdoqId[] => {
  let remainingLimit = limit;

  return children.reduce<ArdoqId[]>((acc, id) => {
    const { isFilteredOut, children: itemChildren = [] } = hierarchy[id];

    if (remainingLimit <= 0) {
      return acc;
    }

    const hasChildren = Boolean(itemChildren && itemChildren.length > 0);

    if (!isFilteredOut) {
      acc.push(id);
    }
    remainingLimit--;

    if (hasChildren) {
      const childIds = composeComponentsIds({
        hierarchy,
        children: itemChildren,
        limit: remainingLimit,
      });

      for (const childId of childIds) {
        if (remainingLimit <= 0) {
          break;
        }

        acc.push(childId);
        remainingLimit--;
      }
    }

    return acc;
  }, []);
};
