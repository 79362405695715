import { TextRenderSupport } from '@ardoq/yfiles';
import { HORIZONTAL_ELLIPSIS } from '@ardoq/global-consts';

type TextRenderSupportAddTextArgs = Parameters<
  typeof TextRenderSupport.addText
>;

/**
 * Removes the last two characters of the last line of a multiline text and adds an ellipsis.
 */
const truncateLastLine = (text: string) => {
  const lines = text.split('\n');
  const lastLineIndex = !lines[lines.length - 1].trim() ? 2 : 1;
  const lastLine = lines[lines.length - lastLineIndex];
  const truncatedLastLine =
    lastLine.trimEnd().slice(0, -2) + HORIZONTAL_ELLIPSIS;
  return [
    ...lines.slice(0, lines.length - lastLineIndex),
    truncatedLastLine,
  ].join('\n');
};

// TextRenderSupport.addText can modify the string formatting and spaces,
// therefore removing spaces, tabs, and newlines before string comparison
const removeFormattingAndSpaces = (text: string) =>
  text.replace(/[\r\n\t\s]+/g, '');

/**
 * Adds text to a label element using the `TextRenderSupport.addText` method.
 * Fixes issue where an ellipsis is not added at the end of the text
 * if multiline text is truncated and the last visible line fits completely.
 * This version ensures proper text overflow handling when the original method does not add the ellipsis.
 */
const addText = (
  ...args: TextRenderSupportAddTextArgs
): ReturnType<typeof TextRenderSupport.addText> => {
  const passedText = args[0].text;
  const labelText = TextRenderSupport.addText(...args);

  // TextRenderSupport.addText does not add an ellipsis at the end of the text
  // if the last visible line fits completely, even if the next lines were truncated.
  // This check ensures an ellipsis is added if the text is truncated, even if the last line fits.
  if (
    passedText.includes('\n') &&
    removeFormattingAndSpaces(labelText) !==
      removeFormattingAndSpaces(passedText) &&
    !labelText.endsWith(HORIZONTAL_ELLIPSIS)
  ) {
    const labelTextWithEllipsis = `${labelText}${HORIZONTAL_ELLIPSIS}`;
    const { font, wrapping, maximumSize, measurePolicy } = args[0];

    // Measure the text with the ellipsis to check if it fits
    const { width: newWidth, height: newHeight } =
      TextRenderSupport.measureText({
        text: labelTextWithEllipsis,
        font,
        wrapping,
        measurePolicy,
      });
    const { width: initialWidth, height: initialHeight } =
      TextRenderSupport.measureText({
        text: passedText,
        font,
        wrapping,
        maximumSize,
        measurePolicy,
      });

    const isLabelFits = newWidth <= initialWidth && newHeight <= initialHeight;

    return TextRenderSupport.addText({
      ...args[0],
      text: isLabelFits ? labelTextWithEllipsis : truncateLastLine(labelText),
    });
  }

  return labelText;
};

export default addText;
