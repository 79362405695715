import {
  Font,
  FontWeight,
  ILabel,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  TextRenderSupport,
  TextWrapping,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { Node } from 'graph/node';
import { CompElementExtension } from 'aqTypes';
import {
  POPOVER_WRAPPER_CLASSNAME,
  POPOVER_WRAPPER_PADDING,
  POPOVER_Z_INDEX,
} from '@ardoq/popovers';
import { s8, shadowM, colors } from '@ardoq/design-tokens';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { createSvgElement } from '@ardoq/dom-utils';

const HORIZONTAL_INSET = 25;
const VERTICAL_INSET = 15;

const HEADER_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 32,
  fontWeight: FontWeight.ITEM600,
});

const INNER_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 26,
  fontWeight: FontWeight.ITEM400,
});

const getFont = (label: ILabel) => {
  return !label.tag ? HEADER_FONT : INNER_FONT;
};

const renderSwimlaneLabel = (
  container: SVGGElement,
  label: ILabel,
  font: Font
) => {
  const { text, width, height } = getText(label, font);

  const translateX = (label.layout.width - width) * 0.5;
  const translateY = (label.layout.height - height) * 0.5;
  text.setAttribute('transform', `translate(${translateX} ${translateY})`);
  text.setAttribute('fill', 'black');

  const isComponent = label.owner?.tag instanceof Node;
  if (isComponent) {
    (text as SVGTextElement & CompElementExtension).comp =
      label.owner.tag.dataModel;
  }
  container.appendChild(text);
  addListenersForText(text, label);
};

export class ArdoqSwimlaneLabelStyle extends LabelStyleBase {
  override createVisual(context: IRenderContext, label: ILabel) {
    const g = createSvgElement('g');

    renderSwimlaneLabel(g, label, getFont(label));

    const transform = LabelStyleBase.createLayoutTransform(
      context,
      label.layout,
      true
    );
    transform.applyTo(g);
    return new SvgVisual(g);
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    return this.createVisual(context, label);
  }

  override getPreferredSize(label: ILabel) {
    const { width, height } = getText(label, getFont(label));
    return new Size(width, height);
  }
}

const createPopover = () => {
  const popover = document.createElement('div');
  popover.style.position = 'absolute';
  popover.style.display = 'none';
  popover.style.padding = `${POPOVER_WRAPPER_PADDING}px`;
  popover.style.background = colors.white;
  popover.style.boxShadow = shadowM;
  popover.style.borderRadius = s8;
  popover.style.minWidth = '120px';
  popover.style.maxWidth = '272px';
  popover.style.whiteSpace = 'pre-wrap';
  popover.style.overflowWrap = 'break-word';

  popover.classList.add(POPOVER_WRAPPER_CLASSNAME);
  popover.id = 'dynamic-popover';
  document.body.appendChild(popover);

  return popover;
};

const showPopover = (
  event: MouseEvent,
  popover: HTMLElement,
  label: ILabel
) => {
  popover.style.display = 'block';
  popover.style.left = `${event.pageX}px`;
  popover.style.top = `${event.pageY + 10}px`;
  popover.style.zIndex = `${POPOVER_Z_INDEX}`;
  popover.textContent = label.text;
};

const addListenersForText = (text: SVGTextElement, label: ILabel) => {
  const popover = document.getElementById('dynamic-popover') ?? createPopover();

  text.addEventListener('mouseenter', event => {
    showPopover(event, popover!, label);
  });

  text.addEventListener('mouseleave', () => {
    popover!.style.display = 'none';
  });
};

const getText = (label: ILabel, font: Font) => {
  const text = createSvgElement('text');
  font.applyTo(text);

  const textBounds = new Size(
    label.layout.width - HORIZONTAL_INSET * 2,
    label.layout.height - VERTICAL_INSET * 2
  );

  const renderedText = addText({
    targetElement: text,
    text: String(label.text),
    font,
    maximumSize: textBounds,
    wrapping: TextWrapping.WORD_ELLIPSIS,
  });

  const { width, height } = TextRenderSupport.measureText(renderedText, font);

  return { text, width, height };
};
