import { ViewIds } from '@ardoq/api-types';
import { TableviewWrapper, setup } from './common/tableviewWrapper';
import { createViewModel$ } from './componentReducers';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { TableViewProps } from './common/types';

const ComponentTableView = ({ viewState, viewModel }: TableViewProps) => (
  <TableviewWrapper
    viewId={ViewIds.TABLEVIEW}
    name="Tableview"
    sortable
    viewState={viewState}
    viewModel={viewModel}
    knowledgeBaseLink={KnowledgeBaseLink.TABLES}
  />
);

export default setup(ViewIds.TABLEVIEW, ComponentTableView, createViewModel$);
