import { SearchParams } from '@ardoq/api';
import {
  BooleanOperator,
  SortOrder,
  AdvancedSearchResult,
  UnknownDocumentType,
  AdvancedSearchField,
  ArdoqId,
  QueryBuilderQuery,
  QueryBuilderSubquery,
  ZonesBySubdivisionsIds,
} from '@ardoq/api-types';
import { SearchContext } from '@ardoq/query-builder';
import { MappedAdvancedSearchResult } from 'search/resultUtils';

export enum AdvancedSearchError {
  QUERY = 'query',
  EXPORT = 'export',
}

export type AdvancedSearchStateShape = {
  selectedFilterType: SearchContext;
  selectedCondition: BooleanOperator;
  queryBuilderRules: QueryBuilderSubquery | null;
  results: MappedAdvancedSearchResult<UnknownDocumentType>[];
  total: number;
  sortOrder?: SortOrder;
  sortBy?: string;
  selectedFieldNames: string[];
  selectedPage: number;
  fields: AdvancedSearchField[];
  searchError: AdvancedSearchError | null;
  isExporting: boolean;
  showResults: boolean;
};

export type BranchedAdvancedSearchStateShape = AdvancedSearchStateShape & {
  branchId: string | null;
};

export type AdvancedSearchShape = AdvancedSearchStateShape & {
  ruleErrorMessages: string[];
};

export type PayloadSelectCondition = {
  readonly condition: BooleanOperator;
};

export type PayloadSelectFilterType = {
  readonly filterType: SearchContext;
};

export type PayloadSelectPage = {
  readonly pageNumber: number;
};

export type PayloadSetSort = {
  readonly sortBy?: string;
  readonly sortOrder?: SortOrder;
};

export type PayloadSelectFields = {
  readonly selectedFieldNames: string[];
};

export type PayloadQueryAdvancedSearch = {
  readonly queryBuilderRules: QueryBuilderSubquery | null;
} & SearchParams;

export type PayloadQueryAdvancedSearchSuccess = {
  readonly total: number;
  readonly results: AdvancedSearchResult<
    UnknownDocumentType & {
      subdivisionMembership?: ArdoqId[];
    }
  >[];
  readonly fields: AdvancedSearchField[];
};

export type PayloadUpdateAdvancedSearch = {
  readonly queryBuilderRules: QueryBuilderSubquery | null;
};

export type PayloadAdvancedSearchError = {
  readonly searchError: AdvancedSearchError;
};

export type PayloadExportAdvancedSearch = {
  readonly queryBuilderRules: QueryBuilderQuery;
  readonly filename: string;
  readonly branchId: string | null;
};

export type PayloadAdvancedSearchSetBranch = string | null;

export type LabelAndValue = { label: string; value: string };

export type FetchSuggestionFn = (name: string) => Promise<Array<LabelAndValue>>;

export type ExecuteAdvancedSearchContext = {
  searchParams: SearchParams;
  query: QueryBuilderQuery;
};

export type GetSuggestionsParams = {
  readonly workspaceIds?: ArdoqId[] | null;
  readonly subdivisions?: ZonesBySubdivisionsIds;
};
