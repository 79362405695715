import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  MetamodelComponentTypeContext,
  MetamodelContextSettings,
  MetamodelFieldContext,
  MetamodelReferenceCompletenessContext,
  MetamodelReferenceTypeContext,
} from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import {
  getAggregatedPercentageCompletion,
  getPercentageCompletion,
} from './utils';
import { FieldCompletion } from './completion/types';
import CompletionBarHeader from './completion/completionBar/CompletionBarHeader';
import CompletionBar from './completion/completionBar/CompletionBar';
import { trackClickReferenceCompletionInMetamodelSidebar } from '../tracking';
import { dispatchAction } from '@ardoq/rxbeach';
import WorkspaceNavigationTitle from './workspaceNavigationTitle/WorkspaceNavigationTitle';
import { setMetamodelContext } from 'metamodel/navigation/actions';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { InlineText, Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

export const BreadCrumbItem = styled.span<{ $isReference: boolean }>`
  cursor: pointer;
  ${({ $isReference }) => $isReference && 'font-style: italic'}
`;

export const MetamodelSubtitle = ({
  componentCompletion,
  referenceCompletion,
}: {
  componentCompletion: FieldCompletion[];
  referenceCompletion: FieldCompletion[];
}) => (
  <Paragraph>
    {getAggregatedPercentageCompletion(
      componentCompletion,
      referenceCompletion
    )}{' '}
    of this metamodel&apos;s fields are filled out
  </Paragraph>
);

export const AssetFolderSubtitle = ({
  componentCompletion,
  referenceCompletion,
}: {
  componentCompletion: FieldCompletion[];
  referenceCompletion: FieldCompletion[];
}) => (
  <Paragraph>
    {getAggregatedPercentageCompletion(
      componentCompletion,
      referenceCompletion
    )}{' '}
    of fields in this workspace folder are filled out
  </Paragraph>
);

export const WorkspaceSubtitle = ({
  componentCompletion,
  referenceCompletion,
}: {
  componentCompletion: FieldCompletion[];
  referenceCompletion: FieldCompletion[];
}) => (
  <Paragraph>
    {getAggregatedPercentageCompletion(
      componentCompletion,
      referenceCompletion
    )}{' '}
    of fields in this workspace are filled out
  </Paragraph>
);

export const ComponentTypeSubtitle = ({
  fieldCompletion,
}: {
  fieldCompletion: FieldCompletion[];
}) => (
  <Paragraph>
    {fieldCompletion.length ? (
      <>
        {getAggregatedPercentageCompletion(fieldCompletion)} of fields on this
        component type are filled out
      </>
    ) : (
      "This component type doesn't have custom fields"
    )}
  </Paragraph>
);

interface ReferenceCompletionData {
  label: ReactNode;
  connectedComponents: number;
  totalComponents: number;
  context: MetamodelReferenceCompletenessContext & MetamodelContextSettings;
}

const ReferenceCompletionBar = ({
  label,
  connectedComponents,
  totalComponents,
  context,
}: ReferenceCompletionData) => {
  const ratio = connectedComponents / totalComponents;
  const onClick = context.settings.isolatedNavigation
    ? undefined
    : () => {
        trackClickReferenceCompletionInMetamodelSidebar();
        dispatchAction(setMetamodelContext(context));
      };
  return (
    <CompletionBar
      name={label}
      completeness={ratio * 100}
      label={`${Math.floor(ratio * 100)}%`}
      hoverLabel={`${connectedComponents}/${totalComponents}`}
      onClick={onClick}
    />
  );
};

interface ReferenceCompletionLabelProps {
  componentTypeName: string;
  referenceTypeName: string;
  otherComponentTypeIsSource: boolean;
}
const ReferenceCompletionLabel = ({
  componentTypeName,
  referenceTypeName,
  otherComponentTypeIsSource,
}: ReferenceCompletionLabelProps) => (
  <>
    {!otherComponentTypeIsSource && (
      <>
        <InlineText variant="text2">{referenceTypeName}</InlineText> ›{' '}
      </>
    )}
    {componentTypeName}
    {otherComponentTypeIsSource && (
      <>
        {' '}
        › <InlineText variant="text2">{referenceTypeName}</InlineText>
      </>
    )}
  </>
);

/**
 * Take a reference type id and an enum indicating which component to base
 * calculation on (source or target), and return the data necessary to show
 * reference completion
 *
 * Need to explicitly declare the source/target, because using component type id
 * as a base can cause issues if a reference points from and to the same
 * component.
 *
 * @param metamodel
 * @param context
 * @param referenceTypeId
 * @param sourceOrTarget
 */
const completionBarData = (
  metamodel: Metamodel,
  context: (MetamodelComponentTypeContext | MetamodelReferenceTypeContext) &
    MetamodelContextSettings,
  referenceTypeId: string,
  otherComponentTypeIsSource: boolean
): ReferenceCompletionData & {
  id: string;
  sortString: string;
} => {
  const { componentTypes, referenceTypes } = metamodel.metamodel;
  const referenceType = referenceTypes[referenceTypeId];

  const { source: sourceId, target: targetId, metadata } = referenceType;

  const shouldSwitchComponentContext =
    context.type !== SimpleBlockDiagramContentType.COMPONENT;

  const shouldNavigateToSourceContext = shouldSwitchComponentContext
    ? otherComponentTypeIsSource
    : !otherComponentTypeIsSource;

  const sourceComponentType = componentTypes[sourceId];
  const targetComponentType = componentTypes[targetId];

  const newContextComponentId = shouldNavigateToSourceContext
    ? sourceComponentType.id
    : targetComponentType.id;

  const { referenceCompleteness } = metadata;
  const completeness = shouldNavigateToSourceContext
    ? referenceCompleteness.source
    : referenceCompleteness.target;

  const otherComponentType = otherComponentTypeIsSource
    ? sourceComponentType
    : targetComponentType;

  const sortString = referenceType.name + otherComponentType.name;
  const completionBarLabel = (
    <ReferenceCompletionLabel
      componentTypeName={otherComponentType.name}
      referenceTypeName={referenceType.name}
      otherComponentTypeIsSource={otherComponentTypeIsSource}
    />
  );

  return {
    sortString: sortString,
    id: referenceTypeId,
    label: completionBarLabel,
    connectedComponents: completeness.connectedComponents,
    totalComponents: completeness.totalComponents,
    context: {
      settings: context.settings,
      type: SimpleBlockDiagramContentType.REFERENCE_COMPLETENESS,
      contextComponentTypeIsSource: shouldNavigateToSourceContext,
      componentId: newContextComponentId,
      ...referenceType,
    },
  };
};

const sortCompletionData = (
  a: { sortString: string },
  b: { sortString: string }
) => localeCompare(a.sortString, b.sortString, getCurrentLocale());

const RenderCompletion = (
  completionData: ReferenceCompletionData & { id: string }
) => <ReferenceCompletionBar key={completionData.id} {...completionData} />;

export const ReferenceCompletionForComponent = ({
  metamodel,
  context,
}: {
  metamodel: Metamodel;
  context: MetamodelComponentTypeContext & MetamodelContextSettings;
}) => {
  const componentMetatypeId = context.id;
  const referenceTypes = Object.values(metamodel.metamodel.referenceTypes);
  const incomingReferenceCompletionData = referenceTypes
    .filter(referenceType => referenceType.target === componentMetatypeId)
    .map(referenceType =>
      completionBarData(metamodel, context, referenceType.id, true)
    )
    .sort(sortCompletionData);
  const outgoingReferenceCompletionData = referenceTypes
    .filter(referenceType => referenceType.source === componentMetatypeId)
    .map(referenceType =>
      completionBarData(metamodel, context, referenceType.id, false)
    )
    .sort(sortCompletionData);

  return (
    <Box marginTop="xlarge">
      {incomingReferenceCompletionData.length ||
      outgoingReferenceCompletionData.length ? (
        <Paragraph color="textModerate">Reference completion</Paragraph>
      ) : (
        <Paragraph>This component type doesn&apos;t have references</Paragraph>
      )}
      {Boolean(incomingReferenceCompletionData.length) && (
        <Box marginTop="small">
          <CompletionBarHeader
            left="Incoming references"
            right="% of components with reference from"
          />
          {incomingReferenceCompletionData.map(RenderCompletion)}
        </Box>
      )}
      {incomingReferenceCompletionData.length &&
      outgoingReferenceCompletionData.length ? (
        <br />
      ) : null}
      {Boolean(outgoingReferenceCompletionData.length) && (
        <Box marginTop="small">
          <CompletionBarHeader
            left="Outgoing references"
            right="% of components with reference to"
          />
          {outgoingReferenceCompletionData.map(RenderCompletion)}
        </Box>
      )}
    </Box>
  );
};

export const ReferenceTypeTitle = ({
  metamodel,
  context,
}: {
  metamodel: Metamodel;
  context: MetamodelReferenceTypeContext & MetamodelContextSettings;
}) => {
  const { componentTypes } = metamodel.metamodel;
  return (
    <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
      {context.target ? (
        <>
          {componentTypes[context.source].name} › {context.name} ›{' '}
          {componentTypes[context.target].name}
        </>
      ) : (
        context.name
      )}
    </WorkspaceNavigationTitle>
  );
};

export const ReferenceTypeSubtitle = ({
  fieldCompletion,
}: {
  fieldCompletion: FieldCompletion[];
}) => (
  <Paragraph>
    {fieldCompletion.length ? (
      <>
        {getAggregatedPercentageCompletion(fieldCompletion)} of fields on this
        reference type are filled out
      </>
    ) : (
      "This reference type doesn't have custom fields"
    )}
  </Paragraph>
);

export const ReferenceCompletionForReference = ({
  metamodel,
  context,
}: {
  metamodel: Metamodel;
  context: MetamodelReferenceTypeContext & MetamodelContextSettings;
}) => {
  const sourceCompletionData = completionBarData(
    metamodel,
    context,
    context.id,
    true
  );
  const targetCompletionData = completionBarData(
    metamodel,
    context,
    context.id,
    false
  );
  return (
    <Box marginTop="xlarge">
      <Paragraph color="textModerate">Reference completion</Paragraph>
      <CompletionBarHeader
        left="Component type"
        right="% of components with reference"
      />
      <ReferenceCompletionBar {...sourceCompletionData} />
      <ReferenceCompletionBar {...targetCompletionData} />
    </Box>
  );
};

export const FieldSubtitle = ({
  context,
}: {
  context: MetamodelFieldContext;
}) => {
  if (context.type === SimpleBlockDiagramContentType.COMPONENT) {
    return (
      <Paragraph>
        {getPercentageCompletion(context)} of components with this field have
        the field filled out
      </Paragraph>
    );
  } else if (context.type === SimpleBlockDiagramContentType.REFERENCE) {
    return (
      <Paragraph>
        {getPercentageCompletion(context)} of references with this field have
        the field filled out
      </Paragraph>
    );
  }
  return null;
};
