import { CompElementExtension } from 'aqTypes';
import Components from 'collections/components';
import { findAncestorWithProperty } from './domUtils';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';

const getComponentsFromElement = (target: Element) => {
  const elementWithComp = findAncestorWithProperty(target, 'comp') as Element &
    CompElementExtension;
  if (elementWithComp) {
    return [elementWithComp.comp];
  }

  const componentDataset = target.closest<HTMLElement>(
    `[data-global-handler-id].component, [${COMPONENT_ID_ATTRIBUTE}].component`
  )?.dataset;

  const componentId =
    componentDataset?.globalHandlerId || componentDataset?.componentId;

  const component = componentId && Components.collection.get(componentId);
  return component ? [component] : [];
};
export default getComponentsFromElement;
