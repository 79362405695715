import { BlocksViewLink, BlocksViewNode } from '../types';

/** an array of links makes a path. */
type BlocksViewPath = BlocksViewLink[];

const isExplorable = (link: BlocksViewLink) => link.globalTypeId;

/** find all paths from src to tgt */
export const findAllPaths = (
  src: BlocksViewNode,
  tgt: BlocksViewNode,
  maxLength: number = Number.POSITIVE_INFINITY
) => {
  /* this is a potentially explosively slow algorithm but will definitely terminate */
  const nodes = new Set<BlocksViewNode>();
  const path: BlocksViewLink[] = [];
  const ret: BlocksViewPath[] = [];

  const doit = (node: BlocksViewNode) => {
    if (path.length > maxLength) {
      return;
    }

    if (node === tgt) {
      ret.push([...path]);
      return;
    }

    node.links?.forEach(link => {
      if (
        link.source === node &&
        isExplorable(link) &&
        !nodes.has(link.target)
      ) {
        nodes.add(link.target);
        path.push(link);

        doit(link.target);

        nodes.delete(link.target);
        path.pop();
      }
    });
  };

  nodes.add(src);
  doit(src);

  return ret;
};

export type Linked = {
  nodes: Set<BlocksViewNode>;
  links: Set<BlocksViewLink>;
};
export const getLinked = (node: BlocksViewNode): Linked => {
  // blocks diagram does this funky lowlight effect where it displays
  // everything except the hover node and all linked nodes and associated links as
  // lowlit
  //
  // this is ok, I guess, but it's only really one of the possible "follow the links"
  // style things to do (another one is to not do anything)

  const nodes = new Set<BlocksViewNode>();
  const links = new Set<BlocksViewLink>();

  const explore = (node: BlocksViewNode) => {
    if (nodes.has(node)) {
      return;
    }

    nodes.add(node);

    for (const link of node.links ?? []) {
      if (!links.has(link)) {
        links.add(link);

        explore(node === link.target ? link.source : link.target);
      }
    }
  };

  explore(node);

  return {
    nodes: nodes,
    links: links,
  };
};
