import { derivedStream } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { availableViews$ } from 'views/availableViews$';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import workspaces$ from 'streams/workspaces/workspaces$';
import { isEqual } from 'lodash';
import { MetaInfo } from 'streams/views/mainContent/types';
import { startWith } from 'rxjs/operators';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { Tab } from './types';
import { metaInfoListToTabs } from './tabUtils';
import { scopeDiff$ } from 'scope/scopeDiff$';
import { replaceViewIfNotEligible } from 'viewDeprecation/restrictedViews';

const defaultViews = [ViewIds.PAGESVIEW];

const startTabs: Tab[] = [];

const defaultState = {
  tabs: startTabs,
  startView: ViewIds.NONE,
  activeWorkspaceId: '',
  metaInfoList: [],
  selectedMetaInfoList: [],
  savedViews: [],
  isScopeDiffMode: false,
};

const tabOptions$ = derivedStream(
  'tabOptions$',
  availableViews$,
  context$,
  workspaces$,
  scopeDiff$
).pipe(
  map(
    ([
      { views: metaInfoList },
      { workspaceId, presentationId },
      { byId },
      { scopeDiff },
    ]) => {
      if (metaInfoList.length === 0 || !workspaceId) {
        return defaultState;
      }
      // The order of the tabs should follow the order of the views hardcoded in
      // metaInfoTabs.
      const savedViews = (byId[workspaceId]?.views ?? []) as ViewIds[];
      const selectedViews = new Set([...savedViews, ...defaultViews]);
      // If the presentation editor is open the active view is managed
      // by that editor. Trying to set it here would interfere with that.
      const currentStartView = presentationId
        ? ViewIds.NONE
        : byId[workspaceId]?.startView;
      const conformStartView = replaceViewIfNotEligible(currentStartView);
      const isScopeDiffMode = Boolean(scopeDiff);
      return {
        selectedMetaInfoList: metaInfoList.filter(({ id }) =>
          selectedViews.has(id)
        ),
        startView: conformStartView ?? ViewIds.NONE,
        activeWorkspaceId: workspaceId,
        metaInfoList,
        savedViews,
        isScopeDiffMode,
      };
    }
  ),
  distinctUntilChanged<{
    metaInfoList: MetaInfo[];
    selectedMetaInfoList: MetaInfo[];
    startView: ViewIds;
    activeWorkspaceId: ArdoqId;
    savedViews: ViewIds[];
    isScopeDiffMode: boolean;
  }>(isEqual),
  map(
    ({
      selectedMetaInfoList,
      startView,
      activeWorkspaceId,
      metaInfoList,
      savedViews,
    }) => ({
      tabs: metaInfoListToTabs(selectedMetaInfoList),
      startView,
      activeWorkspaceId,
      metaInfoList,
      savedViews,
    })
  ),
  startWith(defaultState),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default tabOptions$;
