import {
  APIEntityType,
  LabeledValue,
  WorkspaceDigestChange,
} from '@ardoq/api-types';
import { getCurrentLocale } from '@ardoq/locale';
import { Column } from '@ardoq/table';
import { dispatchAction } from '@ardoq/rxbeach';
import { openEntityHistoryModal } from 'sync/actions';
import { formatDateTime } from '@ardoq/date-time';

export const DAYS_OPTIONS: LabeledValue<number>[] = [
  {
    value: 1,
    label: '1 day ago',
  },
  {
    value: 7,
    label: '7 days ago',
  },
  {
    value: 14,
    label: '14 days ago',
  },
  {
    value: 30,
    label: '30 days ago',
  },
  {
    value: 60,
    label: '60 days ago',
  },
  {
    value: 90,
    label: '90 days ago',
  },
];

export const createStatTableHeader = (): Column<WorkspaceDigestChange>[] => [
  {
    title: 'Name',
    dataIndex: 'name',
    valueRender: (cell, row) => {
      const hasHistory = row._version > 1;
      return hasHistory && row.entityType !== APIEntityType.TAG ? (
        <a
          onClick={() =>
            dispatchAction(
              openEntityHistoryModal({
                entityId: row._id,
                entityType: row.entityType,
              })
            )
          }
        >
          {cell}
        </a>
      ) : (
        cell
      );
    },
  },
  {
    title: 'Change',
    dataIndex: 'type',
  },
  {
    title: 'By',
    dataIndex: 'lastModifiedByName',
  },
  {
    title: 'Date',
    dataIndex: 'last-updated',
    valueRender: value => formatDateTime(value, getCurrentLocale()),
  },
  {
    title: 'Version',
    dataIndex: '_version',
    cellStyle: {
      textAlign: 'end',
    },
  },
];
