import {
  EphemeralFeaturesCarouselConfig,
  EphemeralNotificationConfig,
} from './types';

export const isCarouselFeaturesConfig = (
  notificationConfig:
    | EphemeralNotificationConfig
    | EphemeralFeaturesCarouselConfig
    | null
): notificationConfig is EphemeralFeaturesCarouselConfig =>
  notificationConfig !== null && 'cards' in notificationConfig;
