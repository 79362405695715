import styled from 'styled-components';
import BodyContainer from 'atomicComponents/Layout/BodyContainer';
import { ChevronIcon } from '@ardoq/icons';
import { MetamodelVisualization } from '@ardoq/metamodel-visualization';
import Stats from './metamodelStats/Stats';
import {
  OpeningMethod,
  trackClickCloseMetamodelStatsButton,
  trackClickOpenMetamodelStats,
} from './tracking';
import { MetamodelContext, SelectedMetamodelItem } from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import * as profiling from '@ardoq/profiling';
import { GraphComponent } from '@ardoq/yfiles';
import {
  selectMetamodelItem,
  setMetamodelInfoContainerIsOpen,
} from './navigation/actions';
import { colors } from '@ardoq/design-tokens';
import { PersistedMetamodel } from '@ardoq/api-types';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { FlexBox } from '@ardoq/layout';

const ViewContainer = styled.div`
  position: relative;
  height: 100%;
  overflow-x: hidden;
`;

interface InfoContainerStyleProps {
  $infoContainerIsOpen: boolean;
  $infoContainerWidth: number;
}

const VisualizationContainer = styled.div<InfoContainerStyleProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: white;
  transition: 0.5s;
  transform: ${({ $infoContainerIsOpen, $infoContainerWidth }) =>
    `translateX(${$infoContainerIsOpen ? -$infoContainerWidth / 2 : 0}px)`};
`;

const ExpandableSidebar = styled(({ ...args }) => (
  <BodyContainer {...args} />
))<InfoContainerStyleProps>`
  position: absolute;
  width: ${({ $infoContainerWidth }) => $infoContainerWidth}px;
  right: ${({
    $infoContainerIsOpen,
    $infoContainerWidth,
  }: InfoContainerStyleProps) =>
    $infoContainerIsOpen ? 0 : -$infoContainerWidth}px;
  padding: 32px;
  transition: 0.5s;
`;

const CloseStatsButton = styled(ChevronIcon)<InfoContainerStyleProps>`
  color: ${colors.grey60};
  position: absolute;
  right: ${({
    $infoContainerIsOpen,
    $infoContainerWidth,
  }: InfoContainerStyleProps) =>
    $infoContainerIsOpen ? '32px' : `${-$infoContainerWidth + 32}px`};
  transition: 0.5s;
  z-index: 2;
  font-size: 24px;
  top: 32px;
  cursor: pointer;
`;

const OpenStatsButtonWrapper = styled.div<InfoContainerStyleProps>`
  position: absolute;
  top: 20px;
  right: ${({ $infoContainerIsOpen, $infoContainerWidth }) =>
    $infoContainerIsOpen ? $infoContainerWidth - 106 : -15}px;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    transform: translate(-15px, 0);
  }
`;

interface InfoContainerProps {
  infoContainerIsOpen: boolean;
  infoContainerWidth: number;
}

const OpenStatsButton = ({
  onClick,
  infoContainerIsOpen,
  infoContainerWidth,
}: {
  onClick: any;
} & InfoContainerProps) => (
  <OpenStatsButtonWrapper
    $infoContainerIsOpen={infoContainerIsOpen}
    $infoContainerWidth={infoContainerWidth}
  >
    <img
      data-tooltip-text="Show Metamodel analytics"
      onClick={onClick}
      src="/img/svg-icons/icon_ardoq_stats.svg"
    />
  </OpenStatsButtonWrapper>
);

const onItemSelection = (selectedItem: SelectedMetamodelItem) => {
  dispatchAction(selectMetamodelItem(selectedItem));
};

interface MetamodelViewProps {
  metamodel: PersistedMetamodel;
  context: MetamodelContext;
  infoContainerIsOpen: boolean;
  infoContainerWidth: number;
  registerVisualizationContainer?: (
    visualizationContainer: HTMLElement
  ) => void;
  registerGraphComponent?: (graphComponent: GraphComponent) => void;
  unregisterVisualizationContainer?: () => void;
  unregisterGraphComponent?: () => void;
}

const MetamodelView = ({
  metamodel,
  context,
  infoContainerIsOpen,
  infoContainerWidth,
  registerVisualizationContainer,
  registerGraphComponent,
  unregisterVisualizationContainer,
  unregisterGraphComponent,
}: MetamodelViewProps) => {
  profiling.useTransaction(
    'metamodel view render',
    1000,
    {
      viewId: 'metamodel',
      metadata: {
        compTypes: Object.keys(metamodel.metamodel.componentTypes).length,
        refTypes: Object.keys(metamodel.metamodel.referenceTypes).length,
      },
    },
    profiling.Team.ENGAGEMENT
  );
  return (
    <ViewContainer>
      <VisualizationContainer
        $infoContainerIsOpen={infoContainerIsOpen}
        $infoContainerWidth={infoContainerWidth}
      >
        <MetamodelVisualization
          metamodelComponents={metamodel.metamodel.componentTypes}
          metamodelReferences={metamodel.metamodel.referenceTypes}
          metamodelWorkspaces={metamodel.metamodel.workspaces}
          metamodelAssetFolders={metamodel.metamodel.workspaceFolders}
          loadingAsset={
            <FlexBox
              justify="center"
              width="full"
              marginTop="xxlarge"
              paddingTop="xxlarge"
            >
              <ArdoqLoaderSpinner
                color={LoaderColor.GRAY}
                size={LoaderSize.MEDIUM}
              />
            </FlexBox>
          }
          onItemFocus={onItemSelection}
          registerVisualizationContainer={registerVisualizationContainer}
          registerGraphComponent={registerGraphComponent}
          unregisterVisualizationContainer={unregisterVisualizationContainer}
          unregisterGraphComponent={unregisterGraphComponent}
          hasLeftMenu={true}
        />
      </VisualizationContainer>
      <OpenStatsButton
        infoContainerIsOpen={infoContainerIsOpen}
        infoContainerWidth={infoContainerWidth}
        onClick={() => {
          trackClickOpenMetamodelStats(OpeningMethod.METAMODEL_STATS_BUTTON);
          dispatchAction(setMetamodelInfoContainerIsOpen(true));
        }}
      />
      <CloseStatsButton
        direction="right"
        $infoContainerIsOpen={infoContainerIsOpen}
        $infoContainerWidth={infoContainerWidth}
        onClick={() => {
          trackClickCloseMetamodelStatsButton();
          dispatchAction(setMetamodelInfoContainerIsOpen(false));
        }}
      />
      <ExpandableSidebar
        $infoContainerIsOpen={infoContainerIsOpen}
        $infoContainerWidth={infoContainerWidth}
      >
        <Stats context={context} metamodel={metamodel} />
      </ExpandableSidebar>
    </ViewContainer>
  );
};

export default MetamodelView;
