import { actionCreator } from '@ardoq/rxbeach';
import { ChartWindowState } from '@ardoq/graph';

export const chartSetWindow = actionCreator<ChartWindowState>(
  '[charting] CHART_SET_WINDOW'
);

// Payload is an unknown view state from the view using the action
type ViewState = Record<string, any>;
export const chartLayoutReset = actionCreator<ViewState>(
  '[charting] CHART_LAYOUT_RESET'
);
