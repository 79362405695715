import {
  RelationshipsLink,
  RelationshipsLinkVisual,
  RelationshipsNode,
} from './types';

const getDistinctKey = (
  link: RelationshipsLink,
  sourceProxy: RelationshipsNode,
  targetProxy: RelationshipsNode
) => `${link.globalTypeId},${sourceProxy.id},${targetProxy.id},${link.label}`;
const getUncollapsedAncestor = (node: RelationshipsNode) => {
  let ret = node;

  for (let t = node.parent; t; t = t.parent) {
    if (!t.open) {
      ret = t;
    }
  }

  return ret;
};

const collapseLinks = (
  collapsedLinks: Map<string, RelationshipsLinkVisual>,
  link: RelationshipsLink
) => {
  const [sourceProxy, targetProxy] = [link.source, link.target].map(
    getUncollapsedAncestor
  );
  const key = getDistinctKey(link, sourceProxy, targetProxy);

  let linkVisual = collapsedLinks.get(key);
  if (linkVisual) {
    linkVisual.modelIds.push(link.modelId);
  } else {
    linkVisual = link as RelationshipsLinkVisual; // optimization, prefer "as" in perf critical code. yes I'm lying to TypeScript.
    linkVisual.sourceProxy = sourceProxy;
    linkVisual.targetProxy = targetProxy;
    linkVisual.modelIds = [link.modelId];
    collapsedLinks.set(key, linkVisual);
  }

  return collapsedLinks;
};

export default collapseLinks;
