import { spreadPayloadOverState } from '@ardoq/common-helpers';
import {
  persistentReducedStream,
  reducer,
  actionCreator,
} from '@ardoq/rxbeach';

type IsCurrentlyHoveredImageBrokenState = {
  isCurrentlyHoveredImageBroken: boolean;
  imageElement?: SVGImageElement;
};

export const setIsCurrentlyHoveredImageBroken =
  actionCreator<IsCurrentlyHoveredImageBrokenState>(
    '[yfileshelperactions] SET_IS_CURRENTLY_HOVERED_IMAGE_BROKEN'
  );

const defaultState: IsCurrentlyHoveredImageBrokenState = {
  isCurrentlyHoveredImageBroken: false,
};

const isCurrentlyHoveredImageBroken$ = persistentReducedStream(
  'setIsCurrentlyHoveredImageBroken$',
  defaultState,
  [reducer(setIsCurrentlyHoveredImageBroken, spreadPayloadOverState)]
);

export default isCurrentlyHoveredImageBroken$;
