import { ViewIds } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

type TrackMultiselectArgs = {
  mainViewId: ViewIds;
  secondaryViewId: ViewIds | null;
};

export const trackMultiselect = ({
  mainViewId,
  secondaryViewId,
}: TrackMultiselectArgs) =>
  trackEvent('Multiselect', {
    mainViewId,
    ...(secondaryViewId ? { secondaryViewId } : {}),
  });
