import { ExcludeFalsy } from '@ardoq/common-helpers';
import { IntegrationElementExtension } from 'aqTypes';
import References from 'collections/references';

const getReferencesFromElement = (target: Element) => {
  const integration = (target as Element & IntegrationElementExtension)
    .integration; // this is still used on edge labels.
  if (integration) {
    return [integration];
  }

  const dataset = target.closest<HTMLElement | SVGElement>(
    '[data-global-handler-id].integration, [data-reference-model-ids].integration'
  )?.dataset;
  if (!dataset) {
    return [];
  }
  const reference =
    dataset.globalHandlerId &&
    References.collection.get(dataset.globalHandlerId);
  if (reference) {
    return [reference];
  }
  const references =
    dataset.referenceModelIds &&
    dataset.referenceModelIds
      .split(',')
      .map(referenceId => References.collection.get(referenceId))
      .filter(ExcludeFalsy);
  return references || [];
};

export default getReferencesFromElement;
