import {
  removeWorkspaces,
  notifyWorkspacesDeleted,
  openWorkspace,
  closeAllWorkspaces,
  confirmDeleteWorkspace,
  componentInWorkspaceNavigationRequested,
} from './actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import workspaces from 'collections/workspaces';
import Context from 'context';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import workspaceDigest from '../workspaceDigest/routines';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from 'components/DeleteAssetModal/types';
import { hideRightPane } from 'appContainer/actions';
import { logError } from '@ardoq/logging';
import { contextInterface } from 'modelInterface/contextInterface';
import surveys$ from '../surveys/surveys$';
import { workspaceApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { confirmCloseAllDatasets } from 'viewpointNavigator/confirmCloseAllDatasets';
import openComponentInWorkspace from 'appInterface/openComponentInWorkspace';
import { loadedState$ } from 'loadedState/loadedState$';
import { context$ } from 'streams/context/context$';
import { isAnyWorkspaceOrLoadedStateOpen } from 'viewpointBuilder/utils';

const handleWorkspacesDeleted = routine(
  ofType(notifyWorkspacesDeleted),
  extractPayload(),
  tap(({ workspaceIds }) => {
    contextInterface.cleanupDeletedWorkspacesInContext(workspaceIds);
  })
);

const handleOpenWorkspace = routine(
  ofType(openWorkspace),
  extractPayload(),
  tap(({ workspaceId, keepCurrentContext, trackingLocation }) => {
    const workspace = workspaces.collection.get(workspaceId);
    if (workspace) {
      const switchContextToNewWorkspace = !(
        keepCurrentContext && Context.activeWorkspaceId()
      );
      Context.loadWorkspaces(
        switchContextToNewWorkspace
          ? {
              contextWorkspace: workspace,
              trackingLocation,
            }
          : {
              workspaces: [workspace],
              trackingLocation,
            }
      );
    } else {
      logError(
        new Error(`Missing workspace for id ${workspaceId}`),
        `Cannot open a workspace with id ${workspaceId}, because it is not in the collection.`
      );
    }
  })
);

// TODO: remove when COMPONENT_LANDING_PAGE feature flag is removed
const handleOpenComponentInWorkspace = routine(
  ofType(componentInWorkspaceNavigationRequested),
  extractPayload(),
  withLatestFrom(loadedState$, context$),
  tap(async ([{ componentId, trackingLocation }, loadedState, context]) => {
    const isSomeViewpointOpen = isAnyWorkspaceOrLoadedStateOpen(
      loadedState,
      context
    );

    if (isSomeViewpointOpen) {
      const confirm = await confirmCloseAllDatasets();
      if (!confirm) return;
      dispatchAction(closeAllWorkspaces());
    }

    openComponentInWorkspace(componentId, trackingLocation);
  })
);

const handleConfirmDeleteWorkspace = routine(
  ofType(confirmDeleteWorkspace),
  extractPayload(),
  withLatestFrom(surveys$),
  tap(async ([workspaceId, { list: surveys }]) => {
    const workspace = workspaces.collection.get(workspaceId);

    const affectedSurveys = surveys
      ? surveys.filter(survey => survey.workspace === workspaceId)
      : [];

    if (workspace) {
      const approved = await confirmDeleteAsset({
        deleteType: DeleteType.WORKSPACE,
        name: workspace.get('name'),
        id: workspaceId,
        affectedSurveys: affectedSurveys,
      });

      if (approved) {
        dispatchAction(hideRightPane());
        const result = await workspaceApi.deleteWorkspaces([workspaceId]);
        if (isArdoqError(result)) {
          // TODO[ARD-22935]: There does not seem to be a way to handle this error yet
          logError(result);
        }
      }
    }
  })
);
const handleRemoveWorkspaces = routine(
  ofType(removeWorkspaces),
  extractPayload(),
  tap(async workspaceIds => {
    workspaces.collection.remove(workspaceIds);
    dispatchAction(notifyWorkspacesDeleted({ workspaceIds }));
  })
);

const handleCloseAllWorkspaces = routine(
  ofType(closeAllWorkspaces),
  tap(async () => {
    await contextInterface.closeAllWorkspaces();
  })
);

export default collectRoutines(
  handleConfirmDeleteWorkspace,
  handleWorkspacesDeleted,
  handleOpenWorkspace,
  handleOpenComponentInWorkspace,
  handleCloseAllWorkspaces,
  workspaceDigest,
  handleRemoveWorkspaces
);
