import { streamReducer, action$, ofType, reduceState } from '@ardoq/rxbeach';
import { Observable, combineLatest } from 'rxjs';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { context$ } from 'streams/context/context$';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import {
  ViewSettings,
  getViewSettingsStreamWithChanges,
} from 'viewSettings/viewSettingsStreams';
import { startAction } from 'actions/utils';
import { TimelineViewProperties } from '../types';
import {
  ViewLoadingStatus,
  buildTimelineData,
  defaultEmptyState,
  TimelineViewSettings,
  TimelineViewModel,
} from '@ardoq/timeline';
import { getData } from '../data';
import { APIComponentAttributes, ArdoqId, ViewIds } from '@ardoq/api-types';
import defaultState from 'views/defaultState';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';
import { debounceTime, startWith, map, filter } from 'rxjs/operators';
import graphModel$ from 'modelInterface/graphModel$';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import {
  getFilteredViewSettings$,
  getViewSettingsFilter,
} from 'views/filteredViewSettings$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import type { ExtractStreamShape } from 'tabview/types';
import componentAttributesData$ from '../../../streams/componentAttributesData$';
import { locale$ } from '../../../streams/locale$';
import { Locale } from '@ardoq/locale';
import { dateRangeOperations } from '@ardoq/date-range';
import { isEmpty } from 'lodash';
import { getHierarchySortComparator } from '@ardoq/timeline2024';

const emptyState: TimelineViewModel = {
  ...defaultEmptyState,
  viewSettings: defaultState.get(ViewIds.TIMELINE) as TimelineViewSettings,
};

type ModelResetResult = Omit<TimelineViewProperties, 'viewSettings'>;

interface ResetArgs {
  context: ContextShape;
  componentAttributesById: Record<ArdoqId, APIComponentAttributes>;
  userLocale: Locale;
  viewSettings: TimelineViewSettings;
  graph: GraphModelShape;
  isViewpointMode: boolean;
}
const reset = (
  previousState: ModelResetResult,
  {
    context,
    componentAttributesById,
    userLocale,
    viewSettings,
    graph,
    isViewpointMode,
  }: ResetArgs
): ModelResetResult => {
  const newState = {
    ...previousState,
    viewStatus: ViewLoadingStatus.READY,
    viewSettings: viewSettings,
    isViewpointMode,
  };
  if (!context.workspaceId || isEmpty(componentAttributesById)) {
    return newState;
  }
  const sort = context.sort;

  const hierarchySortComparator = getHierarchySortComparator({
    componentAttributesById,
    sort,
    isDateRangeField: dateRangeOperations.isCombinedDateRangeFieldNames(
      sort.attr
    ),
    userLocale,
  });

  const { nodes, childrenMap, errors, hasClones } = getData(
    context,
    graph,
    viewSettings.useNewGrouping && !isViewpointMode
  );

  const timelineData = buildTimelineData({
    nodes,
    childrenMap,
    graph,
    contextComponentId: context.componentId,
    viewSettings,
    selectedFields: [],
    hasGrouping: groupingRuleInterface.count() > 0,
    hierarchySortComparator,
  });

  return {
    ...newState,
    ...timelineData,
    errors,
    hasClones,
  };
};

const rawTimelineViewSettings$ =
  getViewSettingsStreamWithChanges<TimelineViewSettings>(ViewIds.TIMELINE);

const ignoreViewSettingsChange = getViewSettingsFilter<TimelineViewSettings>(
  'gridPrecision',
  'domainRange'
);
const getFilteredTimelineViewSettings = (
  viewSettings$: Observable<ViewSettings<TimelineViewSettings>>
) =>
  getFilteredViewSettings$(viewSettings$).pipe(
    filter(
      (viewSettings, index) => !ignoreViewSettingsChange(viewSettings, index)
    ),
    map(({ currentSettings }) => currentSettings)
  );
const filteredTimelineViewSettings$ = getFilteredTimelineViewSettings(
  rawTimelineViewSettings$
);

export const viewModel$ = (
  viewInstanceId: string
): Observable<TimelineViewProperties> => {
  const resetNotification$ = action$.pipe(
    ofType(
      notifyComponentsUpdated,
      notifyComponentsAdded,
      notifyComponentsRemoved,
      notifyFiltersChanged,
      notifyFieldAdded,
      notifyFieldRemoved,
      notifyFieldUpdated
    ),
    startWith(startAction())
  );

  const reset$ = combineLatest([
    context$,
    componentAttributesData$,
    locale$,
    filteredTimelineViewSettings$,
    graphModel$,
    isViewpointMode$,
    resetNotification$,
  ]).pipe(debounceTime(50));
  const handleReset = (
    previousState: ModelResetResult,
    [
      context,
      { componentAttributesById },
      userLocale,
      viewSettings,
      graph,
      { isViewpointMode },
    ]: ExtractStreamShape<typeof reset$>
  ) =>
    reset(previousState, {
      context,
      componentAttributesById,
      userLocale,
      viewSettings,
      graph,
      isViewpointMode,
    });

  const resetStream$ = action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState<ModelResetResult>(
      'timelineViewModel',
      {
        ...emptyState,
        viewInstanceId,
        viewStatus: ViewLoadingStatus.LOADING,
        isViewpointMode: false,
      },
      [streamReducer(reset$, handleReset)]
    ),
    // Debounce viewModel$ emissions to ensure fewer renders
    debounceTime(50)
  );

  return combineLatest([resetStream$, rawTimelineViewSettings$]).pipe(
    map(([viewModel, { currentSettings }]) => ({
      ...viewModel,
      viewSettings: currentSettings,
    }))
  );
};
