import { ArdoqId } from '@ardoq/api-types';
import { GraphItem as OldGraphItem } from 'graph/GraphItem';
import {
  AggregatedGraphEdge,
  GraphEdge,
  GraphGroup,
  GraphItem,
  GraphNode,
  ParentChildGraphEdge,
} from '@ardoq/graph';
import { Node } from 'graph/node';
import type { Edge } from 'graph/types';
import { ProteanTabularLayoutGridLayoutInfo } from 'tabview/proteanDiagram/intentionalLayout/layoutStage/types';

/** @deprecated avoid using legacy types. */
type GraphItemOrOldGraphItem = OldGraphItem | GraphItem;
/** @deprecated use GraphViewDataModel from the graph package. */
export interface ArdoqGraphComponentViewModel<
  TNode extends GraphItemOrOldGraphItem = Node | GraphNode,
  TGroup extends GraphItemOrOldGraphItem = Node | GraphGroup,
  TEdge extends GraphItemOrOldGraphItem =
    | Edge
    | GraphEdge
    | ParentChildGraphEdge
    | AggregatedGraphEdge,
> {
  groups: GraphItemsModel<TGroup>;
  nodes: GraphItemsModel<TNode>;
  edges: GraphItemsModel<TEdge>;
}
/** @deprecated use GraphItemsModel from the graph package. */
export interface GraphItemsModel<
  T extends OldGraphItem | GraphItem = OldGraphItem | GraphItem,
> {
  byId: Map<ArdoqId, T>;
  add: ArdoqId[];
  update: ArdoqId[];
  remove: ArdoqId[];
}

export interface WorkerError {
  error: Error;
  message?: string | null;
  extraData?: Record<string | number, unknown>;
  errorLevel: WorkerErrorLevel;
}
export enum WorkerErrorLevel {
  WARNING,
  ERROR,
}

export type GraphLayoutResult = {
  grids?: ProteanTabularLayoutGridLayoutInfo[];
};
