import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Slider } from '@ardoq/forms';
import TraversalInfoGrid from './TraversalInfoGrid';
import { Link, normal14, semibold14 } from '@ardoq/typography';
import { colors, s32 } from '@ardoq/design-tokens';
import TraversalInfoMap from './TraversalInfoMap';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { useOnMount } from '@ardoq/hooks';
import UserSettings from 'models/UserSettings';
import { ViewIds } from '@ardoq/api-types';
import { updateViewSettings } from '@ardoq/view-settings';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackEvent } from 'tracking/tracking';
import { currentTimestamp, parseToTimestamp } from '@ardoq/date-time';

const Container = styled.div`
  ${normal14};
  font-weight: normal;
  width: 528px;
`;
const Row = styled.div`
  display: flex;
  justify-items: stretch;
  justify-content: center;
  align-items: center;
`;

const SliderContainer = styled.div`
  width: 208px;
  height: 40px;
  margin-left: 26px;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
  > div:last-child > div:last-child > div:last-child {
    /* slider value label */
    ${semibold14};
    align-self: center;
  }
`;
const Label = styled.label`
  ${normal14}
  font-weight: normal;
`;
const Bold = styled.span`
  ${semibold14}
`;
let checkedUserSettings = false;
const TraversalInfoPopover = () => {
  /** need to use useCallback for this because React forbids async functions in useEffect. */
  const checkUserSettings = useCallback(async () => {
    if (checkedUserSettings) {
      return;
    }
    const viewId = ViewIds.DEPENDENCY_MAP_2;
    const dependencyMapUserSettings = new UserSettings(viewId);
    const seenTraversalInfo = await dependencyMapUserSettings.get<
      string | boolean
    >('seenTraversalInfo');
    if (typeof seenTraversalInfo !== 'boolean' || !seenTraversalInfo) {
      const seenTraversalInfoTime = !seenTraversalInfo
        ? false
        : parseToTimestamp(seenTraversalInfo);
      trackEvent('Viewed traversal info popover', {
        elapsedMillisecondsSinceUserOpenedTraversalOptionsWithBadge:
          seenTraversalInfoTime
            ? currentTimestamp() - seenTraversalInfoTime
            : 0,
      });
      dispatchAction(
        updateViewSettings({
          viewId,
          settings: { seenTraversalInfo: true },
          persistent: true,
        })
      );
    }
    checkedUserSettings = true;
  }, []);
  useOnMount(() => {
    checkUserSettings();
  });

  const [inDegrees, setInDegrees] = useState(3);
  const [outDegrees, setOutDegrees] = useState(3);
  const [hoveredComponentId, setHoveredComponentId] = useState<string | null>(
    null
  );
  return (
    <Container>
      <Label>
        For the incoming and outgoing degrees of relationship the direction does
        matter.
      </Label>
      <Label>
        It only includes components which are{' '}
        <Bold>connected in the same direction</Bold>.
      </Label>
      <Row style={{ marginTop: s32 }}>
        <Label>Incoming:</Label>
        <SliderContainer>
          <Slider
            minValue={0}
            maxValue={3}
            value={inDegrees}
            onChange={setInDegrees}
            isValueLabelVisible={true}
            thumbColor={colors.green50}
            barColor={colors.grey80}
            valueBarColor={colors.grey80}
          />
        </SliderContainer>
      </Row>
      <Row style={{ marginBottom: s32 }}>
        <Label>Outgoing:</Label>
        <SliderContainer>
          <Slider
            minValue={0}
            maxValue={3}
            value={outDegrees}
            onChange={setOutDegrees}
            isValueLabelVisible={true}
            thumbColor={colors.purple35}
            barColor={colors.grey80}
            valueBarColor={colors.grey80}
          />
        </SliderContainer>
      </Row>
      <Row style={{ marginBottom: s32 }}>
        <TraversalInfoGrid
          inDegrees={inDegrees}
          outDegrees={outDegrees}
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
        />
        <TraversalInfoMap
          inDegrees={inDegrees}
          outDegrees={outDegrees}
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
        />
      </Row>
      <Link
        href={KnowledgeBaseLink.DEPENDENCY_MAP_2}
        typography="text2"
        target="_blank"
      >
        To learn more about degrees of relationships, read our knowledge base
        article.
      </Link>
    </Container>
  );
};
export default TraversalInfoPopover;
