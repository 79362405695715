import { colors } from '@ardoq/design-tokens';
import { RepresentationData } from '@ardoq/data-model';
import styled from 'styled-components';
import {
  formatOtherLabel,
  GROUP_HEADER_FONT_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT,
  OtherLabel,
  Rect,
  SvgComponentRepresentation,
  TAB_HEIGHT,
} from '@ardoq/graph';
import {
  HEADER_ICON_SIZE,
  GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT,
} from './consts';
import {
  type VisualDiffClassName,
  ensureContrast,
  visualDiffBackgroundColors,
  visualDiffColors,
} from '@ardoq/color-helpers';
import { useEffect, useRef } from 'react';
import { Size, TextMeasurePolicy, TextWrapping } from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { getElementOpacityIfTransparentized } from './utils';
import { OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING } from './yFilesExtensions/labels/consts';
import {
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
  LABEL_VERTICAL_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
  OTHER_LABEL_MARGIN,
} from '../../../yfilesExtensions/styles/consts';
import { applySpecialStyle } from './yFilesExtensions/labels/labelUtils';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
} from './yFilesExtensions/modernized/consts';
import { readableColor } from 'polished';
import NodeIcon from 'yfilesExtensions/styles/modernized/NodeIcon';

const ICON_Y = (TAB_HEIGHT - GROUP_HEADER_FONT_HEIGHT) / 2;

type OtherLabelTextElementProps = {
  label: string;
  x: number;
  y: number;
  isTransparentized: boolean;
  isPlaceholder: boolean;
  isModern: boolean;
};
const OtherLabelTextElement = ({
  label,
  x,
  y,
  isTransparentized,
  isPlaceholder,
  isModern,
}: OtherLabelTextElementProps) => {
  const elementRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      addText({
        targetElement: elementRef.current,
        text: label,
        font: isModern
          ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT
          : BLOCK_DIAGRAM_OTHER_LABEL_FONT,
        maximumSize: isModern
          ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE
          : BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
        wrapping: TextWrapping.WORD_ELLIPSIS,
        measurePolicy: TextMeasurePolicy.SVG,
      });

      applySpecialStyle(elementRef.current, isPlaceholder, isTransparentized);
    }
  }, [label, isTransparentized, isPlaceholder, x, y, isModern]);

  return <text ref={elementRef} textAnchor={'start'} y={y} x={x}></text>;
};
type OtherLabelBackgroundProperties = {
  size: Size;
  backgroundY: number;
  isModern: boolean;
};
const OtherLabelBackground = ({
  size,
  backgroundY,
  isModern,
}: OtherLabelBackgroundProperties) => (
  <rect
    width={size.width + OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING * 2}
    height={size.height}
    rx={4}
    ry={4}
    stroke={colors.blue80}
    strokeWidth={1}
    fill={colors.blue95}
    x={
      isModern
        ? MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
        : BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X
    }
    y={backgroundY}
  />
);

type OtherLabelData = {
  otherLabel: OtherLabel;
  size: Size;
  previousHeight: number;
};
type OtherLabelProps = OtherLabelData & {
  groupLabelHeight: number;
  isTransparentized: boolean;
  isModern: boolean;
};
const OtherLabelComponent = ({
  otherLabel,
  size,
  previousHeight,
  groupLabelHeight,
  isTransparentized,
  isModern,
}: OtherLabelProps) => {
  const label = formatOtherLabel(otherLabel);
  if (!label) {
    return null;
  }
  const yOffset = isModern
    ? MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT + OTHER_LABEL_MARGIN
    : groupLabelHeight + LABEL_VERTICAL_PADDING * 2;
  const backgroundY = yOffset + previousHeight;

  return (
    <g>
      <OtherLabelBackground
        size={size}
        backgroundY={backgroundY}
        isModern={isModern}
      />
      <OtherLabelTextElement
        label={label}
        isTransparentized={isTransparentized}
        isPlaceholder={false}
        isModern={isModern}
        x={
          (isModern
            ? MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
            : BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X) +
          OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING
        }
        y={backgroundY}
      />
    </g>
  );
};

const CollapsibleGroupHeaderLabel = styled.text<{
  $isTransparentized: boolean;
}>`
  fill: ${colors.grey15};
  fill-opacity: ${({ $isTransparentized }) =>
    $isTransparentized ? '0.5' : '1'};
  dominant-baseline: central;
`;
interface GroupHeaderLabelProperties {
  representationData: RepresentationData | null;
  representationColor: string | undefined;
  text: string;
  fieldLabelAndValue?: string | null;
  groupLabelHeight: number;
  groupLabelWidth: number;
  groupNameMaxHeight: number;
  labelX: number;
  visualDiffClass: VisualDiffClassName | '';
  isTransparentized: boolean;
  otherLabelsData?: OtherLabelData[];
  isModern: boolean;
}
const GroupHeaderLabel = ({
  representationData,
  representationColor,
  text,
  fieldLabelAndValue,
  groupLabelHeight,
  groupLabelWidth,
  groupNameMaxHeight,
  labelX,
  visualDiffClass,
  isTransparentized,
  otherLabelsData,
  isModern,
}: GroupHeaderLabelProperties) => {
  const textElementRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    if (textElementRef.current) {
      addText({
        targetElement: textElementRef.current,
        text,
        maximumSize: new Size(groupLabelWidth, groupNameMaxHeight),
        ...(isModern
          ? MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS
          : BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS),
      });
    }
  }, [text, groupLabelWidth, groupNameMaxHeight, isModern]);

  const fill = representationData
    ? visualDiffClass
      ? ensureContrast(
          visualDiffBackgroundColors[visualDiffClass],
          visualDiffColors[visualDiffClass]
        )
      : representationColor
    : undefined;
  const fontSize = isModern
    ? MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT
    : GROUP_HEADER_FONT_HEIGHT;

  const componentRepresentationBounds = representationData
    ? isModern
      ? new Rect(
          0,
          MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
          MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
          MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT
        )
      : new Rect(
          BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
          ICON_Y,
          HEADER_ICON_SIZE,
          HEADER_ICON_SIZE
        )
    : Rect.IMPOSSIBLE;

  const textColor =
    isModern && representationColor
      ? readableColor(representationColor)
      : undefined;

  return (
    <>
      {representationData && (
        <g
          className={visualDiffClass}
          style={{
            fill,
            color: fill,
            stroke: 'none',
            opacity: getElementOpacityIfTransparentized(isTransparentized),
          }}
        >
          {isModern && representationData.icon ? (
            <NodeIcon
              isNegative={false}
              icon={representationData.icon}
              iconColor={
                representationColor
                  ? readableColor(
                      representationColor,
                      colors.grey15,
                      colors.grey90
                    )
                  : colors.transparent0
              }
              iconBounds={componentRepresentationBounds}
            />
          ) : (
            <SvgComponentRepresentation
              representationData={representationData}
              bounds={componentRepresentationBounds}
            />
          )}
        </g>
      )}
      <CollapsibleGroupHeaderLabel
        x={labelX}
        y={
          isModern
            ? groupLabelHeight >
              MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT.fontSize * 1.5
              ? 0
              : MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
            : -GROUP_PADDING
        }
        ref={textElementRef}
        $isTransparentized={isTransparentized}
        style={textColor ? { fill: textColor, stroke: 'none' } : undefined}
      />
      {fieldLabelAndValue && (
        <CollapsibleGroupHeaderLabel
          x={labelX}
          y={groupLabelHeight + fontSize}
          fontSize={fontSize}
          $isTransparentized={isTransparentized}
        >
          {fieldLabelAndValue}
        </CollapsibleGroupHeaderLabel>
      )}
      {otherLabelsData?.map((otherLabelData, index) => (
        <OtherLabelComponent
          key={index}
          groupLabelHeight={groupLabelHeight}
          isTransparentized={isTransparentized}
          isModern={isModern}
          {...otherLabelData}
        />
      ))}
    </>
  );
};

export default GroupHeaderLabel;
