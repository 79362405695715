import { range, sum } from 'lodash';
import { returnZero } from '@ardoq/common-helpers';
import {
  ComponentMatrixGroup,
  ComponentMatrixGroupOwnerType,
  ComponentMatrixViewModel,
  RowElementModel,
} from '../types';
import {
  augmentRowWithGridColumnInfo,
  calculateRowHeaderBottomOffset,
  calculateRowHeaderTopOffset,
  hasChildren,
  isFirstChild,
  isLastChild,
} from '../util';
import { Size } from '@ardoq/graph';
import {
  COMPONENT_HIERARCHY_PADDING,
  COMPONENT_ITEM_CONTAINER_BORDER,
  COMPONENT_ITEM_CONTAINER_MARGIN,
  COMPONENT_ITEM_CONTAINER_PADDINGRIGHT,
  COMPONENT_ITEM_HEIGHT,
  HEADER_CELL_HORIZONTAL_PADDING,
  HEADER_CELL_VERTICAL_PADDING,
  ICON_MARGIN,
  ICON_WIDTH,
  LEVEL_HEADER_CELL_HORIZONTAL_PADDING,
  MARGIN_PER_LEVEL,
  NONE_HEADER_LABEL,
  ROW_HEADER_HIERARCHY_INDENTATION,
  ROW_HEADER_LABEL_HEIGHT,
} from '../consts';
import { MATERIAL_ICONS_DEFAULT_FONT_SIZE } from 'tabview/consts';
import { measureLevelLabel } from './measureLabels';
import { MeasureStyledText } from '@ardoq/dom-utils';

const COLUMN_HEADER_HORIZONTAL_BORDER = 1;
const ICON_HORIZONTAL_MARGIN = 2 * ICON_MARGIN;
const ITEM_WIDTH_WITHOUT_TEXT =
  ICON_WIDTH +
  ICON_HORIZONTAL_MARGIN +
  2 * COMPONENT_ITEM_CONTAINER_MARGIN +
  2 * COMPONENT_ITEM_CONTAINER_BORDER +
  COMPONENT_ITEM_CONTAINER_PADDINGRIGHT;

const EXPANDER_WIDTH = MATERIAL_ICONS_DEFAULT_FONT_SIZE;

const textMeasurer = new MeasureStyledText();
const getTextWidth = (text: string) =>
  Math.ceil(textMeasurer.getTextWidth({ text })); // using Math.ceil since chrome appears to round up when deciding to apply the text overflow ellipsis. either that or the text measuring is inaccurate ¯\_(ツ)_/¯

const getColumnCount = (rowModels: RowElementModel[]) =>
  rowModels.length === 0
    ? 0
    : sum(rowModels[0].bodyCells.map(bodyCell => bodyCell.columnSpan));
const getRowCount = (rowModels: RowElementModel[]) =>
  rowModels.length === 0
    ? 0
    : sum(
        rowModels.map(rowModel =>
          rowModel.bodyCells.length === 0 ? 0 : rowModel.bodyCells[0].rowSpan
        )
      );

const MAX_COLUMN_WIDTH = 500;

export const measureCells = (rowModels: RowElementModel[]) => {
  const columnCount = getColumnCount(rowModels);
  const rowCount = getRowCount(rowModels);
  // create the empty matrix
  const result: Size[][] = range(rowCount).map(() =>
    range(columnCount).map(() => ({ width: NaN, height: NaN }))
  );

  for (
    let rowModelIndex = 0;
    rowModelIndex < rowModels.length;
    rowModelIndex++
  ) {
    const rowModel = rowModels[rowModelIndex];
    for (
      let bodyCellIndex = 0;
      bodyCellIndex < rowModel.bodyCells.length;
      bodyCellIndex++
    ) {
      const bodyCell = rowModel.bodyCells[bodyCellIndex];
      const currentCellWidth =
        bodyCell.items.length === 0
          ? 0
          : Math.min(
              MAX_COLUMN_WIDTH,
              (Math.max(
                ...bodyCell.items.map(({ componentLabel }) =>
                  getTextWidth(componentLabel)
                )
              ) +
                ITEM_WIDTH_WITHOUT_TEXT) /
                bodyCell.columnSpan
            );

      const allItemsHeight =
        COMPONENT_ITEM_HEIGHT * bodyCell.items.length +
        COMPONENT_ITEM_CONTAINER_MARGIN * (bodyCell.items.length + 1);
      const currentCellHeight = allItemsHeight / bodyCell.rowSpan;
      for (
        let spannedRowIndex = 0;
        spannedRowIndex < bodyCell.rowSpan;
        spannedRowIndex++
      ) {
        for (
          let spannedColumnIndex = 0;
          spannedColumnIndex < bodyCell.columnSpan;
          spannedColumnIndex++
        ) {
          result[rowModelIndex + spannedRowIndex][
            bodyCell.columnIndex + spannedColumnIndex
          ] = { width: currentCellWidth, height: currentCellHeight };
        }
      }
    }
  }
  return result;
};

const getHeaderRowWidth = (row: ComponentMatrixGroup) => {
  const headerValue = row.address[row.address.length - 1];
  if (!headerValue) {
    return 0;
  }
  const iconAndTextWidth =
    row.ownerType === ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT
      ? getTextWidth(row.label ?? '') + ICON_WIDTH + ICON_HORIZONTAL_MARGIN
      : getTextWidth(headerValue) + 2 * HEADER_CELL_HORIZONTAL_PADDING;

  return iconAndTextWidth + (row.children.size ? EXPANDER_WIDTH : 0);
};
const getHeaderColumnWidth = (column: ComponentMatrixGroup) => {
  const headerValue = column.address[column.address.length - 1];
  if (column.isHidden || headerValue === COMPONENT_HIERARCHY_PADDING) {
    return 0;
  }

  const expanderWidth = hasChildren(column) ? EXPANDER_WIDTH : 0;
  const indentation = (column.address.length - 1) * (MARGIN_PER_LEVEL * 2);
  const isComponent =
    column.ownerType === ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT;
  const iconWidth = isComponent ? ICON_WIDTH + ICON_HORIZONTAL_MARGIN : 0;

  const headerLabel = headerValue === null ? NONE_HEADER_LABEL : headerValue;
  const headerLabelWidth = isComponent
    ? getTextWidth(column.label ?? '')
    : getTextWidth(headerLabel) + 2 * HEADER_CELL_HORIZONTAL_PADDING;

  return (
    headerLabelWidth +
    indentation +
    iconWidth +
    expanderWidth +
    COLUMN_HEADER_HORIZONTAL_BORDER
  );
};

export const getHeaderColumnWidths = (viewModel: ComponentMatrixViewModel) => {
  const result = range(
    viewModel.columnsByDepth[viewModel.columnsByDepth.length - 1].length
  ).map(returnZero);

  for (let level = 0; level < viewModel.columnsByDepth.length; level++) {
    const currentLevelColumns = augmentRowWithGridColumnInfo(
      viewModel.columnsByDepth[level]
    );
    let gridColumnIndex = 0;
    for (
      let columnIndex = 0;
      columnIndex < currentLevelColumns.length;
      columnIndex++
    ) {
      const column = currentLevelColumns[columnIndex];
      const currentColumnWidth = Math.min(
        MAX_COLUMN_WIDTH,
        getHeaderColumnWidth(column)
      );
      result[gridColumnIndex] = Math.max(
        result[gridColumnIndex],
        currentColumnWidth
      );
      gridColumnIndex += column.colSpan;
    }
  }
  return result;
};

export const measureRowHeaders = (rowModels: RowElementModel[]) => {
  const result = rowModels.map(rowModel =>
    rowModel.headerCells.reduce(
      (size: Size, headerCell) => {
        if (
          headerCell.group.isHidden ||
          headerCell.group.label === COMPONENT_HIERARCHY_PADDING
        ) {
          return size;
        }
        const level = headerCell.group.address.length - 1;

        const { topPadding, bottomPadding } =
          level > 0
            ? {
                topPadding: isFirstChild(headerCell)
                  ? calculateRowHeaderTopOffset(headerCell.indexAddress)
                  : 2 * HEADER_CELL_VERTICAL_PADDING,
                bottomPadding: isLastChild(headerCell)
                  ? calculateRowHeaderBottomOffset(level)
                  : 0,
              }
            : { topPadding: 0, bottomPadding: 0 };
        const minHeight = COMPONENT_ITEM_HEIGHT + topPadding + bottomPadding;

        return {
          height: Math.max(minHeight, size.height + ROW_HEADER_LABEL_HEIGHT),
          width: Math.max(
            size.width,
            ROW_HEADER_HIERARCHY_INDENTATION * level +
              getHeaderRowWidth(headerCell.group)
          ),
        };
      },
      { width: 0, height: 0 }
    )
  );

  return result;
};

export const measureRowLevelHeaders = (levelHeaders: string[]) =>
  levelHeaders.reduce(
    (width: number, levelHeader: string, index: number) =>
      Math.max(
        width,
        measureLevelLabel(levelHeader) +
          index * ROW_HEADER_HIERARCHY_INDENTATION +
          LEVEL_HEADER_CELL_HORIZONTAL_PADDING * 2
      ),
    0
  );
