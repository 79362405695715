import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import {
  SelectedReferenceTypeInfo,
  TraversedReferenceTypeInfo,
} from '@ardoq/settings-bar';
import { ViewError } from '@ardoq/graph';
import { ContextShape } from '@ardoq/data-model';
export interface ComponentMatrixViewSettings {
  selectedFieldNamesX: string[];
  selectedFieldNamesY: string[];
  referenceTypeX: SelectedReferenceTypeInfo | null;
  referenceTypeY: SelectedReferenceTypeInfo | null;
  filterNulls: boolean;
  includeAllDescendants: boolean;
}

export interface ComponentMatrixViewSettingsState {
  listFields: APIFieldAttributes[];
  traversedOutgoingReferenceTypes: TraversedReferenceTypeInfo[];
  traversedIncomingReferenceTypes: TraversedReferenceTypeInfo[];
}

export interface ComponentMatrixMeasurements {
  mainContainerLeftColumnWidth: number;
  columnHeaderWidths: number[];
  columnHeaderAndColumnWidths: number[];
  rowHeights: number[];
}
export interface ComponentMatrixViewProperties {
  viewSettingsState: ComponentMatrixViewSettingsState;
  viewSettings: ComponentMatrixViewSettings;
  viewModel: ComponentMatrixViewModel;
  hasReferences: boolean;
  hasFilteredNullItems: boolean;
  errors: ViewError[];
}
export interface ComponentMatrixViewStreamState
  extends ComponentMatrixViewProperties {
  viewSettings: ComponentMatrixViewSettings;
  context: ContextShape;
}
export interface ComponentMatrixViewModel {
  columns: Map<string | null, ComponentMatrixGroup>;
  rows: Map<string | null, ComponentMatrixGroup>;
  items: ComponentMatrixItem[];
  columnsByDepth: ComponentMatrixGroup[][];
  rowsByDepth: ComponentMatrixGroup[][];
  columnLevelLabels: string[];
  rowLevelLabels: string[];
  measurements: ComponentMatrixMeasurements;
  rowModels: RowElementModel[];
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
}
export interface ComponentMatrixGroup {
  address: (string | null)[];
  ownerType: ComponentMatrixGroupOwnerType;
  label: string | null;
  isExpanded: boolean;
  isHidden: boolean;
  children: Map<string | null, ComponentMatrixGroup>;
}
export interface ComponentMatrixItem {
  address: ComponentMatrixItemAddress;
  componentId: ArdoqId;
}
interface ComponentMatrixItemAddress {
  rowValues: (string | null)[];
  columnValues: (string | null)[];
}
export enum ComponentMatrixGroupOwnerType {
  REFERENCED_COMPONENT,
  LIST_FIELD_VALUE,
  EMPTY,
}
export interface HeaderCellModel {
  value: string | null;
  span: number;
  group: ComponentMatrixGroup;
  indexAddress: number[];
  siblingCount: number;
}
export type BodyCellItemModel = {
  componentId: ArdoqId;
  className: string;
  iconColor: string;
  componentLabel: string;
};
export interface BodyCellModel {
  items: BodyCellItemModel[];
  columnIndex: number;
  columnSpan: number;
  rowSpan: number;
}
export interface RowElementModel {
  headerCells: HeaderCellModel[];
  bodyCells: BodyCellModel[];
}
export enum ComponentMatrixDimension {
  ROW,
  COLUMN,
}
