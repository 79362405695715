import { DoqType } from '@ardoq/doq';
import { ViewIds } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoTagsWithSettingsDoq from 'tabview/graphViews/emptyStates/NoTagsWithSettingsDoq';
import NoTagsDoq from 'tabview/graphViews/emptyStates/NoTagsDoq';

type EmptyStateProps = {
  hasNoTags: boolean;
  hasExcludedTags: boolean;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
};

const VIEW_ID = ViewIds.TAGSCAPE;

const EmptyState = ({
  hasNoTags,
  hasExcludedTags,
  isPresentationMode,
  isWorkspaceEmpty,
}: EmptyStateProps) => {
  if (hasExcludedTags) {
    return (
      <NoTagsWithSettingsDoq
        viewId={VIEW_ID}
        doqType={DoqType.TAGSCAPE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.TAGSCAPE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasNoTags) {
    return (
      <NoTagsDoq
        doqType={DoqType.TAGSCAPE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
