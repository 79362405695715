import { StyledDropdownMenuOption } from './atoms';
import { NOT_IN_USE_TAG_MESSAGE } from '../constants';
import { Tag } from '@ardoq/status-ui';

type DropdownMenuOptionProps = {
  label: string;
  isActive: boolean;
  isUnused?: boolean;
  onClick?: () => void;
};

export const DropdownMenuOption = ({
  label,
  isUnused,
  isActive,
  onClick,
}: DropdownMenuOptionProps) => (
  <StyledDropdownMenuOption
    label={label}
    isChecked={isActive}
    onClick={onClick}
    key={label}
    rightContent={isUnused ? <Tag label={NOT_IN_USE_TAG_MESSAGE} /> : undefined}
    isDisabled={isUnused}
    keepRightContentActive
    truncateTitle
  />
);
