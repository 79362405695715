import { ComponentProps } from 'react';
import { connect } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { SettingsBar } from '@ardoq/settings-bar';
import { ExportConfigParams } from '@ardoq/view-settings';
import { viewHasLegend } from 'views/metaInfoTabs';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { ViewSettingsShape, ViewStreamShape } from './types';
import { getLeftMenu } from './settingsBar';

const VIEW_ID = ViewIds.CAPABILITY_MAP;

type CapabilityMapSettingsBarProps = {
  legendOnClick: () => void;
  exports: ExportConfigParams;
  viewModel: ViewStreamShape;
  currentSettings: ViewSettingsShape;
} & Omit<
  ComponentProps<typeof SettingsBar>,
  'viewId' | 'rightMenu' | 'leftMenu'
>;

const CapabilityMapSettingsBar = ({
  viewModel,
  currentSettings: viewState,
  showLeftSettingsSection,
  showRightSettingsSection,
  exports,
  legendOnClick,
}: CapabilityMapSettingsBarProps) => (
  <div className="menuContainer">
    <SettingsBar
      viewId={VIEW_ID}
      leftMenu={getLeftMenu({ viewModel, viewState })}
      showLeftSettingsSection={showLeftSettingsSection}
      showRightSettingsSection={showRightSettingsSection}
      rightMenu={getRightMenuConfig({
        viewId: VIEW_ID,
        viewstate: viewState,
        exports,
        withLegend: viewHasLegend(VIEW_ID),
        knowledgeBaseLink: KnowledgeBaseLink.CAPABILITY_MAP,
        legendOnClick,
        onViewSettingsUpdate,
      })}
    />
  </div>
);

const viewSettings$ =
  getViewSettingsStreamWithChanges<ViewSettingsShape>(VIEW_ID);

export default connect(CapabilityMapSettingsBar, viewSettings$);
