import { ReferenceDirection } from '@ardoq/graph';
import { CreateReferencesState } from './createReferencesTypes';

export const defaultCreateReferencesState: CreateReferencesState = {
  isLinking: false,
  linkTarget: null,
  linkSourceNodeId: null,
  linkSourceIds: [],
  linkPosition: { x: 0, y: 0 },
  linkSourceStartPosition: { x: 0, y: 0 },
  hasLinkTarget: false,
  refDirection: ReferenceDirection.UNSET,
  abortController: null,
  shouldShowRefDrawing: false,
  navigatorContainerBox: null,
};
