import { EntityBackground, RootContainer } from './atoms';
import type { MouseEventHandler, OptionalContainerProps } from '../types';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import ComponentLabelDecorator from './ComponentLabelDecorator';
import { treemapCommands } from '../commands';
import { getIsHighlighted } from '../helpers';

const handleMouseOver: MouseEventHandler = (e, id) => {
  e.stopPropagation();
  treemapCommands.setHoveredItemState(id);
};

const getHandleClickCb =
  (isCurrentlyFocused: boolean): MouseEventHandler =>
  (event, componentId) => {
    event.stopPropagation();
    treemapCommands.setFocusedItemState(componentId!, isCurrentlyFocused);
    if (isCurrentlyFocused) {
      treemapCommands.setHoveredItemState(null);
    }
  };

const OptionalContainer = ({
  data,
  children,
  maxLabelWidth,
  labelFontSize,
  hoveredComponentId,
  focusedComponentId,
}: OptionalContainerProps) => {
  const { id, displayOptions } = data;
  if (!id) {
    return <>{children}</>;
  }
  const isHighlighted = getIsHighlighted(
    id,
    hoveredComponentId,
    focusedComponentId
  );
  const handleClick = getHandleClickCb(id === focusedComponentId);
  return (
    <RootContainer
      className="component"
      style={{
        backgroundColor: displayOptions.backgroundColor,
        color: displayOptions.color,
      }}
      $isHighlighted={isHighlighted}
      onMouseOver={e => handleMouseOver(e, id)}
      onMouseLeave={() => treemapCommands.setHoveredItemState(null)}
      onClick={e => handleClick(e, id)}
      {...{ [COMPONENT_ID_ATTRIBUTE]: id }}
    >
      <EntityBackground
        $isHighlighted={isHighlighted}
        $backgroundColor={displayOptions.backgroundColor}
      />
      <ComponentLabelDecorator
        data={data}
        maxLabelWidth={maxLabelWidth}
        labelFontSize={labelFontSize}
      />
      {children}
    </RootContainer>
  );
};

export default OptionalContainer;
