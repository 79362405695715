import {
  Font,
  type ILabel,
  type INode,
  TextMeasurePolicy,
  TextRenderSupport,
} from '@ardoq/yfiles';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';

const getFittingFontSize = (
  label: ILabel,
  measurePolicy: TextMeasurePolicy
) => {
  const maxFontSize = (label.owner as INode).layout.height;
  const widthAtMaxFontSize = TextRenderSupport.measureText({
    text: label.text,
    font: new Font(ARDOQ_DEFAULT_FONT_FAMILY, maxFontSize),
    measurePolicy,
  }).width;
  const ratio = (label.owner as INode).layout.width / widthAtMaxFontSize;
  return maxFontSize * Math.min(1, ratio);
};

export default getFittingFontSize;
