import { GetCssClassNamesOption } from '@ardoq/data-model';

import { ViewIds } from '@ardoq/api-types';
import { ImageSrc } from '@ardoq/data-model';

export const MATERIAL_ICONS_DEFAULT_FONT_SIZE = 24;
export const NEVER_SHOW_AGAIN = 'neverShowCloneInfoAgain';
export const VIEW_SCROLLBAR_WIDTH_CSS_VARIABLE = '--view-scrollbar-width';

export const GET_CSS_BACKGROUND_OPTIONS: GetCssClassNamesOption = Object.freeze(
  {
    useAsBackgroundStyle: true,
  }
);

export const EXCLUDE_UNUSED_TAGS = 'Exclude unused tags';
export const HIDE_EMPTY_VALUES = 'Hide empty values';

const thumbnails: {
  [key in ViewIds]?: ImageSrc;
} = {
  [ViewIds.SWIMLANE]: `swimlane.jpg`,
  [ViewIds.DEPWHEEL]: 'dependencyWheel.jpg',
  [ViewIds.TREEMAP]: 'treemap.jpg',
  [ViewIds.TAGSCAPE]: 'tagscape.jpg',
  [ViewIds.COMPONENTTREE]: 'componentTree.jpg',
  [ViewIds.NETWORK]: 'networkView.jpg',
  [ViewIds.RELATIONSHIPS_3]: 'relationshipsView3.jpg',
  [ViewIds.TABLEVIEW]: 'tableview.jpg',
  [ViewIds.SPIDER]: 'spiderChart.jpg',
  [ViewIds.ROADMAP]: 'product_roadmap.jpg',
  [ViewIds.DEPENDENCY_MAP_2]: 'dependencyMap2.jpg',
  [ViewIds.DEPMATRIX]: 'dependencyMatrix.jpg',
  [ViewIds.REFERENCETABLE]: 'reference-table.jpg',
  [ViewIds.BUBBLE]: 'bubbles.jpg',
  [ViewIds.CAPABILITY_MAP]: 'capability-map.jpg',
  [ViewIds.TIMELINE]: 'timeline.jpg',
  [ViewIds.BLOCK_DIAGRAM]: 'blockDiagram.jpg',
  [ViewIds.PAGESVIEW]: 'Pages-view-2.jpg',
  [ViewIds.COMPONENT_MATRIX]: 'componentMatrix.jpg',
  [ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM]: 'circular.jpg',
  [ViewIds.ISOMETRIC_BLOCK_DIAGRAM]: 'blockDiagram.jpg',
  [ViewIds.BLOCKS]: 'blockDiagram.jpg',
  [ViewIds.MODERNIZED_BLOCK_DIAGRAM]: 'blockDiagram.jpg',
  [ViewIds.HIERARCHIC_IN_GRID]: 'blockDiagram.jpg',
};

export const getViewThumbnailSrc = (viewId: ViewIds) =>
  thumbnails[viewId] ? `img/viewsExample/${thumbnails[viewId]}` : '';

export const NO_BREAK_SPACE = '\xa0';
