import styled from 'styled-components';
import { ArdoqId } from '@ardoq/api-types';
import { addComponentsToScenario } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/actionWrappers';
import { trackClickStartAddToScenario } from 'scope/tracking';
import { getSelectedIds } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/scenarioRelatedSelection$';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { colors } from '@ardoq/design-tokens';

export const ADD_TO_SCENARIO_BUTTON_WIDTH = 173;
export const ADD_TO_SCENARIO_BUTTON_HEIGHT = 48;
const CORNER_RADIUS = 4;
const Y_OFFSET = 34;
const ICON_X_OFFSET = 11;
const TEXT_X_OFFSET = ICON_X_OFFSET + 30;

const ButtonWrapper = styled.g`
  cursor: pointer;
  pointer-events: all;
  fill: ${colors.surfaceActionSecondary};
  &:hover {
    fill: ${colors.surfaceActionSecondaryHover};
  }
`;

const Text = styled.text`
  font-size: 28px;
  fill: ${colors.textDefault};
`;

const Icon = styled(Text)`
  font-size: 40px;
`;

interface ArdoqLabelStyleElementProps {
  componentId: ArdoqId;
}

const ArdoqLabelStyleElement = ({
  componentId,
}: ArdoqLabelStyleElementProps) => (
  <ButtonWrapper
    onClick={() => {
      const selectedIds = getSelectedIds();
      addComponentsToScenario([
        ...new Set([
          ...selectedIds.filter(componentInterface.isComponent),
          componentId,
        ]),
      ]);
      trackClickStartAddToScenario('relatedView');
    }}
  >
    <rect
      width={ADD_TO_SCENARIO_BUTTON_WIDTH}
      height={ADD_TO_SCENARIO_BUTTON_HEIGHT}
      rx={CORNER_RADIUS}
      ry={CORNER_RADIUS}
    />
    <Icon y={Y_OFFSET} x={ICON_X_OFFSET}>
      +
    </Icon>
    <Text y={Y_OFFSET} x={TEXT_X_OFFSET}>
      INCLUDE
    </Text>
  </ButtonWrapper>
);

export default ArdoqLabelStyleElement;
