import { colors } from '@ardoq/design-tokens';
import { logError } from '@ardoq/logging';
import {
  INode,
  IRenderContext,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { GraphNode } from '@ardoq/graph';
import SparkMD5 from 'spark-md5';
import { DATA_RENDER_HASH } from 'yfilesExtensions/styles/consts';
import { componentInterface } from '@ardoq/component-interface';
import { getImageColorFilterValueForModel } from 'views/ConditionalFormattingImageColorFilters';
import { createSvgElement } from '@ardoq/dom-utils';

const createRenderDataCache = (
  { layout: { width, height } }: INode,
  graphNode: GraphNode,
  fill: string
) =>
  SparkMD5.hash(
    [
      width,
      height,
      graphNode.getImage(),
      graphNode.getShape(),
      fill,
      graphNode.modelId,
    ].join(',')
  );

const errorElement = (node: INode) =>
  createSvgElement('rect', {
    x: `${node.layout.x}`,
    y: `${node.layout.y}`,
    width: `${node.layout.width}`,
    height: `${node.layout.height}`,
  });

const createElement = (node: INode, graphNode: GraphNode, fill: string) => {
  const {
    layout: { width, height },
  } = node;
  const image = graphNode.getImage();
  if (image) {
    const result = createSvgElement('image', {
      href: image,
      width: `${width}`,
      height: `${height}`,
    });
    const filter = getImageColorFilterValueForModel(graphNode.modelId);
    if (filter) {
      result.setAttribute('filter', filter);
    }
    return result;
  }

  const shapeId = graphNode.getShape();
  if (shapeId) {
    const shapeElement = document.querySelector(`#${shapeId}`)?.cloneNode(true);
    if (!(shapeElement instanceof SVGSVGElement)) {
      logError(Error('node shape not found.'));
      return null;
    }
    shapeElement.setAttribute('width', `${width}`);
    shapeElement.setAttribute('height', `${height}`);
    shapeElement.setAttribute('fill', fill);
    shapeElement.setAttribute('stroke', colors.black);
    shapeElement.setAttribute('color', fill);

    const container = createSvgElement('g'); // shapeElement may be an svg element, which does not support the transform attribute, which we will need.
    container.appendChild(shapeElement);
    return container;
  }
  const rect = createSvgElement('rect', {
    width: `${width}`,
    height: `${height}`,
  });

  rect.setAttribute('fill', fill ?? 'transparent');
  rect.setAttribute('stroke', colors.black);

  return rect;
};

const createVisual = (node: INode, graphNode: GraphNode, fill: string) => {
  const element = createElement(node, graphNode, fill) ?? errorElement(node);
  const { modelId } = graphNode;
  element.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
  element.setAttribute('class', graphNode.isComponent() ? 'component' : '');
  element.setAttribute(
    DATA_RENDER_HASH,
    createRenderDataCache(node, graphNode, fill)
  );
  const {
    layout: { x, y },
  } = node;
  SvgVisual.setTranslate(element, x, y);
  return new SvgVisual(element);
};

class ProteanNodeStyle extends NodeStyleBase {
  protected override createVisual(
    _: IRenderContext,
    node: INode
  ): Visual | null {
    if (!(node.tag instanceof GraphNode)) {
      logError(Error('unrecognized node data.'));
      return new SvgVisual(errorElement(node));
    }

    const graphNode = node.tag;
    const { modelId } = graphNode;
    const { fill = 'transparent' } =
      componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
        useAsBackgroundStyle: false,
      });
    return createVisual(node, node.tag, fill);
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    node: INode
  ): Visual | null {
    const element = (oldVisual as SvgVisual).svgElement;
    const hash = element.getAttribute(DATA_RENDER_HASH);
    const graphNode = node.tag as GraphNode | null;
    if (!graphNode) {
      return null;
    }
    const { modelId } = graphNode;
    const { fill = 'transparent' } =
      componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
        useAsBackgroundStyle: false,
      });
    const newHash = createRenderDataCache(node, graphNode, fill);
    if (hash === newHash) {
      const {
        layout: { x, y },
      } = node;
      SvgVisual.setTranslate(element, x, y);
      return oldVisual;
    }
    return createVisual(node, graphNode, fill);
  }
}
export default ProteanNodeStyle;
