import { snakeCase, uniqBy, zip } from 'lodash';
import {
  MetaModelComponentTypeWithNullTypes,
  MetaModelReferenceTypeWithNullTypes,
  MetaModelWithNullTypes,
  isMetaModelComponentType,
  isMetaModelReferenceType,
  isMetaModelTriple,
} from './types';
import {
  META_MODEL_MODEL_ID,
  META_MODEL_NAME,
  META_MODEL_WORKSPACE_ID,
  NULL_TYPE_NAME,
  START_OFFSET_REF_TYPE_ID,
} from './consts';
import {
  APIComponentAttributes,
  APIEntityType,
  APIModelAttributes,
  APIScopeData,
  APIReferenceAttributes,
  APIResourcePermissionAttributes,
  ArdoqId,
  ArrowType,
  DirectedTripleWithFilters,
  LineType,
  MetaModel,
  MetaModelComponentType,
  MetaModelReferenceType,
  MetaModelTriple,
  PermissionAccessLevel,
  PermissionType,
  ResourceType,
  ViewIds,
} from '@ardoq/api-types';
import SparkMD5 from 'spark-md5';
import { getCurrentLocale, Locale, localeCompare } from '@ardoq/locale';
import { currentDateTimeISO, currentTimestamp } from '@ardoq/date-time';
import { isArdoqError } from '@ardoq/common-helpers';
import { enhancePartialScopeData } from '@ardoq/renderers';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import {
  LoadMetaModelAsScopedDataState,
  MetaModelWithContextLoadedState,
} from 'viewpointBuilder/metaModel/loadMetaModelTypes';
import { MetaModelLoadedState } from './loadMetaModel';

type CreateMetaDataArgs = {
  isoDate: string;
  userId: string;
  userName: string;
  userEmail: string | null;
};

const createMetaData = ({
  isoDate,
  userId,
  userName,
  userEmail,
}: CreateMetaDataArgs) => ({
  ['created-by']: userId,
  ['last-modified-by']: userId,
  ['last-updated']: isoDate,
  created: isoDate,
  createdBy: userId,
  createdByEmail: userEmail ?? '',
  createdByName: userName,
  lastModifiedBy: userId,
  lastModifiedByEmail: userEmail ?? '',
  lastModifiedByName: userName,
  lastUpdated: isoDate,
});

const createComponentTypes = (
  componentTypes: MetaModelComponentType[],
  typeIds: string[]
) =>
  zip(componentTypes, typeIds).map(([type, typeId], index) => {
    if (type === undefined || typeId === undefined)
      throw Error('list length mismatch');
    const { name, style } = type;
    return {
      id: typeId,
      name,
      returnsValue: false,
      index,
      children: {},
      ...style,
      ardoq: {
        entityType: APIEntityType.COMPONENT_TYPE,
      },
    };
  });

const getTypeNameToId = <K>(types: { name: string; id: K }[]) => {
  const map = new Map(types.map(({ name, id }) => [name, id]));
  return (name: string) => {
    const typeId = map.get(name);
    if (typeId === undefined) {
      throw Error(`No type id for type name '${name}'`);
    }
    return typeId;
  };
};

const createReferenceTypes = (referenceTypes: MetaModelReferenceType[]) =>
  referenceTypes.map(({ name, style }, index) => {
    const typeId = index + START_OFFSET_REF_TYPE_ID;
    return {
      id: typeId,
      name,
      index,
      returnsValue: false,
      ...style,
    };
  });

type CreateModelArgs = {
  componentTypes: ReturnType<typeof createComponentTypes>;
  id: string;
  metaData: ReturnType<typeof createMetaData>;
  referenceTypes: ReturnType<typeof createReferenceTypes>;
  category?: string;
};

const createModel = ({
  componentTypes,
  id,
  metaData,
  referenceTypes,
  category = 'Other',
}: CreateModelArgs): APIModelAttributes => ({
  _id: id,
  ...metaData,
  ardoq: { 'entity-type': APIEntityType.MODEL },
  blankTemplate: false,
  category,
  defaultViews: [],
  description: '',
  flexible: true,
  maxReferenceTypeKey: START_OFFSET_REF_TYPE_ID + referenceTypes.length,
  name: META_MODEL_NAME,
  referenceTypes: {
    '2': {
      color: '#f00',
      id: 2,
      index: 2,
      line: LineType.DASHED,
      lineBeginning: ArrowType.NONE,
      lineEnding: ArrowType.BOTH_FILLED,
      name: 'Implicit',
      returnsValue: false,
      svgStyle: null,
    },
    ...Object.fromEntries(referenceTypes.map(type => [type.id, type])),
  },
  root: Object.fromEntries(componentTypes.map(type => [type.id, type])),
  startView: ViewIds.BLOCK_DIAGRAM,
  useAsTemplate: false,
  _version: -1,
});

type CreateWorkspaceArgs = {
  componentCount: number;
  id: string;
  metaData: ReturnType<typeof createMetaData>;
  modelId: string;
  name: string;
};

const createWorkspace = ({
  componentCount,
  id,
  metaData,
  modelId,
  name,
}: CreateWorkspaceArgs) => ({
  _id: id,
  _version: 1,
  ...metaData,
  'comp-counter': componentCount,
  'delete-pending': null,
  'workspace-key': id.slice(0, 6).toUpperCase(),
  ardoq: { 'entity-type': APIEntityType.WORKSPACE },
  componentModel: modelId,
  componentTemplate: null,
  defaultPerspective: null,
  defaultSort: null,
  description: '',
  folder: null,
  name,
  origin: null,
  startView: ViewIds.BLOCK_DIAGRAM,
  type: '1',
  views: [ViewIds.PAGESVIEW, ViewIds.BLOCK_DIAGRAM],
  subdivisionMembership: [],
  gremlinEnabled: true,
});

type CreateComponentArgs = {
  id: string;
  incomingReferenceCount: number;
  metaData: ReturnType<typeof createMetaData>;
  modelId: string;
  name: string;
  order: number;
  outgoingReferenceCount: number;
  rootWorkspaceId: string;
  typeId: string;
  typeName: string;
  subdivisionMembership: ArdoqId[];
};

const createComponent = ({
  id,
  incomingReferenceCount,
  metaData,
  modelId,
  name,
  order,
  outgoingReferenceCount,
  rootWorkspaceId,
  typeId,
  typeName,
  subdivisionMembership,
}: CreateComponentArgs): APIComponentAttributes => ({
  _id: id,
  _order: order,
  _version: 1,
  ...metaData,
  'component-key': id.slice(0, 6).toUpperCase(),
  ardoq: {
    entityType: APIEntityType.COMPONENT,
    incomingReferenceCount,
    outgoingReferenceCount,
  },
  children: [],
  color: undefined,
  description: '',
  icon: undefined,
  image: undefined,
  isPublic: null,
  model: modelId,
  name,
  origin: null,
  parent: null,
  rootWorkspace: rootWorkspaceId,
  shape: undefined,
  type: typeName,
  typeId,
  subdivisionMembership: subdivisionMembership ?? [],
});

type CreateReferenceArgs = {
  displayText: string;
  id: string;
  metaData: ReturnType<typeof createMetaData>;
  modelId: string;
  rootWorkspaceId: string;
  sourceId: string;
  targetId: string;
  typeId: number;
};

const createReference = ({
  displayText,
  id,
  metaData,
  modelId,
  rootWorkspaceId,
  sourceId,
  targetId,
  typeId,
}: CreateReferenceArgs): APIReferenceAttributes => ({
  _id: id,
  _version: 1,
  ...metaData,
  ardoq: { 'entity-type': APIEntityType.REFERENCE },
  description: '',
  displayText,
  model: modelId,
  mustBeSaved: null,
  order: 1,
  origin: null,
  returnValue: null,
  rootWorkspace: rootWorkspaceId,
  source: sourceId,
  sourceCardinality: null,
  target: targetId,
  targetCardinality: null,
  targetWorkspace: rootWorkspaceId,
  type: typeId,
});

const generateModelTypeId = (offset = 0) => {
  // Offset makes sure we can generate multiple type-id's that are surely unique
  return `p${currentTimestamp() - offset + Math.round(Math.random() * 10000)}`;
};

type IncomingOutgoingCountDict = {
  incoming: Record<string, number>;
  outgoing: Record<string, number>;
};

const countIncomingAndOutgoingReferences = (
  triples: MetaModelTriple[]
): IncomingOutgoingCountDict => {
  const incoming: Record<string, number> = {};
  const outgoing: Record<string, number> = {};
  // Classic for loop for performance reasons, see
  // https://ardoqcom.atlassian.net/browse/ARD-22079 for more context.
  for (const { sourceComponentType, targetComponentType } of triples) {
    if (incoming[targetComponentType] === undefined) {
      incoming[targetComponentType] = 0;
    }
    if (outgoing[sourceComponentType] === undefined) {
      outgoing[sourceComponentType] = 0;
    }
    incoming[targetComponentType]++;
    outgoing[sourceComponentType]++;
  }
  return { incoming, outgoing };
};

type CreatePermissionArgs = {
  id: string;
  resourceId: string;
  resourceType: ResourceType;
  userId: string;
  userName: string;
  permissions: PermissionAccessLevel[];
  writeAccess: boolean;
  metaData: ReturnType<typeof createMetaData>;
};

const createPermission = ({
  id,
  metaData,
  permissions,
  resourceId,
  resourceType,
  userId,
  userName,
  writeAccess,
}: CreatePermissionArgs): APIResourcePermissionAttributes => ({
  _id: id,
  ...metaData,
  'resource-type': resourceType,
  ardoq: { 'entity-type': APIEntityType.PERMISSION },
  groups: [],
  permissions: [
    {
      _id: userId,
      name: userName,
      permissions: permissions,
      type: PermissionType.USER,
      writeAccess,
    },
  ],
  resource: resourceId,
});

const isMetaModelWorkspace = (id: string) => id === META_MODEL_WORKSPACE_ID;

type MetaModelToScopeDataArgs = {
  metaModel: MetaModel;
} & CreateMetaDataArgs;

const metaModelToScopeData = ({
  metaModel,
  isoDate,
  userEmail,
  userId,
  userName,
}: MetaModelToScopeDataArgs): APIScopeData => {
  const metaData = createMetaData({ isoDate, userEmail, userId, userName });
  const counts = countIncomingAndOutgoingReferences(metaModel.triples);
  const referenceTypes = createReferenceTypes(metaModel.referenceTypes);
  // The component types of the meta model are unique, meaning we can use
  // them as ids.
  const componentTypes = createComponentTypes(
    metaModel.componentTypes,
    metaModel.componentTypes.map(({ name }) => snakeCase(name))
  );

  const getCompTypeIdWithTypeName = getTypeNameToId<string>(componentTypes);

  const getRefTypeIdWithTypeName = getTypeNameToId<number>(referenceTypes);

  const model = createModel({
    id: META_MODEL_MODEL_ID,
    metaData,
    componentTypes,
    referenceTypes,
  });

  const workspace = createWorkspace({
    id: META_MODEL_WORKSPACE_ID,
    modelId: META_MODEL_MODEL_ID,
    metaData,
    componentCount: componentTypes.length,
    name: 'Meta Model Triples',
  });

  const components: APIComponentAttributes[] = componentTypes.map(({ name }) =>
    createComponent({
      // The component types of the meta model are unique, meaning we can use
      // them as ids.
      id: SparkMD5.hash(name),
      incomingReferenceCount: counts.incoming[name],
      metaData,
      modelId: META_MODEL_MODEL_ID,
      name,
      order: 1,
      outgoingReferenceCount: counts.outgoing[name],
      rootWorkspaceId: META_MODEL_WORKSPACE_ID,
      typeId: getCompTypeIdWithTypeName(name),
      typeName: name,
      subdivisionMembership: [],
    })
  );

  const componentNameToIdMap = new Map(
    components.map(({ name, _id }) => [name, _id])
  );

  const references = metaModel.triples.map(
    ({ sourceComponentType, targetComponentType, referenceType }) =>
      metaModelOperations.createReference({
        displayText: referenceType,
        // Triples are unique in the meta model, we can use them to create an id.
        id: SparkMD5.hash(
          `${sourceComponentType}${referenceType}${targetComponentType}`
        ),
        metaData,
        modelId: META_MODEL_MODEL_ID,
        rootWorkspaceId: META_MODEL_WORKSPACE_ID,
        sourceId: componentNameToIdMap.get(sourceComponentType)!,
        targetId: componentNameToIdMap.get(targetComponentType)!,
        typeId: getRefTypeIdWithTypeName(referenceType),
      })
  );

  return {
    workspaces: [workspace],
    models: [model],
    fields: [],
    components,
    references,
    tags: [],
    permissions: [],
    scopeComponents: [],
  };
};

const checkAndFixMetaModel = ({
  componentTypes,
  referenceTypes,
  triples,
}: MetaModelWithNullTypes): MetaModel => ({
  componentTypes: componentTypes.map(componentType =>
    isMetaModelComponentType(componentType)
      ? componentType
      : fixComponentType(componentType)
  ),
  referenceTypes: referenceTypes.map(referenceType =>
    isMetaModelReferenceType(referenceType)
      ? referenceType
      : fixReferenceType(referenceType)
  ),
  triples: triples.map(triple =>
    isMetaModelTriple(triple)
      ? triple
      : {
          sourceComponentType: triple.sourceComponentType ?? NULL_TYPE_NAME,
          targetComponentType: triple.targetComponentType ?? NULL_TYPE_NAME,
          referenceType: triple.referenceType ?? NULL_TYPE_NAME,
        }
  ),
});

const fixReferenceType = (
  referenceType: MetaModelReferenceTypeWithNullTypes
): MetaModelReferenceType => ({
  ...referenceType,
  name: referenceType.name ?? NULL_TYPE_NAME,
  style: {
    ...referenceType.style,
    color: referenceType.style.color ?? 'black',
    line: referenceType.style.line ?? LineType.SOLID,
    lineEnding: referenceType.style.lineEnding ?? ArrowType.BOTH,
  },
});

const fixComponentType = (
  componentType: MetaModelComponentTypeWithNullTypes
): MetaModelComponentType => ({
  ...componentType,
  name: componentType.name ?? NULL_TYPE_NAME,
  style: {
    ...componentType.style,
    level: componentType.style.level ?? 0,
  },
});

const typeNameToWorkspaceAndFieldMaps = (metaModel: MetaModel) => {
  const typeNameToWorkspaceMap = new Map(
    metaModel.componentTypes.map(({ name, usedInWorkspaceIds }) => [
      name,
      usedInWorkspaceIds,
    ])
  );
  const componentTypeNameToFieldMap = new Map(
    metaModel.componentTypes.map(({ name, fieldNames }) => [name, fieldNames])
  );
  const referenceTypeNameToFieldMap = new Map(
    metaModel.referenceTypes.map(({ name, fieldNames }) => [name, fieldNames])
  );
  return {
    typeNameToWorkspaceMap,
    componentTypeNameToFieldMap,
    referenceTypeNameToFieldMap,
  };
};

const toTripleKey = ({
  sourceComponentType,
  referenceType,
  targetComponentType,
}: MetaModelTriple): string =>
  [sourceComponentType, referenceType, targetComponentType].join('');

const extendMetamodelWithTriples = (
  { componentTypes, referenceTypes, triples }: MetaModel,
  additionalTriples: MetaModelTriple[]
): MetaModel => {
  const metamodelComponentTypeNames = new Set(
    componentTypes.map(({ name }) => name)
  );
  const metamodelReferenceTypeNames = new Set(
    referenceTypes.map(({ name }) => name)
  );

  const additionalTriplesNormalized = additionalTriples.map(triple => ({
    ...triple,
    referenceType:
      'ardoq_parent' === triple.referenceType
        ? 'Is Parent Of'
        : triple.referenceType,
  }));

  const additionalComponentTypes = additionalTriplesNormalized
    .flatMap(({ sourceComponentType, targetComponentType }) => [
      sourceComponentType,
      targetComponentType,
    ])
    .filter(name => !metamodelComponentTypeNames.has(name))
    .map(toComponentFallbackType);

  const additionalReferenceTypes = additionalTriplesNormalized
    .map(({ referenceType }) => referenceType)
    .filter(name => !metamodelReferenceTypeNames.has(name))
    .map(toReferenceFallbackType);

  const locale = getCurrentLocale();
  const sortByName = <T extends { name: string }>(
    { name: nameA }: T,
    { name: nameB }: T
  ) => localeCompare(nameA, nameB, locale);

  // Sorting the metamodel to make it comparable with `isEqual` with another
  // metamodel.
  return {
    componentTypes: [...componentTypes, ...additionalComponentTypes]
      .map(toSortedTypes)
      .sort(sortByName),
    referenceTypes: [...referenceTypes, ...additionalReferenceTypes]
      .map(toSortedTypes)
      .sort(sortByName),
    triples: uniqBy(
      [...triples, ...additionalTriplesNormalized],
      toTripleKey
    ).sort(sortTriples(locale)),
  };
};

const toSortedTypes = <
  T extends { usedInWorkspaceIds: ArdoqId[]; fieldNames: string[] },
>(
  type: T
) => ({
  ...type,
  usedInWorkspaceIds: type.usedInWorkspaceIds.sort(),
  fieldNames: type.fieldNames.sort(),
});

const sortTriples =
  (locale: Locale) => (tripleA: MetaModelTriple, tripleB: MetaModelTriple) => {
    if (tripleA.sourceComponentType !== tripleB.sourceComponentType) {
      return localeCompare(
        tripleA.sourceComponentType,
        tripleB.sourceComponentType,
        locale
      );
    }
    if (tripleA.referenceType !== tripleB.referenceType) {
      return localeCompare(
        tripleA.referenceType,
        tripleB.referenceType,
        locale
      );
    }
    return localeCompare(
      tripleA.targetComponentType,
      tripleB.targetComponentType,
      locale
    );
  };

const toComponentFallbackType = (name: string): MetaModelComponentType => ({
  name,
  style: {
    color: 'blue',
    icon: 'None',
    image: null,
    level: 0,
    shape: null,
    standard: null,
  },
  usedInWorkspaceIds: [],
  fieldNames: [],
});

const toReferenceFallbackType = (name: string): MetaModelReferenceType => ({
  name,
  style: {
    color: 'black',
    line: LineType.SOLID,
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.BOTH,
    svgStyle: null,
  },
  usedInWorkspaceIds: [],
  fieldNames: [],
});

const getTriplesFromPaths = (
  paths: DirectedTripleWithFilters[][]
): MetaModelTriple[] => {
  return uniqBy(
    paths.flatMap(path =>
      path.map(({ sourceType, targetType, referenceType }) => ({
        sourceComponentType: sourceType,
        referenceType,
        targetComponentType: targetType,
      }))
    ),
    toTripleKey
  );
};

const processMetaModelAndUser = ([metaModelResult, currentUser]: [
  MetaModelLoadedState,
  CurrentUserState,
]): LoadMetaModelAsScopedDataState => {
  if (isArdoqError(metaModelResult)) {
    return {
      error: metaModelResult.error.message,
      status: 'ERROR',
    };
  }

  const { _id: userId, email: userEmail, name: userName } = currentUser;
  const scopeData = metaModelOperations.metaModelToScopeData({
    metaModel: metaModelOperations.checkAndFixMetaModel(metaModelResult),
    isoDate: currentDateTimeISO(),
    userEmail,
    userId,
    userName,
  });

  const {
    typeNameToWorkspaceMap,
    componentTypeNameToFieldMap,
    referenceTypeNameToFieldMap,
  } = metaModelOperations.typeNameToWorkspaceAndFieldMaps(metaModelResult);

  return {
    referenceTypes: metaModelResult.referenceTypes,
    componentTypes: metaModelResult.componentTypes,
    componentNameAndFieldsIndex: componentTypeNameToFieldMap,
    referenceNameAndFieldsIndex: referenceTypeNameToFieldMap,
    componentNameAndWorkspacesIndex: typeNameToWorkspaceMap,
    data: enhancePartialScopeData(scopeData),
    status: 'DATA_LOADED',
  } satisfies MetaModelWithContextLoadedState;
};

export const metaModelOperations = {
  checkAndFixMetaModel,
  countIncomingAndOutgoingReferences,
  createComponent,
  createComponentTypes,
  createMetaData,
  createModel,
  createPermission,
  createReference,
  createReferenceTypes,
  createWorkspace,
  generateModelTypeId,
  getTypeNameToId,
  isMetaModelWorkspace,
  metaModelToScopeData,
  typeNameToWorkspaceAndFieldMaps,
  extendMetamodelWithTriples,
  getTriplesFromPaths,
  processMetaModelAndUser,
};
