import { componentInterface } from './componentInterface';
import { ArdoqId } from '@ardoq/api-types';

const allDescendantsReducer = (
  allDescendents: ArdoqId[],
  componentId: ArdoqId
): ArdoqId[] => {
  const descendents = componentInterface
    .getChildren(componentId)
    .reduce(allDescendantsReducer, []);
  // Create a shallow copy of the array to avoid mutating it directly
  const newAllDescendants = allDescendents.slice();
  newAllDescendants.push(componentId, ...descendents);
  return newAllDescendants;
};
export default allDescendantsReducer;
