import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useEffectOnce, useResizeObserver } from '@ardoq/hooks';
import {
  ColumnHeader,
  DependencyMatrixCell,
  DependencyMatrixTableBody,
  DependencyMatrixTableComponent,
  DependencyMatrixTableHead,
  HorizontalVirtualizedEmptySpace,
  RowHeader,
  ScrollContainer,
  VerticalVirtualizedEmptySpace,
} from '../atoms';
import {
  ComponentsData,
  DependencyMatrixViewSettings,
  ReferencesMap,
  ScrollPositions,
  TableScrollIndex,
} from '../types';
import { ArdoqId } from '@ardoq/api-types';
import { TargetComponent } from './TargetComponent';
import { HorizontalLabel, VerticalLabel } from './ComponentLabel';
import { DependencyMatrixRow } from './DependencyMatrixRow';
import recordViewScrollbarWidth from '../../recordViewScrollbarWidth';
import { CELL_SIZE, CONTENT_PANE_CLASSNAME } from '../consts';
import { getInfiniteScrollBounds, scrollIndexShouldUpdate } from '../utils';
import {
  PayloadLastClickedCell,
  dependencyMatrixLastClickedCell$,
} from '../viewModel$/dependencyMatrixLastClickedCell$';
import { dependencyMatrixCommands } from '../commands';

type DependencyMatrixProps = {
  viewSettings: DependencyMatrixViewSettings;
  targetData: ComponentsData;
  sourceData: ComponentsData;
  references: ReferencesMap;
  readOnly: boolean;
  isExporting: boolean;
  workspaceId: ArdoqId;
  selectedComponentId: ArdoqId;
  focusedComponentId: ArdoqId | null;
};

export const DependencyMatrix = (props: DependencyMatrixProps) => {
  const {
    sourceData,
    targetData,
    readOnly,
    references,
    viewSettings,
    isExporting,
    workspaceId,
    selectedComponentId,
    focusedComponentId,
  } = props;

  const { componentIds: targetIds, workspaceName: targetName } = targetData;
  const { componentIds: sourceIds, workspaceName: sourceName } = sourceData;

  const tableHead = useRef<HTMLTableHeaderCellElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [tableScrollIndexes, setTableScrollIndexes] =
    useState<TableScrollIndex>({
      verticalIndex: 0,
      horizontalIndex: 0,
    });

  const { width: containerWidth = 0, height: containerHeight = 0 } =
    useResizeObserver(containerRef);

  const renderCounts = useMemo(() => {
    const topOffset = tableHead.current ? tableHead.current.offsetHeight : 0;
    const leftOffset = tableHead.current ? tableHead.current.offsetWidth : 0;
    const tableWidth = containerWidth - leftOffset;
    const tableHeight = containerHeight - topOffset;

    return {
      horizontalCount: tableWidth / CELL_SIZE,
      verticalCount: tableHeight / CELL_SIZE,
    };
  }, [containerHeight, containerWidth]);

  const updateScrollIndex = useCallback(
    (scrollPosition: ScrollPositions) => {
      const { horizontalIndex, verticalIndex } = tableScrollIndexes;
      const { horizontalCount, verticalCount } = renderCounts;

      const newHorizontalIndex = Math.round(scrollPosition.left / CELL_SIZE);
      const newVerticalIndex = Math.round(scrollPosition.top / CELL_SIZE);

      if (
        scrollIndexShouldUpdate(
          horizontalIndex,
          newHorizontalIndex,
          horizontalCount
        ) ||
        scrollIndexShouldUpdate(verticalIndex, newVerticalIndex, verticalCount)
      ) {
        setTableScrollIndexes({
          horizontalIndex: newHorizontalIndex,
          verticalIndex: newVerticalIndex,
        });
      }
    },
    [renderCounts, tableScrollIndexes]
  );

  const onScrollContainerClicked = useCallback(() => {
    dependencyMatrixCommands.setFocusedItemState(null);
  }, []);

  const verticalBounds = useMemo(
    () =>
      getInfiniteScrollBounds({
        renderCount: renderCounts.verticalCount,
        currentIndex: tableScrollIndexes.verticalIndex,
        componentIds: sourceIds,
        isExporting,
      }),
    [
      isExporting,
      renderCounts.verticalCount,
      sourceIds,
      tableScrollIndexes.verticalIndex,
    ]
  );

  const horizontalBounds = useMemo(
    () =>
      getInfiniteScrollBounds({
        renderCount: renderCounts.horizontalCount,
        currentIndex: tableScrollIndexes.horizontalIndex,
        componentIds: targetIds,
        isExporting,
      }),
    [
      renderCounts.horizontalCount,
      isExporting,
      targetIds,
      tableScrollIndexes.horizontalIndex,
    ]
  );

  const debouncedScroll = useMemo(
    () =>
      debounce((scrollPositions: ScrollPositions) => {
        updateScrollIndex(scrollPositions);
      }, 10),
    [updateScrollIndex]
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const contentPane = containerRef.current.closest<HTMLElement>(
      `.${CONTENT_PANE_CLASSNAME}`
    );

    if (!contentPane) {
      return;
    }

    const observer = recordViewScrollbarWidth(
      contentPane,
      containerRef.current
    );
    return () => observer.disconnect();
  });

  const [lastClickedCell, setLastClickedCell] =
    useState<PayloadLastClickedCell | null>(null);
  useEffectOnce(() => {
    const lastClickedCellSubscription =
      dependencyMatrixLastClickedCell$.subscribe(newLastClickedCell =>
        setLastClickedCell(newLastClickedCell)
      );
    return () => {
      lastClickedCellSubscription.unsubscribe();
    };
  });

  return (
    <ScrollContainer
      ref={containerRef}
      $isExporting={isExporting}
      onClick={onScrollContainerClicked}
      onScroll={event => {
        event.persist();
        if (!(event.target instanceof Element)) {
          return;
        }
        debouncedScroll({
          top: event.target.scrollTop,
          left: event.target.scrollLeft,
        });
      }}
    >
      <DependencyMatrixTableComponent
        $isExporting={isExporting}
        $isReadOnly={false}
      >
        <DependencyMatrixTableHead>
          <tr>
            <ColumnHeader ref={tableHead} />
            <HorizontalVirtualizedEmptySpace
              $isExporting={isExporting}
              $width={horizontalBounds.preSize}
            />
            <ColumnHeader>
              <HorizontalLabel label={`Target: ${targetName}`} />
            </ColumnHeader>
            {horizontalBounds.componentIds.map(componentId => (
              <TargetComponent componentId={componentId} key={componentId} />
            ))}
            <HorizontalVirtualizedEmptySpace
              $isExporting={isExporting}
              $width={horizontalBounds.postSize}
            />
          </tr>
        </DependencyMatrixTableHead>

        <DependencyMatrixTableBody $isExporting={isExporting}>
          <VerticalVirtualizedEmptySpace
            $isExporting={isExporting}
            $height={verticalBounds.preSize}
          />
          {sourceName ? (
            <tr>
              <RowHeader>
                <VerticalLabel label={`Source: ${sourceName}`} />
              </RowHeader>
              <DependencyMatrixCell />
              <DependencyMatrixCell />
              {horizontalBounds.componentIds.map(targetId => (
                <DependencyMatrixCell key={targetId} />
              ))}
            </tr>
          ) : null}
          {verticalBounds.componentIds.map(componentId => (
            <DependencyMatrixRow
              componentId={componentId}
              workspaceId={workspaceId}
              selectedComponentId={selectedComponentId}
              targetIds={horizontalBounds.componentIds}
              key={componentId}
              readOnly={readOnly}
              rowReferences={references[componentId] || []}
              viewSettings={viewSettings}
              isExporting={isExporting}
              highlightLastClickedTargetId={
                lastClickedCell?.highlightAsLastClicked &&
                lastClickedCell?.sourceId === componentId
                  ? lastClickedCell.targetId
                  : undefined
              }
              focusedComponentId={focusedComponentId}
            />
          ))}

          <VerticalVirtualizedEmptySpace
            $isExporting={isExporting}
            $height={verticalBounds.postSize}
          />
        </DependencyMatrixTableBody>
      </DependencyMatrixTableComponent>
    </ScrollContainer>
  );
};
