import {
  MetamodelComponentTypeContext,
  MetamodelContextSettings,
} from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import {
  ComponentTypeSubtitle,
  ReferenceCompletionForComponent,
} from 'metamodel/metamodelStats/atoms';
import BreadCrumbNavigation from './breadCrumbNavigation/BreadCrumbNavigation';
import { getFieldCompletion } from './utils';
import FieldCompletionWidget from './completion/FieldCompletionWidget';
import WorkspaceNavigationTitle from './workspaceNavigationTitle/WorkspaceNavigationTitle';
import { Box } from '@ardoq/layout';

const ComponentTypeStats = ({
  context,
  metamodel,
}: {
  context: MetamodelComponentTypeContext & MetamodelContextSettings;
  metamodel: Metamodel;
}) => {
  const content = metamodel.metamodel;
  const componentType = content.componentTypes[context.id];
  const componentFieldCompletion = getFieldCompletion(
    componentType,
    metamodel.metamodel.dateRangeFieldMap
  );
  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
          {context.name}
        </WorkspaceNavigationTitle>
      </Box>
      <ComponentTypeSubtitle fieldCompletion={componentFieldCompletion} />
      <ReferenceCompletionForComponent
        metamodel={metamodel}
        context={context}
      />
      <FieldCompletionWidget
        fieldCompletion={componentFieldCompletion}
        context={context}
        metamodel={metamodel}
      />
    </Box>
  );
};

export default ComponentTypeStats;
