import { AdvancedSearchFieldLiteral } from 'search/types';
import { APIFieldType, AdvancedSearchField } from '@ardoq/api-types';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';

const toObjReducer = (
  acc: AdvancedSearchFieldLiteral,
  obj: AdvancedSearchField
): AdvancedSearchFieldLiteral => ({
  ...acc,
  [obj.name]: { ...obj },
});

export const ardoqOid: AdvancedSearchField = {
  name: 'id',
  label: 'Ardoq OID',
  type: APIFieldType.TEXT,
};

export const ardoqId: AdvancedSearchField = {
  name: 'component-key',
  label: 'Ardoq ID',
  type: APIFieldType.TEXT,
};

export const name: AdvancedSearchField = {
  name: 'name',
  label: 'Name',
  type: APIFieldType.TEXT,
};

export const created: AdvancedSearchField = {
  name: 'created',
  label: 'Created date',
  type: APIFieldType.DATE_TIME,
};

export const lastUpdated: AdvancedSearchField = {
  name: 'last-updated',
  label: 'Last updated date',
  type: APIFieldType.DATE_TIME,
};

const description: AdvancedSearchField = {
  name: 'description',
  label: 'Description',
  type: APIFieldType.TEXT_AREA,
};

const displayText: AdvancedSearchField = {
  name: 'displayText',
  label: 'Display text',
  type: APIFieldType.TEXT,
};

const createdBy: AdvancedSearchField = {
  name: 'ardoq.createdByName',
  label: 'Created by',
  type: APIFieldType.TEXT,
};

const lastModifiedBy: AdvancedSearchField = {
  name: 'ardoq.lastModifiedByName',
  label: 'Last updated by',
  type: APIFieldType.TEXT,
};

const tags: AdvancedSearchField = {
  name: TAGS_FIELD_NAME,
  label: 'Tags',
  type: APIFieldType.TEXT,
};

const childCount: AdvancedSearchField = {
  name: 'child-count',
  label: 'Number of children',
  type: APIFieldType.NUMBER,
};

const rootWorkspace: AdvancedSearchField = {
  name: 'ardoq.rootWorkspaceName',
  label: 'Workspace',
  type: APIFieldType.TEXT,
};

const parent: AdvancedSearchField = {
  name: 'ardoq.parentComponentName',
  label: 'Parent',
  type: APIFieldType.TEXT,
};

const typeName: AdvancedSearchField = {
  name: 'typeName',
  label: 'Type',
  type: APIFieldType.TEXT,
};

const source: AdvancedSearchField = {
  name: 'ardoq.sourceComponentName',
  label: 'Source',
  type: APIFieldType.TEXT,
};

const target: AdvancedSearchField = {
  name: 'ardoq.targetComponentName',
  label: 'Target',
  type: APIFieldType.TEXT,
};

export const DEFAULT_FIELDS = [
  ardoqOid,
  parent,
  childCount,
  typeName,
  tags,
  rootWorkspace,
  source,
  target,
  name,
  lastUpdated,
  created,
  createdBy,
  lastModifiedBy,
];

export const component: AdvancedSearchFieldLiteral = [
  ardoqOid,
  ardoqId,
  name,
  typeName,
  lastUpdated,
  created,
  createdBy,
  lastModifiedBy,
  description,
  tags,
  rootWorkspace,
  parent,
  childCount,
].reduce(toObjReducer, {});

export const reference: AdvancedSearchFieldLiteral = [
  ardoqOid,
  name,
  typeName,
  lastUpdated,
  created,
  createdBy,
  lastModifiedBy,
  description,
  displayText,
  tags,
  rootWorkspace,
  source,
  target,
].reduce(toObjReducer, {});

export const workspace: AdvancedSearchFieldLiteral = [
  ardoqOid,
  name,
  lastUpdated,
  created,
  createdBy,
  lastModifiedBy,
  description,
].reduce(toObjReducer, {});
