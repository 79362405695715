import { ArdoqId } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { ComponentsData } from '../types';
import { composeComponentsIds } from './composeComponentsIds';
import { componentInterface } from 'modelInterface/components/componentInterface';

export const getTargetData = (
  workspaceId: ArdoqId | null,
  limit = Infinity
): ComponentsData => {
  if (!workspaceId) {
    return { componentIds: [], workspaceName: null, allComponentsCount: 0 };
  }

  const workspaceName = workspaceInterface.getWorkspaceName(workspaceId);
  const hierarchy = componentInterface.getComponentsHierarchyByWorkspaceIds([
    workspaceId,
  ]);
  const allComponentsCount =
    Object.keys(hierarchy).length -
    [hierarchy.root, hierarchy[workspaceId]].length;
  const isLimitingComponents = allComponentsCount > limit;

  const componentIds = composeComponentsIds({
    hierarchy,
    children: hierarchy[workspaceId].children || [],
    limit: isLimitingComponents ? limit : Infinity,
  });

  return { componentIds, workspaceName, allComponentsCount };
};
