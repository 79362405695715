import styled from 'styled-components';
import {
  ColumnHeaderBackground,
  ViewLegendContainerElement,
} from '@ardoq/graph';
import {
  ViewLegendRowContent,
  exportingViewLegendRowContentStyle,
} from '@ardoq/view-legend';

const ViewPaneElement = styled.div`
  flex: 1 1 50%;
  background-color: transparent;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 1px; /* Needs width for scroll to work */
  overflow: auto;

  .contentPane {
    flex: 1;
    height: 100%;
    overflow: auto;
    position: relative;
    .view-plugin {
      height: 100%;
    }

    svg {
      flex: 1;
    }
    &.forExport {
      height: fit-content;
      width: fit-content;
      flex: unset;
      ${ViewLegendContainerElement} {
        visibility: visible;
        max-height: unset;
        bottom: 0px;
        flex: unset;
        height: fit-content;
      }
      ${ViewLegendRowContent} {
        ${exportingViewLegendRowContentStyle}
      }
      ${ColumnHeaderBackground} {
        border-right: unset;
      }
    }
  }
`;
export default ViewPaneElement;
