import { ChevronIcon, Icon, IconName } from '@ardoq/icons';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { colors, shadowS } from '@ardoq/design-tokens';
import { LayoutConstraintRuleProps, LayoutRuleRowData } from './types';
import { header2, header4, text2 } from '@ardoq/typography';
import { LayoutRuleRowContainer } from './styles';
import LayoutRuleRow from './layoutRuleRow';
import { LayoutRulesInputPaneRef } from '../settingsBar/types';

const LayoutRulesPaneContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 344px;
  z-index: 100;
  box-shadow: ${shadowS};
`;

const PaneExpander = styled(ChevronIcon)`
  margin-left: auto;
  order: 2;
  cursor: pointer;
  background-color: inherit;
  border: none;
  padding: 0;
`;
const LayoutRulesPaneHeaderContainer = styled.div`
  display: flex;
  height: 56px;
  width: 100%;
  align-items: center;
  background-color: ${colors.grey90};
  padding: 16px;
`;

const LayoutRulesPaneContent = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 24px;
`;

const LayoutRulesDescriptionContainer = styled.div`
  ${text2}
  margin-bottom: 16px;
`;

const RuleColumnHeader = styled.div`
  ${header4}
`;
const PaneHeaderLabel = styled.div`
  ${header2}
`;
type LayoutRulesPaneHeaderProps = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};
const LayoutRulesPaneHeader = ({
  isCollapsed,
  setIsCollapsed,
}: LayoutRulesPaneHeaderProps) => {
  return (
    <LayoutRulesPaneHeaderContainer>
      <Icon
        iconName={IconName.TABLE_ROWS}
        iconSize={24}
        style={{ marginRight: '8px' }}
      />
      <PaneHeaderLabel>Set component position</PaneHeaderLabel>
      <PaneExpander
        iconSize={24}
        onClick={() => setIsCollapsed(!isCollapsed)}
        direction={isCollapsed ? 'down' : 'up'}
      />
    </LayoutRulesPaneHeaderContainer>
  );
};

type LayoutRulesInputPaneProps = {
  ruleRows: LayoutRuleRowData[];
  setLayoutRule: (layoutRule: LayoutConstraintRuleProps) => void;
};

const LayoutRulesInputPane = forwardRef<
  LayoutRulesInputPaneRef,
  LayoutRulesInputPaneProps
>(({ ruleRows, setLayoutRule }: LayoutRulesInputPaneProps, ref) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  useImperativeHandle(ref, () => ({ setIsCollapsed }));
  return (
    <LayoutRulesPaneContainer>
      <LayoutRulesPaneHeader
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      {isCollapsed ? null : (
        <LayoutRulesPaneContent>
          <LayoutRulesDescriptionContainer>
            Arrange component types from top to bottom with position numbers.
            <br />
            <br />
            You can also move specific components directly in the layout.
            Position numbers will not apply to those components.
          </LayoutRulesDescriptionContainer>
          <LayoutRuleRowContainer>
            <RuleColumnHeader style={{ width: 208 }}>
              Component type
            </RuleColumnHeader>
            <RuleColumnHeader style={{ width: 80 }}>Position</RuleColumnHeader>
          </LayoutRuleRowContainer>
          {ruleRows.map((ruleRow, index) => (
            <LayoutRuleRow
              key={`${ruleRow.ruleRowLabel}_${index}`}
              setLayoutRule={setLayoutRule}
              {...ruleRow}
            />
          ))}
        </LayoutRulesPaneContent>
      )}
    </LayoutRulesPaneContainer>
  );
});

export default LayoutRulesInputPane;
