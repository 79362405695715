import {
  CollapsibleGraphGroup,
  GraphItemsModel,
  LegendComponentType,
  LegendReferenceType,
  Rectangle,
  RelationshipDiagramViewSettings,
  Vector,
  ViewError,
} from '@ardoq/graph';
import { ArdoqId, ArrowType } from '@ardoq/api-types';
import { RepresentationData } from '@ardoq/data-model';
import { LayoutType } from './layout/layout';
import { SettingsConfig } from '@ardoq/view-settings';
import type { DropdownItem } from '@ardoq/dropdown-menu';
import { Projection } from './misc/projection';

export enum DragOperation {
  /** not draggging */
  None = 0,
  /** panning the window */
  Pan = 1,
  AreaSelect = 2,
  /** expander */
  Expand = 4,
  /** moving the selection */
  Move = 8,
  /** moving selection handle */
  Left = 16,
  /** moving selection handle */
  Right = 32,
  /** moving selection handle */
  Top = 64,
  /** moving selection handle */
  Bottom = 128,
  /** moving selection handle */
  LeftTop = Left | Top,
  /** moving selection handle */
  RightTop = Right | Top,
  /** moving selection handle */
  RightBottom = Right | Bottom,
  /** moving selection handle */
  LeftBottom = Left | Bottom,
}

export type NodeSelection = {
  empty: () => boolean;
  has: (node: BlocksViewNode) => boolean;
  forEach: (callbackfn: (node: BlocksViewNode) => void) => void;
  bounds: () => Rectangle;
  nodes: () => Set<BlocksViewNode>;

  group: () => BlocksViewNode | null;
  clear: () => void;
  add: (node: BlocksViewNode) => void;
  remove: (node: BlocksViewNode) => void;
};

export type BlocksViewProperties = {
  viewSettings: BlocksViewSettings;
  graph: BlocksViewGraph | null;
  componentTypes: LegendComponentType[];
  referenceModelTypes: LegendReferenceType[];
  errors: ViewError[];
  hasClones: boolean;
  groups: GraphItemsModel<CollapsibleGraphGroup>;
  isViewpointMode: boolean;
  viewInstanceId: string;
};

export type BlocksViewSettings = RelationshipDiagramViewSettings & {
  highlightDisconnectedComponents: boolean;
  lastClickedNodeId: string | null;
  layoutData: LayoutData;
};

type BlocksViewAnimation = {
  begin: number;
  from: Rectangle | Vector[];
  duration: number;
};

export type BlocksViewNode = {
  parent: BlocksViewNode | null;
  children: BlocksViewNode[] | null;
  links: BlocksViewLink[] | null;

  id: string;
  modelId: string | null;
  representationData: RepresentationData | null;
  labels: string[];
  isContext: boolean;
  color: string;

  col: number;
  colSpan: number;
  row: number;
  rowSpan: number;

  /* ephemeral bits used by the view */
  displayLabel: string[][]; // wrapped and truncated version of label
  width: number; // item preferred width (comes off node style; ignored for open group items)
  height: number; // item preferred height (comes off node style; ignored for open group items) */
  open?: boolean; // group node is open
  cell?: Rectangle; // world cell rectangle
  bounds: Rectangle; // world bounds rectangle
  content?: Rectangle; // open group child area
  rowSize?: number[]; // height of group node rows;
  colSize?: number[]; // width of group node columns;
  visualState: BlocksViewVisualState;
  animation: BlocksViewAnimation | null;
};

export interface BlocksViewLink {
  source: BlocksViewNode;
  target: BlocksViewNode;

  labels: string[];
  lineBeginning: ArrowType;
  lineEnding: ArrowType;
  modelId: ArdoqId;
  dashArray: number[];
  color: string;

  globalTypeId: string | null;

  bounds?: Rectangle;
  route?: Vector[];
  sourceProxy?: BlocksViewNode;
  targetProxy?: BlocksViewNode;
  visualState: BlocksViewVisualState;
  animation?: BlocksViewAnimation | null;
}

export type BlocksViewGraph = {
  /** the root graph node */
  root: BlocksViewNode;
  /** all the edges in the graph */
  edges: BlocksViewLink[];
};

export enum BlocksViewVisualState {
  None = 0,
  Hover = 1,
  Select = 2,
  Highlight = 4,
  /**
   * if the node is currently being moved by mouse-drag.
   * _not_ if the node is being resized by mouse-drag.
   */
  Dragging = 8,
}

export interface Interactions {
  attach: () => void;
  detach: () => void;

  renderCanvas: () => boolean;
  contextMenuOptions: () => DropdownItem[];
  viewActionsConfigs: () => SettingsConfig[];
}

export enum InteractionMode {
  None,
  Edit,
  View,
}

export type Controller = {
  fit: (rc: Rectangle) => void;
  zoomTo: (wd: Vector, value: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  expandAll: () => void;
  collapseAll: () => void;
  setOpenAll: (value: boolean) => void;

  expand: (node: BlocksViewNode) => void;
  collapse: (node: BlocksViewNode) => void;
  setOpen: (node: BlocksViewNode, value: boolean) => void;

  rotate: (groups: Iterable<BlocksViewNode>) => void;
  flipHorizontal: (groups: Iterable<BlocksViewNode>) => void;
  flipVertical: (groups: Iterable<BlocksViewNode>) => void;
  resetLayout: (
    groups: Iterable<BlocksViewNode>,
    layoutType: LayoutType
  ) => void;
  undo: () => void;

  renderCanvas: () => void;

  setInteractionMode: (value: InteractionMode) => void;
  getInteractionMode: () => InteractionMode;
  viewActionsConfigs: () => SettingsConfig[];

  showNodeTooltip: (node: BlocksViewNode, wd: Vector) => void;
  showLinkTooltip: (link: BlocksViewLink, wd: Vector) => void;
  cancelTooltip: () => void;

  createExportCanvas: () => OffscreenCanvas | null;

  attach: (value: Interactions) => void;
  detach: () => void;

  selection: NodeSelection;
  canvas: HTMLCanvasElement;
  projection: Projection;
  graph: BlocksViewGraph;
  viewInstanceId: string;
};

export type NodeLayoutData = {
  col: number;
  colSpan: number;
  row: number;
  rowSpan: number;
};

export type LayoutData = {
  [key: string]: NodeLayoutData;
};

export type RectangleSides = [
  left: number,
  top: number,
  right: number,
  bottom: number,
];
