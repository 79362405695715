import styled, { css } from 'styled-components';
import { colors, r4, s4 } from '@ardoq/design-tokens';
import { TreeRowContent } from 'atomicComponents/TreeRowContent';
import { ACTIVE_BOX_SHADOW, ACTIVE_BRIGHTNESS } from '@ardoq/global-consts';
import { BASE_TRANSITION_MS } from 'consts';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import { getFocusRingCSS } from '@ardoq/style-helpers';
import {
  COMPONENT_TREE_ICON_RIGHT_MARGIN,
  COMPONENT_TREE_ROW_BORDER,
  COMPONENT_TREE_ROW_LEFT_PADDING,
  COMPONENT_TREE_ROW_MAX_WIDTH,
  COMPONENT_TREE_ROW_RIGHT_PADDING,
} from './consts';

const LEFT_INDENT = 20;

export const RepresentationWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: inherit;
  pointer-events: none;
`;

export const RowContent = styled(TreeRowContent)<{
  $depth?: number;
  $isSelected?: boolean;
  $isFilteredOut?: boolean;
  $isFocused?: boolean;
  $isHovered?: boolean;
}>`
  height: 24px;
  max-width: ${COMPONENT_TREE_ROW_MAX_WIDTH}px;
  padding: ${s4} ${COMPONENT_TREE_ROW_RIGHT_PADDING} ${s4}
    ${COMPONENT_TREE_ROW_LEFT_PADDING};
  justify-content: flex-start;
  border: ${COMPONENT_TREE_ROW_BORDER}px solid ${colors.grey50};
  border-radius: ${r4};
  background-color: ${colors.grey95};
  stroke: ${colors.grey50};
  cursor: pointer;
  user-select: none;
  overflow: visible;
  position: relative;
  transition-property: filter, box-shadow;
  transition-duration: ${BASE_TRANSITION_MS}ms;
  transition-timing-function: ease-in-out;
  margin: ${({ $depth = 0 }) => {
    const indent = $depth * LEFT_INDENT;

    return `0 -${indent}px 3px ${indent}px`;
  }};
  ${({ $isFilteredOut }) =>
    $isFilteredOut &&
    css`
      opacity: 0.32;
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      /* Need to cover inline border styles */
      border: 1px solid ${colors.white} !important;
      ${getFocusRingCSS(0, colors.blue50)}
      stroke: none;
    `}
  ${({ $isFocused, $isHovered }) =>
    ($isFocused || $isHovered) &&
    css`
      background-color: ${colors.grey90};
      border-color: ${colors.grey15};
      color: ${colors.grey25};
      box-shadow: ${ACTIVE_BOX_SHADOW};
      filter: brightness(${ACTIVE_BRIGHTNESS});
    `}

  > div,
  > svg {
    &:not(:last-child) {
      margin-right: ${COMPONENT_TREE_ICON_RIGHT_MARGIN}px;
    }
  }
`;

export const ComponentTreeContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 10px;
`;

export const ComponentTreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const WorkspaceIcon = styled(Icon).attrs({
  iconName: IconName.WORKSPACE,
  iconSize: IconSize.SMALL,
})`
  color: ${colors.grey35};
`;
const componentTreeTextColorStyle = css`
  color: ${colors.grey25};
`;
export const LabelTextOverflow = styled(TextOverflow)`
  ${componentTreeTextColorStyle}
`;
export const ComponentTreeLabel = styled.span`
  ${componentTreeTextColorStyle}
`;

export const ViewContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export const ContextMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
