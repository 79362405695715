import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { Notifier } from '@ardoq/dropdown-menu';

type NoSelectedColumnDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
  selectColumnAction?: Notifier;
};

const NoSelectedColumnDoq = ({
  doqType,
  includeActions = true,
  selectColumnAction,
}: NoSelectedColumnDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">All columns are currently hidden.</FlexBox>
          <FlexBox gap="medium">
            To show more data, select at least one column.
          </FlexBox>
        </Stack>
      }
      buttonLabel={includeActions ? 'Select column' : undefined}
      onButtonClick={
        includeActions && selectColumnAction
          ? () => selectColumnAction.notify()
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoSelectedColumnDoq;
