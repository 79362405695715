import { DoqType } from '@ardoq/doq';
import { ViewIds } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import { NoConnectedComponentsDoq } from '@ardoq/graph';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoSelectedContextComponentDoq from 'tabview/graphViews/emptyStates/NoSelectedContextComponentDoq';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
type EmptyStateProps = {
  noConnectedComponents: boolean;
  hasActiveFilter: boolean;
  noContext: boolean;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
};

const EmptyState = ({
  hasActiveFilter,
  noConnectedComponents,
  noContext,
  isPresentationMode,
  isWorkspaceEmpty,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.BLOCK_DIAGRAM_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }
  if (noContext) {
    return (
      <NoSelectedContextComponentDoq doqType={DoqType.BLOCK_DIAGRAM_ERROR} />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.BLOCK_DIAGRAM_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (noConnectedComponents) {
    return (
      <NoConnectedComponentsDoq
        doqType={DoqType.BLOCK_DIAGRAM_ERROR}
        viewId={ViewIds.BLOCK_DIAGRAM}
        includeActions={!isPresentationMode}
        onViewSettingsUpdate={onViewSettingsUpdate}
      />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
