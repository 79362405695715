import ReactJson from 'react-json-view';
import { ModalSize, info } from '@ardoq/modal';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';

const JsonBodyWrapper = styled.div`
  padding: ${s32};
`;

type JsonModalProps = {
  title?: string;
  json: Record<string, unknown>;
};

export const jsonModal = ({ title = 'Details', json }: JsonModalProps) =>
  info({
    title,
    modalSize: ModalSize.L,
    body: (
      <JsonBodyWrapper>
        <ReactJson
          src={json}
          name={false}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={copy =>
            navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))
          }
        />
      </JsonBodyWrapper>
    ),
  });
