import {
  BaseClass,
  GeneralPath,
  IEdge,
  IObstacleProvider,
} from '@ardoq/yfiles';
import { createPath } from './edgeUtil';

export default class BasicEdgeObstacleProvider extends BaseClass(
  IObstacleProvider
) {
  private edge: IEdge;
  private path: GeneralPath;
  constructor(edge: IEdge) {
    super();
    this.edge = edge;
    this.path = createPath(this.edge, true);
  }
  getObstacles() {
    return this.path;
  }
}
