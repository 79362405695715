import {
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  FreeEdgeLabelModel,
  FreeNodeLabelModel,
  IEdge,
  IGraph,
  ILabel,
  IModelItem,
  INode,
  Insets,
  InteriorLabelModel,
  InteriorLabelModelPosition,
  Point,
  Rect,
  Size,
} from '@ardoq/yfiles';
import { isEqualWith } from 'lodash';
import * as encodingUtils from '@ardoq/html';
import { logError } from '@ardoq/logging';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ArdoqAddToScenario } from 'yfilesExtensions/styles/ArdoqAddToScenario';
import { DiffType } from '@ardoq/data-model';
import { ViewIds } from '@ardoq/api-types';
import {
  MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
} from 'yfilesExtensions/styles/modernized/consts';
import {
  AggregatedGraphEdge,
  CollapsibleGraphGroup,
  GraphItem,
  GraphNode,
  type RelationshipDiagramGraphEdgeType,
  graphItemLabel,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  formatOtherLabel,
} from '@ardoq/graph';
import AggregateEdgeStyle from '../../AggregateEdgeStyle';
import sizeEmptyGroupNodes from '../../sizeEmptyGroupNodes';
import MultiLabelStyle from '../../labels/MultiLabelStyle';
import ArdoqImageStyle from 'yfilesExtensions/styles/modernized/ArdoqImageStyle';
import ArdoqNodeStyle from 'yfilesExtensions/styles/modernized/ArdoqNodeStyle';
import { createFifoCache } from '@ardoq/common-helpers';
import modernizedBlockDiagramRepresentationData from '../modernizedBlockDiagramRepresentationData';
import GroupSizeConstraintProvider from '../GroupSizeConstraintProvider';
import {
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT,
} from '../consts';
import GroupSubLabelStyle from '../GroupSubLabelStyle';
import type { ModernizedBlockDiagramViewSettings } from 'tabview/blockDiagram/types';
import IconNodeStyle from 'yfilesExtensions/styles/modernized/IconNodeStyle';
import { ArdoqIcon } from '@ardoq/icons';
import GroupDescendantCountLabelStyle from '../GroupDescendantCountLabelStyle';
import IconGroupLabelStyle from '../IconGroupLabelStyle';
import OtherLabelStyle from '../OtherLabelStyle';
import IconGroupStyle from '../IconGroupStyle';
import ShapeGroupStyle from '../ShapeGroupStyle';
import {
  getOtherLabelsHeight,
  getOtherLabelSizes,
} from '../../labels/labelUtils';
import { OTHER_LABEL_MARGIN } from 'yfilesExtensions/styles/consts';
import CollapsedComponentGroupLabelStyle from '../CollapsedComponentGroupLabelStyle';
import {
  addAggregatedCountLabel,
  addGroupURLNodeLabel,
  addURLEdgeLabel,
  addURLNodeLabel,
} from './addGraphLabels';
import { updateEdgeLabels, updateLabels } from './updateGraphLabels';
import mainLabelLayoutParameter from './mainLabelLayoutParameter';
import collapsedComponentGroupLayoutParameter from './collapsedComponentGroupLayoutParameter';
import isUrlLabel from './isUrlLabel';
import GroupLabelModel from './GroupLabelModel';

const groupSubLabelLayoutParameter = (graphGroup: CollapsibleGraphGroup) => {
  const itemLabels = graphGroup.getItemLabels();
  if (!itemLabels || !itemLabels.otherLabels?.length) {
    return new InteriorLabelModel({
      insets: new Insets(
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING +
          MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
      ),
    }).createParameter(InteriorLabelModelPosition.NORTH_WEST);
  }
  const { otherLabels } = itemLabels;
  const otherLabelSizes = getOtherLabelSizes(otherLabels, true);
  const otherLabelsHeight = getOtherLabelsHeight(otherLabelSizes);
  return new InteriorLabelModel({
    insets: new Insets(
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
      2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING +
        MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT +
        otherLabelsHeight,
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
    ),
  }).createParameter(InteriorLabelModelPosition.NORTH_WEST);
};

/** the invisible separator character. */
const SEPARATOR = '\u2063';

const shapeNodeStyle = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (shapeName: string) => new ArdoqNodeStyle(shapeName)
);
const imageNodeStyle = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (imageUrl: string) => new ArdoqImageStyle(imageUrl)
);
const iconNodeStyle = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (icon: ArdoqIcon) => new IconNodeStyle(icon)
);

const getNodeStyle = (
  graphNode: GraphNode,
  componentStyle: ModernizedBlockDiagramViewSettings['componentStyle']
) => {
  const getRepresentationData =
    modernizedBlockDiagramRepresentationData(componentStyle);
  const representationData = getRepresentationData(graphNode.modelId);
  if (representationData?.isImage && representationData.value) {
    return imageNodeStyle(representationData.value);
  }
  if (componentStyle === 'icon') {
    const icon = representationData.icon;
    if (icon) {
      return iconNodeStyle(icon);
    }
  }
  return shapeNodeStyle(graphNode.getShape());
};

const getInitialLayout = (ancestor: INode | null) =>
  // if this is a descendant of a node which has been expanded, its origin (for animation purposes) should be the group center.
  ancestor ? new Rect(ancestor.layout.center, ancestor.layout.toSize()) : null;

const graphItemsEqual = (
  a: GraphItem | GraphItem[],
  b: GraphItem | GraphItem
) => !Array.isArray(a) && a.isEqual(b);

// #region label layout parameters

const addToScenarioButtonStyle = new ArdoqAddToScenario();
const addToScenarioNodeButtonModelParameter =
  FreeNodeLabelModel.INSTANCE.createParameter(
    new Point(0.5, 0), // place label at node layout top-center
    new Point(0, -5), // move up 5px
    new Point(0.5, 1) // anchor point of label is bottom-center
  );

const labelStyle = MultiLabelStyle.Modern;

const groupLabelLayoutParameter =
  GroupLabelModel.Instance.createDefaultParameter();
const groupDescendantCountBadgeParameter = new InteriorLabelModel({
  insets: new Insets(MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING),
}).createParameter(InteriorLabelModelPosition.NORTH_EAST);
const otherLabelsLayoutParameter = new ExteriorLabelModel({
  insets: new Insets(0, 0, 0, OTHER_LABEL_MARGIN),
}).createParameter(ExteriorLabelModelPosition.SOUTH);
// #endregion

const filterNullValues = <TKey, TValue>(
  keyValuePair: [TKey, TValue | null]
): keyValuePair is [TKey, TValue] => keyValuePair[1] !== null;

type DiffUpdateGraphArgs<T extends GraphItem, Y extends IModelItem> = {
  oldMap: Map<string, Y>;
  businessObjects: T[];
  create: (graphItem: T) => Y | null;
  update: (graphItem: T) => Y | null;
  remove: (graphItem: Y, oldMap: Map<string, Y>) => void;
};
const diffUpdateGraph = <T extends GraphItem, Y extends IModelItem>({
  oldMap,
  businessObjects,
  create,
  update,
  remove,
}: DiffUpdateGraphArgs<T, Y>): Map<string, Y> => {
  const newMap = new Map(
    businessObjects
      .map((businessObject): [string, Y | null] => [
        businessObject.id,
        // Add new items, update existing items
        (oldMap.has(businessObject.id) ? update : create)(businessObject),
      ])
      .filter(filterNullValues)
  );
  businessObjects.forEach(businessObject => oldMap.delete(businessObject.id));

  // Delete old items
  Array.from(oldMap.values()).forEach(graphItem => remove(graphItem, oldMap));
  return newMap;
};

export default class YGraphBuilder {
  private edgeMap = new Map<string, IEdge>();
  private nodeMap = new Map<string, INode>();
  private groupNodeMap = new Map<string, INode>();
  public componentStyle: ModernizedBlockDiagramViewSettings['componentStyle'] =
    'shape';
  nodesSource: GraphNode[] = [];
  edgesSource: RelationshipDiagramGraphEdgeType[] = [];
  groupsSource: CollapsibleGraphGroup[] = [];

  constructor(
    viewId: ViewIds,
    private graph: IGraph
  ) {
    // Initialization
    graph.edgeDefaults.style = new AggregateEdgeStyle();
    graph.groupNodeDefaults.labels.layoutParameter = groupLabelLayoutParameter;

    // Style and labels
    graph.edgeDefaults.labels.style = labelStyle;
    graph.nodeDefaults.labels.style = labelStyle;

    const edgeLabelModel = new FreeEdgeLabelModel();
    graph.edgeDefaults.labels.layoutParameter =
      edgeLabelModel.createDefaultParameter();
  }
  private createEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const sourceNode =
      this.nodeMap.get(graphEdge.source) ||
      this.groupNodeMap.get(graphEdge.source);
    const targetNode =
      this.nodeMap.get(graphEdge.target) ||
      this.groupNodeMap.get(graphEdge.target);
    if (!sourceNode || !targetNode) {
      logError(Error('Source or Target node not found while creating Edge.'));
      return null;
    }

    const edge = this.graph.createEdge({
      tag: graphEdge,
      labels: [encodingUtils.unescapeHTML(graphEdge.getLabel())],
      source: sourceNode,
      target: targetNode,
    });

    if (graphEdge.hasURLFields()) {
      addURLEdgeLabel(this.graph, edge, graphEdge, true);
    }
    if (graphEdge instanceof AggregatedGraphEdge) {
      addAggregatedCountLabel(this.graph, edge, graphEdge);
    }
    return edge;
  };
  private updateEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const edge = this.edgeMap.get(graphEdge.id);
    if (!edge) {
      logError(Error('Edge not found in updateEdge.'));
      return null;
    }
    edge.tag = graphEdge; //  could be a new GraphEdge with the same id.
    /** is the sourcePort or targetPort gone? (group Node turned into a normal node or vice-versa) */
    const isEdgeMissingPort = !edge.sourcePort || !edge.targetPort;
    if (isEdgeMissingPort) {
      return this.createEdge(graphEdge);
    }

    if (
      edge.sourceNode?.tag instanceof GraphItem &&
      edge.targetNode?.tag instanceof GraphItem
    ) {
      if (
        edge.sourceNode.tag.id !== graphEdge.source ||
        edge.targetNode.tag.id !== graphEdge.target
      ) {
        // if source or target has changed, we must create a new edge.
        this.removeEdge(edge);
        return this.createEdge(graphEdge);
      }
    }

    updateEdgeLabels(edge, this.graph, graphEdge, true);
    return edge;
  };

  private getAncestorInGraph = (graphNode: GraphItem): INode | null =>
    (graphNode.parent &&
      (this.groupNodeMap.get(graphNode.parent.id) ??
        this.getAncestorInGraph(graphNode.parent))) ??
    null;

  private createNode = (graphNode: GraphNode) => {
    const labelText = graphItemLabel(graphNode);
    const node = this.graph.createNode({
      parent: graphNode.parent
        ? (this.groupNodeMap.get(graphNode.parent.id) ?? null)
        : null,
      tag: graphNode,
      labels: [
        {
          text: labelText,
          layoutParameter: mainLabelLayoutParameter(labelText),
        },
      ],
      layout: getInitialLayout(this.getAncestorInGraph(graphNode)),
      style: getNodeStyle(graphNode, this.componentStyle),
    });

    if (graphNode.hasURLFields()) {
      addURLNodeLabel(this.graph, node, graphNode);
    }
    if (
      !(graphNode.getVisualDiffType() === DiffType.PLACEHOLDER) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }
    this.addOtherNodeLabels(node, graphNode);
    this.updateNodeLayout(node);
    return node;
  };
  private updateNode = (graphNode: GraphNode) => {
    const node = this.nodeMap.get(graphNode.id)!;
    if (!node) {
      logError(Error('Node not found in updateNode.'));
      return null;
    }
    const style = getNodeStyle(graphNode, this.componentStyle);
    if (style !== node.style) {
      this.graph.setStyle(node, style);
    }
    this.updateNodeLayout(node);

    if (graphNode.hasURLFields() && !node.labels.find(isUrlLabel)) {
      addURLNodeLabel(this.graph, node, graphNode);
    }
    this.addOtherNodeLabels(node, graphNode);

    updateLabels(node, this.graph, graphNode);

    if (graphNode.parent) {
      const parentNode = this.groupNodeMap.get(graphNode.parent.id);
      if (parentNode) {
        this.graph.setParent(node, parentNode);
      } else {
        logError(Error('Parent node not found.'));
      }
    } else {
      this.graph.setParent(node, null);
    }
    if (
      !(graphNode.getVisualDiffType() === DiffType.PLACEHOLDER) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }
    return node;
  };

  private addGroupNodeLabels = (
    graphNode: CollapsibleGraphGroup,
    groupNode: INode
  ) => {
    const isCollapsedComponentGroup =
      graphNode.collapsed && graphNode.isComponent();
    const labelText = graphNode.getItemLabels()?.mainLabel ?? '';
    this.graph.addLabel({
      owner: groupNode,
      text: labelText,
      style: isCollapsedComponentGroup
        ? CollapsedComponentGroupLabelStyle.Instance
        : IconGroupLabelStyle.Instance,
      layoutParameter: isCollapsedComponentGroup
        ? collapsedComponentGroupLayoutParameter(labelText)
        : groupLabelLayoutParameter,
    });
    if (!graphNode.collapsed && graphNode.subLabel) {
      this.graph.addLabel({
        owner: groupNode,
        text: graphNode.subLabel,
        layoutParameter: groupSubLabelLayoutParameter(graphNode),
        style: GroupSubLabelStyle.Instance,
      });
    }
    if (graphNode.collapsed) {
      this.graph.addLabel({
        owner: groupNode,
        text: `${graphNode.descendantCount}`,
        layoutParameter: groupDescendantCountBadgeParameter,
        style: GroupDescendantCountLabelStyle.Instance,
      });
    }
    if (isCollapsedComponentGroup) {
      this.addOtherNodeLabels(groupNode, graphNode);
    } else {
      this.removeOtherNodeLabels(groupNode);
    }
    if (graphNode.hasURLFields()) {
      addGroupURLNodeLabel(this.graph, groupNode, graphNode);
    }
  };
  private createGroupNode = (graphNode: CollapsibleGraphGroup) => {
    const groupNode = this.graph.createGroupNode({
      tag: graphNode,
      layout: null,
      style:
        this.componentStyle === 'icon'
          ? IconGroupStyle.Instance
          : ShapeGroupStyle.Instance,
    });
    this.addGroupNodeLabels(graphNode, groupNode);
    return groupNode;
  };
  private updateGroupNode = (graphNode: CollapsibleGraphGroup) => {
    const groupNode = this.groupNodeMap.get(graphNode.id);
    if (!groupNode) {
      logError(Error('Group node not found.'));
      return null;
    }
    this.graph.setNodeLayout(
      groupNode,
      new Rect(groupNode.layout.toPoint(), new Size(1, 1))
    );
    this.graph.setStyle(
      groupNode,
      this.componentStyle === 'icon'
        ? IconGroupStyle.Instance
        : ShapeGroupStyle.Instance
    );
    groupNode.tag = graphNode; // this could be a new GraphGroup with the same id.
    groupNode.labels
      .toArray()
      .forEach((label: ILabel) => this.graph.remove(label));

    this.addGroupNodeLabels(graphNode, groupNode);
    return groupNode;
  };

  private removeItem = (item: IModelItem, map: Map<string, IModelItem>) => {
    const businessObject: GraphItem = item.tag;
    map.delete(businessObject.id);
    if (this.graph.contains(item)) {
      this.graph.remove(item);
    }
  };
  private removeEdge = (edge: IEdge) => {
    // Make sure the edge is attached to the graph
    if (edge.sourcePort || edge.targetPort) {
      this.removeItem(edge, this.edgeMap);
    }
  };

  hasLayoutUpdate({
    nodes,
    groups,
    edges,
  }: {
    nodes: GraphNode[];
    groups: CollapsibleGraphGroup[];
    edges: RelationshipDiagramGraphEdgeType[];
  }) {
    const nodesUnchanged =
      !nodes || isEqualWith(nodes, this.nodesSource, graphItemsEqual);
    const groupsUnchanged =
      !groups || isEqualWith(groups, this.groupsSource, graphItemsEqual);
    const edgesUnchanged =
      !edges || isEqualWith(edges, this.edgesSource, graphItemsEqual);

    return !(nodesUnchanged && groupsUnchanged && edgesUnchanged);
  }
  clearEdges() {
    Array.from(this.edgeMap.values()).forEach(this.removeEdge);
  }
  clear() {
    this.graph.clear();

    this.groupNodeMap.clear();
    this.nodeMap.clear();
    this.edgeMap.clear();
  }
  buildGraph() {
    this.clear();

    this.groupNodeMap = new Map(
      this.groupsSource.map(graphNode => [
        graphNode.id,
        this.createGroupNode(graphNode),
      ])
    );

    this.setGroupNodeParents();

    this.nodeMap = new Map(
      this.nodesSource.map(graphNode => [
        graphNode.id,
        this.createNode(graphNode),
      ])
    );

    sizeEmptyGroupNodes(
      this.graph,
      GroupSizeConstraintProvider.Instance.getMinimumSize
    );

    this.edgeMap = new Map(
      this.edgesSource.map(graphEdge => {
        return [graphEdge.id, this.createEdge(graphEdge)!];
      })
    );
  }

  updateGraph() {
    if (this.nodeMap.size + this.edgeMap.size + this.groupNodeMap.size === 0) {
      this.buildGraph();
      return;
    }

    this.groupNodeMap = diffUpdateGraph({
      oldMap: this.groupNodeMap,
      businessObjects: this.groupsSource,
      create: this.createGroupNode,
      update: this.updateGroupNode,
      remove: this.removeItem,
    });

    this.setGroupNodeParents();

    this.nodeMap = diffUpdateGraph<GraphNode, INode>({
      oldMap: this.nodeMap,
      businessObjects: this.nodesSource,
      create: this.createNode,
      update: this.updateNode,
      remove: this.removeItem,
    });

    sizeEmptyGroupNodes(
      this.graph,
      GroupSizeConstraintProvider.Instance.getMinimumSize
    );

    this.edgeMap = diffUpdateGraph({
      oldMap: this.edgeMap,
      businessObjects: this.edgesSource,
      create: this.createEdge,
      update: this.updateEdge,
      remove: this.removeEdge,
    });
  }

  private removeOtherNodeLabels(owner: INode) {
    const labelsToRemove = owner.labels
      .filter(({ style }) => style === OtherLabelStyle.Instance)
      .toArray();
    labelsToRemove.forEach(labelToRemove => this.graph.remove(labelToRemove));
  }
  private addOtherNodeLabels(
    owner: INode,
    graphNode: GraphNode | CollapsibleGraphGroup
  ) {
    this.removeOtherNodeLabels(owner);

    const itemLabels = graphNode.getItemLabels();
    if (!itemLabels?.otherLabels?.length) {
      return;
    }
    const text = itemLabels.otherLabels.map(formatOtherLabel).join(SEPARATOR);
    this.graph.addLabel({
      owner,
      text,
      layoutParameter: otherLabelsLayoutParameter,
      style: OtherLabelStyle.Instance,
      tag: graphNode,
    });
  }
  private setGroupNodeParents() {
    // Set parents of group nodes
    Array.from(this.groupNodeMap.values()).forEach(groupNode => {
      const parent =
        groupNode.tag instanceof GraphItem &&
        groupNode.tag.parent &&
        this.groupNodeMap.get(groupNode.tag.parent.id);

      if (parent) {
        this.graph.setParent(groupNode, parent);
      }
    });
  }
  private updateNodeLayout(node: INode) {
    const {
      tag,
      layout: {
        topLeft: { x, y },
      },
    } = node;
    const graphNode = tag as GraphNode;
    this.graph.setStyle(node, getNodeStyle(graphNode, this.componentStyle));
    this.graph.setNodeLayout(
      node,
      new Rect(
        x,
        y,
        MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
        MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT
      )
    );
  }
  private addToScenarioButton(owner: INode, graphNode: GraphItem) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter: addToScenarioNodeButtonModelParameter,
      style: addToScenarioButtonStyle,
      tag: graphNode,
    });
  }
}
