import styled from 'styled-components';
import { useRef } from 'react';
import { connectInstance } from '@ardoq/rxbeach';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import { VisualizationContainer } from 'tabview/ViewContainer';
import {
  HierarchicalTreemapNode,
  HierarchicalTreemapViewProperties,
} from '../../types';
import getViewModel$ from '../../viewModel/viewModel$';
import HierarchicalPackingSettingsBar from './HierarchicalPackingSettingsBar';
import * as d3 from 'd3';
import HierarchicalPackingViewNode from './HierarchicalPackingViewNode';
import { ViewIds } from '@ardoq/api-types';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { truncateSvgLabel } from 'utils/measureText';
import { useResizeObserver } from '@ardoq/hooks';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

type Size = [width: number, height: number];

const layout = (size: Size) => d3.pack<HierarchicalTreemapNode>().size(size);

const LABEL_SIZE = 10;
const Label = styled.text`
  text-anchor: middle;
  dominant-baseline: middle;
  font: ${LABEL_SIZE}px ${ARDOQ_DEFAULT_FONT_FAMILY};
`;

const HierarchicalPackingView = (props: HierarchicalTreemapViewProperties) => {
  const {
    viewModel: { hierarchy },
    viewSettings: { maxDepth },
  } = props;

  const viewContainer = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver(viewContainer);
  const packingRootNode = layout([width, height])(hierarchy);
  const descendants = packingRootNode
    .descendants()
    .filter(
      node =>
        node !== packingRootNode && (maxDepth <= 0 || node.depth <= maxDepth)
    );
  return (
    <SettingsBarAndViewContainer>
      <div className="menuContainer">
        <HierarchicalPackingSettingsBar
          {...props}
          viewContainer={viewContainer}
        />
      </div>
      <VisualizationContainer
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
        ref={viewContainer}
      >
        <svg style={{ height: '100%', width: '100%' }}>
          {descendants.map(node => (
            <HierarchicalPackingViewNode key={node.data.id} node={node} />
          ))}
          {descendants.map(
            ({ x, y, r, children, value, data: { id, label } }) => {
              if (children?.length) {
                // can't render a label in the center of a parent node. it likely would collide with the labels of its children.
                return null;
              }
              const labelText = `${label} (${value})`;
              const labelWidth = 2 * r;
              const labelDisplayText = truncateSvgLabel(
                labelText,
                labelWidth,
                ARDOQ_DEFAULT_FONT_FAMILY,
                LABEL_SIZE
              );
              return (
                <Label key={`text_${id}`} x={x} y={y}>
                  {labelDisplayText}
                </Label>
              );
            }
          )}
        </svg>
      </VisualizationContainer>
    </SettingsBarAndViewContainer>
  );
};

export default connectInstance(
  HierarchicalPackingView,
  getViewModel$(ViewIds.HIERARCHICAL_PACKING)
);
