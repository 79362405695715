import { INode, Point, Size } from '@ardoq/yfiles';
import { isContextNode } from './nodeDecorator';
import getNodeOutline from 'yfilesExtensions/getNodeOutline';
import getNodeBounds from 'yfilesExtensions/getNodeBounds';
import ArdoqNodeStyleBase from './ArdoqNodeStyleBase';

abstract class LegacyArdoqNodeStyleBase extends ArdoqNodeStyleBase {
  constructor() {
    super(false);
  }
  abstract get size(): Size;
  override getOutline(node: INode) {
    return getNodeOutline(node);
  }
  override getIntersection(node: INode, inner: Point, outer: Point) {
    // this method is called by the edge router when looking for a port to connect to this node.
    // the base implementation will find the intersection point using the result of getOutline(node).
    const straightDown = inner.y > outer.y && inner.x === outer.x;

    if (straightDown) {
      // because the base implementation uses getOutline() to find the intersection, it would connect downward pointing edges to the top side of the label bounds, which is ugly.
      // like this...

      //  ref  refs refs ref
      //   |   ↓ ↓ ↓ ↓ ↓  |
      //   |   _________  |
      //   |  |  node   | |
      //   ↓  | outline | ↓
      //  ____|         |_____
      // | long label outline |
      // |____________________|

      // we like that the label bounds are part of the outline when the refs come in upward or from the side, but downward is ugly.
      // the workaround is actually an optimization: we simply find the intersection point along the topside of the node.

      const nodeBounds = getNodeBounds(node, isContextNode(node));
      return new Point(
        Math.max(nodeBounds.x, Math.min(nodeBounds.maxX, outer.x)),
        nodeBounds.y
      );
    }
    return super.getIntersection(node, inner, outer);
  }
}
export default LegacyArdoqNodeStyleBase;
