import { actionCreator } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';

export interface ViewIdPayload {
  viewId: ViewIds;
}
export interface RelationshipDiagramCollapseGroupPayload extends ViewIdPayload {
  groupId: string;
}
export const relationshipDiagramToggleCollapseGroup =
  actionCreator<RelationshipDiagramCollapseGroupPayload>(
    '[relationshipDiagrams] RELATIONSHIP_DIAGRAM_COLLAPSE_GROUP'
  );
export const relationshipDiagramCollapseAllGroups =
  actionCreator<ViewIdPayload>(
    '[relationshipDiagrams] RELATIONSHIP_DIAGRAM_COLLAPSE_ALL_GROUPS'
  );
export const relationshipDiagramExpandAllGroups = actionCreator<ViewIdPayload>(
  '[relationshipDiagrams] RELATIONSHIP_DIAGRAM_EXPAND_ALL_GROUPS'
);
export const relationshipDiagramReset = actionCreator(
  '[relationshipDiagrams] RELATIONSHIP_DIAGRAM_RESET'
);
