import { VisuallyHidden } from '@react-aria/visually-hidden';
import { HTMLAttributes, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { getFocusRingBorderCSS } from '@ardoq/style-helpers';
import { Icon, IconName } from '@ardoq/icons';
import { trackEvent } from 'tracking/tracking';
import { semibold14 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';
import { DisabledZoomControls } from '@ardoq/view-settings';

const ZOOM_ICON_HEIGHT = '36px';

const ZoomButtonIcon = styled(Icon).attrs({
  style: { height: ZOOM_ICON_HEIGHT },
})`
  margin-top: auto;
  && {
    line-height: ${ZOOM_ICON_HEIGHT};
  }
`;
const activeZoomButtonStyle = css`
  background-color: ${colors.grey90};
  color: ${colors.grey25};
`;
const ZoomButton = styled.button<{ $disabled?: boolean; $isActive?: boolean }>`
  ${semibold14}
  ${({ $isActive }) =>
    $isActive
      ? activeZoomButtonStyle
      : css`
          color: color-mix(in srgb, ${colors.grey25} 70%, transparent);
          background-color: transparent;
        `}
  width: 100%;
  height: ${ZOOM_ICON_HEIGHT};
  margin-bottom: 5px;
  box-shadow: none;
  border: none;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: ${ZOOM_ICON_HEIGHT};
  min-width: 64px;
  padding: 0 8px;
  transition: all 0.2s ease-out;
  text-align: center;

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.32;
        `
      : css`
          cursor: pointer;

          &:hover,
          &.active,
          &:active,
          &:focus {
            ${activeZoomButtonStyle}
          }
          &:focus-visible {
            ${getFocusRingBorderCSS()}
          }
        `}
`;

type ZoomControlProps = {
  iconName: IconName;
  disabled?: boolean;
  isActive?: boolean;
  label: string;
} & Omit<HTMLAttributes<HTMLButtonElement>, 'style'>;

export const ZoomControl = ({
  iconName,
  onClick,
  disabled,
  isActive,
  label,
  ...labelProps
}: ZoomControlProps) => (
  <ZoomButton
    onClick={e => {
      e.stopPropagation();
      if (!disabled) {
        trackEvent('Clicked zoom control', { iconName });
        return onClick?.(e);
      }
    }}
    data-tooltip-text={label}
    disabled={disabled}
    $disabled={disabled}
    $isActive={isActive}
    {...labelProps}
  >
    <ZoomButtonIcon iconName={iconName} />
    <VisuallyHidden>{label}</VisuallyHidden>
  </ZoomButton>
);

type ZoomControlsProps = {
  zoomIn: () => void;
  zoomCenter: () => void;
  zoomOut: () => void;
  disabledState?: DisabledZoomControls;
};

const ZoomControls = ({
  zoomIn,
  zoomCenter,
  zoomOut,
  children,
  disabledState = DisabledZoomControls.NONE,
}: PropsWithChildren<ZoomControlsProps>) => (
  <>
    <ZoomControl
      iconName={IconName.ZOOM_OUT_MAP}
      onClick={zoomCenter}
      label="Fit window"
      disabled={Boolean(disabledState & DisabledZoomControls.FIT_WINDOW)}
    />
    <ZoomControl
      iconName={IconName.ZOOM_IN}
      onClick={zoomIn}
      label="Zoom in"
      disabled={Boolean(disabledState & DisabledZoomControls.ZOOM_IN)}
    />
    <ZoomControl
      iconName={IconName.ZOOM_OUT}
      onClick={zoomOut}
      label="Zoom out"
      disabled={Boolean(disabledState & DisabledZoomControls.ZOOM_OUT)}
    />
    {children}
  </>
);

export default ZoomControls;
