import { PermissionContext } from '@ardoq/access-control';
import { CanCreatorMigrateSlidesInfo } from '@ardoq/api';
import {
  APIPresentationAssetAttributes,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { PayloadLoadSlide } from 'presentation/viewPane/actions';
import { MetaInfoViewStatus } from 'streams/views/mainContent/types';

export type ViewModifier = {
  valueLabel: string;
  isDiscontinued: boolean;
};

export type PresentationSlideViewInfo = {
  slideId: string;
  viewStatus: MetaInfoViewStatus;
  killDate: Date | null;
  viewId: ViewIds;
  slideName: string;
  createdByName: string;
  createdByEmail: string;
  hasFullAccess?: boolean;
};

export enum DeprecationPhasesInPresentationScope {
  DISCONTINUED_ONLY = 'DISCONTINUED_ONLY',
  SOON_TO_BE_DISCONTINUED_ONLY = 'SOON_TO_BE_DISCONTINUED_ONLY',
  MIXED_PHASES = 'MIXED_PHASES',
}

export enum SlidePermisionsInPresentationScope {
  FULL_PERMISSIONS = 'FULL_PERMISSIONS',
  LIMITED_PERMISSIONS = 'LIMITED_PERMISSIONS',
  MIXED_PERMISSIONS = 'MIXED_PERMISSIONS',
}

export type OverallPresentationViewInfo = {
  presentation: APIPresentationAssetAttributes;
  overallDeprecationPhase?: DeprecationPhasesInPresentationScope;
  overallPermissionsLevel?: SlidePermisionsInPresentationScope;
};

export type DeprecatedSlidesNotificationProperties = {
  canEditPresentation: boolean;
  deprecatedSlides: PresentationSlideViewInfo[];
  minimumKillDate: Date | null;
  overallPresentationViewInfo?: OverallPresentationViewInfo;
  currentUserIsOrgAdmin: boolean;
  slidesInfo?: CanCreatorMigrateSlidesInfo;
  activeSlide?: PayloadLoadSlide;
  presentation?: APIPresentationAssetAttributes;
  permissionContext: PermissionContext;
};

export type DeprecatedSlideNotificationDetailsArgs = {
  deprecatedSlides: PresentationSlideViewInfo[];
  canEditPresentation: boolean;
  overallPresentationViewInfo: OverallPresentationViewInfo;
  slidesMigrationInfo?: CanCreatorMigrateSlidesInfo;
};

export type SlidesMigrationInfo = {
  activePresentationId?: ArdoqId;
  slidesInfo?: CanCreatorMigrateSlidesInfo;
  activeSlide?: PayloadLoadSlide;
};
