import { MouseEventHandler, useCallback } from 'react';
import { ChartConfiguration, SpiderChartPoint } from './types';
import {
  getRangeColor,
  getScaleRadialX,
  getScaleRadialY,
  indexToRadians,
} from './utils';
import { DATAPOINT_RADIUS, POLYGON_INDEX_ATTRIBUTE } from './consts';
import { WithPopover } from '@ardoq/popovers';

type ChartPointProps = {
  config: ChartConfiguration;
  chartPoint: SpiderChartPoint;
  chartPointIndex: number;
  chartPointsIndex: number;
  onMouseEnter: MouseEventHandler<SVGCircleElement>;
  onMouseLeave: MouseEventHandler<SVGCircleElement>;
};

export const ChartPoint = ({
  chartPoint,
  chartPointIndex,
  chartPointsIndex,
  config: { maxValue, centerX, centerY, radius, total },
  onMouseEnter,
  onMouseLeave,
}: ChartPointProps) => {
  const dataValue = Math.max(chartPoint.value, 0);
  const popoverContent = useCallback(
    () => chartPoint.formattedValue,
    [chartPoint.formattedValue]
  );
  const normalizedValue = dataValue / maxValue;
  const angle = indexToRadians(chartPointIndex, total);
  const cx = getScaleRadialX(centerX, normalizedValue, radius, angle);
  const cy = getScaleRadialY(
    centerY,
    normalizedValue,
    radius,
    indexToRadians(chartPointIndex, total)
  );

  return (
    <WithPopover content={popoverContent}>
      <circle
        r={DATAPOINT_RADIUS}
        cx={cx}
        cy={cy}
        style={{
          fill: getRangeColor(chartPointsIndex),
          fillOpacity: 0.9,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...{ [POLYGON_INDEX_ATTRIBUTE]: chartPointsIndex }}
      />
    </WithPopover>
  );
};
