import { useRef, useState } from 'react';
import { useResizeObserver } from '@ardoq/hooks';
import { truncateComponentLabel, ComponentLabelParts } from '@ardoq/graph';
import { WithPopover } from '@ardoq/popovers';

type GetFullLabelTextArgs = {
  label: string | null;
  sublabelValue?: string | null;
  sublabelKey?: string | null;
};
const getFullLabelText = ({
  label,
  sublabelValue,
  sublabelKey,
}: GetFullLabelTextArgs) => {
  if (!label) {
    return '';
  }
  if (!sublabelValue) {
    return label;
  }
  return `${label} [${sublabelKey ? `${sublabelKey}: ` : ''}${sublabelValue}]`;
};

type SelfTruncatingComponentLabelProperties = ComponentLabelParts & {
  measure: (label: string) => number;
  isWithPopover?: boolean;
  bypassRenderLimit?: boolean;
};
const SelfTruncatingComponentLabel = ({
  label,
  fieldLabel,
  fieldValue,
  measure,
  isWithPopover,
  bypassRenderLimit,
}: SelfTruncatingComponentLabelProperties) => {
  const container = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  useResizeObserver(container, ({ width }) => {
    setWidth(width);
  });

  const fullLabelText = getFullLabelText({
    label,
    sublabelValue: fieldValue,
    sublabelKey: fieldLabel,
  });

  if (bypassRenderLimit) {
    return <div>{fullLabelText}</div>;
  }

  const truncatedLabel = width
    ? truncateComponentLabel({
        fieldValue,
        fieldLabel,
        label,
        width,
        measure,
      })
    : fullLabelText;

  const isTruncated = truncatedLabel !== fullLabelText;

  return (
    <div ref={container} style={{ width: '100%' }}>
      {!isWithPopover ? (
        truncatedLabel
      ) : isTruncated ? (
        <WithPopover content={fullLabelText}>{truncatedLabel}</WithPopover>
      ) : (
        fullLabelText
      )}
    </div>
  );
};

export default SelfTruncatingComponentLabel;
