import { createSvgElement } from '@ardoq/dom-utils';
import {
  type ILabel,
  type INode,
  type IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  TextMeasurePolicy,
  TextRenderSupport,
  TextWrapping,
  type Visual,
} from '@ardoq/yfiles';
import {
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT,
} from './consts';
import { colors } from '@ardoq/design-tokens';
import { PREFERRED_MINIMUM_GROUP_WIDTH } from '../../consts';
import { GROUP_SUBLABEL_FONT } from '../../utils';

class GroupSubLabelStyle extends LabelStyleBase {
  static Instance = new GroupSubLabelStyle();
  private constructor() {
    super();
  }
  override createVisual(
    context: IRenderContext,
    { text, layout, owner }: ILabel
  ): Visual | null {
    const textElement = createSvgElement('text', {
      fill: colors.textSubtle,
    });
    textElement.style.dominantBaseline = 'central';
    if (!owner) {
      return null;
    }
    const {
      layout: { width: groupLayoutWidth },
    } = owner as INode;
    const maximumSize = new Size(
      Math.max(
        groupLayoutWidth - 2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        PREFERRED_MINIMUM_GROUP_WIDTH
      ),
      MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT
    );
    TextRenderSupport.addText(
      textElement,
      text,
      GROUP_SUBLABEL_FONT,
      maximumSize,
      TextWrapping.WORD_ELLIPSIS,
      TextMeasurePolicy.SVG
    );

    LabelStyleBase.createLayoutTransform(context, layout, false).applyTo(
      textElement
    );
    return new SvgVisual(textElement);
  }
  override getPreferredSize(label: ILabel): Size {
    const { text, owner } = label;
    if (!owner) {
      return Size.EMPTY;
    }
    const {
      layout: { width: groupLayoutWidth },
    } = owner as INode;
    const maximumSize = new Size(
      Math.max(
        groupLayoutWidth - 2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        PREFERRED_MINIMUM_GROUP_WIDTH
      ),
      MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT
    );
    return TextRenderSupport.measureText(
      text,
      GROUP_SUBLABEL_FONT,
      maximumSize,
      TextWrapping.WORD_ELLIPSIS,
      TextMeasurePolicy.SVG
    );
  }
}
export default GroupSubLabelStyle;
