import HeaderComponent from './headerComponent';

interface ColumnHeaderComponentArgs {
  componentId: string;
  showExpander: boolean;
  isExpanded: boolean;
  isHighlighted: boolean;
  expanderClicked: () => void;
  address: (string | null)[];
}

const ColumnHeaderComponent = ({
  componentId,
  showExpander,
  expanderClicked,
  isExpanded,
  isHighlighted,
  address,
}: ColumnHeaderComponentArgs) => (
  <HeaderComponent
    componentId={componentId}
    address={address}
    showExpander={showExpander}
    expanderClicked={expanderClicked}
    isExpanded={isExpanded}
    isRowHeader={false}
    containerStyle={address.length === 1 ? { borderRight: '1px solid' } : {}}
    iconContainerStyle={{
      marginLeft: 'auto',
      marginTop: '-2px',
      height: '20px',
    }}
    isHighlighted={isHighlighted}
  />
);
export default ColumnHeaderComponent;
