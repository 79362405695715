import { APIReferenceType, ArdoqId, ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { SettingsType, updateViewSettings } from '@ardoq/view-settings';
import { handleStoreSetting } from 'viewSettings/settingsHelper';
import { DependencyMatrixViewSettings } from './types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { dispatchAction } from '@ardoq/rxbeach';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { SHOW_ONLY_CONNECTED_COMPONENTS } from '@ardoq/global-consts';

const getWorkspaceSelectLabel = ({
  referenceToWsId,
}: DependencyMatrixViewSettings) => {
  const workspaceName =
    referenceToWsId && workspaceInterface.getWorkspaceName(referenceToWsId);
  const name = workspaceName || 'Current workspace';

  return `Reference to: ${name}`;
};

const getWorkspaceOptions = (
  viewId: ViewIds,
  viewState: DependencyMatrixViewSettings,
  workspaceIds: ArdoqId[]
) =>
  workspaceIds.map(workspaceId => {
    const label = workspaceInterface.getWorkspaceName(workspaceId);

    return {
      label,
      name: label,
      isActive: viewState.referenceToWsId === workspaceId,
      onClick: getWorkspaceOnClick(viewId, workspaceId),
      type: DropdownOptionType.OPTION,
    };
  });

const getWorkspaceOnClick = (viewId: ViewIds, id: ArdoqId) => () => {
  const referenceToWsId = id;
  handleStoreSetting(viewId, { referenceToWsId });
};

const getRefTypeOptionOnClick =
  (
    viewId: ViewIds,
    currentReferenceCreationType: Record<ArdoqId, number>,
    workspaceId: ArdoqId,
    refTypeIndex?: number
  ) =>
  () => {
    const referenceCreationType: Record<ArdoqId, number | undefined> = {
      ...currentReferenceCreationType,
    };

    referenceCreationType[workspaceId] = refTypeIndex;
    handleStoreSetting(viewId, { referenceCreationType });
  };

const getReferenceCreationOptions = (
  viewId: ViewIds,
  viewState: DependencyMatrixViewSettings,
  refTypes: Record<string | number, APIReferenceType>,
  workspaceId: ArdoqId
) => {
  const NO_REF_TYPE_SELECTED_LABEL = 'Unspecified';
  const { referenceCreationType } = viewState;
  const currentReferenceCreationType = referenceCreationType[workspaceId];
  const locale = getCurrentLocale();

  const refTypeOptions = Object.values(refTypes)
    .map(refType => ({
      label: refType.name,
      name: refType.name,
      isActive: currentReferenceCreationType === refType.id,
      onClick: getRefTypeOptionOnClick(
        viewId,
        referenceCreationType,
        workspaceId,
        refType.id
      ),
      type: DropdownOptionType.OPTION,
    }))
    .sort((a, b) => localeCompare(a.name, b.name, locale));

  return [
    {
      label: 'Reference Type',
      type: DropdownOptionType.HEADER,
    },
    {
      label: NO_REF_TYPE_SELECTED_LABEL,
      name: NO_REF_TYPE_SELECTED_LABEL,
      isActive: currentReferenceCreationType === undefined,
      onClick: getRefTypeOptionOnClick(
        viewId,
        referenceCreationType,
        workspaceId
      ),
      type: DropdownOptionType.OPTION,
    },
    {
      type: DropdownOptionType.DIVIDER,
    },
    ...refTypeOptions,
  ];
};

const getReferenceCreationSelectLabel = (
  viewState: DependencyMatrixViewSettings,
  refTypes: Record<string | number, APIReferenceType>,
  workspaceId: ArdoqId
) => {
  const CREATION_TYPE_LABEL_PREFIX = 'On cell click:';
  const { referenceCreationType } = viewState;
  const currentReferenceCreationType = referenceCreationType[workspaceId];
  const selectedRefName = refTypes[currentReferenceCreationType]?.name;

  if (selectedRefName) {
    return `${CREATION_TYPE_LABEL_PREFIX} Create '${selectedRefName}' reference`;
  }
  return `${CREATION_TYPE_LABEL_PREFIX} Create reference`;
};

type GetLeftMenuConfigArgs = {
  viewId: ViewIds;
  viewState: DependencyMatrixViewSettings;
  workspaceId: ArdoqId;
  workspacesIds: ArdoqId[];
};
export const getLeftMenuConfig = ({
  viewId,
  viewState,
  workspaceId,
  workspacesIds,
}: GetLeftMenuConfigArgs) => {
  const refTypes =
    (workspaceId && workspaceInterface.getReferenceTypes(workspaceId)) || {};
  const { showOnlyConnectedComponents } = viewState;

  return [
    {
      id: 'selectReferenceToWs',
      label: getWorkspaceSelectLabel(viewState),
      iconName: IconName.WORKSPACE,
      options: getWorkspaceOptions(viewId, viewState, workspacesIds),
      type: SettingsType.DROPDOWN,
    },
    {
      id: 'selectReferenceCreationType',
      options: getReferenceCreationOptions(
        viewId,
        viewState,
        refTypes,
        workspaceId
      ),
      label: getReferenceCreationSelectLabel(viewState, refTypes, workspaceId),
      iconName: IconName.REFERENCE,
      type: SettingsType.DROPDOWN,
    },
    {
      id: 'showOnlyConnectedComponents',
      label: SHOW_ONLY_CONNECTED_COMPONENTS,
      iconName: IconName.CONNECTED,
      type: SettingsType.TOGGLE,
      isActive: showOnlyConnectedComponents,
      onClick: () => {
        dispatchAction(
          updateViewSettings({
            viewId,
            settings: {
              showOnlyConnectedComponents: !showOnlyConnectedComponents,
            },
            persistent: true,
          })
        );
      },
    },
  ];
};

export default getLeftMenuConfig;
