// TODO not used in navigator2024, but need to keep the file for now
// Can be removed when we move navigator2024 back to navigator.
import { actionCreator } from '@ardoq/rxbeach';

export const afterDragStart = actionCreator<{
  ids: Set<string>;
  entityType?: string;
}>('[dragAndDrop] AFTER_DRAG_START');

export const beforeDragEnd = actionCreator<{
  ids: Set<string>;
  targetDropId: string | null;
  dropContext: string | null;
  entityType?: string;
}>('[dragAndDrop] BEFORE_DRAG_END');
