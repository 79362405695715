import { createElement } from 'react';
import { INode, IRenderContext, SvgVisual } from '@ardoq/yfiles';
import { isContextGraphNode } from 'yfilesExtensions/styles/nodeDecorator';
import { DiffType } from '@ardoq/data-model';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { GraphNode, ensureRoot, disposeCallback } from '@ardoq/graph';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import { classes } from '@ardoq/common-helpers';
import ModernizedNodeStyle from './ModernizedNodeStyle';
import ArdoqNode from './ArdoqNode';
import { componentInterface } from '@ardoq/component-interface';
import { MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT } from './consts';
import leafNodeShapeY from './leafNodeShapeY';

class ArdoqNodeStyle extends ModernizedNodeStyle {
  private shapeTemplateHref: string;
  constructor(shapeTemplateId: string) {
    super();
    this.shapeTemplateHref = `#${shapeTemplateId}`;
  }

  override createVisual(context: IRenderContext, node: INode): SvgVisual {
    const result = super.createVisual(context, node);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }
  protected override render(container: SVGElement, node: INode) {
    const { tag } = node;
    const graphNode = tag as GraphNode;
    container.setAttribute(
      'class',
      classes(
        graphNode.getCSS({ useAsBackgroundStyle: false }),
        'skipContextUpdate'
      )
    );
    const { isGhost, modelId } = graphNode;
    const visualDiffType = graphNode.getVisualDiffType();
    const visualDiffClass =
      getCssClassFromDiffType(visualDiffType) ||
      (isGhost ? 'visual-diff-unchanged' : '');

    const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
    const tooltipAttributes = getChangedPopover(modelId, visualDiffType);
    if (modelId) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
    }

    ensureRoot(container).render(
      createElement(ArdoqNode, {
        templateHref: this.shapeTemplateHref,
        isPlaceholder,
        isContext: isContextGraphNode(graphNode),
        isTransparentized: graphNode.isTransparentized,
        visualDiffClass,
        tooltipAttributes,
        color: componentInterface.getComponentColor(modelId) || 'transparent',
        shapeY: leafNodeShapeY(
          node,
          MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT,
          false
        ),
      })
    );
  }
}

export default ArdoqNodeStyle;
