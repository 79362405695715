import { persistentReducedStream, reducer } from '@ardoq/rxbeach';

import {
  WorkspaceDigestState,
  fetchWorkspaceDigestHasError,
  fetchWorkspaceDigestPending,
  startState,
  workspaceDigestAction,
} from './actions';

const workspaceDigestReducer = (
  _: WorkspaceDigestState,
  wsDigest: WorkspaceDigestState
) => ({
  ...wsDigest,
});
const workspaceDigestReducerPending = (
  state: WorkspaceDigestState,
  pending: boolean
) => ({ ...state, isLoading: pending });
const workspaceDigestReducerHasError = (
  state: WorkspaceDigestState,
  hasError: boolean
) => ({ ...state, hasError });

export const workspaceDigest$ = persistentReducedStream(
  'workspaceDigest$',
  startState,
  [
    reducer(workspaceDigestAction, workspaceDigestReducer),
    reducer(fetchWorkspaceDigestPending, workspaceDigestReducerPending),
    reducer(fetchWorkspaceDigestHasError, workspaceDigestReducerHasError),
  ]
);
