import { action$, extractPayload, ofType, withNamespace } from '@ardoq/rxbeach';
import { startWith } from 'rxjs/operators';
import { setHoverState } from '../actions';
import { EMPTY_HIGHLIGHT_STATE } from '../consts';

export const getHoverStateStream = (viewInstanceId: string) =>
  action$.pipe(
    withNamespace(viewInstanceId),
    ofType(setHoverState),
    extractPayload(),
    startWith(EMPTY_HIGHLIGHT_STATE)
  );
