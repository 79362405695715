import {
  Color,
  EdgeStyleDecorationInstaller,
  GraphComponent,
  GraphItemTypes,
  IEdge,
  IModelItem,
  INode,
  ItemHoverInputMode,
  NodeStyleDecorationInstaller,
  PolylineEdgeStyle,
  Stroke,
  StyleDecorationZoomPolicy,
  VoidNodeStyle,
} from '@ardoq/yfiles';
import { GraphEdge, ParentChildGraphEdge } from '@ardoq/graph';
import { EDGE_SMOOTHING_LENGTH } from 'tabview/relationshipDiagrams/consts';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { referenceInterface } from '@ardoq/reference-interface';

const getEdgeColor = (graphEdge: GraphEdge) =>
  Color.from(
    graphEdge instanceof ParentChildGraphEdge
      ? 'black'
      : (referenceInterface.getCssColors(dataModelId(graphEdge), {
          useAsBackgroundStyle: false,
        })?.stroke ?? 'transparent')
  );
const getEdgeStyle = (graphEdge: GraphEdge) => {
  const { r, g, b } = getEdgeColor(graphEdge);

  return new PolylineEdgeStyle({
    stroke: new Stroke(r, g, b, 82, 10),
    smoothingLength: EDGE_SMOOTHING_LENGTH,
  });
};

export function installHoverMode(
  graphComponent: GraphComponent,
  edgeHover = false
) {
  const hoverMode = new ArdoqHoverInputMode();

  hoverMode.exclusive = false;
  hoverMode.enabled = true;
  hoverMode.hoverItems = GraphItemTypes.NODE | GraphItemTypes.EDGE;
  hoverMode.discardInvalidItems = false;

  // decorate the nodes and edges with custom highlight styles
  const decorator = graphComponent.graph.decorator;

  const nodeStyleHighlight = new NodeStyleDecorationInstaller();
  nodeStyleHighlight.nodeStyle = new VoidNodeStyle();
  decorator.nodeDecorator.highlightDecorator.setImplementation(
    nodeStyleHighlight
  );

  const edgeStyleHighlight = new EdgeStyleDecorationInstaller({
    edgeStyle: new PolylineEdgeStyle({ stroke: Stroke.TRANSPARENT }),
    zoomPolicy: StyleDecorationZoomPolicy.WORLD_COORDINATES,
  });
  decorator.edgeDecorator.highlightDecorator.setImplementation(
    edgeStyleHighlight
  );

  hoverMode.addHoveredItemChangedListener((_, event) => {
    const highlightIndicatorManager = graphComponent.highlightIndicatorManager;
    highlightIndicatorManager.clearHighlights();
    if (event.item === null) {
      return;
    }
    if (INode.isInstance(event.item)) {
      graphComponent.graph.edgesAt(event.item).forEach(edge => {
        edgeStyleHighlight.edgeStyle = getEdgeStyle(edge.tag);
        highlightIndicatorManager.addHighlight(edge);
      });
    }
    if (edgeHover && IEdge.isInstance(event.item)) {
      highlightIndicatorManager.addHighlight(event.item);
    }
  });

  return hoverMode;
}

class ArdoqHoverInputMode extends ItemHoverInputMode {
  constructor() {
    super();
  }
  isValidHoverItem(item: IModelItem) {
    return item.tag && super.isValidHoverItem(item);
  }
}
