import { GraphItem } from '@ardoq/graph';
import {
  ILabel,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import {
  getOtherLabelsHeightModernized,
  getOtherLabelSizes,
  renderOtherLabels,
} from '../labels/labelUtils';
import { createSvgElement } from '@ardoq/dom-utils';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH } from 'yfilesExtensions/styles/modernized/consts';

class OtherLabelStyle extends LabelStyleBase {
  static Instance = new OtherLabelStyle();
  private constructor() {
    super();
  }
  override createVisual(context: IRenderContext, label: ILabel): Visual | null {
    const { tag, layout } = label;
    const graphItem = tag as GraphItem;
    const container = createSvgElement('g');
    renderOtherLabels({
      graphItem,
      container,
      translateX: 0,
      yOffset: 0,
      isPlaceholder: false,
      isModern: true,
    });
    LabelStyleBase.createLayoutTransform(context, layout, false).applyTo(
      container
    );
    return new SvgVisual(container);
  }
  override getPreferredSize(label: ILabel): Size {
    const graphItem = label.tag as GraphItem;
    if (!graphItem) {
      return Size.EMPTY;
    }
    const itemLabels = graphItem.getItemLabels();
    if (!itemLabels?.otherLabels) {
      return Size.EMPTY;
    }
    const { otherLabels } = itemLabels;
    const otherLabelSizes = getOtherLabelSizes(otherLabels, true);
    const otherLabelsHeight = getOtherLabelsHeightModernized(otherLabelSizes);
    const result = new Size(
      MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
      otherLabelsHeight
    );
    return result;
  }
}
export default OtherLabelStyle;
