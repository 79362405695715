import {
  LinkUpdatePayload,
  SetLinkSourcePayload,
} from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { CreateReferencesState } from './createReferencesTypes';
import { defaultCreateReferencesState } from './defaultCreateReferencesState';
import { CreateReferenceSourceChangedPayload } from './createReferencesActions';
import { ReferenceDirection } from '@ardoq/graph';
import { getTargetId } from './utils';

const setLinkSources = (
  state: CreateReferencesState,
  {
    linkSourceNodeId,
    linkSourceIds,
    startPosition,
    refDirection,
    abortController,
  }: SetLinkSourcePayload
): CreateReferencesState => {
  return {
    ...state,
    isLinking: true,
    abortController,
    linkSourceNodeId,
    linkSourceIds,
    linkPosition: startPosition,
    linkSourceStartPosition: startPosition,
    refDirection,
    shouldShowRefDrawing:
      refDirection === ReferenceDirection.OUTGOING ||
      refDirection === ReferenceDirection.INCOMING,
  };
};

const linkUpdate = (
  state: CreateReferencesState,
  { position, linkTarget }: LinkUpdatePayload
) => ({
  ...state,
  linkPosition: position,
  linkTarget,
  hasLinkTarget:
    Boolean(linkTarget) && getTargetId(linkTarget) !== state.linkSourceNodeId,
});
const reset = (): CreateReferencesState => ({
  ...defaultCreateReferencesState,
});

const createReferenceSourceChanged = (
  state: CreateReferencesState,
  {
    startPosition,
    shouldShowRefDrawing,
    navigatorContainerBox,
  }: CreateReferenceSourceChangedPayload
): CreateReferencesState => ({
  ...state,
  linkSourceStartPosition: startPosition,
  shouldShowRefDrawing,
  navigatorContainerBox,
});

export const createReferencesOperations = {
  setLinkSources,
  linkUpdate,
  reset,
  createReferenceSourceChanged,
};
