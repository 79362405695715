import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  type DropdownConfig,
  SettingsType,
  updateViewSettings,
} from '@ardoq/view-settings';
import { BubbleChartViewSettings } from './bubbleChart/types';
import { ViewIds } from '@ardoq/api-types';
import { resolveIncludeAllDescendants } from './bubbleChart/util';

const includeAllDescendantsDropdownMenu = ({
  includeAllDescendants,
}: Pick<BubbleChartViewSettings, 'includeAllDescendants'>): DropdownConfig => {
  const resolvedIncludeAllDescendants = resolveIncludeAllDescendants(
    includeAllDescendants
  );
  return {
    id: 'includeAllDescendants',
    label: 'Include descendants',
    iconName: IconName.BLUR_ON,
    type: SettingsType.DROPDOWN,
    options: [
      {
        type: DropdownOptionType.OPTION,
        label: 'Hide all descendants',
        isActive: resolvedIncludeAllDescendants === 'none',
        onClick: () =>
          dispatchAction(
            updateViewSettings({
              viewId: ViewIds.BUBBLE,
              settings: {
                includeAllDescendants: 'none',
              } satisfies Partial<BubbleChartViewSettings>,
              persistent: true,
            })
          ),
      } satisfies DropdownOption,
      {
        type: DropdownOptionType.OPTION,
        label: 'Show direct child components',
        isActive: resolvedIncludeAllDescendants === 'direct',
        onClick: () =>
          dispatchAction(
            updateViewSettings({
              viewId: ViewIds.BUBBLE,
              settings: {
                includeAllDescendants: 'direct',
              } satisfies Partial<BubbleChartViewSettings>,
              persistent: true,
            })
          ),
      } satisfies DropdownOption,
      {
        type: DropdownOptionType.OPTION,
        label: 'Show all descendants',
        isActive: resolvedIncludeAllDescendants === 'all',
        onClick: () =>
          dispatchAction(
            updateViewSettings({
              viewId: ViewIds.BUBBLE,
              settings: {
                includeAllDescendants: 'all',
              } satisfies Partial<BubbleChartViewSettings>,
              persistent: true,
            })
          ),
      } satisfies DropdownOption,
    ],
  };
};
export default includeAllDescendantsDropdownMenu;
