import {
  GROUP_HEADER_FONT_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT,
  TAB_HEIGHT,
} from '@ardoq/graph';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  Font,
  TextMeasurePolicy,
  TextWrapping,
  FontWeight,
} from '@ardoq/yfiles';
import { MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE } from './yFilesExtensions/modernized/consts';

export const SUBHEADER_FONT_HEIGHT = 30;
export const GROUP_PADDING = 10;
const EXPANDER_MARGIN = 5;
export const EXPANDER_RADIUS = TAB_HEIGHT / 2 - 2 * EXPANDER_MARGIN;
export const HEADER_ICON_SIZE = GROUP_HEADER_FONT_HEIGHT;
export const HEADER_ICON_PADDING = 5;

export const PREFERRED_MINIMUM_GROUP_WIDTH = 672;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT,
  fontWeight: FontWeight.ITEM500,
});
export const BLOCK_DIAGRAM_GROUP_LABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: GROUP_HEADER_FONT_HEIGHT,
});
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS = {
  font: MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT,
  wrapping: TextWrapping.WORD_ELLIPSIS,
  measurePolicy: TextMeasurePolicy.SVG,
};
export const BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS = {
  font: BLOCK_DIAGRAM_GROUP_LABEL_FONT,
  wrapping: TextWrapping.WORD_ELLIPSIS,
  measurePolicy: TextMeasurePolicy.SVG,
};

export const BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X = TAB_HEIGHT;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X =
  2 * MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE;
