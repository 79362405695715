import { createElement } from 'react';
import ReactDOM from 'react-dom';
import {
  ILabel,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import ArdoqLabelStyleElement, {
  ADD_TO_SCENARIO_BUTTON_HEIGHT,
  ADD_TO_SCENARIO_BUTTON_WIDTH,
} from './ArdoqLabelStyleElement';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { createSvgElement } from '@ardoq/dom-utils';

const renderAddToScenarioLabel = (
  context: IRenderContext,
  container: SVGElement,
  label: ILabel
) => {
  const componentId = dataModelId(label.tag);
  if (!componentId) return;
  // https://ardoqcom.atlassian.net/browse/ARD-19967
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    createElement(ArdoqLabelStyleElement, { componentId }),
    container
  );
};

export class ArdoqAddToScenario extends LabelStyleBase {
  override createVisual(context: IRenderContext, label: ILabel) {
    const g = createSvgElement('g');

    renderAddToScenarioLabel(context, g, label);
    LabelStyleBase.createLayoutTransform(label.layout, true).applyTo(g);
    return new SvgVisual(g);
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    return this.createVisual(context, label);
  }

  override getPreferredSize() {
    return new Size(
      ADD_TO_SCENARIO_BUTTON_WIDTH,
      ADD_TO_SCENARIO_BUTTON_HEIGHT
    );
  }
}
