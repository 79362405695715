export enum GremlinResultType {
  REFERENCE = 'REFERENCE',
  PATH = 'PATH',
  COMPONENT = 'COMPONENT',
  DIRECT = 'DIRECT',
  MAP = 'MAP',
}

// generic result type, once the result type is recognized
// we are casting results to more specific onces
export interface GremlinResult {
  [key: string]: any;
}

export interface GremlinMapResult {
  [key: string]: string | boolean | number;
}

export enum GremlinObjectType {
  VERTEX = 'vertex',
  EDGE = 'edge',
}

export interface GremlinComponentResult {
  id: string;
  label: string;
  type: GremlinObjectType.VERTEX;
  properties: {
    rootWorkspace: [{ value: string }];
    name: [{ value: string }];
  } & Record<string, unknown>;
}

export interface GremlinPathResult {
  labels: unknown[];
  objects: (
    | GremlinComponentResult
    | GremlinReferenceResult
    | GremlinParentReferenceResult
  )[];
}

export interface GremlinReferenceResult {
  id: string;
  inV: string;
  inVLabel: string;
  label: string;
  outV: string;
  outVLabel: string;
  type: GremlinObjectType.EDGE;
  properties: {
    created: string;
    createdBy: string;
    displayText: string;
    lastModifiedBy: string;
    lastUpdated: string;
    model: string;
    order: number;
    rootWorkspace: string;
    source: string;
    target: string;
    targetWorkspace: string;
  };
}

export interface GremlinParentReferenceResult {
  id: string;
  inV: string;
  inVLabel: string;
  label: 'ardoq_parent';
  outV: string;
  outVLabel: string;
  type: GremlinObjectType.EDGE;
}
