import { useEffect, useRef, useState } from 'react';

const useComputedStyle = (
  element: HTMLDivElement | null
): CSSStyleDeclaration | null => {
  const [computedStyle, setComputedStyle] =
    useState<CSSStyleDeclaration | null>(null);
  const windowRef = useRef<Window & typeof globalThis>(window);

  useEffect(() => {
    windowRef.current = window;
  });
  useEffect(() => {
    if (element) {
      setComputedStyle(windowRef.current.getComputedStyle(element));
    }
  }, [element]);

  return computedStyle;
};

export default useComputedStyle;
