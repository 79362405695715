import { PagesViewSettings } from 'tabview/pagesView/types';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  MenuMetaOptions,
  type ReferenceTypeOption,
  type TraversedReferenceTypeInfo,
  isTraversedReferenceType,
  matchSelectedReferenceType,
  getAllNoneDropdownGroup,
  getDropdownOptionGroup,
  formatReferenceType,
  getFieldMultiSelectDropdownOptions,
  getRightMenuConfig,
} from '@ardoq/settings-bar';
import {
  type SettingsConfig,
  SettingsType,
  viewSettingsConsts,
} from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { APIFieldAttributes, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import type { OnViewSettingsUpdate } from '@ardoq/data-model';
import { popoverRegistry } from '@ardoq/popovers';
import { addToPresentation } from 'viewSettings/exportHandlers';
import { viewHasLegend } from 'views/metaInfoTabs';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { SimpleTextPopover } from '@ardoq/popovers';
import { createElement } from 'react';
import { CommonDropdownOptions } from '@ardoq/global-consts';

const VIEW_ID = ViewIds.PAGESVIEW;
const FIELD_SETTINGS_KEY = 'includeFields';

const PAGES_VIEW_LEGEND_IMPOSSIBLE = 'pagesViewLegendImpossible';
popoverRegistry.set(PAGES_VIEW_LEGEND_IMPOSSIBLE, () =>
  createElement(SimpleTextPopover, {
    text: 'Legend is available when a conditional format is added in Perspectives',
  })
);

const referenceTypeIsSelected = (
  value: TraversedReferenceTypeInfo,
  selectedReferenceTypes: ReferenceTypeOption[]
) =>
  Boolean(
    selectedReferenceTypes.find(
      selectedReferenceType =>
        isTraversedReferenceType(selectedReferenceType) &&
        matchSelectedReferenceType(value, selectedReferenceType)
    )
  );

const referenceOptionIsActive = (
  referenceOption: ReferenceTypeOption,
  selectedReferenceTypes: ReferenceTypeOption[],
  availableReferenceTypes: TraversedReferenceTypeInfo[]
) => {
  if (
    referenceOption === CommonDropdownOptions.ALL ||
    referenceOption === CommonDropdownOptions.ALL_EXCEPT_PARENT_CHILD
  ) {
    return (
      selectedReferenceTypes.includes(referenceOption) ||
      availableReferenceTypes.every(referenceType =>
        referenceTypeIsSelected(referenceType, selectedReferenceTypes)
      )
    );
  } else if (referenceOption === CommonDropdownOptions.NONE) {
    return selectedReferenceTypes.length === 0;
  }
  return (
    selectedReferenceTypes.includes(CommonDropdownOptions.ALL) ||
    referenceTypeIsSelected(
      referenceOption as TraversedReferenceTypeInfo,
      selectedReferenceTypes
    )
  );
};

const toggleReferenceType = (
  value: ReferenceTypeOption,
  selectedReferenceTypes: ReferenceTypeOption[],
  availableReferenceTypes: TraversedReferenceTypeInfo[]
) => {
  if (value === CommonDropdownOptions.ALL) {
    return selectedReferenceTypes.includes(CommonDropdownOptions.ALL)
      ? []
      : [CommonDropdownOptions.ALL];
  } else if (value === CommonDropdownOptions.NONE) {
    return [];
  } else if (selectedReferenceTypes.includes(CommonDropdownOptions.ALL)) {
    return availableReferenceTypes.filter(
      referenceType =>
        !matchSelectedReferenceType(
          referenceType,
          value as TraversedReferenceTypeInfo
        )
    );
  } else if (
    referenceTypeIsSelected(
      value as TraversedReferenceTypeInfo,
      selectedReferenceTypes as TraversedReferenceTypeInfo[]
    )
  ) {
    return selectedReferenceTypes.filter(
      selectedReferenceType =>
        !matchSelectedReferenceType(
          value as TraversedReferenceTypeInfo,
          selectedReferenceType as TraversedReferenceTypeInfo
        )
    );
  }
  return [...selectedReferenceTypes, value];
};

const getSelectedReferenceOnClick =
  (
    settingsKey: string,
    selectedReferenceTypes: ReferenceTypeOption[],
    availableReferenceTypes: TraversedReferenceTypeInfo[]
  ) =>
  (value: ReferenceTypeOption) => {
    const newSelectedReferenceTypes = toggleReferenceType(
      value,
      selectedReferenceTypes,
      availableReferenceTypes
    );
    const settings = { [settingsKey]: newSelectedReferenceTypes };
    const persistent = true;
    dispatchAction(
      updateViewSettings({
        viewId: VIEW_ID,
        settings,
        persistent,
      })
    );
  };

export const getLeftMenu = (
  viewState: PagesViewSettings,
  incomingReferenceTypes: TraversedReferenceTypeInfo[],
  outgoingReferenceTypes: TraversedReferenceTypeInfo[],
  workspaceFields: (Pick<APIFieldAttributes, 'name' | 'label'> & {
    deselectedByDefault?: boolean;
  })[],
  onViewSettingsUpdate: OnViewSettingsUpdate
): SettingsConfig[] => {
  const {
    hasRefTypeOptions: hasIncomingReferenceTypes,
    referenceTypeGroup: incomingReferenceTypeOptions,
  } = getDropdownOptionGroup<TraversedReferenceTypeInfo, PagesViewSettings>({
    viewId: VIEW_ID,
    availableRefTypes: incomingReferenceTypes,
    key: 'includeIncomingReferenceTypes',
    viewState,
    isActive: (referenceType: TraversedReferenceTypeInfo) =>
      referenceOptionIsActive(
        referenceType,
        viewState.includeIncomingReferenceTypes,
        incomingReferenceTypes
      ),
    onClick: getSelectedReferenceOnClick(
      'includeIncomingReferenceTypes',
      viewState.includeIncomingReferenceTypes,
      incomingReferenceTypes
    ),
    formatValue: formatReferenceType,
    metaOptions: MenuMetaOptions.NO_OPTIONS,
  });

  const incomingAllNone = getAllNoneDropdownGroup<
    TraversedReferenceTypeInfo,
    PagesViewSettings
  >({
    viewId: VIEW_ID,
    viewState,
    key: 'includeIncomingReferenceTypes',
    availableOptions: incomingReferenceTypes,
    isActive: (commonDropdownOption: CommonDropdownOptions) =>
      referenceOptionIsActive(
        commonDropdownOption,
        viewState.includeIncomingReferenceTypes,
        incomingReferenceTypes
      ),
    onClick: getSelectedReferenceOnClick(
      'includeIncomingReferenceTypes',
      viewState.includeIncomingReferenceTypes,
      incomingReferenceTypes
    ),
  });

  const {
    hasRefTypeOptions: hasOutgoingReferenceTypes,
    referenceTypeGroup: outgoingReferenceTypeOptions,
  } = getDropdownOptionGroup<TraversedReferenceTypeInfo, PagesViewSettings>({
    viewId: VIEW_ID,
    availableRefTypes: outgoingReferenceTypes,
    key: 'includeOutgoingReferenceTypes',
    viewState,
    isActive: (referenceType: TraversedReferenceTypeInfo) =>
      referenceOptionIsActive(
        referenceType,
        viewState.includeOutgoingReferenceTypes,
        outgoingReferenceTypes
      ),
    onClick: getSelectedReferenceOnClick(
      'includeOutgoingReferenceTypes',
      viewState.includeOutgoingReferenceTypes,
      outgoingReferenceTypes
    ),
    formatValue: formatReferenceType,
    metaOptions: MenuMetaOptions.NO_OPTIONS,
  });

  const outgoingAllNone = getAllNoneDropdownGroup<
    TraversedReferenceTypeInfo,
    PagesViewSettings
  >({
    viewId: VIEW_ID,
    viewState,
    key: 'includeOutgoingReferenceTypes',
    availableOptions: outgoingReferenceTypes,
    isActive: (commonDropdownOption: CommonDropdownOptions) =>
      referenceOptionIsActive(
        commonDropdownOption,
        viewState.includeOutgoingReferenceTypes,
        outgoingReferenceTypes
      ),
    onClick: getSelectedReferenceOnClick(
      'includeOutgoingReferenceTypes',
      viewState.includeOutgoingReferenceTypes,
      outgoingReferenceTypes
    ),
  });

  const outgoingGroup = hasOutgoingReferenceTypes
    ? [
        {
          label: viewSettingsConsts.OUTGOING_REFERENCE_TYPES,
          type: DropdownOptionType.HEADER,
        },
        ...outgoingAllNone,
        ...outgoingReferenceTypeOptions,
      ]
    : [];

  const incomingGroup = hasIncomingReferenceTypes
    ? [
        {
          label: viewSettingsConsts.INCOMING_REFERENCE_TYPES,
          type: DropdownOptionType.HEADER,
        },
        ...incomingAllNone,
        ...incomingReferenceTypeOptions,
      ]
    : [];

  return [
    {
      id: 'toggleFields',
      label: 'Select fields',
      iconName: IconName.FORMAT_LIST_BULLETED,
      options: getFieldMultiSelectDropdownOptions(
        VIEW_ID,
        workspaceFields,
        viewState as Pick<PagesViewSettings, 'includeFields'>,
        FIELD_SETTINGS_KEY,
        onViewSettingsUpdate
      ),
      type: SettingsType.DROPDOWN,
      isDisabled: false,
      isKeepOpen: true,
    },
    {
      id: 'toggleReferenceTypes',
      label: 'Select reference types',
      iconName: IconName.REFERENCE,
      options: [...outgoingGroup, ...incomingGroup],
      type: SettingsType.DROPDOWN,
      isDisabled: !hasIncomingReferenceTypes && !hasOutgoingReferenceTypes,
      isKeepOpen: true,
    },
    {
      id: 'hideEmptyFields',
      label: viewState.hideEmptyFields
        ? 'Show empty fields'
        : 'Hide empty fields',
      iconName: viewState.hideEmptyFields
        ? IconName.SPACE_BAR
        : IconName.SPACE_BAR_CROSSED,
      type: SettingsType.TOGGLE,
      isActive: viewState.hideEmptyFields,
      isDisabled: false,
      onViewSettingsUpdate,
    },
    {
      id: 'expandDescription',
      label: viewState.expandDescription
        ? 'Collapse descriptions'
        : 'Expand descriptions',
      iconName: viewState.expandDescription
        ? IconName.UNFOLD_LESS
        : IconName.UNFOLD_MORE,
      type: SettingsType.TOGGLE,
      isActive: viewState.expandDescription,
      isDisabled: false,
      onViewSettingsUpdate,
    },
  ];
};

type GetRightMenuArgs = {
  viewSettings: PagesViewSettings;
  onViewSettingsUpdate: OnViewSettingsUpdate;
  isEmptyView: boolean;
  printOrSaveToPdf: () => void;
  isLegendPossible: boolean;
};

export const getRightMenu = ({
  viewSettings,
  onViewSettingsUpdate,
  isEmptyView,
  printOrSaveToPdf,
  isLegendPossible,
}: GetRightMenuArgs) =>
  getRightMenuConfig({
    viewId: VIEW_ID,
    viewstate: viewSettings,
    exports: {
      printOrSaveToPdf,
      addToPresentation: () => addToPresentation(VIEW_ID),
      isDisabled: isEmptyView,
    },
    withLegend: viewHasLegend(VIEW_ID),
    knowledgeBaseLink: KnowledgeBaseLink.PAGES,
    onViewSettingsUpdate,
  }).map(setting =>
    setting.id === viewSettingsConsts.IS_LEGEND_ACTIVE
      ? {
          id: viewSettingsConsts.IS_LEGEND_ACTIVE,
          label: 'Toggle legend',
          iconName: IconName.INFO,
          isActive: viewSettings[viewSettingsConsts.IS_LEGEND_ACTIVE],
          isDisabled: !isLegendPossible || isEmptyView,
          popoverId: !isLegendPossible ? PAGES_VIEW_LEGEND_IMPOSSIBLE : null,
          persistent: false,
          type: SettingsType.TOGGLE,
          onViewSettingsUpdate,
        }
      : setting
  );
