import { INode, Size } from '@ardoq/yfiles';
import { isContextNode, isSelectedRelatedNode } from './nodeDecorator';
import { CompElementExtension } from 'aqTypes';
import SparkMD5 from 'spark-md5';
import { CONTEXT_HIGHLIGHT_PADDING } from './consts';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { GraphNode, ensureRoot } from '@ardoq/graph';
import LegacyArdoqNodeStyleBase from './LegacyArdoqNodeStyleBase';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import ArdoqNode from './ArdoqNode';
import { createElement } from 'react';
import { determineImgStyleSize } from './util';
import { DiffType } from '@ardoq/data-model';
import { Node } from 'graph/node';
import { isConditionalFormattingForComponentsActive } from 'utils/modelCssManager/getCssColors';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { getImageColorFilterValueForModel } from 'views/ConditionalFormattingImageColorFilters';

export default class ArdoqImageStyle extends LegacyArdoqNodeStyleBase {
  private imageUrl: string;
  private _size: Size | null = null;

  constructor(imageUrl: string) {
    super();
    this.imageUrl = imageUrl;
  }

  override get size() {
    if (this._size === null) {
      this._size = determineImgStyleSize(this.imageUrl);
    }
    return this._size;
  }

  override getHash(node: INode) {
    const graphNode = node.tag as GraphNode | Node;
    return SparkMD5.hash(
      [
        graphNode && isConditionalFormattingForComponentsActive()
          ? graphNode.getCSS()
          : '',
        graphNode.getVisualDiffType(),
        graphNode.isTransparentized,
        graphNode ? node.tag.isGhost : false,
        isContextNode(node),
        isSelectedRelatedNode(node),
        node.layout.width,
        node.layout.height,
      ].join('-')
    );
  }

  protected override render(container: SVGElement, node: INode) {
    (container as SVGElement & CompElementExtension).comp =
      node.tag && node.tag.dataModel;

    const visualDiffType = node.tag.getVisualDiffType();
    const visualDiffClass =
      getCssClassFromDiffType(visualDiffType) ||
      (node.tag instanceof GraphNode && node.tag.isGhost
        ? 'visual-diff-unchanged'
        : '');

    const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
    const isContext = isContextNode(node);
    const isSelectedRelated = isSelectedRelatedNode(node);
    const { width, height } = node.layout;
    const id = dataModelId(node.tag);
    const tooltipAttributes = getChangedPopover(id, visualDiffType);
    if (id) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, id);
      container.setAttribute('class', (node.tag as Node).getCSS());
    }

    ensureRoot(container).render(
      createElement(ArdoqNode, {
        imageHref: this.imageUrl,
        conditionalFormattingColorFilterValue:
          getImageColorFilterValueForModel(id),
        isPlaceholder,
        isContext,
        isSelectedRelated,
        width,
        height,
        visualDiffClass,
        tooltipAttributes,
        contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
        isTransparentized: node.tag.isTransparentized,
      })
    );
  }
}
