import ComponentMatrixRowHeaderCell from './componentMatrixRowHeaderCell';
import { RowElementModel } from '../types';
import { ArdoqId } from '@ardoq/api-types';

interface ComponentMatrixRowHeaderArgs {
  rowModel: RowElementModel;
  rowIndex: number;
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
}
const ComponentMatrixRowHeader = ({
  rowModel,
  rowIndex,
  hoveredComponentId,
  focusedComponentId,
}: ComponentMatrixRowHeaderArgs) => (
  <>
    {rowModel.headerCells.map((headerCell, headerCellIndex) => {
      return (
        <ComponentMatrixRowHeaderCell
          key={headerCellIndex}
          headerCell={headerCell}
          rowIndex={rowIndex}
          hoveredComponentId={hoveredComponentId}
          focusedComponentId={focusedComponentId}
        />
      );
    })}
  </>
);
export default ComponentMatrixRowHeader;
