import { MouseEventHandler } from 'react';
import { ChevronDownIcon, ChevronUpIcon, IconSize } from '@ardoq/icons';
import { ReservedExpander } from './atoms';

type ExpanderProps = {
  hasChildren: boolean;
  isCollapsed: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  size?: IconSize | number;
};

export const Expander = ({
  hasChildren,
  onClick,
  isCollapsed,
  size = 18,
}: ExpanderProps) => (
  <ReservedExpander onClick={onClick} $size={size}>
    {hasChildren &&
      (isCollapsed ? (
        <ChevronDownIcon iconSize={size} />
      ) : (
        <ChevronUpIcon iconSize={size} />
      ))}
  </ReservedExpander>
);
