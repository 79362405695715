import { RefObject, createRef, Component } from 'react';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

const GhostElement = styled.div`
  background-color: ${colors.blue95};
  border: 1px solid hsla(210, 100%, 50%, 0.9);
  pointer-events: none;
  position: fixed;
  z-index: 10;
`;

type GhostProps = {
  left: number;
  top: number;
  onSelection: (clientRect: DOMRect) => any;
};

class Ghost extends Component<GhostProps, any> {
  state = {
    style: {},
  };

  ghostRef: RefObject<HTMLDivElement> = createRef();

  onMouseMove = (event: MouseEvent) => {
    if (!this.ghostRef.current) {
      return;
    }
    const { clientX: x, clientY: y } = event;
    const isOnLeftSide = x < this.props.left;
    const isOnTopSide = y < this.props.top;
    const left = isOnLeftSide ? x : this.props.left;
    const right = isOnLeftSide ? this.props.left : x;
    const top = isOnTopSide ? y : this.props.top;
    const bottom = isOnTopSide ? this.props.top : y;
    const style = {
      left: `${left}px`,
      top: `${top}px`,
      width: `${right - left}px`,
      height: `${bottom - top}px`,
    };
    this.setState({ style });
  };

  onMouseUp = () => {
    if (this.ghostRef.current) {
      this.props.onSelection(this.ghostRef.current.getBoundingClientRect());
    }
  };

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  render() {
    return <GhostElement ref={this.ghostRef} style={this.state.style} />;
  }
}

export default Ghost;
