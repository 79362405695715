import { colors } from '@ardoq/design-tokens';
import {
  GraphComponent,
  IGraph,
  INode,
  IRenderContext,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import type { GraphGroup } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { createFifoCache } from '@ardoq/common-helpers';
import nodeBoundingShape from '../../nodeBoundingShape';
import proteanDiagramPathData from '../../proteanDiagramPathData';
import { createSvgElement } from '@ardoq/dom-utils';

const MARGIN = 10;
/** @returns a function to calculate the bounds of a node and its children. */
const nodeBoundsCalculator = (graph: IGraph) => {
  /** @returns the bounds of the node and its children. */
  const nodeBounds: (node: INode) => Point[] = createFifoCache(
    Infinity,
    (node: INode) => nodeBoundingShape(node, graph, MARGIN, nodeBounds)
  );
  return nodeBounds;
};
class ConvexHullGroupStyle extends NodeStyleBase {
  protected override createVisual(
    context: IRenderContext,
    node: INode
  ): Visual | null {
    const {
      layout: { x, y, width, height },
    } = node;
    if (!isFinite(width) || !isFinite(height) || !isFinite(x) || !isFinite(y)) {
      return null;
    }
    const graph = (context.canvasComponent as GraphComponent).graph;

    const { fill = colors.grey90, stroke = colors.black } =
      getComponentCssColors((node.tag as GraphGroup).modelId, {
        useAsBackgroundStyle: true,
      }) ?? { fill: colors.grey90, stroke: colors.black };
    const calculateNodeBounds = nodeBoundsCalculator(graph);
    const pathData = proteanDiagramPathData(calculateNodeBounds(node));
    if (!pathData) {
      return null;
    }
    const pathElement = createSvgElement('path', {
      d: pathData,
      fill,
      stroke,
    });
    pathElement.style.opacity = '0.8';
    return new SvgVisual(pathElement);
  }
}
export default ConvexHullGroupStyle;
