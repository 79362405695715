import { createFifoCache } from '@ardoq/common-helpers';
import { GRAPH_ITEM_LABEL_CACHE_SIZE } from '@ardoq/graph';
import addText from 'yfilesExtensions/addText';
import { Font } from '@ardoq/yfiles';
import { createSvgElement } from '@ardoq/dom-utils';
import { logWarn } from '@ardoq/logging';
import { getMaxLabelSize } from './getMaxLabelSize';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
} from 'yfilesExtensions/styles/consts';

const createTextElement = (
  args: string,
  font: Font,
  isModern: boolean,
  isSingleLine?: boolean
) => {
  const [text, legacyTruncatedFieldValueAndLabelForNode] =
    args.split(DELIMETER);

  const textElement = createSvgElement('text', {
    'text-anchor': 'middle',
  });
  font.applyTo(textElement);

  if (isSingleLine) {
    // optimization: if the text is not multiline, don't use TextRenderSupport.addText. this call is expensive, and it generates <tspan> elements which leads to a larger DOM.
    textElement.textContent = text;
    textElement.setAttribute('dominant-baseline', 'central');
    return textElement;
  }

  const maximumSize = getMaxLabelSize(
    font,
    isModern,
    legacyTruncatedFieldValueAndLabelForNode
  );

  addText({
    ...(isModern
      ? MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS
      : BLOCK_DIAGRAM_LABEL_TEXT_PARAMS),
    targetElement: textElement,
    text,
    font,
    maximumSize,
  });

  if (legacyTruncatedFieldValueAndLabelForNode) {
    const tspan = createSvgElement('tspan', {
      dy: '1.5em',
      x: '0',
    });
    tspan.textContent = legacyTruncatedFieldValueAndLabelForNode;
    textElement.appendChild(tspan);
  }

  return textElement;
};

const DELIMETER = '{&&&&}'; // a random string that is unlikely to be in a label
const mainNodeLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, BLOCK_DIAGRAM_NODE_LABEL_FONT, false)
);
const modernizedNodeLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT, true)
);

const mainEdgeLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, BLOCK_DIAGRAM_EDGE_LABEL_FONT, false)
);
const modernizedEdgeLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT, true)
);

const otherLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, BLOCK_DIAGRAM_OTHER_LABEL_FONT, false)
);
const modernizedOtherLabelTextElementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (args: string) =>
    createTextElement(args, MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT, true)
);

/**
 * text elements are cached based on the text value, so it's possible we'll be pulling another node's label (with the same text) from the cache.
 * we don't want to steal another node's label! if it already has a parent, it's being used, so just clone it.
 */
const cloneIfHasParent = (cachedTextElement: SVGTextElement) =>
  cachedTextElement.parentNode
    ? (cachedTextElement.cloneNode(true) as SVGTextElement)
    : cachedTextElement;
const cachedTextElement = (
  text: string,
  font: Font,
  isSingleLine: boolean,
  isModern: boolean,
  legacyTruncatedFieldValueAndLabelForNode?: string
) => {
  const createElementArgs = legacyTruncatedFieldValueAndLabelForNode
    ? `${text}${DELIMETER}${legacyTruncatedFieldValueAndLabelForNode}`
    : text;

  if (isSingleLine) {
    return createTextElement(createElementArgs, font, isModern, isSingleLine);
  }

  switch (font) {
    case BLOCK_DIAGRAM_NODE_LABEL_FONT:
      return mainNodeLabelTextElementCache(createElementArgs);
    case MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT:
      return modernizedNodeLabelTextElementCache(createElementArgs);
    case BLOCK_DIAGRAM_EDGE_LABEL_FONT:
      return mainEdgeLabelTextElementCache(createElementArgs);
    case MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT:
      return modernizedEdgeLabelTextElementCache(createElementArgs);
    case BLOCK_DIAGRAM_OTHER_LABEL_FONT:
      return otherLabelTextElementCache(createElementArgs);
    case MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT:
      return modernizedOtherLabelTextElementCache(createElementArgs);
    default:
      logWarn(new Error('Unexpected font in label text element cache'), null, {
        font,
      });
      return createSvgElement('text');
  }
};

export const getCachedMultiLabelTextElement = (
  text: string,
  font: Font,
  isSingleLine: boolean,
  isModern: boolean,
  legacyTruncatedFieldValueAndLabelForNode?: string
) =>
  cloneIfHasParent(
    cachedTextElement(
      text,
      font,
      isSingleLine,
      isModern,
      legacyTruncatedFieldValueAndLabelForNode
    )
  );
