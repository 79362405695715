import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoConnectedComponentsWorkspaceDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
};

const NoConnectedComponentsWorkspaceDoq = ({
  doqType,
}: NoConnectedComponentsWorkspaceDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No connected components"
      message="Select a workspace containing at least one component which is connected to another component."
    />
  </EmptyStatesDoqContainer>
);
export default NoConnectedComponentsWorkspaceDoq;
