import { createSvgElement } from '@ardoq/dom-utils';
import {
  type ILabel,
  type IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  type Visual,
} from '@ardoq/yfiles';
import {
  AGGREGATED_COUNT_BADGE_RADIUS,
  type CollapsibleGraphGroup,
  estimateCountLabelWidth,
  NO_LINK_TARGET_DECORATION,
} from '@ardoq/graph';
import { colors } from '@ardoq/design-tokens';

class GroupDescendantCountLabelStyle extends LabelStyleBase {
  static Instance = new GroupDescendantCountLabelStyle();
  private constructor() {
    super();
  }
  override createVisual(
    context: IRenderContext,
    { text, layout, owner, tag }: ILabel
  ): Visual | null {
    const graphNode: CollapsibleGraphGroup | null | undefined =
      tag || owner?.tag;
    if (!graphNode) {
      return null;
    }

    const container = createSvgElement('g');
    const { descendantCount } = graphNode;
    const width = Math.max(
      estimateCountLabelWidth(descendantCount),
      2 * AGGREGATED_COUNT_BADGE_RADIUS
    );
    const background = createSvgElement('rect', {
      fill: colors.grey35,
      width: `${width}`,
      height: `${2 * AGGREGATED_COUNT_BADGE_RADIUS}`,
      rx: `${AGGREGATED_COUNT_BADGE_RADIUS}`,
      ry: `${AGGREGATED_COUNT_BADGE_RADIUS}`,
      class: NO_LINK_TARGET_DECORATION,
    });
    container.appendChild(background);
    const textElement = createSvgElement('text', {
      fill: colors.white,
      x: `${width / 2}`,
      y: `${AGGREGATED_COUNT_BADGE_RADIUS}`,
    });
    textElement.style.dominantBaseline = 'central';
    textElement.style.textAnchor = 'middle';
    textElement.textContent = text;
    container.appendChild(textElement);

    LabelStyleBase.createLayoutTransform(context, layout, false).applyTo(
      container
    );
    return new SvgVisual(container);
  }
  override getPreferredSize({ tag, owner }: ILabel): Size {
    const graphNode: CollapsibleGraphGroup | null | undefined =
      tag || owner?.tag;
    if (!graphNode) {
      return Size.ZERO;
    }
    return new Size(
      estimateCountLabelWidth(graphNode.descendantCount),
      2 * AGGREGATED_COUNT_BADGE_RADIUS
    );
  }
}
export default GroupDescendantCountLabelStyle;
