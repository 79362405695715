import { Vector } from '@ardoq/graph';
import { BlocksViewNode } from '../types';
import { isGroup } from '../viewModel$/util';
import { LayoutType, resetLayout } from './layout';

type EntryItem = {
  node: BlocksViewNode;
  col: number;
  colSpan: number;
  row: number;
  rowSpan: number;
};

const createEntryItem = (node: BlocksViewNode): EntryItem => {
  return {
    node: node,
    col: node.col,
    colSpan: node.colSpan,
    row: node.row,
    rowSpan: node.rowSpan,
  };
};

type Entry = EntryItem[];

// the stack should almost certainly be limited in size
// and must be reset when
const stack: Entry[] = [];

export const editCanUndo = () => {
  return stack.length > 0;
};

export const editUndo = () => {
  const entry = stack.pop();

  if (entry) {
    for (const edit of entry) {
      edit.node.col = edit.col;
      edit.node.colSpan = edit.colSpan;
      edit.node.row = edit.row;
      edit.node.rowSpan = edit.rowSpan;
    }
  }
};

export const editInitialiseUndoStack = () => {
  stack.length = 0;
};

export const editResize = (
  node: BlocksViewNode,
  col: number,
  colSpan: number,
  row: number,
  rowSpan: number
) => {
  if (
    node.col !== col ||
    node.colSpan !== colSpan ||
    node.row !== row ||
    node.rowSpan !== rowSpan
  ) {
    const entry: Entry = [];
    const shuffle = [0, 0];

    if (col < 1 || row < 1) {
      shuffle[0] = col < 1 ? -col + 1 : 0; // make sure that nodes start on col 1
      shuffle[1] = row < 1 ? -row + 1 : 0; // make sure that nodes start on row 1

      const group = node.parent!;

      for (const child of group.children!) {
        entry.push(createEntryItem(child));

        child.col += shuffle[0];
        child.row += shuffle[1];
      }
    } else {
      entry.push(createEntryItem(node));
    }

    node.col = col + shuffle[0];
    node.colSpan = colSpan;
    node.row = row + shuffle[1];
    node.rowSpan = rowSpan;

    stack.push(entry);

    //  node.parent!.children!.sort(lexicalComparator);
  }
};

export const editMove = (
  group: BlocksViewNode,
  nodes: Iterable<BlocksViewNode>,
  dragOffset: Vector
) => {
  if (dragOffset[0] === 0 && dragOffset[1] === 0) {
    return;
  }

  const entry: Entry = [];
  const shuffle = [0, 0];

  if (dragOffset[0] < 0 || dragOffset[1] < 0) {
    let colMin = +Infinity;
    let rowMin = +Infinity;

    for (const node of nodes) {
      colMin = Math.min(colMin, node.col + dragOffset[0]);
      rowMin = Math.min(rowMin, node.row + dragOffset[1]);
    }

    shuffle[0] = colMin < 1 ? -colMin + 1 : 0;
    shuffle[1] = rowMin < 1 ? -rowMin + 1 : 0;
  }

  if (shuffle[0] !== 0 || shuffle[1] !== 0) {
    for (const child of group.children!) {
      entry.push(createEntryItem(child));

      child.col += shuffle[0];
      child.row += shuffle[1];
    }
  } else {
    for (const node of nodes) {
      entry.push(createEntryItem(node));
    }
  }

  for (const node of nodes) {
    node.col += dragOffset[0];
    node.row += dragOffset[1];
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
  //    group.children!.sort(lexicalComparator)
};

export const editReset = (
  groups: Iterable<BlocksViewNode>,
  type: LayoutType
) => {
  const entry: Entry = [];

  for (const group of groups) {
    if (isGroup(group)) {
      for (const child of group.children!) {
        entry.push(createEntryItem(child));
      }

      resetLayout(group, type);
    }
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
};

export const editFlipHorz = (groups: Iterable<BlocksViewNode>) => {
  const entry: Entry = [];

  for (const group of groups) {
    if (isGroup(group) && group.colSize && group.rowSize) {
      const colMax = group.colSize!.length - 1;

      for (const child of group.children!) {
        const col = colMax - (child.col + child.colSpan - 1);

        if (col !== child.col) {
          entry.push(createEntryItem(child));
          child.col = col;
        }
      }
    }
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
};

export const editFlipVert = (groups: Iterable<BlocksViewNode>) => {
  const entry: Entry = [];

  for (const group of groups) {
    if (isGroup(group) && group.colSize && group.rowSize) {
      const rowMax = group.rowSize!.length - 1;

      for (const child of group.children!) {
        const row = rowMax - (child.row + child.rowSpan - 1);

        if (child.row !== row) {
          entry.push(createEntryItem(child));
          child.row = row;
        }
      }
    }
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
};

export const editRotate = (groups: Iterable<BlocksViewNode>) => {
  const entry: Entry = [];

  for (const group of groups) {
    if (isGroup(group)) {
      /* rotate the children */

      const colMax = group.colSize!.length - 1;

      for (const child of group.children!) {
        const left = child.row;
        const top = colMax - (child.col + child.colSpan - 1);

        if (
          child.col !== left ||
          child.row !== top ||
          child.colSpan !== child.rowSpan
        ) {
          entry.push(createEntryItem(child));

          child.col = left;
          child.row = top;

          const t = child.colSpan;
          child.colSpan = child.rowSpan;
          child.rowSpan = t;
        }
      }

      /* rotate the group */

      const colSize = group.colSize;
      group.colSize = group.rowSize;
      group.rowSize = colSize;
    }
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
};

export const editInsertCol = (group: BlocksViewNode, col: number) => {
  if (!group.children?.length) {
    return;
  }

  const entry: Entry = [];

  for (const child of group.children) {
    if (child.col > col) {
      entry.push(createEntryItem(child));

      child.col += 2;
    }
  }

  if (entry.length !== 0) {
    stack.push(entry);
  }
};

export const editCanDeleteCol = (group: BlocksViewNode, col: number) => {
  if (!group.children?.length) {
    return;
  }

  if (!group.colSize || col <= 0 || col >= group.colSize.length - 1) {
    return false;
  }

  if ((col & 0) === 0) {
    // can only delete routing col if the both adjacent node col are empty and col isn't spanned
    // the test is too sketchy to try implementing before the release

    return false;
  }

  for (const child of group.children) {
    if (child.col >= col && child.col + child.colSpan - 1 <= col) {
      return false;
    }
  }

  return true;
};

export const editDeleteCol = (group: BlocksViewNode, col: number) => {
  if (!group.children?.length) {
    return;
  }

  const entry: Entry = [];

  for (const child of group.children) {
    if (child.col > col) {
      entry.push(createEntryItem(child));
      child.col -= 2;
    }
  }

  if (entry.length > 0) {
    stack.push(entry);
  }
};

export const editInsertRow = (group: BlocksViewNode, row: number) => {
  if (!group.children?.length) {
    return;
  }

  const entry: Entry = [];

  for (const child of group.children) {
    if (child.row > row) {
      entry.push(createEntryItem(child));
      child.row += 2;
    }
  }

  if (entry.length > 0) {
    stack.push(entry);
  }
};
export const editCanDeleteRow = (group: BlocksViewNode, row: number) => {
  if (!group.children?.length) {
    return false;
  }

  if (!group.rowSize || row <= 0 || row >= group.rowSize.length - 1) {
    return false;
  }

  if ((row & 0) === 0) {
    // the test is too sketchy to try implementing before the release

    return false;
  }

  for (const child of group.children) {
    if (child.row >= row && child.row + child.rowSpan - 1 <= row) {
      return false;
    }
  }

  return true;
};

export const editDeleteRow = (group: BlocksViewNode, row: number) => {
  if (!group.children?.length) {
    return;
  }

  const entry: Entry = [];

  for (const child of group.children) {
    if (child.row > row) {
      entry.push(createEntryItem(child));
      child.row -= 2;
    }
  }

  if (entry.length > 0) {
    stack.push(entry);
  }
};
