import { ComponentBackboneModel, Reference } from 'aqTypes';
import { alert, confirmDelete } from '@ardoq/modal';
import { ComponentsAndOrReferences } from './types';
import {
  deleteComponentsByIds,
  deleteReferencesByIds,
  getDialogConfigByIds,
  wrapInList,
} from './helpers';
import { logError } from '@ardoq/logging';
import { getManagedDialogConfig } from 'externallyManaged/confirmManagedComponentDeletion/getDialogConfig';
import externallyManagedNotice$ from 'externallyManaged/externallyManagedNotice$';
import { ArdoqId } from '@ardoq/api-types';
import trackDialogDeleteButton from '../tracking';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import {
  isArdoqError,
  ArdoqError,
  RequireAtLeastOne,
} from '@ardoq/common-helpers';
import { contextInterface } from 'modelInterface/contextInterface';

const { isExternallyManaged: isComponentExternallyManaged } =
  componentInterface;
const { isExternallyManaged: isReferenceExternallyManaged } =
  referenceInterface;

export const launchDeleteComponentsAndReferencesConfirmationDialog = async ({
  components,
  references,
}: ComponentsAndOrReferences) => {
  const wrappedComponents = wrapInList<ComponentBackboneModel>(components);
  const wrappedReferences = wrapInList<Reference>(references);

  return launchDeleteComponentsAndReferencesConfirmationDialogByIds({
    componentIds: wrappedComponents.map(({ id }) => id),
    referenceIds: wrappedReferences.map(({ id }) => id),
  });
};

type ComponentsAndOrReferencesIds = RequireAtLeastOne<
  {
    componentIds?: ArdoqId | ArdoqId[];
    componentNames?: string[]; // use this to get component names in the modal when the components are not loaded in the backbone collection
    referenceIds?: ArdoqId | ArdoqId[];
  },
  'componentIds' | 'referenceIds'
> & {
  getNextTreeSelectionCandidate?: () => ArdoqId | null;
  shouldReturnComponentBatchDeleteResponse?: boolean;
};

export const launchDeleteComponentsAndReferencesConfirmationDialogByIds =
  async ({
    componentIds,
    componentNames,
    referenceIds,
    getNextTreeSelectionCandidate = () => null,
    shouldReturnComponentBatchDeleteResponse,
  }: ComponentsAndOrReferencesIds) => {
    const wrappedComponentIds = wrapInList<ArdoqId>(componentIds);
    const wrappedReferenceIds = wrapInList<ArdoqId>(referenceIds);

    const externallyManagedComponentsCount = wrappedComponentIds.filter(
      isComponentExternallyManaged
    ).length;

    const externallyManagedReferencesCount = wrappedReferenceIds.filter(
      isReferenceExternallyManaged
    ).length;

    const shouldShowManagedDialog =
      (externallyManagedComponentsCount || externallyManagedReferencesCount) &&
      !externallyManagedNotice$.state.isSuppressed;

    const confirmed = await confirmDelete(
      shouldShowManagedDialog
        ? getManagedDialogConfig({
            numOfComponents: externallyManagedComponentsCount,
            numOfReferences: externallyManagedReferencesCount,
          })
        : await getDialogConfigByIds(
            wrappedComponentIds,
            wrappedReferenceIds,
            componentNames
          )
    );

    if (confirmed) {
      trackDialogDeleteButton(); // the only way to reach this line is if the delete button was clicked
      const references = deleteReferencesByIds(wrappedReferenceIds);
      if (isArdoqError(references)) {
        handleError(references);
        return false;
      }
      const currentContextComponentId =
        contextInterface.getCurrentComponentId();
      const nextTreeSelectionCandidate = getNextTreeSelectionCandidate();
      if (
        nextTreeSelectionCandidate &&
        currentContextComponentId &&
        wrappedComponentIds.includes(currentContextComponentId)
      ) {
        contextInterface.setComponentById(nextTreeSelectionCandidate);
      }
      const components = await deleteComponentsByIds(wrappedComponentIds);
      if (isArdoqError(components)) {
        handleError(components);
        return false;
      }
      if (shouldReturnComponentBatchDeleteResponse && components)
        return components;
    }
    return confirmed;
  };

const handleError = (error: ArdoqError) => {
  alert({
    title: 'Could not delete components',
    text:
      'An error occurred while deleting the components. ' +
      'The error has been reported, but please contact support ' +
      'if the error persists.',
  });
  logError(error, 'confirmDeletion error');
};

type ConfirmDeleteBroadcastProps = {
  tagName: string;
  componentNames: string[];
  referenceNames: string[];
};

export const confirmDeleteTag = async ({
  tagName,
  componentNames,
  referenceNames,
}: ConfirmDeleteBroadcastProps) => {
  return confirmDelete({
    title: 'Are you sure you want to delete the tag?',
    text: (
      <>
        Are you sure you want to delete the <strong>#{tagName}</strong> tag and
        remove it from the following components and references:
        <br />
        <br />
        <ul>
          {componentNames.map(componentName => (
            <li key={componentName}>{componentName}</li>
          ))}
          {referenceNames.map(referenceName => (
            <li key={referenceName}>{referenceName}</li>
          ))}
        </ul>
      </>
    ),
    confirmButtonClickId: 'confirm-delete-tag',
  });
};
