import { HORIZONTAL_ELLIPSIS } from '@ardoq/global-consts';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { ComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';

const textMeasureContext = document.createElement('canvas').getContext('2d')!;
textMeasureContext.font = `1px ${ARDOQ_DEFAULT_FONT_FAMILY}`;

const measurementsFor1pxFontSize: Map<string, TextMetrics> = new Map();
export const clearFontMetricsCache = () => measurementsFor1pxFontSize.clear();

const LABEL_MARGIN = 6;

const setCharacterWidth = (char: string, textMetrics: TextMetrics) => {
  if (!measurementsFor1pxFontSize.has(char)) {
    measurementsFor1pxFontSize.set(char, textMetrics);
  }
};

const getCharacterWidth = (char: string) => {
  const existingWidth = measurementsFor1pxFontSize.get(char)?.width;

  if (!existingWidth) {
    const newCharMetrics = textMeasureContext.measureText(char);
    setCharacterWidth(char, newCharMetrics);
    return newCharMetrics.width;
  }
  return existingWidth;
};

const getStringWidth = (text: string, fontSize: number) => {
  let width = 0;
  for (const char of text) {
    width += getCharacterWidth(char);
  }
  return width * fontSize;
};

const getMeasureFn = (fontSize: number) => (text: string) =>
  getStringWidth(text, fontSize);

const ELLIPSIS_WIDTH = getCharacterWidth(HORIZONTAL_ELLIPSIS);

/**
 * @param yOffset the vertical offset, relative to the center of the circle, used to determine the available width of the label, by measuring a horizontal chord across the circle.
 */
const truncateLabel = (
  labelInfo: ComponentLabelParts,
  r: number,
  scale: number,
  fontSize: number,
  reduceWidth: number,
  yOffset: number
) => {
  /* in principle you could split the label across as many lines as the margin makes room for */
  /* in practice, combining elision with splitting turns a basically linear-time problem in to an NP one for little gain */

  const scaledRadius = r / scale;
  const scaledDistance = yOffset / scale;
  const scaledChordWidth =
    2 * Math.sqrt(scaledRadius ** 2 - scaledDistance ** 2);
  const scaledEllipsisWidth = (ELLIPSIS_WIDTH * fontSize) / scale;

  // when we zoom out, we want the label to be further away from the node. When zooming in, we can reduce the margin.
  const scaledLabelMargin = LABEL_MARGIN * scale;

  const availableWidth =
    (scaledChordWidth - scaledLabelMargin * 2 - scaledEllipsisWidth) /
      devicePixelRatio -
    reduceWidth;

  return truncateComponentLabel({
    ...labelInfo,
    width: availableWidth,
    measure: getMeasureFn(fontSize),
  });
};

export default truncateLabel;
