import { intersection } from 'lodash';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { PersonalSetting } from '@ardoq/api-types';
import { EphemeralConfig, EphemeralNotificationKeys } from './types';

export const getEphemeralNotificationsSetting = (
  currentUserState: CurrentUserState
) =>
  currentUserState.settings[
    PersonalSetting.EPHERMERAL_NOTIFICATION_SETTINGS_KEY
  ];

export const addDismissedAndRemoveIrrelevant = (
  bannerId: EphemeralNotificationKeys,
  currentlyRelevantKeys: EphemeralNotificationKeys[],
  currentSetting?: string[]
): EphemeralNotificationKeys[] => {
  if (!currentSetting) {
    return currentlyRelevantKeys.includes(bannerId) ? [bannerId] : [];
  }

  const relevantCurrentBannerIds = intersection(
    currentSetting,
    currentlyRelevantKeys
    // funny that the return type of an intersection with EphemeralNotificationKeys is incomaptible with EphemeralNotificationKeys
  ) as EphemeralNotificationKeys[];

  return [...relevantCurrentBannerIds, bannerId];
};

type IsEphemeralNotificationVisibleArgs = {
  notificationConfig: EphemeralConfig;
  currentUserState: CurrentUserState;
};
export const isEphemeralNotificationVisible = ({
  notificationConfig,
  currentUserState,
}: IsEphemeralNotificationVisibleArgs) => {
  const { relevantFromDate, relevantUntilDate, isRelevantForCurrentUser } =
    notificationConfig;
  const currentDate = new Date();

  const dismissedBanners =
    getEphemeralNotificationsSetting(currentUserState) ?? [];

  return (
    currentDate >= relevantFromDate &&
    currentDate <= relevantUntilDate &&
    isRelevantForCurrentUser(currentUserState) &&
    !dismissedBanners.includes(notificationConfig.id)
  );
};
