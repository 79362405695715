import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { URL_BOX_ICON_CLASS_NAME } from 'yfilesExtensions/styles/consts';

export const FillingDiv = styled.div`
  flex: 1;
  height: 100%;
`;
export const FILTER_DIMENSION_WORKAROUND_CSS_CLASS =
  'filter-dimension-workaround';
export const GraphContainer = styled(FillingDiv)<{ $isModern?: boolean }>`
  .table-background {
    fill: transparent;
    fill-opacity: 0;
    stroke: #8dc5ee;
    stroke-width: 4px;
  }
  .table-line {
    fill: rgb(200, 200, 200);
    stroke: none;
  }
  .stripe-inset {
    fill: #dceffc;
    stroke: #8dc5ee;
    stroke-width: 2px;
  }

  svg {
    g.component {
      stroke: #333;
      stroke-width: 0.5px;
      stroke-opacity: 1;
      &:not(.${URL_BOX_ICON_CLASS_NAME}) {
        pointer-events: bounding-box;
      }
    }

    g > path.workspace {
      fill: ${colors.grey90};
      fill-opacity: 1;
    }
    .fade-out,
    .fade-out > path {
      opacity: 0.25;
    }
  }
  path {
    &.integration:not(.hoverPath) {
      stroke-width: ${({ $isModern }) => ($isModern ? '1px' : '2px')};
      pointer-events: none;
    }
  }

  .image-diff-background {
    fill: hsl(0, 0%, 90%);
    opacity: 1;
  }

  path.visual-diff-placeholder:not(.integration):not(.parent-child-reference),
  rect.visual-diff-placeholder,
  image.visual-diff-placeholder,
  use.visual-diff-placeholder,
  circle.visual-diff-placeholder:not(.image-diff-background) {
    opacity: 0;
  }

  .visual-diff-placeholder-label text,
  .visual-diff-placeholder-label text.integration {
    fill: ${colors.grey60};
    stroke: ${colors.grey60};
  }

  .label-visual-diff-mode text {
    fill: #3e4753 !important;
    stroke: #3e4753 !important;
  }

  .visual-diff-placeholder path.integration {
    fill: none;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2 4 !important;
  }

  .${FILTER_DIMENSION_WORKAROUND_CSS_CLASS} {
    opacity: 0;
    fill: none;
    pointer-events: none;
  }

  .visual-diff-placeholder.image-diff-background {
    fill: none;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2 4;
    opacity: 1;
  }

  .yfiles-canvascomponent-svg {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
`;
