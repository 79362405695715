import { ArdoqId, ReportFilterQuery } from '@ardoq/api-types';

export enum ReportModule {
  READER = 'reader',
  BUILDER = 'builder',
  OVERVIEW = 'overview',
}

export type ExportReportParams = {
  id: ArdoqId;
  filterQuery: ReportFilterQuery;
  reportName: string;
};
