import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { focusedComponentChanged } from 'tabview/actions';
import { selectComponent } from 'streams/components/ComponentActions';

const VIEW_ID = ViewIds.DEPMATRIX;

const setFocusedItemState = (itemId: ArdoqId | null) => {
  dispatchAction(focusedComponentChanged(itemId), VIEW_ID);
};

const setSelectedItemState = (itemId: ArdoqId) => {
  dispatchAction(selectComponent({ cid: itemId }));
};

export const dependencyMatrixCommands = {
  setFocusedItemState,
  setSelectedItemState,
};
