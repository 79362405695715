import { ViewIds } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import {
  type CollapsibleGraphGroup,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_IMAGE_CLIP_PATH,
  NO_LINK_TARGET_DECORATION,
} from '@ardoq/graph';
import { INode } from '@ardoq/yfiles';
import { getElementOpacityIfTransparentized } from '../../utils';
import { createSvgElement } from '@ardoq/dom-utils';
import { ArdoqIconCategory, fontAwesomeIcons, IconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { relationshipDiagramToggleCollapseGroup } from 'tabview/relationshipDiagrams/actions';
import materialIconsText from 'tabview/materialIconsText';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_IMAGE_CIRCLE_STROKE_WIDTH,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
} from './consts';
import { componentInterface } from '@ardoq/component-interface';
import { readableColor } from 'polished';
import { MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT } from 'yfilesExtensions/styles/modernized/consts';
import { RepresentationData } from '@ardoq/data-model';
import leafNodeShapeY from 'yfilesExtensions/styles/modernized/leafNodeShapeY';

const EXPANDER_X =
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING +
  MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE / 2;
const EXPANDER_Y =
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING +
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT / 2;

export const readableButtonColor = (backgroundColor: string) =>
  readableColor(backgroundColor, colors.grey50, colors.white);

export const createElementAsGroupFolder = (
  node: INode,
  group: CollapsibleGraphGroup,
  viewId: ViewIds,
  representationData: RepresentationData
) => {
  const isComponent = group.isComponent();
  const { id: groupId, modelId, isTransparentized, collapsed } = group;
  const {
    layout: { width, height },
  } = node;
  const container = createSvgElement('g', {
    opacity: `${getElementOpacityIfTransparentized(isTransparentized)}`,
  });
  const isCollapsedComponentGroup = collapsed && isComponent;
  const cornerRadius = isCollapsedComponentGroup ? 8 : 10;
  const backgroundRect = createSvgElement('rect', {
    rx: `${cornerRadius}`,
    width: `${width}`,
    height: `${height}`,
    fill: colors.grey95,
    stroke: colors.grey60,
    class: NO_LINK_TARGET_DECORATION,
  });

  const { fill: componentColor } =
    componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
      useAsBackgroundStyle: false,
    });
  const stripeColor =
    componentColor && !isCollapsedComponentGroup ? componentColor : null;
  const stripe = stripeColor
    ? createSvgElement('path', {
        d: `M 0 ${cornerRadius} A ${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} 0 H ${width - cornerRadius} A ${cornerRadius} ${cornerRadius} 0 0 1 ${width} ${cornerRadius} V ${MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT} H 0 Z`,
        fill: stripeColor,
        stroke: 'none',
      })
    : null;
  if (stripe) {
    container.appendChild(stripe);
  }
  const separator = isCollapsedComponentGroup
    ? createSvgElement('path', {
        d: `M 0 ${MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT} H ${width}`,
        fill: 'none',
        stroke: colors.grey80,
      })
    : null;

  container.appendChild(backgroundRect);
  if (stripe) {
    container.appendChild(stripe);
  }
  if (separator) {
    container.appendChild(separator);
  }

  const expanderIcon = materialIconsText(
    collapsed ? IconName.UNFOLD_MORE : IconName.UNFOLD_LESS,
    {
      x: `${EXPANDER_X}`,
      y: `${EXPANDER_Y}`,
      'font-size': `${MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE}px`,
      fill: readableButtonColor(stripeColor || colors.grey95),
      stroke: 'none',
    }
  );
  container.appendChild(expanderIcon);
  const expanderClickRegion = createSvgElement('circle', {
    fill: colors.transparent0,
    stroke: 'none',
    cx: `${EXPANDER_X}`,
    cy: `${EXPANDER_Y}`,
    r: `${MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE}`,
    class: NO_LINK_TARGET_DECORATION,
  });
  container.appendChild(expanderClickRegion);
  expanderClickRegion.addEventListener('click', () =>
    dispatchAction(
      relationshipDiagramToggleCollapseGroup({ viewId, groupId }),
      viewId
    )
  );

  if (isCollapsedComponentGroup) {
    const fill = componentColor || colors.transparent0;

    const {
      isImage,
      value: representationValue,
      shapeName,
      icon,
    } = representationData;
    if (isImage && representationValue) {
      const cx = width / 2;
      const leafShapeY = leafNodeShapeY(
        node,
        MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
        true
      );
      const cy =
        leafShapeY + MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2;
      const imageOutline = createSvgElement('circle', {
        cx: `${cx}`,
        cy: `${cy}`,
        r: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}`,
        'stroke-width': `${MODERNIZED_BLOCK_DIAGRAM_IMAGE_CIRCLE_STROKE_WIDTH}`,
        stroke: componentColor || colors.transparent0,
        fill: 'none',
      });
      container.appendChild(imageOutline);
      const image = createSvgElement('image', {
        href: representationValue,
        x: `${cx - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}`,
        y: `${cy - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}`,
        width: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER}`,
        height: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER}`,
        'clip-path': `url(#${MODERNIZED_BLOCK_DIAGRAM_LEAF_IMAGE_CLIP_PATH})`,
      });
      container.appendChild(image);
    } else if (shapeName) {
      const leafShapeY = leafNodeShapeY(
        node,
        MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT,
        true
      );
      const shape = createSvgElement('use', {
        href: `#${shapeName}`,
        x: '0',
        y: `${leafShapeY}`,
        width: `${width}`,
        height: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT}`,
        fill,
      });
      container.appendChild(shape);
    } else if (icon && representationValue) {
      const { isSVG, category } = icon;
      const leafShapeY = leafNodeShapeY(
        node,
        MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
        true
      );
      const cx = width / 2;
      const cy =
        leafShapeY + MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2;
      const circle = createSvgElement('circle', {
        cx: `${cx}`,
        cy: `${cy}`,
        r: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}`,
        fill,
        stroke: 'none',
      });
      container.appendChild(circle);
      const iconColor = readableColor(fill, colors.grey15, colors.white);
      if (isSVG) {
        const iconElement = createSvgElement('use', {
          x: `${cx - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE / 2}`,
          y: `${cy - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE / 2}`,
          width: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE}`,
          height: `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE}`,
          href: `#${representationValue}`,
          color: iconColor,
          fill: iconColor,
        });
        container.appendChild(iconElement);
      } else if (category === ArdoqIconCategory.FontAwesome) {
        const text = createSvgElement('text', {
          'font-size': `${MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE}px`,
          'font-family': 'FontAwesome',
          'dominant-baseline': 'central',
          'text-anchor': 'middle',
          x: `${cx}`,
          y: `${cy}`,
          color: iconColor,
          fill: iconColor,
        });
        text.textContent = fontAwesomeIcons[icon.id];
        container.appendChild(text);
      }
    }
  }

  return container;
};
