import { ArdoqId } from '@ardoq/api-types';
import { Node } from './types';
import type { Edge } from './types';
import GraphCollection from './GraphCollection';
import { ComponentBackboneModel } from 'aqTypes';
import { GraphItem } from '@ardoq/graph';
import { GraphItem as OldGraphItem } from 'graph/GraphItem';

const areEdgesIncluded = (node: Node, edgeMap: GraphCollection<Edge>) => {
  return (
    node.incomingEdges.some(edge => !!edgeMap.get(edge.id)) ||
    node.outgoingEdges.some(edge => !!edgeMap.get(edge.id))
  );
};
const areChildrenIncluded = (
  node: Node,
  leafNodes: Set<Node>,
  groupNodes: Set<Node>
) => node.children.some(child => groupNodes.has(child) || leafNodes.has(child));

const isLeafNode =
  (includeChildren: boolean, contextComponent: ComponentBackboneModel | null) =>
  (node: Node) =>
    node.children.size() === 0 &&
    (includeChildren ||
      node.dataModel === contextComponent ||
      node.incomingEdges.length > 0 ||
      node.outgoingEdges.length > 0);

export const getChildrenAndGroups = (
  contextComponent: ComponentBackboneModel | null,
  nodes: Node[],
  edgeMap: GraphCollection<Edge>,
  includeChildren: boolean
): { children: Node[]; groups: Node[] } => {
  const leafNodes = new Set<Node>(
    nodes.filter(isLeafNode(includeChildren, contextComponent))
  );
  // if the node has 1 or more children included, put it in the group set.
  const groupNodes = new Set<Node>(
    nodes.filter(node => node.children.some(child => leafNodes.has(child)))
  );

  const includedGroups: Node[] = [];
  nodes.forEach(node => {
    if (areChildrenIncluded(node, leafNodes, groupNodes)) {
      includedGroups.push(node);
    } else if (
      (!leafNodes.has(node) && areEdgesIncluded(node, edgeMap)) ||
      groupNodes.has(node)
    ) {
      groupNodes.delete(node);
      leafNodes.add(node);
    }
    return false;
  });

  return { children: [...leafNodes], groups: includedGroups };
};

export const getChildrenAndRows = (
  nodes: Node[],
  edgeCollection: GraphCollection<Edge>,
  contextComponentId?: ArdoqId
): { children: Node[]; rows: Node[] } => {
  const children = nodes.filter(
    node =>
      node.dataModel.get('_id') === contextComponentId ||
      areEdgesIncluded(node, edgeCollection)
  );

  const rows = nodes.filter(node =>
    areChildrenIncluded(node, new Set<Node>(children), new Set<Node>())
  );

  return { children, rows };
};

export const graphItemsEqual = (
  a: GraphItem | OldGraphItem,
  b: GraphItem | OldGraphItem
) =>
  a instanceof GraphItem && b instanceof GraphItem ? a.isEqual(b) : undefined; // returning undefined here tells _.isEqualWith to use the default comparator.
