import { ElementType, ReactNode } from 'react';
import { ArdoqId } from '@ardoq/api-types';

export enum scrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface InfiniteScrollProps<
  TContainer extends ElementType = 'div',
  TRow extends ElementType = 'div',
> {
  items: string[];
  bypassRenderLimit: boolean;
  itemRenderFunction: (item: string, index: number) => ReactNode | null;
  getScrollTarget?: (el: HTMLElement) => HTMLElement;
  workspaceId?: ArdoqId;
  scrollToItem?: string;
  contextComponentId?: ArdoqId;
  numberOfComponentsToRender: number;
  shouldDispatchTopVisibleItem?: boolean;
  Container?: TContainer;
  BlankRow?: TRow;
  getRowElement: (el: Element) => HTMLElement | null;
  getStickyHeaderHeight?: (container: HTMLElement | null) => number;
  children?: ReactNode;
}

export type InfiniteScrollState = {
  scrollIndex: number;
  scrolledHeightTop: number;
  scrolledHeightBottom: number;
  isAppendingToTop: boolean;
  isAppendingToBottom: boolean;
};
