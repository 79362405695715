import {
  streamReducer,
  action$,
  reduceState,
  dispatchAction,
  ofType,
} from '@ardoq/rxbeach';
import { Observable, combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import {
  ViewSettings,
  getViewSettingsStreamWithChanges,
} from 'viewSettings/viewSettingsStreams';
import {
  TimelineViewSettings,
  TimelineViewModelState,
  TIMELINE_VIEW_SOURCE_DATA_RELEVANT_VIEW_SETTINGS,
  TIMELINE_RENDERING_RELEVANT_VIEW_SETTINGS,
  DEFAULT_TIMELINE_EVENT_CALLBACKS,
  timelineViewModelOperations,
  getViewRelevantScopeData,
  getHierarchySortComparator,
  timelineViewSettingsOperations,
  TimelineSettingsControlsDependencies,
  getGraphNodeOrGroupLabel,
  ViewModelSourceData,
  DEFAULT_TIMELINE_VIEW_MODEL_SOURCE_DATA,
  getRenderingData$,
} from '@ardoq/timeline2024';
import { APIEntityType, ArdoqId, ViewIds } from '@ardoq/api-types';
import { map, filter, debounceTime, startWith } from 'rxjs/operators';
import graphModel$ from 'modelInterface/graphModel$';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import {
  getFilteredViewSettings$,
  getViewSettingsFilter,
} from 'views/filteredViewSettings$';
import { componentInterface } from '@ardoq/component-interface';
import { isPresentationMode } from 'appConfig';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { simpleNotifier } from '@ardoq/dropdown-menu';
import { ExtractStreamShape } from 'tabview/types';
import { isEmpty } from 'lodash';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { getTimelineViewGraphHierarchyForViewpointMode } from './utils';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { fieldInterface } from '@ardoq/field-interface';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import {
  buildGraphFromTraversalAndSearch,
  ComponentLabelParts,
  getAppliedGroups,
  getCanBeUsedAsGroup,
  getFirstFormattedMultiLabel,
  getGraphInterfaceWithModelInterfaces,
  getLabelsInterfaceWithModelInterfaces,
  getOtherLabelsDataForComponent,
  isGraphModelValid,
  LabelsInterface,
} from '@ardoq/graph';
import componentAttributesData$ from 'streams/componentAttributesData$';
import { locale$ } from 'streams/locale$';
import { dateRangeOperations } from '@ardoq/date-range';
import {
  ComponentByIdSortDependencies,
  returnTrue,
} from '@ardoq/common-helpers';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { startAction } from 'actions/utils';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import { getViewGraphHierarchyRootItems } from '@ardoq/timeline2024';
import { updateViewProperties, viewSettingsConsts } from '@ardoq/view-settings';
import { FieldsAndItemsSourceData } from '@ardoq/timeline2024';

const getLegacyLabelPartsFromMultiLabelFormatting = (
  labelsInterface: LabelsInterface,
  componentId: ArdoqId,
  getComponentDisplayName: (id: ArdoqId) => string | null
): ComponentLabelParts => {
  const componentLabel = getComponentDisplayName(componentId);

  const defaultLabelInfo = {
    label: componentLabel,
    fieldLabel: null,
    fieldValue: null,
  };

  const labelsData = getOtherLabelsDataForComponent(
    labelsInterface,
    componentId
  );

  if (!labelsData) {
    return defaultLabelInfo;
  }

  const firstRelevantLabelFormatting = getFirstFormattedMultiLabel(
    labelsInterface,
    componentId,
    labelsData,
    APIEntityType.COMPONENT
  );

  if (!firstRelevantLabelFormatting) {
    return defaultLabelInfo;
  }

  const [fieldLabel, fieldValue, showFieldLabel] = firstRelevantLabelFormatting;

  return {
    label: componentLabel,
    fieldLabel: showFieldLabel ? fieldLabel : null,
    fieldValue: fieldValue,
  };
};

type BuildViewModelSourceDataArgs = ExtractStreamShape<typeof viewSourceData$>;

const updateViewSettingsDataForViewpointMode = (
  settingsData: TimelineSettingsControlsDependencies
) => {
  dispatchAction(
    updateViewProperties({
      viewId: ViewIds.TIMELINE,
      viewProperties: settingsData,
    })
  );
};

const buildViewModelSourceDataReducer = (
  state: ViewModelSourceData,
  [
    { componentId: contextComponentId, workspacesIds, sort },
    viewSettings,
    graph,
    loadedGraph,
    { componentAttributesById },
    userLocale,
  ]: BuildViewModelSourceDataArgs
): ViewModelSourceData => {
  const timelinePackageInterface = state.timelinePackageInterface;

  const {
    getAllGroupingRules,
    componentInterface: { isComponent, getDisplayName, getAllComponentIds },
  } = timelinePackageInterface;

  const userDefinedGrouping = getAllGroupingRules();

  const componentDoubleClickHandler = (componentId: ArdoqId) => {
    if (!isComponent(componentId)) {
      return;
    }
    dispatchAction(openDetailsDrawer([componentId]));
  };

  const hasGroupingStyles = true;
  const updatedState: ViewModelSourceData = {
    ...state,
    timelinePackageInterface: {
      ...timelinePackageInterface,
      componentDoubleClickHandler,
    },
    contextComponentId,
    graph,
    hasGroupingStyles,
    sourceDataViewSettings: viewSettings,
  };

  /**
   * Pass GraphContext as argument to use scopeData utils.
   * The graphInterface and labelsInterface are used to ensure that the view can be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();
  const labelsInterface = getLabelsInterfaceWithModelInterfaces();

  if (
    !isGraphModelValid(graphInterface, graph) ||
    isEmpty(componentAttributesById)
  ) {
    // if graph is invalid, typically this means the graphModel$ has not updated itself with the latest state
    // of the References collection, we therefore avoid rebuilding the graph based on other updates
    return updatedState;
  }

  const sortDependencies: ComponentByIdSortDependencies = {
    componentAttributesById,
    sort,
    isDateRangeField: dateRangeOperations.isCombinedDateRangeFieldNames(
      sort.attr
    ),
    userLocale,
  };

  const hierarchySortComparator = getHierarchySortComparator(sortDependencies);

  const hasUserDefinedGroups = Boolean(userDefinedGrouping.length);

  // graph model traversal with applied grouping rules hierarchy
  const traversalAndGroupingResult = buildGraphFromTraversalAndSearch({
    graphInterface,
    viewId: ViewIds.TIMELINE,
    graph,
    groupingRules: getAppliedGroups(userDefinedGrouping),
    loadedGraph,
    canBeUsedAsGroup: getCanBeUsedAsGroup(
      graphInterface,
      loadedGraph,
      hasUserDefinedGroups
    ),
    allComponentIdsRelevantForFilterCache: getAllComponentIds(), // This is a hack to satisfy the broken FilterCache.
  });

  // abstraction of the view graph hierarchy to graph nodes with temporary ids
  const { viewGraphHierarchy, allComponentIds } =
    getTimelineViewGraphHierarchyForViewpointMode({
      graphInterface,
      loadedGraph,
      graph,
      workspacesIds,
      hasUserDefinedGroups,
      contextComponentId,
      hierarchySortComparator,
      traversalAndGroupingResult,
    });

  const { isModernizedStyle } = timelinePackageInterface.featureInterface;

  const getLabelParts = (modelId: string) =>
    getLegacyLabelPartsFromMultiLabelFormatting(
      labelsInterface,
      modelId,
      getDisplayName
    );

  viewGraphHierarchy.forEach(({ rawGraphItem, graphItem }) => {
    if (rawGraphItem) {
      graphItem.setItemLabels(
        getGraphNodeOrGroupLabel({
          labelsInterface,
          rawGraphItem,
          isComponent,
          getLabelParts,
          isModernizedStyle,
        })
      );
    }
  });

  const { viewRelevantWorkspacesScopeData, viewRelevantComponentsScopeData } =
    getViewRelevantScopeData({
      allComponentIds,
      timelinePackageInterface,
    });

  const fieldsMetaData = timelineViewModelOperations.getFieldsMetaData({
    viewRelevantWorkspacesScopeData,
    viewSettings,
  });

  const { errors, hasClones } = traversalAndGroupingResult;

  const fieldsAndItemsSourceData: FieldsAndItemsSourceData = {
    viewGraphHierarchy,
    startSetNodes: getViewGraphHierarchyRootItems(viewGraphHierarchy),
    ...fieldsMetaData,
  };

  return {
    ...updatedState,
    errors,
    hasClones,
    viewRelevantComponentsScopeData,
    fieldsAndItemsSourceData,
    contextComponentId,
  };
};

// #region view settings stream filters

type IgnoreSettingsPredicate = (
  { changedSettings }: ViewSettings<TimelineViewSettings>,
  index: number
) => boolean;

const ignoreViewSettingsChangeForModelSourceData =
  getViewSettingsFilter<TimelineViewSettings>(
    ...TIMELINE_RENDERING_RELEVANT_VIEW_SETTINGS
  );

const ignoreViewSettingsChangeForRenderingData =
  getViewSettingsFilter<TimelineViewSettings>(
    ...TIMELINE_VIEW_SOURCE_DATA_RELEVANT_VIEW_SETTINGS
  );

const getFilteredTimelineViewSettings = (
  viewSettings$: Observable<ViewSettings<TimelineViewSettings>>,
  predicate: IgnoreSettingsPredicate
) =>
  getFilteredViewSettings$(viewSettings$).pipe(
    filter((viewSettings, index) => !predicate(viewSettings, index)),
    map(({ currentSettings }) => currentSettings)
  );

// #endregion

// #region stream composition

const rawTimelineViewSettings$ =
  getViewSettingsStreamWithChanges<TimelineViewSettings>(ViewIds.TIMELINE);

const viewSettingsForModelSourceData$ = getFilteredTimelineViewSettings(
  rawTimelineViewSettings$,
  ignoreViewSettingsChangeForModelSourceData
);
const viewSettingsForRenderingData$ = getFilteredTimelineViewSettings(
  rawTimelineViewSettings$,
  ignoreViewSettingsChangeForRenderingData
);

const isLegendStateChange = ({
  changedSettings,
}: ViewSettings<TimelineViewSettings>) =>
  changedSettings.some(
    changedSetting => changedSetting[0] === viewSettingsConsts.IS_LEGEND_ACTIVE
  );

const timelineViewLegend$ = rawTimelineViewSettings$.pipe(
  filter(isLegendStateChange),
  map(({ currentSettings }) => currentSettings)
);

const filtersChange$ = action$.pipe(
  ofType(notifyFiltersChanged),
  startWith(startAction())
);

const viewSourceData$ = combineLatest([
  context$,
  viewSettingsForModelSourceData$,
  graphModel$,
  loadedGraph$,
  componentAttributesData$,
  locale$,
  filtersChange$,
]).pipe(debounceTime(DEFAULT_DEBOUNCE_TIME * 2)); // should be larger than the debounce time of the componentAttributesData$

export const getViewpointModeViewModel$ = (
  viewInstanceId: string
): Observable<TimelineViewModelState> => {
  const openDateRangeViewSetting = simpleNotifier();
  const openMilestonesViewSetting = simpleNotifier();

  const initialViewModelSourceDataState: ViewModelSourceData = {
    ...DEFAULT_TIMELINE_VIEW_MODEL_SOURCE_DATA,
    timelineViewEventCallbacks: {
      ...DEFAULT_TIMELINE_EVENT_CALLBACKS,
      openDateRangeViewSetting,
      openMilestonesViewSetting,
      onViewSettingsUpdate,
      isPresentationMode,
    },
    timelinePackageInterface: {
      componentInterface: {
        getWorkspaceOfComponent: componentInterface.getWorkspaceId,
        getRepresentationData: componentInterface.getRepresentationData,
        getComponentWorkspaceModelId: componentInterface.getModelId,
        getTypeId: componentInterface.getTypeId,
        isIncludedInContextByFilter: returnTrue,
        getComponentChildren: componentInterface.getChildren,
        getComponentDisplayColor:
          componentInterface.getComponentDisplayColorAsSVGAttributes,
        getComponentFieldValue: componentInterface.getFieldValue,
        getDisplayName: componentInterface.getDisplayName,
        isComponent: componentInterface.isComponent,
        getAllComponentIds: componentInterface.getAllComponentIds,
      },
      workspaceInterface: {
        getWorkspaceModelId: workspaceInterface.getWorkspaceModelId,
        getComponentTypesForWorkspace: workspaceInterface.getComponentTypes,
        getWorkspaceNameById: workspaceInterface.getWorkspaceName,
      },
      fieldInterface: {
        getFieldsOfWorkspace: fieldInterface.getFieldsOfWorkspace,
      },
      featureInterface: {
        isModernizedStyle: true,
      },
      getAllGroupingRules: groupingRuleInterface.getAll,
      isPresentationMode,
      onViewSettingsUpdate,
    },
    viewInstanceId,
  };

  // This will be the main stream that will flow into the package
  const viewModelSourceData$ = action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState<ViewModelSourceData>(
      'timelineViewModelSourceData',
      initialViewModelSourceDataState,
      [
        streamReducer<ViewModelSourceData, BuildViewModelSourceDataArgs>(
          viewSourceData$,
          buildViewModelSourceDataReducer
        ),
      ]
    )
  );

  /**
   * The below will live inside the package
   */

  return getRenderingData$({
    viewInstanceId,
    viewModelSourceData$,
    viewSettingsForRenderingData$,
    timelineViewLegend$,
    viewSettingsControls: {
      getSettingsControls:
        timelineViewSettingsOperations.getSettingsControlsForViewpointMode,
      updateSettingsControls: updateViewSettingsDataForViewpointMode,
    },
  });
};
