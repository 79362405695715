import type { ModelType } from 'models/ModelType';
import { DiffType } from '@ardoq/data-model';
import type { OldGraphItemDataModel } from './legacyTypes';

export default class ComponentTypeValue implements OldGraphItemDataModel {
  componentType: ModelType;
  constructor(args: { componentType: ModelType }) {
    this.componentType = args.componentType;
  }

  isIncludedInContextByFilter(): boolean {
    return true;
  }

  name(): string {
    return this.componentType.name;
  }

  getCSS(): string {
    return '';
  }

  isReference(): boolean {
    return false;
  }

  get() {
    return undefined;
  }

  getVisualDiffType() {
    return DiffType.NONE;
  }
}
