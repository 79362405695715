import { attachmentApi } from '@ardoq/api';
import { APIAttachment, ArdoqId, isAttachment, Named } from '@ardoq/api-types';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import { dispatchAction } from '@ardoq/rxbeach';
import { logIfUsed } from '@ardoq/sentry';
import { RowType } from '@ardoq/table';
import { groupBy, intersection, intersectionBy } from 'lodash';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { useEffect } from 'react';
import {
  deleteAttachments,
  deleteEmptyFolder,
  deleteFolders,
  setRenameFocus,
  transferToGlobal,
} from './actions';
import {
  openDeleteDocumentModal,
  openDeleteMultipleDocumentsModal,
} from './DeleteDocumentModals';
import {
  DocumentArchiveContext,
  DocumentBrowserEntity,
  DocumentBrowserRow,
  DocumentFolder,
  EmptyFolder,
} from './types';

export const collectDescendantsFromRow = (
  row: DocumentBrowserEntity
): DocumentBrowserEntity[] => {
  const children = ((row as any).children || []).filter(
    ({ rowType }: DocumentBrowserEntity) => rowType !== RowType.EMPTY_FOLDER
  );
  return [...children, ...children.map(collectDescendantsFromRow)].flat();
};

const collectGroupedDescendantsIds = (row: DocumentBrowserRow) => {
  if (row.rowType !== RowType.FOLDER) {
    return {
      folderIds: [],
      attachmentIds: [(row as DocumentBrowserEntity)._id].filter(ExcludeFalsy),
    };
  }
  const { folders = [], attachments = [] } = groupBy(
    collectDescendantsFromRow(row),
    ({ rowType }) => (rowType === RowType.FOLDER ? 'folders' : 'attachments')
  );
  return {
    folderIds: [row._id, ...folders.map(({ _id }) => _id)],
    attachmentIds: attachments.map(({ _id }) => _id),
  };
};

const isFolder = (row: DocumentBrowserRow): row is DocumentFolder =>
  row.rowType === RowType.FOLDER;

const isEmptyFolder = (row: DocumentBrowserRow): row is EmptyFolder =>
  row.rowType === RowType.EMPTY_FOLDER;

const deleteRow = (row: DocumentBrowserRow) => {
  if (isFolder(row)) {
    dispatchAction(deleteFolders([row]));
  } else if (isEmptyFolder(row)) {
    // I do not belive this is used
    logIfUsed('ARD-23340: Delete empty folder');
    // I am banging folderId here as if this is not set the entity does not have an id.
    dispatchAction(deleteEmptyFolder(row.meta.folderId!));
  } else {
    dispatchAction(deleteAttachments([row._id]));
  }
};

const namingConflicts = (attachmentIds: ArdoqId[]): string[] => {
  const globalAttachments = documentArchiveInterface.getGlobalAttachments();
  const attachments = attachmentIds.map(
    documentArchiveInterface.getAttachmentById
  );
  // ---- pure logic under this line ----
  const globalAttachmentNames = globalAttachments.map(
    ({ filename }) => filename
  );
  const attachmentNames = attachments
    .map(attachment => attachment?.filename)
    .filter(ExcludeFalsy);
  return intersectionBy(attachmentNames, globalAttachmentNames);
};

export const getMenuOptions = (
  row: DocumentBrowserRow,
  context: DocumentArchiveContext,
  selectedAttachments: ArdoqId[],
  workspaceId: ArdoqId | null,
  isScenarioMode?: boolean
): DropdownOption[] => {
  if (row.rowType === RowType.EMPTY_FOLDER) return [];
  const isMultipleSelection =
    selectedAttachments.length > 1 && selectedAttachments.includes(row._id);
  if (isMultipleSelection) {
    const conflictingNames = namingConflicts(selectedAttachments);
    return [
      context === DocumentArchiveContext.WS &&
        workspaceId && {
          label: `Transfer to global (${selectedAttachments.length})`,
          isDisabled: isScenarioMode || conflictingNames.length > 0,
          description:
            conflictingNames.length > 0
              ? `${conflictingNames.join(', ')} already exists in global`
              : undefined,
          onClick: () => {
            dispatchAction(
              transferToGlobal({
                workspaceId,
                attachmentAndFolderIds: {
                  attachmentIds: selectedAttachments,
                  folderIds: [],
                },
              })
            );
          },
          type: DropdownOptionType.OPTION,
        },
      {
        label: `Delete selected (${selectedAttachments.length})`,
        isDisabled: isScenarioMode,
        onClick: async () => {
          const selectedRows: (Named & { rowType: RowType })[] = [];
          selectedAttachments.forEach(id => {
            const attachment = documentArchiveInterface.getAttachmentById(id);
            if (attachment) {
              selectedRows.push({
                rowType: RowType.DOCUMENT,
                name: attachment.filename,
              });
            }
          });
          const confirmed =
            await openDeleteMultipleDocumentsModal(selectedRows);
          if (confirmed) {
            dispatchAction(deleteAttachments(selectedAttachments));
          }
        },
        type: DropdownOptionType.OPTION,
      },
    ].filter(ExcludeFalsy);
  }
  // Because folders can contain attachments and other folders, we need to check for naming conflicts in both cases
  const selectedRowNamingConflict = namingConflicts([row._id]);
  const childRowsNamingConflicts = namingConflicts(
    collectGroupedDescendantsIds(row).attachmentIds
  );
  const conflictingNames = [
    ...selectedRowNamingConflict,
    ...childRowsNamingConflicts,
  ];
  return [
    row.rowType !== RowType.FOLDER && {
      label: 'Download',
      onClick: () => {
        downloadFile((row as APIAttachment).uri);
      },
      type: DropdownOptionType.OPTION,
    },

    context === DocumentArchiveContext.WS &&
      workspaceId && {
        label: 'Transfer to global',
        isDisabled: isScenarioMode || conflictingNames.length > 0,
        description:
          conflictingNames.length > 0
            ? `${conflictingNames[0]} already exists in global`
            : undefined,
        onClick: () => {
          dispatchAction(
            transferToGlobal({
              workspaceId,
              attachmentAndFolderIds: {
                ...collectGroupedDescendantsIds(row),
              },
            })
          );
        },
        type: DropdownOptionType.OPTION,
      },
    {
      label: 'Delete',
      isDisabled: isScenarioMode,
      onClick: async () => {
        const descendants = collectDescendantsFromRow(row);
        const confirmed = await openDeleteDocumentModal(row, descendants);
        if (confirmed) {
          deleteRow(row);
        }
      },
      type: DropdownOptionType.OPTION,
    },
    {
      label: 'Rename',
      isDisabled: isScenarioMode,
      onClick: () => {
        dispatchAction(setRenameFocus(row._id));
      },
      type: DropdownOptionType.OPTION,
    },
  ].filter(ExcludeFalsy);
};

export const useDragover = ({
  onEnter,
  onLeave,
  onDrop,
}: {
  onEnter: VoidFunction;
  onLeave: VoidFunction;
  onDrop: (e: DragEvent) => void;
}) => {
  useEffect(() => {
    let dragTimer: ReturnType<typeof setTimeout>;
    let withinDropArea = false;
    const handleDragover = (e: DragEvent) => {
      e.preventDefault();
      clearTimeout(dragTimer);
      if (withinDropArea === false) {
        withinDropArea = true;
        onEnter();
      }
    };
    const handleDragleave = (e: DragEvent) => {
      e.preventDefault();
      dragTimer = setTimeout(() => {
        withinDropArea = false;
        onLeave();
      }, 25);
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      onDrop(e);
    };

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragover);
    document.addEventListener('dragleave', handleDragleave);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragover);
      document.removeEventListener('dragleave', handleDragleave);
    };
  }, [onEnter, onLeave, onDrop]);
};

const IMAGE_CONTENT_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
];

const DOCUMENT_CONTENT_TYPES = ['application/pdf'];

export const getAttachmentRowType = (contentType?: string) => {
  if (contentType && IMAGE_CONTENT_TYPES.includes(contentType)) {
    return RowType.IMAGE;
  }
  if (contentType && DOCUMENT_CONTENT_TYPES.includes(contentType)) {
    return RowType.DOCUMENT;
  }
  return RowType.UNKOWN_FILE;
};

export const areFileNamesUniq = (
  fileNames: string[],
  context: DocumentArchiveContext
) => {
  const {
    [DocumentArchiveContext.WS]: wsAttachments = [],
    [DocumentArchiveContext.ORG]: orgAttachments = [],
  } = groupBy(
    documentArchiveInterface.getDocumentArchiveAttachments(),
    ({ context }) => context
  );
  const attachments =
    context === DocumentArchiveContext.ORG ? orgAttachments : wsAttachments;

  return (
    intersection(
      attachments.map(({ filename }) => filename),
      fileNames
    ).length === 0
  );
};

// This might not be used anymore
export const alertDuplicatedNamesConflict = () =>
  alert({
    title: 'File name conflict',
    subtitle:
      'Document Archive cannot contain multiple files with the same name',
  });

export const alertFileSizeIsTooBig = () =>
  alert({
    title: 'File is too big',
    subtitle: 'The file exceeds the maximum size of 20MB',
  });

export const getFileNamesFromRows = (rows: DocumentBrowserEntity[]) =>
  (rows.filter(row => isAttachment(row)) as APIAttachment[]).map(
    ({ filename }) => filename
  );

const isValidArdoqUrl = (url: string) =>
  Boolean(url.match(/^\/api\/attachment\//));

const downloadFile = (url: string) => {
  if (!isValidArdoqUrl(url)) {
    logError(new Error('Attempt to download file from out of Ardoq.'));
    return;
  }

  const link = document.createElement('a');
  link.download = '';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const splitFilename = (filename = '') => {
  const [, filenameBase = filename, filenameExtension = ''] =
    filename.match(/(.+)(\.\S+)$/) || [];
  return { filenameBase, filenameExtension };
};

export const isFilenameTaken = async (file: File, workspaceId: ArdoqId) => {
  const attachments = await attachmentApi.getWorkspaceAttachments(workspaceId);
  if (isArdoqError(attachments)) {
    return attachments;
  }
  return (
    attachments.filter(({ filename }) => filename === file.name).length > 0
  );
};
