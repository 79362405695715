import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { OnViewSettingsUpdate } from '@ardoq/data-model';

export const onViewSettingsUpdate: OnViewSettingsUpdate = (
  viewId,
  settings,
  persistent,
  settingsPath
) => {
  dispatchAction(
    updateViewSettings({ viewId, settings, persistent, settingsPath })
  );
};
