import Group from 'graph/group';
import Component from 'models/component';
import Reference from 'models/reference';
import Workspace from 'models/workspace';
import {
  ComponentBackboneModel,
  Reference as ReferenceBackboneModel,
} from 'aqTypes';

export const isGroup = (model: any): model is Group => model instanceof Group;

export const isComponent = (
  model: any
): model is typeof Component.model & ComponentBackboneModel =>
  model instanceof Component.model;

export const isReference = (
  model: any
): model is typeof Reference.model & ReferenceBackboneModel =>
  model instanceof Reference.model;

export const isWorkspace = (model: any): model is typeof Workspace.model =>
  model instanceof Workspace.model;

export const isSomeEnum =
  <T>(e: T) =>
  (token: any): token is T[keyof T] =>
    e !== null &&
    e !== undefined &&
    Object.values(e).includes(token as T[keyof T]);
