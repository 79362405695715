import activeView$ from 'streams/views/mainContent/activeView$';
import { trackEvent } from 'tracking/tracking';
import { DataLimitProps } from './types';

export const trackShowingDataLimitWarning = ({
  canBypass,
  isEmpty,
  reducedDegrees,
  isAboveLimit,
}: DataLimitProps) =>
  trackEvent('Triggered view data limit warning', {
    canBypass,
    isEmpty,
    reducedDegrees,
    isAboveLimit,
    activeView: activeView$.state.mainViewId,
  });
