import { tagInterface } from 'modelInterface/tags/tagInterface';
import styled from 'styled-components';
import {
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import { DescriptionFormat } from 'tabview/pagesView/commonUIElements';
import { colors } from '@ardoq/design-tokens';
import { ArdoqId } from '@ardoq/api-types';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import { unescapeHTML } from '@ardoq/html';
import { TAG_ID_ATTRIBUTE } from 'consts';

const Tag = styled.span`
  background-color: #d0e3ff;
  border-radius: 2px;
  border: 1px solid #a1c7ff;
  color: ${colors.blue50};
  font-size: 0.9em;
  margin: ${verticalSpacing.SMALL} ${horizontalSpacing.SMALL} 0 0;
  padding: 0 ${horizontalSpacing.SMALL} 0 ${horizontalSpacing.SMALL};
`;

const TagsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -${verticalSpacing.SMALL};
`;

const tagPopoverRenderer = (target: Element | null) => {
  if (!(target instanceof HTMLElement)) return null;
  const { tagId } = target.dataset;
  const description = tagInterface.getDescription(tagId!);
  return description?.length ? (
    <DescriptionFormat>{unescapeHTML(description)}</DescriptionFormat>
  ) : null;
};

const TAG_POPOVER_ID = 'pages-view-tag-popover';
const withTagPopover = (tagId: ArdoqId) => ({
  [POPOVER_ID_ATTR]: TAG_POPOVER_ID,
  'data-tag-id': tagId,
});

popoverRegistry.set(TAG_POPOVER_ID, tagPopoverRenderer);

const Tags = ({ modelId }: { modelId: string }) => (
  <TagsLayout>
    {tagInterface.getTagsForModel(modelId).map(tag => (
      <Tag
        key={tag.id}
        {...{ [TAG_ID_ATTRIBUTE]: tag.id }}
        {...withTagPopover(tag.id)}
      >
        {tag.name}
      </Tag>
    ))}
  </TagsLayout>
);

export default Tags;
