import {
  BLOCKS_VIEW_NODECHANNEL_MINIMUM_SIZE,
  BLOCKS_VIEW_ROUTINGCHANNEL_MINIMUM_SIZE,
} from '../consts';
import { createRouter } from './router';
import {
  BlocksViewGraph,
  BlocksViewLink,
  BlocksViewNode,
  LayoutData,
  NodeLayoutData,
} from '../types';
import { isGroup } from '../viewModel$/util';
import type { Rectangle } from '@ardoq/graph';
import { rectHeight, rectWidth } from '../misc/geometry';
import { getNodeMinimumCellSize, setNodeCellRect } from '../visual/node';
import {
  getColPositions,
  getLayoutData,
  getNodeLayoutIndex,
  getRowPositions,
  isLayoutSet,
} from './util';
import { sum } from 'lodash';
import { setLinkRoute } from '../visual/link';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { ViewIds } from '@ardoq/api-types';
import { resetGroupSmart } from './yfilesLayout';
import { fixupGroupGrid, resetGroupGrid } from './gridLayout';

// const lexicalComparator = (a: BlocksViewNode, b: BlocksViewNode) => a.row < b.row ? -1 : a.row > b.row ? 1 : a.col - b.col;

export enum LayoutType {
  Smart,
  Grid,
}

/** force the group's children to be laid out in the specified way even if they're already laid out */
export const resetLayout = (
  node: BlocksViewNode,
  mode: LayoutType,
  recursive: boolean = false
) => {
  if (!node.children?.length) {
    return;
  }

  if (recursive) {
    node.children.forEach(child => resetLayout(child, mode, recursive));
  }

  switch (mode) {
    case LayoutType.Smart:
      resetGroupSmart(node);
      break;

    case LayoutType.Grid:
      resetGroupGrid(node);
      break;
  }

  //       group.children!.sort(lexicalComparator);
};

/** repair the group's unlaid out children */
/* this is used when any of a group's children are restored without any layout information */
/* there are numerous possibilites for what to do here */
/* 1. keep the children which *were* restored where they are and add the others at the bottom */
/* 2. decide the whole group is crap and apply the group's preferred layout */
/* 3. keep the children which *were* restored where they are and try to add the new children to a sensible place based on relationships or something */
/* 4. keep the children which were restored where they are and just try to plug any holes */
/* probably many others */
/* for any particular choice, I can guarantee you that there's at least one use-case for which it fails and that, no, the solution is */
/* not to "let the user choose" */
export const fixupLayout = (rootNode: BlocksViewNode) => {
  const doit = (group: BlocksViewNode) => {
    if (isGroup(group)) {
      let fixupRequired = false;

      for (const child of group.children!) {
        doit(child);

        fixupRequired = fixupRequired || !isLayoutSet(child);
      }

      if (fixupRequired) {
        // fixup type should depend on the group's layout type but groups don't really have a layout type
        // and in any case the only real (specified) fixup is gridded

        fixupGroupGrid(group);
      }
    }
  };

  doit(rootNode);
};

/** work out world node and link positions in terms of the graph's layout and routing */
export const applyLayout = (graph: BlocksViewGraph) => {
  /* this definition of getBusId turns off bussing */
  // const getBusId = (link: BlocksViewLink) => link.modelId;

  const getBusId = (link: BlocksViewLink) => {
    if (!link.globalTypeId) {
      /* bus all parent-child relationships from the same parent */
      return link.sourceProxy!.modelId!;
    }

    return link.modelId!;
  };

  /* get ready for routing */
  const resetGridArrays = (node: BlocksViewNode) => {
    if (isGroup(node) && node.open) {
      let colCnt = 0;
      let rowCnt = 0;

      // should probably be adjusting for negative and empty cols and rows, too

      node.children!.forEach(child => {
        resetGridArrays(child);

        colCnt = Math.max(colCnt, child.col + child.colSpan + 1);
        rowCnt = Math.max(rowCnt, child.row + child.rowSpan + 1);
      });

      node.colSize = new Array<number>(colCnt).fill(
        BLOCKS_VIEW_ROUTINGCHANNEL_MINIMUM_SIZE
      );
      for (let col = 1; col < colCnt; col += 2) {
        node.colSize[col] = BLOCKS_VIEW_NODECHANNEL_MINIMUM_SIZE;
      }

      node.rowSize = new Array<number>(rowCnt).fill(
        BLOCKS_VIEW_ROUTINGCHANNEL_MINIMUM_SIZE
      );
      for (let row = 1; row < rowCnt; row += 2) {
        node.rowSize[row] = BLOCKS_VIEW_NODECHANNEL_MINIMUM_SIZE;
      }
    }
  };

  const setSizes = (node: BlocksViewNode) => {
    if (isGroup(node) && node.open) {
      node.children!.forEach(setSizes);
    }

    if (isGroup(node) && node.open) {
      /* boost node col and row sizes to take into account routing */
      router.adjustSizes(node);

      for (const child of node.children!) {
        /* boost col and row sizes to take into account unspanned children */

        if (child.colSpan !== 1 && child.rowSpan !== 1) {
          continue;
        }

        const sz = getNodeMinimumCellSize(child);

        if (child.colSpan === 1) {
          node.colSize![child.col] = Math.max(node.colSize![child.col], sz[0]);
        }

        if (child.rowSpan === 1) {
          node.rowSize![child.row] = Math.max(node.rowSize![child.row], sz[1]);
        }
      }

      for (const child of node.children!) {
        /* boost col and row sizes to take into account spanned children */

        if (child.colSpan === 1 && child.rowSpan === 1) {
          continue;
        }

        const sz = getNodeMinimumCellSize(child);

        for (let i = 0; i < child.colSpan && sz[0] > 0; ++i) {
          sz[0] -= node.colSize![child.col + i];
        }

        for (let i = 0; i < child.rowSpan && sz[1] > 0; ++i) {
          sz[1] -= node.rowSize![child.row + i];
        }

        if (sz[0] > 0) {
          const cn = (child.colSpan - 1) / 2; // number of columns to accept the residual

          if (cn <= 0) {
            /* there are no routing channels to take the slop; give it all to the node column */
            node.colSize![child.col] += sz[0];
          } else {
            for (let i = 1; i < child.colSpan; i += 2) {
              node.colSize![child.col + i] += sz[0] / cn;
            }
          }
        }

        if (sz[1] > 0) {
          const rn = (child.rowSpan - 1) / 2;

          if (rn <= 0) {
            node.rowSize![child.row] += sz[1];
          } else {
            for (let i = 1; i < child.rowSpan; i += 2) {
              node.rowSize![child.row + i] += sz[1] / rn;
            }
          }
        }
      }
    } else {
      /* leaf or closed group */
      node.colSize = undefined;
      node.rowSize = undefined;
    }
  };

  const setPositions = (node: BlocksViewNode, cellParam: Rectangle | null) => {
    let cell = cellParam;

    if (!cell && node.colSize && node.rowSize) {
      const w = node.colSize.reduce((w, size) => w + size, 0);
      const h = node.rowSize!.reduce((h, size) => h + size, 0);

      cell = [0, 0, w, h];
    }

    if (!cell) {
      cell = [0, 0, node.width, node.height];
    }

    setNodeCellRect(node, cell);

    if (isGroup(node) && node.open) {
      // stretch routing columns to fit

      const residuals = [
        rectWidth(node.content!) - sum(node.colSize!),
        rectHeight(node.content!) - sum(node.rowSize!),
      ];

      if (residuals[0] > 0) {
        /* stretch all routing columns to fit

        const n = 1 + Math.floor(node.colSize!.length / 2);

        for (let c = 0; c < node.colSize!.length; c += 2) {
          node.colSize![c] += residuals[0] / n;
        }
        */

        /* stretch first and last routing column to fit */
        node.colSize![0] += residuals[0] / 2;
        node.colSize![node.colSize!.length - 1] += residuals[0] / 2;
      }

      if (residuals[1] > 0) {
        /* stretch all routing rows to fit

        const n = 1 + Math.floor(node.rowSize!.length / 2);

        for (let r = 0; r < node.rowSize!.length; r += 2) {
          node.rowSize![r] += residuals[1] / n;
        }
        */

        /* stretch first and last routing column to fit */
        node.rowSize![0] += residuals[1] / 2;
        node.rowSize![node.rowSize!.length - 1] += residuals[1] / 2;
      }

      const colPos = getColPositions(node);
      const rowPos = getRowPositions(node);

      node.children!.forEach(child => {
        setPositions(child, [
          colPos[child.col],
          rowPos[child.row],
          colPos[child.col + child.colSpan],
          rowPos[child.row + child.rowSpan],
        ]);
      });
    }
  };

  const router = createRouter(getBusId);

  /* the graph synthetic root doesn't have a parent to lay it out */

  graph.root.col = 0;
  graph.root.colSpan = 1;
  graph.root.row = 0;
  graph.root.rowSpan = 1;

  /* get ready for laying out */

  resetGridArrays(graph.root);

  /* route links */

  graph.edges.forEach(link => {
    router.link(link); // creates waypoints for edges
  });

  /* adjust node col and row sizes */

  setSizes(graph.root); // initialise node col and row sizes (bottom up)

  /* set node positions */

  setPositions(graph.root, null);

  /* set link routes according to their waypoints */

  graph.edges.forEach(link => {
    const route = router.route(link);

    if (route && route.length > 1) {
      setLinkRoute(link, route);
    } else {
      link.route = undefined;
    }
  });
};

export const saveLayout = (rootNode: BlocksViewNode) => {
  dispatchAction(
    updateViewSettings({
      viewId: ViewIds.BLOCKS,
      settings: { layoutData: getLayoutData(rootNode.children) },
      persistent: true,
    })
  );
};

/**
 * We don't save the root node in the layout data, as it is not really part of the layout.
 *
 * @returns true if all nodes have been assigned a layout
 */
export const restoreLayout = (
  nodes: BlocksViewNode[] | null,
  layoutData: LayoutData
) => {
  if (!nodes) {
    return true;
  }

  const restoreSuccessValues = nodes.map(node => restoreNode(node, layoutData));
  return restoreSuccessValues.every(Boolean);
};

/**
 * We don't save the root node in the layout data, as it is not really part of the layout.
 *
 * @returns true if all nodes have been assigned a layout
 */
const restoreNode = (node: BlocksViewNode, layoutData: LayoutData): boolean => {
  const nodeIndex = getNodeLayoutIndex(node);
  const nodeLayoutData: NodeLayoutData | undefined = layoutData[nodeIndex];

  const hasSavedLayout = Boolean(nodeLayoutData);

  if (hasSavedLayout) {
    node.col = nodeLayoutData.col;
    node.colSpan = nodeLayoutData.colSpan;
    node.row = nodeLayoutData.row;
    node.rowSpan = nodeLayoutData.rowSpan;
  } else {
    node.col = NaN;
    node.colSpan = NaN;
    node.row = NaN;
    node.rowSpan = NaN;
  }

  if (!node.children?.length) {
    return hasSavedLayout;
  }

  return hasSavedLayout && restoreLayout(node.children, layoutData);
};
