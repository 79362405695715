import { ViewIds } from '@ardoq/api-types';
import { SettingsType } from '@ardoq/view-settings';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { IconName } from '@ardoq/icons';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { createDropdownSliderOptionManager } from 'viewSettings/settingsHelper';

export const maxDepthContainerMenuItem = (viewId: ViewIds, value: number) => ({
  id: 'maxDepthContainer',
  label: 'Maximum depth',
  type: SettingsType.DROPDOWN,
  iconName: 'hot_tub' as IconName,
  options: [
    createDropdownSliderOptionManager(
      viewId,
      new Map(),
      onViewSettingsUpdate
    )({
      name: 'maxDepth',
      label: 'Maximum depth',
      value,
      min: 0,
      max: 10,
      step: 1,
      type: DropdownOptionType.SLIDER,
    }),
  ],
});
