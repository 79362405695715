import { getResultType } from './utils';
import { GremlinResult, GremlinResultType } from './types';
import { flatten, uniq, without } from 'lodash';
import { searchFieldLabels$ } from 'streams/fields/advancedSearchFields$';
import { connect } from '@ardoq/rxbeach';
import { FormWrapper } from '@ardoq/forms';
import { Multiselect } from '@ardoq/select';

const getColumnsFromResult = (result: GremlinResult): string[] => {
  const isComponent = getResultType(result) === GremlinResultType.COMPONENT;
  const isReference = getResultType(result) === GremlinResultType.REFERENCE;
  if (isComponent || isReference) {
    return without(Object.keys(result.properties || []), 'name');
  }

  return [];
};

const collectColumnsFromResults = (results: GremlinResult[]): string[] => {
  return uniq(flatten(results.map(getColumnsFromResult)));
};

type StreamedProps = {
  fieldsLabels: { [fieldName: string]: string };
};

type OwnProps = {
  onSelectedColumnsChange: (selectedColumns: string[] | null) => void;
  selectedColumns: string[];
  results: GremlinResult[];
};

type GremlinResultsColumnsSelectProps = StreamedProps & OwnProps;
const GremlinResultsColumnsSelect = (
  props: GremlinResultsColumnsSelectProps
) => {
  const resultTypes = getResultType(props.results[0]);

  // for now we are limiting ability to select columns to components, and
  // references only
  if (
    ![GremlinResultType.COMPONENT, GremlinResultType.REFERENCE].includes(
      resultTypes
    )
  )
    return null;

  return (
    <FormWrapper>
      <Multiselect
        label="Select columns"
        options={collectColumnsFromResults(props.results).map(key => ({
          label: props.fieldsLabels[key] || key,
          value: key,
        }))}
        value={props.selectedColumns}
        onValueChange={props.onSelectedColumnsChange}
      />
    </FormWrapper>
  );
};

export default connect(GremlinResultsColumnsSelect, searchFieldLabels$);
