import { ArdoqId } from '@ardoq/api-types';
import {
  ColumnDiv,
  Container,
  ContainerTitle,
  Group,
  Leaf,
  ReferencedLeaf,
  ReferencedLeafComponent,
  RowDiv,
} from './atoms';
import { CONTAINER_MARGIN, GRID_WIDTH } from './consts';
import { LayoutBoxType, LayoutBoxTypes } from './types';
import {
  TruncatingComponentLabel,
  TruncatingComponentLabelProperties,
  TruncatingReferenceLabel,
} from '@ardoq/graph';
import { LEAF_NODES_MARGIN } from 'tabview/capabilityMap/consts';

const CapabilityMapComponentLabel = (
  args: Omit<TruncatingComponentLabelProperties, 'iconClassName'>
) => <TruncatingComponentLabel {...args} iconClassName="componentIcon" />;

const getGridWidth = (
  calculatedGridWidth: number,
  parentCalculatedGridWidth: number | undefined,
  targetGridWidth: number,
  parentGridWidth: number,
  isRoot = false
) => {
  if (isRoot) {
    return 1;
  }
  if (parentCalculatedGridWidth) {
    return calculatedGridWidth / parentCalculatedGridWidth;
  }
  return targetGridWidth / parentGridWidth;
};

type LayoutBoxProps = {
  layoutBox: LayoutBoxType;
  parentGridWidth?: number;
  parentCalculatedGridWidth?: number;
  isRoot?: boolean;
  hoveredComponentId?: ArdoqId | null;
  focusedComponentId?: ArdoqId | null;
};

const LayoutBox = ({
  layoutBox,
  parentGridWidth = 0,
  parentCalculatedGridWidth,
  hoveredComponentId,
  focusedComponentId,
}: LayoutBoxProps) => {
  const {
    layoutType,
    targetGridWidth,
    calculatedGridWidth,
    layoutBoxes,
    leavesCount,
    classNames,
    representationData,
    id,
    referenceLabel,
    referenceFieldValue,
    referenceFieldLabel,
    iconColor,
    hasCollapsedNodes,
    isCollapsedReference,
  } = layoutBox;
  const isHighlighted = id === focusedComponentId || id === hoveredComponentId;
  switch (layoutType) {
    case LayoutBoxTypes.COLUMN: {
      const isRoot = !parentGridWidth;
      const gridWidth = getGridWidth(
        calculatedGridWidth,
        parentCalculatedGridWidth,
        targetGridWidth,
        parentGridWidth,
        isRoot
      );
      const style = { width: `${gridWidth * 100}%` };

      return (
        <ColumnDiv style={style}>
          {layoutBoxes.map((childBox, index) => (
            <LayoutBox
              key={`col-${index}`}
              layoutBox={childBox}
              focusedComponentId={focusedComponentId}
              hoveredComponentId={hoveredComponentId}
              parentGridWidth={targetGridWidth}
              parentCalculatedGridWidth={calculatedGridWidth}
            />
          ))}
        </ColumnDiv>
      );
    }

    case LayoutBoxTypes.ROW: {
      const isRootRow = !parentGridWidth;
      const style = isRootRow
        ? {
            width: `${
              Math.max(calculatedGridWidth, targetGridWidth) * GRID_WIDTH
            }px`,
          }
        : {};

      const propagatedParentGridWidth = parentCalculatedGridWidth
        ? Math.min(parentCalculatedGridWidth, targetGridWidth)
        : targetGridWidth;

      return (
        <RowDiv style={style}>
          {layoutBoxes.map((childBox, index) => (
            <LayoutBox
              key={`row-${index}`}
              layoutBox={childBox}
              focusedComponentId={focusedComponentId}
              hoveredComponentId={hoveredComponentId}
              parentGridWidth={propagatedParentGridWidth}
              parentCalculatedGridWidth={
                isRootRow ? undefined : calculatedGridWidth
              }
            />
          ))}
        </RowDiv>
      );
    }

    case LayoutBoxTypes.GROUP: {
      const gridWidth = getGridWidth(
        calculatedGridWidth,
        parentCalculatedGridWidth,
        targetGridWidth,
        parentGridWidth
      );

      const style = {
        width: `${gridWidth * 100}%`,
      };

      return (
        <Group style={style}>
          {layoutBoxes.map((childBox, index) => (
            <LayoutBox
              key={`container-${index}`}
              layoutBox={childBox}
              focusedComponentId={focusedComponentId}
              hoveredComponentId={hoveredComponentId}
              parentGridWidth={Math.min(
                targetGridWidth,
                leavesCount || Infinity
              )}
            />
          ))}
        </Group>
      );
    }

    case LayoutBoxTypes.CONTAINER: {
      const gridWidth = getGridWidth(
        calculatedGridWidth,
        parentCalculatedGridWidth,
        targetGridWidth,
        parentGridWidth
      );

      const style = {
        width: `calc(${gridWidth * 100}% - ${2 * CONTAINER_MARGIN}px)`,
      };

      return (
        <Container
          $containsCollapsedNodes={hasCollapsedNodes}
          $isHighlighted={isHighlighted}
          style={style}
          className={classNames}
          data-component-id={id}
        >
          <ContainerTitle className="container-title">
            <CapabilityMapComponentLabel
              representationData={representationData}
              id={id}
              labelClassName="container-name"
              iconColor={iconColor}
            />
          </ContainerTitle>
          {layoutBoxes.map((childBox, index) => (
            <LayoutBox
              key={`container-${index}`}
              layoutBox={childBox}
              focusedComponentId={focusedComponentId}
              hoveredComponentId={hoveredComponentId}
              parentGridWidth={Math.min(
                targetGridWidth,
                leavesCount || Infinity
              )}
            />
          ))}
        </Container>
      );
    }

    case LayoutBoxTypes.LEAF: {
      const gridWidth = getGridWidth(
        calculatedGridWidth,
        parentCalculatedGridWidth,
        targetGridWidth,
        parentGridWidth
      );

      const style = {
        width: `calc(${gridWidth * 100}% - ${2 * LEAF_NODES_MARGIN}px)`,
      };
      return (
        <Leaf
          $containsCollapsedNodes={hasCollapsedNodes}
          $isHighlighted={isHighlighted}
          className={`leaf-node ${classNames}`}
          style={style}
          data-component-id={id}
        >
          <CapabilityMapComponentLabel
            representationData={representationData}
            id={id}
            labelClassName="leaf-name"
            iconColor={iconColor}
          />
        </Leaf>
      );
    }

    case LayoutBoxTypes.LEAF_REFERENCED: {
      const style = {
        width: `${(targetGridWidth / parentGridWidth) * 100}%`,
      };
      return (
        <div style={style}>
          <ReferencedLeaf
            className={classNames}
            data-component-id={id}
            $isCollapsedReference={isCollapsedReference}
            $isHighlighted={isHighlighted}
          >
            {referenceLabel && referenceLabel.length > 0 && (
              <TruncatingReferenceLabel
                className="referenceLabel"
                label={referenceLabel}
                fieldLabel={referenceFieldLabel}
                fieldValue={referenceFieldValue}
              />
            )}
            <ReferencedLeafComponent className="leaf-node">
              <CapabilityMapComponentLabel
                representationData={representationData}
                id={id}
                labelClassName="leaf-name"
                iconColor={iconColor}
              />
            </ReferencedLeafComponent>
          </ReferencedLeaf>
        </div>
      );
    }

    default:
      return null;
  }
};

export default LayoutBox;
