import {
  BaseClass,
  IGraph,
  IGroupBoundsCalculator,
  INode,
  INodeSizeConstraintProvider,
  Rect,
  Size,
  TextRenderSupport,
} from '@ardoq/yfiles';
import {
  BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  PREFERRED_MINIMUM_GROUP_WIDTH,
  SUBHEADER_FONT_HEIGHT,
} from '../consts';
import { logError } from '@ardoq/logging';
import {
  CollapsibleGraphGroup,
  GROUP_HEADER_FONT_HEIGHT,
  TEXT_PADDING_VERTICAL,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
} from '@ardoq/graph';
import {
  determineImgStyleSize,
  determineStyleSize,
} from 'yfilesExtensions/styles/util';
import { isContextNode } from 'yfilesExtensions/styles/nodeDecorator';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  HALO_PADDING,
  NODE_HEIGHT,
  NODE_WIDTH,
} from 'yfilesExtensions/styles/consts';
import getNodeLayoutSize from 'yfilesExtensions/getNodeLayoutSize';
import { GraphItem } from 'graph/GraphItem';
import { DiffType } from '@ardoq/data-model';
import { createFifoCache } from '@ardoq/common-helpers';
import {
  getGroupHeaderWidthWithoutLabel,
  measureGroupLabel,
  measureGroupSubLabel,
  getGroupLabelMaxWidth,
  getGroupLabelMaxWidthModernized,
} from '../utils';
import {
  getOtherLabelsHeight,
  getOtherLabelSizes,
  getOtherLabelsWidth,
} from './labels/labelUtils';

/** @returns the bounds of a CollapsibleGraphGroup in classic Block Diagram, based on the group's existing layout and some label measurements. */
export const groupBounds = (groupNode: INode) => {
  const { tag: group, layout } = groupNode;
  if (!(group instanceof CollapsibleGraphGroup)) {
    logError(Error('Invalid node in GroupBoundsCalculator.'));
    return Rect.EMPTY;
  }
  const isComponent = group.isComponent();

  const groupLabels = group.getItemLabels();

  if (!groupLabels) {
    return new Rect(layout.x, layout.y, layout.width, 0);
  }

  const { subLabel, mainLabel } = groupLabels;

  const headerLabelIndentX = getGroupHeaderWidthWithoutLabel(group);

  const groupLabelWidthWithSpacing =
    measureGroupLabel(mainLabel || '') + headerLabelIndentX + GROUP_PADDING;
  const groupSublabelWidthWithSpacing = measureGroupSubLabel(subLabel || '');

  const minimumGroupWidth = Math.min(
    PREFERRED_MINIMUM_GROUP_WIDTH,
    Math.max(groupLabelWidthWithSpacing, groupSublabelWidthWithSpacing)
  );

  if (!group.collapsed) {
    // this is what defines the position of child nodes in respect to the label

    const {
      groupLabelHeight,
      otherLabelsHeight,
      otherLabelsWidth,
      subLabelHeight,
      legacyFormattingHeight,
    } = getGroupLabelDimensions(groupNode, false);

    const groupLabelHeightTotal =
      groupLabelHeight +
      otherLabelsHeight +
      subLabelHeight +
      legacyFormattingHeight +
      subLabelHeight +
      GROUP_HEADER_FONT_HEIGHT;

    return new Rect(
      layout.x,
      layout.y,
      Math.max(
        layout.width,
        minimumGroupWidth,
        otherLabelsWidth + headerLabelIndentX
      ),
      groupLabelHeightTotal
    );
  }

  if (isComponent) {
    const { width: styleWidth, height: styleHeight } = group.getImage()
      ? determineImgStyleSize(group.getImage())
      : determineStyleSize(`#${group.getShape()}`);

    if (styleWidth <= 0 || styleHeight <= 0) {
      // this can happen if there's a component with no image and no shape. this seems to be an invalid configuration, but it is possible to configure a component type this way. in this case we'll just give it a default size.
      return new Rect(layout.x, layout.y, NODE_WIDTH, NODE_HEIGHT);
    }

    const yAspectRatio = NODE_HEIGHT / styleHeight;
    const xAspectRatio = NODE_WIDTH / styleWidth;

    let width;
    let height;

    if (styleWidth * yAspectRatio > NODE_WIDTH) {
      width = NODE_WIDTH;
      height = styleHeight * xAspectRatio;
    } else {
      width = styleWidth * yAspectRatio;
      height = NODE_HEIGHT;
    }
    const hasContextHighlight = isContextNode(groupNode);
    const nodeSize = getNodeLayoutSize({
      styleWidth: width,
      styleHeight: height,
      hasContextHighlight,
      contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
      visualDiffType:
        groupNode.tag instanceof GraphItem
          ? groupNode.tag.getVisualDiffType()
          : DiffType.NONE,
    });
    return new Rect(
      groupNode.layout.topLeft,
      hasContextHighlight
        ? nodeSize
        : new Size(
            nodeSize.width + 2 * HALO_PADDING,
            nodeSize.height + 2 * HALO_PADDING
          )
    );
  }

  const { groupLabelHeight } = getGroupLabelDimensions(groupNode, false);

  return new Rect(
    layout.x,
    layout.y,
    Math.max(layout.width, minimumGroupWidth),
    groupLabelHeight + TEXT_PADDING_VERTICAL / 2
  );
};

export default class GroupBoundsCalculator extends BaseClass(
  IGroupBoundsCalculator,
  INodeSizeConstraintProvider
) {
  static Instance = new GroupBoundsCalculator();
  private constructor() {
    super();
  }
  override getMaximumSize(_node: INode): Size {
    return Size.INFINITE;
  }
  override getMinimumEnclosedArea(_node: INode): Rect {
    return Rect.EMPTY;
  }
  override getMinimumSize(node: INode): Size {
    return groupBounds(node).size;
  }
  override calculateBounds(graph: IGraph, groupNode: INode): Rect {
    return groupBounds(groupNode);
  }
}

const getGroupLabelHeightFromCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (key: string) => {
    const { text, width } = JSON.parse(key);

    return TextRenderSupport.measureText({
      text,
      maximumSize: new Size(width || 0, Infinity),
      ...BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
    });
  }
);
const getGroupLabelSizeFromCacheModernized = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (key: string) => {
    const { text, width } = JSON.parse(key);

    return TextRenderSupport.measureText({
      text,
      maximumSize: new Size(width || 0, Infinity),
      ...MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
    });
  }
);

const getGroupLabelHeightWithinBlock = (text: string, width: number) => {
  if (!text) {
    return 0;
  }

  const { height } = getGroupLabelHeightFromCache(
    JSON.stringify({
      text,
      width,
    })
  );

  return height;
};
const getGroupLabelHeightWithinBlockModernized = (
  text: string,
  width: number
) => {
  if (!text) {
    return 0;
  }

  const { height } = getGroupLabelSizeFromCacheModernized(
    JSON.stringify({
      text,
      width,
    })
  );

  return height;
};

export const getGroupLabelDimensions = (
  groupNode: INode | null,
  isModern: boolean
) => {
  const defaultResult = {
    groupLabelWidth: 0,
    groupLabelHeight: 0,
    groupNameMaxHeight: 0,
    otherLabelsHeight: 0,
    otherLabelsWidth: 0,
    legacyFormattingHeight: 0,
    subLabelHeight: 0,
  };

  if (!groupNode) {
    return defaultResult;
  }

  const group = groupNode.tag as CollapsibleGraphGroup;
  const { mainLabel, otherLabels, legacyLabelParts, subLabel } =
    group.getItemLabels() ?? {};

  const isLegacyFormatting = legacyLabelParts?.fieldValue;

  const groupNameMaxHeight = getMaxGroupLabelHeight(
    Boolean(isLegacyFormatting),
    isModern
  );

  const legacyFormattingHeight = isLegacyFormatting
    ? LEGACY_FORMATTING_VALUE_HEIGHT_GROUP
    : 0;

  // includes the height of the sublabel and the top margin to the label
  const subLabelHeight = subLabel ? SUBHEADER_FONT_HEIGHT * 2 : 0;

  const groupLabelWidth = isModern
    ? getGroupLabelMaxWidthModernized(groupNode)
    : getGroupLabelMaxWidth(groupNode);
  const groupLabelHeight = Math.min(
    groupNameMaxHeight,
    isModern
      ? getGroupLabelHeightWithinBlockModernized(
          mainLabel ?? '',
          groupLabelWidth
        )
      : getGroupLabelHeightWithinBlock(mainLabel ?? '', groupLabelWidth)
  );

  if (!otherLabels?.length) {
    return {
      ...defaultResult,
      groupLabelWidth,
      groupLabelHeight,
      groupNameMaxHeight,
      legacyFormattingHeight,
      subLabelHeight,
    };
  }

  const otherLabelSizes = getOtherLabelSizes(otherLabels, isModern);

  const otherLabelsWidth = getOtherLabelsWidth(otherLabelSizes);

  const otherLabelsHeight = getOtherLabelsHeight(otherLabelSizes);

  return {
    groupLabelWidth,
    groupLabelHeight,
    groupNameMaxHeight,
    otherLabelsHeight,
    otherLabelsWidth,
    legacyFormattingHeight,
    subLabelHeight,
  };
};

const LEGACY_FORMATTING_VALUE_HEIGHT_GROUP = getGroupLabelHeightWithinBlock(
  '[]',
  Infinity
);

const THREE_LINES_GROUP_LABEL_HEIGHT = getGroupLabelHeightWithinBlock(
  Array(2).fill('1\r\n').join(''),
  Infinity
);

const FOUR_LINES_GROUP_LABEL_HEIGHT = getGroupLabelHeightWithinBlock(
  Array(3).fill('1\r\n').join(''),
  Infinity
);

const TWO_LINES_MODERNIZED_GROUP_LABEL_HEIGHT =
  getGroupLabelHeightWithinBlockModernized(
    Array(1).fill('1\r\n').join(''),
    Infinity
  );

export const getMaxGroupLabelHeight = (
  hasLegacyFormatting: boolean,
  isModern: boolean
) =>
  isModern
    ? TWO_LINES_MODERNIZED_GROUP_LABEL_HEIGHT
    : hasLegacyFormatting
      ? THREE_LINES_GROUP_LABEL_HEIGHT
      : FOUR_LINES_GROUP_LABEL_HEIGHT;
