import styled, { css } from 'styled-components';
import { ACTIVE_BOX_SHADOW, ACTIVE_BRIGHTNESS } from '@ardoq/global-consts';
import { ViewLegendContainerElement } from '@ardoq/graph';
import { BASE_TRANSITION_MS } from '../../../consts';
import { text2 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';
import {
  DropdownMenuOption,
  DropdownMenuSectionTitle,
} from '@ardoq/dropdown-menu-ui';
import { ICON_SELECTOR } from '@ardoq/icons';

const containerBorderRadius = '5px';

const containerTransitionRules = css`
  transition-property: filter, box-shadow;
  transition-duration: ${BASE_TRANSITION_MS}ms;
  transition-timing-function: ease-in-out;
`;

export const TreemapLayoutWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  ${text2}
`;

export const TreemapEntityComponent = styled.div<{
  $isSelected?: boolean;
  $isHighlighted: boolean;
}>`
  position: absolute;
  border-radius: ${containerBorderRadius};
  border-width: ${({ $isSelected }) => ($isSelected ? '2px' : '1px')};
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  ${containerTransitionRules}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      box-shadow: ${ACTIVE_BOX_SHADOW};
    `}
`;

export const EntityBackground = styled.div<{
  $isHighlighted: boolean;
  $backgroundColor?: string;
}>`
  position: absolute;
  inset: 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${containerBorderRadius};
  ${containerTransitionRules}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      filter: brightness(${ACTIVE_BRIGHTNESS});
    `}
`;

export const ComponentDecoratorWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;

  > div {
    /* prevents decorator from being wider than the container with display: flex */
    min-width: 0;
  }
`;

export const ComponentDecoratorTextContainer = styled.div`
  white-space: nowrap;
  color: ${colors.grey25};
  /* inherit from TreemapLayoutWrapper text2 */
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
`;

export const ExportContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &.forExport {
    min-width: 100%;
    ${ViewLegendContainerElement} {
      box-sizing: border-box;
      max-width: 100%;
      align-items: flex-end;
      padding-top: 1rem;
      padding-right: 4rem;
      & > div {
        max-width: 344px;
        min-width: 224px;
      }
    }
  }
`;

export const ViewWrapper = styled.div<{
  $hasData: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;

  ${({ $hasData }) =>
    $hasData &&
    css`
      margin-bottom: 7rem;
    `}
`;

export const RootContainer = styled(TreemapEntityComponent)`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-width: 2px;
  padding-top: 0.4rem;
  justify-content: flex-start;
  flex-direction: column;
`;

const DROPDOWN_MENU_WIDTH = '448px';
export const StyledDropdownMenuOption = styled(DropdownMenuOption)`
  width: ${DROPDOWN_MENU_WIDTH};

  & ${ICON_SELECTOR} {
    color: ${colors.grey35};
  }
`;

export const PopoverContentWrapper = styled.div`
  display: flex;
`;

export const StyledDropdownMenuSectionTitle = styled(DropdownMenuSectionTitle)`
  width: ${DROPDOWN_MENU_WIDTH};
`;
