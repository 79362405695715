import { Component } from 'react';
import {
  GremlinComponentResult,
  GremlinMapResult,
  GremlinPathResult,
  GremlinReferenceResult,
  GremlinResult,
  GremlinResultType,
} from '../types';
import { getResultType } from '../utils';
import GremlinResultsTableComponent from './GremlinResultsTableComponent';
import GremlinResultsTablePath from './GremlinResultsTablePath';
import GremlinResultsTableReference from './GremlinResultsTableReference';
import GremlinResultsTableMap from './GremlinResultsTableMap';
import GremlinResultsTableDirect from './GremlinResultsTableDirect';
import { SortByFn, SortById } from '@ardoq/pagination';
import { logError } from '@ardoq/logging';
import { SortOrder } from '@ardoq/api-types';

interface GremlinResultsTableProps {
  results: GremlinResult[];
  resultKeys?: string[];
  selectedColumns: string[];
  showRaw?: boolean;
  printable?: boolean;
  sortBy: SortByFn;
  sortOrder?: SortOrder;
  sortById: SortById;
}

const GremlinResultsTableSwitch = (props: GremlinResultsTableProps) => {
  const printable = props.printable || false;
  const resultType =
    (props.showRaw && GremlinResultType.DIRECT) ||
    getResultType(props.results[0]);
  return (
    <>
      {resultType === GremlinResultType.COMPONENT && (
        <GremlinResultsTableComponent
          results={props.results as GremlinComponentResult[]}
          selectedColumns={props.selectedColumns}
          printable={printable}
          sortBy={props.sortBy}
          sortOrder={props.sortOrder}
          sortById={props.sortById}
        />
      )}
      {resultType === GremlinResultType.PATH && (
        <GremlinResultsTablePath
          results={props.results as GremlinPathResult[]}
          printable={printable}
          sortBy={props.sortBy}
          sortOrder={props.sortOrder}
          sortById={props.sortById}
        />
      )}
      {resultType === GremlinResultType.REFERENCE && (
        <GremlinResultsTableReference
          results={props.results as GremlinReferenceResult[]}
          selectedColumns={props.selectedColumns}
          printable={printable}
          sortBy={props.sortBy}
          sortOrder={props.sortOrder}
          sortById={props.sortById}
        />
      )}
      {resultType === GremlinResultType.MAP && (
        <GremlinResultsTableMap
          results={props.results as GremlinMapResult[]}
          columnKeys={props.resultKeys}
          printable={printable}
          sortBy={props.sortBy}
          sortOrder={props.sortOrder}
          sortById={props.sortById}
        />
      )}
      {resultType === GremlinResultType.DIRECT && (
        <GremlinResultsTableDirect results={props.results} />
      )}
    </>
  );
};

type GremlinResultsTableState = {
  forceRawResults: boolean;
};

class GremlinResultsTable extends Component<
  GremlinResultsTableProps,
  GremlinResultsTableState
> {
  constructor(props: GremlinResultsTableProps) {
    super(props);
    this.state = {
      forceRawResults: false,
    };
  }
  componentDidCatch(error: Error) {
    logError(error, 'Error while parsing gremlin results');
    this.setState({
      forceRawResults: true,
    });
  }
  componentDidUpdate(prevProps: GremlinResultsTableProps) {
    if (
      this.state.forceRawResults &&
      prevProps.results !== this.props.results
    ) {
      this.setState({
        forceRawResults: false,
      });
    }
  }
  render() {
    const showRaw = this.props.showRaw || this.state.forceRawResults;
    return (
      <>
        {this.state.forceRawResults && (
          <p>
            <em>
              We were unable to format and render your results nicely - your
              query results are being shown as raw JSON
            </em>
          </p>
        )}
        <GremlinResultsTableSwitch {...this.props} showRaw={showRaw} />
      </>
    );
  }
}

export default GremlinResultsTable;
