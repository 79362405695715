import { CSSProperties } from 'react';
import {
  ComponentMatrixListValueHeaderCellContainer,
  HeaderCell,
} from './atoms';
import {
  ComponentMatrixDimension,
  ComponentMatrixGroup,
  ComponentMatrixGroupOwnerType,
  HeaderCellModel,
} from '../types';
import IconClickable from 'atomicComponents/IconClickable';
import { ChevronIconName } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { componentMatrixToggleCollapseGroup } from '../actions';
import { COMPONENT_HIERARCHY_PADDING } from '../consts';
import { hasChildren } from '../util';
import RowHeaderComponent from './rowHeaderComponent';
import getRowHeaderCellStyle from './getRowHeaderCellStyle';
import getRowHeaderMargins from './getRowHeaderMargins';
import { ArdoqId } from '@ardoq/api-types';
import { componentMatrixCommands } from '../commands';

const toggleCollapsed = (row: ComponentMatrixGroup) => {
  dispatchAction(
    componentMatrixToggleCollapseGroup({
      dimension: ComponentMatrixDimension.ROW,
      address: row.address,
    })
  );
};

interface ComponentMatrixRowHeaderCellArgs {
  headerCell: HeaderCellModel;
  rowIndex: number;
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
}
const ComponentMatrixRowHeaderCell = ({
  headerCell,
  rowIndex,
  hoveredComponentId,
  focusedComponentId,
}: ComponentMatrixRowHeaderCellArgs) => {
  if (headerCell.group.isHidden) {
    return null;
  }
  const isPadding =
    headerCell.value === COMPONENT_HIERARCHY_PADDING ||
    headerCell.group.ownerType === ComponentMatrixGroupOwnerType.EMPTY;
  if (isPadding) {
    return null;
  }
  const headerCellStyle: CSSProperties = {
    gridRow: `${rowIndex + 1} / ${rowIndex + 1 + headerCell.span}`,
    gridColumn: '1 / 2',
    ...getRowHeaderMargins(headerCell),
  };

  const level = headerCell.group.address.length - 1;
  const isComponent =
    headerCell.group.ownerType ===
    ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT;
  if (isComponent && headerCell.value) {
    return (
      <HeaderCell
        style={headerCellStyle}
        onMouseEnter={() =>
          componentMatrixCommands.setHoveredItemState(headerCell.value)
        }
        onMouseLeave={() => componentMatrixCommands.setHoveredItemState(null)}
        onClick={e => {
          e.stopPropagation();
          componentMatrixCommands.setFocusedItemState(headerCell.value);
        }}
      >
        <RowHeaderComponent
          componentId={headerCell.value}
          address={headerCell.group.address}
          isExpanded={headerCell.group.isExpanded}
          showExpander={hasChildren(headerCell.group)}
          expanderClicked={() => toggleCollapsed(headerCell.group)}
          headerCell={headerCell}
          isHighlighted={
            hoveredComponentId === headerCell.value ||
            focusedComponentId === headerCell.value
          }
        />
      </HeaderCell>
    );
  }
  return (
    <HeaderCell style={headerCellStyle}>
      <ComponentMatrixListValueHeaderCellContainer
        $level={level}
        style={getRowHeaderCellStyle(headerCell)}
      >
        {headerCell.value || 'none'}
        {hasChildren(headerCell.group) && !isPadding ? (
          <IconClickable
            display="inline"
            onClick={() => toggleCollapsed(headerCell.group)}
            iconName={
              headerCell.group.isExpanded
                ? ChevronIconName.UP
                : ChevronIconName.DOWN
            }
            style={{ float: 'right', flex: 'none' }}
          />
        ) : null}
      </ComponentMatrixListValueHeaderCellContainer>
    </HeaderCell>
  );
};
export default ComponentMatrixRowHeaderCell;
