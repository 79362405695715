import styled from 'styled-components';

const BodyContainer = styled.div`
  position: relative;
  background: #fff;
  flex: 1 1 100%;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.24);
  overflow: auto;
  height: 100%;
  z-index: 1;
`;

export default BodyContainer;
