import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';
import navigateToComponent from 'tabview/navigateToComponent';

const VIEW_ID = ViewIds.TREEMAP;

const setHoveredItemState = (itemId: ArdoqId | null) => {
  dispatchAction(hoveredComponentChanged(itemId), VIEW_ID);
};

const setFocusedItemState = (
  componentId: ArdoqId | null,
  isCurrentlyFocused?: boolean
) => {
  dispatchAction(
    focusedComponentChanged(!isCurrentlyFocused ? componentId : null),
    VIEW_ID
  );
};

const toggleSelectedItem = (itemId: ArdoqId) => {
  navigateToComponent(itemId);
};

export const treemapCommands = {
  setHoveredItemState,
  setFocusedItemState,
  toggleSelectedItem,
};
