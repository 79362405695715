import { HtmlCanvasVisual, INode, IRenderContext } from '@ardoq/yfiles';

class CanvasRectangularGroupVisual extends HtmlCanvasVisual {
  constructor(
    private group: INode,
    private fill: string,
    private stroke: string
  ) {
    super();
  }
  paint(renderContext: IRenderContext, ctx: CanvasRenderingContext2D): void {
    const {
      layout: { x, y, width, height },
    } = this.group;
    ctx.rect(x, y, width, height);
    ctx.fillStyle = this.fill;
    ctx.fill();
    ctx.strokeStyle = this.stroke;
    ctx.stroke();
  }
}
export default CanvasRectangularGroupVisual;
