import { ReactNode } from 'react';
import { FieldCompletion } from './types';
import { MetamodelContent } from '@ardoq/api-types';
import { MetamodelContext } from 'metamodel/types';
import CompletionBarHeader from './completionBar/CompletionBarHeader';
import CompletionBar from './completionBar/CompletionBar';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMetamodelContext } from 'metamodel/navigation/actions';
import { colors } from '@ardoq/design-tokens';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { DefaultNotification } from '@ardoq/status-ui';
import { Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

interface ComponentFieldCompletionWidgetProps {
  componentTypeFieldCompletion?: FieldCompletion[];
  referenceTypeFieldCompletion?: FieldCompletion[];
  content: MetamodelContent;
  context: MetamodelContext;
  notification?: ReactNode;
}

const ComponentFieldCompletionWidget = ({
  componentTypeFieldCompletion,
  content,
  context,
  notification,
}: ComponentFieldCompletionWidgetProps) => (
  <Box marginTop="xlarge">
    <Paragraph color="textModerate">
      Field completeness for component types in {context.name}
    </Paragraph>
    {componentTypeFieldCompletion && componentTypeFieldCompletion.length ? (
      <Box marginTop="small">
        <CompletionBarHeader left="Type" right="% of fields with value" />
        {componentTypeFieldCompletion.map(
          ({ id, name, completeness, filledFieldCount, totalFieldCount }) => {
            const textColor = totalFieldCount ? 'textDefault' : 'textSubtle';
            const fillColor = totalFieldCount ? colors.green60 : colors.grey90;
            const label = totalFieldCount
              ? `${Math.floor(completeness)}%`
              : 'N/A';
            const hoverLabel = `${filledFieldCount}/${totalFieldCount}`;
            const componentType = content.componentTypes[id];
            return (
              <CompletionBar
                key={id}
                name={name}
                textColor={textColor}
                fillColor={fillColor}
                completeness={completeness}
                label={label}
                hoverLabel={hoverLabel}
                onClick={() => {
                  dispatchAction(
                    setMetamodelContext({
                      ...componentType,
                      type: SimpleBlockDiagramContentType.COMPONENT,
                    })
                  );
                }}
              />
            );
          }
        )}
      </Box>
    ) : (
      <DefaultNotification>{notification}</DefaultNotification>
    )}
  </Box>
);

export default ComponentFieldCompletionWidget;
