import { distinctUntilChanged, map } from 'rxjs/operators';
import HumanPlaceholder from 'atomicComponents/HumanPlaceholder/HumanPlaceholder';
import { EmptyWorkspaceProps } from './types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { isPresentationMode } from 'appConfig';
import gridEditor2023$ from 'gridEditor2023/gridEditor$';
import { gridEditorStateOperations as gridEditor2023Operations } from 'gridEditor2023/gridEditorStateOperations';
import { combineLatest } from 'rxjs';
import * as gridEditor2023Actions from 'gridEditor2023/actions';

const openGridEditor = () => {
  dispatchAction(gridEditor2023Actions.showGridEditor());
};

const EmptyWorkspace = (props: EmptyWorkspaceProps) => {
  if (isPresentationMode()) {
    return (
      <HumanPlaceholder
        title="There is no data to show"
        subtitle="Data can be added through the main Ardoq application."
      />
    );
  }
  const openGridEditorButton = {
    label: 'Open Grid Editor',
    isPrimary: true,
    onClick: openGridEditor,
  };
  const actions = props.gridVisible ? [] : [openGridEditorButton];
  const subtitle =
    props.subtitle ??
    `Data can easily be added through the Grid Editor ${
      props.gridVisible ? 'below' : ''
    }`;

  return (
    <HumanPlaceholder
      title="There is no data to show"
      subtitle={subtitle}
      actions={actions}
      {...props}
    />
  );
};

export default connect(
  EmptyWorkspace,
  combineLatest({
    gridVisible: gridEditor2023$.pipe(
      map(gridEditor2023 => {
        return (
          gridEditor2023Operations.isDockedGridEditorState(gridEditor2023) &&
          gridEditor2023Operations.isDockedGridEditorOpen(gridEditor2023)
        );
      }),
      distinctUntilChanged()
    ),
  })
);
