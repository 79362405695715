import { MetamodelContext } from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import {
  getWorkspaceComponentCompletion,
  getWorkspaceReferenceCompletion,
  workspaceIsEmpty,
} from './utils';
import { WorkspaceSubtitle } from './atoms';
import BreadCrumbNavigation from './breadCrumbNavigation/BreadCrumbNavigation';
import ComponentFieldCompletionWidget from './completion/ComponentFieldCompletionWidget';
import ReferenceFieldCompletionWidget from './completion/ReferenceFieldCompletionWidget';
import WorkspaceNavigationTitle from './workspaceNavigationTitle/WorkspaceNavigationTitle';
import { DefaultNotification } from '@ardoq/status-ui';
import { Box } from '@ardoq/layout';

const WorkspaceStats = ({
  context,
  metamodel,
}: {
  context: MetamodelContext;
  metamodel: Metamodel;
}) => {
  const content = metamodel.metamodel;
  const componentCompletion = getWorkspaceComponentCompletion(context, content);
  const referenceCompletion = getWorkspaceReferenceCompletion(context, content);
  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
          {context.name}
        </WorkspaceNavigationTitle>
      </Box>
      <WorkspaceSubtitle
        componentCompletion={componentCompletion}
        referenceCompletion={referenceCompletion}
      />
      {workspaceIsEmpty(context, content) ? (
        <DefaultNotification>
          This workspace has no components or references
        </DefaultNotification>
      ) : (
        <>
          <ComponentFieldCompletionWidget
            componentTypeFieldCompletion={componentCompletion}
            content={content}
            context={context}
            notification="This workspace has no components"
          />
          <ReferenceFieldCompletionWidget
            referenceTypeFieldCompletion={referenceCompletion}
            content={content}
            context={context}
            notification="This workspace has no references"
          />
        </>
      )}
    </Box>
  );
};

export default WorkspaceStats;
