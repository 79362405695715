import {
  LegendItemModel,
  ViewLegend,
  ViewLegendSection,
  formattingSections,
  getActiveConditionalFormattingForLegend,
  componentsLegendRow,
  referencesLegendRow,
  formattingLegendRow,
} from '@ardoq/view-legend';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DiffMode } from '@ardoq/api-types';
import { LegendComponentType, LegendReferenceType } from '@ardoq/graph';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  graphViewLegendHighlightComponentType,
  graphViewLegendHighlightConditionalFormatting,
  graphViewLegendHighlightReferenceType,
  graphViewLegendSetSelectedComponentTypes,
  graphViewLegendSetSelectedConditionalFormatting,
  graphViewLegendSetSelectedReferenceTypes,
} from './actions';

interface GraphViewLegendProps {
  referenceTypes: LegendReferenceType[];
  componentTypes: LegendComponentType[];
  hasCollapsedNodes: boolean;
  hasReferenceParents: boolean;
  hasNonComponentNodes: boolean;
  isUserDefinedGrouping: boolean;
  showComponentSwatches: boolean;
  showReferenceConditionalFormatting: boolean;
  showComponentShapes: boolean;
  showReferencesAsLines: boolean;
  activeDiffMode: DiffMode | null;
  selectableItems?: boolean;
  selectedConditionalFormattingIds?: string[];
  itemClickTrackingFunction?: (legendRowLabel: string) => void;
}

const selectableFormattingSection = (
  section: ViewLegendSection,
  selectedIds: Set<string> | null,
  setSelectedItems: (items: LegendItemModel[]) => void
): ViewLegendSection => ({
  ...section,
  selectableItems: true,
  setSelectedItems,
  items: section.items.map(item => ({
    ...item,
    isSelected: item.id ? (selectedIds?.has(item.id) ?? false) : false,
    onMouseOver: () =>
      dispatchAction(
        graphViewLegendHighlightConditionalFormatting({ id: item.id || '' })
      ),
    onMouseOut: () =>
      dispatchAction(
        graphViewLegendHighlightConditionalFormatting({ id: null })
      ),
  })),
});

const getSelectedItemsSetter = (
  formatting: ViewLegendSection[],
  selectedIds?: Set<string>
) => {
  const selectedIdsBySection = new Map(
    formatting.map(section => [
      section,
      section.items
        .filter(({ id }) => id && selectedIds?.has(id))
        .map(({ id }) => id),
    ])
  );
  /** legend sections were sort of designed to each have their own independent selection. but in the formatting legend, we can have a selection spanning multiple sections. this function serves to manage the consolidated selection of all sections under the formatting legend row. */
  return (section: ViewLegendSection) => (items: LegendItemModel[]) => {
    const selectedIdsFromThisSection = new Set(items.map(({ id }) => id));
    const selectionFromThisSection = section.items
      .filter(({ id }) => id && selectedIdsFromThisSection.has(id))
      .map(({ id }) => id)
      .filter(ExcludeFalsy);
    selectedIdsBySection.set(section, Array.from(selectedIdsFromThisSection));
    const selectionFromOtherSections = [...selectedIdsBySection.entries()]
      .filter(([currentSection]) => currentSection !== section)
      .flatMap(([, filteredItems]) => filteredItems.filter(ExcludeFalsy));
    dispatchAction(
      graphViewLegendSetSelectedConditionalFormatting({
        ids: [...selectionFromThisSection, ...selectionFromOtherSections],
      })
    );
  };
};

const getConditionalFormatting = ({
  showReferenceConditionalFormatting,
}: Pick<GraphViewLegendProps, 'showReferenceConditionalFormatting'>) => {
  const { components, references, tags } =
    getActiveConditionalFormattingForLegend();
  return {
    components,
    references: showReferenceConditionalFormatting ? references : [],
    tags,
  };
};

const setSelectedComponentItems = (
  items: LegendItemModel[],
  componentTypes: LegendComponentType[]
) => {
  const selectedIds = new Set(items.map(({ id }) => id));
  dispatchAction(
    graphViewLegendSetSelectedComponentTypes({
      componentTypes: componentTypes.filter(({ ids }) =>
        selectedIds.has(ids.join(','))
      ),
    })
  );
};
const onComponentMouseOver = (componentType: LegendComponentType | null) =>
  dispatchAction(
    graphViewLegendHighlightComponentType({
      componentType,
    })
  );

const setSelectedReferenceItems = (
  items: LegendItemModel[],
  referenceTypes: LegendReferenceType[]
) => {
  const selectedIds = new Set(items.map(({ id }) => id));
  dispatchAction(
    graphViewLegendSetSelectedReferenceTypes({
      referenceTypes: referenceTypes.filter(({ globalTypeIds }) =>
        selectedIds.has(globalTypeIds.join(','))
      ),
    })
  );
};
const onReferenceMouseOver = (referenceType: LegendReferenceType | null) =>
  dispatchAction(
    graphViewLegendHighlightReferenceType({
      referenceType,
    })
  );

const GraphViewLegend = ({
  componentTypes,
  referenceTypes,
  hasCollapsedNodes,
  hasReferenceParents,
  hasNonComponentNodes,
  isUserDefinedGrouping,
  showComponentSwatches,
  showReferenceConditionalFormatting,
  showComponentShapes,
  showReferencesAsLines,
  activeDiffMode,
  selectableItems,
  selectedConditionalFormattingIds,
  itemClickTrackingFunction,
}: GraphViewLegendProps) => (
  <ViewLegend
    componentTypes={[]}
    referenceTypes={[]}
    additionalRows={[
      componentsLegendRow({
        componentTypes,
        hasCollapsedNodes,
        hasReferenceParents,
        hasNonComponentNodes,
        isUserDefinedGrouping,
        showComponentSwatches,
        showComponentShapes,
        selectableItems,
        onComponentMouseOver,
        setSelectedComponentItems,
        isComponentTypesHeadingVisible: Boolean(
          hasCollapsedNodes || hasReferenceParents || showComponentShapes
        ),
      }),
      referencesLegendRow({
        referenceTypes,
        showReferencesAsLines,
        selectableItems,
        onReferenceMouseOver,
        setSelectedReferenceItems,
      }),
      formattingLegendRow({
        selectableItems,
        getSelectedItemsSetter,
        selectableFormattingSection,
        formatting: formattingSections(
          getConditionalFormatting({ showReferenceConditionalFormatting }),
          true
        ),
        selectedIds: selectedConditionalFormattingIds
          ? new Set(selectedConditionalFormattingIds)
          : undefined,
      }),
    ].filter(ExcludeFalsy)}
    activeDiffMode={activeDiffMode}
    itemClickTrackingFunction={itemClickTrackingFunction}
  />
);

export default GraphViewLegend;
