import { ViewIds } from '@ardoq/api-types';
import { ArrowForwardIcon } from '@ardoq/icons';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { dispatchAction } from '@ardoq/rxbeach';
import { StatusType } from '@ardoq/status-ui';
import { isPresentationMode } from 'appConfig';
import { CurrentUserState } from 'streams/currentUser/currentUser$';
import { setActiveMainTabLeft } from 'streams/views/mainContent/actions';
import { showViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import {
  EphemeralFeaturesCarouselConfig,
  EphemeralNotificationConfig,
  EphemeralNotificationKeys,
} from './types';
import BlocksViewCarouselGif from './assets/interactiveBlocks.gif';

export const RELEVANT_NOTIFICATION_KEYS: EphemeralNotificationKeys[] =
  Object.values(EphemeralNotificationKeys);

const switchToNewBlockDiagram: EphemeralNotificationConfig = {
  id: EphemeralNotificationKeys.SWITCH_TO_THE_NEW_BLOCK_DIAGARAM,
  title: 'Switch to the sleek new Block Diagram',
  description: (
    <>
      Give your data a fresh, polished look with better control of color and
      component style.
      <br />
      This current Block diagram will be phased out of Viewpoint data
      exploration by March 31st 2025.
    </>
  ),
  badgeConfig: { statusType: StatusType.NEW, label: 'New' },
  actionButtonText: 'Switch now',
  onActionButtonClick: () => {
    dispatchAction(
      setActiveMainTabLeft({
        activeTabId: ViewIds.MODERNIZED_BLOCK_DIAGRAM,
      })
    );
  },
  relevantFromDate: new Date('2024-03-14'),
  relevantUntilDate: new Date('2025-03-31'),
  isRelevantForCurrentUser: (_: CurrentUserState) => !isPresentationMode(),
};

const timelineMultiLabelPromo: EphemeralNotificationConfig = {
  id: EphemeralNotificationKeys.TIMELINE_MULTI_LABEL_PROMOTION,
  title: 'Timeline updates - clearer data, deeper insights 🚀',
  description: (
    <ul>
      <li>Share richer context with multiple labels on component</li>
      <li>Expand overlapping phases for clearer data visibility</li>
    </ul>
  ),
  badgeConfig: { statusType: StatusType.NEW, label: 'New' },
  actionButtonText: 'Try it now',
  onActionButtonClick: () => {
    dispatchAction(
      showViewSidePanel(ViewSidePanelContentIds.PERSPECTIVES_LABELS)
    );
  },
  relevantFromDate: new Date('2024-03-14'),
  relevantUntilDate: new Date('2025-03-31'),
  isRelevantForCurrentUser: (currentUser: CurrentUserState) =>
    !isPresentationMode() &&
    currentUser.writeAccess &&
    currentUser.created < '2024-03-17',
};

const blocksViewModalConfig: EphemeralFeaturesCarouselConfig = {
  id: EphemeralNotificationKeys.BLOCKS_VIEW_INTRODUCTION_MODAL,
  cards: [
    {
      header: 'Introducing Interactive Blocks!',
      Content: () => (
        <img
          alt="An image displaying how to open interactive blocks view and move around nodes"
          src={BlocksViewCarouselGif}
          style={{ maxHeight: 'inherit', objectFit: 'contain' }}
        />
      ),
      bodyText: (
        <>
          <div>
            Enjoy the flexibility to rearrange your data for better
            storytelling.
          </div>
          <ul>
            <li>
              Drag and drop components to positions that stakeholders understand
            </li>
            <li>Resize components to emphasize key information</li>
          </ul>
        </>
      ),
      externalLinkText: 'Learn more about Interactive Blocks',
      externalLinkUrl: KnowledgeBaseLink.INTERACTIVE_BLOCKS,
    },
  ],
  endButtonTitle: (
    <div style={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
      <span>Get started</span>
      <ArrowForwardIcon />
    </div>
  ),
  relevantFromDate: new Date('2025-03-26'),
  relevantUntilDate: new Date('2025-04-26'),
  isRelevantForCurrentUser: () => !isPresentationMode(),
};

export const EPHEMERAL_NOTIFICATION_CONFIGS = new Map<
  string,
  EphemeralNotificationConfig | EphemeralFeaturesCarouselConfig
>([
  [
    EphemeralNotificationKeys.SWITCH_TO_THE_NEW_BLOCK_DIAGARAM,
    switchToNewBlockDiagram,
  ],
  [
    EphemeralNotificationKeys.TIMELINE_MULTI_LABEL_PROMOTION,
    timelineMultiLabelPromo,
  ],
  [
    EphemeralNotificationKeys.BLOCKS_VIEW_INTRODUCTION_MODAL,
    blocksViewModalConfig,
  ],
]);
