import { LabelCreator } from './LabelCreator';
import { FreeNodeLabelModel, Point } from '@ardoq/yfiles';

export class NodeLabelCreator extends LabelCreator {
  static instance = new NodeLabelCreator();
  private static urlNodeButtonModelParameter =
    new FreeNodeLabelModel().createParameter(
      new Point(1.15, 0), // upper right corner
      new Point(0, 0),
      new Point(0.5, 0.5)
    );
  private constructor() {
    super();
  }
  override getLayoutParameter() {
    return NodeLabelCreator.urlNodeButtonModelParameter;
  }
}
