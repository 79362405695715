import { ComponentDataSourceItem } from '../types';
import { ComponentRow } from './ComponentRow';
import { ComponentTreeWrapper } from '../atoms';
import { EXPORT_AUTOSIZE_CLASS_NAME } from '@ardoq/global-consts';

interface ComponentTreeProps {
  dataSource: ComponentDataSourceItem[];
}

export const ComponentTree = ({ dataSource }: ComponentTreeProps) => (
  <ComponentTreeWrapper className={EXPORT_AUTOSIZE_CLASS_NAME}>
    {dataSource.map(dataSourceItem => (
      <ComponentRow
        key={dataSourceItem.id}
        componentId={dataSourceItem.id}
        {...dataSourceItem}
      />
    ))}
  </ComponentTreeWrapper>
);
