import styled from 'styled-components';
import GroupExpander from './GroupExpander';
import { GROUP_PADDING, SUBHEADER_FONT_HEIGHT } from './consts';
import {
  CollapsibleGraphGroup,
  CountLabel,
  TAB_HEIGHT,
  estimateCountLabelWidth,
} from '@ardoq/graph';
import { ViewIds } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import {
  VisualDiffClassName,
  ensureContrast,
  visualDiffBackgroundColors,
  visualDiffColors,
} from '@ardoq/color-helpers';
import { getElementOpacityIfTransparentized } from './utils';

const CORNER_RADIUS = 4;

const CollapsibleGroupRect = styled.rect`
  fill: ${colors.grey90};
`;

const CollapsibleGroupHeaderSubLabel = styled.text`
  fill: ${colors.grey35};
  font-size: ${SUBHEADER_FONT_HEIGHT}px;
  font-style: italic;
  dominant-baseline: central;
`;

interface CollapsibleGroupProperties {
  group: CollapsibleGraphGroup;
  width: number;
  height: number;
  subLabelOffestY: number;
  offsetX: number;
  className?: string;
  borderColor?: string;
  subTitle?: string;
  descendantCount: number;
  viewId: ViewIds;
  visualDiffClass: VisualDiffClassName | '';
}

const CollapsibleGroup = ({
  group: { id: groupId, collapsed, isTransparentized },
  width,
  height,
  subLabelOffestY,
  offsetX,
  className,
  borderColor,
  subTitle,
  descendantCount,
  viewId,
  visualDiffClass,
}: CollapsibleGroupProperties) => {
  const groupElementsOpacityStyle = {
    opacity: getElementOpacityIfTransparentized(isTransparentized),
  };
  return (
    <>
      <CollapsibleGroupRect
        className={className}
        style={{
          fill: visualDiffClass
            ? visualDiffBackgroundColors[visualDiffClass]
            : undefined,
          stroke: visualDiffClass
            ? ensureContrast(
                visualDiffBackgroundColors[visualDiffClass],
                visualDiffColors[visualDiffClass]
              )
            : borderColor,
          ...groupElementsOpacityStyle,
        }}
        width={width}
        height={height}
        rx={CORNER_RADIUS}
        ry={CORNER_RADIUS}
      />
      <GroupExpander
        viewId={viewId}
        groupId={groupId}
        collapsed={collapsed}
        boundsSize={TAB_HEIGHT}
        style={groupElementsOpacityStyle}
      />
      {/** labelling is done in GroupLabelStyle, so that they can participate in the EdgeRouter's node label avoidance. */}
      {collapsed && (
        <CountLabel
          count={descendantCount}
          transform={`translate(${
            width -
            estimateCountLabelWidth(descendantCount) / 2 -
            GROUP_PADDING / 2
          }, ${TAB_HEIGHT / 2})`}
          style={groupElementsOpacityStyle}
        />
      )}
      {subTitle && (
        <CollapsibleGroupHeaderSubLabel
          y={subLabelOffestY}
          x={offsetX}
          style={groupElementsOpacityStyle}
        >
          {subTitle}
        </CollapsibleGroupHeaderSubLabel>
      )}
    </>
  );
};

export default CollapsibleGroup;
