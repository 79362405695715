import { dispatchAction } from '@ardoq/rxbeach';
import { IconName } from '@ardoq/icons';
import {
  NameLabelDict,
  RefTypeIds,
  ViewSettingsShape,
  ViewStreamShape,
} from './types';
import {
  type SettingsConfig,
  SettingsType,
  viewSettingsConsts,
  updateViewSettings,
} from '@ardoq/view-settings';
import {
  type DropdownItem,
  type DropdownOption,
  DropdownOptionType,
} from '@ardoq/dropdown-menu';
import { ViewIds } from '@ardoq/api-types';
import { collapseChildrenDropdownTree } from '@ardoq/dependency-map';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { GlobalReferenceTypeId, GlobalReferenceTypes } from '@ardoq/data-model';

type IsActive = (
  viewState: ViewSettingsShape,
  settingsKey: string,
  name: string
) => boolean;

const defaultIsActive: IsActive = (
  viewState: ViewSettingsShape,
  settingsKey: string,
  name: string
) => name === viewState[settingsKey as keyof ViewSettingsShape];

const isGroupingKeyActive =
  (componentTypeIds: Set<string>) =>
  (viewState: ViewSettingsShape, settingsKey: string, name: string) => {
    if (name === '') {
      // If the last selected hidden type doesn't belong to this workspace
      // let 'None' be selected in the dropdown.
      const groupingTypeId = viewState[
        settingsKey as keyof ViewSettingsShape
      ] as any as string;
      return groupingTypeId === '' || !componentTypeIds.has(groupingTypeId);
    }
    return defaultIsActive(viewState, settingsKey, name);
  };

const getOptions = (
  viewState: ViewSettingsShape,
  list: NameLabelDict[],
  settingsKey: string,
  isActive: IsActive = defaultIsActive
): DropdownOption[] =>
  list.map(({ name, label }) => ({
    label: label,
    isActive: isActive(viewState, settingsKey, name),
    onClick: getOnClick(settingsKey, name),
    type: DropdownOptionType.OPTION,
    truncateLabel: true,
  }));

const getOnClick = (key: string, value: any) => () => {
  const settings = { [key]: value };
  const persistent = true;
  dispatchAction(
    updateViewSettings({ viewId: ViewIds.CAPABILITY_MAP, settings, persistent })
  );
};

const toggleField = (
  availableRefTypeIds: GlobalReferenceTypeId[],
  selectedRefTypeIds: GlobalReferenceTypeId[],
  refTypeId: GlobalReferenceTypeId
) => {
  switch (refTypeId) {
    case RefTypeIds.ALL:
      return selectedRefTypeIds.includes(RefTypeIds.ALL)
        ? []
        : [RefTypeIds.ALL];
    case RefTypeIds.NONE:
      return [];

    default:
      if (selectedRefTypeIds.includes(RefTypeIds.ALL)) {
        return availableRefTypeIds.filter(
          selectedRefTypeId => selectedRefTypeId !== refTypeId
        );
      }
      if (selectedRefTypeIds.includes(refTypeId)) {
        return selectedRefTypeIds.filter(
          selectedRefTypeId => selectedRefTypeId !== refTypeId
        );
      }
      return selectedRefTypeIds.concat([refTypeId]);
  }
};

const getOptionClickHandler =
  (
    viewId: ViewIds,
    availableRefTypeIds: GlobalReferenceTypeId[],
    selectedRefTypeIds: GlobalReferenceTypeId[],
    refTypeId: GlobalReferenceTypeId,
    key: string
  ) =>
  () => {
    const settings = {
      [key]: toggleField(availableRefTypeIds, selectedRefTypeIds, refTypeId),
    };
    const persistent = true;
    dispatchAction(updateViewSettings({ viewId, settings, persistent }));
  };

type ReferenceTypeDict = {
  referenceTypes: GlobalReferenceTypes;
};

const getReferenceTypeOptions = (
  {
    referenceTypes: { targetReferenceTypes, sourceReferenceTypes },
  }: ReferenceTypeDict,
  viewState: ViewSettingsShape
) => {
  const showAllOutgoing = viewState.includeOutgoingReferenceTypes.includes(
    RefTypeIds.ALL
  );
  const targetRefTypeIds = sourceReferenceTypes.map(({ id }) => id);
  const outgoingRefTypes = sourceReferenceTypes.map<DropdownOption>(
    ({ id, name }) => ({
      name: id,
      label: name,
      onClick: getOptionClickHandler(
        ViewIds.CAPABILITY_MAP,
        targetRefTypeIds,
        viewState.includeOutgoingReferenceTypes,
        id,
        'includeOutgoingReferenceTypes'
      ),
      type: DropdownOptionType.OPTION,
      isActive:
        showAllOutgoing || viewState.includeOutgoingReferenceTypes.includes(id),
      truncateLabel: true,
    })
  );
  const outgoingAllNone =
    outgoingRefTypes.length > 0
      ? [
          {
            name: RefTypeIds.ALL,
            label: viewSettingsConsts.SHOW_ALL,
            onClick: getOptionClickHandler(
              ViewIds.CAPABILITY_MAP,
              targetRefTypeIds,
              viewState.includeOutgoingReferenceTypes,
              RefTypeIds.ALL,
              'includeOutgoingReferenceTypes'
            ),
            type: DropdownOptionType.OPTION,
            isActive: viewState.includeOutgoingReferenceTypes.includes(
              RefTypeIds.ALL
            ),
          },
          {
            name: RefTypeIds.NONE,
            label: viewSettingsConsts.SHOW_NONE,
            onClick: getOptionClickHandler(
              ViewIds.CAPABILITY_MAP,
              targetRefTypeIds,
              viewState.includeOutgoingReferenceTypes,
              RefTypeIds.NONE,
              'includeOutgoingReferenceTypes'
            ),
            type: DropdownOptionType.OPTION,
            isActive: viewState.includeOutgoingReferenceTypes.length === 0,
          },
        ]
      : [];

  const showAllIncoming = viewState.includeIncomingReferenceTypes.includes(
    RefTypeIds.ALL
  );
  const sourceRefTypeIds = targetReferenceTypes.map(({ id }) => id);
  const incomingRefTypes = targetReferenceTypes.map<DropdownOption>(
    ({ id, name }) => ({
      name: id,
      label: name,
      onClick: getOptionClickHandler(
        ViewIds.CAPABILITY_MAP,
        sourceRefTypeIds,
        viewState.includeIncomingReferenceTypes,
        id,
        'includeIncomingReferenceTypes'
      ),
      type: DropdownOptionType.OPTION,
      isActive:
        showAllIncoming || viewState.includeIncomingReferenceTypes.includes(id),
      truncateLabel: true,
    })
  );
  const incomingAllNone =
    incomingRefTypes.length > 0
      ? [
          {
            name: RefTypeIds.ALL,
            label: viewSettingsConsts.SHOW_ALL,
            onClick: getOptionClickHandler(
              ViewIds.CAPABILITY_MAP,
              targetRefTypeIds,
              viewState.includeIncomingReferenceTypes,
              RefTypeIds.ALL,
              'includeIncomingReferenceTypes'
            ),
            type: DropdownOptionType.OPTION,
            isActive: viewState.includeIncomingReferenceTypes.includes(
              RefTypeIds.ALL
            ),
          },
          {
            name: RefTypeIds.NONE,
            label: viewSettingsConsts.SHOW_NONE,
            onClick: getOptionClickHandler(
              ViewIds.CAPABILITY_MAP,
              targetRefTypeIds,
              viewState.includeIncomingReferenceTypes,
              RefTypeIds.NONE,
              'includeIncomingReferenceTypes'
            ),
            type: DropdownOptionType.OPTION,
            isActive: viewState.includeIncomingReferenceTypes.length === 0,
          },
        ]
      : [];

  return [
    outgoingRefTypes.length > 0 && {
      label: viewSettingsConsts.OUTGOING_REFERENCE_TYPES,
      type: DropdownOptionType.HEADER,
    },
    ...outgoingAllNone,
    ...outgoingRefTypes,
    incomingRefTypes.length > 0 && {
      label: viewSettingsConsts.INCOMING_REFERENCE_TYPES,
      type: DropdownOptionType.HEADER,
    },
    ...incomingAllNone,
    ...incomingRefTypes,
  ].filter(Boolean) as DropdownItem[];
};

export const getLeftMenu = ({
  viewModel,
  viewState,
}: {
  viewModel: ViewStreamShape;
  viewState: ViewSettingsShape;
}): SettingsConfig[] => [
  {
    id: 'capabilityField',
    label: 'Select field to order by',
    iconName: IconName.VIEW_COMPACT,
    options: getOptions(viewState, viewModel.listFields, 'selectedFieldName'),
    type: SettingsType.DROPDOWN,
  },
  {
    id: 'supportingValue',
    label: 'Select vertical column value',
    iconName: IconName.VIEW_ARRAY,
    type: SettingsType.DROPDOWN,
    options: getOptions(viewState, viewModel.listValues, 'supportingValue'),
    isDisabled: viewModel.listValues.length === 0,
  },
  {
    id: 'groupingValue',
    label: 'Select hidden component type',
    iconName: IconName.CONTAINER,
    type: SettingsType.DROPDOWN,
    options: getOptions(
      viewState,
      [
        { name: '', label: 'None' },
        ...viewModel.componentTypes.map(({ componentType: { id, name } }) => ({
          name: id,
          label: name,
        })),
      ],
      'groupingTypeId',
      isGroupingKeyActive(
        new Set(viewModel.componentTypes.map(({ componentType: { id } }) => id))
      )
    ),
    isDisabled: viewModel.componentTypes.length === 0,
  },
  {
    id: 'isLayoutDirectionAnyVertical',
    label: 'Layout of components without field value',
    iconName: IconName.ORIENTATION_VERTICAL,
    isActive: viewState.isLayoutDirectionAnyVertical,
    type: SettingsType.TOGGLE,
    onViewSettingsUpdate,
  },
  {
    id: 'selectReferences',
    label: 'Select reference types to be included',
    iconName: IconName.REFERENCE,
    options: getReferenceTypeOptions(viewModel, viewState),
    type: SettingsType.DROPDOWN,
    isDisabled: false,
    isKeepOpen: true,
  },
  collapseChildrenDropdownTree({
    viewId: ViewIds.CAPABILITY_MAP,
    treeDepth: viewState.treeDepth,
    maxTreeDepth: viewModel.maxTreeDepth,
    onViewSettingsUpdate,
  }),
];
