import { IconName } from '@ardoq/icons';
import { getDropdownDivider } from '../menuItems';
import {
  DropdownOption,
  DropdownOptionType,
  DropdownSubmenu,
} from '@ardoq/dropdown-menu';
import { ArdoqId } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { allComponentsRootLevel } from './helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { showManageSubdivisionMembershipModal } from 'subdivisionEditor/manageMembershipModal/actions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

export const subdivisionsMenu = (
  componentIds: ArdoqId[],
  scenarioId?: ArdoqId | null
): (DropdownOption | DropdownSubmenu)[] => {
  if (scenarioId) return [];
  if (!hasFeature(Features.PERMISSION_ZONES)) return [];

  const canManageSubdivisionMembership =
    currentUserInterface.currentUserIsOrgAdmin();

  const isDisabled =
    !canManageSubdivisionMembership || !allComponentsRootLevel(componentIds);

  return [
    {
      name: 'manage-subdivision-membership',
      label: 'Configure permissions',
      iconName: IconName.SSO,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        dispatchAction(showManageSubdivisionMembershipModal(componentIds));
      },
      isDisabled,
    },
    getDropdownDivider(),
  ].filter(ExcludeFalsy);
};
