import {
  HEADER_CELL_VERTICAL_PADDING,
  MARGIN_PER_LEVEL,
  ROW_HEADER_HIERARCHY_INDENTATION,
} from '../consts';
import { HeaderCellModel } from '../types';
import {
  calculateRowHeaderBottomOffset,
  calculateRowHeaderTopOffset,
  isFirstChild,
  isLastChild,
} from '../util';

const getRowHeaderMargins = (headerCell: HeaderCellModel) => {
  const level = headerCell.indexAddress.length - 1;
  if (level === 0) {
    return null;
  }

  const marginLeft = level * ROW_HEADER_HIERARCHY_INDENTATION;
  const marginRight = MARGIN_PER_LEVEL * level;

  const firstChild = isFirstChild(headerCell);
  const lastChild = isLastChild(headerCell);

  const marginTop = firstChild
    ? calculateRowHeaderTopOffset(headerCell.indexAddress)
    : HEADER_CELL_VERTICAL_PADDING;

  const marginBottom =
    HEADER_CELL_VERTICAL_PADDING +
    (lastChild ? calculateRowHeaderBottomOffset(level) : 0);
  return { marginLeft, marginRight, marginTop, marginBottom };
};

export default getRowHeaderMargins;
