import {
  DefaultLayoutGraph,
  GroupingKeys,
  HierarchicLayout,
  HierarchicLayoutConfig,
  YDimension,
  YNode,
} from '@ardoq/yfiles';
import { BlocksViewNode } from '../types';
import { getDescendantCount, isGroup } from '../viewModel$/util';
import { last, sortedIndexOf } from 'lodash';
import { isOdd } from '../misc/math';
import { logError } from '@ardoq/logging';

const standardHierarchicLayoutOptions: HierarchicLayoutConfig = {
  considerNodeLabels: true,
  integratedEdgeLabeling: true,
  backLoopRouting: false,
  orthogonalRouting: true,
  minimumLayerDistance: 35,
  recursiveGroupLayering: true,
  compactGroups: false,
  componentLayoutEnabled: false,
  fromScratchLayeringStrategy: 'hierarchical-optimal',

  /* my madey uppy stuff */
  // maximumDuration: 10000,
  // gridSpacing: 50,
  // componentLayoutEnabled: true,
  // componentArrangementPolicy: 'topmost',
};

function* leaves(node: BlocksViewNode): Iterable<BlocksViewNode> {
  if (isGroup(node)) {
    for (const child of node.children!) {
      yield* leaves(child);
    }
  } else {
    yield node;
  }
}

function* groups(node: BlocksViewNode): Iterable<BlocksViewNode> {
  if (isGroup(node)) {
    yield node;

    for (const child of node.children!) {
      yield* groups(child);
    }
  }
}

const buildOrdinates = (
  ynodes: Iterable<YNode>,
  minEdge: (node: YNode) => number,
  maxEdge: (node: YNode) => number
) => {
  const epsilon = 0.01;

  /* initialise event queue and set of ordinates */

  const events = [];
  const ordinates = new Set<number>();

  for (const ynode of ynodes) {
    const mn = minEdge(ynode);
    const mx = maxEdge(ynode);

    events.push({ isEnter: true, position: mn });
    events.push({ isEnter: false, position: mx });

    ordinates.add(mn);
    ordinates.add(mx);
  }

  events.sort((a, b) => b.position - a.position);

  /* process event queue, adding missing grid lines to ordinate set */

  let col = 0;
  while (last(events)) {
    const { isEnter, position } = last(events)!;

    if (isEnter && !isOdd(col)) {
      ordinates.add(position - epsilon);
      ++col;
    }

    if (!isEnter && isOdd(col)) {
      ordinates.add(position - epsilon);
      ++col;
    }

    ++col;

    while (last(events)?.position === position) {
      events.pop();
    }
  }

  /* return the ordinate set as a sorted array */

  return Array.from(ordinates).sort((n1, n2) => n1 - n2);
};

export const resetGroupSmart = (group: BlocksViewNode) => {
  const yNodes = new Map<BlocksViewNode, YNode>();
  const nodes = new Map<YNode, BlocksViewNode>();
  const yGraph = new DefaultLayoutGraph();
  const yLayout = new HierarchicLayout(standardHierarchicLayoutOptions);

  function* ychildren(group: BlocksViewNode) {
    for (const child of group.children!) {
      yield yNodes.get(child)!;
    }
  }

  const buildYNodes = (group: BlocksViewNode) => {
    for (const node of leaves(group)) {
      const yNode = yGraph.createNode();

      yGraph.setSize(yNode, new YDimension(100, 100));
      yNodes.set(node, yNode);
      nodes.set(yNode, node);
    }

    for (const node of groups(group)) {
      const cnt = getDescendantCount(group);
      const sz = Math.ceil(Math.sqrt(cnt));

      const yNode = yGraph.createNode();
      yGraph.setSize(yNode, new YDimension(100 * sz, 100 * sz));
      yNodes.set(node, yNode);
      nodes.set(yNode, node);
    }

    const idProvider = yGraph.createNodeMap();
    for (const ynode of yGraph.nodes) {
      const node = nodes.get(ynode);

      if (node) {
        idProvider.set(ynode, node.id);
      }
    }
    yGraph.addDataProvider(GroupingKeys.NODE_ID_DP_KEY, idProvider);

    const parentIdProvider = yGraph.createNodeMap();
    for (const ynode of yGraph.nodes) {
      const node = nodes.get(ynode);

      if (node && node.parent) {
        parentIdProvider.set(ynode, node.parent!.id);
      }
    }
    yGraph.addDataProvider(
      GroupingKeys.PARENT_NODE_ID_DP_KEY,
      parentIdProvider
    );

    const isGroupProvider = yGraph.createNodeMap();
    for (const ynode of yGraph.nodes) {
      const node = nodes.get(ynode);

      if (node && node.children) {
        isGroupProvider.setBoolean(ynode, true);
      }
    }
    yGraph.addDataProvider(GroupingKeys.GROUP_DP_KEY, isGroupProvider);
  };

  const buildYLinks = (node: BlocksViewNode) => {
    if (node.links) {
      for (const link of node.links) {
        const ySource = link.source === node ? yNodes.get(link.source) : null;
        const yTarget = ySource ? yNodes.get(link.target) : null;

        if (ySource && yTarget) {
          yGraph.createEdge(ySource, yTarget);
        }
      }
    }

    if (node.children) {
      for (const child of node.children) {
        buildYLinks(child);
      }
    }
  };

  const extractLayout = (group: BlocksViewNode) => {
    if (!group.children) {
      return;
    }

    for (const child of group.children) {
      extractLayout(child);
    }

    const epsilon = 0.1;
    const leftEdge = (ynode: YNode) =>
      yGraph.getBoundingBox(ynode).toRect().minX;
    const topEdge = (ynode: YNode) =>
      yGraph.getBoundingBox(ynode).toRect().minY;
    const rightEdge = (ynode: YNode) =>
      yGraph.getBoundingBox(ynode).toRect().maxX - epsilon;
    const bottomEdge = (ynode: YNode) =>
      yGraph.getBoundingBox(ynode).toRect().maxY - epsilon;

    const children = [...ychildren(group)];
    const cols = buildOrdinates(children, leftEdge, rightEdge);
    const rows = buildOrdinates(children, topEdge, bottomEdge);

    /* look up node layout in the ordinals */

    for (const child of group.children) {
      const ynode = yNodes.get(child)!;

      child.col = sortedIndexOf(cols, leftEdge(ynode));
      child.row = sortedIndexOf(rows, topEdge(ynode));
      child.colSpan = sortedIndexOf(cols, rightEdge(ynode)) - child.col;
      child.rowSpan = sortedIndexOf(rows, bottomEdge(ynode)) - child.row;

      if (!isOdd(child.col) || !isOdd(child.row)) {
        logError(Error('Unrecoverable parity error in resetGroupSmart.'));
      }

      if (!isOdd(child.colSpan) || !isOdd(child.rowSpan)) {
        logError(Error('Unrecoverable parity error in resetGroupSmart.'));
      }
    }
  };

  if (isGroup(group)) {
    buildYNodes(group);
    buildYLinks(group);
    yLayout.applyLayout(yGraph);
    extractLayout(group);
  }
};
