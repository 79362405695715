import { colors } from '@ardoq/design-tokens';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { rgba } from 'polished';

export const BLOCKS_VIEW_FIT_PADDING = 30;

export const BLOCKS_VIEW_LOWLIGHT = 0.2;

// layout constants

export const BLOCKS_VIEW_NODE_MINIMUM_WIDTH = 158; //  minimum grid row size for all nodes
export const BLOCKS_VIEW_NODE_MINIMUM_HEIGHT = 120; // minimum grid col size for all nodes
export const BLOCKS_VIEW_COLLAPSED_GROUP_MINIMUM_HEIGHT = 152;

export const BLOCKS_VIEW_NODECHANNEL_MINIMUM_SIZE = 16; // minimum grid row and col size for routing cols and rows
export const BLOCKS_VIEW_ROUTINGCHANNEL_MINIMUM_SIZE = 16; // minimum grid row and col size for routing cols and rows
export const BLOCKS_VIEW_ROUTING_MARGIN = 16; // space between non-empty routing channels and nodes
export const BLOCKS_VIEW_ROUTING_SEP = 26; // space between routing lines

export const BLOCKS_VIEW_GOODDROP_STROKE = colors.blue50;
export const BLOCKS_VIEW_GOODDROP_FILL = rgba(
  BLOCKS_VIEW_GOODDROP_STROKE,
  0.01
);
export const BLOCKS_VIEW_BADDROP_STROKE = colors.red60;
export const BLOCKS_VIEW_BADDROP_FILL = rgba(colors.red95, 0.6);
export const BLOCKS_VIEW_BADRESIZE_FILL = rgba(colors.red95, 0.6);

export const BLOCKS_VIEW_NODE_DETAILMAX = 8; // node size (pixels) where node content starts getting elided
export const BLOCKS_VIEW_NODE_DETAILMIN = 3; // node size (pixels) wherenode content is completely elided

export const BLOCKS_VIEW_CONTEXT_COLOR = colors.grey50; // color for context node highlight
export const BLOCKS_VIEW_CONTEXT_WIDTH = 4;

export const BLOCKS_VIEW_NODE_MARGIN = 0; // node margin world units (space between cell and visible bounds)
export const BLOCKS_VIEW_NODE_PAD = 4; // node padding world units (minimum space between visible bounds and content)
export const BLOCKS_VIEW_NODE_SEP = 6; // space between internal components
export const BLOCKS_VIEW_NODE_BORDERRADIUS = 8;

export const BLOCKS_VIEW_MULTILABEL_BORDERCOLOR = colors.blue80;
export const BLOCKS_VIEW_MULTILABEL_COLOR = colors.blue15;
export const BLOCKS_VIEW_MULTILABEL_FONTHEIGHT = 12;
export const BLOCKS_VIEW_MULTILABEL_FONT = `${BLOCKS_VIEW_MULTILABEL_FONTHEIGHT}px ${ARDOQ_DEFAULT_FONT_FAMILY}`;

export const BLOCKS_VIEW_NODE_LABEL_COLOR = 'black';
export const BLOCKS_VIEW_NODE_LABEL_WRAP = 200;
export const BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT = 14;
export const BLOCKS_VIEW_NODE_LABEL_FONT = `500 ${BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT}px/0.375 ${ARDOQ_DEFAULT_FONT_FAMILY}`;
export const BLOCKS_VIEW_NODE_SUBLABEL_FONT = `italic ${BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT}px/20px ${ARDOQ_DEFAULT_FONT_FAMILY}`;

export const BLOCKS_VIEW_GROUP_BORDERCOLOR = '#b7bDc5';
export const BLOCKS_VIEW_GROUP_BORDERWIDTH = 1.0;
export const BLOCKS_VIEW_GROUP_BACKGROUNDCOLOR = colors.grey95;
export const BLOCKS_VIEW_GROUP_BADGE_FILLCOLOR = colors.black; // group expand button fill
export const BLOCKS_VIEW_GROUP_BADGE_COLOR = colors.white; // group badge button stroke
export const BLOCKS_VIEW_GROUP_BADGE_FONT = `16px ${ARDOQ_DEFAULT_FONT_FAMILY}`; // group badge font
export const BLOCKS_VIEW_GROUP_BADGE_SIZE = 24; // minimum  group badge size
export const BLOCKS_VIEW_GROUP_GRID_COLOR = colors.blue80; // editting group grid line color
export const BLOCKS_VIEW_GROUP_MULTILABEL_BACKGROUNDCOLOR = colors.blue95;
export const BLOCKS_VIEW_MULTILABEL_BORDERRADIUS = 4;
export const BLOCKS_VIEW_GROUP_PAD = 6;

export const BLOCKS_VIEW_LEAF_BORDERCOLOR = colors.grey80;
export const BLOCKS_VIEW_LEAF_BORDERWIDTH = 0.75;
export const BLOCKS_VIEW_LEAF_BACKGROUNDCOLOR = 'white';
export const BLOCKS_VIEW_LEAF_RD_OUTERSIZE = 58; // width and height of leaf node "icon"
export const BLOCKS_VIEW_LEAF_RD_INNERSIZE = 50; // width and height of leaf node "icon"
export const BLOCKS_VIEW_LEAF_MULTILABEL_BACKGROUNDCOLOR = colors.blue95;

export const BLOCKS_VIEW_LINK_LINEWIDTH = 0.75; // line nominal line thickness (world units)
export const BLOCKS_VIEW_LINK_LINEMINWIDTH = 1.0; // line minimum line thickness (device units)
export const BLOCKS_VIEW_LINK_MARKER_SIZE = 4; // edge end marker size (world units)
export const BLOCKS_VIEW_LINK_MARKER_DARKEN = 0.15; // how much to darken link color to get marker fill
export const BLOCKS_VIEW_LINK_LABEL_FONT = `16px ${ARDOQ_DEFAULT_FONT_FAMILY}`;
export const BLOCKS_VIEW_LINK_LABEL_HEIGHT = 10;
export const BLOCKS_VIEW_LINK_LABEL_COLOR = colors.black;
export const BLOCKS_VIEW_LINK_LABEL_FILLCOLOR = colors.white;
export const BLOCKS_VIEW_LINK_LABEL_PADDING = 4;
export const BLOCKS_VIEW_LINK_LABEL_MAXLENGTH = 148;
export const BLOCKS_VIEW_HIGHLIGHT_COLOR = rgba(colors.green50, 0.35); // color for highlit nodes and links
export const BLOCKS_VIEW_LINK_CORNER_RADIUS = 10;

export const BLOCKS_VIEW_HANDLE_RENDERSIZE = 6; // editing handle size (pixels)
export const BLOCKS_VIEW_HANDLE_GRABSIZE = 9; // editing handle size (pixels)
export const BLOCKS_VIEW_HANDLE_COLOR = colors.black; // editing handle stroke
export const BLOCKS_VIEW_HANDLE_FILLCOLOR = colors.white; // editing handle fill

export const lineTypeSolid = [];
export const lineTypeDashed = [10, 5];
export const lineTypeDotted = [2];

export const ROOT_NODE_ID = 'root';
