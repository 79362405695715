import { FontAwesomeSvgText, Rect } from '@ardoq/graph';
import {
  ArdoqIcon,
  ArdoqIconCategory,
  fontAwesomeIcons,
  type IsNegativeProps,
  SvgIcon,
} from '@ardoq/icons';

type SvgComponentIconProperties = {
  icon: ArdoqIcon;
  bounds: Rect;
  color: string;
} & IsNegativeProps;
const SvgComponentIcon = ({
  icon: { isSVG, id, category, scale },
  bounds: { left, top, width, height },
  color,
  isNegative,
}: SvgComponentIconProperties) => {
  if (isSVG) {
    return (
      <SvgIcon
        iconId={id}
        left={`${left}`}
        top={`${top}`}
        width={`${width}`}
        height={`${height}`}
        scale={scale}
        color={color}
        isNegative={isNegative}
      />
    );
  }
  if (category === ArdoqIconCategory.FontAwesome) {
    return (
      <FontAwesomeSvgText
        fontSize={height}
        x={left + height / 2}
        y={top + height / 2}
        fill={color}
        stroke="none"
      >
        {fontAwesomeIcons[id]}
      </FontAwesomeSvgText>
    );
  }
};
export default SvgComponentIcon;
