import { RelationshipsNode } from './types';

const buildGroupNodesById = (
  map: Map<string, RelationshipsNode>,
  current: RelationshipsNode
) => {
  if (current.children?.length) {
    map.set(current.id, current);
    current.children?.reduce(buildGroupNodesById, map);
  }

  return map;
};

export const getGroupNodesWithChangedOpenState = (
  currentRootNode: RelationshipsNode | null,
  prevRootNode: RelationshipsNode | null
) => {
  if (!prevRootNode || !currentRootNode) {
    return new Set<RelationshipsNode>();
  }

  const nodesWithChangedOpenState = new Set<RelationshipsNode>();

  const previousNodesById = [prevRootNode].reduce(
    buildGroupNodesById,
    new Map()
  );

  const currentNodesById = [currentRootNode].reduce(
    buildGroupNodesById,
    new Map()
  );

  currentNodesById.forEach((currentNode, id) => {
    const previousNode = previousNodesById.get(id);

    if (previousNode && previousNode.open !== currentNode.open) {
      nodesWithChangedOpenState.add(currentNode);
    }
  });

  return nodesWithChangedOpenState;
};
