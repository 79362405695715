import type {
  AggregatedReferenceType,
  OnViewSettingsUpdate,
} from '@ardoq/data-model';
import { ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { type DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  MenuMetaOptions,
  showOnlyConnectedComponents,
  dropdownTraversalMenu,
  nodeDistanceSlider,
} from '@ardoq/settings-bar';
import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { SimpleTextPopover, popoverRegistry } from '@ardoq/popovers';
import { ALL_EXCEPT_PARENTS } from './consts';
import { CHILDREN, PARENTS, CommonDropdownOptions } from '@ardoq/global-consts';
import {
  RELATIONSHIPS_2_ANIMATION_THRESHOLD,
  type SetNumeric,
  type RelationshipsViewSettings,
} from '@ardoq/graph';
import { onViewSettingsUpdate as ardoqFrontOnViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { filterInterface } from 'modelInterface/filters/filterInterface';

type TraversalMenuArgs = Parameters<typeof dropdownTraversalMenu>[0];

const ANIMATION_DISABLED_POPOVER = 'ANIMATION_DISABLED';
popoverRegistry.set(ANIMATION_DISABLED_POPOVER, () => (
  <SimpleTextPopover
    text={`Animation has been disabled due to graph size over ${RELATIONSHIPS_2_ANIMATION_THRESHOLD} components and references.`}
  />
));

interface GetLeftMenuArgs {
  viewSettings: RelationshipsViewSettings;
  traversedOutgoingReferenceTypes: AggregatedReferenceType[];
  traversedIncomingReferenceTypes: AggregatedReferenceType[];
  referenceTypes: AggregatedReferenceType[];
  canAnimate: boolean;
  onNodeDistanceUpdated: SetNumeric;
  onViewSettingsUpdate: OnViewSettingsUpdate;
  viewId: ViewIds;
}

type TraverseParentsAndChildrenOnClickHandlerArgs = {
  name: string;
  isActive: boolean;
  viewId: ViewIds;
  onViewSettingsUpdate: OnViewSettingsUpdate;
};
const traverseParentsAndChildrenOnClickHandler =
  ({
    name,
    isActive,
    viewId,
    onViewSettingsUpdate,
  }: TraverseParentsAndChildrenOnClickHandlerArgs) =>
  () =>
    onViewSettingsUpdate(
      viewId,
      {
        [name]: isActive ? [ALL_EXCEPT_PARENTS] : [CommonDropdownOptions.ALL],
      },
      true
    );
const TRAVERSE_PARENTS_OPTION_NAME = 'includeIncomingReferenceTypeNames';
const TRAVERSE_CHILDREN_OPTION_NAME = 'includeOutgoingReferenceTypeNames';

type TraverseParentsAndChildrenItemsArgs = Pick<
  TraversalMenuArgs,
  'viewSettings' | 'onViewSettingsUpdate' | 'viewId'
>;
/** gets two menu items which toggle "Traverse Parents" and "Traverse Children" */
const traverseParentsAndChildrenItems = ({
  viewSettings: {
    includeIncomingReferenceTypeNames,
    includeOutgoingReferenceTypeNames,
  },
  onViewSettingsUpdate,
  viewId,
}: TraverseParentsAndChildrenItemsArgs): [
  traverseParentsItem: DropdownOption,
  traverseChildrenItem: DropdownOption,
] => {
  const [isTraverseParentsSelected, isTraverseChildrenSelected] = [
    includeIncomingReferenceTypeNames,
    includeOutgoingReferenceTypeNames,
  ].map(referenceTypeNames =>
    referenceTypeNames.includes(CommonDropdownOptions.ALL)
  );

  const traverseParentsItem: DropdownOption = {
    name: TRAVERSE_PARENTS_OPTION_NAME,
    type: DropdownOptionType.OPTION,
    label: PARENTS,
    isActive: isTraverseParentsSelected,
    testId: 'rv-traverse-parents-option',
    onClick: traverseParentsAndChildrenOnClickHandler({
      name: TRAVERSE_PARENTS_OPTION_NAME,
      isActive: isTraverseParentsSelected,
      viewId,
      onViewSettingsUpdate,
    }),
  };
  const traverseChildrenItem: DropdownOption = {
    name: TRAVERSE_CHILDREN_OPTION_NAME,
    type: DropdownOptionType.OPTION,
    label: CHILDREN,
    isActive: isTraverseChildrenSelected,
    testId: 'rv-traverse-children-option',
    onClick: traverseParentsAndChildrenOnClickHandler({
      name: TRAVERSE_CHILDREN_OPTION_NAME,
      isActive: isTraverseChildrenSelected,
      viewId,
      onViewSettingsUpdate,
    }),
  };
  return [traverseParentsItem, traverseChildrenItem];
};

type AnimateToggleArgs = Pick<
  GetLeftMenuArgs,
  'canAnimate' | 'onViewSettingsUpdate'
> & {
  animate: boolean;
};
const animateToggle = ({
  animate,
  canAnimate,
  onViewSettingsUpdate,
}: AnimateToggleArgs) => ({
  id: 'animate',
  label: !canAnimate
    ? undefined // if animation is disabled, the label is left blank, and a popover is set via the popoverId property.
    : animate
      ? 'Stop Animation'
      : 'Animate',
  iconName:
    !animate || !canAnimate
      ? IconName.ANIMATION_PLAY
      : IconName.ANIMATION_PAUSE,
  currentValue: animate,
  isActive: false,
  isDisabled: !canAnimate,
  popoverId: canAnimate ? undefined : ANIMATION_DISABLED_POPOVER,
  type: SettingsType.TOGGLE,
  onViewSettingsUpdate,
});

export const relationshipsTraversalMenu = ({
  viewSettings,
  traversedOutgoingReferenceTypes,
  traversedIncomingReferenceTypes,
  referenceTypes,
  viewId,
}: Omit<
  TraversalMenuArgs,
  'maximumDepth' | 'options' | 'onViewSettingsUpdate'
>) => {
  const baseTraversalMenu = dropdownTraversalMenu({
    viewSettings,
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
    viewId,
    maximumDepth: 99,
    options: {
      degreesOfSingleDirection: false,
      selectReferenceTypes: false,
      degreesOfBothDirections: true,
      selectParentChildReferenceTypes: false,
      metaOptions: MenuMetaOptions.ALL_AND_NONE,
    },
    onViewSettingsUpdate: ardoqFrontOnViewSettingsUpdate,
    quickPerspectiveReferenceTypeFilters:
      filterInterface.getActiveQuickReferenceTypeFilters(),
  });
  return {
    ...baseTraversalMenu,
    options: [
      ...baseTraversalMenu.options,
      ...traverseParentsAndChildrenItems({
        viewSettings,
        onViewSettingsUpdate: ardoqFrontOnViewSettingsUpdate,
        viewId,
      }),
    ],
  };
};
export const getLeftMenu = ({
  viewSettings,
  traversedOutgoingReferenceTypes,
  traversedIncomingReferenceTypes,
  referenceTypes,
  canAnimate,
  onNodeDistanceUpdated,
  onViewSettingsUpdate,
  viewId,
}: GetLeftMenuArgs): SettingsConfig[] => {
  return [
    relationshipsTraversalMenu({
      viewSettings,
      traversedOutgoingReferenceTypes,
      traversedIncomingReferenceTypes,
      referenceTypes,
      viewId,
    }),
    showOnlyConnectedComponents(
      viewSettings.showOnlyConnectedComponents,
      onViewSettingsUpdate
    ),
    nodeDistanceSlider({
      currentValue: viewSettings.nodeDistance,
      onBeforeUpdate: onNodeDistanceUpdated,
      viewId,
      onViewSettingsUpdate,
    }),
    animateToggle({
      animate: viewSettings.animate,
      canAnimate,
      onViewSettingsUpdate,
    }),
  ];
};
