import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  setActiveMigration,
  setPresentationMetadata,
  setReportDatasourceMigrationData,
} from './actions';
import { MigrationChecklistState } from './types';
import { REPORT_DATASOURCE_MIGRATION_ID } from './ReportDatasourceChecklistContent';

const handleSetPresentationMetadata = (
  state: MigrationChecklistState,
  presentationMetadata: MigrationChecklistState['presentationMetadata']
) => ({
  ...state,
  presentationMetadata,
});

const handleSetActiveMigration = (
  state: MigrationChecklistState,
  activeMigrationId: MigrationChecklistState['activeMigrationId']
) => ({
  ...state,
  activeMigrationId,
});

const handleSetReportDatasourceMigrationData = (
  state: MigrationChecklistState,
  reportDatasourceMigrationData: MigrationChecklistState['reportDatasourceMigrationData']
) => ({
  ...state,
  reportDatasourceMigrationData,
});

const defaultState: MigrationChecklistState = {
  activeMigrationId: REPORT_DATASOURCE_MIGRATION_ID,
  presentationMetadata: {},
  reportDatasourceMigrationData: null,
};

const migrationChecklist$ = persistentReducedStream(
  'migrationChecklist$',
  defaultState,
  [
    reducer(setPresentationMetadata, handleSetPresentationMetadata),
    reducer(setActiveMigration, handleSetActiveMigration),
    reducer(
      setReportDatasourceMigrationData,
      handleSetReportDatasourceMigrationData
    ),
  ]
);

export default migrationChecklist$;
