import {
  dispatchAction,
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tagComponents } from 'streams/tags/TagActions';
import { beforeDragEnd } from 'navigator2024/handlers/dragndrop';
import { filter, tap } from 'rxjs/operators';
import { tagInterface } from '@ardoq/tag-interface';
import { hasComponentsFromOtherWorkspace } from './utils';

const handleDragEnded = routine(
  ofType(beforeDragEnd),
  extractPayload(),
  filter(({ dropContext }) => dropContext === 'tagscape'),
  tap(({ targetDropId, ids }) => {
    const tagData = targetDropId && tagInterface.getTagData(targetDropId);
    const tagWorkspaceId = tagData && tagData.rootWorkspace;
    const shouldTag = Boolean(
      tagWorkspaceId && !hasComponentsFromOtherWorkspace(tagWorkspaceId, ids)
    );

    if (shouldTag) {
      dispatchAction(tagComponents({ tagId: targetDropId, componentIds: ids }));
    }
  })
);

export const tagscapeRoutines = collectRoutines(handleDragEnded);
