import styled from 'styled-components';

export const ChartWrapper = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: auto;
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
