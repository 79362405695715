import styled from 'styled-components';
import { ArdoqId } from '@ardoq/api-types';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { isPresentationMode } from 'appConfig';
import { verticalSpacing } from 'tabview/pagesView/constants';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { dispatchAction } from '@ardoq/rxbeach';
import { showRightPane } from 'appContainer/actions';

const EditButton = styled.span`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  cursor: pointer;
  margin: ${verticalSpacing.SMALL} 9px 0 auto;
  height: 20px;
  text-transform: uppercase;
  @media print {
    display: none;
  }
`;

const getTriggerEditDescription =
  (modelId: ArdoqId, modelIsComponent: boolean) => () => {
    dispatchAction(
      showRightPane(
        modelIsComponent
          ? {
              type: GetContentOptionsType.COMPONENT_PROPERTIES,
              componentIds: [modelId],
            }
          : {
              type: GetContentOptionsType.WORKSPACE_PROPERTIES,
              workspaceId: modelId,
            }
      )
    );
  };

const EditDescriptionButton = ({
  modelId,
  descriptionIsEmpty,
  canEditDescription,
}: {
  modelId: ArdoqId;
  descriptionIsEmpty: boolean;
  canEditDescription: boolean;
}) => {
  const modelIsComponent = componentInterface.isComponent(modelId);
  const modelString = modelIsComponent ? 'component' : 'workspace';
  const actionString = descriptionIsEmpty ? 'Add' : 'Edit';

  return canEditDescription && !isPresentationMode() ? (
    <EditButton
      data-click-id={`edit-${modelString}-description-button`}
      data-tooltip-text={`${actionString} ${modelString} description`}
      onClick={getTriggerEditDescription(modelId, modelIsComponent)}
    >
      {actionString}
    </EditButton>
  ) : null;
};

export default EditDescriptionButton;
