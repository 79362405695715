import { HierarchicLayoutConfig, INode, LayoutDescriptor } from '@ardoq/yfiles';
import { OnLayoutGraphArgs } from 'tabview/graphComponent/onLayoutGraph';
import {
  CollapsibleGraphGroup,
  GraphItem,
  Rect,
  inside,
  zoomToNodeFromCurrentContentRect,
} from '@ardoq/graph';

const isHierarchicLayout = (
  layoutDescriptor: LayoutDescriptor
): layoutDescriptor is LayoutDescriptor & {
  properties: HierarchicLayoutConfig;
} => layoutDescriptor.name === 'HierarchicLayout';

const isExpandedGroup = (
  dataItem: GraphItem | null
): dataItem is CollapsibleGraphGroup =>
  dataItem instanceof CollapsibleGraphGroup && !dataItem.collapsed;

const onLayoutGraph =
  (expandedStateChangingGroupId: string | null) =>
  ({ graphComponent, layoutDescriptor }: OnLayoutGraphArgs) => {
    const { contentRect, viewport, graph } = graphComponent;
    if (viewport.height === 0 || viewport.width === 0) {
      // viewport has no size, which probably means the view was closed.
      return;
    }

    for (const label of graph.labels) {
      const node = label.owner;
      const dataItem: GraphItem | null = node?.tag;
      if (!INode.isInstance(node) || !isExpandedGroup(dataItem)) {
        continue;
      }
    }

    if (!isHierarchicLayout(layoutDescriptor)) {
      return;
    }

    const isFreshLayout =
      layoutDescriptor.properties?.layoutMode === 'from-scratch';

    if (isFreshLayout) {
      graphComponent.fitContent();
      return;
    }
    const isFullGraphDisplayed = inside(
      Rect.fromRectLike(contentRect),
      Rect.fromRectLike(viewport)
    );
    if (isFullGraphDisplayed) {
      return;
    }
    if (expandedStateChangingGroupId) {
      const zoomToGroupNode = graph.nodes.find(
        node =>
          node.tag instanceof CollapsibleGraphGroup &&
          node.tag.id === expandedStateChangingGroupId
      );
      if (zoomToGroupNode) {
        zoomToNodeFromCurrentContentRect(zoomToGroupNode, graphComponent);
      }
    }
  };
export default onLayoutGraph;
