import { FreeNodeLabelModel, Point } from '@ardoq/yfiles';
import { URL_ICON_MARGIN } from 'yfilesExtensions/styles/consts';
/**
 * The label parameter specifying the location of URL icons in a leaf node or collapsed component group.
 * @description Such labels are placed near the top-right corner of the node's layout.
 */
export const urlNodeButtonModelParameter =
  FreeNodeLabelModel.INSTANCE.createParameter({
    layoutRatio: new Point(1, 0),
    layoutOffset: new Point(URL_ICON_MARGIN, 0),
    labelRatio: new Point(0, 0.5),
  });
