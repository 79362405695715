import { PermissionContext } from '@ardoq/access-control';

export type Layout = {
  // This is a simplified type to match the type with the same name from the `react-grid-layout` library. Didn't want to add it as a dependency just for the type.
  x: number;
  y: number;
  h: number;
  w: number;
  i: string;
};

export enum LoadingState {
  LOADING = 1,
  SAVING,
  LOADING_ERROR,
  SAVING_ERROR,
  FETCH_DATA_SOURCES_ERROR,
}

export type CanEditDashboardGuardProps = {
  children: React.ReactNode;
  dashboardId?: string;
  permissionContext: PermissionContext;
};

export const SURVEY_VALID_INVALID_FIELD_NAME = 'ardoq.valid';
