import { LinkedComponent } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import { COMPONENT_HIERARCHY_PADDING } from '../consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { zip } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SelectedReferenceTypeInfo } from '@ardoq/settings-bar';

const WORKSPACE_ID_PREFIX = '?wsId=';
export const extractReferenceTypeId = (value: string) =>
  value.slice(0, value.indexOf(WORKSPACE_ID_PREFIX));
export const extractWorkspaceId = (value: string) =>
  value.slice(value.indexOf(WORKSPACE_ID_PREFIX) + WORKSPACE_ID_PREFIX.length);
export const formatReferenceType = (referenceType: SelectedReferenceTypeInfo) =>
  `${referenceType.referenceWorkspaceId}-${referenceType.referenceTypeId}${WORKSPACE_ID_PREFIX}${referenceType.linkedWorkspaceId}`;

const getComponentHierarchyAddress = (
  componentId: string | undefined
): (string | null)[] => {
  if (componentId === undefined) {
    return [null];
  }
  const result = [componentId];
  let parentId = componentInterface.getParentId(componentId);

  while (parentId) {
    result.unshift(parentId);
    parentId = componentInterface.getParentId(parentId);
  }
  return result;
};

const getLinkedComponents = (
  componentId: ArdoqId,
  referenceTypeId: string,
  workspaceId: string | undefined,
  getSourcesOrTargets: (componentId: ArdoqId) => LinkedComponent[]
) =>
  getSourcesOrTargets(componentId)
    .map(linkedComponent => {
      if (
        componentInterface.getWorkspaceId(linkedComponent.componentId) !==
        workspaceId
      ) {
        return null;
      }
      const linkedComponentReferenceTypeId = referenceInterface.getGlobalTypeId(
        linkedComponent.referenceId
      );
      return linkedComponentReferenceTypeId === referenceTypeId
        ? {
            componentId: linkedComponent.componentId,
            referenceTypeId: linkedComponentReferenceTypeId,
          }
        : null;
    })
    .filter(ExcludeFalsy);

const getReferenceAddressWithComponentHierarchy = (
  referencedComponentId: string | undefined,
  referenceTypeId: string | undefined,
  componentHierarchyMaxDepth: number = Number.NEGATIVE_INFINITY
) => {
  if (!referenceTypeId) {
    return [];
  }
  const componentHierarchyAddress = getComponentHierarchyAddress(
    referencedComponentId
  );
  return componentHierarchyAddress.length >= componentHierarchyMaxDepth
    ? componentHierarchyAddress
    : componentHierarchyAddress.concat(
        Array(
          componentHierarchyMaxDepth - componentHierarchyAddress.length
        ).fill(COMPONENT_HIERARCHY_PADDING)
      );
};

export const getLinkedComponentsAddresses = (
  componentId: string,
  referenceTypeId: string,
  workspaceId: string,
  getSourcesOrTargets: (componentId: ArdoqId) => LinkedComponent[],
  includeComponentHierarchy = true,
  componentHierarchyMaxDepth?: number
) => {
  const linkedComponents = getLinkedComponents(
    componentId,
    referenceTypeId,
    workspaceId,
    getSourcesOrTargets
  );

  const actualResult = includeComponentHierarchy
    ? linkedComponents.length
      ? linkedComponents.map(linkedComponent =>
          getReferenceAddressWithComponentHierarchy(
            linkedComponent.componentId,
            linkedComponent.referenceTypeId,
            componentHierarchyMaxDepth
          )
        )
      : [
          [null].concat(
            Array((componentHierarchyMaxDepth || 1) - 1).fill(
              COMPONENT_HIERARCHY_PADDING
            )
          ),
        ]
    : linkedComponents.length
      ? (zip(
          linkedComponents.map(linkedComponent => linkedComponent.componentId)
        ) as (string | null)[][])
      : [[null]];
  return actualResult;
};
export const referenceTypeIdMaxHierarchyDepth = (
  startSet: ArdoqId[],
  referenceTypeId: string,
  workspaceId: string,
  getSourcesOrTargets: (componentId: ArdoqId) => LinkedComponent[]
) =>
  startSet.length === 0
    ? 0
    : Math.max(
        ...startSet
          .flatMap(componentId =>
            getLinkedComponentsAddresses(
              componentId,
              referenceTypeId,
              workspaceId,
              getSourcesOrTargets
            )
          )
          .map(address => address.length)
      );
