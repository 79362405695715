import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { ViewIds } from '@ardoq/api-types';
import { getViewModel$ } from '../viewModel$/viewModel$';
import {
  DependencyMatrixViewSettings,
  DependencyMatrixViewModel,
} from '../types';
import { DependencyMatrix } from './DependencyMatrix';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { CONTENT_PANE_CLASSNAME } from '../consts';
import getLeftMenuConfig from '../getLeftMenuConfig';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { onViewSettingsUpdate } from '../../onViewSettingsUpdate';
import getExportSettings from '../getExportSettings';
import { SettingsBar, settingsBarConsts } from '@ardoq/settings-bar';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend,
} from '@ardoq/view-legend';
import { ViewLegendContainer } from '@ardoq/graph';
import { ContextMenuWrapper, ExportContainer } from '../atoms';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { isPresentationMode } from 'appConfig';
import EmptyState from './EmptyState';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { noop } from 'lodash';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';

const VIEW_ID = ViewIds.DEPMATRIX;

const viewState$ = getViewSettingsStream<DependencyMatrixViewSettings>(VIEW_ID);

const DependencyMatrixView = (props: DependencyMatrixViewModel) => {
  const {
    viewSettings,
    workspaceId,
    workspacesIds,
    componentId: selectedComponentId,
    focusedComponentId,
    sourceData,
    targetData,
    references,
    legendReferenceTypes,
    noConnectedComponents,
    errors,
    permissionContext,
  } = props;

  const [isExporting, setIsExporting] = useState(false);

  const exportContainer = useRef<HTMLDivElement | null>(null);

  const [clearedErrors, setClearedErrors] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    VIEW_ID,
    NEVER_SHOW_AGAIN
  );

  const { exportToPng, addToPresentation } = useMemo(
    () => getExportSettings(VIEW_ID, exportContainer),
    []
  );
  const startExporting = useCallback(() => setIsExporting(true), []);
  const exportTableToPng = useCallback(async () => {
    try {
      await exportToPng();
    } finally {
      setIsExporting(false);
    }
  }, [exportToPng]);

  // Exporting a table to a PNG image after the table has rendered all the data
  useEffect(() => {
    if (isExporting) {
      exportTableToPng();
    }
  }, [isExporting, exportTableToPng]);

  const heightOffset = useViewLegendSubscription();

  const hasDataToShow =
    sourceData.componentIds.length > 0 && targetData.componentIds.length > 0;

  const hasNoWriteAccess = !workspaceAccessControlInterface.canEditWorkspace(
    permissionContext,
    workspaceId,
    getActiveScenarioState()
  );

  const isEmptyView = !hasDataToShow || noConnectedComponents;

  const presentationMode = isPresentationMode();

  return (
    <ContextMenuWrapper data-context-menu={OBJECT_CONTEXT_MENU_NAME}>
      {!presentationMode && (
        <SettingsBar
          viewId={VIEW_ID}
          leftMenu={getLeftMenuConfig({
            viewId: VIEW_ID,
            viewState: viewSettings,
            workspaceId,
            workspacesIds,
          })}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            viewstate: viewSettings,
            onViewSettingsUpdate,
            knowledgeBaseLink: KnowledgeBaseLink.DEPMATRIX,
            exports: {
              exportToPng: startExporting,
              addToPresentation,
              isDisabled: isEmptyView,
            },
          })}
        />
      )}

      {isEmptyView ? (
        <EmptyState noConnectedComponents={noConnectedComponents} />
      ) : (
        <ExportContainer
          $isExporting={isExporting}
          className={CONTENT_PANE_CLASSNAME}
          ref={exportContainer}
        >
          <ErrorInfoBox
            errors={clearedErrors ? [] : errors}
            hasClones={false}
            clearHasClones={noop}
            isShowNeverAgainSet={neverShowAgain}
            isPresentationMode={presentationMode}
            clearErrors={() => setClearedErrors(true)}
            toggleNeverShowAgain={toggleNeverShowAgain}
          />
          <DependencyMatrix
            readOnly={hasNoWriteAccess || presentationMode}
            references={references}
            sourceData={sourceData}
            targetData={targetData}
            viewSettings={viewSettings}
            isExporting={isExporting}
            workspaceId={workspaceId}
            selectedComponentId={selectedComponentId}
            focusedComponentId={focusedComponentId}
          />
          <ViewLegendContainer
            heightOffset={heightOffset}
            visible={viewSettings[settingsBarConsts.IS_LEGEND_ACTIVE]}
            style={isExporting ? { position: 'static' } : {}}
          >
            <ViewLegend
              activeDiffMode={null}
              componentTypes={getComponentTypesForLegend(
                sourceData.componentIds
              )}
              referenceTypes={legendReferenceTypes}
              activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
            />
          </ViewLegendContainer>
        </ExportContainer>
      )}
    </ContextMenuWrapper>
  );
};

export default connect(DependencyMatrixView, getViewModel$(viewState$));
