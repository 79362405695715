import { ViewIds } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ViewLegendContainer } from '@ardoq/graph';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend,
} from '@ardoq/view-legend';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { ComponentMatrixItem } from './types';

type ComponentMatrixViewLegendProps = {
  isLegendActive: boolean;
  componentMatrixItems: ComponentMatrixItem[];
};

type ComponentMatrixViewLegendViewSettings = {
  isLegendActive: boolean;
};

const ComponentMatrixViewLegend = ({
  isLegendActive,
  componentMatrixItems,
}: ComponentMatrixViewLegendProps) => {
  const activeDiffMode = getActiveDiffMode();
  const isScopeDiffMode = isInScopeDiffMode();
  const heightOffset = useViewLegendSubscription();
  return (
    <ViewLegendContainer heightOffset={heightOffset} visible={isLegendActive}>
      <ViewLegend
        activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
        componentTypes={getComponentTypesForLegend(
          componentMatrixItems.flatMap(item => [
            item.componentId,
            ...item.address.columnValues.filter(ExcludeFalsy),
            ...item.address.rowValues.filter(ExcludeFalsy),
          ])
        )}
        activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
      />
    </ViewLegendContainer>
  );
};

const viewSettings$ =
  getViewSettingsStream<ComponentMatrixViewLegendViewSettings>(
    ViewIds.COMPONENT_MATRIX
  );

export default connect(
  ComponentMatrixViewLegend,
  viewSettings$.pipe(
    map(viewSettings => ({ isLegendActive: viewSettings.isLegendActive }))
  )
);
