import { ProteanLayoutType } from 'tabview/proteanDiagram/types';

export const LAYOUT_TYPE_LABELS: Record<ProteanLayoutType, string> = {
  [ProteanLayoutType.Circular]: 'Circular',
  [ProteanLayoutType.Hierarchic]: 'Hierarchic',
  [ProteanLayoutType.Organic]: 'Organic',
  [ProteanLayoutType.Orthogonal]: 'Orthogonal',
  [ProteanLayoutType.Radial]: 'Radial',
  [ProteanLayoutType.CompactDisk]: 'Compact Disk',
  [ProteanLayoutType.Swimlanes]: 'Swimlanes',
  [ProteanLayoutType.SpatialMap]: 'Spatial Map',
  [ProteanLayoutType.GeographicMap]: 'Geographic Map',
  [ProteanLayoutType.Cactus]: 'Cactus',
  [ProteanLayoutType.Tree]: 'Tree',
  [ProteanLayoutType.TreeMap]: 'TreeMap',
  [ProteanLayoutType.HierarchicInGrid]: 'Hierarchic in Grid',
  [ProteanLayoutType.Tabular]: 'Tabular',
};

export const LAYOUT_TYPE_MENU_ID = 'layoutType';
export const INPUT_MODE_MENU_ID = 'inputMode';
export const EDIT_MODE_MENU_ID = 'editMode';
export const LAYOUT_RESET_MENU_ID = 'reset-layout';
