import { clamp } from 'lodash';
import { interp } from './math';

const ellipsis = '…';

export const truncateString = (
  w: number,
  label: string,
  measureText: (text: string) => number
) => {
  let width = w;
  let bWidth = measureText(label);
  let text = label;

  if (bWidth > width) {
    const ellipsisWidth = measureText(ellipsis);

    if (width < ellipsisWidth) {
      text = '';
    } else {
      let a = 0;
      let aWidth = 0;
      let b = label.length - 1;

      width -= ellipsisWidth;
      while (b - a > 1) {
        const p = (width - aWidth) / (bWidth - aWidth);
        const c = clamp(Math.round(interp(p, a, b)), a + 1, b - 1);

        text = label.substring(0, c);
        const textWidth = measureText(text);

        if (textWidth > width) {
          b = c;
          bWidth = textWidth;
        } else {
          a = c;
          aWidth = textWidth;
        }
      }

      text = text + ellipsis;
    }
  }

  return text;
};

// this is the worstest possible version of wrap string
// please fix and optimise it
// there could *and probably should* be a limit on the number of lines
// this thing outputs, although it should be noted that if the output
// is truncated then ellipses would be required.
//
// note also that wrapString may sometimes be called just to find the line
// count so actually creating the line content may be a waste of time
//
/** a RegExp that matches whitespace, tab, and newline. */
const ws = new RegExp('[ \\t\\n]+'); // should include all whitespace characters as defined by Ardoq

// or possibly change "measure" to work thusly
// measure(str, a, b) returns the length of the substring

export const wrapString = (
  str: string,
  width: number,
  measure: (str: string) => number,
  maxLines: number = 3
) => {
  if (maxLines <= 0) {
    return [];
  }

  const tokens = str.split(ws, 5000);
  const output = [];

  const wrapWord = (word: string) => {
    if (measure(word) > width) {
      // str contains no line breaks but must be fit to width
      // this implementation can break anywhere, although in
      // practice it should not break in all sorts of clever
      // circumstances such as after a currency symbol

      let a = 0;

      while (a < word.length && output.length < maxLines) {
        let b = word.length;

        while (b > a && measure(word.slice(a, b)) > width) {
          b = b - 1;
        }

        if (b === a) {
          return null;
        }

        if (b === word.length) {
          break;
        }

        output.push(word.slice(a, b));
        a = b;
      }

      return word.slice(a);
    }

    return word;
  };

  for (let i = 0; i < tokens.length && output.length < maxLines; ) {
    let line = wrapWord(tokens[i++]);

    if (!line) {
      return [];
    }

    if (output.length < maxLines) {
      while (
        i < tokens.length &&
        measure(line.concat(' ', tokens[i])) < width
      ) {
        line = line.concat(' ', tokens[i++]);
      }

      output.push(line);
    }
  }

  return output;
};
