import { ComponentType, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { action$, extractPayload, ofType, useStream } from '@ardoq/rxbeach';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { notifyViewLoading } from './actions';
import { logError } from '@ardoq/logging';
import type { HasViewInstanceId } from '@ardoq/graph';
import {
  ArdoqLoaderComponent,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { globalPaneLoader$ } from 'components/GlobalPaneLoader/globalPaneLoader$';
import { contextPresentation$ } from 'streams/presentations/contextPresentation$';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > .content {
    height: 100%;
    width: 100%;
  }
`;
const LoaderOverlay = styled.div<{ $isFromPresentation: boolean }>`
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.$isFromPresentation
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
        `
      : css`
          position: absolute;
          background: rgba(255, 255, 255, 0.5);
        `}
`;
interface WithProgressIndicatorProperties<P extends HasViewInstanceId> {
  WrappedComponent: ComponentType<P>;
  wrappedProps: P;
  showContentWhileLoading: boolean;
}

const WithLoadingIndicator = <WrappedProps extends HasViewInstanceId>({
  WrappedComponent,
  wrappedProps,
  showContentWhileLoading,
}: WithProgressIndicatorProperties<WrappedProps>) => {
  const presentation = useStream(contextPresentation$);
  const globalPaneLoader = useStream(globalPaneLoader$);
  const isFromPresentation = Boolean(
    typeof presentation !== 'symbol' && presentation
  );
  const isGlobalPaneLoaderDisplayed =
    typeof globalPaneLoader !== 'symbol' ? globalPaneLoader.isLoading : true;

  const [subscription] = useState(() =>
    action$
      .pipe(
        ofType(notifyViewLoading),
        extractPayload(),
        filter(
          ({ viewInstanceId }) => viewInstanceId === wrappedProps.viewInstanceId
        ),
        map(({ isBusy }) => isBusy),
        distinctUntilChanged()
      )
      .subscribe(isBusy => {
        if (!loadingContainerRef.current) {
          return;
        }
        if (isFromPresentation) {
          loadingContainerRef.current.style.transitionDuration = '0s';
        } else {
          loadingContainerRef.current.style.transitionDuration = isBusy // show slowly, hide quickly
            ? '2s'
            : '20ms';
        }

        loadingContainerRef.current.style.opacity =
          isBusy && !isGlobalPaneLoaderDisplayed ? '1' : '0';

        if (!containerRef.current) {
          return;
        }
        containerRef.current.style.visibility =
          !showContentWhileLoading && isBusy && !isGlobalPaneLoaderDisplayed
            ? 'hidden'
            : '';
      })
  );
  useEffect(() => () => subscription.unsubscribe(), [subscription]);
  useEffect(() => {
    if (!containerRef.current) {
      logError(Error('Progress indicator failed to find rendered container.'));
      return;
    }
    if (!showContentWhileLoading) {
      containerRef.current.style.visibility = 'hidden';
    }
  }, [showContentWhileLoading]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const loadingContainerRef = useRef<HTMLDivElement | null>(null);
  return (
    <Container>
      <LoaderOverlay
        ref={loadingContainerRef}
        $isFromPresentation={Boolean(isFromPresentation)}
      >
        <ArdoqLoaderComponent
          size={LoaderSize.LARGE}
          loaderColor={LoaderColor.BRAND}
          loaderText="Loading data..."
        />
      </LoaderOverlay>
      <div className="content" ref={containerRef}>
        <WrappedComponent {...wrappedProps} />
      </div>
    </Container>
  );
};
export default WithLoadingIndicator;
