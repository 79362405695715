import { DoqType } from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import cantManageUsers from 'components/AppMainSidebar/CantManageUsers';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

interface NoWorkspacePermissionDialogBodyProps {
  workspaceName: string | null;
}

const NoWorkspacePermissionDialogBody = (
  props: NoWorkspacePermissionDialogBodyProps
) => {
  return (
    <>
      <div>Workspace: {props.workspaceName}</div>
      <div>
        <Link typography="text2" hideIcon onClick={() => cantManageUsers()}>
          Contact your admin
        </Link>{' '}
        to get Write access.
      </div>
    </>
  );
};

export const noWorkspacePermissionDialogConfig = (
  workspaceId: string | null
) => {
  const workspaceName = workspaceId
    ? workspaceInterface.getWorkspaceName(workspaceId)
    : '';
  return {
    title: 'You don’t have workspace permission',
    subtitle:
      'You don’t have Write access to the workspace this component belongs to.',
    text: <NoWorkspacePermissionDialogBody workspaceName={workspaceName} />,
    doqType: DoqType.LOCKED,
  };
};
