import {
  ILabel,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { colors } from '@ardoq/design-tokens';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
} from 'yfilesExtensions/styles/consts';
import { getLabelPreferredSize } from 'yfilesExtensions/styles/measureLabels';
import { createSvgElement } from '@ardoq/dom-utils';

const MINIMUM_VISIBLE_FONTSIZE = 6;

class ProteanEdgeLabelStyle extends LabelStyleBase {
  protected override createVisual(
    context: IRenderContext,
    label: ILabel
  ): Visual | null {
    const { layout, text } = label;
    if (!text) {
      return null;
    }
    const zoom = context.canvasComponent?.zoom ?? 0;
    const font = BLOCK_DIAGRAM_EDGE_LABEL_FONT;
    if (font.fontSize * zoom < MINIMUM_VISIBLE_FONTSIZE) {
      return null;
    }
    const { width, height } = layout;
    const backgroundRect = createSvgElement('rect', {
      fill: colors.white,
      width: `${width}`,
      height: `${height}`,
    });
    const textElement = createSvgElement('text', {
      'font-size': `${font.fontSize}px`,
      transform: `translate(${LABEL_HORIZONTAL_PADDING / 2} ${
        LABEL_VERTICAL_PADDING / 2
      })`,
      fill: colors.black,
    });
    font.applyTo(textElement);
    addText({
      targetElement: textElement,
      text,
      font,
      ...BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
    });
    const container = createSvgElement('g');
    const layoutTransform = LabelStyleBase.createLayoutTransform(
      context,
      layout,
      false
    );
    layoutTransform.applyTo(container);
    container.appendChild(backgroundRect);
    container.appendChild(textElement);

    return new SvgVisual(container);
  }

  protected override getPreferredSize(label: ILabel): Size {
    return getLabelPreferredSize(label.text, BLOCK_DIAGRAM_EDGE_LABEL_FONT);
  }
}

export default ProteanEdgeLabelStyle;
