import { CSSProperties, ReactNode } from 'react';
import { Icon, IconName } from '@ardoq/icons';
import { ImageWrapper, PersonImage } from './atoms';
import { HumanPlaceholderActionOption } from './types';
import { PrimaryButton } from '@ardoq/button';
import { Header1, Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

type HumanPlaceholderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  actions?: HumanPlaceholderActionOption[];
  wrapperStyle?: CSSProperties;
};

const HumanPlaceholder = ({
  title,
  subtitle = '',
  actions = [],
}: HumanPlaceholderProps) => {
  return (
    <FlexBox width="full" marginTop="xxlarge" align="center" justify="center">
      <FlexBox>
        <Stack justify="center" align="center">
          <Stack gap="small">
            <Header1>{title}</Header1>
            <Paragraph variant="text1Bold">{subtitle}</Paragraph>
          </Stack>
          <FlexBox margin="small">
            {actions.map(
              ({ label, onClick, isExternalLink = false }, index) => {
                return (
                  <PrimaryButton key={index} onClick={onClick}>
                    {label}{' '}
                    {isExternalLink && <Icon iconName={IconName.OPEN_IN_NEW} />}
                  </PrimaryButton>
                );
              }
            )}
          </FlexBox>
        </Stack>
        <FlexBox paddingLeft="large">
          <ImageWrapper>
            <PersonImage />
          </ImageWrapper>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default HumanPlaceholder;
