import { DiffMode, ViewIds } from '@ardoq/api-types';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { getRelationshipDiagramViewModel$ } from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramViewModel$';
import {
  getViewSettingsStreamWithChanges,
  ViewSettings,
} from 'viewSettings/viewSettingsStreams';
import { BlocksViewProperties, BlocksViewSettings } from '../types';
import {
  action$,
  dispatchAction,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { updateViewProperties, viewSettingsConsts } from '@ardoq/view-settings';
import buildBlocksViewProperties from './buildBlocksViewProperties';
import { withNamespaceOrNullNamespace } from '../../../streams/utils/streamOperators';
import { RelationshipDiagramViewModelStreamState } from '@ardoq/graph';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import defaultState from '../../../views/defaultState';
import { isEqual } from 'lodash';

const haveViewSettingsChanged = (
  { currentSettings: previous }: ViewSettings<BlocksViewSettings>,
  { currentSettings: current }: ViewSettings<BlocksViewSettings>
) => isEqual(previous, current);

const rawViewSettings$ = getViewSettingsStreamWithChanges<BlocksViewSettings>(
  ViewIds.BLOCKS
).pipe(
  distinctUntilChanged<ViewSettings<BlocksViewSettings>>(
    haveViewSettingsChanged
  )
);

export const unfilteredBlocksViewSettings$ = rawViewSettings$.pipe(
  map(({ currentSettings }) => currentSettings)
);

const defaultSettingsState = defaultState.get(ViewIds.BLOCKS);

// we definitely don't need some of these properties, but this corresponds to the type
const initialBlocksViewModelState: BlocksViewProperties = {
  viewSettings: {
    highlightDisconnectedComponents: false,
    lastClickedNodeId: null,
    isLegendActive: false,
    activeDiffMode: DiffMode.MAIN,
    collapsedGroupIds: [],
    useNewGrouping: false,
    onViewSettingsUpdate,
    ...defaultSettingsState,
  },
  graph: null,
  componentTypes: [],
  referenceModelTypes: [],
  errors: [],
  hasClones: false,
  groups: {
    byId: new Map(),
    add: [],
    update: [],
    remove: [],
  },
  isViewpointMode: false,
  viewInstanceId: '',
};

const updateBlocksViewSettings = (
  state: BlocksViewProperties,
  viewSettings: BlocksViewSettings
) => ({
  ...state,
  viewSettings,
});

const getViewModel$ = (
  viewInstanceId: string
): Observable<BlocksViewProperties> => {
  const graphModelShape$ = getRelationshipDiagramViewModel$({
    viewInstanceId,
    viewId: ViewIds.BLOCKS,
    viewSettings$: rawViewSettings$,
    viewSettingsToIgnore: ['layoutData', viewSettingsConsts.IS_LEGEND_ACTIVE],
    shouldCollapseGraph: false,
  });

  const viewModelResult$ = action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState<BlocksViewProperties>(
      'BlocksViewModelResult',
      initialBlocksViewModelState,
      [
        streamReducer<
          BlocksViewProperties,
          RelationshipDiagramViewModelStreamState<BlocksViewSettings>
        >(graphModelShape$, buildBlocksViewProperties),
        streamReducer(unfilteredBlocksViewSettings$, updateBlocksViewSettings),
      ]
    )
  );

  return viewModelResult$.pipe(
    tap(viewProperties =>
      dispatchAction(
        updateViewProperties({
          viewId: ViewIds.BLOCKS,
          viewProperties,
        })
      )
    )
  );
};

export default getViewModel$;
