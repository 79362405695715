import {
  pathsToFilters,
  pathsToHierarchy,
  getAllPaths,
} from './pathAndGraphUtils';
import { PathAsHierarchyArgs } from './pathAndGraphTypes';

/**
 * For some visualizations we need to be able to represent the graph as a
 * hierarchy. An example is the Dependency Map. The graph is a directed graph
 * potentially with cycles, so by just looking at the graph it is not possible
 * to automatically transform it to a hierarchy. So we take the user intention
 * as input to transform the graph to a hierarchy. The user intentions are the
 * meta model traversals. They build a tree based on how the UI is designed.
 * The meta model traversals are a list of start sets and paths.
 * The start sets are the components to start from, and the paths are the
 * references to follow. The result is a hierarchy of linked components. The
 * linked components are the components that are connected by references. The
 * hierarchy is a tree structure where each node has a list of linked
 * components. Each linked component has a list of parent connections (there
 * could potentially be multiple references in the graph linking to the same
 * parent). The parent connections are the references that connect the linked
 * component to the parent component.
 */
export const getAllPathsAsHierarchy = ({
  graphInterface,
  hierarchyDefinition,
  graphModel,
  workspacesIds,
}: PathAsHierarchyArgs) => {
  const hierarchyDefinitionAsFilters = hierarchyDefinition.map(
    ({ startSetResult, collapsedPaths }) => ({
      startSet: startSetResult,
      pathsAsFilters: pathsToFilters(
        graphInterface,
        collapsedPaths,
        workspacesIds
      ),
    })
  );

  return pathsToHierarchy(
    getAllPaths(graphInterface, hierarchyDefinitionAsFilters, graphModel)
  );
};
