import { ViewIds } from '@ardoq/api-types';
import { OnViewSettingsUpdate } from '@ardoq/data-model';
import { ExportViewEvent, getExportsForHtmlView } from '@ardoq/export';
import { SettingsConfig } from '@ardoq/view-settings';
import { getRightMenuConfig, ViewSettingsBase } from '@ardoq/settings-bar';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { ExcludeFalsy } from '@ardoq/common-helpers';

export type GetRightSettingsMenuConfigForWorkspaceModeArgs<
  TViewSettings extends ViewSettingsBase,
> = {
  viewSettings: TViewSettings;
  viewId: ViewIds;
  getContainer: () => HTMLElement | null;
  viewHasLegend: (viewId: ViewIds) => boolean;
  onViewSettingsUpdate: OnViewSettingsUpdate;
  trackingFunction:
    | (() => void)
    | ((metadata: ExportViewEvent) => Promise<void>);
  getStyleSheets: (() => Promise<any>) | (() => CSSStyleSheet[]);
  isEmptyView: boolean;
  addToPresentation: (name: ViewIds) => void;
  knowledgeBaseLink: KnowledgeBaseLink;
  /**
   * contains the today button and zoom controls, provided by view context
   */
  zoomControls: SettingsConfig[];
};
export const getRightSettingsMenuConfig = <
  TViewSettings extends ViewSettingsBase,
>({
  viewSettings,
  viewId,
  viewHasLegend,
  onViewSettingsUpdate,
  getContainer,
  trackingFunction,
  getStyleSheets,
  isEmptyView,
  addToPresentation,
  zoomControls,
  knowledgeBaseLink,
}: GetRightSettingsMenuConfigForWorkspaceModeArgs<TViewSettings>): SettingsConfig[] => {
  const exports = getExportsForHtmlView({
    container: getContainer,
    exportedViewMetadata: {
      name: viewId,
      isViewpointMode: false,
      isDiscoverViewpoint: false,
    },
    trackingFunction,
    addToPresentation,
    showPresentationOption: true,
    getStyleSheets,
  });

  return [
    ...zoomControls,
    ...getRightMenuConfig({
      viewId: viewId,
      viewstate: viewSettings,
      exports: {
        ...exports,
        isDisabled: isEmptyView,
      },
      withLegend: viewHasLegend(viewId),
      knowledgeBaseLink,
      onViewSettingsUpdate,
      isFullscreenDisabled: false,
    }),
  ].filter(ExcludeFalsy);
};
