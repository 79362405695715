import styled from 'styled-components';
import { interpolate } from '@ardoq/common-helpers';
import { s16 } from '@ardoq/design-tokens';
import { STRINGS } from './consts';
import excludeWorkspaceGif from './exclude-workspace.gif';

const GifContainer = styled.img`
  width: 360px;
  height: 202px;
  object-fit: cover;
  margin-top: ${s16};
  margin-bottom: ${s16};
`;

type HowToProps = {
  workspaceName: string;
};

export const HowTo = ({ workspaceName }: HowToProps) => (
  <>
    <div>{interpolate(STRINGS.MESSAGE.HOW.SUMMARY, { workspaceName })}</div>
    <div style={{ marginTop: s16 }}>{STRINGS.MESSAGE.HOW.TO_EXCLUDE}</div>
    <ol>
      {STRINGS.MESSAGE.HOW.STEPS.map(step => (
        <li key={step}>{step}</li>
      ))}
    </ol>
    <GifContainer alt={STRINGS.IMAGE_ALT} src={excludeWorkspaceGif} />
  </>
);
