import {
  getGraphInterfaceWithModelInterfaces,
  type RawGraphItem,
  type TraverseOptions,
} from '@ardoq/graph';
import { buildGraph } from 'modelInterface/graph/buildGraphForWorkspaceMode';
import { GraphModelShape } from '@ardoq/data-model';
import { buildTraversalViewModel } from 'tabview/graphViews/traversalViewModel';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { uniq } from 'lodash';
import { componentInterface } from '@ardoq/component-interface';

interface DependencyMapTraversalViewModelProperties {
  startSet: ArdoqId[];
  graphModel: GraphModelShape;
  /** these are the traversal options used before reference traversal occurs in the Dependency Map. They will be used to determine the startSet for the reference types menu. */
  baseTraverseOptions?: TraverseOptions;
  /** these are the user's traverse options which specify the degrees of traversal. */
  referenceTraverseOptions: TraverseOptions;
}
const dependencyMapTraversalViewModel = ({
  startSet,
  graphModel,
  baseTraverseOptions,
  referenceTraverseOptions,
}: DependencyMapTraversalViewModelProperties) => {
  // potential optimization: move this buildGraph call to viewModel$ and perform a two-stage buildGraph rather than leveraging sequentialTraverseOptions for reference traversal. that would eliminate the redundancy of this buildGraph call.

  /**
   * remove interface calls from viewModel scope and pass GraphContext
   * as argument to use scopeData utils.
   * Currently, the graphInterface is used to ensure that the graph can
   * be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();
  const { componentMap, groupMap } = buildGraph({
    graphInterface,
    viewId: ViewIds.DEPENDENCY_MAP_2,
    startSet,
    graph: graphModel,
    traverseOptions: baseTraverseOptions,
  });
  const componentIds = uniq(
    [...componentMap.values(), ...groupMap.values()]
      .filter((graphItem): graphItem is RawGraphItem & { modelId: string } =>
        Boolean(
          graphItem.modelId && componentInterface.isComponent(graphItem.modelId)
        )
      )
      .map(({ modelId }) => modelId)
  );
  return buildTraversalViewModel({
    graphInterface,
    graphModel,
    startSet: componentIds,
    traverseOptions: referenceTraverseOptions,
  });
};
export default dependencyMapTraversalViewModel;
