import {
  ChartWindowState,
  Margin,
  NumericRange,
  NumericScalingFunction,
  Rect,
  type HasViewInstanceId,
} from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { EmbeddableViewConfig } from 'tabview/types';
import { APIFieldAttributes, ReferenceDirection } from '@ardoq/api-types';

export interface BubbleChartAxisProperties {
  margin: Margin;
  height: number;
  width: number;
  ticks: number[];
  scaler: (value: number) => number;
  grid: boolean;
  title: string;
}

export interface BubbleChartDataPointArgs {
  data: BubbleChartBubbleModel;
}

export type BubbleChartLabelData = BubbleChartBubbleModel & {
  key?: string;
};

export interface BubbleChartBubbleModel extends BubbleChartDataPointModel {
  fontSize: number;
  fontOpacity: number;
  isBlurred: boolean;
}

export type BubbleChartDataPointModel = {
  cid: string;
  uniqueId?: string;
  x: number;
  y: number;
  radius: number;
  class: string;
  label: string;
  isContext: boolean;
};

export interface BubbleChartScalers {
  fontSize: NumericScalingFunction;
  fontOpacity: NumericScalingFunction;
  radius: NumericScalingFunction;
  unscaleRadius: NumericScalingFunction;
  x: NumericScalingFunction;
  y: NumericScalingFunction;
}

export interface BubbleLayoutInfo {
  cid: string;
  center: Point;
  radius: number;
  labelRect: Rect;
  index: number;
  fontSize: number;
}

export type BubbleChartDomains = Record<
  'x' | 'y' | 'radius' | 'labeledBubblesRadius',
  NumericRange
>;
export enum BubbleChartGridMode {
  NONE = 'none',
  GRID = 'grid',
  TIME = 'time',
  CUSTOM = 'custom',
}
export interface BubbleChartQuadrantSettings {
  xMinimum: number;
  xMaximum: number;
  yMinimum: number;
  yMaximum: number;
  labels: [string, string, string, string];
}

type BubbleChartViewSettingsBase = {
  isLegendActive: boolean;
};

export type IncludeDescendants = 'none' | 'direct' | 'all';

export type BubbleChartViewSettings = BubbleChartViewSettingsBase & {
  background: BubbleChartGridMode;
  selectedFieldNameX: string | BubbleChartSelectedFieldInfo | null;
  selectedFieldNameY: string | BubbleChartSelectedFieldInfo | null;
  selectedFieldNameRadius: string | BubbleChartSelectedFieldInfo | null; // actually more like the "area" field since bubbles are scaled by area, not radius.
  customQuadrantSettings: BubbleChartQuadrantSettings;
  /** for backwards compatibility, true means show all descendants and false means show direct children */
  includeAllDescendants: boolean | IncludeDescendants;
  bubbleAreaScaleFactor: number;
  fieldSource: 'byComponent' | 'byReference';
  selectedReferenceTypeName: string | null;
  selectedReferenceDirection: ReferenceDirection;
  showBubblesWithZeroValue: boolean;
};

type BubbleChartViewPropertiesBase<
  TViewSettings extends BubbleChartViewSettingsBase,
> = HasViewInstanceId &
  ChartWindowState & {
    viewSettings: TViewSettings;
    isLoadingWorkspace: boolean;
    data: BubbleChartDataPointModel[];
    domain: BubbleChartDomains;
    xAxisTitle: string;
    yAxisTitle: string;
    hoverState: HighlightState;
    focusState: HighlightState;
  };
export type BubbleChartViewProperties =
  BubbleChartViewPropertiesBase<BubbleChartViewSettings> & {
    numericFields: APIFieldAttributes[];
    embeddableViewConfig?: EmbeddableViewConfig;
    availableIncomingReferenceTypeNames: string[];
    availableOutgoingReferenceTypeNames: string[];
    availableReferenceFields: FieldNameAndLabel[];
    availableReferencedComponentFields: FieldNameAndLabel[];
  };

export enum BubbleChartSelectedFieldType {
  REFERENCE,
  REFERENCED_COMPONENT,
}
export type BubbleChartSelectedFieldInfo = {
  fieldName: string;
  type: BubbleChartSelectedFieldType;
};

export type FieldNameAndLabel = Pick<APIFieldAttributes, 'name' | 'label'>;

export type HighlightState = {
  target: SVGElement | null;
  id: string;
  labelPosition: Point | null;
};
