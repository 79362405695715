import { SVGAttributes } from 'react';
import styled from 'styled-components';
import { ArdoqSvgImageElement } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { setIsCurrentlyHoveredImageBroken } from 'tabview/graphComponent/isCurrentlyHoveredImageBroken$';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH } from './consts';
import {
  MODERNIZED_BLOCK_DIAGRAM_IMAGE_CIRCLE_STROKE_WIDTH,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
} from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';
import {
  MODERNIZED_BLOCK_DIAGRAM_LEAF_IMAGE_CLIP_PATH,
  NO_LINK_TARGET_DECORATION,
} from '@ardoq/graph';

const ImageOutline = styled.circle`
  && {
    fill: none;
  }
`;
type NodeRepresentationProperties = {
  templateHref?: string;
  imageHref?: string;
  isPlaceholder: boolean;
  height: number;
  width: number;
  x: number;
  y: number;
  visualDiffClass: string;
  tooltipAttributes?: Record<string, string>;
  color: string;
  /** true if images should use a circular clip path. */
  isCircle: boolean;
};
const NodeRepresentation = ({
  templateHref,
  imageHref,
  isPlaceholder,
  height,
  width,
  x,
  y,
  visualDiffClass,
  color,
  isCircle,
  tooltipAttributes = {},
}: NodeRepresentationProperties & SVGAttributes<SVGGElement>) => {
  if (templateHref && !isPlaceholder) {
    return (
      <use
        href={templateHref}
        className={visualDiffClass}
        x={x}
        y={y}
        height={height}
        width={width}
        {...tooltipAttributes}
      />
    );
  }

  if (imageHref && !isPlaceholder) {
    const cx = MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH / 2;
    const cy = y + MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2;
    return (
      <>
        <ArdoqSvgImageElement
          href={imageHref}
          className={visualDiffClass}
          x={cx - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}
          y={cy - MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}
          height={MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER}
          width={MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER}
          clipPath={
            isCircle
              ? `url(#${MODERNIZED_BLOCK_DIAGRAM_LEAF_IMAGE_CLIP_PATH})`
              : undefined
          }
          onBrokenImageMouseEnter={(imageElement?: SVGImageElement) => {
            dispatchAction(
              setIsCurrentlyHoveredImageBroken({
                isCurrentlyHoveredImageBroken: true,
                imageElement: imageElement,
              })
            );
          }}
          onBrokenImageMouseLeave={(imageElement?: SVGImageElement) => {
            dispatchAction(
              setIsCurrentlyHoveredImageBroken({
                isCurrentlyHoveredImageBroken: false,
                imageElement: imageElement,
              })
            );
          }}
          {...tooltipAttributes}
        />
        <ImageOutline
          className={NO_LINK_TARGET_DECORATION}
          cx={cx}
          cy={cy}
          r={MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}
          strokeWidth={MODERNIZED_BLOCK_DIAGRAM_IMAGE_CIRCLE_STROKE_WIDTH}
          stroke={color}
        />
      </>
    );
  }
};

export default NodeRepresentation;
