import {
  ProteanDiagramViewProperties,
  ProteanDiagramViewSettings,
  ProteanLayoutType,
} from '../types';
import { getViewModel$ } from '../viewModel$/viewModel$';
import ProteanDiagramBaseView from '../view/ProteanDiagramBaseView';
import {
  EDIT_MODE_MENU_ID,
  LAYOUT_RESET_MENU_ID,
} from '../view/settingsBar/consts';
import { ViewIds } from '@ardoq/api-types';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import { SEPARATE_REFERENCES_TOGGLE } from 'tabview/relationshipDiagrams/leftMenuItems';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';

const getViewSettingsWithDefaultInsets = (
  viewSettings: ProteanDiagramViewSettings
) => ({
  ...viewSettings,
  layoutOptions: {
    ...viewSettings.layoutOptions,
    hierarchicInGrid: viewSettings.layoutOptions.hierarchicInGrid && {
      ...viewSettings.layoutOptions.hierarchicInGrid,
      columnInsets:
        PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchicInGrid.columnInsets,
      rowInsets: PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchicInGrid.rowInsets,
      minColumnSize:
        PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchicInGrid.minColumnSize,
      minRowSize: PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchicInGrid.minRowSize,
    },
  },
});

const settingsBarCLeftMenuItemIds = [
  settingsBarConsts.DEGREES_OF_RELATIONSHIP_SLIDER_MENU_ITEM_ID,
  EDIT_MODE_MENU_ID,
  LAYOUT_RESET_MENU_ID,
];

const HierarchicInGridView = (props: ProteanDiagramViewProperties) => {
  return (
    <ProteanDiagramBaseView
      {...{
        ...props,
        viewSettings: {
          ...getViewSettingsWithDefaultInsets(props.viewSettings),
          layoutType: ProteanLayoutType.HierarchicInGrid,
        },
      }}
      viewId={ViewIds.HIERARCHIC_IN_GRID}
      leftMenuFilter={items => [
        ...items.filter(item => settingsBarCLeftMenuItemIds.includes(item.id)),
        {
          ...SEPARATE_REFERENCES_TOGGLE,
          isActive:
            props.viewSettings.layoutOptions.hierarchicInGrid
              ?.separateReferences,
          onClick: () => {
            const separateReferences =
              !props.viewSettings.layoutOptions.hierarchicInGrid
                ?.separateReferences;
            dispatchAction(
              updateViewSettings({
                viewId: ViewIds.HIERARCHIC_IN_GRID,
                persistent: true,
                settings: {
                  ...props.viewSettings,
                  layoutOptions: {
                    ...props.viewSettings.layoutOptions,
                    hierarchicInGrid: {
                      ...props.viewSettings.layoutOptions.hierarchicInGrid,
                      separateReferences,
                    },
                  },
                },
              })
            );
          },
        },
      ]}
    />
  );
};

export default connectInstance(
  HierarchicInGridView,
  getViewModel$(ViewIds.HIERARCHIC_IN_GRID)
);
