export const devLicense = {
  company: 'Ardoq AS',
  date: '01/15/2025',
  distribution: false,
  domains: ['*'],
  fileSystemAllowed: true,
  licensefileversion: '1.1',
  localhost: true,
  oobAllowed: true,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Ardoq.com',
  subscription: '02/14/2028',
  type: 'project',
  version: '2.6',
  watermark: 'yFiles HTML Development License',
  key: '51014d1b512804a658155981bd5eb0290a77e3c6',
};
