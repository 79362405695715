export enum ContextMenuTestIds {
  COMPONENT = 'component-context-menu',
  REFERENCE = 'reference-context-menu',
  WORKSPACE = 'workspace-context-menu',
  TAG = 'tag-context-menu',
  SCENARIO = 'scenario-context-menu',
  COMPONENT_AND_REFERENCE = 'component-and-reference-context-menu',
  NON_COMPONENT_GROUP = 'non-component-group-context-menu',
}

export const INTEGRATION_CLASS = 'integration';

export const TOGGLE_LOCK_MENU_ITEM_KEY = 'toggle-lock';
