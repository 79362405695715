import { spreadPayloadOverState } from '@ardoq/common-helpers';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setSlidesMigrationInfo } from 'presentation/actions';
import { SlidesMigrationInfo } from 'viewDeprecation/types';

const defaultState: SlidesMigrationInfo = {
  activePresentationId: undefined,
  slidesInfo: undefined,
  activeSlide: undefined,
};

const reducers = [
  reducer(
    setSlidesMigrationInfo,
    spreadPayloadOverState<SlidesMigrationInfo, SlidesMigrationInfo>
  ),
];

export const slidesMigrationInfo$ =
  persistentReducedStream<SlidesMigrationInfo>(
    'slidesMigrationInfo$',
    defaultState,
    reducers
  );
