import { createElement } from 'react';
import { INode, IRenderContext, SvgVisual } from '@ardoq/yfiles';
import { DiffType } from '@ardoq/data-model';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { type GraphNode, ensureRoot, disposeCallback } from '@ardoq/graph';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import ModernizedNodeStyle from './ModernizedNodeStyle';
import ArdoqNode from './ArdoqNode';
import { componentInterface } from '@ardoq/component-interface';
import { ArdoqIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import leafNodeShapeY from './leafNodeShapeY';
import { MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER } from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';
import { isContextGraphNode } from '../nodeDecorator';

class IconNodeStyle extends ModernizedNodeStyle {
  constructor(private icon: ArdoqIcon) {
    super();
  }

  override createVisual(context: IRenderContext, node: INode): SvgVisual {
    const result = super.createVisual(context, node);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }
  protected override render(container: SVGElement, node: INode) {
    const { tag } = node;
    const graphNode = tag as GraphNode;
    const { isGhost, modelId } = graphNode;
    const visualDiffType = graphNode.getVisualDiffType();
    const visualDiffClass =
      getCssClassFromDiffType(visualDiffType) ||
      (isGhost ? 'visual-diff-unchanged' : '');

    const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
    const tooltipAttributes = getChangedPopover(modelId, visualDiffType);
    if (modelId) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
    }

    const { fill: color = colors.transparent0 } =
      componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
        useAsBackgroundStyle: false,
      });

    ensureRoot(container).render(
      createElement(ArdoqNode, {
        icon: this.icon,
        isPlaceholder,
        isContext: isContextGraphNode(graphNode),
        isTransparentized: graphNode.isTransparentized,
        visualDiffClass,
        tooltipAttributes,
        color,
        shapeY: leafNodeShapeY(
          node,
          MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
          false
        ),
      })
    );
  }
}

export default IconNodeStyle;
