import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSourceItem } from '../types';

type SelectionState = { byId: Record<string, boolean> };
type DataSourceState<Item, Data> = {
  dataSource: Item[];
  byId: Record<string, Data>;
};

export const withSelection$ = <Item extends DataSourceItem, Data>(
  dataSource$: Observable<DataSourceState<Item, Data>>,
  selection$: Observable<SelectionState>
) =>
  combineLatest([dataSource$, selection$]).pipe(
    map(([{ dataSource, byId }, selection]) => ({
      dataSource: dataSource.map(e => ({
        ...e,
        isSelected: Boolean(selection.byId[e.id]),
        isMultiSelect: Object.values(selection.byId).filter(Boolean).length > 1,
      })),
      byId,
    }))
  );
