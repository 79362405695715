import { ArdoqId, GroupType } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import {
  GraphComponent,
  HighlightIndicatorManager,
  ICanvasObjectInstaller,
  INode,
  IRenderContext,
  MatrixOrder,
  NodeStyleBase,
  NodeStyleDecorationInstaller,
  Point,
  StyleDecorationZoomPolicy,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { areFileSizesValid } from 'components/DocumentBrowser/DocumentBrowserStackPage';
import {
  alertFileSizeIsTooBig,
  isFilenameTaken,
} from 'components/DocumentBrowser/utils';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { CONTEXT_HIGHLIGHT_PADDING } from 'yfilesExtensions/styles/consts';
import {
  isContextNode,
  isImageNode,
} from 'yfilesExtensions/styles/nodeDecorator';
import { alert } from '@ardoq/modal';
import { LABEL_CLASS } from 'yfilesExtensions/styles/consts';
import { noWorkspacePermissionDialogConfig } from './NoWorkspacePermissionDialog';
import { unknownDocumentArchiveErrorDialog } from './NoDocumentArchivePermissionDialog';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { GraphItem } from '@ardoq/graph';
import './DragAndDropImageHighlightIndicatorManager.css';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { lockedComponentDialog } from './LockedComponentDialog';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { createSvgElement } from '@ardoq/dom-utils';
import { isArdoqError } from '@ardoq/common-helpers';
import { attachmentApi } from '@ardoq/api';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';

const MARGIN = 1;
const GROUP_NODE_CORNER_RADIUS = 4;

interface RenderDataCache {
  shape: 'circle' | 'rect';
  padding: number;
  width: number;
  height: number;
  isGroupNode: boolean;
  isHovered: boolean;
  zoomedIn: boolean;
  isValidDropTarget: boolean;
  equals: (self: RenderDataCache, other: RenderDataCache) => boolean;
}

const renderDataCache = new WeakMap<Element, RenderDataCache>();

/**
 * Creates an object containing all necessary data to create a visual for the node.
 */
const createRenderDataCache = (
  context: IRenderContext,
  node: INode
): RenderDataCache => {
  const padding = isContextNode(node) ? CONTEXT_HIGHLIGHT_PADDING : 0;
  const isGroupNode = node.tag.isGroupNode;
  const workspaceId = componentInterface.getWorkspaceId(dataModelId(node.tag));

  return {
    shape: isImageNode(node) && !isGroupNode ? 'circle' : 'rect',
    padding,
    width: node.layout.width - 2 * padding,
    height: node.layout.height - 2 * padding,
    isGroupNode,
    isHovered: node.tag.isHovered,
    zoomedIn: context.zoom > 1,
    isValidDropTarget:
      !componentInterface.isLocked(dataModelId(node.tag)) &&
      !!workspaceId &&
      workspaceAccessControlInterface.canEditWorkspace(
        currentUserInterface.getPermissionContext(),
        workspaceId,
        getActiveScenarioState()
      ),
    equals: (self, other): boolean =>
      self.shape === other.shape &&
      self.padding === other.padding &&
      self.width === other.width &&
      self.height === other.height &&
      self.isHovered === other.isHovered &&
      self.isGroupNode === other.isGroupNode &&
      self.zoomedIn === other.zoomedIn &&
      self.isValidDropTarget === other.isValidDropTarget,
  };
};
const render = (
  context: IRenderContext,
  node: INode,
  container: SVGElement,
  cache: RenderDataCache
) => {
  // store information with the visual on how we created it
  renderDataCache.set(container, cache);

  if (cache.isValidDropTarget) {
    const animatedOutline = createSvgElement(cache.shape, {
      class: 'drag-and-drop-highlight-animated-outline',
      width: `${cache.width}`,
      height: `${cache.height}`,
      cy: `${cache.height / 2}`,
      cx: `${cache.width / 2}`,
      r: `${cache.height / 2}`,
      rx: cache.isGroupNode ? `${GROUP_NODE_CORNER_RADIUS}` : '0',
      ry: cache.isGroupNode ? `${GROUP_NODE_CORNER_RADIUS}` : '0',
      fill: 'transparent',
      stroke: cache.isHovered ? colors.blue35 : colors.blue50,
      ['stroke-linecap']: 'square',
      ['stroke-width']: '4',
      ['stroke-dasharray']: cache.isHovered
        ? '0'
        : cache.shape === 'circle'
          ? '12.8'
          : '16',
      style: 'pointer-events: none',
    });

    container.append(animatedOutline);
  }
};

const maybeRenderInvalidDropzoneClipPath = (
  context: IRenderContext,
  node: INode,
  cache: RenderDataCache
) => {
  if (!cache.isValidDropTarget) {
    const id = (node.tag as GraphItem).id;

    const invalidMask = createSvgElement('rect', {
      'data-id': id,
      width: `${cache.width - 2 * MARGIN}`,
      height: `${cache.height - 2 * MARGIN}`,
      rx: cache.isGroupNode ? `${GROUP_NODE_CORNER_RADIUS}` : '0',
      ry: cache.isGroupNode ? `${GROUP_NODE_CORNER_RADIUS}` : '0',
      x: `${MARGIN}`,
      y: `${MARGIN}`,
      style: 'pointer-events: none',
    });

    const { padding } = cache;
    const matrix = context.viewTransform.clone();
    matrix.invert();
    matrix.translate(new Point(padding, padding), MatrixOrder.PREPEND);
    matrix.translate(node.layout.topLeft, MatrixOrder.PREPEND);
    matrix.applyTo(invalidMask);

    const clipNode =
      context.canvasComponent?.overlayPanel.querySelector('#clip');

    if (clipNode?.hasChildNodes()) {
      for (const child of clipNode.childNodes) {
        if ((child as SVGRectElement).dataset.id === id) {
          clipNode.removeChild(child);
        }
      }
    }

    clipNode?.appendChild(invalidMask);
  }
};

class DropzoneNodeStyle extends NodeStyleBase {
  /**
   * Creates the visual for a node.
   * @see Overrides {@link NodeStyleBase.createVisual}
   */
  override createVisual(context: IRenderContext, node: INode): Visual | null {
    const g = createSvgElement('g');

    const cache = createRenderDataCache(context, node);
    const { padding } = cache;

    render(context, node, g, cache);
    maybeRenderInvalidDropzoneClipPath(context, node, cache);

    SvgVisual.setTranslate(g, node.layout.x + padding, node.layout.y + padding);

    return new SvgVisual(g);
  }

  /**
   * Re-renders the node using the old visual for performance reasons.
   * @see Overrides {@link NodeStyleBase.updateVisual}
   */
  override updateVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode
  ): SvgVisual {
    const g = oldVisual.svgElement;
    const oldCache = renderDataCache.get(g);
    const newCache = createRenderDataCache(context, node);
    const { padding } = newCache;

    if (!oldCache || !newCache.equals(newCache, oldCache)) {
      // something changed - re-render the visual
      while (g.hasChildNodes()) {
        g.removeChild(g.firstChild!);
      }
      render(context, node, g, newCache);
    }
    maybeRenderInvalidDropzoneClipPath(context, node, newCache);

    SvgVisual.setTranslate(g, node.layout.x + padding, node.layout.y + padding);

    return oldVisual;
  }
}

class DragAndDropImageHighlightIndicatorManager extends HighlightIndicatorManager<INode> {
  private static getNodeInstaller = () => {
    return new NodeStyleDecorationInstaller({
      nodeStyle: new DropzoneNodeStyle(),
      margins: MARGIN,
      zoomPolicy: StyleDecorationZoomPolicy.WORLD_COORDINATES,
    });
  };

  getInstaller(item: INode): ICanvasObjectInstaller | null {
    if (item instanceof INode) {
      return DragAndDropImageHighlightIndicatorManager.getNodeInstaller();
    }
    return super.getInstaller(item);
  }
}

export const initializeDragAndDropHighlightIndicator = (
  graphComponent: GraphComponent
) => {
  const addValidDropzoneHighlightToNodes = (evt: DragEvent) => {
    evt.preventDefault();

    transitioning = true;
    setTimeout(() => {
      transitioning = false;
    }, 1);

    if (isDraggingImage(evt)) {
      const highlightManager = graphComponent.highlightIndicatorManager;
      for (const node of graphComponent.graph.nodes) {
        if (
          node.tag !== null &&
          node.tag?.type !== GroupType.COMPONENT &&
          node.tag?.type !== GroupType.FIELD &&
          node.tag?.type !== GroupType.TAG &&
          node.tag?.type !== GroupType.WORKSPACE
        ) {
          highlightManager.addHighlight(node);
          node.tag.isGroupNode = graphComponent.graph.isGroupNode(node);
        }
      }
    }
  };

  const setHoveredDropzone = (evt: DragEvent) => {
    evt.preventDefault();

    transitioning = true;
    setTimeout(() => {
      transitioning = false;
    }, 1);

    graphComponent.invalidate();

    const hoveredElement = evt.target as Element;

    const hoveredDOMNode =
      hoveredElement.closest<SVGElement>('g[data-component-id]') ||
      hoveredElement.closest<SVGElement>('g[data-global-handler-id]');

    graphComponent.graph.nodes.forEach((node: INode) => {
      if (
        !node.tag ||
        node.tag?.type === GroupType.COMPONENT ||
        node.tag?.type === GroupType.FIELD ||
        node.tag?.type === GroupType.TAG ||
        node.tag?.type === GroupType.WORKSPACE
      ) {
        return;
      }

      const nodeId = node.tag?.modelId || node.tag?.dataModel?.id || null;
      const hoveredNodeId =
        hoveredDOMNode?.dataset?.componentId ||
        hoveredDOMNode?.dataset?.globalHandlerId;

      const isHoveringLabel = hoveredElement.closest<SVGElement>(
        `g.${LABEL_CLASS}`
      );
      const isHoveringNode = nodeId === hoveredNodeId;

      if (isHoveringLabel) node.tag.isHovered = false;
      else if (isHoveringNode) node.tag.isHovered = true;
      else node.tag.isHovered = false;
    });
  };

  const clearHighlights = (evt: DragEvent) => {
    evt.preventDefault();
    graphComponent.invalidate();

    if (transitioning === false) {
      const highlightManager = graphComponent.highlightIndicatorManager;
      highlightManager.clearHighlights();
      clipNode.innerHTML = '';
    }
  };

  const upsertIcon = async (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    graphComponent.invalidate();

    const dropTarget = evt.target as Element;

    const componentId =
      dropTarget.closest<SVGElement>(`g[${COMPONENT_ID_ATTRIBUTE}]`)?.dataset
        ?.componentId ||
      dropTarget.closest<SVGElement>(`g[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`)
        ?.dataset?.globalHandlerId ||
      null;

    const isDroppedOnLabel = dropTarget.closest<SVGElement>(`g.${LABEL_CLASS}`);
    if (isDroppedOnLabel || !componentId) {
      return;
    }
    const workspaceId = componentInterface.getWorkspaceId(componentId);
    if (!workspaceId) {
      return;
    }

    if (
      !workspaceAccessControlInterface.canEditWorkspace(
        currentUserInterface.getPermissionContext(),
        workspaceId,
        getActiveScenarioState()
      )
    ) {
      alert(noWorkspacePermissionDialogConfig(workspaceId));
      return;
    }
    if (componentInterface.isLocked(componentId)) {
      alert(lockedComponentDialog());
      return;
    }

    const file = Array.from(evt.dataTransfer?.files || [])[0];
    if (!areFileSizesValid([file])) {
      alertFileSizeIsTooBig();
      return;
    }
    const isFilenameTakenResult = await isFilenameTaken(file, workspaceId);
    if (isArdoqError(isFilenameTakenResult)) {
      alert(unknownDocumentArchiveErrorDialog(workspaceId));
      return;
    }
    if (isFilenameTakenResult) {
      setComponentImage(
        componentId,
        `/api/attachment/workspace/${workspaceId}/${file.name}`
      );
      return;
    }
    const result = await attachmentApi.uploadFiles({
      files: [file],
      typeId: workspaceId,
      type: 'workspace',
    });
    if (isArdoqError(result)) {
      alert(unknownDocumentArchiveErrorDialog(workspaceId));
      return;
    }

    // We can use result[0] as we know that we have only uploaded a single file
    // and the result is an array of the uploaded files
    setComponentImage(componentId, result[0].uri);
  };

  graphComponent.highlightIndicatorManager =
    new DragAndDropImageHighlightIndicatorManager();

  const clipNode = configureInvalidDropzoneOverlayPanel(
    graphComponent.overlayPanel
  );

  let transitioning = false;
  window.addEventListener('dragenter', addValidDropzoneHighlightToNodes);
  window.addEventListener('dragover', setHoveredDropzone);
  window.addEventListener('dragleave', clearHighlights);
  window.addEventListener('drop', clearHighlights);
  window.addEventListener('drop', upsertIcon);

  const unregister = () => {
    window.removeEventListener('dragenter', addValidDropzoneHighlightToNodes);
    window.removeEventListener('dragover', setHoveredDropzone);
    window.removeEventListener('dragleave', clearHighlights);
    window.removeEventListener('drop', clearHighlights);
    window.removeEventListener('drop', upsertIcon);
  };
  return unregister;
};

const isDraggingImage = (evt: DragEvent) => {
  const imageTypes = [
    'image/svg+xml',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpeg',
    'image/webp',
  ];
  if (evt.dataTransfer && evt.dataTransfer.items) {
    const fileType = evt.dataTransfer.items[0]?.type;
    if (fileType) return imageTypes.includes(fileType);
    return false;
  }
  return false;
};

const createMask = () => {
  const mask = createSvgElement('g', {
    'clip-path': 'url(#clip)',
  });
  const rect = createSvgElement('rect', {
    width: `100%`,
    height: `100%`,
    fill: 'white',
  });
  mask.appendChild(rect);
  return mask;
};

const configureInvalidDropzoneOverlayPanel = (overlayPanel: HTMLElement) => {
  const invalidDropzonePattern = createSvgElement('svg', {
    xmlns: 'http://www.w3.org/2000/svg',
    opacity: '0.8',
    style:
      'position:absolute;top:0;left:0;height:100%;width:100%;display:block;pointer-events:none;',
  });
  const clipNode = createSvgElement('clipPath', { id: 'clip' });
  invalidDropzonePattern.appendChild(createMask());
  invalidDropzonePattern.appendChild(clipNode);
  overlayPanel.appendChild(invalidDropzonePattern);
  overlayPanel.setAttribute(
    'style',
    'position:absolute;top:0;left:0;height:100%;width:100%;display:block;pointer-events:none;'
  );

  return clipNode;
};

const setComponentImage = (componentId: ArdoqId, value: string) => {
  componentInterface.setAttributes({ _id: componentId, image: value });
};
