import { useCallback, useEffect, useRef, useState } from 'react';
import { ArdoqId } from '@ardoq/api-types';
import styled from 'styled-components';
import {
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import NavigatorItem from 'tabview/pagesView/PresentationNavigatorItem';
import { InfiniteScroll } from 'atomicComponents/InfiniteScroll/InfiniteScroll';
import { colors } from '@ardoq/design-tokens';
import { HasComponentIds, HasFilteredComponentIds } from './types';
import { useEffectOnce } from '@ardoq/hooks';

interface PresentationNavigatorProps
  extends HasComponentIds,
    HasFilteredComponentIds {
  workspaceId: ArdoqId;
  topVisibleComponentId: ArdoqId | undefined;
}

const NavigatorContainer = styled.div`
  width: 30%;
  padding: ${verticalSpacing.LARGE} ${horizontalSpacing.SMALL} 0
    ${horizontalSpacing.MEDIUM};
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: ${colors.grey95};
`;

const PresentationNavigator = ({
  componentIds,
  filteredComponentIds,
  workspaceId,
  topVisibleComponentId,
}: PresentationNavigatorProps) => {
  const visibleItems = useRef(new Set<ArdoqId>());
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            visibleItems.current.add(entry.target.id);
          } else if (visibleItems.current.has(entry.target.id)) {
            visibleItems.current.delete(entry.target.id);
          }
        });
      },
      // The { rootMargin: '-100px' } setting shrinks the virtual boundaries of the container to ensure that
      // only truly visible components are considered to be added to the visibleItems set.
      { rootMargin: '-100px' }
    )
  );
  const itemElements = useRef<HTMLDivElement[]>([]);
  const [scrollToItem, setScrollToItem] = useState('');
  const resetObserver = useCallback(() => {
    Array.from(itemElements.current).forEach(item => {
      observer.current.unobserve(item);
      observer.current.observe(item);
    });
  }, [itemElements, observer]);

  useEffectOnce(() => {
    resetObserver();

    return () => {
      observer.current.disconnect();
    };
  });

  useEffect(() => {
    if (
      topVisibleComponentId &&
      scrollToItem !== topVisibleComponentId &&
      !visibleItems.current.has(topVisibleComponentId)
    ) {
      setScrollToItem(topVisibleComponentId);
      resetObserver();
    }
  }, [resetObserver, scrollToItem, topVisibleComponentId]);

  const filteredComponentIdsSet = new Set(filteredComponentIds);

  const navigatorItemRender = (componentId: ArdoqId) => (
    <NavigatorItem
      observeItem={(item: HTMLDivElement) => {
        observer.current.observe(item);
        itemElements.current.push(item);
      }}
      unobserveItem={(item: HTMLDivElement) => {
        observer.current.unobserve(item);
        itemElements.current = itemElements.current.filter(
          itemElement => itemElement.id !== item.id
        );
        visibleItems.current.delete(item.id);
      }}
      key={componentId}
      componentId={componentId}
      shouldHighlight={componentId === topVisibleComponentId}
      shouldDim={filteredComponentIdsSet.has(componentId)}
    />
  );
  return (
    <NavigatorContainer>
      <InfiniteScroll
        items={componentIds}
        bypassRenderLimit={false}
        itemRenderFunction={navigatorItemRender}
        workspaceId={workspaceId}
        numberOfComponentsToRender={90}
        scrollToItem={scrollToItem}
        getRowElement={el => (el instanceof HTMLDivElement ? el : null)}
      />
    </NavigatorContainer>
  );
};

export default PresentationNavigator;
