import styled from 'styled-components';
import { ViewIds } from '@ardoq/api-types';
import { IconName, IconSize } from '@ardoq/icons';
import { createDropdownSliderOptionManager } from '@ardoq/settings-bar';
import { DropdownConfig, SettingsType } from '@ardoq/view-settings';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { REFERENCE_PARENTS_DISABLED_POPOVER } from './consts';
import { INFINITE_DEGREES } from '@ardoq/dependency-map';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';

/**
 * element to display a label adjacent to the degrees sliders in the dropdown menu.
 * these elements are styled to have equal width, so they align nicely when repeated vertically.
 */
const DegreesLabelContainer = styled.div`
  width: 112px;
  text-align: right;
`;

const INCLUDE_REFERENCE_PARENTS_BASE_CONFIG = {
  id: 'includeReferenceParents',
  label: 'Include reference parents',
  iconName: IconName.INCLUDE_REFERENCE_PARENTS,
  iconSize: IconSize.REGULAR,
};

type IncludeReferenceParentsSliderArgs = {
  referenceParentMaxDepth: number;
  referenceChildMaxDepth: number;
  showChildDegreesSlider: boolean;
  degreesOfParenthood: number;
  degreesOfChildhood: number;
};

const includeReferenceParentsSlider = ({
  referenceParentMaxDepth,
  referenceChildMaxDepth,
  degreesOfParenthood,
  degreesOfChildhood,
  showChildDegreesSlider,
}: IncludeReferenceParentsSliderArgs): DropdownConfig => {
  const getSliderOption = createDropdownSliderOptionManager(
    ViewIds.DEPENDENCY_MAP_2,
    new Map(),
    onViewSettingsUpdate
  );
  const [parentDegreesSlider, childDegreesSlider] = [
    {
      name: 'degreesOfParenthood',
      label: <DegreesLabelContainer>Parent degrees:</DegreesLabelContainer>,
      degrees: degreesOfParenthood,
      maxDegrees: referenceParentMaxDepth,
    },
    {
      name: 'degreesOfChildhood',
      label: <DegreesLabelContainer>Child degrees:</DegreesLabelContainer>,
      degrees: degreesOfChildhood,
      maxDegrees: referenceChildMaxDepth,
    },
  ].map(({ name, label, degrees, maxDegrees }) =>
    getSliderOption({
      name,
      label,
      value: Number.isFinite(degrees) ? degrees : maxDegrees,
      min: 0,
      max: maxDegrees,
      step: 1,
      substituteMax: INFINITE_DEGREES,
      substituteMaxLabel: `Unlimited`,
      type: DropdownOptionType.SLIDER,
      isDisabled: !maxDegrees,
    })
  );
  const isDisabled = !referenceParentMaxDepth && !referenceChildMaxDepth;
  return {
    ...INCLUDE_REFERENCE_PARENTS_BASE_CONFIG,
    type: SettingsType.DROPDOWN,
    isKeepOpen: true,
    isDisabled,
    popoverId: isDisabled ? REFERENCE_PARENTS_DISABLED_POPOVER : undefined,
    isActive: Boolean(referenceParentMaxDepth && degreesOfParenthood),
    options: showChildDegreesSlider
      ? [parentDegreesSlider, childDegreesSlider]
      : [parentDegreesSlider],
  };
};

export default includeReferenceParentsSlider;
