import { APIReferenceType, ArdoqId } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import {
  EdgeStyleBase,
  GeneralPath,
  IEdge,
  IRenderContext,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { GraphEdge, PARENT_CHILD_LINE_TYPE } from '@ardoq/graph';
import { lineTypeDashArrays } from 'tabview/canvasRendering/consts';
import { referenceInterface } from '@ardoq/reference-interface';
import { SMOOTH_PATH_OPTIONS } from 'tabview/relationshipDiagrams/consts';
import { createSvgElement } from '@ardoq/dom-utils';

const render = (
  element: SVGElement,
  edge: IEdge,
  referenceTypes: Map<ArdoqId, APIReferenceType>
) => {
  const graphEdge = edge.tag as GraphEdge;
  if (!graphEdge) {
    element.setAttribute('d', '');
    return element;
  }
  const path = new GeneralPath();
  path.moveTo(edge.sourcePort!.location);
  edge.bends.forEach(({ location }) => path.lineTo(location));
  path.lineTo(edge.targetPort!.location);
  const { stroke = colors.black } = referenceInterface.getCssColors(
    graphEdge.modelId,
    {
      useAsBackgroundStyle: false,
    }
  ) ?? { stroke: colors.black };

  const lineType =
    referenceTypes.get(graphEdge.modelId)?.line ?? PARENT_CHILD_LINE_TYPE; // infer if there's no modelType, then this is a parent-child reference
  const dashArray = lineTypeDashArrays[lineType];

  element.setAttribute(
    'd',
    path.createSmoothedPath(SMOOTH_PATH_OPTIONS).createSvgPathData()
  );
  element.setAttribute('stroke', stroke);
  element.setAttribute('fill', 'none');
  element.setAttribute('marker-start', `url(#${graphEdge.getLineBeginning()})`);
  element.setAttribute('marker-end', `url(#${graphEdge.getLineEnding()})`);
  element.setAttribute('stroke-dasharray', `${dashArray}`);
  return element;
};

class ProteanEdgeStyle extends EdgeStyleBase {
  constructor(private referenceTypes: Map<ArdoqId, APIReferenceType>) {
    super();
  }
  protected override createVisual(
    context: IRenderContext,
    edge: IEdge
  ): Visual | null {
    const element = render(createSvgElement('path'), edge, this.referenceTypes);
    if (!element) {
      return null;
    }
    return new SvgVisual(element);
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    edge: IEdge
  ): Visual | null {
    render((oldVisual as SvgVisual).svgElement, edge, this.referenceTypes);
    return oldVisual;
  }
}

export default ProteanEdgeStyle;
