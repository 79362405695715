import { useEffect, useRef } from 'react';
import { useResizeObserver } from '@ardoq/hooks';
import { defaults } from 'lodash';
import useTreemapLayout from '../hooks/useTreemapLayout';
import useComputedStyle from '../hooks/useComputedStyle';
import type {
  MouseEventHandler,
  PositionAndSize,
  TemplateLayoutOptions,
  TemplateLayoutProps,
  TemplateLayoutStreamedProps,
  TreemapEntity,
} from '../types';
import {
  EntityBackground,
  TreemapEntityComponent,
  TreemapLayoutWrapper,
} from './atoms';
import OptionalContainer from './OptionalContainer';
import { ONE_REM } from '../constants';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import ComponentLabelDecorator from './ComponentLabelDecorator';
import { clearStringWidthCache } from '../getLabelText';
import { WithPopover } from '@ardoq/popovers';
import { PopoverContent } from './PopoverContent';
import { treemapCommands } from '../commands';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { getFocusedStream, getHoveredStream } from 'tabview/streams';
import { getIsHighlighted } from '../helpers';
import { combineLatest, map, Observable } from 'rxjs';
import { connect } from '@ardoq/rxbeach';

const VIEW_ID = ViewIds.TREEMAP;

const getMaxLabelWidth = (containerWidth: number) => {
  const paddingWidth = containerWidth / (containerWidth > 150 ? 3 : 4);
  return containerWidth - paddingWidth;
};

const defaultOptions: TemplateLayoutOptions = {
  padding: 0,
  innerPadding: 3,
};

const getPositionAndSize = (entity: TreemapEntity): PositionAndSize => ({
  left: entity.x0,
  top: entity.y0,
  width: entity.x1 - entity.x0,
  height: entity.y1 - entity.y0,
});

const isNotTooSmall = (dimensions: PositionAndSize, rem: number): boolean => {
  const { width, height } = dimensions;
  return width > 2 * rem && height > 2 * rem;
};

const handleEntityMouseOver: MouseEventHandler = (event, componentId) => {
  event.stopPropagation();
  treemapCommands.setHoveredItemState(componentId!);
};

const handleEntityClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  prevFocusedComponentId: ArdoqId | null,
  componentId: ArdoqId | null
) => {
  event.stopPropagation();
  treemapCommands.setFocusedItemState(
    componentId,
    prevFocusedComponentId === componentId
  );
};

const TreemapLayout = ({
  data,
  style = {},
  options: _options,
  hoveredComponentId,
  focusedComponentId,
}: TemplateLayoutProps) => {
  useEffect(() => clearStringWidthCache, []);
  const targetEl = useRef<HTMLDivElement>(null);
  const containerSize = useResizeObserver(targetEl);
  const { fontSize } = useComputedStyle(targetEl.current) ?? {
    fontSize: `${ONE_REM}px`,
  };

  const rem = parseInt(fontSize, 10);

  const options = defaults(_options, defaultOptions, { rem });

  const entities: TreemapEntity[] = useTreemapLayout(data, {
    ...containerSize,
    ...options,
  });
  const maxRootLabelWidth = getMaxLabelWidth(containerSize.width || 0);

  return (
    <TreemapLayoutWrapper
      ref={targetEl}
      style={style}
      onMouseLeave={() => treemapCommands.setHoveredItemState(null)}
    >
      <OptionalContainer
        data={data}
        maxLabelWidth={maxRootLabelWidth}
        labelFontSize={rem}
        hoveredComponentId={hoveredComponentId}
        focusedComponentId={focusedComponentId}
      >
        {entities.length > 0 &&
          entities.map((entity: TreemapEntity) => {
            const positionAndSize = getPositionAndSize(entity);
            const { data: component } = entity;
            const { displayOptions } = component;
            const isEntityNotTooSmall = isNotTooSmall(positionAndSize, rem);
            const maxLabelWidth = getMaxLabelWidth(positionAndSize.width);
            const isHighlighted = getIsHighlighted(
              component.id!,
              hoveredComponentId,
              focusedComponentId
            );
            const popoverContent = isEntityNotTooSmall
              ? null
              : () => PopoverContent({ component });

            return (
              <WithPopover key={component.id!} content={popoverContent}>
                <TreemapEntityComponent
                  key={component.id!}
                  className="component"
                  style={{
                    ...positionAndSize,
                    backgroundColor: displayOptions.backgroundColor,
                    color: displayOptions.color,
                  }}
                  $isSelected={displayOptions.isSelected}
                  $isHighlighted={isHighlighted}
                  onMouseOver={event =>
                    handleEntityMouseOver(event, component.id)
                  }
                  onClick={event =>
                    handleEntityClick(event, focusedComponentId, component.id)
                  }
                  onDoubleClick={() =>
                    treemapCommands.toggleSelectedItem(component.id!)
                  }
                  {...{ [COMPONENT_ID_ATTRIBUTE]: component.id }}
                >
                  <EntityBackground
                    $isHighlighted={isHighlighted}
                    $backgroundColor={displayOptions.backgroundColor}
                  />
                  {isEntityNotTooSmall ? (
                    <ComponentLabelDecorator
                      data={component}
                      maxLabelWidth={maxLabelWidth}
                      labelFontSize={rem}
                    />
                  ) : null}
                </TreemapEntityComponent>
              </WithPopover>
            );
          })}
      </OptionalContainer>
    </TreemapLayoutWrapper>
  );
};

const interactiveStates$: Observable<TemplateLayoutStreamedProps> =
  combineLatest([getHoveredStream(VIEW_ID), getFocusedStream(VIEW_ID)]).pipe(
    map(([hoveredComponentId, focusedComponentId]) => ({
      hoveredComponentId,
      focusedComponentId,
    }))
  );

const ConnectedTreemapLayout = connect<
  TemplateLayoutProps,
  TemplateLayoutStreamedProps
>(TreemapLayout, interactiveStates$);

export default ConnectedTreemapLayout;
