import { combineLatest, Observable } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { optimisticPresentations$ } from './presentations$';
import { map } from 'rxjs/operators';
import { APIPresentationAssetAttributes } from '@ardoq/api-types';

export const contextPresentation$: Observable<
  APIPresentationAssetAttributes | undefined
> = combineLatest({
  context: context$,
  presentations: optimisticPresentations$,
}).pipe(
  map(
    ({ context, presentations }) => presentations.byId[context.presentationId]
  )
);
