import { combineLatest, Observable } from 'rxjs';
import {
  action$,
  combineReducers,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import defaultState from 'views/defaultState';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { SpiderChartStreamedProps, SpiderChartViewSettings } from './types';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { bypassLimitAction } from './actions';
import { isPresentationMode } from 'appConfig';
import { ContextShape } from '@ardoq/data-model';
import { EMPTY_CONTEXT_SHAPE } from 'streams/context/ContextShape';
import { buildViewModel } from './buildViewModel';
import { ExtractStreamShape } from 'tabview/types';
import { focusedComponentChanged } from 'tabview/actions';

type SpiderChartViewStreamState = SpiderChartStreamedProps & {
  context: ContextShape;
};

const emptyState: SpiderChartViewStreamState = {
  viewSettings: defaultState.get(ViewIds.SPIDER),
  context: EMPTY_CONTEXT_SHAPE,
  viewModel: {
    sideways: false,
    workspaceId: '',
    numericFields: [],
    selectedFields: [],
    componentId: '',
    chartPoints: [],
    legendRows: [],
    focusedComponentId: null,
    errors: [],
  },
};

const reset = ({
  context,
  viewSettings,
  viewModel,
}: SpiderChartViewStreamState): SpiderChartViewStreamState => {
  if (!context.workspaceId) {
    return emptyState;
  }

  return {
    context,
    viewSettings,
    viewModel,
  };
};

const handleFocusedComponentChanged = (
  state: SpiderChartViewStreamState,
  focusedComponentId: ArdoqId | null
): SpiderChartViewStreamState => ({
  ...state,
  viewModel: {
    ...state.viewModel,
    focusedComponentId:
      focusedComponentId !== state.viewModel.focusedComponentId
        ? focusedComponentId
        : null,
  },
});

export const getViewModel$ = (
  viewState$: Observable<SpiderChartViewSettings>
) => {
  const reset$ = combineLatest([
    viewState$,
    context$,
    modelUpdateNotification$,
  ]);
  const resetReducer = (
    previousState: SpiderChartViewStreamState,
    [viewSettings, context]: ExtractStreamShape<typeof reset$>
  ) => {
    return reset({
      context,
      viewSettings,
      viewModel: buildViewModel({
        componentId: context.componentId,
        workspaceId: context.workspaceId,
        focusedComponentId: previousState.viewModel.focusedComponentId,
        viewSettings,
        bypassLimit: isPresentationMode(),
      }),
    });
  };

  const bypassLimitReducer = (state: SpiderChartViewStreamState) => {
    return {
      ...state,
      viewModel: buildViewModel({
        componentId: state.context.componentId,
        workspaceId: state.context.workspaceId,
        focusedComponentId: state.viewModel.focusedComponentId,
        viewSettings: state.viewSettings,
        bypassLimit: true,
      }),
    };
  };

  return action$.pipe(
    combineReducers<SpiderChartViewStreamState>(emptyState, [
      streamReducer(reset$, resetReducer),
      reducer(focusedComponentChanged, handleFocusedComponentChanged),
      reducer(bypassLimitAction, bypassLimitReducer),
    ])
  );
};
