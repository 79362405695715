import { ArdoqId, PersistedMetamodel } from '@ardoq/api-types';
import type { MetamodelContext } from 'metamodel/types';

export enum MetamodelPane {
  LIST = 'metamodel-list',
  EDIT = 'metamodel-edit',
  SELECTED = 'selected-metamodel',
}

export enum InfoContainerWidth {
  WIDE = 960,
  NARROW = 720,
}

export interface MetamodelNavigationState {
  pane: MetamodelPane;
  metamodel?: PersistedMetamodel;
  metamodelId?: ArdoqId;
  context: MetamodelContext;
  infoContainerIsOpen: boolean;
  infoContainerWidth: number;
}
