import { APIFieldType, GroupType, ViewIds } from '@ardoq/api-types';
import { Observable } from 'rxjs';
import { getRelationshipDiagramViewModel$ } from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramViewModel$';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import {
  ProteanDiagramViewProperties,
  ProteanDiagramViewSettings,
  ProteanLayoutType,
  ProteanRenderingMode,
} from '../types';
import { map } from 'rxjs/operators';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import {
  CollapsibleGraphGroup,
  GraphItemsModel,
  GraphNode,
  WORKSPACE_ICON_REPRESENTATION,
  addedAndUpdatedGraphEdges,
} from '@ardoq/graph';
import { RepresentationData } from '@ardoq/data-model';
import { canvasResolveImage } from 'tabview/canvasRendering/canvasResolvedImages';
import graphEdgesToReferenceTypes from 'tabview/relationshipDiagrams/graphEdgesToReferenceTypes';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Point } from '@ardoq/yfiles';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { getComponentTypesForLegend } from '@ardoq/view-legend';

const allNodesReducer = (
  acc: (CollapsibleGraphGroup | GraphNode)[],
  graphItemsModel: GraphItemsModel<CollapsibleGraphGroup | GraphNode>
) => {
  const byId = graphItemsModel.byId;
  const ids = [...graphItemsModel.add, ...graphItemsModel.update];
  ids.reduce((items: (CollapsibleGraphGroup | GraphNode)[], currentId) => {
    const node = byId.get(currentId);
    if (node) {
      items.push(node);
    }
    return items;
  }, acc);
  return acc;
};

export const getViewModel$ =
  (viewId = ViewIds.PROTEAN_DIAGRAM) =>
  (viewInstanceId: string): Observable<ProteanDiagramViewProperties> => {
    const relationshipDiagramViewModel$ = getRelationshipDiagramViewModel$({
      viewId,
      viewSettings$:
        getViewSettingsStreamWithChanges<ProteanDiagramViewSettings>(viewId),
      viewSettingsToIgnore: ['layoutType', 'layoutOptions'],
      shouldCollapseGraph: true,
    });
    return relationshipDiagramViewModel$.pipe(
      map(relationshipDiagram => {
        const {
          viewSettings,
          viewModel,
          streamState: { context },
        } = relationshipDiagram;
        const { nodes, groups } = viewModel;
        const allLinks = addedAndUpdatedGraphEdges(viewModel);
        const { referenceTypes } = graphEdgesToReferenceTypes(allLinks);
        const numericFields = fieldInterface.getFieldsOfWorkspace(
          context.workspaceId,
          [APIFieldType.NUMBER]
        );
        const allNodes = [nodes, groups].reduce(allNodesReducer, []);
        const nodePositions =
          viewSettings.layoutType === ProteanLayoutType.SpatialMap ||
          viewSettings.layoutType === ProteanLayoutType.GeographicMap
            ? new Map(
                allNodes
                  .map(node => {
                    if (!node.isComponent()) {
                      return null;
                    }
                    const [xValue, yValue] = [
                      viewSettings.layoutOptions.spatialMap
                        ?.selectedFieldNameX ?? '',
                      viewSettings.layoutOptions.spatialMap
                        ?.selectedFieldNameY ?? '',
                    ].map(
                      fieldName =>
                        (componentInterface.getFieldValue(
                          node.modelId,
                          fieldName
                        ) as number | null) ?? 0
                    );
                    const result: [string, Point] = [
                      node.id,
                      new Point(xValue, yValue),
                    ];
                    return result;
                  })
                  .filter(ExcludeFalsy)
              )
            : new Map();

        const componentTypes = getComponentTypesForLegend(
          allNodes
            .filter(node => node.isComponent())
            .map(({ modelId }) => modelId)
        );

        if (viewSettings.renderingMode !== ProteanRenderingMode.Canvas) {
          const proteanProps: ProteanDiagramViewProperties = {
            ...relationshipDiagram,
            viewInstanceId,
            nodeRepresentationData: new Map(),
            referenceTypes,
            numericFields,
            nodePositions,
            isViewpointMode: loadedGraph$.state.isViewpointMode,
            componentTypes,
          };
          return proteanProps;
        }

        const nodeRepresentationData = allNodes.reduce(
          (acc: Map<string, RepresentationData>, node) => {
            const representationData: RepresentationData | null =
              node.isComponent()
                ? componentInterface.getRepresentationData(node.modelId)
                : node instanceof CollapsibleGraphGroup &&
                    node.type === GroupType.WORKSPACE
                  ? WORKSPACE_ICON_REPRESENTATION
                  : null;
            if (!representationData || !representationData.isImage) {
              return acc;
            }
            acc.set(node.id, representationData);
            canvasResolveImage(representationData);
            return acc;
          },
          new Map()
        );

        const proteanProps: ProteanDiagramViewProperties = {
          ...relationshipDiagram,
          viewInstanceId,
          nodeRepresentationData,
          referenceTypes,
          numericFields,
          nodePositions,
          isViewpointMode: loadedGraph$.state.isViewpointMode,
          componentTypes,
        };
        return proteanProps;
      })
    );
  };
