import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { urlUtils } from '@ardoq/common-helpers';
import {
  APIFieldAttributes,
  APIFieldType,
  isContentTypeSupportedByImagePreview,
} from '@ardoq/api-types';
import FormattedDate from 'atomicComponents/FormattedDate';
import { formatDateOnly, parseDate } from '@ardoq/date-time';
import { format } from 'utils/numberUtils';
import Markdown from 'atomicComponents/Markdown';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { NoValue } from '@ardoq/renderers';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { FieldValues } from './types';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';
import { DateRangeFieldValues, dateRangeOperations } from '@ardoq/date-range';
import { logError } from '@ardoq/logging';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { getCurrentLocale } from '@ardoq/locale';
import { Checkbox } from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { Link } from '@ardoq/typography';
import { showImagePreviewModal } from '@ardoq/image-preview';

const getFileComponent = (id: string) => {
  const file = documentArchiveInterface.getAttachmentById(id);

  if (!file) return `${id} (File not found)`;

  const isSupportedByImagePreview = isContentTypeSupportedByImagePreview(file);

  if (!isSupportedByImagePreview) {
    return (
      <Link
        hideIcon
        underlineOnHover
        href={file.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {file.filename}
      </Link>
    );
  }

  return (
    <Link
      hideIcon
      underlineOnHover
      onClick={() => {
        showImagePreviewModal({
          title: file.filename,
          url: file.uri,
          link: { href: file.uri, label: 'Download' },
        });
      }}
    >
      {file.filename}
    </Link>
  );
};

const getUser = (
  modelId: string,
  field: Partial<APIFieldAttributes>,
  val: string
) => {
  const { name, email } = getNameAndEmail(modelId, field, val);

  return email ? (
    <a
      href={`mailto:${window.encodeURI(email)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name || email}
    </a>
  ) : (
    name
  );
};

const getNameAndEmail = (
  modelId: string,
  field: Partial<APIFieldAttributes>,
  val: string
) => {
  const user = orgUsersInterface.getUserById(val);
  const attributeGetter = getAttributeGetter(modelId);

  if (user) {
    return { name: user.name, email: user.email };
  }

  if (field.name === 'created-by') {
    const { createdByName: name, createdByEmail: email } = attributeGetter(
      modelId,
      ['createdByName', 'createdByEmail']
    );
    return { name, email };
  }

  if (field.name === 'last-modified-by') {
    const { lastModifiedByName: name, lastModifiedByEmail: email } =
      attributeGetter(modelId, ['lastModifiedByName', 'lastModifiedByEmail']);
    return { name, email };
  }

  return { name: 'User info missing' };
};

const getEmail = (
  modelId: string,
  field: Pick<APIFieldAttributes, 'name'>,
  val: string
) => {
  const attributeGetter = getAttributeGetter(modelId);

  let label = val;
  if (field.name === 'createdByEmail') {
    const { createdByName } = attributeGetter(modelId, ['createdByName']);
    label = createdByName ?? '';
  } else if (field.name === 'lastModifiedByEmail') {
    const { lastModifiedByName } = attributeGetter(modelId, [
      'lastModifiedByName',
    ]);
    label = lastModifiedByName ?? '';
  }
  return (
    <a
      href={`mailto:${window.encodeURI(val)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

const getAttributeGetter = (modelId: string) =>
  componentInterface.isComponent(modelId)
    ? componentInterface.getAttributes
    : referenceInterface.getAttributes;

const getUrl = (val: string) => (
  <a
    href={urlUtils.processUrl(
      val.indexOf('://') > 0 || val.indexOf('//') === 0
        ? val
        : `http://${val.replace(/^\//, '')}`
    )}
    target="_blank"
    rel="noopener noreferrer"
  >
    {val}
  </a>
);

const getDateRange = ([startDate, endDate]: DateRangeFieldValues) => {
  const locale = getCurrentLocale();

  return startDate || endDate
    ? `${startDate ? formatDateOnly(startDate, locale) : 'No start date'} - ${
        endDate ? formatDateOnly(endDate, locale) : 'No end date'
      }`
    : '';
};

const FieldValueFormatWarningConatiner = styled.div`
  display: flex;
  align-items: center;
`;

const FieldValueFormatWarning = ({ value }: { value: unknown }) => {
  const popoverMessage = `Something is wrong. Field value "${value}" shouldn't have this format. This event has been logged, thank you.`;
  return (
    <FieldValueFormatWarningConatiner>
      {String(value)}
      <WithPopover content={popoverMessage}>
        <Icon
          iconName={IconName.WARNING}
          color={colors.yellow60}
          iconSize={IconSize.SMALL}
          style={{ marginLeft: 8 }}
        />
      </WithPopover>
    </FieldValueFormatWarningConatiner>
  );
};

type FormatFieldContentArgs = {
  value: string | number | string[] | DateRangeFieldValues | undefined | null;
  modelId: string;
  field: Pick<APIFieldAttributes, 'name' | 'type' | 'numberFormatOptions'>;
  isLocalDateFormat?: boolean;
};
export const formatFieldContent = ({
  value,
  modelId,
  field,
  isLocalDateFormat,
}: FormatFieldContentArgs) => {
  const locale = getCurrentLocale();

  if (
    (value === undefined || value === null) &&
    field.type !== APIFieldType.CHECKBOX
  ) {
    return '';
  }

  // #region this is a temporary logging solution to find out why we get an invalid date time vaue
  // https://ardoqcom.atlassian.net/browse/ARD-20106
  // reevaluate/delete after 2024-02-01
  if (field.type === APIFieldType.DATE_TIME && value) {
    try {
      parseDate(value as string).toString();
    } catch (dateError) {
      logError(dateError as Error, 'Invalid date time value', {
        value,
        modelId,
        field,
      });

      if (Array.isArray(value) && value.length === 2) {
        try {
          const [start, end] = value as DateRangeFieldValues;
          if (typeof start === 'string') {
            parseDate(start).toString();
          }
          if (typeof end === 'string') {
            parseDate(end).toString();
          }
        } catch (dateRangeError) {
          logError(dateRangeError as Error, 'Invalid date time value', {
            value,
            modelId,
            field,
          });
          return 'Invalid date format and type for date range fields';
        }
        return getDateRange(value as DateRangeFieldValues);
      }

      return 'Invalid date value format for single date field';
    }
  }
  // #endregion

  switch (field.type) {
    case APIFieldType.DATE_ONLY:
      return formatDateOnly(value as string, locale);
    case APIFieldType.DATE_TIME:
      return (
        <FormattedDate
          date={value as string}
          isLocalDateFormat={isLocalDateFormat}
        />
      );
    case APIFieldType.NUMBER:
      return format(value as number, field.numberFormatOptions);
    case APIFieldType.EMAIL:
      return getEmail(modelId, field, value as string);
    case APIFieldType.CHECKBOX:
      return typeof value === 'undefined' || value === null ? (
        <FlexBox>
          <Checkbox isChecked={null} />
          <FlexBox align="center">
            <NoValue />
          </FlexBox>
        </FlexBox>
      ) : (
        <Checkbox isChecked={Boolean(value)} />
      );
    case APIFieldType.URL:
      return getUrl(value as string);
    case APIFieldType.USER:
      return getUser(modelId, field, value as string);
    case APIFieldType.FILE:
      return getFileComponent(value as string);
    case APIFieldType.TEXT_AREA:
      return <Markdown>{value as string}</Markdown>;
    case APIFieldType.DATE_ONLY_RANGE:
      return getDateRange(value as DateRangeFieldValues);
    case APIFieldType.DATE_TIME_RANGE:
      return getDateRange(value as DateRangeFieldValues);
    case APIFieldType.SELECT_MULTIPLE_LIST:
      // adding logging and error handling for https://ardoqcom.atlassian.net/browse/ARD-20653
      if (!Array.isArray(value)) {
        logError(
          new Error('Invalid value for select multiple list field'),
          'Invalid value for select multiple list field',
          { value, modelId, field }
        );
        return <FieldValueFormatWarning value={value} />;
      }
      return value.join(', ');

    default:
      // Adding this to make TypeScript happy.
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
  }
};

/**
 * Gets all tags for the model and returns an array with thier ids, null if empty.
 */
const getTagFieldValue = (modelId: string) => {
  const tagIds = tagInterface.getTagsForModel(modelId).map(({ id }) => id);
  return tagIds.length ? tagIds : null;
};

export const getFieldValues = (
  modelId: string,
  fields: Array<
    | Pick<APIFieldAttributes, 'name' | '_id' | 'label' | 'type'>
    | APIFieldAttributes
  >
) => {
  const modelIsComponent = componentInterface.isComponent(modelId);
  const attributeGetter = modelIsComponent
    ? componentInterface.getAttributes
    : referenceInterface.getAttributes;

  const simpleAttributes: string[] = [];

  const specialFieldValues = fields.reduce((acc: FieldValues, field) => {
    if (dateRangeOperations.isDateRangeField(field)) {
      // date range fields have a special format
      const { start, end } = field.dateTimeFields;
      const { [start.name]: startValue, [end.name]: endValue } =
        attributeGetter(modelId, [start.name, end.name]);
      acc[field.name] = startValue || endValue ? [startValue, endValue] : null;
    } else if (field.name === TAGS_FIELD_NAME) {
      // tags aren't attributes
      acc[field.name] = getTagFieldValue(modelId);
    } else {
      simpleAttributes.push(field.name);
    }

    return acc;
  }, {});

  const simpleFieldValues = attributeGetter(modelId, simpleAttributes);

  if (!modelIsComponent) {
    const referenceType = referenceInterface.getGlobalReferenceType(modelId);
    simpleFieldValues.type = referenceType?.name;
  }

  return { ...simpleFieldValues, ...specialFieldValues };
};
