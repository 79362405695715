import { ArdoqId } from '@ardoq/api-types';
import {
  type ComponentTypeInfo,
  componentUrlFieldValues,
  getUrlFieldsByComponentType,
  type ReferenceTypeInfo,
  getUrlFieldsByReferenceType,
  referenceUrlFieldValues,
} from '@ardoq/graph';

interface UrlFieldValuesArgs {
  componentTypes: ComponentTypeInfo[];
  referenceTypes: ReferenceTypeInfo[];
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
}
const urlFieldValues = ({
  componentTypes,
  referenceTypes,
  componentIds,
  referenceIds,
}: UrlFieldValuesArgs) => {
  const urlFieldsByComponentType = getUrlFieldsByComponentType(componentTypes);
  const urlFieldsByReferenceType = getUrlFieldsByReferenceType(referenceTypes);
  return {
    urlFieldValuesByComponentId: componentUrlFieldValues(
      componentIds,
      urlFieldsByComponentType
    ),
    urlFieldValuesByReferenceId: referenceUrlFieldValues(
      referenceIds,
      urlFieldsByReferenceType
    ),
  };
};

export default urlFieldValues;
