import { TabularLayoutOptions } from 'tabview/proteanDiagram/types';
import {
  type GraphItem,
  mapByExcludingNull,
  type RelationshipDiagramViewModel,
} from '@ardoq/graph';
import {
  combineTabularConstraints,
  nodesAndGroupsFromGraphViewDataModel,
} from './util';
import { partition } from 'lodash';
import {
  ConstraintsMap,
  ConstraintSource,
  ResolvedLayoutConstraint,
} from 'tabview/proteanDiagram/view/types';
import twoKeySet from 'tabview/proteanDiagram/intentionalLayout/layoutStage/twoKeySet';

const GOLDEN_RATIO = 1.618033988;
const goldenRatioRectangleDimensions = (area: number) => {
  const height = Math.sqrt(area / GOLDEN_RATIO);
  const width = area / height;
  return { width, height };
};

const resolveTabularConstraints = (
  {
    columnConstraintsWithSource = [],
    rowConstraintsWithSource = [],
    columnSpans = [],
    rowSpans = [],
    layoutRules = [],
  }: Pick<
    TabularLayoutOptions,
    | 'layoutRules'
    | 'columnConstraintsWithSource'
    | 'rowConstraintsWithSource'
    | 'rowSpans'
    | 'columnSpans'
  >,
  viewModel: RelationshipDiagramViewModel
) => {
  const [rowConstraintRules, columnConstraintRules] = partition(
    layoutRules.filter(
      ({ dimension }) => dimension === 'row' || dimension === 'column'
    ),
    ({ dimension }) => dimension === 'row'
  );
  const graphNodes = nodesAndGroupsFromGraphViewDataModel(viewModel);

  const rowConstraintsMap = combineTabularConstraints(
    rowConstraintsWithSource,
    rowConstraintRules,
    graphNodes
  );
  const columnConstraintsMap = combineTabularConstraints(
    columnConstraintsWithSource,
    columnConstraintRules,
    graphNodes
  );

  const [rowSpansMap, columnSpansMap] = [rowSpans, columnSpans].map(
    spans =>
      new Map(
        spans.map(([nodeId, value]) => [
          nodeId,
          [
            nodeId,
            value,
            value,
            ConstraintSource.VIEW_SETTINGS,
          ] satisfies ResolvedLayoutConstraint,
        ])
      )
  );

  return arrangeNodesInGrid({
    graphNodes,
    rowConstraintsMap,
    columnConstraintsMap,
    rowSpansMap,
    columnSpansMap,
  });
};

type ArrangeNodesInGridArgs = {
  graphNodes: GraphItem[];
  rowConstraintsMap: ConstraintsMap;
  columnConstraintsMap: ConstraintsMap;
  rowSpansMap: ConstraintsMap;
  columnSpansMap: ConstraintsMap;
};
const arrangeNodesInGrid = ({
  graphNodes,
  rowConstraintsMap,
  columnConstraintsMap,
  rowSpansMap,
  columnSpansMap,
}: ArrangeNodesInGridArgs) => {
  const groupChildren = mapByExcludingNull(
    graphNodes,
    graphNode => graphNode.parent
  );
  const oneNodePerCell = (items: GraphItem[]) => {
    const occupiedConstraints = twoKeySet<number, number>();

    // add cells occupied by nodes that were moved by drag to the occupied set to prevent other nodes from being placed there.
    items.forEach(item => {
      const { id } = item;
      const [, , colValue, colSource] = columnConstraintsMap.get(id) || [];
      if (colSource !== ConstraintSource.MOVED_BY_DND) {
        return;
      }

      const [, , rowValue] = rowConstraintsMap.get(id) || [];
      const [, , rowSpanValue] = rowSpansMap.get(id) || [];
      const [, , columnSpanValue] = columnSpansMap.get(id) || [];

      const startRow = rowValue || 0;
      const startColumn = colValue || 0;
      const endRow = startRow + (rowSpanValue || 1);
      const endColumn = startColumn + (columnSpanValue || 1);
      for (let yy = startRow; yy <= endRow; yy++) {
        for (let xx = startColumn; xx <= endColumn; xx++) {
          occupiedConstraints.add(rowValue || 0, colValue || 0);
        }
      }
    });
    const maxColumn = Math.floor(
      goldenRatioRectangleDimensions(items.length).width
    );
    items.forEach(item => {
      const { id } = item;
      // #region make sure this node has a constraints entry
      [rowConstraintsMap, columnConstraintsMap].forEach(constraintsMap => {
        if (!constraintsMap.has(id)) {
          constraintsMap.set(id, [id, 0, 0, ConstraintSource.GENERATED]);
        }
      });
      // #endregion
      const rowConstraint = rowConstraintsMap.get(id)!;
      const columnConstraint = columnConstraintsMap.get(id)!;
      if (columnConstraint[3] !== ConstraintSource.MOVED_BY_DND) {
        while (occupiedConstraints.has(rowConstraint[2], columnConstraint[2])) {
          const nextColumn = columnConstraint[2] + 1;
          if (nextColumn <= maxColumn) {
            columnConstraint[2]++;
          } else {
            rowConstraint[2]++;
            columnConstraint[2] = 0;
          }
        }
      }
      const rowSpan = rowSpansMap.get(id) ?? [
        id,
        1,
        1,
        ConstraintSource.GENERATED,
      ];
      const columnSpan = columnSpansMap.get(id) ?? [
        id,
        1,
        1,
        ConstraintSource.GENERATED,
      ];
      const [, , rowSpanValue] = rowSpan;
      const [, , columnSpanValue] = columnSpan;
      const startRow = rowConstraint[2];
      const startColumn = columnConstraint[2];
      const endRow = startRow + rowSpanValue - 1;
      const endColumn = startColumn + columnSpanValue - 1;
      for (let yy = startRow; yy <= endRow; yy++) {
        for (let xx = startColumn; xx <= endColumn; xx++) {
          occupiedConstraints.add(yy, xx);
        }
      }

      // #region update the display value with whatever is appropriate.
      rowConstraint[1] = rowConstraint[2];
      columnConstraint[1] = columnConstraint[2];
      // #endregion
      const children = groupChildren.get(item);
      if (children?.length) {
        oneNodePerCell(children);
      }
    });
  };
  const rootNodes = graphNodes.filter(({ parent }) => !parent);
  oneNodePerCell(rootNodes);

  return {
    rowConstraintsMap,
    columnConstraintsMap,
    rowSpansMap,
    columnSpansMap,
  };
};

export default resolveTabularConstraints;
