import { useEffect, useRef } from 'react';
import {
  BlocksViewProperties,
  Controller,
  InteractionMode,
  LayoutData,
} from '../types';
import getViewModel$ from '../viewModel$/viewModel$';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  canvasResolvedImageReady,
  svgImageReady,
} from 'tabview/canvasRendering/canvasResolvedImages';
import { connectInstance } from '@ardoq/rxbeach';
import { ViewCanvas, ZoomContainer } from 'tabview/relationshipDiagrams/atoms';
import { viewSettingsConsts } from '@ardoq/view-settings';
import {
  LayoutType,
  applyLayout,
  resetLayout,
  restoreLayout,
  saveLayout,
  fixupLayout,
} from '../layout/layout';
import { getLayoutData } from '../layout/util';
import { isEqual } from 'lodash';
import ViewContainer from 'tabview/ViewContainer';
import RelationshipsViewLegend from 'tabview/relationsD3View/hierarchical/RelationshipsViewLegend';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import { editInitialiseUndoStack } from '../layout/edit';
import { createController } from './controller';
import {
  createAndUpdateViewContext,
  RelationshipsViewLegendRef,
  updateController,
} from './viewContext';
import { useEffectOnce } from '@ardoq/hooks';
import { isPresentationMode } from 'appConfig';
import ZoomControls from 'atomicComponents/Zoomable/ZoomControls';
import { zoomToFitOnClick } from './editInteractions';
import EphemeralCarouselFeatures from 'ephemeralNotification/EphemeralCarouselFeatures';
import { EphemeralNotificationKeys } from 'ephemeralNotification/types';

const BlocksView = ({
  graph,
  viewSettings,
  componentTypes,
  referenceModelTypes,
  viewInstanceId,
}: BlocksViewProperties) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const controllerRef = useRef<Controller | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const legendRef = useRef<RelationshipsViewLegendRef>(null);
  const legendHeightOffset = useViewLegendSubscription();
  const layoutDataRef = useRef<LayoutData | null>(null);

  useEffectOnce(() => {
    const rerender = () => controllerRef.current?.renderCanvas();
    const subscriptions = [canvasResolvedImageReady, svgImageReady].map(
      rerenderAction => subscribeToAction(rerenderAction, rerender)
    );

    return () => {
      clearSubscriptions(subscriptions);
      if (controllerRef.current) {
        controllerRef.current.detach();
      }
    };
  });

  const canvas = canvasRef.current;
  useEffect(() => {
    if (graph) {
      if (controllerRef.current) {
        controllerRef.current.detach();
      }

      if (canvas) {
        const onAppRefresh = () =>
          updateController({
            controllerRef,
            containerRef,
            isLegendActive: legendRef.current?.getIsVisible(),
          });
        const newController = createController(
          canvas,
          graph,
          viewInstanceId,
          onAppRefresh
        );

        controllerRef.current = newController;
      }

      const restoredLayout = layoutDataRef.current;

      if (
        restoredLayout &&
        Object.keys(restoredLayout).length > 2 &&
        !isEqual(restoredLayout, getLayoutData(graph.root.children))
      ) {
        if (!restoreLayout(graph.root.children, restoredLayout)) {
          fixupLayout(graph.root);
        }
      } else {
        resetLayout(graph.root, LayoutType.Smart, false);
      }

      saveLayout(graph.root);
      applyLayout(graph);
      editInitialiseUndoStack();

      const controller = controllerRef.current!;

      const interactionMode = isPresentationMode()
        ? InteractionMode.View
        : InteractionMode.Edit;

      controller.fit(graph.root.bounds);
      controller.setInteractionMode(interactionMode);
      controller.renderCanvas();

      createAndUpdateViewContext({
        controllerRef,
        containerRef,
        isLegendActive: legendRef.current?.getIsVisible(),
      });
    }
  }, [graph, canvas, viewInstanceId]);

  useEffect(() => {
    layoutDataRef.current = viewSettings.layoutData;
    const isLegendActive = viewSettings[viewSettingsConsts.IS_LEGEND_ACTIVE];

    if (legendRef.current?.getIsVisible() !== isLegendActive) {
      legendRef.current?.setIsVisible(isLegendActive);
    }
    createAndUpdateViewContext({
      controllerRef,
      containerRef,
      isLegendActive,
    });
  }, [viewSettings]);

  const zoomCenter = () => {
    if (!graph || !controllerRef.current) {
      return;
    }
    zoomToFitOnClick(controllerRef.current, graph)();
  };

  return (
    <ViewContainer ref={containerRef}>
      <EphemeralCarouselFeatures
        ephemeralNotificationKey={
          EphemeralNotificationKeys.BLOCKS_VIEW_INTRODUCTION_MODAL
        }
      />
      <ViewCanvas
        ref={canvasRef}
        tabIndex={0}
        data-canvas-hit-test={viewInstanceId}
      />
      {isPresentationMode() && (
        <ZoomContainer>
          <ZoomControls
            zoomIn={() => controllerRef.current?.zoomIn()}
            zoomOut={() => controllerRef.current?.zoomOut()}
            zoomCenter={zoomCenter}
          />
        </ZoomContainer>
      )}

      <RelationshipsViewLegend
        initiallyVisible={viewSettings[viewSettingsConsts.IS_LEGEND_ACTIVE]}
        ref={legendRef}
        componentTypes={componentTypes}
        referenceTypes={referenceModelTypes}
        hasCollapsedNodes={false}
        hasNonComponentNodes={false}
        hasReferenceParents={false}
        isUserDefinedGrouping={false}
        showComponentSwatches={true}
        showReferenceConditionalFormatting={true}
        showComponentShapes={false}
        showReferencesAsLines={true}
        activeDiffMode={null}
        heightOffset={legendHeightOffset}
        selectableItems={false}
      />
    </ViewContainer>
  );
};

const ConnectedBlocksView = connectInstance(BlocksView, getViewModel$);
export default ConnectedBlocksView;
