import { s16, s4 } from '@ardoq/design-tokens';

export const COMPONENT_TREE_ROW_MAX_WIDTH = 500;
export const COMPONENT_TREE_ROW_LEFT_PADDING = s4;
export const COMPONENT_TREE_ROW_RIGHT_PADDING = s16;
export const COMPONENT_TREE_ROW_HORIZONTAL_PADDING =
  parseInt(COMPONENT_TREE_ROW_LEFT_PADDING, 10) +
  parseInt(COMPONENT_TREE_ROW_RIGHT_PADDING, 10);
export const COMPONENT_TREE_ROW_BORDER = 1;
export const COMPONENT_TREE_ICON_RIGHT_MARGIN = 4;
