import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getViewModel$ as getViewModelBase$ } from '../viewModel$';
import {
  IsometricBlockDiagramViewProperties,
  IsometricBlockDiagramViewSettings,
} from './types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { action$, ofType } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { APIFieldType } from '@ardoq/api-types';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { notifyComponentsUpdated } from 'streams/components/ComponentActions';
import { startAction } from 'actions/utils';

type BaseArgs = Parameters<typeof getViewModelBase$>[0];
const getViewModel$ =
  (
    args: Omit<BaseArgs, 'viewSettings$'> & {
      viewSettings$: Observable<
        ViewSettings<IsometricBlockDiagramViewSettings>
      >;
    }
  ) =>
  (viewInstanceId: string): Observable<IsometricBlockDiagramViewProperties> =>
    combineLatest([
      getViewModelBase$(args)(viewInstanceId),
      context$,
      action$.pipe(ofType(notifyComponentsUpdated), startWith(startAction())),
    ]).pipe(
      map(([props, context]) => {
        const viewSettings =
          props.viewSettings as IsometricBlockDiagramViewSettings;
        const allComponentNodes = [
          ...props.viewModel.nodes.add,
          ...props.viewModel.nodes.update,
        ]
          .map(nodeId => props.viewModel.nodes.byId.get(nodeId))
          .filter(ExcludeFalsy);
        const allValues = allComponentNodes
          .map(graphNode =>
            componentInterface.getFieldValue(
              graphNode.modelId,
              viewSettings.selectedFieldNameValue
            )
          )
          .filter((value): value is number => Number.isFinite(value));

        const selectedValueFieldDomain: [min: number, max: number] =
          !allValues.length
            ? [10, 10]
            : [Math.min(...allValues), Math.max(...allValues)];

        return {
          ...props,
          viewSettings,
          numericFields: fieldInterface.getFieldsOfWorkspace(
            context.workspaceId,
            [APIFieldType.NUMBER]
          ),
          selectedValueFieldDomain,
        };
      })
    );
export default getViewModel$;
