import styled from 'styled-components';

export const ReservedExpander = styled.div<{ $size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 2px;
  width: ${({ $size }) => $size}px;
`;
