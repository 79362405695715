import * as encodingUtils from '@ardoq/html';
import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import {
  IEdge,
  IEdgeStyle,
  IGraph,
  ILabel,
  ILabelOwner,
  IModelItem,
  INode,
  INodeStyle,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  Size,
} from '@ardoq/yfiles';
import { getDefaultSize, getStyle } from 'yfilesExtensions/styles/styleLoader';
import type { Edge, Node } from 'graph/types';
import { NodeLabelCreator } from 'tabview/graphComponent/graphBuilder/NodeLabelCreator';
import { EdgeLabelCreator } from 'tabview/graphComponent/graphBuilder/EdgeLabelCreator';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { GraphItem } from 'graph/GraphItem';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { CircularRelationshipDiagramViewModel } from './types';
import getNodeLayoutSize from 'yfilesExtensions/getNodeLayoutSize';
import { isContextNode } from 'yfilesExtensions/styles/nodeDecorator';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  NODE_HEIGHT,
  NODE_WIDTH,
} from 'yfilesExtensions/styles/consts';
import { DiffType } from '@ardoq/data-model';
import LegacyArdoqNodeStyleBase from 'yfilesExtensions/styles/LegacyArdoqNodeStyleBase';

const defaultSize = new Size(10, 10);

const isURLLabel = (label: ILabel) => {
  return label.style instanceof ArdoqURLLabelStyle;
};

const toGroupNode = (graphNode: Node) => ({
  tag: graphNode,
});

const defaultNodeLayout = (node: INode) => ({
  style: new ShapeNodeStyle({
    shape: ShapeNodeShape.ELLIPSE,
    fill: 'black',
  }),
  layout: new Rect(
    node.layout.x,
    node.layout.y,
    defaultSize.width,
    defaultSize.height
  ),
});

const styledNodeLayout = (node: INode): { style: INodeStyle; layout: Rect } => {
  const style = getStyle(node.tag);
  const size = (style as LegacyArdoqNodeStyleBase).size;
  const styleWidth = size?.width || getDefaultSize().width;
  const styleHeight = size?.height || getDefaultSize().height;
  const yAspectRatio = NODE_HEIGHT / styleHeight;
  const xAspectRatio = NODE_WIDTH / styleWidth;

  let width;
  let height;

  if (styleWidth * yAspectRatio > NODE_WIDTH) {
    width = NODE_WIDTH;
    height = styleHeight * xAspectRatio;
  } else {
    width = styleWidth * yAspectRatio;
    height = NODE_HEIGHT;
  }

  return {
    style,
    layout: new Rect(
      node.layout.topLeft,
      getNodeLayoutSize({
        styleWidth: width,
        styleHeight: height,
        hasContextHighlight: isContextNode(node),
        contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
        visualDiffType:
          node.tag instanceof GraphItem
            ? node.tag.getVisualDiffType()
            : DiffType.NONE,
      })
    ),
  };
};

const updateLabels = (
  graph: IGraph,
  node: ILabelOwner,
  graphNode: GraphItem
) => {
  const labelsToRemove: ILabel[] = [];
  node.labels.forEach(label => {
    if (label.tag === null) {
      graph.setLabelText(
        label,
        encodingUtils.unescapeHTML(graphNode.getLabel())
      );
    } else if (isURLLabel(label) && !label.tag.hasURLFields()) {
      labelsToRemove.push(label);
    }
  });
  labelsToRemove.forEach(label => graph.remove(label));
};

interface NodeData {
  tag: Node;
  labels: string[];
  parent?: INode;
}
const createNodeData = (graphNode: Node, yParent: INode | null): NodeData => {
  const result: NodeData = {
    tag: graphNode,
    labels: [encodingUtils.unescapeHTML(graphNode.getLabel())],
  };
  if (yParent) {
    result.parent = yParent;
  }
  return result;
};

const createNode = (
  graph: IGraph,
  graphNode: Node,
  yParent: INode | null,
  enableStyles: boolean
) => {
  const ynode = graph.createNode(createNodeData(graphNode, yParent));

  if (graphNode.hasURLFields()) {
    graph.addLabel(NodeLabelCreator.instance.createLabel(ynode, graphNode));
  }
  const { style, layout } = enableStyles
    ? styledNodeLayout(ynode)
    : defaultNodeLayout(ynode);

  graph.setStyle(ynode, style);
  graph.setNodeLayout(ynode, layout);

  return ynode;
};

const updateNode = (
  graph: IGraph,
  node: INode,
  graphNode: Node,
  enableStyles: boolean
) => {
  const { style, layout } = enableStyles
    ? styledNodeLayout(node)
    : defaultNodeLayout(node);
  graph.setStyle(node, style);
  graph.setNodeLayout(node, layout);

  if (
    graphNode.hasURLFields() &&
    !node.labels.some(label => isURLLabel(label))
  ) {
    graph.addLabel(NodeLabelCreator.instance.createLabel(node, graphNode));
  }

  updateLabels(graph, node, graphNode);
  return node;
};

const removeNodes = (
  graph: IGraph,
  ids: string[],
  nodesById: Map<string, IModelItem>
) => {
  ids
    .filter(id => nodesById.has(id))
    .forEach(id => {
      const node = nodesById.get(id);
      if (node) {
        graph.remove(node);
      }
    });
};

const getNodesInGraph = (graph: IGraph): Map<string, INode> =>
  new Map<string, INode>(
    graph.nodes.map<[string, INode]>(n => [n.tag.dataModel.id, n]).toArray()
  );

const addNodes = (
  graph: IGraph,
  nodes: GraphItemsModel<Node>,
  enableStyles: boolean
) => {
  const nodesInGraph = getNodesInGraph(graph);
  graph.nodes.forEach(n => {
    if (!graph.isGroupNode(n)) {
      nodesInGraph.set(n.tag.dataModel.id, n);
    }
  });
  const { add, update, remove, byId } = nodes;
  add
    .map(id => byId.get(id))
    .forEach(node => {
      if (!node) {
        return;
      }
      const yParent =
        (node.parent && nodesInGraph.get(dataModelId(node.parent))) || null;

      createNode(graph, node, yParent, enableStyles);
    });
  update.forEach(id => {
    const node = byId.get(id);
    if (!node) {
      return;
    }
    const ynode = nodesInGraph.get(id);
    if (ynode) {
      updateNode(graph, ynode, node, enableStyles);
    } else {
      const parent =
        (node.parent && nodesInGraph.get(dataModelId(node.parent))) || null;
      createNode(graph, node, parent, enableStyles);
    }
  });
  removeNodes(graph, remove, nodesInGraph);
};

const createEdgeData = (
  graphEdge: Edge,
  source: INode,
  target: INode
): {
  source: INode;
  target: INode;
  style?: IEdgeStyle;
  tag?: unknown;
  labels?: any[];
  ports?: any[];
  bends?: any[];
} => ({
  tag: graphEdge,
  labels: [encodingUtils.unescapeHTML(graphEdge.getLabel())],
  source,
  target,
});

const createEdge = (
  graph: IGraph,
  graphEdge: Edge,
  source: INode,
  target: INode
) => {
  const yedge = graph.createEdge(createEdgeData(graphEdge, source, target));
  if (graphEdge.hasURLFields()) {
    graph.addLabel(EdgeLabelCreator.instance.createLabel(yedge, graphEdge));
  }
  return yedge;
};

const updateEdge = (
  graph: IGraph,
  edge: IEdge,
  graphEdge: Edge,
  source: INode,
  target: INode
) => {
  if (
    graphEdge &&
    graphEdge.hasURLFields() &&
    !edge.labels.some(label => isURLLabel(label))
  ) {
    graph.addLabel(EdgeLabelCreator.instance.createLabel(edge, graphEdge));
  }

  if (!edge.sourcePort || !edge.targetPort) {
    return createEdge(graph, graphEdge, source, target);
  }

  updateLabels(graph, edge, graphEdge);
  return edge;
};

const sourceNode = (nodesInGraph: Map<string, INode>, edge: Edge) =>
  nodesInGraph.get(dataModelId(edge.sourceNode));

const targetNode = (nodesInGraph: Map<string, INode>, edge: Edge) =>
  nodesInGraph.get(dataModelId(edge.targetNode));

const addEdges = (graph: IGraph, edges: GraphItemsModel<Edge>) => {
  const nodesInGraph = getNodesInGraph(graph);

  const edgesInGraph = new Map<string, IEdge>(
    graph.edges.map<[string, IEdge]>(e => [e.tag.dataModel.id, e]).toArray()
  );

  const { add, update, remove, byId } = edges;

  add
    .map(id => byId.get(id))
    .forEach(edge => {
      if (!edge) {
        return;
      }
      const source = sourceNode(nodesInGraph, edge);
      const target = targetNode(nodesInGraph, edge);
      if (source && target) {
        createEdge(graph, edge, source, target);
      }
    });
  update.forEach(id => {
    const edge = byId.get(id);
    if (!edge) {
      return;
    }
    const yEdge = edgesInGraph.get(id);
    const source = sourceNode(nodesInGraph, edge);
    const target = targetNode(nodesInGraph, edge);
    if (!source || !target) {
      return;
    }
    if (yEdge) {
      updateEdge(graph, yEdge, edge, source, target);
    } else {
      createEdge(graph, edge, source, target);
    }
  });
  removeNodes(graph, remove, edgesInGraph);
};

const addGroups = (graph: IGraph, groupNodes: GraphItemsModel<Node>) => {
  const newMap = new Map<string, INode>();
  const groupsInGraph = new Map();
  graph.nodes.forEach(n => {
    if (graph.isGroupNode(n)) {
      groupsInGraph.set(n.tag.dataModel.id, n);
    }
  });
  const { add, update, byId } = groupNodes;

  add.forEach(id => {
    const groupNode = byId.get(id);
    if (groupNode) {
      newMap.set(id, graph.createGroupNode(toGroupNode(groupNode)));
    }
  });
  update.forEach(id => {
    const yGroup = groupsInGraph.get(id);
    const groupNode = yGroup || byId.get(id);
    if (groupNode) {
      newMap.set(id, yGroup || graph.createGroupNode(toGroupNode(groupNode)));
    }
  });

  [...newMap.values()].forEach(groupNode => {
    const parent =
      groupNode.tag.parent && newMap.get(groupNode.tag.parent.dataModel.id);
    if (parent) {
      graph.setParent(groupNode, parent);
    }
  });
};

export const build = (
  graph: IGraph,
  model: CircularRelationshipDiagramViewModel,
  enableStyles: boolean,
  forceRefresh: boolean
) => {
  if (forceRefresh) {
    graph.clear();
  }
  addGroups(graph, model.groups);
  addNodes(graph, model.nodes, enableStyles);
  addEdges(graph, model.edges);
};
