import { getIdealGridWidth } from './utils';
import {
  LayoutAnyRow,
  LayoutBoxType,
  LayoutBoxTypes,
  LayoutCenterColumn,
  LayoutNode,
  LayoutPriorityRow,
  TemporaryDict,
} from './types';
import { range } from 'lodash';
import { ReferenceDirection } from '@ardoq/api-types';
import { sum } from 'lodash';

const WEIGHT_EMPTY_CELLS = 1;
const WEIGHT_COLUMN_DEVIATION = 1.01;
const WEIGHT_ROW_DEVIATION = 1;

const getColumnCount = (cellCount: number, rowCount: number) =>
  cellCount > 0 && rowCount > 0 ? Math.ceil(cellCount / rowCount) : 0;

/**
 * Layout strategy
 *
 * For each core row calculate all the variations, e.g. a block with 6 leafs
 * can be layed out as 1x6, 2*5, 2*4, 2*3, 3*2, 4*2 or 6*1. That means get
 * the max count of rows and then calculate for each row hight:
 *
 *  - the empty cells (as an indicator of the compactness of the variation)
 *  - the deviation of the target column count
 *  - the deviation of the target row count
 *
 *  Based on these numbers (with according weighing) choose the best fit.
 */

const getBestFit = (row: number[], targetColCount: number) => {
  const targetRowCount = Math.round(sum(row) / targetColCount);
  const maxRowCount = Math.max(...row);
  return range(1, maxRowCount + 1)
    .map(rowCount => {
      const columnCounts = row.map(cellCount =>
        getColumnCount(cellCount, rowCount)
      );
      const totalColumnCount = sum(columnCounts);
      const emptyCellCount = sum(
        row.map(
          (cellCount, index) => rowCount * columnCounts[index] - cellCount
        )
      );
      const columnCountDeviation = Math.abs(targetColCount - totalColumnCount);
      const rowCountDeviation = Math.abs(targetRowCount - rowCount);
      const rating =
        emptyCellCount * WEIGHT_EMPTY_CELLS +
        columnCountDeviation * WEIGHT_COLUMN_DEVIATION +
        rowCountDeviation * WEIGHT_ROW_DEVIATION;

      return {
        inputRow: row,
        rowCount,
        columnCounts,
        total: emptyCellCount + columnCountDeviation + rowCountDeviation,
        rating,
      };
    })
    .reduce(
      (bestFit, candidate) =>
        candidate.rating < bestFit.rating ? candidate : bestFit,
      {
        rating: Infinity,
        columnCounts: [],
        inputRow: [],
        rowCount: 0,
        total: 0,
      }
    );
};

const layoutContainer: LayoutNode = ({
  node,
  targetGridWidth,
  isLayoutDirectionAnyVertical,
}) => {
  const {
    id,
    name,
    classNames,
    representationData,
    leavesCount,
    priorityValue,
    referencedDirection,
    referenceLabel,
    referenceFieldValue,
    referenceFieldLabel,
    isGrouping,
    collapsedDescendants,
    iconColor,
    isCollapsedReference,
  } = node;

  const isReferenced = referencedDirection !== ReferenceDirection.NONE;

  if (
    sum([
      node.priorityRows.length,
      node.supportingColumns.length,
      node.anyRow.length,
    ]) === 0
  ) {
    return {
      id,
      name,
      classNames,
      representationData,
      layoutType: isReferenced
        ? LayoutBoxTypes.LEAF_REFERENCED
        : LayoutBoxTypes.LEAF,
      layoutBoxes: [],
      targetGridWidth: 1,
      calculatedGridWidth: 0,
      leavesCount,
      hasPriorityValue: Boolean(priorityValue),
      referencedDirection,
      referenceLabel,
      referenceFieldValue,
      referenceFieldLabel,
      iconColor,
      isCollapsedReference,
    };
  }
  const layoutBox = layout({
    node,
    targetGridWidth,
    isLayoutDirectionAnyVertical,
  });
  return {
    id,
    name,
    classNames,
    representationData,
    layoutType: isGrouping ? LayoutBoxTypes.GROUP : LayoutBoxTypes.CONTAINER,
    layoutBoxes: [layoutBox],
    targetGridWidth,
    calculatedGridWidth: 0,
    leavesCount,
    hasPriorityValue: Boolean(priorityValue),
    hasCollapsedNodes: collapsedDescendants.length > 0,
    referencedDirection,
    referenceLabel,
    referenceFieldValue,
    referenceFieldLabel,
    iconColor,
  };
};

const layoutPriorityRow: LayoutPriorityRow = ({
  priorityRow,
  targetGridWidth,
  isLayoutDirectionAnyVertical,
}) => {
  const { columnCounts } = getBestFit(
    priorityRow.map(node => node.leavesCount),
    targetGridWidth
  );

  return {
    layoutType: LayoutBoxTypes.ROW,
    layoutBoxes: priorityRow.map((node, index) =>
      layoutContainer({
        node,
        targetGridWidth: columnCounts[index],
        isLayoutDirectionAnyVertical,
      })
    ),
    targetGridWidth: sum(columnCounts),
    calculatedGridWidth: 0,
    hasPriorityValue: true,
  };
};

const layoutAnyRow: LayoutAnyRow = ({
  anyRow,
  targetGridWidth,
  isLayoutDirectionAnyVertical,
}) => {
  return {
    layoutType: LayoutBoxTypes.ROW,
    layoutBoxes: anyRow.map(node =>
      layoutContainer({
        node,
        targetGridWidth: isLayoutDirectionAnyVertical
          ? 1
          : Math.min(node.leavesCount, targetGridWidth),
        isLayoutDirectionAnyVertical,
      })
    ),
    targetGridWidth,
    calculatedGridWidth: 0,
    hasPriorityValue: false,
  };
};

const layoutCenterColumn: LayoutCenterColumn = ({
  priorityRows,
  anyRow,
  targetGridWidth,
  isLayoutDirectionAnyVertical,
}) => {
  const rows = priorityRows.map(priorityRow =>
    layoutPriorityRow({
      priorityRow,
      targetGridWidth,
      isLayoutDirectionAnyVertical,
    })
  );
  if (anyRow.length) {
    rows.push(
      layoutAnyRow({ anyRow, targetGridWidth, isLayoutDirectionAnyVertical })
    );
  }

  return {
    layoutType: LayoutBoxTypes.COLUMN,
    layoutBoxes: rows,
    targetGridWidth,
    calculatedGridWidth: 0,
    hasPriorityValue: priorityRows.length > 0,
  };
};

type GetNodeToLayoutContainerArgs = {
  targetGridWidth: number;
  isLayoutDirectionAnyVertical: boolean;
};
const getNodeToLayoutContainer =
  ({
    targetGridWidth,
    isLayoutDirectionAnyVertical,
  }: GetNodeToLayoutContainerArgs) =>
  (node: TemporaryDict) =>
    layoutContainer({
      node,
      targetGridWidth,
      isLayoutDirectionAnyVertical,
    });

const layout: LayoutNode = ({
  node,
  targetGridWidth,
  isLayoutDirectionAnyVertical,
}) => {
  const { supportingColumns, priorityRows, anyRow } = node;

  const centerColumn = layoutCenterColumn({
    priorityRows,
    anyRow,
    targetGridWidth: targetGridWidth - supportingColumns.length,
    isLayoutDirectionAnyVertical,
  });

  if (supportingColumns.length === 0) {
    return centerColumn;
  }
  const halfCount = Math.round(supportingColumns.length / 2);

  const supportingColumnWidth =
    supportingColumns.length === 1 &&
    priorityRows.length === 0 &&
    anyRow.length === 0
      ? targetGridWidth
      : 1;

  const nodeToLayoutContainer = getNodeToLayoutContainer({
    targetGridWidth: supportingColumnWidth,
    isLayoutDirectionAnyVertical,
  });
  return {
    targetGridWidth,
    layoutType: LayoutBoxTypes.ROW,
    layoutBoxes: [
      ...supportingColumns.slice(0, halfCount).map(nodeToLayoutContainer),
      centerColumn,
      ...supportingColumns.slice(halfCount).map(nodeToLayoutContainer),
    ],
    hasPriorityValue: supportingColumns.length > 0 || priorityRows.length > 0,
    calculatedGridWidth: 0,
  };
};

const setCalculatedGridWidth = (layoutBox: LayoutBoxType) => {
  const { layoutBoxes, layoutType, targetGridWidth } = layoutBox;
  layoutBoxes.forEach(setCalculatedGridWidth);
  switch (layoutType) {
    case LayoutBoxTypes.COLUMN: {
      const priorityBoxes = layoutBoxes.filter(box => box.hasPriorityValue);
      layoutBox.calculatedGridWidth =
        priorityBoxes.length > 0
          ? Math.max(...priorityBoxes.map(box => box.calculatedGridWidth))
          : targetGridWidth;
      break;
    }

    case LayoutBoxTypes.ROW: {
      const priorityBoxes = layoutBoxes.filter(box => box.hasPriorityValue);
      layoutBox.calculatedGridWidth =
        priorityBoxes.length > 0
          ? sum(priorityBoxes.map(box => box.calculatedGridWidth))
          : targetGridWidth;
      break;
    }

    case LayoutBoxTypes.CONTAINER:
    case LayoutBoxTypes.GROUP:
      layoutBox.calculatedGridWidth = layoutBoxes[0].calculatedGridWidth;
      break;

    case LayoutBoxTypes.LEAF:
    case LayoutBoxTypes.LEAF_REFERENCED:
      layoutBox.calculatedGridWidth = 1;
      break;
  }
};

const MAX_DEVIATION_GRID_WIDTH = 1;

export const layoutRoot = (
  rootNode: TemporaryDict,
  isLayoutDirectionAnyVertical: boolean
) => {
  const { minGridWidth, leavesCount } = rootNode;
  const idealGridWidth = getIdealGridWidth(leavesCount);
  const deviation = (minGridWidth - idealGridWidth) / idealGridWidth;
  // The bigger the deviation is the less it contributes to the
  // grid target, maxing out at MAX_DEVIATION_GRID_WIDTH
  const targetGridWidth = Math.max(
    minGridWidth,
    deviation > 0
      ? Math.round(
          idealGridWidth *
            (1 +
              (1 - 1 / Math.E ** (deviation / MAX_DEVIATION_GRID_WIDTH)) *
                MAX_DEVIATION_GRID_WIDTH)
        )
      : idealGridWidth
  );

  const rootLayoutBox = layout({
    node: rootNode,
    targetGridWidth,
    isLayoutDirectionAnyVertical,
  });
  setCalculatedGridWidth(rootLayoutBox);

  return rootLayoutBox;
};
