import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { ConstraintConfig, ProteanGraphState } from '../types';
import { LayoutConstraint } from 'tabview/graphViews/types';
import incrementConstraint from '../incrementConstraint';
import onNewLayoutOptions from '../onNewLayoutOptions';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import { ProteanLayoutOptions } from 'tabview/proteanDiagram/types';

const constraintIncrementor =
  (
    state: ProteanGraphState,
    itemId: string,
    layoutOptionsKey: keyof ProteanLayoutOptions
  ) =>
  <K extends keyof T, T extends { [key in K]: LayoutConstraint[] }>(
    direction: 1 | -1,
    key: K,
    indexOfCurrentConstraint: number,
    options: T
  ) =>
  () => {
    const currentLayoutOptions = {
      ...PROTEAN_DEFAULT_LAYOUT_OPTIONS,
      ...state.viewSettings.layoutOptions,
    };
    const newConstraints = incrementConstraint(
      options[key],
      indexOfCurrentConstraint,
      direction,
      itemId
    );
    const layoutOptions = {
      ...currentLayoutOptions,
      [layoutOptionsKey]: {
        ...options,
        [key]: newConstraints,
      },
    };
    onNewLayoutOptions(layoutOptions, state);
  };

const getConstraintInfo = (constraints: LayoutConstraint[], itemId: string) => {
  const index = constraints.findIndex(([id]) => id === itemId);
  return { index, value: constraints[index]?.[1] };
};

const layoutConstraintSubmenu =
  <
    K extends keyof T,
    T extends {
      [key in K]: LayoutConstraint[];
    },
  >(
    labels: string[],
    keys: K[],
    layoutOptionsKey: keyof ProteanLayoutOptions
  ) =>
  (state: ProteanGraphState, itemId: string, options: T) => {
    const constraintInfo = keys.map(key =>
      getConstraintInfo(options[key], itemId)
    );
    const config = constraintInfo.map((currentConstraintInfo, ii) => ({
      label: labels[ii],
      key: keys[ii],
      index: currentConstraintInfo.index,
      current: currentConstraintInfo.value,
    }));

    const incrementItemConstraint = constraintIncrementor(
      state,
      itemId,
      layoutOptionsKey
    );
    const getSubmenu = ({
      label,
      key,
      index,
      current,
    }: ConstraintConfig<K, T>) => ({
      type: DropdownOptionType.SUBMENU,
      label: `${label} (${current ?? '0'})`,
      options: [
        {
          type: DropdownOptionType.BUTTON_OPTION,
          label: 'Increase',
          onClick: incrementItemConstraint(1, key, index, options),
        },
        {
          type: DropdownOptionType.BUTTON_OPTION,
          label: 'Decrease',
          onClick: incrementItemConstraint(-1, key, index, options),
        },
      ],
    });

    return config.map(getSubmenu);
  };
export default layoutConstraintSubmenu;
