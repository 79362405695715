import { colors } from '@ardoq/design-tokens';
import { IconButtonState, IconName } from '@ardoq/icons';
import { createSvgElement } from '@ardoq/dom-utils';
import materialIconsText from 'tabview/materialIconsText';

const svgIconButton = (
  iconName: IconName,
  radius: number,
  state: IconButtonState,
  attributes: Record<string, string>
) => {
  const container = createSvgElement('g', {
    stroke: colors.grey50,
    ...attributes,
  });
  const iconButtonCircle = createSvgElement('circle', {
    'stroke-width': '2px',
    cx: `${radius}`,
    cy: `${radius}`,
    r: `${radius - 1}`,
  });
  switch (state) {
    case 'initial':
      iconButtonCircle.style.fill = colors.white;
      iconButtonCircle.style.stroke = colors.grey80;
      break;
    case 'hover':
      iconButtonCircle.style.fill = colors.grey95;
      iconButtonCircle.style.stroke = colors.grey60;
      break;
    case 'pressed':
      iconButtonCircle.style.fill = colors.grey90;
      iconButtonCircle.style.stroke = colors.grey60;
      break;
  }
  container.append(iconButtonCircle);
  container.append(
    materialIconsText(iconName, {
      x: `${radius}`,
      y: `${radius}`,
      'font-size': '20px',
    })
  );
  return container;
};

const svgGroupExpander = (
  isExpanded: boolean,
  radius: number,
  attributes: Record<string, string>
) =>
  svgIconButton(
    isExpanded ? IconName.UNFOLD_LESS : IconName.UNFOLD_MORE,
    radius,
    'initial',
    attributes
  );
export default svgGroupExpander;
