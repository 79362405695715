import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

type NoReferencesDoqProps = {
  referenceType: string;
};

export const NoReferencesDoq = ({ referenceType }: NoReferencesDoqProps) => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.TREEMAP_ERROR}-emptystate__no-references`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.TREEMAP_ERROR}
      title="There is no data to show"
      message={`Selected components don't have any ${referenceType} references. Size components by a different attribute to show them in Treemap.`}
    />
  </EmptyStatesDoqContainer>
);

type NoSelectedUnusedFieldDoqProps = {
  fieldName: string;
};

export const NoSelectedUnusedFieldDoq = ({
  fieldName,
}: NoSelectedUnusedFieldDoqProps) => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.TREEMAP_ERROR}-emptystate__no-selected-unused-field`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.TREEMAP_ERROR}
      title="There is no data to show"
      message={`Selected components don’t use field ${fieldName}. Size components by a different field to show them in Treemap.`}
    />
  </EmptyStatesDoqContainer>
);

const SizeAttributeText = () => (
  <>
    “
    <Icon
      iconName={IconName.FORMAT_LIST_BULLETED}
      style={{ verticalAlign: 'bottom', color: colors.grey60 }}
    />
    &nbsp;Select size attribute”
  </>
);

export const NoDescendantsDoq = () => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.TREEMAP_ERROR}-emptystate__no-descendents`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.TREEMAP_ERROR}
      title="No descendants to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            The selected components have no descendants.
          </FlexBox>
          <div>
            Select a component with at least one descendant in the navigator on
            the left or change the size attribute in view modifier&nbsp;
            <SizeAttributeText />
          </div>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);

type NoPositiveValuesDoqProps = {
  activeFieldLabel?: string;
};

export const NoPositiveValuesDoq = ({
  activeFieldLabel,
}: NoPositiveValuesDoqProps) => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.TREEMAP_ERROR}-emptystate__no-positive-values`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.TREEMAP_ERROR}
      title="No visible value to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            The selected components have numbers with a negative value in the
            field{activeFieldLabel ? ` - ${activeFieldLabel}` : ''}
          </FlexBox>
          <div>
            To use this Treemap, select a component with a field number greater
            than 0 or change the size attribute in view modifier&nbsp;
            <SizeAttributeText />
          </div>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);
