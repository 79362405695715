import { Observable } from 'rxjs';
import { context$ } from 'streams/context/context$';
import {
  DependencyWheelViewModel,
  DependencyWheelViewSettings,
} from '@ardoq/dependency-wheel';
import { combineLatest } from 'rxjs';
import { composeViewModel } from './composeViewModel';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import defaultState from 'views/defaultState';
import { action$, combineReducers, streamReducer } from '@ardoq/rxbeach';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import graphModel$ from 'modelInterface/graphModel$';
import { ExtractStreamShape } from 'tabview/types';

const emptyState: DependencyWheelViewModel = {
  viewSettings: defaultState.get(
    ViewIds.DEPWHEEL
  ) as DependencyWheelViewSettings,
  references: [],
  components: [],
  isComponentsCountLimitExceeded: false,
  totalComponentsCount: 0,
  referenceTypesForLegend: [],
  selectedComponentId: null,
  isExploreMode: false,
};

type ResetArgs = {
  viewSettings: DependencyWheelViewSettings;
  selectedWorkspaceId: ArdoqId;
  composedViewModel: ReturnType<typeof composeViewModel>;
};

const reset = ({
  viewSettings,
  selectedWorkspaceId,
  composedViewModel,
}: ResetArgs): DependencyWheelViewModel => {
  if (!selectedWorkspaceId) {
    return emptyState;
  }

  return { ...composedViewModel, viewSettings };
};

export const getViewModel$ = (
  viewSettings$: Observable<DependencyWheelViewSettings>
) => {
  const reset$ = combineLatest([
    viewSettings$,
    context$,
    graphModel$,
    modelUpdateNotification$,
  ]);
  const resetReducer = (
    _: DependencyWheelViewModel,
    [viewSettings, context, graphModel]: ExtractStreamShape<typeof reset$>
  ) => {
    const composedViewModel = composeViewModel(
      context,
      viewSettings,
      graphModel.referenceMap
    );

    return reset({
      viewSettings,
      composedViewModel,
      selectedWorkspaceId: context.workspaceId,
    });
  };

  return action$.pipe(
    combineReducers<DependencyWheelViewModel>(emptyState, [
      streamReducer(reset$, resetReducer),
    ])
  );
};
