import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { ViewIds } from '@ardoq/api-types';
import {
  getFocusedItemIdStream,
  getHoveredItemIdStream,
  type DependencyMapViewSettings,
  type DependencyMapViewStreamedProps,
} from '@ardoq/dependency-map';
import graphModel$ from 'modelInterface/graphModel$';
import {
  action$,
  combineReducers,
  dispatchAction,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { EMPTY_CONTEXT_SHAPE } from 'streams/context/ContextShape';
import { Observable, combineLatest } from 'rxjs';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import {
  EMPTY_LOADED_SCENARIO_DATA,
  LoadedScenarioData,
  loadedScenarioData$,
} from 'loadedScenarioData$';
import { mainAppModuleSidebar$ } from 'appContainer/MainAppModule/MainAppModuleSidebar/mainAppModuleSidebar$';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import { ShowRelatedComponentsShape } from 'scenarioRelated/types';
import { scenarioRelatedComponents$ } from 'scenarioRelated/scenarioRelatedComponents$';
import { bypassLimit } from './actions';
import defaultState from 'views/defaultState';
import { EMPTY_GRAPH_MODEL } from 'modelInterface/consts';
import { notifyViewLoading } from 'tabview/actions';
import buildViewModel from './buildViewModel';
import modelCss$ from 'utils/modelCssManager/modelCss$';
import { filter, tap } from 'rxjs/operators';
import { isUpdatingAppState$ } from 'isUpdatingAppState$';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import type { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import type { ExtractStreamShape } from 'tabview/types';
import { updateViewProperties } from '@ardoq/view-settings';
import { loadedGraphOperations } from '../../traversals/loadedGraphOperations';

const VIEW_ID = ViewIds.DEPENDENCY_MAP_2;

const emptyState: DependencyMapViewStreamState = {
  viewModel: {
    nodes: [],
    maxTreeDepth: 0,
    componentTypes: [],
    noConnectedComponents: false,
    legendReferenceTypes: [],
    traversedIncomingReferenceTypes: [],
    traversedOutgoingReferenceTypes: [],
    itemCount: 0,
    isUserDefinedGrouping: false,
    referenceParentMaxDepth: 0,
    referenceChildMaxDepth: 0,
    errors: [],
    referenceTypes: [],
    hasClones: false,
    nodeAddressMap: new Map(),
    hasNonComponentNodes: false,
    urlFieldValuesByComponentId: new Map(),
    urlFieldValuesByReferenceId: new Map(),
  },
  viewSettings: defaultState.get(VIEW_ID) as DependencyMapViewSettings,
  context: EMPTY_CONTEXT_SHAPE,
  graphModel: EMPTY_GRAPH_MODEL,
  loadedScenarioData: EMPTY_LOADED_SCENARIO_DATA,
  scenarioRelatedComponents: { componentIds: [] },
  showScopeRelated: false,
  viewInstanceId: '',
  isUpdatingAppState: false,
  loadedGraph: loadedGraphOperations.getEmpty(),
  hoveredComponentId: null,
  focusedComponentId: null,
};

const viewSettings$ = getViewSettingsStream<DependencyMapViewSettings>(VIEW_ID);

interface DependencyMapViewStreamState extends DependencyMapViewStreamedProps {
  context: ContextShape;
  graphModel: GraphModelShape;
  loadedScenarioData: LoadedScenarioData;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
  showScopeRelated: boolean;
  isUpdatingAppState: boolean;
  loadedGraph: LoadedGraphWithViewpointMode;
  hoveredComponentId: string | null;
  focusedComponentId: string | null;
}

const getViewModel$ =
  () =>
  (viewInstanceId: string): Observable<DependencyMapViewStreamState> => {
    const reset$ = combineLatest({
      viewSettings: viewSettings$,
      graphModel: graphModel$,
      context: context$,
      loadedScenarioData: loadedScenarioData$,
      mainAppModuleSidebar: mainAppModuleSidebar$,
      scenarioRelatedComponents: scenarioRelatedComponents$,
      isUpdatingAppState: isUpdatingAppState$,
      loadedGraph: loadedGraph$,
      modelUpdateNotification: modelUpdateNotification$,
      modelCss: modelCss$, // since this viewModel$ resolves iconColors from the model css classes, we must reset and re-calculate the icon colors when modelCss$ is updated.
    });
    const reset = (
      previousState: DependencyMapViewStreamState,
      {
        viewSettings,
        graphModel,
        context,
        loadedScenarioData,
        mainAppModuleSidebar: { showScopeRelated },
        scenarioRelatedComponents,
        isUpdatingAppState,
        loadedGraph,
      }: ExtractStreamShape<typeof reset$>
    ): DependencyMapViewStreamState => {
      dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: true }));

      if (isUpdatingAppState) {
        return {
          ...previousState,
          isUpdatingAppState,
        };
      }
      return {
        viewSettings,
        viewModel: buildViewModel({
          context,
          viewSettings,
          graphModel,
          loadedScenarioData,
          scenarioRelatedComponents,
          showScopeRelated,
          bypassLimit: false,
          loadedGraph,
        }),
        viewInstanceId,
        context,
        graphModel,
        loadedScenarioData,
        scenarioRelatedComponents,
        showScopeRelated,
        isUpdatingAppState,
        loadedGraph,
        hoveredComponentId: null,
        focusedComponentId: null,
      };
    };

    const handleHoveredComponentChanged = (
      state: DependencyMapViewStreamState,
      hoveredComponentId: string | null
    ): DependencyMapViewStreamState => ({
      ...state,
      hoveredComponentId,
      focusedComponentId: state.focusedComponentId,
    });

    const handleFocusedComponentChanged = (
      state: DependencyMapViewStreamState,
      focusedComponentId: string | null
    ): DependencyMapViewStreamState => {
      const hasFocusedChanged = focusedComponentId !== state.focusedComponentId;
      return {
        ...state,
        hoveredComponentId: hasFocusedChanged ? state.hoveredComponentId : null,
        focusedComponentId: hasFocusedChanged ? focusedComponentId : null,
      };
    };

    const onBypassLimit = (state: DependencyMapViewStreamState) => {
      dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: true }));
      return {
        ...state,
        viewModel: buildViewModel({
          context: state.context,
          viewSettings: state.viewSettings,
          graphModel: state.graphModel,
          loadedScenarioData: state.loadedScenarioData,
          scenarioRelatedComponents: state.scenarioRelatedComponents,
          showScopeRelated: state.showScopeRelated,
          bypassLimit: true,
          loadedGraph: state.loadedGraph,
        }),
      };
    };
    return action$.pipe(
      combineReducers(emptyState, [
        streamReducer(reset$, reset),
        streamReducer(
          getHoveredItemIdStream(viewInstanceId),
          handleHoveredComponentChanged
        ),
        streamReducer(
          getFocusedItemIdStream(viewInstanceId),
          handleFocusedComponentChanged
        ),
        reducer(bypassLimit, onBypassLimit),
      ]),
      filter(({ isUpdatingAppState }) => !isUpdatingAppState),
      tap(viewProperties =>
        dispatchAction(
          updateViewProperties({ viewId: VIEW_ID, viewProperties })
        )
      )
    );
  };

export default getViewModel$;
