import { ViewIds } from '@ardoq/api-types';
import defaultState from 'views/defaultState';
import { BubbleChartViewSettings } from './types';

export const DISPLAYED_LABELS_LIMIT = 100;

export const BUBBLE_SLIDER_OPTIONS = { min: 0, max: 1, step: 0.01 };
export const BUBBLE_SLIDER_RANGE = { min: 1 / 10, max: 4 };

export const CONTEXT_HIGHLIGHT_STROKE_WIDTH = 4;

export const ZERO_BUBBLE = 'zeroBubble';
export const ZERO_BUBBLE_SIZE = 6;

export const defaultBubbleChartViewSettings = defaultState.get(
  ViewIds.BUBBLE
) satisfies BubbleChartViewSettings;

export const EMPTY_HIGHLIGHT_STATE = {
  target: null,
  id: '',
  labelPosition: null,
};
