import { ArrowType } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import type { Point } from '@ardoq/math';
import drawArrow from './drawArrow';

const SIZE = devicePixelRatio * 5;

const drawMarker = (
  context: CanvasRenderingContext2D,
  arrow: ArrowType,
  [x, y]: Point,
  angle: number,
  scale: number
) => {
  const size = SIZE * Math.min(1, scale);
  if (size < 0.5) {
    // no need to draw what is too small to see
    return;
  }
  context.save();
  context.strokeStyle = colors.black;
  context.translate(x, y);
  context.rotate(angle);
  context.translate(-x, -y);
  drawArrow(context, x, y, size, arrow);
  context.restore();
};
export default drawMarker;
