import { Rect } from '@ardoq/yfiles';
import { PROTEAN_GROUP_MARGIN } from './consts';
import { GraphGroup } from '@ardoq/graph';
import { componentInterface } from '@ardoq/component-interface';
import { MeasureStyledSvgText } from '@ardoq/dom-utils';
import { BLOCK_DIAGRAM_NODE_LABEL_FONT } from 'yfilesExtensions/styles/consts';
import { PROTEAN_GROUP_EXPANDER_RADIUS } from '../consts';

const MAXIMUM_WIDTH = 500;

const proteanRectangularGroupMinimumBounds = (group: GraphGroup) => {
  const text = group.getLabel();
  const font = BLOCK_DIAGRAM_NODE_LABEL_FONT;
  const textWidth = text
    ? MeasureStyledSvgText.Instance.getTextWidth({
        text,
        fontSize: `${font.fontSize}px`,
        fontFamily: `${font.fontFamily}`,
      })
    : 0;
  const iconWidth =
    group.isComponent() &&
    componentInterface.getRepresentationData(group.modelId)
      ? font.fontSize
      : 0;

  const minimumWidth =
    2 * PROTEAN_GROUP_MARGIN +
    2 * PROTEAN_GROUP_EXPANDER_RADIUS +
    iconWidth +
    textWidth;
  const minimumHeight =
    BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize + 2 * PROTEAN_GROUP_MARGIN;

  return new Rect(0, 0, Math.min(minimumWidth, MAXIMUM_WIDTH), minimumHeight);
};
export default proteanRectangularGroupMinimumBounds;
