import { getComponentItemData } from '../utils';
import { ColumnHeader } from '../atoms';
import { ComponentLabel } from './ComponentLabel';
import { ArdoqId } from '@ardoq/api-types';

type TargetHeaderItemProps = {
  componentId: ArdoqId;
};

const TYPE = 'TARGET';
export const TargetComponent = ({ componentId }: TargetHeaderItemProps) => {
  const component = getComponentItemData(componentId, TYPE);

  return (
    <ColumnHeader>
      <ComponentLabel type={TYPE} component={component} />
    </ColumnHeader>
  );
};
