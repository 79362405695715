import {
  IColumn,
  IGraph,
  INode,
  IRow,
  Insets,
  NodeStyleStripeStyleAdapter,
  Point,
  Rect,
  Table,
  TableNodeStyle,
  VoidNodeStyle,
} from '@ardoq/yfiles';
import { ArdoqYGraphBuilder } from 'yfilesExtensions/ardoqYGraphBuilder';
import ArdoqSwimlaneStripeStyle from './styles/ArdoqSwimlaneStripeStyle';
import { ArdoqSwimlaneLabelStyle } from './styles/ArdoqSwimlaneLabelStyle';
import ArdoqTableBackgroundStyle from './styles/ArdoqTableBackgroundStyle';
import * as encodingUtils from '@ardoq/html';
import { isEqual } from 'lodash';
import { Node } from 'graph/node';
import FieldValue from 'graph/FieldValue';
import { GraphBuilderHasLayoutArgs } from './types';
import type { Edge } from 'graph/types';
import { urlNodeButtonModelParameter } from 'tabview/blockDiagram/view/yFilesExtensions/labelParameters';
import { ArdoqURLLabelStyle } from './styles/ardoqURLLabelStyle';
import { GraphNode } from '@ardoq/graph';

const PARENT_ROW_INSETS = new Insets(175, 50, 50, 50);
const CHILD_ROW_INSETS = new Insets(150, 50, 50, 50);

const PARENT_COLUMN_INSETS = new Insets(50, 175, 50, 50);
const CHILD_COLUMN_INSETS = new Insets(50, 150, 50, 50);

interface TableGraphBuilderHasLayoutArgs extends GraphBuilderHasLayoutArgs {
  rows: Node[];
}

type ConstructorArgs = {
  graph: IGraph;
  rowHeight?: number;
  columnWidth?: number;
};

export default class TableGraphBuilder extends ArdoqYGraphBuilder {
  rowHeight: number;
  columnWidth: number;
  rowsSource: Node[];
  tableNode?: INode;
  tableNodeStyle: TableNodeStyle;
  table: Table;

  _rowMap: Map<string, IRow | IColumn>;
  private _isVertical: boolean;

  constructor({ graph, rowHeight = 200, columnWidth = 200 }: ConstructorArgs) {
    super(graph);
    const table = new Table();
    const tableNodeStyle = new TableNodeStyle(table);

    this.rowsSource = [];
    this._rowMap = new Map();

    tableNodeStyle.backgroundStyle = new ArdoqTableBackgroundStyle();

    this.rowHeight = rowHeight;
    this.columnWidth = columnWidth;
    this.table = table;
    this.tableNodeStyle = tableNodeStyle;

    this._isVertical = true; // initializing this field to true so setVertical(false) registers the change and initializes defaults.
    this.setVertical(false);
  }
  createNode = (graphNodeArg: Node | GraphNode) => {
    const graphNode = graphNodeArg as Node; // type assertion: we are always getting a Node here, never a GraphNode. this is not in agreement with the base class.
    const belongsToATableRow = this.findTargetRow(graphNode);

    let node;
    const truncateNodeName = true;
    if (belongsToATableRow) {
      node = this.graph.createNode({
        parent: this.tableNode,
        tag: graphNode,
        labels: [encodingUtils.unescapeHTML(graphNode.name(truncateNodeName))],
      });
    } else {
      node = this.graph.createNode({
        tag: graphNode,
        labels: [encodingUtils.unescapeHTML(graphNode.name(truncateNodeName))],
      });
    }

    if (graphNode.hasURLFields()) {
      this.graph.addLabel({
        owner: node,
        text: '',
        layoutParameter: urlNodeButtonModelParameter,
        style: ArdoqURLLabelStyle.Classic,
        tag: graphNode,
      });
    }
    this.updateNodeLayout(node);
    return node;
  };

  updateNode = (graphNode: Node | GraphNode) => {
    const node = super._updateNode(graphNode);
    // Ensure node is also moved to the correct row
    this.moveNodeToRow(node);
    return node;
  };

  createRow = (graphNode: Node) => {
    let row;
    if (graphNode.isField()) {
      const field = (graphNode.dataModel as FieldValue).field;
      const tag = field.getRawLabel();
      const findTag = (currentRow: IRow | IColumn) => currentRow.tag === tag;
      let parentRow = this.isVertical
        ? this.table.columns.find(findTag)
        : this.table.rows.find(findTag);

      if (!parentRow) {
        parentRow = this.isVertical
          ? this.table.createColumn({
              tag,
              insets: PARENT_COLUMN_INSETS,
              minWidth: 500,
            })
          : this.table.createRow({
              tag,
              insets: PARENT_ROW_INSETS,
              minHeight: 500,
            });
        this.table.addLabel({
          owner: parentRow,
          text: encodingUtils.unescapeHTML(field.getLabel()),
        });
      }
      row = this.isVertical
        ? this.table.createChildColumn({
            owner: parentRow as IColumn,
            tag: graphNode,
            insets: CHILD_COLUMN_INSETS,
          })
        : this.table.createChildRow({
            owner: parentRow as IRow,
            tag: graphNode,
            insets: CHILD_ROW_INSETS,
          });
      this.table.setStripeInsets(
        parentRow,
        this.isVertical ? new Insets(0, 175, 0, 0) : new Insets(175, 0, 0, 0)
      );

      this.table.addLabel({
        owner: row,
        text: encodingUtils.unescapeHTML(graphNode.getValue()),
        tag: graphNode,
      });
    } else {
      row = this.isVertical
        ? this.table.createColumn({
            tag: graphNode,
            insets: PARENT_COLUMN_INSETS,
          })
        : this.table.createRow({ tag: graphNode, insets: PARENT_ROW_INSETS });

      this.table.addLabel({
        owner: row,
        text: encodingUtils.unescapeHTML(graphNode.getLabel()),
        tag: graphNode,
      });
    }

    return row;
  };

  updateRow = (graphNode: Node) => this._rowMap.get(graphNode.id)!;

  removeRow = (row: IRow | IColumn) => {
    this._rowMap.delete(row.tag.id);
    this.table.remove(row);
  };
  get isVertical() {
    return this._isVertical;
  }
  setVertical(vertical: boolean) {
    if (vertical === this._isVertical) {
      return;
    }
    this._isVertical = vertical;

    this.clear();
    // @ts-expect-error todo: address access modifier issues
    this.table.columnDefaults = this.table.createColumnDefaults();
    // @ts-expect-error todo: address access modifier issues
    this.table.rowDefaults = this.table.createRowDefaults();
    if (vertical) {
      this.table.columnDefaults.size = this.rowHeight;
      this.table.columnDefaults.insets = new Insets(0, 100, 0, 0);
      this.table.columnDefaults.style = new ArdoqSwimlaneStripeStyle();
      this.table.columnDefaults.labels.style = new ArdoqSwimlaneLabelStyle();
      this.table.rowDefaults.size = this.columnWidth;
      this.table.rowDefaults.style = new NodeStyleStripeStyleAdapter(
        VoidNodeStyle.INSTANCE
      );
    } else {
      this.table.rowDefaults.size = this.rowHeight;
      this.table.rowDefaults.insets = new Insets(100, 0, 0, 0);
      this.table.rowDefaults.style = new ArdoqSwimlaneStripeStyle();
      this.table.rowDefaults.labels.style = new ArdoqSwimlaneLabelStyle();
      this.table.columnDefaults.size = this.columnWidth;
      this.table.columnDefaults.style = new NodeStyleStripeStyleAdapter(
        VoidNodeStyle.INSTANCE
      );
    }
  }
  updateNodeLayout(node: INode) {
    super.updateNodeLayout(node);
    this.moveNodeToRow(node);
  }

  findTargetRow(graphNodeArg: Node | GraphNode) {
    const graphNode = graphNodeArg as Node; // type assertion: we are always getting a Node here, never a GraphNode. this is not in agreement with the base class.
    const parentRow = this._rowMap.get(graphNode.parent && graphNode.parent.id);
    const selfRow = this._rowMap.get(graphNode.id);
    return parentRow || selfRow;
  }

  moveNodeToRow(node: INode) {
    const graphNode = node.tag;
    const targetRow = this.findTargetRow(graphNode);

    if (targetRow) {
      const placementRectangle = new Rect(
        targetRow.layout.center.x - node.layout.width / 2,
        targetRow.layout.center.y - node.layout.height / 2,
        node.layout.width,
        node.layout.height
      );
      this.graph.setNodeLayout(node, placementRectangle);
    }
  }

  clear() {
    super.clear();
    this.table.clear();
    this._rowMap.clear();
  }

  hasLayoutUpdate(args: TableGraphBuilderHasLayoutArgs) {
    const { nodes, groups, edges, rows } = args;
    const nodesUnchanged = !nodes || isEqual(nodes, this.nodesSource);
    const groupsUnchanged = !groups || isEqual(groups, this.groupsSource);
    const edgesUnchanged = !edges || isEqual(edges, this.edgesSource);
    const rowsUnchanged = !rows || isEqual(rows, this.rowsSource);

    return !(
      nodesUnchanged &&
      groupsUnchanged &&
      edgesUnchanged &&
      rowsUnchanged
    );
  }

  buildGraph() {
    this.clear();
    if (this.isVertical) {
      this.table.createRow(this.columnWidth);
    } else {
      this.table.createColumn(this.columnWidth);
    }
    this.tableNode = this.graph.createGroupNode({
      layout: this.table.layout.toRect(),
      style: this.tableNodeStyle,
    });
    this.graph.setNodeCenter(this.tableNode, Point.ORIGIN);

    this._rowMap = new Map(
      this.rowsSource.map(graphNode => [
        graphNode.id,
        this.createRow(graphNode),
      ])
    );
    this._nodeMap = new Map(
      this.nodesSource.map(graphNode => [
        graphNode.id,
        this.createNode(graphNode as Node),
      ])
    );
    this._edgeMap = new Map(
      this.edgesSource.map(graphEdge => [
        graphEdge.id,
        this.createEdge(graphEdge as Edge),
      ])
    );
  }

  updateGraph({ isReordered = false } = {}) {
    if (
      isReordered ||
      !this.tableNode ||
      !this.graph.nodes.includes(this.tableNode)
    ) {
      this.buildGraph();
      return;
    }
    this._rowMap = this._diffUpdateGraph({
      oldMap: this._rowMap,
      businessObjects: this.rowsSource,
      createFunc: this.createRow,
      updateFunc: this.updateRow,
      removeFunc: this.removeRow,
    });
    this.graph.setNodeLayout(this.tableNode, this.table.layout.toRect());
    super.updateGraph();
  }
}
