import { actionCreator } from '@ardoq/rxbeach';

interface TopVisibleItemChangedPayload {
  itemId: string;
}

export const topVisibleItemChanged =
  actionCreator<TopVisibleItemChangedPayload>(
    '[InfiniteScroll] TOP_VISIBLE_ITEM_CHANGED'
  );
