import { ViewIds } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { createSvgElement } from '@ardoq/dom-utils';
import { CollapsibleGraphGroup, disposeCallback } from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  type Class,
  type INode,
  INodeInsetsProvider,
  INodeSizeConstraintProvider,
  type IRenderContext,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import SparkMD5 from 'spark-md5';
import { selectComponent } from 'streams/components/ComponentActions';
import { DATA_RENDER_HASH } from 'yfilesExtensions/styles/consts';
import { createElementAsGroupFolder } from './utils';
import GroupInsetsProvider from './GroupInsetsProvider';
import GroupSizeConstraintProvider from './GroupSizeConstraintProvider';
import type { RepresentationData } from '@ardoq/data-model';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { classes } from '@ardoq/common-helpers';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';

const createRenderDataCache = (node: INode, group: CollapsibleGraphGroup) => {
  const { labels, layout } = node;
  const { modelId, isTransparentized } = group;
  return SparkMD5.hash(
    [
      componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
        useAsBackgroundStyle: false,
      }).fill,
      group.getVisualDiffType(),
      isTransparentized,
      group.isContext(),
      layout.width,
      layout.height,
      labels.at(0)?.layout.height ?? 0,
    ].join('-')
  );
};

const setContainerAttributes = (
  container: SVGElement,
  group: CollapsibleGraphGroup
) => {
  const { id, modelId } = group;
  const isComponent = group.isComponent();
  container.setAttribute(
    'class',
    classes('group', 'skipContextUpdate', isComponent && 'component')
  );
  container.setAttribute('data-node-id', id);
  if (modelId) {
    container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, modelId);
    if (isComponent) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
    }
    return;
  }
  container.removeAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);
};

const render = (
  container: SVGElement,
  node: INode,
  cache: string,
  viewId: ViewIds,
  representationData: RepresentationData
) => {
  const { tag: group } = node;
  if (!(group instanceof CollapsibleGraphGroup)) {
    logError(Error('Non-collapsible node in CollapsibleGroupStyle render.'));
    return;
  }
  container.setAttribute(DATA_RENDER_HASH, cache);
  setContainerAttributes(container, group);

  const componentGroupElement = createElementAsGroupFolder(
    node,
    group,
    viewId,
    representationData
  );
  container.innerHTML = '';
  container.appendChild(componentGroupElement);
};

abstract class GroupStyle extends NodeStyleBase {
  protected constructor(
    private getRepresentationData: (modelId: string) => RepresentationData
  ) {
    super();
  }

  override createVisual(context: IRenderContext, node: INode) {
    const g = createSvgElement('g');
    const { tag } = node;
    const graphNode = tag as CollapsibleGraphGroup;
    const { modelId } = graphNode;
    g.addEventListener('dblclick', () => {
      if (
        !modelId ||
        !componentInterface.isComponent(modelId) ||
        componentInterface.isScenarioRelated(modelId)
      ) {
        return;
      }
      dispatchAction(selectComponent({ cid: modelId }));
    });
    const cache = createRenderDataCache(node, node.tag);
    render(
      g,
      node,
      cache,
      ViewIds.MODERNIZED_BLOCK_DIAGRAM,
      this.getRepresentationData(modelId)
    );

    SvgVisual.setTranslate(g, node.layout.x, node.layout.y);
    const result = new SvgVisual(g);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    node: INode
  ) {
    if (!(oldVisual instanceof SvgVisual)) {
      logError(Error('Invalid visual in ModernizedYGraphStyle updateVisual.'));
      return oldVisual;
    }
    const { tag: group, layout } = node;
    const { modelId } = group;
    const container = oldVisual.svgElement;
    const oldCache = container.getAttribute(DATA_RENDER_HASH);
    const newCache = createRenderDataCache(node, group);
    if (oldCache !== newCache) {
      render(
        container,
        node,
        newCache,
        ViewIds.MODERNIZED_BLOCK_DIAGRAM,
        this.getRepresentationData(modelId)
      );
    }
    SvgVisual.setTranslate(container, layout.x, layout.y);
    return oldVisual;
  }

  protected override lookup(node: INode, type: Class) {
    switch (type) {
      case INodeInsetsProvider.$class:
        return GroupInsetsProvider.Instance;
      case INodeSizeConstraintProvider.$class:
        return GroupSizeConstraintProvider.Instance;
    }
    return super.lookup(node, type);
  }
}
export default GroupStyle;
