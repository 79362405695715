import { dispatchAction } from '@ardoq/rxbeach';
import type {
  GraphInputMode,
  IModelItem,
  ItemClickedEventArgs,
} from '@ardoq/yfiles';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';

const graphViewpointModeItemDoubleClickedListener = (
  _: GraphInputMode,
  e: ItemClickedEventArgs<IModelItem>
) => {
  if (!e.item.tag || !e.item.tag.isComponent?.()) return;
  dispatchAction(openDetailsDrawer([e.item.tag.modelId]));
  e.handled = true;
};

export default graphViewpointModeItemDoubleClickedListener;
