// https://keycode.info/

export default {
  tab: 9,
  enter: 13,
  escape: 27,
  space: 32,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
  letterL: 76,
  letterD: 68,
  letterE: 69,
  letterZ: 90,
};
