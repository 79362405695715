import { Features, hasFeature } from '@ardoq/features';
import { ArdoqId } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';

const enhanceBaseUrlWithScenarioNamespace = (baseUrl: string) =>
  isScenarioMode()
    ? baseUrl.replace('/api', `/api/scenario/${getActiveScenarioId()}`)
    : baseUrl;

export const getModelUrl = (base: string, isNew: boolean, id: ArdoqId) => {
  const enhancedBase = enhanceBaseUrlWithScenarioNamespace(base);
  if (isNew) return enhancedBase;
  return `${enhancedBase}/${encodeURIComponent(id)}`;
};

/**
 * @deprecated connect to `activeScenario$` instead.
 *
 * Github: {@link https://github.com/ardoq/ardoq-packages/blob/3fabfe128211db038b220a19253f32f5df20a41f/apps/ardoq-front/src/js/ardoq/streams/activeScenario%24.ts#L18 activeScenario$}
 */
export const isScenarioMode = () =>
  hasFeature(Features.SCENARIOS_BETA) && Boolean(getActiveScenarioId());
