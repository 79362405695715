import { IEdge, IGraph, INode } from '@ardoq/yfiles';

interface GetCriticalPathArgs {
  graph: IGraph;
  node: INode;
}
const getCriticalPath = ({ graph, node }: GetCriticalPathArgs) => {
  const criticalPathEdges = new Set<IEdge>();
  let inEdges = graph.inEdgesAt(node);
  let currentNode = node;
  const processedNodes = new Set<INode>();
  while (inEdges.size === 1 && !processedNodes.has(currentNode)) {
    processedNodes.add(currentNode);
    const currentEdge = inEdges.get(0);
    criticalPathEdges.add(currentEdge);
    currentNode = currentEdge.sourceNode!;
    inEdges = graph.inEdgesAt(currentNode);
  }
  let outEdges = graph.outEdgesAt(node);
  currentNode = node;
  while (outEdges.size === 1 && !processedNodes.has(currentNode)) {
    processedNodes.add(currentNode);
    const currentEdge = outEdges.get(0);
    criticalPathEdges.add(currentEdge);
    currentNode = currentEdge.targetNode!;
    outEdges = graph.outEdgesAt(currentNode);
  }
  return criticalPathEdges;
};

interface CriticalPathsReducerState {
  graph: IGraph;
  criticalPaths: IEdge[][];
  processedNodes: Set<INode>;
}
const criticalPathsReducer = (
  state: CriticalPathsReducerState,
  node: INode
) => {
  if (state.processedNodes.has(node)) {
    return state;
  }
  const criticalPathEdges = getCriticalPath({ graph: state.graph, node });
  // discard any critical paths which contain nodes in existing critical paths.
  if (
    criticalPathEdges.size > 1 &&
    ![...criticalPathEdges].some(({ sourceNode, targetNode }) =>
      [sourceNode!, targetNode!].some(node => state.processedNodes.has(node))
    )
  ) {
    criticalPathEdges.forEach(edge =>
      [edge.sourceNode!, edge.targetNode!].forEach(node =>
        state.processedNodes.add(node)
      )
    );
    state.criticalPaths.push([...criticalPathEdges]);
  }
  return state;
};

interface GetCriticalPathsArgs {
  graph: IGraph;
  contextNode: INode | null;
}
const getCriticalPaths = ({ graph, contextNode }: GetCriticalPathsArgs) => {
  const contextNodeCriticalPath = contextNode
    ? getCriticalPath({
        graph,
        node: contextNode,
      })
    : new Set<IEdge>();
  if (contextNode && contextNodeCriticalPath.size < 2) {
    // there's a context node, but we couldn't determine a critical path. do not mark any path as critical-- return an empty result.
    return new Map();
  }
  const processedNodes = new Set<INode>();
  contextNodeCriticalPath.forEach(edge =>
    [edge.sourceNode!, edge.targetNode!].forEach(node =>
      processedNodes.add(node)
    )
  );
  const { criticalPaths: nonContextNodeCriticalPaths } = graph.nodes.reduce(
    criticalPathsReducer,
    {
      criticalPaths: [],
      processedNodes,
      graph,
    }
  );
  const result = new Map<IEdge, number>();

  contextNodeCriticalPath.forEach(
    edge => result.set(edge, 1 + nonContextNodeCriticalPaths.length) // highest priority
  );

  nonContextNodeCriticalPaths
    .sort((pathA, pathB) => pathA.length - pathB.length) // longer paths get higher priority
    .forEach((criticalPath, criticalPathIndex) =>
      criticalPath.forEach(edge => result.set(edge, 1 + criticalPathIndex))
    );
  return result;
};
export default getCriticalPaths;
