import { Size, Rect, IGraph, INode } from '@ardoq/yfiles';
import { NODE_HEIGHT, NODE_WIDTH } from 'yfilesExtensions/styles/consts';

const sizeEmptyGroupNodes = (
  graph: IGraph,
  getMinimumSize: (groupNode: INode) => Size
) =>
  graph.nodes
    .filter(node => graph.isGroupNode(node))
    .forEach(groupNode => {
      if (graph.getChildren(groupNode).size === 0) {
        // according to yFiles documentation, the minimum node size is ignored for group nodes with zero children. therefore, we must manually set the layout size of these group nodes after creation.
        // https://docs.yworks.com/yfileshtml/#/api/GroupingKeys#GroupingKeys-field-MINIMUM_NODE_SIZE_DP_KEY
        const groupNodeMinimumSize = getMinimumSize(groupNode);
        const groupNodeSize =
          groupNodeMinimumSize.width > 0 && groupNodeMinimumSize.height > 0
            ? groupNodeMinimumSize
            : new Size(NODE_WIDTH, NODE_HEIGHT);
        graph.setNodeLayout(
          groupNode,
          new Rect(groupNode.layout.topLeft, groupNodeSize)
        );
      }
    });

export default sizeEmptyGroupNodes;
