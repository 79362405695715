import { SVGProps } from 'react';
import { colors } from '@ardoq/design-tokens';
import {
  MODERNIZED_BLOCK_DIAGRAM_CORNER_RADIUS,
  MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
  MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_INNER,
  MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_OUTER,
} from './consts';

const ContextHighlightRect = (props: SVGProps<SVGRectElement>) => (
  <rect
    width={MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH}
    height={MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT}
    rx={MODERNIZED_BLOCK_DIAGRAM_CORNER_RADIUS}
    {...props}
  />
);

const ContextHighlight = () => (
  <>
    (
    <ContextHighlightRect
      fill="none"
      stroke={colors.grey90}
      strokeWidth={
        2 *
        (MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_INNER / 2 +
          MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_OUTER)
      } // add half the inner stroke and then double the whole thing. all this trickery because there's no stroke-alignment in SVG.
    />
    <ContextHighlightRect
      fill="white" // hiding the inset portion of the outer stroke rect
      stroke={colors.grey50}
      strokeWidth={MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_INNER}
    />
    )
  </>
);
export default ContextHighlight;
