import Backbone from 'backbone';
import { every } from 'lodash';
import Filters from 'collections/filters';
import { DiffType } from '@ardoq/data-model';
import type { Reference } from 'aqTypes';
import type { OldGraphItemDataModel } from './legacyTypes';

type GroupConstructorArgs = { dataModels: Reference[] };
class Group extends Backbone.Model implements OldGraphItemDataModel {
  dataModels: Reference[] = [];
  constructor(options: GroupConstructorArgs) {
    super(options);
    if (options.dataModels) {
      this.dataModels = options.dataModels;
    }
  }
  add(dataModel: Reference) {
    this.dataModels.push(dataModel);
  }
  contains(dataModel: Reference) {
    return this.dataModels.indexOf(dataModel) !== -1;
  }
  size() {
    return this.dataModels.length;
  }
  remove(dataModel: Reference) {
    this.dataModels.splice(this.dataModels.indexOf(dataModel), 1);
  }
  isIncludedInContextByFilter() {
    return every(this.dataModels, function (child) {
      return Filters.isIncludedInContextByFilter(child);
    });
  }
  getModels() {
    return this.dataModels;
  }
  // Because it doesn't inherit from basicModel
  isReference() {
    return false;
  }
  getVisualDiffType() {
    return DiffType.NONE;
  }
}
export default Group;
