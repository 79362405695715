import { memo, useCallback, useRef, useState } from 'react';
import { ComponentMatrixViewModel } from '../types';
import ComponentMatrixBody, {
  ComponentMatrixBodyRef,
} from './componentMatrixBody';
import ComponentMatrixLevelHeadersArea from './componentMatrixLevelHeadersArea';
import ComponentMatrixColumnHeadersArea from './componentMatrixColumnHeadersArea';
import ComponentMatrixRowHeadersArea, {
  ComponentMatrixRowHeadersAreaRef,
} from './componentMatrixRowHeadersArea';
import { ComponentMatrixMainContainer } from '@ardoq/graph';
import { useResizeObserver } from '@ardoq/hooks';
import Context from 'context';
import { componentMatrixCommands } from '../commands';

type ComponentMatrixProperties = {
  viewModel: ComponentMatrixViewModel;
};
const ComponentMatrix = (props: ComponentMatrixProperties) => {
  const rowHeadersReference = useRef<ComponentMatrixRowHeadersAreaRef>(null);
  const columnHeadersReference = useRef<ComponentMatrixColumnHeadersArea>(null);
  const bodyReference = useRef<ComponentMatrixBodyRef>(null);
  const syncScroll = useCallback(
    (el: HTMLDivElement) => {
      if (!rowHeadersReference.current || !columnHeadersReference.current) {
        return;
      }
      const { scrollLeft, scrollTop } = el;
      rowHeadersReference.current.setScrollTop(scrollTop);
      columnHeadersReference.current.setScrollLeft(scrollLeft);
    },
    [rowHeadersReference, columnHeadersReference]
  );
  const [horizontalScrollbarHeight, setHorizontalScrollbarHeight] = useState(0);
  const containerReference = useRef<HTMLDivElement>(null);
  useResizeObserver(containerReference, () => {
    if (!bodyReference.current?.container.current) {
      return;
    }
    const currentHorizontalScrollbarHeight =
      (bodyReference.current.container.current?.offsetHeight ?? 0) -
      (bodyReference.current.container.current?.clientHeight ?? 0);
    if (currentHorizontalScrollbarHeight !== horizontalScrollbarHeight) {
      setHorizontalScrollbarHeight(currentHorizontalScrollbarHeight);
      syncScroll(bodyReference.current.container.current);
    }
  });

  const { viewModel } = props;
  const { measurements, rowModels, hoveredComponentId, focusedComponentId } =
    viewModel;
  const {
    mainContainerLeftColumnWidth,
    columnHeaderAndColumnWidths,
    rowHeights,
  } = measurements;
  const contextComponentId = Context?.component()?.id;
  return (
    <ComponentMatrixMainContainer
      ref={containerReference}
      onWheel={e => {
        if (!bodyReference.current) {
          return;
        }
        bodyReference.current.scrollBy(e.deltaX, e.deltaY);
      }}
      onClick={() => componentMatrixCommands.setFocusedItemState(null)}
    >
      <ComponentMatrixLevelHeadersArea viewModel={viewModel} />
      <ComponentMatrixColumnHeadersArea
        ref={columnHeadersReference}
        viewModel={viewModel}
        columnWidths={columnHeaderAndColumnWidths}
      />
      <ComponentMatrixRowHeadersArea
        ref={rowHeadersReference}
        rowModels={rowModels}
        rowHeights={rowHeights}
        horizontalScrollbarHeight={horizontalScrollbarHeight}
        width={mainContainerLeftColumnWidth}
        hoveredComponentId={hoveredComponentId}
        focusedComponentId={focusedComponentId}
      />
      <ComponentMatrixBody
        ref={bodyReference}
        rowModels={rowModels}
        rowHeights={rowHeights}
        columnWidths={columnHeaderAndColumnWidths}
        onScroll={e => syncScroll(e.currentTarget)}
        contextComponentId={contextComponentId}
        hoveredComponentId={hoveredComponentId}
        focusedComponentId={focusedComponentId}
      />
    </ComponentMatrixMainContainer>
  );
};

export default memo(ComponentMatrix);
