import Reference from 'models/reference';
import type { Reference as ReferenceBackboneModel } from 'aqTypes';
import { APIReferenceAttributes } from '@ardoq/api-types';
import { metaModelOverwrites } from './metaModelOverwrites';

type MetaModelReferenceType = ReferenceBackboneModel & {
  new (attrs: Partial<APIReferenceAttributes>): ReferenceBackboneModel;
};

export const MetaModelReference: MetaModelReferenceType =
  Reference.model.extend(metaModelOverwrites);
