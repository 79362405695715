import { getSvgTestDocument } from 'svg/util';
import { Size, SvgVisual } from '@ardoq/yfiles';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from './consts';
import { createSvgElement } from '@ardoq/dom-utils';
import { referenceInterface } from '../../modelInterface/references/referenceInterface';
import { MARKER_PROPS } from '@ardoq/icons';

const createTemplateElement = (href: string, className: string) =>
  createSvgElement('use', { href, class: className });

const templateSizeCache = new Map<string, Size>();

export const determineStyleSize = (shapeTemplateHref: string) => {
  if (templateSizeCache.has(shapeTemplateHref)) {
    return templateSizeCache.get(shapeTemplateHref)!;
  }
  const element = createTemplateElement(shapeTemplateHref, '');
  getSvgTestDocument().appendChild(element);
  const bbox = element.getBBox();
  const size = new Size(bbox.width, bbox.height);
  element.remove();
  templateSizeCache.set(shapeTemplateHref, size);
  return size;
};
export const determineImgStyleSize = (imageHref: string) => {
  const img = document.createElement('img');
  img.src = imageHref;
  // this approach to measuring images is fundamentally broken.
  // if the browser has not already cached this image url, naturalWidth/naturalHeight will be zero until the image is loaded (i.e. after the element' onload event has been raised).
  // providing a zero-size layout to a graph element will throw an exception in yFiles and hang the app, as the graph layout will never complete.
  // so the real solution to this would be to measure all images before the graph layout even begins.
  // but it would be complex to implement such an async solution, and this is a critical regression since Nov. 4.
  // in lieu of the real solution, I am falling back to IMAGE_WIDTH and IMAGE_HEIGHT, which will force a square layout in this event. and that was the behavior for many years before it was changed on Nov. 4 2021.
  const size = new Size(
    img.naturalWidth || IMAGE_WIDTH,
    img.naturalHeight || IMAGE_HEIGHT
  );
  return size;
};

export const disablePointerEvents = (visual: SvgVisual): void =>
  visual && visual.svgElement.setAttribute('pointer-events', 'none');

export const getEdgeLabelIndent = (edgeId: string) => {
  const lineBeginning = referenceInterface.getLineBeginning(edgeId);
  const lineEnding = referenceInterface.getLineEnding(edgeId);
  const beginningMarkerWidth = MARKER_PROPS[lineBeginning].markerWidth;
  const endingMarkerWidth = MARKER_PROPS[lineEnding].markerWidth;

  return Math.max(beginningMarkerWidth, endingMarkerWidth);
};
