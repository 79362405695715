import {
  action$,
  combineReducers,
  dispatchAction,
  streamReducer,
  ofType,
} from '@ardoq/rxbeach';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { Observable, combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { ComponentDataSourceItem, ComponentTreeViewSettings } from '../types';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { componentInterface } from 'modelInterface/components/componentInterface';
import defaultState from 'views/defaultState';
import { getDataSource } from '../utils/getTreeData';
import { bypassLimitAction } from '../actions';
import { map, startWith } from 'rxjs/operators';
import { ViewError } from '@ardoq/graph';
import { ItemsType, nodeLimitError } from '@ardoq/error-info-box';
import { format } from 'utils/numberUtils';
import { isPresentationMode } from 'appConfig';
import { ExtractStreamShape } from 'tabview/types';
import { setupCollapsed$ } from 'streams/setupCollapsed/setupCollapsed$';
import { getFocusedStream, getHoveredStream } from 'tabview/streams';

const VIEW_ID = ViewIds.COMPONENTTREE;
const COMPONENTS_LIMIT = 10000;

const { collapsed$, toggleCollapse, setCollapsed } = setupCollapsed$(VIEW_ID);

const emptyState: ComponentTreeViewModel = {
  viewSettings: defaultState.get(
    ViewIds.COMPONENTTREE
  ) as ComponentTreeViewSettings,
  sourceData: [],
  sourceDataWithRenderingData: [],
  collapsed: {},
  collapsibleIds: [],
  focusedComponentId: null,
  hoveredComponentId: null,
  selectedComponentId: null,
  bypassLimit: false,
  errors: [],
};

type ResetSourceData = (
  previousState: ComponentTreeViewModel,
  args: [
    ComponentTreeViewSettings,
    ExtractStreamShape<typeof context$>,
    Record<ArdoqId, boolean>,
    boolean,
    null,
  ]
) => ComponentTreeViewModel;

type SourceDataShape = {
  viewSettings: ComponentTreeViewSettings;
  sourceData: ComponentDataSourceItem[];
  sourceDataWithRenderingData: ComponentDataSourceItem[];
  collapsed: Record<ArdoqId, boolean>;
  collapsibleIds: ArdoqId[];
  errors: ViewError[];
  bypassLimit: boolean;
};

type NodesAffectedByItneractiveStates = {
  selectedComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
  hoveredComponentId: ArdoqId | null;
};

type ComponentTreeViewModel = SourceDataShape &
  NodesAffectedByItneractiveStates;

type ReplaceItemIfItIsInteractedArgs = {
  item: ComponentDataSourceItem;
  focusedComponentId: string | null;
  hoveredComponentId: string | null;
  selectedComponentId: string | null;
  prevFocusedComponentId?: string | null;
};
const enhanceItemDataWithInteractiveStates = ({
  item,
  focusedComponentId,
  hoveredComponentId,
  selectedComponentId,
  prevFocusedComponentId,
}: ReplaceItemIfItIsInteractedArgs): ComponentDataSourceItem => {
  const isFocused = item.id === focusedComponentId;
  const isLatestFocused = item.id === prevFocusedComponentId;
  const isSelected = item.id === selectedComponentId;
  let isHovered = item.id === hoveredComponentId;

  if (!isFocused && !isHovered && !isSelected) {
    return item;
  }

  // If latest unfocused unhover too
  if (isLatestFocused && !isFocused) {
    isHovered = false;
  }

  return {
    ...item,
    isFocused,
    isHovered,
    isSelected,
  };
};

const resetComponentSourceData: ResetSourceData = (
  previousState,
  [viewSettings, context, collapsed, isBypassLimit]
) => {
  if (!context.workspaceId) {
    return emptyState;
  }

  const bypassLimit =
    previousState.bypassLimit || isBypassLimit || isPresentationMode();

  const hierarchy = componentInterface.getComponentsHierarchyByWorkspaceIds(
    context.workspacesIds
  );

  const allComponentsCount =
    // minus number of workspaceIds + 'root' key
    Object.keys(hierarchy).length -
    (context.workspacesIds.filter(id => hierarchy[id]).length + 1);

  const shouldLimitItems =
    allComponentsCount > COMPONENTS_LIMIT && !bypassLimit;

  const sourceData = getDataSource(
    collapsed,
    hierarchy,
    shouldLimitItems ? COMPONENTS_LIMIT : Infinity
  );

  const collapsibleIds = sourceData.reduce((acc: string[], { id }) => {
    if (hierarchy[id].children && id !== 'root') {
      acc.push(id);
    }

    return acc;
  }, []);

  const errors: ViewError[] = shouldLimitItems
    ? [
        nodeLimitError({
          itemCount: allComponentsCount,
          limit: COMPONENTS_LIMIT,
          itemsType: ItemsType.COMPONENTS,
          formatNumber: format,
          onProceedAnyway: () => dispatchAction(bypassLimitAction()),
        }),
      ]
    : [];

  const { focusedComponentId, hoveredComponentId } = previousState;
  const selectedComponentId = context.componentId;

  return {
    viewSettings,
    sourceData,
    sourceDataWithRenderingData: sourceData.map(item =>
      enhanceItemDataWithInteractiveStates({
        item,
        focusedComponentId,
        hoveredComponentId,
        selectedComponentId,
      })
    ),
    collapsed,
    collapsibleIds,
    bypassLimit,
    errors,
    focusedComponentId,
    hoveredComponentId,
    selectedComponentId,
  };
};
const setInteractiveComponentStates = (
  previousState: ComponentTreeViewModel,
  {
    focusedComponentId,
    hoveredComponentId,
  }: { focusedComponentId: string | null; hoveredComponentId: string | null }
): ComponentTreeViewModel => {
  const cleanSourceDataItems = previousState.sourceData;
  const selectedComponentId = previousState.selectedComponentId;

  const sourceDataWithUpdatedInteractiveStates = cleanSourceDataItems.map(
    item =>
      enhanceItemDataWithInteractiveStates({
        item,
        focusedComponentId,
        hoveredComponentId,
        selectedComponentId,
        prevFocusedComponentId: previousState.focusedComponentId,
      })
  );

  return {
    ...previousState,
    sourceDataWithRenderingData: sourceDataWithUpdatedInteractiveStates,
    focusedComponentId,
    hoveredComponentId,
  };
};

const interactiveComponentStates$ = combineLatest([
  getFocusedStream(VIEW_ID),
  getHoveredStream(VIEW_ID),
]).pipe(
  map(([focusedComponentId, hoveredComponentId]) => ({
    focusedComponentId,
    hoveredComponentId,
  }))
);

export const getViewModel$ = (
  viewSettings$: Observable<ComponentTreeViewSettings>
) => {
  const sourceData$ = combineLatest([
    viewSettings$,
    context$,
    collapsed$,
    action$.pipe(ofType(bypassLimitAction), startWith(false), map(Boolean)),
    modelUpdateNotification$,
  ]);

  return action$.pipe(
    combineReducers<ComponentTreeViewModel>(emptyState, [
      streamReducer(sourceData$, resetComponentSourceData),
      streamReducer(interactiveComponentStates$, setInteractiveComponentStates),
    ])
  );
};

export { toggleCollapse, setCollapsed };
