import { ZoomTransform, zoomIdentity } from 'd3';
import { dispatchAction } from '@ardoq/rxbeach';
import { zoomChanged } from './actions';
import {
  BUTTON_CLICK_ZOOM_FACTOR,
  EXPANDER_RADIUS,
  NODE_RADIUS,
  TEXT_NOMINALSIZE,
} from './consts';
import { RelationshipsNode, WindowRect } from './types';
import { absolutePosition } from './visuals/node';
import { markFatness } from './visuals/util';
import { Rect, type Size, inside } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { DisabledZoomControls } from '@ardoq/view-settings';

const generatorForEach =
  <G>(generator: (target: G) => Generator<G>) =>
  (target: G, func: (target: G) => void) => {
    for (const currentTarget of generator(target)) {
      func(currentTarget);
    }
  };
const generatorEvery =
  <G>(generator: (target: G) => Generator<G>) =>
  (target: G, predicate: (target: G) => boolean) => {
    for (const currentTarget of generator(target)) {
      if (!predicate(currentTarget)) {
        return false;
      }
    }
    return true;
  };

function* includeAllAncestors(
  node: RelationshipsNode
): Generator<RelationshipsNode> {
  yield node;
  if (node.parent) {
    yield* includeAllAncestors(node.parent);
  }
}
export const ancestorsIncludes = (
  node: RelationshipsNode,
  value: RelationshipsNode
) => {
  for (const currentNode of includeAllAncestors(node)) {
    if (currentNode === value) {
      return true;
    }
  }
  return false;
};

export const ancestorsForEach = generatorForEach(includeAllAncestors);
export const everyAncestor = generatorEvery(includeAllAncestors);

export function* includeAllDescendants(
  node: RelationshipsNode
): Generator<RelationshipsNode> {
  yield node;
  if (!node.children) {
    return;
  }
  for (const child of node.children) {
    yield* includeAllDescendants(child);
  }
}
export const findDescendant = (
  node: RelationshipsNode,
  predicate: (node: RelationshipsNode) => boolean
) => {
  for (const currentNode of includeAllDescendants(node)) {
    if (predicate(currentNode)) {
      return currentNode;
    }
  }
  return null;
};

export const descendantsForEach = generatorForEach(includeAllDescendants);
export const everyDescendant = generatorEvery(includeAllDescendants);

export const isAncestor = (
  descendant: RelationshipsNode,
  ancestor: RelationshipsNode
): boolean =>
  descendant.parent
    ? descendant.parent === ancestor || isAncestor(descendant.parent, ancestor)
    : false;

const FIT_NODE_VERTICAL_MARGIN = 2 * (TEXT_NOMINALSIZE * 1.5 + EXPANDER_RADIUS);

export const getNodeAABB = (node: RelationshipsNode): number[] | null => {
  let aabb: number[] = [0, 0, 0, 0];

  const doit = (
    { r, isSynthetic, children }: RelationshipsNode,
    tx: number,
    ty: number
  ) => {
    if (isSynthetic && children) {
      if (
        !aabb ||
        tx - r < aabb[0] ||
        ty - r < aabb[1] ||
        tx + r > aabb[2] ||
        ty + r > aabb[3]
      ) {
        children.forEach(child => doit(child, tx + child.x, ty + child.y));
      }
    }

    if (!isSynthetic) {
      if (aabb) {
        aabb[0] = Math.min(aabb[0], tx - r);
        aabb[1] = Math.min(aabb[1], ty - r);
        aabb[2] = Math.max(aabb[2], tx + r);
        aabb[3] = Math.max(aabb[3], ty + r);
      } else {
        aabb = [tx - r, ty - r, tx + r, ty + r];
      }

      if (children) {
        aabb[3] += EXPANDER_RADIUS;
      }
    }
  };

  doit(node, 0, 0);

  return aabb;
};

const getFitNodeToCanvasScale = (
  canvasOffsetWidth: number,
  canvasOffsetHeight: number,
  nodeDiameter: number
) =>
  Math.min(
    canvasOffsetWidth / nodeDiameter,
    canvasOffsetHeight / (nodeDiameter + FIT_NODE_VERTICAL_MARGIN)
  );

export const getFitNodeTransform = (
  canvas: HTMLCanvasElement,
  node: RelationshipsNode
) => {
  const bounds = getNodeAABB(node);

  if (bounds) {
    const [centerX, centerY] = absolutePosition(node);

    const { r } = node;
    const { offsetHeight, offsetWidth } = canvas;
    /** the height of the node in addition to its aabb ...more or less */
    const additionalNodeHeight = 3 * TEXT_NOMINALSIZE + 2 * markFatness(r);
    const availableHeight = offsetHeight - additionalNodeHeight;

    const [left, top, right, bottom] = bounds;

    const scale = Math.min(
      offsetWidth / (right - left),
      availableHeight / (bottom - top)
    );

    return zoomIdentity
      .translate(
        offsetWidth / 2,
        availableHeight / 2 + additionalNodeHeight / 2
      )
      .scale(scale)
      .translate(
        -(centerX + 0.5 * (right + left)),
        -(centerY + 0.5 * (bottom + top))
      );
  }

  return zoomIdentity;
};

export const transformViewCanvas = (
  transform: ZoomTransform,
  viewInstanceId: string
) => {
  const canvasTransform = new ZoomTransform(
    transform.k * devicePixelRatio,
    transform.x * devicePixelRatio,
    transform.y * devicePixelRatio
  );

  dispatchAction(
    zoomChanged({
      transform: canvasTransform,
    }),
    viewInstanceId
  );
};

/**
 * Although it is possible to disable all zoom controls at once, we disable only the one
 * control when reaching a corresponding zoom  limit during a "natural" user flow.
 *
 * This function is intentionally incorrect mathematically (ZOOM_OUT state scale should be
 * multiplied by ZOOM_FACTOR ** -1), because the zoom factor of a button click and a wheel
 * tick aren't the same. To always reach and display the upper zoom limit we set the disabled
 * state one scaleBy(ZOOM_FACTOR) before the limit is reached. Because of the scale size.
 */
export const getNaturalZoomControlsDisabledState = (
  currentScale: number,
  currentZoomScaleExtent: [min: number, max: number]
) => {
  const scaleWithoutDevicePixelRatio = currentScale / devicePixelRatio;
  return scaleWithoutDevicePixelRatio <= currentZoomScaleExtent[0]
    ? DisabledZoomControls.ZOOM_OUT
    : scaleWithoutDevicePixelRatio * BUTTON_CLICK_ZOOM_FACTOR >=
        currentZoomScaleExtent[1]
      ? DisabledZoomControls.ZOOM_IN
      : DisabledZoomControls.NONE;
};

export const getZoomScaleExtent = (
  canvas: HTMLCanvasElement | null,
  rootNode: RelationshipsNode | null
): [min: number, max: number] =>
  canvas && rootNode
    ? zoomScaleExtent(
        { width: canvas.offsetWidth, height: canvas.offsetHeight },
        getFitNodeTransform(canvas, rootNode).k
      )
    : [0, Infinity];

/**
 * @param param0 the canvas offsetWidth and offsetHeight.
 * @param scaleToFit the scale ("k") of the transform to fit the root node.
 */
export const zoomScaleExtent = (
  { width: canvasWidth, height: canvasHeight }: Size,
  scaleToFit: number
): [min: number, max: number] => {
  const newZoomScaleExtent: [number, number] = [
    scaleToFit,
    getFitNodeToCanvasScale(canvasWidth, canvasHeight, NODE_RADIUS * 2),
  ];
  return newZoomScaleExtent;
};

const convertRelationshipsViewPointOrRectToRectObject = (
  pointOrRect: Point | WindowRect
): Rect => {
  const [x, y, bottom = x, right = y] = pointOrRect;
  return {
    left: x,
    top: y,
    right,
    bottom,
    height: bottom - y,
    width: right - x,
  };
};

export const isPointInRect = (point: Point, rect: WindowRect) =>
  inside(
    convertRelationshipsViewPointOrRectToRectObject(point),
    convertRelationshipsViewPointOrRectToRectObject(rect)
  );

type FindPathAndViewportBoundaryIntersectionArgs = {
  currentDistance: number;
  startDistanceLimit: number;
  endDistanceLimit: number;
  pathElement: SVGPathElement;
  currentWindowRect: WindowRect | null;
};
/**
 * Find the distance of the intersection point between the path and the boundaries of the viewport.
 * Returns the intersection DOMpoint and the pahtLength if the intersection point is found
 */
export const findPathAndViewportBoundaryIntersection = ({
  currentDistance,
  startDistanceLimit,
  endDistanceLimit,
  pathElement,
  currentWindowRect,
}: FindPathAndViewportBoundaryIntersectionArgs): {
  intersectionPointDistance: number;
  intersectionPoint: DOMPoint;
} | null => {
  const currentPoint = pathElement?.getPointAtLength(currentDistance);
  if (!currentWindowRect || !currentPoint) {
    return null;
  }

  // Base case: if the point is intersecting with viewport boundaries, return the distance and
  // the intersection point. We round the numbers to decrease the amount of calls for a sub-
  // pixel difference in the label placement. Remove it if needed, it will increase the number
  // of recursive calls.
  const roundedCurrentX = Math.floor(currentPoint.x);
  const roundedCurrentY = Math.floor(currentPoint.y);
  if (
    currentWindowRect
      .map(Math.floor)
      .some(
        viewportBoundary =>
          roundedCurrentX === viewportBoundary ||
          roundedCurrentY === viewportBoundary
      )
  ) {
    return {
      intersectionPointDistance: currentDistance,
      intersectionPoint: currentPoint,
    };
  }

  const isCurrentPointInWindowRect = isPointInRect(
    [currentPoint.x, currentPoint.y],
    currentWindowRect
  );

  // Safety: if the base case is never satisfied (there is no intersection for whatever reason),
  // we will arrive at this condition. This is valid for base case condition checks with rounded
  // and non-rounded numbers
  const isBaseCaseCacnnotBeFound = isCurrentPointInWindowRect
    ? currentDistance === startDistanceLimit
    : currentDistance === endDistanceLimit;

  const findIntersectionArgs = isCurrentPointInWindowRect
    ? // if the current point is within the viewPort - move towards the edge
      {
        currentDistance: (currentDistance + endDistanceLimit) / 2,
        startDistanceLimit: currentDistance,
        endDistanceLimit: endDistanceLimit,
        pathElement,
        currentWindowRect,
      }
    : // if the current point is outside the viewPort - move towards the center
      {
        currentDistance: (currentDistance + startDistanceLimit) / 2,
        startDistanceLimit: startDistanceLimit,
        endDistanceLimit: currentDistance,
        pathElement,
        currentWindowRect,
      };
  return isBaseCaseCacnnotBeFound
    ? null
    : findPathAndViewportBoundaryIntersection(findIntersectionArgs);
};
