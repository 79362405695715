import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { setCollapsed } from '../models/viewModel$';

interface GetLeftMenuConfigParams {
  collapsed: Record<ArdoqId, boolean>;
  collapsibleIds: ArdoqId[];
}

export const getLeftMenu = ({
  collapsed,
  collapsibleIds,
}: GetLeftMenuConfigParams): SettingsConfig[] => {
  const isAllCollapsed = collapsibleIds.every(
    componentId => collapsed[componentId]
  );

  return [
    {
      id: 'CollapseAll',
      label: 'Collapse all',
      iconName: IconName.COLLAPSE_ALL,
      isDisabled: collapsibleIds.length === 0,
      isActive: isAllCollapsed,
      onClick: () => {
        dispatchAction(setCollapsed(collapsibleIds));
      },
      type: SettingsType.BUTTON,
    },
    {
      id: 'ExpandAll',
      label: 'Expand all',
      iconName: IconName.EXPAND_ALL,
      isDisabled: collapsibleIds.length === 0,
      isActive:
        Object.keys(collapsed).length === 0 ||
        !Object.values(collapsed).some(Boolean),
      onClick: () => {
        dispatchAction(setCollapsed([]));
      },
      type: SettingsType.BUTTON,
    },
  ];
};
