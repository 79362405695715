import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { connect } from '@ardoq/rxbeach';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import TimelineView2024 from './TimelineView2024';
import TimelineView from 'tabview/timeline/TimelineView';

/**
 * For instnace, TimelineView2024 should be available only in phoenix mode. It is not directly com-
 * patible with Discover yet, due to the phoenix mode scope data and streams missing in Discover.
 * This can be a temporary condition, at the moment it is not fully clear what road we will take.
 */
const TimelineViewController = ({
  isViewpointMode,
}: LoadedGraphWithViewpointMode) =>
  isViewpointMode ? <TimelineView2024 /> : <TimelineView />;

export default connect(TimelineViewController, isViewpointMode$);
