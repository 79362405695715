import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { SpiderChartPoint } from './view/types';
import { sum, mean } from 'lodash';
import { ComponentTypeForLegend, ViewLegendRowModel } from '@ardoq/view-legend';
import { getRangeColor } from './view/utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import allDescendantsReducer from 'modelInterface/components/allDescendantsReducer';
import { ChildAggregationFunction } from './types';
import { fieldInterface } from '@ardoq/field-interface';
import { getComponentLabelParts } from '@ardoq/graph';

const getFieldValueAsNumeric = (componentId: ArdoqId, fieldName: string) => {
  const fieldValue = componentInterface.getFieldValue(componentId, fieldName);

  if (Number.isFinite(fieldValue)) {
    return fieldValue as number;
  }

  return 0;
};

const getChartPointValue = (
  componentId: ArdoqId,
  fieldName: string,
  childAggregationFunction: ChildAggregationFunction
) => {
  if (childAggregationFunction === 'zero') {
    return getFieldValueAsNumeric(componentId, fieldName);
  }

  const descendants = [componentId].reduce(allDescendantsReducer, []);

  const values = descendants.map(descendantComponentId =>
    getFieldValueAsNumeric(descendantComponentId, fieldName)
  );

  switch (childAggregationFunction) {
    case 'sum':
      return sum(values);
    case 'average':
      return mean(values);
  }
};

const getDataPoint = (
  componentId: ArdoqId,
  field: APIFieldAttributes,
  childAggregationFunction: ChildAggregationFunction,
  isFlipped: boolean
) => {
  const value = getChartPointValue(
    componentId,
    field.name,
    childAggregationFunction
  );

  const labelInfo = isFlipped
    ? { label: field.label, fieldLabel: null, fieldValue: null }
    : getComponentLabelParts(componentId);

  const fieldId = field._id;
  return {
    id: `${componentId}~~${fieldId}`,
    componentId,
    labelInfo,
    value,
    formattedValue: fieldInterface.format({ fieldId, value }) ?? `${value}`,
  };
};

type GetChartPointsArgs = {
  relevantComponentIds: ArdoqId[];
  numericFields: APIFieldAttributes[];
  selectedFieldNames: Record<string, boolean>;
  childAggregationFunction: ChildAggregationFunction;
  isFlipped: boolean;
};

export const getChartPoints = ({
  relevantComponentIds,
  numericFields,
  selectedFieldNames,
  childAggregationFunction,
  isFlipped,
}: GetChartPointsArgs): SpiderChartPoint[][] => {
  if (relevantComponentIds.length < 1) {
    return [];
  }

  const selectedFieldNamesList = Object.keys(selectedFieldNames).filter(
    name => selectedFieldNames[name]
  );

  const fields = numericFields.filter(field =>
    selectedFieldNamesList.includes(field.name)
  );

  if (isFlipped) {
    return relevantComponentIds.map(componentId =>
      fields.map(field =>
        getDataPoint(componentId, field, childAggregationFunction, isFlipped)
      )
    );
  }

  return fields.map(field =>
    relevantComponentIds.map(componentId =>
      getDataPoint(componentId, field, childAggregationFunction, isFlipped)
    )
  );
};

type GetChildComponentIdsArgs = {
  includeAllDescendants: boolean;
  workspaceId: ArdoqId;
  selectedComponentId?: ArdoqId;
};

export const getChildComponentIds = ({
  includeAllDescendants,
  selectedComponentId,
  workspaceId,
}: GetChildComponentIdsArgs) => {
  const childrenIds = selectedComponentId
    ? componentInterface.getChildren(selectedComponentId)
    : componentInterface.getRootComponents(workspaceId);

  return (
    includeAllDescendants
      ? childrenIds.reduce(allDescendantsReducer, [])
      : childrenIds
  ).filter(componentInterface.isIncludedInContextByFilter);
};

type GetLegendRowsParams = {
  sideways: boolean;
  workspaceId: ArdoqId;
  relevantComponentIds: ArdoqId[];

  selectedFields: APIFieldAttributes[];
};

const getLegendContent = (labels: string[]): ComponentTypeForLegend[] =>
  labels.map((label, index) => ({
    label: label || '',
    color: getRangeColor(index),
    representationData: {
      isImage: false,
      value: null,
    },
  }));

export const getLegendRows = ({
  sideways,
  workspaceId,
  relevantComponentIds,
  selectedFields,
}: GetLegendRowsParams): ViewLegendRowModel[] => {
  const title = sideways ? 'Components' : 'Values';

  const legendLabels = sideways
    ? relevantComponentIds
        .map(componentInterface.getDisplayName)
        .filter(ExcludeFalsy)
    : selectedFields.map(field => field.label);

  return [
    {
      title,
      id: workspaceId,
      sections: [
        {
          heading: null,
          items: getLegendContent(legendLabels),
        },
      ],
    },
  ];
};
