import { ChevronIcon } from '@ardoq/icons';
import styled, { css } from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import {
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import { colors } from '@ardoq/design-tokens';

const TextButton = styled.div<{ $marginTop: boolean }>`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  display: inline-block;
  padding-left: ${horizontalSpacing.SMALL};
  cursor: pointer;
  ${props =>
    props.$marginTop &&
    css`
      margin-top: ${verticalSpacing.SMALL};
    `}
  @media print {
    display: none;
  }
`;

const SmallChevron = styled(ChevronIcon)`
  height: 16px;
  margin: 0 2px 0 2px;
  vertical-align: middle;
`;

const ExpandButton = ({
  isExpanded,
  expand,
  showLessString = 'Show less',
  showMoreString = 'Show more',
  marginTop = true,
  className,
}: {
  isExpanded: boolean;
  expand: (expand: boolean) => void;
  showLessString?: string;
  showMoreString?: string;
  marginTop?: boolean;
  className?: string;
}) =>
  isExpanded ? (
    <TextButton
      className={className}
      $marginTop={marginTop}
      onClick={() => expand(false)}
    >
      {showLessString}
      <SmallChevron direction="up" style={{ fontSize: '16px' }} />
    </TextButton>
  ) : (
    <TextButton
      className={className}
      $marginTop={marginTop}
      onClick={() => expand(true)}
    >
      {showMoreString}
      <SmallChevron direction="down" style={{ fontSize: '16px' }} />
    </TextButton>
  );

export default ExpandButton;
