import { ViewIds } from '@ardoq/api-types';
import { MutableRefObject } from 'react';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import {
  SettingsBar,
  getFieldDropdownOptions,
  dropdownTraversalMenu,
} from '@ardoq/settings-bar';
import { SettingsType } from '@ardoq/view-settings';
import { HierarchicalPackingViewProperties } from '../../types';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getExportsForHtmlView } from '@ardoq/export';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import { IconName } from '@ardoq/icons';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { maxDepthContainerMenuItem } from '../menuItems';

type HierarchicalPackingSettingsBarProps = HierarchicalPackingViewProperties & {
  viewContainer: MutableRefObject<HTMLElement | null>;
};

const getLeftMenuConfig = ({
  viewSettings,
  viewModel: {
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
  },
  numericFields,
}: HierarchicalPackingSettingsBarProps) => [
  dropdownTraversalMenu({
    viewSettings,
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
    viewId: ViewIds.HIERARCHICAL_PACKING,
    maximumDepth: 99,
    onViewSettingsUpdate,
    quickPerspectiveReferenceTypeFilters:
      filterInterface.getActiveQuickReferenceTypeFilters(),
  }),
  {
    id: 'sizeByFieldName',
    label: 'Size by field',
    type: SettingsType.DROPDOWN,
    iconName: IconName.FORMAT_LIST_BULLETED,
    options: getFieldDropdownOptions(
      ViewIds.HIERARCHICAL_PACKING,
      numericFields,
      'sizeByFieldName',
      fieldName => viewSettings.sizeByFieldName === fieldName,
      onViewSettingsUpdate
    ),
  },
  maxDepthContainerMenuItem(
    ViewIds.HIERARCHICAL_PACKING,
    viewSettings.maxDepth
  ),
];

const HierarchicalPackingSettingsBar = (
  args: HierarchicalPackingSettingsBarProps
) => {
  const { viewSettings, viewContainer } = args;
  const exports = getExportsForHtmlView({
    container: () => viewContainer.current,
    exportedViewMetadata: {
      name: ViewIds.HIERARCHICAL_PACKING,
    },
    getStyleSheets,
  });

  return (
    <SettingsBar
      viewId={ViewIds.HIERARCHICAL_PACKING}
      leftMenu={getLeftMenuConfig(args)}
      rightMenu={getRightMenuConfig({
        viewId: ViewIds.HIERARCHICAL_PACKING,
        viewstate: viewSettings,
        exports,
        onViewSettingsUpdate,
      })}
    />
  );
};

export default HierarchicalPackingSettingsBar;
