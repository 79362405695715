import { useContext, useEffect, useState } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { connectInstance, dispatchAction } from '@ardoq/rxbeach';
import { getViewpointModeViewModel$ } from './viewModel/getViewpointModeViewModel$';
import {
  Timeline,
  TimelineProperties,
  getLegendRows,
  setViewSettingsControlsId,
} from '@ardoq/timeline2024';
import type { TimelineViewModelState } from '@ardoq/timeline2024';
import { ViewLegendContainer } from '@ardoq/graph';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
} from '@ardoq/view-legend';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import EmptyState from './EmptyState';
import UserSettings from 'models/UserSettings';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { useEffectOnce } from '@ardoq/hooks';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { isPresentationMode } from '../../appConfig';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { isInScopeDiffMode } from '../../scope/scopeDiff';
import { ViewPaneSettingsControlsIdContext } from '@ardoq/view-settings';
import EphemeralNotification from 'ephemeralNotification/EphemeralNotification';
import { EphemeralNotificationKeys } from 'ephemeralNotification/types';
import { zIndex } from '@ardoq/design-tokens';

const VIEW_ID = ViewIds.TIMELINE;

const TimelineView = (props: TimelineViewModelState) => {
  const [errorState, setErrorState] = useState({
    isNeverShowAgainSet: false,
    clearedErrors: false,
    clearedHasClones: false,
  });

  const viewPaneSettingsControlsId = useContext(
    ViewPaneSettingsControlsIdContext
  );

  useEffectOnce(() => {
    const readNeverShowAgain = async () => {
      const isNeverShowAgainSet =
        (await new UserSettings(ViewIds.TIMELINE).get<boolean>(
          NEVER_SHOW_AGAIN
        )) ?? false;
      if (isNeverShowAgainSet !== errorState.isNeverShowAgainSet) {
        setErrorState({ ...errorState, isNeverShowAgainSet });
      }
    };

    readNeverShowAgain();
  });

  const {
    viewStatus,
    timelineViewModelData: {
      timelineViewFieldsRegistry: {
        timeSpanFields,
        milestoneFields,
        orderedSelectedMilestoneFields,
        orderedSelectedPhaseFields,
      },
      legendComponentTypes,
      hasGrouping,
    },
    viewModelSourceData: {
      errors,
      hasClones,
      timelineViewEventCallbacks: {
        openDateRangeViewSetting,
        openMilestonesViewSetting,
      },
      viewInstanceId,
      timelinePackageInterface: { componentDoubleClickHandler },
    },
    timelineViewRenderingData: {
      timelineChartRenderingData: { timelineChartProperties },
      renderedItemLabels,
      renderedItemPhases,
      rowHeights,
      distancesInPixelsToParentOrPreviousSibling,
    },
    viewSettings,
  } = props;

  /**
   * This will be unnecessary when we have a wrapper component with separated model
   * and rendering data states - the menu composition is called with result of
   * rendering data. The rendering data state should be composed in a wrapper component,
   * where we have access to the provided settings id context.
   */
  useEffect(() => {
    dispatchAction(
      setViewSettingsControlsId({ viewPaneSettingsControlsId }),
      viewInstanceId
    );
  }, [viewPaneSettingsControlsId, viewInstanceId]);

  const { isLegendActive, selectedMilestoneFields, selectedPhaseFields } =
    viewSettings;

  const renderViewErrors = () => (
    <ErrorInfoBox
      errors={errorState.clearedErrors ? [] : errors}
      hasClones={
        !errorState.isNeverShowAgainSet &&
        !errorState.clearedHasClones &&
        hasClones
      }
      clearHasClones={() => {
        if (errorState.clearedHasClones) {
          return;
        }
        setErrorState({ ...errorState, clearedHasClones: true });
      }}
      clearErrors={() => {
        if (errorState.clearedErrors) {
          return;
        }
        setErrorState({ ...errorState, clearedErrors: true });
      }}
      isShowNeverAgainSet={errorState.isNeverShowAgainSet}
      isPresentationMode={isPresentationMode()}
      toggleNeverShowAgain={() => {
        const isNeverShowAgainSet = !errorState.isNeverShowAgainSet;
        new UserSettings(ViewIds.TIMELINE).set(
          NEVER_SHOW_AGAIN,
          isNeverShowAgainSet
        );
        setErrorState({ ...errorState, isNeverShowAgainSet });
      }}
    />
  );

  const activeDiffMode = isInScopeDiffMode() ? getActiveDiffMode() : null;
  const renderViewLegend = () => (
    <HeightOffset>
      {heightOffset => (
        <ViewLegendContainer
          heightOffset={heightOffset}
          visible={isLegendActive}
        >
          <ViewLegend
            componentTypes={legendComponentTypes}
            activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
            additionalRows={getLegendRows({
              orderedSelectedMilestoneFields,
              orderedSelectedPhaseFields,
            })}
            activeDiffMode={activeDiffMode}
          />
        </ViewLegendContainer>
      )}
    </HeightOffset>
  );

  const renderEmptyState = () => (
    <EmptyState
      milestoneFields={milestoneFields}
      timeSpanFields={timeSpanFields}
      selectedMilestoneFields={selectedMilestoneFields}
      selectedPhaseFields={selectedPhaseFields}
      openDateRangeViewSetting={openDateRangeViewSetting}
      openMilestonesViewSetting={openMilestonesViewSetting}
    />
  );

  const timelineProperties: TimelineProperties = {
    contextMenuDataAttributeName: COMPONENT_ID_ATTRIBUTE, // GLOBAL_HANDLER_ID_ATTRIBUTE for Discover
    hasGrouping,
    timelineChartProperties,
    renderedItemLabels,
    renderedItemPhases,
    rowHeights,
    distancesInPixelsToParentOrPreviousSibling,
    componentDoubleClickHandler,

    renderViewErrors,
    renderViewLegend,
    renderEmptyState,

    appSpecificProperties: {},
    viewInstanceId,
    viewStatus,
  };
  return (
    <>
      <Timeline {...timelineProperties} />
      <EphemeralNotification
        wrapperPositionStyle={{
          position: 'absolute',
          top: 16,
          paddingLeft: 16,
          zIndex: zIndex.VIEW_LEGEND,
          justifyContent: 'start',
        }}
        ephemeralNotificationKey={
          EphemeralNotificationKeys.TIMELINE_MULTI_LABEL_PROMOTION
        }
        notificationBodyStyle={{ maxWidth: 860, flex: 1 }}
      />
    </>
  );
};

const PerformanceTrackedTimelineView = (props: TimelineViewModelState) =>
  WithPerformanceTracking('timeline view render', 1000, {
    WrappedComponent: TimelineView,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: { itemCount: props.timelineViewModelData.viewModelData.length },
  });

export default connectInstance(
  PerformanceTrackedTimelineView,
  getViewpointModeViewModel$
);
