export const EMPTY_LIST_DATA = {
  componentIds: [],
  workspaceName: null,
  allComponentsCount: 0,
};

export const CONTENT_PANE_CLASSNAME = 'contentPane';

export const CELL_SIZE = 30;
export const RENDER_BUFFER = 5;
export const INFINITE_SCROLL_THRESHOLD = 100;
export const MATRIX_CELLS_LIMIT = 25000000;

export const DEPENDENCY_MATRIX_ROW_HEADER_WIDTH = 200;
export const DEPENDENCY_MATRIX_ROW_HEADER_HORIZONTAL_PADDING = 10;
export const DEPENDENCY_MATRIX_COLUMN_HEADER_WIDTH = 175;
export const DEPENDENCY_MATRIX_COLUMN_HEADER_RIGHT_PADDING = 5;
export const DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN = 6;
/** this is the font size from typography text2. */
export const DEPENDENCY_MATRIX_HEADER_COMPONENT_FONT_SIZE = 14;
