import {
  APIFieldType,
  APIReferenceAttributes,
  APIReferenceAttributesLite,
  ArrowType,
  FilterInterfaceFilter,
  isArrowType,
} from '@ardoq/api-types';
import Fields from 'collections/fields';
import CurrentUser from 'models/currentUser';
import Reference from 'models/reference';
import { getReferenceTypeSvgStyle } from '@ardoq/renderers';
import {
  AugmentedModel,
  getAttributeOfModel,
  getAttributesOfModel,
  StringKeys,
} from 'modelInterface/genericInterfaces';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { ExcludedSet } from 'collections/consts';
import References from 'collections/references';
import {
  CanReferenceBeUnlocked,
  CreateReference,
  DestroyReference,
  FlipReferenceSourceAndTarget,
  GetAttributes,
  GetAttribute,
  GetLiteAttributes,
  GetCssClassNames,
  GetCssColors,
  GetDescription,
  GetDisplayText,
  GetFields,
  GetFieldValue,
  GetGlobalReferenceType,
  GetGlobalTypeId,
  GetLineBeginning,
  GetLineEnding,
  GetManagedStatesForReference,
  GetModelType,
  GetName,
  GetRawName,
  GetReferenceData,
  GetReferencesCount,
  GetReferencesOfWorkspaces,
  GetReferenceTypeName,
  GetRootWorkspaceId,
  GetShortName,
  GetSourceComponentId,
  GetStubReferencesWithTypeName,
  GetStyle,
  GetTargetComponentId,
  GetTypeId,
  GetTypeNameFilter,
  GetVersion,
  GetVisualDiffType,
  HasURLFields,
  IsExternallyManaged,
  IsExternallyMissing,
  IsIncludedInContextByFilter,
  IsLocked,
  IsReference,
  IsReferenceTypeNameUsed,
  IsScenarioRelated,
  RemoveFromCollection,
  SetAttributes,
  SetLock,
  IsReferenceLabelFormattingActive,
  GetRawLabel,
  GetReferenceTypeNameByReferenceId,
  ReferenceInterfaceImplementation,
  GetGraphSnapshot,
} from '@ardoq/reference-interface';
import { pick, uniq } from 'lodash';
import Filters, { currentFilterColor } from 'collections/filters';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { getWorkspaceEntities } from 'collectionInterface/genericInterfaces';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { getFillAndStroke } from '@ardoq/color-helpers';
import { DIFF_TYPE } from '@ardoq/global-consts';
import {
  DiffType,
  ReferenceLabelSource,
  GetCssClassNamesOption,
  LinkedComponent,
  SimpleReferenceModel,
} from '@ardoq/data-model';
import { getReferenceLabelByField } from 'modelInterface/util';
import {
  isFormattedByField,
  isFormattedByReferenceType,
} from '@ardoq/reference-operations';
import { getCurrentLocale, localeAreEqualLowercase } from '@ardoq/locale';
import { VirtualReference } from 'models/virtualReference';
import { getAddToMaps } from '@ardoq/common-helpers';

// NOTE:
// The purpose of BB interfaces is to avoid exposing the BB model. In practice
// this means you should __never__ return a BB model from these functions.

const getAttributesConfig: [
  Backbone.Collection<AugmentedModel<APIReferenceAttributes>>,
  Map<StringKeys<APIReferenceAttributes>, StringKeys<APIReferenceAttributes>>,
] = [References.collection, new Map([['id', '_id']])];

const getAttributes: GetAttributes =
  getAttributesOfModel<APIReferenceAttributes>(...getAttributesConfig);

const getAttribute: GetAttribute = getAttributeOfModel<APIReferenceAttributes>(
  ...getAttributesConfig
);

const getLiteAttributes: GetLiteAttributes = referenceId => {
  const reference = References.collection.get(referenceId);
  if (!reference) return null;

  const attributes = reference.attributes as APIReferenceAttributes;
  return {
    _id: attributes._id,
    _version: attributes._version,
    name: attributes.name,
    model: attributes.model,
    type: attributes.type,
    rootWorkspace: attributes.rootWorkspace,
    lock: attributes.lock,
    source: attributes.source,
    target: attributes.target,
  } satisfies APIReferenceAttributesLite;
};

const getGlobalTypeId: GetGlobalTypeId = referenceId => {
  const reference = References.collection.get(referenceId)!;
  const refType = reference ? reference.getRefType() : null;
  return refType && refType !== 'null'
    ? `${reference.get('rootWorkspace')}-${refType.id}`
    : null;
};

const getTypeId: GetTypeId = referenceId =>
  References.collection.get(referenceId)?.getType() ?? null;

const isIncludedInContextByFilter: IsIncludedInContextByFilter =
  referenceId => {
    const reference = References.collection.get(referenceId);
    return reference ? reference.isIncludedInContextByFilter() : true;
  };

const getDisplayLabel: GetRawLabel = referenceId => {
  const currentFilter: FilterInterfaceFilter =
    Filters.getRefLabelFilter()?.[0]?.attributes;

  const filterValue =
    currentFilter?.value ?? ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE;

  if (filterValue === ReferenceLabelSource.NONE) {
    return null;
  }

  const referenceAttributes: APIReferenceAttributes =
    References.collection.get(referenceId)?.attributes;
  if (!referenceAttributes) {
    return null;
  }

  if (currentFilter && isFormattedByField(filterValue)) {
    return getReferenceLabelByField({
      referenceAttributes,
      filterValue,
      excludeTime: !currentFilter.includeTime,
    });
  }

  if (!isFormattedByReferenceType(filterValue, referenceAttributes)) {
    return referenceAttributes.displayText ?? null;
  }
  const referenceTypeName =
    referenceInterface.getModelType(referenceId)?.name || null;
  return referenceTypeName;
};

const getDescription: GetDescription = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.getDescription() : null;
};

const isReference: IsReference = referenceId =>
  References.collection.get(referenceId) instanceof Reference.model;

const hasURLFields: HasURLFields = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference
    ? Fields.collection
        .getByReference(reference)
        .filter(field => field.getType() === APIFieldType.URL)
        .map(field => reference.get(field.get('name')))
        .some(url => url)
    : false;
};

const getCssClassNames: GetCssClassNames = (referenceId, options) => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.getCSS(options) : null;
};

const getReferenceCssColors = (
  referenceId: ArdoqId,
  { useAsBackgroundStyle }: GetCssClassNamesOption
) => {
  const referenceType = getModelType(referenceId);
  if (!referenceType) {
    return { fill: undefined, stroke: undefined };
  }
  const { color } = referenceType;
  if (!useAsBackgroundStyle) {
    return { fill: undefined, stroke: color };
  }
  return getFillAndStroke(color, useAsBackgroundStyle);
};

const getCssColors: GetCssColors = (referenceId, options) => {
  const reference = References.collection.get(referenceId);
  const filterColor = reference && currentFilterColor(reference);
  return filterColor
    ? options.useAsBackgroundStyle
      ? getFillAndStroke(filterColor, options.useAsBackgroundStyle)
      : { fill: undefined, stroke: filterColor }
    : getReferenceCssColors(referenceId, options);
};

const getTargetComponentId: GetTargetComponentId = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.getTargetId() : null;
};

const getSourceComponentId: GetSourceComponentId = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.getSourceId() : null;
};

const getReferencesBySourceId = (sourceId: ArdoqId) => {
  return References.collection
    .filter(reference => reference.getSourceId() === sourceId)
    .map(reference => reference.id);
};

const getReferencesByTargetId = (targetId: ArdoqId) => {
  return References.collection
    .filter(reference => reference.getTargetId() === targetId)
    .map(reference => reference.id);
};

const getReferencesOfWorkspaces: GetReferencesOfWorkspaces = workspaceIds => {
  const referencesOfWorkspaces = References.collection
    .filter(reference => reference.isIncludedInContextByFilter())
    .filter(reference => {
      const source = reference.getSource();
      const target = reference.getTarget();

      return (
        workspaceIds.includes(source.getWorkspace()?.id) ||
        workspaceIds.includes(target.getWorkspace()?.id)
      );
    })
    .map(reference => reference.id);

  return uniq(referencesOfWorkspaces);
};

const getLineBeginning: GetLineBeginning = (referenceId, isAggregated) => {
  const reference = References.collection.get(referenceId);
  const modelType = reference && reference.getModelType();
  if (modelType?.hasCardinality) {
    return isAggregated
      ? ArrowType.NONE
      : (reference!.get('sourceCardinality') ??
          modelType.lineBeginning ??
          ArrowType.NONE);
  }
  return modelType?.lineBeginning ?? ArrowType.NONE;
};

const getDisplayText: GetDisplayText = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.get('displayText') : null;
};

const getLineEnding: GetLineEnding = (referenceId, isAggregated) => {
  const reference = References.collection.get(referenceId);
  const modelType = reference && reference.getModelType();
  const lineEndingCandidate = modelType?.lineEnding;
  const lineEnding = isArrowType(lineEndingCandidate)
    ? lineEndingCandidate
    : ArrowType.NONE;

  if (modelType?.hasCardinality) {
    return isAggregated
      ? ArrowType.NONE
      : (reference!.get('targetCardinality') ?? lineEnding);
  }
  return lineEnding;
};

const getReferenceTypeName: GetReferenceTypeName = (
  modelId: string,
  referenceTypeId: number
) => {
  const workspace = workspaceInterface.findByModel(modelId!);
  const existingRefTypes =
    workspaceInterface.getReferenceTypes(workspace?._id || '') || {};
  const name = Object.values(existingRefTypes).find(refObj => {
    return refObj.id === referenceTypeId;
  })?.name;
  return name || null;
};

const getReferenceTypeNameByReferenceId: GetReferenceTypeNameByReferenceId =
  referenceId => {
    const typeId = getTypeId(referenceId);
    const modelId = getAttribute(referenceId, 'model');
    return modelId && typeId ? getReferenceTypeName(modelId, typeId) : null;
  };

const isReferenceTypeNameUsed: IsReferenceTypeNameUsed = (
  referenceTypeName: string,
  modelId: string
) => {
  const locale = getCurrentLocale();
  const refTypeNameSearch = referenceTypeName.trim();
  const workspace = workspaceInterface.findByModel(modelId!);
  const existingRefTypes =
    workspaceInterface.getReferenceTypes(workspace?._id || '') || {};

  return Object.values(existingRefTypes).some(refType =>
    localeAreEqualLowercase(refType.name.trim(), refTypeNameSearch, locale)
  );
};

const getStyle: GetStyle = referenceId => {
  const reference = References.collection.get(referenceId);
  const refType = reference && reference.getRefType();
  return refType && refType !== 'null'
    ? getReferenceTypeSvgStyle(refType.line, refType.color)
    : null;
};

const isReferenceLabelFormattingActive: IsReferenceLabelFormattingActive =
  () => {
    const activeRefLabelFilters = Filters.getRefLabelFilter();
    return activeRefLabelFilters.length > 0;
  };

const getFields: GetFields = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? reference.getFields().map(field => field.toJSON()) : [];
};

const getManagedStatesForReference: GetManagedStatesForReference =
  referenceId => {
    const reference = References.collection.get(referenceId);
    if (!reference) {
      return [];
    }
    const jobIds = workspaceInterface
      .getWorkspaceIntegrations(reference.get('rootWorkspace'))
      .map(x => x._id);
    const managedData = reference.attributes['ardoq-persistent']?.managed || {};
    return jobIds.filter(x => managedData[x]).map(x => managedData[x]);
  };

const isExternallyManaged: IsExternallyManaged = referenceId => {
  const activeManagedDefinitions = getManagedStatesForReference(referenceId);
  return Boolean(activeManagedDefinitions.find(x => x.seenInLastImport));
};

const isExternallyMissing: IsExternallyMissing = referenceId => {
  const activeManagedDefinitions = getManagedStatesForReference(referenceId);
  return Boolean(activeManagedDefinitions.every(x => !x.seenInLastImport));
};

const isScenarioRelated: IsScenarioRelated = referenceId => {
  const references = References.collection.getExcludedIds(
    ExcludedSet.SCENARIO_RELATED_SET
  );
  return references && references.has(referenceId);
};

const getModelType: GetModelType = referenceId =>
  References.collection.get(referenceId)?.getModelType() ?? null;

const getName: GetName = referenceId =>
  References.collection.get(referenceId)?.getName() ?? null;

const getRawName: GetRawName = referenceId =>
  References.collection.get(referenceId)?.getRawName() ?? null;

const getShortName: GetShortName = referenceId =>
  References.collection.get(referenceId)?.getShortName() ?? null;

const getReferenceData: GetReferenceData = referenceId => {
  const reference = References.collection.get(referenceId);
  return reference ? structuredClone(reference.attributes) : null;
};

const getRootWorkspaceId: GetRootWorkspaceId = referenceId =>
  References.collection.get(referenceId)?.get('rootWorkspace') ?? null;

const setLock: SetLock = (referenceId, value) => {
  const reference = References.collection.get(referenceId);
  if (!reference) {
    return;
  }
  if (value) {
    reference.lock();
  } else {
    reference.unlock();
  }
};

const isLocked: IsLocked = referenceId =>
  References.collection.get(referenceId)?.getLock() ?? false;

const getVersion: GetVersion = referenceId =>
  References.collection.get(referenceId)?.get('_version') ?? null;

const setAttributes: SetAttributes = attributes =>
  References.collection.get(attributes._id)?.set(attributes);

const removeFromCollection: RemoveFromCollection = referenceId => {
  const reference = References.collection.get(referenceId);
  if (reference) {
    References.collection.remove(reference);
  }
};

const destroyReference: DestroyReference = referenceId => {
  const reference = References.collection.get(referenceId);
  if (reference) {
    reference.destroy();
  }
};

const getCollectionSize = () => References.collection.size();

const getFieldValue: GetFieldValue = (referenceId, fieldName) =>
  References.collection.get(referenceId)?.get(fieldName) ?? null;

const getGlobalReferenceType: GetGlobalReferenceType = referenceId => {
  const reference = References.collection.get(referenceId);
  const refType = reference ? reference.getRefType() : null;
  const refTypeId = getGlobalTypeId(referenceId);
  return refType && refType !== 'null' && refTypeId
    ? {
        id: refTypeId,
        name: refType.name,
      }
    : null;
};

const createReference: CreateReference = (
  sourceId,
  targetId,
  referenceType
) => {
  const reference = new References.collection.model({
    source: sourceId,
    target: targetId,
    type: referenceType,
  });
  References.collection.add(reference);
  reference.save();
};

const flipReferenceSourceAndTarget: FlipReferenceSourceAndTarget = (
  referenceId,
  type
) => {
  References.collection.get(referenceId)?.flipSourceTarget(type);
};

const canReferenceBeUnlocked: CanReferenceBeUnlocked = referenceId => {
  const reference = References.collection.get(referenceId);
  return Boolean(reference && CurrentUser.canUnlock(reference));
};

const getTypeNameFilter: GetTypeNameFilter = (table, virtualTypeId) => id => {
  const reference = References.collection.get(id);
  if (!reference) {
    return false;
  }
  const { rootWorkspace, type } = reference.attributes;
  if (reference instanceof VirtualReference) {
    return type === virtualTypeId;
  }
  return Boolean(rootWorkspace && table[rootWorkspace] === type);
};

const getWorkspaceReferences = (id: ArdoqId) =>
  getWorkspaceEntities(APIEntityType.REFERENCE, id);

const saveAllChangedReferences = (isForced: boolean) =>
  getCollectionForEntityType(APIEntityType.REFERENCE).saveAllChangedModels(
    isForced
  );

const getVisualDiffType: GetVisualDiffType = referenceId =>
  References.collection.get(referenceId)?.get(DIFF_TYPE) ?? DiffType.NONE;

const getReferencesCount: GetReferencesCount = () => {
  return References.collection.length;
};

const getStubReferencesWithTypeName: GetStubReferencesWithTypeName = () =>
  References.collection.map(reference => {
    const type = reference.getRefType();
    return {
      ...pick(reference.attributes, ['_id', 'displayText', 'source', 'target']),
      typeName: type === 'null' ? '' : type.name,
    };
  });

/**
 * Gets a GraphModelShape with an empty referenceTypes list.
 */
const getGraphSnapshot: GetGraphSnapshot = (
  scopeReferenceIds,
  parentChildReferences
) => {
  const references = References.collection.toArray();
  const visibleReferenceIds = new Set(scopeReferenceIds);
  const referencesInTheGraph = references.filter(reference =>
    visibleReferenceIds.has(reference.id)
  );
  const sourceMap = new Map<ArdoqId, LinkedComponent[]>();
  const targetMap = new Map<ArdoqId, LinkedComponent[]>();
  const referenceMap = new Map<ArdoqId, SimpleReferenceModel>();
  const addToMaps = getAddToMaps({ referenceMap, sourceMap, targetMap });
  referencesInTheGraph.forEach(
    ({
      attributes: { _id: referenceId, source: sourceId, target: targetId },
    }) => addToMaps({ referenceId, sourceId, targetId })
  );
  // The BE sets for a parent_child reference the parent as target and the
  // child as source. The convention on the FE is the opposite.
  parentChildReferences.forEach(({ source, target, _id }) =>
    addToMaps({ referenceId: _id, sourceId: source, targetId: target })
  );
  return { sourceMap, targetMap, referenceMap, referenceTypes: [] };
};

export const referenceInterface: ReferenceInterfaceImplementation = {
  getVisualDiffType,
  getAttributes,
  getAttribute,
  getLiteAttributes,
  getGlobalTypeId,
  getTypeId,
  isIncludedInContextByFilter,
  getReferenceCssColors,
  getDisplayLabel,
  getDescription,
  isReference,
  hasURLFields,
  getCssClassNames,
  getCssColors,
  getTargetComponentId,
  getSourceComponentId,
  getReferencesBySourceId,
  getReferencesByTargetId,
  getReferencesOfWorkspaces,
  getLineBeginning,
  getDisplayText,
  getLineEnding,
  getReferenceTypeName,
  getReferenceTypeNameByReferenceId,
  isReferenceTypeNameUsed,
  getStyle,
  isReferenceLabelFormattingActive,
  getFields,
  getManagedStatesForReference,
  isExternallyManaged,
  isExternallyMissing,
  isScenarioRelated,
  getModelType,
  getName,
  getRawName,
  getShortName,
  getReferenceData,
  getRootWorkspaceId,
  setLock,
  isLocked,
  getVersion,
  setAttributes,
  removeFromCollection,
  destroyReference,
  getCollectionSize,
  getFieldValue,
  getGlobalReferenceType,
  createReference,
  flipReferenceSourceAndTarget,
  canReferenceBeUnlocked,
  getTypeNameFilter,
  getWorkspaceReferences,
  saveAllChangedReferences,
  getReferencesCount,
  getStubReferencesWithTypeName,
  getGraphSnapshot,
};
