import {
  ArdoqId,
  GroupingRule,
  GroupType,
  ReferenceDirection,
  ViewIds,
} from '@ardoq/api-types';
import getGraphShapeForTraversalHierarchy from 'modelInterface/graph/graphShapeForTraversalHierarchy';
import {
  buildGraphFromTraversalAndSearch,
  getCanBeUsedAsGroup,
  getGraphInterfaceWithModelInterfaces,
  LoadedGraphWithViewpointMode,
  TraverseOptions,
} from '@ardoq/graph';
import { componentInterface } from '@ardoq/component-interface';
import {
  ContextShape,
  GraphModelShape,
  ShowRelatedComponentsShape,
} from '@ardoq/data-model';
import {
  TRAVERSE_ALL_BASE,
  TRAVERSE_PARENTS_REFTYPES,
} from 'modelInterface/graph/consts';
import { CommonDropdownOptions } from '@ardoq/global-consts';
import { hasGroupByParentAll } from 'modelInterface/graph/utils';
import { getTraversalConfiguration } from 'tabview/graphViews/traversalViewModel';
import { DependencyMapGroupingData, ViewSettingsTraverseConfig } from './types';
import { DependencyMapViewSettings } from '@ardoq/dependency-map';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getStartSet } from 'tabview/graphViews/util';
import { buildGraph } from 'modelInterface/graph/buildGraphForWorkspaceMode';

// #region view settings traverse config
const TRAVERSE_ALL_CHILDREN: TraverseOptions = {
  ...TRAVERSE_ALL_BASE,
  incomingReferenceTypes: new Set(),
  outgoingReferenceTypes: TRAVERSE_PARENTS_REFTYPES,
};
const TRAVERSE_ALL_PARENTS_AND_CHILDREN: TraverseOptions = {
  ...TRAVERSE_ALL_BASE,
  incomingReferenceTypes: TRAVERSE_PARENTS_REFTYPES,
  outgoingReferenceTypes: TRAVERSE_PARENTS_REFTYPES,
};
const traverseReferenceChildren = (
  degreesOfChildhood: number
): TraverseOptions => ({
  maxDegrees: 0,
  maxDegreesIncoming: 0,
  maxDegreesOutgoing: degreesOfChildhood === Infinity ? 99 : degreesOfChildhood,
  isParentRelationAsReference: true,
  incomingReferenceTypes: new Set(),
  outgoingReferenceTypes: new Set([CommonDropdownOptions.PARENT_AS_REFERENCE]),
});

const groupingRuleImplicitTraversal = (
  groupingRule: GroupingRule
): TraverseOptions | null => {
  switch (groupingRule.type) {
    case GroupType.PARENT_ALL:
      return TRAVERSE_ALL_PARENTS_AND_CHILDREN;
    case GroupType.REFERENCE: {
      const isIncoming = groupingRule.direction === ReferenceDirection.INCOMING;
      const referenceTypes = new Set([groupingRule.targetId ?? '']);
      return {
        maxDegrees: 0,
        isParentRelationAsReference: false,
        maxDegreesIncoming: isIncoming ? 99 : 0,
        maxDegreesOutgoing: isIncoming ? 0 : 99,
        incomingReferenceTypes: isIncoming ? referenceTypes : null,
        outgoingReferenceTypes: isIncoming ? null : referenceTypes,
      };
    }
  }
  return null;
};

type GetViewSettingsTraverseConfigArgs = {
  supportedUserDefinedGroupingRules: GroupingRule[];
  viewSettings: DependencyMapViewSettings;
  graphModel: GraphModelShape;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
  degreesOfChildhood: number;
  groupingImplicitTraversalsEnabled: boolean;
};
export const getViewSettingsTraverseConfig = ({
  supportedUserDefinedGroupingRules,
  viewSettings,
  graphModel,
  scenarioRelatedComponents,
  degreesOfChildhood,
  groupingImplicitTraversalsEnabled,
}: GetViewSettingsTraverseConfigArgs): ViewSettingsTraverseConfig => {
  const {
    traverseOptions: userTraverseOptions,
    sequentialTraverseOptions: userSequentialTraversalOptions,
  } = getTraversalConfiguration({
    viewSettings,
    graphModel,
    scenarioRelatedComponents,
  });

  const hasUserDefinedGrouping = supportedUserDefinedGroupingRules.length > 0;

  const referenceTraverseOptions = { ...userTraverseOptions, maxDegrees: 0 };

  const baseTraverseOptions = hasUserDefinedGrouping
    ? !groupingImplicitTraversalsEnabled &&
      hasGroupByParentAll(supportedUserDefinedGroupingRules)
      ? TRAVERSE_ALL_PARENTS_AND_CHILDREN // requirement: if the user has provided a ParentAll grouping rule, traverse all parents and children
      : undefined // if the user has provided grouping without GBPA, do not traverse parents or children, because these things can interfere with the user's grouping.
    : TRAVERSE_ALL_CHILDREN; // requirement: if the user has not provided a grouping rule, traverse all children

  const groupingImplicitTraversals =
    groupingImplicitTraversalsEnabled && hasUserDefinedGrouping
      ? supportedUserDefinedGroupingRules
          .map(groupingRuleImplicitTraversal)
          .filter(ExcludeFalsy)
      : [];

  const sequentialTraverseOptions = [
    ...groupingImplicitTraversals,
    referenceTraverseOptions,
    ...(userSequentialTraversalOptions ?? []),
    degreesOfChildhood ? traverseReferenceChildren(degreesOfChildhood) : null,
  ].filter(ExcludeFalsy);

  return {
    referenceTraverseOptions,
    baseTraverseOptions,
    userTraverseOptions,
    sequentialTraverseOptions,
  };
};

// #endregion view settings traverse config

// #region getTraverseAndGroupingResult

const VIEW_ID = ViewIds.DEPENDENCY_MAP_2;

type GetTraversalAndGroupingResultArgs = {
  context: ContextShape;
  viewSettings: DependencyMapViewSettings;
  graphModel: GraphModelShape;
  loadedGraph: LoadedGraphWithViewpointMode;
  groupingData: DependencyMapGroupingData;
  includeOnlyConnectedComponents: boolean;
  viewSettingsTraverseConfig: ViewSettingsTraverseConfig;
};
export const getTraverseAndGroupingResult = ({
  context,
  viewSettings,
  graphModel,
  loadedGraph,
  groupingData,
  includeOnlyConnectedComponents,
  viewSettingsTraverseConfig,
}: GetTraversalAndGroupingResultArgs) => {
  const startSet = getStartSet(context);
  const { appliedGroupingRules, hasUserDefinedReferenceTypeGroups } =
    groupingData;

  const { baseTraverseOptions, sequentialTraverseOptions } =
    viewSettingsTraverseConfig;

  /**
   * remove interface calls from viewModel scope and pass GraphContext
   * as argument to use scopeData utils.
   * Currently, the graphInterface is used to ensure that the graph can
   * be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  const graph =
    loadedGraph.isViewpointMode && hasUserDefinedReferenceTypeGroups
      ? buildGraphFromTraversalAndSearch({
          graphInterface,
          viewId: VIEW_ID,
          graph: graphModel,
          groupingRules: appliedGroupingRules,
          loadedGraph,
          allComponentIdsRelevantForFilterCache:
            componentInterface.getAllComponentIds(), // This is a hack to satisfy the broken FilterCache.
        })
      : buildGraph({
          graphInterface,
          viewId: VIEW_ID,
          startSet,
          graph: graphModel,
          traverseOptions: baseTraverseOptions,
          sequentialTraverseOptions,
          groupingRules: appliedGroupingRules,
          includeOnlyConnectedComponents,
          useNewGrouping: viewSettings.useNewGrouping,
        });

  return {
    graph,
    startSet,
  };
};

type getTraverseAndGroupingResultForTraversalHierarchyArgs = {
  graphModel: GraphModelShape;
  loadedGraph: LoadedGraphWithViewpointMode;
  workspacesIds: ArdoqId[];
  appliedGroupingRules: GroupingRule[];
  hasValidUserDefinedGroups: boolean;
};
export const getTraverseAndGroupingResultForTraversalHierarchy = ({
  graphModel,
  loadedGraph,
  workspacesIds,
  appliedGroupingRules,
  hasValidUserDefinedGroups,
}: getTraverseAndGroupingResultForTraversalHierarchyArgs) => {
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  const graph = buildGraphFromTraversalAndSearch({
    graphInterface,
    viewId: ViewIds.DEPENDENCY_MAP_2,
    graph: graphModel,
    groupingRules: appliedGroupingRules,
    loadedGraph,
    canBeUsedAsGroup: getCanBeUsedAsGroup(
      graphInterface,
      loadedGraph,
      hasValidUserDefinedGroups
    ),
    allComponentIdsRelevantForFilterCache:
      componentInterface.getAllComponentIds(), // This is a hack to satisfy the broken FilterCache.
  });

  const { graphAsHierarchy, rootItems, startSet } =
    getGraphShapeForTraversalHierarchy({
      graphInterface,
      loadedGraph,
      graphModel,
      workspacesIds,
      traversalAndGroupingResult: graph,
    });

  return {
    graph,
    graphAsHierarchy,
    rootItems,
    startSet,
  };
};

// #endregion getTraverseAndGroupingResult
