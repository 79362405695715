import { ComponentMatrixGroup, HeaderCellModel } from './types';
import {
  COMPONENT_HIERARCHY_PADDING,
  HEADER_ROW_HEIGHT,
  MARGIN_PER_LEVEL,
  ROW_HEADER_LABEL_HEIGHT,
} from './consts';
import { ASSETS_AND_TEST_CONTAINER_ID } from '@ardoq/icons';
import { createFifoCache } from '@ardoq/common-helpers';

export const countLeaves = (
  count: number,
  group: ComponentMatrixGroup
): number =>
  group.children.size
    ? [...group.children.values()].reduce(countLeaves, count)
    : count + 1;

export const hasChildren = (group: ComponentMatrixGroup): boolean =>
  Boolean(group.children.size) &&
  [...group.children].some(
    ([childKey, childGroup]) =>
      childKey !== COMPONENT_HIERARCHY_PADDING || hasChildren(childGroup)
  );

const headerCellsReducer = (
  state: {
    currentGridColumn: number;
    result: (ComponentMatrixGroup & { gridColumn: number; colSpan: number })[];
  },
  currentValue: ComponentMatrixGroup
) => {
  const colSpan =
    [...currentValue.children.values()].reduce(countLeaves, 0) || 1;
  state.result.push({
    ...currentValue,
    gridColumn: state.currentGridColumn,
    colSpan,
  });
  state.currentGridColumn += colSpan;
  return state;
};
export const augmentRowWithGridColumnInfo = (group: ComponentMatrixGroup[]) =>
  group.reduce(headerCellsReducer, { currentGridColumn: 1, result: [] }).result;

export const isFirstChild = ({ indexAddress }: HeaderCellModel) =>
  indexAddress.length && indexAddress[indexAddress.length - 1] === 0;
export const isLastChild = ({ indexAddress, siblingCount }: HeaderCellModel) =>
  indexAddress.length &&
  indexAddress[indexAddress.length - 1] === siblingCount - 1;

/** top margin is increased by item height for each consecutive parent which is the first child of its parent. */
export const calculateRowHeaderTopOffset = (indexAddress: number[]) => {
  const relevantAddress = indexAddress.slice(1).reverse();
  let result = 0;
  for (let ii = 0; ii < relevantAddress.length; ii++) {
    if (isNaN(relevantAddress[ii])) {
      continue; // skip padding
    }
    if (relevantAddress[ii] !== 0) {
      return result; // parent at this level is not the first child. this is enough top margin.
    }
    result += ROW_HEADER_LABEL_HEIGHT;
  }
  return result;
};

export const calculateRowHeaderBottomOffset = (level: number) =>
  MARGIN_PER_LEVEL * (level - 1); // no extra margin necessary between levels 0 and 1

const getTestDivElement = (() => {
  let divElement: HTMLDivElement | null = null;
  return () => {
    if (!divElement) {
      divElement = document.createElement('div');
    }
    if (!divElement.parentElement) {
      const container = document.getElementById(ASSETS_AND_TEST_CONTAINER_ID);
      container?.appendChild(divElement);
    }
    return divElement;
  };
})();
const DIV_COLOR_BY_CLASS_NAME_CACHE_SIZE = 500;
const divColorByClassNameCache = createFifoCache<string, string>(
  DIV_COLOR_BY_CLASS_NAME_CACHE_SIZE,
  (className: string) => {
    const testDiv = getTestDivElement();
    testDiv.setAttribute('class', className);
    return window.getComputedStyle(testDiv).backgroundColor;
  }
);

export const getDivBackgroundColor = (className: string) =>
  divColorByClassNameCache(className);

export const columnHeadersAreaGridTemplateRows = (
  columnsByDepth: ComponentMatrixGroup[][]
) =>
  `${columnsByDepth
    .map(level => {
      const result = level.some(header => !header.isHidden)
        ? `${HEADER_ROW_HEIGHT}px`
        : 0;
      return result;
    })
    .join(' ')} 1fr`; // the bottom row is flexible since it is for the row level labels, which may consist of multiple rows.
