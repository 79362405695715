import { FC } from 'react';
import { isPresentationMode } from 'appConfig';
import { DoqLayout, DoqWithSpeechBubble } from '@ardoq/doq';
import { DangerButton } from '@ardoq/button';
import { IconName } from '@ardoq/icons';
import { slideInterface } from 'modelInterface/presentations/slideInterface';
import {
  APIPresentationAssetAttributes,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { confirm } from '@ardoq/modal';
import { connect } from '@ardoq/rxbeach';
import {
  ButtonLabelLinkContainer,
  DeleteDialogMessageIcon,
  DelteDialogMesageContainer,
  DoqFaceElementContainer,
  HowToReplaceSlideMessage,
  MigrateToImprovedSuccessorMessage,
  StyledButtonLabelIcon,
  ViewDiscontinuedNoPermissionsMessage,
} from './atoms';
import {
  doDeleteSlide,
  getDoqTypeForView,
  openSlideMigrationKBArticle,
} from './util';
import { slidesMigrationInfo$ } from 'streams/slidesMigrationInfo$';
import { StackPageManager } from '@ardoq/stack-page-manager';
import { deprecatedViewsSuccessors } from './restrictedViews';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import presentations$ from 'streams/presentations/presentations$';
import { combineLatest, map } from 'rxjs';
import { presentationOperations } from 'streams/presentations/presentationOperations';
import { CanCreatorMigrateSlidesInfo } from '@ardoq/api';
import { PayloadLoadSlide } from 'presentation/viewPane/actions';

let suppressDeleteSlideDialogs = false;

const deleteCurrentSlide = async (
  slideId: ArdoqId,
  presentation: APIPresentationAssetAttributes
) => {
  if (suppressDeleteSlideDialogs) {
    doDeleteSlide(slideId, presentation);
    return;
  }

  const result = await confirm({
    title: 'Delete slide?',
    text: (
      <DelteDialogMesageContainer>
        <DeleteDialogMessageIcon iconName={IconName.WARNING} /> This cannot be
        undone
      </DelteDialogMesageContainer>
    ),
    confirmButtonTitle: 'Delete slide',
    cancelButtonTitle: 'Cancel',
    isConfirmButtonDanger: true,
    showRememberActionCheckbox: true,
    rememberActionCustomCheckboxText: "Don't show this again",
  });

  if (result) {
    suppressDeleteSlideDialogs = !!result.rememberAction;
    doDeleteSlide(slideId, presentation);
  }
};

type DoqFaceMessageProps = {
  hasPermissions: boolean;
  viewHasSuccessor: boolean;
  isPresentation: boolean;
  creatorCanMigrate?: boolean;
  viewId?: ViewIds;
};
const DoqFaceMessage = ({
  hasPermissions,
  viewHasSuccessor,
  isPresentation,
  viewId,
  creatorCanMigrate,
}: DoqFaceMessageProps) => {
  if (!isPresentation && hasPermissions) {
    return <HowToReplaceSlideMessage />;
  }

  if (!hasPermissions || !viewHasSuccessor) {
    return (
      <ViewDiscontinuedNoPermissionsMessage
        creatorCanMigrate={creatorCanMigrate}
      />
    );
  }

  return <MigrateToImprovedSuccessorMessage viewId={viewId} />;
};

type DoqFaceComponentProps = {
  hasPermissions: boolean;
  isPresentation: boolean;
  slideId: ArdoqId;
  creatorCanMigrate?: boolean;
  presentation: APIPresentationAssetAttributes;
};
const DoqFaceComponent = ({
  slideId,
  hasPermissions,
  isPresentation,
  creatorCanMigrate,
  presentation,
}: DoqFaceComponentProps) => {
  const showButtons = hasPermissions && isPresentation;
  const viewId = slideInterface.getViewId(slideId) || ViewIds.PAGESVIEW;
  const viewHasSuccessor = deprecatedViewsSuccessors.has(viewId);

  return (
    <DoqFaceElementContainer id="discontinued-doqface-element-container">
      <DoqWithSpeechBubble
        title="Discontinued view"
        layout={DoqLayout.PAGE}
        message={DoqFaceMessage({
          viewHasSuccessor,
          creatorCanMigrate,
          hasPermissions,
          isPresentation,
          viewId: slideInterface.getViewId(slideId),
        })}
        buttonLabel={
          showButtons ? (
            <ButtonLabelLinkContainer>
              How to migrate slides
              <StyledButtonLabelIcon iconName={IconName.OPEN_IN_NEW} />
            </ButtonLabelLinkContainer>
          ) : undefined
        }
        doqType={getDoqTypeForView(viewId)}
        extraButton={
          showButtons ? (
            <DangerButton
              onClick={() => deleteCurrentSlide(slideId, presentation)}
            >
              Delete slide
            </DangerButton>
          ) : undefined
        }
        onButtonClick={showButtons ? openSlideMigrationKBArticle : undefined}
      />
    </DoqFaceElementContainer>
  );
};

const DiscontinuedViewReplacement: FC<{
  activePresentation?: APIPresentationAssetAttributes;
  slidesInfo?: CanCreatorMigrateSlidesInfo;
  activeSlide?: PayloadLoadSlide;
}> = ({ activeSlide, slidesInfo, activePresentation }) => {
  if (!activeSlide || !activePresentation) {
    // there is no scenario and no design for a discontinued view replacement without a slide
    // and respective text dependent on user permissions. Therefore I use the slide and not
    // the viewId. Adjust if needed.
    return null;
  }

  const isPresentation = isPresentationMode();
  const hasPermissions =
    activeSlide.hasFullAccess &&
    presentationAccessControlOperations.canEditPresentation(
      currentUserInterface.getPermissionContext(),
      activePresentation
    );

  return (
    <>
      <StackPageManager />
      <DoqFaceComponent
        creatorCanMigrate={slidesInfo?.[activeSlide.id]?.creatorCanMigrate}
        hasPermissions={Boolean(hasPermissions)}
        isPresentation={isPresentation}
        slideId={activeSlide.id}
        presentation={activePresentation}
      />
    </>
  );
};

export default connect(
  DiscontinuedViewReplacement,
  combineLatest([slidesMigrationInfo$, presentations$]).pipe(
    map(
      ([
        { slidesInfo, activePresentationId, activeSlide },
        presentationsState,
      ]) => {
        const activePresentation = activePresentationId
          ? presentationOperations.getPresentationById(
              presentationsState,
              activePresentationId
            )
          : undefined;
        return {
          activeSlide,
          slidesInfo,
          activePresentation,
        };
      }
    )
  )
);
