import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoReferencesOrListFieldsDoqProps = { doqType: DoqType };

const NoReferencesOrListFieldsDoq = ({
  doqType,
}: NoReferencesOrListFieldsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            This workspace doesn’t have any references or list fields.
          </FlexBox>
          <FlexBox gap="medium">
            To use this view, add a list field to the workspace or have at least
            one component referencing another.
          </FlexBox>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoReferencesOrListFieldsDoq;
