import {
  APIFieldAttributes,
  ArdoqId,
  ViewIds,
  APIFieldType,
} from '@ardoq/api-types';
import { PagesViewModel, PagesViewSettings } from 'tabview/pagesView/types';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { connect } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { getViewModel$ } from './viewModel$';
import styled, { createGlobalStyle } from 'styled-components';
import { SettingsBar, settingsBarConsts } from '@ardoq/settings-bar';
import { Component } from 'react';
import WorkspacePage from 'tabview/pagesView/WorkspacePage';
import ComponentPage from 'tabview/pagesView/ComponentPage';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { TrackingExportFormat } from '@ardoq/export';
import { InfiniteScroll } from 'atomicComponents/InfiniteScroll/InfiniteScroll';
import { getLeftMenu, getRightMenu } from 'tabview/pagesView/menu';
import { getDefaultAttributeLabelForComponent } from '@ardoq/renderers';
import { getComponentFieldsWithDateRanges } from './utils';
import PresentationNavigator from 'tabview/pagesView/PresentationNavigator';
import { isPresentationMode } from 'appConfig';
import { isSafari, classes } from '@ardoq/common-helpers';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { pagesContextMenuName } from './contextMenu';
import EmptyState from './EmptyState';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
} from '@ardoq/view-legend';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import { ViewLegendContainer } from '@ardoq/graph';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { componentInterface } from '@ardoq/component-interface';
import { CommonDropdownOptions } from '@ardoq/global-consts';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';
import { Features, hasFeature } from '@ardoq/features';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

const VIEW_ID = ViewIds.PAGESVIEW;

const executePrintOperation = () => {
  if (isSafari()) {
    // window.print() didn't work for Safari
    document.execCommand('print', false);
  } else {
    window.print();
  }
};

const PrintableContainer = createGlobalStyle`
    @media print {
      body {
        visibility: hidden;
      }
    }

    @media print {
      .PagesView.printable {
        visibility: visible;
        width: 100%;
      }
    }`;

const PagesViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
  @media print {
    overflow: visible;
  }
`;

const InfiniteScrollWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media print {
    overflow: visible;
  }
`;

const NavbarAndContentContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  @media print {
    overflow: visible;
  }
`;

const DEFAULT_FIELDS = [
  { name: 'component-key', type: APIFieldType.TEXT, deselectedByDefault: true },
  {
    name: 'created-by',
    type: APIFieldType.USER,
    deselectedByDefault: true,
  },
  { name: 'created', type: APIFieldType.DATE_TIME, deselectedByDefault: true },
  {
    name: 'last-modified-by',
    type: APIFieldType.USER,
    deselectedByDefault: false,
  },
  {
    name: 'lastUpdated',
    type: APIFieldType.DATE_TIME,
    deselectedByDefault: false,
  },
  { name: 'type', type: APIFieldType.TEXT, deselectedByDefault: true },
  {
    name: TAGS_FIELD_NAME,
    type: APIFieldType.LIST,
    deselectedByDefault: false,
  },
].map((field, i) => ({
  _id: `default-field-${i}`,
  label: getDefaultAttributeLabelForComponent(field.name) || field.name,
  _order: i,
  ...field,
}));

const includedFields =
  (viewSettings: PagesViewSettings) =>
  (
    field: Pick<APIFieldAttributes, 'name'> & { deselectedByDefault?: boolean }
  ) => {
    if (
      field.deselectedByDefault &&
      !viewSettings.includeFields.includes(CommonDropdownOptions.ALL)
    ) {
      return viewSettings.includeFields.includes(field.name);
    }
    return (
      viewSettings.includeFields.includes(field.name) ||
      viewSettings.includeFields.includes(CommonDropdownOptions.ALL) ||
      viewSettings.includeFields.includes(
        CommonDropdownOptions.ALL_EXCEPT_DESELECTED_BY_DEFAULT
      )
    );
  };

type PageViewProps = PagesViewModel;
class PagesView extends Component<PageViewProps> {
  state = {
    isPrintingMode: false,
  };
  printMql = window.matchMedia('print');

  printOrSaveToPdf = () => {
    trackExportingVisualization({
      name: VIEW_ID,
      format: TrackingExportFormat.PDF,
    });
    this.setState({
      isPrintingMode: true,
    });
  };

  safariBeforeAfterPrint = (mql: MediaQueryListEvent) => {
    if (!mql.matches) {
      // after print
      this.onAfterPrint();
    }
  };

  onAfterPrint = () => {
    this.setState({
      isPrintingMode: false,
    });
  };
  addPrintListeners = () => {
    if (!this.printMql.addEventListener) {
      // addListener() is deprecated but safari doesn't support addEventListener()
      this.printMql.addListener(mql => this.safariBeforeAfterPrint(mql));
    } else {
      window.addEventListener('afterprint', this.onAfterPrint);
    }
  };

  componentRenderFunction = (id: ArdoqId) => {
    const {
      referenceMap,
      viewSettings,
      isViewpointMode,
      isScenarioMode,
      permissionContext,
      subdivisions,
    } = this.props;

    const { isPrintingMode } = this.state;

    const componentData = componentInterface.getComponentData(id);

    const canEditDescription = hasFeature(Features.PERMISSION_ZONES)
      ? (
          componentInterface.getAttribute(id, 'subdivisionMembership') ??
          ([] as string[])
        ).some(membershipId =>
          subdivisionAccessControlInterface.canEditSubdivision(
            permissionContext,
            subdivisions,
            membershipId
          )
        )
      : workspaceInterface.hasWriteAccess(
          componentInterface.getWorkspaceId(id) ?? ''
        );

    return (
      <ComponentPage
        key={id}
        componentId={id}
        expandDescription={viewSettings.expandDescription}
        bypassRenderLimit={isPrintingMode}
        defaultFields={DEFAULT_FIELDS.filter(includedFields(viewSettings))}
        fields={getComponentFieldsWithDateRanges(id)
          .filter(includedFields(viewSettings))
          .filter(
            field =>
              componentData &&
              !componentAccessControlOperation.hasNoAccessToFieldInAnyModes({
                component: componentData,
                fieldId: field._id,
                permissionContext,
                subdivisionsContext: subdivisions,
                isPresentationMode: isPresentationMode(),
                isScenarioMode,
              })
          )}
        references={referenceMap.get(id)!}
        hideEmptyFields={viewSettings.hideEmptyFields}
        isLocalDateFormat={isPrintingMode}
        isViewpointMode={isViewpointMode}
        canEditDescription={canEditDescription}
      />
    );
  };

  componentDidMount(): void {
    this.addPrintListeners();
  }

  componentWillUnmount(): void {
    if (!this.printMql.removeEventListener) {
      this.printMql.removeListener(this.safariBeforeAfterPrint);
    }
    window.removeEventListener('afterprint', this.onAfterPrint);
  }

  componentDidUpdate(
    _: PageViewProps,
    prevState: Readonly<{ isPrintingMode: boolean }>
  ) {
    if (this.state.isPrintingMode && !prevState.isPrintingMode) {
      executePrintOperation();
    }
  }

  render() {
    const {
      viewSettings,
      context,
      incomingReferenceTypes,
      outgoingReferenceTypes,
      componentIds,
      allComponentIds,
      filteredComponentIds,
      workspaceFields,
      scrollToViewComponent,
      topVisibleComponentId,
    } = this.props;
    const { isPrintingMode } = this.state;

    const containerClassNames = classes(
      'PagesView', // used with integration tests
      this.state.isPrintingMode ? 'printable' : null
    );

    const isEmptyView = !componentIds.length;

    const activeConditionalFormatting =
      getActiveConditionalFormattingForLegend();

    const isLegendPossible = Boolean(
      activeConditionalFormatting.components.length ||
        activeConditionalFormatting.tags.length
    );

    const isLegendVisible =
      viewSettings[settingsBarConsts.IS_LEGEND_ACTIVE] &&
      isLegendPossible &&
      !isEmptyView;

    return (
      <>
        <PagesViewContainer
          className={containerClassNames}
          data-context-menu={pagesContextMenuName}
        >
          {!isPresentationMode() && (
            <SettingsBar
              viewId={VIEW_ID}
              leftMenu={getLeftMenu(
                viewSettings,
                incomingReferenceTypes,
                outgoingReferenceTypes,
                [...DEFAULT_FIELDS, ...workspaceFields],
                onViewSettingsUpdate
              )}
              rightMenu={getRightMenu({
                viewSettings,
                isEmptyView,
                printOrSaveToPdf: this.printOrSaveToPdf,
                isLegendPossible: isLegendPossible,
                onViewSettingsUpdate,
              })}
            />
          )}
          <NavbarAndContentContainer>
            {isPresentationMode() && (
              <PresentationNavigator
                componentIds={allComponentIds}
                filteredComponentIds={filteredComponentIds}
                workspaceId={context.workspaceId}
                topVisibleComponentId={topVisibleComponentId}
              />
            )}

            {isEmptyView ? (
              <EmptyState />
            ) : (
              <InfiniteScrollWrapper>
                <InfiniteScroll
                  workspaceId={context.workspaceId}
                  contextComponentId={context.componentId}
                  bypassRenderLimit={isPrintingMode}
                  items={componentIds}
                  itemRenderFunction={this.componentRenderFunction}
                  scrollToItem={scrollToViewComponent}
                  numberOfComponentsToRender={15}
                  shouldDispatchTopVisibleItem={true}
                  getRowElement={el =>
                    el instanceof HTMLDivElement &&
                    (el.classList.contains('pages-view-component-page') ||
                      el.dataset.clickNamespace ===
                        'pages view workspace header')
                      ? el
                      : null
                  }
                >
                  <WorkspacePage
                    workspaceId={context.workspaceId}
                    bypassRenderLimit={isPrintingMode}
                    expandDescription={viewSettings.expandDescription}
                    shouldShowDescription={!context.componentId}
                    canEditDescription={workspaceInterface.hasWriteAccess(
                      context.workspaceId
                    )}
                  />
                </InfiniteScroll>
              </InfiniteScrollWrapper>
            )}
          </NavbarAndContentContainer>
          <HeightOffset>
            {heightOffset => (
              <ViewLegendContainer
                visible={isLegendVisible && !isPrintingMode}
                heightOffset={heightOffset}
              >
                <ViewLegend
                  componentTypes={[]}
                  activeDiffMode={null}
                  activeConditionalFormatting={activeConditionalFormatting}
                />
              </ViewLegendContainer>
            )}
          </HeightOffset>
        </PagesViewContainer>
        {this.state.isPrintingMode && <PrintableContainer />}
      </>
    );
  }
}
const viewState$ = getViewSettingsStream<PagesViewSettings>(VIEW_ID);

export default connect(PagesView, getViewModel$(viewState$, context$));
