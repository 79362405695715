import { ChartConfiguration } from './types';
import { floatErrorProofAdd, floatErrorProofMultiply } from 'tabview/ticks';

type ChartAxisLevelLabelProps = {
  levelIndex: number;
  config: ChartConfiguration;
};

export const ChartAxisLevelLabel = ({
  levelIndex,
  config: { levels, height, toRight, radius, width, roundedInterval },
}: ChartAxisLevelLabelProps) => {
  const levelFactor = radius * ((levelIndex + 1) / levels);

  const level = floatErrorProofAdd(
    floatErrorProofMultiply(roundedInterval, levelIndex),
    roundedInterval
  );

  return (
    <text x={width / 2 + toRight} y={height / 2 - levelFactor}>
      {level}
    </text>
  );
};
