import { createElement } from 'react';
import { Root } from 'react-dom/client';
import {
  ILabel,
  INode,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import {
  BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS,
  PREFERRED_MINIMUM_GROUP_WIDTH,
} from '../../consts';
import {
  CollapsibleGraphGroup,
  disposeCallback,
  initializeRoot,
  ensureRoot,
  truncateComponentLabel,
} from '@ardoq/graph';
import {
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  DATA_RENDER_HASH,
  LABEL_VERTICAL_PADDING,
} from 'yfilesExtensions/styles/consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { ensureContrast } from '@ardoq/color-helpers';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { colors } from '@ardoq/design-tokens';
import { getCssClassFromDiffType } from 'scope/modelUtil';
import { GroupType } from '@ardoq/api-types';
import {
  getGroupLabelDimensions,
  getMaxGroupLabelHeight,
} from '../GroupBoundsCalculator';
import GroupHeaderLabel from '../../GroupHeaderLabel';
import {
  folderStyleGroupLabelStartX,
  getElementOpacityIfTransparentized,
  getGroupHeaderWidthWithoutLabel,
  getGroupHeaderWidthWithoutLabelModernized,
  getGroupLabelMaxWidth,
  getGroupLabelMaxWidthModernized,
  measureGroupLabel,
  measureGroupLabelModernized,
} from '../../utils';
import { createSvgElement } from '@ardoq/dom-utils';
import { getMultiLabelHash, getOtherLabelSizes } from './labelUtils';
import {
  measureExpandedGroupNodeLabelWidth,
  measureExpandedGroupNodeLabelWidthModernized,
} from './legacyLabelUtils';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_ONE_LINE_HEIGHT,
} from '../modernized/consts';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';

const render = (
  root: Root,
  container: SVGElement,
  label: ILabel,
  getRepresentationData = componentInterface.getRepresentationData,
  isModern = false
) => {
  const groupNode = label.owner as INode;
  const group = label.owner?.tag as CollapsibleGraphGroup; // using as and not instanceof for performance critical code
  const isComponent = group.isComponent();
  const { type, id, modelId, collapsed, isTransparentized } = group;
  const isWorkspace = type === GroupType.WORKSPACE;
  const representationData = isComponent
    ? getRepresentationData(modelId)
    : null;

  container.setAttribute(DATA_RENDER_HASH, getMultiLabelHash(label));
  if (isComponent) {
    container.setAttribute('class', 'component');
    container.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
  } else if (isWorkspace) {
    container.setAttribute('class', 'workspace');
  }
  if (modelId) {
    container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, modelId);
  } else {
    container.removeAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);
  }
  container.setAttribute('data-node-id', id);
  if (isModern) {
    container.style.pointerEvents = 'none';
  }
  const [backgroundFill, foregroundFill] = isComponent
    ? [true, false].map(
        useAsBackgroundStyle =>
          getComponentCssColors(modelId, { useAsBackgroundStyle })?.fill ?? null
      )
    : [colors.grey90, colors.black];
  const representationColor =
    (isModern
      ? foregroundFill
      : backgroundFill && foregroundFill
        ? ensureContrast(backgroundFill, foregroundFill)
        : backgroundFill || foregroundFill) || colors.transparent0;

  const visualDiffType = group.getVisualDiffType();
  const visualDiffClass = getCssClassFromDiffType(visualDiffType);
  const labelX = isModern // in modernized logic, labelX is relative to the label layout, which starts to the right of the expander.
    ? representationData
      ? MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT +
        MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN
      : 0
    : folderStyleGroupLabelStartX(group);

  if (!isComponent && collapsed && !isModern) {
    const textElement = createSvgElement('text', {
      x: `${labelX}`,
      y: '0',
      opacity: String(getElementOpacityIfTransparentized(isTransparentized)),
    });
    addText({
      ...(isModern
        ? MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS
        : BLOCK_DIAGRAM_GROUP_LABEL_TEXT_PARAMS),
      targetElement: textElement,
      text: group.getItemLabels()?.mainLabel ?? '',
      maximumSize: new Size(
        isModern
          ? getGroupLabelMaxWidthModernized(groupNode)
          : getGroupLabelMaxWidth(groupNode),
        isModern
          ? getMaxGroupLabelHeight(false, true)
          : BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.height
      ),
    });
    const oldTextElement = container.querySelectorAll('text')[0];
    if (oldTextElement) {
      container.replaceChild(textElement, oldTextElement);
    } else {
      container.appendChild(textElement);
    }
    return;
  }

  const groupLabels = group.getItemLabels();

  if (!groupLabels) {
    return;
  }

  const { mainLabel, otherLabels, legacyLabelParts } = groupLabels;

  const { groupLabelHeight, groupLabelWidth, groupNameMaxHeight } =
    getGroupLabelDimensions(groupNode, isModern);

  const otherLabelSizes = otherLabels
    ? getOtherLabelSizes(otherLabels, isModern)
    : [];

  const otherLabelsData =
    otherLabels &&
    otherLabels.map((otherLabel, index) => ({
      otherLabel,
      size: otherLabelSizes[index],
      previousHeight: otherLabelSizes
        .slice(0, index)
        .map(({ height }) => height)
        .reduce((a, b) => a + b + LABEL_VERTICAL_PADDING, 0),
    }));

  const legacyFieldLabelAndValue = legacyLabelParts?.fieldValue
    ? truncateComponentLabel({
        ...legacyLabelParts,
        label: '',
        measure: isModern
          ? measureExpandedGroupNodeLabelWidthModernized
          : measureExpandedGroupNodeLabelWidth,
        width:
          groupNode.layout.width -
          (isModern
            ? getGroupHeaderWidthWithoutLabelModernized(group)
            : getGroupHeaderWidthWithoutLabel(group)),
      })
    : undefined;

  root.render(
    createElement(GroupHeaderLabel, {
      text: mainLabel || '',
      fieldLabelAndValue: legacyFieldLabelAndValue,
      groupLabelHeight,
      groupLabelWidth,
      groupNameMaxHeight,
      labelX,
      representationData,
      representationColor: representationData ? representationColor : undefined,
      visualDiffClass,
      isTransparentized,
      otherLabelsData,
      isModern,
    })
  );
};
export default class MultiLabelGroupStyle extends LabelStyleBase {
  static Instance = new MultiLabelGroupStyle();
  protected constructor(
    private getRepresentationData = componentInterface.getRepresentationData,
    private isModern = false
  ) {
    super();
  }
  override createVisual(context: IRenderContext, label: ILabel): Visual | null {
    const container = createSvgElement('g');
    render(
      initializeRoot(container),
      container,
      label,
      this.getRepresentationData,
      this.isModern
    );
    LabelStyleBase.createLayoutTransform(context, label.layout, false).applyTo(
      container
    );
    const result = new SvgVisual(container);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }
  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    const container = (oldVisual as SvgVisual).svgElement;
    const hash = container.getAttribute(DATA_RENDER_HASH);
    if (hash !== getMultiLabelHash(label)) {
      render(
        ensureRoot(container),
        container,
        label,
        this.getRepresentationData,
        this.isModern
      );
    }
    LabelStyleBase.createLayoutTransform(context, label.layout, false).applyTo(
      container
    );
    return oldVisual;
  }
  override getPreferredSize(label: ILabel): Size {
    const groupNode = label.owner as INode;
    const graphGroup: CollapsibleGraphGroup | undefined = label.owner?.tag;

    const isComponent = graphGroup && graphGroup.isComponent();
    if (!graphGroup || (isComponent && graphGroup.collapsed)) {
      const { layout } = groupNode;
      if (this.isModern) {
        const { width } = layout;
        return new Size(width, MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT);
      }
      return layout.toSize();
    }
    const labelText = graphGroup.getItemLabels()?.mainLabel ?? '';

    const groupLabelWidth = this.isModern
      ? measureGroupLabelModernized(labelText)
      : measureGroupLabel(labelText);
    const totalHorizontalPadding = this.isModern
      ? getGroupHeaderWidthWithoutLabelModernized(graphGroup)
      : folderStyleGroupLabelStartX(graphGroup);
    const preferredGroupWidth = totalHorizontalPadding + groupLabelWidth;
    const {
      groupLabelHeight: classicGroupLabelHeight,
      otherLabelsHeight,
      otherLabelsWidth,
    } = getGroupLabelDimensions(groupNode, this.isModern);
    const groupLabelHeight = this.isModern
      ? MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_ONE_LINE_HEIGHT // in modernized logic, getPreferredSize is always optimistically measuring in the single-line style. we do this because the group width could potentially be very large... but the group width might not be known yet.
      : classicGroupLabelHeight;
    const mainLabelWidth = this.isModern
      ? preferredGroupWidth - totalHorizontalPadding
      : Math.min(preferredGroupWidth, PREFERRED_MINIMUM_GROUP_WIDTH);

    return new Size(
      Math.max(mainLabelWidth, otherLabelsWidth),
      groupLabelHeight + otherLabelsHeight
    );
  }
}
