import * as d3 from 'd3';
import type { HierarchicalTreemapNode } from '../../types';
import styled from 'styled-components';

const NodeElement = styled.circle``;

type HierarchicalPackingViewNodeProperties = {
  node: d3.HierarchyCircularNode<HierarchicalTreemapNode>;
};

const HierarchicalPackingViewNode = (
  props: HierarchicalPackingViewNodeProperties
) => {
  const {
    x,
    y,
    r,
    data: { className },
  } = props.node;
  return <NodeElement cx={x} cy={y} r={r} className={className ?? undefined} />;
};

export default HierarchicalPackingViewNode;
