import {
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  FreeEdgeLabelModel,
  FreeNodeLabelModel,
  GroupingKeys,
  IEdge,
  IGraph,
  IGroupBoundsCalculator,
  ILabel,
  ILabelModelParameter,
  IModelItem,
  INode,
  Insets,
  Point,
  Rect,
} from '@ardoq/yfiles';
import { isEqualWith } from 'lodash';
import * as encodingUtils from '@ardoq/html';
import { getDefaultSize, getStyle } from 'yfilesExtensions/styles/styleLoader';
import ArdoqLabelStyle from 'yfilesExtensions/styles/ArdoqLabelStyle';
import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import ArdoqEdgeStyle from 'yfilesExtensions/styles/ArdoqEdgeStyle';
import { graphItemsEqual } from 'graph/graphFunctions';
import { GraphEdge, GraphGroup, GraphItem, GraphNode } from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { ArdoqAddToScenario } from './styles/ArdoqAddToScenario';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { DiffType } from '@ardoq/data-model';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import getNodeLayoutSize from './getNodeLayoutSize';
import { isContextNode } from './styles/nodeDecorator';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  CONTEXT_HIGHLIGHT_STROKE,
  NODE_HEIGHT,
  NODE_WIDTH,
} from './styles/consts';
import { urlNodeButtonModelParameter } from 'tabview/blockDiagram/view/yFilesExtensions/labelParameters';
import { logWarn } from '@ardoq/sentry';
import type { Node, Edge } from 'graph/types';
import { ComponentBackboneModel } from 'aqTypes';
import { GraphItemModel } from 'graph/GraphItem';
import { GraphBuilderHasLayoutArgs } from './types';

type DiffUpdateGraphArgs<
  TItem extends IModelItem,
  TObj extends { id: string },
> = {
  oldMap: Map<string, TItem>;
  businessObjects: TObj[];
  createFunc: (graphItem: TObj) => TItem;
  updateFunc: (graphItem: TObj) => TItem;
  removeFunc?: (graphItem: TItem, map: Map<string, TItem>) => void;
};

const isURLLabel = (label: ILabel) => {
  return label.style instanceof ArdoqURLLabelStyle;
};

export class ArdoqYGraphBuilder {
  groupsSource: (Node | GraphGroup)[];
  nodesSource: (Node | GraphNode)[];
  edgesSource: (Edge | GraphEdge)[];
  private _groupNodeMap: Map<string, INode>;
  protected _nodeMap: Map<string, INode>;
  protected _edgeMap: Map<string, IEdge>;
  private hasGroups: boolean;
  private urlGroupNodeButtonModelParameter: ILabelModelParameter;
  private urlEdgeButtonModelParameter: ILabelModelParameter;
  private addToScenarioNodeButtonModelParameter: ILabelModelParameter;
  private addToScenarioButtonStyle: ArdoqAddToScenario;

  constructor(protected graph: IGraph) {
    // Public variables
    this.graph = graph;
    this.groupsSource = [];
    this.nodesSource = [];
    this.edgesSource = [];
    this.hasGroups = false;

    // Private variables
    this._groupNodeMap = new Map();
    this._nodeMap = new Map();
    this._edgeMap = new Map();

    // Initialization
    graph.edgeDefaults.style = new ArdoqEdgeStyle();

    // Style and labels
    const labelModel = new ExteriorLabelModel();
    labelModel.insets = new Insets(CONTEXT_HIGHLIGHT_STROKE);
    graph.nodeDefaults.labels.layoutParameter = labelModel.createParameter(
      ExteriorLabelModelPosition.SOUTH
    );

    const labelStyle = ArdoqLabelStyle.Instance;
    graph.edgeDefaults.labels.style = labelStyle;
    graph.nodeDefaults.labels.style = labelStyle;

    this.urlGroupNodeButtonModelParameter =
      new FreeNodeLabelModel().createParameter(
        new Point(0, 0), // upper left corner
        new Point(0, 0),
        new Point(0.5, 0.5)
      );
    this.urlEdgeButtonModelParameter =
      new FreeEdgeLabelModel().createDefaultParameter();

    this.addToScenarioButtonStyle = new ArdoqAddToScenario();
    this.addToScenarioNodeButtonModelParameter =
      new FreeNodeLabelModel().createParameter(
        new Point(0, -0.75),
        new Point(1, 0),
        new Point(0, 1)
      );

    const edgeLabelModel = new FreeEdgeLabelModel();
    graph.edgeDefaults.labels.layoutParameter =
      edgeLabelModel.createDefaultParameter();

    // registers information about the minimum node size of group nodes
    graph.mapperRegistry.createDelegateMapper(
      GroupingKeys.MINIMUM_NODE_SIZE_DP_KEY,
      groupNode => {
        const boundsProvider = groupNode.lookup(IGroupBoundsCalculator.$class);
        const bounds = boundsProvider!.calculateBounds(graph, groupNode);
        return bounds.toSize().toYDimension();
      }
    );
  }
  createGroupNode = (graphNode: Node | GraphGroup) => {
    const group = this.graph.createGroupNode({
      tag: graphNode,
    });
    // isGroup() doesn't seem to work for the old group nodes.
    if (graphNode instanceof GraphGroup && graphNode.hasURLFields()) {
      this.addURLNodeLabel(group, graphNode);
    }
    return group;
  };

  removeGroupNode = (groupNode: INode) => {
    this.removeItem(groupNode, this._groupNodeMap);
  };
  updateGroupNode = (graphNode: Node<GraphItemModel> | GraphGroup) =>
    this._groupNodeMap.get(graphNode.id)!;
  createNode = (graphNode: Node | GraphNode) => {
    const node = this.graph.createNode({
      parent:
        (graphNode.parent && this._groupNodeMap.get(graphNode.parent.id)) ||
        null,
      tag: graphNode,
      labels: [encodingUtils.unescapeHTML(graphNode.getLabel())],
    });

    if (graphNode.hasURLFields()) {
      this.addURLNodeLabel(node, graphNode);
    }
    if (
      graphNode instanceof GraphItem &&
      !(
        isInScopeDiffMode() &&
        graphNode.getVisualDiffType() === DiffType.PLACEHOLDER
      ) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }

    this.updateNodeLayout(node);
    return node;
  };

  updateNode = (graphNode: Node | GraphNode) => this._updateNode(graphNode);

  removeNode = (node: INode) => {
    this.removeItem(node, this._nodeMap);
  };
  createEdge = (graphEdge: Edge | GraphEdge) => {
    const edge = this.graph.createEdge({
      tag: graphEdge,
      labels: [encodingUtils.unescapeHTML(graphEdge.getLabel())],
      source:
        this._nodeMap.get(graphEdge.source) ||
        this._groupNodeMap.get(graphEdge.source)!,
      target:
        this._nodeMap.get(graphEdge.target) ||
        this._groupNodeMap.get(graphEdge.target)!,
    });

    if (graphEdge.hasURLFields()) {
      this.addURLEdgeLabel(edge, graphEdge);
    }
    return edge;
  };
  updateEdge = (graphEdge: Edge | GraphEdge) => {
    const edge = this._edgeMap.get(graphEdge.id)!;

    if (
      graphEdge.hasURLFields() &&
      !edge.labels.find(label => isURLLabel(label))
    ) {
      this.addURLEdgeLabel(edge, graphEdge);
    }

    // Create new edge if sourcePort/targetPort is gone (group Node turned into a normal node or vice-versa)
    if (!edge.sourcePort || !edge.targetPort) {
      return this.createEdge(graphEdge);
    }

    const labelsToRemove: ILabel[] = [];
    edge.labels.forEach(label => {
      if (label.tag === null) {
        this.graph.setLabelText(
          label,
          encodingUtils.unescapeHTML(graphEdge.getLabel())
        );
      } else if (isURLLabel(label) && !label.tag.hasURLFields()) {
        labelsToRemove.push(label);
      }
    });
    labelsToRemove.forEach(label => this.graph.remove(label));

    return edge;
  };

  removeEdge = (edge: IEdge) => {
    // Make sure the edge is attached to the graph
    if (edge.sourcePort || edge.targetPort) {
      this.removeItem(edge, this._edgeMap);
    }
  };
  removeItem = (item: IModelItem, map: Map<string, IModelItem>) => {
    const businessObject = item.tag;
    map.delete(businessObject.id);
    if (this.graph.contains(item)) {
      this.graph.remove(item);
    }
  };
  /** @param _unused a caller is sending a second, unused argument. ignoring this for now. */
  updateNodeLayout(node: INode, _unused?: unknown) {
    const style = getStyle(node.tag);
    const styleWidth =
      (style.size && style.size.width) || getDefaultSize().width;
    const styleHeight =
      (style.size && style.size.height) || getDefaultSize().height;
    const yAspectRatio = NODE_HEIGHT / styleHeight;
    const xAspectRatio = NODE_WIDTH / styleWidth;

    let width;
    let height;

    if (styleWidth * yAspectRatio > NODE_WIDTH) {
      width = NODE_WIDTH;
      height = styleHeight * xAspectRatio;
    } else {
      width = styleWidth * yAspectRatio;
      height = NODE_HEIGHT;
    }

    this.graph.setStyle(node, style);
    this.graph.setNodeLayout(
      node,
      new Rect(
        node.layout.topLeft,
        getNodeLayoutSize({
          styleWidth: width,
          styleHeight: height,
          hasContextHighlight: isContextNode(node),
          contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
          visualDiffType:
            node.tag instanceof GraphItem
              ? node.tag.getVisualDiffType()
              : DiffType.NONE,
        })
      )
    );
  }
  addURLNodeLabel(owner: INode, graphNode: Node | GraphNode | GraphGroup) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter:
        graphNode instanceof GraphGroup
          ? this.urlGroupNodeButtonModelParameter
          : urlNodeButtonModelParameter,
      style: ArdoqURLLabelStyle.Classic,
      tag: graphNode,
    });
  }
  addURLEdgeLabel(owner: IEdge, graphEdge: Edge | GraphEdge) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter: this.urlEdgeButtonModelParameter,
      style: ArdoqURLLabelStyle.Classic,
      tag: graphEdge,
    });
  }
  addToScenarioButton(owner: INode, graphNode: GraphItem) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter: this.addToScenarioNodeButtonModelParameter,
      style: this.addToScenarioButtonStyle,
      tag: graphNode,
    });
  }
  getNode(businessObject: ComponentBackboneModel) {
    let node;
    try {
      node = this.graph.nodes.first(
        candidate => candidate.tag?.dataModel === businessObject
      );
    } catch (e) {
      logWarn(new Error(`Couldn't get node in graphBuilder.`));
    }
    return node;
  }

  clear() {
    this.graph.clear();

    this._groupNodeMap.clear();
    this._nodeMap.clear();
    this._edgeMap.clear();
  }

  clearEdges() {
    Array.from(this._edgeMap.values()).forEach(this.removeEdge);
  }

  buildGraph() {
    this.clear();

    this._groupNodeMap = new Map(
      this.groupsSource.map(graphNode => [
        graphNode.id,
        this.createGroupNode(graphNode),
      ])
    );
    this.hasGroups = this.groupsSource.length > 0;

    this._setGroupNodeParents();

    this._nodeMap = new Map(
      this.nodesSource.map(graphNode => [
        graphNode.id,
        this.createNode(graphNode),
      ])
    );

    this._edgeMap = new Map(
      this.edgesSource.map(graphEdge => [
        graphEdge.id,
        this.createEdge(graphEdge),
      ])
    );
  }

  updateGraph() {
    if (
      this._nodeMap.size + this._edgeMap.size + this._groupNodeMap.size ===
      0
    ) {
      this.buildGraph();
      return;
    }

    this._groupNodeMap = this._diffUpdateGraph({
      oldMap: this._groupNodeMap,
      businessObjects: this.groupsSource,
      createFunc: this.createGroupNode,
      updateFunc: this.updateGroupNode,
    });

    this.hasGroups = this.groupsSource.length > 0;

    this._setGroupNodeParents();

    this._nodeMap = this._diffUpdateGraph({
      oldMap: this._nodeMap,
      businessObjects: this.nodesSource,
      createFunc: this.createNode,
      updateFunc: this.updateNode,
    });

    this._edgeMap = this._diffUpdateGraph({
      oldMap: this._edgeMap,
      businessObjects: this.edgesSource,
      createFunc: this.createEdge,
      updateFunc: this.updateEdge,
    });
  }
  hasLayoutUpdate({ nodes, groups, edges }: GraphBuilderHasLayoutArgs) {
    const nodesUnchanged =
      !nodes || isEqualWith(nodes, this.nodesSource, graphItemsEqual);
    const groupsUnchanged =
      !groups || isEqualWith(groups, this.groupsSource, graphItemsEqual);
    const edgesUnchanged =
      !edges || isEqualWith(edges, this.edgesSource, graphItemsEqual);

    return !(nodesUnchanged && groupsUnchanged && edgesUnchanged);
  }

  // Private methods

  _setGroupNodeParents() {
    // Set parents of group nodes
    Array.from(this._groupNodeMap.values()).forEach(groupNode => {
      const graphNode = groupNode.tag as Node | GraphGroup;
      const parent =
        graphNode.parent && this._groupNodeMap.get(graphNode.parent.id);
      if (parent) {
        if (parent === groupNode) {
          // this shouldn't happen (but it does)

          const parentGraphNode = parent.tag as Node | GraphGroup;
          logError(Error('Self-parented node in graph builder.'), null, {
            groupNodeId: graphNode.id,
            parentId: parentGraphNode.id,
            groupNodeMapKeys: Array.from(this._groupNodeMap.keys()),
            groupNodeMapNodeIds: Array.from(this._groupNodeMap.values())
              .map<Node | GraphGroup>(node => node.tag)
              .map(node => node.id),
          });
        } else {
          this.graph.setParent(groupNode, parent);
        }
      }
    });
  }

  _diffUpdateGraph<TItem extends IModelItem, TObj extends { id: string }>({
    oldMap,
    businessObjects,
    createFunc,
    updateFunc,
    removeFunc = this.removeItem,
  }: DiffUpdateGraphArgs<TItem, TObj>) {
    const newMap = new Map(
      businessObjects.map(businessObject => [
        businessObject.id,
        // Add new items, update existing items
        (oldMap.has(businessObject.id) ? updateFunc : createFunc)(
          businessObject
        ),
      ])
    );
    businessObjects.forEach(businessObject => oldMap.delete(businessObject.id));

    // Delete old items
    Array.from(oldMap.values()).forEach(graphItem =>
      removeFunc(graphItem, oldMap)
    );
    return newMap;
  }

  // We also need this as prototype method because a sub class
  // overwrites it, but still also needs the method of its base class.
  _updateNode(graphNode: Node | GraphNode) {
    const node = this._nodeMap.get(graphNode.id)!;
    const style = getStyle(graphNode);

    if (style !== node.style) {
      this.graph.setStyle(node, style);
    }
    this.updateNodeLayout(node, style);
    if (
      graphNode.hasURLFields() &&
      !node.labels.find(label => isURLLabel(label))
    ) {
      this.addURLNodeLabel(node, graphNode);
    }

    if (
      graphNode instanceof GraphItem &&
      !(
        isInScopeDiffMode() &&
        graphNode.getVisualDiffType() === DiffType.PLACEHOLDER
      ) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }

    const labelsToRemove: ILabel[] = [];
    node.labels.forEach(label => {
      if (label.tag === null) {
        this.graph.setLabelText(
          label,
          encodingUtils.unescapeHTML(graphNode.getLabel())
        );
      } else if (isURLLabel(label) && !label.tag.hasURLFields()) {
        labelsToRemove.push(label);
      }
    });
    labelsToRemove.forEach(label => this.graph.remove(label));

    return node;
  }
}
