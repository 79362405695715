import {
  currentDate,
  formatDateTime,
  formatRelative,
  parseDate,
} from '@ardoq/date-time';
import { getCurrentLocale, Locale } from '@ardoq/locale';
import { logError } from '@ardoq/logging';

type FormattedDateProps = {
  date: string | Date;
  isLocalDateFormat?: boolean;
};

const getFormattedLocalDate = (date: string | Date, locale: Locale) => {
  try {
    const targetDate = typeof date === 'string' ? parseDate(date) : date;

    return formatRelative(targetDate, currentDate(), { locale });
  } catch (error) {
    logError(error as Error, 'Failed to format relative date', { date });

    return 'Invalid date';
  }
};

const FormattedDate = ({ date, isLocalDateFormat }: FormattedDateProps) => {
  const locale = getCurrentLocale();
  const formattedDateTime = formatDateTime(date, locale);

  return (
    <span data-tooltip-text={formattedDateTime}>
      {isLocalDateFormat
        ? formattedDateTime
        : getFormattedLocalDate(date, locale)}
    </span>
  );
};

export default FormattedDate;
