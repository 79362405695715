import { TrackingLocation } from 'tracking/types';
import { ArdoqId } from '@ardoq/api-types';
import { dateRangeOperations } from '@ardoq/date-range';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { fieldOperations } from '@ardoq/core';

import { contextInterface } from 'modelInterface/contextInterface';

export const setContextToWorkspace = (
  workspaceId: string,
  trackingLocation?: TrackingLocation
) => {
  contextInterface.loadWorkspaces({
    workspaceIds: [],
    contextWorkspaceId: workspaceId,
    trackingLocation,
  });
};

export const getComponentFieldsWithDateRanges = (componentId: ArdoqId) =>
  dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
    componentInterface.getFields(componentId)
  ).fields;

export const getReferenceFieldsWithDateRanges = (componentId: ArdoqId) =>
  dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
    referenceInterface.getFields(componentId)
  ).fields;

export const getComponentFieldsOfWorkspace = (workspaceId: ArdoqId) =>
  dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
    fieldInterface
      .getAllFieldsOfWorkspace(workspaceId)
      .filter(fieldOperations.appliesToComponents)
  ).fields;
