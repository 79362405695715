import { Metamodel } from '@ardoq/api-types';
import {
  MetamodelContext,
  MetamodelEntityContext,
  MetamodelFieldContext,
  SelectedMetamodelItem,
} from 'metamodel/types';
import { InfoContainerWidth } from './types';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';

export const getContextFromMetamodelItem = (
  metamodel: Metamodel,
  { elementType, elementId, field }: SelectedMetamodelItem
): MetamodelEntityContext | undefined => {
  if (!elementType || !elementId) return;
  if (elementType === SimpleBlockDiagramContentType.COMPONENT) {
    const component = metamodel.metamodel.componentTypes[elementId];
    if (!component) return;
    return {
      ...component,
      type: SimpleBlockDiagramContentType.COMPONENT,
      field,
    };
  } else if (elementType === SimpleBlockDiagramContentType.REFERENCE) {
    const reference = metamodel.metamodel.referenceTypes[elementId];
    return {
      ...reference,
      type: SimpleBlockDiagramContentType.REFERENCE,
      field,
    };
  } else if (elementType === SimpleBlockDiagramContentType.WORKSPACE) {
    const workspace = metamodel.metamodel.workspaces[elementId];
    if (!workspace) return;
    return { ...workspace, type: SimpleBlockDiagramContentType.WORKSPACE };
  } else if (elementType === SimpleBlockDiagramContentType.WORKSPACE_FOLDER) {
    const assetFolder = metamodel.metamodel.workspaceFolders[elementId];
    if (!assetFolder) return;
    return {
      ...assetFolder,
      type: SimpleBlockDiagramContentType.WORKSPACE_FOLDER,
    };
  }
};

export const getContainerWidthFromMetamodelContext = (
  context?: MetamodelContext
) =>
  context &&
  ((context as MetamodelFieldContext).field ||
    context.type === SimpleBlockDiagramContentType.REFERENCE_COMPLETENESS)
    ? InfoContainerWidth.WIDE
    : InfoContainerWidth.NARROW;
