import { useState } from 'react';
import {
  MetamodelContextSettings,
  MetamodelReferenceCompletenessContext,
} from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import BreadCrumbNavigation from 'metamodel/metamodelStats/breadCrumbNavigation/BreadCrumbNavigation';
import GremlinSearchTableWithLoader from '../GremlinSearchTableWithLoader';
import { DisplayPreference } from './types';
import {
  getDefaultDisplayPreference,
  getReferenceCompletionQuery,
} from './utils';
import {
  ReferenceCompletionExplanation,
  ReferenceCompletionTitle,
} from './atoms';
import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import { Box } from '@ardoq/layout';

interface ReferenceCompletionStatsProps {
  context: MetamodelReferenceCompletenessContext & MetamodelContextSettings;
  metamodel: Metamodel;
}
const ReferenceCompletionStats = ({
  context,
  metamodel,
}: ReferenceCompletionStatsProps) => {
  const [displayPreference, setDisplayPreference] = useState<DisplayPreference>(
    getDefaultDisplayPreference(context)
  );

  const displayPreferenceOptions = [
    { value: DisplayPreference.WITH_REFERENCE, label: 'With reference' },
    { value: DisplayPreference.WITHOUT_REFERENCE, label: 'Without reference' },
  ];

  const query = getReferenceCompletionQuery(
    metamodel,
    context,
    displayPreference
  );
  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <ReferenceCompletionTitle metamodel={metamodel} context={context} />
      </Box>

      <Box display="inline-block">
        <RadioGroup
          value={displayPreference}
          options={displayPreferenceOptions}
          onValueChange={value =>
            setDisplayPreference(value as DisplayPreference)
          }
          groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        />
      </Box>
      <ReferenceCompletionExplanation
        metamodel={metamodel}
        context={context}
        displayPreference={displayPreference}
      />
      <GremlinSearchTableWithLoader query={query} />
    </Box>
  );
};

export default ReferenceCompletionStats;
