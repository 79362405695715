import type { DashboardApiError, LoadedDashboard } from '@ardoq/dashboard';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  persistLoadedDashboardData,
  setDashboardApiError,
  SetDashboardApiErrorPayload,
  setDashboardLoadingState,
} from './actions';
import type { ArdoqId } from '@ardoq/api-types';
import { LoadingState } from './types';
import { currentDate } from '@ardoq/date-time';

const defaultState: LoadedDashboardsState = {
  loadedDashboards: {},
};

export type LoadedDashboardsState = {
  loadedDashboards: Record<ArdoqId, LoadedDashboard>;
  apiError?: DashboardApiError;
  apiLoadingState?: LoadingState | null;
};

const setLoadedDashboard = (
  state: LoadedDashboardsState,
  loadedDashboard: LoadedDashboard
): LoadedDashboardsState => ({
  ...state,
  apiError: undefined,
  apiLoadingState: null,
  loadedDashboards: {
    ...state.loadedDashboards,
    [loadedDashboard._id]: { ...loadedDashboard, _time: currentDate() },
  },
});

const setApiErrorAndLoadingState = (
  state: LoadedDashboardsState,
  {
    apiError,
    apiLoadingState = LoadingState.LOADING_ERROR,
  }: SetDashboardApiErrorPayload
): LoadedDashboardsState => ({
  ...state,
  apiError,
  apiLoadingState,
});

const setApiLoadingState = (
  state: LoadedDashboardsState,
  apiLoadingState: LoadingState | null
): LoadedDashboardsState => ({
  ...state,
  apiLoadingState,
  apiError: undefined,
});

const loadedDashboards$ = persistentReducedStream<LoadedDashboardsState>(
  'loadedDashboards$',
  defaultState,
  [
    reducer(persistLoadedDashboardData, setLoadedDashboard),
    reducer(setDashboardApiError, setApiErrorAndLoadingState),
    reducer(setDashboardLoadingState, setApiLoadingState),
  ]
);

export default loadedDashboards$;
