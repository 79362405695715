import {
  BlocksViewGraph,
  BlocksViewLink,
  BlocksViewNode,
  BlocksViewVisualState,
} from '../types';

import { isGroup } from '../viewModel$/util';
import { getColPositions, getRowPositions } from '../layout/util';
import type { Rectangle, Vector } from '@ardoq/graph';
import {
  rectEnclose,
  rectInterp,
  rectIntersects,
  vectorsInterp,
} from '../misc/geometry';
import { drawNode, DrawNodeOpacity } from './node';
import { hitTestNode } from './node';
import { hitTestLink } from './link';
import { drawLink } from './link';
import { drawLinkLabel } from './link';
import { clamp } from 'lodash';
import { easeOutCubic } from '../misc/math';
import { currentTimestamp } from '@ardoq/date-time';

import {
  BLOCKS_VIEW_GROUP_GRID_COLOR,
  BLOCKS_VIEW_LINK_LINEMINWIDTH,
  BLOCKS_VIEW_LINK_LINEWIDTH,
} from '../consts';

export type CanvasRenderingContext =
  | CanvasRenderingContext2D
  | OffscreenCanvasRenderingContext2D;

export const hitTestGraphLinks = (
  graph: BlocksViewGraph,
  pos: Vector,
  ctx: CanvasRenderingContext
): BlocksViewLink | null => {
  const slop = 5 / ctx.getTransform().a;

  let ret = null;

  for (let i = 0; i < graph.edges.length && !ret; ++i) {
    ret = hitTestLink(graph.edges[i], pos, slop) ? graph.edges[i] : null;
  }

  return ret;
};

export const hitTestGraphNodes = (
  node: BlocksViewNode,
  pos: Vector
): BlocksViewNode | null => {
  const doit = (node: BlocksViewNode) => {
    let ret = hitTestNode(node, pos) ? node : null;

    if (isGroup(node) && node.open) {
      for (let i = 0; i < node.children!.length && ret === node; ++i) {
        ret = doit(node.children![i]) ?? ret;
      }
    }

    return ret;
  };

  return doit(node);
};

/* this is styled */
export const drawGridLines = (
  node: BlocksViewNode,
  _window: Rectangle,
  ctx: CanvasRenderingContext
) => {
  const lineWidth = ctx.lineWidth;

  if (!isGroup(node)) {
    return;
  }

  {
    const colPosns = getColPositions(node);
    const rowPosns = getRowPositions(node);
    const rc = [
      colPosns[0],
      rowPosns[0],
      colPosns[colPosns.length - 1],
      rowPosns[rowPosns.length - 1],
    ];

    ctx.beginPath();

    for (let i = 1; i < colPosns.length - 1; ++i) {
      ctx.moveTo(colPosns[i], rc[1]);
      ctx.lineTo(colPosns[i], rc[3]);
    }

    for (let i = 1; i < rowPosns.length - 1; ++i) {
      ctx.moveTo(rc[0], rowPosns[i]);
      ctx.lineTo(rc[2], rowPosns[i]);
    }

    ctx.lineWidth = 1.0 / ctx.getTransform().a;
    ctx.strokeStyle = BLOCKS_VIEW_GROUP_GRID_COLOR;
    ctx.stroke();
  }

  ctx.lineWidth = lineWidth;
};

/* this is for debugging
const drawGridLines = (
  node: BlocksViewNode,
  window: Rectangle,
  ctx: CanvasRenderingContext
) => {
  if (!isGroup(node)) {
    return;
  }

  ctx.save();

  ctx.globalAlpha = 0.1;
  ctx.fillStyle = 'red';
  ctx.strokeStyle = 'red';
  const pixel = 1 / ctx.getTransform().a;
  ctx.lineWidth = 3 * pixel;

  const colPosns = getColPositions(node);
  const rowPosns = getRowPositions(node);
  const rc: Rectangle = [
    Math.max(window[0], node.content![0]),
    Math.max(window[1], node.content![1]),
    Math.min(window[2], node.content![2]),
    Math.min(window[3], node.content![3]),
  ];

  for (let c = 0; c < colPosns.length; c += 2) {
    for (let r = 1; r < rowPosns.length; r += 2) {
      ctx.fillRect(
        colPosns[c],
        rowPosns[r],
        colPosns[c + 1] - colPosns[c],
        rowPosns[r + 1] - rowPosns[r]
      );
    }
  }

  for (let i = 0; i < rowPosns.length; i += 2) {
    ctx.fillRect(
      rc[0],
      rowPosns[i],
      rectWidth(rc),
      rowPosns[i + 1] - rowPosns[i]
    );
  }

  ctx.strokeRect(
    node.content![0],
    node.content![1],
    rectWidth(node.content!),
    rectHeight(node.content!)
  );

  ctx.restore();
};
*/
const tween = easeOutCubic;

type GraphRenderMode = 'normal' | 'lowlight' | 'export';
export const renderGraph = (
  graph: BlocksViewGraph,
  window: Rectangle,
  renderMode: GraphRenderMode,
  group: BlocksViewNode | null,
  ctx: CanvasRenderingContext
) => {
  let done = true;

  const pixel = 1.0 / ctx.getTransform().a;
  ctx.globalAlpha = 1.0;

  const lowlight = renderMode === 'lowlight';
  const isExport = renderMode === 'export';

  const renderNodes = (node: BlocksViewNode) => {
    const p = node.animation
      ? clamp(
          (currentTimestamp() - node.animation.begin) / node.animation.duration,
          0,
          1
        )
      : 1;

    if (node.animation && p === 1) {
      node.animation = null;
    }

    let cell = node.cell;

    if (node.cell && node.animation) {
      done = false;

      cell = rectInterp(
        tween(p),
        node.animation!.from as Rectangle,
        node.cell,
        null
      );
    }

    if (cell && rectIntersects(cell, window)) {
      const isDragging =
        (node.visualState & BlocksViewVisualState.Dragging) > 0;
      const opacity = lowlight
        ? DrawNodeOpacity.LOW_LIGHT
        : isDragging
          ? DrawNodeOpacity.DRAG_SOURCE
          : DrawNodeOpacity.NORMAL;
      drawNode(node, cell, opacity, ctx);

      if (node === group && group.open && !isExport) {
        drawGridLines(node, window, ctx);
      }
    }

    if (isGroup(node) && node.open) {
      node.children?.forEach(child => renderNodes(child));
    }
  };

  const renderLink = (link: BlocksViewLink) => {
    const p = link.animation
      ? clamp(
          (currentTimestamp() - link.animation.begin) / link.animation.duration,
          0,
          1
        )
      : 1;

    if (link.animation && p === 1) {
      link.animation = null;
    }

    let route = link.route!;
    let bounds = link.bounds;

    if (link.route && link.animation) {
      done = false;

      route = vectorsInterp(
        tween(p),
        link.animation.from as Vector[],
        link.route,
        null
      );

      bounds = rectEnclose(...route);
    }

    if (bounds && rectIntersects(bounds, window)) {
      drawLink(link, route, lowlight, ctx);
    }
  };

  const renderLinks = () => {
    ctx.globalAlpha = 1.0;
    ctx.lineWidth = Math.max(
      BLOCKS_VIEW_LINK_LINEWIDTH,
      pixel * BLOCKS_VIEW_LINK_LINEMINWIDTH
    );

    for (const link of graph.edges) {
      renderLink(link);
    }

    for (const link of graph.edges) {
      if (!link.animation) {
        drawLinkLabel(link, link.route!, window, lowlight, ctx);
      }
    }

    ctx.setLineDash([]);
  };

  renderNodes(graph.root);
  renderLinks();

  return done;
};
