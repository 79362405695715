import { type PayloadLoadSlide, loadSlide, loadSlideError } from './actions';
import {
  type ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import slides$ from 'streams/slides/slides$';
import { APISlideAttributes } from '@ardoq/api-types';

export type ViewPaneState = {
  slideError: any;
  loadedSlide: APISlideAttributes | null;
};

const defaultState: ViewPaneState = {
  slideError: undefined,
  loadedSlide: null,
};

const handleLoadSlide = (
  currentState: ViewPaneState,
  { id }: PayloadLoadSlide
): ViewPaneState => ({
  ...currentState,
  slideError: undefined,
  loadedSlide: slides$.state.byId[id],
});

const handleLoadSlideError = (
  currentState: ViewPaneState,
  { error }: ExtractPayload<typeof loadSlideError>
): ViewPaneState => ({
  ...currentState,
  slideError: error,
  loadedSlide: null,
});

const reducers = [
  reducer(loadSlide, handleLoadSlide),
  reducer(loadSlideError, handleLoadSlideError),
];

const viewPane$ = persistentReducedStream('viewPane$', defaultState, reducers);

export default viewPane$;
