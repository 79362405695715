import { action$, reducer, reduceState } from '@ardoq/rxbeach';
import { resultMapper } from 'search/resultUtils';
import { findSortOrder } from './utils';
import {
  fieldSelect,
  loadQuery,
  loadQueryError,
  loadQuerySuccess,
  pageSelect,
  PayloadFieldSelect,
  PayloadLoadQueryError,
  PayloadLoadQuerySuccess,
  PayloadPageSelect,
  PayloadSortChange,
  sortChange,
} from './actions';
import { SortOrder, UnknownDocumentType } from '@ardoq/api-types';
import { SearchResultApiConnectorState } from './types';

const defaultState: SearchResultApiConnectorState = {
  page: 1,
  perPage: 100,
  total: 100,
  results: [],
  sortBy: undefined,
  sortById: null,
  errorMsg: undefined,
  query: {},
  sortOrder: SortOrder.DEFAULT,
  selectedFieldNames: [],
  fields: [],
  initialLoadCompleted: false,
  isLoading: false,
};

const loadQueryHandler = (currentState: SearchResultApiConnectorState) => ({
  ...currentState,
  isLoading: true,
});

const handleLoadQuerySuccess = (
  currentState: SearchResultApiConnectorState,
  { total, results }: PayloadLoadQuerySuccess
): SearchResultApiConnectorState => ({
  ...currentState,
  errorMsg: undefined,
  isLoading: false,
  initialLoadCompleted: true,
  total,
  results: results.map(resultMapper<UnknownDocumentType>),
});

const handlePageSelect = (
  currentState: SearchResultApiConnectorState,
  { page }: PayloadPageSelect
) => ({
  ...currentState,
  page,
  isLoading: true,
});

const handleFieldSelect = (
  currentState: SearchResultApiConnectorState,
  { fieldNames }: PayloadFieldSelect
) => ({
  ...currentState,
  selectedFieldNames: fieldNames,
});

const handleSortChange = (
  currentState: SearchResultApiConnectorState,
  { fieldName }: PayloadSortChange
): SearchResultApiConnectorState => {
  const [sortBy, sortOrder] = findSortOrder(currentState, fieldName);
  return { ...currentState, sortBy, sortOrder, page: 1, isLoading: true };
};

const setErrorMessage = (
  currentState: SearchResultApiConnectorState,
  { errorMsg }: PayloadLoadQueryError
): SearchResultApiConnectorState => ({
  ...currentState,
  isLoading: false,
  errorMsg,
});

const reducers = [
  reducer(loadQuery, loadQueryHandler),
  reducer(loadQuerySuccess, handleLoadQuerySuccess),
  reducer(pageSelect, handlePageSelect),
  reducer(sortChange, handleSortChange),
  reducer(fieldSelect, handleFieldSelect),
  reducer(loadQueryError, setErrorMessage),
];

const searchResultApi$ = action$.pipe(
  reduceState('searchResultApi$', defaultState, reducers)
);

export default searchResultApi$;
