import type { APIReferenceType, ArdoqId } from '@ardoq/api-types';
import type { RepresentationData } from '@ardoq/data-model';
import {
  DefaultLabelStyle,
  EdgeDefaults,
  ExteriorLabelModel,
  NodeDefaults,
  VoidLabelStyle,
  VoidNodeStyle,
} from '@ardoq/yfiles';
import { ProteanLayoutType, ProteanRenderingMode } from '../types';
import CanvasEdgeStyle from './styles/canvas/edge/CanvasEdgeStyle';
import CanvasConvexHullGroupStyle from './styles/groupStyles/canvas/CanvasConvexHullGroupStyle';
import CanvasRectangularGroupStyle from './styles/groupStyles/canvas/CanvasRectangularGroupStyle';
import CanvasLabelStyle from './styles/canvas/label/CanvasLabelStyle';
import CanvasNodeStyle from './styles/canvas/node/CanvasNodeStyle';
import ConvexHullGroupStyle from './styles/groupStyles/svg/ConvexHullGroupStyle';
import ProteanBottomNodeLabelStyle from './styles/svg/ProteanBottomNodeLabelStyle';
import ProteanEdgeLabelStyle from './styles/svg/ProteanEdgeLabelStyle';
import ProteanEdgeStyle from './styles/svg/ProteanEdgeStyle';
import ProteanLabelStyle from './styles/svg/ProteanLabelStyle';
import ProteanNodeStyle from './styles/svg/ProteanNodeStyle';
import RectangularGroupStyle from './styles/groupStyles/svg/RectangularGroupStyle';
import { ProteanGraphState } from './types';

const selectGroupStyle = ({ state }: GetStylesArgs) => {
  const {
    viewId,
    viewSettings: { renderingMode, layoutType },
  } = state;
  switch (renderingMode) {
    case ProteanRenderingMode.WebGL2:
      return VoidNodeStyle.INSTANCE;
    case ProteanRenderingMode.Canvas:
      switch (layoutType) {
        case ProteanLayoutType.Swimlanes:
          return VoidNodeStyle.INSTANCE;
        case ProteanLayoutType.Hierarchic:
        case ProteanLayoutType.Orthogonal:
          return new CanvasRectangularGroupStyle();
        default:
          return new CanvasConvexHullGroupStyle();
      }
    default: // svg
      switch (layoutType) {
        case ProteanLayoutType.Swimlanes:
          return VoidNodeStyle.INSTANCE;
        case ProteanLayoutType.Tabular:
        case ProteanLayoutType.HierarchicInGrid:
        case ProteanLayoutType.Hierarchic:
        case ProteanLayoutType.Orthogonal:
          return RectangularGroupStyle.get(viewId);
        default:
          return new ConvexHullGroupStyle();
      }
  }
};

const selectLabelStyle = ({
  state: {
    viewSettings: { layoutType, renderingMode },
  },
}: GetStylesArgs) => {
  switch (renderingMode) {
    case ProteanRenderingMode.WebGL2:
      return {
        labelStyle: new DefaultLabelStyle(),
        labelLayoutParameter: new NodeDefaults().labels.layoutParameter,
      };
    case ProteanRenderingMode.Canvas:
      return {
        labelStyle: new CanvasLabelStyle(),
        labelLayoutParameter: new NodeDefaults().labels.layoutParameter,
      };
    default:
    case ProteanRenderingMode.SVG: {
      switch (layoutType) {
        case ProteanLayoutType.Tabular:
        case ProteanLayoutType.HierarchicInGrid:
        case ProteanLayoutType.Hierarchic:
          return {
            labelStyle: new ProteanBottomNodeLabelStyle(),
            labelLayoutParameter: ExteriorLabelModel.SOUTH,
          };
        default:
          return {
            labelStyle: new ProteanLabelStyle(),
            labelLayoutParameter: new NodeDefaults().labels.layoutParameter,
          };
      }
    }
  }
};

export type GetStylesArgs = {
  nodeRepresentationData: Map<string, RepresentationData>;
  referenceTypes: Map<ArdoqId, APIReferenceType>;
  state: ProteanGraphState;
};
const getStyles = (args: GetStylesArgs) => {
  const {
    state: {
      viewSettings: { renderingMode },
    },
    nodeRepresentationData,
    referenceTypes,
  } = args;
  const groupStyle = selectGroupStyle(args);
  const { labelStyle, labelLayoutParameter } = selectLabelStyle(args);
  switch (renderingMode) {
    case ProteanRenderingMode.WebGL2:
      return {
        labelStyle,
        labelLayoutParameter,
        nodeStyle: new NodeDefaults().style,
        edgeStyle: new EdgeDefaults().style,
        edgeLabelStyle: VoidLabelStyle.INSTANCE,
        groupStyle,
      };
    case ProteanRenderingMode.Canvas:
      return {
        labelStyle,
        labelLayoutParameter,
        nodeStyle: new CanvasNodeStyle(nodeRepresentationData),
        edgeStyle: new CanvasEdgeStyle(referenceTypes),
        edgeLabelStyle: VoidLabelStyle.INSTANCE,
        groupStyle,
      };
    default:
    case ProteanRenderingMode.SVG:
      return {
        labelStyle,
        labelLayoutParameter,
        nodeStyle: new ProteanNodeStyle(),
        edgeStyle: new ProteanEdgeStyle(referenceTypes),
        edgeLabelStyle: new ProteanEdgeLabelStyle(),
        groupStyle,
      };
  }
};

export default getStyles;
