import { useEffect, useRef, useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { getViewModel$ } from './viewModel$';
import { ViewIds } from '@ardoq/api-types';
import { onViewSettingsUpdate } from '../onViewSettingsUpdate';
import { SettingsBar, settingsBarConsts } from '@ardoq/settings-bar';
import { getRightMenuConfig } from './getRightMenuConfig';
import { getLeftMenuConfig } from './getLeftMenuConfig';
import { ChartWrapper, ViewContainer } from './atoms';
import { useResizeObserver } from '@ardoq/hooks';
import { SpiderChart } from './view/SpiderChart';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import { ViewLegend } from '@ardoq/view-legend';
import { ViewLegendContainer } from '@ardoq/graph';
import { trackNodeCountWithPresentationMeta } from 'tracking/events/visualizations';
import { isPresentationMode } from 'appConfig';
import EmptyState from './EmptyState';
import { noop } from 'lodash';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from '../consts';
import { SpiderChartStreamedProps } from './types';

const VIEW_ID = ViewIds.SPIDER;
const viewState$ = getViewSettingsStream(VIEW_ID);

const SpiderChartView = ({
  viewSettings,
  viewModel,
}: SpiderChartStreamedProps) => {
  const {
    chartPoints,
    legendRows,
    selectedFields,
    componentsCount,
    errors,
    numericFields,
    workspaceId,
    sideways,
    focusedComponentId,
  } = viewModel;
  const containerElement = useRef<HTMLDivElement>(null);
  const chartWrapperElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof componentsCount === 'number') {
      trackNodeCountWithPresentationMeta(VIEW_ID, componentsCount);
    }
  }, [componentsCount]);

  const { width: containerWidth = 0, height: containerHeight = 0 } =
    useResizeObserver(containerElement);

  useEffect(() => {
    const scrollWidth = chartWrapperElement.current?.scrollWidth ?? 0;
    const clientWidth = chartWrapperElement.current?.clientWidth ?? 0;
    const scrollCenterPosition = (scrollWidth - clientWidth) / 2;

    chartWrapperElement.current?.scrollTo(scrollCenterPosition, 0);
  }, [containerWidth]);

  const [clearedErrors, setClearedErrors] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    VIEW_ID,
    NEVER_SHOW_AGAIN
  );

  const noNumericFields = !numericFields.length;
  const noSelectedFields = !selectedFields.length;
  const notEnoughComponents =
    typeof componentsCount === 'number' && componentsCount < 1;

  const isEmptyView =
    notEnoughComponents || noNumericFields || noSelectedFields;

  return (
    <>
      {!isPresentationMode() && (
        <SettingsBar
          viewId={VIEW_ID}
          leftMenu={getLeftMenuConfig(
            VIEW_ID,
            viewSettings,
            sideways,
            workspaceId,
            numericFields,
            selectedFields
          )}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            getContainer: () => containerElement?.current,
            viewSettings,
            isEmptyView,
            onViewSettingsUpdate,
          })}
        />
      )}

      <ViewContainer ref={containerElement}>
        {isEmptyView ? (
          <EmptyState
            noNumberFields={noNumericFields}
            noSelectedFields={noSelectedFields}
            notEnoughComponents={notEnoughComponents}
            allDescendantsIncluded={viewSettings.includeAllDescendants}
          />
        ) : (
          <ChartWrapper ref={chartWrapperElement}>
            <SpiderChart
              chartPoints={chartPoints}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              focusedComponentId={focusedComponentId}
            />
          </ChartWrapper>
        )}

        <ErrorInfoBox
          errors={clearedErrors ? [] : errors}
          hasClones={false}
          clearHasClones={noop}
          isShowNeverAgainSet={neverShowAgain}
          isPresentationMode={isPresentationMode()}
          clearErrors={() => setClearedErrors(true)}
          toggleNeverShowAgain={toggleNeverShowAgain}
        />

        <ViewLegendContainer
          heightOffset={useViewLegendSubscription()}
          visible={
            !isEmptyView && viewSettings[settingsBarConsts.IS_LEGEND_ACTIVE]
          }
        >
          <ViewLegend
            activeDiffMode={null}
            componentTypes={[]}
            displayShapesInsteadOfIcons={true}
            additionalRows={legendRows}
          />
        </ViewLegendContainer>
      </ViewContainer>
    </>
  );
};

export default connect(SpiderChartView, getViewModel$(viewState$));
