import { ArdoqId, ReportFilterQuery } from '@ardoq/api-types';
import {
  EnhancedSearchResponse,
  ExportButtonCommands,
  searchResultsOperations,
} from '@ardoq/report-reader';
import { exportToJSON } from '../utils';
import { Maybe } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  exportToExcelButtonWasClicked,
  exportToReadableExcelButtonWasClicked,
} from 'report/actions';

const getReportName = (searchResults: Maybe<EnhancedSearchResponse>) =>
  searchResults ? searchResultsOperations.getName(searchResults) : 'Report';

const getExportToReadableExcel =
  (
    id: ArdoqId,
    filterQuery: ReportFilterQuery,
    reportName: string
  ): VoidFunction =>
  () => {
    dispatchAction(
      exportToReadableExcelButtonWasClicked({
        id,
        filterQuery,
        reportName,
      })
    );
  };

const getExportToExcel =
  (
    id: ArdoqId,
    filterQuery: ReportFilterQuery,
    reportName: string
  ): VoidFunction =>
  () => {
    dispatchAction(
      exportToExcelButtonWasClicked({
        id,
        filterQuery,
        reportName,
      })
    );
  };

export const getExportButtonCommands = (
  id: ArdoqId,
  searchResults: Maybe<EnhancedSearchResponse>,
  filterQuery: ReportFilterQuery
): ExportButtonCommands => {
  const reportName = getReportName(searchResults);
  return {
    exportToReadableExcel: getExportToReadableExcel(
      id,
      filterQuery,
      reportName
    ),
    exportToExcel: getExportToExcel(id, filterQuery, reportName),
    exportToJSON: () => exportToJSON(id),
  };
};
