import { EdgeStyleBase, IEdge, IRenderContext, Visual } from '@ardoq/yfiles';
import { GraphEdge, PARENT_CHILD_LINE_TYPE } from '@ardoq/graph';
import { referenceInterface } from '@ardoq/reference-interface';
import CanvasEdgeVisual from './CanvasEdgeVisual';
import { APIReferenceType, ArdoqId, ArrowType } from '@ardoq/api-types';
import { lineTypeDashArrays } from 'tabview/canvasRendering/consts';
import { colors } from '@ardoq/design-tokens';

class CanvasEdgeStyle extends EdgeStyleBase {
  constructor(private referenceTypes: Map<ArdoqId, APIReferenceType>) {
    super();
  }

  protected override createVisual(
    context: IRenderContext,
    edge: IEdge
  ): Visual | null {
    const graphEdge = edge.tag as GraphEdge;
    const { stroke = colors.black } = referenceInterface.getCssColors(
      graphEdge.modelId,
      {
        useAsBackgroundStyle: false,
      }
    ) ?? { stroke: colors.black };

    const referenceType = this.referenceTypes.get(graphEdge.modelId);
    const lineType = referenceType?.line ?? PARENT_CHILD_LINE_TYPE; // infer if there's no modelType, then this is a parent-child reference
    const dashArray = lineTypeDashArrays[lineType];
    const lineBeginning = referenceType?.lineBeginning ?? ArrowType.NONE;
    const lineEnding = referenceType?.lineEnding ?? ArrowType.NONE;

    return new CanvasEdgeVisual(
      edge,
      stroke,
      dashArray,
      lineBeginning,
      lineEnding
    );
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    _edge: IEdge
  ): Visual | null {
    return oldVisual;
  }
}
export default CanvasEdgeStyle;
