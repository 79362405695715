import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoConnectedComponentsContextDoqProps = {
  doqType: DoqType;
};

const NoConnectedComponentsContextDoq = ({
  doqType,
}: NoConnectedComponentsContextDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No connected components"
      message="Select a component that is connected to other components or select a workspace containing at least one component which is connected to another component."
    />
  </EmptyStatesDoqContainer>
);
export default NoConnectedComponentsContextDoq;
