import { INode, IRenderContext, NodeStyleBase, Visual } from '@ardoq/yfiles';
import { GraphGroup } from '@ardoq/graph';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { colors } from '@ardoq/design-tokens';

abstract class CanvasGroupStyle extends NodeStyleBase {
  protected abstract createCanvasVisual(
    group: INode,
    fill: string,
    stroke: string
  ): Visual | null;
  protected override createVisual(
    context: IRenderContext,
    group: INode
  ): Visual | null {
    const graphGroup = group.tag as GraphGroup;
    const { fill = colors.grey90, stroke = colors.black } =
      getComponentCssColors(graphGroup.modelId, {
        useAsBackgroundStyle: true,
      }) ?? { fill: colors.grey90, stroke: colors.black };

    return this.createCanvasVisual(group, fill, stroke);
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    _group: INode
  ): Visual | null {
    return oldVisual;
  }
}
export default CanvasGroupStyle;
