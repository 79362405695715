import { DatasourceTable } from '@ardoq/table';
import { GremlinResult } from '../types';
import styled from 'styled-components';
import { formatValue } from '../utils';

interface GremlinResultsTableDirectProps {
  results: GremlinResult[];
}

const PrettyStyleBase = styled.div`
  display: block;
  font-size: 13px;
  word-break: break-all;
  word-wrap: break-word;
  font-family: monospace;
  padding: 0;
  margin: 0;
  color: #333333;
  background-color: transparent;
  border: none;
`;

const PrettyRender = ({ value }: { value: any }) => {
  const prettyString = JSON.stringify(value, null, 2);
  return (
    <PrettyStyleBase as={prettyString.length > 3000 ? 'span' : 'pre'}>
      {prettyString}
    </PrettyStyleBase>
  );
};

const GremlinResultsTableDirect = (props: GremlinResultsTableDirectProps) => (
  <DatasourceTable
    dataSource={props.results}
    data-intercom-target="gremlin results table"
    columns={[
      {
        title: 'Results',
        valueRender: (_: any, result) =>
          typeof result === 'object' ? (
            <PrettyRender value={result} />
          ) : (
            formatValue(result)
          ),
      },
    ]}
    renderEmptyTable={{ title: 'No results found' }}
  />
);

export default GremlinResultsTableDirect;
