import { createFifoCache } from '@ardoq/common-helpers';
import { RepresentationData } from '@ardoq/data-model';
import { logError } from '@ardoq/logging';
import { actionCreator, dispatchAction } from '@ardoq/rxbeach';
import {
  FALLBACK_IMAGE_ICON_INFO,
  IconInfoForSvgImage,
  createSvgImageUrl,
} from '@ardoq/icons';

export const canvasResolvedImages = new Map<string, HTMLImageElement>();
/** dispatched when an image url is loaded in the browser, necessitating a redraw. */
export const canvasResolvedImageReady = actionCreator(
  '[Canvas] RESOLVED_IMAGE_READY'
);
/** dispatched when an SVG image is loaded in the browser, necessitating a redraw. */
export const svgImageReady = actionCreator('[Canvas] SVG_IMAGE_READY');

/**
 * Cache for svg elements to avoid cloning sub-elements of icons.
 */
const svgElementCache = new Map<string, SVGSVGElement>();
const dispatchSvgImageReady = () => dispatchAction(svgImageReady());
/** args interpolation: `${assetId}~~${color}~~${fill}~~${stroke}~~${width}~~${height}` */
export const svgImage = createFifoCache(
  Infinity,
  (iconInfo: IconInfoForSvgImage) => {
    const url = createSvgImageUrl(iconInfo, svgElementCache);
    if (url === '') {
      logError(Error('unresolved svg image'));
    }
    const result = new Image();
    result.src = url;
    if (!result.complete) {
      result.addEventListener('load', dispatchSvgImageReady, { once: true });
    }
    return result;
  }
);

export const FALLBACK_IMAGE = svgImage(FALLBACK_IMAGE_ICON_INFO);

const setCanvasResolvedImage = (
  representationData: RepresentationData,
  image: HTMLImageElement
) => {
  canvasResolvedImages.set(representationData.value!, image);
  dispatchAction(canvasResolvedImageReady());
};

// attempting to draw incomplete image to the canvas will produce blankness.
// if the image is complete, this indicates the browser has downloaded it.
// otherwise, we must wait for onload and dispatch this event to the view, so it can redraw itself when the image is ready.
export const canvasResolveImage = (representationData: RepresentationData) => {
  if (
    representationData.isImage &&
    representationData.value &&
    !canvasResolvedImages.has(representationData.value)
  ) {
    const img = new Image();
    img.onload = () => {
      setCanvasResolvedImage(representationData, img);
    };
    img.onerror = () => {
      setCanvasResolvedImage(representationData, FALLBACK_IMAGE);
    };

    img.src = representationData.value;
  }
};
