import * as d3 from 'd3';
import { RelationshipsNode } from './types';
import { sample } from 'lodash';
import { ONE_DEGREE_TO_RADIANS } from './consts';

/* have a bunch of predefined layouts */

type HomogenousLayoutItem = [
  minimumEnclosingCircleX: number,
  minimumEnclosingCircleY: number,
  minimumEnclosingCircleRadius: number,
  ...circlePositions: number[],
];
/** a map of already-calculated homogenous layouts, where each child is the same size. */
const homogenousLayouts = new Map<number, HomogenousLayoutItem>();

/** gets a homogenous layout, containing the minimum enclosing circle, and the XY coordinates of packed circles with equal radii. */
function getHomogenousLayout(size: number) {
  if (homogenousLayouts.has(size)) {
    return homogenousLayouts.get(size)!;
  }

  const circles = d3.packSiblings(
    Array.from({ length: size }, () => ({ x: 0, y: 0, r: 1 }))
  );
  const mec = d3.packEnclose(circles);
  const result: HomogenousLayoutItem = [
    mec.x,
    mec.y,
    mec.r,
    ...circles.flatMap(circle => [circle.x, circle.y]),
  ];

  homogenousLayouts.set(size, result);
  return result;
}

/**
 * applies a circle packing layout to the given nodes, setting their x, y, and r properties.
 * @returns the minimum enclosing circle of the laid out nodes
 */
export function packLayout(
  nodes: RelationshipsNode[],
  margin: number,
  random: () => number
) {
  const rotation = getRotationFactor(random);

  /** if nodes have a uniform radius, this will be that radius. otherwise, NaN. */
  const radiusOfHomogenousNodes =
    nodes.length && !nodes.some(({ r }) => r !== nodes[0].r) ? nodes[0].r : NaN;

  /* only get a layout for homogeneous groups */

  const layout = !Number.isNaN(radiusOfHomogenousNodes)
    ? getHomogenousLayout(nodes.length)
    : null;

  if (layout) {
    /* apply a precalculated layout */

    const scale = radiusOfHomogenousNodes + margin;

    const m00 = scale * Math.cos(rotation);
    const m10 = scale * Math.sin(rotation);
    const m01 = -m10;
    const m11 = m00;

    for (let i = 0; i < nodes.length; ++i) {
      nodes[i].x = m00 * layout[3 + 2 * i] + m01 * layout[4 + 2 * i];
      nodes[i].y = m10 * layout[3 + 2 * i] + m11 * layout[4 + 2 * i];
    }
    return {
      x: m00 * layout[0] + m01 * layout[1],
      y: m10 * layout[0] + m11 * layout[1],
      r: scale * layout[2],
    };
  }

  /* work out and apply a packed layout */

  const m00 = Math.cos(rotation);
  const m10 = Math.sin(rotation);
  const m01 = -m10;
  const m11 = m00;

  /* calculate layout */

  d3.packSiblings(
    nodes
      .map(node => ({ x: 0, y: 0, r: node.r + margin, node }))
      .sort((a, b) => b.r - a.r)
  ).forEach(({ x, y, node }) => {
    node.x = m00 * x + m01 * y;
    node.y = m10 * x + m11 * y;
  });

  /* calculate minimum enclosing circle */

  const { x, y, r } = d3.packEnclose(nodes);

  return {
    x: m00 * x + m01 * y,
    y: m10 * x + m11 * y,
    r: r,
  };
}

/**
 * Ranges where labels would not collide.
 * Currently we have 6 nodes in a circle around 1 node => avoid 60 degree ranges.
 */
const suitableRotationRangesInDegrees = [
  [20, 40],
  [80, 100],
  [140, 160],
  [200, 220],
  [260, 280],
  [320, 340],
];

const degreeToRadians = (deg: number) => deg * ONE_DEGREE_TO_RADIANS;

/**
 * Avoid aligning labels horizontally to prevent collision of long labels
 * @returns an angle in radians, selected at random from within acceptable ranges for collision-free label rotation.
 */
const getRotationFactor = (random: () => number): number => {
  const [min, max] = sample(suitableRotationRangesInDegrees)!.map(
    degreeToRadians
  );
  return min + (max - min) * random();
};
