import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { dispatchAction } from '@ardoq/rxbeach';
import { HIDE_EMPTY_VALUES } from 'tabview/consts';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { updateViewSettings } from '@ardoq/view-settings';

type NoDataWithHideEmptySettingDoqProps = {
  doqType: DoqType;
  viewId: string;
  includeActions?: boolean;
};

const NoDataWithHideEmptySettingDoq = ({
  doqType,
  viewId,
  includeActions = true,
}: NoDataWithHideEmptySettingDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            There are only empty values from these row and column groupings.
          </FlexBox>
          <FlexBox gap="medium">
            <span style={{ display: 'inline' }}>
              To show more data, deactivate the view modifier &quot;
              {HIDE_EMPTY_VALUES}&quot;{' '}
              <Icon
                iconSize={IconSize.SMALL}
                iconName={IconName.NOT_INTERESTED}
              />
            </span>
          </FlexBox>
        </Stack>
      }
      buttonLabel={includeActions ? 'Deactivate setting' : undefined}
      onButtonClick={
        includeActions
          ? () =>
              dispatchAction(
                updateViewSettings({
                  viewId,
                  settings: { filterNulls: false },
                  persistent: true,
                })
              )
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoDataWithHideEmptySettingDoq;
