import { INodeStyle, Size } from '@ardoq/yfiles';
import { Node } from 'graph/types';
import { GraphNode } from '@ardoq/graph';
import ArdoqNodeStyle from './ArdoqNodeStyle';
import ArdoqImageStyle from './ArdoqImageStyle';

const DEFAULT_SIZE = new Size(100, 60);

const shapeStyles = new Map<string, ArdoqNodeStyle>();
const imageStyles: Record<string, ArdoqImageStyle> = {};

const getImageStyle = (url: string) => {
  if (imageStyles[url]) {
    return imageStyles[url];
  }
  const style = new ArdoqImageStyle(url);
  imageStyles[url] = style;
  return style;
};

export const getDefaultSize = () => DEFAULT_SIZE;
export const getStyle = (node: Node | GraphNode) => {
  const nodeImage = node.getImage();

  if (nodeImage) {
    return getImageStyle(nodeImage);
  }

  const shapeId = node.getShape();
  const shapeStyle = shapeStyles.get(shapeId);

  if (shapeStyle) {
    return shapeStyle;
  }

  const nodeStyle = new ArdoqNodeStyle(shapeId);
  shapeStyles.set(shapeId, nodeStyle);
  return nodeStyle;
};

export const getNodeSize = (style: INodeStyle) => {
  return (style as ArdoqNodeStyle).size;
};
