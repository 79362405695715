import type { LegendReferenceType } from '@ardoq/graph';
import { RelationshipsLinkVisual } from '../types';
import { getMatchingFilterIdsFilter, getReferenceTypesFilter } from './util';

const getSelectedLinks = (
  links: RelationshipsLinkVisual[],
  selectedReferenceTypes: LegendReferenceType[] | null,
  selectedConditionalFormatting: string[] | null
) => {
  const linkMatchesReferenceTypes = selectedReferenceTypes?.length
    ? getReferenceTypesFilter(selectedReferenceTypes)
    : () => false;
  const linkMatchesConditionalFormatting = getMatchingFilterIdsFilter(
    new Set(selectedConditionalFormatting)
  );
  return links.filter(
    link =>
      linkMatchesReferenceTypes(link) || linkMatchesConditionalFormatting(link)
  );
};
export default getSelectedLinks;
