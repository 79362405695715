import { HierarchicInGridLayoutOptions } from '../../proteanDiagram/types';
import { RelationshipDiagramViewModel } from '@ardoq/graph';
import {
  combineTabularConstraints,
  nodesAndGroupsFromGraphViewDataModel,
} from './util';
import { partition } from 'lodash';
import {
  ConstraintSource,
  ResolvedLayoutConstraint,
} from 'tabview/proteanDiagram/view/types';

const resolveHierarchicInGridConstraints = (
  {
    columnConstraintsWithSource = [],
    rowConstraintsWithSource = [],
    columnSpans = [],
    rowSpans = [],
    layoutRules = [],
  }: Pick<
    HierarchicInGridLayoutOptions,
    | 'layoutRules'
    | 'columnConstraintsWithSource'
    | 'rowConstraintsWithSource'
    | 'columnSpans'
    | 'rowSpans'
  >,
  viewModel: RelationshipDiagramViewModel
) => {
  const [rowConstraintRules, columnConstraintRules] = partition(
    layoutRules.filter(
      ({ dimension }) => dimension === 'row' || dimension === 'column'
    ),
    ({ dimension }) => dimension === 'row'
  );
  const graphNodes = nodesAndGroupsFromGraphViewDataModel(viewModel);

  const rowConstraintsMap = combineTabularConstraints(
    rowConstraintsWithSource,
    rowConstraintRules,
    graphNodes
  );
  const columnConstraintsMap = combineTabularConstraints(
    columnConstraintsWithSource,
    columnConstraintRules,
    graphNodes
  );

  const [rowSpansMap, columnSpansMap] = [rowSpans, columnSpans].map(
    spans =>
      new Map(
        spans.map(([nodeId, value]) => [
          nodeId,
          [
            nodeId,
            value,
            value,
            ConstraintSource.VIEW_SETTINGS,
          ] satisfies ResolvedLayoutConstraint,
        ])
      )
  );

  return {
    rowConstraintsMap,
    columnConstraintsMap,
    rowSpansMap,
    columnSpansMap,
  };
};

export default resolveHierarchicInGridConstraints;
