import { RefObject, forwardRef, createRef, Component } from 'react';
import { BubbleChartQuadrantSettings } from './types';
import { SecondaryButton, PrimaryButton, ButtonGroup } from '@ardoq/button';
import { ModalSize, ModalTemplate } from '@ardoq/modal';
import { TextInput } from '@ardoq/forms';
import { Paragraph } from '@ardoq/typography';
import { FlexBox, Stack } from '@ardoq/layout';

interface QuadrantCustomizerProperties {
  initialValue: BubbleChartQuadrantSettings;
  close: (result: BubbleChartQuadrantSettings) => void;
}

const getInputValue = (input: RefObject<HTMLInputElement>) => {
  if (!input.current) {
    return NaN;
  }
  const valueAsNumber = input.current.valueAsNumber;
  return valueAsNumber === null ? NaN : valueAsNumber;
};

const LabeledInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { label: string }
>((props, ref) => (
  <TextInput
    label={props.label}
    ref={ref}
    type={props.type}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
  />
));
class QuadrantCustomizer extends Component<QuadrantCustomizerProperties> {
  private xMinimumInput = createRef<HTMLInputElement>();
  private xMaximumInput = createRef<HTMLInputElement>();
  private yMinimumInput = createRef<HTMLInputElement>();
  private yMaximumInput = createRef<HTMLInputElement>();
  private quadrant1LabelInput = createRef<HTMLInputElement>();
  private quadrant2LabelInput = createRef<HTMLInputElement>();
  private quadrant3LabelInput = createRef<HTMLInputElement>();
  private quadrant4LabelInput = createRef<HTMLInputElement>();

  private cancel = () => this.props.close(this.props.initialValue);
  private getQuadrantSettings = (): BubbleChartQuadrantSettings => ({
    xMinimum: getInputValue(this.xMinimumInput),
    xMaximum: getInputValue(this.xMaximumInput),
    yMinimum: getInputValue(this.yMinimumInput),
    yMaximum: getInputValue(this.yMaximumInput),
    labels: [
      this.quadrant1LabelInput.current!.value,
      this.quadrant2LabelInput.current!.value,
      this.quadrant3LabelInput.current!.value,
      this.quadrant4LabelInput.current!.value,
    ],
  });
  render() {
    return (
      <ModalTemplate
        modalSize={ModalSize.S}
        headerText="Custom Quadrants"
        onCloseButtonClick={this.cancel}
        footerContent={
          <ButtonGroup>
            <SecondaryButton onClick={this.cancel}>Cancel</SecondaryButton>
            <PrimaryButton
              onClick={() => this.props.close(this.getQuadrantSettings())}
            >
              Apply
            </PrimaryButton>
          </ButtonGroup>
        }
      >
        <FlexBox padding="medium" justify="center">
          <Stack gap="small">
            <FlexBox justify="center" marginBottom="xsmall">
              <Paragraph variant="text1Bold">Quadrant Labels</Paragraph>
            </FlexBox>
            <FlexBox gap="small">
              <TextInput
                ref={this.quadrant1LabelInput}
                defaultValue={this.props.initialValue.labels[0]}
              />
              <TextInput
                ref={this.quadrant2LabelInput}
                defaultValue={this.props.initialValue.labels[1]}
              />
            </FlexBox>
            <FlexBox gap="small">
              <TextInput
                ref={this.quadrant3LabelInput}
                defaultValue={this.props.initialValue.labels[2]}
              />
              <TextInput
                ref={this.quadrant4LabelInput}
                defaultValue={this.props.initialValue.labels[3]}
              />
            </FlexBox>

            <FlexBox paddingTop="small" gap="small">
              <LabeledInput
                label="X Minimum:"
                type="number"
                ref={this.xMinimumInput}
                defaultValue={`${this.props.initialValue.xMinimum}`}
                placeholder="Auto"
              />
              <LabeledInput
                label="X Maximum:"
                type="number"
                ref={this.xMaximumInput}
                defaultValue={`${this.props.initialValue.xMaximum}`}
                placeholder="Auto"
              />
            </FlexBox>
            <FlexBox gap="small">
              <LabeledInput
                label="Y Minimum:"
                type="number"
                ref={this.yMinimumInput}
                defaultValue={`${this.props.initialValue.yMinimum}`}
                placeholder="Auto"
              />
              <LabeledInput
                label="Y Maximum:"
                type="number"
                ref={this.yMaximumInput}
                defaultValue={`${this.props.initialValue.yMaximum}`}
                placeholder="Auto"
              />
            </FlexBox>
          </Stack>
        </FlexBox>
      </ModalTemplate>
    );
  }
}
export default QuadrantCustomizer;
