export const STRINGS = {
  TITLE: 'Open scenario to exclude workspace',
  SUBTITLE: undefined,
  MESSAGE: {
    WHY: 'Components from the {workspaceName} workspace are used in the scenarios:',
    HOW: {
      SUMMARY:
        'Workspace {workspaceName} must be excluded from these scenarios before it can be deleted.',
      TO_EXCLUDE: 'To exclude workspace:',
      STEPS: [
        'Open a scenario.',
        'Exclude workspace from the scenario.',
        'Return and repeat with the remaining scenarios.',
        'Go to Workspace overview page to delete workspace.',
      ],
    },
  },
  IMAGE_ALT: 'Animation showing how to exclude a workspace from a scenario',
  SELECT: {
    LABEL: 'Choose scenario',
    PLACEHOLDER: 'Type to search',
  },
  PRIMARY_BUTTON_LABEL: 'Open scenario',
  SECONDARY_BUTTON_LABEL: 'Cancel',
};
