import { Metamodel } from '@ardoq/api-types';
import {
  MetamodelComponentTypeContext,
  MetamodelContext,
  MetamodelContextSettings,
  MetamodelFieldContext,
  MetamodelReferenceCompletenessContext,
  MetamodelReferenceTypeContext,
} from 'metamodel/types';
import MetamodelStats from './MetamodelStats';
import AssetFolderStats from './AssetFolderStats';
import WorkspaceStats from './WorkspaceStats';
import ComponentTypeStats from './ComponentTypeStats';
import ReferenceTypeStats from './ReferenceTypeStats';
import ComponentFieldStats from './ComponentFieldStats';
import ReferenceFieldStats from './ReferenceFieldStats';
import ReferenceCompletionStats from './referenceCompletionStats/ReferenceCompletionStats';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';

const Stats = ({
  context,
  metamodel,
}: {
  context: MetamodelContext;
  metamodel: Metamodel;
}) => {
  if (context.type === SimpleBlockDiagramContentType.WORKSPACE_FOLDER) {
    return <AssetFolderStats context={context} metamodel={metamodel} />;
  }
  if (context.type === SimpleBlockDiagramContentType.WORKSPACE) {
    return <WorkspaceStats context={context} metamodel={metamodel} />;
  }
  if (
    context.type === SimpleBlockDiagramContentType.COMPONENT &&
    !context.field
  ) {
    return (
      <ComponentTypeStats
        context={context as MetamodelComponentTypeContext & MetamodelContext}
        metamodel={metamodel}
      />
    );
  }
  if (
    context.type === SimpleBlockDiagramContentType.REFERENCE &&
    !context.field
  ) {
    return (
      <ReferenceTypeStats
        context={
          context as MetamodelReferenceTypeContext & MetamodelContextSettings
        }
        metamodel={metamodel}
      />
    );
  }
  if (
    context.type === SimpleBlockDiagramContentType.COMPONENT &&
    !context.settings.isolatedNavigation
  ) {
    return (
      <ComponentFieldStats
        context={context as MetamodelFieldContext & MetamodelContextSettings}
        metamodel={metamodel}
      />
    );
  }
  if (
    context.type === SimpleBlockDiagramContentType.REFERENCE &&
    !context.settings.isolatedNavigation
  ) {
    return (
      <ReferenceFieldStats
        context={context as MetamodelFieldContext & MetamodelContextSettings}
        metamodel={metamodel}
      />
    );
  }
  if (
    context.type === SimpleBlockDiagramContentType.REFERENCE_COMPLETENESS &&
    !context.settings.isolatedNavigation
  ) {
    return (
      <ReferenceCompletionStats
        context={
          context as MetamodelReferenceCompletenessContext &
            MetamodelContextSettings
        }
        metamodel={metamodel}
      />
    );
  }

  return <MetamodelStats metamodel={metamodel} context={context} />;
};

export default Stats;
