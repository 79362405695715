import HeaderComponent from './headerComponent';
import { HeaderCellModel } from '../types';
import getRowHeaderCellStyle from './getRowHeaderCellStyle';

interface RowHeaderComponentArgs {
  componentId: string;
  showExpander: boolean;
  isExpanded: boolean;
  headerCell: HeaderCellModel;
  isHighlighted: boolean;
  address: (string | null)[];
  expanderClicked: () => void;
}
const RowHeaderComponent = ({
  componentId,
  showExpander,
  expanderClicked,
  isExpanded,
  headerCell,
  isHighlighted,
  address,
}: RowHeaderComponentArgs) => (
  <HeaderComponent
    componentId={componentId}
    address={address}
    showExpander={showExpander}
    expanderClicked={expanderClicked}
    isExpanded={isExpanded}
    isRowHeader={true}
    containerStyle={getRowHeaderCellStyle(headerCell)}
    iconContainerStyle={{
      marginLeft: 'auto',
      marginTop: -2,
      height: 20,
    }}
    isHighlighted={isHighlighted}
  />
);
export default RowHeaderComponent;
