import { Observable } from 'rxjs';
import { action$, reducer, reduceState } from '@ardoq/rxbeach';
import { withNamespaceOrNullNamespace } from '../../streams/utils/streamOperators';
import {
  updateSettingsBarData,
  type UpdateSettingsBarDataArgs,
} from '@ardoq/view-settings';
import { ViewIds } from '@ardoq/api-types';
import { SettingsBarState } from './types';
import { getSharedExportFunctions } from '../../tabview/getSharedExportFunctions';
import { getRightSettingsMenuConfig } from './getRightMenuSettingsConfig';
import { viewHasLegend } from '../../views/metaInfoTabs';
import { onViewSettingsUpdate } from '../../tabview/onViewSettingsUpdate';
import { logWarn } from '@ardoq/logging';

const DEFAULT_SETTINGS_BAR_STATE: SettingsBarState = {
  viewId: ViewIds.NONE,
  leftMenu: [],
  rightMenu: [],
};

const viewSettingsBarDataUpdated = (
  _: SettingsBarState,
  { settingsControlsDependencies, viewId }: UpdateSettingsBarDataArgs
): SettingsBarState => {
  const {
    viewModifiers: leftMenu,
    viewSettings,
    viewContext: { getContainer, zoomControls, knowledgeBaseLink },
    isEmptyView,
  } = settingsControlsDependencies;

  const { getStyleSheets, addToPresentation, trackingFunction } =
    getSharedExportFunctions();

  if (!(zoomControls && knowledgeBaseLink)) {
    logWarn(
      new Error(
        'zoomControls and knowledgeBaseLink are required in settings bar'
      )
    );
    return { leftMenu, rightMenu: [], viewId };
  }

  const rightMenu = getRightSettingsMenuConfig({
    viewSettings,
    viewId,
    viewHasLegend,
    onViewSettingsUpdate,
    getContainer,
    trackingFunction,
    getStyleSheets,
    isEmptyView,
    addToPresentation,
    zoomControls,
    knowledgeBaseLink,
  });

  return { rightMenu, leftMenu, viewId };
};

export const getSettingsBarInstanceStateForViewId = (
  viewId: ViewIds,
  uniqueNamespace: string
): Observable<SettingsBarState> => {
  const initialSettingsBarState: SettingsBarState = {
    ...DEFAULT_SETTINGS_BAR_STATE,
    viewId,
  };

  return action$.pipe(
    withNamespaceOrNullNamespace(uniqueNamespace),
    reduceState<SettingsBarState>(
      'viewPaneSettingsBar',
      initialSettingsBarState,
      [reducer(updateSettingsBarData, viewSettingsBarDataUpdated)]
    )
  );
};

export default getSettingsBarInstanceStateForViewId;
