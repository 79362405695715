import { forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { RowElementModel } from '../types';
import ComponentMatrixRowHeader from './componentMatrixRowHeader';
import { MAIN_GRID_LEFT_COLUMN_MAX_WIDTH_VIEWPORT_UNITS } from '../consts';
import { ArdoqId } from '@ardoq/api-types';

const ComponentMatrixRowHeaderContainer = styled.div`
  overflow: hidden;
  display: grid;
  grid-row: 2;
  grid-column: 1;
  grid-template-columns: 100%;
  max-width: ${MAIN_GRID_LEFT_COLUMN_MAX_WIDTH_VIEWPORT_UNITS}vw;
`;

type ComponentMatrixRowHeadersAreaProperties = {
  rowModels: RowElementModel[];
  rowHeights: number[];
  width: number;
  horizontalScrollbarHeight: number;
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
};
export type ComponentMatrixRowHeadersAreaRef = {
  setScrollTop: (scrollTop: number) => void;
};
const ComponentMatrixRowHeadersArea = forwardRef<
  ComponentMatrixRowHeadersAreaRef,
  ComponentMatrixRowHeadersAreaProperties
>((props, ref) => {
  const container = useRef<HTMLDivElement>(null);

  const setScrollTop = (value: number) => {
    if (!container.current) {
      return;
    }
    container.current.scrollTop = value;
  };
  useImperativeHandle(ref, () => ({ setScrollTop }));

  const {
    rowModels,
    rowHeights,
    width,
    horizontalScrollbarHeight,
    hoveredComponentId,
    focusedComponentId,
  } = props;

  return (
    <ComponentMatrixRowHeaderContainer
      ref={container}
      style={{
        width,
        gridTemplateRows: rowHeights.map(height => `${height}px`).join(' '),
        marginBottom: horizontalScrollbarHeight,
      }}
    >
      {rowModels.map((rowModel, index) => (
        <ComponentMatrixRowHeader
          key={index}
          rowModel={rowModel}
          rowIndex={index}
          hoveredComponentId={hoveredComponentId}
          focusedComponentId={focusedComponentId}
        />
      ))}
    </ComponentMatrixRowHeaderContainer>
  );
});

export default ComponentMatrixRowHeadersArea;
