import { colors } from '@ardoq/design-tokens';
import {
  Class,
  INode,
  INodeInsetsProvider,
  INodeSizeConstraintProvider,
  IRenderContext,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import {
  CollapsibleGraphGroup,
  getComponentLabelParts,
  truncateComponentLabel,
  truncateText,
} from '@ardoq/graph';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import ProteanGroupInsetsProvider from '../ProteanGroupInsetsProvider';
import {
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  DATA_RENDER_HASH,
} from 'yfilesExtensions/styles/consts';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { PROTEAN_GROUP_MARGIN } from '../consts';
import ProteanGroupSizeConstraintProvider from './ProteanGroupSizeConstraintProvider';
import { componentInterface } from '@ardoq/component-interface';
import svgComponentRepresentation from './svgComponentRepresentation';
import svgGroupExpander from './svgGroupExpander';
import { dispatchAction } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import { relationshipDiagramToggleCollapseGroup } from 'tabview/relationshipDiagrams/actions';
import SparkMD5 from 'spark-md5';
import { createSvgElement, MeasureStyledSvgText } from '@ardoq/dom-utils';
import { PROTEAN_GROUP_EXPANDER_RADIUS } from '../../consts';

const CORNER_RADIUS = 4;
const ICON_SIZE = 38;

const createRenderDataCache = (
  { layout: { width, height } }: INode,
  graphGroup: CollapsibleGraphGroup
) =>
  SparkMD5.hash(
    [
      width,
      height,
      graphGroup.getImage(),
      graphGroup.collapsed,
      graphGroup.modelId,
    ].join(',')
  );

const measureGroupLabel = (text: string) =>
  MeasureStyledSvgText.Instance.getTextWidth({
    text,
    fontSize: `${BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize}`,
    fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  });
const truncateGroupLabel = truncateText(measureGroupLabel);

const stylesByViewId = new Map<ViewIds, RectangularGroupStyle>();

class RectangularGroupStyle extends NodeStyleBase {
  static get(viewId: ViewIds) {
    if (!stylesByViewId.has(viewId)) {
      stylesByViewId.set(viewId, new RectangularGroupStyle(viewId));
    }
    return stylesByViewId.get(viewId)!;
  }
  private constructor(private readonly viewId: ViewIds) {
    super();
  }

  protected override createVisual(
    context: IRenderContext,
    node: INode
  ): Visual | null {
    const {
      layout: { x, y, width, height },
    } = node;
    if (!isFinite(width) || !isFinite(height) || !isFinite(x) || !isFinite(y)) {
      return null;
    }
    const graphGroup = node.tag as CollapsibleGraphGroup;
    const { modelId, collapsed } = graphGroup;
    const { fill = colors.grey90, stroke = colors.black } =
      getComponentCssColors(modelId, {
        useAsBackgroundStyle: true,
      }) ?? { fill: colors.grey90, stroke: colors.black };

    const expander = svgGroupExpander(
      !collapsed,
      PROTEAN_GROUP_EXPANDER_RADIUS,
      {
        transform: `translate(${PROTEAN_GROUP_MARGIN}, ${PROTEAN_GROUP_MARGIN})`,
      }
    );
    expander.addEventListener('click', () =>
      dispatchAction(
        relationshipDiagramToggleCollapseGroup({
          groupId: graphGroup.id,
          viewId: this.viewId,
        })
      )
    );

    const pathElement = createSvgElement('rect', {
      width: `${width}`,
      height: `${height}`,
      fill,
      stroke,
      rx: `${CORNER_RADIUS}`,
      ry: `${CORNER_RADIUS}`,
    });
    pathElement.style.opacity = '0.8';

    const isComponent = graphGroup.isComponent();
    const representationData = isComponent
      ? componentInterface.getRepresentationData(graphGroup.modelId)
      : null;

    const iconElement = representationData
      ? svgComponentRepresentation({
          representationData,
          bounds: [
            PROTEAN_GROUP_MARGIN +
              2 * PROTEAN_GROUP_EXPANDER_RADIUS +
              PROTEAN_GROUP_MARGIN,
            PROTEAN_GROUP_MARGIN,
            ICON_SIZE,
            ICON_SIZE,
          ],
        })
      : null;

    const { fontSize } = BLOCK_DIAGRAM_NODE_LABEL_FONT;
    const groupLabelStart =
      PROTEAN_GROUP_MARGIN +
      2 * PROTEAN_GROUP_EXPANDER_RADIUS +
      PROTEAN_GROUP_MARGIN +
      (iconElement ? fontSize + PROTEAN_GROUP_MARGIN : 0);
    const textElement = createSvgElement('text', {
      x: `${groupLabelStart}px`,
      y: `${PROTEAN_GROUP_MARGIN}px`,
      'dominant-baseline': 'hanging',
      'text-anchor': 'start',
      'font-family': ARDOQ_DEFAULT_FONT_FAMILY,
      'font-size': `${fontSize}px`,
      fill: colors.black,
    });

    const widthForLabel =
      node.layout.width - PROTEAN_GROUP_MARGIN - groupLabelStart;
    const groupLabel = isComponent
      ? truncateComponentLabel({
          width: widthForLabel,
          measure: measureGroupLabel,
          ...getComponentLabelParts(graphGroup.modelId),
        })
      : truncateGroupLabel(graphGroup.getLabel(), widthForLabel, {
          fontSize,
          fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
        });
    textElement.textContent = groupLabel;

    const container = createSvgElement('g');
    container.setAttribute('class', isComponent ? `component` : '');
    container.appendChild(pathElement);
    container.appendChild(expander);
    if (iconElement) {
      const iconContainer = createSvgElement('g', {
        fill: isComponent
          ? (componentInterface.getComponentDisplayColorAsSVGAttributes(
              graphGroup.modelId,
              {
                useAsBackgroundStyle: false,
              }
            ).fill ?? colors.black)
          : colors.black,
        stroke: 'none',
      });
      iconContainer.appendChild(iconElement);
      container.appendChild(iconContainer);
    }
    container.appendChild(textElement);
    container.setAttribute(
      DATA_RENDER_HASH,
      createRenderDataCache(node, graphGroup)
    );
    SvgVisual.setTranslate(container, x, y);
    return new SvgVisual(container);
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    node: INode
  ): Visual | null {
    const element = (oldVisual as SvgVisual).svgElement;
    const hash = element.getAttribute(DATA_RENDER_HASH);
    const newHash = createRenderDataCache(
      node,
      node.tag as CollapsibleGraphGroup
    );
    if (hash === newHash) {
      const {
        layout: { x, y },
      } = node;
      SvgVisual.setTranslate(element, x, y);
      return oldVisual;
    }
    return this.createVisual(context, node);
  }
  protected override lookup(node: INode, type: Class<any>) {
    switch (type) {
      case INodeInsetsProvider.$class:
        return ProteanGroupInsetsProvider.Instance;
      case INodeSizeConstraintProvider.$class:
        return ProteanGroupSizeConstraintProvider.Instance;
    }
    return super.lookup(node, type);
  }
}
export default RectangularGroupStyle;
