import { GraphItem, GraphViewDataModel } from '@ardoq/graph';
import resolveConstraintsReducer from './resolveConstraintsReducer';
import { LayoutConstraintRuleProps } from 'tabview/proteanDiagram/view/layoutRules/types';
import { LayoutConstraint } from '../types';
import {
  LayoutConstraintWithSource,
  ResolvedLayoutConstraint,
} from 'tabview/proteanDiagram/view/types';
import resolveTabularConstraintsReducer from './resolveTabularConstraintsReducer';

export const nodesAndGroupsFromGraphViewDataModel = ({
  nodes,
  groups,
}: GraphViewDataModel) =>
  [groups, nodes]
    .map(items =>
      [...items.add, ...items.update].map(id => items.byId.get(id)!)
    )
    .flat();

export const combineConstraints = (
  constraints: LayoutConstraint[],
  constraintRules: LayoutConstraintRuleProps[],
  graphNodes: GraphItem[]
) =>
  graphNodes.reduce(
    resolveConstraintsReducer(new Map(constraints), constraintRules),
    new Map()
  );

export const combineTabularConstraints = (
  constraints: LayoutConstraintWithSource[],
  constraintRules: LayoutConstraintRuleProps[],
  graphNodes: GraphItem[]
) =>
  graphNodes.reduce(
    resolveTabularConstraintsReducer(
      getResolvedConstraintsMap(constraints),
      constraintRules
    ),
    new Map()
  );

const getResolvedConstraintsMap = (constraints: LayoutConstraintWithSource[]) =>
  new Map(
    constraints.map(([id, value, source]) => [
      id,
      [id, value, value, source] satisfies ResolvedLayoutConstraint,
    ])
  );
