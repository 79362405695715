import { Metamodel } from '@ardoq/api-types';
import { MetamodelReferenceCompletenessContext } from 'metamodel/types';
import { DisplayPreference } from './types';
import { escapeSingleQuotes } from '../utils';

const isSourceContext = ({
  source: sourceId,
  componentId,
  contextComponentTypeIsSource,
}: MetamodelReferenceCompletenessContext) =>
  sourceId === componentId && contextComponentTypeIsSource;

export const getDefaultDisplayPreference = (
  context: MetamodelReferenceCompletenessContext
) => {
  const { source, target } = context.metadata.referenceCompleteness;
  const { connectedComponents, totalComponents } = isSourceContext(context)
    ? source
    : target;

  return connectedComponents === totalComponents
    ? DisplayPreference.WITH_REFERENCE
    : DisplayPreference.WITHOUT_REFERENCE;
};

export const getDirectionDescription = (
  context: MetamodelReferenceCompletenessContext
) => (isSourceContext(context) ? 'outgoing' : 'incoming');

export const getDirectionPreposition = (
  context: MetamodelReferenceCompletenessContext
) => (isSourceContext(context) ? 'to' : 'from');

export const getDisplayPreferenceDescription = (
  displayPreference: DisplayPreference
) =>
  displayPreference === DisplayPreference.WITH_REFERENCE
    ? 'have'
    : 'do not have';

export const getContextComponentType = (
  { metamodel }: Metamodel,
  { componentId }: MetamodelReferenceCompletenessContext
) => metamodel.componentTypes[componentId];

export const getOtherComponentType = (
  { metamodel }: Metamodel,
  context: MetamodelReferenceCompletenessContext
) => {
  const { source: sourceId, target: targetId } = context;
  const otherComponentTypeId = isSourceContext(context) ? targetId : sourceId;
  return metamodel.componentTypes[otherComponentTypeId];
};

const getQueryStep = (context: MetamodelReferenceCompletenessContext) =>
  isSourceContext(context) ? 'out' : '__.in';

const getQueryFilter = (displayPreference: DisplayPreference) =>
  displayPreference === DisplayPreference.WITH_REFERENCE ? 'filter' : 'not';

export const getReferenceCompletionQuery = (
  metamodel: Metamodel,
  context: MetamodelReferenceCompletenessContext,
  displayPreference: DisplayPreference
) => {
  const { name: contextComponentLabel, workspaceId: contextWorkspaceId } =
    getContextComponentType(metamodel, context);

  const { name: otherComponentLabel, workspaceId: otherWorkspaceId } =
    getOtherComponentType(metamodel, context);

  const step = getQueryStep(context);
  const filter = getQueryFilter(displayPreference);
  const referenceLabel = context.name;

  return `g.V().has('rootWorkspace', '${contextWorkspaceId}').
                hasLabel('${escapeSingleQuotes(contextComponentLabel)}').
                ${filter}(
                  ${step}('${escapeSingleQuotes(referenceLabel)}').
                  has('rootWorkspace', '${otherWorkspaceId}').
                  hasLabel('${escapeSingleQuotes(otherComponentLabel)}'))`;
};
