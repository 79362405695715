import { Metamodel } from '@ardoq/api-types';
import { MetamodelContext } from 'metamodel/types';
import WorkspaceNavigationPopover from './workspaceNavigationPopover/WorkspaceNavigationPopover';
import { trackClickContextTitle } from 'metamodel/tracking';
import { handleNavigation } from 'metamodel/utils';
import { Title } from './atoms';
import { getTrackingNavigationContext } from './utils';

interface WorkspaceNavigationTitleProps {
  metamodel: Metamodel;
  context: MetamodelContext;
  children?: any;
}

const WorkspaceNavigationTitle = ({
  metamodel,
  context,
  children,
}: WorkspaceNavigationTitleProps) => {
  const title = context.settings.isolatedNavigation ? (
    <Title>{children}</Title>
  ) : (
    <WorkspaceNavigationPopover metamodel={metamodel} context={context}>
      <Title
        onClick={() => {
          const navigationContext = getTrackingNavigationContext(context);
          trackClickContextTitle(navigationContext);
          handleNavigation(metamodel, context);
        }}
      >
        {children}
      </Title>
    </WorkspaceNavigationPopover>
  );
  return <div>{title}</div>;
};

export default WorkspaceNavigationTitle;
