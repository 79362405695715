import { RichTextReader } from '@ardoq/rich-text-editor';
import Cell from './Cell';
import { HeaderModel, TableViewRow } from './types';
import { colors } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';
import { Text } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

const RowItem = styled.tr<{
  $isHighlighted?: boolean;
  $isDescriptionExpanded?: boolean;
  $isDescription?: boolean;
}>`
  position: relative;

  ${({ $isHighlighted, $isDescriptionExpanded, $isDescription }) =>
    $isHighlighted &&
    css`
      background-color: ${colors.blue60}10 !important;
      &:after {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        content: ' ';
        inset: 0;
        border: 2px solid ${colors.blue60};
        ${$isDescriptionExpanded
          ? $isDescription
            ? 'border-top: 0;'
            : 'border-bottom: 0;'
          : ''}
      }
    `}
`;

type ViewProps = {
  model: TableViewRow;
  headers: HeaderModel[];
  isHighlighted?: boolean;
  rowIndex: number;
};

const Row = ({ model, headers, isHighlighted, rowIndex }: ViewProps) => (
  <>
    <RowItem
      id={model.cid}
      data-id={model.id}
      data-parent={model.parent && model.parent.id}
      $isHighlighted={isHighlighted}
      $isDescriptionExpanded={model.expandDescription}
    >
      {headers.map((header, i) => (
        <Cell
          rowIndex={rowIndex}
          key={`${i}-${header.key}`}
          type={header.cellType}
          attribute={header.key}
          isExpandController={header.isExpandController}
          model={model}
          numberFormatOptions={header.numberFormatOptions}
        />
      ))}
    </RowItem>
    {model.expandDescription && (
      <RowItem
        $isHighlighted={isHighlighted}
        $isDescriptionExpanded
        $isDescription
      >
        <td colSpan={headers.length}>
          <Box paddingTop="small">
            <Text variant="text2Bold">Description:</Text>
            <RichTextReader content={model.markdownDescription} />
          </Box>
        </td>
      </RowItem>
    )}
  </>
);

export default Row;
