import type { ViewIds } from '@ardoq/api-types';
import { GraphNode } from '@ardoq/graph';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  IEdge,
  type IEnumerable,
  INode,
  Rect,
  type GraphComponent,
  type GraphInputMode,
  type IModelItem,
  type ItemClickedEventArgs,
} from '@ardoq/yfiles';
import { contextMenuData } from 'contextMenus/objectContextMenu';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { sum } from 'lodash';
import { getZoomToFitContextMenuItem } from 'contextMenus/utils';
import { isOpenViewInferenceWindowMenuItem } from 'traversals/viewInference/utils';
import {
  type DropdownOption,
  DropdownOptionType,
  type DropdownDivider,
} from '@ardoq/dropdown-menu';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { IconName } from '@ardoq/icons';
import { relationshipDiagramToggleCollapseGroup } from 'tabview/relationshipDiagrams/actions';
import { getExpandOrCollapseGroupMenuItem } from 'contextMenus/utils';
import { GraphEdge } from '@ardoq/graph';

type ContextMenuData = ReturnType<typeof contextMenuData>;
const ITEM_IDS_PROPERTY_KEYS = [
  'componentIds',
  'referenceIds',
  'workspaceIds',
] as const;
const isMultiselect = (contextMenu: ContextMenuData) =>
  sum(ITEM_IDS_PROPERTY_KEYS.map(key => contextMenu[key]?.length ?? 0)) > 1;
const isGroupExpanded = ({ collapsed }: CollapsibleGraphGroup) => !collapsed;
type CollapseOrExpandAllArgs = {
  shouldCollapse: boolean;
  graphGroups: IEnumerable<CollapsibleGraphGroup>;
  viewId: ViewIds;
};
const collapseOrExpandAll = ({
  shouldCollapse,
  graphGroups,
  viewId,
}: CollapseOrExpandAllArgs) =>
  graphGroups.forEach(({ collapsed, id }) => {
    if (collapsed === shouldCollapse) {
      // this group is already collapsed or expanded as we want it to be.
      return;
    }
    dispatchAction(
      relationshipDiagramToggleCollapseGroup({
        viewId,
        groupId: id,
      })
    );
  });
type GraphViewpointModeItemRightClickedListenerArgs = {
  graphComponent: GraphComponent;
  isViewpointMode: () => boolean;
  viewId: ViewIds;
};
const graphViewpointModeItemRightClickedListener =
  ({
    graphComponent,
    isViewpointMode,
    viewId,
  }: GraphViewpointModeItemRightClickedListenerArgs) =>
  (_: GraphInputMode, e: ItemClickedEventArgs<IModelItem>) => {
    const {
      item,
      location: { x, y },
    } = e;
    const { x: clientX, y: clientY } = graphComponent.toPageFromView(
      graphComponent.toViewCoordinates([x, y])
    );
    const position = { left: clientX, top: clientY };

    if (INode.isInstance(item)) {
      const {
        layout: {
          topLeft: { x: nodeX, y: nodeY },
          width,
          height,
        },
        tag: graphItem,
      } = item;
      if (graphItem instanceof GraphNode) {
        const contextMenu = contextMenuData(
          [graphItem.modelId],
          [],
          graphItem.modelId,
          {
            target: null,
            event: null,
            x: clientX,
            y: clientY,
            isViewpointMode: isViewpointMode(),
          }
        );
        const options = contextMenu.options ?? [];
        const testId = contextMenu.testId;
        if (!isMultiselect(contextMenu)) {
          const zoomFitMenuItems = [
            getZoomToFitContextMenuItem(() =>
              graphComponent.zoomToAnimated(
                new Rect(nodeX, nodeY, width, height)
              )
            ),
            {
              name: 'divider',
              type: DropdownOptionType.DIVIDER,
            },
          ];
          if (isOpenViewInferenceWindowMenuItem(options[0])) {
            // We always want to have the "Add new dataset with AI" button on the top of the list
            options.splice(2, 0, ...zoomFitMenuItems);
          } else {
            options.unshift(...zoomFitMenuItems);
          }
        }

        dispatchAction(
          setContextMenuState({ items: options, testId, position })
        );
        e.handled = true;
      }
      if (graphItem instanceof CollapsibleGraphGroup) {
        const { id, collapsed } = graphItem;
        const contextMenu = contextMenuData(
          [graphItem.modelId],
          [],
          graphItem.modelId,
          {
            target: null,
            event: null,
            x: clientX,
            y: clientY,
            isViewpointMode: isViewpointMode(),
          }
        );
        const options = contextMenu.options ?? [];
        const testId = contextMenu.testId;
        if (isMultiselect(contextMenu)) {
          const { graph, selection } = graphComponent;
          const isSelectionAllGroups = selection.every(
            selectionItem =>
              INode.isInstance(selectionItem) &&
              graph.isGroupNode(selectionItem)
          );
          if (isSelectionAllGroups) {
            const graphGroups = selection.map<CollapsibleGraphGroup>(
              selectionItem => selectionItem.tag
            );

            const shouldCollapse = graphGroups.some(isGroupExpanded);

            options.unshift(
              {
                type: DropdownOptionType.OPTION,
                iconName: shouldCollapse
                  ? IconName.COLLAPSE_ALL
                  : IconName.EXPAND_ALL,
                label: shouldCollapse ? 'Collapse groups' : 'Expand groups',
                onClick: () =>
                  collapseOrExpandAll({ shouldCollapse, graphGroups, viewId }),
              } satisfies DropdownOption,
              { type: DropdownOptionType.DIVIDER } satisfies DropdownDivider
            );
          }
        } else {
          options.unshift(
            getZoomToFitContextMenuItem(() =>
              graphComponent.zoomToAnimated(
                new Rect(nodeX, nodeY, width, height)
              )
            ),
            getExpandOrCollapseGroupMenuItem(!collapsed, () =>
              dispatchAction(
                relationshipDiagramToggleCollapseGroup({
                  viewId,
                  groupId: id,
                })
              )
            ),
            {
              name: 'divider',
              type: DropdownOptionType.DIVIDER,
            }
          );
        }

        dispatchAction(
          setContextMenuState({ items: options, testId, position })
        );
        e.handled = true;
      }
    } else if (IEdge.isInstance(item)) {
      const { tag: graphItem } = item;
      if (graphItem instanceof GraphEdge) {
        const contextMenu = contextMenuData(
          [],
          [graphItem.modelId],
          graphItem.modelId,
          {
            target: null,
            event: null,
            x: clientX,
            y: clientY,
            isViewpointMode: isViewpointMode(),
          }
        );
        const options = contextMenu.options ?? [];
        const testId = contextMenu.testId;
        dispatchAction(
          setContextMenuState({ items: options, testId, position })
        );
        e.handled = true;
      }
    }
  };

export default graphViewpointModeItemRightClickedListener;
