import { ReactNode, useState } from 'react';
import {
  CompletionBarBackground,
  CompletionBarContainer,
  CompletionBarFill,
} from './atoms';
import { Paragraph, TextColor } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';

const CompletionBar = ({
  name,
  textColor,
  fillColor,
  completeness,
  label,
  hoverLabel,
  onClick,
}: {
  name: string | ReactNode;
  textColor?: TextColor;
  fillColor?: string;
  completeness: number;
  label: string;
  hoverLabel: string;
  onClick?: () => void;
}) => {
  const [showHoverLabel, setShowHoverLabel] = useState<boolean>(false);
  const completionText = showHoverLabel ? hoverLabel : label;
  return (
    <CompletionBarContainer
      onMouseEnter={() => setShowHoverLabel(true)}
      onMouseLeave={() => setShowHoverLabel(false)}
      onClick={onClick}
    >
      <CompletionBarBackground />
      <CompletionBarFill $color={fillColor} $completeness={completeness} />
      <FlexBox
        paddingX="small"
        justify="space-between"
        width="full"
        paddingY="xxsmall"
      >
        <Paragraph variant="text2" color={textColor}>
          {name}
        </Paragraph>
        <Paragraph variant="text2" color={textColor}>
          {completionText}
        </Paragraph>
      </FlexBox>
    </CompletionBarContainer>
  );
};

export default CompletionBar;
