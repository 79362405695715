import { WorkspaceDigestType } from '@ardoq/api-types';

export const initialWorkspaceDigestType = (): WorkspaceDigestType => ({
  'top-contributors': [],
  name: '',
  changes: {
    components: {
      new: [],
      updated: [],
    },
    references: {
      new: [],
      updated: [],
    },
    tags: {
      new: [],
      updated: [],
    },
    unknown: {
      new: [],
      updated: [],
    },
  },
  stats: [],
});
