import { orderBy } from 'lodash';
import { formatDateOnly, formatDateTime } from '@ardoq/date-time';
import { IconSize } from '@ardoq/icons';
import {
  CellTypes,
  type ComponentProps,
  type HeaderModel,
  type TableViewRow,
  type UriData,
  type WorkspaceProps,
} from './types';
import {
  COMPONENT_PATH_LIST_ITEM_BULLET_SIZE,
  COMPONENT_PATH_LIST_ITEM_MAX_WIDTH,
  EXPAND_ICON_SIZE,
  REFERENCE_LIST_CELL_ITEM_COMMA_LEFT_MARGIN,
  REFERENCE_LIST_CELL_ITEM_COMMA_RIGHT_MARGIN,
  REFERENCE_LIST_CELL_ITEM_MAX_WIDTH,
  TABLE_CELL_BORDER_WIDTH,
  TABLE_CELL_HORIZONTAL_PADDING,
  TEXT_CELL_TEXT_MAX_WIDTH,
  UNSET_CHECKBOX_LABEL,
} from './consts';
import { format } from 'utils/numberUtils';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { getCellValue } from './utils';
import { getCurrentLocale } from '@ardoq/locale';
import { MeasureStyledText, MeasureStyledTextTag } from '@ardoq/dom-utils';

const CELL_PADDING = 2 * TABLE_CELL_HORIZONTAL_PADDING;
const CELL_BORDER = 2 * TABLE_CELL_BORDER_WIDTH;
const CELL_PADDING_AND_BORDER = CELL_PADDING + CELL_BORDER;

const PATH_ITEM_TEXT_MAX_WIDTH =
  COMPONENT_PATH_LIST_ITEM_MAX_WIDTH - COMPONENT_PATH_LIST_ITEM_BULLET_SIZE;

const textCellMeasurer = new MeasureStyledText();
const measureTextCellText = (text: string) =>
  textCellMeasurer.getTextWidth({ text });
const uriCellMeasurer = MeasureStyledTextTag.create('a');
const measureUriCellText = (text: string) =>
  uriCellMeasurer.getTextWidth({ text });

/** the checkbox right margin as defined in Cell.tsx under CheckboxStyledCell. copying the number in here because it's defined as a string (s8) there.  */
const CHECKBOX_RIGHT_MARGIN = 8;
const CHECKBOX_COLUMN_WIDTH =
  IconSize.REGULAR +
  CHECKBOX_RIGHT_MARGIN +
  measureTextCellText(UNSET_CHECKBOX_LABEL) +
  CELL_PADDING_AND_BORDER;

const linkedComponentCommaTotalWidth =
  Math.ceil(measureTextCellText(',')) +
  REFERENCE_LIST_CELL_ITEM_COMMA_LEFT_MARGIN +
  REFERENCE_LIST_CELL_ITEM_COMMA_RIGHT_MARGIN;

const getNameCellName = (value: any) =>
  typeof value === 'string' ? value : (value as ComponentProps).name;
/** @returns the width of the cell of the given type and value, including cell padding and borders */
const measureCell = (type: CellTypes, value: any, key: string) => {
  const locale = getCurrentLocale();

  switch (type) {
    case CellTypes.TEXT:
      return (
        Math.min(
          TEXT_CELL_TEXT_MAX_WIDTH,
          measureTextCellText(value as string)
        ) + CELL_PADDING_AND_BORDER
      );
    case CellTypes.NUMBER:
      return Math.min(
        TEXT_CELL_TEXT_MAX_WIDTH,
        (typeof value === 'number'
          ? measureTextCellText(
              format(
                value,
                fieldInterface.getNumberFormatByName(key) ?? undefined
              )
            )
          : 0) + CELL_PADDING_AND_BORDER
      );
    case CellTypes.NAME: {
      return (
        Math.min(
          TEXT_CELL_TEXT_MAX_WIDTH,
          measureTextCellText(getNameCellName(value))
        ) +
        CELL_PADDING_AND_BORDER +
        EXPAND_ICON_SIZE
      );
    }
    case CellTypes.PATH: {
      if (value === undefined || value === null || !Array.isArray(value)) {
        // this shouldn't happen. the error will be logged by tableViewWrapper.
        return 0;
      }
      const pathItemWidths = (value as (WorkspaceProps | ComponentProps)[]).map(
        props =>
          Math.min(PATH_ITEM_TEXT_MAX_WIDTH, measureTextCellText(props.name))
      );
      return (
        Math.max(...pathItemWidths) +
        CELL_PADDING_AND_BORDER +
        COMPONENT_PATH_LIST_ITEM_BULLET_SIZE
      );
    }
    case CellTypes.PARENT:
      return (
        Math.min(
          TEXT_CELL_TEXT_MAX_WIDTH,
          measureTextCellText((value as ComponentProps | undefined)?.name || '')
        ) + CELL_PADDING_AND_BORDER
      );
    case CellTypes.DATE_ONLY:
      return (
        measureTextCellText(formatDateOnly(value, locale)) +
        CELL_PADDING_AND_BORDER
      );
    case CellTypes.DATE_TIME:
      return (
        measureTextCellText(formatDateTime(value, locale)) +
        CELL_PADDING_AND_BORDER
      );
    case CellTypes.REFERENCE_LIST: {
      const referencedComponentNameWidths =
        (value as ComponentProps[] | undefined)?.map(linkedComponent =>
          measureTextCellText(linkedComponent.name)
        ) || [];
      const maxComponentNameWidth = Math.max(
        0,
        ...referencedComponentNameWidths
      );
      const widestItemIsLastItem =
        referencedComponentNameWidths.findIndex(
          width => width === maxComponentNameWidth
        ) ===
        referencedComponentNameWidths.length - 1;
      return (
        Math.min(
          REFERENCE_LIST_CELL_ITEM_MAX_WIDTH,
          maxComponentNameWidth +
            (widestItemIsLastItem ? 0 : linkedComponentCommaTotalWidth)
        ) + CELL_PADDING_AND_BORDER
      );
    }
    case CellTypes.URI:
      return (
        measureUriCellText((value as UriData).label) + CELL_PADDING_AND_BORDER
      );
    case CellTypes.CHECKBOX:
      return CHECKBOX_COLUMN_WIDTH;
    case CellTypes.TEXT_AREA:
      return (
        Math.min(
          TEXT_CELL_TEXT_MAX_WIDTH,
          value
            ? Math.max(
                ...(value as string)
                  .split('\n')
                  .map(line => measureTextCellText(line))
              )
            : 0
        ) + CELL_PADDING_AND_BORDER
      );
  }
  return TEXT_CELL_TEXT_MAX_WIDTH;
};

const widestPathItem = (cellValue: any) =>
  Array.isArray(cellValue)
    ? Math.max(
        ...(cellValue as (WorkspaceProps | ComponentProps)[]).map(
          ({ name }) => name.length
        )
      )
    : 0;

const cellLengthCalculators: Partial<
  Record<CellTypes, (cellValue: any) => number>
> = {
  [CellTypes.TEXT]: value => (value ? (value as string).length : 0),
  [CellTypes.PATH]: widestPathItem,
  [CellTypes.NUMBER]: cellValue =>
    typeof cellValue === 'number' ? `${cellValue}`.length : 0,
  [CellTypes.NAME]: cellValue => getNameCellName(cellValue).length,
  [CellTypes.PARENT]: cellValue =>
    (cellValue as ComponentProps | undefined)?.name?.length ?? 0,
  [CellTypes.DATE_ONLY]: cellValue =>
    formatDateOnly(cellValue, getCurrentLocale()).length,
  [CellTypes.DATE_TIME]: cellValue =>
    formatDateTime(cellValue, getCurrentLocale()).length,
};

/** @returns a sampling of the top 50 cell values which are likely to produce the widest cell. */
const getWidestValuesSample = (cellValues: any[], cellType: CellTypes) =>
  cellLengthCalculators[cellType]
    ? orderBy(cellValues, cellLengthCalculators[cellType], 'desc').slice(0, 50)
    : cellValues;

/** @returns the width of the widest cell in the column. */
export const getColumnBodyWidth = (
  { key, cellType }: HeaderModel,
  rows: TableViewRow[]
) =>
  Math.max(
    ...getWidestValuesSample(
      rows.map(row => getCellValue(row[key])),
      cellType
    ).map(cellValue => measureCell(cellType, cellValue, key))
  );
