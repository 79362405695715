import { ReferenceDirection, ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { modal } from '@ardoq/modal';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { viewHasLegend } from 'views/metaInfoTabs';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { SettingsBar, getFieldDropdownOptions } from '@ardoq/settings-bar';
import {
  type ExportConfigParams,
  type SettingsConfig,
  SettingsType,
  type DropdownConfig,
  type ToggleConfig,
} from '@ardoq/view-settings';
import {
  type DropdownHeader,
  type DropdownItem,
  type DropdownOption,
  DropdownOptionType,
  type DropdownSubmenu,
} from '@ardoq/dropdown-menu';
import { createDropdownSliderOptionManager } from 'viewSettings/settingsHelper';
import { updateViewSettings } from '@ardoq/view-settings';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { BUBBLE_SLIDER_OPTIONS } from './consts';
import QuadrantCustomizer from './QuadrantCustomizer';
import {
  BubbleChartGridMode,
  type BubbleChartQuadrantSettings,
  type BubbleChartViewProperties,
  type BubbleChartViewSettings,
  type FieldNameAndLabel,
  BubbleChartSelectedFieldType,
} from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { popoverRegistry } from '@ardoq/popovers';
import includeAllDescendantsDropdownMenu from 'tabview/includeAllDescendantsDropdownMenu';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { Features, hasFeature } from '@ardoq/features';

const VIEW_ID = ViewIds.BUBBLE;

type FieldInfoKey =
  | 'selectedFieldNameX'
  | 'selectedFieldNameY'
  | 'selectedFieldNameRadius';

type FieldPickerArgs = {
  fieldName: FieldInfoKey;
  additionalOptions?: DropdownItem[];
} & Omit<
  DropdownConfig,
  'id' | 'type' | 'options' | 'popoverId' | 'isDisabled'
>;

const FIELD_PICKERS_DISABLED_POPOVER_ID = 'fieldPickersDisabled';
popoverRegistry.set(
  FIELD_PICKERS_DISABLED_POPOVER_ID,
  () => 'There is no field in the selected field source.'
);

const referenceFieldNameOption = (
  { name, label }: FieldNameAndLabel,
  currentSettings: BubbleChartViewSettings,
  key: FieldInfoKey,
  type: BubbleChartSelectedFieldType
) => {
  const value = currentSettings[key];
  const isActive =
    typeof value === 'object' &&
    value?.fieldName === name &&
    value?.type === type;

  return {
    label,
    type: DropdownOptionType.OPTION,
    isActive,
    onClick: () =>
      dispatchAction(
        updateViewSettings({
          viewId: VIEW_ID,
          settings: {
            [key]: {
              fieldName: name,
              type: isActive ? undefined : type,
            },
          } satisfies Partial<BubbleChartViewSettings>,
          persistent: true,
        })
      ),
  };
};

const availableReferenceFieldOptions = (
  availableReferenceFields: FieldNameAndLabel[],
  availableReferencedComponentFields: FieldNameAndLabel[],
  currentSettings: BubbleChartViewSettings,
  key: FieldInfoKey
): DropdownItem[] => {
  const fieldsToOptions = (
    referenceFields: FieldNameAndLabel[],
    type: BubbleChartSelectedFieldType
  ) => {
    const locale = getCurrentLocale();
    return referenceFields
      .sort((a, b) => localeCompareNumericLowercase(a.label, b.label, locale))
      .map(referenceField =>
        referenceFieldNameOption(referenceField, currentSettings, key, type)
      );
  };
  return [
    availableReferenceFields.length &&
      ({
        type: DropdownOptionType.HEADER,
        label: 'Reference fields',
      } satisfies DropdownHeader),
    ...fieldsToOptions(
      availableReferenceFields,
      BubbleChartSelectedFieldType.REFERENCE
    ),
    availableReferenceFields.length &&
      availableReferencedComponentFields.length && {
        type: DropdownOptionType.DIVIDER,
      },
    availableReferencedComponentFields.length &&
      ({
        type: DropdownOptionType.HEADER,
        label: 'Referenced Component fields',
      } satisfies DropdownHeader),
    ...fieldsToOptions(
      availableReferencedComponentFields,
      BubbleChartSelectedFieldType.REFERENCED_COMPONENT
    ),
  ].filter(ExcludeFalsy);
};

const referenceTypeNameOption = (
  referenceTypeName: string,
  direction: ReferenceDirection,
  isActive: boolean
): DropdownOption => ({
  name: referenceTypeName,
  type: DropdownOptionType.OPTION,
  label: referenceTypeName,
  isActive,
  onClick: () =>
    dispatchAction(
      updateViewSettings({
        viewId: ViewIds.BUBBLE,
        settings: {
          fieldSource: 'byReference',
          selectedReferenceDirection: direction,
          selectedReferenceTypeName: referenceTypeName,
        } satisfies Partial<BubbleChartViewSettings>,
        persistent: true,
      })
    ),
});

const sortedFieldNameOptions = (
  ...args: Parameters<typeof getFieldDropdownOptions>
) => {
  const locale = getCurrentLocale();
  return getFieldDropdownOptions(...args).sort((a, b) =>
    localeCompareNumericLowercase(a.name ?? '', b.name ?? '', locale)
  );
};

const showBubblesWithZeroValueConfig = ({
  showBubblesWithZeroValue,
}: BubbleChartViewSettings) => {
  const isActive =
    showBubblesWithZeroValue === undefined || showBubblesWithZeroValue; // an undefined value indicates the user has not set this property yet, so we default it to true.
  return {
    id: 'showBubblesWithZeroValue',
    label: isActive ? 'Hide size 0 bubbles' : 'Show size 0 bubbles',
    type: SettingsType.TOGGLE,
    iconName: IconName.NOT_INTERESTED,
    isActive,
    onViewSettingsUpdate,
  } satisfies ToggleConfig;
};

type LeftMenuProps = Pick<
  BubbleChartViewProperties,
  | 'numericFields'
  | 'availableIncomingReferenceTypeNames'
  | 'availableOutgoingReferenceTypeNames'
  | 'availableReferenceFields'
  | 'availableReferencedComponentFields'
>;
type GetLeftMenuArgs = LeftMenuProps &
  Pick<BubbleChartViewProperties, 'viewSettings'>;

const getLeftMenu = ({
  numericFields,
  viewSettings,
  availableIncomingReferenceTypeNames,
  availableOutgoingReferenceTypeNames,
  availableReferenceFields,
  availableReferencedComponentFields,
}: GetLeftMenuArgs): SettingsConfig[] => {
  const { fieldSource, selectedReferenceDirection, selectedReferenceTypeName } =
    viewSettings;
  const isByReference = fieldSource === 'byReference';
  const getReferenceTypeNameOption =
    (referenceDirection: ReferenceDirection) => (referenceTypeName: string) =>
      referenceTypeNameOption(
        referenceTypeName,
        referenceDirection,
        isByReference &&
          selectedReferenceDirection === referenceDirection &&
          selectedReferenceTypeName === referenceTypeName
      );

  const fieldPickersDisabled = isByReference
    ? availableReferenceFields.length +
        availableReferencedComponentFields.length ===
      0
    : numericFields.length === 0;

  const fieldPicker = ({
    fieldName,
    additionalOptions = [],
    ...rest
  }: FieldPickerArgs): DropdownConfig => ({
    id: fieldName,
    options: [
      ...(isByReference
        ? availableReferenceFieldOptions(
            availableReferenceFields,
            availableReferencedComponentFields,
            viewSettings,
            fieldName
          )
        : sortedFieldNameOptions(
            VIEW_ID,
            numericFields,
            fieldName,
            name => viewSettings[fieldName] === name,
            onViewSettingsUpdate
          )),
      ...additionalOptions,
    ],
    type: SettingsType.DROPDOWN,
    isDisabled: fieldPickersDisabled,
    popoverId: fieldPickersDisabled
      ? FIELD_PICKERS_DISABLED_POPOVER_ID
      : undefined,
    ...rest,
  });

  const fieldSourceByReferenceIsDisabled =
    availableIncomingReferenceTypeNames.length +
      availableOutgoingReferenceTypeNames.length ===
    0;

  return [
    {
      id: 'selectFieldSource',
      type: SettingsType.DROPDOWN,
      label: 'Select field source',
      iconName: IconName.SETTINGS,
      options: [
        {
          name: 'fieldSourceByComponents',
          type: DropdownOptionType.OPTION,
          label: 'Component fields only',
          isActive: viewSettings.fieldSource === 'byComponent',
          onClick: () =>
            dispatchAction(
              updateViewSettings({
                viewId: ViewIds.BUBBLE,
                settings: { fieldSource: 'byComponent' },
                persistent: true,
              })
            ),
        } satisfies DropdownOption,
        {
          name: 'fieldSourceByReference',
          type: DropdownOptionType.SUBMENU,
          label: 'Reference fields',
          isActive: viewSettings.fieldSource === 'byReference',
          isDisabled: fieldSourceByReferenceIsDisabled,
          description:
            'This will also include component fields linked to the selected reference type',
          popoverProps: fieldSourceByReferenceIsDisabled
            ? {
                content:
                  'There is no reference in the selected context components or workspace.',
              }
            : undefined,
          maxDropdownSize: DropdownSize.M,
          options: [
            availableIncomingReferenceTypeNames.length
              ? {
                  type: DropdownOptionType.HEADER,
                  label: 'Incoming',
                }
              : null,
            ...availableIncomingReferenceTypeNames.map(
              getReferenceTypeNameOption(ReferenceDirection.INCOMING)
            ),
            availableIncomingReferenceTypeNames.length &&
            availableOutgoingReferenceTypeNames.length
              ? { id: 'divider', type: DropdownOptionType.DIVIDER }
              : null,
            availableOutgoingReferenceTypeNames.length
              ? {
                  type: DropdownOptionType.HEADER,
                  label: 'Outgoing',
                }
              : null,
            ...availableOutgoingReferenceTypeNames.map(
              getReferenceTypeNameOption(ReferenceDirection.OUTGOING)
            ),
          ].filter(ExcludeFalsy),
        } satisfies DropdownSubmenu,
      ],
    } satisfies DropdownConfig,
    fieldPicker({
      fieldName: 'selectedFieldNameX',
      label: 'Select X-axis field',
      iconName: IconName.ARROW_RIGHT_ALT,
    }),
    fieldPicker({
      fieldName: 'selectedFieldNameY',
      label: 'Select Y-axis field',
      iconName: IconName.ARROW_RIGHT_ALT,
      className: 'rotate-270',
    }),
    fieldPicker({
      fieldName: 'selectedFieldNameRadius',
      label: 'Select Size field',
      iconName: IconName.BUBBLE_CHART,
      additionalOptions: [
        { type: DropdownOptionType.DIVIDER },
        getSliderOption({
          name: 'bubbleAreaScaleFactor',
          label: 'Bubble size',
          value: viewSettings.bubbleAreaScaleFactor,
          ...BUBBLE_SLIDER_OPTIONS,
          type: DropdownOptionType.SLIDER,
          hideValue: true,
        }),
      ],
    }),
    {
      id: 'showBackground',
      label: 'Select background',
      iconName: IconName.MENU,
      options: getGridModeDropdownOptions(viewSettings),
      type: SettingsType.DROPDOWN,
    } satisfies DropdownConfig,
    includeAllDescendantsDropdownMenu(viewSettings),
    hasFeature(Features.SIZE_ZERO_BUBBLES) &&
      showBubblesWithZeroValueConfig(viewSettings),
  ].filter(ExcludeFalsy);
};

const getSliderOption = createDropdownSliderOptionManager(
  VIEW_ID,
  new Map(),
  onViewSettingsUpdate
);

const showCustomQuadrantSettingsModal = async (
  viewSettings: BubbleChartViewSettings
) => {
  const result = await modal(resolve => (
    <QuadrantCustomizer
      close={resolve}
      initialValue={viewSettings.customQuadrantSettings}
    />
  ));
  if (!result) {
    return;
  }
  const settings: Pick<BubbleChartViewSettings, 'customQuadrantSettings'> = {
    customQuadrantSettings: result as BubbleChartQuadrantSettings,
  };
  dispatchAction(
    updateViewSettings({ viewId: VIEW_ID, settings, persistent: true })
  );
};

const getGridModeDropdownOptions = (viewSettings: BubbleChartViewSettings) => {
  const result = getFieldDropdownOptions(
    VIEW_ID,
    [
      { name: BubbleChartGridMode.NONE, label: 'None' },
      { name: BubbleChartGridMode.GRID, label: 'Grid' },
      { name: BubbleChartGridMode.TIME, label: 'TIME' },
      {
        name: BubbleChartGridMode.CUSTOM,
        label: 'Custom',
        onClick: () => {
          dispatchAction(
            updateViewSettings({
              viewId: VIEW_ID,
              settings: { background: BubbleChartGridMode.CUSTOM },
              persistent: true,
            })
          );
          showCustomQuadrantSettingsModal(viewSettings);
        },
      },
    ],
    'background',
    name =>
      viewSettings.background === name ||
      (name === BubbleChartGridMode.NONE && !viewSettings.background),
    onViewSettingsUpdate
  );
  return result;
};

type BubbleChartSettingsBarProps = LeftMenuProps & {
  currentSettings: BubbleChartViewSettings;
  showLeftSettingsSection?: boolean;
  showRightSettingsSection?: boolean;
  exports: ExportConfigParams;
  legendOnClick: () => void;
};

const BubbleChartSettingsBar = ({
  numericFields,
  availableIncomingReferenceTypeNames,
  availableOutgoingReferenceTypeNames,
  availableReferenceFields,
  availableReferencedComponentFields,
  currentSettings: viewSettings,
  showLeftSettingsSection,
  showRightSettingsSection,
  exports,
  legendOnClick,
}: BubbleChartSettingsBarProps) => {
  return (
    <div className="menuContainer">
      <SettingsBar
        viewId={VIEW_ID}
        leftMenu={getLeftMenu({
          numericFields,
          viewSettings,
          availableIncomingReferenceTypeNames,
          availableOutgoingReferenceTypeNames,
          availableReferenceFields,
          availableReferencedComponentFields,
        })}
        showLeftSettingsSection={showLeftSettingsSection}
        showRightSettingsSection={showRightSettingsSection}
        rightMenu={getRightMenuConfig({
          viewId: VIEW_ID,
          viewstate: viewSettings,
          exports,
          withLegend: viewHasLegend(VIEW_ID),
          knowledgeBaseLink: KnowledgeBaseLink.BUBBLE_CHART,
          legendOnClick,
          onViewSettingsUpdate,
        })}
      />
    </div>
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<BubbleChartViewSettings>(VIEW_ID);

export default connect(BubbleChartSettingsBar, viewSettings$);
