import { NumericRange } from '@ardoq/graph';

const BUBBLE_LEGEND_RADIUS_RANGE: NumericRange = [10, 65];
export const MAX_BUBBLE_RADIUS = BUBBLE_LEGEND_RADIUS_RANGE[1];
export const BUBBLE_LEGEND_STROKE_WIDTH = 1;
export const LEGEND_TEXT_PX_SIZE = 14;
export const PADDING_TOP_BUBBLES = LEGEND_TEXT_PX_SIZE / 2;
// If min. bubble is very small, almost the whole height of the label
// will be at the bottom of the svg
export const PADDING_BOTTOM_BUBBLES = LEGEND_TEXT_PX_SIZE;
export const PADDING_RIGHT_BUBBLES = 50;
export const MIN_PIXELS_BETWEEN_BUBBLE_RADII = 15;
export const SPACE_FOR_ZERO = 25;
export const DATA_LABEL_LEFT_PADDING = 5;
export const MAX_INTERMEDIATE_STEPS = 2;
