import { map, shareReplay, startWith } from 'rxjs/operators';
import { ActionWithPayload, action$, ofType } from '@ardoq/rxbeach';
import {
  notifyComponentChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
} from 'streams/fields/FieldActions';
import {
  notifyGroupingAdded,
  notifyGroupingRemoved,
  notifyGroupingSynced,
  notifyGroupingUpdated,
} from 'streams/grouping/GroupingActions';
import {
  ReferenceIdsPayload,
  notifyReferencesAdded,
  notifyReferencesRemoved,
  notifyReferencesUpdated,
} from 'streams/references/ReferenceActions';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { viewLoaded } from 'streams/views/mainContent/actions';
import References from 'collections/references';

const referenceActions = [
  notifyReferencesUpdated.type,
  notifyReferencesAdded.type,
  notifyReferencesRemoved.type,
];

const contextChangeActions = [
  notifyComponentChanged.type,
  notifyWorkspaceChanged.type,
];

const relevantAction$ = action$.pipe(
  ofType(
    notifyComponentChanged,
    notifyWorkspaceChanged,
    notifyComponentsAdded,
    notifyComponentsRemoved,
    notifyReferencesUpdated,
    notifyReferencesAdded,
    notifyReferencesRemoved,
    notifyFiltersChanged,
    notifyFieldAdded,
    notifyFieldRemoved,
    notifyGroupingAdded,
    notifyGroupingUpdated,
    notifyGroupingRemoved,
    notifyGroupingSynced,
    viewLoaded
  )
);

const layoutUpdate$ = relevantAction$.pipe(
  map(action => {
    const referenceOrderChanged =
      referenceActions.includes(action.type) &&
      (
        action as ActionWithPayload<ReferenceIdsPayload>
      ).payload.referenceIds.some(referenceId => {
        const reference = References.collection.get(referenceId);
        return reference && reference.changed.order;
      });
    const isContextChange = contextChangeActions.includes(action.type);
    return {
      referenceOrderChanged,
      preserveViewport:
        referenceActions.includes(action.type) ||
        action.type === notifyComponentsUpdated.type,
      isContextChange,
    };
  }),
  startWith({
    referenceOrderChanged: false,
    preserveViewport: false,
    isContextChange: false,
  }),
  shareReplay(1)
);
export default layoutUpdate$;
