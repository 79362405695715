import { reducedStream, reducer } from '@ardoq/rxbeach';
import { setIsProteanEditModeEnabled } from './actions';
import { SetIsProteanEditModeEnabledPayload } from './actions';
import {
  initialProteanEditModeState,
  isProteanEditModeEnabledReducer,
} from './reducers';

/**
 * For instance we need only isEnabled property here, but should build out this state as
 * we add edit mode capabilities hence the generic file name.
 */

const isProteanEditModeEnabled$ =
  reducedStream<SetIsProteanEditModeEnabledPayload>(
    `protean-diagram-set-edit-mode-enabled`,
    initialProteanEditModeState,
    [reducer(setIsProteanEditModeEnabled, isProteanEditModeEnabledReducer)]
  );

export default isProteanEditModeEnabled$;
