import { isPlainObject } from 'lodash';
import { SortByFn, SortById } from '@ardoq/pagination';
import { DatasourceTable } from '@ardoq/table';
import { GremlinMapResult, GremlinResultType } from '../types';
import { formatValue, showDetails } from '../utils';
import styled from 'styled-components';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SortOrder } from '@ardoq/api-types';

const Pre = styled.pre`
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
`;
interface GremlinResultsTableMapProps {
  results: GremlinMapResult[];
  printable?: boolean;
  sortBy: SortByFn;
  sortById: SortById;
  sortOrder?: SortOrder;
  columnKeys?: string[];
}

const GremlinResultsTableMap = (props: GremlinResultsTableMapProps) => {
  return (
    <DatasourceTable
      dataSource={props.results}
      data-intercom-target="gremlin results table"
      columns={[
        ...(props.columnKeys || []).map(key => ({
          title: key,
          dataIndex: key,
          valueRender: (x: any) =>
            isPlainObject(x) ? (
              <Pre>{JSON.stringify(x, null, 2)} </Pre>
            ) : (
              formatValue(x)
            ),
          onHeaderClick: () => props.sortBy(key),
          sortOrder: props.sortById === key && props.sortOrder,
        })),
        !props.printable && {
          headerStyle: { width: '150px' },
          valueRender: (_: any, result: GremlinMapResult) => (
            <a onClick={() => showDetails(result, GremlinResultType.MAP)}>
              Show details
            </a>
          ),
        },
      ].filter(ExcludeFalsy)}
      renderEmptyTable={{ title: 'No results found' }}
    />
  );
};

export default GremlinResultsTableMap;
