import { INode } from '@ardoq/yfiles';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
} from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';

/** @returns the y-position of the top of the shape in modernized block diagram, in order to vertically center the shape in the available space above the top of the main label. */
const leafNodeShapeY = (
  { labels, layout: { y: nodeY } }: INode,
  shapeHeight: number,
  isCollapsedComponentGroup: boolean
) => {
  const mainLabelLayout = labels.at(0)?.layout;
  const mainLabelTop = mainLabelLayout?.bounds.y ?? 0;
  const shapeAreaTop = isCollapsedComponentGroup
    ? MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT +
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
    : 0;
  const shapeCenterY =
    shapeAreaTop + (mainLabelTop - (nodeY + shapeAreaTop)) / 2;
  return shapeCenterY - shapeHeight / 2;
};
export default leafNodeShapeY;
