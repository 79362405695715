import { ArdoqId } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  componentInterface,
  componentOrDescendantsAreIncludedInContextByFilter,
} from '@ardoq/component-interface';
import { GraphModelShape } from '@ardoq/data-model';
import {
  GraphGroup,
  GraphInterface,
  GraphNode,
  RawGraphItem,
  TraversalAndGroupingResult,
  LoadedGraphWithViewpointMode,
} from '@ardoq/graph';
import {
  TimelineViewGraphHierarchy,
  getViewGraphHierarchyFromTraversal,
} from '@ardoq/timeline2024';
import { createViewGraphHierarchy } from '@ardoq/timeline2024';
import type { HierarchySortComparator } from '@ardoq/timeline2024';
import { uniq } from 'lodash';
import getGraphShapeForTraversalHierarchy from 'modelInterface/graph/graphShapeForTraversalHierarchy';

type ComponentRawGraphItem = Omit<RawGraphItem, 'modelId'> & {
  modelId: string;
};

const isItemComponent = (item: RawGraphItem): item is ComponentRawGraphItem =>
  Boolean(item.modelId && componentInterface.isComponent(item.modelId));

export const getComponentGroupRawItems = (
  groupMap: Map<string, RawGraphItem>
) => Array.from(groupMap.values()).filter(isItemComponent);

export const rootGroupsAndStartSetToViewGraphHierarchy = (
  rootGroups: RawGraphItem[],
  startSet: string[],
  componentMap: Map<string, RawGraphItem>,
  hierarchySortComparator: HierarchySortComparator
) => {
  const rootGroupModelIds = new Set(
    rootGroups.map(({ modelId }) => modelId).filter(ExcludeFalsy)
  );

  const ungroupedStartSetNodes = startSet
    .map(entityId => componentMap.get(entityId))
    .filter((rawItem): rawItem is RawGraphItem =>
      Boolean(
        rawItem &&
          !rawItem.parentId &&
          rawItem.modelId &&
          !rootGroupModelIds.has(rawItem.modelId)
      )
    );

  const commonHierarchy = rootGroups.concat(ungroupedStartSetNodes);

  const sortedRootNodes = commonHierarchy.toSorted(hierarchySortComparator);

  const { viewGraphHierarchy } = sortedRootNodes.reduce(
    createViewGraphHierarchy(),
    {
      viewGraphHierarchy: new Map(),
      usedNodes: new Map(),
      hierarchySortComparator,
    }
  );

  return viewGraphHierarchy;
};

type GetTimelineViewGraphHierarchyForViewpointModeArgs = {
  graphInterface: GraphInterface;
  loadedGraph: LoadedGraphWithViewpointMode;
  graph: GraphModelShape;
  workspacesIds: string[];
  hasUserDefinedGroups: boolean;
  contextComponentId: string;
  hierarchySortComparator: HierarchySortComparator;
  traversalAndGroupingResult: TraversalAndGroupingResult;
};
/**
 * Default behavior is to group by traversal steps and the traversal root nodes should be grouped by parent-all.
 *
 * Currently (not final), if user defines other grouping rules, it would break the traversal hierarchy =>
 * We create the view graph hierarchy either from the user defined grouping OR the the loadedGraph =>
 * if hasUserDefinedGrouping - use it, otherwise getViewGraphHierarchyFromTraversal.
 */
export const getTimelineViewGraphHierarchyForViewpointMode = ({
  loadedGraph,
  graph: graphModel,
  workspacesIds,
  hasUserDefinedGroups,
  hierarchySortComparator,
  graphInterface,
  traversalAndGroupingResult,
}: GetTimelineViewGraphHierarchyForViewpointModeArgs) => {
  const { rootGroups, componentMap, groupMap } = traversalAndGroupingResult;

  if (hasUserDefinedGroups) {
    const allComponentIds = loadedGraph.scopeComponentIds;

    return {
      viewGraphHierarchy: rootGroupsAndStartSetToViewGraphHierarchy(
        rootGroups,
        allComponentIds,
        componentMap,
        hierarchySortComparator
      ),
      allComponentIds,
    };
  }

  const { graphAsHierarchy, rootItems } = getGraphShapeForTraversalHierarchy({
    loadedGraph,
    graphModel,
    workspacesIds,
    graphInterface,
    traversalAndGroupingResult,
  });

  // root nodes are grouped by parent-all, so we need to add them to the allComponentIds
  // otherwise parent-all groups won't have component data in the view collection.

  const componentGroupsRawGraphItems = getComponentGroupRawItems(groupMap);

  const viewGraphHierarchy = getViewGraphHierarchyFromTraversal({
    graphAsHierarchy,
    rootItems,
    hierarchySortComparator,
  });

  const componentGroupModelIds = componentGroupsRawGraphItems.map(
    ({ modelId }) => modelId
  );

  return {
    viewGraphHierarchy,
    allComponentIds: uniq(
      loadedGraph.scopeComponentIds.concat(componentGroupModelIds)
    ),
  };
};

const createOrGetGraphNodeForComponent = (
  entityId: ArdoqId,
  usedNodesByEntityId: Map<ArdoqId, GraphNode>
) => {
  const existingGraphNode = usedNodesByEntityId.get(entityId);
  if (existingGraphNode) {
    return existingGraphNode;
  }
  const newGraphNode = GraphNode.create(entityId);
  usedNodesByEntityId.set(entityId, newGraphNode);
  return newGraphNode;
};

type UngroupedComponentsToViewGraphHierarchyReducerState = {
  viewGraphHierarchy: TimelineViewGraphHierarchy;
  componentMap: Map<string, RawGraphItem>;
  allComponentIds?: string[];
  usedGraphNodes?: Map<ArdoqId, GraphNode>;
  groupedNodes?: Map<string, GraphNode | GraphGroup>;
};
/**
 * This function is called to reduce the startSet of components when
 * - we are in workspace mode
 * - there is no grouping applied
 *
 * This means, the same entity can not have 2 graph nodes.
 */
export const ungroupedComponentsToViewGraphHierarchyReducer =
  (parentGraphNodeId?: string) =>
  (
    state: UngroupedComponentsToViewGraphHierarchyReducerState,
    entityId: ArdoqId
  ): UngroupedComponentsToViewGraphHierarchyReducerState => {
    const {
      viewGraphHierarchy,
      allComponentIds,
      usedGraphNodes = new Map<ArdoqId, GraphNode>(),
      groupedNodes,
      componentMap,
    } = state;

    if (groupedNodes?.has(entityId)) {
      return state;
    }

    /**
     * We are reusing nodes because the child nodes are created from the parent node,
     * so that we can add the viewGraphHierarchy entry with graph items and children.
     */
    const graphNode = createOrGetGraphNodeForComponent(
      entityId,
      usedGraphNodes
    );
    allComponentIds?.push(entityId);

    const childrenIds = componentInterface
      .getChildren(entityId)
      .filter(componentOrDescendantsAreIncludedInContextByFilter);
    const childNodes = childrenIds.map(childId =>
      createOrGetGraphNodeForComponent(childId, usedGraphNodes)
    );

    const rawGraphItem: RawGraphItem = componentMap.get(entityId) || {
      id: entityId,
      modelId: entityId,
    };

    viewGraphHierarchy.set(graphNode.id, {
      children: childNodes,
      parentGraphNodeId,
      graphItem: graphNode,
      rawGraphItem: rawGraphItem,
    });

    return childrenIds?.length
      ? childrenIds.reduce(
          ungroupedComponentsToViewGraphHierarchyReducer(graphNode.id),
          {
            viewGraphHierarchy,
            componentMap,
            allComponentIds,
            usedGraphNodes,
            groupedNodes,
          }
        )
      : state;
  };
