import Components from 'collections/components';
import { ContextShape } from '@ardoq/data-model';
import { componentInterface } from '@ardoq/component-interface';

const getRootComponents = (workspaceId: string) =>
  Components.collection.filter(
    component =>
      !component.getParent() && component.get('rootWorkspace') === workspaceId
  );
/**
 * Get components to be included in this view
 *
 * RelevantComponents in this case is calculated in the following way:
 * 1) If we're looking at a component
 *    a) If the component has children, return its children
 *    b) If the component does not have children, look at the parent
 *       i)  If the parent is a component, return its children
 *       ii) If the parent is a workspace, return workspace components, subject
 *           to filters
 * 2) If we're looking at a workspace
 *    a) Return workspace components
 *
 * Simplified, where we don't differentiate between workspaces and components,
 * this can be expressed as:
 * If a workspace or component has children, show them, otherwise show siblings.
 *
 * @param {*} flattenWorkspaces true to return all components in a workspace, false to return root components only.
 * @returns {{hasComponentsAvailable: boolean; relevantComponents: ComponentBackboneModel[]}}
 * hasComponentsAvailable indicates if there were components available before filtering
 */
export const relevantComponentsFromContextShape = (
  context: ContextShape,
  flattenWorkspaces = true
) => {
  let hasComponentsAvailable = false;
  if (context.componentId) {
    // already in a component
    hasComponentsAvailable = true;
    const children = componentInterface
      .getChildren(context.componentId)
      .filter(componentInterface.isIncludedInContextByFilter);

    const contextComponent = Components.collection.get(context.componentId)!;

    if (children.length > 0) {
      const relevantComponents = children.map(
        childId => Components.collection.get(childId)!
      );
      relevantComponents.unshift(contextComponent);

      return {
        relevantComponents,
        hasComponentsAvailable,
      };
    }
    return {
      hasComponentsAvailable,
      relevantComponents: [contextComponent],
    };
  }

  if (context.workspaceId) {
    const workspaceComponents = flattenWorkspaces
      ? Components.collection.getFlatHierarchy(
          getRootComponents(context.workspaceId)
        )
      : getRootComponents(context.workspaceId);

    return {
      hasComponentsAvailable: !!workspaceComponents.length,
      relevantComponents: workspaceComponents,
    };
  }
  return {
    hasComponentsAvailable,
    relevantComponents: [],
  };
};
