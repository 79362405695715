import {
  dispatchAction,
  reducedStream,
  reducer,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import {
  clearLoadedState,
  createdItemsProcessed,
  CreatedItemsProcessedPayload,
  deleteLoadedState,
  itemsCreated,
  loadedStateUpdated,
  LoadedStateUpdatedPayload,
  registerLoadedState,
  setTraversalId,
  SetTraversalIdPayload,
  toggleCollapsedPathActive,
  toggleLoadedStateVisibility,
  ToggleLoadedStateVisibilityPayload,
} from './actions';
import { loadedStateOperations } from './loadedStateOperations';
import { distinctUntilChanged, tap } from 'rxjs';
import {
  APIComponentAttributes,
  ArdoqId,
  CreatedItemsLoadedState,
  LoadedState,
  LoadedStateHash,
} from '@ardoq/api-types';
import { removedComponentAndReferenceIds$ } from './removedComponentsAndReferences$';
import { rebuildTraversalAndSearchState } from 'streams/traversals/actions';
import { changedParents$ } from './changedParents$';

const defaultState: LoadedState[] = [];

export const loadedState$ = toPersistentStream<LoadedState[]>(
  'loadedState$',
  reducedStream<LoadedState[]>('loadedState$', defaultState, [
    reducer<LoadedState[], LoadedState[]>(
      registerLoadedState,
      loadedStateOperations.reduceRegisterLoadedState
    ),
    reducer<LoadedState[], void>(
      clearLoadedState,
      loadedStateOperations.reduceClearLoadedState
    ),
    reducer<LoadedState[], SetTraversalIdPayload>(
      setTraversalId,
      loadedStateOperations.reduceSetTraversalId
    ),
    reducer<LoadedState[], ToggleLoadedStateVisibilityPayload>(
      toggleLoadedStateVisibility,
      loadedStateOperations.reduceToggleLoadedStateVisibility
    ),
    reducer<LoadedState[], LoadedStateHash>(
      deleteLoadedState,
      loadedStateOperations.reduceDeleteLoadedState
    ),
    reducer<LoadedState[], LoadedStateUpdatedPayload>(
      loadedStateUpdated,
      loadedStateOperations.replaceExistingLoadedState
    ),
    reducer<LoadedState[], CreatedItemsLoadedState>(
      itemsCreated,
      loadedStateOperations.reduceUpdateOrCreateLoadedStateOnItemCreated
    ),
    reducer<LoadedState[], CreatedItemsProcessedPayload>(
      createdItemsProcessed,
      loadedStateOperations.createdItemsProcessed
    ),
    reducer(
      toggleCollapsedPathActive,
      loadedStateOperations.toggleCollapsedPathActive
    ),
    streamReducer<LoadedState[], Set<ArdoqId>>(
      removedComponentAndReferenceIds$,
      loadedStateOperations.handleRemovedComponentsAndReferences
    ),
    streamReducer<LoadedState[], APIComponentAttributes[]>(
      changedParents$,
      loadedStateOperations.handleChangedParents
    ),
  ]).pipe(
    // The `handleRemovedComponentsAndReferences` reducer just returns the
    // current state if there is nothing to remove, e.g. if the user just
    // closed a block. The `distinctUntilChanged` catches these unnecessary
    // updates.
    distinctUntilChanged(),
    tap(loadedStates => {
      dispatchAction(rebuildTraversalAndSearchState({ loadedStates }));
      window.ARDOQ.loadedState = loadedStates;
    })
  ),
  []
);
