import VisualDiffSwitcher, { Option } from './VisualDiffSwitcher';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import {
  trackClickCloseVisualDiff,
  trackToggleVisualDiffMode,
} from 'scope/tracking';
import { DiffMode, ViewIds } from '@ardoq/api-types';
import { isLoadingScope } from 'scope/scopeDiff';
import { closeVisualDiff } from 'appContainer/MainAppModule/MainAppModuleSidebar/NavigatorTopBar/utils';
import { HasViewId } from '@ardoq/graph';

const getActiveDiffModeSetter = (viewId: ViewIds, diffMode: DiffMode) => () => {
  dispatchAction(
    updateViewSettings({
      viewId,
      settings: {
        activeDiffMode: diffMode,
      },
      persistent: true,
    })
  );
  trackToggleVisualDiffMode(diffMode);
};

const diffModeToOption = new Map([
  [DiffMode.SCENARIO, Option.RIGHT],
  [DiffMode.DIFF, Option.CENTER],
  [DiffMode.MAIN, Option.LEFT],
]);

const getActiveOption = (branchName: DiffMode) =>
  diffModeToOption.get(branchName) ?? null;

const activeDiffModes = (viewId: ViewIds): Record<Option, VoidFunction> => ({
  [Option.LEFT]: getActiveDiffModeSetter(viewId, DiffMode.MAIN),
  [Option.CENTER]: getActiveDiffModeSetter(viewId, DiffMode.DIFF),
  [Option.RIGHT]: getActiveDiffModeSetter(viewId, DiffMode.SCENARIO),
});
type BlockDiagramVisualDiffSwitcherProperties = HasViewId & {
  scenarioName?: string;
  activeDiffMode: DiffMode;
};
const BlockDiagramVisualDiffSwitcher = ({
  viewId,
  scenarioName,
  activeDiffMode,
}: BlockDiagramVisualDiffSwitcherProperties) => (
  <VisualDiffSwitcher
    onCloseDiffMode={() => {
      closeVisualDiff();
      trackClickCloseVisualDiff('switcher');
    }}
    scenarioName={scenarioName}
    disabled={isLoadingScope()}
    activeOption={getActiveOption(activeDiffMode)}
    optionCallbacks={activeDiffModes(viewId)}
  />
);
export default BlockDiagramVisualDiffSwitcher;
