import styled from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { APIFieldAttributes } from '@ardoq/api-types';
import {
  Header,
  HeaderAndTableContainer,
  TableRow,
  TableRowContainer,
} from 'tabview/pagesView/commonUIElements';
import Tags from 'tabview/pagesView/Tags';
import FieldLabel from 'tabview/pagesView/FieldLabel';
import {
  formatFieldContent,
  getFieldValues,
} from 'tabview/pagesView/FieldUtils';
import { colors } from '@ardoq/design-tokens';
import { TAGS_FIELD_NAME } from '@ardoq/data-model';
import { FlexBox } from '@ardoq/layout';

const FieldContent = styled.div`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  width: 70%;
`;
interface FieldsProps {
  fields: APIFieldAttributes[];
  defaultFields: Pick<APIFieldAttributes, 'name' | '_id' | 'label' | 'type'>[];
  modelId: string;
  showHeader: boolean;
  hideEmptyFields: boolean;
  isLocalDateFormat?: boolean;
}
const emptyValues = [null, undefined, ''];

const Fields = ({
  modelId,
  defaultFields,
  fields,
  showHeader,
  hideEmptyFields,
  isLocalDateFormat,
}: FieldsProps) => {
  const fieldValues = getFieldValues(modelId, [...defaultFields, ...fields]);
  const fieldsToShow = [...defaultFields, ...fields].filter(
    field => !hideEmptyFields || !emptyValues.includes(fieldValues[field.name])
  );

  return fieldsToShow.length ? (
    <HeaderAndTableContainer>
      {showHeader && <Header>Fields</Header>}
      <>
        {fieldsToShow.map(field => (
          <TableRowContainer key={field._id}>
            <TableRow>
              <FieldLabel
                text={field.name === TAGS_FIELD_NAME ? 'Tags' : field.label}
                fieldId={field._id}
              />
              <FieldContent>
                <FlexBox gap="small">
                  <FlexBox flex={1}>
                    {field.name === TAGS_FIELD_NAME ? (
                      <Tags modelId={modelId} />
                    ) : (
                      formatFieldContent({
                        value: fieldValues[field.name],
                        modelId,
                        field,
                        isLocalDateFormat,
                      })
                    )}
                  </FlexBox>
                </FlexBox>
              </FieldContent>
            </TableRow>
          </TableRowContainer>
        ))}
      </>
    </HeaderAndTableContainer>
  ) : null;
};
export default Fields;
