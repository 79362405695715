import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  ProteanDiagramStreamedProps,
  ProteanDiagramViewProperties,
} from '../types';
import ProteanDiagramSettingsBar from './settingsBar/ProteanDiagramSettingsBar';
import {
  Cursor,
  EdgesSource,
  GraphBuilder,
  GraphComponent,
  GraphEditorInputMode,
  GraphInputMode,
  GraphItemTypes,
  GraphViewerInputMode,
  ICommand,
  IEdge,
  ILabel,
  IModelItem,
  INode,
  ItemClickedEventArgs,
  NodesSource,
} from '@ardoq/yfiles';
import { logError } from '@ardoq/logging';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import { ViewIds } from '@ardoq/api-types';
import { ZoomContainer } from 'tabview/relationshipDiagrams/atoms';
import ZoomControls from 'atomicComponents/Zoomable/ZoomControls';
import ContextualToolbar from './contextualToolbar/ContextualToolbar';
import ContextualToolbarComponent from './contextualToolbar/ContextualToolbarComponent';
import changeLayout from './changeLayout';
import changeRenderingMode from './changeRenderingMode';
import { VisualizationContainer } from 'tabview/ViewContainer';
import {
  CollapsibleGraphGroup,
  GraphItem,
  GraphNode,
  ViewLegendContainer,
  type RelationshipDiagramGraphEdgeType,
} from '@ardoq/graph';
import { ProteanGraphState, RenderGraphFlags } from './types';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { getComponentMenu } from 'contextMenus/componentMenu';
import { getReferenceMenu } from 'contextMenus/referenceMenu';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import getAdditionalContextMenuItems from './contextMenu/getAdditionalContextMenuItems';
import LayoutRulesInputPane from './layoutRules/layoutRulesPane';
import {
  addOrUpdateSingleLayoutRule,
  getComponentTypeToRowLayoutRules,
} from './layoutRules/utils';
import { nodesAndGroupsFromGraphViewDataModel } from 'tabview/graphViews/layoutConstraints/util';
import isProteanEditModeEnabled$ from '../editMode$';
import { LayoutConstraintRuleProps } from './layoutRules/types';
import { getTabularInputMode } from '../editMode/getTabularInputMode';
import { installHoverMode } from 'yfilesExtensions/view/highlightControls';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
} from '@ardoq/view-legend';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { uniq } from 'lodash';

const GRAPH_VIEWER_OPTIONS: ConstructorParameters<
  typeof GraphViewerInputMode
>[0] = {
  clickableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
  focusableItems: GraphItemTypes.NONE,
  selectableItems: GraphItemTypes.NONE,
  clickSelectableItems: GraphItemTypes.NONE,
};

const getViewerInputMode = (
  graphComponent: GraphComponent,
  rightClickedListener: Parameters<
    GraphViewerInputMode['addItemRightClickedListener']
  >[0]
) => {
  const result = new GraphViewerInputMode({
    ...GRAPH_VIEWER_OPTIONS,
    itemHoverInputMode: installHoverMode(graphComponent, true),
  });
  result.itemHoverInputMode.hoverCursor = Cursor.POINTER;
  result.moveViewportInputMode.validBeginCursor = Cursor.GRAB;
  result.addItemRightClickedListener(rightClickedListener);
  return result;
};

const createEditorInputMode = (
  graphComponent: GraphComponent,
  contextualToolbar: ContextualToolbar
) => {
  const result = new GraphEditorInputMode();
  // update the contextual toolbar when the selection changes ...
  result.addMultiSelectionFinishedListener((src, args) => {
    // this implementation of the contextual toolbar only supports nodes, edges and labels
    contextualToolbar.selectedItems = args.selection
      .filter(
        item =>
          item instanceof INode ||
          item instanceof ILabel ||
          item instanceof IEdge
      )
      .toArray();
  });
  // ... or when an item is right clicked
  result.addItemRightClickedListener((src, args) => {
    // this implementation of the contextual toolbar only supports nodes, edges and labels
    graphComponent.selection.clear();
    graphComponent.selection.setSelected(args.item, true);
    contextualToolbar.selectedItems = [args.item];
  });

  // if an item is deselected or deleted, we remove that element from the selectedItems
  graphComponent.selection.addItemSelectionChangedListener((src, args) => {
    if (!args.itemSelected) {
      // remove the element from the selectedItems of the contextual toolbar
      const idx = contextualToolbar.selectedItems.findIndex(
        item => item === args.item
      );
      const newSelection = contextualToolbar.selectedItems.slice();
      newSelection.splice(idx, 1);
      contextualToolbar.selectedItems = newSelection;
    }
  });
  return result;
};

const GraphContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ProteanDiagramBaseView = ({
  viewId = ViewIds.PROTEAN_DIAGRAM,
  viewSettings,
  viewModel,
  viewInstanceId,
  nodeRepresentationData,
  referenceTypes,
  numericFields,
  nodePositions,
  leftMenuFilter,
  isEditModeEnabled,
  isViewpointMode,
  componentTypes,
}: ProteanDiagramViewProperties & ProteanDiagramStreamedProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const graphComponent = useRef<GraphComponent>();

  useEffect(() => {
    if (!containerRef.current) {
      logError(Error('container not found!'));
      return;
    }
    graphComponent.current = new GraphComponent({
      div: containerRef.current,
      devicePixelRatio,
    });
  }, [graphComponent]);
  const {
    layoutType: initialLayoutType,
    renderingMode: initialRenderingMode,
    isLegendActive,
  } = viewSettings;
  const rightClickHandler = useCallback(
    (sender: GraphInputMode, e: ItemClickedEventArgs<IModelItem>) => {
      if (
        !graphComponent.current ||
        !e.location ||
        !(e.item.tag instanceof GraphItem)
      ) {
        return;
      }

      const isComponent = e.item.tag.isComponent();
      if (isComponent) {
        const { x, y } = graphComponent.current.toPageFromView(
          graphComponent.current.toViewCoordinates(e.location)
        );

        const componentMenu =
          getComponentMenu({
            componentIds: [e.item.tag.modelId],
            eventTargetModelId: e.item.tag.modelId,
            event: null,
            target: null,
            x,
            y,
            isViewpointMode,
          }) ?? [];
        const contextMenuItems = [
          ...componentMenu,
          ...getAdditionalContextMenuItems(state.current, e.item.tag.id),
        ].filter(ExcludeFalsy);
        if (!contextMenuItems) {
          return;
        }
        dispatchAction(
          setContextMenuState({
            items: contextMenuItems,
            position: { left: x, top: y },
          })
        );
        e.handled = true;
      } else if (e.item.tag.isGroup()) {
        const { x, y } = graphComponent.current.toPageFromView(
          graphComponent.current.toViewCoordinates(e.location)
        );
        dispatchAction(
          setContextMenuState({
            items: getAdditionalContextMenuItems(state.current, e.item.tag.id),
            position: { left: x, top: y },
          })
        );
        e.handled = true;
      } else if (e.item.tag.isReference()) {
        const { x, y } = graphComponent.current.toPageFromView(
          graphComponent.current.toViewCoordinates(e.location)
        );
        dispatchAction(
          setContextMenuState({
            items:
              getReferenceMenu({
                referenceIds: [e.item.tag.modelId],
                event: null,
                target: null,
                x,
                y,
                isViewpointMode,
              }) ?? [],
            position: { left: x, top: y },
          })
        );
      }
    },
    [graphComponent, isViewpointMode]
  );

  const graphBuilder = useRef<GraphBuilder>();
  const nodesSource = useRef<NodesSource<GraphNode>>();
  const groupsSource = useRef<NodesSource<CollapsibleGraphGroup>>();
  const edgesSource = useRef<EdgesSource<RelationshipDiagramGraphEdgeType>>();

  const state = useRef<ProteanGraphState>({
    viewId,
    viewInstanceId,
    graphComponent,
    viewSettings,
    nodesSource,
    groupsSource,
    edgesSource,
    graphBuilder,
    viewModel,
    lastViewModel: null,
    lastViewSettings: null,
    nodePositions,
    nodeMap: null,
    renderFlags: RenderGraphFlags.FROM_SCRATCH | RenderGraphFlags.LAYOUT_GRAPH,
    lastLayoutState: null,
    layoutState: { hierarchic: null, tabular: null, hierarchicInGrid: null },
    isEditModeEnabled,
  });

  // #region update these every time the control renders with new props.
  state.current.viewModel = viewModel;
  state.current.viewSettings = viewSettings;
  // #endregion
  useEffect(() => {
    if (!graphComponent) {
      return;
    }
    changeRenderingMode({
      renderingMode: initialRenderingMode,
      layoutType: initialLayoutType,
      nodeRepresentationData,
      referenceTypes,
      state: state.current,
    });
    changeLayout({
      nodeRepresentationData,
      referenceTypes,
      state: state.current,
    });
  }, [
    graphComponent,
    viewModel,
    initialLayoutType,
    initialRenderingMode,
    nodePositions,
    viewInstanceId,
    nodeRepresentationData,
    referenceTypes,
  ]);

  useEffect(() => {
    if (!graphComponent.current) {
      return;
    }

    graphComponent.current.inputMode =
      isEditModeEnabled && viewId === ViewIds.HIERARCHIC_IN_GRID
        ? getTabularInputMode(
            graphComponent.current,
            state.current,
            rightClickHandler
          )
        : getViewerInputMode(graphComponent.current, rightClickHandler);
  }, [isEditModeEnabled, viewId, rightClickHandler]);

  const layoutRulesPaneRef =
    useRef<React.ElementRef<typeof LayoutRulesInputPane>>(null);

  const graphNodes = nodesAndGroupsFromGraphViewDataModel(viewModel);

  const contextualToolbarContainer = useRef<HTMLDivElement>(null);

  const setLayoutRule = (layoutRule: LayoutConstraintRuleProps) =>
    addOrUpdateSingleLayoutRule(state.current, layoutRule);

  const hasLayoutRules = viewId === ViewIds.HIERARCHIC_IN_GRID;

  const currentLayoutRules =
    state.current.viewSettings.layoutOptions.tabular?.layoutRules;

  return (
    <SettingsBarAndViewContainer>
      <div className="menuContainer">
        <ProteanDiagramSettingsBar
          isEditModeEnabled={isEditModeEnabled}
          viewSettings={viewSettings}
          containerRef={containerRef}
          layoutType={initialLayoutType}
          renderingMode={initialRenderingMode}
          editMode={false}
          graphComponent={graphComponent}
          changeEditMode={editMode => {
            if (!graphComponent.current) {
              return;
            }
            graphComponent.current.graph.undoEngineEnabled = editMode;
            graphComponent.current.inputMode = editMode
              ? createEditorInputMode(
                  graphComponent.current,
                  new ContextualToolbar(
                    graphComponent.current,
                    contextualToolbarContainer.current!
                  )
                )
              : getViewerInputMode(graphComponent.current, rightClickHandler);
          }}
          numericFields={numericFields}
          nodeRepresentationData={nodeRepresentationData}
          referenceTypes={referenceTypes}
          state={state.current}
          leftMenuFilter={leftMenuFilter}
          layoutRulesPaneRef={layoutRulesPaneRef}
          isViewpointMode={isViewpointMode}
        />
      </div>
      <VisualizationContainer onContextMenu={e => e.preventDefault()}>
        {hasLayoutRules && isEditModeEnabled && (
          <LayoutRulesInputPane
            ref={layoutRulesPaneRef}
            setLayoutRule={setLayoutRule}
            ruleRows={getComponentTypeToRowLayoutRules({
              graphNodes,
              layoutType: state.current.viewSettings.layoutType,
              currentLayoutRules,
            })}
          />
        )}
        <GraphContainer ref={containerRef}>
          <ContextualToolbarComponent ref={contextualToolbarContainer} />
        </GraphContainer>
        <ZoomContainer style={{ top: 0 }}>
          <ZoomControls
            zoomIn={() =>
              ICommand.INCREASE_ZOOM.execute(1.2, graphComponent.current)
            }
            zoomOut={() =>
              ICommand.DECREASE_ZOOM.execute(1.2, graphComponent.current)
            }
            zoomCenter={() => graphComponent.current?.fitContent(true)}
          />
        </ZoomContainer>

        <ViewLegendContainer visible={isLegendActive}>
          <ViewLegend
            displayShapesInsteadOfIcons={true}
            componentTypes={componentTypes}
            referenceTypes={uniq(Array.from(referenceTypes.values()))}
            activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
            activeDiffMode={isInScopeDiffMode() ? getActiveDiffMode() : null}
          />
        </ViewLegendContainer>
      </VisualizationContainer>
    </SettingsBarAndViewContainer>
  );
};

export default connect(ProteanDiagramBaseView, isProteanEditModeEnabled$);
