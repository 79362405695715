import {
  map,
  tap,
  withLatestFrom,
  mergeMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  collectRoutines,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import currentUser$ from 'streams/currentUser/currentUser$';
import { addDismisssedEphemeralNotification } from './actions';
import { APICurrentUser, PersonalSetting } from '@ardoq/api-types';
import { handleError, userApi } from '@ardoq/api';
import currentUser from 'models/currentUser';
import { logError } from '@ardoq/logging';
import {
  addDismissedAndRemoveIrrelevant,
  getEphemeralNotificationsSetting,
} from './utils';
import { RELEVANT_NOTIFICATION_KEYS } from './ephemeralNotificationConfig';

const updateSettings = (user: APICurrentUser) => {
  currentUser.set('settings', user.settings);
  currentUser.trigger('sync');
};

const handleAddDismisssedEphemeralNotification = routine(
  ofType(addDismisssedEphemeralNotification),
  extractPayload(),
  distinctUntilChanged(),
  withLatestFrom(currentUser$),
  map(([dismissedBannerId, currentUserState]) => {
    const currentSetting = getEphemeralNotificationsSetting(currentUserState);

    const newSetting = dismissedBannerId
      ? addDismissedAndRemoveIrrelevant(
          dismissedBannerId,
          RELEVANT_NOTIFICATION_KEYS,
          currentSetting
        )
      : currentSetting;

    return {
      userId: currentUserState._id,
      settings: {
        ...currentUserState.settings,
        [PersonalSetting.EPHERMERAL_NOTIFICATION_SETTINGS_KEY]: newSetting,
      },
    };
  }),
  mergeMap(userApi.updateSettings),
  handleError(logError),
  tap(updateSettings)
);

export const ephemeralNotificationRoutines = collectRoutines(
  handleAddDismisssedEphemeralNotification
);
