import { returnZero } from '@ardoq/common-helpers';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import { COMPONENT_HIERARCHY_PADDING } from '../consts';
import { ComponentMatrixGroup, ComponentMatrixGroupOwnerType } from '../types';

const labelFromAddressToken = (
  token: string | null,
  ownerType: ComponentMatrixGroupOwnerType
) =>
  ownerType === ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT
    ? token === COMPONENT_HIERARCHY_PADDING
      ? COMPONENT_HIERARCHY_PADDING
      : truncateComponentLabel({
          ...getComponentLabelParts(token!),
          width: Infinity,
          measure: returnZero,
        })
    : token;

export const addAddressToGroup = (
  address: (string | null)[],
  ownerTypes: ComponentMatrixGroupOwnerType[],
  groupMap: Map<string | null, ComponentMatrixGroup>
) => {
  if (!address.length) {
    return;
  }

  let currentGroup: ComponentMatrixGroup =
    groupMap.get(address[0]) ||
    groupMap
      .set(address[0], {
        address: [address[0]],
        label: labelFromAddressToken(address[0], ownerTypes[0]),
        children: new Map(),
        ownerType: ownerTypes[0],
        isExpanded: false,
        isHidden: false,
      })
      .get(address[0])!;

  address.slice(1).forEach((token, index) => {
    const ownerType = ownerTypes[1 + index];
    currentGroup =
      currentGroup.children.get(token) ||
      currentGroup.children
        .set(token, {
          address: address.slice(0, index + 2),
          label: labelFromAddressToken(token, ownerType),
          children: new Map(),
          ownerType,
          isExpanded: false,
          isHidden: true,
        })
        .get(token)!;
  });
};
