import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { addToPresentation } from 'viewSettings/exportHandlers';
import {
  getReferenceTypeDropdownOptions,
  MenuMetaOptions,
} from '@ardoq/settings-bar';
import {
  type DropdownConfig,
  type SettingsConfig,
  SettingsType,
  type ToggleConfig,
  viewSettingsConsts,
} from '@ardoq/view-settings';
import {
  DropdownOption,
  DropdownOptionType,
  Notifier,
} from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import { HeaderModel, HeaderType, TableViewSettings } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ViewIds } from '@ardoq/api-types';
import { OnViewSettingsUpdate } from '@ardoq/data-model';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { partition } from 'lodash';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { SimpleTextPopover, popoverRegistry } from '@ardoq/popovers';
import { createElement } from 'react';

const TABLES_VIEW_LEGEND_IMPOSSIBLE = 'tablesViewLegendImpossible';
popoverRegistry.set(TABLES_VIEW_LEGEND_IMPOSSIBLE, () =>
  createElement(SimpleTextPopover, {
    text: 'Legend is available when a conditional format is added in Perspectives and at least 1 column displays components',
  })
);

const UNUSED_RECORD = {};

const SHOW_ALL = Symbol('SHOW_ALL');
const HIDE_ALL = Symbol('HIDE_ALL');

const leftMenu: SettingsConfig[] = [
  {
    id: 'toggleColumns',
    label: 'Toggle Columns',
    iconName: IconName.SPLIT_SCREEN,
    options: [],
    type: SettingsType.DROPDOWN,
    isDisabled: false,
    isKeepOpen: true,
  },
  {
    id: 'expandDescription',
    label: 'Expand Descriptions',
    iconName: IconName.UNFOLD_MORE,
    type: SettingsType.TOGGLE,
    isActive: true,
    isDisabled: false,
  },
];

type GetRightMenuArgs = {
  viewState: TableViewSettings;
  knowledgeBaseLink: string;
  viewId: ViewIds;
  onViewSettingsUpdate: OnViewSettingsUpdate;
  isEmptyView: boolean;
  isLegendPossible: boolean;
};
export const getRightMenu = ({
  viewState,
  knowledgeBaseLink,
  viewId,
  onViewSettingsUpdate: onUpdateViewSettings,
  isEmptyView,
  isLegendPossible,
}: GetRightMenuArgs): SettingsConfig[] => {
  const userHasWriteAccess =
    currentUserInterface.getCurrentUserAttributes().writeAccess;
  return [
    knowledgeBaseLink
      ? {
          id: 'knowledgeBaseLink',
          label: 'Knowledge base',
          iconName: IconName.KNOWLEDGE_BASE,
          type: SettingsType.BUTTON,
          onClick: () => window.open(knowledgeBaseLink, '_blank'),
          isActive: false,
        }
      : null,
    {
      id: viewSettingsConsts.IS_LEGEND_ACTIVE,
      label: 'Toggle legend',
      iconName: IconName.INFO,
      isActive: viewState[viewSettingsConsts.IS_LEGEND_ACTIVE],
      isDisabled: !isLegendPossible || isEmptyView,
      popoverId: !isLegendPossible ? TABLES_VIEW_LEGEND_IMPOSSIBLE : null,
      persistent: false,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate: onUpdateViewSettings,
    },
    {
      id: viewSettingsConsts.FULLSCREEN_KEY,
      label: viewState[viewSettingsConsts.FULLSCREEN_KEY]
        ? viewSettingsConsts.LEAVE_FULLSCREEN
        : viewSettingsConsts.FULLSCREEN,
      isActive: viewState[viewSettingsConsts.FULLSCREEN_KEY],
      iconName: viewState[viewSettingsConsts.FULLSCREEN_KEY]
        ? IconName.FULLSCREEN_EXIT
        : IconName.FULLSCREEN,
      type: SettingsType.TOGGLE,
      persistent: false,
      onViewSettingsUpdate: onUpdateViewSettings,
    },
    {
      id: 'export',
      label: 'Export',
      type: SettingsType.EXPORT,
      addToPresentation: userHasWriteAccess
        ? () => addToPresentation(viewId)
        : undefined,
      // Excluded, because it may cause browser crash. https://ardoqcom.atlassian.net/browse/ARD-16359
      // exportToPng: pngExporter,
      isDisabled: isEmptyView,
    },
  ].filter(ExcludeFalsy);
};

type ToggleFieldArgs = {
  visibleColumnsSet: Set<string>;
  headers: HeaderModel[];
  key: string | symbol;
};

const composeNewViewSettings = ({
  visibleColumnsSet,
  headers,
  key,
}: ToggleFieldArgs) => {
  const allColumns = headers.map(column => column.key);

  // hideAllColumns is needed to answer the question: are there no visible columns
  // because we have a new user, or is it so because all columns schould be hidden?
  let hideAllColumns = false;

  switch (key) {
    case SHOW_ALL:
      allColumns.forEach(col => visibleColumnsSet.add(col));
      break;
    case HIDE_ALL:
      allColumns.forEach(col => visibleColumnsSet.delete(col));
      hideAllColumns = true;
      break;
    default:
      if (typeof key === 'string') {
        if (visibleColumnsSet.has(key)) {
          visibleColumnsSet.delete(key);
        } else {
          visibleColumnsSet.add(key);
        }
      }
      hideAllColumns = allColumns.every(
        columnKey => !visibleColumnsSet.has(columnKey)
      );
  }

  return {
    visibleColumns: Array.from(visibleColumnsSet),
    hideAllColumns,
  };
};

type GetOptionClickHandlerArgs = ToggleFieldArgs & {
  viewId: ViewIds;
};
const getOptionClickHandler =
  ({ viewId, headers, visibleColumnsSet, key }: GetOptionClickHandlerArgs) =>
  () =>
    dispatchAction(
      updateViewSettings({
        viewId,
        settings: composeNewViewSettings({
          visibleColumnsSet,
          headers,
          key,
        }),
        persistent: true,
      })
    );

type GetDropDownOptionsArgs = {
  viewId: ViewIds;
  showAll: boolean;
  hideAllColumns: boolean;
  defaultHeaders: HeaderModel[];
  referenceTypeHeaders: HeaderModel[];
  fieldHeaders: HeaderModel[];
  visibleColumnsSet: Set<string>;
};
const getDropdownOptions = ({
  viewId,
  showAll,
  hideAllColumns,
  defaultHeaders,
  referenceTypeHeaders,
  fieldHeaders,
  visibleColumnsSet,
}: GetDropDownOptionsArgs) => {
  const allHeaders = [
    ...defaultHeaders,
    ...referenceTypeHeaders,
    ...fieldHeaders,
  ];
  const [incomingReferenceHeaders, outgoingReferenceHeaders] = partition(
    referenceTypeHeaders,
    ({ type }) => type === HeaderType.SOURCE
  );
  const referenceTypeOptions = getReferenceTypeDropdownOptions({
    viewId,
    availableIncomingRefTypes: incomingReferenceHeaders,
    availableOutgoingRefTypes: outgoingReferenceHeaders,
    viewState: UNUSED_RECORD,
    metaOptions: MenuMetaOptions.NO_OPTIONS,
    isActive: ({ key }) => Boolean(key && visibleColumnsSet.has(key)),
    isParentRelationAsReference: false,
    onClick: ({ key }) =>
      getOptionClickHandler({
        viewId,
        headers: allHeaders,
        visibleColumnsSet,
        key,
      })(),
    formatValue: ({ label }) => label,
    onViewSettingsUpdate,
  });

  const [defaultHeaderOptions, fieldHeaderOptions] = [
    defaultHeaders,
    fieldHeaders,
  ].map(headers =>
    headers.map(({ key, label }) => {
      // show all colums as active if there are no visible columns but we should use them
      const isActive = visibleColumnsSet?.size
        ? visibleColumnsSet.has(key)
        : !hideAllColumns;

      const option: DropdownOption = {
        label,
        isActive,
        onClick: getOptionClickHandler({
          viewId,
          headers: allHeaders,
          visibleColumnsSet,
          key,
        }),
        type: DropdownOptionType.OPTION,
        truncateLabel: true,
      };

      return option;
    })
  );
  return [
    {
      label: viewSettingsConsts.SHOW_ALL,
      name: 'show_all',
      isActive: showAll,
      onClick: getOptionClickHandler({
        viewId,
        headers: allHeaders,
        visibleColumnsSet,
        key: SHOW_ALL,
      }),
      type: DropdownOptionType.OPTION,
    },
    {
      label: 'Hide all',
      name: 'hide_all',
      isActive: hideAllColumns,
      onClick: getOptionClickHandler({
        viewId,
        headers: allHeaders,
        visibleColumnsSet,
        key: HIDE_ALL,
      }),
      type: DropdownOptionType.OPTION,
    },
    {
      type: DropdownOptionType.DIVIDER,
    },
    defaultHeaderOptions.length && {
      type: DropdownOptionType.HEADER,
      label: 'Default',
    },
    ...defaultHeaderOptions,
    ...referenceTypeOptions,
    fieldHeaderOptions.length && {
      type: DropdownOptionType.HEADER,
      label: 'Fields',
    },
    ...fieldHeaderOptions,
  ].filter(ExcludeFalsy);
};

export const getLeftMenu = (
  viewId: ViewIds.REFERENCETABLE | ViewIds.TABLEVIEW,
  { expandDescription, visibleColumns, hideAllColumns }: TableViewSettings,
  defaultHeaders: HeaderModel[],
  referenceTypeHeaders: HeaderModel[],
  fieldHeaders: HeaderModel[],
  onUpdateViewSettings: OnViewSettingsUpdate,
  openShowColumnsViewSetting: Notifier
): SettingsConfig[] => {
  const newLeftMenu = [...leftMenu];

  const allHeaders = [
    ...defaultHeaders,
    ...referenceTypeHeaders,
    ...fieldHeaders,
  ];
  const allColumns = allHeaders.map(h => h.key);

  const visibleColumnsSet = new Set(visibleColumns || []);

  const showAll = visibleColumnsSet.size
    ? allColumns.every(columnKey => visibleColumnsSet.has(columnKey))
    : !hideAllColumns; // if there are no visible cols specified and we should not hide all columns - show them all

  const expandDescriptionButton = newLeftMenu[1] as ToggleConfig;
  expandDescriptionButton.iconName = expandDescription
    ? IconName.UNFOLD_LESS
    : IconName.UNFOLD_MORE;
  expandDescriptionButton.label = expandDescription
    ? 'Collapse Descriptions'
    : 'Expand Descriptions';
  expandDescriptionButton.isActive = expandDescription;
  expandDescriptionButton.onViewSettingsUpdate = onUpdateViewSettings;
  const toggleColumnsDropdown = newLeftMenu[0] as DropdownConfig;
  toggleColumnsDropdown.requestOpen = openShowColumnsViewSetting;
  toggleColumnsDropdown.options = getDropdownOptions({
    viewId,
    showAll,
    hideAllColumns,
    defaultHeaders,
    referenceTypeHeaders,
    fieldHeaders,
    visibleColumnsSet,
  });
  return newLeftMenu;
};
