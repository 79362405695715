import { MutableRefObject, useState } from 'react';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { SettingsBar, dropdownTraversalMenu } from '@ardoq/settings-bar';
import {
  HierarchicInGridLayoutOptions,
  HierarchicLayoutOptions,
  ProteanDiagramViewProperties,
  ProteanLayoutOptions,
  ProteanLayoutType,
  ProteanRenderingMode,
  TabularLayoutOptions,
} from '../../types';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getExportsForYFilesView } from '@ardoq/export';
import { IconName } from '@ardoq/icons';
import { type DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  type DropdownConfig,
  SettingsType,
  SettingsConfig,
} from '@ardoq/view-settings';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import changeLayoutBase, { ChangeLayoutArgs } from '../changeLayout';
import changeRenderingModeBase, {
  ChangeRenderingModeArgs,
} from '../changeRenderingMode';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import { LayoutRulesInputPaneRef, LeftMenuConfigProps } from './types';
import {
  EDIT_MODE_MENU_ID,
  INPUT_MODE_MENU_ID,
  LAYOUT_RESET_MENU_ID,
  LAYOUT_TYPE_LABELS,
  LAYOUT_TYPE_MENU_ID,
} from './consts';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import getLayoutOptionsDropdown from './getLayoutOptionsDropdown';
import {
  ConstraintSource,
  LayoutConstraintWithSource,
  ProteanGraphState,
} from '../types';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import defaultState from 'views/defaultState';
import { setIsProteanEditModeEnabled } from 'tabview/proteanDiagram/actions';
import { ViewIds } from '@ardoq/api-types';
import renderGraph from '../renderGraph';
import { confirm } from '@ardoq/modal';
import { updateLayoutRules } from '../layoutRules/utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SimpleTextPopover, popoverRegistry } from '@ardoq/popovers';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { useEffectOnce } from '@ardoq/hooks';
import type { GraphComponent } from '@ardoq/yfiles';
import { addToPresentation } from 'viewSettings/exportHandlers';

const getLayoutTypeOptions = ({
  layoutType: currentLayoutType,
  changeLayout,
  nodeRepresentationData,
  referenceTypes,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'layoutType'
  | 'changeLayout'
  | 'nodeRepresentationData'
  | 'referenceTypes'
  | 'state'
>): DropdownItem[] =>
  [
    ProteanLayoutType.Hierarchic,
    ProteanLayoutType.Organic,
    ProteanLayoutType.Orthogonal,
    ProteanLayoutType.Circular,
    ProteanLayoutType.Radial,
    ProteanLayoutType.CompactDisk,
    ProteanLayoutType.Swimlanes,
    ProteanLayoutType.SpatialMap,
    ProteanLayoutType.GeographicMap,
    ProteanLayoutType.Cactus,
    ProteanLayoutType.Tree,
    ProteanLayoutType.TreeMap,
    ProteanLayoutType.Tabular,
    ProteanLayoutType.HierarchicInGrid,
  ].map(layoutType => ({
    label: LAYOUT_TYPE_LABELS[layoutType],
    name: LAYOUT_TYPE_LABELS[layoutType],
    type: DropdownOptionType.OPTION,
    isActive: layoutType === currentLayoutType,
    onClick: () => {
      state.viewSettings.layoutType = layoutType;
      changeLayout({
        referenceTypes,
        nodeRepresentationData,
        state,
      });
    },
  }));

const PROTEAN_RENDERING_MODE_LABELS: Record<ProteanRenderingMode, string> = {
  [ProteanRenderingMode.SVG]: 'SVG',
  [ProteanRenderingMode.Canvas]: 'Canvas',
  [ProteanRenderingMode.WebGL2]: 'WebGL2',
};

const getRenderingModeOptions = ({
  changeRenderingMode,
  renderingMode: currentRenderingMode,
  nodeRepresentationData,
  referenceTypes,
  layoutType,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'changeRenderingMode'
  | 'renderingMode'
  | 'nodeRepresentationData'
  | 'referenceTypes'
  | 'layoutType'
  | 'state'
>): DropdownItem[] =>
  [
    ProteanRenderingMode.SVG,
    ProteanRenderingMode.Canvas,
    ProteanRenderingMode.WebGL2,
  ].map(renderingMode => ({
    label: PROTEAN_RENDERING_MODE_LABELS[renderingMode],
    name: PROTEAN_RENDERING_MODE_LABELS[renderingMode],
    isActive: currentRenderingMode === renderingMode,
    type: DropdownOptionType.OPTION,
    onClick: () =>
      changeRenderingMode({
        renderingMode,
        nodeRepresentationData,
        referenceTypes,
        layoutType,
        state,
      }),
  }));

const getLayoutTypeDropdown = ({
  layoutType,
  changeLayout,
  nodeRepresentationData,
  referenceTypes,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'layoutType'
  | 'changeLayout'
  | 'nodeRepresentationData'
  | 'referenceTypes'
  | 'state'
>): DropdownConfig => ({
  id: LAYOUT_TYPE_MENU_ID,
  type: SettingsType.DROPDOWN,
  label: 'Layout type',
  iconName: 'hot_tub' as IconName,
  options: getLayoutTypeOptions({
    layoutType,
    changeLayout,
    nodeRepresentationData,
    referenceTypes,
    state,
  }),
});
const getRenderingModeDropdown = ({
  changeRenderingMode,
  renderingMode,
  nodeRepresentationData,
  referenceTypes,
  layoutType,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'changeRenderingMode'
  | 'renderingMode'
  | 'nodeRepresentationData'
  | 'referenceTypes'
  | 'layoutType'
  | 'state'
>): DropdownConfig => ({
  id: 'renderingMode',
  type: SettingsType.DROPDOWN,
  label: 'Rendering mode',
  iconName: 'camera' as IconName,
  options: getRenderingModeOptions({
    changeRenderingMode,
    renderingMode,
    nodeRepresentationData,
    referenceTypes,
    layoutType,
    state,
  }),
});

const isTabularLayoutOptions = (
  layoutOptions:
    | TabularLayoutOptions
    | HierarchicLayoutOptions
    | HierarchicInGridLayoutOptions
): layoutOptions is TabularLayoutOptions | HierarchicInGridLayoutOptions =>
  'columnConstraintsWithSource' in layoutOptions &&
  'rowConstraintsWithSource' in layoutOptions;
const isHierarchicLayoutOptions = (
  layoutOptions:
    | TabularLayoutOptions
    | HierarchicLayoutOptions
    | HierarchicInGridLayoutOptions
): layoutOptions is HierarchicLayoutOptions =>
  'sequenceConstraints' in layoutOptions && 'layerConstraints' in layoutOptions;

const NO_LAYOUT_CONSTRAINTS_POPOIVER = 'NO_LAYOUT_CONSTRAINTS_POPOIVER';
popoverRegistry.set(NO_LAYOUT_CONSTRAINTS_POPOIVER, () => (
  <SimpleTextPopover text={`There is no change to reset in the layout.`} />
));

const resetLayoutConfirm = async () =>
  confirm({
    title: 'Reset layout?',
    text: 'The component positions and arrangement you have set will be changed back to default state.',
    confirmButtonTitle: 'Reset layout',
  });

const hasNotGeneratedConstraints = (
  constraints: LayoutConstraintWithSource[]
) => constraints.some(([, , source]) => source !== ConstraintSource.GENERATED);

const hasIndividualNodeConstraints = (
  layoutOptions:
    | TabularLayoutOptions
    | HierarchicLayoutOptions
    | HierarchicInGridLayoutOptions
    | null
) => {
  if (!layoutOptions) {
    return false;
  }

  if (isTabularLayoutOptions(layoutOptions)) {
    return (
      hasNotGeneratedConstraints(layoutOptions.columnConstraintsWithSource) ||
      hasNotGeneratedConstraints(layoutOptions.rowConstraintsWithSource) ||
      layoutOptions.columnSpans.length ||
      layoutOptions.rowSpans.length
    );
  }

  if (isHierarchicLayoutOptions(layoutOptions)) {
    return (
      layoutOptions.sequenceConstraints.length ||
      layoutOptions.layerConstraints.length
    );
  }

  return false;
};

const getViewLayoutOptions = (
  layoutOptions: ProteanLayoutOptions,
  layoutType: ProteanLayoutType
) => {
  switch (layoutType) {
    case ProteanLayoutType.Tabular:
      return layoutOptions.tabular;
    case ProteanLayoutType.Hierarchic:
      return layoutOptions.hierarchic;
    case ProteanLayoutType.HierarchicInGrid:
      return layoutOptions.hierarchicInGrid;
    default:
      return null;
  }
};

const getLeftMenuConfig = ({
  changeRenderingMode,
  isEditModeEnabled,
  changeIsEditModeEnabled,
  editMode,
  changeEditMode,
  numericFields,
  changeLayout,
  nodeRepresentationData,
  referenceTypes: referenceTypesMap,
  state,
  leftMenuFilter,
  layoutOptions,
  layoutRulesPaneRef,
  isViewpointMode,
}: LeftMenuConfigProps): SettingsConfig[] => {
  const {
    viewId,
    viewModel: {
      traversedOutgoingReferenceTypes,
      traversedIncomingReferenceTypes,
      referenceTypes,
    },
    viewSettings,
  } = state;
  const { layoutType, renderingMode } = viewSettings;

  const viewLayoutOptions = getViewLayoutOptions(layoutOptions, layoutType);

  const isResetButtonDisabled =
    !hasIndividualNodeConstraints(viewLayoutOptions) &&
    !viewLayoutOptions?.layoutRules?.length;

  const onResetLayoutClick = async () => {
    const confirmed = await resetLayoutConfirm();

    if (!confirmed) {
      return;
    }
    const newLayoutOptions = defaultState.get(viewId)?.layoutOptions;
    state.viewSettings.layoutOptions = newLayoutOptions;
    state.viewSettings.collapsedGroupIds = [];

    layoutRulesPaneRef?.current?.setIsCollapsed(true);

    updateLayoutRules(state, [], null);

    onViewSettingsUpdate(
      viewId,
      { layoutOptions: newLayoutOptions, collapsedGroupIds: [] },
      true
    );
    changeLayout({
      nodeRepresentationData,
      referenceTypes: referenceTypesMap,
      state,
    });
  };

  const result = [
    !isViewpointMode &&
      dropdownTraversalMenu({
        viewSettings,
        traversedOutgoingReferenceTypes,
        traversedIncomingReferenceTypes,
        referenceTypes,
        viewId,
        maximumDepth: 99,
        onViewSettingsUpdate,
        quickPerspectiveReferenceTypeFilters:
          filterInterface.getActiveQuickReferenceTypeFilters(),
      }),
    getLayoutTypeDropdown({
      layoutType,
      changeLayout,
      nodeRepresentationData,
      referenceTypes: referenceTypesMap,
      state,
    }),
    getLayoutOptionsDropdown({
      layoutType,
      numericFields,
      changeLayout,
      referenceTypes: referenceTypesMap,
      nodeRepresentationData,
      state,
    }),
    getRenderingModeDropdown({
      changeRenderingMode,
      renderingMode,
      nodeRepresentationData,
      referenceTypes: referenceTypesMap,
      layoutType,
      state,
    }),
    {
      id: EDIT_MODE_MENU_ID,
      type: SettingsType.TOGGLE,
      isActive: isEditModeEnabled,
      label: isEditModeEnabled ? 'Close edit mode' : 'Open edit mode',
      onClick: () => {
        changeIsEditModeEnabled(!isEditModeEnabled);
      },
      iconName: IconName.EDIT,
    },
    {
      id: INPUT_MODE_MENU_ID,
      type: SettingsType.TOGGLE,
      isActive: editMode,
      label: 'Input mode',
      onClick: () => changeEditMode(!editMode),
      iconName: IconName.CUBES,
    },
    isEditModeEnabled
      ? {
          id: LAYOUT_RESET_MENU_ID,
          type: SettingsType.BUTTON,
          isDisabled: isResetButtonDisabled,
          popoverId: isResetButtonDisabled
            ? NO_LAYOUT_CONSTRAINTS_POPOIVER
            : undefined,
          onClick: onResetLayoutClick,
          label: isResetButtonDisabled ? '' : 'Reset layout',
          iconName: IconName.HISTORY,
        }
      : null,
  ].filter(ExcludeFalsy);

  return leftMenuFilter ? leftMenuFilter(result) : result;
};

type ProteanDiagramSettingsBarProps = Omit<
  LeftMenuConfigProps,
  | 'changeLayout'
  | 'changeRenderingMode'
  | 'changeIsEditModeEnabled'
  | 'layoutOptions'
> &
  Pick<ProteanDiagramViewProperties, 'viewSettings'> & {
    containerRef: MutableRefObject<HTMLElement | null>;
    graphComponent: MutableRefObject<GraphComponent | undefined>;
    state: ProteanGraphState;
    leftMenuFilter?: (items: SettingsConfig[]) => SettingsConfig[];
    isEditModeEnabled: boolean;
    layoutRulesPaneRef?: MutableRefObject<LayoutRulesInputPaneRef | null>;
  };

type ProteanDiagramSettingsBarState = {
  layoutType: ProteanLayoutType;
  layoutOptions: ProteanLayoutOptions;
};
const ProteanDiagramSettingsBar = ({
  viewSettings,
  containerRef,
  graphComponent,
  layoutType: initialLayoutType,
  renderingMode: initialRenderingMode,
  editMode: initialEditMode,
  changeEditMode,
  numericFields,
  nodeRepresentationData,
  referenceTypes,
  state,
  leftMenuFilter,
  isEditModeEnabled,
  layoutRulesPaneRef,
  isViewpointMode,
}: ProteanDiagramSettingsBarProps) => {
  const { viewId } = state;

  useEffectOnce(() => {
    const subscription = getViewSettingsStream(viewId).subscribe(
      newViewSettings => {
        const { layoutType, layoutOptions } = newViewSettings;
        setViewState({ layoutType, layoutOptions });
      }
    );
    return () => subscription.unsubscribe();
  });

  const exports = getExportsForYFilesView({
    container: () => containerRef.current,
    graphComponent: () => graphComponent.current ?? null,
    exportedViewMetadata: {
      name: viewId,
    },
    getStyleSheets,
    addToPresentation,
  });

  const layoutOptions = {
    ...PROTEAN_DEFAULT_LAYOUT_OPTIONS,
    ...viewSettings.layoutOptions,
  };
  const [viewState, setViewState] = useState<ProteanDiagramSettingsBarState>({
    layoutType: initialLayoutType,
    layoutOptions: layoutOptions,
  });
  const [currentRenderingMode, setCurrentRenderingMode] =
    useState(initialRenderingMode);

  // Edit mode is the "input mode" state, but we want to keep the old name for now.
  // In the near future the existing input mode with resize D&D will be merged with
  // the layout editing mode, which is defined by isEditModeEnabled state.
  const [editMode, setEditMode] = useState(initialEditMode);

  const changeLayout = async (args: ChangeLayoutArgs) => {
    const {
      state: { viewSettings: currentViewSettings },
    } = args;
    const { layoutType } = currentViewSettings;
    const newLayoutOptions = {
      ...PROTEAN_DEFAULT_LAYOUT_OPTIONS,
      ...currentViewSettings.layoutOptions,
    };
    dispatchAction(
      updateViewSettings({
        settings: { layoutType, layoutOptions: newLayoutOptions },
        persistent: true,
        viewId,
      })
    );
    setViewState({ layoutType, layoutOptions: newLayoutOptions });
    await changeLayoutBase(args);
  };
  const changeRenderingMode = async (args: ChangeRenderingModeArgs) => {
    const { renderingMode } = args;
    dispatchAction(
      updateViewSettings({
        settings: { renderingMode },
        persistent: true,
        viewId,
      })
    );
    setCurrentRenderingMode(renderingMode);
    changeRenderingModeBase(args);
  };
  return (
    <SettingsBar
      viewId={viewId}
      leftMenu={getLeftMenuConfig({
        isViewpointMode,
        layoutType: viewState.layoutType,
        layoutOptions: viewState.layoutOptions,
        changeRenderingMode,
        renderingMode: currentRenderingMode,
        isEditModeEnabled,
        changeIsEditModeEnabled: newIsEditModeEnabled => {
          dispatchAction(
            setIsProteanEditModeEnabled({
              isEditModeEnabled: newIsEditModeEnabled,
            })
          );
          if (viewId === ViewIds.HIERARCHIC_IN_GRID) {
            state.isEditModeEnabled = newIsEditModeEnabled;
            renderGraph(state);
          }
        },
        editMode,
        changeEditMode: newEditMode => {
          setEditMode(newEditMode);
          changeEditMode(newEditMode);
        },
        numericFields,
        changeLayout,
        nodeRepresentationData,
        referenceTypes,
        state,
        leftMenuFilter,
        layoutRulesPaneRef,
      })}
      rightMenu={getRightMenuConfig({
        viewId,
        viewstate: viewSettings,
        exports,
        onViewSettingsUpdate,
        isFullscreenDisabled: false,
        isExportsDisabled: isEditModeEnabled,
        exportsDisabledMessage: 'Save & Export is disabled while in edit mode.',
      })}
    />
  );
};

export default ProteanDiagramSettingsBar;
