import {
  GeneralPath,
  INode,
  IRectangle,
  IRenderContext,
  NodeStyleBase,
  Point,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { logError } from '@ardoq/logging';
import { GraphNode } from '@ardoq/graph';
import { darken } from 'polished';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { colors } from '@ardoq/design-tokens';
import { createSvgElement } from '@ardoq/dom-utils';

type ScalingFunction = (graphNode: GraphNode) => number;
const getPoints = (
  { x, y, width, height }: IRectangle,
  scaler: ScalingFunction,
  graphNode: GraphNode
) => {
  const topLeft = new Point(x, y);
  const topRight = new Point(x + width, y);
  const bottomRight = new Point(x + width, y + height);
  const bottomLeft = new Point(x, y + height);
  const depth = scaler(graphNode);
  const [raisedTopLeft, raisedTopRight, raisedBottomRight, raisedBottomLeft] = [
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
  ].map(p => new Point(p.x - depth, p.y - depth));
  return {
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
    raisedTopLeft,
    raisedTopRight,
    raisedBottomRight,
    raisedBottomLeft,
  };
};

/**
 * A node style that visualizes the node as block in an isometric fashion.
 */
export default class IsometricNodeStyle extends NodeStyleBase {
  constructor(public scaler: (node: GraphNode) => number) {
    super();
  }
  createVisual(context: IRenderContext, node: INode) {
    if (!(node.tag instanceof GraphNode)) {
      logError(Error('Unexpected node tag.'));
      return null;
    }
    const container = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const {
      topRight,
      bottomRight,
      bottomLeft,
      raisedTopLeft,
      raisedTopRight,
      raisedBottomRight,
      raisedBottomLeft,
    } = getPoints(node.layout, this.scaler, node.tag);
    const top = new GeneralPath();
    top.moveTo(raisedTopLeft);
    top.lineTo(raisedTopRight);
    top.lineTo(raisedBottomRight);
    top.lineTo(raisedBottomLeft);
    top.lineTo(raisedTopLeft);
    const front = new GeneralPath();
    front.moveTo(bottomLeft);
    front.lineTo(raisedBottomLeft);
    front.lineTo(raisedBottomRight);
    front.lineTo(bottomRight);
    front.lineTo(bottomLeft);
    const side = new GeneralPath();
    side.moveTo(bottomRight);
    side.lineTo(raisedBottomRight);
    side.lineTo(raisedTopRight);
    side.lineTo(topRight);
    side.lineTo(bottomRight);

    const fill =
      getComponentCssColors(node.tag.modelId, { useAsBackgroundStyle: false })
        ?.fill ?? colors.black;

    const darkFill = darken(0.1, fill);
    const darkerFill = darken(0.2, fill);
    container.appendChild(
      createSvgElement('path', { d: top.createSvgPathData(), fill })
    );
    container.appendChild(
      createSvgElement('path', { d: front.createSvgPathData(), fill: darkFill })
    );
    container.appendChild(
      createSvgElement('path', {
        d: side.createSvgPathData(),
        fill: darkerFill,
      })
    );

    return new SvgVisual(container);
  }

  updateVisual(context: IRenderContext, oldVisual: Visual, node: INode) {
    return this.createVisual(context, node);
  }

  getOutline(node: INode) {
    if (!(node.tag instanceof GraphNode)) {
      logError(Error('Unexpected node tag.'));
      return null;
    }
    const {
      topRight,
      bottomRight,
      bottomLeft,
      raisedTopLeft,
      raisedTopRight,
      raisedBottomLeft,
    } = getPoints(node.layout, this.scaler, node.tag);
    const result = new GeneralPath();
    result.moveTo(bottomLeft);
    result.lineTo(raisedBottomLeft);
    result.lineTo(raisedTopLeft);
    result.lineTo(raisedTopRight);
    result.lineTo(topRight);
    result.lineTo(bottomRight);
    result.lineTo(bottomLeft);
    return result;
  }
}
