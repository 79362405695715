import { IconName } from '@ardoq/icons';
import { SettingsType, ToggleConfig } from '@ardoq/view-settings';
import { onViewSettingsUpdate } from './onViewSettingsUpdate';

const includeAllDescendantsMenuItem = (isActive: boolean): ToggleConfig => ({
  id: 'includeAllDescendants',
  label: 'Include descendants',
  iconName: isActive ? IconName.BLUR_ON : IconName.BLUR_OFF,
  isActive,
  type: SettingsType.TOGGLE,
  onViewSettingsUpdate,
});
export default includeAllDescendantsMenuItem;
