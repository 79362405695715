import { map } from 'rxjs';
import loadedPresentation$ from '../../presentation/streams/loadedPresentation$';

/**
 * Stream to identify if a view is in presentation mode
 */
const presentationMode$ = loadedPresentation$.pipe(
  map(({ loadedPresentationId }) => ({
    isPresentationMode: !!loadedPresentationId,
  }))
);

export default presentationMode$;
