import { ComponentProps, ComponentType, FunctionComponent } from 'react';

interface ToUniqueClass {
  <P extends JSX.IntrinsicAttributes>(
    WrappedComponent: ComponentType<P>
  ): FunctionComponent<ComponentProps<typeof WrappedComponent>>;
}

export const toUniqueClass: ToUniqueClass = WrappedComponent => props => (
  <WrappedComponent {...props} />
);
