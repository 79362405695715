import ViewPaneElement from 'atomicComponents/ViewPaneElement';
import ViewContainer from 'tabview/ViewContainer';
import type { ContextShape } from '@ardoq/data-model';
import activeView$ from 'streams/views/mainContent/activeView$';
import { componentInterface } from '@ardoq/component-interface';
import { getRelevantComponentIds } from '@ardoq/graph';

export const getViewIdOfClickedElement = (clickedElement?: Element) => {
  const isRightPane = clickedElement?.closest(`${ViewPaneElement}:last-child`);

  return isRightPane
    ? activeView$.state.secondaryViewId!
    : clickedElement?.closest(`${ViewPaneElement}, ${ViewContainer}`)
      ? activeView$.state.mainViewId
      : null;
};

export const getStartSet = (context: ContextShape) =>
  context.componentId &&
  componentInterface.isIncludedInContextByFilter(context.componentId)
    ? [context.componentId]
    : getRelevantComponentIds(context);
