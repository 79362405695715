import { ViewIds } from '@ardoq/api-types';
import styled from 'styled-components';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';

const getDoqDataByViewId = (viewId?: ViewIds) => {
  switch (viewId) {
    case ViewIds.BLOCK_DIAGRAM:
    case ViewIds.MODERNIZED_BLOCK_DIAGRAM:
      return {
        doqType: DoqType.BLOCK_DIAGRAM_ERROR,
        viewName: 'Block Diagram',
      };
    case ViewIds.SWIMLANE:
      return { doqType: DoqType.SWIMLANE_ERROR, viewName: 'Swimlane' };
    default:
      return { doqType: null, viewName: '' };
  }
};

const getDoqAboveLimitWarningMessage = (viewName: string) =>
  `There are too many components and references to show and this can make the ${viewName} slow.` +
  '\n \n ' +
  'For a smoother experience, try editing your dataset to show fewer components and references.';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

type DoqWarningProps = {
  viewId?: ViewIds;
  canBypass?: boolean;
  onContinueClick?: VoidFunction;
};

export const DoqWarning = ({
  viewId,
  canBypass,
  onContinueClick,
}: DoqWarningProps) => {
  const { doqType, viewName } = getDoqDataByViewId(viewId);
  if (!doqType) {
    return null;
  }

  const buttonLabel =
    canBypass && onContinueClick ? 'Continue anyway' : undefined;

  return (
    <Container>
      <ResponsiveDoqWithSpeechBubble
        doqType={doqType}
        message={getDoqAboveLimitWarningMessage(viewName)}
        title="This view may run slowly"
        buttonLabel={buttonLabel}
        onButtonClick={buttonLabel && onContinueClick}
      />
    </Container>
  );
};
