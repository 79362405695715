import type { IEdge, IGraph, ILabel, ILabelOwner } from '@ardoq/yfiles';
import * as encodingUtils from '@ardoq/html';
import {
  AggregatedGraphEdge,
  GraphItem,
  GraphNode,
  type RelationshipDiagramGraphEdgeType,
  graphItemLabel,
} from '@ardoq/graph';
import isUrlLabel from './isUrlLabel';
import mainLabelLayoutParameter from './mainLabelLayoutParameter';
import { addAggregatedCountLabel, addURLEdgeLabel } from './addGraphLabels';

export const updateLabels = (
  owner: ILabelOwner,
  graph: IGraph,
  graphItem: GraphItem
) => {
  const isNode = graphItem instanceof GraphNode;
  const labelsToRemove: ILabel[] = [];
  owner.labels.forEach(label => {
    if (label.tag === null) {
      const labelText = graphItemLabel(graphItem);
      graph.setLabelText(label, labelText);
      if (isNode) {
        graph.setLabelLayoutParameter(
          label,
          mainLabelLayoutParameter(labelText)
        );
      }
    } else if (isUrlLabel(label) && !label.tag.hasURLFields()) {
      labelsToRemove.push(label);
    }
  });
  labelsToRemove.forEach(label => graph.remove(label));
};
export const updateEdgeLabels = (
  edge: IEdge,
  graph: IGraph,
  graphEdge: RelationshipDiagramGraphEdgeType,
  isModern: boolean
) => {
  const labelsToRemove = edge.labels.toArray();
  for (const label of labelsToRemove) {
    graph.remove(label);
  }

  const labelText = encodingUtils.unescapeHTML(graphEdge.getLabel());
  graph.addLabel(edge, labelText);
  if (graphEdge.hasURLFields()) {
    addURLEdgeLabel(graph, edge, graphEdge, isModern);
  }
  if (graphEdge instanceof AggregatedGraphEdge) {
    addAggregatedCountLabel(graph, edge, graphEdge);
  }
};
