import layoutUpdate$ from 'tabview/graphComponent/layoutUpdate$';
import {
  getRelationshipDiagramViewModel$,
  relationshipDiagramEmptyState,
} from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramViewModel$';
import {
  BlockDiagramViewProperties,
  BlockDiagramViewPropertiesBase,
  BlockDiagramViewSettings,
} from './types';
import { Observable, combineLatest } from 'rxjs';
import {
  action$,
  combineReducers,
  dispatchAction,
  streamReducer,
  ofType,
} from '@ardoq/rxbeach';
import { debounceTime, filter, startWith, tap } from 'rxjs/operators';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { ViewIds } from '@ardoq/api-types';
import { notifyModelChanged } from 'streams/models/actions';
import { startAction } from 'actions/utils';
import { notifyViewLoading } from 'tabview/actions';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { GraphItem } from '@ardoq/graph';
import { isUpdatingAppState$ } from 'isUpdatingAppState$';
import { settingsBarConsts } from '@ardoq/settings-bar';
import type { ExtractStreamShape } from 'tabview/types';
import { updateViewProperties } from '@ardoq/view-settings';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import scenarios$ from 'streams/scenarios/scenarios$';

const emptyState = <TViewSettings extends BlockDiagramViewSettings>(
  viewId: ViewIds
): BlockDiagramViewPropertiesBase<TViewSettings> => ({
  ...relationshipDiagramEmptyState<TViewSettings>(viewId),
  viewInstanceId: '',
  zoomComponentId: null,
  preserveViewport: false,
  clearEdges: false,
  isViewpointMode: false,
});

const IGNORE_VIEW_SETTINGS: (keyof BlockDiagramViewSettings)[] = [
  'sequenceConstraints',
  'layerConstraints',
  'separateReferences',
  settingsBarConsts.IS_LEGEND_ACTIVE,
];

const setAsUpdated = <T extends GraphItem>(
  graphItems: GraphItemsModel<T>
): GraphItemsModel<T> => ({
  byId: graphItems.byId,
  add: [],
  update: [...graphItems.update, ...graphItems.add],
  remove: [],
});
const clonedViewModelAsUpdate = (
  viewModel: BlockDiagramViewProperties['viewModel']
) => ({
  ...viewModel,
  expandedStateChangingGroupId: null,
  nodes: setAsUpdated(viewModel.nodes),
  groups: setAsUpdated(viewModel.groups),
  edges: setAsUpdated(viewModel.edges),
});

const updateSeparateReferences = <
  TViewSettings extends BlockDiagramViewSettings,
>(
  properties: BlockDiagramViewPropertiesBase<TViewSettings>
): BlockDiagramViewPropertiesBase<TViewSettings> => ({
  ...properties,
  viewSettings: {
    ...properties.viewSettings,
    separateReferences: !properties.viewSettings.separateReferences,
  },
  viewModel: clonedViewModelAsUpdate(properties.viewModel),
});
/** separate references button should bypass the reset reducer and trigger an incremental update (with animation) */
const toggleSeparateReferences = <
  TViewSettings extends BlockDiagramViewSettings,
>(
  viewSettings$: Observable<ViewSettings<TViewSettings>>
) =>
  streamReducer(
    viewSettings$.pipe(
      filter(
        r =>
          r.changedSettings.length === 1 &&
          r.changedSettings[0][0] === 'separateReferences'
      )
    ),
    updateSeparateReferences<TViewSettings>
  );

interface GetViewModel$Args<
  TViewSettings extends BlockDiagramViewSettings = BlockDiagramViewSettings,
> {
  viewId: ViewIds;
  viewSettings$: Observable<ViewSettings<TViewSettings>>;
}
export const getViewModel$ =
  <TViewSettings extends BlockDiagramViewSettings = BlockDiagramViewSettings>({
    viewId,
    viewSettings$,
  }: GetViewModel$Args<TViewSettings>) =>
  (
    viewInstanceId: string
  ): Observable<BlockDiagramViewPropertiesBase<TViewSettings>> => {
    const relationshipDiagramViewModel$ = getRelationshipDiagramViewModel$({
      viewId,
      viewInstanceId,
      viewSettings$,
      viewSettingsToIgnore: IGNORE_VIEW_SETTINGS,
      shouldCollapseGraph: true,
    });
    const reset$ = combineLatest([
      relationshipDiagramViewModel$,
      layoutUpdate$,
      scenarios$,
      activeScenario$,
      action$.pipe(ofType(notifyModelChanged), startWith(startAction)),
    ]).pipe(
      tap(() =>
        dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: true }))
      ),
      debounceTime(100)
    );
    const resetReducer = (
      _: BlockDiagramViewPropertiesBase<TViewSettings>,
      [viewModel, layoutUpdate, scenarios, scenarioId]: ExtractStreamShape<
        typeof reset$
      >
    ): BlockDiagramViewPropertiesBase<TViewSettings> => ({
      ...viewModel,
      zoomComponentId: layoutUpdate.isContextChange
        ? viewModel.streamState.context.componentId
        : null,
      clearEdges: layoutUpdate.referenceOrderChanged,
      preserveViewport: layoutUpdate.preserveViewport,
      scenarioName: scenarios.byId[scenarioId.scenarioId || '']?.name,
      viewInstanceId,
      isViewpointMode: viewModel.loadedGraph.isViewpointMode,
    });

    return action$.pipe(
      combineReducers<BlockDiagramViewPropertiesBase<TViewSettings>>(
        emptyState<TViewSettings>(viewId),
        [
          streamReducer(reset$, resetReducer),
          toggleSeparateReferences(viewSettings$),
        ]
      ),
      filter(() => !isUpdatingAppState$.state),
      tap(viewProperties =>
        dispatchAction(updateViewProperties({ viewId, viewProperties }))
      )
    );
  };
