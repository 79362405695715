import * as React from 'react';
import styled from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  sortComponents,
  sortComponentsByReferences,
  toggleComponentDescription,
} from 'streams/components/ComponentActions';
import { toggleReferenceDescription } from 'streams/references/ReferenceActions';
import { NumericSortOrder, SortAttribute } from '@ardoq/common-helpers';
import { ViewIds } from '@ardoq/api-types';
import { HeaderModel, HeaderType } from './types';
import {
  TABLE_CELL_BORDER_WIDTH,
  TABLE_CELL_HORIZONTAL_PADDING,
} from './consts';
import { colors } from '@ardoq/design-tokens';
import { COMPONENT_ID_SELECTOR } from 'consts';
import { ChevronDownIcon, ChevronUpIcon } from '@ardoq/icons';

const EXPAND_DESCRIPTION = 'expand-description';

const handleComponentClick = (dispatch: string, cid: string) => {
  if (dispatch === EXPAND_DESCRIPTION) {
    dispatchAction(
      toggleComponentDescription({
        cid,
      })
    );
  }
};

const handleReferenceClick = (dispatch: string, cid: string) => {
  if (dispatch === EXPAND_DESCRIPTION) {
    dispatchAction(
      toggleReferenceDescription({
        cid,
      })
    );
  }
};

const handleTableClick = (
  e: React.MouseEvent<HTMLTableElement, MouseEvent>
) => {
  if (!(e.target instanceof Element)) {
    return;
  }
  const dispatchTarget = e.target.closest('[data-dispatch]');
  if (!(dispatchTarget instanceof HTMLElement)) {
    return;
  }
  const { dispatch } = dispatchTarget.dataset;
  if (!dispatch) {
    return;
  }
  const componentTarget = e.target.closest(COMPONENT_ID_SELECTOR);
  if (componentTarget instanceof HTMLElement) {
    const { componentId } = componentTarget.dataset;
    return handleComponentClick(dispatch, componentId!);
  }

  const referenceTarget = e.target.closest('[data-reference-cid]');
  if (referenceTarget instanceof HTMLElement) {
    const { referenceCid } = referenceTarget.dataset;
    return handleReferenceClick(dispatch, referenceCid!);
  }
};

const shouldSortComponentsByReferences = (
  viewId: ViewIds,
  order: number | null | undefined,
  header: HeaderModel
) =>
  viewId === ViewIds.TABLEVIEW &&
  order !== NumericSortOrder.ON_REFERENCE_ASCENDING &&
  (header.type === HeaderType.SOURCE || header.type === HeaderType.TARGET);

const sortBy =
  (sortable: boolean, header: HeaderModel, viewId: ViewIds) =>
  (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    e.stopPropagation();
    if (!sortable) {
      return;
    }

    let order: NumericSortOrder | null = null;
    let attr = header.key;
    if (shouldSortComponentsByReferences(viewId, header.order, header)) {
      if (header.order === null) {
        order = NumericSortOrder.ON_REFERENCE_DESCENDING;
      } else if (header.order === NumericSortOrder.ON_REFERENCE_DESCENDING) {
        order = NumericSortOrder.ON_REFERENCE_ASCENDING;
      }
      dispatchAction(
        sortComponentsByReferences({
          attr,
          order: order!,
          name: header.key,
        })
      );
    } else {
      if (header.order === null) {
        order = NumericSortOrder.DESC;
      } else if (
        header.order === NumericSortOrder.ASC ||
        header.order === NumericSortOrder.ON_REFERENCE_ASCENDING
      ) {
        order = null;
        attr = SortAttribute.ORDER;
      } else {
        order = NumericSortOrder.ASC;
      }
      dispatchAction(
        sortComponents({
          attr,
          order: order!,
          name: header.key,
        })
      );
    }
  };

interface HeaderRowArgs {
  sortable: boolean;
  headers: HeaderModel[];
  viewId: ViewIds;
}
export const HeaderRow = ({
  sortable,
  headers = [],
  viewId,
}: HeaderRowArgs) => {
  return (
    <thead>
      <tr className="tableHeader">
        {headers.map((header, idx) => (
          <th
            className="tableCell"
            key={header.key + idx}
            onClick={sortBy(sortable, header, viewId)}
          >
            <div className="headerCellContent">
              {header.label}
              {header.order !== null &&
                (header.order === NumericSortOrder.ASC ||
                header.order === NumericSortOrder.ON_REFERENCE_ASCENDING ? (
                  <ChevronDownIcon />
                ) : (
                  <ChevronUpIcon />
                ))}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const TableElement = styled.table.attrs({ className: 'tableViewTableElement' })`
  table-layout: fixed;
  transition: all 0.2s ease-out;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  & > thead {
    position: relative;
    z-index: 3;
  }

  /* Prevent clipping of first highlighted tr border */
  &&& > thead > tr > th:before {
    top: -2px;
  }

  & > thead > tr > th,
  & > tbody > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid ${colors.grey80};
    border: ${TABLE_CELL_BORDER_WIDTH}px solid ${colors.grey80} !important;
  }

  & > thead > tr > th {
    border-bottom: 1px solid ${colors.grey80};
    background-color: transparent;
    color: ${colors.grey60};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    vertical-align: bottom;

    & .headerCellContent {
      display: flex;
      align-items: center;
    }
  }

  & > tbody > tr > td {
    height: 48px;
    padding: 0 ${TABLE_CELL_HORIZONTAL_PADDING}px;
    vertical-align: middle;
  }

  & > colgroup + thead > tr:first-child > th,
  & > thead:first-child > tr:first-child > th,
  & > colgroup + thead > tr:first-child > td {
    border-top: 0;
  }

  & > tbody > tr:nth-of-type(odd) {
    background-color: ${colors.grey95};
  }

  .forExport & {
    min-width: 1200px;
  }
`;
interface TableArgs {
  children: React.ReactNode[];
  columnWidths: number[];
}
export const Table = React.forwardRef<HTMLTableElement, TableArgs>(
  ({ columnWidths, children }, ref) => {
    const [header, ...rows] = children;
    return (
      <TableElement onClick={handleTableClick} ref={ref}>
        <colgroup>
          {columnWidths.map((width, index) => (
            <col key={`${index}_${width}`} style={{ width }} />
          ))}
          {/* additional col for pseudo-element that we use for row highlighting */}
          <col style={{ width: 0, maxWidth: 0 }} />
        </colgroup>
        {header}
        <tbody>{rows}</tbody>
      </TableElement>
    );
  }
);
