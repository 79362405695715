import { popoverRegistry } from '@ardoq/popovers';
import { LayoutOrientation } from '@ardoq/yfiles';

export const BLOCK_DIAGRAM_LAYOUT_OPTIONS: [string, LayoutOrientation][] = [
  ['Left to right', LayoutOrientation.LEFT_TO_RIGHT],
  ['Right to left', LayoutOrientation.RIGHT_TO_LEFT],
  ['Top to bottom', LayoutOrientation.TOP_TO_BOTTOM],
  ['Bottom to top', LayoutOrientation.BOTTOM_TO_TOP],
];

export const COMPONENT_STYLE_VIEW_SETTING_POPOVER =
  'componentStyleViewSettingPopover';
popoverRegistry.set(
  COMPONENT_STYLE_VIEW_SETTING_POPOVER,
  () => 'If a component has an image, it will be displayed by default.'
);
