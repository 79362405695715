import { ExtractPayload, action$, reducer, reduceState } from '@ardoq/rxbeach';
import { debounceTime, map } from 'rxjs/operators';
import {
  addToStyleSheet,
  addToTempStyleSheet,
  clearStyles,
  clearTempStyles,
} from './actions';
import { FILTER_STYLES_KEY } from './consts';
import { CssEntry } from './types';

type ModelCssStreamShape = {
  styles: Map<string, CssEntry[]>;
  temps: Set<string>;
};

const handleAddToStyleSheet = (
  state: ModelCssStreamShape,
  keyedEntries: ExtractPayload<typeof addToStyleSheet>
) => {
  Object.keys(keyedEntries).forEach(key =>
    state.styles.set(key, keyedEntries[key])
  );
  return state;
};

const handleAddToTempStyleSheet = (
  state: ModelCssStreamShape,
  keyedEntries: ExtractPayload<typeof addToStyleSheet>
) => {
  Object.keys(keyedEntries).forEach(key => {
    state.styles.set(key, keyedEntries[key]);
    state.temps.add(key);
  });
  return state;
};

const handleClearStyles = (
  state: ModelCssStreamShape,
  { keys }: ExtractPayload<typeof clearStyles>
) => {
  keys.forEach(key => {
    state.styles.delete(key);
    state.temps.delete(key);
  });
  return state;
};

const handleClearTempStyles = (state: ModelCssStreamShape) => {
  for (const key of state.temps) {
    state.styles.delete(key);
  }
  state.temps.clear();
  return state;
};

/** A stream providing an array of css classes for Components, Workspace Metamodels, and Conditional Formatting Filters. */
const modelCss$ = action$.pipe(
  reduceState<{ styles: Map<string, CssEntry[]>; temps: Set<string> }>(
    'modelCssProcessing$',
    { styles: new Map(), temps: new Set() },
    [
      reducer(addToStyleSheet, handleAddToStyleSheet),
      reducer(addToTempStyleSheet, handleAddToTempStyleSheet),
      reducer(clearStyles, handleClearStyles),
      reducer(clearTempStyles, handleClearTempStyles),
    ]
  ),
  debounceTime(50),
  map(state => {
    const entries = state.styles;
    // sort selectors.
    // conditional formatting filter styles should always take precedence over model and component styles, therefore they must appear later in the document.
    const filterEntries = entries.get(FILTER_STYLES_KEY);
    if (!filterEntries) {
      return [...entries.values()].flat();
    }
    const clonedEntries = new Map(entries.entries());
    clonedEntries.delete(FILTER_STYLES_KEY);
    const sortedValues = [...clonedEntries.values(), filterEntries];
    return sortedValues.flat();
  })
);

export default modelCss$;
