import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { dispatchAction } from '@ardoq/rxbeach';
import { EXCLUDE_UNUSED_TAGS } from 'tabview/consts';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { updateViewSettings } from '@ardoq/view-settings';

type NoTagsWithSettingsDoqProps = {
  doqType: DoqType;
  viewId: string;
  includeActions?: boolean;
};

const NoTagsWithSettingsDoq = ({
  doqType,
  viewId,
  includeActions = true,
}: NoTagsWithSettingsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">Workspace does not have any used tags.</FlexBox>
          <FlexBox gap="medium">
            <span style={{ display: 'inline' }}>
              To show more data, deactivate the view modifier &quot;
              {EXCLUDE_UNUSED_TAGS}&quot;{' '}
              <Icon iconSize={IconSize.SMALL} iconName={IconName.TAG} />
            </span>
          </FlexBox>
        </Stack>
      }
      buttonLabel={includeActions ? 'Deactivate setting' : undefined}
      onButtonClick={
        includeActions
          ? () =>
              dispatchAction(
                updateViewSettings({
                  viewId,
                  settings: { excludeUnused: false },
                  persistent: true,
                })
              )
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoTagsWithSettingsDoq;
