import { NumericRange, NumericScalingFunction } from '@ardoq/graph';
import { BubbleChartDataPointModel } from '../types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import BubbleRadiusLegend from './BubbleRadiusLegend';

const getBubbleLegendRow = (
  radiusFieldName: string,
  chartData: BubbleChartDataPointModel[],
  radiusDomain: NumericRange,
  radiusScaler: NumericScalingFunction,
  radiusUnscaler: NumericScalingFunction,
  showBubblesWithZeroValue: boolean
) => {
  const field = fieldInterface.getByName(radiusFieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: true,
  });
  if (!field) {
    return undefined;
  }
  if (chartData.every(({ radius }) => !radius)) {
    // no need for a radius legend when every datapoint has a zero radius.
    return undefined;
  }
  return [
    {
      id: field._id,
      title: fieldInterface.getLabel(field._id) ?? '',
      sections: [
        {
          heading: null,
          items: [
            {
              getShape: () => (
                <BubbleRadiusLegend
                  chartData={chartData}
                  radiusDomain={radiusDomain}
                  radiusScaler={radiusScaler}
                  radiusUnscaler={radiusUnscaler}
                  includeZero={
                    showBubblesWithZeroValue &&
                    chartData.some(({ radius }) => !radius)
                  }
                />
              ),
              color: 'white',
              style: { paddingBottom: 0 },
            },
          ],
        },
      ],
    },
  ];
};

export default getBubbleLegendRow;
