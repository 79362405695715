import { NumericRange } from '@ardoq/graph';
import {
  floatErrorProofAdd,
  floatErrorProofMultiply,
  getRoundedInterval,
} from 'tabview/ticks';

const enumerate = (start: number, interval: number, end: number): number[] => {
  const result = [];
  for (let i = start; i <= end; i = floatErrorProofAdd(i, interval)) {
    result.push(i);
  }
  return result;
};

export const ticks = (
  domain: NumericRange,
  pixels: number,
  visibleRange: NumericRange
): number[] => {
  if (pixels <= 0) {
    return [];
  }
  const tickInterval = getRoundedInterval(visibleRange, pixels);

  const [rangeMin, rangeMax] = visibleRange;

  const distanceToStart = rangeMin - domain[0];
  const distanceToEnd = domain[1] - rangeMax;

  const ticksBeforeStart = Math.ceil(distanceToStart / tickInterval);
  const ticksAfterEnd = Math.ceil(distanceToEnd / tickInterval);

  const start = floatErrorProofAdd(
    domain[0],
    floatErrorProofMultiply(tickInterval, ticksBeforeStart)
  );

  const end = floatErrorProofAdd(
    domain[1],
    -floatErrorProofMultiply(tickInterval, ticksAfterEnd)
  );

  return enumerate(start, tickInterval, end);
};
