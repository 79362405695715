import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  ILabel,
  INode,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  TextMeasurePolicy,
  Visual,
} from '@ardoq/yfiles';
import { PROTEAN_MAX_FONT_SIZE } from '../../consts';
import getFittingFontSize from '../getFittingFontSize';
import { DUMMY_FONT } from '../consts';
import { createSvgElement } from '@ardoq/dom-utils';

class ProteanLabelStyle extends LabelStyleBase {
  fill?: string;
  font = DUMMY_FONT;
  protected override createVisual(
    context: IRenderContext,
    label: ILabel
  ): Visual | null {
    const { owner, text } = label;

    const zoom = context.canvasComponent?.zoom ?? 0;
    const fontSize =
      this.font === DUMMY_FONT
        ? Math.min(
            PROTEAN_MAX_FONT_SIZE / zoom,
            getFittingFontSize(label, TextMeasurePolicy.SVG)
          )
        : this.font.fontSize;

    if (fontSize * zoom < 10) {
      return null;
    }
    const textElement = createSvgElement('text', {
      'dominant-baseline': 'central',
      'text-anchor': 'middle',
      'font-family': ARDOQ_DEFAULT_FONT_FAMILY,
      'font-size': `${fontSize}px`,
      transform: `translate(${
        (owner as INode).layout.x + (owner as INode).layout.width / 2
      }, ${(owner as INode).layout.y + (owner as INode).layout.height / 2})`,
      fill: this.fill ?? '',
    });
    textElement.textContent = text;

    return new SvgVisual(textElement);
  }
  protected override getPreferredSize(label: ILabel): Size {
    return (label.owner as INode).layout.toSize();
  }
}

export default ProteanLabelStyle;
