import {
  ArrowType,
  LineType,
  MetaModelComponentType,
  MetaModelReferenceType,
  MetaModelTriple,
} from '@ardoq/api-types';
import { Nullable } from '@ardoq/common-helpers';

export const isMetaModelComponentType = (
  candidate: MetaModelComponentType | MetaModelComponentTypeWithNullTypes
): candidate is MetaModelComponentType => typeof candidate.name === 'string';

export const isMetaModelReferenceType = (
  candidate: MetaModelReferenceType | MetaModelReferenceTypeWithNullTypes
): candidate is MetaModelReferenceType => typeof candidate.name === 'string';

export const isMetaModelTriple = (
  candidate: MetaModelTriple | MetaModelTripleWithNullTypes
): candidate is MetaModelTriple =>
  Object.values(candidate).every(name => typeof name === 'string');

export type MetaModelComponentTypeWithNullTypes = {
  name: MetaModelComponentType['name'] | null;
  parentName?: MetaModelComponentType['parentName'];
  style: Nullable<MetaModelComponentType['style']>;
  usedInWorkspaceIds: MetaModelComponentType['usedInWorkspaceIds'];
  fieldNames: MetaModelComponentType['fieldNames'];
};

export type MetaModelReferenceTypeWithNullTypes = {
  name: MetaModelReferenceType['name'] | null;
  style: {
    color: string | null;
    line: LineType | null;
    lineBeginning?: ArrowType;
    lineEnding: ArrowType | null;
    svgStyle: string | null;
    hasCardinality?: boolean;
  };
  usedInWorkspaceIds: MetaModelReferenceType['usedInWorkspaceIds'];
  fieldNames: MetaModelReferenceType['fieldNames'];
};

type MetaModelTripleWithNullTypes = Nullable<MetaModelTriple>;

export type MetaModelWithNullTypes = {
  componentTypes: MetaModelComponentTypeWithNullTypes[];
  referenceTypes: MetaModelReferenceTypeWithNullTypes[];
  triples: MetaModelTripleWithNullTypes[];
};
