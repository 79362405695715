import { ArrowType } from '@ardoq/api-types';
import { CanvasRenderingContext } from './types';
import { colors } from '@ardoq/design-tokens';
import { logWarn } from '@ardoq/logging';

const triangle = (
  context: CanvasRenderingContext,
  x: number,
  y: number,
  size: number
) => {
  context.moveTo(x - size, y - size);
  context.lineTo(x, y);
  context.lineTo(x - size, y + size);
};
const diamond = (
  context: CanvasRenderingContext,
  x: number,
  y: number,
  size: number
) => {
  context.moveTo(x - 2 * size, y - size);
  context.lineTo(x, y);
  context.lineTo(x - 2 * size, y + size);
  context.lineTo(x - 4 * size, y);
};

const drawArrow = (
  context: CanvasRenderingContext,
  x: number,
  y: number,
  size: number,
  arrow: ArrowType
) => {
  switch (arrow) {
    case ArrowType.NONE:
      break;
    case ArrowType.CIRCLE:
    case ArrowType.CIRCLE_START:
      context.beginPath();
      context.ellipse(x - size / 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = colors.black;
      context.fill();
      break;
    case ArrowType.BOTH:
    case ArrowType.BOTH_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x, y);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;
    case ArrowType.BOTH_FILLED:
    case ArrowType.BOTH_FILLED_START:
      context.beginPath();
      triangle(context, x, y, size);
      context.closePath();
      context.fillStyle = colors.black;
      context.fill();
      context.stroke();
      break;
    case ArrowType.BOTH_OUTLINED:
    case ArrowType.BOTH_OUTLINED_START:
      context.beginPath();
      triangle(context, x, y, size);
      context.closePath();
      context.fillStyle = colors.white;
      context.fill();
      context.stroke();
      break;
    case ArrowType.DIAMOND:
    case ArrowType.DIAMOND_START:
      context.beginPath();
      diamond(context, x, y, size);
      context.closePath();
      context.fillStyle = colors.white;
      context.fill();
      context.stroke();
      break;
    case ArrowType.DIAMOND_FILLED:
    case ArrowType.DIAMOND_FILLED_START:
      context.beginPath();
      diamond(context, x, y, size);
      context.closePath();
      context.fillStyle = colors.black;
      context.fill();
      break;
    case ArrowType.TOP:
    case ArrowType.BOTTOM_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x, y);
      context.stroke();
      break;
    case ArrowType.TOP_START:
    case ArrowType.BOTTOM:
      context.beginPath();
      context.moveTo(x - size, y + size);
      context.lineTo(x, y);
      context.stroke();
      break;
    case ArrowType.ONE:
    case ArrowType.ONE_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;
    case ArrowType.ONE_MANDATORY:
    case ArrowType.ONE_MANDATORY_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.moveTo(x - 2 * size, y - size);
      context.lineTo(x - 2 * size, y + size);
      context.stroke();
      break;
    case ArrowType.MANY:
    case ArrowType.MANY_START:
      context.beginPath();
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;
    case ArrowType.ONE_OR_MANY:
    case ArrowType.ONE_OR_MANY_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;
    case ArrowType.ZERO_OR_ONE:
    case ArrowType.ZERO_OR_ONE_START:
      context.beginPath();
      context.ellipse(x - size / 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = colors.white;
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(x - size * 2, y - size);
      context.lineTo(x - size * 2, y + size);
      context.stroke();
      break;
    case ArrowType.ZERO_OR_MANY:
    case ArrowType.ZERO_OR_MANY_START:
      context.beginPath();
      context.ellipse(x - size * 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = colors.white;
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;
    case ArrowType.DEFAULT_START:
      context.beginPath();
      context.moveTo(x, y - size);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;
    default:
      logWarn(Error('unrecognized arrow type'), null, { arrow });
      break;
  }
};
export default drawArrow;
