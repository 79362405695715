import { CSSProperties } from 'react';
import styled from 'styled-components';
import { componentInterface } from 'modelInterface/components/componentInterface';
import IconClickable from 'atomicComponents/IconClickable';
import { ChevronIconName } from '@ardoq/icons';
import { IconContainer } from './atoms';
import { ComponentRepresentation } from '@ardoq/renderers';
import {
  COMPONENT_CONTEXT_ITEM_CONTAINER_BORDER,
  HEADER_CELL_VERTICAL_PADDING,
  MARGIN_PER_LEVEL,
} from '../consts';
import { ComponentLabel } from './atoms';
import { classes, createFifoCache, returnZero } from '@ardoq/common-helpers';
import { getDivBackgroundColor } from '../util';
import Context from 'context';
import Components from 'collections/components';
import {
  ACTIVE_BOX_SHADOW,
  ACTIVE_BRIGHTNESS,
  COMPONENT_ID_ATTRIBUTE,
  LI_BACKGROUND_CLASS_NAME,
} from '@ardoq/global-consts';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import SelfTruncatingComponentLabel from 'tabview/SelfTruncatingComponentLabel';
import { popoverRegistry, POPOVER_ID_ATTR } from '@ardoq/popovers';
import { getIconColor } from '@ardoq/dependency-map';
import { MeasureStyledText } from '@ardoq/dom-utils';
import { colors } from '@ardoq/design-tokens';

const componentLabelPopover = (target: Element | null) => {
  if (!target) {
    return null;
  }
  const componentId = target.getAttribute(COMPONENT_ID_ATTRIBUTE);
  if (!componentId) {
    return null;
  }
  return truncateComponentLabel({
    ...getComponentLabelParts(componentId),
    width: Infinity,
    measure: returnZero,
  });
};
const HEADER_COMPONENT_LABEL_POPOVER_ID = 'headerComponentLabelPopover';
popoverRegistry.set(HEADER_COMPONENT_LABEL_POPOVER_ID, componentLabelPopover);

const GET_CSS_CLASS_NAMES_PARAMS = {
  useAsBackgroundStyle: true,
};

const IconAndLabelContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
`;

const HeaderComponentContainer = styled.div`
  display: block;
  box-shadow: 0px 0px 1px 0px;
  &.active {
    filter: brightness(${ACTIVE_BRIGHTNESS});
    box-shadow: ${ACTIVE_BOX_SHADOW};
  }
  &.component.${LI_BACKGROUND_CLASS_NAME}.context {
    border-width: ${COMPONENT_CONTEXT_ITEM_CONTAINER_BORDER}px;
    border-color: ${colors.blue50} !important;
  }
  padding-top: ${HEADER_CELL_VERTICAL_PADDING}px;
`;

/** calculates the number of consecutive ancestors which are the same color as the component with the given address. */
const brightnessIncrease = (
  color: string,
  address: (string | null)[]
): number => {
  if (address.length <= 1) {
    return 0;
  }
  const parentId = address[address.length - 2];
  if (!parentId) {
    return 0;
  }
  const parentColor = getDivBackgroundColor(
    componentInterface.getCssClassNames(parentId, GET_CSS_CLASS_NAMES_PARAMS) ??
      ''
  );
  if (parentColor !== color) {
    return 0;
  }
  return 1 + brightnessIncrease(parentColor, address.slice(0, -1));
};

const measureHeaderComponentText = createFifoCache(1000, (text: string) =>
  MeasureStyledText.Instance.getTextWidth({
    text,
  })
);
interface HeaderComponentArgs {
  componentId: string;
  showExpander: boolean;
  isExpanded: boolean;
  expanderClicked: () => void;
  isRowHeader: boolean;
  containerStyle: CSSProperties;
  iconContainerStyle: CSSProperties;
  isHighlighted: boolean;
  address: (string | null)[];
}

const HeaderComponent = ({
  componentId,
  showExpander,
  expanderClicked,
  isExpanded,
  isRowHeader,
  containerStyle,
  iconContainerStyle,
  isHighlighted,
  address,
}: HeaderComponentArgs) => {
  const level = address.length - 1;
  const rootLevel = level === 0;
  const actualContainerStyle = {
    height:
      rootLevel || isRowHeader
        ? '100%'
        : `calc(100% - ${level * 2 * MARGIN_PER_LEVEL}px)`,
    width:
      rootLevel || isRowHeader
        ? '100%'
        : `calc(100% - ${level * 2 * MARGIN_PER_LEVEL}px)`,
    ...containerStyle,
  };

  const classNames =
    componentInterface.getCssClassNames(
      componentId,
      GET_CSS_CLASS_NAMES_PARAMS
    ) ?? '';

  if (!rootLevel) {
    if (!isRowHeader) {
      actualContainerStyle.margin = `${level * MARGIN_PER_LEVEL}px`;
    }
    const brightnessIncreaseFactor = brightnessIncrease(
      getDivBackgroundColor(classNames),
      address
    );
    const brightness =
      (1 + brightnessIncreaseFactor * 0.05) *
      (isHighlighted ? ACTIVE_BRIGHTNESS : 1);
    actualContainerStyle.filter = `brightness(${brightness})`;
  }
  const componentRepresentationData = componentInterface.getRepresentationData(
    componentId
  ) ?? {
    isImage: false,
    value: null,
  };

  const contextMenuAttribute = COMPONENT_ID_ATTRIBUTE;
  const isContext = Context?.component()?.id === componentId;
  return (
    <HeaderComponentContainer
      style={actualContainerStyle}
      className={classes(
        classNames,
        isHighlighted && 'active',
        isContext && 'context'
      )}
    >
      <IconAndLabelContainer
        className="component skipContextUpdate"
        {...{ [contextMenuAttribute]: componentId }}
        onDoubleClick={() =>
          Context.setComponent(Components.collection.get(componentId))
        }
      >
        <IconContainer $color={getIconColor(componentId, componentId, false)}>
          <ComponentRepresentation
            isImage={componentRepresentationData.isImage}
            value={componentRepresentationData.value}
            icon={componentRepresentationData.icon}
          />
        </IconContainer>
        <ComponentLabel
          style={{ width: '100%' }}
          {...{
            [POPOVER_ID_ATTR]: HEADER_COMPONENT_LABEL_POPOVER_ID,
            [COMPONENT_ID_ATTRIBUTE]: componentId,
          }}
        >
          <SelfTruncatingComponentLabel
            {...getComponentLabelParts(componentId)}
            measure={measureHeaderComponentText}
          />
        </ComponentLabel>
        {showExpander && (
          <IconClickable
            display="inline"
            onClick={expanderClicked}
            iconName={isExpanded ? ChevronIconName.UP : ChevronIconName.DOWN}
            containerStyle={iconContainerStyle}
          />
        )}
      </IconAndLabelContainer>
    </HeaderComponentContainer>
  );
};
export default HeaderComponent;
