import { createFifoCache } from '@ardoq/common-helpers';
import { GRAPH_ITEM_LABEL_CACHE_SIZE } from '@ardoq/graph';

const canvasLabelMeasurer = (font: string) => {
  const canvas = new OffscreenCanvas(0, 0);
  const context = canvas.getContext('2d');
  if (!context) {
    return () => NaN; // 💣
  }
  context.font = font;
  return createFifoCache(
    GRAPH_ITEM_LABEL_CACHE_SIZE,
    (text: string) => context.measureText(text).width
  );
};

export default canvasLabelMeasurer;
