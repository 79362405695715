import { ViewIds } from '@ardoq/api-types';
import { Observable, shareReplay } from 'rxjs';
import { map, tap, distinctUntilChanged } from 'rxjs/operators';
import { getRelationshipDiagramViewModel$ } from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramViewModel$';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import type {
  RelationshipsViewProperties,
  RelationshipsViewSettings,
} from '../types';
import { ALL_EXCEPT_PARENTS } from 'tabview/relationsD3View/consts';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { notifyViewLoading } from 'tabview/actions';
import { relationshipsViewDistinctReferenceKey } from '@ardoq/graph';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import {
  dispatchAction,
  action$,
  ofType,
  extractPayload,
} from '@ardoq/rxbeach';
import { updateViewProperties } from '@ardoq/view-settings';
import buildRelationshipsViewProperties from './buildRelationshipsViewProperties';
import { layoutBegin } from '../actions';

const relevantProps = new Set<string>([
  'name',
  'type',
  'typeId',
  'parent',
  'displayText',
  'image',
  'icon',
  'color',
]);

export const isOptimizeLayoutDisabled$ = action$.pipe(
  ofType(layoutBegin),
  extractPayload(),
  map(({ layout }) => layout === 'packLayout'),
  distinctUntilChanged(),
  shareReplay(1)
);

export const unfilteredRelationshipsViewSettings$ =
  getViewSettingsStreamWithChanges<RelationshipsViewSettings>(
    ViewIds.RELATIONSHIPS_3
  );

const relationshipViewModel$ = (viewInstanceId: string) =>
  getRelationshipDiagramViewModel$({
    viewId: ViewIds.RELATIONSHIPS_3,
    viewSettings$: unfilteredRelationshipsViewSettings$,
    viewSettingsToIgnore: [
      settingsBarConsts.IS_LEGEND_ACTIVE,
      settingsBarConsts.FULLSCREEN_KEY,
      'bundleRelationships',
      'collapsedGroupIds',
      'highlightDisconnectedComponents',
      'lastClickedNodeId',
    ],
    overrideTraverseOptions: ({ menuConfig, referenceTypeMap }) => {
      const [outgoingReferenceTypes, incomingReferenceTypes] = [
        menuConfig.outgoingReferenceTypes,
        menuConfig.incomingReferenceTypes,
      ].map(referenceTypes =>
        referenceTypes
          ? new Set(
              [...referenceTypes].flatMap(referenceType =>
                referenceType === ALL_EXCEPT_PARENTS
                  ? [...referenceTypeMap.values()].flat()
                  : referenceType
              )
            )
          : referenceTypes
      );
      return {
        ...menuConfig,
        maxDegreesIncoming: 0, // this view does not allow individual degrees. but if the user had access to the beta, they might have a non-zero value left over in their settings.
        maxDegreesOutgoing: 0, // this view does not allow individual degrees. but if the user had access to the beta, they might have a non-zero value left over in their settings.
        outgoingReferenceTypes,
        incomingReferenceTypes,
      };
    },
    getDistinctReferenceKey: relationshipsViewDistinctReferenceKey,
    viewInstanceId,
    shouldCollapseGraph: false,
    hasRelevantChanges: changedKeys => {
      const labelFormattingFields =
        filterInterface.getComponentLabelFormattingFields();
      return changedKeys.some(
        key => labelFormattingFields.has(key) || relevantProps.has(key)
      );
    },
  }).pipe(
    tap(() => notifyViewLoading({ viewInstanceId, isBusy: true })),
    map(buildRelationshipsViewProperties(viewInstanceId))
  );

export const getViewModel$ =
  () =>
  (viewInstanceId: string): Observable<RelationshipsViewProperties> =>
    relationshipViewModel$(viewInstanceId).pipe(
      tap(viewProperties =>
        dispatchAction(
          updateViewProperties({
            viewId: ViewIds.RELATIONSHIPS_3,
            viewProperties,
          })
        )
      )
    );
