import { useEffect, useState } from 'react';
import { SearchBackend, SearchType } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import { GremlinSearchStateShape } from 'search/Gremlin/types';
import { usePaginationGremlinSearch } from 'search/Gremlin/usePaginationGremlinSearch';
import GremlinSearchError from 'search/Gremlin/GremlinResults/GremlinSearchError';
import GremlinResults from 'search/Gremlin/GremlinResults/GremlinResults';
import { metamodelQueryResults$ } from 'search/Gremlin/gremlinResults$';
import { LoadingWrapper } from '@ardoq/status-ui';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { FlexBox } from '@ardoq/layout';

type OwnProps = {
  query: string;
};

const GremlinSearchTableWithLoader = ({
  query,
  results,
  totalResults,
  showRawResults,
  syntaxError,
  searchError,
}: OwnProps & GremlinSearchStateShape) => {
  const model = {
    backend: SearchBackend.GREMLIN,
    name: '',
    parameterQueryId: null,
    query,
    supportedParams: [],
    type: SearchType.METAMODEL_QUERY,
  };

  const { resultsId, loadPaginatedResults, doPaginatedSearch } =
    usePaginationGremlinSearch({
      model,
      queryParams: {},
      searchType: SearchType.METAMODEL_QUERY,
    });

  const [isSearching, setIsSearching] = useState(true);
  useEffect(() => {
    setIsSearching(true);
    doPaginatedSearch();
    // Adding doPaginatedSearch as a dependency here will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setIsSearching(false);
  }, [results]);

  return (
    <>
      {searchError && <GremlinSearchError syntaxError={syntaxError} />}
      {results && (
        <LoadingWrapper loading={isSearching}>
          <GremlinResults
            result={results}
            showRaw={showRawResults}
            loadResults={loadPaginatedResults}
            totalResults={totalResults}
            resultsId={resultsId}
          />
        </LoadingWrapper>
      )}
      {!searchError && !results && (
        <FlexBox justify="center" paddingTop="xxlarge">
          <ArdoqLoaderSpinner
            color={LoaderColor.GRAY}
            size={LoaderSize.MEDIUM}
          />
        </FlexBox>
      )}
    </>
  );
};

export default connect(GremlinSearchTableWithLoader, metamodelQueryResults$);
