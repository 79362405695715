export const prodLicense = {
  company: 'Ardoq AS',
  date: '01/15/2025',
  distribution: true,
  domains: ['ardoq.com'],
  fileSystemAllowed: false,
  licensefileversion: '1.1',
  localhost: false,
  oobAllowed: false,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Ardoq.com',
  subscription: '02/14/2028',
  type: 'project',
  version: '2.6',
  key: '4976a90b82a046b7f34fc994898b60dd05425730',
};
