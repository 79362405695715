import styled from 'styled-components';
import { range } from 'lodash';
import { ComponentMatrixGroup, ComponentMatrixViewModel } from '../types';
import {
  COMPONENT_HIERARCHY_PADDING,
  MAIN_GRID_LEFT_COLUMN_MAX_WIDTH_VIEWPORT_UNITS,
  ROW_HEADER_HIERARCHY_INDENTATION,
} from '../consts';
import { ColumnLevelHeaderCell, LevelHeaderCell } from './atoms';
import { INCOMING_SUFFIX, OUTGOING_SUFFIX } from '../consts';
import { Icon, IconName } from '@ardoq/icons';
import { columnHeadersAreaGridTemplateRows } from '../util';
import { colors } from '@ardoq/design-tokens';
import { TextOverflow } from '@ardoq/popovers';

const stripCollapsedLevelLabels = <T,>(
  groupsByDepth: ComponentMatrixGroup[][],
  labels: T[]
) => {
  const truncated = truncateLevelLabels(groupsByDepth, labels);
  return [
    ...truncated,
    ...range(groupsByDepth.length - truncated.length).map(() => ''),
  ];
};

type LevelLabelProperties = { label: string };
const LevelLabel = ({ label }: LevelLabelProperties) => {
  const isOutgoingReference = label.endsWith(OUTGOING_SUFFIX);
  const isIncomingReference = label.endsWith(INCOMING_SUFFIX);
  const isReference = isOutgoingReference || isIncomingReference;
  return isReference ? (
    <>
      <TextOverflow>{label.slice(0, -OUTGOING_SUFFIX.length)}</TextOverflow>
      <Icon
        style={{ verticalAlign: 'middle', fontSize: '16px' }}
        iconName={
          isOutgoingReference ? IconName.ARROW_FORWARD : IconName.ARROW_BACK
        }
      />
    </>
  ) : (
    <TextOverflow>{label}</TextOverflow>
  );
};

const truncateLevelLabels = <T,>(
  groupsByDepth: ComponentMatrixGroup[][],
  labels: T[]
) => labels.slice(0, 1 + Math.max(0, firstExpandedLevel(groupsByDepth)));

const firstExpandedLevel = (groupsByDepth: ComponentMatrixGroup[][]) => {
  const index = groupsByDepth
    .slice()
    .reverse()
    .findIndex(groups =>
      groups.some(group => group.isExpanded && !group.isHidden)
    );
  return index < 0 ? index : groupsByDepth.length - index;
};

const ComponentMatrixLevelHeadersAreaGrid = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  max-width: ${MAIN_GRID_LEFT_COLUMN_MAX_WIDTH_VIEWPORT_UNITS}vw;
`;
const LevelHeader = styled.div`
  display: flex;
`;
const ComponentMatrixColumnLevelHeader = styled(LevelHeader)`
  background-color: ${colors.grey90}80;
`;
const ComponentMatrixRowLevelHeader = styled(LevelHeader)`
  background-color: ${colors.grey80}80;
`;
const RowLevelLabelsGrid = styled.div`
  display: grid;
  overflow: hidden;
  grid-column: 1;
  z-index: 1;
`;
const ComponentMatrixLevelHeadersBackgroundContainer = styled.div`
  position: relative;
  grid-column: 1;
`;

interface ComponentMatrixLevelHeadersAreaProperties {
  viewModel: ComponentMatrixViewModel;
}
const ComponentMatrixLevelHeadersArea = ({
  viewModel,
}: ComponentMatrixLevelHeadersAreaProperties) => {
  const {
    measurements: { mainContainerLeftColumnWidth },
    rowsByDepth,
    rowLevelLabels: viewModelRowLevelLabels,
    columnLevelLabels: viewModelColumnLevelLabels,
    columnsByDepth,
  } = viewModel;
  if (mainContainerLeftColumnWidth === 0) {
    return null;
  }

  const rowLevelLabels = stripCollapsedLevelLabels(
    rowsByDepth,
    viewModelRowLevelLabels
  )
    .map((label, index) => ({
      label,
      indentation: index * ROW_HEADER_HIERARCHY_INDENTATION,
    }))
    .filter(({ label }) => label !== COMPONENT_HIERARCHY_PADDING);
  const columnLevelLabels = stripCollapsedLevelLabels(
    columnsByDepth,
    viewModelColumnLevelLabels
  );

  return (
    <ComponentMatrixLevelHeadersAreaGrid
      style={{
        width: mainContainerLeftColumnWidth,
        gridTemplateRows: columnHeadersAreaGridTemplateRows(columnsByDepth),
      }}
    >
      <ComponentMatrixLevelHeadersBackgroundContainer
        style={{
          gridRow: `1 / ${columnLevelLabels.length + 2}`,
        }}
      >
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          style={
            /* due to our exporter ignoring the computed styles of svg nodes, these properties must be set directly on the style attribute. */
            {
              height: '100%',
              width: '100%',
              position: 'absolute',
            }
          }
        >
          <path d="M0 0 L0 100 L100 100 L0 0" fill={colors.grey80} />
          <path d="M0 0 L100 0 L100 100 L0 0" fill={colors.grey90} />
        </svg>
      </ComponentMatrixLevelHeadersBackgroundContainer>
      {columnsByDepth.map((_, level) => {
        const currentLabel = columnLevelLabels[level];
        if (!currentLabel || currentLabel === COMPONENT_HIERARCHY_PADDING) {
          return null;
        }

        return (
          <ColumnLevelHeaderCell key={level} style={{ gridRow: level + 1 }}>
            <ComponentMatrixColumnLevelHeader>
              <LevelLabel label={currentLabel} />
            </ComponentMatrixColumnLevelHeader>
          </ColumnLevelHeaderCell>
        );
      })}
      <RowLevelLabelsGrid style={{ gridRow: columnLevelLabels.length + 1 }}>
        {rowLevelLabels.map((rowLevelLabel, index) => {
          return (
            <LevelHeaderCell
              key={`${rowLevelLabel.label}-${index}`}
              style={{
                gridRow: index + 1,
                marginLeft: rowLevelLabel.indentation,
              }}
            >
              <ComponentMatrixRowLevelHeader>
                <LevelLabel label={rowLevelLabel.label} />
              </ComponentMatrixRowLevelHeader>
            </LevelHeaderCell>
          );
        })}
      </RowLevelLabelsGrid>
    </ComponentMatrixLevelHeadersAreaGrid>
  );
};

export default ComponentMatrixLevelHeadersArea;
