import { ComponentProps } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { getRightMenu } from 'tabview/blockDiagram/menus';
import { connect, useStream } from '@ardoq/rxbeach';
import { scenarioIsInSync$ } from 'scenarioIsInSync$';
import { combineLatest, map } from 'rxjs';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import type {
  AggregatedReferenceType,
  OnViewSettingsUpdate,
} from '@ardoq/data-model';
import type { ModernizedBlockDiagramViewSettings } from 'tabview/blockDiagram/types';
import { getIsViewBusy$ } from '../isViewBusy$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { SettingsBar } from '@ardoq/settings-bar';
import getLeftMenu from '../modernized/getLeftMenu';

type ModernizedScenarioSyncedSettingsBarProps = {
  scenarioIsInSync: { isInSync: boolean };
  viewSettings: ModernizedBlockDiagramViewSettings;
  viewInstanceId: string;
  getRightMenuArgs: Omit<
    Parameters<typeof getRightMenu>[0],
    'viewSettings' | 'isInSync' | 'isViewpointMode' | 'additional'
  >;
  getLeftMenuArgs: {
    traversedOutgoingReferenceTypes: AggregatedReferenceType[];
    traversedIncomingReferenceTypes: AggregatedReferenceType[];
    referenceTypes: AggregatedReferenceType[];
    viewId:
      | ViewIds.BLOCK_DIAGRAM
      | ViewIds.METAMODEL
      | ViewIds.MODERNIZED_BLOCK_DIAGRAM;
    onViewSettingsUpdate: OnViewSettingsUpdate;
    activeGroups: string[];
    isViewpointMode: boolean;
  };
} & Omit<ComponentProps<typeof SettingsBar>, 'rightMenu' | 'leftMenu'>;

const ModernizedScenarioSyncedSettingsBar = ({
  scenarioIsInSync,
  getRightMenuArgs,
  getLeftMenuArgs,
  viewSettings,
  viewInstanceId,
  viewId,
  ...props
}: ModernizedScenarioSyncedSettingsBarProps) => {
  const isViewBusy = useStream(getIsViewBusy$(viewInstanceId));

  return (
    <SettingsBar
      {...props}
      rightMenu={getRightMenu({
        ...getRightMenuArgs,
        viewSettings,
        isInSync: scenarioIsInSync.isInSync,
      })}
      leftMenu={getLeftMenu({
        ...getLeftMenuArgs,
        isViewBusy: Boolean(isViewBusy),
        viewSettings,
      })}
      viewId={viewId}
    />
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<ModernizedBlockDiagramViewSettings>(
    ViewIds.MODERNIZED_BLOCK_DIAGRAM
  );

export default connect(
  ModernizedScenarioSyncedSettingsBar,
  combineLatest([scenarioIsInSync$, viewSettings$, isViewpointMode$]).pipe(
    map(([scenarioIsInSync, viewSettings, { isViewpointMode }]) => ({
      scenarioIsInSync,
      viewSettings: viewSettings.currentSettings,
      isViewpointMode,
    }))
  )
);
