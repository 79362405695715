import { INode, Visual } from '@ardoq/yfiles';
import CanvasRectangularGroupVisual from './CanvasRectangularGroupVisual';
import CanvasGroupStyle from './CanvasGroupStyle';

class CanvasRectangularGroupStyle extends CanvasGroupStyle {
  protected override createCanvasVisual(
    group: INode,
    fill: string,
    stroke: string
  ): Visual | null {
    return new CanvasRectangularGroupVisual(group, fill, stroke);
  }
}
export default CanvasRectangularGroupStyle;
