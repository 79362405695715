const MAXIMUM_DURATION = 0x7fffffff;
// This maximumDuration may look a bit strange. The purpose is to tell the
// layout engine to not spend more time than need in the according
// algorithms if we have a large graph, but for small graphs, take as
// much time as needed to create a optimal solution. The idea being
// that a large graph is anyway not too interesting with a hierarchic
// or tabular layout.
// Also see Jira ARD-1002 for more context.

/** @returns the maximum amount of time, in milliseconds, which a graph layout stage should spend on a graph with the given node and edge counts. */
const maximumGraphLayoutDuration = (nodeCount: number, edgeCount: number) =>
  Math.min(
    MAXIMUM_DURATION,
    Math.round(MAXIMUM_DURATION / (nodeCount + edgeCount) ** 2)
  );

export default maximumGraphLayoutDuration;
