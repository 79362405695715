import { actionCreator } from '@ardoq/rxbeach';
import { AdvancedSearchResult, UnknownDocumentType } from '@ardoq/api-types';

export const loadQuery = actionCreator('[SearchResultTable] LOAD_QUERY');

export type PayloadLoadQuerySuccess = {
  readonly total: number;
  readonly results: AdvancedSearchResult<UnknownDocumentType>[];
};
export const loadQuerySuccess = actionCreator<PayloadLoadQuerySuccess>(
  '[SearchResultTable] LOAD_QUERY_SUCCESS'
);

export type PayloadPageSelect = {
  readonly page: number;
};
export const pageSelect = actionCreator<PayloadPageSelect>(
  '[SearchResultTable] PAGE_SELECT'
);

export type PayloadSortChange = {
  readonly fieldName?: string;
};
export const sortChange = actionCreator<PayloadSortChange>(
  '[SearchResultTable] SORT_CHANGE'
);

export type PayloadFieldSelect = {
  readonly fieldNames: string[];
};
export const fieldSelect = actionCreator<PayloadFieldSelect>(
  '[SearchResultTable] FIELD_SELECT'
);

export type PayloadLoadQueryError = {
  readonly errorMsg: string;
};
export const loadQueryError = actionCreator<PayloadLoadQueryError>(
  '[SearchResultTable] LOAD_QUERY_ERROR'
);
