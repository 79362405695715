import styled from 'styled-components';
import { useRef } from 'react';
import { connectInstance } from '@ardoq/rxbeach';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import { VisualizationContainer } from 'tabview/ViewContainer';
import {
  HierarchicalTreemapNode,
  HierarchicalTreemapViewProperties,
  TreemapHierarchyNode,
} from '../../types';
import getViewModel$ from '../../viewModel/viewModel$';
import SunburstSettingsBar from './SunburstSettingsBar';
import * as d3 from 'd3';
import { ViewIds } from '@ardoq/api-types';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { truncateSvgLabel } from 'utils/measureText';
import { useResizeObserver } from '@ardoq/hooks';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

const ARC_PADDING = 1;

const arcInnerRadius = ({ y0 }: TreemapHierarchyNode) => y0;
const arcOuterRadius = ({ y1 }: TreemapHierarchyNode) => y1 - ARC_PADDING;
const arc = (diameter: number) =>
  d3
    .arc<d3.HierarchyRectangularNode<HierarchicalTreemapNode>>()
    .startAngle(({ x0 }) => x0)
    .endAngle(({ x1 }) => x1)
    .padAngle(({ x0, x1 }) =>
      Math.min((x1 - x0) / 2, (2 * ARC_PADDING) / diameter)
    )
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius);

const layout = (radius: number) =>
  d3.partition<HierarchicalTreemapNode>().size([2 * Math.PI, radius / 2]);

const LABEL_SIZE = 10;
const Label = styled.text`
  text-anchor: middle;
  dominant-baseline: middle;
  font: ${LABEL_SIZE}px ${ARDOQ_DEFAULT_FONT_FAMILY};
`;

const SunburstView = (props: HierarchicalTreemapViewProperties) => {
  const {
    viewModel: { hierarchy },
    viewSettings: { maxDepth },
  } = props;

  const viewContainer = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver(viewContainer);
  const diameter = Math.min(width, height);
  const sunburstRootNode = layout(diameter)(hierarchy);
  const getArc = arc(diameter);

  const descendants = sunburstRootNode
    .descendants()
    .filter(
      node =>
        node !== sunburstRootNode && (maxDepth <= 0 || node.depth <= maxDepth)
    );

  return (
    <SettingsBarAndViewContainer>
      <div className="menuContainer">
        <SunburstSettingsBar {...props} viewContainer={viewContainer} />
      </div>
      <VisualizationContainer
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
        ref={viewContainer}
      >
        <svg style={{ height: '100%', width: '100%' }}>
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            {descendants.map(node => (
              <path
                key={node.data.id}
                className={node.data.className ?? undefined}
                d={getArc(node) ?? undefined}
              />
            ))}
            {descendants.map(node => {
              const {
                x0,
                x1,
                y0,
                y1,
                value,
                data: { id, label },
              } = node;
              const x = (((x0 + x1) / 2) * 180) / Math.PI;
              const y = (y0 + y1) / 2;
              const labelTransform = `rotate(${
                x - 90
              }) translate(${y}, 0) rotate(${x < 180 ? 0 : 180})`;
              const labelText = `${label} (${value})`;
              const labelWidth = arcOuterRadius(node) - arcInnerRadius(node);
              const labelDisplayText = truncateSvgLabel(
                labelText,
                labelWidth,
                ARDOQ_DEFAULT_FONT_FAMILY,
                LABEL_SIZE
              );
              return (
                <Label key={`text_${id}`} transform={labelTransform}>
                  {labelDisplayText}
                </Label>
              );
            })}
          </g>
        </svg>
      </VisualizationContainer>
    </SettingsBarAndViewContainer>
  );
};

export default connectInstance(SunburstView, getViewModel$(ViewIds.SUNBURST));
