import { partition } from 'lodash';
import {
  ConstraintSource,
  ResolvedLayoutConstraint,
} from '../../proteanDiagram/view/types';
import { HierarchicLayoutOptions } from '../../proteanDiagram/types';
import { GraphItem, RelationshipDiagramViewModel } from '@ardoq/graph';
import {
  combineConstraints,
  nodesAndGroupsFromGraphViewDataModel,
} from './util';

const parentDepth = (item: GraphItem): number =>
  item.parent ? parentDepth(item.parent) + 1 : 1;

const constraintValueFromNode = (
  graphNode: GraphItem,
  constraintsMap: Map<string, ResolvedLayoutConstraint>
) => constraintsMap.get(graphNode.id)?.[1] ?? 0;

/** @returns an array starting with the most distant parent of graphNode, and ending with graphNode. */
const getAncestors = (graphNode: GraphItem): GraphItem[] =>
  graphNode.parent
    ? [...getAncestors(graphNode.parent), graphNode]
    : [graphNode];

/** @returns a function that will find the layer constraint for a given node and apply a resolved value, which respects the layer constraints of a node's ancestors. */
const applyHierarchyToConstraintForGraphNode =
  (constraints: Map<string, ResolvedLayoutConstraint>, maxDepth: number) =>
  (graphNode: GraphItem) => {
    const nodeId = graphNode.id;
    if (!constraints.has(nodeId)) {
      constraints.set(nodeId, [nodeId, 0, 0, ConstraintSource.GENERATED]);
    }
    const constraint = constraints.get(nodeId)!;

    const resolvedConstraintValue = getAncestors(graphNode).reduce(
      (result, current, distanceFromRoot) =>
        result +
        constraintValueFromNode(current, constraints) *
          10 ** (maxDepth - distanceFromRoot),
      0
    );
    constraint[2] = resolvedConstraintValue;
  };

const resolveHierarchicalConstraints = (
  {
    layerConstraints,
    sequenceConstraints,
    layoutRules = [],
  }: Pick<
    HierarchicLayoutOptions,
    'layerConstraints' | 'sequenceConstraints' | 'layoutRules'
  >,
  viewModel: RelationshipDiagramViewModel
) => {
  const [sequenceConstraintRules, layerConstraintRules] = partition(
    layoutRules.filter(
      ({ dimension }) => dimension === 'sequence' || dimension === 'layer'
    ),
    ({ dimension }) => dimension === 'sequence'
  );
  const graphNodes = nodesAndGroupsFromGraphViewDataModel(viewModel);

  const resolvedSequenceConstraints = combineConstraints(
    sequenceConstraints,
    sequenceConstraintRules,
    graphNodes
  );
  const resolvedLayerConstraints = combineConstraints(
    layerConstraints,
    layerConstraintRules,
    graphNodes
  );

  const maxDepth = Math.max(...graphNodes.map(parentDepth));
  const [
    applyHierarchyToSequenceConstraintForGraphNode,
    applyHierarchyToLayerConstraintForGraphNode,
  ] = [resolvedSequenceConstraints, resolvedLayerConstraints].map(constraints =>
    applyHierarchyToConstraintForGraphNode(constraints, maxDepth)
  );
  graphNodes.forEach(applyHierarchyToSequenceConstraintForGraphNode);
  graphNodes.forEach(applyHierarchyToLayerConstraintForGraphNode);

  return {
    layerConstraints: [...resolvedLayerConstraints.values()],
    sequenceConstraints: [...resolvedSequenceConstraints.values()],
  };
};

export default resolveHierarchicalConstraints;
