import { IconName } from '@ardoq/icons';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import {
  type ButtonConfig,
  type DropdownConfig,
  SettingsType,
} from '@ardoq/view-settings';
import type { RelationshipDiagramViewSettings } from '@ardoq/graph';
import { ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  relationshipDiagramCollapseAllGroups,
  relationshipDiagramExpandAllGroups,
} from './actions';
import { BooleanOrAuto } from 'tabview/types';
import { popoverRegistry } from '@ardoq/popovers';
import { WILDCARD } from '@ardoq/common-helpers';
import { noop } from 'lodash';

const NO_GROUPING_TO_EXPAND_POPOVER = 'noGroupingToExpand';
const NO_GROUPING_TO_COLLAPSE_POPOVER = 'noGroupingToCollapse';
popoverRegistry.set(NO_GROUPING_TO_EXPAND_POPOVER, () => 'No groups to expand');
popoverRegistry.set(
  NO_GROUPING_TO_COLLAPSE_POPOVER,
  () => 'No groups to collapse'
);

export const expandAll = (
  { collapsedGroupIds }: RelationshipDiagramViewSettings,
  viewId: ViewIds,
  isDisabled: boolean
): ButtonConfig => ({
  id: 'expandAll',
  label: 'Expand all',
  iconName: IconName.EXPAND_ALL,
  isActive: !isDisabled && collapsedGroupIds.length === 0,
  onClick: !isDisabled
    ? () =>
        dispatchAction(relationshipDiagramExpandAllGroups({ viewId }), viewId)
    : noop,
  type: SettingsType.BUTTON,
  isDisabled,
  popoverId: isDisabled ? NO_GROUPING_TO_EXPAND_POPOVER : undefined,
});

const allCollapsed = (collapsedGroupIds: string[]) =>
  collapsedGroupIds.some(id => id === WILDCARD);
export const collapseAll = (
  { collapsedGroupIds }: RelationshipDiagramViewSettings,
  viewId: ViewIds,
  isDisabled: boolean
): ButtonConfig => ({
  id: 'collapseAll',
  label: 'Collapse all',
  iconName: IconName.COLLAPSE_ALL,
  isActive: !isDisabled && allCollapsed(collapsedGroupIds),
  onClick: !isDisabled
    ? () =>
        dispatchAction(relationshipDiagramCollapseAllGroups({ viewId }), viewId)
    : noop,
  type: SettingsType.BUTTON,
  isDisabled,
  popoverId: isDisabled ? NO_GROUPING_TO_COLLAPSE_POPOVER : undefined,
});

type BooleanOrAutoDropdownArgs = Pick<
  DropdownConfig,
  'id' | 'label' | 'iconName' | 'isActive'
> & {
  currentValue: BooleanOrAuto;
  applyValue: (value: BooleanOrAuto) => void;
};
export const booleanOrAutoDropdown = ({
  id,
  label,
  iconName,
  isActive,
  currentValue,
  applyValue,
}: BooleanOrAutoDropdownArgs) => ({
  id,
  label,
  iconName,
  isActive,
  type: SettingsType.DROPDOWN,
  options: [
    {
      name: 'auto',
      label: 'Auto',
      isActive: currentValue === BooleanOrAuto.Auto,
      onClick: () => applyValue(BooleanOrAuto.Auto),
      type: DropdownOptionType.OPTION,
    },
    {
      name: 'on',
      label: 'On',
      isActive: currentValue === BooleanOrAuto.True,
      onClick: () => applyValue(BooleanOrAuto.True),
      type: DropdownOptionType.OPTION,
    },
    {
      name: 'off',
      label: 'Off',
      isActive: currentValue === BooleanOrAuto.False,
      onClick: () => applyValue(BooleanOrAuto.False),
      type: DropdownOptionType.OPTION,
    },
  ],
});

export const SEPARATE_REFERENCES_TOGGLE = {
  id: 'separateReferences',
  label: 'Separate references',
  iconName: IconName.SEPARATE_REFERENCES,
  type: SettingsType.TOGGLE,
};
