import styled, { css } from 'styled-components';
import { colors, spacing } from '@ardoq/design-tokens';
import { DependencyMatrixTable } from '@ardoq/graph';
import { text2 } from '@ardoq/typography';
import {
  DEPENDENCY_MATRIX_COLUMN_HEADER_RIGHT_PADDING,
  DEPENDENCY_MATRIX_COLUMN_HEADER_WIDTH,
  DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN,
  DEPENDENCY_MATRIX_ROW_HEADER_HORIZONTAL_PADDING,
  DEPENDENCY_MATRIX_ROW_HEADER_WIDTH,
} from './consts';

const Header = styled.th`
  font-size: 12px;
`;

export const ColumnHeader = styled(Header)`
  background-color: transparent;
  text-transform: none;
  padding: ${spacing.s4};
  vertical-align: bottom;
  border: 1px solid ${colors.grey80};
  font-weight: 40;
  white-space: nowrap;
  height: 140px;
  width: 30px;
  min-width: 30px;
  position: sticky;
  top: 0;
  z-index: 2;
  pointer-events: none;

  > div {
    pointer-events: all;
  }

  &:first-child {
    width: 200px;
  }

  &:not(:first-child) {
    padding-left: 1px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
`;

const SELECTED_ROW_COLOR = `color-mix(in srgb, ${colors.blue60} 16%, transparent)`;
const SELECTED_ROW_HEADER_COLOR = `color-mix(in srgb, ${colors.blue60} 16%, white)`;

export const Row = styled.tr<{
  $isLastClicked: boolean;
  $isSelected: boolean;
}>`
  background-color: ${props =>
    props.$isSelected
      ? SELECTED_ROW_COLOR
      : props.$isLastClicked
        ? colors.grey95
        : 'inherit'};
`;

export const RowHeader = styled(Header)<{ $isSelected?: boolean }>`
  vertical-align: top;
  padding: ${spacing.s4} ${DEPENDENCY_MATRIX_ROW_HEADER_HORIZONTAL_PADDING}px;
  left: 0;
  top: auto;
  width: ${DEPENDENCY_MATRIX_ROW_HEADER_WIDTH}px;
  z-index: 1;
  text-align: right;
  border: none !important;
  border-right: 1px solid ${colors.grey80} !important;
  white-space: nowrap;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  position: sticky;
  color: ${colors.grey25}99;
  font-size: 12px;
  font-weight: normal;
  background-color: ${({ $isSelected }) =>
    $isSelected ? SELECTED_ROW_HEADER_COLOR : colors.white};
`;

export const DependencyMatrixTableHead = styled.thead`
  background-color: white;
  border-bottom: 1px solid ${colors.grey80};
  position: sticky;
  top: 0;
  z-index: 2;

  > tr {
    > th:first-child {
      position: sticky;
      left: 0;
      z-index: 10;
      background-color: white;
      /* needed to override selectors under .table-bordered in bootstrap.css */
      border-top: 0 !important;
      border-left: 0 !important;
    }
  }
`;

export const VerticalLabelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  line-height: 18px;
  position: relative;
  color: ${colors.grey35};
`;

export const HorizontalLabelContainer = styled(VerticalLabelContainer)`
  width: ${DEPENDENCY_MATRIX_COLUMN_HEADER_WIDTH}px;
  transform: rotate(315deg) translate(30px, -55px);
  flex-direction: row;
  justify-content: flex-start;
  padding-right: ${DEPENDENCY_MATRIX_COLUMN_HEADER_RIGHT_PADDING}px;
  position: relative;
`;

const getFullWidthContentAttribute = (
  attrName: 'before' | 'after',
  isTextOverflowed?: boolean
) => {
  if (!isTextOverflowed) {
    return '';
  }

  return css`
    &::${attrName} {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `;
};

type IsTextOverflowedProp = { $isTextOverflowed?: boolean };
export const HorizontalIconContainer = styled.div<IsTextOverflowedProp>`
  margin-right: ${DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN}px;
  display: inline;

  ${({ $isTextOverflowed }) =>
    getFullWidthContentAttribute('after', $isTextOverflowed)}
`;

export const VerticalIconContainer = styled.div<IsTextOverflowedProp>`
  margin-left: ${DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN}px;

  ${({ $isTextOverflowed }) =>
    getFullWidthContentAttribute('before', $isTextOverflowed)}
`;

export const Text = styled.span`
  ${
    text2 /* if this changes, DEPENDENCY_MATRIX_HEADER_COMPONENT_FONT_SIZE must be updated */
  };
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  color: ${colors.grey35};
`;

type IsExportingProp = {
  $isExporting: boolean;
};

export const ScrollContainer = styled.div<IsExportingProp>`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  ${({ $isExporting }) =>
    $isExporting &&
    css`
      flex-basis: 100%;
      overflow: visible;
      width: fit-content !important;
      height: fit-content !important;
    `}
`;

export const DependencyMatrixTableComponent = styled(
  DependencyMatrixTable
)<IsExportingProp>`
  top: 0;
  z-index: 1;
  position: ${({ $isExporting }) => ($isExporting ? 'static' : 'absolute')};
`;

export const DependencyMatrixTableBody = styled.tbody<IsExportingProp>`
  ${({ $isExporting }) =>
    !$isExporting &&
    css`
      position: relative;
      td:hover::after {
        background-color: ${colors.grey95};
        content: '';
        top: 0;
        position: absolute;
        bottom: 0;
        width: inherit;
        z-index: -1;
      }
    `}
`;

export const DependencyMatrixCell = styled.td`
  width: 30px;
  min-width: 30px;

  &&&& {
    height: 30px;
    padding: 0;
    vertical-align: top;
  }

  border-right: 1px solid ${colors.grey80};
  border-bottom: 1px solid ${colors.grey80};
`;

export const CheckedWrapper = styled.div<{
  $readOnly: boolean;
}>`
  margin-top: 2px;
  display: flex;
  justify-content: center;
  max-height: 20px;

  &:last-child {
    margin-bottom: 6px;
  }

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      &:hover {
        cursor: default;
      }
    `}
`;

export const ReferenceCellElement = styled(DependencyMatrixCell)<{
  $isValidReference: boolean;
  $highlightAsLastClicked: boolean;
}>`
  ${({ $isValidReference }) =>
    !$isValidReference &&
    css`
      background-color: ${colors.white};
      cursor: not-allowed;
      border: none;
    `}

  ${({ $highlightAsLastClicked }) =>
    $highlightAsLastClicked &&
    css`
      && {
        background-color: ${colors.blue95};
        border: 2px solid ${colors.blue50} !important;
      }
    `}
`;

export const VerticalVirtualizedEmptySpace = styled.tr<{
  $isExporting: boolean;
  $height?: number;
}>`
  && {
    background-color: ${colors.white};
  }

  ${({ $isExporting }) =>
    $isExporting &&
    css`
      display: none;
    `}

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `}
`;

export const HorizontalVirtualizedEmptySpace = styled.th<
  IsExportingProp & {
    $width?: number;
  }
>`
  && {
    background-color: ${colors.white};
  }

  ${({ $isExporting }) =>
    $isExporting &&
    css`
      display: none;
    `}

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

export const ContextMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const ExportContainer = styled.div<IsExportingProp>`
  ${({ $isExporting }) =>
    $isExporting &&
    css`
      min-height: fit-content;
      min-width: fit-content;
      display: flex;
      gap: 120px;
      flex-grow: 1 !important;
    `}
`;
