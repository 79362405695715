import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { HasHoveredComponentId, SetsHoveredComponentId } from '@ardoq/graph';
import { colors, s8, vizGreen40, vizPurple50 } from '@ardoq/design-tokens';

interface NodeProperties {
  isIncoming: boolean;
}
interface NodeComponentProperties
  extends NodeProperties,
    HasHoveredComponentId,
    SetsHoveredComponentId,
    HTMLAttributes<HTMLDivElement> {
  componentId: string;
}
const NodeElement = styled.div<{ $isIncoming: boolean; $isActive: boolean }>`
  display: flex;
  flex: 1;
  transition-property: background-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  ${({ $isIncoming, $isActive }) => css`
    background-color: ${$isIncoming
      ? $isActive
        ? vizGreen40
        : colors.green95
      : $isActive
        ? colors.purple50
        : vizPurple50};
    border: 1px solid ${$isIncoming ? colors.green50 : colors.purple25};
  `}
  margin: ${s8};
  min-height: 24px;
  border-radius: 1000px;
`;

const Node = ({
  componentId,
  hoveredComponentId,
  setHoveredComponentId,
  isIncoming,
  ...elementProps
}: NodeComponentProperties) => (
  <NodeElement
    {...elementProps}
    $isIncoming={isIncoming}
    $isActive={hoveredComponentId === componentId}
    onMouseOver={e => {
      setHoveredComponentId(componentId);
      e.stopPropagation();
    }}
  />
);
export default Node;
