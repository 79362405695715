import { contextInterface } from 'modelInterface/contextInterface';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import { TrackingLocation } from 'tracking/types';
import { ArdoqId } from '@ardoq/api-types';

export const closeOpenWorkspaces = (excludeWorkspaces: ArdoqId[]) => {
  const workspaceIdsToClose = contextInterface
    .getLoadedWorkspaceIds()
    .filter((workspaceId: ArdoqId) => !excludeWorkspaces.includes(workspaceId));
  contextInterface.closeWorkspaces(workspaceIdsToClose, {});
};

type OpenWorkspaceOptions = {
  trackingLocation?: TrackingLocation;
};

export const openWorkspace = async (
  workspaceId: ArdoqId,
  { trackingLocation }: OpenWorkspaceOptions = {}
) => {
  if (contextInterface.isContextWorkspace(workspaceId)) {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
    );
  } else {
    await contextInterface.loadWorkspaces({
      contextWorkspaceId: workspaceId,
      workspaceIds: [],
      trackingLocation,
    });
  }
};
