import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import { ViewLegendContainer, ViewLegendContainerProps } from '@ardoq/graph';

const ZOOM_CONTROLS_HEIGHT = 36;
export const ZoomContainer = styled.div.attrs({
  className: NO_EXPORT_CLASS_NAME,
})`
  position: absolute;
  right: 8px;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export type ZoomableViewLegendContainerForwardRefType = {
  setIsVisible: (isVisible: boolean) => void;
};

type ZoomableViewLegendContainerForwardRefPops = ViewLegendContainerProps & {
  initiallyVisible: boolean;
  heightOffset: number;
};

export const ZoomableViewLegendContainerForwardRef = forwardRef<
  ZoomableViewLegendContainerForwardRefType,
  React.PropsWithChildren<ZoomableViewLegendContainerForwardRefPops>
>((props, ref) => {
  const [isVisible, setIsVisible] = useState(props.initiallyVisible);
  useImperativeHandle(ref, () => ({ setIsVisible }));
  return (
    <ZoomableViewLegendContainer
      heightOffset={props.heightOffset || 0}
      visible={isVisible}
    >
      {props.children}
    </ZoomableViewLegendContainer>
  );
});

export const ZoomableViewLegendContainer = (
  props: ViewLegendContainerProps
) => (
  <ViewLegendContainer
    {...props}
    style={{ paddingTop: ZOOM_CONTROLS_HEIGHT }}
  />
);
export const MaterialIconsText = styled.text`
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Material Icons Round';
`;

export const ViewCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;
