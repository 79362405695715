export const horizontalSpacing = {
  LARGE: '50px',
  MEDIUM: '20px',
  SMALL: '5px',
};

export const verticalSpacing = {
  LARGE: '20px',
  SMALL: '10px',
  EXTRA_SMALL: '5px',
};

export const DEFAULT_NUMBER_OF_LIST_ITEMS_TO_SHOW = 5;

export const ICON_SIZE = '24px';

export const CONTENT_MAX_WIDTH = '1200px';
export const VERTICAL_LINE_WIDTH = '4px';
