import styled from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { horizontalSpacing } from 'tabview/pagesView/constants';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { DescriptionFormat } from 'tabview/pagesView/commonUIElements';
import { colors } from '@ardoq/design-tokens';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import { ArdoqId } from '@ardoq/api-types';
import { unescapeHTML } from '@ardoq/html';
interface FieldProps {
  fieldId: string;
  text: string;
}

const LabelContainer = styled.div`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: ${horizontalSpacing.SMALL};
  font-weight: bold;
`;

const Label = styled.div`
  max-width: 400px;
`;

const FIELD_POPOVER_ID = 'pages-view-fields-popover';
const fieldPopoverRenderer = (target: Element | null) => {
  if (!(target instanceof HTMLElement)) return null;
  const containerEl = target.parentElement!;
  const labelIsOverflowing = target.offsetWidth > containerEl.offsetWidth;
  const { label, fieldId } = target.dataset;
  const description = fieldInterface.getDescription(fieldId!);
  return labelIsOverflowing || description?.length ? (
    <>
      {labelIsOverflowing && <Label>{label}</Label>}
      {description?.length ? (
        <DescriptionFormat>{unescapeHTML(description)}</DescriptionFormat>
      ) : (
        ''
      )}
    </>
  ) : null;
};

popoverRegistry.set(FIELD_POPOVER_ID, fieldPopoverRenderer);
const withFieldPopover = (fieldId: ArdoqId, label: string) => ({
  [POPOVER_ID_ATTR]: FIELD_POPOVER_ID,
  'data-field-id': fieldId,
  'data-label': label,
});

const FieldLabel = ({ fieldId, text }: FieldProps) => (
  <LabelContainer key={fieldId} className="overflowHiddenOnPrint">
    <span {...withFieldPopover(fieldId, text)}>{text}</span>
  </LabelContainer>
);

export default FieldLabel;
