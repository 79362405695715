import { Metamodel } from '@ardoq/api-types';
import { MetamodelContext } from 'metamodel/types';
import {
  getPopoverMessageFromContext,
  getWorkspaceNamesFromContext,
} from './utils';
import WorkspaceNavigationPopoverContent from './WorkspaceNavigationPopverContent';
import { dispatchAction } from '@ardoq/rxbeach';
import { createPopover, removePopover } from 'components/popover/actions';

interface WorkspaceNavigationPopoverProps {
  metamodel: Metamodel;
  context: MetamodelContext;
  children?: any;
}

const WorkspaceNavigationPopover = ({
  metamodel,
  context,
  children,
}: WorkspaceNavigationPopoverProps) => {
  const popoverMessage = getPopoverMessageFromContext(context);
  const workspaceNames = getWorkspaceNamesFromContext(metamodel, context);
  const popoverContent = WorkspaceNavigationPopoverContent({
    popoverMessage,
    workspaceNames,
  });
  return (
    <span
      style={{ display: 'inline-block', maxWidth: '100%' }}
      onMouseEnter={event =>
        dispatchAction(
          createPopover({
            popoverContent,
            targetElement: event.currentTarget,
          })
        )
      }
      onMouseLeave={() => dispatchAction(removePopover())}
    >
      {children}
    </span>
  );
};

export default WorkspaceNavigationPopover;
