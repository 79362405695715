import { debounce } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { ViewIds } from '@ardoq/api-types';
import type { OnViewSettingsUpdate } from '@ardoq/data-model';
import { createDropdownSliderOptionManager as createDropdownSliderOptionManagerBase } from '@ardoq/settings-bar';

const getUpdateSettingsDebounced = (
  onViewSettingsUpdate: OnViewSettingsUpdate
) =>
  debounce(
    (viewId, settings, persistent, settingsPath) =>
      onViewSettingsUpdate(viewId, settings, persistent, settingsPath),
    500
  );

export const createDropdownSliderOptionManager: typeof createDropdownSliderOptionManagerBase =
  (viewId, cache, onViewSettingsUpdate) =>
    createDropdownSliderOptionManagerBase(
      viewId,
      cache,
      getUpdateSettingsDebounced(onViewSettingsUpdate)
    );

export const handleStoreSetting = (
  viewId: ViewIds,
  settings: Record<string, any>
) => {
  const persistent = true;
  dispatchAction(updateViewSettings({ viewId, settings, persistent }));
};
