import { Column, DatasourceTable } from '@ardoq/table';
import { GremlinComponentResult, GremlinResultType } from '../types';
import { formatValue, navigateToEntity, showDetails } from '../utils';
import { SortByFn, SortById } from '@ardoq/pagination';
import { getIn } from 'utils/collectionUtil';
import { searchFieldLabels$ } from 'streams/fields/advancedSearchFields$';
import { connect } from '@ardoq/rxbeach';
import { SortOrder } from '@ardoq/api-types';

type StreamedProps = {
  fieldsLabels: { [fieldName: string]: string };
};

type OwnProps = {
  results: GremlinComponentResult[];
  printable?: boolean;
  sortBy: SortByFn;
  sortById: SortById;
  sortOrder?: SortOrder;
  selectedColumns: string[];
};

type GremlinResultsTableComponentProps = StreamedProps & OwnProps;

const GremlinComponentsResultTable = (
  props: GremlinResultsTableComponentProps
) => (
  <DatasourceTable
    dataSource={props.results}
    data-intercom-target="gremlin results table"
    columns={
      [
        {
          title: 'Id',
          dataIndex: 'id',
          headerStyle: { width: '1%' },
        },
        {
          title: 'Name',
          valueRender: (_: any, result: GremlinComponentResult) => (
            <a onClick={() => navigateToEntity(result)}>
              {getIn<string>(result, ['properties', 'name', 0, 'value']) || ''}
            </a>
          ),
          onHeaderClick: () =>
            props.sortBy(
              result =>
                getIn<string>(result, ['properties', 'name', 0, 'value']) || '',
              'name'
            ),
          sortOrder: props.sortById === 'name' && props.sortOrder,
        },
        ...props.selectedColumns.map(columnName => ({
          title: props.fieldsLabels[columnName] || columnName,
          dataIndex: `properties.${columnName}[0].value`,
          valueRender: formatValue,
          headerStyle: { minWidth: '100px' },
          onHeaderClick: () => props.sortBy(columnName),
          sortOrder: props.sortById === columnName && props.sortOrder,
        })),
        !props.printable && {
          headerStyle: { width: '150px' },
          valueRender: (_: any, result: GremlinComponentResult) => (
            <a onClick={() => showDetails(result, GremlinResultType.COMPONENT)}>
              Show details
            </a>
          ),
        },
      ].filter(Boolean) as Column<GremlinComponentResult>[]
    }
    renderEmptyTable={{ title: 'No results found' }}
  />
);

export default connect(GremlinComponentsResultTable, searchFieldLabels$);
