import {
  forwardRef,
  memo,
  RefObject,
  UIEvent,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { BodyCellModel, RowElementModel } from '../types';
import { BodyCell } from './atoms';
import ComponentItem from './componentItem';
import { ArdoqId } from '@ardoq/api-types';

const ComponentMatrixBodyContainer = styled.div`
  display: grid;
  grid-row: 2;
  grid-column: 2;
  overflow: auto;
  .forExport & {
    overflow: visible;
  }
`;

interface ComponentMatrixBodyProperties {
  rowModels: RowElementModel[];
  columnWidths: number[];
  rowHeights: number[];
  contextComponentId: ArdoqId | null;
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
  onScroll: (event: UIEvent<HTMLDivElement>) => void;
}

const isBodyCellEffectivelyEqual = (a: BodyCellModel, b: BodyCellModel) => {
  return (
    a.columnIndex === b.columnIndex &&
    a.columnSpan === b.columnSpan &&
    a.rowSpan === b.rowSpan &&
    isEqual(a.items, b.items)
  );
};
const isRowModelEffectivelyEqual = (a: RowElementModel, b: RowElementModel) => {
  return (
    a.bodyCells.length === b.bodyCells.length &&
    a.bodyCells.every((bodyCell, bodyCellIndex) =>
      isBodyCellEffectivelyEqual(bodyCell, b.bodyCells[bodyCellIndex])
    )
  );
};

const hasComponentId = (
  rowModels: RowElementModel[],
  componentId: string | null
) =>
  componentId !== null &&
  rowModels.some(rowModel =>
    rowModel.bodyCells.some(bodyCell =>
      bodyCell.items.some(item => item.componentId === componentId)
    )
  );

export type ComponentMatrixBodyRef = {
  scrollBy: (scrollLeft: number, scrollTop: number) => void;
  container: RefObject<HTMLDivElement>;
};
const ComponentMatrixBody = forwardRef<
  ComponentMatrixBodyRef,
  ComponentMatrixBodyProperties
>((props, ref) => {
  const container = useRef<HTMLDivElement>(null);
  const scrollBy = (scrollLeft: number, scrollTop: number) => {
    if (!container.current) {
      return;
    }
    container.current.scrollBy(scrollLeft, scrollTop);
  };
  useImperativeHandle(ref, () => ({ scrollBy, container }));
  const {
    rowModels,
    columnWidths,
    rowHeights,
    onScroll,
    hoveredComponentId,
    focusedComponentId,
  } = props;
  return (
    <ComponentMatrixBodyContainer
      ref={container}
      style={{
        gridTemplateColumns: `${columnWidths
          .map(value => `${value}px`)
          .join(' ')}`,
        gridTemplateRows: rowHeights.map(height => `${height}px`).join(' '),
      }}
      onScroll={onScroll}
      onWheel={e => e.stopPropagation()}
    >
      {rowModels.map((rowModel, rowIndex) =>
        rowModel.bodyCells.map((bodyCell, bodyCellIndex) => (
          <BodyCell
            key={`${rowIndex}.${bodyCellIndex}`}
            style={{
              gridColumn: `${bodyCell.columnIndex + 1} / ${
                bodyCell.columnIndex + 1 + bodyCell.columnSpan
              }`,
              gridRow: `${rowIndex + 1} / ${rowIndex + 1 + bodyCell.rowSpan}`,
            }}
          >
            {bodyCell.items.map(
              (
                { componentId, className, componentLabel, iconColor },
                itemIndex
              ) => (
                <ComponentItem
                  key={`${rowIndex}.${bodyCellIndex}.${itemIndex}`}
                  componentId={componentId}
                  className={className}
                  iconColor={iconColor}
                  componentLabel={componentLabel}
                  isHighlighted={
                    componentId === hoveredComponentId ||
                    componentId === focusedComponentId
                  }
                />
              )
            )}
          </BodyCell>
        ))
      )}
    </ComponentMatrixBodyContainer>
  );
});

export default memo(
  ComponentMatrixBody,
  (props, nextProps) =>
    !(
      props.rowModels.length !== nextProps.rowModels.length ||
      props.columnWidths.length !== nextProps.columnWidths.length ||
      props.rowHeights.length !== nextProps.rowHeights.length ||
      (props.contextComponentId !== nextProps.contextComponentId &&
        (hasComponentId(props.rowModels, props.contextComponentId) ||
          hasComponentId(nextProps.rowModels, nextProps.contextComponentId))) ||
      (props.hoveredComponentId !== nextProps.hoveredComponentId &&
        (hasComponentId(props.rowModels, props.hoveredComponentId) ||
          hasComponentId(nextProps.rowModels, nextProps.hoveredComponentId))) ||
      (props.focusedComponentId !== nextProps.focusedComponentId &&
        (hasComponentId(props.rowModels, props.focusedComponentId) ||
          hasComponentId(nextProps.rowModels, nextProps.focusedComponentId))) ||
      !isEqual(props.rowHeights, nextProps.rowHeights) ||
      !isEqual(props.columnWidths, nextProps.columnWidths) ||
      props.rowModels.some(
        (rowModel, rowModelIndex) =>
          !isRowModelEffectivelyEqual(
            rowModel,
            nextProps.rowModels[rowModelIndex]
          )
      )
    )
);
