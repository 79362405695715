import { TreemapData } from '../types';
import {
  ComponentDecoratorTextContainer,
  ComponentDecoratorWrapper,
} from './atoms';
import { EntityIcon } from '@ardoq/decorators';
import { getLabelText } from '../getLabelText';
import { WithPopover } from '@ardoq/popovers';
import { PopoverContent } from './PopoverContent';
import { ICON_MARGIN, ICON_SIZE } from '../constants';
import { getIconWidth } from '../getIconWidth';

type ComponentLabelDecoratorProps = {
  data: TreemapData;
  maxLabelWidth: number;
  labelFontSize: number;
};

const ComponentLabelDecorator = ({
  data,
  maxLabelWidth,
  labelFontSize,
}: ComponentLabelDecoratorProps) => {
  const { label: componentName, value, displayOptions } = data;
  const imageProps = displayOptions.componentDecoratorImageProps;
  const componentValue = value.toString();
  const iconWidth = getIconWidth(imageProps);

  const availableTextSpace = maxLabelWidth - iconWidth;

  const { labelText, isTruncated } = getLabelText({
    maxLabelWidth,
    componentName,
    componentValue,
    fontSize: labelFontSize,
    iconWidth,
  });

  const iconMargin = labelText ? ICON_MARGIN : 0;
  const iconStyle = {
    marginRight: iconMargin,
    maxWidth: ICON_SIZE,
  };

  const popoverContent = isTruncated
    ? () => PopoverContent({ component: data })
    : null;

  return (
    <WithPopover content={popoverContent}>
      <ComponentDecoratorWrapper style={{ maxWidth: maxLabelWidth }}>
        {Boolean(iconWidth) && <EntityIcon {...imageProps} style={iconStyle} />}
        <ComponentDecoratorTextContainer
          style={{ maxWidth: availableTextSpace }}
        >
          {labelText}
        </ComponentDecoratorTextContainer>
      </ComponentDecoratorWrapper>
    </WithPopover>
  );
};

export default ComponentLabelDecorator;
