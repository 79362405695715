import * as d3 from 'd3';
import { TreemapHierarchyNode, TreemapTilingMethod } from '../../types';

export const HIERARCHICAL_TREEMAP_FONT_SIZE = 12;

type TilingMethodEntry = {
  label: string;
  method: (
    node: TreemapHierarchyNode,
    x0: number,
    y0: number,
    x1: number,
    y1: number
  ) => void;
};

export const TILING_METHODS: Record<TreemapTilingMethod, TilingMethodEntry> = {
  ['binary']: { label: 'Binary', method: d3.treemapBinary },
  ['dice']: { label: 'Dice', method: d3.treemapDice },
  ['slice']: { label: 'Slice', method: d3.treemapSlice },
  ['sliceDice']: { label: 'Slice and Dice', method: d3.treemapSliceDice },
  ['squarify']: { label: 'Squarify', method: d3.treemapSquarify },
};
