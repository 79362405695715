import { HTMLAttributes, forwardRef } from 'react';
import { MarkdownViewer } from '@ardoq/markdown';

type MarkdownProps = {
  children: string;
} & HTMLAttributes<HTMLDivElement>;
const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(
  ({ children, ...htmlAttrs }, ref) => (
    <div ref={ref} {...htmlAttrs}>
      <MarkdownViewer content={children} sanitationLevel="permissive" />
    </div>
  )
);

export default Markdown;
