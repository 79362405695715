import type {
  ComponentBackboneModel,
  Reference,
  Tag,
  Workspace,
} from 'aqTypes';
import type ComponentTypeValue from './ComponentTypeValue';
import type FieldValue from './FieldValue';
import type { DiffType } from '@ardoq/data-model';
import type Group from './group';

interface GraphItemDestroyOptions {
  ignoreNodeUpdates?: boolean;
}

export abstract class GraphItem<TModel = GraphItemModel> {
  abstract id: string;
  abstract getLabel(): string;
  abstract hasURLFields(): boolean;
  abstract dataModel: TModel;
  abstract destroy(options: GraphItemDestroyOptions): void;
  abstract getVisualDiffType: () => DiffType;
}

export type NamedGraphItemModel =
  | ComponentBackboneModel
  | Workspace
  | Reference
  | FieldValue
  | ComponentTypeValue
  | Tag;
export type GraphItemModel = NamedGraphItemModel | Group;
