import { APISurveyAttributes, ArdoqId, AssetType } from '@ardoq/api-types';
import { SavedPerspective } from 'modelInterface/perspectives/perspectiveInterface';

export enum DeleteType {
  FOLDER,
  WORKSPACE,
  SCENARIO,
  MULTIPLE,
  FIELD,
  PRESENTATION,
  SURVEY,
  METAMODEL,
  REPORT,
  BROADCAST,
  DASHBOARD,
  VIEWPOINT,
  TRAVERSAL,
  REFERENCE_TYPE,
  BOOKMARK,
  QUERY,
}

export type Item = {
  id: string;
  name: string;
  type: AssetType;
};

export type AssetsGroups = {
  folders: Item[];
  workspaces: Item[];
  presentations: Item[];
  surveys: Item[];
  metamodels: Item[];
  scenarios: Item[];
  reports: Item[];
  dashboards: Item[];
  viewpoints: Item[];
  traversals: Item[];
  bookmarks: Item[];
  broadcasts: Item[];
};

export type DeleteFolderProps = {
  deleteType: DeleteType.FOLDER;
  name: string;
  assets: AssetsGroups;
};

export type DeleteFieldProps = {
  deleteType: DeleteType.FIELD;
  name: string;
  affectedEntities: {
    workspaceCount: number;
    surveyCount: number;
    componentCount: number;
    referenceCount: number;
  };
};

export type DeleteWorkspaceProps = {
  deleteType: DeleteType.WORKSPACE;
  name: string;
  id?: string;
  affectedSurveys: APISurveyAttributes[];
};

export type DeleteTraversalProps = {
  deleteType: DeleteType.TRAVERSAL;
  name: string;
  id?: string;
};
export type DeleteBookmarkProps = {
  deleteType: DeleteType.BOOKMARK;
  name: string;
  id?: string;
};

export type DeleteScenarioProps = {
  deleteType: DeleteType.SCENARIO;
  name: string;
  id: ArdoqId;
};

export type DeleteMultipleAssetsProps = {
  deleteType: DeleteType.MULTIPLE;
  assets: AssetsGroups;
};

export type DeletePresentationProps = {
  deleteType: DeleteType.PRESENTATION;
  name: string;
};

export type DeleteSurveyProps = {
  deleteType: DeleteType.SURVEY;
  name: string;
};

export type DeleteBroadcastProps = {
  deleteType: DeleteType.BROADCAST;
  name: string;
};

export type DeleteMetamodelProps = {
  deleteType: DeleteType.METAMODEL;
  name: string;
};

export type DeleteDashboardProps = {
  deleteType: DeleteType.DASHBOARD;
  name: string;
};

export type DeleteReportProps = {
  deleteType: DeleteType.REPORT;
  name: string;
  id: ArdoqId;
};

export type DeleteViewpointProps = {
  deleteType: DeleteType.VIEWPOINT;
  name: string;
  affectedBroadcasts: number;
};

export type DeleteReferenceTypeProps = {
  deleteType: DeleteType.REFERENCE_TYPE;
  name: string;
  id: number;
  affectedSurveys: APISurveyAttributes[];
};

export type DeleteDynamicFilterProps = {
  deleteType: DeleteType.QUERY;
  name: string;
  id: ArdoqId;
  affectedPerspectives: SavedPerspective[];
};

export type DeleteAssetProps =
  | DeleteFieldProps
  | DeleteFolderProps
  | DeleteWorkspaceProps
  | DeleteScenarioProps
  | DeleteMultipleAssetsProps
  | DeletePresentationProps
  | DeleteSurveyProps
  | DeleteBroadcastProps
  | DeleteDashboardProps
  | DeleteMetamodelProps
  | DeleteReportProps
  | DeleteViewpointProps
  | DeleteTraversalProps
  | DeleteReferenceTypeProps
  | DeleteBookmarkProps
  | DeleteDynamicFilterProps;

export type ScenarioBasicDetails = { name: string; _id: ArdoqId };
