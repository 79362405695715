import {
  Font,
  type ILabel,
  INode,
  IOrientedRectangle,
  Size,
} from '@ardoq/yfiles';
import { createSvgElement } from '@ardoq/dom-utils';
import { colors } from '@ardoq/design-tokens';
import {
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  OTHER_LABEL_MARGIN,
} from 'yfilesExtensions/styles/consts';
import {
  AggregatedGraphEdge,
  CollapsibleGraphGroup,
  formatOtherLabel,
  GraphItem,
  GraphNode,
  OtherLabel,
  ParentChildGraphEdge,
} from '@ardoq/graph';
import {
  DISTANCE_TO_EDGE_MARKERS,
  OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING,
} from './consts';
import SparkMD5 from 'spark-md5';
import { getElementOpacityIfTransparentized } from '../../utils';
import { getEdgeLabelIndent } from '../../../../../yfilesExtensions/styles/util';
import { ArdoqId } from '@ardoq/api-types';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';
import { getCachedMultiLabelTextElement } from './elementCaches';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { sumBy } from 'lodash';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH } from 'yfilesExtensions/styles/modernized/consts';

export const getOtherLabelsHashSource = (otherLabels: OtherLabel[]) => {
  return otherLabels.map(formatOtherLabel).join('|');
};

export const getMultiLabelHash = (label: ILabel) => {
  const graphItem = label.owner!.tag as GraphItem;
  const labelLayoutWidth = String(label.layout.width);

  const nodeLayoutWidth =
    label.owner instanceof INode ? String(label.owner.layout.width) : '';

  const itemLabels = graphItem.getItemLabels();

  const otherLabelsHashSource = itemLabels?.otherLabels
    ? getOtherLabelsHashSource(itemLabels.otherLabels)
    : '';

  const mainLabel = itemLabels?.mainLabel ?? '';
  const subLabel = itemLabels?.subLabel ?? '';

  return SparkMD5.hash(
    `${mainLabel},${graphItem.getVisualDiffType()},${
      graphItem.isTransparentized
    },${otherLabelsHashSource}${subLabel}${labelLayoutWidth}${nodeLayoutWidth}`
  );
};

export const applySpecialStyle = (
  textElement: SVGTextElement,
  isPlaceholder: boolean,
  isTransparentized: boolean
) => {
  if (isPlaceholder) {
    textElement.style.fill = '';
    textElement.style.stroke = '';
  } else {
    textElement.style.fill = colors.black;
    textElement.style.fillOpacity = String(
      getElementOpacityIfTransparentized(isTransparentized)
    );
    textElement.style.stroke = 'none';
  }
};

type GetTextElementArgs = {
  container: SVGElement;
  text: string;
  font: Font;
  transform: string;
  isPlaceholder: boolean;
  isOpaque: boolean;
  isModern: boolean;
  isSingleLine?: boolean;
  legacyTruncatedFieldValueAndLabelForNode?: string;
};
const addTextElement = ({
  container,
  text,
  font,
  transform,
  isPlaceholder,
  isOpaque,
  isModern,
  isSingleLine = false,
  legacyTruncatedFieldValueAndLabelForNode,
}: GetTextElementArgs) => {
  const textElement = getCachedMultiLabelTextElement(
    text,
    font,
    isSingleLine,
    isModern,
    legacyTruncatedFieldValueAndLabelForNode
  );

  textElement.setAttribute('transform', transform);

  applySpecialStyle(textElement, isPlaceholder, isOpaque);

  container.appendChild(textElement);

  return textElement;
};

const BLOCK_DIAGRAM_OTHER_LABELS_VERTICAL_PADDING = 0;
const MODERNIZED_BLOCK_DIAGRAM_OTHER_LABELS_VERTICAL_PADDING = 4;
type RenderOtherLabelsArgs = {
  graphItem: GraphItem;
  translateX: number;
  isPlaceholder: boolean;
  container: SVGElement;
  yOffset: number;
  isModern: boolean;
};

export const renderOtherLabels = ({
  graphItem,
  translateX,
  isPlaceholder,
  container,
  yOffset,
  isModern,
}: RenderOtherLabelsArgs) => {
  let currentY = yOffset; // nameLabelSize.height + LABEL_VERTICAL_PADDING * 2;
  const font = isModern
    ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT
    : BLOCK_DIAGRAM_OTHER_LABEL_FONT;
  const isModernLeafNode = isModern && graphItem instanceof GraphNode;
  const isModernGroup = isModern && graphItem instanceof CollapsibleGraphGroup;
  const isModernCollapsedComponentGroup =
    isModernGroup && graphItem.collapsed && graphItem.isComponent();
  /** true if the other labels should be horizontally centered under the leaf node or collapsed component group. */
  const isModernCenterAligned =
    isModernLeafNode || isModernCollapsedComponentGroup;

  graphItem.getItemLabels()?.otherLabels?.forEach(otherLabel => {
    const label = formatOtherLabel(otherLabel);
    if (!label) {
      return;
    }

    const { width: otherLabelWidth, height: otherLabelHeight } =
      measureLabelElement(label, font);

    const width =
      otherLabelWidth + OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING * 2;
    const otherLabelBackgroundTranslateX = isModernCenterAligned
      ? (MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH - width) / 2
      : isModernGroup
        ? 0
        : translateX -
          OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING -
          otherLabelWidth / 2;

    const verticalPadding = isModern
      ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABELS_VERTICAL_PADDING
      : BLOCK_DIAGRAM_OTHER_LABELS_VERTICAL_PADDING;
    const otherLabelBackground = createSvgElement('rect', {
      rx: '4px',
      ry: '4px',
      stroke: colors.blue80,
      'stroke-width': '1px',
      fill: colors.blue95,
      width: `${width}px`,
      height: `${otherLabelHeight + 2 * verticalPadding}px`,
      transform: `translate(${otherLabelBackgroundTranslateX} ${currentY})`,
    });
    container.appendChild(otherLabelBackground);
    const labelTranslateX = isModernCenterAligned
      ? otherLabelBackgroundTranslateX + width / 2
      : isModernGroup
        ? width / 2
        : translateX;
    const transform = `translate(${labelTranslateX} ${currentY + verticalPadding})`;

    addTextElement({
      container,
      text: label,
      font,
      transform,
      isPlaceholder,
      isModern,
      isOpaque: graphItem.isTransparentized,
      isSingleLine: false,
    });

    currentY += otherLabelHeight + 2 * verticalPadding + OTHER_LABEL_MARGIN;
  });
};

const createMainLabelBackground = (
  layout: IOrientedRectangle,
  edgeMarkersInset: number
) =>
  createSvgElement('rect', {
    width: `${layout.width - edgeMarkersInset * 2}`,
    height: `${layout.height - edgeMarkersInset * 2 + LABEL_VERTICAL_PADDING}`,
    fill: 'white',
    stroke: 'none',
    rx: '4',
    ry: '4',
    transform: `translate(${edgeMarkersInset} ${edgeMarkersInset})`,
  });

type CreateMainLabelArgs = {
  font: Font;
  labelText: string;
  layout: IOrientedRectangle;
  edgeMarkersInset: number;
  isPlaceholder: boolean;
  isTransparentized: boolean;
  container: SVGElement;
  isSingleLine: boolean;
  hasAdditionalLabels: boolean;
  modelId: ArdoqId;
  legacyTruncatedFieldValueAndLabelForNode?: string;
  isModern: boolean;
};

const createMainLabel = ({
  font,
  labelText,
  layout,
  edgeMarkersInset,
  isPlaceholder,
  isTransparentized,
  container,
  isSingleLine,
  legacyTruncatedFieldValueAndLabelForNode,
  isModern,
}: CreateMainLabelArgs) => {
  const translateY = isModern
    ? isSingleLine
      ? layout.height / 2
      : 0
    : isSingleLine
      ? layout.height / 2 + LABEL_VERTICAL_PADDING / 2
      : LABEL_VERTICAL_PADDING + edgeMarkersInset;

  const transform = `translate(${layout.width / 2} ${translateY})`;

  addTextElement({
    container,
    text: labelText,
    font,
    transform,
    isPlaceholder,
    isModern,
    isOpaque: isTransparentized,
    isSingleLine,
    legacyTruncatedFieldValueAndLabelForNode,
  });
};

type CreateAndAppendLabelsArgs = {
  graphItem: GraphItem;
  container: SVGElement;
  layout: IOrientedRectangle;
  edgeMarkersInset: number;
  isPlaceholder: boolean;
  isTransparentized: boolean;
  mainLabelFont: Font;
  isModern: boolean;
};
export const createAndAppendLabels = ({
  graphItem,
  container,
  layout,
  edgeMarkersInset,
  isPlaceholder,
  isTransparentized,
  mainLabelFont,
  isModern,
}: CreateAndAppendLabelsArgs) => {
  const itemLabels = graphItem.getItemLabels();

  if (!itemLabels) {
    return;
  }

  const { mainLabel: mainLabelText, legacyTruncatedFieldValueAndLabelForNode } =
    itemLabels;

  const hasAdditionalLabels = Boolean(itemLabels.otherLabels?.length);

  if (!(mainLabelText || hasAdditionalLabels)) {
    return;
  }
  const isEdge =
    graphItem.isReference() ||
    graphItem instanceof ParentChildGraphEdge ||
    graphItem instanceof AggregatedGraphEdge;
  if (isModern) {
    if (isEdge && mainLabelText) {
      const { width, height } = layout;
      const labelBackgroundRect = createSvgElement('rect', {
        width: `${width}`,
        height: `${height}`,
        fill: colors.white,
      });
      container.appendChild(labelBackgroundRect);
    }
  } else {
    const labelBackgroundRect = createMainLabelBackground(
      layout,
      edgeMarkersInset
    );
    container.appendChild(labelBackgroundRect);
  }

  // create node name label

  const mainLabelSize = mainLabelText
    ? measureLabelElement(mainLabelText, mainLabelFont)
    : Size.EMPTY;

  const isSingleLine =
    !itemLabels.legacyTruncatedFieldValueAndLabelForNode &&
    mainLabelSize.height < 2 * mainLabelFont.fontSize &&
    !hasAdditionalLabels;

  if (mainLabelText) {
    createMainLabel({
      font: mainLabelFont,
      labelText: mainLabelText,
      layout,
      edgeMarkersInset,
      isPlaceholder,
      isTransparentized,
      container,
      isSingleLine,
      hasAdditionalLabels,
      modelId: dataModelId(graphItem),
      legacyTruncatedFieldValueAndLabelForNode,
      isModern,
    });
  }

  // #region create and append additional labels
  const shouldRenderOtherLabels = hasAdditionalLabels && !(isModern && !isEdge);
  if (shouldRenderOtherLabels) {
    const mainLabelPadding = mainLabelText ? LABEL_VERTICAL_PADDING : 0;
    const yOffset = isModern
      ? mainLabelSize.height + LABEL_VERTICAL_PADDING
      : mainLabelSize.height +
        LABEL_VERTICAL_PADDING +
        mainLabelPadding +
        edgeMarkersInset;
    renderOtherLabels({
      graphItem,
      translateX: layout.width / 2,
      isPlaceholder,
      container,
      isModern,
      yOffset,
    });
  }
  // #endregion
};

export const getOtherLabelSizes = (
  otherLabels: OtherLabel[],
  isModern: boolean
) =>
  otherLabels.map(otherLabel => {
    const label = formatOtherLabel(otherLabel);
    return measureLabelElement(
      label,
      isModern
        ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT
        : BLOCK_DIAGRAM_OTHER_LABEL_FONT
    );
  });

export const getOtherLabelsWidth = (otherLabelsSizes: Size[]) =>
  Math.max(
    ...otherLabelsSizes.map(
      ({ width }) =>
        width +
        2 * OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING +
        LABEL_HORIZONTAL_PADDING
    )
  );

export const getOtherLabelsHeight = (otherLabelsSizes: Size[]) =>
  otherLabelsSizes.reduce(
    (acc, { height }) => acc + height + OTHER_LABEL_MARGIN,
    0
  );

export const getOtherLabelsHeightModernized = (otherLabelSizes: Size[]) =>
  otherLabelSizes.length
    ? sumBy(
        otherLabelSizes,
        ({ height }) =>
          height + 2 * MODERNIZED_BLOCK_DIAGRAM_OTHER_LABELS_VERTICAL_PADDING
      ) +
      otherLabelSizes.length * OTHER_LABEL_MARGIN
    : 0;

export const getEdgeMarkersInset = (
  modelId: ArdoqId,
  isReference: boolean,
  isParentChildReference: boolean
) =>
  isReference
    ? getEdgeLabelIndent(modelId) + DISTANCE_TO_EDGE_MARKERS
    : isParentChildReference
      ? 10 + DISTANCE_TO_EDGE_MARKERS
      : 0;
