import { ArdoqId, WorkspaceDigestType } from '@ardoq/api-types';
import {
  ModalSize,
  ModalTemplate,
  ModalTemplateProps,
  modal,
} from '@ardoq/modal';
import { Select } from '@ardoq/select';
import { LabeledValue } from 'aqTypes';
import { useEffect, useMemo, useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import {
  WorkspaceDigestState,
  initWorkspaceDigest,
} from 'streams/workspaceDigest/actions';
import { workspaceDigest$ } from 'streams/workspaceDigest/workspaceDigest$';
import { Container, StatsDetails, TopContributors } from './atoms';
import { DAYS_OPTIONS } from './consts';
import { ErrorNotification } from '@ardoq/status-ui';
import { FormWrapper } from '@ardoq/forms';
import { Box } from '@ardoq/layout';

interface WorkspaceDigestModalProps
  extends Omit<ModalTemplateProps, 'isLoading'>,
    WorkspaceDigestState {
  onClose?: () => void;
  workspace: ArdoqId;
  daysAgo: number;
  daysOptions: LabeledValue<number>[];
  workspaceDigest: WorkspaceDigestType;
}

function WorkspaceDigestModal({
  onClose,
  workspace,
  daysOptions,
  daysAgo,
  workspaceDigest,
  hasError,
  isLoading,
  ...rest
}: WorkspaceDigestModalProps) {
  const [days, setDays] = useState<number>(daysAgo);
  const errorMessage = 'Error while loading digest for workspace.';

  useEffect(() => {
    initWorkspaceDigest(workspace, days);
  }, [workspace, days]);

  const headerText = useMemo(() => {
    if (isLoading) {
      return 'Loading Changes';
    }
    if (hasError) return 'Error';
    return `Changes for workspace "${workspaceDigest.name}"`;
  }, [isLoading, hasError, workspaceDigest.name]);

  return (
    <ModalTemplate
      onCloseButtonClick={onClose}
      headerText={headerText}
      {...rest}
    >
      <Container>
        {!hasError ? (
          !isLoading && (
            <div>
              <FormWrapper>
                <Select
                  label="Changes since"
                  hintMessage="Note: These statistics do not include deletions, only additions
                and changes."
                  value={days}
                  className="input input-medium"
                  onValueChange={selectedValue =>
                    setDays(selectedValue as number)
                  }
                  options={daysOptions}
                />
              </FormWrapper>
              <Box marginY="large">
                <TopContributors
                  contributors={workspaceDigest['top-contributors']}
                />
              </Box>
              <StatsDetails workspaceDigest={workspaceDigest} />
            </div>
          )
        ) : (
          <ErrorNotification>{errorMessage}</ErrorNotification>
        )}
      </Container>
    </ModalTemplate>
  );
}

const ConnectedWorkspaceDigestModal = connect(
  WorkspaceDigestModal,
  workspaceDigest$
);

export const opWorkspaceDigestViewModal = (
  workspaceId: ArdoqId,
  daysAgo: number
) => {
  modal(resolve => {
    return (
      <ConnectedWorkspaceDigestModal
        workspace={workspaceId}
        daysOptions={DAYS_OPTIONS}
        daysAgo={daysAgo}
        onClose={() => resolve(false)}
        modalSize={ModalSize.M}
      />
    );
  });
};
