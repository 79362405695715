import { DoqType } from '@ardoq/doq';
import NoReferencesOrListFieldsDoq from 'tabview/graphViews/emptyStates/NoReferencesOrListFieldsDoq';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoDataWithHideEmptySettingDoq from 'tabview/graphViews/emptyStates/NoDataWithHideEmptySettingDoq';
import { ViewIds } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';

type EmptyStateProps = {
  noVisibleComponents: boolean;
  noReferencesOrListFields: boolean;
  hasActiveFilter: boolean;
  allDescendantsIncluded: boolean;
  hasFilteredNullItems: boolean;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
};

const VIEW_ID = ViewIds.COMPONENT_MATRIX;

const EmptyState = ({
  noVisibleComponents,
  hasActiveFilter,
  allDescendantsIncluded,
  noReferencesOrListFields,
  hasFilteredNullItems,
  isPresentationMode,
  isWorkspaceEmpty,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.COMPONENT_MATRIX_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if ((hasActiveFilter || !allDescendantsIncluded) && noVisibleComponents) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.COMPONENT_MATRIX_ERROR}
        includeActions={false}
        canApplyIncludeAllDescendantsFilter
      />
    );
  }

  if (hasFilteredNullItems && noVisibleComponents) {
    return (
      <NoDataWithHideEmptySettingDoq
        viewId={VIEW_ID}
        doqType={DoqType.COMPONENT_MATRIX_ERROR}
      />
    );
  }

  if (noReferencesOrListFields) {
    return (
      <NoReferencesOrListFieldsDoq doqType={DoqType.COMPONENT_MATRIX_ERROR} />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
