import { ComponentSearchData } from 'viewpointBuilder/selectContextTab/types';
import { ExcludeFalsy, isArdoqError, ArdoqError } from '@ardoq/common-helpers';
import {
  APIComponentAttributes,
  APIScopeDataCore,
  APIReferenceAttributes,
  APITagAttributes,
  ArdoqId,
  BooleanOperator,
  Operator,
  QueryBuilderQuery,
  QueryBuilderSubquery,
  SearchContext,
  SearchResultDoc,
  SortOrder,
} from '@ardoq/api-types';
import {
  addComponentsToCollections,
  addReferencesToCollections,
  addTagsToCollections,
  clearComponentHierarchies,
} from '../../scope/utils';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
} from '../../streams/components/ComponentActions';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
} from '../../streams/references/ReferenceActions';
import { notifyWorkspaceAggregatedLoaded } from '../../streams/workspaces/actions';
import Components from 'collections/components';
import Context from 'context';
import References from 'collections/references';
import Workspaces from 'collections/workspaces';
import { dispatchAction } from '@ardoq/rxbeach';
import { TrackingLocation } from 'tracking/types';
import { getIcon } from '@ardoq/icons';
import {
  ensureContrast,
  getDefaultCSSColor,
  getLightenedColor,
} from '@ardoq/color-helpers';
import { MetaModelReference } from 'models/metaModelReference';
import { MetaModelModel } from 'models/metaModelModel';
import Models from 'collections/models';
import { MetaModelWorkspace } from 'models/metaModelWorkspace';
import { MetaModelComponent } from 'models/metaModelComponent';
import {
  META_MODEL_MODEL_ID,
  META_MODEL_WORKSPACE_ID,
} from 'architectureModel/consts';
import { ExcludedSet } from 'collections/consts';
import { advancedSearchApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import { componentInterface } from '@ardoq/component-interface';
import { advancedSearchComponentNameAndTypeAndStartSetQuery } from '@ardoq/query-builder';
import { resetGraph } from 'modelInterface/graphModelActions';
import { difference, uniq } from 'lodash';
import { Workspace } from 'aqTypes';
import { clearAllEventListenersOnModels } from 'collections/helpers/clearAllEventListenersOnModels';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { VirtualReference } from 'models/virtualReference';
import { NewReference } from 'traversals/pathAndGraphTypes';
import { EnhancedRepresentationData } from './types';

type AdvancedComponentSearchResponse = {
  results: ComponentSearchData[];
  totalCount: number;
};

const loadComponentsFromAdvancedSearch = async (
  componentName: string,
  componentTypeName: string,
  startSetFilter: QueryBuilderSubquery | null
): Promise<AdvancedComponentSearchResponse | ArdoqError> => {
  const requestBody = advancedSearchComponentNameAndTypeAndStartSetQuery(
    componentName,
    componentTypeName,
    startSetFilter
  );
  const response = await advancedSearchApi.search<
    SearchResultDoc & ComponentDoc
  >(requestBody, {
    sortBy: 'rank,name',
    sortOrder: SortOrder.ASC,
  });
  if (isArdoqError(response)) {
    return response;
  }

  return {
    totalCount: response.total,
    results: response.results.map(({ doc }) =>
      mapAdvancedSearchResultToComponentSearchData(doc)
    ),
  };
};

// While it might seem unusual to use the advanced search functionality to load
// components by ID, this approach aligns with the existing pattern of searching
// components by name, type, or other advanced search criteria. This ensures a
// consistent data format across different search types, including representation
// data and color. A typical use case for this function is to initiate a new
// traversal in the viewpoint builder with a specific set of component IDs, for
// instance, those selected from a context menu.
const loadComponentsByIdFromAdvancedSearch = async (
  componentIds: string[]
): Promise<AdvancedComponentSearchResponse> => {
  const requestBody =
    componentIdsToAdvancedSearchQueryRequestBody(componentIds);
  const response = await advancedSearchApi.search<
    SearchResultDoc & ComponentDoc
  >(requestBody);

  if (isArdoqError(response)) {
    logError(
      Error('Failed to fetch components by id with advanced search api')
    );
    return {
      totalCount: 0,
      results: [],
    };
  }

  return {
    totalCount: response.total,
    results: response.results.map(({ doc }) =>
      mapAdvancedSearchResultToComponentSearchData(doc)
    ),
  };
};

const loadComponentsFromQueryBuilder = async (
  query: QueryBuilderQuery
): Promise<AdvancedComponentSearchResponse> => {
  const response = await advancedSearchApi.search<
    SearchResultDoc & ComponentDoc
  >(query, {
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  });
  if (isArdoqError(response)) {
    logError(
      Error('Failed to fetch components by id with advanced search api')
    );
    return {
      totalCount: 0,
      results: [],
    };
  }
  return {
    totalCount: response.total,
    results: response.results.map(({ doc }) =>
      mapAdvancedSearchResultToComponentSearchData(doc)
    ),
  };
};

const componentIdsToAdvancedSearchQueryRequestBody = (
  componentIds: string[]
): QueryBuilderQuery => ({
  condition: BooleanOperator.AND,
  rules: [
    {
      id: 'type',
      field: 'type',
      type: 'string',
      input: 'select',
      operator: Operator.EQUAL,
      value: SearchContext.COMPONENT,
    },
    {
      condition: BooleanOperator.OR,
      rules: componentIds.map(id => ({
        id: '_id',
        field: '_id',
        input: 'text',
        type: 'string',
        operator: Operator.EQUAL,
        value: id,
      })),
    },
  ],
});

const mapAdvancedSearchResultToComponentSearchData = (
  doc: SearchResultDoc & ComponentDoc
): ComponentSearchData => ({
  name: doc.name,
  createdByName: getCreatedByName(doc),
  lastModifiedByName: getLastModifiedByName(doc),
  id: doc._id,
  entityType: doc.entityType,
  rootWorkspaceName: doc.rootWorkspaceName,
  representationData:
    getComponentRepresentationDataAndColorFromSearchResult(doc),
});

// The doc format returned by the advanced search endpoint is not really a
// subset of APIComponentAttributes. For the real product we will most
// likely have a dedicated new endpoint, so typing doc here on the fly.
export type ComponentDoc = {
  rootWorkspace: string;
  entityType: string;
  icon: string | null;
  image: string | null;
  color: string | null;
  rootWorkspaceName: string;
  lastModifiedBy: string;
  createdBy: string;
};

export const getComponentRepresentationDataAndColorFromSearchResult = (
  doc: ComponentDoc
): EnhancedRepresentationData => {
  const {
    rootWorkspace,
    entityType: type,
    icon,
    image,
    color: componentColor,
  } = doc;
  let isImage = false;
  let value = null;
  let color = '#000';
  if (image) {
    isImage = true;
    value = image;
  } else if (icon) {
    value = icon;
  } else {
    const componentType = Object.values(
      Workspaces.collection.get(rootWorkspace)?.getModel()?.getAllTypes() ?? {}
    ).find(({ name }) => name === type);
    if (componentType) {
      value = componentType.image || componentType.icon;
      isImage = Boolean(componentType.image);
      if (componentType.color) {
        color = componentType.color;
      } else {
        color = getDefaultCSSColor(componentType.level);
      }
    }
  }
  if (componentColor) {
    color = componentColor;
  }

  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);
  return { isImage, value, icon: getIcon(value), color, contrastColor };
};

function emitActionsOnAddedAndRemovedComponentsAndReferences(preUpdateCache: {
  components: Set<any>;
  references: Set<any>;
}) {
  const afterUpdateCache = {
    components: new Set(Components.collection.map(m => m.id)),
    references: new Set(References.collection.map(m => m.id)),
  };

  const added = {
    components: [...afterUpdateCache.components].filter(
      id => !preUpdateCache.components.has(id)
    ),
    references: [...afterUpdateCache.references].filter(
      id => !preUpdateCache.references.has(id)
    ),
  };

  const removed = {
    components: [...preUpdateCache.components].filter(
      id => !afterUpdateCache.components.has(id)
    ),
    references: [...preUpdateCache.references].filter(
      id => !afterUpdateCache.references.has(id)
    ),
  };

  if (added.components.length) {
    dispatchAction(notifyComponentsAdded({ componentIds: added.components }));
  }
  if (removed.components.length) {
    dispatchAction(
      notifyComponentsRemoved({ componentIds: removed.components })
    );
  }
  if (added.references.length) {
    dispatchAction(notifyReferencesAdded({ referenceIds: added.references }));
  }
  if (removed.references.length) {
    dispatchAction(
      notifyReferencesRemoved({ referenceIds: removed.references })
    );
  }
}

type UpdateCollectionsAndNotifyArgs = {
  components: APIComponentAttributes[];
  references: APIReferenceAttributes[];
  tags: APITagAttributes[] | undefined;
  trackingLocation?: TrackingLocation | null;
  activeWorkspaceId?: ArdoqId | null;
  workspaceOrder?: ArdoqId[] | null;
  componentIdsToKeep: ArdoqId[];
  referenceIdsToKeep: ArdoqId[];
  isGraphReset?: boolean;
};

const updateCollectionsAndContextAndDispatchNotifyActions = ({
  components,
  references,
  tags = [],
  trackingLocation,
  componentIdsToKeep,
  referenceIdsToKeep,
  isGraphReset = false,
  activeWorkspaceId,
  workspaceOrder,
}: UpdateCollectionsAndNotifyArgs) => {
  const preUpdateCache = {
    components: new Set(Components.collection.map(m => m.id)),
    references: new Set(References.collection.map(m => m.id)),
  };
  clearComponentHierarchies();
  addReferencesToCollections({
    references,
  });
  addComponentsToCollections({
    components,
  });
  addTagsToCollections({
    tags,
  });
  const componentIdsSet = new Set(componentIdsToKeep);
  const referenceIdsSet = new Set(referenceIdsToKeep);
  componentIdsToKeep.forEach(id => addAllAncestorsToSet(componentIdsSet, id));
  // This check has reduced the missing reference logs a lot, but now we see
  // a lot of missing source or target errors.
  if (!isGraphReset) {
    logErrorsForInvalidReferences(referenceIdsToKeep, componentIdsSet);
  }
  const componentsToRemove = Components.collection.filter(
    component => !componentIdsSet.has(component.id)
  );
  const referencesToRemove = References.collection.filter(
    reference => !referenceIdsSet.has(reference.id)
  );
  // The graph stream acts like a cache, so make sure it gets cleared if some
  // references get removed.
  if (referencesToRemove.length > 0) {
    dispatchAction(resetGraph());
  }
  clearAllEventListenersOnModels([
    ...componentsToRemove,
    ...referencesToRemove,
  ]);
  // Remove references and components before closing workspaces.
  // Closing workspaces will e.g. trigger cleanup in the components collection
  // based on the references collection.
  References.collection.remove(referencesToRemove, { silent: true });
  Components.collection.remove(componentsToRemove, { silent: true });
  References.collection.buildCacheForSourceAndTargetMaps();
  emitActionsOnAddedAndRemovedComponentsAndReferences(preUpdateCache);

  // The tags collection is cleaning up itself by listening to
  // `notifyWorkspaceClosed` event.

  const openWorkspaceIds = uniq(
    componentIdsToKeep.map(componentInterface.getWorkspaceId)
  );

  const openWorkspaces = openWorkspaceIds
    .map(id => id && Workspaces.collection.get(id))
    .filter(ExcludeFalsy);
  logErrorForMissingComponentsAndWorkspacesIfNeeded(
    openWorkspaces,
    openWorkspaceIds
  );
  Context.setOpenWorkspacesInViewpointMode({
    workspaces: openWorkspaces,
    trackingLocation,
    activeWorkspace: activeWorkspaceId
      ? Workspaces.collection.get(activeWorkspaceId)
      : null,
    workspaceOrder,
  });
};

const updateVirtualReferences = (references: NewReference[]) => {
  const currentVirtualReferences = References.collection.filter(
    reference => reference instanceof VirtualReference
  );
  References.collection.remove(currentVirtualReferences, { silent: true });
  const newVirtualReferences = references.map(
    reference => new VirtualReference(reference)
  );
  References.collection.add(newVirtualReferences, { silent: false });
};

const logErrorForMissingComponentsAndWorkspacesIfNeeded = (
  openWorkspaces: Workspace[],
  openWorkspaceIds: (ArdoqId | null)[]
) => {
  const hasMissingComponents = openWorkspaceIds.some(idOrNull => !idOrNull);
  if (hasMissingComponents) {
    logError(
      Error(
        'Missing components in updateCollectionsAndContextAndDispatchNotifyActions'
      )
    );
  }
  const missingWorkspaceIds = difference(
    openWorkspaceIds.filter(ExcludeFalsy),
    openWorkspaces.map(({ id }) => id)
  );
  if (missingWorkspaceIds.length) {
    logError(
      Error(
        'Missing workspaces in updateCollectionsAndContextAndDispatchNotifyActions'
      ),
      null,
      { missingWorkspaceIds }
    );
  }
};

const setMetaModelScopeDataToCollections = async ({
  scopeData: {
    workspaces: [workspaceAttributes],
    models: [modelAttributes],
    components,
    references,
  },
  trackingLocation,
}: {
  scopeData: APIScopeDataCore;
  trackingLocation?: TrackingLocation;
}) => {
  Workspaces.collection.addExcludedFromView(
    [META_MODEL_WORKSPACE_ID],
    ExcludedSet.INTERNAL
  );
  if (Models.collection.get(META_MODEL_MODEL_ID)) {
    Models.collection.remove(META_MODEL_MODEL_ID);
  }
  Models.collection.add(new MetaModelModel(modelAttributes));
  const workspace = new MetaModelWorkspace(workspaceAttributes);
  Workspaces.collection.add(workspace);

  References.collection.add(
    references.map(refAttrs => new MetaModelReference(refAttrs))
  );
  Components.collection.add(
    components.map(compAttrs => new MetaModelComponent(compAttrs))
  );
  References.collection.buildCacheForSourceAndTargetMaps();

  workspace.aggregateLoaded = true;
  dispatchAction(
    notifyWorkspaceAggregatedLoaded({ workspaceId: workspace.getId() })
  );

  await Context.loadWorkspaces({
    contextWorkspace: workspace,
    workspaces: [],
    trackingLocation,
  });
};

const getLastModifiedByName = (
  componentDoc: SearchResultDoc & ComponentDoc
) => {
  if (componentDoc.ardoq.lastModifiedByName) {
    return componentDoc.ardoq.lastModifiedByName;
  }
  const user = orgUsersInterface.getUserById(componentDoc.lastModifiedBy);
  return user?.name ?? '';
};

const getCreatedByName = (componentDoc: SearchResultDoc & ComponentDoc) => {
  if (componentDoc.ardoq.createdByName) {
    return componentDoc.ardoq.createdByName;
  }
  const user = orgUsersInterface.getUserById(componentDoc.createdBy);
  return user?.name ?? '';
};

const addAllAncestorsToSet = (set: Set<string>, id: string) => {
  const parentId = Components.collection.get(id)?.get('parent');
  if (!parentId || set.has(parentId)) {
    return;
  }
  set.add(parentId);
  addAllAncestorsToSet(set, parentId);
};

// Verify that all reference ids to kept are in the collection and that both
// source and target of these references are in the set of component ids to
// keep.
const logErrorsForInvalidReferences = (
  referenceIds: string[],
  componentIdsSet: Set<string>
) =>
  referenceIds.forEach(id => {
    const reference = References.collection.get(id);
    if (!reference) {
      logError(
        Error('Missing reference in cleanUpComponentAndReferenceCollections')
      );
    } else {
      if (!componentIdsSet.has(reference.get('source'))) {
        logError(
          Error('Missing source in cleanUpComponentAndReferenceCollections')
        );
      }
      if (!componentIdsSet.has(reference.get('target'))) {
        logError(
          Error('Missing target in cleanUpComponentAndReferenceCollections')
        );
      }
    }
  });

const getLoadedWorkspaceIds = () => Context.workspaces().map(({ id }) => id);

export const prototypeInterface = {
  loadComponentsFromAdvancedSearch,
  getComponentRepresentationDataAndColorFromSearchResult,
  setMetaModelScopeDataToCollections,
  loadComponentsByIdFromAdvancedSearch,
  loadComponentsFromQueryBuilder,
  updateCollectionsAndContextAndDispatchNotifyActions,
  getLoadedWorkspaceIds,
  updateVirtualReferences,
};
