import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

/** This action is dispatched when a metamodel changes. Typically this is a change to the style or definition of a component type or reference type. */
export const notifyModelChanged = actionCreator('[model] NOTIFY_MODEL_CHANGED');

interface DeleteModelPayload {
  id: ArdoqId;
}

export const deleteModel = actionCreator<DeleteModelPayload>(
  '[model] DELETE_MODEL'
);

export const fetchAllModels = actionCreator('[model] FETCH_ALL');
export const fetchAllModelsSucceeded = actionCreator(
  '[model] FETCH_ALL_SUCCEEDED'
);
export const fetchAllModelsFailed = actionCreator('[model] FETCH_ALL_FAILED');
