import { PopoverPlacement, showPopoverAt } from '@ardoq/popovers';
import { setIsCurrentlyHoveredImageBroken } from 'tabview/graphComponent/isCurrentlyHoveredImageBroken$';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { Subscription } from 'rxjs';

let destroyPopover: VoidFunction | undefined;
let hoveredImageElement: SVGImageElement | undefined;

/**
 * When the user zooms or drags, the image position ad dimensions change. We need to account for that.
 * Reevaluate the image rect on every mousemove and wheel event until the mouse leaves the image
 */
const mouseEventOnHoverHandler = () => {
  showBrokenImagePopover(hoveredImageElement);
};

/**
 *  Register / unregister mouse events handler for DOMRect reevaluation
 */
const watchMouseMoveAndWheel = (
  imageElement: SVGImageElement,
  shouldWatch: boolean
) => {
  if (shouldWatch) {
    document.addEventListener('mousemove', mouseEventOnHoverHandler);
    document.addEventListener('mouseup', mouseEventOnHoverHandler);
    imageElement.addEventListener('wheel', mouseEventOnHoverHandler, {
      passive: true,
    });
  } else {
    document.removeEventListener('mousemove', mouseEventOnHoverHandler);
    document.removeEventListener('mouseup', mouseEventOnHoverHandler);
    imageElement.removeEventListener('wheel', mouseEventOnHoverHandler);
  }
};

/**
 * Mutates the module-scoped destroyPopover let and shows the broken Image
 * popover above the image element based on clientRect
 */
const showBrokenImagePopover = (imageElement?: SVGImageElement) => {
  if (!hoveredImageElement) hoveredImageElement = imageElement;

  const imageElementDomRect = imageElement?.getBoundingClientRect();

  if (imageElementDomRect) {
    const [popoverX, popoverY] = [
      imageElementDomRect.x + imageElementDomRect?.width / 2,
      imageElementDomRect.y,
    ];

    destroyPopover = showPopoverAt({
      content: (
        <div style={{ maxWidth: 350 }}>{'Image cannot be displayed'}</div>
      ),
      position: [popoverX, popoverY],
      placement: PopoverPlacement.TOP,
    });
  }
};

/**
 * Returns an subscription which calls the showBrokenImagePopover fn,
 * which then will show the popover based on the image DOMRect.
 * Remeber to unsubscribe.
 */
const BrokenImagePopoverSubsciption = (): Subscription => {
  return subscribeToAction(
    setIsCurrentlyHoveredImageBroken,
    ({ isCurrentlyHoveredImageBroken, imageElement }) => {
      if (!imageElement) return;
      if (isCurrentlyHoveredImageBroken) {
        showBrokenImagePopover(imageElement);
        watchMouseMoveAndWheel(imageElement, true);
      } else {
        watchMouseMoveAndWheel(imageElement, false);
        destroyPopover?.();
        destroyPopover = undefined;
        hoveredImageElement = undefined;
      }
    }
  );
};

export default BrokenImagePopoverSubsciption;
