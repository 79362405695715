import type { BlockDiagramViewSettings, GetLeftMenuArgs } from './types';
import {
  showOnlyConnectedComponents,
  dropdownTraversalMenu,
} from '@ardoq/settings-bar';
import {
  type SettingsConfig,
  SettingsType,
  type ToggleConfig,
  DropdownConfig,
} from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { handleStoreSetting } from 'viewSettings/settingsHelper';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { GraphComponent, LayoutOrientation } from '@ardoq/yfiles';
import { ViewIds } from '@ardoq/api-types';
import { isInScopeDiffMode, isLoadingScope } from 'scope/scopeDiff';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { EXPORT_FORMAT, getExportsForYFilesView } from '@ardoq/export';
import { viewHasLegend } from 'views/metaInfoTabs';
import { toggleVisualDiff } from 'appContainer/MainAppModule/MainAppModuleSidebar/NavigatorTopBar/utils';
import {
  collapseAll,
  expandAll,
  SEPARATE_REFERENCES_TOGGLE,
} from 'tabview/relationshipDiagrams/leftMenuItems';
import type { OnViewSettingsUpdate } from '@ardoq/data-model';
import { SCENARIO_OUT_OF_SYNC_POPOVER_ID } from 'app/popovers/ScenarioOutOfSyncPopover';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { Features, hasFeature } from '@ardoq/features';
import { type DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { addToPresentation } from 'viewSettings/exportHandlers';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { BLOCK_DIAGRAM_LAYOUT_OPTIONS } from './consts';
import { exportToLucidchart } from 'integrations/lucidchart/utils';

const getSetLayoutOrientationOnClick =
  (direction: LayoutOrientation, viewId: ViewIds) => () => {
    handleStoreSetting(viewId, {
      layoutOrientation: direction,
    });
  };

export const getLayoutDirectionOption =
  (viewSettings: BlockDiagramViewSettings, viewId: ViewIds) =>
  ([label, direction]: [string, LayoutOrientation]) => ({
    name: `layout_direction_${label.toLowerCase().replace(/\s+/g, '_')}`,
    label,
    onClick: getSetLayoutOrientationOnClick(direction, viewId),
    type: DropdownOptionType.OPTION,
    isActive: viewSettings.layoutOrientation === direction,
  });

const separateReferencesToggle = (
  viewSettings: BlockDiagramViewSettings,
  onViewSettingsUpdate: OnViewSettingsUpdate
): ToggleConfig => ({
  ...SEPARATE_REFERENCES_TOGGLE,
  isActive: Boolean(viewSettings.separateReferences),
  onViewSettingsUpdate,
});

export const separateReferencesDropdown = (
  viewId: ViewIds,
  currentSetting: boolean,
  onViewSettingsUpdate: OnViewSettingsUpdate
): DropdownConfig => ({
  ...SEPARATE_REFERENCES_TOGGLE,
  label: 'Reference lines',
  type: SettingsType.DROPDOWN,
  options: [
    {
      label: 'Combine reference lines',
      type: DropdownOptionType.OPTION,
      isActive: !currentSetting,
      onClick: () =>
        onViewSettingsUpdate(viewId, { separateReferences: false }, true),
    } satisfies DropdownOption,
    {
      label: 'Separate reference lines',
      type: DropdownOptionType.OPTION,
      isActive: currentSetting,
      onClick: () =>
        onViewSettingsUpdate(viewId, { separateReferences: true }, true),
    } satisfies DropdownOption,
  ],
});

const newGrouping = (
  viewSettings: BlockDiagramViewSettings,
  onViewSettingsUpdate: OnViewSettingsUpdate
): ToggleConfig => ({
  id: 'useNewGrouping',
  label: 'Use new grouping',
  iconName: IconName.RHOMBUS,
  type: SettingsType.TOGGLE,
  isActive: Boolean(viewSettings.useNewGrouping),
  onViewSettingsUpdate,
});

export const getLeftMenu = ({
  viewSettings,
  traversedOutgoingReferenceTypes,
  traversedIncomingReferenceTypes,
  referenceTypes,
  viewId,
  onViewSettingsUpdate,
  activeGroups,
  isViewBusy,
  isViewpointMode,
}: GetLeftMenuArgs): SettingsConfig[] => {
  const layoutDirectionOptions = BLOCK_DIAGRAM_LAYOUT_OPTIONS.map(
    getLayoutDirectionOption(viewSettings, viewId)
  );
  const useNewGrouping = hasFeature(Features.GROUPING_RESEARCH)
    ? newGrouping(viewSettings, onViewSettingsUpdate)
    : null;

  const isExpandCollapseButtonsDisabled =
    activeGroups.length === 0 || Boolean(isViewBusy);
  const collapseAllConfig = collapseAll(
    viewSettings,
    viewId,
    isExpandCollapseButtonsDisabled
  );
  const expandAllConfig = expandAll(
    viewSettings,
    viewId,
    isExpandCollapseButtonsDisabled
  );
  if (isViewpointMode) {
    return [
      {
        id: 'layoutDirection',
        type: SettingsType.DROPDOWN,
        iconName: IconName.SORT_ASC,
        label: 'Layout direction',
        options: layoutDirectionOptions,
        dropdownSize: DropdownSize.S,
      },
      separateReferencesDropdown(
        ViewIds.BLOCK_DIAGRAM,
        viewSettings.separateReferences,
        onViewSettingsUpdate
      ),
    ].filter(ExcludeFalsy);
  }
  return [
    dropdownTraversalMenu({
      viewSettings,
      traversedOutgoingReferenceTypes,
      traversedIncomingReferenceTypes,
      referenceTypes,
      viewId,
      maximumDepth: 99,
      onViewSettingsUpdate,
      quickPerspectiveReferenceTypeFilters:
        filterInterface.getActiveQuickReferenceTypeFilters(),
    }),
    {
      id: 'layout-direction',
      label: 'Select layout direction',
      iconName: IconName.SORT_ASC,
      type: SettingsType.DROPDOWN,
      options: layoutDirectionOptions,
    },
    showOnlyConnectedComponents(
      viewSettings.showOnlyConnectedComponents,
      onViewSettingsUpdate
    ),
    collapseAllConfig,
    expandAllConfig,
    separateReferencesToggle(viewSettings, onViewSettingsUpdate),
    useNewGrouping,
  ].filter(ExcludeFalsy);
};

const getVisualDiffOptions = (isInSync: boolean | undefined) => {
  const isActive = isInScopeDiffMode() || isLoadingScope();

  return isScenarioMode()
    ? [
        {
          id: 'showVisualDiff',
          label: isActive ? 'Close visual diff' : 'Open visual diff',
          iconName: isActive ? IconName.DIFF : IconName.DIFF_EMPTY,
          isActive: isActive,
          isDisabled: !isInSync,
          onClick: () => toggleVisualDiff({ clickSource: 'settingsBar' }),
          type: SettingsType.BUTTON,
          popoverId: SCENARIO_OUT_OF_SYNC_POPOVER_ID,
        },
      ]
    : [];
};

type GetRightMenuArgs = Pick<
  Parameters<typeof getRightMenuConfig>[0],
  'viewId' | 'onViewSettingsUpdate' | 'legendOnClick'
> & {
  viewSettings: BlockDiagramViewSettings;
  getContainer: () => Element | null;
  getGraphComponent: () => GraphComponent | null;
  isInSync?: boolean;
  isEmptyView: boolean;
  showExportFailureModal?: (format: EXPORT_FORMAT) => void;
};

export const getRightMenu = ({
  viewSettings,
  getContainer,
  getGraphComponent,
  viewId,
  isInSync,
  legendOnClick,
  onViewSettingsUpdate,
  isEmptyView,
  showExportFailureModal,
}: GetRightMenuArgs) => [
  ...getVisualDiffOptions(isInSync),
  ...getRightMenuConfig({
    viewId,
    viewstate: viewSettings,
    exports: {
      ...getExportsForYFilesView({
        container: getContainer,
        graphComponent: getGraphComponent,
        exportedViewMetadata: { name: viewId },
        trackingFunction: trackExportingVisualization,
        addToPresentation,
        getStyleSheets,
        showExportFailureModal,
        exportToLucidchart: () =>
          exportToLucidchart(viewSettings.isLegendActive),
      }),
      isDisabled: isEmptyView,
    },
    withLegend: viewHasLegend(viewId),
    knowledgeBaseLink: KnowledgeBaseLink.BLOCK_DIAGRAM,
    legendOnClick: viewHasLegend(viewId) ? legendOnClick : undefined,
    onViewSettingsUpdate,
  }),
];
