import { ViewIds } from '@ardoq/api-types';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { viewHasLegend } from 'views/metaInfoTabs';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { SettingsBar } from '@ardoq/settings-bar';
import type { ExportConfigParams } from '@ardoq/view-settings';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import componentMatrixLeftMenu from './leftMenu';
import {
  ComponentMatrixViewSettings,
  ComponentMatrixViewSettingsState,
} from './types';

const VIEW_ID = ViewIds.COMPONENT_MATRIX;

type ComponentMatrixSettingsBarProps = {
  viewSettings: ComponentMatrixViewSettings;
  exports: ExportConfigParams;
  viewSettingsState: ComponentMatrixViewSettingsState;
};
const ComponentMatrixSettingsBar = ({
  viewSettings,
  exports,
  viewSettingsState,
}: ComponentMatrixSettingsBarProps) => (
  <div className="menuContainer">
    <SettingsBar
      viewId={VIEW_ID}
      leftMenu={componentMatrixLeftMenu(viewSettings, viewSettingsState)}
      rightMenu={getRightMenuConfig({
        viewId: VIEW_ID,
        viewstate: viewSettings,
        exports,
        withLegend: viewHasLegend(VIEW_ID),
        knowledgeBaseLink: KnowledgeBaseLink.COMPONENT_MATRIX,
        onViewSettingsUpdate,
      })}
    />
  </div>
);
const viewSettings$ = getViewSettingsStream<ComponentMatrixViewSettings>(
  ViewIds.COMPONENT_MATRIX
);

export default connect(
  ComponentMatrixSettingsBar,
  viewSettings$.pipe(map(viewSettings => ({ viewSettings })))
);
