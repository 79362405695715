import type { ProteanGraphState } from '../types';
import { ProteanLayoutType } from 'tabview/proteanDiagram/types';
import layoutConstraintSubmenu from './sequenceConstraintSubMenu';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';

const hierarchicLayoutConstraintSubmenu = layoutConstraintSubmenu(
  ['Sequence Constraint', 'Layer Constraint'],
  ['sequenceConstraints', 'layerConstraints'],
  'hierarchic'
);
const hierarchicInGridLayoutConstraintSubMenu = layoutConstraintSubmenu(
  ['Row span', 'Column Span'],
  ['rowSpans', 'columnSpans'],
  'hierarchicInGrid'
);

const tabularLayoutConstraintSubMenu = layoutConstraintSubmenu(
  ['Row span', 'Column Span'],
  ['rowSpans', 'columnSpans'],
  'tabular'
);
const getAdditionalContextMenuItems = (
  state: ProteanGraphState,
  itemId: string
) => {
  switch (state.viewSettings.layoutType) {
    case ProteanLayoutType.Hierarchic:
      return hierarchicLayoutConstraintSubmenu(state, itemId, {
        ...PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchic,
        ...state.viewSettings.layoutOptions.hierarchic,
      });
    case ProteanLayoutType.HierarchicInGrid:
      return hierarchicInGridLayoutConstraintSubMenu(state, itemId, {
        ...PROTEAN_DEFAULT_LAYOUT_OPTIONS.hierarchicInGrid,
        ...state.viewSettings.layoutOptions.hierarchicInGrid,
      });
    case ProteanLayoutType.Tabular:
      return tabularLayoutConstraintSubMenu(state, itemId, {
        ...PROTEAN_DEFAULT_LAYOUT_OPTIONS.tabular,
        ...state.viewSettings.layoutOptions.tabular,
      });
    default:
      return [];
  }
};
export default getAdditionalContextMenuItems;
