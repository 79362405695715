import {
  BaseClass,
  INode,
  INodeSizeConstraintProvider,
  Rect,
  Size,
} from '@ardoq/yfiles';
import proteanRectangularGroupMinimumBounds from '../proteanRectangularGroupMinimumBounds';

class ProteanGroupSizeConstraintProvider extends BaseClass(
  INodeSizeConstraintProvider
) {
  static Instance = new ProteanGroupSizeConstraintProvider();
  private constructor() {
    super();
  }
  override getMaximumSize(_node: INode): Size {
    return Size.INFINITE;
  }
  override getMinimumEnclosedArea(_node: INode): Rect {
    return Rect.EMPTY;
  }
  override getMinimumSize(node: INode): Size {
    return proteanRectangularGroupMinimumBounds(node.tag).size;
  }
}
export default ProteanGroupSizeConstraintProvider;
