import { INode, Visual } from '@ardoq/yfiles';
import CanvasConvexHullGroupVisual from './CanvasConvexHullGroupVisual';
import CanvasGroupStyle from './CanvasGroupStyle';

class CanvasConvexHullGroupStyle extends CanvasGroupStyle {
  protected override createCanvasVisual(
    group: INode,
    fill: string,
    stroke: string
  ): Visual | null {
    return new CanvasConvexHullGroupVisual(group, fill, stroke);
  }
}
export default CanvasConvexHullGroupStyle;
