import { BlocksViewNode } from '../types';
import { isLayoutSet } from './util';

export const fixupGroupGrid = (group: BlocksViewNode) => {
  if (!group.children?.length) {
    return;
  }

  let columnInsertionCursor = 1;
  let rowInsertionCursor = 1;
  let highestOccupiedColumn = 1;

  let fixupRequired = false;

  for (const node of group.children!) {
    if (isLayoutSet(node)) {
      highestOccupiedColumn = Math.max(
        highestOccupiedColumn,
        node.col + node.colSpan + 1
      );
      rowInsertionCursor = Math.max(
        rowInsertionCursor,
        node.row + node.rowSpan + 1
      );
    } else {
      fixupRequired = true;
    }
  }

  if (fixupRequired) {
    const nodeCount = group.children.length;

    if (columnInsertionCursor < nodeCount / 2) {
      highestOccupiedColumn = 1 + 2 * Math.ceil(Math.sqrt(nodeCount));
    }

    for (const node of group.children!) {
      if (!isLayoutSet(node)) {
        node.col = columnInsertionCursor;
        node.colSpan = 1;
        node.row = rowInsertionCursor;
        node.rowSpan = 1;

        columnInsertionCursor = columnInsertionCursor + 2;

        if (columnInsertionCursor >= highestOccupiedColumn) {
          columnInsertionCursor = 1;
          rowInsertionCursor += 2;
        }
      }
    }
  }
};

export const resetGroupGrid = (group: BlocksViewNode) => {
  if (!group.children?.length) {
    return;
  }

  const recurse = (node: BlocksViewNode) => {
    if (node.children && node.children.length !== 0) {
      for (const child of node.children) {
        recurse(child);
      }

      const nodeCount = node.children.length;
      const colMax = 1 + 2 * Math.ceil(Math.sqrt(nodeCount));
      let col = 1; // column insertion cursor
      let row = 1; // row insertion cursor

      for (const child of node.children) {
        child.col = col;
        child.colSpan = 1;
        child.row = row;
        child.rowSpan = 1;

        col = col + 2;

        if (col >= colMax) {
          col = 1;
          row += 2;
        }
      }
    }
  };

  recurse(group);
};
