import { notifyViewLoading } from 'tabview/actions';
import { action$, extractPayload, ofType } from '@ardoq/rxbeach';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export const getIsViewBusy$ = (currentViewInstanceId: string) =>
  action$.pipe(
    ofType(notifyViewLoading),
    extractPayload(),
    filter(({ viewInstanceId }) => viewInstanceId === currentViewInstanceId),
    map(({ isBusy }) => isBusy),
    distinctUntilChanged()
  );
