import {
  LABEL_MARGIN,
  TICK_LENGTH,
  labelHeight,
  removeCollisions,
} from '@ardoq/graph';
import { TITLE_MARGIN } from 'utils/charting/consts';
import { BubbleChartAxisProperties } from './types';
import { AxisLine, AxisTick, AxisTitle, XAxisLabel } from './atoms';
import { formatAxisLabel } from './util';
import { MeasureStyledText } from '@ardoq/dom-utils';

export const XAxis = (args: BubbleChartAxisProperties) => {
  const { margin, height, width, ticks, scaler, grid, title } = args;
  const gridLineLength = grid ? `${-height}` : `-${TICK_LENGTH}`;
  const textMeasurer = new MeasureStyledText();
  const arrangedTicks = ticks.map(tick => {
    const label = formatAxisLabel(tick);
    return {
      position: scaler(tick),
      label,
      width: textMeasurer.getTextWidth({ text: label }),
    };
  });
  const decollidedTicks = removeCollisions(
    arrangedTicks,
    arrangedTick => arrangedTick.position - arrangedTick.width / 2,
    arrangedTick => arrangedTick.position + arrangedTick.width / 2
  );
  return (
    <g transform={`translate(${margin.left}, ${height + margin.top})`}>
      {decollidedTicks.map(tick => (
        <g key={tick.label} transform={`translate(${tick.position}, 0)`}>
          <AxisTick y2={gridLineLength} y1={TICK_LENGTH} />
          <XAxisLabel y={TICK_LENGTH + LABEL_MARGIN} dy=".71em">
            {tick.label}
          </XAxisLabel>
        </g>
      ))}
      <AxisLine d={`M0,${TICK_LENGTH}V0H${width}V${TICK_LENGTH}`} />
      <AxisTitle
        x={width / 2}
        y={margin.bottom - labelHeight / 2 - TITLE_MARGIN}
      >
        {title}
      </AxisTitle>
    </g>
  );
};
