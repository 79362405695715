import { Icon, IconName } from '@ardoq/icons';

const mapping = new Map<boolean, IconName>([
  [true, IconName.UNFOLD_LESS],
  [false, IconName.UNFOLD_MORE],
]);

interface ExpandToggleIconProps {
  expanded?: boolean;
}

const ExpandToggleIcon = ({
  expanded = false,
  ...rest
}: ExpandToggleIconProps) => (
  <Icon iconName={mapping.get(expanded)!} {...rest} />
);

export default ExpandToggleIcon;
