import {
  GraphComponent,
  HierarchicLayoutConfig,
  INode,
  LayoutDescriptor,
  Rect as YRect,
} from '@ardoq/yfiles';
import { Rect, inside } from '@ardoq/graph';
import { dataModelId } from './graphComponentUtil';

const zoomViewport = (
  graphComponent: GraphComponent,
  preservedViewport: YRect | null,
  zoomComponentId: string | null
) => {
  if (preservedViewport) {
    const constrainedViewport = graphComponent.viewportLimiter.limitViewport(
      graphComponent,
      preservedViewport
    );
    graphComponent.zoomTo(constrainedViewport.center, graphComponent.zoom);
  } else if (zoomComponentId) {
    const nodeToZoom = graphComponent.graph.nodes.find(
      node =>
        node.tag &&
        (node.tag.id === zoomComponentId ||
          dataModelId(node.tag) === zoomComponentId)
    );
    if (nodeToZoom) {
      zoomToNode(graphComponent, nodeToZoom);
    }
  }
};
const zoomToNode = (graphComponent: GraphComponent, node: INode) => {
  if (node) {
    const newViewport = YRect.fromCenter(
      node.layout.center,
      graphComponent.viewport.size
    );
    const constrainedViewport = graphComponent.viewportLimiter.limitViewport(
      graphComponent,
      newViewport
    );
    graphComponent.zoomTo(constrainedViewport.center, graphComponent.zoom);
  } else {
    graphComponent.fitContent();
  }
};
const getLayoutOrientation = (layout: LayoutDescriptor | null) =>
  layout?.name === 'HierarchicLayout'
    ? (layout.properties as HierarchicLayoutConfig).layoutOrientation
    : null;
const didLayoutOrientationChange = (
  layoutDescriptor: LayoutDescriptor,
  previousLayoutDescriptor: LayoutDescriptor | null
) =>
  getLayoutOrientation(layoutDescriptor) !==
  getLayoutOrientation(previousLayoutDescriptor);
export interface OnLayoutGraphArgs {
  graphComponent: GraphComponent;
  hasGraphUpdate: boolean;
  layoutDescriptor: LayoutDescriptor;
  previousLayoutDescriptor: LayoutDescriptor | null;
}

interface GetOnLayoutGraphArgs {
  /** Should the graph viewport be preserved after layout? */
  preserveViewport: boolean;
  /** ID of the node or group to zoom in on. */
  zoomComponentId: string | null;
}
/** Provides the common implementation of post-layout viewport adjustment. */
export const onLayoutGraphFactory =
  ({ preserveViewport, zoomComponentId }: GetOnLayoutGraphArgs) =>
  ({
    graphComponent,
    layoutDescriptor,
    previousLayoutDescriptor,
    hasGraphUpdate,
  }: OnLayoutGraphArgs) => {
    const { contentRect, viewport } = graphComponent;
    const isFullGraphDisplayed = inside(
      Rect.fromRectLike(contentRect),
      Rect.fromRectLike(viewport)
    );
    const fitContent =
      didLayoutOrientationChange(layoutDescriptor, previousLayoutDescriptor) ||
      (!zoomComponentId && hasGraphUpdate) ||
      isFullGraphDisplayed;
    const preservedViewport = preserveViewport ? viewport : null;
    if (
      fitContent &&
      !preservedViewport &&
      contentRect.width > 0 &&
      contentRect.height > 0 &&
      viewport.width > 0 &&
      viewport.height > 0 // fitContent will throw errors if you call it with a viewport or contentRect with no size.
    ) {
      graphComponent.fitContent();
      return;
    }

    if (preservedViewport || zoomComponentId) {
      zoomViewport(graphComponent, preservedViewport, zoomComponentId);
    }
  };
