import { GraphItem } from './GraphItem';

export default class GraphCollection<T extends GraphItem = GraphItem>
  implements Iterable<T>
{
  _map = new Map<string, T>();
  constructor(items: T[] | Set<T> = []) {
    items.forEach((item: T) => this.add(item));
  }
  [Symbol.iterator](): Iterator<T> {
    return this.values();
  }
  get length(): number {
    return this.size();
  }

  add(
    item:
      | T
      | T[]
      | GraphCollection<T>
      | { forEach(callbackfn: (value: T) => void): void }
  ): void {
    const itemArray = item as T[] | GraphCollection<T>;
    if (itemArray.forEach) {
      itemArray.forEach(i => this.add(i));
    } else {
      const singleItem = item as T;
      this.set(singleItem.id, singleItem);
    }
  }

  filter(pred: (value: T) => boolean): T[] {
    return this.toArray().filter(pred);
  }

  some(pred: (value: T) => boolean): boolean {
    return this.toArray().some(pred);
  }

  toArray(): T[] {
    return Array.from(this.values());
  }

  clear(destroyOptions = {}): void {
    this.forEach(item => item.destroy(destroyOptions));
    this._map.clear();
  }

  // Expose map funcs

  get(key: string): T | undefined {
    return this._map.get(key);
  }

  set(key: string, value: T): Map<string, T> {
    return this._map.set(key, value);
  }

  forEach(func: (item: T) => any) {
    this._map.forEach(func);
  }

  values(): IterableIterator<T> {
    return this._map.values();
  }

  size(): number {
    return this._map.size;
  }

  delete(key: string): boolean {
    return this._map.delete(key);
  }

  flatten(): T[] {
    return this.toArray();
  }
}
