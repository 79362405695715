import { DoqType } from '@ardoq/doq';
import { ViewIds } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoSelectedColumnDoq from 'tabview/graphViews/emptyStates/NoSelectedColumnDoq';
import NoReferencesDoq from 'tabview/graphViews/emptyStates/NoReferencesDoq';
import { Notifier } from '@ardoq/dropdown-menu';

type EmptyStateProps = {
  hasComponentsAvailable: boolean;
  hasNoSelectedColumn: boolean;
  hasActiveFilter: boolean;
  hasNoRows: boolean;
  viewId: ViewIds.TABLEVIEW | ViewIds.REFERENCETABLE;
  isPresentationMode: boolean;
  isWorkspaceEmpty: boolean;
  openShowColumnsViewSettingTable: Notifier;
};

const EmptyState = ({
  hasActiveFilter,
  hasComponentsAvailable,
  hasNoSelectedColumn,
  hasNoRows,
  viewId,
  isPresentationMode,
  isWorkspaceEmpty,
  openShowColumnsViewSettingTable,
}: EmptyStateProps) => {
  const doqType =
    viewId === ViewIds.TABLEVIEW
      ? DoqType.TABLE_ERROR
      : DoqType.REFERENCE_TABLE_ERROR;

  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={doqType}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={doqType}
        includeActions={!isPresentationMode}
      />
    );
  }

  // for REFERENCETABLE when hasNoRows=true AND hasComponentsAvailable=true
  // return the empty state of NoReferences when there are components available
  if (
    viewId === ViewIds.REFERENCETABLE &&
    hasNoRows &&
    hasComponentsAvailable
  ) {
    return <NoReferencesDoq doqType={doqType} />;
  }

  if (hasNoSelectedColumn) {
    return (
      <NoSelectedColumnDoq
        doqType={doqType}
        includeActions={!isPresentationMode}
        selectColumnAction={openShowColumnsViewSettingTable}
      />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
