import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import { DoqType } from '@ardoq/doq';
import { SizeBasedOnOptions, WorkspaceField } from '../types';
import {
  getUnusedSelectedFieldLabel,
  hasAvailableReferences,
  isByCustomField,
} from '../helpers';
import {
  NoDescendantsDoq,
  NoPositiveValuesDoq,
  NoReferencesDoq,
  NoSelectedUnusedFieldDoq,
} from './NoDataDoqys';
import { SizeBasedOn } from 'views/treemapConsts';

type EmptyStateProps = {
  hasComponentsAvailable: boolean;
  isWorkspaceEmpty: boolean;
  hasActiveFilter: boolean;
  hasIncomingReferences: boolean;
  hasOutgoingReferences: boolean;
  sizeBasedOn: SizeBasedOnOptions;
  fieldsInUse: Set<string>;
  workspaceFields: WorkspaceField[];
  isPresentationMode: boolean;
  activeFieldLabel?: string;
};

const EmptyState = ({
  hasComponentsAvailable,
  isWorkspaceEmpty,
  hasActiveFilter,
  sizeBasedOn,
  hasIncomingReferences,
  hasOutgoingReferences,
  fieldsInUse,
  workspaceFields,
  isPresentationMode,
  activeFieldLabel,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.TREEMAP_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.TREEMAP_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasComponentsAvailable && sizeBasedOn === SizeBasedOn.CHILD_COUNT) {
    return <NoDescendantsDoq />;
  }

  const selectedUnusedField = getUnusedSelectedFieldLabel({
    sizeBasedOn,
    fieldsInUse,
    workspaceFields,
  });

  if (selectedUnusedField) {
    return <NoSelectedUnusedFieldDoq fieldName={selectedUnusedField} />;
  }

  const { hasReferences, referenceType } = hasAvailableReferences({
    sizeBasedOn,
    hasIncomingReferences,
    hasOutgoingReferences,
  });

  if (!hasReferences && referenceType) {
    return <NoReferencesDoq referenceType={referenceType} />;
  }

  if (hasComponentsAvailable && isByCustomField(sizeBasedOn)) {
    return <NoPositiveValuesDoq activeFieldLabel={activeFieldLabel} />;
  }

  return null;
};

export default connect(EmptyState, emptyView$);
