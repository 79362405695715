import { MetamodelContext } from 'metamodel/types';
import { NavigationContext } from 'metamodel/tracking';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';

export const getTrackingNavigationContext = (context: MetamodelContext) => {
  switch (context.type) {
    case SimpleBlockDiagramContentType.COMPONENT:
      return context.field
        ? NavigationContext.COMPONENT_TYPE_FIELD
        : NavigationContext.COMPONENT_TYPE;
    case SimpleBlockDiagramContentType.REFERENCE:
      return context.field
        ? NavigationContext.REFERENCE_TYPE_FIELD
        : NavigationContext.REFERENCE_TYPE;
    case SimpleBlockDiagramContentType.WORKSPACE:
      return NavigationContext.WORKSPACE;
    case SimpleBlockDiagramContentType.WORKSPACE_FOLDER:
      return NavigationContext.WORKSPACE_FOLDER;
    default:
      return NavigationContext.METAMODEL;
  }
};
