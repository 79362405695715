import { ViewIds } from '@ardoq/api-types';
import { trackEvent } from 'tracking/tracking';

type TrackInteractiveLegendItemClickMetaData = {
  viewId: ViewIds;
  legendRow: string;
};
export const trackInteractiveLegendItemClick = (
  metadata: TrackInteractiveLegendItemClickMetaData
) => {
  trackEvent('Clicked interactive legend item', metadata);
};
