import { map } from 'lodash';
import Group from 'graph/group';
import Filters, { currentFilterColor } from 'collections/filters';
import Fields from 'collections/fields';
import ChildCollection from 'graph/NodeChildCollection';
import * as encodingUtils from '@ardoq/html';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { DiffType } from '@ardoq/data-model';
import { componentInterface } from 'modelInterface/components/componentInterface';

/**
 * @typedef {import('aqTypes').ComponentBackboneModel} ComponentBackboneModel
 * @typedef {import('aqTypes').Reference} Reference
 * @typedef {import('aqTypes').Tag} Tag
 * @typedef {import('./GraphItem').GraphItemModel} GraphItemModel
 * @typedef {import('./GraphItem').NamedGraphItemModel} NamedGraphItemModel
 * @typedef {import('graph/types').Edge} Edge
 * @typedef {import('graph/FieldValue').default} FieldValue
 */

let idCounter = 0;
function uniqueId() {
  return `n${idCounter++}`;
}

/**
 * A node can represent a component, workspace or aggregated set of nodes (a group)
 */
export class Node {
  /**
   * @param {object} param
   * @param {GraphItemModel} param.dataModel
   * @param {Node} [param.parent]
   */
  constructor({ dataModel, parent }) {
    this.id = uniqueId();
    this.included = true;
    this.excluded = false;
    this.treeDepth = undefined;
    this.dataModel = dataModel;
    this.isTransparentized = false;
    if (parent) {
      this.setParent(parent);
    }
    /** @type {ChildCollection} */
    this.children = new ChildCollection([], {
      parent: this,
    });
    // an object that ties it to the parent (usually a reference)
    this.parentRelations = {};
    /** @type {Edge[]} */
    this.incomingEdges = [];
    /** @type {Edge[]} */
    this.outgoingEdges = [];
    /** @type {Edge[]} */
    this.aggregatedIncomingEdges = [];
    /** @type {Edge[]} */
    this.aggregatedOutgoingEdges = [];
  }

  getShape() {
    let shape;
    if (/** @type {ComponentBackboneModel} */ (this.dataModel).getShape) {
      shape = /** @type {ComponentBackboneModel} */ (this.dataModel).getShape();
    }
    return shape || 'predefinedProcess';
  }

  getLabel() {
    return this.name();
  }
  /** this is only used by TableGraphBuilder. */
  getValue() {
    return /** @type {FieldValue} */ (this.dataModel).getValue();
  }

  name() {
    if (this.isTag()) {
      return `#${/** @type {Tag} */ (this.dataModel).name()}`;
    }

    if (this.isComponent()) {
      const componentId = /** @type {ComponentBackboneModel} */ (
        this.dataModel
      ).getId();
      return componentInterface.getNameWithFieldLabelAndValue(componentId);
    } else if (
      /** @type {ComponentBackboneModel | Reference} */ (this.dataModel)
        .getRawLabel
    ) {
      return /** @type {ComponentBackboneModel | Reference} */ (
        this.dataModel
      ).getRawLabel();
    } else if (
      /** @type {ComponentBackboneModel | Reference} */ (this.dataModel)
        .getLabel
    ) {
      return encodingUtils.unescapeHTML(
        /** @type {ComponentBackboneModel | Reference} */ (
          this.dataModel
        ).getLabel()
      );
    } else if (/** @type {Reference} */ (this.dataModel).getRawName) {
      return /** @type {Reference} */ (this.dataModel).getRawName();
    }
    return /** @type {NamedGraphItemModel} */ (this.dataModel).name();
  }

  getFullPathNameArr() {
    const parentPath = [];
    /** @type {Node | undefined} */
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let current = this;
    do {
      parentPath.unshift(current.name());
      current = current.parent;
    } while (current);

    return parentPath;
  }
  /** @param {Node} parent */
  setParent(parent) {
    this.parent = parent;
  }

  /** @returns {number} */
  getTreeDepth() {
    if (this.treeDepth === undefined) {
      this.treeDepth = this.parent ? this.parent.getTreeDepth() + 1 : 0;
    }
    return this.treeDepth;
  }

  isGroup() {
    return this.dataModel instanceof Group;
  }

  isComponent() {
    return (
      this.dataModel &&
      /** @type {ComponentBackboneModel | Reference} */ (this.dataModel)
        .urlRoot &&
      /** @type {ComponentBackboneModel | Reference} */ (
        this.dataModel
      ).urlRoot.indexOf('/api/component') !== -1
    );
  }

  isWorkspace() {
    return (
      this.dataModel &&
      this.dataModel.urlRoot &&
      this.dataModel.urlRoot.indexOf('/api/workspace') !== -1
    );
  }

  isTag() {
    return (
      this.dataModel &&
      this.dataModel.urlRoot &&
      this.dataModel.urlRoot.indexOf('/api/tag') !== -1
    );
  }

  isField() {
    return this.dataModel && this.dataModel.field;
  }

  hasChildren() {
    return this.children && this.children.size() > 0;
  }

  isIncludedInContextByFilter() {
    if (this.isComponent() || this.isGroup()) {
      return Filters.isIncludedInContextByFilter(this.dataModel);
    }
    return true;
  }

  isColoredByFilter() {
    if (this.isComponent()) {
      return !!currentFilterColor(this.dataModel);
    }
    return false;
  }

  hasURLFields() {
    if (this.isComponent()) {
      return Fields.collection
        .getByComponent(this.dataModel)
        .filter(field => field.getType() === 'Url')
        .map(field => this.dataModel.get(field.get('name')))
        .some(url => url);
    }
    return false;
  }

  getImage() {
    if (this.isComponent()) {
      return this.dataModel.getImage();
    }
  }

  getIncomingEdges() {
    return this.incomingEdges;
  }

  getOutgoingEdges() {
    return this.outgoingEdges;
  }

  getAggregatedIncomingEdges() {
    return this.aggregatedIncomingEdges;
  }

  getAggregatedOutgoingEdges() {
    return this.aggregatedOutgoingEdges;
  }

  getRepresentation() {
    if (this.isComponent()) {
      return this.dataModel.getRepresentation();
    }
    return '';
  }

  getCSSFilterColor() {
    return this.dataModel.getCSSFilterColor
      ? this.dataModel.getCSSFilterColor()
      : undefined;
  }

  getCSS(options) {
    if (this.isComponent() || this.isWorkspace()) {
      return this.dataModel.getCSS(options);
    } else if (this.isField()) {
      return 'fieldGraphNode';
    } else if (this.isTag) {
      return 'tagGraphNode';
    }
    return '';
  }

  getRootParent() {
    return this.parent ? this.parent.getRootParent() : this;
  }

  findOutgoingEdgesTo(targetNode) {
    return this.outgoingEdges.filter(edge => {
      return edge.target === targetNode.id;
    });
  }

  findIncomingEdgesFrom(targetNode) {
    return this.incomingEdges.filter(edge => {
      return edge.source === targetNode.id;
    });
  }

  // Should be private
  addIncomingEdge(edge) {
    this.incomingEdges.push(edge);
  }

  removeIncomingEdge(edge) {
    this.incomingEdges.splice(this.incomingEdges.indexOf(edge), 1);
  }

  /** @param {Edge} edge */
  addOutgoingEdge(edge) {
    this.outgoingEdges.push(edge);
  }
  /** @param {Edge} edge */
  removeOutgoingEdge(edge) {
    this.outgoingEdges.splice(this.outgoingEdges.indexOf(edge), 1);
  }
  /** @param {Edge} edge */
  addAggregatedIncomingEdge(edge) {
    this.aggregatedIncomingEdges.push(edge);
  }
  /** @param {Edge} edge */
  removeAggregatedIncomingEdge(edge) {
    this.aggregatedincomingEdges.splice(
      this.aggregatedincomingEdges.indexOf(edge),
      1
    );
  }
  /** @param {Edge} edge */
  addAggregatedOutgoingEdge(edge) {
    this.aggregatedOutgoingEdges.push(edge);
  }
  /** @param {Edge} edge */
  removeAggregatedOutgoingEdge(edge) {
    this.aggregatedOutgoingEdges.splice(
      this.aggregatedOutgoingEdges.indexOf(edge),
      1
    );
  }

  getVisualDiffType() {
    return (
      (this.dataModel &&
        typeof this.dataModel.get === 'function' &&
        this.dataModel.get(DIFF_TYPE)) ||
      DiffType.NONE
    );
  }

  destroy({ ignoreNodeUpdates = false } = {}) {
    if (this.children.length) {
      map(this.children.toArray(), 'id').forEach(childId => {
        this.children.get(childId).destroy();
      });
    }
    if (this.isGroup()) {
      this.dataModel.destroy();
    }
    if (this.parent && !ignoreNodeUpdates) {
      this.parent.children.delete(this.id);
    }
  }
}
