import Context from 'context';
import { INode } from '@ardoq/yfiles';
import { getSelectedIds } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/scenarioRelatedSelection$';
import type { ArdoqId } from '@ardoq/api-types';
import type { GraphItem } from '@ardoq/graph';
import type { Node as OldGraphNode } from 'graph/node';
import type { Edge as OldGraphEdge } from 'graph/edge';

export const isContextGraphNode = (
  node: GraphItem | OldGraphNode | OldGraphEdge | undefined
) => {
  if (!node) {
    return false;
  }
  const contextComponent = Context.component();
  return 'modelId' in node
    ? node.modelId === contextComponent?.id
    : node.dataModel === contextComponent;
};

export const isContextNode = (node: INode) => isContextGraphNode(node.tag);

export const isSelectedRelatedNode = (node: INode) =>
  isPartOfMultiSelection(node?.tag?.modelId);

export const isPartOfMultiSelection = (modelId: ArdoqId) => {
  const selectedIds = getSelectedIds();
  return selectedIds.length > 1 && new Set(selectedIds).has(modelId);
};

const isImageGraphNode = (node: OldGraphNode | undefined) => {
  return Boolean(node?.getImage());
};

export const isImageNode = (node: INode) => isImageGraphNode(node.tag);
