import { TagModel, TagscapeViewModel, TagscapeViewSettings } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Tag } from 'aqTypes';
import Components from 'collections/components';
import References from 'collections/references';
import Tags from 'collections/tags';
import { ModelType } from 'models/ModelType';
import {
  afterDragStart,
  beforeDragEnd,
} from 'navigator2024/handlers/dragndrop';
import {
  type ExtractPayload,
  persistentReducedStream,
  reducer,
  multiActionReducer,
} from '@ardoq/rxbeach';
import {
  type ComponentIdsPayload,
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import {
  type WorkspaceChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import {
  type ReferenceIdsPayload,
  notifyReferencesRemoved,
  notifyReferencesUpdated,
} from 'streams/references/ReferenceActions';
import {
  notifyTagAdded,
  notifyTagRemoved,
  notifyTagUpdated,
} from 'streams/tags/TagActions';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { combineLatest } from 'rxjs';
import { type APITagAttributes, ArdoqId, ViewIds } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { setTagsFromScenario } from './actions';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';
import { colors } from '@ardoq/design-tokens';
import { ensureContrast } from '@ardoq/color-helpers';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { hasComponentsFromOtherWorkspace } from './utils';

const VIEW_ID = ViewIds.TAGSCAPE;

const emptyState: TagscapeViewModel = {
  tags: [],
  workspace: {
    id: '',
    hasComponents: false,
    isWriter: false,
  },
  disableAddingTagToDraggedComponent: false,
};

const relevant = (wid: string) => (tag: Tag) =>
  Boolean(tag.cid && tag.get('rootWorkspace') === wid);

const toViewModel = (tag: Tag) => {
  const referenceIds: string[] = tag.get('references') || [];
  const refs = referenceIds
    .map(id => References.collection.get(id))
    .filter(ExcludeFalsy)
    .map(r => ({
      id: r.get('_id'),
      cid: r.cid,
      name: r.getShortName(),
      isHovered: false,
      isFocused: false,
    }));
  const componentIds: string[] = tag.get('components') || [];
  const comps = componentIds
    .map(id => Components.collection.get(id))
    .filter(ExcludeFalsy)
    .map(c => {
      const type = c.getMyType() as ModelType;
      const componentColor = c.getColor() || colors.black;
      return {
        id: c.get('_id'),
        cid: c.cid,
        name: c.get('name'),
        typeId: type.id,
        isHovered: false,
        isFocused: false,
        color: ensureContrast(colors.white, componentColor),
      };
    });

  return {
    cid: tag.cid,
    name: tag.get('name'),
    description: tag.get('description') || '',
    parked: true,
    references: refs,
    components: comps,
    isUsed: comps.length > 0 || refs.length > 0,
    id: tag.id,
  };
};

const toTagModel = (viewModel: TagModel) => Tags.collection.get(viewModel.id);

const refreshTags = (tags: TagModel[]) => {
  return (
    tags
      .map(toTagModel)
      /*
       * Why do we need to filter here?
       * When a workspace is closed, we clean up the relevant
       * backbone collections, like components, references, and tags.
       * This is all done by listening to events, so it's not really coordinated.
       * In addition to this, the state in this stream can be seen as a cache
       * over the tags collection.
       * Back to the workspace closing. Both tags and components collections
       * act on this, but this stream here only listens to `notifyComponentsRemoved`
       * which seems to happen _after_ all the related tags are removed from
       * the tags collection. which means we have stale data in the stream,
       * and some of the tags we go looking for are not here anymore.
       */
      .filter((tag): tag is Tag => Boolean(tag))
      .map(toViewModel)
  );
};

const hasIds = (tag: TagModel, ids: Set<string>) => {
  // TODO remove cids when we have migrated to new code.
  const allIds = new Set([...tag.components.flatMap(c => [c.cid, c.id])]);

  return [...ids].every(id => allIds.has(id));
};

const tagsForWorkspace = (workspaceId: ArdoqId) =>
  Tags.collection.filter(relevant(workspaceId)).map(toViewModel);
const relevantTagsForComponents = (
  tags: TagModel[],
  componentIds: Set<string>
) => tags.filter(t => t.components.some(c => componentIds.has(c.id)));

const relevantTagsForReference = (tags: TagModel[], referenceIds: string[]) => {
  const refIds = new Set(referenceIds);
  return tags.filter(t => t.references.find(r => refIds.has(r.id)));
};

const dragStarted = (
  state: TagscapeViewModel,
  { ids }: ExtractPayload<typeof afterDragStart>
): TagscapeViewModel => {
  const tags = state.tags.map(t => ({ ...t, droppable: !hasIds(t, ids) }));
  const disableAddingTagToDraggedComponent = hasComponentsFromOtherWorkspace(
    state.workspace.id,
    ids
  );

  return {
    ...state,
    tags,
    disableAddingTagToDraggedComponent,
  };
};

const dragEnded = (state: TagscapeViewModel): TagscapeViewModel => {
  const tags = state.tags.map(t => ({ ...t, droppable: false }));
  return { ...state, tags, disableAddingTagToDraggedComponent: false };
};

const addTag = (
  state: TagscapeViewModel,
  { tag }: ExtractPayload<typeof notifyTagAdded>
) => {
  const tags = [...state.tags, toViewModel(tag)];
  return { ...state, tags };
};

const removeTag = (
  state: TagscapeViewModel,
  { tag }: ExtractPayload<typeof notifyTagRemoved>
) => {
  const tags = state.tags.filter(t => t.cid !== tag.cid);
  return { ...state, tags };
};

const updateTag = (
  state: TagscapeViewModel,
  { tag }: ExtractPayload<typeof notifyTagUpdated>
) => {
  const tags = state.tags.map(t => {
    if (t.cid === tag.cid) {
      return toViewModel(tag);
    }
    return t;
  });
  return { ...state, tags };
};

const getHasComponentsInWorkspace = (workspaceId: string) =>
  !!componentInterface.getRootComponents(workspaceId).length;

const handleComponentLifecycle = (
  state: TagscapeViewModel,
  { componentIds }: ComponentIdsPayload
) => {
  const tags = relevantTagsForComponents(state.tags, new Set(componentIds))
    .length
    ? refreshTags(state.tags)
    : state.tags;

  const workspace = {
    ...state.workspace,
    hasComponents: getHasComponentsInWorkspace(state.workspace.id),
  };

  return { ...state, tags, workspace };
};

const handleRemoveUpdateReferences = (
  state: TagscapeViewModel,
  { referenceIds }: ReferenceIdsPayload
) => {
  const tags = relevantTagsForReference(state.tags, referenceIds).length
    ? refreshTags(state.tags)
    : state.tags;
  return { ...state, tags };
};

const handleWorkspaceChanged = (
  state: TagscapeViewModel,
  { workspaceId }: WorkspaceChanged
) => ({
  ...state,
  tags: workspaceId ? tagsForWorkspace(workspaceId) : [],
  workspace: {
    ...state.workspace,
    id: workspaceId ?? '',
    isWriter: workspaceInterface.hasWriteAccess(workspaceId ?? ''),
    hasComponents: getHasComponentsInWorkspace(workspaceId ?? ''),
  },
});

const handleSetTagsFromScenario = (
  state: TagscapeViewModel,
  tags: APITagAttributes[]
) => {
  const scenarioTagIds = new Set(tags.map(({ _id }) => _id));
  return {
    ...state,
    tags: refreshTags(state.tags.filter(tag => scenarioTagIds.has(tag.id))),
  };
};

const handleHoveredComponentChanged = (
  state: TagscapeViewModel,
  hoveredComponentId: ArdoqId | null
): TagscapeViewModel => ({
  ...state,
  tags: state.tags.map(tag => ({
    ...tag,
    components: tag.components.map(component => ({
      ...component,
      isHovered: component.id === hoveredComponentId,
    })),
    references: tag.references.map(reference => ({
      ...reference,
      isHovered: reference.id === hoveredComponentId,
    })),
  })),
});

const handleFocusedComponentChanged = (
  state: TagscapeViewModel,
  focusedComponentId: ArdoqId | null
): TagscapeViewModel => ({
  ...state,
  tags: state.tags.map(tag => ({
    ...tag,
    components: tag.components.map(component => ({
      ...component,
      isFocused: component.id === focusedComponentId,
    })),
    references: tag.references.map(reference => ({
      ...reference,
      isFocused: reference.id === focusedComponentId,
    })),
  })),
});

const initialState = emptyState;

// This is not a pattern we would like to adhere to, the reducers
// in this file are impure and reading from Context / Collections
// which is not ideal
const viewModel$ = persistentReducedStream(
  'tagscapeViewReducers',
  initialState,
  [
    reducer(afterDragStart, dragStarted),
    reducer(beforeDragEnd, dragEnded),
    reducer(notifyTagAdded, addTag),
    reducer(notifyTagRemoved, removeTag),
    reducer(notifyTagUpdated, updateTag),
    multiActionReducer(
      [notifyComponentsAdded, notifyComponentsRemoved, notifyComponentsUpdated],
      handleComponentLifecycle
    ),
    multiActionReducer(
      [notifyReferencesRemoved, notifyReferencesUpdated],
      handleRemoveUpdateReferences
    ),
    reducer(notifyWorkspaceChanged, handleWorkspaceChanged),
    reducer(setTagsFromScenario, handleSetTagsFromScenario),
    reducer(hoveredComponentChanged, handleHoveredComponentChanged),
    reducer(focusedComponentChanged, handleFocusedComponentChanged),
  ]
);

const viewStateStream = getViewSettingsStream<TagscapeViewSettings>(VIEW_ID);

export default combineLatest({
  viewModel: viewModel$,
  viewState: viewStateStream,
});
