import { filter, map, withLatestFrom } from 'rxjs';
import { ResourceType, APIComponentAttributes } from '@ardoq/api-types';
import { websocket$ } from 'sync/websocket$';
import { ArdoqEvent, isResourceUpdate } from 'sync/types';
import { allocateToBuffer } from '@ardoq/rxbeach';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';

const isComponentUpdate = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIComponentAttributes> =>
  isResourceUpdate(event) &&
  ardoqEventOperations.isOfResourceType(event, ResourceType.COMPONENT);

const isParentUpdate = (
  { _id, parent }: APIComponentAttributes,
  componentParentMap: LoadedGraphWithViewpointMode['componentParentMap']
) => {
  if (!componentParentMap.has(_id)) {
    return false;
  }
  return componentParentMap.get(_id) !== parent;
};

export const changedParents$ = websocket$.pipe(
  filter(isComponentUpdate),
  withLatestFrom(loadedGraph$),
  filter(
    ([event, { isViewpointMode, componentParentMap }]) =>
      isViewpointMode && isParentUpdate(event.data, componentParentMap)
  ),
  map(([{ data }]) => data),
  allocateToBuffer()
);
