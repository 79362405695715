import {
  DefaultLabelStyle,
  Font,
  HorizontalTextAlignment,
  IColumn,
  IGraph,
  IRow,
  ITable,
  Table,
  TableNodeStyle,
  VerticalTextAlignment,
} from '@ardoq/yfiles';
import { CollapsibleGraphGroup, GraphNode } from '@ardoq/graph';
import SwimStripeStyle from '../styles/SwimStripeStyle';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  SWIM_STRIPE_LABEL_FONT_SIZE,
  SWIM_STRIPE_LABEL_INSET_SIZE,
} from '../consts';

type BuildSwimTableArgs = {
  graph: IGraph;
  groups: CollapsibleGraphGroup[];
  nodes: GraphNode[];
  isVertical: boolean;
};
const buildSwimTable = ({
  graph,
  groups,
  nodes,
  isVertical,
}: BuildSwimTableArgs) => {
  const table = new Table();
  const columnDefaults = isVertical ? table.columnDefaults : table.rowDefaults;
  columnDefaults.style = new SwimStripeStyle();
  columnDefaults.labels.style = new DefaultLabelStyle({
    font: new Font(ARDOQ_DEFAULT_FONT_FAMILY, SWIM_STRIPE_LABEL_FONT_SIZE),
    insets: SWIM_STRIPE_LABEL_INSET_SIZE,
    verticalTextAlignment: VerticalTextAlignment.CENTER,
    horizontalTextAlignment: HorizontalTextAlignment.CENTER,
  });

  const createRow = isVertical
    ? table.createRow.bind(table)
    : table.createColumn.bind(table);
  const createColumn = isVertical
    ? table.createColumn.bind(table)
    : table.createRow.bind(table);
  const createChildColumn = isVertical
    ? table.createChildColumn.bind(table)
    : table.createChildRow.bind(table);

  /** one row to rule them all */
  const row = createRow();

  const rootGroups = groups.filter(group => !group.parent);

  const columns = new Map<CollapsibleGraphGroup | string, IRow | IColumn>();
  /** adds a column to the table, including sub-columns for the node's children */
  const addColumn = <T extends IColumn | IRow>(
    group: CollapsibleGraphGroup,
    parentColumn?: T
  ) => {
    const column = parentColumn
      ? (createChildColumn as unknown as (parent: T) => IRow | IColumn)(
          parentColumn
        )
      : createColumn();
    column.tag = group;
    const label = group.getLabel();

    table.addLabel(column, label);
    columns.set(group, column);
    const childGroups = groups.filter(item => item.parent === group);
    childGroups.forEach(childGroup => {
      addColumn(childGroup, column);
    });
  };
  if (rootGroups.length) {
    rootGroups.forEach(rootGroup => addColumn(rootGroup));
  } else {
    const syntheticRootColumn = isVertical
      ? table.createColumn({ tag: 'syntheticRoot' })
      : table.createRow({ tag: 'syntheticRoot' });
    columns.set('syntheticRoot', syntheticRootColumn);
  }
  const tableStyle = new TableNodeStyle(table);
  const tableGroupNode = graph.createGroupNode(
    null,
    table.layout.toRect(),
    tableStyle,
    'tableGroupNode'
  );

  nodes.forEach(graphNode => {
    const column = graphNode.parent
      ? columns.get(graphNode.parent as CollapsibleGraphGroup)!
      : columns.get('syntheticRoot')!;
    if (!column) {
      return;
    }

    const node = graph.nodes.find(({ tag }) => tag === graphNode);
    if (!node) {
      return;
    }
    ITable.placeNodeInCell(
      graph,
      node,
      tableGroupNode,
      isVertical ? (column as IColumn) : (row as IColumn),
      isVertical ? (row as IRow) : (column as IRow)
    );
  });
};
export default buildSwimTable;
