import type { ArdoqIcon, IsNegativeProps } from '@ardoq/icons';
import SvgComponentIcon from './SvgComponentIcon';
import { ComponentProps, SVGAttributes } from 'react';
import { NO_LINK_TARGET_DECORATION } from '@ardoq/graph';

type NodeIconProps = {
  icon: ArdoqIcon;
  circleColor?: string;
  iconColor: string;
  iconBounds: ComponentProps<typeof SvgComponentIcon>['bounds'];
} & IsNegativeProps &
  Pick<SVGAttributes<SVGCircleElement>, 'cx' | 'cy' | 'r'>;
const NodeIcon = ({
  icon,
  circleColor,
  iconColor,
  cx,
  cy,
  r,
  iconBounds,
  isNegative,
}: NodeIconProps) => (
  <>
    {circleColor && (
      <circle
        className={NO_LINK_TARGET_DECORATION}
        cx={cx}
        cy={cy}
        r={r}
        fill={circleColor}
      />
    )}
    <SvgComponentIcon
      icon={icon}
      color={iconColor}
      bounds={iconBounds}
      isNegative={isNegative}
    />
  </>
);
export default NodeIcon;
