import { DiffType } from '@ardoq/data-model';
import { Size } from '@ardoq/yfiles';
import { PLACEHOLDER_RADIUS } from './styles/consts';

interface GetNodeSizeArgs {
  styleWidth: number;
  styleHeight: number;
  hasContextHighlight: boolean;
  contextHighlightPadding: number;
  visualDiffType: DiffType;
}

const getNodeLayoutSize = ({
  styleWidth,
  styleHeight,
  hasContextHighlight,
  contextHighlightPadding,
  visualDiffType,
}: GetNodeSizeArgs) => {
  const padding = hasContextHighlight ? 2 * contextHighlightPadding : 0;

  if (visualDiffType === DiffType.PLACEHOLDER) {
    return new Size(
      2 * PLACEHOLDER_RADIUS + padding,
      2 * PLACEHOLDER_RADIUS + padding
    );
  }

  return new Size(styleWidth + padding, styleHeight + padding);
};

export default getNodeLayoutSize;
