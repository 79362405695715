import { Column, DatasourceTable } from '@ardoq/table';
import {
  GremlinComponentResult,
  GremlinPathResult,
  GremlinResultType,
} from '../types';
import GremlinPath from './GremlinPath';
import { showDetails } from '../utils';
import { SortByFn, SortById } from '@ardoq/pagination';
import { getIn } from 'utils/collectionUtil';
import { SortOrder } from '@ardoq/api-types';

interface GremlinResultsTableProps {
  results: (GremlinPathResult | GremlinComponentResult)[];
  printable?: boolean;
  sortBy: SortByFn;
  sortById: SortById;
  sortOrder?: SortOrder;
}

const getLabelsFromResult = (result: GremlinPathResult) =>
  result.objects.map(object => ({
    label: object.label,
    name: getIn<string>(object, ['properties', 'name', 0, 'value']) || '',
    type: object.type,
  }));

const GremlinResultsTablePath = (props: GremlinResultsTableProps) => (
  <DatasourceTable
    dataSource={props.results}
    data-intercom-target="gremlin results table"
    columns={
      [
        {
          title: 'Path',
          valueRender: (_: any, result: GremlinPathResult) => (
            <GremlinPath
              pathLabels={getLabelsFromResult(result)}
              printable={props.printable}
            />
          ),
          onHeaderClick: () =>
            props.sortBy((result: GremlinPathResult) => {
              const labels = getLabelsFromResult(result);
              return labels.map(({ label, name }) => label + name).join('');
            }, 'path'),
          sortOrder: props.sortById === 'path' && props.sortOrder,
        },
        !props.printable && {
          headerStyle: { width: '150px' },
          valueRender: (_: any, result: GremlinPathResult) => (
            <a onClick={() => showDetails(result, GremlinResultType.PATH)}>
              Show details
            </a>
          ),
        },
      ].filter(Boolean) as Column<GremlinPathResult | GremlinComponentResult>[]
    }
    renderEmptyTable={{ title: 'No results found' }}
  />
);

export default GremlinResultsTablePath;
