import { MainViewLoadingIndicatorState } from './mainViewLoadingIndicator$';
import { LoadingOverlay } from '@ardoq/layout';
import { connect } from '@ardoq/rxbeach';
import mainViewLoadingIndicator$ from './mainViewLoadingIndicator$';

export const MainPaneLoader = ({
  isLoading,
}: MainViewLoadingIndicatorState) => {
  return isLoading ? <LoadingOverlay /> : null;
};

export const MainPaneLoaderConnected = connect(
  MainPaneLoader,
  mainViewLoadingIndicator$
);
