import { APIEntityType, ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectComponent } from 'streams/components/ComponentActions';
import { toggleCollapse } from './models/viewModel$';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';

const VIEW_ID = ViewIds.COMPONENTTREE;

const toggleHoveredStateForComponent = (
  itemId: ArdoqId,
  isHovered: boolean,
  type?: APIEntityType
) => {
  if (type !== APIEntityType.COMPONENT) {
    return;
  }

  dispatchAction(hoveredComponentChanged(isHovered ? itemId : null), VIEW_ID);
};

const toggleFocusedStateForComponent = (
  itemId: ArdoqId,
  isCurrentlyFocused: boolean,
  type?: APIEntityType
) => {
  if (type !== APIEntityType.COMPONENT) {
    return;
  }

  if (isCurrentlyFocused) {
    dispatchAction(focusedComponentChanged(null), VIEW_ID);
    return;
  }

  dispatchAction(focusedComponentChanged(itemId), VIEW_ID);
};

const unfocusFocusedComponent = () => {
  dispatchAction(focusedComponentChanged(null), VIEW_ID);
};

const toggleSelectedStateForComponent = (
  itemId: ArdoqId,
  type?: APIEntityType
) => {
  if (type !== APIEntityType.COMPONENT) {
    return;
  }

  dispatchAction(selectComponent({ cid: itemId }));
};

const toggleCollapseHierarchy = (componentId: string) => {
  dispatchAction(toggleCollapse(componentId));
};

export const componentTreeCommands = {
  toggleHoveredStateForComponent,
  toggleFocusedStateForComponent,
  unfocusFocusedComponent,
  toggleSelectedStateForComponent,
  toggleCollapseHierarchy,
};
