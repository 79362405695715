import {
  ILabel,
  IRenderContext,
  LabelStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { getLabelPreferredSize } from 'yfilesExtensions/styles/measureLabels';
import {
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
} from 'yfilesExtensions/styles/consts';
import { colors } from '@ardoq/design-tokens';
import { createSvgElement } from '@ardoq/dom-utils';

class ProteanBottomNodeLabelStyle extends LabelStyleBase {
  fill?: string;
  font = BLOCK_DIAGRAM_NODE_LABEL_FONT;
  protected override createVisual(
    context: IRenderContext,
    label: ILabel
  ): Visual | null {
    const { text } = label;

    const container = createSvgElement('g');
    const textElement = createSvgElement('text', {
      transform: `translate(${LABEL_HORIZONTAL_PADDING / 2} ${
        LABEL_VERTICAL_PADDING / 2
      })`,
    });
    const {
      layout: { width, height },
    } = label;
    const backgroundRect = createSvgElement('rect', {
      fill: colors.white,
      width: `${width}`,
      height: `${height}`,
    });
    this.font.applyTo(textElement);
    addText({
      targetElement: textElement,
      text,
      font: this.font,
      ...BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
    });
    const layoutTransform = LabelStyleBase.createLayoutTransform(
      context,
      label.layout,
      false
    );
    layoutTransform.applyTo(container);
    container.appendChild(backgroundRect);
    container.appendChild(textElement);
    return new SvgVisual(container);
  }
  getPreferredSize(label: ILabel) {
    return getLabelPreferredSize(label.text, this.font);
  }
}

export default ProteanBottomNodeLabelStyle;
