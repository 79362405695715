import { ArdoqId, DirectedTriple } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';

export const getParentChildTriples = (
  componentId: ArdoqId,
  parent: ArdoqId
): DirectedTriple[] => {
  const typeName = componentInterface.getTypeName(componentId);
  const parentTypeName = componentInterface.getTypeName(parent);
  if (!typeName || !parentTypeName) return [];
  return [
    {
      direction: 'outgoing',
      sourceType: parentTypeName,
      referenceType: 'ardoq_parent',
      targetType: typeName,
    },
    {
      direction: 'incoming',
      sourceType: typeName,
      referenceType: 'ardoq_parent',
      targetType: parentTypeName,
    },
  ];
};
