import {
  MetamodelComponentTypeContext,
  MetamodelComponentTypeFieldContext,
  MetamodelReferenceTypeContext,
  MetamodelReferenceTypeFieldContext,
} from 'metamodel/types';
import {
  Metamodel,
  QueryBuilderQuery,
  QueryBuilderRule,
} from '@ardoq/api-types';
import { DisplayPreference } from './types';
import { escapeSingleQuotes } from '../utils';
import { dateRangeOperations } from '@ardoq/date-range';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { queryBuilderOperations } from '@ardoq/query-builder';

export const getComponentsOfTypeQuery = ({
  workspaceId,
  name: componentTypeName,
}: MetamodelComponentTypeContext) =>
  `g.V().
     has('rootWorkspace', '${workspaceId}').
     hasLabel('${escapeSingleQuotes(componentTypeName)}')`;

export const getReferencesOfTypeQuery = (
  {
    name: referenceTypeName,
    source: sourceId,
    target: targetId,
  }: MetamodelReferenceTypeContext,
  { metamodel }: Metamodel
) => {
  const { componentTypes } = metamodel;
  const { [sourceId]: source, [targetId]: target } = componentTypes;
  const { name: sourceTypeName, workspaceId: sourceWorkspaceId } = source;
  const { name: targetTypeName, workspaceId: targetWorkspaceId } = target;
  return `g.E().
            hasLabel('${escapeSingleQuotes(referenceTypeName)}').
            and(
              outV().has('rootWorkspace', '${sourceWorkspaceId}').
                hasLabel('${escapeSingleQuotes(sourceTypeName)}'),
              inV().has('rootWorkspace', '${targetWorkspaceId}').
                hasLabel('${escapeSingleQuotes(targetTypeName)}'))`;
};

const getEntitiesWithOrWithoutValueQuery = (
  context:
    | MetamodelComponentTypeFieldContext
    | MetamodelReferenceTypeFieldContext,
  entitiesQuery: string,
  displayPreference: DisplayPreference,
  isCheckboxField: boolean,
  isDateRangeField: boolean
) => {
  if (displayPreference === DisplayPreference.WITH_VALUE) {
    if (isCheckboxField) {
      return `${entitiesQuery}.has('${context.field}', 'true')`;
    }
    if (isDateRangeField) {
      return `${entitiesQuery}.has('${dateRangeOperations.toStartDateName(
        context.field
      )}').has('${dateRangeOperations.toEndDateName(context.field)}')`;
    }
    return `${entitiesQuery}.has('${context.field}')`;
  }
  if (isCheckboxField) {
    return `${entitiesQuery}.not(has('${context.field}', 'true'))`;
  }
  if (isDateRangeField) {
    return `${entitiesQuery}.not(has('${dateRangeOperations.toStartDateName(
      context.field
    )}').has('${dateRangeOperations.toEndDateName(context.field)}'))`;
  }
  return `${entitiesQuery}.not(has('${context.field}'))`;
};

const getFieldQueryRule = (
  context: MetamodelComponentTypeContext,
  displayPreference: DisplayPreference,
  isCheckboxField: boolean
): QueryBuilderRule | null => {
  if (!context.field) return null;
  const preferWithValue = displayPreference === DisplayPreference.WITH_VALUE;
  if (isCheckboxField) {
    return queryBuilderOperations.getCheckboxFieldQueryRule(
      context.field,
      preferWithValue
    );
  }
  if (preferWithValue) {
    return queryBuilderOperations.getFieldIsNotEmptyQueryRule(context.field);
  }
  return queryBuilderOperations.getFieldIsEmptyQueryRule(context.field);
};

export const getComponentsWithOrWithoutValueQueryBuilderQuery = (
  context: MetamodelComponentTypeFieldContext,
  displayPreference: DisplayPreference,
  isCheckboxField: boolean
): QueryBuilderQuery => {
  const rules = queryBuilderOperations.getEmptyQueryWithComponentContext();
  const queryRules = [
    queryBuilderOperations.getRootWorkspaceQueryRule(context.workspaceId),
    queryBuilderOperations.getComponentTypeQueryRule(context.name),
    getFieldQueryRule(context, displayPreference, isCheckboxField),
  ].filter(ExcludeFalsy);

  rules.rules[1].rules = queryRules;

  return rules;
};

export const getReferencesWithOrWithoutValueQuery = (
  context: MetamodelReferenceTypeFieldContext,
  metamodel: Metamodel,
  displayPreference: DisplayPreference,
  isCheckboxField: boolean,
  isDateRangeField: boolean
) =>
  getEntitiesWithOrWithoutValueQuery(
    context,
    getReferencesOfTypeQuery(context, metamodel),
    displayPreference,
    isCheckboxField,
    isDateRangeField
  );
