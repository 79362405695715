import Workspace from 'models/workspace';
import type { Workspace as WorkspaceBackboneModel } from 'aqTypes';
import { APIWorkspaceAttributes } from '@ardoq/api-types';
import { metaModelOverwrites } from './metaModelOverwrites';

type MetaModelWorkspaceType = WorkspaceBackboneModel & {
  new (attrs: Partial<APIWorkspaceAttributes>): WorkspaceBackboneModel;
};

export const MetaModelWorkspace: MetaModelWorkspaceType =
  Workspace.model.extend({
    ...metaModelOverwrites,
    hasWriteAccess: () => false,
  });
