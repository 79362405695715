import { GraphItem } from '@ardoq/graph';
import { LayoutConstraintRuleProps } from 'tabview/proteanDiagram/view/layoutRules/types';
import {
  ConstraintSource,
  ResolvedLayoutConstraint,
} from 'tabview/proteanDiagram/view/types';
import { componentMatchesSubquery } from './componentMatchesSubquery';

const { PERSPECTIVE, VIEW_SETTINGS } = ConstraintSource;

const resolveConstraintsReducer =
  (
    nodeConstraintsMap: Map<string, number>,
    constraintRules: LayoutConstraintRuleProps[]
  ) =>
  (
    resolvedConstraintsMap: Map<string, ResolvedLayoutConstraint>,
    graphNode: GraphItem
  ) => {
    const nodeId = graphNode.id;
    const constraint =
      nodeConstraintsMap.get(nodeId) ??
      constraintRules.find(rule =>
        componentMatchesSubquery(graphNode.modelId, rule.componentRules)
      );
    if (!constraint) {
      return resolvedConstraintsMap;
    }
    const constraintValue =
      typeof constraint === 'number' ? constraint : constraint.order;
    const constraintSource =
      typeof constraint === 'number' ? VIEW_SETTINGS : PERSPECTIVE;
    const resolvedConstraint: ResolvedLayoutConstraint = [
      nodeId,
      constraintValue,
      constraintValue,
      constraintSource,
    ];
    resolvedConstraintsMap.set(nodeId, resolvedConstraint);
    return resolvedConstraintsMap;
  };
export default resolveConstraintsReducer;
