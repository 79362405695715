import { BLOCKS_VIEW_ROUTING_MARGIN, BLOCKS_VIEW_ROUTING_SEP } from '../consts';
import type { Vector } from '@ardoq/graph';
import { BlocksViewLink, BlocksViewNode } from '../types';
import { getLCA, isAscendant } from '../viewModel$/util';
import { getOpenAscendant } from '../viewModel$/util';
import { getColPositions, getRowPositions } from './util';
import { isAscendancyOpen } from '../viewModel$/util';
import { BlockGrid, getBlockGrid } from './blockGrid';
import { vectorDot } from '../misc/geometry';
import {
  routeAscendant,
  routeCousins,
  routeDescendant,
  routeSelf,
  routeSiblings,
} from './route';

type RoutingChannel = Map<string, number>;

export type Waypoint = {
  colRef: BlocksViewNode; // wp cols and rows are in this node's channels
  col: number;
  μcol: number;
  rowRef?: BlocksViewNode; // wp cols and rows are in this node's channels
  row: number;
  μrow: number;
};

export type RoutingInfo = {
  assignMicroCol: (link: BlocksViewLink, col: number) => number;
  assignMicroRow: (link: BlocksViewLink, col: number) => number;
  colChannels: RoutingChannel[];
  rowChannels: RoutingChannel[];
  blockGrid: BlockGrid;
};

export type Router = {
  routeLink: (link: BlocksViewLink) => void;
  adjustSizes: () => void; // boost grid's col and row sizes to make room for routing
  setLinkRoute: () => void; // set route on links
};

const push = (vectors: Vector[], v: Vector) => {
  if (vectors.length > 2) {
    const a: Vector = [
      v[0] - vectors[vectors.length - 2][0],
      v[1] - vectors[vectors.length - 2][1],
    ];

    const b: Vector = [
      vectors[vectors.length - 2][1] - vectors[vectors.length - 1][1],
      vectors[vectors.length - 1][0] - vectors[vectors.length - 2][0],
    ];

    const dot = vectorDot(a, b);

    if (Math.abs(dot) < 0.1) {
      vectors[vectors.length - 1][0] = v[0];
      vectors[vectors.length - 1][1] = v[1];

      return;
    }
  }

  vectors.push(v);
};

export const createRouter = (getBusId: (e: BlocksViewLink) => string) => {
  const routingInfos = new Map<BlocksViewNode, RoutingInfo>();
  const waypoints = new Map<BlocksViewLink, Waypoint[]>();

  const createRoutingInfo = (node: BlocksViewNode) => {
    const colChannels: RoutingChannel[] = [];
    const rowChannels: RoutingChannel[] = [];

    const assignMicroChannel = (
      link: BlocksViewLink,
      channels: RoutingChannel[],
      index: number
    ): number => {
      let channel = channels[index];

      if (!channel) {
        channel = new Map<string, number>();
        channels[index] = channel;
      }

      const busid = getBusId(link);

      let μchannel = channel.get(busid);

      if (μchannel === undefined) {
        μchannel = channel.size;
        channel.set(busid, μchannel);
      }

      return μchannel;
    };

    const assignMicroCol = (link: BlocksViewLink, col: number) => {
      return assignMicroChannel(link, colChannels, col);
    };

    const assignMicroRow = (link: BlocksViewLink, row: number) => {
      return assignMicroChannel(link, rowChannels, row);
    };

    return {
      assignMicroCol: assignMicroCol,
      assignMicroRow: assignMicroRow,
      colChannels: colChannels,
      rowChannels: rowChannels,
      blockGrid: getBlockGrid(node),
    };
  };

  const getRoutingInfo = (node: BlocksViewNode) => {
    let routingInfo: RoutingInfo | undefined = routingInfos.get(node);

    if (!routingInfo) {
      routingInfo = createRoutingInfo(node);

      routingInfos.set(node, routingInfo);
    }

    return routingInfo;
  };

  const calcWaypoints = (link: BlocksViewLink) => {
    link.sourceProxy = undefined;
    link.targetProxy = undefined;
    link.bounds = undefined;

    const lca = getLCA(link.source, link.target);

    if (!lca || !lca.open || !isAscendancyOpen(lca)) {
      /* invisible reference */
      return;
    }

    link.sourceProxy = getOpenAscendant(link.source);
    link.targetProxy = getOpenAscendant(link.target);

    if (link.sourceProxy === link.targetProxy && link.source !== link.target) {
      /* fake self-reference */
      return;
    }

    if (link.sourceProxy === link.targetProxy) {
      waypoints.set(link, routeSelf(link, link.sourceProxy, getRoutingInfo));
      return;
    }

    if (link.sourceProxy.parent === lca && link.targetProxy.parent === lca) {
      waypoints.set(link, routeSiblings(link, getRoutingInfo));
      return;
    }

    if (isAscendant(link.targetProxy, link.sourceProxy)) {
      waypoints.set(link, routeAscendant(link, getRoutingInfo));
      return;
    }

    if (isAscendant(link.sourceProxy, link.targetProxy)) {
      waypoints.set(link, routeDescendant(link, getRoutingInfo));
      return;
    }

    waypoints.set(link, routeCousins(link, getRoutingInfo, lca));
    return;
  };

  // exported function to boost the node col and row sizes to accomodate the routing
  const adjustSizes = (node: BlocksViewNode) => {
    const routingInfo = routingInfos.get(node);

    if (!routingInfo || !node.colSize || !node.rowSize) {
      return;
    }

    for (let col = 0; col < node.colSize.length; ++col) {
      const channel = routingInfo.colChannels[col];

      if (!channel) {
        continue;
      }

      const μchannels = channel.size;
      const routingWidth =
        μchannels > 0
          ? 2 * BLOCKS_VIEW_ROUTING_MARGIN +
            (μchannels - 1) * BLOCKS_VIEW_ROUTING_SEP
          : 0;

      node.colSize[col] = Math.max(node.colSize[col], routingWidth);
    }

    for (let row = 0; row < node.rowSize.length; ++row) {
      const channel = routingInfo.rowChannels[row];

      if (!channel) {
        continue;
      }

      const μchannels = channel.size;
      const routingHeight =
        μchannels > 0
          ? 2 * BLOCKS_VIEW_ROUTING_MARGIN +
            (μchannels - 1) * BLOCKS_VIEW_ROUTING_SEP
          : 0;

      node.rowSize[row] = Math.max(node.rowSize[row], routingHeight);
    }
  };

  // exported function to create the route from a routed link's waypoints
  const getRoute = (link: BlocksViewLink) => {
    let node: BlocksViewNode | null = null; // cache key
    let routingInfo: RoutingInfo | null = null; // cached routing info for node
    let colPos: number[] = []; // cached column positions for node
    let rowPos: number[] = []; // cached row positions for node

    const linkWaypoints = waypoints.get(link);

    if (!linkWaypoints) {
      return undefined;
    }

    const route: Vector[] = [];

    for (const waypoint of linkWaypoints) {
      /* get column stuff for x */

      if (waypoint.colRef !== node) {
        node = waypoint.colRef;
        routingInfo = routingInfos.get(node!)!;
        colPos = getColPositions(node!);
        rowPos = getRowPositions(node!);
      }

      if (!routingInfo) {
        continue;
      }

      const colWidth = node.colSize![waypoint.col];
      const colChannel = routingInfo.colChannels[waypoint.col];
      const routingWidth = colChannel
        ? (colChannel.size - 1) * BLOCKS_VIEW_ROUTING_SEP
        : 0;
      const x =
        waypoint.μcol === -Infinity
          ? colPos[waypoint.col]
          : waypoint.μcol === +Infinity
            ? colPos[waypoint.col] + colWidth
            : colPos[waypoint.col] +
              0.5 * (colWidth - routingWidth) +
              waypoint.μcol * BLOCKS_VIEW_ROUTING_SEP;

      /* get row stuff and y */

      if (waypoint.rowRef && waypoint.rowRef !== node) {
        node = waypoint.rowRef;
        routingInfo = routingInfos.get(node!)!;
        colPos = getColPositions(node!);
        rowPos = getRowPositions(node!);
      }

      if (!routingInfo) {
        continue;
      }

      const rowHeight = node.rowSize![waypoint.row];
      const rowChannel = routingInfo.rowChannels[waypoint.row];
      const routingHeight = rowChannel
        ? (rowChannel.size - 1) * BLOCKS_VIEW_ROUTING_SEP
        : 0;

      const y =
        waypoint.μrow === -Infinity
          ? rowPos[waypoint.row]
          : waypoint.μrow === +Infinity
            ? rowPos[waypoint.row] + rowHeight
            : rowPos[waypoint.row] +
              0.5 * (rowHeight - routingHeight) +
              waypoint.μrow * BLOCKS_VIEW_ROUTING_SEP;

      push(route, [x, y]);
    }

    return route;
  };

  return {
    link: calcWaypoints, // calculate (and remember) the link's waypoints
    adjustSizes: adjustSizes,
    route: getRoute, // calculate the link's positions
  };
};
