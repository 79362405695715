import {
  StreamCollection,
  createStreamFromBackboneCollection,
} from 'streams/utils/streamUtils';
import models from 'collections/models';
import { APIModelAttributes } from '@ardoq/api-types';

export type ModelsState = StreamCollection<APIModelAttributes>;

export const models$ = createStreamFromBackboneCollection<APIModelAttributes>(
  models.collection,
  [
    'name',
    'useAsTemplate',
    'common',
    'category',
    'flexible',
    'createdBy',
    'created-by',
    'startView',
    'description',
    'root',
    'referenceTypes',
    'blankTemplate',
  ]
);
