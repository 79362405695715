import { Fragment } from 'react';
import { BubbleLegendContainer, LegendBubble, LegendLabel } from './atoms';
import {
  BUBBLE_LEGEND_STROKE_WIDTH,
  PADDING_RIGHT_BUBBLES,
  PADDING_TOP_BUBBLES,
  SPACE_FOR_ZERO,
} from './consts';
import { BubbleRadiusLegendProps } from './types';
import { getLegendData } from './utils';

const BubbleRadiusLegend = (props: BubbleRadiusLegendProps) => {
  const {
    legendBubbleData,
    maxLabelWidth,
    svgHeight,
    svgWidth,
    maxRadius,
    maxBubbleDiameterWithStroke,
  } = getLegendData(props);
  const bubbleCenterX = maxRadius + BUBBLE_LEGEND_STROKE_WIDTH;

  const { includeZero } = props;
  const actualSvgHeight = svgHeight + (includeZero ? SPACE_FOR_ZERO : 0);
  const zeroY = actualSvgHeight - 7;
  const labelX = maxBubbleDiameterWithStroke + PADDING_RIGHT_BUBBLES;
  return (
    <BubbleLegendContainer>
      <svg height={`${actualSvgHeight}px`} width={`${svgWidth}px`}>
        {legendBubbleData.map(({ label, radius }, i) => (
          <Fragment key={`legend-data-${i}`}>
            <LegendBubble
              radius={radius}
              leaderLineWidth={maxBubbleDiameterWithStroke}
              centerX={bubbleCenterX}
              centerY={
                maxBubbleDiameterWithStroke -
                radius -
                BUBBLE_LEGEND_STROKE_WIDTH +
                PADDING_TOP_BUBBLES
              }
            />
            <LegendLabel
              label={label}
              width={maxLabelWidth}
              x={labelX}
              y={maxBubbleDiameterWithStroke - 2 * radius + PADDING_TOP_BUBBLES}
            />
          </Fragment>
        ))}
        {props.includeZero && (
          <>
            <LegendBubble
              radius={0}
              leaderLineWidth={maxBubbleDiameterWithStroke}
              centerX={bubbleCenterX}
              centerY={zeroY}
            />
            <LegendLabel label="0" width={maxLabelWidth} x={labelX} y={zeroY} />
          </>
        )}
      </svg>
    </BubbleLegendContainer>
  );
};
export default BubbleRadiusLegend;
