export const TEXT_NOMINALSIZE = 14;
export const SUBLABEL_NOMINALSIZE = 16;
export const EXPANDED_GROUP_TEXT_NOMINALSIZE = 18;
export const NODE_MARGIN = 28; // initial node margin
export const NODE_PADDING = 100; // initial node padding
export const NODE_RADIUS = 27.5;
export const GROUP_CHILDREN_LIMIT = 1000; // biggest group before automatic subdivision
export const EXPANDER_RADIUS = NODE_MARGIN / 2;

export const HIGHLIGHT_DISCONNECTED_COMPONENTS =
  'Highlight disconnected components';

export const BUTTON_CLICK_ZOOM_FACTOR = 1.2;
export const ONE_DEGREE_TO_RADIANS = Math.PI / 180;

export const ROOT_LEAF_GROUP_ID = 'rootLeafGroup';
