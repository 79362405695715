import { ChartConfiguration } from './types';
import { colors } from '@ardoq/design-tokens';

type ChartCircularSegmentProps = {
  levelIndex: number;
  axisIndex: number;
  config: ChartConfiguration;
};

export const ChartCircularSegment = ({
  axisIndex,
  levelIndex,
  config: { levels, height, width, total, radians, radius },
}: ChartCircularSegmentProps) => {
  const levelFactor = radius * ((levelIndex + 1) / levels);

  return (
    <line
      x1={levelFactor * (1 - Math.sin((axisIndex * radians) / total))}
      x2={levelFactor * (1 - Math.sin(((axisIndex + 1) * radians) / total))}
      y1={levelFactor * (1 - Math.cos((axisIndex * radians) / total))}
      y2={levelFactor * (1 - Math.cos(((axisIndex + 1) * radians) / total))}
      style={{
        stroke: colors.grey60,
        strokeOpacity: 0.75,
        strokeWidth: '0.3px',
      }}
      transform={`translate(${width / 2 - levelFactor}, ${
        height / 2 - levelFactor
      })`}
    />
  );
};
