import {
  BridgeManager,
  DefaultEdgePathCropper,
  GeneralPath,
  type IEdge,
  type IPoint,
  type IRenderContext,
  Point,
} from '@ardoq/yfiles';
import { MARKER_PROPS } from '@ardoq/icons';
import { ArdoqId, ArrowType } from '@ardoq/api-types';
import {
  AggregatedGraphEdge,
  GraphEdge,
  GraphItem,
  ParentChildGraphEdge,
  REFERENCE_PATH_STROKE_WIDTH,
} from '@ardoq/graph';
import { Edge as OldGraphEdge } from 'graph/edge';

const getMarkerOffset = (lineCap: ArrowType, isEndPoint: boolean) => {
  // although the typing indicates MARKER_PROPS[lineCap] will be non-null, error logs indicate otherwise. to be extra safe, coerce nulls to 0
  const refX = MARKER_PROPS[lineCap]?.refX ?? 0;
  if (!isEndPoint) {
    return REFERENCE_PATH_STROKE_WIDTH * refX;
  }
  const markerWidth = MARKER_PROPS[lineCap]?.markerWidth ?? 0;
  return REFERENCE_PATH_STROKE_WIDTH * (markerWidth - refX);
};

const offsetEndPoint = (
  point: IPoint,
  nextPoint: IPoint,
  length: number,
  lineCap: ArrowType,
  isEndPoint: boolean
) => {
  if (!length) {
    return point;
  }

  const offset = getMarkerOffset(lineCap, isEndPoint);
  const { x: x1, y: y1 } = point;
  const { x: x2, y: y2 } = nextPoint;
  return new Point(
    x1 - ((x1 - x2) / length) * offset,
    y1 - ((y1 - y2) / length) * offset
  );
};
const edgePathCropper = new DefaultEdgePathCropper();
const cropPathAtSourceAndTarget = (edge: IEdge, path: GeneralPath) =>
  edgePathCropper.cropEdgePath(
    edge,
    true,
    null,
    edgePathCropper.cropEdgePath(edge, false, null, path)
  );

export const createPath = (edge: IEdge, isHover: boolean) => {
  const startPort = edge.sourcePort!.location;
  const endPort = edge.targetPort!.location;

  const secondPoint = edge.bends.size ? edge.bends.first().location : endPort;
  const startLength = startPort.distanceTo(secondPoint);

  const graphEdge = edge.tag as GraphEdge | OldGraphEdge;

  const pathStart = isHover
    ? startPort
    : offsetEndPoint(
        startPort,
        secondPoint,
        startLength,
        graphEdge.getLineBeginning(),
        false
      );

  const path = new GeneralPath();
  path.moveTo(pathStart);

  edge.bends.forEach(bend => {
    if (bend.location.y !== path.lastY || bend.location.x !== path.lastX) {
      path.lineTo(bend.location);
    }
  });
  const penultimatePoint = path.lastCoordinate;
  const endLength = penultimatePoint.distanceTo(endPort);

  const pathEnd = isHover
    ? endPort
    : offsetEndPoint(
        endPort,
        penultimatePoint,
        endLength,
        graphEdge.getLineEnding(),
        true
      );

  path.lineTo(pathEnd);

  return cropPathAtSourceAndTarget(edge, path);
};
export const createPathWithBridges = (
  path: GeneralPath,
  renderContext: IRenderContext
) => {
  const manager = getBridgeManager(renderContext);
  return manager ? manager.addBridges(renderContext, path) : path;
};

const getBridgeManager = (renderContext: IRenderContext) => {
  const bridgeManager = renderContext.lookup(BridgeManager.$class);
  return bridgeManager instanceof BridgeManager && bridgeManager;
};
export const edgeModelIds = (edge: IEdge): ArdoqId[] =>
  edge.tag instanceof ParentChildGraphEdge
    ? []
    : edge.tag instanceof AggregatedGraphEdge
      ? edge.tag.modelIds
      : edge.tag instanceof GraphItem
        ? [edge.tag.modelId]
        : [(edge.tag as OldGraphEdge).dataModel.id];
