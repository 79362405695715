import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { filterInterface } from '@ardoq/filter-interface';
import {
  ItemLabels,
  TraversalViewModel,
  getComponentTypesInfo,
  getGraphEdgeLabel,
  getGraphNodeOrGroupLabel,
  getIsShowReferenceTypeApplied,
  getReferenceTypes,
  reduceLegendReferenceTypes,
  getNodeMainLabel,
  RawGraphItem,
} from '@ardoq/graph';
import { Features, hasFeature } from '@ardoq/features';
import { type ArdoqId, ViewIds } from '@ardoq/api-types';
import { isEqual, uniq, uniqWith } from 'lodash';
import dependencyMapTraversalViewModel from './dependencyMapTraversalViewModel';
import applyUniqueIds from './applyUniqueIds';
import { isPresentationMode } from 'appConfig';
import urlFieldValues from 'tabview/graphViews/urlFieldValues';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  type DependencyMapViewModel,
  INFINITE_DEGREES,
  NODE_LIMIT,
  getReferenceChildMaxDepth,
  getReferenceParentMaxDepthReducer,
  limitNodes,
  nodeFromComponentId,
  sortNodes,
  getMaxTreeDepth,
  consolidateNodeReferenceParentsReducer,
  buildNodes,
  getNodeAddressMap,
  collapseNodes,
  hasNonComponentNodes,
  nodeComponentIdsReducer,
  nodeReferenceIdsReducer,
  nodeReferenceTypeIdToLegendReferenceType,
  buildNodesWithHierarchy,
  ReferenceListReference,
} from '@ardoq/dependency-map';
import { getDependencyMapGroupingData, getDirectReferenceNodes } from './util';
import { trackNodeCountWithPresentationMeta } from 'tracking/events/visualizations';
import { format } from 'utils/numberUtils';
import { trackEvent } from 'tracking/tracking';
import { dispatchAction } from '@ardoq/rxbeach';
import { bypassLimit as bypassLimitAction } from './actions';
import cleanNode from './cleanNode';
import { ItemsType, nodeLimitError } from '@ardoq/error-info-box';
import {
  getTraverseAndGroupingResult,
  getTraverseAndGroupingResultForTraversalHierarchy,
  getViewSettingsTraverseConfig,
} from './traverseAndGrouping';
import {
  BuildViewModelArgs,
  GroupingResult,
  ViewpointTraversalHierarchyGroupingResult,
  ViewSettingsTraverseConfig,
} from './types';
import { LabelsData } from '@ardoq/dependency-map';

const VIEW_ID = ViewIds.DEPENDENCY_MAP_2;

const VIEWPOINT_MODE_VIEW_SETTINGS_TRAVERSE_CONFIG: ViewSettingsTraverseConfig =
  {
    referenceTraverseOptions: {
      maxDegreesIncoming: 0,
      maxDegreesOutgoing: 0,
      maxDegrees: 0,
      isParentRelationAsReference: false,
      incomingReferenceTypes: null,
      outgoingReferenceTypes: null,
    },
  };

const ancestors = (componentId: ArdoqId, depth: number): ArdoqId[] => {
  if (!depth) {
    return [];
  }
  const parentId = componentInterface.getParentId(componentId);
  if (!parentId) {
    return [];
  }
  const parentComponentAncestors = ancestors(parentId, depth - 1);
  if (!parentComponentAncestors.length) {
    return [parentId];
  }
  return [parentId, ...parentComponentAncestors];
};
const descendants = (componentId: ArdoqId, depth: number): ArdoqId[] => {
  if (!depth) {
    return [];
  }
  const children = componentInterface.getChildren(componentId) ?? [];

  return children.flatMap(childId => descendants(childId, depth - 1));
};

const isViewpointTraversalGroupingResult = (
  groupingResult: GroupingResult | ViewpointTraversalHierarchyGroupingResult
): groupingResult is ViewpointTraversalHierarchyGroupingResult =>
  'graphAsHierarchy' in groupingResult;

const buildViewModel = ({
  context,
  viewSettings,
  graphModel,
  loadedScenarioData,
  scenarioRelatedComponents,
  showScopeRelated,
  bypassLimit,
  loadedGraph,
}: BuildViewModelArgs): DependencyMapViewModel => {
  const isViewpointMode = loadedGraph.isViewpointMode;
  const groupingImplicitTraversalsEnabled = hasFeature(
    Features.GROUPING_IMPLICIT_TRAVERSALS
  );

  const groupingData = getDependencyMapGroupingData({
    loadedScenarioData,
    showScopeRelated,
    scenarioRelatedComponents,
    isViewpointMode,
  });

  const {
    treeDepth,
    colorByReference,
    degreesOfChildhood: viewSettingsDegreesOfChildhood,
    degreesOfParenthood: viewSettingsDegreesOfParenthood,
    showOnlyConnectedComponents: viewSettingsShowOnlyConnectedComponents,
    incomingDegreesOfRelationship,
    outgoingDegreesOfRelationship,
  } = viewSettings;

  const showOnlyConnectedComponents =
    viewSettingsShowOnlyConnectedComponents && !isViewpointMode;
  const [degreesOfParenthood, degreesOfChildhood] = [
    viewSettingsDegreesOfParenthood,
    viewSettingsDegreesOfChildhood,
  ].map(value => (value === INFINITE_DEGREES ? Infinity : value));

  const { appliedGroupingRules, supportedUserDefinedGroupingRules } =
    groupingData;

  const viewSettingsTraverseConfig = isViewpointMode
    ? VIEWPOINT_MODE_VIEW_SETTINGS_TRAVERSE_CONFIG
    : getViewSettingsTraverseConfig({
        supportedUserDefinedGroupingRules:
          groupingData.supportedUserDefinedGroupingRules,
        viewSettings,
        graphModel,
        scenarioRelatedComponents,
        groupingImplicitTraversalsEnabled,
        degreesOfChildhood,
      });

  const shouldUseViewpointTraversalHierarchy =
    isViewpointMode && !groupingData.hasUserDefinedReferenceTypeGroups;

  const traverseAndGroupingResult = shouldUseViewpointTraversalHierarchy
    ? getTraverseAndGroupingResultForTraversalHierarchy({
        graphModel,
        loadedGraph,
        workspacesIds: context.workspacesIds,
        appliedGroupingRules,
        hasValidUserDefinedGroups: supportedUserDefinedGroupingRules.length > 0,
      })
    : getTraverseAndGroupingResult({
        context,
        viewSettings,
        graphModel,
        viewSettingsTraverseConfig,
        loadedGraph,
        groupingData,
        includeOnlyConnectedComponents: showOnlyConnectedComponents,
      });

  const { startSet, graph } = traverseAndGroupingResult;
  const { componentMap, groupMap, referenceList: graphReferenceList } = graph;

  const referenceList: ReferenceListReference[] = graphReferenceList
    .filter(reference => !reference.isParentChildReference)
    .map(reference => ({
      ...reference,
      referenceTypeId:
        (reference.modelId &&
          referenceInterface.getGlobalTypeId(reference.modelId)) ??
        null,
    }));

  const componentGroupIds = Array.from(groupMap.values())
    .map(group => group.modelId)
    .filter(
      maybeComponentId =>
        maybeComponentId && componentInterface.isComponent(maybeComponentId)
    ) as ArdoqId[];

  // componentMap intentionally does not contain reference parents and children
  const allComponentIds = uniq(
    Array.from(componentMap.keys()).concat(componentGroupIds)
  );

  const potentialReferencedComponentsHierarchy = uniq(
    allComponentIds.flatMap(componentId => {
      const descendantIds = descendants(
        componentId,
        viewSettingsDegreesOfChildhood
      );
      const ancestorIds = ancestors(
        componentId,
        viewSettingsDegreesOfParenthood
      );
      return descendantIds.concat(ancestorIds);
    })
  );

  /**
   * Component nodes query data by the modelId, unless they are a group node.
   * In that case, the index is the item id, which is a concatenated path.
   */

  const componentItems = new Map<string, RawGraphItem>(
    allComponentIds.concat(potentialReferencedComponentsHierarchy).map(id => [
      id,
      {
        id,
        modelId: id,
        label: '',
        subLabels: [],
      },
    ])
  );

  const labelsData: LabelsData = {
    nodeLabelsData: new Map(
      [...componentItems, ...groupMap].map(([id, item]) => {
        const modelId = item.modelId ?? id;

        const isComponent = componentInterface.isComponent(modelId);

        const labelData = isViewpointMode
          ? getGraphNodeOrGroupLabel(item, isViewpointMode)
          : {
              mainLabel: getNodeMainLabel(item, isComponent, isViewpointMode),
              subLabel: item.subLabels?.join(', '),
            };

        return [id, labelData];
      })
    ),
    edgeLabelsData: new Map<string, ItemLabels>(
      referenceList.map(rawGraphItemWithTypeId => [
        rawGraphItemWithTypeId.id,
        getGraphEdgeLabel(
          rawGraphItemWithTypeId,
          isViewpointMode,
          getIsShowReferenceTypeApplied(
            filterInterface.getAllReferenceLabelFilters()
          )
        ),
      ])
    ),
  };

  const { incomingReferenceTypes, outgoingReferenceTypes } =
    viewSettingsTraverseConfig.userTraverseOptions ?? {
      incomingReferenceTypes: null,
      outgoingReferenceTypes: null,
    };

  const uncollapsedNodes =
    shouldUseViewpointTraversalHierarchy &&
    isViewpointTraversalGroupingResult(traverseAndGroupingResult)
      ? buildNodesWithHierarchy({
          ...traverseAndGroupingResult,
          referenceList,
          showOnlyConnectedComponents: false,
          degreesOfParenthood,
          degreesOfChildhood,
          cleanNode,
          labelsData,
        })
      : buildNodes({
          ...traverseAndGroupingResult,
          referenceList,
          incomingDegreesOfRelationship: isViewpointMode
            ? Infinity
            : incomingDegreesOfRelationship,
          outgoingDegreesOfRelationship: isViewpointMode
            ? Infinity
            : outgoingDegreesOfRelationship,
          incomingReferenceTypes,
          outgoingReferenceTypes,
          showOnlyConnectedComponents,
          isUserDefinedGrouping:
            groupingData.supportedUserDefinedGroupingRules.length > 0,
          degreesOfParenthood,
          degreesOfChildhood,
          contextComponentModelId: context.componentId,
          cleanNode,
          isViewpointMode,
          labelsData,
        });

  const graphItemIdsByNodeId = applyUniqueIds(uncollapsedNodes);
  const nodeAddressMap = getNodeAddressMap({
    nodes: uncollapsedNodes,
    groupMap,
    graphItemIdsByNodeId,
    getDirectReferenceNodes,
  });
  const maxTreeDepth = Math.max(...uncollapsedNodes.map(getMaxTreeDepth));

  const { result: collapsedNodes } = collapseNodes(
    uncollapsedNodes,
    treeDepth ?? Infinity, // Infinity gets cast to null when stored as view setting of a slide.
    nodeAddressMap
  );

  const {
    nodes: nodesToRender,
    itemCount,
    originalItemCount,
  } = bypassLimit
    ? { nodes: collapsedNodes, itemCount: NaN, originalItemCount: NaN }
    : limitNodes(collapsedNodes, NODE_LIMIT);
  const componentIds = [
    ...nodesToRender.reduce(nodeComponentIdsReducer, new Set()),
  ];
  const { componentTypes, legendComponentTypes } =
    getComponentTypesInfo(componentIds);

  const formattingFilters = filterInterface.getFormattingFilters();
  const referenceTypeIdToLegendType =
    nodeReferenceTypeIdToLegendReferenceType(formattingFilters);
  const referenceIds = uniqWith(
    [...nodesToRender.reduce(nodeReferenceIdsReducer, new Set())],
    isEqual
  );
  const referenceTypes = getReferenceTypes(referenceIds);
  const legendReferenceTypes = colorByReference
    ? referenceTypes
        .map(({ referenceType }) =>
          referenceTypeIdToLegendType(referenceType?.id ?? '')
        )
        .filter(ExcludeFalsy)
        .reduce(reduceLegendReferenceTypes, {
          result: [],
          keyedResults: new Map(),
          considerLineCaps: false,
        }).result
    : [];
  const { errors: groupingErrors, hasClones } = graph;

  const noConnectedComponents =
    showOnlyConnectedComponents && nodesToRender.length === 0;
  const contextComponentIsNotConnected =
    noConnectedComponents && context.componentId;

  const errors = [
    ...groupingErrors,
    ...groupingData.unsupportedUserDefinedGroupingRules,
    itemCount < originalItemCount &&
      nodeLimitError({
        itemCount: originalItemCount,
        formatNumber: format,
        onProceedAnyway: () => {
          trackEvent('Clicked "Proceed anyway" button');
          dispatchAction(bypassLimitAction());
        },
        limit: NODE_LIMIT,
        itemsType: ItemsType.COMPONENTS_AND_REFERENCES,
      }),
  ].filter(ExcludeFalsy);

  const referenceParentMaxDepthReducer = getReferenceParentMaxDepthReducer(
    componentInterface,
    referenceInterface
  );
  const referenceParentMaxDepth = nodesToRender.reduce(
    referenceParentMaxDepthReducer,
    0
  );

  const referenceChildMaxDepth = getReferenceChildMaxDepth(
    componentInterface,
    referenceInterface
  );
  const referenceChildrenMaxDepth = Math.max(
    ...nodesToRender.map(referenceChildMaxDepth)
  );

  const { baseTraverseOptions, referenceTraverseOptions } =
    viewSettingsTraverseConfig;

  // This is a hacky quick-win performance improvement to reduce calls to
  // buildGraph (which can be quite expensive) in presentations. The
  // traversalViewModel is only used to populate the traversal dropdown menu in
  // the app, so it serves no purpose in presentations.
  const traversalViewModel: TraversalViewModel =
    isViewpointMode || isPresentationMode()
      ? {
          traversedOutgoingReferenceTypes: [],
          traversedIncomingReferenceTypes: [],
          referenceTypes: [],
        }
      : dependencyMapTraversalViewModel({
          startSet,
          graphModel,
          baseTraverseOptions,
          referenceTraverseOptions,
        });

  const nodes = contextComponentIsNotConnected
    ? [
        {
          ...nodeFromComponentId(context.componentId, labelsData),
          id: context.componentId,
        },
      ]
    : sortNodes(nodesToRender).reduce(
        consolidateNodeReferenceParentsReducer,
        []
      );

  const { urlFieldValuesByComponentId, urlFieldValuesByReferenceId } =
    urlFieldValues({
      componentTypes,
      referenceTypes,
      componentIds,
      referenceIds,
    });

  trackNodeCountWithPresentationMeta(VIEW_ID, itemCount);

  return {
    nodes,
    componentTypes: legendComponentTypes,
    legendReferenceTypes,
    ...traversalViewModel,
    maxTreeDepth,
    errors,
    hasClones,
    itemCount,
    isUserDefinedGrouping:
      groupingData.supportedUserDefinedGroupingRules.length > 0,
    noConnectedComponents,
    referenceParentMaxDepth,
    referenceChildMaxDepth:
      referenceChildrenMaxDepth === -Infinity ? 0 : referenceChildrenMaxDepth,
    nodeAddressMap,
    hasNonComponentNodes: nodesToRender.some(hasNonComponentNodes),
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
    isViewpointMode,
  };
};

export default buildViewModel;
