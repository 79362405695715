import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Chip } from '@ardoq/status-ui';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { isPresentationMode } from 'appConfig';

export enum Option {
  CENTER,
  LEFT,
  RIGHT,
}

const CENTER_WIDTH = 90;

const CloseChip = styled.div`
  border-radius: 12px;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.grey80};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey35};
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.grey35};
  }
`;

const OuterContainer = styled.div<{ $disabled?: boolean }>`
  position: absolute;
  right: 20px;
  top: 50px;
  background-color: white;
  border-radius: 18px;
  display: flex;
  align-items: center;
  * {
    transition: none !important;
  }

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Container = styled.div`
  display: flex;
`;

// Temporary fix.
// Current Chip from @ardoq/legacy has box-sizing: content-box.
const FixedChip = styled(Chip)`
  height: 34px;
`;

const LeftOption = styled(FixedChip)`
  border-radius: 16px;
  margin: 0;
  padding-right: ${CENTER_WIDTH + 4}px;
  &:hover {
    z-index: 1;
  }
`;

const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightOption = styled(FixedChip)`
  border-radius: 16px;
  margin: 0;
  padding-left: ${CENTER_WIDTH + 4}px;
  max-width: 250px;
  &:hover {
    z-index: 1;
  }
`;

const CenterOption = styled(FixedChip)`
  position: relative;
  background: ${colors.grey90};
  border: 1px solid ${colors.grey80};
  width: ${CENTER_WIDTH}px;
  margin: 0 -${CENTER_WIDTH}px;
  z-index: 3;

  ${props =>
    props.isActive &&
    css`
      background: ${colors.grey35};
      border: 1px solid ${colors.grey25};
      color: white;
    `}
`;

const BorderPainter = styled.div<{
  $hasBorderLeft?: boolean;
  $hasBorderRight?: boolean;
}>`
  position: absolute;
  left: -1px;
  top: -1px;
  width: ${CENTER_WIDTH}px;
  height: 34px;
  border: 1px solid transparent;

  ${props =>
    props.$hasBorderLeft &&
    css`
      border-radius: 16px 0 0 16px;
      border-color: ${colors.grey35};
      border-right-color: transparent;
    `}

  ${props =>
    props.$hasBorderRight &&
    css`
      border-radius: 0 16px 16px 0;
      border-color: ${colors.grey35};
      border-left-color: transparent;
    `}
`;

const VisualDiffSwitcher = ({
  scenarioName,
  activeOption,
  optionCallbacks,
  disabled,
  className,
  onCloseDiffMode,
}: {
  scenarioName?: string;
  disabled?: boolean;
  activeOption: Option | null;
  optionCallbacks: Record<Option, () => void>;
  className?: string;
  onCloseDiffMode?: () => void;
}) => {
  const [isOver, setIsOver] = useState<null | Option>(null);
  const isRightActive =
    activeOption === Option.RIGHT || activeOption === Option.CENTER;
  const isCenterActive = activeOption === Option.CENTER;
  const isLeftActive =
    activeOption === Option.LEFT || activeOption === Option.CENTER;

  return (
    <OuterContainer className={className} $disabled={disabled}>
      <Container>
        <LeftOption
          onMouseEnter={() => setIsOver(Option.LEFT)}
          onMouseLeave={() => setIsOver(null)}
          isActive={isLeftActive}
          onClick={optionCallbacks[Option.LEFT]}
          icon={
            <Icon
              iconName={IconName.MASTER}
              style={
                isLeftActive
                  ? { color: colors.grey60 }
                  : { color: colors.grey80 }
              }
            />
          }
        >
          Mainline
        </LeftOption>

        <CenterOption
          isActive={isCenterActive}
          onClick={optionCallbacks[Option.CENTER]}
          icon={
            <Icon
              iconName={IconName.DIFF}
              style={
                isCenterActive ? { color: 'white' } : { color: colors.grey80 }
              }
            />
          }
        >
          <BorderPainter
            $hasBorderRight={
              !isCenterActive && isLeftActive && isOver === Option.LEFT
            }
            $hasBorderLeft={
              !isCenterActive && isRightActive && isOver === Option.RIGHT
            }
          />
          Diff
        </CenterOption>
        <RightOption
          onMouseEnter={() => setIsOver(Option.RIGHT)}
          onMouseLeave={() => setIsOver(null)}
          isActive={isRightActive}
          onClick={optionCallbacks[Option.RIGHT]}
          icon={
            <Icon
              iconName={IconName.SCENARIO}
              style={{
                flexShrink: 0,
                ...(isRightActive
                  ? { color: colors.green50 }
                  : { color: colors.grey80 }),
              }}
            />
          }
        >
          <Ellipsis>{scenarioName || 'Current scenario'}</Ellipsis>
        </RightOption>
      </Container>
      {!isPresentationMode() && (
        <CloseChip
          onClick={onCloseDiffMode}
          data-tooltip-text="Close visual diff"
        >
          <Icon iconName={IconName.CLOSE} iconSize={IconSize.SMALL} />
        </CloseChip>
      )}
    </OuterContainer>
  );
};

export default VisualDiffSwitcher;
