import Reference from 'models/reference';
import type { Reference as ReferenceBackboneModel } from 'aqTypes';
import {
  APIModelAttributes,
  APIReferenceAttributes,
  PathCollapsingRule,
} from '@ardoq/api-types';
import Backbone from 'backbone';
import type { Model as ModelBackboneModel } from 'aqTypes';
import { Model } from 'models/model';
import SparkMD5 from 'spark-md5';
import { flattenDict } from 'loadedState/flattenDict';
import { dispatchAction } from '@ardoq/rxbeach';
import { notifyModelChanged } from 'streams/models/actions';
import { filterObject } from '@ardoq/common-helpers';

type VirtualReferenceType = ReferenceBackboneModel & {
  new (attrs: Partial<APIReferenceAttributes>): ReferenceBackboneModel;
};

type CreateVirtualReferenceAttributes = {
  referenceId: string;
  sourceId: string;
  targetId: string;
  referenceStyle: PathCollapsingRule['referenceStyle'];
  name: string;
};

// A reference type living only on the FE, should never be persisted to the BE.
export const VirtualReference: VirtualReferenceType = Reference.model.extend({
  initialize: function (
    this: ReferenceBackboneModel,
    attributes: CreateVirtualReferenceAttributes
  ) {
    const typeId = virtualModel.getAndRegisterTypeFromStyleAndName(
      attributes.referenceStyle,
      attributes.name
    );
    this.id = attributes.referenceId;
    this.set('_id', attributes.referenceId);
    this.set('type', typeId);
    this.set('displayText', attributes.name);
    this.set('source', attributes.sourceId);
    this.set('target', attributes.targetId);
    this.set('model', virtualModel.id);
    this.set('rootWorkspace', this.getSource().get('rootWorkspace'));
    this.set('description', '');
  },
  changedAndMustBeSaved: () => false,
  fetch: () => ({}) as any,
  getFields: () => [],
  getMissingComp: () => null,
  getModel: () => virtualModel,
  getModelId: () => virtualModel.id,
  hasReturnValue: () => false,
  hasWriteAccess: () => false,
  save: () => Promise.resolve(),
  sync: () => ({}) as any,
  validate: () => false,
});

type VirtualModelClass = ModelBackboneModel & {
  extend: typeof Backbone.Model.extend;
  getAndRegisterTypeFromStyleAndName: (
    style: PathCollapsingRule['referenceStyle'],
    name: string
  ) => number;
  getReferenceTypesForWorkspace: (
    workspaceId: string
  ) => APIModelAttributes['referenceTypes'];
  registerVirtualTypeForWorkspaces: (
    style: PathCollapsingRule['referenceStyle'],
    name: string,
    workspaceIds: string[]
  ) => void;
  new (attributes?: Record<string, any>): VirtualModelClass;
};

const virtualTypesWorkspaceMap = new Map();

const VirtualModel: VirtualModelClass = Model.extend({
  changedAndMustBeSaved: () => false,
  fetch: () => ({}) as any,
  getAndRegisterTypeFromStyleAndName: function (
    style: PathCollapsingRule['referenceStyle'],
    name: string
  ) {
    const hash = styleToHash({ style, name });
    if (this.registeredTypes.has(hash)) {
      return this.registeredTypes.get(hash)!;
    }
    const newTypeId = this.getNewTypeId();
    this.registeredTypes.set(hash, newTypeId);
    this.registerType(newTypeId, name, style);
    return newTypeId;
  },
  registerVirtualTypeForWorkspaces: function (
    style: PathCollapsingRule['referenceStyle'],
    name: string,
    workspaceIds: string[]
  ) {
    const typeId = this.getAndRegisterTypeFromStyleAndName(style, name);
    if (!virtualTypesWorkspaceMap.has(typeId)) {
      virtualTypesWorkspaceMap.set(typeId, new Set());
    }
    const workspaceSet = virtualTypesWorkspaceMap.get(typeId)!;
    workspaceIds.forEach(id => workspaceSet.add(id));
  },
  getNewTypeId: function () {
    return this.typeId--;
  },
  initialize: function (this: VirtualModelClass) {},
  registeredTypes: new Map<string, number>(),
  registerType: function (
    typeId: number,
    name: string,
    style: PathCollapsingRule['referenceStyle']
  ) {
    this.attributes.referenceTypes[typeId] = { ...style, name, id: typeId };
    dispatchAction(notifyModelChanged(this));
  },
  save: () => Promise.resolve(),
  sync: () => ({}) as any,
  typeId: -1000,
  // Overwrite the default reference types to ensure that we only get the
  // virtual types.
  getReferenceTypes: function (this: ModelBackboneModel) {
    return this.get('referenceTypes') ?? {};
  },
  getReferenceTypesForWorkspace: function (workspaceId: string) {
    const types = this.get('referenceTypes') ?? {};
    return filterObject(types, id =>
      virtualTypesWorkspaceMap.get(Number(id))?.has(workspaceId)
    );
  },
});

const styleToHash = (obj: {
  style: PathCollapsingRule['referenceStyle'];
  name: string;
}) => SparkMD5.hash(flattenDict(obj).join(''));

export const virtualModel = new VirtualModel({
  _id: 'virtual-model',
  referenceTypes: {},
  root: {},
});
