import { useRef } from 'react';
import { connectInstance } from '@ardoq/rxbeach';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import { VisualizationContainer } from 'tabview/ViewContainer';
import {
  HierarchicalTreemapNode,
  HierarchicalTreemapViewProperties,
  TreemapHierarchyNode,
  TreemapTilingMethod,
} from '../../types';
import getViewModel$ from '../../viewModel/viewModel$';
import HierarchicalTreemapSettingsBar from './HierarchicalTreemapSettingsBar';
import * as d3 from 'd3';
import HierarchicalTreemapViewNode from './HierarchicalTreemapViewNode';
import styled from 'styled-components';
import { HIERARCHICAL_TREEMAP_FONT_SIZE, TILING_METHODS } from './consts';
import { ViewIds } from '@ardoq/api-types';
import { useResizeObserver } from '@ardoq/hooks';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

type Size = [width: number, height: number];

const paddingExceptRoot = (value: number) => (node: TreemapHierarchyNode) =>
  node.parent ? value : 0;

const layout = (size: Size, tilingMethod: TreemapTilingMethod) =>
  d3
    .treemap<HierarchicalTreemapNode>()
    .tile(TILING_METHODS[tilingMethod].method)
    .size(size)
    .paddingOuter(paddingExceptRoot(3))
    .paddingTop(paddingExceptRoot(HIERARCHICAL_TREEMAP_FONT_SIZE + 4));

const TreemapContainer = styled(VisualizationContainer)`
  position: relative;
  overflow: hidden;
`;

const HierarchicalTreemapView = (props: HierarchicalTreemapViewProperties) => {
  const {
    viewModel: { hierarchy },
    viewSettings: { tilingMethod, maxDepth },
  } = props;

  const viewContainer = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver(viewContainer);
  const treemapRootNode = layout([width, height], tilingMethod)(hierarchy);
  return (
    <SettingsBarAndViewContainer>
      <div className="menuContainer">
        <HierarchicalTreemapSettingsBar
          {...props}
          viewContainer={viewContainer}
        />
      </div>
      <TreemapContainer
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
        ref={viewContainer}
      >
        {treemapRootNode
          .descendants()
          .filter(
            node =>
              node !== treemapRootNode &&
              (maxDepth <= 0 || node.depth <= maxDepth)
          )
          .map(node => (
            <HierarchicalTreemapViewNode key={node.data.id} node={node} />
          ))}
      </TreemapContainer>
    </SettingsBarAndViewContainer>
  );
};

export default connectInstance(
  HierarchicalTreemapView,
  getViewModel$(ViewIds.HIERARCHICAL_TREEMAP)
);
