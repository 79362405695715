import {
  InfoContainerWidth,
  MetamodelNavigationState,
  MetamodelPane,
} from './types';
import { type ExtractPayload, reducer } from '@ardoq/rxbeach';
import {
  selectMetamodel,
  selectMetamodelItem,
  selectMetamodelPane,
  setMetamodelContext,
  setMetamodelContextSettings,
  setMetamodelInfoContainerIsOpen,
} from './actions';
import { updateMetamodel } from 'streams/metamodels/metamodelActions';
import {
  getContainerWidthFromMetamodelContext,
  getContextFromMetamodelItem,
} from './utils';
import type {
  MetamodelContext,
  MetamodelContextSettingMap,
  MetamodelEntityContext,
  SelectedMetamodelItem,
} from 'metamodel/types';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { trackOpenedMetamodelEditor } from '../tracking';
import { PersistedMetamodel } from '@ardoq/api-types';

const EMPTY_METAMODEL_CONTEXT: MetamodelContext = {
  id: '',
  name: '',
  type: SimpleBlockDiagramContentType.INVALID,
  settings: { isolatedNavigation: false },
};
export const defaultState: MetamodelNavigationState = {
  pane: MetamodelPane.LIST,
  metamodel: undefined,
  metamodelId: undefined,
  context: EMPTY_METAMODEL_CONTEXT,
  infoContainerIsOpen: false,
  infoContainerWidth: InfoContainerWidth.NARROW,
};

const handleSelectMetamodelPane = (
  currentState: MetamodelNavigationState,
  { pane, trackingLocation }: ExtractPayload<typeof selectMetamodelPane>
) => {
  if (pane === MetamodelPane.EDIT) {
    trackOpenedMetamodelEditor({
      trackingLocation,
      resourceId: currentState.metamodelId,
    });
  }

  return { ...currentState, pane };
};

const handleUpdateMetamodel = (
  currentState: MetamodelNavigationState,
  metamodel: PersistedMetamodel
): MetamodelNavigationState => ({
  ...currentState,
  metamodel,
});

const handleSelectMetamodel = (
  { pane, context }: MetamodelNavigationState,
  metamodelId?: string
): MetamodelNavigationState => ({
  ...defaultState,
  pane,
  // Do not reset settings
  context: { ...EMPTY_METAMODEL_CONTEXT, settings: context.settings },
  metamodelId,
});

const handleSelectMetamodelItem = (
  currentState: MetamodelNavigationState,
  selectedItem: SelectedMetamodelItem
) => {
  const { metamodel } = currentState;
  if (!metamodel) return currentState;
  const context =
    getContextFromMetamodelItem(metamodel, selectedItem) ??
    EMPTY_METAMODEL_CONTEXT;
  const newContextWithSettings = {
    ...context,
    settings: currentState.context.settings,
  };
  return {
    ...currentState,
    context: newContextWithSettings,
    infoContainerIsOpen: true,
    infoContainerWidth: InfoContainerWidth.NARROW,
  };
};

const handleSetMetamodelContext = (
  currentState: MetamodelNavigationState,
  context: MetamodelEntityContext
) => {
  const newContextWithSettings = {
    ...context,
    settings: currentState.context.settings,
  };
  return {
    ...currentState,
    context: newContextWithSettings,
    infoContainerIsOpen: true,
    infoContainerWidth: getContainerWidthFromMetamodelContext(
      newContextWithSettings
    ),
  };
};

const handleSetMetamodelContextSettings = (
  currentState: MetamodelNavigationState,
  settings: MetamodelContextSettingMap
) => ({
  ...currentState,
  context: {
    ...currentState.context,
    settings,
  },
});

const handleSetMetamodelInfoContainerIsOpen = (
  currentState: MetamodelNavigationState,
  infoContainerIsOpen: boolean
) => ({
  ...currentState,
  infoContainerIsOpen,
});

export const reducers = [
  reducer(selectMetamodelPane, handleSelectMetamodelPane),
  reducer(updateMetamodel, handleUpdateMetamodel),
  reducer(selectMetamodel, handleSelectMetamodel),
  reducer(selectMetamodelItem, handleSelectMetamodelItem),
  reducer(setMetamodelContext, handleSetMetamodelContext),
  reducer(setMetamodelContextSettings, handleSetMetamodelContextSettings),
  reducer(
    setMetamodelInfoContainerIsOpen,
    handleSetMetamodelInfoContainerIsOpen
  ),
];
