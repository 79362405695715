import { ZoomTransform, zoomIdentity } from 'd3';
import { transparentize } from 'polished';
import {
  RelationshipsLink,
  RelationshipsLinkVisual,
  RelationshipsNode,
  WindowRect,
} from '../types';
import { type Vector, spline } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import HitContext from '../HitContext';
import * as d3 from 'd3';
import { absolutePosition } from './node';
import { createFifoCache } from '@ardoq/common-helpers';
import { referenceInterface } from '@ardoq/reference-interface';
import { colors } from '@ardoq/design-tokens';
import drawMarker from '../../../canvasRendering/drawMarker';
import { isAncestor } from '../util';

export const getReferenceColors = createFifoCache(
  Infinity,
  ({ modelIds: [modelId] }: RelationshipsLinkVisual) =>
    referenceInterface.getCssColors(modelId, { useAsBackgroundStyle: false })
);

const arePointsEqual = (a: Point, b: Point) => a[0] === b[0] && a[1] === b[1];

const circleVisible = (center: number[], radius: number, window: number[]) => {
  const cx = 0.5 * (window[2] + window[0]);
  const cy = 0.5 * (window[3] + window[1]);
  const width = window[2] - window[0];
  const height = window[3] - window[1];

  const dx = Math.abs(center[0] - cx) - width / 2;
  const dy = Math.abs(center[1] - cy) - height / 2;

  if (dx > radius || dy > radius) {
    return false;
  }
  if (dx <= 0 || dy <= 0) {
    return true;
  }

  return dx * dx + dy * dy <= radius * radius;
};

const linksCurve = d3.line();

const fixupOutside = (src: Point, r: number, tgt: Point): Point => {
  const R = [tgt[0] - src[0], tgt[1] - src[1]];
  const Rm = Math.hypot(R[0], R[1]);

  if (Rm > 0) {
    R[0] = R[0] / Rm;
    R[1] = R[1] / Rm;
  }

  return [src[0] + r * R[0], src[1] + r * R[1]];
};

const lowestCommonAncestor = (link: RelationshipsLink) => {
  const set = new Set<RelationshipsNode>();

  for (let t: RelationshipsNode | null = link.source; t; t = t.parent) {
    set.add(t);
  }

  for (let t: RelationshipsNode | null = link.target; t; t = t.parent) {
    if (set.has(t)) {
      return t;
    }
  }

  return null;
};

const contains = (n: RelationshipsNode, x: number, y: number) => {
  const dist = Math.hypot(n.x - x, n.y - y);

  return dist < n.r;
};

/**
 * determines the best link connection points, adjusting for the special circumstances of a link which connects a descendant to an ancestor.
 * @returns the link connection points on the circumference of the specified nodes
 */
const linkPorts = (
  sourceProxy: RelationshipsNode,
  targetProxy: RelationshipsNode,
  [sourceCenterX, sourceCenterY]: Point,
  [targetCenterX, targetCenterY]: Point,
  [linkVectorX, linkVectorY]: Vector
): [sourcePort: Point, targetPort: Point] =>
  isAncestor(targetProxy, sourceProxy)
    ? [
        [
          sourceCenterX + sourceProxy.r * linkVectorX,
          sourceCenterY + sourceProxy.r * linkVectorY,
        ],
        [
          targetCenterX + targetProxy.r * linkVectorX,
          targetCenterY + targetProxy.r * linkVectorY,
        ],
      ]
    : isAncestor(sourceProxy, targetProxy)
      ? [
          [
            sourceCenterX - sourceProxy.r * linkVectorX,
            sourceCenterY - sourceProxy.r * linkVectorY,
          ],
          [
            targetCenterX - targetProxy.r * linkVectorX,
            targetCenterY - targetProxy.r * linkVectorY,
          ],
        ]
      : [
          [
            sourceCenterX + sourceProxy.r * linkVectorX,
            sourceCenterY + sourceProxy.r * linkVectorY,
          ],
          [
            targetCenterX - targetProxy.r * linkVectorX,
            targetCenterY - targetProxy.r * linkVectorY,
          ],
        ];

const lineSegment = (
  sourceCenter: Point,
  targetCenter: Point,
  sourceProxy: RelationshipsNode,
  targetProxy: RelationshipsNode
) => {
  const concentric =
    targetCenter[0] === sourceCenter[0] && targetCenter[1] === sourceCenter[1];
  const linkVector: Vector = concentric
    ? [-1, 0]
    : [targetCenter[0] - sourceCenter[0], targetCenter[1] - sourceCenter[1]];
  const Rm = Math.hypot(linkVector[0], linkVector[1]);

  if (Rm > 0) {
    linkVector[0] /= Rm;
    linkVector[1] /= Rm;
  }

  return linkPorts(
    sourceProxy,
    targetProxy,
    sourceCenter,
    targetCenter,
    linkVector
  );
};

export const getLinkPoints = (
  link: RelationshipsLinkVisual,
  window: WindowRect | null,
  bundle: number,
  hasAdaptive: boolean
): Point[] | null => {
  const { sourceProxy, targetProxy } = link;

  if (sourceProxy === targetProxy) {
    return null;
  }

  const sourceCenter = absolutePosition(sourceProxy);
  const targetCenter = absolutePosition(targetProxy);

  if (
    window &&
    !circleVisible(sourceCenter, sourceProxy.r, window) &&
    !circleVisible(targetCenter, targetProxy.r, window)
  ) {
    return null;
  }

  if (bundle && (!hasAdaptive || link.source.parent !== link.target.parent)) {
    const lca = lowestCommonAncestor(link);
    const nodes: RelationshipsNode[] = [];

    const sourceIsLowestCommonAncestor = sourceProxy === lca;
    const targetIsLowestCommonAncestor = targetProxy === lca;

    if (!sourceIsLowestCommonAncestor) {
      let prev = null;

      for (let curr = sourceProxy; curr !== lca; curr = curr.parent!) {
        if (prev && contains(prev, curr.x, curr.y)) {
          /* prev is over the center of curr, so curr isn't involved in bundle routing */
          continue;
        }

        nodes.push(curr);
        prev = curr;
      }
    }

    if (!sourceIsLowestCommonAncestor && !targetIsLowestCommonAncestor && lca) {
      nodes.push(lca);
    }

    if (!targetIsLowestCommonAncestor) {
      const downnodes: RelationshipsNode[] = [];
      let prev = null;
      let curr = targetProxy;

      for (; curr !== lca; curr = curr.parent!) {
        if (prev && contains(prev, curr.x, curr.y)) {
          continue;
        }
        downnodes.push(curr);
        prev = curr;
      }

      downnodes.reverse();
      nodes.push(...downnodes);
    }

    if (nodes.length > 2) {
      const points = nodes.map(absolutePosition);

      // #region if the first and second points are the same, remove the second point
      if (points.length > 2) {
        const firstPoint = points[0];
        const secondPoint = points[1];
        if (arePointsEqual(firstPoint, secondPoint)) {
          points.splice(1, 1);
        }
      }
      // #endregion
      // #region if the last and penultimate points are the same, remove the penultimate point
      if (points.length > 2) {
        const lastPoint = points.at(-1)!;
        const penultimatePoint = points.at(-2)!;
        if (arePointsEqual(lastPoint, penultimatePoint)) {
          points.splice(-2, 1);
        }
      }
      // #endregion

      // #region fixup the first and last points to touch the node circumference instead of its center
      if (!sourceIsLowestCommonAncestor) {
        points[0] = fixupOutside(points[0], nodes[0].r, points[1]);
      } /* else { fixup inside } */

      if (!targetIsLowestCommonAncestor) {
        points[points.length - 1] = fixupOutside(
          points[points.length - 1],
          nodes[nodes.length - 1].r,
          points[points.length - 2]
        );
      } /* else { fixup inside } */

      // #endregion
      return points;
    }
    // if bundling did not produce a path with more than two points, we'll fall through to the non-bundling behavior below.
  }

  return lineSegment(sourceCenter, targetCenter, sourceProxy, targetProxy);
};

const bezierTangent = (a: number, b: number, c: number, d: number, t: number) =>
  (1 - t) ** 2 * (b - a) + 2 * t * (1 - t) * (c - b) + t ** 2 * (d - c);
export const getBundlingFactory = (beta: number) => d3.curveBundle.beta(beta);

const testContext = new HitContext();

const markerPosition = (
  link: RelationshipsLink,
  linkPoints: Point[],
  bundling: number
) => {
  if (linkPoints.length === 2) {
    if (link.similarLinksCount > 1) {
      const [startX, startY, cpX, cpY, endX, endY] = spline(
        absolutePosition(link.source),
        link.source.r,
        absolutePosition(link.target),
        link.target.r,
        link.similarLinksIndex,
        link.similarLinksCount
      );

      const startPoint: Point = [startX, startY];
      const endPoint: Point = [endX, endY];
      const startTangentX = bezierTangent(startX, cpX, cpX, endX, 0);
      const startTangentY = bezierTangent(startY, cpY, cpY, endY, 0);
      const endTangentX = bezierTangent(startX, cpX, cpX, endX, 1);
      const endTangentY = bezierTangent(startY, cpY, cpY, endY, 1);

      return {
        startPoint,
        endPoint,
        startAngle: Math.atan2(startTangentY, startTangentX) - Math.PI,
        endAngle: Math.atan2(endTangentY, endTangentX),
      };
    }
    const startPoint = linkPoints[0];
    const [startX, startY] = startPoint;
    const endPoint = linkPoints.at(-1)!;
    const [endX, endY] = endPoint;
    return {
      startPoint,
      endPoint,
      startAngle: Math.atan2(startY - endY, startX - endX),
      endAngle: Math.atan2(endY - startY, endX - startX),
    };
  }
  addLinkToContext(link, linkPoints, bundling, testContext, zoomIdentity);
  const startPoint = linkPoints[0];
  const endPoint = linkPoints.at(-1)!;
  const [
    [firstSplineStartX, firstSplineStartY],
    [firstSplineCp1X, firstSplineCp1Y],
    [firstSplineCp2X, firstSplineCp2Y],
    [firstSplineEndX, firstSplineEndY],
  ] = testContext.lastPathSplines[0];
  const [
    [lastSplineStartX, lastSplineStartY],
    [lastSplineCp1X, lastSplineCp1Y],
    [lastSplineCp2X, lastSplineCp2Y],
    [lastSplineEndX, lastSplineEndY],
  ] = testContext.lastPathSplines.at(-1)!;

  const startTangentX = bezierTangent(
    firstSplineStartX,
    firstSplineCp1X,
    firstSplineCp2X,
    firstSplineEndX,
    0
  );
  const startTangentY = bezierTangent(
    firstSplineStartY,
    firstSplineCp1Y,
    firstSplineCp2Y,
    firstSplineEndY,
    0
  );
  const endTangentX = bezierTangent(
    lastSplineStartX,
    lastSplineCp1X,
    lastSplineCp2X,
    lastSplineEndX,
    1
  );
  const endTangentY = bezierTangent(
    lastSplineStartY,
    lastSplineCp1Y,
    lastSplineCp2Y,
    lastSplineEndY,
    1
  );
  return {
    startPoint,
    endPoint,
    startAngle: Math.atan2(startTangentY, startTangentX) - Math.PI,
    endAngle: Math.atan2(endTangentY, endTangentX),
  };
};

const transparentizeLinkColor = transparentize(0.7);

export const addLinkToContext = (
  link: RelationshipsLink,
  linkPoints: Point[],
  bundle: number,
  context: CanvasRenderingContext2D,
  viewTransform: ZoomTransform
) => {
  const transformedLinkPoints = linkPoints.map(point =>
    viewTransform.apply(point)
  );
  if (transformedLinkPoints.length === 2) {
    if (link.similarLinksCount > 1) {
      const { source, target, similarLinksIndex, similarLinksCount } = link;

      const [startX, startY, cpX, cpY, endX, endY] = spline(
        viewTransform.apply(absolutePosition(source)),
        source.r * viewTransform.k,
        viewTransform.apply(absolutePosition(target)),
        target.r * viewTransform.k,
        similarLinksIndex,
        similarLinksCount
      );
      context.moveTo(startX, startY);
      context.bezierCurveTo(cpX, cpY, cpX, cpY, endX, endY);
      return;
    }
    const [[startX, startY], [endX, endY]] = transformedLinkPoints;
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    return;
  }
  linksCurve.curve(getBundlingFactory(bundle));
  linksCurve.context(context);
  linksCurve(transformedLinkPoints);
};
export const linkWidth = ({ modelIds }: RelationshipsLinkVisual) =>
  Math.min(8, modelIds.length);

export const drawLink = (
  link: RelationshipsLinkVisual,
  window: WindowRect | null,
  bundle: number,
  viewTransform: ZoomTransform,
  context: CanvasRenderingContext2D,
  marked: boolean,
  hasAdaptive: boolean
) => {
  const linkPoints = getLinkPoints(link, window, bundle, hasAdaptive);
  if (!linkPoints) {
    return;
  }
  context.beginPath();
  const stroke = getReferenceColors(link)?.stroke ?? colors.black;
  context.strokeStyle = stroke;
  context.setLineDash(link.dashArray);
  const lineWidth = linkWidth(link);
  context.lineWidth = lineWidth;

  addLinkToContext(link, linkPoints, bundle, context, viewTransform);
  context.stroke();
  context.setLineDash([]);
  if (marked) {
    context.lineWidth = 8;
    context.strokeStyle = transparentizeLinkColor(stroke);
    context.stroke();
  }

  context.lineWidth = lineWidth;

  const { startPoint, startAngle, endPoint, endAngle } = markerPosition(
    link,
    linkPoints,
    bundle
  );
  drawMarker(
    context,
    link.lineBeginning,
    viewTransform.apply(startPoint),
    startAngle,
    viewTransform.k
  );
  drawMarker(
    context,
    link.lineEnding,
    viewTransform.apply(endPoint),
    endAngle,
    viewTransform.k
  );
};
