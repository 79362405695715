import { range } from 'lodash';
import { NumericRange, NumericScalingFunction } from '@ardoq/graph';
import {
  getFractionalLeadingZeroes,
  getNumThousandGroups,
  getNumWholeDigits,
} from 'utils/numberUtils';
import { format } from 'utils/numberUtils';
import {
  BUBBLE_LEGEND_STROKE_WIDTH,
  DATA_LABEL_LEFT_PADDING,
  LEGEND_TEXT_PX_SIZE,
  MAX_BUBBLE_RADIUS,
  MAX_INTERMEDIATE_STEPS,
  MIN_PIXELS_BETWEEN_BUBBLE_RADII,
  PADDING_BOTTOM_BUBBLES,
  PADDING_RIGHT_BUBBLES,
  PADDING_TOP_BUBBLES,
} from './consts';
import {
  BubbleRadiusLegendProps,
  LegendBubbleData,
  LegendBubbleDataRange,
} from './types';
import { MeasureStyledSvgText } from '@ardoq/dom-utils';

const getBubbleData = (
  dataValue: number,
  radiusScaler: NumericScalingFunction
) => ({
  dataValue,
  radius: radiusScaler(dataValue),
});

const constrainLegendBubbleRange = (
  sortedDataVals: number[],
  radiusScaler: NumericScalingFunction,
  radiusUnscaler: NumericScalingFunction,
  maxBubbleRadius: number
): LegendBubbleDataRange => {
  const minBubbleDataVal = sortedDataVals[0];
  const minBubbleData = getBubbleData(minBubbleDataVal, radiusScaler);

  const minNonZeroDataVal = sortedDataVals.find(num => num !== 0);
  const minNonZeroBubbleData = minNonZeroDataVal
    ? getBubbleData(minNonZeroDataVal, radiusScaler)
    : null;

  const maxBubbleDataVal = sortedDataVals[sortedDataVals.length - 1];
  const maxBubbleData = getBubbleData(maxBubbleDataVal, radiusScaler);

  const constrainedMaxBubbleData =
    maxBubbleData.radius > maxBubbleRadius
      ? {
          dataValue: radiusUnscaler(maxBubbleRadius),
          radius: maxBubbleRadius,
        }
      : maxBubbleData;

  const isMinBubbleNonZeroAndLessThanMax =
    minBubbleData.radius > 0 &&
    minBubbleData.radius < constrainedMaxBubbleData.radius;
  if (isMinBubbleNonZeroAndLessThanMax)
    return [minBubbleData, constrainedMaxBubbleData];

  if (
    minNonZeroBubbleData &&
    minNonZeroBubbleData.radius < constrainedMaxBubbleData.radius
  )
    return [minNonZeroBubbleData, constrainedMaxBubbleData];

  return [null, constrainedMaxBubbleData];
};

/** Given min and max radii, equally space radii between them */
const getIntermediateRadiiSteps = (radiusRange: NumericRange) => {
  const [min, max] = radiusRange;
  const spaceForIntermediateSteps =
    max - min - 2 * MIN_PIXELS_BETWEEN_BUBBLE_RADII;
  if (spaceForIntermediateSteps < 0) return radiusRange;
  const numIntermediateSteps = Math.min(
    MAX_INTERMEDIATE_STEPS,
    Math.floor(spaceForIntermediateSteps / MIN_PIXELS_BETWEEN_BUBBLE_RADII) + 1
  );
  const spaceBetweenSteps = Math.floor(
    (max - min - MIN_PIXELS_BETWEEN_BUBBLE_RADII) / numIntermediateSteps
  );
  const intermediateSteps = range(1, numIntermediateSteps + 1).map(
    stepN => min + spaceBetweenSteps * stepN
  );
  return intermediateSteps;
};

const nicelyRoundDown = (n: number) => {
  const numDigits = getNumWholeDigits(n);
  if (Math.abs(n) < 1) {
    // Round to the first non-zero fractional digit
    const numFractionalLeadingZeroes = getFractionalLeadingZeroes(n);
    const multiplier = Math.pow(10, numFractionalLeadingZeroes + 1);
    return Math.floor(n * multiplier) / multiplier;
  } else if (numDigits < 3) {
    return Math.floor(n);
  } else if (numDigits === 3) {
    return Math.floor(n / 10) * 10;
  }
  // Round to the highest-order thousands group
  const numThouGroups = getNumThousandGroups(n);
  const digitsToDrop = (numThouGroups - 1) * 3;
  const multiplier = Math.pow(10, digitsToDrop);
  return Math.floor(n / multiplier) * multiplier;
};

const getConstrainedAndRoundedBubbleData = ({
  chartData,
  radiusDomain: [radiusDomainMinimum, radiusDomainMaximum],
  radiusScaler,
  radiusUnscaler,
}: BubbleRadiusLegendProps): LegendBubbleData[] => {
  const dataVals = chartData.map(({ radius }) => radius);
  dataVals.sort((a, b) => a - b);

  const maxBubbleRadius = MAX_BUBBLE_RADIUS;

  const [minConstrainedBubbleData, maxConstrainedBubbleData] =
    constrainLegendBubbleRange(
      dataVals,
      radiusScaler,
      radiusUnscaler,
      maxBubbleRadius
    );

  const isZeroRange = radiusDomainMinimum === radiusDomainMaximum;

  const roundedMax = getBubbleData(
    isZeroRange
      ? maxConstrainedBubbleData.dataValue
      : nicelyRoundDown(maxConstrainedBubbleData.dataValue),
    radiusScaler
  );
  if (!minConstrainedBubbleData) return [roundedMax];
  const roundedMin = getBubbleData(
    isZeroRange
      ? minConstrainedBubbleData.dataValue
      : nicelyRoundDown(minConstrainedBubbleData.dataValue),
    radiusScaler
  );

  const areMinAndMaxAdequatelySpaced =
    roundedMax.radius - roundedMin.radius >= MIN_PIXELS_BETWEEN_BUBBLE_RADII;
  if (!areMinAndMaxAdequatelySpaced) return [roundedMax];

  const intermediateBubbleData = getIntermediateRadiiSteps([
    roundedMin.radius,
    roundedMax.radius,
  ]).map(radius =>
    getBubbleData(nicelyRoundDown(radiusUnscaler(radius)), radiusScaler)
  );
  return [roundedMin, ...intermediateBubbleData, roundedMax].reduce<
    LegendBubbleData[]
  >((resultBubbles, data, i, arr) => {
    if (i === 0 || i === arr.length - 1) return resultBubbles.concat(data);
    const previousData = resultBubbles[resultBubbles.length - 1];
    const nextData = arr[i + 1];
    const isAdequatelySpaced =
      data.radius - previousData.radius >= MIN_PIXELS_BETWEEN_BUBBLE_RADII &&
      nextData.radius - data.radius >= MIN_PIXELS_BETWEEN_BUBBLE_RADII;
    return isAdequatelySpaced ? resultBubbles.concat(data) : resultBubbles;
  }, []);
};

const fontSize = `${LEGEND_TEXT_PX_SIZE}px`;
const getLegendLabelWidth = (text: string) =>
  MeasureStyledSvgText.Instance.getTextWidth({ text, fontSize });

const getDiameterWithStroke = (radius: number) =>
  2 * (radius + BUBBLE_LEGEND_STROKE_WIDTH);

export const getLegendData = ({
  chartData,
  radiusDomain,
  radiusScaler,
  radiusUnscaler,
  includeZero,
}: BubbleRadiusLegendProps) => {
  const legendBubbleData = getConstrainedAndRoundedBubbleData({
    chartData,
    radiusDomain,
    radiusScaler,
    radiusUnscaler,
    includeZero,
  }).map(({ radius, dataValue }) => ({
    radius,
    label: format(dataValue),
  }));
  const labelWidths = legendBubbleData.map(({ label }) =>
    getLegendLabelWidth(label)
  );
  const maxLabelWidth = Math.max(...labelWidths);
  const maxRadius = legendBubbleData[legendBubbleData.length - 1].radius;
  const maxBubbleDiameterWithStroke = getDiameterWithStroke(maxRadius);
  const svgHeight =
    maxBubbleDiameterWithStroke + PADDING_TOP_BUBBLES + PADDING_BOTTOM_BUBBLES;
  const svgWidth =
    maxBubbleDiameterWithStroke +
    PADDING_RIGHT_BUBBLES +
    maxLabelWidth +
    DATA_LABEL_LEFT_PADDING;

  return {
    legendBubbleData,
    maxLabelWidth,
    svgHeight,
    svgWidth,
    maxRadius,
    maxBubbleDiameterWithStroke,
  };
};
