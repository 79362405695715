import {
  AdvancedSearchResult,
  AdvancedSearchResultType,
  UnknownDocumentType,
} from '@ardoq/api-types';
import { getIn } from '../utils/collectionUtil';

const COMPONENT_NAME_DEFAULT = 'Error: Name missing';

export interface MappedAdvancedSearchResult<T extends UnknownDocumentType>
  extends Omit<AdvancedSearchResult<T>, 'doc'> {
  id: string;
  rootWorkspace: string | undefined;
  name: string;
  created: string;
  'last-updated': string;
}
const makeReferenceName = <T extends UnknownDocumentType>(
  result: AdvancedSearchResult<T>
) =>
  `${getIn(
    result.doc,
    ['ardoq', 'sourceComponentName'],
    COMPONENT_NAME_DEFAULT
  )} → ${getIn(
    result.doc,
    ['ardoq', 'targetComponentName'],
    COMPONENT_NAME_DEFAULT
  )}`;

const getName = <T extends UnknownDocumentType>(
  result: AdvancedSearchResult<T>
) =>
  result.type === AdvancedSearchResultType.REFERENCE
    ? makeReferenceName(result)
    : result.doc.name;

export const resultMapper = <T extends UnknownDocumentType>(
  result: AdvancedSearchResult<T>
): MappedAdvancedSearchResult<T> => {
  const mappedResult = {
    ...result.doc,
    type: result.type,
    id: result.doc._id,
    rootWorkspace: result.doc.rootWorkspace,
    name: getName(result),
    created: result.doc.created,
    'last-updated': result.doc['last-updated'],
  };
  return mappedResult;
};
