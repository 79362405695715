import { DataLimitProps } from './types';

export const getWarningTitle = ({
  isEmpty = false,
  reducedDegrees = 0,
  isAboveLimit = false,
}: DataLimitProps) => {
  if (reducedDegrees || isAboveLimit) {
    return 'There are too many components and references to show. This visualization may run slowly.';
  }
  if (isEmpty) {
    return 'There are no components or references to show for the current context.';
  }
  return null;
};

export const getWarningSubtitle = ({
  reducedDegrees = 0,
  isAboveLimit = false,
}: DataLimitProps) => {
  if (reducedDegrees) {
    return (
      <>
        <p>
          We reduced the traversed distance to{' '}
          <strong>{reducedDegrees} degrees of separation</strong> for your
          convenience.
        </p>
        <p>
          You can narrow down your selection by choosing a component from the
          navigator on the left or by applying filters.
        </p>
      </>
    );
  }
  if (isAboveLimit) {
    return 'Narrow down your selection by choosing a component from the navigator on the left, or by applying filters.';
  }
  return null;
};
