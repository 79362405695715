import * as React from 'react';
import { throttle } from 'lodash';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ComponentRepresentation } from '@ardoq/renderers';
import { ComponentItemContainer, ComponentLabel, IconContainer } from './atoms';
import { classes } from '@ardoq/common-helpers';
import { BodyCellItemModel } from '../types';
import Context from 'context';
import Components from 'collections/components';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { TextOverflow } from '@ardoq/popovers';
import { componentMatrixCommands } from '../commands';

const MOUSE_MOVE_EVENT_THROTTLE = 50;
interface ComponentItemArgs extends BodyCellItemModel {
  isHighlighted: boolean;
}
const ComponentItem = ({
  componentId,
  iconColor,
  componentLabel,
  className,
  isHighlighted,
}: ComponentItemArgs) => {
  const handleSetHoveredComponentId = () => {
    if (!mouseOverEvent) {
      return;
    }
    componentMatrixCommands.setHoveredItemState(componentId);
  };
  const throttledSetHoveredComponentId = throttle(
    handleSetHoveredComponentId,
    MOUSE_MOVE_EVENT_THROTTLE
  );
  let mouseOverEvent: MouseEvent | null = null;
  const doSetHoveredComponentId = (e: React.MouseEvent) => {
    mouseOverEvent = e.nativeEvent;
    throttledSetHoveredComponentId();
  };
  const componentRepresentationData = componentInterface.getRepresentationData(
    componentId
  ) ?? {
    isImage: false,
    value: null,
  };

  const contextMenuAttribute = COMPONENT_ID_ATTRIBUTE;
  const isContext = Context.componentId() === componentId;
  return (
    <ComponentItemContainer
      {...{ [contextMenuAttribute]: componentId }}
      className={classes(
        className,
        'skipContextUpdate',
        isHighlighted && 'active',
        isContext && 'context'
      )}
      onMouseEnter={doSetHoveredComponentId}
      onMouseLeave={() => {
        mouseOverEvent = null;
        componentMatrixCommands.setHoveredItemState(null);
      }}
      onClick={e => {
        e.stopPropagation();
        componentMatrixCommands.setFocusedItemState(componentId);
      }}
      onDoubleClick={() =>
        Context.setComponent(Components.collection.get(componentId))
      }
    >
      <IconContainer $color={iconColor}>
        <ComponentRepresentation
          isImage={componentRepresentationData.isImage}
          value={componentRepresentationData.value}
          icon={componentRepresentationData.icon}
        />
      </IconContainer>
      <ComponentLabel>
        <TextOverflow>{componentLabel}</TextOverflow>
      </ComponentLabel>
    </ComponentItemContainer>
  );
};

export default ComponentItem;
