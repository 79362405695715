import { action$, reducer, reduceState, streamReducer } from '@ardoq/rxbeach';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import {
  addDismisssedEphemeralNotification,
  ephemeralNotificationRendered,
} from './actions';
import { EPHEMERAL_NOTIFICATION_CONFIGS } from './ephemeralNotificationConfig';
import { isEphemeralNotificationVisible } from './utils';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import { logWarn } from '@ardoq/logging';
import { addDays } from '@ardoq/date-time';
import {
  EphemeralConfig,
  EphemeralFeaturesCarouselConfig,
  EphemeralNotificationConfig,
} from './types';

type EphemeralNotificationState = {
  isVisible: boolean;
  notificationConfig:
    | EphemeralNotificationConfig
    | EphemeralFeaturesCarouselConfig
    | null;
  currentUserState: CurrentUserState | null;
  instanceId?: string;
};

const getIsVisible = (
  currentUserState: CurrentUserState,
  notificationConfig: EphemeralConfig | null
) =>
  notificationConfig
    ? isEphemeralNotificationVisible({
        notificationConfig,
        currentUserState,
      })
    : false;

const handleCurrentUserUpdate = (
  state: EphemeralNotificationState,
  currentUserState: CurrentUserState
): EphemeralNotificationState => ({
  ...state,
  isVisible: getIsVisible(currentUserState, state.notificationConfig),
  currentUserState,
});

const handleEphemeralNotificationRendered = (
  state: EphemeralNotificationState,
  bannerId: string
) => {
  const notificationConfig =
    EPHEMERAL_NOTIFICATION_CONFIGS.get(bannerId) ?? null;
  const { currentUserState } = state;

  if (
    notificationConfig &&
    new Date() > addDays(notificationConfig.relevantUntilDate, 7) // add a one week buffer for removing the banner
  ) {
    logWarn(
      new Error('Ephemeral banner is expired'),
      'Please kindly remove the outdated code',
      { notificationConfig }
    );
  }

  if (!currentUserState) {
    return {
      ...state,
      notificationConfig,
      isVisible: false,
    };
  }

  return {
    ...state,
    notificationConfig,
    isVisible: getIsVisible(currentUserState, notificationConfig),
  };
};

// too long to wat for response, we hide the notification immediately
const provideInstantVisualFeedback = (
  state: EphemeralNotificationState,
  bannerId: string
) =>
  state.notificationConfig && state.notificationConfig.id === bannerId
    ? {
        ...state,
        isVisible: false,
      }
    : state;

const initialState = {
  isVisible: false,
  currentUserState: null,
  notificationConfig: null,
  instanceId: undefined,
};
export const ephemeralNotification$ = (instanceId: string) => {
  const stateStream$ = action$.pipe(
    withNamespaceOrNullNamespace(instanceId),
    reduceState<EphemeralNotificationState>(
      'ephemeralNotification',
      {
        ...initialState,
        instanceId,
      },
      [
        streamReducer(currentUser$, handleCurrentUserUpdate),
        reducer(
          ephemeralNotificationRendered,
          handleEphemeralNotificationRendered
        ),
        reducer(
          addDismisssedEphemeralNotification,
          provideInstantVisualFeedback
        ),
      ]
    )
  );

  return stateStream$;
};
