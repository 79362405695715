import {
  attachmentApi,
  FetchAttachmentsAndFoldersResponse,
  folderApi,
  handleError,
} from '@ardoq/api';
import { ArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import {
  documentArchiveAttachments,
  documentArchiveFolders,
} from 'collections/documentArchive';
import { setIsLoading } from 'components/GlobalPaneLoader/globalPaneLoader$';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { notifyWorkspaceChanged } from 'streams/context/ContextActions';
import {
  createFolder,
  deleteAttachments,
  deleteEmptyFolder,
  deleteFolders,
  deleteSuccess,
  moveAttachmentsToFolder,
  moveFoldersToFolder,
  renameAttachment,
  renameFolder,
  renameSuccess,
  transferToGlobal,
  transferToGlobalSuccess,
  uploadFiles,
} from './actions';
import { deleteMultipleFolders } from './routineUtils';
import currentUser$ from 'streams/currentUser/currentUser$';
import { of } from 'rxjs';
import { OrgAccessLevel } from '@ardoq/api-types';
import { permissionsOperations } from '@ardoq/access-control';

const handleArdoqError = (error: ArdoqError) => {
  logError(error, 'Document Archive routine error');
  dispatchAction(setIsLoading(false));
  alert({
    title: 'Error',
    subtitle: 'Something went wrong with this request.',
  });
};

const handleCreateFolder = routine(
  ofType(createFolder),
  extractPayload(),
  switchMap(folderApi.create),
  handleError(handleArdoqError)
);

const handleUploadFiles = routine(
  ofType(uploadFiles),
  extractPayload(),
  switchMap(attachmentApi.uploadFiles),
  handleError(handleArdoqError)
);

const handleRenameFolder = routine(
  ofType(renameFolder),
  extractPayload(),
  switchMap(folderApi.rename),
  handleError(),
  tap(async response => {
    dispatchAction(renameSuccess(response._id));
  })
);

const handleRenameAttachment = routine(
  ofType(renameAttachment),
  extractPayload(),
  switchMap(attachmentApi.rename),
  handleError(),
  tap(async response => {
    dispatchAction(renameSuccess(response._id));
  })
);

const handleDeleteAttachments = routine(
  ofType(deleteAttachments),
  extractPayload(),
  switchMap(attachmentApi.deleteMultiple),
  handleError(handleArdoqError),
  tap(payload => {
    dispatchAction(deleteSuccess(payload));
  })
);

const handleDeleteFolders = routine(
  ofType(deleteFolders),
  extractPayload(),
  switchMap(deleteMultipleFolders),
  handleError(handleArdoqError),
  tap(payload => {
    dispatchAction(deleteSuccess(payload));
  })
);

/**
 * The types are lying, and I do not belive that we ever have an EmptyFolder, which means that this routine is never used.
 */
const handleDeleteEmptyFolder = routine(
  ofType(deleteEmptyFolder),
  extractPayload(),
  switchMap(folderApi.delete),
  handleError(handleArdoqError),
  tap(payload => {
    dispatchAction(deleteSuccess([payload]));
  })
);

const handleMoveAttachmentsToFolder = routine(
  ofType(moveAttachmentsToFolder),
  extractPayload(),
  switchMap(attachmentApi.moveMultipleToFolder),
  handleError(handleArdoqError)
);

const handleMoveFoldersToFolder = routine(
  ofType(moveFoldersToFolder),
  extractPayload(),
  switchMap(folderApi.moveMultipleToFolder),
  handleError(handleArdoqError)
);

const handleFetchOnWorkspaceChange = routine(
  ofType(notifyWorkspaceChanged),
  extractPayload(),
  map(({ workspaceId }) => workspaceId),
  filter(Boolean),
  filter(workspaceId => !metaModelOperations.isMetaModelWorkspace(workspaceId)),
  withLatestFrom(currentUser$),
  switchMap(([workspaceId, currentUser]) => {
    const emptyResponse = of({
      workspaceAttachments: [],
      orgAttachments: [],
      workspaceFolders: [],
      orgFolders: [],
    } as FetchAttachmentsAndFoldersResponse);
    // the document archive endpoint doesn't work for contributors or public users
    // because we need to be able to fetch attachments and folders in presentations, we exit early here
    // if the user is a contributor or public user to avoid showing the error modal/throwing excess errors
    const organization = currentUser?.organization;
    if (!organization) return emptyResponse;
    if (
      permissionsOperations.getUserOrgAccessLevel(currentUser) ===
      OrgAccessLevel.CONTRIBUTOR
    ) {
      return of({
        workspaceAttachments: [],
        orgAttachments: [],
        workspaceFolders: [],
        orgFolders: [],
      } as FetchAttachmentsAndFoldersResponse);
    }
    return attachmentApi.fetchAttachmentsAndFolders({
      workspaceId,
      orgId: organization._id,
    });
  }),
  handleError(handleArdoqError),
  tap(
    ({
      workspaceAttachments,
      orgAttachments,
      workspaceFolders,
      orgFolders,
    }) => {
      documentArchiveAttachments.set(
        workspaceAttachments.concat(orgAttachments)
      );
      documentArchiveFolders.set(workspaceFolders.concat(orgFolders));
    }
  )
);

const handleTransformToGlobal = routine(
  ofType(transferToGlobal),
  extractPayload(),
  switchMap(attachmentApi.transformToGlobal),
  handleError(handleArdoqError),
  tap(payload => {
    dispatchAction(transferToGlobalSuccess(payload));
  })
);

export const documentBrowserRoutines = collectRoutines(
  handleCreateFolder,
  handleUploadFiles,
  handleRenameFolder,
  handleRenameAttachment,
  handleDeleteAttachments,
  handleDeleteFolders,
  handleDeleteEmptyFolder,
  handleMoveAttachmentsToFolder,
  handleMoveFoldersToFolder,
  handleFetchOnWorkspaceChange,
  handleTransformToGlobal
);
