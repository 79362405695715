import { combineLatest, map } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

import { action$, ofType } from '@ardoq/rxbeach';

import presentationMode$ from './presentationMode$';
import activeFilter$ from 'streams/filters/activeFilter$';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsSynced,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { context$ } from 'streams/context/context$';
import { startAction } from 'actions/utils';

import { componentInterface } from 'modelInterface/components/componentInterface';

const componentUpdate$ = action$.pipe(
  ofType(
    notifyComponentsAdded,
    notifyComponentsUpdated,
    notifyComponentsRemoved,
    notifyComponentsSynced
  ),
  startWith(startAction())
);

const workspaceId$ = context$.pipe(
  map(context => context.workspaceId),
  distinctUntilChanged()
);

const emptyView$ = combineLatest([
  workspaceId$,
  presentationMode$,
  activeFilter$,
  componentUpdate$,
]).pipe(
  map(([workspaceId, presentationMode, activeFilter]) => ({
    isPresentationMode: presentationMode.isPresentationMode,
    hasActiveFilter: Boolean(
      activeFilter.activeFilters?.componentRules.rules?.length ||
        activeFilter.activeFilters?.referenceRules.rules?.length
    ),
    isWorkspaceEmpty: componentInterface.isWorkspaceEmpty(workspaceId),
  }))
);

export default emptyView$;
