import { useState } from 'react';
import {
  MetamodelContextSettings,
  MetamodelFieldContext,
} from 'metamodel/types';
import { FieldSubtitle } from '../atoms';
import BreadCrumbNavigation from '../breadCrumbNavigation/BreadCrumbNavigation';
import { getFieldLabel } from '../utils';
import {
  getComponentsWithOrWithoutValueQueryBuilderQuery,
  getReferencesWithOrWithoutValueQuery,
} from './utils';
import GremlinSearchTableWithLoader from '../GremlinSearchTableWithLoader';
import WorkspaceNavigationTitle from '../workspaceNavigationTitle/WorkspaceNavigationTitle';
import { APIFieldType, Metamodel } from '@ardoq/api-types';
import { DisplayPreference } from './types';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { dateRangeOperations } from '@ardoq/date-range';
import { Paragraph } from '@ardoq/typography';
import { GroupFieldLayout, RadioGroup } from '@ardoq/forms';
import { Box } from '@ardoq/layout';
import { fieldInterface } from '@ardoq/field-interface';
import AdvancedSearchTableWithLoader from '../AdvancedSearchTable/AdvancedSearchTableWithLoader';

const FieldStats = ({
  context,
  metamodel,
}: {
  context: MetamodelFieldContext & MetamodelContextSettings;
  metamodel: Metamodel;
}) => {
  // Show non empty results if all fields are filled out
  const { instanceCount, fieldUsage } = context.metadata;

  const defaultDisplayPreference =
    instanceCount === fieldUsage[context.field]
      ? DisplayPreference.WITH_VALUE
      : DisplayPreference.WITHOUT_VALUE;

  const [displayPreference, setDisplayPreference] = useState<DisplayPreference>(
    defaultDisplayPreference
  );

  const dateRangeFieldMap = metamodel.metamodel.dateRangeFieldMap?.get(
    context.workspaceId
  );
  const isDateRangeField = Boolean(dateRangeFieldMap?.[context.field]);
  const fieldModel = fieldInterface.getByName(context.field, {
    acrossWorkspaces: true,
  });
  const IsCheckboxField = fieldModel
    ? fieldModel.type === APIFieldType.CHECKBOX
    : false;

  const displayPreferenceOptions = [
    { value: DisplayPreference.WITH_VALUE, label: 'With value' },
    { value: DisplayPreference.WITHOUT_VALUE, label: 'Without value' },
  ];

  const shouldUseAdvancedSearchQuery =
    context.type === SimpleBlockDiagramContentType.COMPONENT;

  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
          {isDateRangeField
            ? dateRangeOperations.extractDateRangeFieldLabel(
                dateRangeFieldMap?.[context.field]?.start?.label ??
                  context.field
              )
            : getFieldLabel(context.field)}
        </WorkspaceNavigationTitle>
      </Box>
      <Paragraph>Field id: {context.field}</Paragraph>
      <FieldSubtitle context={context} />
      <Box marginTop="small" display="inline-block">
        <RadioGroup
          value={displayPreference}
          options={displayPreferenceOptions}
          onValueChange={value =>
            setDisplayPreference(value as DisplayPreference)
          }
          groupFieldLayout={GroupFieldLayout.HORIZONTAL}
        />
      </Box>
      {shouldUseAdvancedSearchQuery ? (
        <AdvancedSearchTableWithLoader
          query={getComponentsWithOrWithoutValueQueryBuilderQuery(
            context,
            displayPreference,
            IsCheckboxField
          )}
        />
      ) : (
        <GremlinSearchTableWithLoader
          query={getReferencesWithOrWithoutValueQuery(
            context,
            metamodel,
            displayPreference,
            IsCheckboxField,
            isDateRangeField
          )}
        />
      )}
    </Box>
  );
};

export default FieldStats;
