import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoSelectedContextComponentDoqProps = { doqType: DoqType };

const NoSelectedContextComponentDoq = ({
  doqType,
}: NoSelectedContextComponentDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="Choose a context component"
      message="In order to visualize this workspace, you need to choose a context component in the navigator."
    />
  </EmptyStatesDoqContainer>
);
export default NoSelectedContextComponentDoq;
