import { ComponentType, useEffect } from 'react';
import * as profiling from '@ardoq/profiling';
import { dispatchAction } from '@ardoq/rxbeach';
import { notifyViewRenderDone } from 'tabview/actions';
import { ViewIds } from '@ardoq/api-types';

const WithPerformanceTracking = <WrappedProps extends Record<string, any>>(
  name: string,
  threshold: number,
  args: profiling.TransactionOptions & {
    WrappedComponent: ComponentType<WrappedProps>;
    wrappedProps: WrappedProps;
    viewId: ViewIds;
  },
  viewIsReady: boolean = true
) => {
  profiling.useTransaction(name, threshold, args, profiling.Team.INSIGHT);
  useEffect(() => {
    if (viewIsReady) dispatchAction(notifyViewRenderDone(args.viewId));
  }, [viewIsReady, args.viewId]);
  const { WrappedComponent, wrappedProps } = args;
  return (
    <WrappedComponent
      {...(wrappedProps as JSX.IntrinsicAttributes & WrappedProps)}
    />
  );
};
export default WithPerformanceTracking;
