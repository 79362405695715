import { action$, extractPayload, ofType, withNamespace } from '@ardoq/rxbeach';
import { startWith } from 'rxjs';
import { focusedComponentChanged, hoveredComponentChanged } from './actions';

export const getHoveredStream = (viewId: string) =>
  action$.pipe(
    withNamespace(viewId),
    ofType(hoveredComponentChanged),
    extractPayload(),
    startWith(null)
  );

export const getFocusedStream = (viewId: string) =>
  action$.pipe(
    withNamespace(viewId),
    ofType(focusedComponentChanged),
    extractPayload(),
    startWith(null)
  );
