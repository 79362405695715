import { Model } from 'models/model';
import type { Model as ModelBackboneModel } from 'aqTypes';
import { APIModelAttributes } from '@ardoq/api-types';
import { metaModelOverwrites } from './metaModelOverwrites';

type MetaModelModelType = ModelBackboneModel & {
  new (attrs: Partial<APIModelAttributes>): ModelBackboneModel;
};

export const MetaModelModel: MetaModelModelType =
  Model.extend(metaModelOverwrites);
