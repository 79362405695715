import { GraphItem, GroupType } from '@ardoq/graph';
import type { ComponentBackboneModel, Reference } from 'aqTypes';
import { Node as OldGraphNode } from 'graph/node';
import { Edge as OldGraphEdge } from 'graph/edge';
import * as TypeCheck from 'utils/typeCheck';
import { GraphEdge } from '@ardoq/graph';
import type {
  GraphInputMode,
  IModelItem,
  ItemClickedEventArgs,
} from '@ardoq/yfiles';
import { GraphNode } from '@ardoq/graph';
import { GraphGroup } from '@ardoq/graph';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { dispatchAction } from '@ardoq/rxbeach';
import { selectComponent } from 'streams/components/ComponentActions';
import { selectReference } from 'streams/references/ReferenceActions';
import { openWorkspace } from 'streams/workspaces/actions';
import { componentGraphSelectionChanged } from 'componentSelection/componentSelectionActions';

const graphItemModelId = ({ modelId }: GraphItem) => modelId;
const backboneModelId = ({ cid }: ComponentBackboneModel | Reference) => cid;

const isOldGraphNodeAndComponent = (
  graphItem: unknown
): graphItem is OldGraphNode & {
  dataModel: ComponentBackboneModel;
} =>
  graphItem instanceof OldGraphNode &&
  TypeCheck.isComponent(graphItem.dataModel);

const isOldGraphEdgeAndReference = (
  graphItem: unknown
): graphItem is GraphEdge & { dataModel: Reference } =>
  graphItem instanceof OldGraphEdge &&
  TypeCheck.isReference(graphItem.dataModel);

const getModelId = ({ tag }: IModelItem) =>
  tag instanceof GraphItem
    ? graphItemModelId(tag)
    : isOldGraphNodeAndComponent(tag) || isOldGraphEdgeAndReference(tag)
      ? backboneModelId(tag.dataModel)
      : '';

const itemIsComponent = ({ tag }: IModelItem) =>
  (tag instanceof GraphNode ||
    tag instanceof OldGraphNode ||
    tag instanceof GraphGroup) &&
  tag.isComponent();

const itemIsReference = ({ tag }: IModelItem) =>
  tag instanceof GraphEdge || tag instanceof OldGraphEdge;

const itemIsWorkspace = ({ tag }: IModelItem) =>
  tag instanceof CollapsibleGraphGroup && tag.type === GroupType.WORKSPACE;

/** @returns true if navigation occurs. */
const navigate = (item: IModelItem) => {
  const modelId = getModelId(item);

  if (itemIsComponent(item)) {
    // clear selection and set context component
    dispatchAction(componentGraphSelectionChanged({ graphSelection: [] }));
    dispatchAction(selectComponent({ cid: modelId }));
    return true;
  }

  if (itemIsReference(item)) {
    dispatchAction(selectReference({ cid: modelId }));
    return true;
  }

  if (itemIsWorkspace(item)) {
    dispatchAction(
      openWorkspace({ workspaceId: modelId, keepCurrentContext: false })
    );
    return true;
  }
  return false;
};

const graphItemDoubleClickedListener = (
  _: GraphInputMode,
  e: ItemClickedEventArgs<IModelItem>
) => (e.handled = navigate(e.item));

export default graphItemDoubleClickedListener;
