import {
  ComponentBackboneModel,
  Filter as FilterBackboneModel,
  Reference,
} from 'aqTypes';
import type {
  CreateComponentLabelFilter,
  CreateFilterOptions,
  CreateLabelFormatting,
  CreateReferenceLabelFilter,
  FiltersAsQueryBuilderQueries,
  GetAllReferenceLabelFilters,
  GetComponentLabelFormattingFields,
  GetFilterCount,
  GetFormattingFilters,
  GetLabelFormatting,
  GetNonFormattingFilterCount,
  GetReferenceLabelFilter,
  PerspectivesFilters,
  RemoveComponentLabelFormattingFilters,
  RemoveReferenceLabelFormattingFilters,
  UpdateFiltered,
} from '@ardoq/filter-interface';
import {
  // aliased due to naming conflict with locally exported filterInterface
  filterInterface as filterInterfaceRegistry,
  findQuickPerspectivesTypeEqualitySubquery,
  GetComponentLabelFilter,
  getLabelFormattingRules,
} from '@ardoq/filter-interface';
import { ComponentLabelSource, ReferenceLabelSource } from '@ardoq/data-model';
import {
  type ArdoqId,
  type FilterInterfaceFilter,
  type FormattingFilter,
  type FilterInfoOperator,
  FilterTypes,
  type QueryBuilderSubquery,
  FilterAttributes,
} from '@ardoq/api-types';
import { FormattingRule } from '@ardoq/perspectives';
import {
  formattingRulesToFormattingAttributes,
  getComponentLabelFormattingFilterAttributes,
  getReferenceLabelFormattingFilterAttributes,
} from './formattingRulesToFormattingAttributes';
import {
  mapComponentAttributes,
  mapReferencesAttributes,
  labelFormattingToFilterAttributes,
} from './util';
import Filters from 'collections/filters';
import Fields from 'collections/fields';
import References from 'collections/references';
import Components from 'collections/components';
import { backboneFilterToFilterAttributes } from 'collections/filterUtils';

const DEFAULT_MULTI_LABEL_REFERENCE_FORMATTING_FILTER_ATTRIBUTES = [
  {
    affectComponent: false,
    affectReference: true,
    isNegative: false,
    name: '',
    type: FilterTypes.REFERENCE_LABEL,
    value: ReferenceLabelSource.REFERENCE_TYPE,
  },
];

const DEFAULT_LEGACY_REFERENCE_LABEL_FORMATTING_FILTER_ATTRIBUTES = {
  affectComponent: false,
  affectReference: true,
  isNegative: false,
  name: '',
  type: FilterTypes.REFERENCE_LABEL,
  value: ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE,
};

type ToModel = (filter: FilterBackboneModel) => FilterInterfaceFilter;
const toModel: ToModel = filter => ({
  id: 'cid' in filter ? filter.cid : undefined,
  affectComponent: false,
  affectReference: false,
  comparator: undefined,
  isNegative: false,
  name: '',
  type: '',
  value: '',
  ...filter.attributes,
});

const getAllReferenceLabelFilters: GetAllReferenceLabelFilters = () => {
  const allFilters = Filters.getFiltersByType(FilterTypes.REFERENCE_LABEL).map(
    toModel
  );

  return allFilters.length > 0
    ? allFilters
    : DEFAULT_MULTI_LABEL_REFERENCE_FORMATTING_FILTER_ATTRIBUTES;
};
const getReferenceLabelFilter: GetReferenceLabelFilter = () => {
  const [filter] = Filters.getRefLabelFilter();
  return filter
    ? toModel(filter)
    : DEFAULT_LEGACY_REFERENCE_LABEL_FORMATTING_FILTER_ATTRIBUTES;
};

/**
 * Returns the reference types of the quick perspective filters only if there
 * are no advanced filter rules.
 * In a mix of advanced and quick perspective filters the meaning would be
 * ambiguous. */
const getActiveQuickReferenceTypeFilters = () => {
  const referenceFilters =
    Filters.getFiltersAsQueryBuilderQueries()?.referenceRules;
  if (!referenceFilters) {
    return null;
  }

  const referenceTypesSubquery =
    findQuickPerspectivesTypeEqualitySubquery(referenceFilters);

  if (!referenceTypesSubquery) {
    return null;
  }

  const selectedReferenceTypes = referenceTypesSubquery.rules.map(
    ({ value }) => value
  );
  return selectedReferenceTypes.length > 0
    ? new Set(selectedReferenceTypes)
    : null;
};

const getComponentLabelFilter: GetComponentLabelFilter = () => {
  const [filter] = Filters.getCompLabelFilter();
  return filter
    ? toModel(filter)
    : {
        affectComponent: true,
        affectReference: false,
        isNegative: false,
        name: '',
        type: FilterTypes.COMPONENT_LABEL,
        value: ComponentLabelSource.NAME,
      };
};

const getComponentLabelFormattingFields: GetComponentLabelFormattingFields =
  () => new Set<string>(Filters.getCompLabelFilter().map(f => f.get('value')));

const getLabelFormatting: GetLabelFormatting = () =>
  getLabelFormattingRules(Filters.map(backboneFilterToFilterAttributes));

/** Returns the count of active filters. Note: this does not include formatting filters. */
const getNonFormattingFilterCount: GetNonFormattingFilterCount = () =>
  Filters.getFilterCount();

// feel free to modify these param types. I am building them ad-hoc based on legacy usage, as I convert more code to use filterInterface.
const createFilter: (
  attributes: Omit<FilterInterfaceFilter, 'type' | 'comparator'> & {
    filterName?: string;
    comparator?: FilterInfoOperator;
  },
  options: CreateFilterOptions
) => void = (attributes, options) => {
  Filters.createFilter(attributes, options);
};

const loadFilters = (filters: FilterAttributes[]) => {
  resetFilters();
  filters.forEach(filter => {
    // shouldTriggerChangeEvent is backbone-stuff, we're trying to move away from it so we're setting it to false
    // use dedicated action triggerFiltersChangedEvent to notify the app about the change instead
    Filters.createFilter(filter, { shouldTriggerChangeEvent: false });
  });
};

const createReferenceLabelFilter: CreateReferenceLabelFilter = (
  referenceLabelFormattingValue,
  includeTime
) => {
  const filterAttributes = getReferenceLabelFormattingFilterAttributes(
    referenceLabelFormattingValue,
    includeTime
  );

  Filters.createFilter(filterAttributes, {
    shouldTriggerChangeEvent: true,
  });
};

const createComponentLabelFilter: CreateComponentLabelFilter = (
  componentLabelFormattingValue,
  includeTime
) => {
  const filterAttributes = getComponentLabelFormattingFilterAttributes(
    componentLabelFormattingValue,
    includeTime
  );

  Filters.createFilter(filterAttributes, {
    shouldTriggerChangeEvent: true,
  });
};
const createLabelFormatting: CreateLabelFormatting = formatting => {
  const filterAttributes = formatting.flatMap(
    labelFormattingToFilterAttributes
  );
  for (const currentFilterAttributes of filterAttributes) {
    Filters.createFilter(currentFilterAttributes, {
      shouldTriggerChangeEvent: true,
    });
  }
};

const removeReferenceLabelFormattingFilters: RemoveReferenceLabelFormattingFilters =
  shouldTriggerChangeEvent => {
    Filters.removeReferenceLabelFormattingFilters({ shouldTriggerChangeEvent });
  };

const removeComponentLabelFormattingFilters: RemoveComponentLabelFormattingFilters =
  shouldTriggerChangeEvent => {
    Filters.removeComponentLabelFormattingFilters({ shouldTriggerChangeEvent });
  };

const setConditionalFormattingFilters = (formattingRules: FormattingRule[]) => {
  const mappedFormattingRules =
    formattingRulesToFormattingAttributes(formattingRules);

  Filters.setConditionalFormattingFilters({
    formattingRules: mappedFormattingRules,
  });
};

/** tells the filter cache to include the given component. this is a sort of workaround to a technical deficiency caused by the cache itself */
const updateFiltered: UpdateFiltered = componentId => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return;
  }
  Filters.updateFiltered(component);
};

const getFormattingFilters: GetFormattingFilters = () =>
  Filters.getFormattingFilters().map(toModel) as FormattingFilter[];

const removeFormattingFiltersByField = (fieldId: ArdoqId) => {
  const field = Fields.collection.get(fieldId);
  if (field) {
    Filters.removeFormattingFiltersByField(field);
  }
};

const getFilterColors = () => Filters.getFilterColors();

const getFiltersAsQueryBuilderQueries = (): FiltersAsQueryBuilderQueries =>
  Filters.getFiltersAsQueryBuilderQueries();

const getMatchingColorFilters = (model?: ComponentBackboneModel | Reference) =>
  model
    ? Filters.getFormattingFilters().filter(
        filter =>
          filter.get('color') &&
          filter.isAffected(model) &&
          filter.isIncluded(model)
      )
    : null;
const getColorFiltersForComponent = (modelId: ArdoqId) =>
  getMatchingColorFilters(Components.collection.get(modelId));

const getColorFiltersForReference = (modelId: ArdoqId) =>
  getMatchingColorFilters(References.collection.get(modelId));

const containsAnyNestedRule = (subquery: QueryBuilderSubquery) =>
  subquery.rules.length !== 0;

/**
 * Query builder returns a subquery when there are no rules defined
 * { condition: "OR"; rules: [] }
 *
 * Arqod-front should ignore such rules.
 */
const applyPerspectiveFilters = (filters: PerspectivesFilters) => {
  const componentRules = containsAnyNestedRule(filters.componentFilterRules)
    ? filters.componentFilterRules
    : null;
  const referenceRules = containsAnyNestedRule(filters.referenceFilterRules)
    ? filters.referenceFilterRules
    : null;

  Filters.setFiltersFromQueryBuilderQueries({
    componentRules,
    referenceRules,
  });
  Filters.updateWorkspaceFilter(filters.filterByWorkspaceIds);

  // Old perspective editor would keep selection in the Perspective editor and the main app
  // automatically, without using 'Apply' button. That means that we don't handle
  // `toggleDynamicFilterActive` and `selectDynamicFilterOption` actions in Perspective editor reducers,
  // but pass them to the main app and expect to see the updated through `notifyFiltersChange` action
  // with activated/deactivated graph filters as part of the payload.
  // As a consequence, we don't apply Graph filters manually when user clicks 'Apply' or 'Save' button
  // in the perspective editor.
};

const resetFilters = () => Filters.reset();

const getFilterCount: GetFilterCount = () => {
  return Filters.length;
};

const initializeFilterInterface = () =>
  filterInterfaceRegistry.registerImplementation({
    getReferenceLabelFilter,
    getAllReferenceLabelFilters,
    getComponentLabelFilter,
    getComponentLabelFormattingFields,
    getLabelFormatting,
    getNonFormattingFilterCount,
    createFilter,
    updateFiltered,
    getFormattingFilters,
    // @ts-expect-error this function returns a list containing dividers as { divider: true }, not just the attributes as defined in FilterInterfaceImplementation
    mapComponentAttributes,
    // @ts-expect-error this function returns a list containing dividers as { divider: true }, not just the attributes as defined in FilterInterfaceImplementation
    mapReferencesAttributes,
    applyPerspectiveFilters,
    createReferenceLabelFilter,
    createComponentLabelFilter,
    createLabelFormatting,
    removeReferenceLabelFormattingFilters,
    removeComponentLabelFormattingFilters,
    setConditionalFormattingFilters,
    resetFilters,
    getFilterCount,
    getActiveQuickReferenceTypeFilters,
    removeFormattingFiltersByField,
    getFilterColors,
    getFiltersAsQueryBuilderQueries,
    getColorFiltersForComponent,
    getColorFiltersForReference,
    loadFilters,
  });

export const filterInterface = {
  getAllReferenceLabelFilters,
  getReferenceLabelFilter,
  getLabelFormatting,
  getActiveQuickReferenceTypeFilters,
  getComponentLabelFilter,
  getComponentLabelFormattingFields,
  getNonFormattingFilterCount,
  createFilter,
  createReferenceLabelFilter,
  createComponentLabelFilter,
  createLabelFormatting,
  removeReferenceLabelFormattingFilters,
  removeComponentLabelFormattingFilters,
  setConditionalFormattingFilters,
  updateFiltered,
  getFormattingFilters,
  mapComponentAttributes,
  mapReferencesAttributes,
  removeFormattingFiltersByField,
  getFilterColors,
  getFiltersAsQueryBuilderQueries,
  getColorFiltersForComponent,
  getColorFiltersForReference,
  resetFilters,
  getFilterCount,
  initializeFilterInterface,
  loadFilters,
};
