export type Cell = {
  col: number;
  row: number;
};

/* information about an explored cell */
type CellInfo = {
  cameFrom: Cell | null;
  gScore: number;
  fScore: number;
};

/* a map keyed by cell value with specified missing value */
function cellMap<Type>(missing: Type) {
  const map = new Map<number, Type>();
  const key = (k: Cell) => k.col * 100000 + k.row;

  return {
    empty: () => map.size === 0,
    get: (cell: Cell) => map.get(key(cell)) ?? missing,
    set: (cell: Cell, value: Type) => map.set(key(cell), value),
    has: (cell: Cell) => map.has(key(cell)),
    delete: (cell: Cell) => map.delete(key(cell)),
    values: () => map.values(),
  };
}

function cellSet() {
  const map = new Map<number, Cell>();
  const key = (k: Cell) => k.col * 100000 + k.row;

  return {
    empty: () => map.size === 0,
    keys: () => map.values(),
    add: (value: Cell) => map.set(key(value), value),
    delete: (value: Cell) => map.delete(key(value)),
    has: (value: Cell) => map.has(key(value)),
  };
}

/* possible moves (could conveivably, but not usefully, be a parameter) */
const directions = [
  [-1, 0],
  [0, -1],
  [1, 0],
  [0, 1],
];

/* cost of move from cell k to neighbor (could conveivably be a parameter to astar) */
const costofMoveToNeighbour = (_cell: Cell, _neighbor: Cell) => 1;

export function* astar(
  seeds: Cell[],
  isValid: (cell: Cell) => boolean,
  h: (cell: Cell) => number
) {
  const open = cellSet();
  const info = cellMap<CellInfo>({
    cameFrom: null,
    gScore: +Infinity,
    fScore: +Infinity,
  });

  /* initialise the solution seeds */

  for (const seed of seeds) {
    open.add(seed);
    info.set(seed, { cameFrom: null, gScore: 0, fScore: 0 });
  }

  /* keep looking for solution until we run out of options */

  let solution: Cell | null = null;

  while (!open.empty() && !solution) {
    let current: Cell | null = null;
    let scoreCurrent = +Infinity;

    /* get the current element as the lowest scored open element */
    /* In O(n), this is catastrophically slow. The optimal solution would be to use a fibonnaci heap */
    for (const cell of open.keys()) {
      const scoreElement = info.get(cell).fScore;

      if (scoreElement < scoreCurrent) {
        scoreCurrent = scoreElement;
        current = cell;
      }
    }

    current = current!; // it is impossible for the lowest number in a non-empty set of finite numbers to be infinite

    if (h(current) === 0) {
      /* suggestion scored zero; that makes it a solution */

      solution = current;
    } else {
      /* worse than perfect not a solution; keep looking */

      open.delete(current);

      for (const direction of directions) {
        const neighbor = {
          col: current.col + direction[0],
          row: current.row + direction[1],
        };

        if (isValid(neighbor)) {
          const gScore =
            info.get(current).gScore + costofMoveToNeighbour(current, neighbor);

          if (gScore < info.get(neighbor).gScore) {
            info.set(neighbor, {
              cameFrom: current,
              gScore: gScore,
              fScore: gScore + h(neighbor),
            });

            if (!open.has(neighbor)) {
              open.add(neighbor);
            }
          }
        }
      }
    }
  }

  /* dump the solution */

  if (solution) {
    let current: Cell = solution;

    yield current;

    while (info.get(current).cameFrom) {
      current = info.get(current).cameFrom!;

      yield current;
    }
  }
}
