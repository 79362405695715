import { ViewIds } from '@ardoq/api-types';
import { logError, logWarn } from '@ardoq/logging';
import yfiles, {
  GraphComponent,
  Insets,
  LayoutData,
  LayoutDescriptor,
  LayoutExecutorAsync,
  PortAdjustmentPolicy,
  TimeSpan,
} from '@ardoq/yfiles';
import { worker } from './getWorker';
import { GraphLayoutResult, WorkerError, WorkerErrorLevel } from './types';

const isWorkerError = (data: any): data is WorkerError => 'errorLevel' in data;
worker.addEventListener('message', event => {
  if (isWorkerError(event.data)) {
    const { error, message, extraData } = event.data;
    if (event.data.errorLevel === WorkerErrorLevel.ERROR) {
      logError(error, message, extraData);
    } else {
      logWarn(error, message, extraData);
    }
  }
});

const ensureLicense = () => {
  let postedLicense = false;
  return (() => {
    if (!postedLicense) {
      const license = (yfiles as unknown & { license?: unknown }).license;
      if (!license) {
        logError(Error('yFiles license not found.'));
        return;
      }
      worker.postMessage(license);
      postedLicense = true;
    }
  })();
};
let transaction = 0;
type RunLayoutArgs = {
  graphComponent: GraphComponent;
  layoutDescriptor: LayoutDescriptor;
  layoutData?: LayoutData | null;
  duration?: TimeSpan;
  animateViewport?: boolean;
  updateContentRect?: boolean;
  targetBoundsInsets?: Insets;
  portAdjustmentPolicy?: PortAdjustmentPolicy;
  viewId: ViewIds;
  configureTableLayout?: boolean;
  onLayoutResult?: (result: GraphLayoutResult) => void;
  viewInstanceId?: string;
};

const activeLayoutExecutorsByViewInstanceId = new Map<
  string,
  LayoutExecutorAsync
>();
const runWebWorkerLayout = async ({
  graphComponent,
  layoutDescriptor,
  layoutData,
  duration = TimeSpan.fromMilliseconds(0),
  animateViewport = false,
  updateContentRect = false,
  targetBoundsInsets = Insets.EMPTY,
  portAdjustmentPolicy,
  viewId,
  configureTableLayout,
  onLayoutResult,
  viewInstanceId,
}: RunLayoutArgs) => {
  ensureLicense();
  const shouldAnimate = duration.totalMilliseconds > 0;
  const executor = new LayoutExecutorAsync({
    messageHandler: data => {
      const currentTransaction = transaction++;
      return new Promise(resolve => {
        const handler = (event: MessageEvent<any>) => {
          if (
            isWorkerError(event.data) ||
            event.data.transaction !== currentTransaction
          ) {
            return;
          }
          resolve(event.data);
          onLayoutResult?.(event.data.result);
          worker.removeEventListener('message', handler);
        };
        worker.addEventListener('message', handler);
        worker.postMessage({
          ...data,
          viewId,
          transaction: currentTransaction,
        });
      });
    },
    graphComponent,
    layoutDescriptor,
    layoutData,
    duration,
    easedAnimation: shouldAnimate,
    animateViewport,
    updateContentRect,
    targetBoundsInsets,
    portAdjustmentPolicy,
    configureTableLayout,
  });
  if (viewInstanceId) {
    activeLayoutExecutorsByViewInstanceId.get(viewInstanceId)?.cancel();
    activeLayoutExecutorsByViewInstanceId.set(viewInstanceId, executor);
  }

  await executor.start();
  if (viewInstanceId) {
    activeLayoutExecutorsByViewInstanceId.delete(viewInstanceId);
  }
};

export default runWebWorkerLayout;
