import {
  RegisteredReducer,
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import { WILDCARD } from '@ardoq/common-helpers';

const defaultState: Record<string, boolean> = {};

type StateStreamExtension = {
  reducers?: RegisteredReducer<Record<string, boolean>, any>[];
  defaultState?: Record<string, any>;
};

/*
 * Creates stream state that handles collapse
 * Supports single toggling
 * Can be extended by providing the extensions parameter
 */
export const setupCollapsed$ = (
  namespace: string,
  extensions?: StateStreamExtension
) => {
  const toggleCollapse = actionCreator<ArdoqId>(
    `[${namespace}] collapsed toggleCollapse`
  );
  const setCollapsed = actionCreator<ArdoqId[]>(
    `[${namespace}] collapsed setCollapse`
  );

  const handleToggleCollapse = (
    state: Record<ArdoqId, boolean>,
    id: string
  ): Record<ArdoqId, boolean> => ({
    ...state,
    [id]: state[WILDCARD] ? false : !state[id],
    [WILDCARD]: false,
  });

  const handleSetCollapse = (_: Record<ArdoqId, boolean>, ids: string[]) =>
    ids.reduce((acc, id) => ({ ...acc, [id]: true }), {});

  const collapsed$ = persistentReducedStream<Record<ArdoqId, boolean>>(
    `${namespace} collapsed$`,
    { ...defaultState, ...extensions?.defaultState },
    [
      reducer(toggleCollapse, handleToggleCollapse),
      reducer(setCollapsed, handleSetCollapse),
      ...(extensions?.reducers || []),
    ]
  );

  return { collapsed$, toggleCollapse, setCollapsed };
};
