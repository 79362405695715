import { ArdoqId } from '@ardoq/api-types';
import {
  PopoverPlacement,
  popoverConfig,
  popoverRegistry,
} from '@ardoq/popovers';
import {
  UrlFieldValue,
  getUrlFieldValuesByComponentId,
  getUrlFieldValuesByReferenceId,
} from '@ardoq/graph';
import { GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID } from 'tabview/graphComponent/consts';
import { HasUrlFieldValuesById } from './types';
import urlFieldValuesPopover from './urlFieldValuesPopover';
import { uniqBy } from 'lodash';

popoverConfig.set(GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID, {
  preferredPlacement: PopoverPlacement.TOP,
});

export interface RegisterUrlFieldValuesPopoverArgs
  extends Partial<HasUrlFieldValuesById> {
  allComponentIds: string[];
  allReferenceIds: string[];
}
const entries: RegisterUrlFieldValuesPopoverArgs[] = [];

export const registerUrlFieldValuesPopover = (
  entry: RegisterUrlFieldValuesPopoverArgs
) => {
  entries.push(entry);
  rebuild();
};

export const unregisterUrlFieldValuesPopover = (
  entry: RegisterUrlFieldValuesPopoverArgs
) => {
  entries.splice(entries.indexOf(entry), 1);
  rebuild();
};

let urlFieldValuesByComponentId: Map<ArdoqId, UrlFieldValue[]> | null = null;
let urlFieldValuesByReferenceId: Map<ArdoqId, UrlFieldValue[]> | null = null;

const buildMap = (
  fieldValuesKey: 'urlFieldValuesByComponentId' | 'urlFieldValuesByReferenceId',
  modelIdsKey: 'allComponentIds' | 'allReferenceIds',
  getFunction: (modelIds: string[]) => Map<ArdoqId, UrlFieldValue[] | null>
) =>
  entries.reduce((result, entry) => {
    const resolvedUrlFieldValues =
      entry[fieldValuesKey] ?? getFunction(entry[modelIdsKey]);

    [...resolvedUrlFieldValues.entries()].forEach(
      ([modelId, urlFieldValues]) => {
        result.set(
          modelId,
          uniqBy(
            [...(result.get(modelId) ?? []), ...(urlFieldValues ?? [])],
            'label'
          )
        );
      }
    );
    return result;
  }, new Map<ArdoqId, UrlFieldValue[]>());

const rebuild = () => {
  if (!entries.length) {
    urlFieldValuesByComponentId = null;
    urlFieldValuesByReferenceId = null;
    popoverRegistry.delete(GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID);
  }

  urlFieldValuesByComponentId = buildMap(
    'urlFieldValuesByComponentId',
    'allComponentIds',
    getUrlFieldValuesByComponentId
  );
  urlFieldValuesByReferenceId = buildMap(
    'urlFieldValuesByReferenceId',
    'allReferenceIds',
    getUrlFieldValuesByReferenceId
  );
  popoverRegistry.set(
    GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID,
    urlFieldValuesPopover({
      urlFieldValuesByComponentId,
      urlFieldValuesByReferenceId,
    })
  );
};
