import { AggregatedReferenceType, GraphModelShape } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import {
  getReferenceTypes,
  getReferenceTypesOverwrittenOptions,
} from 'modelInterface/graph/utils';
import { isEqual } from 'lodash';
import { GraphInterface, TraverseOptions } from '@ardoq/graph';

export default class ReferenceTypeOptionCache {
  private graph: GraphModelShape | null = null;
  private startSet: ArdoqId[] = [];
  private traverseOptions: TraverseOptions = {
    maxDegreesIncoming: 0,
    maxDegreesOutgoing: 0,
    maxDegrees: 0,
    isParentRelationAsReference: false,
    outgoingReferenceTypes: null,
    incomingReferenceTypes: null,
  };
  private traversedOutgoingReferenceTypes: AggregatedReferenceType[] = [];
  private traversedIncomingReferenceTypes: AggregatedReferenceType[] = [];

  getReferenceTypeOptions(
    graphInterface: GraphInterface,
    graph: GraphModelShape,
    startSet: ArdoqId[],
    traverseOptions: TraverseOptions
  ) {
    const overwrittenTraverseOptions = {
      ...traverseOptions,
      ...getReferenceTypesOverwrittenOptions,
    };
    if (
      !(
        graph === this.graph &&
        isIdenticalSet(startSet, this.startSet) &&
        isEqual(overwrittenTraverseOptions, this.traverseOptions)
      )
    ) {
      const {
        traversedOutgoingReferenceTypes,
        traversedIncomingReferenceTypes,
      } = getReferenceTypes({
        graphInterface,
        graph,
        startSet,
        traverseOptions: overwrittenTraverseOptions,
      });

      this.graph = graph;
      this.startSet = startSet;
      this.traverseOptions = overwrittenTraverseOptions;
      this.traversedOutgoingReferenceTypes = traversedOutgoingReferenceTypes;
      this.traversedIncomingReferenceTypes = traversedIncomingReferenceTypes;
    }

    return {
      traversedOutgoingReferenceTypes: this.traversedOutgoingReferenceTypes,
      traversedIncomingReferenceTypes: this.traversedIncomingReferenceTypes,
    };
  }
}

const isIdenticalSet = (listA: string[], listB: string[]) => {
  const setA = new Set(listA);
  const setB = new Set(listB);
  const setAandB = new Set([...listA, ...listB]);
  return setA.size === setB.size && setA.size === setAandB.size;
};
