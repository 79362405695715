import {
  ConcurrencyController,
  GraphComponent,
  IInputModeContext,
  INode,
  INodeHitTester,
  InputModeBase,
  ModifierKeys,
  MouseButtons,
  MouseEventArgs,
  Point,
  WaitInputMode,
} from '@ardoq/yfiles';

export class NodeDragInputMode extends InputModeBase {
  private graphComponent: GraphComponent | null = null;
  private node: INode | null = null;
  private parentNode: INode | null = null;
  private onDropHandler: null | ((node: INode, location: Point) => void) = null;

  private readonly onMouseDownListener: (
    _: GraphComponent,
    evt: MouseEventArgs
  ) => void;
  private readonly onMouseUpListener: (
    _: GraphComponent,
    evt: MouseEventArgs
  ) => void;
  private readonly onMouseDragListener: (
    _: GraphComponent,
    evt: MouseEventArgs
  ) => void;

  constructor(private waitInputMode: WaitInputMode) {
    super();

    this.onMouseDownListener = (_, evt) => this.onMouseDown(evt);
    this.onMouseUpListener = (_, evt) => this.onMouseUp(evt);
    this.onMouseDragListener = (_, evt) => this.onMouseDrag(evt);
  }

  install(context: IInputModeContext, controller: ConcurrencyController): void {
    super.install(context, controller);

    this.graphComponent = context.canvasComponent as GraphComponent;
    this.graphComponent.addMouseDownListener(this.onMouseDownListener);
    this.graphComponent.addMouseUpListener(this.onMouseUpListener);
  }

  uninstall(context: IInputModeContext): void {
    super.uninstall(context);

    if (this.graphComponent) {
      this.graphComponent.removeMouseDownListener(this.onMouseDownListener);
      this.graphComponent.removeMouseUpListener(this.onMouseUpListener);
    }

    this.graphComponent = null;
  }

  private onMouseDrag(event: MouseEventArgs): void {
    if (!this.node || !this.graphComponent) {
      return;
    }

    const parentNodeId = this.node?.tag?.parent?.id;
    const parentNode: INode | null =
      parentNodeId &&
      this.graphComponent.graph.nodes.find(
        ({ tag }) => tag?.id === parentNodeId
      );

    if (!parentNode?.layout.contains(event.location)) {
      this.cancel();
      this.graphComponent.inputMode?.cancel();
    }
  }

  cancel(): void {
    if (this.graphComponent) {
      this.graphComponent.removeMouseDragListener(this.onMouseDragListener);
    }
    this.node = null;
    this.parentNode = null;
    super.cancel();
  }

  private onMouseDown({ modifiers, location, buttons }: MouseEventArgs): void {
    if (
      modifiers !== ModifierKeys.NONE ||
      buttons === MouseButtons.RIGHT ||
      this.waitInputMode.waiting
    ) {
      // To avoid clashes with other input modes:
      // Don't arm this mode if shift/ctrl/cmd is held down
      // Don't arm this mode for the right mouse button
      // Don't arm this mode if the waitInputMode is active
      return;
    }

    const nodeHitTester = this.inputModeContext!.lookup(INodeHitTester.$class);

    if (!nodeHitTester || !this.graphComponent) {
      return;
    }

    const node = nodeHitTester
      .enumerateHits(this.inputModeContext!, location)
      .at(0);

    if (node) {
      this.node = node;
      const parentNodeId: string = node.tag?.parent?.id;
      const parentNode =
        parentNodeId &&
        this.graphComponent.graph.nodes.find(
          ({ tag }) => tag?.id === parentNodeId
        );

      if (parentNode) {
        this.parentNode = parentNode;
        this.graphComponent.addMouseDragListener(this.onMouseDragListener);
      }
    }
  }

  private onMouseUp(event: MouseEventArgs): void {
    if (!this.onDropHandler || !this.node || !this.graphComponent) {
      return;
    }

    const bounds = this.parentNode
      ? this.parentNode.layout
      : this.graphComponent.viewport;

    if (bounds.contains(event.location)) {
      this.onDropHandler(this.node, event.location);
    } else {
      this.graphComponent.inputMode?.cancel();
    }

    this.cancel();
  }

  addDropHandler(handler: (node: INode, location: Point) => void): void {
    this.onDropHandler = handler;
  }
}
