import { Metamodel } from '@ardoq/api-types';
import { MetamodelContext, MetamodelFieldContext } from 'metamodel/types';
import { getWorkspaceIdsFromContext } from 'metamodel/utils';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';

export const getWorkspaceNamesFromContext = (
  metamodel: Metamodel,
  context: MetamodelContext
) => {
  const contextWorkspaceIds = getWorkspaceIdsFromContext(metamodel, context);
  const { workspaces } = metamodel.metamodel;
  return contextWorkspaceIds.map(
    contextWorkspaceId => workspaces[contextWorkspaceId].name
  );
};

export const getPopoverMessageFromContext = (context?: MetamodelContext) => {
  if (context) {
    const { type } = context;
    const {
      COMPONENT,
      REFERENCE,
      REFERENCE_COMPLETENESS,
      WORKSPACE,
      WORKSPACE_FOLDER,
    } = SimpleBlockDiagramContentType;
    if ((context as MetamodelFieldContext).field) {
      return "Open this field's workspace:";
    } else if (type === COMPONENT || type === REFERENCE_COMPLETENESS) {
      return "Open this component type's workspace:";
    } else if (type === REFERENCE) {
      return "Open this reference type's workspace:";
    } else if (type === WORKSPACE) {
      return 'Open this workspace:';
    } else if (type === WORKSPACE_FOLDER) {
      return `Open all workspaces in this folder:`;
    }
  }
  return `Open all workspaces in this metamodel:`;
};
