import { ViewIds } from '@ardoq/api-types';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import Fields from 'collections/fields';
import Filters from 'collections/filters';
import References from 'collections/references';
import {
  emptyState,
  extractCustomFields,
  fieldHeaderProps,
  referenceProps,
  setupHeaders,
} from './common/utils';
import {
  resetExpandedDescriptionsReducer,
  toggleDescriptionReducer,
} from './common/commonReducers';

import { loadVisualizationSlideSuccess } from 'presentation/viewPane/actions';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
  notifyReferencesUpdated,
} from 'streams/references/ReferenceActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { isUnique, isUniqueCombination } from 'utils/collectionUtil';
import { referenceComparator } from 'utils/compareUtils';
import { ComponentBackboneModel, Reference } from 'aqTypes';
import {
  CellTypes,
  HeaderModel,
  TableViewModel,
  TableViewRow,
} from './common/types';
import { debounceTime, startWith, withLatestFrom } from 'rxjs/operators';
import {
  action$,
  streamReducer,
  extractPayload,
  ofType,
  reduceState,
} from '@ardoq/rxbeach';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import * as profiling from '@ardoq/profiling';
import { Observable, combineLatest } from 'rxjs';
import type { ContextShape } from '@ardoq/data-model';
import { context$ } from 'streams/context/context$';
import { ContextSort } from '@ardoq/common-helpers';
import { relevantComponentsFromContextShape } from 'tabview/componentReducers';
import Components from 'collections/components';
import { APIFieldAttributes } from '@ardoq/api-types/';
import { dateRangeOperations } from '@ardoq/date-range';
import { uniqBy } from 'lodash';
import type { ExtractStreamShape } from 'tabview/types';

const defaultHeaders: HeaderModel[] = [
  {
    key: 'id',
    label: 'Ardoq OID',
    cellType: CellTypes.TEXT,
    isExpandController: false,
  },
  {
    key: 'source',
    label: 'Source Component',
    cellType: CellTypes.NAME,
    order: null,
    isExpandController: false,
  },
  {
    key: 'type',
    label: 'Type',
    cellType: CellTypes.TEXT,
    order: null,
    isExpandController: false,
  },
  {
    key: 'target',
    label: 'Target Component',
    cellType: CellTypes.NAME,
    order: null,
    isExpandController: false,
  },
  {
    key: 'displayText',
    label: 'Display Text',
    cellType: CellTypes.TEXT,
    order: null,
    isExpandController: true,
  },
  {
    key: 'created',
    label: 'Created at',
    cellType: CellTypes.DATE_TIME,
    isExpandController: false,
  },
  {
    key: 'last-updated',
    label: 'Updated at',
    cellType: CellTypes.DATE_TIME,
    isExpandController: false,
  },
];
const toRow =
  (fields: APIFieldAttributes[]) =>
  (reference: Reference): TableViewRow => ({
    hasDescription: Boolean(reference.get('description')),
    markdownDescription: reference.get('description') || '',
    ...referenceProps(reference),
    ...extractCustomFields(fields, reference),
    expandDescription: false,
  });

const getIncomingAndOutgoingReferences = (
  component: ComponentBackboneModel
) => [
  ...References.collection.getSourceRefsByComponent(component),
  ...References.collection.getTargetRefsByComponent(component),
];
const getChildComponentReferences = (component: ComponentBackboneModel) => {
  const innerRecursiveStep = (comp: ComponentBackboneModel): Reference[] =>
    comp.getChildren().length > 0
      ? comp
          .getChildren()
          .flatMap(child => [
            ...getIncomingAndOutgoingReferences(comp),
            ...innerRecursiveStep(child),
          ])
      : getIncomingAndOutgoingReferences(comp);
  return innerRecursiveStep(component)
    .filter(isUnique('id'))
    .sort(referenceComparator);
};

const getReferences = (context: ContextShape) => {
  const contextComponent = Components.collection.get(context.componentId);
  if (contextComponent) {
    return getChildComponentReferences(contextComponent);
  } else if (context.workspaceId) {
    return References.collection
      .filter(
        ref =>
          ref.get('rootWorkspace') === context.workspaceId ||
          ref.get('targetWorkspace') === context.workspaceId
      )
      .sort(referenceComparator);
  }
  return [];
};

type NextStateArgs = {
  references: Reference[];
  sort: ContextSort;
  hasComponentsAvailable: boolean;
};
const nextState = ({
  references,
  sort,
  hasComponentsAvailable,
}: NextStateArgs) => {
  if (references.length === 0) {
    return { ...emptyState, rows: [], hasComponentsAvailable };
  }

  const filteredReferences = references.filter(r =>
    Filters.isIncludedInContextByFilter(r)
  );

  const referenceTypes = filteredReferences
    .map(ref => ({
      typeId: ref.get('type'),
      modelId: ref.getModelId(),
    }))
    .filter(isUniqueCombination('modelId', 'typeId'));

  const locale = getCurrentLocale();
  const customFields = uniqBy(
    referenceTypes.flatMap(({ typeId, modelId }) =>
      Fields.collection
        .getByReferenceType(typeId, modelId)
        .map(field => field.toJSON())
    ),
    '_id'
  ).sort((a, b) => localeCompareNumericLowercase(a.label, b.label, locale));

  // The compareBackboneModels function expects a comma separated string for date range field names. This gets used when you click
  // a column header in the table view to sort the data by that column.
  const customFieldsToPresent = dateRangeOperations
    .mergeDateTimeFieldsToDateRangeFields(customFields)
    .fields.map(field =>
      dateRangeOperations.isDateRangeField(field)
        ? {
            ...field,
            name: `${field.dateTimeFields.start.name},${field.dateTimeFields.end.name}`,
          }
        : field
    );

  return {
    ...emptyState,
    hasComponentsAvailable,
    defaultHeaders: setupHeaders(sort, defaultHeaders),
    fieldHeaders: setupHeaders(
      sort,
      uniqBy(customFieldsToPresent, 'name').map(fieldHeaderProps)
    ),
    referenceTypeHeaders: [],
    rows: filteredReferences.map(toRow(customFieldsToPresent)),
  };
};

const getConnectedComponents = (referenceIds: string[]) =>
  new Set(
    referenceIds.flatMap(id => {
      const reference = References.collection.get(id);
      return (
        (reference && [reference.getSourceId(), reference.getTargetId()]) || []
      );
    })
  );

const reset = (context: ContextShape): TableViewModel => {
  const transaction = profiling.startTransaction(
    'reference table view model calculation',
    500,
    profiling.Team.INSIGHT
  );
  const { hasComponentsAvailable } =
    relevantComponentsFromContextShape(context);
  const state = nextState({
    references: getReferences(context),
    sort: context.sort,
    hasComponentsAvailable,
  });
  profiling.endTransaction(transaction, { viewId: ViewIds.REFERENCETABLE });
  return state;
};

const resetNotifier$ = combineLatest([
  context$,
  action$.pipe(
    ofType(
      notifyFieldUpdated,
      notifyFieldAdded,
      notifyFieldRemoved,
      notifyFiltersChanged,
      loadVisualizationSlideSuccess,
      notifyReferencesRemoved
    ),
    startWith(null)
  ),
]).pipe(debounceTime(50));
const resetOnChanges = (
  _: TableViewModel,
  [context]: ExtractStreamShape<typeof resetNotifier$>
) => reset(context);

const referencesChanged$ = action$.pipe(
  ofType(notifyReferencesUpdated, notifyReferencesAdded),
  extractPayload(),
  withLatestFrom(context$)
);
const maybeResetOnReferencesChanged = (
  state: TableViewModel,
  [{ referenceIds }, context]: ExtractStreamShape<typeof referencesChanged$>
) => {
  const changedComponents = getConnectedComponents(referenceIds);

  if (context.componentId && !changedComponents.has(context.componentId)) {
    // If the current context is a component, and this component is not a part
    // of the changed references, nothing in this view has changed
    return state;
  }
  return reset(context);
};

const componentsChanged$ = action$.pipe(
  ofType(
    notifyComponentsAdded,
    notifyComponentsRemoved,
    notifyComponentsUpdated
  ),
  extractPayload(),
  withLatestFrom(context$)
);

const maybeResetOnComponentsChanged = (
  state: TableViewModel,
  [{ componentIds }, context]: ExtractStreamShape<typeof componentsChanged$>
) => {
  if (context.componentId && !componentIds.includes(context.componentId)) {
    // If the current context is a component, and the context component
    // is not part of the changed components, the change will not affect this view
    return state;
  }
  return reset(context);
};

export const createViewModel$ = (
  viewInstanceId: string
): Observable<TableViewModel> => {
  return action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState('referenceTableViewModel$', emptyState, [
      resetExpandedDescriptionsReducer(ViewIds.REFERENCETABLE),
      toggleDescriptionReducer,
      streamReducer(referencesChanged$, maybeResetOnReferencesChanged),
      streamReducer(componentsChanged$, maybeResetOnComponentsChanged),
      streamReducer(resetNotifier$, resetOnChanges),
    ]),
    debounceTime(50)
  );
};
