import { last } from 'lodash';
import { License } from '@ardoq/yfiles';
import { devLicense } from './devLicense';
import { prodLicense } from './prodLicense';

export const initializeYFilesLicense = async () => {
  License.value =
    process.env.NODE_ENV === 'development' ||
    last(location.hostname.split('.')) === 'dev' ||
    location.hostname.endsWith('localhost')
      ? devLicense
      : prodLicense;
};
