import { ArdoqId } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { getTargetData } from './getTargetData';
import { ComponentsData } from '../types';
import { composeComponentsIds } from './composeComponentsIds';

export const getSourceData = (
  selectedComponentId: ArdoqId | null,
  workspaceId: ArdoqId | null,
  limit = Infinity
): ComponentsData => {
  if (!selectedComponentId) {
    return getTargetData(workspaceId, limit);
  }

  const children = componentInterface.getChildren(selectedComponentId);
  const parentId = componentInterface.getParentId(selectedComponentId);

  if (workspaceId && children.length === 0) {
    if (!parentId) {
      return getTargetData(workspaceId, limit);
    }

    return getSourceData(parentId, workspaceId, limit);
  }

  const hierarchy =
    componentInterface.getComponentHierarchy(selectedComponentId);

  const allComponentsCount = Object.keys(hierarchy).length;
  const isLimitingComponents = allComponentsCount > limit;

  const componentIds = composeComponentsIds({
    hierarchy,
    children: [selectedComponentId],
    limit: isLimitingComponents ? limit : Infinity,
  });

  return { componentIds, workspaceName: null, allComponentsCount };
};
