import { CONTAINER_MARGIN, LEAF_NODES_MIN_WIDTH } from './consts';
import styled, { css } from 'styled-components';
import { LEAF_NODES_MARGIN } from 'tabview/capabilityMap/consts';
import { colors } from '@ardoq/design-tokens';
import {
  CapabilityMapContentContainer,
  ViewLegendContainerElement,
} from '@ardoq/graph';
import { ViewLegendRowContent } from '@ardoq/view-legend';
import { COLLAPSED_REFERENCE_BOX_SHADOW } from '@ardoq/dependency-map';
import { ACTIVE_BRIGHTNESS } from '@ardoq/global-consts';

const containsCollapsedNodesStyle = css<CollapsibleNodeProps>`
  ${({ $containsCollapsedNodes }) =>
    $containsCollapsedNodes
      ? css`
          border-width: 3px;
          border-style: double;
        `
      : css`
          border-width: 1px;
          border-style: solid;
        `}
`;

const highlightedStyle = css<CollapsibleNodeProps>`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background-color: inherit;
    filter: brightness(${ACTIVE_BRIGHTNESS});
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      &:after {
        opacity: 1;
      }
    `}

  & > * {
    position: relative;
    z-index: 1;
  }
`;
type CollapsibleNodeProps = {
  $containsCollapsedNodes?: boolean;
  $isHighlighted?: boolean;
};

export const ViewContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  & .componentIcon {
    margin-left: ${LEAF_NODES_MARGIN}px;
  }
  &.forExport {
    overflow: visible;
    height: fit-content;
    width: fit-content;
    flex: unset;
    ${CapabilityMapContentContainer} {
      padding: 0px;
    }
    ${ViewLegendContainerElement} {
      flex: unset;
      height: fit-content;
      max-height: unset;
      max-height: none;
      padding-top: 0px !important;
      bottom: 0px;
      visibility: visible;
    }
    ${ViewLegendRowContent} {
      height: unset;
      padding: 24px;
      overflow: visible;
    }
  }
`;

export const ColumnDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const RowDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Container = styled.div<CollapsibleNodeProps>`
  border-color: hsla(0, 0%, 0%, 0.5);
  display: flex;
  flex-direction: column;
  margin: ${CONTAINER_MARGIN}px;
  ${containsCollapsedNodesStyle}
  ${highlightedStyle}
  overflow: clip;
`;

export const Group = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin-top: ${LEAF_NODES_MARGIN}px;
  padding: ${LEAF_NODES_MARGIN}px;
  & .container-name {
    color: ${colors.grey25};
    flex: 1;
  }
`;

export const Leaf = styled.div<CollapsibleNodeProps>`
  border-color: hsla(0, 0%, 0%, 0.5);
  box-sizing: border-box;
  display: flex;
  margin: ${LEAF_NODES_MARGIN}px;
  padding: ${LEAF_NODES_MARGIN}px;
  min-width: ${LEAF_NODES_MIN_WIDTH}px;
  ${containsCollapsedNodesStyle}
  ${highlightedStyle}
  & .leaf-name {
    color: ${colors.grey25};
    flex: 1;
    margin-right: ${LEAF_NODES_MARGIN}px;
  }
`;
export const ReferencedLeaf = styled.div<{
  $isCollapsedReference?: boolean;
  $isHighlighted?: boolean;
}>`
  border: 1px solid hsla(0, 0%, 0%, 0.5);
  box-sizing: border-box;
  margin: ${LEAF_NODES_MARGIN}px;
  border-radius: 16px;
  min-width: ${LEAF_NODES_MIN_WIDTH}px;
  ${highlightedStyle}
  ${({ $isCollapsedReference }) =>
    $isCollapsedReference &&
    css`
      box-shadow: ${COLLAPSED_REFERENCE_BOX_SHADOW};
      transition: box-shadow 300ms ease-in-out;
    `}

  & .referenceLabel {
    margin: ${LEAF_NODES_MARGIN}px ${LEAF_NODES_MARGIN}px 0
      ${1.5 * LEAF_NODES_MARGIN}px;
  }
`;

export const ReferencedLeafComponent = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${LEAF_NODES_MARGIN}px;
  & .leaf-name {
    flex: 1;
    color: ${colors.grey25};
    margin-right: ${LEAF_NODES_MARGIN}px;
  }
`;
