import { actionCreator } from '@ardoq/rxbeach';
import { DashboardApiError } from '@ardoq/dashboard';
import { APIDashboardAttributes } from '@ardoq/api-types';

const ACTION_PREFIX = '[usageMetricsDashboard]';
const addPrefix = (actionName: string): `[${string}] ${string}` =>
  `${ACTION_PREFIX} ${actionName}`;

export const persistMetricsDashboardData =
  actionCreator<APIDashboardAttributes>(
    addPrefix('PERSIST_METRICS_DASHBOARD_DATA')
  );

export const fetchUsageMetricsDashboardData = actionCreator(
  addPrefix('FETCH_USAGE_METRICS_DASHBOARD_DATA')
);

export const setMetricsDashboardApiError = actionCreator<DashboardApiError>(
  addPrefix('SET_DASHBOARD_API_ERROR')
);
