import styled from 'styled-components';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { Box } from '@ardoq/layout';

const ListItemWithoutBullet = styled.li`
  list-style-type: none;
`;

const SmallIcon = styled(Icon)`
  font-size: 16px;
  vertical-align: middle;
`;

const PopoverMessage = styled.p`
  color: ${colors.green35};
  font-weight: normal;
`;

interface WorkspaceNavigationPopoverContentProps {
  popoverMessage: string;
  workspaceNames: string[];
}

const WorkspaceNavigationPopoverContent = ({
  popoverMessage,
  workspaceNames,
}: WorkspaceNavigationPopoverContentProps) => (
  <Box>
    <PopoverMessage>{popoverMessage}</PopoverMessage>
    {workspaceNames.map((workspaceName, index) => (
      <ListItemWithoutBullet key={index}>
        <SmallIcon iconName={IconName.WORKSPACE} /> {workspaceName}
      </ListItemWithoutBullet>
    ))}
  </Box>
);

export default WorkspaceNavigationPopoverContent;
