import {
  ConstraintsMap,
  ConstraintSource,
  LayoutConstraintWithSource,
} from '../view/types';
import { CollapsibleGraphGroup, GraphNode } from '@ardoq/graph';
import type { Point } from '@ardoq/math';

const { MOVED_BY_DND, PERSPECTIVE } = ConstraintSource;

type UpdateConstraintValuesParams = {
  constraintsMap: ConstraintsMap;
  nodeId: string;
  cellIndex: number;
};
/** Add or overwrite constraint value for a node */
const updateConstraintValues = ({
  constraintsMap,
  nodeId,
  cellIndex,
}: UpdateConstraintValuesParams): ConstraintsMap => {
  constraintsMap.set(nodeId, [nodeId, cellIndex, cellIndex, MOVED_BY_DND]);
  return constraintsMap;
};

type GetNodeIdsByAxisParams = {
  columnConstraintsMap: ConstraintsMap;
  rowConstraintsMap: ConstraintsMap;
  rowIndex: number;
  nodeParentId: string;
  getParentIdByNodeId: (id: string) => string;
};
/** get node ids in the same row as the target cell */
const getNodeIdsByRow = ({
  columnConstraintsMap,
  rowConstraintsMap,
  rowIndex,
  nodeParentId,
  getParentIdByNodeId,
}: GetNodeIdsByAxisParams) => {
  const nodeIds = new Set<string>();
  for (const nodeId of columnConstraintsMap.keys()) {
    if (
      rowConstraintsMap.get(nodeId)?.[2] === rowIndex &&
      getParentIdByNodeId(nodeId) === nodeParentId
    ) {
      nodeIds.add(nodeId);
    }
  }
  return nodeIds;
};

/** Get target cell index (X or Y axis) after dragging a node */
const getCellIndex = (cellSizesList: number[], position: number): number => {
  let sum = 0;

  const index = cellSizesList.findIndex(cellSize => {
    sum = sum + cellSize;

    return position < sum;
  });

  return index === -1 ? cellSizesList.length : index;
};

type IsCellEmptyParams = {
  targetCellRowIndex: number;
  targetCellColumnIndex: number;
  columnConstraintsMap: ConstraintsMap;
  rowConstraintsMap: ConstraintsMap;
  nodeParentId?: string;
  getParentIdByNodeId: (id: string) => string;
};
const isCellEmpty = ({
  targetCellRowIndex,
  targetCellColumnIndex,
  columnConstraintsMap,
  rowConstraintsMap,
  nodeParentId,
  getParentIdByNodeId,
}: IsCellEmptyParams) => {
  const constraintsForTargetColumn = [...columnConstraintsMap.values()].filter(
    ([columnConstraintNodeId, columnIndex]) => {
      const targetCellNodeParentId = getParentIdByNodeId(
        columnConstraintNodeId
      );

      return (
        columnIndex === targetCellColumnIndex &&
        targetCellNodeParentId === nodeParentId
      );
    }
  );

  const constrainsForTargetCell =
    constraintsForTargetColumn.length &&
    [...rowConstraintsMap.values()].find(
      ([rowConstraintNodeId, rowIndex]) =>
        rowIndex === targetCellRowIndex &&
        constraintsForTargetColumn.find(
          ([columnConstraintNodeId]) =>
            columnConstraintNodeId === rowConstraintNodeId
        )
    );

  return !constrainsForTargetCell;
};

type MoveNodesInRowParams = {
  constraintsMap: ConstraintsMap;
  dropPosition: number;
  dragStartPosition: number;
  nodesInLine: Set<string>;
};
/** Update node constraints in the row after node drop */
const moveNodesInRow = ({
  constraintsMap,
  dropPosition,
  dragStartPosition,
  nodesInLine,
}: MoveNodesInRowParams) => {
  // get direction of the drag
  const positionsDelta = dropPosition - dragStartPosition;

  constraintsMap.forEach(([id, _, constraintValue, source]) => {
    if (!nodesInLine.has(id)) {
      return;
    }

    let newValue = constraintValue;

    // if the node moved to the left,
    // increase constraint value for nodes that are between the drop position and the initial position
    if (
      positionsDelta < 0 &&
      constraintValue >= dropPosition &&
      constraintValue < dragStartPosition
    ) {
      newValue++;
    }

    // if the node moved to the right,
    // decrease constraint value for nodes that are between the drop position and the initial position
    if (
      positionsDelta > 0 &&
      constraintValue <= dropPosition &&
      constraintValue > dragStartPosition
    ) {
      newValue--;
    }

    if (newValue !== constraintValue) {
      constraintsMap.set(id, [id, newValue, newValue, source]);
    }
  });

  return constraintsMap;
};

type GetUpdatedConstraintsParams = {
  columnWidths: number[];
  rowHeights: number[];
  nodeDropLocationX: number;
  nodeDropLocationY: number;
  nodeId: string;
  nodesById: Map<string, GraphNode>;
  groupsById: Map<string, CollapsibleGraphGroup>;
  columnConstraintsMap: ConstraintsMap;
  rowConstraintsMap: ConstraintsMap;
};
const getUpdatedConstraintsMap = ({
  columnWidths,
  rowHeights,
  nodeDropLocationX,
  nodeDropLocationY,
  nodeId,
  columnConstraintsMap,
  rowConstraintsMap,
  nodesById,
  groupsById,
}: GetUpdatedConstraintsParams): {
  newColumnConstraintsMap: ConstraintsMap;
  newRowConstraintsMap: ConstraintsMap;
} => {
  const getParentIdByNodeId = (id: string) => {
    const parent = nodesById.get(id)?.parent || groupsById.get(id)?.parent;
    return parent?.id || '';
  };
  const nodeParentId = getParentIdByNodeId(nodeId);

  const targetCellColumnIndex = getCellIndex(columnWidths, nodeDropLocationX);
  const targetCellRowIndex = getCellIndex(rowHeights, nodeDropLocationY);

  const isSameCell =
    targetCellColumnIndex === columnConstraintsMap.get(nodeId)?.[1] &&
    targetCellRowIndex === rowConstraintsMap.get(nodeId)?.[1];

  if (isSameCell) {
    return {
      newColumnConstraintsMap: columnConstraintsMap,
      newRowConstraintsMap: rowConstraintsMap,
    };
  }

  if (
    isCellEmpty({
      rowConstraintsMap,
      columnConstraintsMap,
      targetCellRowIndex,
      targetCellColumnIndex,
      nodeParentId,
      getParentIdByNodeId,
    })
  ) {
    return {
      newColumnConstraintsMap: updateConstraintValues({
        nodeId,
        constraintsMap: columnConstraintsMap,
        cellIndex: targetCellColumnIndex,
      }),
      newRowConstraintsMap: updateConstraintValues({
        nodeId,
        constraintsMap: rowConstraintsMap,
        cellIndex: targetCellRowIndex,
      }),
    };
  }

  const rowNotChanged =
    rowConstraintsMap.get(nodeId)?.[1] === targetCellRowIndex;

  // map of node ids and their coordinates
  const nodeCoordinatesMap = new Map<string, Point>();
  columnConstraintsMap.forEach((value, key) => {
    const y = value[1];
    const x = rowConstraintsMap.get(key)?.[1];

    if (x !== undefined) {
      nodeCoordinatesMap.set(key, [x, y]);
    }
  });

  const updateConstraintsMap = (axis: 'x' | 'y'): ConstraintsMap => {
    const constraintsMap =
      axis === 'x' ? columnConstraintsMap : rowConstraintsMap;
    const cellIndex = axis === 'x' ? targetCellColumnIndex : targetCellRowIndex;

    if (axis === 'x' && rowNotChanged) {
      const nodesInLine = getNodeIdsByRow({
        columnConstraintsMap,
        rowConstraintsMap,
        nodeParentId,
        getParentIdByNodeId,
        rowIndex: targetCellRowIndex,
      });

      return updateConstraintValues({
        constraintsMap: moveNodesInRow({
          nodesInLine,
          constraintsMap,
          dropPosition: cellIndex,
          dragStartPosition: constraintsMap.get(nodeId)![1],
        }),
        nodeId,
        cellIndex,
      });
    }

    if (axis === 'x' && !rowNotChanged) {
      const nodesInLine = getNodeIdsByRow({
        columnConstraintsMap,
        rowConstraintsMap,
        rowIndex: targetCellRowIndex,
        nodeParentId,
        getParentIdByNodeId,
      });
      return updateConstraintValues({
        constraintsMap: moveNodesInRow({
          nodesInLine,
          constraintsMap,
          dropPosition: cellIndex,
          dragStartPosition: Infinity,
        }),
        nodeId,
        cellIndex,
      });
    }

    return updateConstraintValues({
      constraintsMap,
      nodeId,
      cellIndex,
    });
  };

  return {
    newColumnConstraintsMap: updateConstraintsMap('x'),
    newRowConstraintsMap: updateConstraintsMap('y'),
  };
};

/* Filter out perspective constraints that we don't want to save in viewSettings.
 * Converts Map to LayoutConstraintWithSource[] for viewSettings */
const getConstraintsForViewSettings = (constraintsMap: ConstraintsMap) =>
  [...constraintsMap.values()].reduce<LayoutConstraintWithSource[]>(
    (acc, [id, value, _, source]) => {
      if (source === PERSPECTIVE) {
        return acc;
      }

      acc.push([id, value, source] as LayoutConstraintWithSource);
      return acc;
    },
    []
  );

export const getUpdatedConstraints = (params: GetUpdatedConstraintsParams) => {
  const { newColumnConstraintsMap, newRowConstraintsMap } =
    getUpdatedConstraintsMap(params);

  return {
    newColumnConstraints: getConstraintsForViewSettings(
      newColumnConstraintsMap
    ),
    newRowConstraints: getConstraintsForViewSettings(newRowConstraintsMap),
  };
};
