import { componentInterface } from '@ardoq/component-interface';
import {
  CollapsibleGraphGroup,
  GROUP_HEADER_FONT_HEIGHT,
  GraphGroup,
  estimateCountLabelWidth,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
} from '@ardoq/graph';
import {
  BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
  GROUP_PADDING,
  HEADER_ICON_PADDING,
  HEADER_ICON_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
  PREFERRED_MINIMUM_GROUP_WIDTH,
  SUBHEADER_FONT_HEIGHT,
} from './consts';
import { URL_ICON_MARGIN, URL_ICON_SIZE } from 'yfilesExtensions/styles/consts';
import { Font, FontStyle, FontWeight, INode } from '@ardoq/yfiles';
import { createFifoCache } from '@ardoq/common-helpers';
import { RepresentationData } from '@ardoq/data-model';
import { MeasureStyledSvgText } from '@ardoq/dom-utils';
import { MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT } from '@ardoq/graph';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import {
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN,
  MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH,
} from './yFilesExtensions/modernized/consts';

const getRepresentationDataWidth = (
  representationData: RepresentationData | null | boolean
) => (representationData ? HEADER_ICON_SIZE + HEADER_ICON_PADDING : 0);

export const folderStyleGroupLabelStartXModernized = (group: GraphGroup) => {
  const representationData =
    group.isComponent() &&
    componentInterface.getRepresentationData(group.modelId);
  return (
    MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X +
    (representationData
      ? MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT +
        MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN
      : 0)
  );
};
export const folderStyleGroupLabelStartX = (group: GraphGroup) => {
  const representationData =
    group.isComponent() &&
    componentInterface.getRepresentationData(group.modelId);
  return (
    BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X +
    getRepresentationDataWidth(representationData)
  );
};
/**
 * gets the total horizontal padding necessary around the label, including space for the expander, representation, left padding and right padding.
 *
 * this amounts to the difference between the maximum label width and the group width.
 */
export const getGroupHeaderWidthWithoutLabelModernized = (
  group: CollapsibleGraphGroup
) => {
  const urlIconWidth = group.hasURLFields()
    ? URL_ICON_SIZE + URL_ICON_MARGIN
    : 0;
  return (
    folderStyleGroupLabelStartXModernized(group) +
    MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN +
    urlIconWidth
  );
};
export const getGroupHeaderWidthWithoutLabel = (
  group: CollapsibleGraphGroup
) => {
  const urlIconsSpace =
    group.isComponent() || group.isReference()
      ? URL_ICON_SIZE + URL_ICON_MARGIN * 2
      : 0;
  const countLabelWidth = group.collapsed
    ? estimateCountLabelWidth(group.descendantCount)
    : 0;

  return (
    folderStyleGroupLabelStartX(group) +
    urlIconsSpace +
    countLabelWidth +
    GROUP_PADDING * 2
  );
};

export const getGroupLabelMaxWidth = (groupNode: INode | null) => {
  if (!groupNode) {
    return 0;
  }
  const { tag: group, layout } = groupNode;

  return (
    Math.max(layout.width, PREFERRED_MINIMUM_GROUP_WIDTH) -
    getGroupHeaderWidthWithoutLabel(group)
  );
};
export const getGroupLabelMaxWidthModernized = (groupNode: INode | null) => {
  if (!groupNode) {
    return 0;
  }
  const {
    tag: group,
    layout: { width: groupLayoutWidth },
  } = groupNode;

  return (
    Math.max(
      groupLayoutWidth,
      MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH
    ) - getGroupHeaderWidthWithoutLabelModernized(group)
  );
};

export const measureGroupLabel = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${GROUP_HEADER_FONT_HEIGHT}px`,
    })
);
export const measureGroupLabelModernized = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_FONT_HEIGHT}px`,
    })
);

export const measureGroupSubLabel = createFifoCache(5000, (text: string) =>
  MeasureStyledSvgText.Instance.getTextWidth({
    text,
    fontSize: `${SUBHEADER_FONT_HEIGHT}px`,
  })
);

export const getElementOpacityIfTransparentized = (
  isTransparentized: boolean,
  opacity = 0.32
) => (!isTransparentized ? 1 : opacity);

export const GROUP_SUBLABEL_FONT = new Font({
  fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
  fontSize: 14,
  fontWeight: FontWeight.ITEM400,
  fontStyle: FontStyle.ITALIC,
});
