import {
  GeneralPath,
  INode,
  IRenderContext,
  ITable,
  NodeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import ArdoqSwimlaneStripeStyle from './ArdoqSwimlaneStripeStyle';
import { createSvgElement } from '@ardoq/dom-utils';

interface GetBoundsArgs {
  node: INode;
  table: ITable;
  isVertical: boolean;
}
const getBounds = ({
  node: {
    layout: { x, y, width, height },
  },
  table: {
    accumulatedInsets: { left: leftInset, top: topInset },
  },
  isVertical,
}: GetBoundsArgs) => ({
  width: isVertical ? width - leftInset : width,
  height: isVertical ? height : height - topInset,
  left: isVertical ? x + leftInset : x,
  top: isVertical ? y : y + topInset,
});

const isTableVertical = (table: ITable) =>
  table.columnDefaults.style instanceof ArdoqSwimlaneStripeStyle;

interface RectLike {
  width: number;
  height: number;
  left: number;
  top: number;
}

type TableBackgroundContainerElement = SVGGElement & {
  cache?: RectLike;
};

export default class ArdoqTableBackgroundStyle extends NodeStyleBase {
  override createVisual(context: IRenderContext, node: INode) {
    const table = node.lookup(ITable.$class) as ITable;
    if (table === null) {
      return null;
    }

    const isVertical = isTableVertical(table);
    const tableBounds = getBounds({ node, table, isVertical });
    const {
      width: tableBackgroundWidth,
      height: tableBackgroundHeight,
      left: tableBackgroundLeft,
      top: tableBackgroundTop,
    } = tableBounds;
    const g: TableBackgroundContainerElement = createSvgElement('g');

    const createPath = isVertical ? createVerticalPath : createHorizontalPath;

    const pathElement = createPath(
      tableBackgroundWidth,
      tableBackgroundHeight
    ).createSvgPath();
    pathElement.setAttribute('class', 'table-background');

    g.appendChild(pathElement);

    g.setAttribute(
      'transform',
      `translate(${tableBackgroundLeft} ${tableBackgroundTop})`
    );
    (g as TableBackgroundContainerElement).cache = tableBounds;
    return new SvgVisual(g);
  }

  updateVisual(context: IRenderContext, oldVisual: Visual, node: INode) {
    const g = (oldVisual as SvgVisual)
      .svgElement as TableBackgroundContainerElement;
    if (!(g instanceof window.SVGElement) || g.childElementCount === 0) {
      return this.createVisual(context, node);
    }
    const cache = g.cache;
    const table = node.lookup(ITable.$class) as ITable;
    if (table === undefined || table === null || !cache) {
      return this.createVisual(context, node);
    }
    const bounds =
      table && getBounds({ node, table, isVertical: isTableVertical(table) });
    if (bounds.width !== cache?.width || bounds.height !== cache?.height) {
      return this.createVisual(context, node);
    }
    // only x and y have changed, so we'll just change the translate.
    g.cache = bounds;
    SvgVisual.setTranslate(g, bounds.left, bounds.top);
    return oldVisual;
  }
}

const createHorizontalPath = (width: number, height: number) => {
  const path = new GeneralPath();
  path.moveTo(width, 0);
  path.lineTo(0, 0);
  path.lineTo(0, height);
  path.lineTo(width, height);
  return path;
};

const createVerticalPath = (width: number, height: number) => {
  const path = new GeneralPath();
  path.moveTo(0, height);
  path.lineTo(0, 0);
  path.lineTo(width, 0);
  path.lineTo(width, height);
  return path;
};
