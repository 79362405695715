import { RegisteredReducer, reducer } from '@ardoq/rxbeach';
import { loadStoredQuery, resetAllSearchState } from 'search/actions';
import {
  advancedSearchError,
  exportAdvancedSearch,
  exportAdvancedSearchSuccess,
  queryAdvancedSearch,
  queryAdvancedSearchSuccess,
  selectCondition,
  selectFields,
  selectFilterType,
  selectPage,
  setAdvancedSearchBranch,
  updateAdvancedSearch,
} from './actions';
import advancedSearchOperations from './advancedSearchOperations';
import { BranchedAdvancedSearchStateShape } from './types';

export const reducers: RegisteredReducer<BranchedAdvancedSearchStateShape>[] = [
  reducer(
    updateAdvancedSearch,
    advancedSearchOperations.updateAdvancedSearchReducer
  ),
  reducer(selectCondition, advancedSearchOperations.selectConditionReducer),
  reducer(selectFilterType, advancedSearchOperations.selectFilterTypeReducer),
  reducer(
    queryAdvancedSearchSuccess,
    advancedSearchOperations.queryAdvancedSearchSuccessReducer
  ),
  reducer(
    advancedSearchError,
    advancedSearchOperations.advancedSearchErrorReducer
  ),
  reducer(
    queryAdvancedSearch,
    advancedSearchOperations.queryAdvancedSearchReducer
  ),
  reducer(
    resetAllSearchState,
    advancedSearchOperations.resetAllBranchedSearchStateReducer
  ),
  reducer(selectPage, advancedSearchOperations.selectPageReducer),
  reducer(selectFields, advancedSearchOperations.selectFieldsReducer),
  reducer(
    exportAdvancedSearch,
    advancedSearchOperations.exportAdvancedSearchReducer
  ),
  reducer(
    exportAdvancedSearchSuccess,
    advancedSearchOperations.exportAdvancedSearchSuccessReducer
  ),
  reducer(loadStoredQuery, advancedSearchOperations.loadStoredQueryReducer),
  reducer(setAdvancedSearchBranch, advancedSearchOperations.setBranchReducer),
];
