import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import ComponentHeader from 'tabview/pagesView/ComponentHeader';
import styled from 'styled-components';
import { componentInterface } from 'modelInterface/components/componentInterface';
import ReferencePage from 'tabview/pagesView/ReferencePage';
import {
  CONTENT_MAX_WIDTH,
  VERTICAL_LINE_WIDTH,
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import Description from 'tabview/pagesView/Description';
import Fields from 'tabview/pagesView/Fields';
import VerticalLine from 'tabview/pagesView/VerticalLine';
import EditDescriptionButton from 'tabview/pagesView/EditDescriptionButton';
import { colors, spacing } from '@ardoq/design-tokens';

const LEFT_SPACING = `${
  (parseInt(horizontalSpacing.LARGE, 10) - parseInt(VERTICAL_LINE_WIDTH, 10)) /
  2
}px`;

/* Chrome does not have a problem with content fragmentation when dealing with block elements.
Multicolumn flex layout does not generate boxes in a way that allows clean page breaks in all
browsers as of today  */

const InnerLayout = styled.div`
  width: 100%;
  padding: 0 ${horizontalSpacing.MEDIUM} 0 ${LEFT_SPACING};
`;
const OuterLayout = styled.div`
  margin: ${verticalSpacing.LARGE} 0 ${verticalSpacing.LARGE} ${LEFT_SPACING};
  position: relative;
`;

const DescriptionContainer = styled.div`
  display: flex;
  max-width: ${CONTENT_MAX_WIDTH};
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  margin: ${spacing.s16} 0;
`;

const ComponentHeaderAndDescriptionContainer = styled.div`
  /* avoid separation of header from the description */
  break-inside: avoid-page;
  page-break-inside: avoid;
`;

interface ComponentPageProps {
  bypassRenderLimit: boolean;
  expandDescription: boolean;
  componentId: ArdoqId;
  defaultFields: Pick<APIFieldAttributes, 'name' | 'type' | 'label' | '_id'>[];
  fields: APIFieldAttributes[];
  references: {
    incoming: ArdoqId[];
    outgoing: ArdoqId[];
  };
  hideEmptyFields: boolean;
  isLocalDateFormat: boolean;
  isViewpointMode: boolean;
  canEditDescription: boolean;
}

const ComponentPage = ({
  bypassRenderLimit,
  componentId,
  fields,
  defaultFields,
  references,
  hideEmptyFields,
  expandDescription,
  isLocalDateFormat,
  isViewpointMode,
  canEditDescription,
}: ComponentPageProps) => {
  return (
    <>
      <OuterLayout id={componentId} className="pages-view-component-page">
        <VerticalLine componentId={componentId} />
        <InnerLayout data-component-id={componentId}>
          <ComponentHeaderAndDescriptionContainer>
            <ComponentHeader
              componentId={componentId}
              bypassRenderLimit={bypassRenderLimit}
              isViewpointMode={isViewpointMode}
            />
            <DescriptionContainer>
              <Description
                text={componentInterface.getDescription(componentId) ?? ''}
                bypassRenderLimit={bypassRenderLimit}
                expandDescription={expandDescription}
              />
              <EditDescriptionButton
                modelId={componentId}
                descriptionIsEmpty={
                  !componentInterface.getDescription(componentId)
                }
                canEditDescription={canEditDescription}
              />
            </DescriptionContainer>
          </ComponentHeaderAndDescriptionContainer>
          <Fields
            modelId={componentId}
            defaultFields={defaultFields}
            fields={fields}
            showHeader={true}
            hideEmptyFields={hideEmptyFields}
            isLocalDateFormat={isLocalDateFormat}
          />

          <ReferencePage
            references={references}
            bypassRenderLimit={bypassRenderLimit}
            expandDescription={expandDescription}
            defaultFields={defaultFields}
            hideEmptyFields={hideEmptyFields}
          />
        </InnerLayout>
      </OuterLayout>
      <Divider />
    </>
  );
};

export default ComponentPage;
