import {
  action$,
  reducedStream,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { returnPayload, returnFalse, returnTrue } from '@ardoq/common-helpers';
import { showDetailsDrawer, hideDetailsDrawer } from './actions';

const isOpen$ = action$.pipe(
  reduceState('isOpen', false, [
    reducer(showDetailsDrawer, returnTrue),
    reducer(hideDetailsDrawer, returnFalse),
  ]),
  debounceTime(32),
  distinctUntilChanged()
);

export const isDetailsDrawerOpen$ = reducedStream<boolean>(
  'isDetailsDrawerOpen$',
  false,
  [streamReducer(isOpen$, returnPayload)]
);
