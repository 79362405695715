import { actionCreator } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';

interface PayloadZoom {
  readonly viewId: ViewIds;
  readonly duration?: number;
}

export const zoomIn = actionCreator<PayloadZoom>('[Zoomable] ZOOM_IN');
export const zoomOut = actionCreator<PayloadZoom>('[Zoomable] ZOOM_OUT');
export const zoomCenter = actionCreator<PayloadZoom>('[Zoomable] ZOOM_CENTER');
