import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoNumericFieldsDoqProps = { doqType: DoqType };

const NoNumericFieldsDoq = ({ doqType }: NoNumericFieldsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No number fields to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            This workspace doesn’t have any number fields.
          </FlexBox>
          <FlexBox gap="medium">
            To use this view, add a number field to your components.
          </FlexBox>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoNumericFieldsDoq;
