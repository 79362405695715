import { partition } from 'lodash';
import type { RelationshipDiagramViewModel } from '@ardoq/graph';

type NewAndPrevious = [newThing: string[], oldThing: Set<string>];

const processUpdates = (
  previousViewModel: RelationshipDiagramViewModel,
  newViewModel: RelationshipDiagramViewModel
) => {
  const [
    [previousNodes, previousGroups, previousEdges],
    [newNodes, newGroups, newEdges],
  ] = [previousViewModel, newViewModel].map(({ nodes, groups, edges }) =>
    [nodes, groups, edges].map(items => [...items.add, ...items.update])
  );
  const [previousNodesSet, previousGroupsSet, previousEdgesSet] = [
    previousNodes,
    previousGroups,
    previousEdges,
  ].map(items => new Set(items));

  const newAndPreviousNodes: NewAndPrevious = [newNodes, previousNodesSet];
  const newAndPreviousGroups: NewAndPrevious = [newGroups, previousGroupsSet];
  const newAndPreviousEdges: NewAndPrevious = [newEdges, previousEdgesSet];

  const [
    [updatedNodes, addedNodes],
    [updatedGroups, addedGroups],
    [updatedEdges, addedEdges],
  ] = [newAndPreviousNodes, newAndPreviousGroups, newAndPreviousEdges].map(
    ([newItems, previousItems]) =>
      partition(newItems, item => previousItems.has(item))
  );
  const result: RelationshipDiagramViewModel = {
    ...newViewModel,
    nodes: {
      add: addedNodes,
      update: updatedNodes,
      remove: newViewModel.nodes.remove,
      byId: newViewModel.nodes.byId,
    },
    groups: {
      add: addedGroups,
      update: updatedGroups,
      remove: newViewModel.groups.remove,
      byId: newViewModel.groups.byId,
    },
    edges: {
      add: addedEdges,
      update: updatedEdges,
      remove: newViewModel.edges.remove,
      byId: newViewModel.edges.byId,
    },
  };
  return result;
};

export default processUpdates;
