import { BaseClass, INode, INodeInsetsProvider, Insets } from '@ardoq/yfiles';
import {
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE,
} from './consts';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { getOtherLabelsHeight, getOtherLabelSizes } from '../labels/labelUtils';

/**
 * the left group inset.
 *
 * this is enough space to leave a column on the left side of the group, as wide as the group expander click region.
 *
 * this prevents nodes from being placed along the left side of groups.
 *
 * in turn, this prevents edges from routing through this space and blocking the expander button.
 */
const LEFT_INSET = 2 * MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE;

export default class GroupInsetsProvider extends BaseClass(
  INodeInsetsProvider
) {
  static Instance = new GroupInsetsProvider();
  private constructor() {
    super();
  }
  override getInsets({ tag }: INode): Insets {
    const graphNode: CollapsibleGraphGroup | null = tag;
    const itemLabels = graphNode?.getItemLabels();
    if (!itemLabels) {
      return new Insets(
        LEFT_INSET,
        MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT +
          MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
      );
    }
    const { subLabel, otherLabels } = itemLabels;
    const subLabelHeight = subLabel
      ? MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT +
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
      : 0;
    if (!otherLabels?.length) {
      return new Insets(
        LEFT_INSET,
        MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT +
          subLabelHeight +
          MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
      );
    }
    const otherLabelSizes = getOtherLabelSizes(otherLabels, true);
    const otherLabelsHeight = getOtherLabelsHeight(otherLabelSizes);
    return new Insets(
      LEFT_INSET,
      MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT +
        subLabelHeight +
        otherLabelsHeight +
        MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
      MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
    );
  }
}
