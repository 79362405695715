import { ArdoqId } from '@ardoq/api-types';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { NormalComponentPath } from 'tabview/pagesView/ComponentPath';
import styled from 'styled-components';
import { horizontalSpacing } from 'tabview/pagesView/constants';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { pagesContextMenuName } from './contextMenu';

interface ReferencePopoverProps {
  componentId: ArdoqId;
  referenceId: ArdoqId;
  isSource: boolean;
}

const PathWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TextWrapper = styled.div`
  margin-right: ${horizontalSpacing.SMALL};
`;

const ReferencePopoverContentWrapper = styled.div`
  max-width: 448px;
`;

const DisplayText = ({
  referenceId,
}: Pick<ReferencePopoverProps, 'referenceId'>) => {
  const displayText = referenceInterface.getDisplayText(referenceId);
  return displayText && displayText.length ? (
    <>
      <br /> Display Text: {displayText}
    </>
  ) : null;
};

const REFERENCE_POPOVER_ID = 'pages-view-reference-popover';
const referencePopoverRender = (target: Element | null) => {
  if (!(target instanceof HTMLElement)) return null;
  const { componentId, referenceId, origin } = target.dataset;
  if (!(componentId && referenceId && origin)) {
    return null;
  }
  return (
    <ReferencePopover
      componentId={componentId}
      referenceId={referenceId}
      isSource={origin === 'source'}
    />
  );
};
popoverRegistry.set(REFERENCE_POPOVER_ID, referencePopoverRender);
const ReferencePopover = ({
  componentId,
  referenceId,
  isSource,
}: ReferencePopoverProps) => (
  <ReferencePopoverContentWrapper data-context-menu={pagesContextMenuName}>
    <PathWrapper>
      <TextWrapper>Reference {isSource ? 'from: ' : 'to: '}</TextWrapper>
      <NormalComponentPath componentId={componentId} />
    </PathWrapper>
    <DisplayText referenceId={referenceId} />
  </ReferencePopoverContentWrapper>
);

export const withReferencePopover = ({
  componentId,
  referenceId,
  isSource,
}: ReferencePopoverProps) => ({
  [POPOVER_ID_ATTR]: REFERENCE_POPOVER_ID,
  [COMPONENT_ID_ATTRIBUTE]: componentId,
  'data-reference-id': referenceId,
  'data-origin': isSource ? 'source' : 'target',
});
