import { ActionStream, ObservableState, ofType } from '@ardoq/rxbeach';
import { Node } from 'graph/types';
import { GraphItem } from 'graph/GraphItem';
import { ArdoqId } from '@ardoq/api-types';
import type { Edge } from 'graph/types';
import { ComponentBackboneModel } from 'aqTypes';
import type { ContextShape } from '@ardoq/data-model';
import { aggregatedGraphInstance } from 'graph/aggregatedGraph';
import {
  CircularRelationshipDiagramViewModel,
  CircularRelationshipDiagramViewSettings,
} from './types';
import { Observable, combineLatest } from 'rxjs';
import { startAction } from 'actions/utils';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
  notifyReferencesUpdated,
} from 'streams/references/ReferenceActions';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { debounceTime, map, scan, startWith } from 'rxjs/operators';
import { ChildGraphInclusionPolicy } from 'graph/ChildGraphInclusionPolicy';
import * as graphFunctions from 'graph/graphFunctions';
import { AggregatedGraphCommonArgs, AggregatedGraphResult } from 'graph/types';
import defaultState from 'views/defaultState';
import { ViewIds } from '@ardoq/api-types';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { BooleanOrAuto } from 'tabview/types';
import { getFilteredViewSettings$ } from 'views/filteredViewSettings$';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import Components from 'collections/components';

const ENABLE_STYLES_AUTO_THRESHOLD = 500;
type ViewModelShape = CircularRelationshipDiagramViewModel & {
  viewSettings: CircularRelationshipDiagramViewSettings;
};
const emptyState: ViewModelShape = {
  groups: {
    byId: new Map<ArdoqId, Node>(),
    add: [],
    update: [],
    remove: [],
  },
  nodes: {
    byId: new Map<ArdoqId, Node>(),
    add: [],
    update: [],
    remove: [],
  },
  edges: {
    byId: new Map<ArdoqId, Edge>(),
    add: [],
    update: [],
    remove: [],
  },
  stylesEnabled: false,
  viewSettings: defaultState.get(
    ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM
  ) as CircularRelationshipDiagramViewSettings,
};
const underThreshold = (
  viewModel: CircularRelationshipDiagramViewModel
): boolean => {
  const totalItemsInGraph =
    viewModel.nodes.add.length +
    viewModel.nodes.update.length -
    viewModel.nodes.remove.length +
    viewModel.edges.add.length +
    viewModel.edges.update.length -
    viewModel.edges.remove.length;
  return totalItemsInGraph < ENABLE_STYLES_AUTO_THRESHOLD;
};

const diff = (currentIds: Set<string>, nextIds: Set<string>) => {
  return {
    add: Array.from(nextIds).filter(id => !currentIds.has(id)),
    remove: Array.from(currentIds).filter(id => !nextIds.has(id)),
    update: Array.from(currentIds).filter(id => nextIds.has(id)),
  };
};
interface BuildGraphResult {
  groups: Node[];
  edges: Edge[];
  nodes: Node[];
}
const buildGraph = (
  graph: AggregatedGraphResult,
  contextComponent: ComponentBackboneModel | null,
  nodes: Node[],
  includeChildren: boolean
): BuildGraphResult => {
  const { children, groups } = graphFunctions.getChildrenAndGroups(
    contextComponent,
    nodes,
    graph.edges,
    includeChildren
  );
  // filter out edges connected to group nodes.  group nodes have no visual representation in explorer view.
  const edges = graph.edges.filter(
    edge =>
      edge.sourceNode.children.size() === 0 &&
      edge.targetNode.children.size() === 0
  );

  return {
    groups,
    nodes: children,
    edges,
  };
};
const keys = (m: Map<string, GraphItem>) => new Set(m.keys());
const toViewModel = <TItem extends GraphItem>(
  current: GraphItemsModel<TItem>,
  next: TItem[]
): GraphItemsModel<TItem> => {
  const currentIds = keys(current.byId);
  const byId = new Map<ArdoqId, TItem>(
    next.map<[ArdoqId, TItem]>(item => [dataModelId(item), item])
  );

  return {
    byId,
    ...diff(currentIds, new Set(next.map(n => dataModelId(n)))),
  };
};
const graphDiff = (
  state: CircularRelationshipDiagramViewModel,
  { groups, nodes, edges }: BuildGraphResult
): Partial<CircularRelationshipDiagramViewModel> => {
  return {
    nodes: toViewModel(state.nodes, nodes),
    edges: toViewModel(state.edges, edges),
    groups: toViewModel(state.groups, groups),
  };
};
interface ChangeContextArgs {
  context: ContextShape;
  viewSettings: CircularRelationshipDiagramViewSettings;
}

const changeComponent = (
  state: CircularRelationshipDiagramViewModel,
  { context, viewSettings }: ChangeContextArgs
) => {
  const { componentId } = context;
  const component = Components.collection.get(componentId)!;
  const graph = aggregatedGraphInstance.getGraphByComponent({
    ...toGraphParams(viewSettings),
    component,
  });
  const nodes = graph.rootNodes.toArray();
  return {
    ...state,
    viewSettings,
    ...graphDiff(
      state,
      buildGraph(graph, component, nodes, viewSettings.includeChildren)
    ),
  };
};

const changeWorkspace = (
  state: CircularRelationshipDiagramViewModel,
  { context, viewSettings }: ChangeContextArgs
) => {
  const { componentId, workspaceId } = context;
  const component = Components.collection.get(componentId) ?? null;
  const graph = aggregatedGraphInstance.getGraphByWorkspace({
    ...toGraphParams(viewSettings),
    workspaceId,
  });
  const nodes = graph.rootNodes.flatten();
  return {
    ...state,
    viewSettings,
    ...graphDiff(
      state,
      buildGraph(graph, component, nodes, viewSettings.includeChildren)
    ),
  };
};
const toGraphParams = (args: {
  incomingDegreesOfRelationship: number;
  outgoingDegreesOfRelationship: number;
  includeChildren: boolean;
}): AggregatedGraphCommonArgs => ({
  maxDegreesIncoming: args.incomingDegreesOfRelationship,
  maxDegreesOutgoing: args.outgoingDegreesOfRelationship,
  includeChildGraphs: args.includeChildren
    ? ChildGraphInclusionPolicy.RECURSIVE
    : ChildGraphInclusionPolicy.NONE,
});

const reset = (
  state = emptyState,
  { context, viewSettings }: ChangeContextArgs
): ViewModelShape => {
  let result = state;
  const { componentId, workspaceId } = context;
  if (componentId) {
    result = changeComponent(state, { viewSettings, context });
  } else if (workspaceId) {
    result = changeWorkspace(state, { viewSettings, context });
  }
  result.stylesEnabled =
    viewSettings.enableStyles === BooleanOrAuto.Auto
      ? underThreshold(result)
      : viewSettings.enableStyles === BooleanOrAuto.True;
  return result;
};
export const getViewModel$ =
  (
    action$: ActionStream,
    viewState$: Observable<
      ViewSettings<CircularRelationshipDiagramViewSettings>
    >,
    context$: ObservableState<ContextShape>
  ) =>
  (viewInstanceId: string) => {
    const myAction$ = action$.pipe(
      ofType(
        notifyComponentsUpdated,
        notifyComponentsAdded,
        notifyComponentsRemoved,
        notifyReferencesUpdated,
        notifyReferencesAdded,
        notifyReferencesRemoved,
        notifyFiltersChanged
      ),
      startWith(startAction())
    );
    return combineLatest([
      myAction$,
      context$,
      getFilteredViewSettings$<CircularRelationshipDiagramViewSettings>(
        viewState$
      ),
    ]).pipe(
      debounceTime(100),
      map(([action, context, viewSettings]) => ({
        action,
        context,
        viewSettings: viewSettings.currentSettings,
      })),
      scan(reset, emptyState),
      map(({ viewSettings, groups, edges, nodes, stylesEnabled }) => {
        return {
          viewSettings,
          viewModel: {
            groups,
            edges,
            nodes,
            stylesEnabled,
          },
          viewInstanceId,
        };
      })
    );
  };
