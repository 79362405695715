import { DoqType } from '@ardoq/doq';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoNumericFieldsDoq from 'tabview/graphViews/emptyStates/NoNumericFieldsDoq';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoNumericValuesInFieldsDoq from 'tabview/graphViews/emptyStates/NoNumericValuesInFieldsDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoFieldsSelectedDoq from 'tabview/graphViews/emptyStates/NoFieldsSelectedDoq';

type EmptyStateProps = {
  isWorkspaceEmpty: boolean;
  hasActiveFilter: boolean;
  allDescendantsIncluded: boolean;
  noNumericFields: boolean;
  hasFieldsSelected: boolean;
  isPresentationMode: boolean;
};

const EmptyState = ({
  isWorkspaceEmpty,
  hasActiveFilter,
  allDescendantsIncluded,
  noNumericFields,
  hasFieldsSelected,
  isPresentationMode,
}: EmptyStateProps) => {
  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.BUBBLE_CHART_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter || !allDescendantsIncluded) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.BUBBLE_CHART_ERROR}
        includeActions={false}
        canApplyIncludeAllDescendantsFilter
      />
    );
  }

  if (noNumericFields) {
    return <NoNumericFieldsDoq doqType={DoqType.BUBBLE_CHART_ERROR} />;
  }

  if (!hasFieldsSelected) {
    return <NoFieldsSelectedDoq doqType={DoqType.BUBBLE_CHART_ERROR} />;
  }

  if (hasFieldsSelected) {
    return <NoNumericValuesInFieldsDoq doqType={DoqType.BUBBLE_CHART_ERROR} />;
  }

  return null;
};

export default connect(EmptyState, emptyView$);
