import { forwardRef, useImperativeHandle, useState } from 'react';
import { DiffMode } from '@ardoq/api-types';
import { ZoomableViewLegendContainer } from 'tabview/relationshipDiagrams/atoms';
import { ViewLegend, ViewLegendProps } from '@ardoq/view-legend';

type RelationshipsViewLegendProps = ViewLegendProps & {
  initiallyVisible: boolean;
  heightOffset?: number;
  activeDiffMode: DiffMode | null;
};
interface RelationshipsViewLegendRef {
  setIsVisible: (isVisible: boolean) => void;
}
const RelationshipsViewLegend = forwardRef<
  RelationshipsViewLegendRef,
  RelationshipsViewLegendProps
>((props, ref) => {
  const [isVisible, setIsVisible] = useState(props.initiallyVisible);
  useImperativeHandle(ref, () => ({ setIsVisible }));
  return (
    <ZoomableViewLegendContainer
      heightOffset={props.heightOffset || 0}
      visible={isVisible}
    >
      <ViewLegend {...props} />
    </ZoomableViewLegendContainer>
  );
});
export default RelationshipsViewLegend;
