import React, {
  ComponentType,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  applySelectionDecorators,
  createGraphComponent,
  focusableItems,
  runLayout,
  selectableItems,
} from './yfilesHelper';
import { applySelectionBehavior } from './graphEvents';
import ZoomControls from 'atomicComponents/Zoomable/ZoomControls';
import {
  GraphComponent,
  GraphViewerInputMode,
  ICommand,
  Insets,
  LayoutData,
  LayoutDescriptor,
  PortAdjustmentPolicy,
  TimeSpan,
} from '@ardoq/yfiles';
import { ArdoqGraphComponentViewModel, GraphItemsModel } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dataModelId } from './graphComponentUtil';
import { FastGraphModelManager } from 'yfilesExtensions/fastGraphModelManager';
import DataLimit from 'components/DataLimit/DataLimit';
import { isPresentationMode } from 'appConfig';
import {
  ComponentTypeForLegend,
  ViewLegend,
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend as getComponentTypesForLegendBase,
} from '@ardoq/view-legend';
import { ParentChildGraphEdge, type HasViewInstanceId } from '@ardoq/graph';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { ViewIds } from '@ardoq/api-types';
import { OnLayoutGraphArgs } from './onLayoutGraph';
import fscreen from 'fscreen';
import { noop, uniqWith, isEqual } from 'lodash';
import { FillingDiv, GraphContainer } from './atoms';
import {
  ZoomContainer,
  ZoomableViewLegendContainerForwardRef,
  ZoomableViewLegendContainerForwardRefType,
} from 'tabview/relationshipDiagrams/atoms';
import { HasUrlFieldValuesById } from 'tabview/graphViews/types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import {
  RegisterUrlFieldValuesPopoverArgs,
  registerUrlFieldValuesPopover,
  unregisterUrlFieldValuesPopover,
} from 'tabview/graphViews/urlFieldValuesPopoverRegistry';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { Subscription } from 'rxjs';
import { toggleLegend } from 'presentation/viewPane/actions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { initializeDragAndDropHighlightIndicator } from 'yfilesExtensions/view/DragAndDropImageHighlightIndicatorManager';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const RENDER_LIMIT = 2000;
const BYPASS_LIMIT_FACTOR = 10;
const DEFAULT_STATE = { bypassLimit: false };

const activeModelIds = ({ add, update, byId }: GraphItemsModel) =>
  [...add, ...update]
    .map(itemId => byId.get(itemId))
    .filter(ExcludeFalsy)
    .map(dataModelId);

const metadataFromViewModel = (viewModel: ArdoqGraphComponentViewModel) => ({
  ...metadataFromGraphItemsModel(viewModel.nodes, 'nodes'),
  ...metadataFromGraphItemsModel(viewModel.edges, 'edges'),
  ...metadataFromGraphItemsModel(viewModel.groups, 'groups'),
});
const metadataFromGraphItemsModel = (
  itemsModel: GraphItemsModel,
  prefix: string
) => ({
  [`${prefix}Added`]: itemsModel.add.length,
  [`${prefix}Updated`]: itemsModel.update.length,
  [`${prefix}Removed`]: itemsModel.remove.length,
});

export const isAboveItemLimit = (nodeCount: number, edgeCount: number) =>
  !isPresentationMode() && nodeCount + edgeCount > RENDER_LIMIT;
export const canBypassLimit = (nodeCount: number, edgeCount: number) =>
  !isPresentationMode() &&
  nodeCount + edgeCount < RENDER_LIMIT * BYPASS_LIMIT_FACTOR;

interface ConfigureLayoutResult {
  layoutDescriptor: LayoutDescriptor;
  layoutData: LayoutData;
  duration?: TimeSpan;
  animateViewport?: boolean;
  updateContentRect?: boolean;
  targetBoundsInsets?: Insets;
  portAdjustmentPolicy?: PortAdjustmentPolicy;
}

type ArdoqGraphComponentProperties = HasViewInstanceId &
  Partial<HasUrlFieldValuesById> & {
    viewModel: ArdoqGraphComponentViewModel;
    enableStyles: boolean;
    isLegendActive: boolean;
    buildGraph: (
      graphComponent: GraphComponent,
      bypassLimit: boolean
    ) => {
      isAboveLimit: boolean;
      isEmpty: boolean;
      canBypass: boolean;
      hasLayoutUpdate: boolean;
    };
    configureLayout: (graphComponent: GraphComponent) => ConfigureLayoutResult;
    configureRendering: (graphComponent: GraphComponent) => void;
    onLayoutGraph: (args: OnLayoutGraphArgs) => void;
    useHoverDecorator: boolean;
    viewId: ViewIds;
    additionalZoomControls?: ReactNode;
    hideZoomControls?: boolean;
    contextMenu?: string;
    isViewpointMode?: boolean;
    displayShapesInsteadOfIcons?: boolean;
  };
export type ArdoqGraphComponentForwardRefType = {
  graphComponent: React.RefObject<GraphComponent | null>;
  graphComponentContainer: React.RefObject<HTMLDivElement>;
};
export const ArdoqGraphComponent = forwardRef<
  ArdoqGraphComponentForwardRefType,
  ArdoqGraphComponentProperties
>(
  (
    {
      useHoverDecorator,
      configureRendering,
      buildGraph,
      configureLayout,
      onLayoutGraph,
      additionalZoomControls,
      isLegendActive,
      viewInstanceId,
      viewId,
      urlFieldValuesByComponentId,
      urlFieldValuesByReferenceId,
      hideZoomControls,
      viewModel,
      contextMenu,
      isViewpointMode,
      displayShapesInsteadOfIcons = true,
    },
    ref
  ) => {
    const graphComponent = useRef<GraphComponent | null>(null);
    const graphComponentContainer = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
      graphComponent,
      graphComponentContainer,
    }));
    const popoverEntry = useRef<RegisterUrlFieldValuesPopoverArgs | null>(null);
    const unregisterDragAndDrop = useRef(noop);
    const previousLayoutDescriptor = useRef<LayoutDescriptor | null>(null);
    const toggleLegendSubscription = useRef<Subscription | null>(null);
    const zoomableViewLegendContainerForwardRef =
      useRef<ZoomableViewLegendContainerForwardRefType>(null);
    const [state, setState] = useState({ bypassLimit: false });

    useEffect(
      /** initialize graphComponent */ () => {
        if (!graphComponentContainer.current || graphComponent.current) {
          return;
        }
        graphComponent.current = createGraphComponent(
          graphComponentContainer.current,
          useHoverDecorator,
          viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM
        );

        unregisterDragAndDrop.current = initializeDragAndDropHighlightIndicator(
          graphComponent.current
        );

        configureRendering(graphComponent.current);
        applySelectionDecorators(
          graphComponent.current,
          25,
          10,
          viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM
        );
        applySelectionBehavior(graphComponent.current, {
          selectableItems,
          focusableItems,
          viewId,
          isViewpointMode: () => loadedGraph$.state.isViewpointMode,
        });

        setState(DEFAULT_STATE); // this was a call to forceUpdate in the original component. so the flow is a bit wrong here, it relies on a 2nd render to actually show the graph.

        const onFullScreenChange = () => {
          if (
            !(graphComponent.current?.inputMode instanceof GraphViewerInputMode)
          ) {
            return;
          }
          const tooltipParentElement =
            fscreen.fullscreenElement instanceof HTMLElement
              ? fscreen.fullscreenElement
              : null;
          graphComponent.current.inputMode.mouseHoverInputMode.toolTipParentElement =
            tooltipParentElement;
        };
        fscreen.addEventListener('fullscreenchange', onFullScreenChange);

        toggleLegendSubscription.current = subscribeToAction(
          toggleLegend,
          ({ legendActive }) => {
            zoomableViewLegendContainerForwardRef.current?.setIsVisible(
              legendActive
            );
          },
          viewId
        );
        return () => {
          fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
          if (popoverEntry.current) {
            unregisterUrlFieldValuesPopover(popoverEntry.current);
            popoverEntry.current = null;
          }

          unregisterDragAndDrop.current?.();

          toggleLegendSubscription.current?.unsubscribe();
        };
      },
      [
        configureRendering,
        useHoverDecorator,
        viewId,
        viewInstanceId,
        isViewpointMode,
      ]
    );

    const {
      hasLayoutUpdate: hasGraphUpdate,
      isEmpty,
      isAboveLimit,
      canBypass,
    } = graphComponent.current
      ? buildGraph(graphComponent.current, state.bypassLimit)
      : {
          hasLayoutUpdate: false,
          isEmpty: true,
          isAboveLimit: false,
          canBypass: true,
        };
    if (graphComponent.current) {
      const {
        layoutDescriptor,
        layoutData,
        duration,
        animateViewport,
        updateContentRect,
        targetBoundsInsets,
        portAdjustmentPolicy,
      } = configureLayout(graphComponent.current);
      if (
        graphComponent.current.graphModelManager instanceof
        FastGraphModelManager
      ) {
        graphComponent.current.graphModelManager.dirty = true;
      }

      graphComponent.current.invalidate();

      runLayout({
        graphComponent: graphComponent.current,
        layoutDescriptor,
        layoutData,
        onLayoutGraph,
        hasGraphUpdate,
        duration,
        previousLayoutDescriptor: previousLayoutDescriptor.current,
        animateViewport,
        updateContentRect,
        targetBoundsInsets,
        portAdjustmentPolicy,
        viewInstanceId,
        viewId,
      });
      previousLayoutDescriptor.current = layoutDescriptor;
      if (popoverEntry.current) {
        unregisterUrlFieldValuesPopover(popoverEntry.current);
      }
      const { nodes, groups, edges } = viewModel;
      popoverEntry.current = {
        urlFieldValuesByComponentId: urlFieldValuesByComponentId,
        urlFieldValuesByReferenceId: urlFieldValuesByReferenceId,
        allComponentIds: [nodes, groups]
          .map(activeModelIds)
          .flat()
          .filter(componentInterface.isComponent),
        allReferenceIds: activeModelIds(edges),
      };
      registerUrlFieldValuesPopover(popoverEntry.current);
    }

    const activeDiffMode = getActiveDiffMode();
    const isScopeDiffMode = isInScopeDiffMode();
    const showWarning = isAboveLimit && !state.bypassLimit;

    const getComponentTypesForLegend =
      useCallback((): ComponentTypeForLegend[] => {
        const { nodes, groups } = viewModel;

        const nodesInGraph = [nodes, groups].flatMap(items =>
          [...items.add, ...items.update].map(id => items.byId.get(id))
        );

        return getComponentTypesForLegendBase(
          nodesInGraph
            .map(nodeInGraph => nodeInGraph && dataModelId(nodeInGraph))
            .filter(ExcludeFalsy)
        );
      }, [viewModel]);

    const getReferenceTypesForLegend = useCallback(() => {
      const { edges } = viewModel;
      const edgesInGraph = [...edges.add, ...edges.update]
        .map(edgeIdInGraph => edges.byId.get(edgeIdInGraph))
        .filter(ExcludeFalsy);

      const hasParentChildEdges = edgesInGraph.some(
        edge => edge instanceof ParentChildGraphEdge
      );

      return [
        ...uniqWith(
          [
            ...edgesInGraph
              .filter(edge => !(edge instanceof ParentChildGraphEdge))
              .map(edge => referenceInterface.getModelType(dataModelId(edge)))
              .filter(ExcludeFalsy),
          ],
          isEqual
        ),
        ...((hasParentChildEdges && [ParentChildGraphEdge.getType()]) || []),
      ];
    }, [viewModel]);

    return (
      <FillingDiv>
        {showWarning ? (
          <DataLimit
            isAboveLimit={isAboveLimit}
            isEmpty={isEmpty}
            canBypass={canBypass}
            onBypassClick={() => setState({ bypassLimit: true })}
            viewId={viewId}
          />
        ) : (
          !hideZoomControls && (
            <ZoomContainer>
              <ZoomControls
                zoomIn={() =>
                  ICommand.INCREASE_ZOOM.execute(1.2, graphComponent.current)
                }
                zoomOut={() =>
                  ICommand.DECREASE_ZOOM.execute(1.2, graphComponent.current)
                }
                zoomCenter={() => graphComponent.current?.fitContent()}
              >
                {additionalZoomControls}
              </ZoomControls>
            </ZoomContainer>
          )
        )}

        <GraphContainer
          $isModern={viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM}
          className="yfiles-canvascomponent"
          ref={graphComponentContainer}
          id="graphComponent"
          data-context-menu={contextMenu ?? OBJECT_CONTEXT_MENU_NAME}
        />
        <HeightOffset>
          {heightOffset => (
            <ZoomableViewLegendContainerForwardRef
              initiallyVisible={isLegendActive}
              ref={zoomableViewLegendContainerForwardRef}
              heightOffset={heightOffset}
            >
              <ViewLegend
                displayShapesInsteadOfIcons={displayShapesInsteadOfIcons}
                componentTypes={getComponentTypesForLegend()}
                referenceTypes={getReferenceTypesForLegend()}
                activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
                activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
              />
            </ZoomableViewLegendContainerForwardRef>
          )}
        </HeightOffset>
      </FillingDiv>
    );
  }
);

export const PerformanceTrackedGraphView = <
  TComponent extends ComponentType<TProps>,
  TProps extends { viewModel: ArdoqGraphComponentViewModel },
>(
  WrappedComponent: TComponent,
  props: TProps,
  viewId: ViewIds
) =>
  WithPerformanceTracking('graph view render', 1000, {
    WrappedComponent,
    wrappedProps: props,
    viewId,
    metadata: metadataFromViewModel(props.viewModel),
  });
