import { createRef, Component } from 'react';
import styled from 'styled-components';
import { Tags } from './tags';
import deleteUnusedTagsDialog from './deleteUnusedTagsDialog';
import { SettingsBar } from '@ardoq/settings-bar';
import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { deleteTags, showAddTagDialog } from 'streams/tags/TagActions';
import { ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { addToPresentation } from 'viewSettings/exportHandlers';
import { exportHtmlToPng, getExportProfilingTransaction } from '@ardoq/export';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { logError } from '@ardoq/logging';
import {
  TagModel,
  TagscapeViewProperties,
  TagscapeViewSettings,
} from './types';
import { colors, s8 } from '@ardoq/design-tokens';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import viewModel$ from './viewModel$';
import { popoverRegistry } from '@ardoq/popovers';
import { tagScapeContextMenuName } from './contextMenu';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import EmptyState from './EmptyState';
import { EXCLUDE_UNUSED_TAGS } from 'tabview/consts';
import { tagscapeCommands } from './commands';

const VIEW_ID = ViewIds.TAGSCAPE;

const DragDropComp = styled.p`
  color: color-mix(in srgb, ${colors.grey25} 80%, transparent);
  font-style: italic;
  margin: 0;
  padding: ${s8} 12px 0;
`;
interface LeftMenuItem {
  id: keyof TagscapeViewSettings | 'addtag' | 'deleteUnused';
  label: string;
  iconName: IconName;
  type: SettingsType;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: (tags: TagModel[]) => void;
}

const deleteUnused = (unusedTags: TagModel[]) => {
  const cids = unusedTags.map(t => t.cid);
  dispatchAction(deleteTags({ cids }));
};

const leftMenu: LeftMenuItem[] = [
  {
    id: 'addtag',
    label: 'Add tag',
    iconName: IconName.ADD_CIRCLE,
    type: SettingsType.BUTTON,
    isDisabled: false,
    onClick: () => dispatchAction(showAddTagDialog()),
  },
  {
    id: 'excludeUnused',
    label: EXCLUDE_UNUSED_TAGS,
    iconName: IconName.TAG,
    isActive: false,
    type: SettingsType.TOGGLE,
  },
  {
    id: 'deleteUnused',
    label: 'Delete unused tags',
    iconName: IconName.CLOSE,
    type: SettingsType.BUTTON,
    isDisabled: false,
    onClick: deleteUnused,
  },
  {
    id: 'excludeRefs',
    label: 'Exclude references',
    iconName: IconName.RANDOM,
    isActive: false,
    type: SettingsType.TOGGLE,
  },
  {
    id: 'excludeComps',
    label: 'Exclude components',
    iconName: IconName.FILE_OUTLINED,
    isActive: false,
    type: SettingsType.TOGGLE,
  },
  {
    id: 'count',
    label: 'Count references and components',
    iconName: IconName.CALCULATOR,
    isActive: false,
    type: SettingsType.TOGGLE,
  },
];

const NO_UNUSED_TAGS_POPOVER = 'noUnusedTags';
popoverRegistry.set(
  NO_UNUSED_TAGS_POPOVER,
  () => 'No unused tags to delete in this workspace'
);

const getLeftMenu = (
  oldMenu: LeftMenuItem[],
  { viewState, viewModel }: TagscapeViewProperties
): SettingsConfig[] =>
  oldMenu.map((menuItem): SettingsConfig => {
    if (menuItem.id === 'deleteUnused') {
      const unusedTags = viewModel.tags.filter(t => !t.isUsed);
      const isDisabled = !unusedTags.length;

      return {
        ...menuItem,
        isDisabled,
        onClick: async () => {
          if (await deleteUnusedTagsDialog(unusedTags)) {
            deleteUnused(unusedTags);
          }
        },
        popoverId: isDisabled ? NO_UNUSED_TAGS_POPOVER : undefined,
      };
    }
    if (menuItem.type === SettingsType.TOGGLE) {
      return {
        ...menuItem,
        isActive: viewState[menuItem.id as keyof TagscapeViewSettings],
        onViewSettingsUpdate,
      };
    }
    if (menuItem.type === SettingsType.BUTTON) {
      return {
        ...menuItem,
        isDisabled: menuItem.isDisabled || !viewModel.workspace.isWriter,
        onClick: menuItem.onClick as () => void,
      };
    }
    return menuItem as SettingsConfig;
  });

const tagFilter = (excludeUnused: boolean) => (tag: TagModel) =>
  !excludeUnused || tag.isUsed;

const TagHelp = () => (
  <DragDropComp>
    Tags in the selected context workspace appear here. Drag components in that
    workspace from the navigator to tag them. Select a different workspace to
    see and tag components in it.
  </DragDropComp>
);
class TagscapeView extends Component<TagscapeViewProperties> {
  private contentPaneRef = createRef<HTMLDivElement>();
  exportToPng = () => {
    const transaction = getExportProfilingTransaction();

    exportHtmlToPng({
      transaction,
      container: this.contentPaneRef.current,
      exportedViewMetadata: {
        name: VIEW_ID,
      },
      trackingFunction: trackExportingVisualization,
      getStyleSheets,
    });
  };
  render() {
    const { viewModel, viewState } = this.props;
    if (!viewModel) {
      logError(Error('Invalid arguments in Tagscape View.'));
      return null;
    }
    const { tags, disableAddingTagToDraggedComponent } = viewModel;
    const visibleTags = tags.filter(tagFilter(viewState.excludeUnused));

    const hasExcludedTags = !!tags.length && tags.length !== visibleTags.length;

    const isEmptyView = !visibleTags.length;

    return (
      <div
        className="tab-pane tabtagscape tabscapeTab active"
        data-context-menu={tagScapeContextMenuName}
        onClick={() => tagscapeCommands.setFocusedItemState(null)}
      >
        <div className="menuContainer">
          <SettingsBar
            viewId={VIEW_ID}
            leftMenu={getLeftMenu(leftMenu, { viewModel, viewState })}
            rightMenu={getRightMenuConfig({
              viewId: VIEW_ID,
              viewstate: viewState,
              exports: {
                exportToPng: this.exportToPng,
                addToPresentation: () => addToPresentation(VIEW_ID),
                isDisabled: isEmptyView,
              },
              withLegend: false,
              knowledgeBaseLink: KnowledgeBaseLink.TAGSCAPE,
              onViewSettingsUpdate,
            })}
          />
        </div>
        {isEmptyView ? (
          <EmptyState
            hasNoTags={!tags.length}
            hasExcludedTags={hasExcludedTags}
          />
        ) : (
          <>
            <TagHelp />
            <div ref={this.contentPaneRef} className="contentPane">
              <Tags
                tags={visibleTags}
                viewState={viewState}
                disabled={disableAddingTagToDraggedComponent}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(TagscapeView, viewModel$);
