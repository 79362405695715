import { BlocksViewLink, BlocksViewNode } from '../types';
import { RoutingInfo, Waypoint } from './router';
import { Bounds as Rectangle } from '@ardoq/graph';

/* low-cost pseudo-maze routing */
export const routeMaze = (
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo,
  lca: BlocksViewNode
): Waypoint[] | null => {
  const source = link.sourceProxy;
  const target = link.sourceProxy;
  const routingInfo = getRoutingInfo(lca);
  const assignMicroRow = routingInfo.assignMicroRow;
  const assignMicroCol = routingInfo.assignMicroCol;
  const blockGrid = routingInfo.blockGrid;
  const rowCnt = lca.rowSize?.length;
  const colCnt = lca.colSize?.length;

  if (!source || !target || !rowCnt || !colCnt) {
    return null;
  }

  const left = 0; // towards the direction of decreasing column
  const up = 1; // towards the direction of decreasing row
  const right = 2; // towards the direction of increasing column
  const down = 3; // towards the direction of increasing row

  const routeToEdge = (
    node: BlocksViewNode,
    primary: number,
    alternate: number
  ) => {
    const wayPoints: Waypoint[] = [];

    let col = 0;
    let μcol = 0;
    let row = 0;
    let μrow = 0;

    switch (primary) {
      case left:
        col = node.col - 1;
        μcol = +Infinity;
        row = node.row + Math.floor(node.rowSpan / 2);
        μrow = assignMicroRow(link, row);
        break;

      case up:
        col = node.col + Math.floor(node.colSpan / 2);
        μcol = assignMicroCol(link, col);
        row = node.row - 1;
        μrow = +Infinity;
        break;

      case right:
        col = node.col + node.colSpan;
        μcol = -Infinity;
        row = node.row + Math.floor(node.rowSpan / 2) + 1;
        μrow = assignMicroRow(link, row);
        break;

      case down:
      default:
        col = node.col + Math.floor(node.colSpan / 2);
        μcol = assignMicroCol(link, col);
        row = node.row + node.rowSpan;
        μrow = -Infinity;
        break;
    }

    wayPoints.push({
      colRef: lca,
      col: col,
      μcol: μcol,
      row: row,
      μrow: μrow,
    });

    while (col !== 0 && row !== 0 && col !== colCnt - 1 && row !== rowCnt - 1) {
      const block = blockGrid.getBlock(col, row, primary);

      if (isFinite(block)) {
        /* move along primary to corner */

        col = primary === left || primary === right ? block : col;
        row = primary === up || primary === down ? block : row;

        wayPoints.push({
          colRef: lca,
          col: col,
          μcol: assignMicroCol(link, col),
          row: row,
          μrow: assignMicroRow(link, row),
        });

        if (alternate === left || alternate === right) {
          /* move horizontally along alternate */

          const rowPrimary = row + (primary === up ? -1 : +1);

          while (blockGrid.get(col, rowPrimary)) {
            col = col + (alternate === left ? -1 : +1);

            if (col === 0 || col === colCnt) {
              /* stop at edge */

              break;
            }
          }
        } else {
          /* move vertically along alternate */

          const colPrimary = col + (primary === left ? -1 : +1);

          while (blockGrid.get(colPrimary, row)) {
            row = row + (alternate === up ? -1 : +1);

            if (col === 0 || col === colCnt) {
              /* stop at edge */

              break;
            }
          }
        }
      } else {
        col = primary === left ? 0 : primary === right ? colCnt - 1 : col;
        row = primary === up ? 0 : primary === down ? rowCnt - 1 : row;
      }

      wayPoints.push({
        colRef: lca,
        col: col,
        μcol: assignMicroCol(link, col),
        row: row,
        μrow: assignMicroRow(link, row),
      });
    }

    return wayPoints;
  };

  const joinRoutes = (a: Waypoint[], b: Waypoint[]) => {
    const classifyEdge = (wp: Waypoint) => {
      if (wp.col === 0) return left;
      if (wp.row === 0) return up;
      if (wp.col === colCnt - 1) return right;
      if (wp.row === rowCnt - 1) return down;

      return 0;
    };

    const endA = a[a.length - 1];
    const edgeA = classifyEdge(endA);
    const endB = b[b.length - 1];
    const edgeB = classifyEdge(endB);

    if (edgeA === edgeB) {
      return a.concat(b.reverse());
    }

    if (
      (edgeA === left && edgeB !== right) ||
      (edgeA === right && edgeB !== left)
    ) {
      /* adjacent edges */

      a.push({
        colRef: lca,
        col: endA.col,
        μcol: assignMicroCol(link, endA.col),
        row: endB.row,
        μrow: assignMicroRow(link, endB.row),
      });

      return a.concat(b.reverse());
    }

    if ((edgeA === up && edgeB !== down) || (edgeA === down && edgeB !== up)) {
      /* adjacent edges */

      a.push({
        colRef: lca,
        col: endB.col,
        μcol: assignMicroCol(link, endB.col),
        row: endA.row,
        μrow: assignMicroRow(link, endA.row),
      });

      return a.concat(b.reverse());
    }

    if (edgeA === left || edgeA === right) {
      /* opposite edges */

      const prefA = endA.row / rowCnt;
      const prefB = endB.row / rowCnt;

      const row =
        (prefA < 0.5 && prefB < 0.5) ||
        (prefA < 0.5 && prefB > 0.5 && prefA < 1 - prefB) ||
        (prefA > 0.5 && prefB < 0.5 && 1 - prefA < prefB)
          ? 0
          : rowCnt - 1;

      a.push({
        colRef: lca,
        col: endA.col,
        μcol: assignMicroCol(link, endA.col),
        row: row,
        μrow: assignMicroRow(link, row),
      });

      a.push({
        colRef: lca,
        col: endB.col,
        μcol: assignMicroCol(link, endB.col),
        row: row,
        μrow: assignMicroRow(link, row),
      });

      return a.concat(b.reverse());
    }

    if (edgeA === up || edgeA === down) {
      /* opposite edges */

      const prefA = endA.row / colCnt;
      const prefB = endB.row / colCnt;

      const col =
        (prefA < 0.5 && prefB < 0.5) ||
        (prefA < 0.5 && prefB > 0.5 && prefA < 1 - prefB) ||
        (prefA > 0.5 && prefB < 0.5 && 1 - prefA < prefB)
          ? 0
          : colCnt - 1;

      a.push({
        colRef: lca,
        col: col,
        μcol: assignMicroCol(link, col),
        row: endA.row,
        μrow: assignMicroRow(link, endA.row),
      });

      a.push({
        colRef: lca,
        col: col,
        μcol: assignMicroCol(link, col),
        row: endB.row,
        μrow: assignMicroRow(link, endB.row),
      });

      return a.concat(b.reverse());
    }

    return null;
  };

  /* work out the primary and alternate routing directions for source and target */

  const sourceRect: Rectangle = [
    source.col,
    source.row,
    source.col + source.colSpan - 1,
    source.row + source.rowSpan - 1,
  ];
  const sourceScore = [
    sourceRect[0] / colCnt,
    sourceRect[1] / rowCnt,
    1 - sourceRect[2] / colCnt,
    1 - sourceRect[3] / rowCnt,
  ];
  let sourcePrimary = 0;
  let sourceAlternate = 0;

  const targetRect: Rectangle = [
    target.col,
    target.row,
    target.col + target.colSpan - 1,
    target.row + target.rowSpan - 1,
  ];
  const targetScore = [
    targetRect[0] / colCnt,
    targetRect[1] / rowCnt,
    1 - targetRect[2] / colCnt,
    1 - targetRect[3] / rowCnt,
  ];
  let targetPrimary = 0;
  let targetAlternate = 0;

  const overlap = [
    source.col <= target.col + target.colSpan - 1 &&
      source.col + source.colSpan - 1 >= target.col,
    source.row <= target.row + target.rowSpan - 1 &&
      source.row + source.rowSpan - 1 >= target.row,
  ];

  if (overlap[0] && !overlap[1]) {
    /* horizontal can be chosen independently, vertical must be opposed */

    sourcePrimary = source.row <= target.row ? up : down;
    sourceAlternate = left; // choose closest left or right edge, not force
    targetPrimary = source.row <= target.row ? down : up;
    targetAlternate = left; // choose closest left or right edge, not force
  }

  if (!overlap[0] && overlap[1]) {
    /* vertical can be chosen independently, horizontal must be opposed */

    sourcePrimary = down;
    targetPrimary = down;

    if (source.row / rowCnt < 1 - (source.row + source.rowSpan - 1) / rowCnt) {
      sourcePrimary = up;
    }

    if (target.row / rowCnt < 1 - (target.row + target.rowSpan - 1) / rowCnt) {
      targetPrimary = up;
    }

    sourceAlternate = source.col <= target.col ? left : right;
    targetAlternate = sourceAlternate === left ? right : left;
  }

  if (!overlap[0] && !overlap[1]) {
    sourcePrimary = sourceScore[1] < sourceScore[3] ? up : down;
    targetPrimary = targetScore[1] < targetScore[3] ? up : down;
    sourceAlternate = source.col <= target.col ? left : right;
    targetAlternate = sourceAlternate === left ? right : left;
  }

  const routeSource = routeToEdge(source, sourcePrimary, sourceAlternate);
  const routeTarget = routeToEdge(target, targetPrimary, targetAlternate);

  if (!routeSource || !routeTarget) {
    /* even the fallback has failed */
    return null;
  }

  return joinRoutes(routeSource, routeTarget);
};
