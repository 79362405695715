import { ViewIds } from '@ardoq/api-types';
import { connectInstance } from '@ardoq/rxbeach';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import {
  ProteanDiagramStreamedProperties,
  ProteanDiagramViewProperties,
} from '../types';
import { getViewModel$ } from '../viewModel$/viewModel$';
import ProteanDiagramBaseView from './ProteanDiagramBaseView';
import { EDIT_MODE_MENU_ID } from './settingsBar/consts';

const ProteanDiagramView = (props: ProteanDiagramViewProperties) => {
  return (
    <ProteanDiagramBaseView
      {...props}
      viewId={ViewIds.PROTEAN_DIAGRAM}
      leftMenuFilter={items =>
        items.filter(item => item.id !== EDIT_MODE_MENU_ID)
      }
    />
  );
};

const PerformanceTrackedProteanDiagramView = (
  props: ProteanDiagramViewProperties
) =>
  WithPerformanceTracking('protean diagram view render', 5000, {
    WrappedComponent: ProteanDiagramView,
    wrappedProps: props,
    viewId: props.viewId ?? ViewIds.PROTEAN_DIAGRAM,
    metadata: {
      linkCount: props.viewModel.edges.byId.size,
    },
  });

const ProteanDiagramViewWithLoadingIndicator = (
  props: ProteanDiagramViewProperties
) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedProteanDiagramView,
    wrappedProps: props,
    showContentWhileLoading: true,
  });

export default connectInstance<
  ProteanDiagramViewProperties,
  ProteanDiagramStreamedProperties
>(ProteanDiagramViewWithLoadingIndicator, getViewModel$());
