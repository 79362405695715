import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { startAction } from 'actions/utils';
import Components from 'collections/components';
import { context$ } from 'streams/context/context$';
import { action$, ofType } from '@ardoq/rxbeach';
import { combineLatestWith, startWith } from 'rxjs/operators';
import { createStateStream } from 'streams/utils/stateStreamUtils';

export const isContextWorkspaceEmpty$ = createStateStream({
  debugName: 'isContextWorkspaceEmpty$',
  createStream: (context$, action$) =>
    action$.pipe(
      ofType(
        notifyComponentsUpdated,
        notifyComponentsRemoved,
        notifyComponentsAdded
      ),
      startWith(startAction()),
      combineLatestWith(context$),
      map(([, context]) => ({
        isContextWorkspaceEmpty: Components.collection.isEmptyWorkspace(
          context.workspace
        ),
      })),
      distinctUntilChanged()
    ),
  isTemporary: true,
})(context$, action$);
