import {
  UnknownDocumentType,
  AdvancedSearchField,
  ArdoqId,
} from '@ardoq/api-types';
import {
  DataSourceTableProps,
  DatasourceTable,
  SelectableDatasourceTable,
} from '@ardoq/table';
import { Pagination } from '@ardoq/pagination';
import { SortOrder, AdvancedSearchResultType } from '@ardoq/api-types';
import {
  fieldsToFieldsLiteral,
  findSelectedFields,
  getDefaultFields,
  getFieldValueRenderer,
  getNextSortOrder,
  getSortByString,
  isFieldSortable,
  prependWithDefaults,
} from './utils';
import { filterUnique } from 'utils/collectionUtil';
import { format } from 'utils/numberUtils';
import { MappedAdvancedSearchResult } from 'search/resultUtils';
import { isDateFieldType } from '@ardoq/date-time';
import { Multiselect } from '@ardoq/select';
import { FormWrapper } from '@ardoq/forms';
import { InlineText } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';

export type OnSortChange = (
  sortBy: string | undefined,
  sortOrder: SortOrder | undefined
) => void;
export type OnPageSelect = (pageNumber: number) => void;
export type OnFieldSelect = (fieldNames: string[]) => void;

type SearchResultTableSelectable = {
  selectable: true;
  selected: ArdoqId[];
  setSelected: (selected: ArdoqId[]) => void;
};
type SearchResultTableNonSelectable = {
  selectable?: false;
};
export type SelectionProps =
  | SearchResultTableSelectable
  | SearchResultTableNonSelectable;

type SearchResultTableProps = SelectionProps & {
  onPageSelect?: OnPageSelect;
  onFieldSelect?: OnFieldSelect;
  onSortChange?: OnSortChange;
  sortOrder?: SortOrder;
  sortBy?: string;
  perPage: number;
  errorMsg?: string;
  page: number;
  total: number;
  fields: AdvancedSearchField[];
  maxWidth?: string;
  isLoading?: boolean;
  results?: MappedAdvancedSearchResult<UnknownDocumentType>[];
  selectedFieldNames?: string[];
  queryRelevantFieldsNames?: string[];
  intercomTarget?: string;
  selectable?: boolean;
};

const getResultType = (
  results: MappedAdvancedSearchResult<UnknownDocumentType>[]
): AdvancedSearchResultType | null => (results[0] ? results[0].type : null);

const SearchResultTable = (props: SearchResultTableProps) => {
  const { results = [], queryRelevantFieldsNames = [] } = props;

  // I assume all the results are of the same type,
  const resultType = getResultType(results);
  const customFields = fieldsToFieldsLiteral(props.fields);
  const defaultFields = getDefaultFields(resultType);
  const fields = Object.assign({}, defaultFields, customFields);

  const queryRelevantKeys = Object.keys(fields).filter(fieldName =>
    queryRelevantFieldsNames.includes(fieldName)
  );

  // do not allow to have none column selected
  // fallback to default set in that case
  const selectedFieldNames = (props.selectedFieldNames || []).length
    ? props.selectedFieldNames || []
    : [
        'name',
        'typeName',
        'last-updated',
        'created',
        ...prependWithDefaults(queryRelevantKeys, Object.keys(customFields)),
      ].filter(filterUnique);

  // setSelectedFieldNames with right order
  const setSelectedFieldNames = (selectedOptions: string[] | null) => {
    if (!selectedOptions) return;
    return (
      props.onFieldSelect &&
      props.onFieldSelect(
        Object.keys(fields).filter(fieldName =>
          selectedOptions.includes(fieldName)
        )
      )
    );
  };

  const getSortFunction = (field: AdvancedSearchField) => {
    const { onSortChange, sortOrder } = props;
    // do not allow sort if we don't know what are the results
    if (!resultType) return;
    // do not allow sort if no sort prop is provided
    if (!onSortChange) return;
    // do not allow sort if field is on a black list
    if (!isFieldSortable(field, resultType)) return;
    return () => {
      const sortBy = getSortByString(field);
      const nextSortOrder =
        props.sortBy === sortBy ? getNextSortOrder(sortOrder) : SortOrder.ASC;
      onSortChange(nextSortOrder ? sortBy : undefined, nextSortOrder);
    };
  };

  const getSortOrder = (sortBy: string) =>
    sortBy === props.sortBy ? props.sortOrder : undefined;

  const dataSourceTableProps: DataSourceTableProps<(typeof results)[0]> = {
    style: { maxWidth: props.maxWidth, overflowX: 'scroll' },
    loading: props.isLoading,
    columns: findSelectedFields(selectedFieldNames, fields).map(field => ({
      title: field.label,
      dataIndex: field.name,
      valueRender: getFieldValueRenderer(field),
      onHeaderClick: getSortFunction(field),
      sortOrder: getSortOrder(field.name),
      headerStyle: { whiteSpace: 'nowrap' },
      cellStyle:
        // this is one condition only, so I did it inline
        // if there is need to extend it, extract it to separated fn
        isDateFieldType(field.type) ? { whiteSpace: 'nowrap' } : {},
    })),
    dataSource: props.errorMsg ? [] : results,
    renderEmptyTable: () => {
      return props.errorMsg ? (
        <div style={{ textAlign: 'center', color: 'red' }}>
          {props.errorMsg}
        </div>
      ) : (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          No results found
        </div>
      );
    },
  };
  return (
    <>
      <FlexBox justify="space-between" paddingBottom="small" align="end">
        <FlexBox>
          <InlineText variant="text1">Number of results: </InlineText>
          <InlineText variant="text1Bold">
            &nbsp;{format(props.total)}
          </InlineText>
        </FlexBox>
        {!!results.length && (
          <FormWrapper>
            <Multiselect
              label="Select columns"
              value={selectedFieldNames}
              options={Object.values(fields).map(({ name, label }) => ({
                label,
                value: name,
              }))}
              isClearable={false}
              onValueChange={setSelectedFieldNames}
            />
          </FormWrapper>
        )}
      </FlexBox>
      {!props.selectable ? (
        <DatasourceTable
          {...dataSourceTableProps}
          data-intercom-target={props.intercomTarget}
        />
      ) : (
        <SelectableDatasourceTable
          {...dataSourceTableProps}
          data-intercom-target={props.intercomTarget}
          selected={props.selected}
          setSelected={props.setSelected}
        />
      )}
      <Pagination
        style={{
          padding: '32px 0 0',
          justifyContent: 'center',
        }}
        isDisabled={props.isLoading}
        currentPageNumber={props.page}
        perPage={props.perPage}
        // back-end doesn't support pagination past the 10000th result
        totalResults={Math.min(10000, props.total)}
        onPageSelect={props.onPageSelect}
      />
    </>
  );
};

export default SearchResultTable;
