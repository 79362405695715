import { BlocksViewLink, BlocksViewNode } from '../types';
import { astar, Cell } from '../misc/astar';
import { BlockGrid, Direction } from './blockGrid';
import { RoutingInfo, Waypoint } from './router';
import { routeMaze } from './maze';

function last<T>(array: T[]) {
  return array[array.length - 1];
}

const smartPath = (
  blockGrid: BlockGrid, // block grid for the lca
  source: BlocksViewNode,
  sourceEdges: Direction[],
  targetScore: (k: Cell) => number
) => {
  /* prepare seeds and get the solution */

  const seeds = [];

  const isValid = (p: Cell) =>
    blockGrid.isInside(p.col, p.row) && !blockGrid.get(p.col, p.row);

  for (const edge of sourceEdges) {
    switch (edge) {
      case Direction.Left:
        for (let row = source.row; row < source.row + source.rowSpan; ++row) {
          seeds.push({ col: source.col - 1, row: row });
        }
        break;

      case Direction.Up:
        for (let col = source.col; col < source.col + source.colSpan; ++col) {
          seeds.push({ col: col, row: source.row - 1 });
        }
        break;

      case Direction.Right:
        for (let row = source.row; row < source.row + source.rowSpan; ++row) {
          seeds.push({ col: source.col + source.colSpan, row: row });
        }
        break;

      case Direction.Down:
        for (let col = source.col; col < source.col + source.colSpan; ++col) {
          seeds.push({ col: col, row: source.row + source.rowSpan });
        }
        break;
    }
  }

  const path = astar(seeds, isValid, targetScore);
  const stripped: Cell[] = [];

  /* strip colinear points into ret */

  const buf: Cell[] = []; // last two output points
  let u: number[] = [0, 0]; // direction between last two output points

  for (const pt of path) {
    if (buf.length === 0) {
      stripped.push(pt);
      buf.push(pt);
      continue;
    }

    if (buf.length === 1) {
      buf.push(pt);
      u = [buf[1].col - buf[0].col, buf[1].row - buf[0].row];
      continue;
    }

    const v = [pt.col - buf[1].col, pt.row - buf[1].row]; // direction from the last output point to this point

    if (u[0] * v[1] - u[1] * v[0] !== 0) {
      /* this point is not colinear with the last output point */

      stripped.push(buf[1]);
      buf[0] = buf[1];
      u = v;
    }

    buf[1] = pt;
  }

  if (stripped.length > 0) {
    /* fixup and flush */

    stripped.push(last(buf));
  }

  return stripped.reverse(); // astar returns points from target to source
};

const routeAcross = (
  from: BlocksViewNode,
  fromDirections: Direction[],
  h: (pt: Cell) => number,
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  const lca = from.parent!;
  const routingInfo = getRoutingInfo(lca);
  const path = smartPath(routingInfo.blockGrid, from, fromDirections, h);
  const waypoints: Waypoint[] = [];

  if (path) {
    for (const point of path) {
      const wp = {
        colRef: lca,
        col: point.col,
        μcol: routingInfo.assignMicroCol(link, point.col),
        row: point.row,
        μrow: routingInfo.assignMicroRow(link, point.row),
      };

      waypoints.push(wp);
    }
  }

  return waypoints;
};

const spliceOut = (from: Waypoint[], to: Waypoint[], direction: Direction) => {
  const a = from[from.length - 1];
  const b = to[0];

  const horizontal = () => {
    const colRef = b.colRef;
    const col = b.col;
    const μcol = b.μcol;

    return [
      {
        colRef: colRef,
        col: col,
        μcol: μcol,

        rowRef: a.rowRef ?? a.colRef,
        row: a.row,
        μrow: a.μrow,
      },
      {
        colRef: colRef,
        col: col,
        μcol: μcol,

        rowRef: b.rowRef ?? b.colRef,
        row: b.row,
        μrow: b.μrow,
      },
    ];
  };

  const vertical = () => {
    const rowRef = b.rowRef ?? b.colRef;
    const row = b.row;
    const μrow = b.μrow;

    return [
      {
        colRef: a.colRef,
        col: a.col,
        μcol: a.μcol,

        rowRef: rowRef,
        row: row,
        μrow: μrow,
      },
      {
        colRef: b.colRef,
        col: b.col,
        μcol: b.μcol,

        rowRef: rowRef,
        row: row,
        μrow: μrow,
      },
    ];
  };

  switch (direction) {
    case Direction.Left:
    case Direction.Right:
      return horizontal();

    case Direction.Up:
    case Direction.Down:
      return vertical();
  }

  return [];
};

const spliceIn = (from: Waypoint[], to: Waypoint[], direction: Direction) => {
  const a = last(from);
  const b = to[0];

  const horizontal = () => {
    const colRef = a.colRef;
    const col = a.col;
    const μcol = a.μcol;

    return [
      {
        colRef: colRef,
        col: col,
        μcol: μcol,

        rowRef: a.rowRef ?? a.colRef,
        row: a.row,
        μrow: a.μrow,
      },
      {
        colRef: colRef,
        col: col,
        μcol: μcol,

        rowRef: b.rowRef ?? b.colRef,
        row: b.row,
        μrow: b.μrow,
      },
    ];
  };

  const vertical = () => {
    const rowRef = a.rowRef ?? a.colRef;
    const row = a.row;
    const μrow = a.μrow;

    return [
      {
        colRef: a.colRef,
        col: a.col,
        μcol: a.μcol,

        rowRef: rowRef,
        row: row,
        μrow: μrow,
      },
      {
        colRef: b.colRef,
        col: b.col,
        μcol: b.μcol,

        rowRef: rowRef,
        row: row,
        μrow: μrow,
      },
    ];
  };

  switch (direction) {
    case Direction.Left:
    case Direction.Right:
      return horizontal();

    case Direction.Up:
    case Direction.Down:
      return vertical();
  }

  return [];
};

const routeOut = (
  from: BlocksViewNode,
  to: BlocksViewNode,
  direction: Direction,
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  const h = (p: Cell, n: BlocksViewNode) => {
    switch (direction) {
      case Direction.Left:
        return p.col;

      case Direction.Right:
        return n.colSize!.length - 1 - p.col;

      case Direction.Down:
        return n.rowSize!.length - 1 - p.row;
    }

    return 0;
  };

  const route: Waypoint[] = [];

  if (from === to) {
    return route;
  }

  let a = from;

  while (a.parent && a !== to) {
    /* route through a's parent from a to a's parent */

    const routingInfo = getRoutingInfo(a.parent);
    const blockGrid = routingInfo.blockGrid;
    const path = smartPath(blockGrid, a, [direction], p => h(p, a.parent!));

    /* get path waypoints */

    const tmp: Waypoint[] = [];

    for (const pt of path) {
      const wp = {
        colRef: a.parent,
        col: pt.col,
        μcol: routingInfo.assignMicroCol(link, pt.col),
        rowRef: a.parent,
        row: pt.row,
        μrow: routingInfo.assignMicroRow(link, pt.row),
      };

      tmp.push(wp);
    }

    /* splice from current route to new bit of path */

    if (route.length !== 0 && tmp.length !== 0) {
      const spl = spliceOut(route, tmp, direction);
      route.push(...spl);
    }

    /* add path to route */

    route.push(...tmp);

    a = a.parent;
  }

  return route;
};

// this finds out which inside edge I got routed from
const getOutsideEdge = (p: Cell, n: BlocksViewNode) => {
  if (p.col === n.col - 1) return Direction.Left;
  if (p.row === n.row - 1) return Direction.Up;
  if (p.col === n.col + n.colSpan) return Direction.Right;
  if (p.row === n.row + n.rowSpan) return Direction.Down;

  return 0;
};

const getInsideEndpoint = (
  node: BlocksViewNode,
  pt: Cell,
  link: BlocksViewLink,
  routingInfo: RoutingInfo
) => {
  const left = 0;
  const up = 0;
  const right = node.colSize!.length - 1;
  const down = node.rowSize!.length - 1;

  if (pt.col === left) {
    return {
      colRef: node,
      col: left,
      μcol: -Infinity,
      rowRef: node,
      row: pt.row,
      μrow: routingInfo.assignMicroRow(link, pt.row), //
    };
  }

  if (pt.row === up) {
    return {
      colRef: node,
      col: pt.col,
      μcol: routingInfo.assignMicroCol(link, pt.col),
      rowRef: node,
      row: up,
      μrow: -Infinity,
    };
  }

  if (pt.col === right) {
    return {
      colRef: node,
      col: right,
      μcol: +Infinity,
      rowRef: node,
      row: pt.row,
      μrow: routingInfo.assignMicroRow(link, pt.row), //
    };
  }

  // must be bottom edge

  return {
    colRef: node,
    col: pt.col,
    μcol: routingInfo.assignMicroCol(link, pt.col),
    rowRef: node,
    row: down,
    μrow: +Infinity,
  };
};

const getOutsideEndpoint = (
  node: BlocksViewNode,
  wp: Waypoint,
  link: BlocksViewLink,
  routingInfo: RoutingInfo
) => {
  const lca = node.parent!;
  if (wp.col === node.col - 1) {
    /* left edge */
    return {
      colRef: lca,
      col: node.col,
      μcol: -Infinity,
      rowRef: lca,
      row: wp.row,
      μrow: routingInfo.assignMicroRow(link, wp.row),
    };
  }

  if (wp.row === node.row - 1) {
    /* top edge */
    return {
      colRef: lca,
      col: wp.col,
      μcol: routingInfo.assignMicroCol(link, wp.col),
      rowRef: lca,
      row: node.row,
      μrow: -Infinity,
    };
  }

  if (wp.col === node.col + node.colSpan) {
    /* right edge */
    return {
      colRef: lca,
      col: node.col + node.colSpan - 1,
      μcol: +Infinity,
      rowRef: lca,
      row: wp.row,
      μrow: routingInfo.assignMicroRow(link, wp.row),
    };
  }

  if (wp.row === node.row + node.rowSpan) {
    /* bottom edge */
    return {
      colRef: lca,
      col: wp.col,
      μcol: routingInfo.assignMicroCol(link, wp.col),
      rowRef: lca,
      row: node.row + node.rowSpan - 1,
      μrow: +Infinity,
    };
  }

  return {
    colRef: lca,
    col: node.col,
    μcol: 0,
    row: node.row,
    μrow: 0,
  };
};

const leftRightBottom = (
  p: Cell,
  left: number,
  top: number,
  right: number,
  bottom: number
) => {
  let leftEdge = 0;
  let rightEdge = 0;
  let bottomEdge = 0;

  {
    const diag =
      (p.col <= left && p.row <= top) ||
      (p.col >= right && p.row <= top) ||
      (p.col <= left && p.row >= bottom) ||
      (p.col >= right && p.row >= bottom);

    const h = Math.abs(left - p.col);
    const v = Math.max(p.row - bottom, 0);

    leftEdge = h + v + (diag ? 1 : 0);
  }

  {
    //
    const diag =
      (p.col <= left && p.row <= top) ||
      (p.col >= right && p.row <= top) ||
      (p.col <= left && p.row >= bottom) ||
      (p.col >= right && p.row >= bottom);
    const h = Math.abs(right - p.col);
    const v = Math.max(p.row - bottom, 0);

    rightEdge = h + v + (diag ? 1 : 0);
  }

  {
    const diag =
      (p.col <= left && p.row <= top) ||
      (p.col >= right && p.row <= top) ||
      (p.col <= left && p.row >= bottom) ||
      (p.col >= right && p.row >= bottom);
    const h = Math.min(Math.max(left - p.col, 0), Math.max(p.col - right, 0));
    const v = Math.abs(bottom - p.row);

    bottomEdge = h + v + (diag ? 1 : 0);
  }

  return Math.min(leftEdge, rightEdge, bottomEdge);
};

export const routeSelf = (
  link: BlocksViewLink,
  node: BlocksViewNode,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  const wp: Waypoint[] = [];
  const lca = node.parent!;
  const routingInfo = getRoutingInfo(lca);

  let col = node.col;
  let μcol = routingInfo.assignMicroCol(link, col);

  let row = node.row + node.rowSpan;
  let μrow = -Infinity;
  wp.push({ colRef: lca, col: col, μcol: μcol, row: row, μrow: μrow }); // a

  row = node.row + node.rowSpan;
  μrow = routingInfo.assignMicroRow(link, row);
  wp.push({ colRef: lca, col: col, μcol: μcol, row: row, μrow: μrow }); // b

  col = node.col - 1;
  μcol = routingInfo.assignMicroCol(link, col);
  wp.push({ colRef: lca, col: col, μcol: μcol, row: row, μrow: μrow }); // c

  row = node.row + node.rowSpan - 1;
  μrow = routingInfo.assignMicroRow(link, row);
  wp.push({ colRef: lca, col: col, μcol: μcol, row: row, μrow: μrow }); // d

  col = node.col - 1;
  μcol = +Infinity;
  wp.push({ colRef: lca, col: col, μcol: μcol, row: row, μrow: μrow }); // e

  return wp;
};

export const routeSiblings = (
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
): Waypoint[] => {
  const source = link.sourceProxy;
  const target = link.targetProxy;

  if (!source || !target) {
    return [];
  }

  const directions = [];

  if (source.col > target.col) {
    directions.push(Direction.Left);
  }

  if (source.row > target.row) {
    directions.push(Direction.Up);
  }

  if (source.col + source.colSpan < target.col + target.colSpan) {
    directions.push(Direction.Right);
  }

  if (source.row + source.rowSpan < target.row + target.rowSpan) {
    // this one may be more subtle
    directions.push(Direction.Down);
  }

  const left = target.col - 1;
  const top = target.row - 1;
  const right = target.col + target.colSpan;
  const bottom = target.row + target.rowSpan;

  const h = (pt: Cell) => {
    const h = Math.max(left - pt.col, 0) + Math.max(pt.col - right, 0);
    const v = Math.max(top - pt.row, 0) + Math.max(pt.row - bottom, 0);
    const diag =
      (pt.col <= left && (pt.row <= top || pt.row >= bottom)) ||
      (pt.col >= right && (pt.row <= top || pt.row >= bottom));

    return h + v + (diag ? 1 : 0);
  };

  const lca = source.parent!;
  const ret = routeAcross(source, directions, h, link, getRoutingInfo);

  if (ret) {
    const routingInfo = getRoutingInfo(lca);
    const bgn = getOutsideEndpoint(source, ret[0], link, routingInfo);
    const end = getOutsideEndpoint(target, last(ret), link, routingInfo);

    ret.unshift(bgn);
    ret.push(end);
  }

  return ret ?? routeMaze(link, getRoutingInfo, lca);
};

export const internalRouteAscendant = (
  link: BlocksViewLink,
  source: BlocksViewNode,
  target: BlocksViewNode,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  let a = source;

  while (a.parent && a.parent !== target) {
    a = a.parent;
  }

  const left = a.col;
  const right = target.colSize!.length - (a.col + a.colSpan);
  const down = target.rowSize!.length - (a.row + a.rowSpan);
  const min = Math.min(left, right, down);

  const direction =
    min === left
      ? Direction.Left
      : min === right
        ? Direction.Right
        : Direction.Down;

  const waypoints = routeOut(source, target, direction, link, getRoutingInfo);

  const bgn = getOutsideEndpoint(
    source,
    waypoints[0],
    link,
    getRoutingInfo(source.parent!)
  );

  const end = getInsideEndpoint(
    target,
    last(waypoints),
    link,
    getRoutingInfo(target)
  );

  waypoints.unshift(bgn);
  waypoints.push(end);

  return waypoints;
};

export const routeAscendant = (
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  const source = link.sourceProxy;
  const target = link.targetProxy;

  if (!source || !target) {
    return [];
  }

  return internalRouteAscendant(link, source, target, getRoutingInfo);
};

export const routeDescendant = (
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo
) => {
  const source = link.targetProxy;
  const target = link.sourceProxy;

  if (!source || !target) {
    return [];
  }

  return internalRouteAscendant(link, source, target, getRoutingInfo).reverse();
};

export const routeCousins = (
  link: BlocksViewLink,
  getRoutingInfo: (node: BlocksViewNode) => RoutingInfo,
  lca: BlocksViewNode
) => {
  const source = link.sourceProxy;
  const target = link.targetProxy;

  if (!source || !target) {
    return [];
  }

  let a = source;
  let b = target;

  while (a.parent && a.parent !== lca) {
    a = a.parent;
  }

  while (b.parent && b.parent !== lca) {
    b = b.parent;
  }

  // look for a direct path not involving the top edge of either a or b and use it if possible

  // work out the plausible exit edges from a to b
  // I could have thrown *all* valid exit edges at smartPath, but I have
  // the feeling that this speeds things up a bit and probably
  // doesn't do any harm
  const directions = (a: BlocksViewNode, b: BlocksViewNode) => {
    const ret = [];

    if (a.col > b.col) {
      /* source right edge is left of target left edge */
      ret.push(Direction.Left);
    }

    if (a.col + a.colSpan < b.col + b.colSpan) {
      /* source right edge is left of target right edge */
      ret.push(Direction.Right);
    }

    if (a.row + a.rowSpan < b.row + b.rowSpan) {
      /* source bottom edge is above target bottom edge */

      ret.push(Direction.Down);
    }

    if (ret.length === 0) {
      /* source spans the target and is basically below it */

      ret.push(Direction.Left);
      ret.push(Direction.Right);
    }

    return ret;
  };

  const left = b.col - 1;
  const top = b.row - 1;
  const right = b.col + b.colSpan;
  const bottom = b.row + b.rowSpan;

  const h = (pt: Cell) => {
    return leftRightBottom(pt, left, top, right, bottom);
  };

  const ab = routeAcross(a, directions(a, b), h, link, getRoutingInfo); // route from a to b

  if (!ab) {
    return routeMaze(link, getRoutingInfo, lca) ?? [];
  }

  const waypoints = [];

  const aEdge = getOutsideEdge(ab[0], a); // find which edge of a ended up getting used
  const sa = routeOut(source, a, aEdge, link, getRoutingInfo);

  const bEdge = getOutsideEdge(last(ab), b); // find which edge b ended up getting used
  const bt = routeOut(target, b, bEdge, link, getRoutingInfo).reverse();

  if (sa.length !== 0) {
    const bgn = getOutsideEndpoint(
      source,
      sa[0],
      link,
      getRoutingInfo(source.parent!)
    );
    const spl = spliceOut(sa, ab, aEdge);

    waypoints.push(bgn);
    waypoints.push(...sa);
    waypoints.push(...spl);
  } else {
    const bgn = getOutsideEndpoint(
      source,
      ab[0],
      link,
      getRoutingInfo(source.parent!)
    );

    waypoints.push(bgn);
  }

  waypoints.push(...ab);

  if (bt.length !== 0) {
    const spl = spliceIn(ab, bt, bEdge);

    const end = getOutsideEndpoint(
      target,
      last(bt),
      link,
      getRoutingInfo(target.parent!)
    );

    waypoints.push(...spl);
    waypoints.push(...bt);
    waypoints.push(end);
  } else {
    const end = getOutsideEndpoint(
      target,
      last(ab),
      link,
      getRoutingInfo(target.parent!)
    );

    waypoints.push(end);
  }

  return waypoints;
};
