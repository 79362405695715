import { Observable, combineLatest } from 'rxjs';
import {
  SwimlaneViewModel,
  SwimlaneViewProperties,
  SwimlaneViewSettings,
} from './types';
import type { ContextShape } from '@ardoq/data-model';
import GroupByCollection from 'collections/groupByCollection';
import { aggregatedGraphInstance } from 'graph/aggregatedGraph';
import { hierarchicGraphInstance } from 'graph/hierarchicGraph';
import * as graphFunctions from 'graph/graphFunctions';
import FieldValue from 'graph/FieldValue';
import { Node } from 'graph/node';
import type { Edge } from 'graph/types';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { ComponentBackboneModel } from 'aqTypes';
import { GraphItem } from 'graph/GraphItem';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import layoutUpdate$ from 'tabview/graphComponent/layoutUpdate$';
import { ObservableState, dispatchAction } from '@ardoq/rxbeach';
import relationshipDiagramComponentUpdate$ from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramComponentUpdate$';
import { startAction } from 'actions/utils';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { readViewComponentIds } from 'streams/viewComponentIds$';
import { APIFieldType, ArdoqId, ViewIds } from '@ardoq/api-types';
import { uniq } from 'lodash';
import { notifyViewLoading } from 'tabview/actions';
import modelCss$ from 'utils/modelCssManager/modelCss$';
import { getFilteredViewSettings$ } from 'views/filteredViewSettings$';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { isEmptyView } from './utils';
import Components from 'collections/components';

const getPathLengths = (viewSettings: SwimlaneViewSettings) => {
  return {
    maxIncomingPathLength: viewSettings.incomingDegreesOfRelationship,
    maxOutgoingPathLength: viewSettings.outgoingDegreesOfRelationship,
  };
};
const buildViewModel = (
  context: ContextShape,
  viewSettings: SwimlaneViewSettings
): SwimlaneViewModel => {
  let nodes;
  let graph;
  let edges: Edge[];

  const locale = getCurrentLocale();
  const maxDegrees = getPathLengths(viewSettings);

  const contextComponent = Components.collection.get(context.componentId);
  if (GroupByCollection.length > 0) {
    if (contextComponent) {
      graph = aggregatedGraphInstance.getGraphByComponent({
        component: contextComponent,
        maxDegreesIncoming: maxDegrees.maxIncomingPathLength,
        maxDegreesOutgoing: maxDegrees.maxOutgoingPathLength,
        isProcess: true,
      });
    } else {
      graph = aggregatedGraphInstance.getGraphByWorkspace({
        workspaceId: context.workspaceId,
        maxDegreesIncoming: maxDegrees.maxIncomingPathLength,
        maxDegreesOutgoing: maxDegrees.maxOutgoingPathLength,
        isProcess: true,
      });
    }
    nodes = graph.rootNodes.toArray();
  } else {
    if (contextComponent) {
      graph = hierarchicGraphInstance.getGraphByComponent({
        component: contextComponent,
        includeParents: true,
        maxDegreesIncoming: maxDegrees.maxIncomingPathLength,
        maxDegreesOutgoing: maxDegrees.maxOutgoingPathLength,
      });
    } else {
      graph = hierarchicGraphInstance.getGraphByWorkspace({
        workspaceId: context.workspaceId,
        includeParents: true,
        maxDegreesIncoming: maxDegrees.maxIncomingPathLength,
        maxDegreesOutgoing: maxDegrees.maxOutgoingPathLength,
      });
    }
    nodes = graph.nodes.toArray();
  }
  edges = graph.edges.toArray();

  let { children, rows } = graphFunctions.getChildrenAndRows(
    nodes,
    graph.edges,
    contextComponent?.id
  );
  if (viewSettings.orderAlphabetically && rows.length) {
    const firstDataModel = rows[0] && (rows[0].dataModel as FieldValue);
    const isNumberField =
      firstDataModel.value &&
      firstDataModel.field &&
      firstDataModel.field.getType() === APIFieldType.NUMBER;
    const sortFunction = isNumberField
      ? (a: Node, b: Node) =>
          (((a.dataModel as FieldValue).value as number) -
            (b.dataModel as FieldValue).value) as number
      : (a: Node, b: Node) => localeCompare(a.name(), b.name(), locale);

    rows = rows.sort(sortFunction);
  }
  // Don't render if there are no rows
  if (!rows.length) {
    children = [];
    edges = [];
  }

  return {
    nodes: toViewModel(children),
    edges: toViewModel(edges),
    groups: toViewModel([] as Node[]),
    rows: toViewModel(rows),
    noConnectedComponents: !rows.length && !!nodes.length,
  };
};
const toViewModel = <T extends GraphItem>(items: T[]): GraphItemsModel<T> => ({
  byId: new Map<ArdoqId, T>(items.map(item => [item.id, item])),
  add: items.map(item => item.id),
  update: [],
  remove: [],
});

export const getViewModel$ =
  (
    viewSettings$: Observable<ViewSettings<SwimlaneViewSettings>>,
    context$: ObservableState<ContextShape>
  ) =>
  (viewInstanceId: string): Observable<SwimlaneViewProperties> =>
    combineLatest([
      context$,
      getFilteredViewSettings$<SwimlaneViewSettings>(viewSettings$),
      layoutUpdate$,
      relationshipDiagramComponentUpdate$().pipe(startWith(startAction())),
      modelCss$, // the url icons must be re-rendered e.g. if a reference color is changed.
    ]).pipe(
      debounceTime(100),
      tap(() => {
        dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: true }));
      }),
      map(([context, viewSettings, layoutUpdate]) => ({
        viewModel: buildViewModel(context, viewSettings.currentSettings),
        viewSettings: viewSettings.currentSettings,
        zoomComponentId: layoutUpdate.isContextChange
          ? context.componentId
          : null,
        clearEdges: layoutUpdate.referenceOrderChanged,
        preserveViewport: layoutUpdate.preserveViewport,
        viewInstanceId,
      })),
      tap(({ viewModel }) => {
        dispatchAction(
          readViewComponentIds({
            [ViewIds.SWIMLANE]: uniq(
              [
                ...viewModel.nodes.byId.values(),
                ...viewModel.groups.byId.values(),
              ]
                .filter(node => node.isComponent())
                .map(node => (node.dataModel as ComponentBackboneModel).id)
            ),
          })
        );
      }),
      tap(({ viewModel }) => {
        if (isEmptyView(viewModel)) {
          // normally isBusy=false is dispatched in the graph layout callback,
          // but in this case we have no graph, so we must dispatch isBusy=false
          // using setTimeout to delay the dispatching of action until the next event cycle
          setTimeout(() =>
            dispatchAction(
              notifyViewLoading({
                viewInstanceId,
                isBusy: false,
              })
            )
          );
        }
      })
    );
