import {
  type ILabel,
  type INode,
  type IRenderContext,
  LabelStyleBase,
  Size,
  TextMeasurePolicy,
  Visual,
} from '@ardoq/yfiles';
import { PROTEAN_MAX_FONT_SIZE } from 'tabview/proteanDiagram/view/consts';
import getFittingFontSize from '../../getFittingFontSize';
import CanvasLabelVisual from './CanvasLabelVisual';
import { DUMMY_FONT } from '../../consts';

class CanvasLabelStyle extends LabelStyleBase {
  font = DUMMY_FONT;
  protected override createVisual(
    context: IRenderContext,
    label: ILabel
  ): Visual | null {
    const {
      text,
      layout: {
        bounds: { centerX, centerY },
      },
    } = label;
    const zoom = context.canvasComponent?.zoom ?? 0;
    return new CanvasLabelVisual(
      centerX,
      centerY,
      this.font === DUMMY_FONT
        ? Math.min(
            PROTEAN_MAX_FONT_SIZE / zoom,
            getFittingFontSize(label, TextMeasurePolicy.CANVAS)
          )
        : this.font.fontSize,
      text
    );
  }
  protected override getPreferredSize(label: ILabel): Size {
    return (label.owner as INode).layout.toSize();
  }
}

export default CanvasLabelStyle;
