import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { range } from 'lodash';
import { colors, s24, s4 } from '@ardoq/design-tokens';
import { HasDegrees } from './types';
import { HasHoveredComponentId, SetsHoveredComponentId } from '@ardoq/graph';
import { CONTEXT_ID } from './consts';

const CAP_SIZE = 9;
const ARROW_LENGTH = 21;
const CIRCLE_DIAMETER = 24;
const CONTEXT_CIRCLE_DIAMETER = 12;
const GRID_SIZE = 9;
const ROW_COLUMN_TEMPLATE = range(GRID_SIZE)
  .map(() => `${CIRCLE_DIAMETER}px`)
  .join(' ');

const traversalInfoNodeColor = (isIncoming: boolean) =>
  isIncoming ? colors.green60 : colors.purple35;
interface GetsLit {
  isLit: boolean;
}
type GetsLitStyleProps = { $isLit: boolean };
const getFaded = ({ $isLit }: GetsLitStyleProps) =>
  !$isLit &&
  css`
    opacity: 0.3;
  `;

interface CircleProperties extends GetsLit {
  isContext: boolean;
  isIncoming: boolean;
  small?: boolean;
}
interface CircleComponentProperties
  extends CircleProperties,
    HasHoveredComponentId,
    SetsHoveredComponentId,
    HTMLAttributes<HTMLDivElement> {
  componentId: string;
}
const Circle = ({
  componentId,
  hoveredComponentId,
  setHoveredComponentId,
  isContext,
  isIncoming,
  small,
  isLit,
  ...elementProps
}: CircleComponentProperties) => (
  <CircleElement
    {...elementProps}
    $isLit={isLit}
    $small={small}
    $isIncoming={isIncoming}
    $isContext={isContext}
    $isActive={hoveredComponentId === componentId}
    onMouseOver={() => isLit && setHoveredComponentId(componentId)}
  />
);
const CircleElement = styled.div<{
  $isActive: boolean;
  $isContext: boolean;
  $isIncoming: boolean;
  $isLit: boolean;
  $small?: boolean;
}>`
  transition-property: opacity, border;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  ${({ $isContext, $isActive, $isIncoming, $small }) => {
    const diameter = $small ? CONTEXT_CIRCLE_DIAMETER : CIRCLE_DIAMETER;
    return css`
      width: ${diameter}px;
      height: ${diameter}px;
      border-radius: ${diameter / 2}px;
      border: ${$isActive ? 3 : 1}px solid
        ${$isContext
          ? $small
            ? 'transparent'
            : $isActive
              ? colors.pink50
              : colors.pink60
          : $isActive
            ? $isIncoming
              ? colors.green50
              : colors.purple25
            : colors.grey60};
      background-color: ${$isContext && $small
        ? $isActive
          ? colors.pink50
          : colors.pink60
        : 'transparent'};
    `;
  }}

  ${getFaded}
`;
enum ArrowDirection {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}
interface ArrowElementArgs {
  $isIncoming: boolean;
  $direction: ArrowDirection;
}
type ArrowArgs = GetsLit & {
  isIncoming: boolean;
  direction: ArrowDirection;
} & HTMLAttributes<HTMLDivElement>;
const LINE_WIDTH = 1;
const ArrowContainer = styled.div<GetsLitStyleProps>`
  transition-property: opacity;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${getFaded}
`;
const Line = styled.div<ArrowElementArgs>`
  grid-row: 1;
  grid-column: 1;
  transition-property: border-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  border-color: ${({ $isIncoming }) => traversalInfoNodeColor($isIncoming)};
  border-style: solid;
  border-width: 0;
  ${({ $direction }) => {
    switch ($direction) {
      case ArrowDirection.UP:
        return css`
          width: 0;
          height: ${ARROW_LENGTH}px;
          border-right-width: ${LINE_WIDTH}px;
          margin-top: ${s4};
        `;
      case ArrowDirection.DOWN:
        return css`
          width: 0;
          height: ${ARROW_LENGTH}px;
          border-right-width: ${LINE_WIDTH}px;
          margin-bottom: ${s4};
        `;
      case ArrowDirection.RIGHT:
        return css`
          height: 0;
          width: ${ARROW_LENGTH}px;
          border-bottom-width: ${LINE_WIDTH}px;
          margin-right: ${s4};
        `;
      case ArrowDirection.LEFT:
        return css`
          height: 0;
          width: ${ARROW_LENGTH}px;
          border-bottom-width: ${LINE_WIDTH}px;
          margin-left: ${s4};
        `;
    }
  }}
`;

const Triangle = styled.div<ArrowElementArgs>`
  grid-row: 1;
  grid-column: 1;
  width: 0;
  height: 0;
  ${({ $isIncoming, $direction }) => {
    switch ($direction) {
      case ArrowDirection.UP:
        return css`
          border-left: ${CAP_SIZE / 2}px solid transparent;
          border-right: ${CAP_SIZE / 2}px solid transparent;
          border-bottom: ${CAP_SIZE}px solid
            ${traversalInfoNodeColor($isIncoming)};
          align-self: start;
          margin-top: ${s4};
        `;
      case ArrowDirection.RIGHT:
        return css`
          border-top: ${CAP_SIZE / 2}px solid transparent;
          border-bottom: ${CAP_SIZE / 2}px solid transparent;
          border-left: ${CAP_SIZE}px solid
            ${traversalInfoNodeColor($isIncoming)};
          justify-self: end;
          margin-right: ${s4};
        `;
      case ArrowDirection.DOWN:
        return css`
          border-left: ${CAP_SIZE / 2}px solid transparent;
          border-right: ${CAP_SIZE / 2}px solid transparent;
          border-top: ${CAP_SIZE}px solid ${traversalInfoNodeColor($isIncoming)};
          align-self: end;
          margin-bottom: ${s4};
        `;
      case ArrowDirection.LEFT:
        return css`
          border-top: ${CAP_SIZE / 2}px solid transparent;
          border-bottom: ${CAP_SIZE / 2}px solid transparent;
          border-right: ${CAP_SIZE}px solid
            ${traversalInfoNodeColor($isIncoming)};
          justify-self: start;
          margin-left: ${s4};
        `;
    }
  }}
`;
const Arrow = ({
  isIncoming,
  direction,
  isLit,
  ...containerStyle
}: ArrowArgs) => (
  <ArrowContainer {...containerStyle} $isLit={isLit}>
    <Line $isIncoming={isIncoming} $direction={direction} />
    <Triangle $isIncoming={isIncoming} $direction={direction} />
  </ArrowContainer>
);
const Container = styled.div`
  display: grid;
  grid-template-columns: ${ROW_COLUMN_TEMPLATE};
  grid-template-rows: ${ROW_COLUMN_TEMPLATE};
  margin: 0 ${s24} 0 ${s4};
  justify-items: center;
  align-items: center;
`;
type TraversalInfoGridProperties = HasDegrees &
  SetsHoveredComponentId &
  HasHoveredComponentId;
const TraversalInfoGrid = ({
  inDegrees,
  outDegrees,
  hoveredComponentId,
  setHoveredComponentId,
}: TraversalInfoGridProperties) => (
  <Container>
    <Circle
      isLit={false}
      isContext={false}
      isIncoming={true}
      componentId="1"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 1, gridColumn: 3 }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={true}
      isLit={false}
      style={{ gridRow: 1, gridColumn: 4 }}
    />
    <Circle
      isLit={false}
      isContext={false}
      isIncoming={false}
      componentId="2"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 1, gridColumn: 5 }}
    />
    <Arrow
      direction={ArrowDirection.LEFT}
      isIncoming={true}
      isLit={false}
      style={{ gridRow: 1, gridColumn: 6 }}
    />
    <Circle
      isLit={false}
      isContext={false}
      isIncoming={true}
      componentId="3"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 1, gridColumn: 7 }}
    />

    <Arrow
      direction={ArrowDirection.UP}
      isIncoming={false}
      isLit={false}
      style={{ gridRow: 2, gridColumn: 5 }}
    />

    <Circle
      isLit={inDegrees > 2}
      isContext={false}
      isIncoming={true}
      componentId="4"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 3, gridColumn: 1 }}
    />
    <Circle
      isLit={inDegrees > 0}
      isContext={false}
      isIncoming={true}
      componentId="5"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 3, gridColumn: 5 }}
    />
    <Circle
      isLit={outDegrees > 2}
      isContext={false}
      isIncoming={false}
      componentId="6"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 3, gridColumn: 9 }}
    />

    <Arrow
      direction={ArrowDirection.DOWN}
      isIncoming={true}
      isLit={inDegrees > 2}
      style={{ gridRow: 4, gridColumn: 1 }}
    />
    <Arrow
      direction={ArrowDirection.DOWN}
      isIncoming={true}
      isLit={inDegrees > 0}
      style={{ gridRow: 4, gridColumn: 5 }}
    />
    <Arrow
      direction={ArrowDirection.UP}
      isIncoming={false}
      isLit={outDegrees > 2}
      style={{ gridRow: 4, gridColumn: 9 }}
    />

    <Circle
      isLit={inDegrees > 1}
      isContext={false}
      isIncoming={true}
      componentId="7"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 1 }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={true}
      isLit={inDegrees > 1}
      style={{ gridRow: 5, gridColumn: 2 }}
    />
    <Circle
      isLit={inDegrees > 0}
      isContext={false}
      isIncoming={true}
      componentId="8"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 3 }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={true}
      isLit={inDegrees > 0}
      style={{ gridRow: 5, gridColumn: 4 }}
    />
    <Circle
      isLit={true}
      isContext={true}
      isIncoming={true}
      componentId={CONTEXT_ID}
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 5 }}
    />
    <Circle
      small
      isLit={true}
      isContext={true}
      isIncoming={false}
      componentId={CONTEXT_ID}
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 5, pointerEvents: 'none' }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={false}
      isLit={outDegrees > 0}
      style={{ gridRow: 5, gridColumn: 6 }}
    />
    <Circle
      isLit={outDegrees > 0}
      isContext={false}
      isIncoming={false}
      componentId="10"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 7 }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={false}
      isLit={outDegrees > 1}
      style={{ gridRow: 5, gridColumn: 8 }}
    />
    <Circle
      isLit={outDegrees > 1}
      isContext={false}
      isIncoming={false}
      componentId="11"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 5, gridColumn: 9 }}
    />

    <Arrow
      direction={ArrowDirection.UP}
      isIncoming={true}
      isLit={inDegrees > 2}
      style={{ gridRow: 6, gridColumn: 1 }}
    />
    <Arrow
      direction={ArrowDirection.DOWN}
      isIncoming={false}
      isLit={outDegrees > 0}
      style={{ gridRow: 6, gridColumn: 5 }}
    />
    <Arrow
      direction={ArrowDirection.DOWN}
      isIncoming={false}
      isLit={outDegrees > 2}
      style={{ gridRow: 6, gridColumn: 9 }}
    />

    <Circle
      isLit={inDegrees > 2}
      isContext={false}
      isIncoming={true}
      componentId="12"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 7, gridColumn: 1 }}
    />
    <Circle
      isLit={outDegrees > 2}
      isContext={false}
      isIncoming={false}
      componentId="13"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 7, gridColumn: 5 }}
    />
    <Circle
      isLit={outDegrees > 2}
      isContext={false}
      isIncoming={false}
      componentId="14"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 7, gridColumn: 9 }}
    />

    <Arrow
      direction={ArrowDirection.UP}
      isIncoming={true}
      isLit={false}
      style={{ gridRow: 8, gridColumn: 5 }}
    />

    <Circle
      isLit={false}
      isContext={false}
      isIncoming={false}
      componentId="15"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 9, gridColumn: 3 }}
    />
    <Arrow
      direction={ArrowDirection.LEFT}
      isIncoming={false}
      isLit={false}
      style={{ gridRow: 9, gridColumn: 4 }}
    />
    <Circle
      isLit={false}
      isContext={false}
      isIncoming={true}
      componentId="16"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 9, gridColumn: 5 }}
    />
    <Arrow
      direction={ArrowDirection.RIGHT}
      isIncoming={false}
      isLit={false}
      style={{ gridRow: 9, gridColumn: 6 }}
    />
    <Circle
      isLit={false}
      isContext={false}
      isIncoming={false}
      componentId="17"
      hoveredComponentId={hoveredComponentId}
      setHoveredComponentId={setHoveredComponentId}
      style={{ gridRow: 9, gridColumn: 7 }}
    />
  </Container>
);

export default TraversalInfoGrid;
