import { ProteanLayoutType } from '../types';
import renderGraph from './renderGraph';
import updateStyles from './updateStyles';
import { RepresentationData } from '@ardoq/data-model';
import { APIReferenceType } from '@ardoq/api-types';
import { ProteanGraphState, RenderGraphFlags } from './types';

export type ChangeLayoutArgs = {
  nodeRepresentationData: Map<string, RepresentationData>;
  referenceTypes: Map<string, APIReferenceType>;
  state: ProteanGraphState;
};

const changeLayout = async ({
  nodeRepresentationData,
  referenceTypes,
  state,
}: ChangeLayoutArgs) => {
  const { layoutType } = state.viewSettings;
  const shouldLayoutGraph =
    layoutType !== ProteanLayoutType.SpatialMap &&
    layoutType !== ProteanLayoutType.GeographicMap;
  state.renderFlags = Number(
    shouldLayoutGraph && RenderGraphFlags.LAYOUT_GRAPH
  );

  updateStyles({
    nodeRepresentationData,
    referenceTypes,
    state,
  });
  renderGraph(state);
};

export default changeLayout;
