import { connect } from '@ardoq/rxbeach';
import { IconName } from '@ardoq/icons';
import { ViewIds } from '@ardoq/api-types';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { SettingsBar, settingsBarConsts } from '@ardoq/settings-bar';
import { viewHasLegend } from 'views/metaInfoTabs';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import {
  type ExportConfigParams,
  type SettingsConfig,
  SettingsType,
} from '@ardoq/view-settings';
import { createDropdownSliderOptionManager } from 'viewSettings/settingsHelper';
import { DropdownOptionType, Notifier } from '@ardoq/dropdown-menu';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { DEGREES_OF_RELATIONSHIP } from '@ardoq/global-consts';
import type { SwimlaneViewSettings } from './types';

const VIEW_ID = ViewIds.SWIMLANE;

const getSliderOption = createDropdownSliderOptionManager(
  VIEW_ID,
  new Map(),
  onViewSettingsUpdate
);

const getLeftMenuConfig = (
  viewSettings: SwimlaneViewSettings,
  openDegreesViewSetting: Notifier
): SettingsConfig[] => {
  return [
    {
      id: 'orderAlphabetically',
      label: 'Order lanes alphabetically',
      iconName: IconName.SORT_ASC,
      isActive: viewSettings.orderAlphabetically,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate,
    },
    {
      id: settingsBarConsts.DEGREES_OF_RELATIONSHIP_SLIDER_MENU_ITEM_ID,
      label: DEGREES_OF_RELATIONSHIP,
      iconName: IconName.ACCOUNT_TREE,
      type: SettingsType.DROPDOWN,
      isKeepOpen: true,
      options: [
        getSliderOption({
          name: 'incomingDegreesOfRelationship',
          label: 'Incoming',
          value: viewSettings.incomingDegreesOfRelationship,
          min: 0,
          max: 10,
          step: 1,
          type: DropdownOptionType.SLIDER,
        }),
        getSliderOption({
          name: 'outgoingDegreesOfRelationship',
          label: 'Outgoing',
          value: viewSettings.outgoingDegreesOfRelationship,
          min: 0,
          max: 11,
          step: 1,
          substituteMax: 99,
          type: DropdownOptionType.SLIDER,
        }),
      ],
      requestOpen: openDegreesViewSetting,
    },
    {
      id: 'isVertical',
      label: 'Orient lanes vertically',
      iconName: IconName.VERTICAL_ALIGN_BOTTOM,
      isActive: viewSettings.isVertical,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate,
    },
  ];
};

type SwimlaneSettingsBarProps = {
  legendOnClick: () => void;
  exports: ExportConfigParams;
  currentSettings: SwimlaneViewSettings;
  openDegreesViewSetting: Notifier;
};

const SwimlaneSettingsBar = ({
  legendOnClick,
  exports,
  currentSettings: currentViewSettings,
  openDegreesViewSetting,
}: SwimlaneSettingsBarProps) => {
  return (
    <SettingsBar
      viewId={VIEW_ID}
      leftMenu={getLeftMenuConfig(currentViewSettings, openDegreesViewSetting)}
      rightMenu={getRightMenuConfig({
        viewId: VIEW_ID,
        viewstate: currentViewSettings,
        exports,
        withLegend: viewHasLegend(VIEW_ID),
        knowledgeBaseLink: KnowledgeBaseLink.SWIMLANE,
        legendOnClick,
        onViewSettingsUpdate,
      })}
    />
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<SwimlaneViewSettings>(VIEW_ID);

export default connect(SwimlaneSettingsBar, viewSettings$);
