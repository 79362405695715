import { ModalSize, confirmDelete } from '@ardoq/modal';
import { TagModel } from './types';

const deleteUnusedTagsDialog = (unusedTags: TagModel[]) =>
  confirmDelete({
    title: 'Delete all unused tags?',
    confirmButtonTitle: 'Delete tags',
    modalSize: ModalSize.XS,
    text: (
      <div>
        <div>The following tags will be deleted:</div>
        <ul>
          {unusedTags.map(({ name, cid }) => (
            <li key={cid}>{name}</li>
          ))}
        </ul>
      </div>
    ),
  });

export default deleteUnusedTagsDialog;
