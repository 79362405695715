import {
  FastGraphModelManager,
  OptimizationMode,
} from 'yfilesExtensions/fastGraphModelManager';
import { GraphComponent } from '@ardoq/yfiles';

export function configureGraphModelManager(
  graphComponent: GraphComponent,
  optimizationMode: OptimizationMode = OptimizationMode.SVG_IMAGE
) {
  const graphModelManager = new FastGraphModelManager(
    graphComponent,
    graphComponent.contentGroup
  );
  graphComponent.graphModelManager = graphModelManager;
  graphModelManager.graphOptimizationMode = optimizationMode;
  return graphModelManager;
}
