export type ViewSidePanelState = {
  contentId: ViewSidePanelContentIds | null;
  isViewpointMode: boolean;
};

export enum ViewSidePanelContentIds {
  SAVE_VIEWPOINT = 'SAVE_VIEWPOINT',
  SAVE_BOOKMARK = 'SAVE_BOOKMARK',
  VIEW_OPTIONS = 'VIEW_OPTIONS',
  PERSPECTIVES_GROUPS = 'PERSPECTIVES_GROUPS',
  PERSPECTIVES_CONDITIONAL_FORMAT = 'PERSPECTIVES_CONDITIONAL_FORMAT',
  PERSPECTIVES_LABELS = 'PERSPECTIVES_LABELS',
  VIEWPOINT_SWITCHER = 'VIEWPOINT_SWITCHER',
}

export type ViewSidePanelReducerFn<T = void> = (
  state: ViewSidePanelState,
  payload: T
) => ViewSidePanelState;
