import { APIFieldType, ViewIds } from '@ardoq/api-types';
import graphModel$ from 'modelInterface/graphModel$';
import { type Observable, combineLatest, map } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import type {
  HierarchicalTreemapNode,
  HierarchicalTreemapViewProperties,
} from '../types';
import buildViewModel from './buildViewModel';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { loadedScenarioData$ } from 'loadedScenarioData$';
import { scenarioRelatedComponents$ } from 'scenarioRelated/scenarioRelatedComponents$';
import { mainAppModuleSidebar$ } from 'appContainer/MainAppModule/MainAppModuleSidebar/mainAppModuleSidebar$';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniqBy } from 'lodash';

const flattenNodes = (
  nodes: HierarchicalTreemapNode[],
  node: HierarchicalTreemapNode
): HierarchicalTreemapNode[] => {
  nodes.push(node);
  node.nodes?.reduce(flattenNodes, nodes);
  return nodes;
};

const getViewModel$ =
  (viewId: ViewIds) =>
  (_viewInstanceId: string): Observable<HierarchicalTreemapViewProperties> =>
    combineLatest([
      context$,
      graphModel$,
      getViewSettingsStream(viewId),
      loadedScenarioData$,
      scenarioRelatedComponents$,
      mainAppModuleSidebar$,
      modelUpdateNotification$,
    ]).pipe(
      map(
        ([
          context,
          graph,
          viewSettings,
          loadedScenarioData,
          scenarioRelatedComponents,
          { showScopeRelated },
        ]) => {
          const viewModel = buildViewModel({
            graph,
            context,
            viewSettings,
            loadedScenarioData,
            scenarioRelatedComponents,
            showScopeRelated,
            viewId,
          });
          const allNodes =
            viewModel.hierarchy.data.nodes?.reduce(flattenNodes, []) ?? [];
          const workspaceIds = allNodes
            .map(({ modelId }) =>
              modelId ? componentInterface.getWorkspaceId(modelId) : null
            )
            .filter(ExcludeFalsy);
          return {
            viewSettings,
            viewModel,
            numericFields: uniqBy(
              workspaceIds.flatMap(workspaceId =>
                fieldInterface.getFieldsOfWorkspace(workspaceId, [
                  APIFieldType.NUMBER,
                ])
              ),
              field => `${field.name}~~${field.label}`
            ),
          };
        }
      )
    );

export default getViewModel$;
