import { BlocksViewNode } from '../types';
import { rectWidth, rectHeight } from '../misc/geometry';
import { isGroup } from '../viewModel$/util';
import { projectionTransform } from './util';
import { renderDragGhost } from '../visual/node';

const dragImageGetter =
  (dragImages: Map<BlocksViewNode, ImageBitmap>) =>
  (node: BlocksViewNode): ImageBitmap => {
    let ret = dragImages.get(node);

    if (!ret) {
      const bounds = node.bounds; // transformRect(node.bounds, canvasRef.current?.getContext('2d')!.getTransform()!); // bounds in viewport coords
      const canvas = new OffscreenCanvas(rectWidth(bounds), rectHeight(bounds)); // should be viewport width and height
      const ctx = canvas.getContext('2d')!;

      const doit = (node: BlocksViewNode) => {
        renderDragGhost(node, ctx);

        if (isGroup(node) && node.open) {
          node.children!.forEach(child => doit(child));
        }
      };

      const xform = projectionTransform(
        [0, 0, ctx.canvas.width, ctx.canvas.height],
        bounds
      );

      ctx.setTransform();
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.setTransform(xform);

      ctx.lineWidth = 1 / xform.a;
      doit(node);

      ret = canvas.transferToImageBitmap();
      dragImages.set(node, ret);
    }

    return ret;
  };

export default dragImageGetter;
