import { APIFieldAttributes } from '@ardoq/api-types';
import { createStreamFromBackboneCollection } from 'streams/utils/streamUtils';
import Fields from 'collections/fields';

export type Field = Pick<
  APIFieldAttributes,
  | '_id'
  | 'name'
  | 'label'
  | 'type'
  | 'model'
  | 'calculatedFieldSettings'
  | 'global'
  | 'globalref'
  | 'componentType'
  | 'referenceType'
  | 'defaultValue'
  | 'description'
  | 'numberFormatOptions'
>;

// this is quite limited stream, because I don't need anything more
// however feel free to extend it if you need more props eg. operators, type etc.
const fields$ = createStreamFromBackboneCollection<Field>(Fields.collection, [
  '_id',
  'name',
  'label',
  'type',
  'model',
  'calculatedFieldSettings',
  'global',
  'globalref',
  'componentType',
  'referenceType',
  'defaultValue',
  'description',
  'numberFormatOptions',
]);

export const rawFields$ = fields$;
