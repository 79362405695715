import { OrgAccessLevel } from '@ardoq/api-types';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const canCurrentUserWrite = (): boolean => {
  const currentUserOrgRole = currentUserInterface.getOrgCurrentUserRole();
  if (!currentUserOrgRole) {
    return false;
  }
  return ![OrgAccessLevel.READER, OrgAccessLevel.CONTRIBUTOR, null].includes(
    currentUserOrgRole
  );
};

const currentUserIsOrgAdmin = (): boolean => {
  const currentUserOrgRole = currentUserInterface.getOrgCurrentUserRole();
  if (!currentUserOrgRole) {
    return false;
  }
  return currentUserOrgRole === OrgAccessLevel.ADMIN;
};

export const currentUserAccessControlInterface = {
  canCurrentUserWrite,
  currentUserIsOrgAdmin,
};
