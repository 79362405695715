import styled from 'styled-components';
import { FlexBox } from '@ardoq/layout';
import { connectInstance, dispatchAction } from '@ardoq/rxbeach';
import {
  addDismisssedEphemeralNotification,
  ephemeralNotificationRendered,
} from './actions';
import { ephemeralNotification$ } from './ephemeralNotification$';
import { useEffect } from 'react';
import { trackEphemeralNotificationButtonClick } from './tracking';
import { InteractiveNotification } from '@ardoq/status-ui';
import {
  EphemeralNotificationConfig,
  EphemeralNotificationKeys,
  EphemeralNotificationStreamedProps,
} from './types';
import { isCarouselFeaturesConfig } from './predicates';

const WrapperContainer = styled(FlexBox)`
  pointer-events: none;
`;

const registerDismissed = (
  notificationId: EphemeralNotificationKeys,
  instanceId: string
) =>
  dispatchAction(
    addDismisssedEphemeralNotification(instanceId, notificationId)
  );
const onActionButtonClick = (
  notificationConfig: EphemeralNotificationConfig,
  instanceId: string
) => {
  registerDismissed(notificationConfig.id, instanceId);
  trackEphemeralNotificationButtonClick({
    button: 'action',
    notificationId: notificationConfig.id,
  });

  notificationConfig.onActionButtonClick?.();
};
const onCloseButtonclick = (
  notificationConfig: EphemeralNotificationConfig,
  instanceId: string
) => {
  registerDismissed(notificationConfig.id, instanceId);
  trackEphemeralNotificationButtonClick({
    button: 'close',
    notificationId: notificationConfig.id,
  });
};

/**
 * Ephemeral notifications are notifications that are relevant for only a finite period of time,
 * for a subset of users, and are shown only once per lifetime for every relevant user.
 *
 * After an ephemeral notification is expired, a warning shall be logged, hopefully reminding us
 * about removing the outdated notifications. This is needed because there is no standard place
 * for all notifications, they can be rendered in different places => we need to add the config
 * and the JSX manually.
 *
 * The notification shall be registered as "dismissed" on any interaction and never be shown again.
 */

type EphemeralNotificationProps = EphemeralNotificationStreamedProps & {
  /**
   * Specify positioning styles for the invisible notification wrapper (position, padding, justify-content, etc)
   */
  wrapperPositionStyle?: React.CSSProperties;
  notificationBodyStyle?: React.CSSProperties;

  /**
   * This should be provided for the banner to be displayed when appropriate
   */
  ephemeralNotificationKey: EphemeralNotificationKeys;
};
const EphemeralNotification = ({
  notificationConfig,
  isVisible,
  wrapperPositionStyle,
  notificationBodyStyle,
  ephemeralNotificationKey,
  instanceId,
}: EphemeralNotificationProps) => {
  useEffect(() => {
    if (!instanceId) {
      return;
    }
    // the stream needs ephemeralNotificationKey to know what to do
    dispatchAction(
      ephemeralNotificationRendered(instanceId, ephemeralNotificationKey)
    );
  }, [ephemeralNotificationKey, instanceId]);

  if (
    !(isVisible && notificationConfig) ||
    isCarouselFeaturesConfig(notificationConfig)
  ) {
    return null;
  }

  return (
    <WrapperContainer
      width="full"
      align="center"
      justify="center"
      style={wrapperPositionStyle}
    >
      <InteractiveNotification
        config={notificationConfig}
        onActionButtonClick={() => {
          onActionButtonClick(notificationConfig, instanceId);
        }}
        onClose={() => {
          onCloseButtonclick(notificationConfig, instanceId);
        }}
        notificationBodyStyle={notificationBodyStyle}
      />
    </WrapperContainer>
  );
};

export default connectInstance(EphemeralNotification, ephemeralNotification$);
