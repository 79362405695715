import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { PrimaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { openUrlInNewTab } from '@ardoq/common-helpers';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoTagsDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
};

const NoTagsDoq = ({ doqType, includeActions = true }: NoTagsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No tags to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            To use this view, add tags to your components.
          </FlexBox>
          <FlexBox gap="medium">
            Check out the knowledge base on how to use tags.
          </FlexBox>
        </Stack>
      }
      buttonLabel={
        includeActions ? (
          <PrimaryButton>
            Open knowledge base <Icon iconName={IconName.OPEN_IN_NEW} />
          </PrimaryButton>
        ) : undefined
      }
      onButtonClick={
        includeActions
          ? () => openUrlInNewTab(KnowledgeBaseLink.USING_TAGS)
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);

export default NoTagsDoq;
