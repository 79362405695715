import {
  NamedGraphItemModel,
  GraphItem as OldGraphItem,
} from 'graph/GraphItem';
import { AggregatedGraphEdge, GraphItem } from '@ardoq/graph';
import FieldValue from 'graph/FieldValue';

export const dataModelId = (item: GraphItem | OldGraphItem): string =>
  item instanceof AggregatedGraphEdge
    ? item.modelIds[0]
    : item instanceof GraphItem
      ? item.modelId
      : (item.dataModel as Backbone.Model).id ||
        (item.dataModel as Backbone.Model | FieldValue).cid ||
        (item.dataModel as NamedGraphItemModel).name();
