import {
  BlocksViewLink,
  BlocksViewNode,
  BlocksViewProperties,
  BlocksViewSettings,
} from '../types';
import {
  augmentNodeWithLinks,
  getLegendReferenceTypes,
} from 'tabview/relationsD3View/hierarchical/viewModel$/buildRelationshipsViewBaseProperties';
import {
  addedAndUpdatedGraphEdges,
  addedAndUpdatedGraphGroups,
  addedAndUpdatedGraphNodes,
  CollapsibleGraphGroup,
  getComponentTypesInfo,
  GraphNode,
  mapByExcludingNull,
  RelationshipDiagramViewModelStreamState,
} from '@ardoq/graph';
import createNode from './createNode';
import createLink from './createLink';
import { GraphNodesToRelationshipsNodesReducerState } from '../../relationshipDiagrams/types';
import reduceNodes from '../../relationsD3View/hierarchical/viewModel$/reduceNodes';
import graphEdgesToReferenceTypes from 'tabview/relationshipDiagrams/graphEdgesToReferenceTypes';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const getRootLeafNodes = (
  rootGraphLeaves: GraphNode[],
  groupChildren: Map<
    CollapsibleGraphGroup,
    (GraphNode | CollapsibleGraphGroup)[]
  >,
  nodeMap: Map<string, BlocksViewNode>,
  viewInstanceId: string
) => {
  const { map: rootLeavesMap } = rootGraphLeaves.reduce<
    GraphNodesToRelationshipsNodesReducerState<BlocksViewNode>
  >(reduceNodes<BlocksViewNode>, {
    map: new Map(),
    groupChildren,
    parent: null,
    viewInstanceId,
    createNode,
  });

  rootGraphLeaves.forEach(leaf =>
    nodeMap.set(leaf.id, rootLeavesMap.get(leaf.id)!)
  );

  return Array.from(rootLeavesMap.values());
};

const buildBlocksViewProperties = (
  state: BlocksViewProperties,
  {
    viewModel,
    viewInstanceId,
  }: RelationshipDiagramViewModelStreamState<BlocksViewSettings>
): BlocksViewProperties => {
  const { groups: graphGroups, errors, hasClones } = viewModel;
  const allGraphGroups = addedAndUpdatedGraphGroups(viewModel);
  const allGraphLeaves = addedAndUpdatedGraphNodes(viewModel);
  const allGraphNodes = [...allGraphGroups, ...allGraphLeaves];
  const groupChildren = mapByExcludingNull(
    allGraphNodes,
    graphNode => graphNode.parent as CollapsibleGraphGroup | null
  );
  const rootGraphGroups = allGraphGroups.filter(({ parent }) => !parent);
  const { map: nodeMap } = rootGraphGroups.reduce<
    GraphNodesToRelationshipsNodesReducerState<BlocksViewNode>
  >(reduceNodes<BlocksViewNode>, {
    map: new Map(),
    groupChildren,
    parent: null,
    viewInstanceId,
    createNode,
  });

  const rootGroupNodes = rootGraphGroups.map(
    graphGroup => nodeMap.get(graphGroup.id)!
  );

  const rootGraphLeaves = allGraphLeaves.filter(({ parent }) => !parent);

  const rootLeafNodes = rootGraphLeaves.length
    ? getRootLeafNodes(rootGraphLeaves, groupChildren, nodeMap, viewInstanceId)
    : [];

  const nodes = rootGroupNodes.concat(rootLeafNodes);

  const allGraphEdges = addedAndUpdatedGraphEdges(viewModel);

  const { referenceModelTypes, referenceTypes: allEdgesReferenceTypes } =
    graphEdgesToReferenceTypes(allGraphEdges);

  const links = allGraphEdges.map(graphEdge => {
    return createLink(
      graphEdge,
      nodeMap,
      allEdgesReferenceTypes.get(graphEdge.modelId) ?? null
    );
  });
  const hasParentChildLinks = allGraphEdges.some(
    graphEdge => !allEdgesReferenceTypes.get(graphEdge.modelId)?.line // infer if there's no modelType, then this is a parent-child reference
  );

  const nodeLinks = links.reduce(
    (result: Map<BlocksViewNode, BlocksViewLink[]>, link) => {
      [link.source, link.target].forEach(node => {
        if (!result.has(node)) {
          result.set(node, []);
        }
        result.get(node)!.push(link);
      });
      return result;
    },
    new Map()
  );

  const rootNode = nodes ? createNode('root', null, undefined, null) : null;

  if (rootNode) {
    rootNode.children = nodes;
    rootNode.children.forEach(child => (child.parent = rootNode));

    nodes.forEach(node => augmentNodeWithLinks(node, nodeLinks));
  }
  const componentIds = allGraphNodes
    .filter(node => node.isComponent())
    .map(({ modelId }) => modelId);
  const { legendComponentTypes } = getComponentTypesInfo(componentIds);
  return {
    viewSettings: state.viewSettings,
    graph: rootNode ? { root: rootNode, edges: links } : null,
    componentTypes: legendComponentTypes,
    referenceModelTypes: getLegendReferenceTypes(
      referenceModelTypes,
      hasParentChildLinks
    ),
    errors,
    hasClones,
    groups: graphGroups,
    isViewpointMode: loadedGraph$.state.isViewpointMode,
    viewInstanceId,
  };
};

export default buildBlocksViewProperties;
