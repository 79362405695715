import {
  BaseClass,
  Class,
  ILabel,
  ILabelModel,
  ILabelModelParameter,
  ILookup,
  INode,
  InteriorLabelModel,
  IOrientedRectangle,
  OrientedRectangle,
  Rect,
} from '@ardoq/yfiles';
import IconGroupLabelStyle from '../IconGroupLabelStyle';
import { MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X } from 'tabview/blockDiagram/view/consts';
import {
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_ONE_LINE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TWO_LINES_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH,
} from '../consts';
import { getGroupHeaderWidthWithoutLabelModernized } from 'tabview/blockDiagram/view/utils';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { componentInterface } from '@ardoq/component-interface';

class GroupLabelModelParameter extends BaseClass(ILabelModelParameter) {
  static Instance = new GroupLabelModelParameter();
  private constructor() {
    super();
  }
  override supports(label: ILabel): boolean {
    return InteriorLabelModel.NORTH_WEST.supports(label);
  }
  override get model(): ILabelModel {
    return GroupLabelModel.Instance;
  }
  override clone(): this {
    return this;
  }
}
class GroupLabelModel extends BaseClass(ILabelModel) {
  static Instance = new GroupLabelModel();
  private constructor() {
    super();
  }
  override createDefaultParameter(): ILabelModelParameter {
    return GroupLabelModelParameter.Instance;
  }
  override getContext(
    _label: ILabel,
    _layoutParameter: ILabelModelParameter
  ): ILookup {
    return this;
  }
  override lookup(_type: Class) {
    return null;
  }
  override getGeometry(
    label: ILabel,
    _layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const labelPreferredSize =
      IconGroupLabelStyle.Instance.getPreferredSize(label);
    if (!(label.owner instanceof INode)) {
      return IOrientedRectangle.EMPTY;
    }
    const {
      owner: {
        layout: { x: groupLayoutX, y: groupLayoutY, width: groupLayoutWidth },
        tag,
      },
    } = label;
    const group = tag as CollapsibleGraphGroup;
    const { width: labelPreferredWidth, height: labelPreferredHeight } =
      labelPreferredSize;

    const maximumGroupWidth = Math.max(
      MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH,
      groupLayoutWidth
    );

    const totalHorizontalPadding =
      getGroupHeaderWidthWithoutLabelModernized(group);
    const maximumLabelWidth = maximumGroupWidth - totalHorizontalPadding;

    const isMultiline =
      labelPreferredWidth > maximumLabelWidth || labelPreferredHeight > 15;

    const representationData =
      group.isComponent() &&
      componentInterface.getRepresentationData(group.modelId);
    const representationDataWidth = representationData
      ? MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT
      : 0;

    const left =
      groupLayoutX + MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X;
    const width =
      Math.min(labelPreferredWidth, maximumLabelWidth) +
      representationDataWidth;

    return new OrientedRectangle(
      new Rect(
        left,
        groupLayoutY,
        width,
        isMultiline
          ? MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TWO_LINES_HEIGHT
          : MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_ONE_LINE_HEIGHT
      )
    );
  }
}
export default GroupLabelModel;
