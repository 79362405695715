import type {
  EdgesSource,
  GraphBuilder,
  GraphComponent,
  INode,
  NodesSource,
  Point,
} from '@ardoq/yfiles';
import type {
  CollapsibleGraphGroup,
  GraphNode,
  RelationshipDiagramGraphEdgeType,
  RelationshipDiagramViewModel,
} from '@ardoq/graph';
import type { ProteanDiagramViewSettings } from '../types';
import { ViewIds } from '@ardoq/api-types';
import { LayoutConstraint } from 'tabview/graphViews/types';

export enum RenderGraphFlags {
  NONE = 0,
  LAYOUT_GRAPH = 1,
  FROM_SCRATCH = 2,
}

export enum ConstraintSource {
  PERSPECTIVE = 'perspective',
  VIEW_SETTINGS = 'viewSettings',
  GENERATED = 'generated',
  MOVED_BY_DND = 'movedByDnD',
}
// since we don't display the labels on the graph anymore, we can probably just remove this and go with the regular LayoutConstraint type.
export type ResolvedLayoutConstraint = [
  id: string,
  displayValue: number,
  graphValue: number,
  source: ConstraintSource,
];
export type ConstraintsMap = Map<string, ResolvedLayoutConstraint>;

export type LayoutConstraintWithSource = [
  id: string,
  value: number,
  source: ConstraintSource,
];

type HierarchicLayoutState = {
  layerConstraints: ResolvedLayoutConstraint[];
  sequenceConstraints: ResolvedLayoutConstraint[];
};

type TabularLayoutState = {
  rowConstraintsMap: ConstraintsMap;
  columnConstraintsMap: ConstraintsMap;
  rowSpansMap: ConstraintsMap;
  columnSpansMap: ConstraintsMap;
};
type ProteanGraphLayoutState = {
  hierarchic: null | HierarchicLayoutState;
  tabular: null | TabularLayoutState;
  hierarchicInGrid: null | TabularLayoutState;
};
export type ProteanGraphState = {
  viewId: ViewIds;
  viewInstanceId: string;
  graphComponent: React.MutableRefObject<GraphComponent | undefined>;
  graphBuilder: React.MutableRefObject<GraphBuilder | undefined>;
  nodesSource: React.MutableRefObject<NodesSource<GraphNode> | undefined>;
  groupsSource: React.MutableRefObject<
    NodesSource<CollapsibleGraphGroup> | undefined
  >;
  edgesSource: React.MutableRefObject<
    EdgesSource<RelationshipDiagramGraphEdgeType> | undefined
  >;
  viewModel: RelationshipDiagramViewModel;
  lastViewModel: RelationshipDiagramViewModel | null;
  viewSettings: ProteanDiagramViewSettings;
  lastViewSettings: ProteanDiagramViewSettings | null;
  lastLayoutState: ProteanGraphLayoutState | null;
  layoutState: ProteanGraphLayoutState;
  nodePositions: Map<string, Point>;
  nodeMap: Map<string, INode> | null;
  renderFlags: RenderGraphFlags;
  isEditModeEnabled: boolean;
};

export type ConstraintConfig<
  K extends keyof TOptions,
  TOptions extends { [key in K]: LayoutConstraint[] },
> = {
  label: string;
  key: K;
  index: number;
  current: number;
};
