import NodeCollection from 'graph/NodeCollection';
import { Node } from './types';
import GraphCollection from './GraphCollection';
class ChildCollection extends NodeCollection {
  parent: Node;
  constructor(models: Node[], { parent }: { parent: Node }) {
    super(models);
    this.parent = parent;
  }
  add(nodes: Node | Node[] | GraphCollection<Node>): void {
    if (nodes && this.parent) {
      const nodesArray = nodes as Node[] | GraphCollection<Node>;
      if (nodesArray.forEach) {
        nodesArray.forEach(node => {
          node.setParent(this.parent);
          super.add(node);
        });
      } else {
        const node = nodes as Node;
        node.setParent(this.parent);
        super.add(nodes);
      }
    }
  }
}
export default ChildCollection;
