import { trackEvent } from 'tracking/tracking';
import { EphemeralNotificationKeys } from './types';

const TRACKING_DOMAIN = 'Ephemeral notification0';

type buttonClickMetadata = {
  button: 'action' | 'close';
  notificationId: EphemeralNotificationKeys;
};

export const trackEphemeralNotificationButtonClick = (
  metadata: buttonClickMetadata
) => trackEvent(`${TRACKING_DOMAIN} button clicked`, metadata);
