import { CompletionBarHeaderText } from './atoms';
import { FlexBox } from '@ardoq/layout';

const CompletionBarHeader = ({
  left,
  right,
}: {
  left: string;
  right: string;
}) => (
  <FlexBox justify="space-between" width="full" paddingX="small">
    <CompletionBarHeaderText>{left}</CompletionBarHeaderText>
    <CompletionBarHeaderText>{right}</CompletionBarHeaderText>
  </FlexBox>
);

export default CompletionBarHeader;
