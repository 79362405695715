import { booleanSubqueryToFilterAttributes } from 'collections/filterUtils';
import Filters from 'collections/filters';
import Components from 'collections/components';
import { ArdoqId, QueryBuilderSubquery } from '@ardoq/api-types';

export const componentMatchesSubquery = (
  componentId: ArdoqId,
  subquery: QueryBuilderSubquery
): boolean =>
  Filters.createFilterFromRule(
    booleanSubqueryToFilterAttributes(subquery, true)
  ).isIncluded(Components.collection.get(componentId)!);
