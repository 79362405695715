import {
  MetamodelContextSettings,
  MetamodelFieldContext,
} from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import FieldStats from './fieldStats/FieldStats';

const ComponentFieldStats = ({
  context,
  metamodel,
}: {
  context: MetamodelFieldContext & MetamodelContextSettings;
  metamodel: Metamodel;
}) => <FieldStats context={context} metamodel={metamodel} />;

export default ComponentFieldStats;
