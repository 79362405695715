import {
  fetchWorkspaceDigest,
  fetchWorkspaceDigestHasError,
  fetchWorkspaceDigestPending,
  viewWorkspaceDigest,
  workspaceDigestAction,
} from 'streams/workspaceDigest/actions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs/operators';
import { opWorkspaceDigestViewModal } from 'workspaceDigestView/WorkspaceDigestModal';
import { handleError, workspaceApi } from '@ardoq/api';

const handleViewDigestClicked = routine(
  ofType(viewWorkspaceDigest),
  extractPayload(),
  tap(({ workspaceId, days }) => {
    opWorkspaceDigestViewModal(workspaceId, days);
  })
);

const handleFetchWorkspaceDigest = routine(
  ofType(fetchWorkspaceDigest),
  extractPayload(),
  switchMap(({ daysAgo, ws }) => {
    dispatchAction(fetchWorkspaceDigestPending(true));
    return workspaceApi.loadWorkspaceDigest(ws, daysAgo);
  }),
  handleError(() => {
    dispatchAction(fetchWorkspaceDigestPending(false));
    dispatchAction(fetchWorkspaceDigestHasError(true));
  }),
  tap(digest => {
    dispatchAction(
      workspaceDigestAction({
        hasError: false,
        isLoading: false,
        workspaceDigest: digest,
      })
    );
  })
);

export default collectRoutines(
  handleViewDigestClicked,
  handleFetchWorkspaceDigest
);
