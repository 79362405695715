import { ArdoqId } from '@ardoq/api-types';
import { RowType } from '@ardoq/table';
import { DocumentFolder } from './types';
import { ApiResponse, attachmentApi, folderApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

/**
 * This needs to be done by the API, as it needs to delete all descendants before deleting the folder itself.
 * Because the BE is not currently supporting this, we need to do it here.
 *
 * I would like to have this in the API package, but RowTypes is an enum which is not available there
 */

/**
 * @deprecated Should be done by the backend
 */
export const isEmptyFolder = (folder: DocumentFolder) => {
  if (folder.children.length !== 1) {
    return false;
  }
  // Because the types are lying, we need to check if the only child is the folder itself
  // as this is the case for an 'empty folder'
  const childId =
    folder.children[0].rowType === RowType.EMPTY_FOLDER
      ? folder.children[0].meta.folderId
      : folder.children[0]._id;
  return childId === folder._id;
};

/**
 * @deprecated Should be done by the backend
 */
export const deleteFoldersRecursive = async (
  folder: DocumentFolder
): ApiResponse<ArdoqId[]> => {
  const deletedIds = [];
  if (isEmptyFolder(folder)) {
    const response = await folderApi.delete(folder._id);
    if (isArdoqError(response)) {
      return response;
    }
    deletedIds.push(response);
    return deletedIds;
  }

  const children = folder.children ?? [];
  const attachments = [];
  for (const child of children) {
    if (child.rowType === RowType.FOLDER) {
      const response = await deleteFoldersRecursive(child);
      if (isArdoqError(response)) {
        return response;
      }
      deletedIds.push(...response);
    } else if (child.rowType === RowType.EMPTY_FOLDER) {
      // Using child.meta.folderId because an empty folder (which I believe is never actually used) has no _id
      const response = await folderApi.delete(child.meta.folderId!);
      if (isArdoqError(response)) {
        return response;
      }
      deletedIds.push(response);
    } else {
      attachments.push(child._id);
    }
  }
  if (attachments.length > 0) {
    const response = await attachmentApi.deleteMultiple(attachments);
    if (isArdoqError(response)) {
      return response;
    }
    deletedIds.push(...response);
  }
  const response = await folderApi.delete(folder._id);
  if (isArdoqError(response)) {
    return response;
  }
  deletedIds.push(response);
  return deletedIds;
};

/**
 * @deprecated Should be done by the backend
 */
export const deleteMultipleFolders = async (
  folders: DocumentFolder[]
): ApiResponse<ArdoqId[]> => {
  const deletedIds = [];
  for (const folder of folders) {
    const response = await deleteFoldersRecursive(folder);
    if (isArdoqError(response)) {
      return response;
    }
    deletedIds.push(...response);
  }
  return deletedIds;
};
