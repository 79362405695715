import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { dispatchAction } from '@ardoq/rxbeach';
import { openSelectFieldsViewSetting } from './actions';

export const NotEnoughComponentsDoq = () => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.SPIDER_CHART}-emptystate__not-enough-components`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.SPIDER_CHART}
      title="There is no data to show"
      message="Select a context with multiple children to see a Spider Chart."
    />
  </EmptyStatesDoqContainer>
);

type NoSelectedFieldsProps = {
  includeActions: boolean;
};

export const NoSelectedFields = ({ includeActions }: NoSelectedFieldsProps) => (
  <EmptyStatesDoqContainer
    data-testid={`${DoqType.SPIDER_CHART}-emptystate__no-selected-fields`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={DoqType.SPIDER_CHART}
      title="There is no data to show"
      message="Select at least one field to see a Spider Chart."
      buttonLabel={includeActions ? 'Select fields' : undefined}
      onButtonClick={
        includeActions
          ? () => dispatchAction(openSelectFieldsViewSetting())
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
