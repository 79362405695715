import {
  EdgeCreator,
  GraphBuilder,
  GraphBuilderItemEventArgs,
  IEdge,
  type ILabel,
  INode,
  type LabelCreator,
  NodeCreator,
  Point,
  Rect,
  Size,
} from '@ardoq/yfiles';
import {
  CollapsibleGraphGroup,
  GraphItem,
  type GraphNode,
  RelationshipDiagramGraphEdgeType,
  addedAndUpdatedGraphGroups,
  addedAndUpdatedGraphNodes,
  addedAndUpdatedGraphEdges,
} from '@ardoq/graph';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  NODE_HEIGHT,
  NODE_WIDTH,
  SPACE_FACTOR,
} from 'yfilesExtensions/styles/consts';
import {
  determineImgStyleSize,
  determineStyleSize,
} from 'yfilesExtensions/styles/util';
import { ProteanLayoutType } from '../../types';
import { ProteanGraphState } from '../types';
import buildSwimTable from './buildSwimTable';
import processUpdates from './processUpdates';
import ProteanGroupSizeConstraintProvider from '../styles/groupStyles/svg/ProteanGroupSizeConstraintProvider';
import mercator from '../mercator';
import sizeEmptyGroupNodes from 'tabview/blockDiagram/view/yFilesExtensions/sizeEmptyGroupNodes';

const groupNodeLayout =
  (state: ProteanGraphState) => (group: CollapsibleGraphGroup) => {
    const position = getNodePosition(state, group);
    const hasPosition =
      position !== Point.ORIGIN && isFinite(position.x) && isFinite(position.y);
    return hasPosition ? new Rect(position, SIZE_ONE) : null;
  };

const nodeLayout = (state: ProteanGraphState) => (graphNode: GraphNode) => {
  const img = graphNode.getImage();
  const shapeId = !img && graphNode.getShape();
  const { width: sourceWidth, height: sourceHeight } = img
    ? determineImgStyleSize(img)
    : shapeId
      ? determineStyleSize(`#${shapeId}`)
      : Size.EMPTY;

  const defaultWidth = img ? IMAGE_WIDTH : NODE_WIDTH;
  const defaultHeight = img ? IMAGE_HEIGHT : NODE_HEIGHT;

  const aspectRatio = sourceWidth / sourceHeight;
  const width = aspectRatio > 1 ? defaultWidth : defaultHeight * aspectRatio;
  const height = aspectRatio < 1 ? defaultHeight : defaultWidth / aspectRatio;
  const position = getNodePosition(state, graphNode);

  return new Rect(position, new Size(width, height));
};

const getNodeUpdatedHandler =
  (layoutChanged: boolean) =>
  <TItem extends GraphItem>(
    nodeCreator: NodeCreator<TItem>,
    { graph, item, dataItem }: GraphBuilderItemEventArgs<INode, TItem>
  ) => {
    const wasGroup = graph.isGroupNode(item);
    const isGroup = dataItem instanceof CollapsibleGraphGroup;
    if (layoutChanged || wasGroup !== isGroup) {
      nodeCreator.updateLayout(graph, item, dataItem);
    }
    nodeCreator.updateTag(graph, item, dataItem);
    graph.setStyle(
      item,
      isGroup ? graph.groupNodeDefaults.style : graph.nodeDefaults.style
    );
    nodeCreator.updateLabels(graph, item, dataItem);
  };

const labelUpdatedListener = (
  labelCreator: LabelCreator<GraphNode>,
  { graph, item, dataItem }: GraphBuilderItemEventArgs<ILabel, GraphNode>
) => {
  labelCreator.updateText(graph, item, dataItem);
  labelCreator.updateStyle(graph, item, dataItem);
  labelCreator.updatePreferredSize(graph, item, dataItem);
  labelCreator.updateLayoutParameter(graph, item, dataItem);
  labelCreator.updateTag(graph, item, dataItem);
};

const GRAPH_LAYOUTS_REQUIRING_REBUILD = new Set([
  ProteanLayoutType.Swimlanes,
  ProteanLayoutType.SpatialMap,
  ProteanLayoutType.GeographicMap,
]);
const shouldRebuildGraph = (
  previousLayoutType: ProteanLayoutType | null,
  nextLayoutType: ProteanLayoutType
) =>
  previousLayoutType === null ||
  GRAPH_LAYOUTS_REQUIRING_REBUILD.has(previousLayoutType) ||
  GRAPH_LAYOUTS_REQUIRING_REBUILD.has(nextLayoutType);

const getAncestorInGraph = (
  state: ProteanGraphState,
  item: GraphItem
): INode | null =>
  (item.parent &&
    (state.nodeMap?.get(item.parent.id) ??
      getAncestorInGraph(state, item.parent))) ??
  null;

const getNodePosition = (state: ProteanGraphState, item: GraphItem) => {
  const { id: nodeId } = item;
  const {
    viewSettings: { layoutType },
    nodePositions,
  } = state;
  switch (layoutType) {
    case ProteanLayoutType.SpatialMap:
      return nodePositions.get(nodeId)?.multiply(SPACE_FACTOR) ?? Point.ORIGIN;
    case ProteanLayoutType.GeographicMap: {
      const position = nodePositions.get(nodeId);
      if (!position) {
        return Point.ORIGIN;
      }
      const projected = mercator([position.x, position.y]);
      if (!projected) {
        return Point.ORIGIN;
      }
      return new Point(projected[0], projected[1]).multiply(SPACE_FACTOR);
    }
    default:
      if (state.nodeMap && item.parent) {
        // new nodes with an existing parent spawn at their nearest ancestor's center position.
        const ancestor = getAncestorInGraph(state, item);
        if (ancestor) {
          return ancestor.layout.center;
        }
      }
      return Point.ORIGIN;
  }
};

/**
 * Just a placeholder size for groups.
 *
 * Due to the variety of layouts performed by Protean Diagram, Size.EMPTY and Size.INFINITE are not suitable as "placeholder" sizes.
 *
 * Layouts are not guaranteed to work on groups at all, and leaving an empty or infinite group will produce an invalid result and can crash.
 *
 * 1x1 works fine as a placeholder. Any layout that works with groups is going to measure out the groups or at least assign a minimum size.
 */
const SIZE_ONE = new Size(1, 1);

const rebuildGraph = (state: ProteanGraphState) => {
  const {
    graphComponent,
    viewModel,
    viewSettings: { layoutType },
    nodesSource,
    groupsSource,
    edgesSource,
    graphBuilder,
    lastViewModel,
    lastViewSettings,
  } = state;
  if (!graphComponent.current) {
    return;
  }
  const { graph } = graphComponent.current;
  const currentViewModel = lastViewModel
    ? processUpdates(lastViewModel, viewModel)
    : viewModel;
  const { groups, nodes, edges } = currentViewModel;
  const lastLayout = lastViewSettings?.layoutType ?? null;
  const isUpdate =
    !shouldRebuildGraph(lastLayout, layoutType) &&
    [groups, nodes, edges].some(items => items.update.length);
  if (!isUpdate) {
    graph.clear();
  }

  const builder = (isUpdate && graphBuilder.current) || new GraphBuilder(graph);
  graphBuilder.current = builder;
  const groupsToCreate = addedAndUpdatedGraphGroups(currentViewModel);
  if (layoutType !== ProteanLayoutType.Swimlanes) {
    // don't add groups for swim lanes.
    if (isUpdate && groupsSource.current) {
      builder.setData(groupsSource.current, groupsToCreate);
    } else {
      groupsSource.current = builder.createGroupNodesSource({
        data: groupsToCreate,
        id: 'id',
        parentId: group => group.parent?.id,
        layout: groupNodeLayout(state),
      });
    }
  }
  const nodesToCreate = addedAndUpdatedGraphNodes(currentViewModel);
  if (isUpdate && nodesSource.current) {
    builder.setData(nodesSource.current, nodesToCreate);
  } else {
    nodesSource.current = builder.createNodesSource({
      data: nodesToCreate,
      id: 'id',
      labels: [graphNode => graphNode.getLabel() || null],
      parentId: graphNode => graphNode.parent?.id,
      layout: nodeLayout(state),
    });
  }

  const edgesToCreate = addedAndUpdatedGraphEdges(currentViewModel);

  if (isUpdate && edgesSource.current) {
    builder.setData(edgesSource.current, edgesToCreate);
  } else {
    edgesSource.current = builder.createEdgesSource({
      data: edgesToCreate,
      labels: [graphEdge => graphEdge.getLabel() || null],
      id: 'id',
      sourceId: edge => edge?.source,
      targetId: edge => edge?.target,
    });
  }

  const updateNodeFromEvent = getNodeUpdatedHandler(
    layoutType !== state.lastViewSettings?.layoutType
  );
  // #region attach events
  nodesSource.current.nodeCreator.addNodeUpdatedListener(updateNodeFromEvent);
  const nodeLabelCreator = nodesSource.current.nodeCreator.createLabelBinding(
    item => item.getLabel() || null
  );
  nodeLabelCreator.addLabelUpdatedListener(labelUpdatedListener);

  groupsSource.current?.nodeCreator.addNodeUpdatedListener(updateNodeFromEvent);
  const updateEdgeFromEvent = (
    _: EdgeCreator<RelationshipDiagramGraphEdgeType>,
    {
      item,
      dataItem,
    }: GraphBuilderItemEventArgs<IEdge, RelationshipDiagramGraphEdgeType>
  ) => {
    const label = item.labels.at(0);
    if (!label) {
      return;
    }
    graph.setLabelText(label, dataItem.getLabel());
  };
  edgesSource.current.edgeCreator.addEdgeUpdatedListener(updateEdgeFromEvent);
  // #endregion

  // #region build or rebuild graph
  if (isUpdate) {
    builder.updateGraph();
    graph.nodes.forEach(node => {
      const wasGroup = graph.isGroupNode(node);
      const isGroup = node.tag instanceof CollapsibleGraphGroup;
      if (wasGroup && !isGroup) {
        graph.getChildren(node).forEach(child => {
          graph.setParent(child, null);
        });
        graph.setIsGroupNode(node, false);
      }
    });
  } else {
    builder.buildGraph();
  }
  // #endregion

  sizeEmptyGroupNodes(
    graph,
    ProteanGroupSizeConstraintProvider.Instance.getMinimumSize
  );

  state.nodeMap = new Map(
    graph.nodes.map(node => [(node.tag as GraphItem).id, node])
  );
  // #region detach events
  nodesSource.current.nodeCreator.removeNodeUpdatedListener(
    updateNodeFromEvent
  );
  nodeLabelCreator.removeLabelUpdatedListener(labelUpdatedListener);
  groupsSource.current?.nodeCreator.removeNodeUpdatedListener(
    updateNodeFromEvent
  );
  edgesSource.current.edgeCreator.removeEdgeUpdatedListener(
    updateEdgeFromEvent
  );
  // #endregion

  if (layoutType === ProteanLayoutType.Swimlanes) {
    buildSwimTable({
      graph,
      groups: groupsToCreate,
      nodes: nodesToCreate,
      isVertical: Boolean(
        state.viewSettings.layoutOptions.swimlanes?.isVertical
      ),
    });
  }
};

export default rebuildGraph;
