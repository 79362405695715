import {
  OnLayoutGraphArgs,
  onLayoutGraphFactory,
} from 'tabview/graphComponent/onLayoutGraph';
import { GraphComponent, INode, Rect } from '@ardoq/yfiles';
import {
  FastGraphModelManager,
  OptimizationMode,
} from 'yfilesExtensions/fastGraphModelManager';

const unionRectReducer = (unionRect: Rect, currentNode: INode) => {
  const left = Math.min(unionRect.x, currentNode.layout.x);
  const top = Math.min(unionRect.y, currentNode.layout.y);
  const right = Math.max(
    unionRect.x + unionRect.width,
    currentNode.layout.x + currentNode.layout.width
  );
  const bottom = Math.max(
    unionRect.y + unionRect.height,
    currentNode.layout.y + currentNode.layout.height
  );
  return new Rect(left, top, right - left, bottom - top);
};
const baseOnLayoutGraph = onLayoutGraphFactory({
  preserveViewport: false,
  zoomComponentId: null,
});
const getContentRectForWebGL = (graphComponent: GraphComponent) => {
  return graphComponent.graph.nodes.reduce(unionRectReducer, Rect.EMPTY);
};
const isWebGL = (
  graphComponent: GraphComponent
): graphComponent is GraphComponent & {
  graphModelManager: FastGraphModelManager;
} =>
  graphComponent.graphModelManager instanceof FastGraphModelManager &&
  graphComponent.graphModelManager.graphOptimizationMode ===
    OptimizationMode.WEBGL;
export const onLayoutGraph = (args: OnLayoutGraphArgs) => {
  const { graphComponent } = args;

  if (isWebGL(graphComponent)) {
    // we're not getting a content rect from this layout.
    graphComponent.contentRect = getContentRectForWebGL(graphComponent);
  }
  baseOnLayoutGraph(args);
  (graphComponent.graphModelManager as FastGraphModelManager).dirty = true;
  graphComponent.invalidate();
};
