import { returnZero } from '@ardoq/common-helpers';
import { RelationshipsNode } from './types';

const arrayOfZeros = (length: number) => Array.from({ length }, returnZero);

export function forceLayout(
  nodes: RelationshipsNode[],
  margin: number,
  random: () => number,
  cycles: number
) {
  const restitution = 0.25;
  const center = 0.001;
  const repulsion = 0.1;
  const count = nodes.length;

  const ddx: number[] = new Array<number>(count);
  const ddy: number[] = new Array<number>(count);

  const nodesDx = arrayOfZeros(count);
  const nodesDy = arrayOfZeros(count);
  for (let i = 0; i < cycles; ++i) {
    // #region initialise acceleration
    for (let j = 0; j < count; ++j) {
      ddx[j] = 0.0;
      ddy[j] = 0.0;
    }
    // #endregion

    // #region accumulate repulsive force
    for (let j = 0; j < count; ++j) {
      const mj = nodes[j].r;

      for (let k = j + 1; k < count; ++k) {
        const mk = nodes[k].r;

        let fx = nodes[j].x - nodes[k].x;
        let fy = nodes[j].y - nodes[k].y;
        let d2 = fx ** 2 + fy ** 2;

        if (d2 < 1) {
          /* create a small random unit force */
          fx = random() - 0.5;
          fy = random() - 0.5;
          d2 = fx ** 2 + fy ** 2;
        }

        const d0 = mj + mk;
        const d1 = d0 + 2 * margin;

        if (d2 < d1 * d1) {
          const d = Math.sqrt(d2);
          const p = 1 - Math.min(1, Math.max(0, (d - d0) / (d1 - d0)));

          fx = (fx * repulsion * p) / d;
          fy = (fy * repulsion * p) / d;

          ddx[j] += fx * mj;
          ddy[j] += fy * mj;

          ddx[k] -= fx * mk;
          ddy[k] -= fy * mk;
        }
      }
    }
    // #endregion

    // #region accumulate center force
    for (let j = 0; j < count; ++j) {
      const m = Math.exp(-Math.hypot(ddx[j], ddy[j]));
      const mj = nodes[j].r;

      let fx = nodes[j].x;
      let fy = nodes[j].y;
      const d = Math.sqrt(fx ** 2 + fy ** 2) - mj;

      if (d < 1) {
        fx = random() - 0.5;
        fy = random() - 0.5;
      }

      ddx[j] -= fx * center * m;
      ddy[j] -= fy * center * m;
    }
    // #endregion

    // #region integrate force to velocity and velocity to position
    for (let j = 0; j < count; ++j) {
      nodesDx[j] *= restitution;
      nodesDx[j] += ddx[j];
      nodesDy[j] *= restitution;
      nodesDy[j] += ddy[j];

      nodes[j].x += nodesDx[j];
      nodes[j].y += nodesDy[j];
    }
    // #endregion

    // possibly stop at the time limit
  }

  let e2 = 0.0;

  for (let j = 0; j < count; ++j) {
    const e2j = ddx[j] ** 2 + ddy[j] ** 2;

    if (e2j > e2) {
      e2 = e2j;
    }
  }

  return e2;
}
