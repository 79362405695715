import { TITLE_MARGIN } from 'utils/charting/consts';
import { LABEL_MARGIN, TICK_LENGTH, labelHeight } from '@ardoq/graph';
import { BubbleChartAxisProperties } from './types';
import { AxisLine, AxisTick, AxisTitle, YAxisLabel } from './atoms';
import { formatAxisLabel } from './util';

export const YAxis = (args: BubbleChartAxisProperties) => {
  const { margin, height, width, ticks, scaler, grid, title } = args;
  const gridLineLength = grid ? `${width}` : `-${TICK_LENGTH}`;
  const titleX = -margin.left + labelHeight / 2 + TITLE_MARGIN;
  const titleY = height / 2;
  return (
    <g transform={`translate(${margin.left}, ${margin.top})`}>
      {ticks.map(tick => (
        <g key={tick} transform={`translate(0, ${scaler(tick)})`}>
          <AxisTick x2={gridLineLength} />
          <YAxisLabel x={-(TICK_LENGTH + LABEL_MARGIN)} dy=".32em">
            {formatAxisLabel(tick)}
          </YAxisLabel>
        </g>
      ))}
      <AxisLine d={`M-${TICK_LENGTH},0H0V${height}H-${TICK_LENGTH}`} />
      <AxisTitle
        x={titleX}
        y={titleY}
        transform={`rotate(-90, ${titleX}, ${titleY})`}
      >
        {title}
      </AxisTitle>
    </g>
  );
};
