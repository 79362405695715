import {
  Action,
  ActionWithoutPayload,
  actionCreator,
  action$,
  dispatchAction,
  ofType,
  ActionCreator,
  withNamespace,
  NamespacedActionCreator,
  NamespacedAction,
  ActionCreatorWithoutNamespace,
  ActionWithoutNamespace,
} from '@ardoq/rxbeach';
import { identity } from 'lodash';
import { firstValueFrom } from 'rxjs';

export type ActionType = ActionWithoutPayload['type'];

export const startAction = actionCreator('[ardoq] START_ACTION');

/**
 * Dispatches an action and waits for either a success or error action to be dispatched
 * Note that a namespaced action requires a namespace to be passed.
 *
 * @deprecated You should move your logic to a routine
 */
export function dispatchActionAndWaitForResponse<
  A extends NamespacedAction<unknown>,
  SuccessPayload,
  ErrorPayload,
>(
  action: A,
  successAction: NamespacedActionCreator<SuccessPayload>,
  errorAction: NamespacedActionCreator<ErrorPayload>,
  namespace: string
): Promise<NamespacedAction<SuccessPayload | ErrorPayload>>;
export function dispatchActionAndWaitForResponse<
  A extends ActionWithoutNamespace<unknown>,
  SuccessPayload,
  ErrorPayload,
>(
  action: A,
  successAction: ActionCreatorWithoutNamespace<SuccessPayload>,
  errorAction: ActionCreatorWithoutNamespace<ErrorPayload>
): Promise<ActionWithoutNamespace<SuccessPayload | ErrorPayload>>;
export function dispatchActionAndWaitForResponse<
  A extends Action<unknown>,
  SuccessPayload,
  ErrorPayload,
>(
  action: A,
  successAction: ActionCreator<SuccessPayload>,
  errorAction: ActionCreator<ErrorPayload>,
  namespace?: string
): Promise<Action<SuccessPayload | ErrorPayload>> {
  const result = firstValueFrom(
    action$.pipe(
      namespace ? withNamespace(namespace) : identity,
      ofType<SuccessPayload | ErrorPayload>(successAction, errorAction)
    )
  );
  setTimeout(() =>
    namespace ? dispatchAction(action, namespace) : dispatchAction(action)
  );
  return result;
}
