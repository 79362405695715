import { getRightMenuConfig as getRightMenuConfigBase } from '@ardoq/settings-bar';
import type { SettingsConfig, ExportConfig } from '@ardoq/view-settings';
import { toggleLegend } from 'presentation/viewPane/actions';
import { ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { isViewEligibleForNewAssets } from 'viewDeprecation/restrictedViews';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { viewLegendCommands } from '@ardoq/view-legend';

type DefaultLegendOnClickArgs = {
  isLegendActive: boolean | undefined;
  viewId: ViewIds;
};
export const defaultLegendOnClick = ({
  isLegendActive,
  viewId,
}: DefaultLegendOnClickArgs) => {
  viewLegendCommands.updateViewLegendSettings({
    viewId,
    isActive: Boolean(isLegendActive),
  });
  dispatchAction(
    toggleLegend({
      viewId: viewId,
      legendActive: !!isLegendActive,
    }),
    viewId
  );
};

const getRightMenuConfig = (
  args: Parameters<typeof getRightMenuConfigBase>[0]
): SettingsConfig[] => {
  const result = getRightMenuConfigBase(args);
  if (!result.length) {
    return result;
  }
  const exportConfig = result.at(-1) as ExportConfig;
  const userHasWriteAccess =
    currentUserInterface.getCurrentUserAttributes().writeAccess;
  exportConfig.addToPresentation =
    // if a view is in the deprecation phase > 2, "Add to presentation" button should not appear. For more info refer to
    // https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2413002789/Discontinued+Views+engineering+perspective.
    // Unfortunately, this is the only function that *all* our view exports have in common, and refactoring is out of
    // scope right now, so I overwrite the "addToPresentation" property here.
    isViewEligibleForNewAssets(args.viewId) && userHasWriteAccess
      ? exportConfig.addToPresentation
      : undefined;
  return result;
};

export default getRightMenuConfig;
