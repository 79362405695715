import UserSettings from 'models/UserSettings';
import { useEffect, useState } from 'react';

export default (key: string, viewId?: string): [boolean, () => void] => {
  const [userSetting, setUserSetting] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setUserSetting(
        (await new UserSettings(viewId).get<boolean>(key)) ?? false
      );
    })();
  }, [viewId, key]);

  const toggleUserSetting = () => {
    new UserSettings(viewId).set(key, !userSetting);
    setUserSetting(!userSetting);
  };

  return [userSetting, toggleUserSetting];
};
