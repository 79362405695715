export const HEADER_CELL_TEXT_MAX_WIDTH = 700;
export const HEADER_CELL_ICON_MARGIN_LEFT = 2;
export const TEXT_CELL_TEXT_MAX_WIDTH = 700;
export const REFERENCE_LIST_CELL_ITEM_MAX_WIDTH = 700;
export const REFERENCE_LIST_CELL_ITEM_COMMA_RIGHT_MARGIN = 5;
export const REFERENCE_LIST_CELL_ITEM_COMMA_LEFT_MARGIN = 2;
export const COMPONENT_PATH_LIST_ITEM_MAX_WIDTH = 700;
export const COMPONENT_PATH_LIST_ITEM_BULLET_SIZE = 20;
export const TABLE_CELL_BORDER_WIDTH = 1;
export const TABLE_CELL_HORIZONTAL_PADDING = 8;
export const EXPAND_ICON_SIZE = 20;
export const UNSET_CHECKBOX_LABEL = 'Not set';
