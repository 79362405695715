import { MouseEvent, Component, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { classes, closest } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { Icon, IconName } from '@ardoq/icons';
import { untagComponent, untagReference } from 'streams/tags/TagActions';
import { isUndefinedOrNull } from 'utils/collectionUtil';
import {
  ActiveTagProperties,
  ComponentModel,
  ReferenceModel,
  TagModel,
  TagProperties,
  TagsProperties,
  TagscapeViewSettings,
} from './types';
import { colors, s32, s4, s8 } from '@ardoq/design-tokens';
import {
  BASE_TRANSITION,
  COMPONENT_ID_SELECTOR,
  TAG_ID_ATTRIBUTE,
} from 'consts';
import { tagscapeCommands } from './commands';
import { disabledCSS } from '@ardoq/style-helpers';

const BASE_SHADOW = '0px 2px 2px rgba(0, 0, 0, 0.12)';

const TagscapeLane = styled.div`
  float: left;
  background-color: color-mix(in srgb, ${colors.blue50} 20%, white 80%);
  border-radius: 2px;
  border: 1px solid color-mix(in srgb, ${colors.blue50} 40%, white 60%);
  transition: ${BASE_TRANSITION};
  &.disabled {
    ${disabledCSS}
  }
  &.canDrop {
    background-color: ${colors.blue50} !important;
    border-radius: 2px;
    color: ${colors.white} !important;
  }
  &.canDrop.hoverDrop {
    background-color: ${colors.blue50} !important;
    border-radius: 2px;
    box-shadow: ${BASE_SHADOW};
    cursor: copy;
    .tag {
      cursor: inherit;
      color: ${colors.white};
    }
  }
  &.removable {
    background: ${colors.grey95};
    color: ${colors.blue50}CC;
    display: none;
  }

  .forExport & {
    white-space: nowrap;
  }
`;
const TagscapeLanes = styled.div`
  width: 100%;
  clear: both;
  float: left;
  user-select: none;
  .tag {
    background-color: transparent;
    border: none;
    color: ${colors.blue50};
    font-size: 1em;
  }
  ${TagscapeLane} {
    width: 98%;
    clear: both;
    background-color: color-mix(in srgb, ${colors.blue50} 10%, white 90%);
    border-radius: 2px;
    border: 1px solid color-mix(in srgb, ${colors.blue50} 40%, white 60%);
    color: ${colors.blue50};
    display: inline-block;
    line-height: 1.4;
    margin: ${s8} ${s8} 0 0;
    .tag {
      color: inherit;
      padding: ${s8};
    }
  }
  .tagscapecomp,
  .tagscapereference {
    background-color: ${colors.white};
    border: 1px solid ${colors.grey80};
    border-radius: 2px;
    display: flex;
    float: left;
    line-height: ${s32};
    padding: 0 0 0 ${s4};
    margin: ${s4};
    transition: ${BASE_TRANSITION};
    &.highlighted {
      box-shadow: 0px 0px 0px 2px
        color-mix(in srgb, ${colors.blue50} 80%, white 20%);
    }
    &.tagscapereference {
      color: ${colors.purple35};
    }
    &.ui-draggable-dragging {
      z-index: 1;
    }
  }
`;
const RemoveTagSpan = styled.span`
  color: ${colors.blue50}A6;
  border-left: 1px solid ${colors.grey80};
  display: inline-block;
  line-height: inherit;
  margin-left: ${s4};
  padding: 0 ${s4};
  transition: ${BASE_TRANSITION};
  &:hover {
    background-color: color-mix(in srgb, ${colors.blue50} 50%, white 50%);
    border-left: 1px solid color-mix(in srgb, ${colors.blue50} 80%, white 20%);
    color: ${colors.blue50};
    text-decoration: none;
  }
  i {
    vertical-align: middle;
  }
`;
const TagParking = styled.div`
  width: 100%;
  clear: both;
  float: left;
  user-select: none;
  ${TagscapeLane} {
    display: inline-block;
    clear: none;
    color: ${colors.blue50};
    cursor: pointer;
    border: 1px solid color-mix(in srgb, ${colors.blue50} 40%, white 60%);
    border-radius: 2px;
    padding: ${s4} ${s8};
    margin: ${s8};
    margin-left: 0;
    &:hover {
      border: 1px solid ${colors.blue60};
    }
  }
  .tag {
    background-color: transparent;
    border: none;
    color: inherit;
  }
  .tagscapecomp,
  .tagscapereference {
    display: none;
  }
`;
const calculateName = (viewState: TagscapeViewSettings, tag: TagModel) => {
  if (!viewState.count || (viewState.excludeRefs && viewState.excludeComps)) {
    return tag.name;
  }
  if (viewState.excludeRefs) {
    return `${tag.name} (${tag.components.length})`;
  }
  if (viewState.excludeComps) {
    return `${tag.name} (${tag.references.length})`;
  }

  return `${tag.name} (C: ${tag.components.length} / R: ${tag.references.length})`;
};
const untagComp = (e: MouseEvent) => {
  e.stopPropagation();
  const tagTarget = closest<HTMLElement>(e.target, '[data-tag-cid]');
  const compTarget = closest<HTMLElement>(e.target, COMPONENT_ID_SELECTOR);
  if (tagTarget === null || compTarget === null) {
    return;
  }
  const { tagCid } = tagTarget.dataset;
  const { componentId: componentCid } = compTarget.dataset;
  if (!(tagCid && componentCid)) return;
  dispatchAction(untagComponent({ tagCid, componentCid }));
};
const untagRef = (e: MouseEvent) => {
  e.stopPropagation();
  const tagTarget = closest<HTMLElement>(e.target, '[data-tag-cid]');
  const refTarget = closest<HTMLElement>(e.target, '[data-reference-cid]');
  if (isUndefinedOrNull(tagTarget) || isUndefinedOrNull(refTarget)) return;
  const { tagCid } = tagTarget.dataset;
  const { referenceCid } = refTarget.dataset;
  if (!(tagCid && referenceCid)) return;

  dispatchAction(untagReference({ tagCid, referenceCid }));
};
const RemoveTagButton = (props: HTMLAttributes<HTMLSpanElement>) => (
  <RemoveTagSpan {...props} title="Click to remove tag from object">
    <Icon iconName={IconName.CLOSE} />
  </RemoveTagSpan>
);
const TaggedReference = ({ reference }: { reference: ReferenceModel }) => {
  const handleMouseOver = () => {
    tagscapeCommands.setHoveredItemState(reference.id);
  };
  const handleClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    tagscapeCommands.setFocusedItemState(reference.id, reference.isFocused);
    if (reference.isFocused) {
      tagscapeCommands.setHoveredItemState(null);
    }
  };
  return (
    <div
      className={classes(
        'tagscapereference',
        (reference.isHovered || reference.isFocused) && 'highlighted'
      )}
      data-reference-cid={reference.cid}
      data-global-handler-id={reference.cid}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => tagscapeCommands.setHoveredItemState(null)}
      onClick={handleClick}
    >
      <div className={`integration reference ${reference.cid}`}>
        {reference.name}
      </div>
      <RemoveTagButton onClick={untagRef} className="prevent-context-change" />
    </div>
  );
};
const TaggedComponent = ({ component }: { component: ComponentModel }) => {
  const handleMouseOver = () => {
    tagscapeCommands.setHoveredItemState(component.id);
  };
  const handleClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    tagscapeCommands.setFocusedItemState(component.id, component.isFocused);
    if (component.isFocused) {
      tagscapeCommands.setHoveredItemState(null);
    }
  };
  return (
    <div
      className={classes(
        'tagscapecomp',
        (component.isHovered || component.isFocused) && 'highlighted'
      )}
      data-component-id={component.cid}
      data-global-handler-id={component.cid}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => tagscapeCommands.setHoveredItemState(null)}
      onClick={handleClick}
    >
      <div
        className={`comp component ${component.cid} ${component.typeId}`}
        style={{ color: component.color }}
      >
        {component.name}
      </div>
      <RemoveTagButton onClick={untagComp} />
    </div>
  );
};
const ActiveTag = ({ tag, excludeComps, excludeRefs }: ActiveTagProperties) => (
  <div key={tag.cid} data-tag-cid={tag.cid}>
    {!excludeComps &&
      tag.components.map(c => <TaggedComponent key={c.cid} component={c} />)}
    {!excludeRefs &&
      tag.references.map(r => <TaggedReference key={r.cid} reference={r} />)}
  </div>
);
const Tag = ({
  viewState,
  tag,
  toggleActive,
  active,
  disabled,
}: TagProperties) => {
  return (
    <TagscapeLane
      className={classes(
        'ui-droppable',
        !tag.isUsed && 'removable',
        tag.droppable && !disabled && 'canDrop',
        disabled && 'disabled'
      )}
      id={`taglane${tag.cid}`}
      data-id={tag.cid}
      data-drop-context="tagscape"
      style={{ display: 'block' }}
      {...{ [TAG_ID_ATTRIBUTE]: tag.id }}
    >
      <div
        onClick={toggleActive}
        className="tagscapetag tag"
        data-tag={tag.name}
      >
        <span data-tooltip-text={tag.description}>
          {calculateName(viewState, tag)}
        </span>
        {active && <ActiveTag tag={tag} {...viewState} />}
      </div>
    </TagscapeLane>
  );
};

type TagState = Record<string, boolean>;
const active = (state: TagState) => (tag: TagModel) => Boolean(state[tag.cid]);
const inActive = (state: Partial<TagState>) => (tag: TagModel) =>
  !state[tag.cid];

export class Tags extends Component<TagsProperties, TagState> {
  constructor(props: TagsProperties) {
    super(props);
    const tmpState = props.tags.reduce<TagState>(
      (acc, tagModel) => ({ ...acc, [tagModel.cid]: false }),
      {}
    );
    this.state = tmpState;
  }
  toggleActive = (e: MouseEvent) => {
    const target = closest<HTMLElement>(e.target, '[data-id]');
    if (target === null) return;
    const id = target.dataset.id;
    if (id) this.setState(state => ({ ...state, [id]: !state[id] }));
  };
  render() {
    const { tags, viewState, disabled } = this.props;
    return (
      <>
        <TagscapeLanes>
          {tags.filter(active(this.state)).map(t => (
            <Tag
              key={t.cid}
              tag={t}
              viewState={viewState}
              toggleActive={this.toggleActive}
              active={true}
              disabled={disabled}
            />
          ))}
        </TagscapeLanes>
        <TagParking>
          {tags.filter(inActive(this.state)).map(t => (
            <Tag
              key={t.cid}
              tag={t}
              viewState={viewState}
              toggleActive={this.toggleActive}
              active={false}
              disabled={disabled}
            />
          ))}
        </TagParking>
      </>
    );
  }
}
