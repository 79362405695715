import { isEqual } from 'lodash';
import { map, distinctUntilChanged } from 'rxjs';
import { context$ } from 'streams/context/context$';

export const contextSelection$ = context$.pipe(
  map(
    ({ scenarioId, workspaceId, componentId }) =>
      componentId || scenarioId || workspaceId
  ),
  distinctUntilChanged(isEqual)
);
