import { returnPayload } from '@ardoq/common-helpers';
import {
  persistentReducedStream,
  reducer,
  actionCreator,
} from '@ardoq/rxbeach';

export const setIsUpdatingAppState = actionCreator<boolean>(
  '[global] IS_UPDATING_APP_STATE'
);

const handleSetIsUpdatingAppState = reducer(
  setIsUpdatingAppState,
  returnPayload<boolean>
);

const defaultState = false;

const reducers = [handleSetIsUpdatingAppState];

export const isUpdatingAppState$ = persistentReducedStream(
  'isUpdatingAppState$',
  defaultState,
  reducers
);
