import { ArdoqId, ViewIds } from '@ardoq/api-types';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import { LoadedScenarioData } from 'loadedScenarioData$';
import { GROUP_BY_PARENT_ALL } from '@ardoq/graph';
import { buildGraph } from 'modelInterface/graph/buildGraphForWorkspaceMode';
import type { ShowRelatedComponentsShape } from 'scenarioRelated/types';
import {
  buildTraversalViewModel,
  getGroupingRules,
  getTraversalConfiguration,
} from 'tabview/graphViews/traversalViewModel';
import { getStartSet } from 'tabview/graphViews/util';
import type {
  HierarchicalTreemapNode,
  HierarchicalTreemapViewModel,
  HierarchicalTreemapViewSettings,
} from '../types';
import reduceNodes from './reduceNodes';
import * as d3 from 'd3';
import { componentInterface } from 'modelInterface/components/componentInterface';
import {
  type RawGraphItem,
  getGraphInterfaceWithModelInterfaces,
  hasDescendantModelId,
} from '@ardoq/graph';
import { returnZero } from '@ardoq/common-helpers';

const hasDescendantModelIdIn = (
  item: RawGraphItem,
  descendantModelIds: Set<ArdoqId>
): boolean =>
  Boolean(
    (item.modelId && descendantModelIds.has(item.modelId)) ||
      item.children?.some(child =>
        hasDescendantModelIdIn(child, descendantModelIds)
      )
  );

const getChildren = ({ nodes }: HierarchicalTreemapNode) => nodes;
const getSingleNodeValueImpl =
  (sizeByFieldName: string) =>
  ({ modelId }: HierarchicalTreemapNode) =>
    (!modelId
      ? 0
      : (componentInterface.getFieldValue(modelId, sizeByFieldName) as
          | number
          | null)) ?? 0;

const getNodeValueImpl = (sizeByFieldName: string | null) => {
  if (!sizeByFieldName) {
    return returnZero;
  }
  return getSingleNodeValueImpl(sizeByFieldName);
};
const coerceHierarchyNodeValue = (
  node: d3.HierarchyNode<HierarchicalTreemapNode>
) => node.value ?? 0;

const sortHierarchyNodes = (
  a: d3.HierarchyNode<HierarchicalTreemapNode>,
  b: d3.HierarchyNode<HierarchicalTreemapNode>
) => coerceHierarchyNodeValue(b) - coerceHierarchyNodeValue(a);

type BuildViewModelArgs = {
  context: ContextShape;
  graph: GraphModelShape;
  viewSettings: HierarchicalTreemapViewSettings;
  loadedScenarioData: LoadedScenarioData;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
  showScopeRelated: boolean;
  viewId: ViewIds;
};
const buildViewModel = ({
  context,
  graph,
  viewSettings,
  loadedScenarioData,
  scenarioRelatedComponents,
  showScopeRelated,
  viewId,
}: BuildViewModelArgs): HierarchicalTreemapViewModel => {
  const startSet = getStartSet(context);

  const groupingRules = getGroupingRules({
    loadedScenarioData,
    showScopeRelated,
    scenarioRelatedComponentIds: scenarioRelatedComponents.componentIds,
  });

  const { traverseOptions } = getTraversalConfiguration({
    viewSettings,
    graphModel: graph,
    scenarioRelatedComponents,
  });

  /**
   * remove interface calls from viewModel scope and pass GraphContext
   * as argument to use scopeData utils.
   * Currently, the graphInterface is used to ensure that the graph can
   * be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  const traversalViewModel = buildTraversalViewModel({
    graphInterface,
    graphModel: graph,
    startSet,
    traverseOptions,
  });

  const { rootGroups, componentMap } = buildGraph({
    graphInterface,
    startSet,
    graph,
    viewId,
    traverseOptions,
    groupingRules: groupingRules.length ? groupingRules : [GROUP_BY_PARENT_ALL],
  });

  const rootItems = rootGroups.length
    ? [
        ...rootGroups,
        ...Array.from(componentMap.values()).filter(
          item => !item.children?.length && !item.parentId
        ),
      ]
    : Array.from(componentMap.values());

  const startSetSet = new Set(startSet);

  const rootItemsInContext = context.componentId
    ? rootItems.filter(item => hasDescendantModelId(item, context.componentId))
    : rootItems.filter(item => hasDescendantModelIdIn(item, startSetSet));
  const { nodes } = rootItemsInContext.reduce(reduceNodes, { nodes: [] });

  const rootNode: HierarchicalTreemapNode = {
    id: 'root',
    modelId: null,
    label: null,
    nodes,
    className: null,
  };

  const { sizeByFieldName } = viewSettings;
  const getNodeValue = getNodeValueImpl(sizeByFieldName);
  const hierarchy = d3
    .hierarchy(rootNode, getChildren)
    .sum(getNodeValue)
    .sort(sortHierarchyNodes);

  return { ...traversalViewModel, hierarchy };
};
export default buildViewModel;
