import { IGraph, INode } from '@ardoq/yfiles';
import * as d3 from 'd3';
import { bloat } from '@ardoq/graph';
import type { Point } from '@ardoq/math';

const nodeBoundingShape = (
  node: INode,
  graph: IGraph,
  margin: number,
  nodeBoundingShapeWithCaching: (node: INode) => Point[]
): Point[] => {
  const leafPerimeter: Point[] = [
    [node.layout.x, node.layout.y],
    [node.layout.x + node.layout.width, node.layout.y],
    [node.layout.x + node.layout.width, node.layout.y + node.layout.height],
    [node.layout.x, node.layout.y + node.layout.height],
  ];

  const children = graph.getChildren(node);
  if (children.size <= 1) {
    return leafPerimeter;
  }

  const bounds = Array.from(children).flatMap(child =>
    nodeBoundingShapeWithCaching(child)
  );
  return bloat(d3.polygonHull(bounds) ?? leafPerimeter, margin);
};

export default nodeBoundingShape;
