import { createRef, Component } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { getViewModel$ } from './viewModel$';
import { action$, dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { SettingsBar } from '@ardoq/settings-bar';
import {
  type SettingsConfig,
  SettingsType,
  viewSettingsConsts,
} from '@ardoq/view-settings';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import getRightMenuConfig, {
  defaultLegendOnClick,
} from 'viewSettings/getRightMenuConfig';
import { getExportsForYFilesView } from '@ardoq/export';
import { createDropdownSliderOptionManager } from 'viewSettings/settingsHelper';
import { context$ } from 'streams/context/context$';
import { updateViewSettings } from '@ardoq/view-settings';
import {
  ArdoqGraphComponent,
  ArdoqGraphComponentForwardRefType,
  PerformanceTrackedGraphView,
} from '../graphComponent/ArdoqGraphComponent';
import {
  CircularRelationshipDiagramViewModel,
  CircularRelationshipDiagramViewSettings,
} from './types';
import { viewHasLegend } from 'views/metaInfoTabs';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { ExplorerLabelStyle } from './ExplorerLabelStyle';
import { showLabels } from './explorerViewUtil';
import {
  CircularLayoutData,
  GraphComponent,
  Insets,
  LayoutDescriptor,
  PolylineEdgeStyle,
  VoidLabelStyle,
  VoidNodeStyle,
} from '@ardoq/yfiles';
import { applySelectionDecorators } from 'tabview/graphComponent/yfilesHelper';
import { DEGREES_OF_RELATIONSHIP } from '@ardoq/global-consts';
import { type HasViewInstanceId } from '@ardoq/graph';
import { Node } from 'graph/node';
import { build } from './graphBuilder';
import ArdoqEdgeStyle from 'yfilesExtensions/styles/ArdoqEdgeStyle';
import {
  FastGraphModelManager,
  OptimizationMode,
} from 'yfilesExtensions/fastGraphModelManager';
import { configureGraphModelManager } from 'yfilesExtensions/view/fastGraphLayout';
import { onLayoutGraph } from './graphLayout';
import { booleanOrAutoDropdown } from 'tabview/relationshipDiagrams/leftMenuItems';
import { BooleanOrAuto } from 'tabview/types';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import ViewAndLegendContainer from 'tabview/ViewAndLegendContainer';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';

const VIEW_ID = ViewIds.CIRCULAR_RELATIONSHIP_DIAGRAM;
const isWebGLSupported = () => {
  const testElement = document.createElement('canvas');
  return (
    !!testElement.getContext('webgl') ||
    !!testElement.getContext('experimental-webgl')
  );
};
const checkStylesEnabled = (graphComponent: GraphComponent) =>
  graphComponent.graph.edgeDefaults.style instanceof ArdoqEdgeStyle;
const configureRendering = (
  graphComponent: GraphComponent,
  enableStyles: boolean
) => {
  graphComponent.addZoomChangedListener(() => {
    if (!checkStylesEnabled(graphComponent)) {
      return;
    }
    const labelsWereShown =
      graphComponent.graph.edgeDefaults.labels.style !==
      VoidLabelStyle.INSTANCE;

    const showLabelsNow = showLabels(graphComponent.zoom);
    graphComponent.graph.edgeDefaults.labels.style =
      graphComponent.graph.nodeDefaults.labels.style = showLabelsNow
        ? new ExplorerLabelStyle(graphComponent)
        : VoidLabelStyle.INSTANCE;

    if (showLabelsNow !== labelsWereShown) {
      const fastGraphModelManager =
        graphComponent.graphModelManager as FastGraphModelManager;
      fastGraphModelManager.dirty = true;
      graphComponent.graph.labels.forEach(label =>
        graphComponent.graphModelManager.update(label)
      );
      graphComponent.invalidate();
    }
  });
  const optimizationMode =
    enableStyles || !isWebGLSupported()
      ? OptimizationMode.SVG_IMAGE
      : OptimizationMode.WEBGL;

  if (graphComponent.graphModelManager instanceof FastGraphModelManager) {
    graphComponent.graphModelManager.graphOptimizationMode = optimizationMode;
  } else {
    configureGraphModelManager(graphComponent, optimizationMode);
    const fastGraphModelManager =
      graphComponent.graphModelManager as FastGraphModelManager;
    fastGraphModelManager.zoomThreshold = Number.MAX_SAFE_INTEGER;
  }
  graphComponent.graph.edgeDefaults.style = enableStyles
    ? new ArdoqEdgeStyle({ enableBridges: false })
    : new PolylineEdgeStyle();
  graphComponent.graph.groupNodeDefaults.style = VoidNodeStyle.INSTANCE;
  graphComponent.graph.edgeDefaults.labels.style =
    graphComponent.graph.nodeDefaults.labels.style = enableStyles
      ? new ExplorerLabelStyle(graphComponent)
      : VoidLabelStyle.INSTANCE;
  applySelectionDecorators(
    graphComponent,
    enableStyles ? 25 : 5,
    enableStyles ? 10 : 5
  );
  graphComponent.minimumZoom = 0.0000001;
};
const buildGraph = (
  graphComponent: GraphComponent,
  viewModel: CircularRelationshipDiagramViewModel,
  enableStyles: boolean
) => {
  const stylesWereEnabled = checkStylesEnabled(graphComponent);
  const enableStylesChanged = enableStyles !== stylesWereEnabled;

  if (enableStylesChanged) {
    configureRendering(graphComponent, enableStyles);
    graphComponent.invalidate();
  }
  build(graphComponent.graph, viewModel, enableStyles, enableStylesChanged);
  return {
    isAboveLimit: false,
    isEmpty: false,
    canBypass: false,
    hasLayoutUpdate: true,
  };
};
const CONSIDER_NODE_LABELS_THRESHOLD = 100;
const configureLayout = (
  graphComponent: GraphComponent,
  groups: GraphItemsModel<Node>
) => {
  const layoutDescriptor: LayoutDescriptor = {
    name: 'CircularLayout',
    properties: {
      layoutStyle:
        groups.add.length || groups.update.length
          ? 'custom-groups'
          : 'bcc-compact',
      considerNodeLabels:
        graphComponent.graph.nodes.size < CONSIDER_NODE_LABELS_THRESHOLD,
      placeChildrenOnCommonRadius: false,
      maximumDeviationAngle: 120,
    },
  };

  const layoutData = new CircularLayoutData();
  layoutData.customGroups.delegate = node =>
    graphComponent.graph.getParent(node);
  return {
    layoutDescriptor,
    layoutData,
    updateContentRect: true,
    animateViewport: true,
    targetBoundsInsets: new Insets(100),
  };
};

const getSliderOption = createDropdownSliderOptionManager(
  VIEW_ID,
  new Map(),
  onViewSettingsUpdate
);
const setEnableStyles = (
  viewstate: CircularRelationshipDiagramViewSettings,
  value: BooleanOrAuto
) => {
  viewstate.enableStyles = value;
  dispatchAction(
    updateViewSettings({
      viewId: VIEW_ID,
      settings: { enableStyles: value },
      persistent: true,
    })
  );
};
const getLeftMenuConfig = (
  viewstate: CircularRelationshipDiagramViewSettings,
  stylesEnabled: boolean
): SettingsConfig[] => {
  return [
    {
      id: viewSettingsConsts.DEGREES_OF_RELATIONSHIP_SLIDER_MENU_ITEM_ID,
      label: DEGREES_OF_RELATIONSHIP,
      iconName: IconName.ACCOUNT_TREE,
      type: SettingsType.DROPDOWN,
      isKeepOpen: true,
      options: [
        getSliderOption({
          name: 'incomingDegreesOfRelationship',
          label: 'Incoming',
          value: viewstate.incomingDegreesOfRelationship,
          min: 0,
          max: 10,
          step: 1,
          type: DropdownOptionType.SLIDER,
        }),
        getSliderOption({
          name: 'outgoingDegreesOfRelationship',
          label: 'Outgoing',
          value: viewstate.outgoingDegreesOfRelationship,
          min: 0,
          max: 11,
          step: 1,
          substituteMax: 99,
          type: DropdownOptionType.SLIDER,
        }),
      ],
    },
    booleanOrAutoDropdown({
      id: 'enableStyles',
      label: 'Enable Styles',
      iconName: IconName.WORKSPACE,
      isActive: stylesEnabled,
      currentValue: viewstate.enableStyles,
      applyValue: value => setEnableStyles(viewstate, value),
    }),
    {
      id: 'includeChildren',
      label: 'Include children',
      iconName: IconName.CUBES,
      isActive: viewstate.includeChildren,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate,
    },
  ];
};

interface CircularRelationshipDiagramProperties extends HasViewInstanceId {
  viewSettings: CircularRelationshipDiagramViewSettings;
  viewModel: CircularRelationshipDiagramViewModel;
}

class CircularRelationshipDiagramView extends Component<CircularRelationshipDiagramProperties> {
  ardoqGraphComponentRef = createRef<ArdoqGraphComponentForwardRefType>();

  getContainer = () =>
    this.ardoqGraphComponentRef.current?.graphComponentContainer.current ??
    null;

  getGraphComponent = () =>
    this.ardoqGraphComponentRef.current?.graphComponent ?? null;

  render() {
    const { viewSettings, viewModel, viewInstanceId } = this.props;
    // let isLegendActive holds the "active" value of the legend and is temorarely detached from
    // the state due to getFilteredViewSettings$.
    // This value & the viewstate are synchronized as soon an update passes the filter.
    let isLegendActive = viewSettings.isLegendActive ?? false;

    const stylesEnabled = viewModel.stylesEnabled;
    return (
      <div className={`tab-pane ${VIEW_ID}Tab active`}>
        <div className="menuContainer">
          <SettingsBar
            viewId={VIEW_ID}
            leftMenu={getLeftMenuConfig(viewSettings, stylesEnabled)}
            rightMenu={getRightMenuConfig({
              viewId: VIEW_ID,
              viewstate: viewSettings,
              exports: getExportsForYFilesView({
                container: this.getContainer,
                graphComponent: () => this.getGraphComponent()?.current ?? null,
                exportedViewMetadata: {
                  name: VIEW_ID,
                },
                ...getSharedExportFunctions(),
              }),
              withLegend: viewHasLegend(VIEW_ID),
              legendOnClick: () => {
                isLegendActive = !isLegendActive;
                defaultLegendOnClick({
                  isLegendActive: isLegendActive,
                  viewId: VIEW_ID,
                });
              },
              onViewSettingsUpdate,
            })}
          />
        </div>
        <ViewAndLegendContainer>
          <ArdoqGraphComponent
            ref={this.ardoqGraphComponentRef}
            viewModel={viewModel}
            viewId={VIEW_ID}
            enableStyles={stylesEnabled}
            isLegendActive={viewSettings.isLegendActive}
            buildGraph={graphComponent =>
              buildGraph(graphComponent, viewModel, stylesEnabled)
            }
            configureLayout={(graphComponent: GraphComponent) =>
              configureLayout(graphComponent, viewModel.groups)
            }
            configureRendering={(graphComponent: GraphComponent) =>
              configureRendering(graphComponent, stylesEnabled)
            }
            onLayoutGraph={onLayoutGraph}
            useHoverDecorator={true}
            viewInstanceId={viewInstanceId}
          />
        </ViewAndLegendContainer>
      </div>
    );
  }
}

const viewState$ =
  getViewSettingsStreamWithChanges<CircularRelationshipDiagramViewSettings>(
    VIEW_ID
  );

const PerformanceTrackedCircularRelationshipDiagram = (
  props: CircularRelationshipDiagramProperties
) =>
  PerformanceTrackedGraphView(CircularRelationshipDiagramView, props, VIEW_ID);

export default connectInstance(
  PerformanceTrackedCircularRelationshipDiagram,
  getViewModel$(action$, viewState$, context$)
);
