import {
  Insets,
  InteriorLabelModel,
  InteriorLabelModelPosition,
} from '@ardoq/yfiles';
import {
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT,
} from 'yfilesExtensions/styles/modernized/consts';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT } from 'yfilesExtensions/styles/consts';

const mainLabelLayoutParameter = (labelText: string) => {
  const { height } = measureLabelElement(
    labelText,
    MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
  );
  const availableHeight =
    MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT -
    MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT -
    3 * MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING;
  const bottomInset = Math.max(
    MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING,
    (availableHeight - height) / 2
  );

  return new InteriorLabelModel({
    insets: new Insets(0, 0, 0, bottomInset),
  }).createParameter(InteriorLabelModelPosition.SOUTH);
};
export default mainLabelLayoutParameter;
