import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { BUBBLE_LEGEND_STROKE_WIDTH, DATA_LABEL_LEFT_PADDING } from './consts';
import { ZERO_BUBBLE, ZERO_BUBBLE_SIZE } from '../consts';

type LegendBubbleProps = {
  radius: number;
  leaderLineWidth: number;
  centerX: number;
  centerY: number;
};
export const LegendBubble = ({
  radius,
  leaderLineWidth,
  centerX,
  centerY,
}: LegendBubbleProps) => {
  const bubbleTopY = centerY - radius;
  return (
    <>
      <line
        x1={centerX}
        y1={bubbleTopY}
        x2={centerX + leaderLineWidth}
        y2={bubbleTopY}
        stroke="lightgray"
        strokeWidth="1"
      />
      {radius ? (
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
          shapeRendering="geometricPrecision"
          fill="transparent"
          stroke={colors.grey15}
          strokeWidth={BUBBLE_LEGEND_STROKE_WIDTH}
        />
      ) : (
        <use
          href={`#${ZERO_BUBBLE}`}
          x={centerX}
          y={centerY}
          style={{ transform: `translate(${-ZERO_BUBBLE_SIZE / 2}px)` }}
        />
      )}
    </>
  );
};

const StyledLegendLabel = styled.text`
  dominant-baseline: middle;
  text-anchor: end;
`;

type LegendLabelProps = {
  label: string;
  width: number;
  x: number;
  y: number;
};
export const LegendLabel = ({ label, width, x, y }: LegendLabelProps) => (
  <StyledLegendLabel
    x={x}
    y={y}
    width={`${width}px`}
    dx={width + DATA_LABEL_LEFT_PADDING}
  >
    {label}
  </StyledLegendLabel>
);

export const BubbleLegendContainer = styled.div`
  position: relative;
`;
