import { Observable, combineLatest, debounceTime } from 'rxjs';
import { PagesViewModel, PagesViewSettings } from 'tabview/pagesView/types';
import {
  ReferenceTypeOption,
  TraversedReferenceTypeInfo,
  isTraversedReferenceType,
  matchSelectedReferenceType,
  getReferenceTypeInfo,
} from '@ardoq/settings-bar';
import {
  componentInterface,
  componentOrDescendantsAreIncludedInContextByFilter,
} from '@ardoq/component-interface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import type {
  GraphModelShape,
  LinkedComponent,
  ContextShape,
} from '@ardoq/data-model';
import { modelUpdateNotificationWithoutGraph$ } from 'modelInterface/modelUpdateNotification$';
import defaultState from 'views/defaultState';
import { makeContext } from 'streams/context/context$';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import {
  ExtractPayload,
  ObservableState,
  combineReducers,
  reducer,
  action$,
  streamReducer,
} from '@ardoq/rxbeach';
import { getComponentFieldsOfWorkspace } from './utils';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { scrollToViewComponent } from 'tabview/pagesView/actions';
import { topVisibleItemChanged } from 'atomicComponents/InfiniteScroll/actions';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { isSameSet } from 'utils/isSameSet';
import graphModel$ from 'modelInterface/graphModel$';
import { uniqBy } from 'lodash';
import { ExtractStreamShape } from 'tabview/types';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import {
  SubdivisionsContext,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { CommonDropdownOptions } from '@ardoq/global-consts';
import documentArchiveFolders$ from 'streams/documentArchive/documentArchiveFolders$';

const VIEW_ID = ViewIds.PAGESVIEW;

const getReferenceTypeAndWorkspaceName = (
  referenceTypeName: string,
  workspaceId: ArdoqId
) => {
  return `${referenceTypeName} (${workspaceInterface.getWorkspaceName(
    workspaceId
  )})`;
};

const referenceIsVisible = (
  linkedComponent: LinkedComponent,
  includedReferenceTypes: ReferenceTypeOption[],
  isOutgoing: boolean
) => {
  return (
    (includedReferenceTypes.length === 1 &&
      includedReferenceTypes[0] === CommonDropdownOptions.ALL) ||
    includedReferenceTypes.find(
      includedReferenceType =>
        isTraversedReferenceType(includedReferenceType) &&
        matchSelectedReferenceType(
          { ...getReferenceTypeInfo(linkedComponent), isOutgoing },
          includedReferenceType
        )
    )
  );
};

const getReferenceMap = (
  componentIds: ArdoqId[],
  includedIncomingReferenceTypes: ReferenceTypeOption[],
  includedOutgoingReferenceTypes: ReferenceTypeOption[]
) =>
  new Map(
    componentIds.map(compId => [
      compId,
      {
        incoming: componentInterface
          .getSources(compId)
          .filter(
            linkedComponent =>
              referenceInterface.isIncludedInContextByFilter(
                linkedComponent.referenceId
              ) &&
              referenceIsVisible(
                linkedComponent,
                includedIncomingReferenceTypes,
                false
              )
          )
          .map(linkedComp => linkedComp.referenceId),
        outgoing: componentInterface
          .getTargets(compId)
          .filter(
            linkedComponent =>
              referenceInterface.isIncludedInContextByFilter(
                linkedComponent.referenceId
              ) &&
              referenceIsVisible(
                linkedComponent,
                includedOutgoingReferenceTypes,
                true
              )
          )
          .map(linkedComp => linkedComp.referenceId),
      },
    ])
  );

const getDescendantComponentIds = (
  workspaceId: ArdoqId | null,
  componentId: ArdoqId | null
): ArdoqId[] => {
  if (componentId) {
    return [
      componentId,
      ...componentInterface
        .getChildren(componentId)
        .flatMap(child => getDescendantComponentIds(workspaceId, child)),
    ];
  }
  if (!componentId && workspaceId) {
    return componentInterface
      .getRootComponents(workspaceId)
      .flatMap((compId: ArdoqId) =>
        getDescendantComponentIds(workspaceId, compId)
      );
  }
  return [];
};

const getFields = (context: ContextShape) =>
  getComponentFieldsOfWorkspace(context.workspaceId);

const getReferenceTypesWithWorkspaceNames = (
  componentIds: ArdoqId[],
  isOutgoing: boolean
): TraversedReferenceTypeInfo[] => {
  const locale = getCurrentLocale();

  const referenceTypes = uniqBy(
    uniqBy(
      componentIds.flatMap(
        isOutgoing
          ? componentInterface.getTargets
          : componentInterface.getSources
      ),
      'referenceId'
    ).map(getReferenceTypeInfo),
    ({ referenceTypeName, linkedWorkspaceId }) =>
      `${referenceTypeName}~~${linkedWorkspaceId}`
  );
  const duplicates = new Set(
    referenceTypes
      .filter(
        (a, index, array) =>
          array.findIndex(b => a.referenceTypeName === b.referenceTypeName) !==
          index
      )
      .map(({ referenceTypeName }) => referenceTypeName)
  );

  return referenceTypes
    .map(referenceType => ({
      ...referenceType,
      isOutgoing,
      displayName: duplicates.has(referenceType.referenceTypeName)
        ? getReferenceTypeAndWorkspaceName(
            referenceType.referenceTypeName,
            referenceType.linkedWorkspaceId
          )
        : referenceType.referenceTypeName,
    }))
    .sort((a, b) => localeCompare(a.displayName, b.displayName, locale));
};

const emptyState: PagesViewModel = {
  viewSettings: defaultState.get(VIEW_ID) as PagesViewSettings,
  context: makeContext(),
  incomingReferenceTypes: [],
  outgoingReferenceTypes: [],
  componentIds: [],
  allComponentIds: [],
  filteredComponentIds: [],
  referenceMap: new Map(),
  workspaceFields: [],
  isViewpointMode: false,
  isScenarioMode: false,
  permissionContext: permissionsOperations.createEmptyPermissionContext(),
  subdivisions: subdivisionsOperations.getEmptySubdivisionsContext(),
};

interface ResetArgs {
  context: ContextShape;
  viewSettings: PagesViewSettings;
  graphModel: GraphModelShape;
  isViewpointMode: boolean;
  isScenarioMode: boolean;
  permissionContext: PermissionContext;
  subdivisions: SubdivisionsContext;
}
class ReferenceTypeCache {
  private result: TraversedReferenceTypeInfo[] = [];
  private ids: ArdoqId[] = [];
  private graphModel: GraphModelShape | null = null;
  constructor(private isOutgoing: boolean) {}

  get(ids: ArdoqId[], graphModel: GraphModelShape) {
    if (graphModel === this.graphModel && isSameSet(ids, this.ids)) {
      return this.result;
    }
    this.result = getReferenceTypesWithWorkspaceNames(ids, this.isOutgoing);
    this.ids = ids;
    this.graphModel = graphModel;
    return this.result;
  }
}

const outgoingReferenceTypesCache = new ReferenceTypeCache(true);
const incomingReferenceTypesCache = new ReferenceTypeCache(false);

const reset = ({
  context,
  viewSettings,
  graphModel,
  isViewpointMode,
  isScenarioMode,
  permissionContext,
  subdivisions,
}: ResetArgs): PagesViewModel => {
  if (!context.workspaceId) {
    return emptyState;
  }

  const allComponentIds = getDescendantComponentIds(
    context.workspaceId,
    context.componentId
  ).filter(componentOrDescendantsAreIncludedInContextByFilter);

  const componentIds = allComponentIds.filter(
    componentInterface.isIncludedInContextByFilter
  );

  const componentIdsSet = new Set(componentIds);
  const filteredComponentIds = allComponentIds.filter(
    componentId => !componentIdsSet.has(componentId)
  );
  const workspaceFields = getFields(context);
  const incomingReferenceTypes = incomingReferenceTypesCache.get(
    componentIds,
    graphModel
  );
  const outgoingReferenceTypes = outgoingReferenceTypesCache.get(
    componentIds,
    graphModel
  );

  const referenceMap = getReferenceMap(
    componentIds,
    viewSettings.includeIncomingReferenceTypes,
    viewSettings.includeOutgoingReferenceTypes
  );

  return {
    viewSettings,
    context,
    workspaceFields,
    componentIds,
    allComponentIds,
    filteredComponentIds,
    incomingReferenceTypes,
    outgoingReferenceTypes,
    referenceMap,
    isViewpointMode,
    isScenarioMode,
    permissionContext,
    subdivisions,
  };
};

const handleScrollToViewComponent = (
  state: PagesViewModel,
  { componentId }: ExtractPayload<typeof scrollToViewComponent>
): PagesViewModel => {
  return {
    ...state,
    scrollToViewComponent: componentId,
  };
};

const handleTopVisibleItemChanged = (
  state: PagesViewModel,
  { itemId }: ExtractPayload<typeof topVisibleItemChanged>
): PagesViewModel => ({
  ...state,
  topVisibleComponentId: itemId,
});

export const getViewModel$ = (
  viewState$: Observable<PagesViewSettings>,
  context$: ObservableState<ContextShape>
) => {
  const reset$ = combineLatest([
    viewState$,
    context$,
    graphModel$,
    isViewpointMode$,
    activeScenario$,
    currentUserPermissionContext$,
    subdivisions$,
    modelUpdateNotificationWithoutGraph$,
    documentArchiveFolders$,
  ]);

  const handleReset = (
    _: PagesViewModel,
    [
      viewSettings,
      context,
      graphModel,
      { isViewpointMode },
      { isScenarioMode },
      permissionContext,
      subdivisions,
    ]: ExtractStreamShape<typeof reset$>
  ) =>
    reset({
      viewSettings,
      context,
      graphModel,
      isViewpointMode,
      isScenarioMode,
      permissionContext,
      subdivisions,
    });

  return action$.pipe(
    combineReducers<PagesViewModel>(emptyState, [
      streamReducer(reset$, handleReset),
      reducer(scrollToViewComponent, handleScrollToViewComponent),
      reducer(topVisibleItemChanged, handleTopVisibleItemChanged),
    ]),
    // avoiding repeated unnecessary values
    // due to interconnected viewModel, activeFilter streams
    // helps with fewer rerenderings of the connected React component
    debounceTime(100)
  );
};
