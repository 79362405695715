import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { VIEW_SUPPORTING_DIFF } from 'scope/consts';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { MetaInfo, MetaInfoViewStatus } from 'streams/views/mainContent/types';
import { ActiveTabsAndTabOptions, Tab } from './types';
import { isViewRestrictedInWorkspaceMode } from 'viewDeprecation/restrictedViews';

type KeepCurrentActiveTabsArgs = {
  activeViews: ViewIds[];
  activeTabIdLeft: ViewIds;
  activeTabIdRight: ViewIds;
  previousActiveTabIdLeft: ViewIds;
  metaInfoList: MetaInfo[];
  tabs: Tab[];
  activeWorkspaceId: ArdoqId;
  savedViews: ViewIds[];
};

export const keepCurrentActiveTabs = ({
  activeViews,
  activeTabIdLeft,
  activeTabIdRight,
  previousActiveTabIdLeft,
  metaInfoList,
  tabs,
  activeWorkspaceId,
  savedViews,
}: KeepCurrentActiveTabsArgs) => {
  const tabIds = new Set(tabs.map(({ id }) => id));

  if (activeViews.every(viewId => tabIds.has(viewId))) {
    return {
      tabs,
      activeTabIdLeft,
      activeTabIdRight,
      previousActiveTabIdLeft,
      metaInfoList,
    };
  }
  return {
    tabs: getTabsInOrder(metaInfoList, new Set([...tabIds, ...activeViews])),
    activeTabIdLeft,
    activeTabIdRight,
    previousActiveTabIdLeft,
    needsToBeSaved: !activeViews.every(id =>
      savedViews.some(savedId => savedId === id)
    ),
    activeWorkspaceId,
    metaInfoList,
  };
};

type SetStartViewActiveArgs = {
  tabs: Tab[];
  startView: ViewIds;
  activeTabIdLeft: ViewIds;
  activeTabIdRight: ViewIds;
  metaInfoList: MetaInfo[];
  activeWorkspaceId: ArdoqId;
  savedViews: ViewIds[];
};

export const setStartViewActive = ({
  tabs,
  startView,
  activeTabIdLeft,
  activeTabIdRight,
  metaInfoList,
  activeWorkspaceId,
  savedViews,
}: SetStartViewActiveArgs) => {
  const tabIds = new Set(tabs.map(({ id }) => id));
  if (tabIds.has(startView)) {
    return {
      tabs,
      activeTabIdLeft: startView,
      activeTabIdRight,
      previousActiveTabIdLeft: activeTabIdLeft,
      metaInfoList,
    };
  }
  return {
    tabs: getTabsInOrder(metaInfoList, new Set([...tabIds, startView])),
    activeTabIdLeft: startView,
    activeTabIdRight,
    previousActiveTabIdLeft: activeTabIdLeft,
    needsToBeSaved: !savedViews.some(id => id === startView),
    activeWorkspaceId,
    metaInfoList,
  };
};

export const allWorkspacesClosedHandler = (state: ActiveTabsAndTabOptions) => {
  if (state.activeTabIdLeft === ViewIds.NONE) return state;

  const previousActiveTabIdLeft = state.activeTabIdLeft;
  return {
    ...state,
    tabs: [],
    activeTabIdLeft: ViewIds.NONE,
    activeTabIdRight: ViewIds.NONE,
    previousActiveTabIdLeft,
  };
};

const getTabsInOrder = (
  metaInfoList: MetaInfo[],
  selectedViews: Set<ViewIds>
) => {
  return metaInfoList
    .filter(({ id }) => selectedViews.has(id))
    .map(({ id, name, viewStatus = MetaInfoViewStatus.NONE }) => ({
      id,
      name: name ?? (id as string),
      label: name ?? (id as string),
      tabId: id,
      viewStatus,
    }));
};

export const setActiveTab = (tabs: Tab[], activeTabId: ViewIds) =>
  tabs.map(tab => ({
    ...tab,
    isActive: activeTabId === tab.tabId,
  }));

export const getRightTabId = (tabs: Tab[], activeTabIdLeft: ViewIds) =>
  tabs.length === 0
    ? ViewIds.NONE
    : (tabs.find(({ id }) => id !== activeTabIdLeft) ?? tabs[0]).id;

export const isDisabledTab = (viewId: ViewIds) => {
  if (isScenarioMode() && isInScopeDiffMode()) {
    return !VIEW_SUPPORTING_DIFF.includes(viewId);
  }
  return false;
};

export const metaInfoListToTabs = (metaInfoList: MetaInfo[]) =>
  metaInfoList
    .map(({ id, name, viewStatus = MetaInfoViewStatus.NONE }) => ({
      id,
      name: name ?? (id as string),
      label: name ?? (id as string),
      tabId: id,
      viewStatus,
      isDisabled: isDisabledTab(id),
    }))
    .filter(({ id }) => !isViewRestrictedInWorkspaceMode(id));
