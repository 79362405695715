import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { PayloadScenarioIsInSync } from 'scope/types';
import { setScenarioIsInSync } from 'scope/actions';

const handleSetScenarioIsInSync = (
  _: PayloadScenarioIsInSync,
  { isInSync }: PayloadScenarioIsInSync
) => ({
  isInSync,
});

const defaultState: PayloadScenarioIsInSync = {
  isInSync: false,
};

const reducers = [reducer(setScenarioIsInSync, handleSetScenarioIsInSync)];

export const scenarioIsInSync$ =
  persistentReducedStream<PayloadScenarioIsInSync>(
    'scenarioIsInSync$',
    defaultState,
    reducers
  );

export const isCurrentScenarioInSync = () => scenarioIsInSync$.state.isInSync;
