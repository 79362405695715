import {
  action$,
  persistentReducedStream,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { returnPayload, returnFalse, returnTrue } from '@ardoq/common-helpers';

const isOpen$ = action$.pipe(
  reduceState('isOpen', false, [
    reducer(showRightPane, returnTrue),
    reducer(hideRightPane, returnFalse),
  ]),
  debounceTime(32),
  distinctUntilChanged()
);

export const isRightEditorPaneOpen$ = persistentReducedStream<boolean>(
  'isRightEditorPaneOpen$',
  false,
  [streamReducer(isOpen$, returnPayload)]
);
