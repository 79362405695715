import { INode, Rect } from '@ardoq/yfiles';
import { CONTEXT_HIGHLIGHT_PADDING, HALO_PADDING } from './styles/consts';

const getNodeBounds = (
  node: INode,
  isContext: boolean,
  isCollapsedComponentGroup?: boolean
) => {
  const { layout } = node;
  const { x, y, width, height } = layout;
  const padding = isCollapsedComponentGroup
    ? CONTEXT_HIGHLIGHT_PADDING - HALO_PADDING
    : CONTEXT_HIGHLIGHT_PADDING;
  return isContext
    ? new Rect(
        x + padding,
        y + padding,
        width - 2 * padding,
        height - 2 * padding
      )
    : layout;
};

export default getNodeBounds;
