import { DiffType } from '@ardoq/data-model';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { MODEL_ID_ATTRIBUTE } from 'consts';
import type { VisualDiffClassName } from '@ardoq/color-helpers';
import { CHANGED_POPOVER_ID } from 'components/WorkspaceHierarchies/components/ChangedPopover';

const diffTypeToCssClass = new Map<DiffType, VisualDiffClassName | ''>([
  [DiffType.NONE, ''],
  [DiffType.PLACEHOLDER, 'visual-diff-placeholder'],
  [DiffType.ADDED, 'visual-diff-added'],
  [DiffType.REMOVED, 'visual-diff-removed'],
  [DiffType.CHANGED, 'visual-diff-changed'],
  [DiffType.UNCHANGED, 'visual-diff-unchanged'],
]);

export const getCssClassFromDiffType = (diffType: DiffType = DiffType.NONE) =>
  diffTypeToCssClass.get(diffType) || '';

export const getChangedPopover = (
  id: string | undefined,
  diffType: DiffType
): Record<string, string> =>
  id && diffType === DiffType.CHANGED
    ? {
        [POPOVER_ID_ATTR]: CHANGED_POPOVER_ID,
        [MODEL_ID_ATTRIBUTE]: id,
      }
    : {};
