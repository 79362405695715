import { ArdoqId, getEntityType } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import {
  ComponentDataSourceItem,
  ComponentItemData,
  PrepareDataSource,
} from '../types';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { NAVIGATOR_BACKGROUND } from 'components/WorkspaceHierarchies/consts';
import { ensureContrast } from '@ardoq/color-helpers';
import { returnZero, WILDCARD, ExcludeFalsy } from '@ardoq/common-helpers';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import {
  COMPONENT_TREE_ICON_RIGHT_MARGIN,
  COMPONENT_TREE_ROW_HORIZONTAL_PADDING,
  COMPONENT_TREE_ROW_MAX_WIDTH,
} from '../consts';
import { IconSize } from '@ardoq/icons';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { MeasureStyledText } from '@ardoq/dom-utils';

const LABEL_MAX_WIDTH =
  COMPONENT_TREE_ROW_MAX_WIDTH -
  COMPONENT_TREE_ROW_HORIZONTAL_PADDING -
  IconSize.MEDIUM -
  COMPONENT_TREE_ICON_RIGHT_MARGIN -
  IconSize.SMALL -
  COMPONENT_TREE_ICON_RIGHT_MARGIN;
const measureLabel = (text: string) =>
  MeasureStyledText.Instance.getTextWidth({
    text,
    fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
    fontSize: '14px',
  });
export const getDataSource = (
  collapsed: Record<ArdoqId, boolean>,
  hierarchy: Record<ArdoqId, { isFilteredOut: boolean; children?: ArdoqId[] }>,
  limit = Infinity
): ComponentDataSourceItem[] =>
  composeSourceData({
    hierarchy,
    collapsed,
    children: hierarchy.root.children || [],
    getItemDataFn: getWorkspaceItemData,
    limit,
  }).filter(ExcludeFalsy);

const composeSourceData = ({
  hierarchy,
  collapsed,
  children,
  depth = 0,
  getItemDataFn = getComponentItemData,
  isParentCollapsed,
  limit = Infinity,
}: PrepareDataSource): (ComponentDataSourceItem | null)[] => {
  let remainingLimit = limit;

  return children.reduce<(ComponentDataSourceItem | null)[]>((acc, id) => {
    const isCollapsed = collapsed[WILDCARD] || Boolean(collapsed[id]);

    if (!hierarchy[id] || remainingLimit <= 0) {
      return acc;
    }

    const { isFilteredOut, children: itemChildren = [] } = hierarchy[id];

    const hasChildren = Boolean(itemChildren && itemChildren.length > 0);

    const data = {
      id,
      depth,
      isCollapsed,
      hasChildren,
      itemData: getItemDataFn(id),
      isFilteredOut,
      isHovered: false,
      isFocused: false,
      isSelected: false,
    };

    acc.push(isParentCollapsed ? null : data);
    if (data.itemData.type !== 'workspace') {
      remainingLimit--;
    }

    if (hasChildren) {
      const childItemsData = composeSourceData({
        hierarchy,
        collapsed,
        children: itemChildren,
        depth: depth + 1,
        isParentCollapsed: isCollapsed || isParentCollapsed,
        limit: remainingLimit,
      });

      childItemsData.forEach(childItem => {
        if (remainingLimit > 0) {
          acc.push(childItem);
          remainingLimit--;
        }
      });
    }
    return acc;
  }, []);
};

const getWorkspaceItemData = (workspaceId: ArdoqId): ComponentItemData => {
  const workspaceData = workspaceInterface.getWorkspaceData(workspaceId);

  return {
    type: getEntityType(workspaceData) ?? undefined,
    name: workspaceInterface.getWorkspaceName(workspaceId),
    representationData: null,
    representation: null,
    displayLabel: null,
    fullLabel: null,
    classNames: 'workspace',
  };
};

const getComponentItemData = (componentId: ArdoqId): ComponentItemData => {
  const componentData = componentInterface.getComponentData(componentId);

  const { fill } = getComponentCssColors(componentId, {
    useAsBackgroundStyle: false,
  }) ?? { fill: undefined };

  const componentColors = getComponentCssColors(componentId, {
    useAsBackgroundStyle: true,
  }) ?? { fill: undefined, stroke: undefined };

  const iconColor = ensureContrast(NAVIGATOR_BACKGROUND, fill ?? 'black');

  const componentLabelParts = getComponentLabelParts(componentId);

  return {
    type: getEntityType(componentData) ?? undefined,
    name: componentInterface.getDisplayName(componentId),
    representationData: componentInterface.getRepresentationData(
      componentId
    ) ?? {
      isImage: false,
      value: null,
    },
    representation: componentInterface.getRepresentation(componentId),
    displayLabel: truncateComponentLabel({
      ...componentLabelParts,
      measure: measureLabel,
      width: LABEL_MAX_WIDTH,
    }),
    fullLabel: truncateComponentLabel({
      ...componentLabelParts,
      measure: returnZero,
      width: Infinity,
    }),
    iconStyle: { color: iconColor },
    componentStyle: {
      backgroundColor: componentColors?.fill,
      borderColor: componentColors?.stroke,
    },
    classNames: 'component',
  };
};
