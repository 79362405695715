import { type PathCollapsingRule, type DirectedTriple } from '@ardoq/api-types';
import type {
  CollapsePathsArgs,
  GetCollapsedPaths,
  NewReference,
} from './pathAndGraphTypes';
import {
  PathToFilters,
  getMatchingPaths,
  getPathToFilters,
  getPathsWithFilters,
} from './pathAndGraphUtils';
import type { GraphInterface } from '@ardoq/graph';
import type { GraphModelShape, LinkedComponent } from '@ardoq/data-model';
import { getAddToMaps } from '@ardoq/common-helpers';

/**
 * Processes the path collapsing rules and determines the new references to be
 * added, as well as the component and reference IDs to be removed. This
 * function does not modify the graph model directly but returns the necessary
 * changes.
 *
 * In Ardoq, the loaded graph is defined by traversals. A traversal is a tree
 * structure composed of type paths, known as triples. A triple represents a
 * directed edge between two nodes, characterized by a source type, a target
 * type, a reference type, and a direction (either incoming or outgoing). The
 * tree is represented as a list of paths from the root to the leaves.
 *
 * A path collapsing rule is defined as a subpath of these paths, together with
 * a name and style for the reference to replace that subpath. The rule can be
 * applied to multiple paths, and the same path can have multiple rules applied
 * to it. A single traversal (or a loaded state) can have multiple path
 * collapsing rules.
 *
 * The algorithm works as follows:
 *   - For each traversal, it gets all the paths with matches for each path
 *     collapsing rule, recording the start index, the length, and the direction
 *     of the subpath.
 *   - For each matching path, it gets all the instance paths and processes them
 *     according to the start index, the length, and the direction of the
 *     subpath.
 *   - It then records the references and components that are no longer needed.
 *     In the first step, only the references and the components of the match in
 *     the instance path are recorded. In the second step, the component and
 *     reference IDs that are no longer connected to the graph are recorded.
 *   - Finally, it returns the new references, the removed component IDs, and the
 *     removed reference IDs.
 */
export const processPathCollapsingRules = ({
  graphInterface,
  collapsePathsArgs,
  graphModel,
  workspacesIds,
}: GetCollapsedPaths): {
  newReferences: NewReference[];
  removedComponentIds: Set<string>;
  removedReferenceIds: Set<string>;
} => {
  if (collapsePathsArgs.length === 0) {
    return {
      newReferences: [],
      removedComponentIds: new Set<string>(),
      removedReferenceIds: new Set<string>(),
    };
  }

  const pathToFilters = getPathToFilters(graphInterface, workspacesIds);
  const newReferences: Map<string, NewReference> = new Map();
  const removedReferenceIds = new Set<string>();
  const removedComponentIds = new Set<string>();
  const processMatchingPaths = getProcessMatchingPaths({
    newReferences,
    replacedReferenceIds: removedReferenceIds,
    removedComponentIds,
    pathToFilters,
    graphInterface,
    graphModel,
  });

  const pathsWithMatches = collapsePathsArgs.flatMap(getTriplePathsWithMatches);
  pathsWithMatches.forEach(processMatchingPaths);

  const collapsedGraph = removeReferencesAndComponents(
    graphModel,
    removedReferenceIds,
    removedComponentIds
  );
  const { sourceMap, targetMap, referenceMap } = collapsedGraph;
  const addToGraph = getAddToMaps({
    referenceMap,
    sourceMap,
    targetMap,
  });
  newReferences.forEach(
    ({ referenceId: referenceId, sourceId: sourceId, targetId: targetId }) => {
      addToGraph({ referenceId, sourceId, targetId });
    }
  );

  const unconnectedComponents = new Set(
    getAllUnconnectedComponents(graphModel, collapsedGraph, collapsePathsArgs)
  );

  // Remove any 'dangling' (with missing source or target) reference from the
  // graph.
  graphModel.referenceMap.forEach(({ sourceId, targetId }, referenceId) => {
    if (
      removedComponentIds.has(sourceId) ||
      removedComponentIds.has(targetId) ||
      unconnectedComponents.has(sourceId) ||
      unconnectedComponents.has(targetId)
    ) {
      removedReferenceIds.add(referenceId);
    }
  });

  return {
    newReferences: Array.from(newReferences.values()),
    removedComponentIds: new Set([
      ...removedComponentIds,
      ...unconnectedComponents,
    ]),
    removedReferenceIds,
  };
};

const removeReferencesAndComponents = (
  graphModel: GraphModelShape,
  replacedReferenceIds: Set<string>,
  removedComponentIds: Set<string>
): GraphModelShape => {
  const { sourceMap, targetMap, referenceMap } = graphModel;
  const filterLinkedComponents = getFilterLinkedComponents(
    replacedReferenceIds,
    removedComponentIds
  );
  const newSourceMap = mapMap(sourceMap, filterLinkedComponents);
  const newTargetMap = mapMap(targetMap, filterLinkedComponents);
  const newReferenceMap = filterMap(
    referenceMap,
    ([id, { sourceId, targetId }]) =>
      !replacedReferenceIds.has(id) &&
      !removedComponentIds.has(sourceId) &&
      !removedComponentIds.has(targetId)
  );
  return {
    ...graphModel,
    sourceMap: newSourceMap,
    targetMap: newTargetMap,
    referenceMap: newReferenceMap,
  };
};

const mapMap = <K, V, U>(
  map: Map<K, V>,
  mapper: ([key, value]: [key: K, value: V]) => [K, U]
): Map<K, U> => new Map(Array.from(map.entries()).map(mapper));

const filterMap = <K, V>(
  map: Map<K, V>,
  filter: ([key, value]: [key: K, value: V]) => boolean
): Map<K, V> => new Map(Array.from(map.entries()).filter(filter));

const getFilterLinkedComponents =
  (replacedReferenceIds: Set<string>, removedComponentIds: Set<string>) =>
  ([sourceId, linkedComponents]: [string, LinkedComponent[]]): [
    string,
    LinkedComponent[],
  ] => {
    return [
      sourceId,
      linkedComponents.filter(
        ({ referenceId, componentId }) =>
          !replacedReferenceIds.has(referenceId) &&
          !removedComponentIds.has(componentId)
      ),
    ];
  };

const getAllUnconnectedComponents = (
  graph: GraphModelShape,
  collapsedGraph: GraphModelShape,
  collapsePathsArgs: CollapsePathsArgs[]
) => {
  const allStartSets = collapsePathsArgs.flatMap(
    ({ startSetResult }) => startSetResult
  );
  const visitedComponents = new Set<string>();
  allStartSets.forEach(start => {
    depthFirstSearch(collapsedGraph, start, visitedComponents);
  });

  return [...graph.sourceMap.keys(), ...graph.targetMap.keys()].filter(
    id => !visitedComponents.has(id)
  );
};

const depthFirstSearch = (
  graph: GraphModelShape,
  start: string,
  visited: Set<string>
) => {
  visited.add(start);
  const linkedTargets = graph.sourceMap.get(start) ?? [];
  const linkedSources = graph.targetMap.get(start) ?? [];
  [...linkedTargets, ...linkedSources]
    .map(({ componentId }) => componentId)
    .filter(id => !visited.has(id))
    .forEach(id => depthFirstSearch(graph, id, visited));
};

type MatchingPaths = {
  startSetResult: string[];
  rule: PathCollapsingRule;
  matchingPaths: {
    path: DirectedTriple[];
    match: {
      startIndex: number;
      length: number;
      start: string;
    };
  }[];
};

type GetProcessMatchingPaths = {
  newReferences: Map<string, NewReference>;
  replacedReferenceIds: Set<string>;
  removedComponentIds: Set<string>;
  pathToFilters: PathToFilters;
  graphInterface: GraphInterface;
  graphModel: GraphModelShape;
};

const getProcessMatchingPaths =
  ({
    newReferences,
    replacedReferenceIds,
    removedComponentIds,
    pathToFilters,
    graphInterface,
    graphModel,
  }: GetProcessMatchingPaths) =>
  ({
    matchingPaths,
    startSetResult,
    rule: { referenceStyle, displayText },
  }: MatchingPaths) => {
    matchingPaths.forEach(({ path, match }) => {
      const { startIndex, length, start } = match;
      const startIndexInPath = startIndex;
      const endIndexInPath = startIndex + length;
      const pathAsFilters = pathToFilters(path);
      const instancePaths = startSetResult.flatMap(componentId =>
        getPathsWithFilters({
          graphInterface,
          pathAsFilters,
          graphModel,
          componentId,
        })
      );

      const processMatchingInstancePath = getProcessMatchingInstancePath({
        start,
        startIndexInPath,
        endIndexInPath,
        newReferences,
        replacedReferenceIds,
        removedComponentIds,
        referenceStyle,
        displayText,
      });

      instancePaths
        // Ensure that we have a path which has the full subpath of the
        // collapsing rule.
        .filter(path => path.length > endIndexInPath)
        .forEach(processMatchingInstancePath);
    });
  };

type GetProcessMatchingInstancePath = {
  start: string;
  startIndexInPath: number;
  endIndexInPath: number;
  newReferences: Map<string, NewReference>;
  replacedReferenceIds: Set<string>;
  removedComponentIds: Set<string>;
  referenceStyle: PathCollapsingRule['referenceStyle'];
  displayText: string;
};

const getProcessMatchingInstancePath =
  ({
    start,
    startIndexInPath,
    endIndexInPath,
    newReferences,
    replacedReferenceIds,
    removedComponentIds,
    referenceStyle,
    displayText,
  }: GetProcessMatchingInstancePath) =>
  (path: LinkedComponent[]) => {
    const startComponent =
      start === 'start' ? path[startIndexInPath] : path[endIndexInPath];
    const endComponent =
      start === 'start' ? path[endIndexInPath] : path[startIndexInPath];
    const reference: NewReference = {
      sourceId: startComponent?.componentId,
      targetId: endComponent?.componentId,
      referenceId: `${startComponent?.componentId}-${endComponent?.componentId}`,
      referenceStyle: referenceStyle,
      name: displayText,
    };
    // This will automatically ensure that a new path between a given source and
    // target can only be replaced once.
    newReferences.set(reference.referenceId, reference);
    const lastComponentId = path[endIndexInPath].componentId;
    path
      .slice(startIndexInPath + 1, endIndexInPath + 1)
      .map(({ referenceId, componentId }) => {
        replacedReferenceIds.add(referenceId);
        if (componentId !== lastComponentId) {
          removedComponentIds.add(componentId);
        }
      });
  };

const getTriplePathsWithMatches = ({
  paths,
  pathCollapsingRules,
  startSetResult,
}: CollapsePathsArgs) => {
  return pathCollapsingRules
    .filter(({ isActive }) => isActive)
    .map(rule => {
      const pathWithMatches = getMatchingPaths(paths, rule);
      return {
        startSetResult,
        rule,
        matchingPaths: pathWithMatches,
      };
    });
};
