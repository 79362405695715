import type { RelationshipsNode } from '../types';
import { includeAllDescendants } from '../util';

const getMinAndMaxDescendants = (node: RelationshipsNode) =>
  [...includeAllDescendants(node)].reduce(
    ({ minDescendants, maxDescendants }, { descendantCount }) =>
      descendantCount
        ? {
            minDescendants: Math.min(minDescendants, descendantCount),
            maxDescendants: Math.max(maxDescendants, descendantCount),
          }
        : { minDescendants, maxDescendants },
    {
      minDescendants: Infinity,
      maxDescendants: -Infinity,
    }
  );

export default getMinAndMaxDescendants;
