import {
  GraphComponent,
  GraphItemTypes,
  HoveredItemChangedEventArgs,
  IEdge,
  IModelItem,
  INode,
  ItemHoverInputMode,
} from '@ardoq/yfiles';
import { debounce } from 'lodash';
import {
  getConnectedNodesAndEdges,
  resetNodesAndEdgesConnectedStyles,
  updateNodesAndEdgesConnectednessToSelection,
} from 'yfilesExtensions/graphSelection';

export const installHoverMode = (graphComponent: GraphComponent) => {
  const hoverMode = new ArdoqHoverInputMode();

  hoverMode.exclusive = false;
  hoverMode.enabled = true;
  hoverMode.hoverItems = GraphItemTypes.NODE | GraphItemTypes.EDGE;
  hoverMode.discardInvalidItems = false;
  hoverMode.addHoveredItemChangedListener(
    debounce((_, { item }: HoveredItemChangedEventArgs) => {
      if (item === null) {
        updateNodesAndEdgesConnectednessToSelection(graphComponent);
        return;
      }
      const { graph } = graphComponent;
      resetNodesAndEdgesConnectedStyles(graph);

      if (INode.isInstance(item) && !graph.isGroupNode(item)) {
        handleNodeHover(item, graphComponent);
      }

      if (IEdge.isInstance(item)) {
        handleEdgeHover(item, graphComponent);
      }

      graphComponent.invalidate();
    }, 100)
  );

  function handleNodeHover(node: INode, graphComponent: GraphComponent) {
    const { connectedNodes: adjacentNodes, connectedEdges: adjacentEdges } =
      getConnectedNodesAndEdges(graphComponent.graph, [node], []);
    applyTransparencyToNonAdjacentNodes(graphComponent, adjacentNodes);
    applyTransparencyToNonAdjacentEdges(graphComponent, adjacentEdges);
  }

  function handleEdgeHover(edge: IEdge, graphComponent: GraphComponent) {
    const { connectedNodes: adjacentNodes, connectedEdges: adjacentEdges } =
      getConnectedNodesAndEdges(graphComponent.graph, [], [edge]);

    applyTransparencyToNonAdjacentNodes(graphComponent, adjacentNodes);
    applyTransparencyToNonAdjacentEdges(graphComponent, adjacentEdges);
  }

  function applyTransparencyToNonAdjacentNodes(
    graphComponent: GraphComponent,
    adjacentNodes: Set<INode>
  ) {
    graphComponent.graph.nodes.forEach(node => {
      if (!adjacentNodes.has(node)) {
        node.tag.isTransparentized = true;
      }
    });
  }

  function applyTransparencyToNonAdjacentEdges(
    graphComponent: GraphComponent,
    adjacentEdges: Set<IEdge>
  ) {
    graphComponent.graph.edges.forEach(edge => {
      if (!adjacentEdges.has(edge)) {
        edge.tag.isTransparentized = true;
      }
    });
  }

  return hoverMode;
};

class ArdoqHoverInputMode extends ItemHoverInputMode {
  constructor() {
    super();
  }
  isValidHoverItem(item: IModelItem) {
    return item.tag && super.isValidHoverItem(item);
  }
}
