import type { SVGAttributes } from 'react';
import { CSS_CLASS_IMAGE_DIFF_BACKGROUND } from 'scope/consts';
import { classes } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';
import { PLACEHOLDER_RADIUS } from '../consts';
import NodeRepresentation from './NodeRepresentation';
import {
  MODERNIZED_BLOCK_DIAGRAM_CORNER_RADIUS,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
} from './consts';
import { getElementOpacityIfTransparentized } from 'tabview/blockDiagram/view/utils';
import { ArdoqIcon } from '@ardoq/icons';
import NodeIcon from './NodeIcon';
import {
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
} from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';
import { NO_LINK_TARGET_DECORATION, Rect } from '@ardoq/graph';
import { readableColor } from 'polished';
import ContextHighlight from './ContextHighlight';

const ICON_LEFT =
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH / 2 -
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE / 2;

type ArdoqNodeProperties = {
  templateHref?: string;
  icon?: ArdoqIcon;
  imageHref?: string;
  isPlaceholder: boolean;
  isContext: boolean;
  isTransparentized?: boolean;
  visualDiffClass: string;
  tooltipAttributes?: Record<string, string>;
  color: string;
  shapeY: number;
};
const ArdoqNode = ({
  isPlaceholder,
  isContext,
  isTransparentized,
  visualDiffClass,
  transform,
  color,
  templateHref,
  tooltipAttributes,
  imageHref,
  icon,
  shapeY,
  ...rest
}: ArdoqNodeProperties & SVGAttributes<SVGGElement>) => {
  return (
    <>
      {isPlaceholder && (
        <circle
          cx={MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH / 2}
          cy={MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT / 2}
          r={PLACEHOLDER_RADIUS}
          className={classes(CSS_CLASS_IMAGE_DIFF_BACKGROUND, visualDiffClass)}
          transform={transform}
        />
      )}
      <g
        transform={transform}
        style={{
          opacity: getElementOpacityIfTransparentized(!!isTransparentized),
        }}
        {...rest}
      >
        {isContext && <ContextHighlight />}
        <rect
          className={NO_LINK_TARGET_DECORATION}
          x={0}
          y={0}
          height={MODERNIZED_BLOCK_DIAGRAM_NODE_HEIGHT}
          width={MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH}
          rx={MODERNIZED_BLOCK_DIAGRAM_CORNER_RADIUS}
          stroke={colors.grey80}
          fill={colors.white}
        />
        {templateHref || imageHref ? (
          <NodeRepresentation
            {...rest}
            imageHref={imageHref}
            templateHref={templateHref}
            tooltipAttributes={tooltipAttributes}
            isPlaceholder={isPlaceholder}
            height={MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT}
            width={MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH}
            x={0}
            y={shapeY}
            visualDiffClass={visualDiffClass}
            color={color}
            isCircle={true}
          />
        ) : icon ? (
          <NodeIcon
            icon={icon}
            circleColor={color}
            iconColor={readableColor(color, colors.white, colors.grey15)}
            isNegative={true}
            iconBounds={
              new Rect(
                ICON_LEFT,
                shapeY +
                  (MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER -
                    MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE) /
                    2,
                MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE,
                MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE
              )
            }
            cx={MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH / 2}
            cy={shapeY + MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}
            r={MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER / 2}
          />
        ) : null}
      </g>
    </>
  );
};

export default ArdoqNode;
