import { Font, Size } from '@ardoq/yfiles';
import { LEGACY_FORMATTING_VALUE_HEIGHT_NODE } from './legacyLabelUtils';
import { LEGACY_NODE_LABEL_MAX_WIDTH } from '@ardoq/graph';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
} from 'yfilesExtensions/styles/consts';

const getNodeLabelMaxSize = (hasLegacyFormatting: boolean) =>
  hasLegacyFormatting
    ? new Size(
        LEGACY_NODE_LABEL_MAX_WIDTH,
        8 * BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize -
          LEGACY_FORMATTING_VALUE_HEIGHT_NODE
      )
    : BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize;

export const getMaxLabelSize = (
  font: Font,
  isModern: boolean,
  legacyTruncatedFieldValueAndLabelForNode?: string
) =>
  isModern
    ? font === MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT
      ? MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE
      : font === MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT
        ? MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE
        : MODERNIZED_BLOCK_DIAGRAM_NAME_LABEL_MAX_SIZE
    : font === BLOCK_DIAGRAM_OTHER_LABEL_FONT
      ? BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE
      : font === BLOCK_DIAGRAM_NODE_LABEL_FONT
        ? getNodeLabelMaxSize(!!legacyTruncatedFieldValueAndLabelForNode)
        : BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE;
