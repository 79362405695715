import { NumericRange } from '@ardoq/graph';
import {
  AXIS_LABEL_HEIGHT,
  DATAPOINT_RADIUS,
  LABEL_PADDING,
  NINETY_DEGREES,
} from './consts';
import { SpiderChartPoint } from './types';

export const indexToRadians = (index: number, total: number) =>
  ((2 * Math.PI) / total) * index;

export const getScaleRadialX = (
  centerX: number,
  normalizedValue: number,
  fullRadius: number,
  angle: number
) => centerX + normalizedValue * fullRadius * Math.cos(angle - NINETY_DEGREES);

export const getScaleRadialY = (
  centerY: number,
  normalizedValue: number,
  fullRadius: number,
  angle: number
) => centerY + normalizedValue * fullRadius * Math.sin(angle - NINETY_DEGREES);

export const getAreaPoints = (
  series: SpiderChartPoint[],
  centerX: number,
  centerY: number,
  total: number,
  radius: number,
  maxValue: number
) => {
  const points: [x: number, y: number][] = series.map((dataPoint, i) => {
    const dataValue = Math.max(dataPoint.value, 0);
    const normalizedValue = dataValue / maxValue;
    const angle = indexToRadians(i, total);
    const xx = getScaleRadialX(centerX, normalizedValue, radius, angle);
    const yy = getScaleRadialY(centerY, normalizedValue, radius, angle);
    return [xx, yy];
  });
  return [...points, points[0]];
};

export const getLabelX = (
  centerX: number,
  radius: number,
  axisIndex: number,
  axisCount: number
) =>
  centerX +
  (radius + AXIS_LABEL_HEIGHT / 2 + DATAPOINT_RADIUS + LABEL_PADDING) *
    Math.cos(indexToRadians(axisIndex, axisCount) - NINETY_DEGREES);

export const getLabelY = (
  centerY: number,
  radius: number,
  axisIndex: number,
  axisCount: number
) =>
  centerY +
  (radius + AXIS_LABEL_HEIGHT / 2 + DATAPOINT_RADIUS + LABEL_PADDING) *
    Math.sin(indexToRadians(axisIndex, axisCount) - NINETY_DEGREES);

export const getLabelTextAnchor = (axisIndex: number, axisCount: number) => {
  if (axisIndex === 0 || axisIndex === axisCount / 2) {
    return 'middle';
  }

  if (axisIndex < axisCount / 2) {
    return 'start';
  }

  return 'end';
};

export const getRoundedRange = (
  range: NumericRange,
  interval: number
): NumericRange => [
  interval * Math.floor(range[0] / interval),
  interval * Math.ceil(range[1] / interval),
];

const colorPalette = [
  '#9F00F0',
  '#FFD100',
  '#00C4E6',
  '#DC8F00',
  '#066AC4',
  '#FC6D81',
  '#009E8E',
  '#DEDBDE',
  '#670501',
  '#98DA1D',
  '#7F2D93',
  '#C173CF',
  '#598322',
  '#EA2DB9',
  '#0B4512',
  '#B0989E',
];

export const getRangeColor = (index: number) =>
  colorPalette[index % colorPalette.length];
