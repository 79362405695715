import type { LinkedComponent } from '@ardoq/data-model';
import { referenceInterface } from '@ardoq/reference-interface';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { componentInterface } from '@ardoq/component-interface';

export type LinkedComponentWithReferenceTypeName = LinkedComponent & {
  referenceTypeName: string;
};
export const linkedComponentToReferenceInfo = (
  linkedComponent: LinkedComponent
) => {
  const { referenceId } = linkedComponent;
  const rootWorkspaceId = referenceInterface.getRootWorkspaceId(referenceId);
  const referenceTypeId = referenceInterface.getTypeId(referenceId);
  if (rootWorkspaceId === null || referenceTypeId === null) {
    return null;
  }
  const referenceTypeName = workspaceInterface.getReferenceTypeById(
    rootWorkspaceId,
    referenceTypeId
  )?.name;
  if (!referenceTypeName) {
    return null;
  }
  return {
    ...linkedComponent,
    referenceTypeName,
  } satisfies LinkedComponentWithReferenceTypeName;
};

export const referenceFields = ({
  referenceId,
}: LinkedComponentWithReferenceTypeName) =>
  referenceInterface.getFields(referenceId);
export const referencedComponentFields = ({
  componentId,
}: LinkedComponentWithReferenceTypeName) =>
  componentInterface.getFields(componentId);
