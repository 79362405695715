import { ReactNode, useState } from 'react';

type RenderTargetProperties = {
  expand: VoidFunction;
  collapse: VoidFunction;
  toggle: VoidFunction;
  isExpanded: boolean;
};
type AqCollapsibleProperties = {
  defaultValue?: boolean;
  renderTarget: (props: RenderTargetProperties) => ReactNode;
  renderContent: (props: RenderTargetProperties) => ReactNode;
};
const AqCollapsible = ({
  defaultValue = true,
  renderTarget: Target,
  renderContent: CollapsibleContent,
}: AqCollapsibleProperties) => {
  const [isExpanded, setIsExpanded] = useState(defaultValue);

  const exposedProps = {
    expand: () => setIsExpanded(true),
    collapse: () => setIsExpanded(false),
    toggle: () => setIsExpanded(!isExpanded),
    isExpanded,
  };

  return (
    <>
      {Target(exposedProps)}
      {isExpanded && CollapsibleContent(exposedProps)}
    </>
  );
};

export default AqCollapsible;
