import { ArdoqId } from '@ardoq/api-types';
import {
  ComponentItemData,
  GetInfiniteScrollBoundsArgs,
  ReferencesMap,
} from './types';
import { contrastEnsuredComponentFill } from 'utils/modelCssManager/getCssColors';
import { NAVIGATOR_BACKGROUND } from 'components/WorkspaceHierarchies/consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { flatten, uniq } from 'lodash';
import { ViewLegendReferenceType } from '@ardoq/view-legend';
import {
  getComponentLabelParts,
  getReferenceTypes,
  truncateComponentLabel,
} from '@ardoq/graph';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { ExcludeFalsy, returnZero } from '@ardoq/common-helpers';
import {
  CELL_SIZE,
  DEPENDENCY_MATRIX_COLUMN_HEADER_RIGHT_PADDING,
  DEPENDENCY_MATRIX_COLUMN_HEADER_WIDTH,
  DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN,
  DEPENDENCY_MATRIX_HEADER_COMPONENT_FONT_SIZE,
  DEPENDENCY_MATRIX_ROW_HEADER_HORIZONTAL_PADDING,
  DEPENDENCY_MATRIX_ROW_HEADER_WIDTH,
  INFINITE_SCROLL_THRESHOLD,
  RENDER_BUFFER,
} from './consts';
import { colors } from '@ardoq/design-tokens';
import { IconSize } from '@ardoq/icons';
import { MeasureStyledText } from '@ardoq/dom-utils';

const { getReferenceTypeById } = workspaceInterface;

const measure = (text: string) =>
  MeasureStyledText.Instance.getTextWidth({
    fontSize: `${DEPENDENCY_MATRIX_HEADER_COMPONENT_FONT_SIZE}px`,
    text,
  });
export const getComponentItemData = (
  componentId: ArdoqId,
  type: 'SOURCE' | 'TARGET'
): ComponentItemData => {
  const headerWidth =
    type === 'TARGET'
      ? DEPENDENCY_MATRIX_COLUMN_HEADER_WIDTH
      : DEPENDENCY_MATRIX_ROW_HEADER_WIDTH;
  const labelPadding =
    type === 'TARGET'
      ? DEPENDENCY_MATRIX_COLUMN_HEADER_RIGHT_PADDING
      : 2 * DEPENDENCY_MATRIX_ROW_HEADER_HORIZONTAL_PADDING;
  const labelMaxWidth =
    headerWidth -
    labelPadding -
    IconSize.MEDIUM -
    DEPENDENCY_MATRIX_COMPONENT_ICON_MARGIN;
  const componentLabelParts = getComponentLabelParts(componentId);
  const label = truncateComponentLabel({
    ...componentLabelParts,
    measure,
    width: labelMaxWidth,
  });
  const fullLabel = truncateComponentLabel({
    ...componentLabelParts,
    measure: returnZero,
    width: Infinity,
  });
  return {
    id: componentId,
    label,
    fullLabel,
    representationData: componentInterface.getRepresentationData(
      componentId
    ) ?? {
      isImage: false,
      value: null,
    },
    cssClassNames:
      componentInterface.getCssClassNames(componentId) || undefined,
    iconStyle: {
      color: contrastEnsuredComponentFill(
        componentId,
        NAVIGATOR_BACKGROUND,
        colors.black
      ),
    },
  };
};
export const getLegendReferenceTypes = (
  workspaceId: ArdoqId,
  referencesMap: ReferencesMap
): ViewLegendReferenceType[] => {
  const referenceIds = uniq(
    flatten(
      Object.values(referencesMap).map(references =>
        references.map(({ referenceId }) => referenceId)
      )
    )
  );

  return getReferenceTypes(referenceIds)
    .map(({ typeId }) => {
      const referenceType = getReferenceTypeById(
        workspaceId,
        typeId.toString()
      );

      return referenceType
        ? {
            name: referenceType.name,
            lineBeginning: referenceType.lineBeginning,
            lineEnding: referenceType.lineEnding,
            color: referenceType.color,
            line: referenceType.line,
          }
        : null;
    })
    .filter(ExcludeFalsy);
};

export const getInfiniteScrollBounds = ({
  renderCount,
  currentIndex,
  componentIds,
  isExporting,
}: GetInfiniteScrollBoundsArgs) => {
  if (renderCount === 0) {
    return {
      componentIds: [],
      preSize: 0,
      postSize: 0,
    };
  }

  if (componentIds.length <= INFINITE_SCROLL_THRESHOLD || isExporting) {
    return {
      componentIds: componentIds,
      preSize: 0,
      postSize: 0,
    };
  }

  const lowerBound = Math.max(currentIndex - RENDER_BUFFER, 0);
  const preSize = lowerBound * CELL_SIZE;

  const calculatedCellsToRender = currentIndex + renderCount + RENDER_BUFFER;
  const upperBound = Math.min(calculatedCellsToRender, componentIds.length);
  const postSize = (componentIds.length - upperBound) * CELL_SIZE;

  return {
    componentIds: componentIds.slice(lowerBound, upperBound),
    preSize,
    postSize,
  };
};

export const scrollIndexShouldUpdate = (
  currentIndex: number,
  newIndex: number,
  renderCount: number
) => {
  return (
    newIndex !== currentIndex &&
    (newIndex < currentIndex - RENDER_BUFFER / 2 ||
      newIndex + renderCount > currentIndex + renderCount + RENDER_BUFFER / 2)
  );
};
