import { ComponentRepresentation } from '@ardoq/renderers';
import { classes } from '@ardoq/common-helpers';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { DESCRIPTION_POPOVER_ID } from './ComponentDescriptionPopover';
import type { GraphItemModel } from 'graph/GraphItem';
import type { Node } from 'graph/types';

type RoadMapViewItemProperties = {
  node: Node<GraphItemModel>;
  isLegendItem: boolean;
  label: string;
};
const RoadMapViewItem = ({
  node,
  label,
  isLegendItem,
}: RoadMapViewItemProperties) => {
  const modelId = dataModelId(node);
  const representationData = componentInterface.getRepresentationData(modelId);

  return (
    <div
      className={classes('item', node.getCSS({ useAsBackgroundStyle: true }))}
      tabIndex={0}
      {...(isLegendItem
        ? {}
        : {
            [COMPONENT_ID_ATTRIBUTE]: modelId,
            [POPOVER_ID_ATTR]: DESCRIPTION_POPOVER_ID,
          })}
    >
      <span className="svgIcon">
        <div
          style={{
            display: 'flex',
            height: 18,
            width: 18,
            alignItems: 'center',
          }}
        >
          {representationData && (
            <ComponentRepresentation {...representationData} />
          )}
        </div>
      </span>
      <span className="name">{label}</span>
    </div>
  );
};

export default RoadMapViewItem;
