import { AdvancedSearchError } from './types';

const advancedSearchErrorToVerb = {
  [AdvancedSearchError.EXPORT]: 'exporting',
  [AdvancedSearchError.QUERY]: 'searching',
};

const AdvancedSearchErrorMessage = ({
  searchError,
  ruleErrorMessages = [],
}: {
  searchError: AdvancedSearchError | null;
  ruleErrorMessages?: string[];
}) => {
  const errorVerb = searchError ? advancedSearchErrorToVerb[searchError] : null;
  return (
    <div className="search-error">
      {(ruleErrorMessages.length || null) && (
        <>
          <p>Update the following query errors before {errorVerb}:</p>
          <ul>
            {ruleErrorMessages.map((ruleErrorMessage, index) => (
              <li key={index}>{ruleErrorMessage}</li>
            ))}
          </ul>
        </>
      )}
      {!ruleErrorMessages.length && errorVerb && (
        <>
          <p>
            An error occurred while {errorVerb}. Please verify that your query
            is valid.
          </p>
          <p>We have been notified and are sorry about the inconvenience.</p>
          <p>If the error persists, please contact support.</p>
        </>
      )}
    </div>
  );
};

export default AdvancedSearchErrorMessage;
