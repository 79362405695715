import { debounceTime, startWith } from 'rxjs';
import { action$, ofType, reducedStream, streamReducer } from '@ardoq/rxbeach';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from './fields/FieldActions';
import { APIComponentAttributes, ArdoqId } from '@ardoq/api-types';
import Components from 'collections/components';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
} from './components/ComponentActions';
import { startAction } from 'actions/utils';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';

type ComponentAttributesById = Record<ArdoqId, APIComponentAttributes>;

type HasComponentAttributes = { attributes: APIComponentAttributes };
const impureGetScopeComponentsData = () =>
  Components.collection.reduce(
    (
      acc: ComponentAttributesById,
      { attributes }: HasComponentAttributes
    ): ComponentAttributesById => {
      acc[attributes._id] = attributes;
      return acc;
    },
    {}
  );

type ConstextSortAttributeValuesDefaultState = {
  componentAttributesById: ComponentAttributesById;
};

const reactToModelUpdate = () => ({
  componentAttributesById: impureGetScopeComponentsData(),
});

const componentAttributesUpdate$ = action$.pipe(
  ofType(
    notifyComponentsUpdated,
    notifyComponentsAdded,
    notifyComponentsRemoved,
    notifyFieldAdded,
    notifyFieldRemoved,
    notifyFieldUpdated
  ),
  startWith(startAction()),
  debounceTime(DEFAULT_DEBOUNCE_TIME)
);

const defaultState: ConstextSortAttributeValuesDefaultState = {
  componentAttributesById: {},
};

const componentAttributesData$ = reducedStream(
  'componentAttributesData$',
  defaultState,
  [streamReducer(componentAttributesUpdate$, reactToModelUpdate)]
);

export default componentAttributesData$;
