import { RelationshipsLink, RelationshipsNode } from '../types';
import * as d3 from 'd3';
import { packLayout } from '../packLayout';
import { NODE_MARGIN, NODE_PADDING } from '../consts';
import { simpleHash } from './util';
import { currentTimestamp } from '@ardoq/date-time';

export function incrementalLayout(
  group: RelationshipsNode,
  radius: (node: RelationshipsNode) => number,
  cycles: number
) {
  const bound = () => {
    const mec = d3.packEnclose(group.children!);

    group.r = mec.r + (group.isSynthetic ? 0 : NODE_PADDING);

    group.children!.forEach(child => {
      child.x = Math.round(child.x - mec.x);
      child.y = Math.round(child.y - mec.y);
    });
  };

  if (!group.open || !group.children || group.children.length === 0) {
    group.r = radius(group);

    return true;
  }

  if (group.children.length === 1) {
    /* trivial placement */

    group.children[0].x = 0;
    group.children[0].y = 0;
    group.r = group.children[0].r + (group.isSynthetic ? 0 : NODE_PADDING);

    return true;
  }

  if (!group.packed) {
    const seed = simpleHash(group.id);

    packLayout(group.children, NODE_MARGIN, d3.randomLcg(seed));
    group.packed = true;
  }

  const then = currentTimestamp();

  if (
    !group.layouter ||
    group.layouter.nodes().length !== group.children.length
  ) {
    /* for whatever reason; the group layouter is no good; make one now */

    group.layouter = d3.forceSimulation<RelationshipsNode, RelationshipsLink>(
      group.children!
    );
    group.layouter.stop();
    group.layouter.force('collide', d3.forceCollide()); // stop stuff overlapping
    group.layouter.force('singleBody', d3.forceRadial(0).strength(0.08)); // pull everything toward the center

    /* now possibly add some link forces */

    if (group.children.length > 3) {
      const links: RelationshipsLink[] = [];
      const done = new Set<string>();

      group.children.forEach(node => {
        node.links?.forEach(link => {
          if (link.source.parent !== link.target.parent) {
            return;
          }

          const s1 = link.source.id + link.target.id;
          if (done.has(s1)) {
            return;
          }

          const s2 = link.target.id + link.source.id;
          if (done.has(s2)) {
            return;
          }

          done.add(s1);
          done.add(s2);
          links.push(link);
        });
      });

      if (links.length !== 0) {
        group.layouter.force('links', d3.forceLink(links));
      }
    }
  }

  if (group.layouter) {
    const layouter = group.layouter;

    const forceCollide = layouter.force(
      'collide'
    ) as d3.ForceCollide<RelationshipsNode>;

    if (forceCollide) {
      forceCollide.radius(d => Math.max(d.r + NODE_MARGIN, 30));
    }

    const forceLink = layouter.force('links') as d3.ForceLink<
      RelationshipsNode,
      RelationshipsLink
    >;

    if (forceLink) {
      forceLink.distance(
        link => link.source.r + link.target.r + 2 * NODE_MARGIN
      );
    }

    do {
      group.layouter.tick(1);
    } while (
      group.layouter.alpha() > group.layouter.alphaMin() &&
      currentTimestamp() - then < cycles
    );

    /* decide if the layout is done */

    let v = 0;

    if (group.layouter.alpha() > group.layouter.alphaMin()) {
      group.children.forEach(child => {
        v = Math.max(v, Math.max(Math.abs(child.vx!), Math.abs(child.vy!)));
      });
    }

    if (group.layouter.alpha() <= group.layouter.alphaMin() || v < 0.1) {
      group.layouter = null;
      bound();

      return true;
    }
  }

  bound();
  return false;
}
