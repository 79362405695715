import { GeneralPath, INode, Rect } from '@ardoq/yfiles';
import {
  CollapsibleGraphGroup,
  graphItemLabel,
  ItemLabels,
} from '@ardoq/graph';
import { Node } from '../graph/node';
import getNodeBounds from './getNodeBounds';
import {
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  CONTEXT_HIGHLIGHT_PADDING,
  CONTEXT_HIGHLIGHT_STROKE,
  LABEL_VERTICAL_PADDING,
} from './styles/consts';
import { getLabelPreferredSize } from './styles/measureLabels';
import { isContextNode } from './styles/nodeDecorator';
import { componentInterface } from '@ardoq/component-interface';
import {
  getOtherLabelsHeight,
  getOtherLabelSizes,
  getOtherLabelsWidth,
} from '../tabview/blockDiagram/view/yFilesExtensions/labels/labelUtils';
import { dataModelId } from '../tabview/graphComponent/graphComponentUtil';
import {
  LEGACY_FORMATTING_VALUE_HEIGHT_NODE,
  measureNodeLabelWidth,
} from 'tabview/blockDiagram/view/yFilesExtensions/labels/legacyLabelUtils';
/**
 * return the outline of the node within its bounds, in Classic Block Diagram.
 * this is the point at which the edge will touch the node.
 * the PortAdjustmentPolicy of the graph layout dictates this behavior.
 */
const getNodeOutline = (node: INode) => {
  const isContext = isContextNode(node);
  const { tag: graphItem } = node;

  const isComponent = graphItem.isComponent();

  const isCollapsedComponentGroup =
    graphItem instanceof CollapsibleGraphGroup &&
    graphItem.collapsed &&
    isComponent;
  const nodeBounds = getNodeBounds(node, isContext, isCollapsedComponentGroup);
  const result = new GeneralPath(2);
  result.appendRectangle(nodeBounds, false);

  // the feature is enabled and other labels are present as a nullable property in metadata
  const itemLabels = graphItem.getItemLabels?.() as ItemLabels | undefined;

  const labelText =
    graphItem instanceof Node
      ? componentInterface.getNameWithFieldLabelAndValue(dataModelId(node.tag)) // this is for Swimlanes. It still uses the old Node class
      : (itemLabels?.mainLabel ?? graphItemLabel(graphItem));

  const { otherLabels, legacyTruncatedFieldValueAndLabelForNode } =
    itemLabels ?? {};

  const legacyFormattingHeight = legacyTruncatedFieldValueAndLabelForNode
    ? LEGACY_FORMATTING_VALUE_HEIGHT_NODE
    : 0;

  const legacyFormattingWidth = legacyTruncatedFieldValueAndLabelForNode
    ? measureNodeLabelWidth(legacyTruncatedFieldValueAndLabelForNode)
    : 0;

  const otherLabelsSizes = otherLabels
    ? getOtherLabelSizes(otherLabels, false)
    : [];

  const otherLabelsHeight = getOtherLabelsHeight(otherLabelsSizes);
  const otherLabelsWidth = getOtherLabelsWidth(otherLabelsSizes);

  const { width: mainLabelWidth, height: mainLabelHeight } =
    getLabelPreferredSize(labelText || '', BLOCK_DIAGRAM_NODE_LABEL_FONT, 0);

  const labelWidth = Math.max(
    mainLabelWidth,
    otherLabelsWidth,
    legacyFormattingWidth
  );
  const labelHeight =
    mainLabelHeight +
    otherLabelsHeight +
    legacyFormattingHeight +
    LABEL_VERTICAL_PADDING;

  const labelRight = nodeBounds.center.x + labelWidth / 2;
  const labelLeft = nodeBounds.center.x - labelWidth / 2;
  const labelVerticalOffset = isContext ? CONTEXT_HIGHLIGHT_PADDING : 0;
  const labelBottomInset = CONTEXT_HIGHLIGHT_STROKE;
  const labelTop = nodeBounds.bottomLeft.y + labelVerticalOffset;
  const labelBounds = new Rect(
    labelLeft,
    labelTop,
    labelRight - labelLeft,
    labelHeight + labelBottomInset
  );
  result.appendRectangle(labelBounds, false);
  return result;
};
export default getNodeOutline;
