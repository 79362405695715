import { NumericRange, range } from '@ardoq/graph';

const countDecimals = (num: number): number => {
  let e = 1,
    result = 0;
  while (Math.round(num * e) / e !== num) {
    e *= 10;
    result++;
  }
  return result;
};

const floatErrorProofMagnitude = (power: number): number =>
  +(10 ** power).toFixed(Math.max(0, 0 - power));

export const floatErrorProofMultiply = (a: number, b: number): number =>
  +(a * b).toFixed(countDecimals(a) + countDecimals(b));

export const floatErrorProofAdd = (a: number, b: number): number =>
  +(a + b).toFixed(Math.max(countDecimals(a), countDecimals(b)));

const goodTickCount = (pixels: number, divider = 40): number =>
  pixels / divider;

const nice = (num: number): number => {
  const log = Math.floor(Math.log10(num));
  const magnitude = floatErrorProofMagnitude(log);
  return floatErrorProofMultiply(
    [1, 2, 5].find(basic => num / magnitude <= basic) || 10,
    magnitude
  );
};

export const getRoundedInterval = (
  visibleRange: NumericRange,
  pixels = 100,
  pixelsDivider = 40
): number =>
  nice(nice(range(visibleRange)) / goodTickCount(pixels, pixelsDivider));
