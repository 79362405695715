import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';

const buttonStyles = css`
  display: inline-block;
  outline: none;
  border: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  height: 42px;
  width: 42px;
  line-height: 42px;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  &:active {
    background-color: ${colors.grey60};
  }
  &:hover {
    background-color: ${colors.grey80};
  }
`;
const Button = styled.button`
  ${buttonStyles}
`;
const Label = styled.label`
  ${buttonStyles}
  text-align: center;
  font-size: 16px;
  > svg {
    margin-bottom: 4px;
  }
`;
const Separator = styled.span`
  height: 28px;
  width: 1px;
  background: ${colors.grey60};
  display: inline-block;
  vertical-align: middle;
`;
const PickerContainer = styled.div`
  position: absolute;
  background-color: ${colors.grey95};
  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.16),
    0 2px 5px 0 rgba(0, 0, 0, 0.26);
  opacity: 0;
  display: none;
  transition: opacity 0.2s ease-out;
  padding: 5px;
  width: 128px;
  text-align: center;
  box-sizing: border-box;

  button {
    width: 32px;
    height: 32px;
    margin: 2px;
  }

  &:not(.bottom):after {
    content: '';
    position: absolute;
    display: inline-block;
    border: 8px solid transparent;
    border-top-color: ${colors.grey80};
    left: calc(50% - 8px);
    top: 100%;
  }
  &.bottom:after {
    content: '';
    position: absolute;
    display: inline-block;
    border: 8px solid transparent;
    border-bottom-color: ${colors.grey80};
    left: calc(50% - 8px);
    top: -16px;
  }
`;
export const PICKER_CONTAINER_SELECTOR = PickerContainer.toString();

const ClipboardAction = styled.div`
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    background-color: ${colors.grey80};
  }

  [disabled] {
    opacity: 0.5;
    cursor: default;
    background-color: transparent;
  }
`;
const ClipboardActions = styled(PickerContainer)`
  text-align: left;
  padding: 0;
`;

const ToggleButton = styled.input`
  display: none !important;

  &:checked + label {
    background-color: ${colors.grey80};
  }
  &:checked&:hover + label {
    background-color: ${colors.grey60};
  }
`;
const ContextualToolbarContainer = styled.div`
  &:focus {
    outline: 0;
  }

  color: ${colors.grey50};
  position: absolute;
  display: block;
  opacity: 0;
  box-sizing: border-box;
  user-select: none;
  background-color: ${colors.grey95};
  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.16),
    0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition:
    opacity 0.2s ease-out,
    width 0.2s ease-out;

  #node-ui,
  #label-ui,
  #edge-ui,
  #clipboard-ui {
    display: inline-block;
  }

  .color-picker button {
    transition: border-radius 0.2s ease;
  }

  .color-picker button:hover {
    border-radius: 5px;
  }

  #shape-picker-shapes button {
    background-size: 25px 25px;
  }

  #arrow-picker-types.target button {
    transform: rotate(180deg);
  }

  /* hide separators if they separate nothing */
  &.label-ui-visible:not(.node-ui-visible)
    #label-ui
    ${Separator},
    &.edge-ui-visible:not(.label-ui-visible):not(.node-ui-visible)
    #edge-ui
    ${Separator} {
    display: none;
  }
`;

const ContextualToolbarComponent = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <ContextualToolbarContainer
      id="contextualToolbar"
      className="contextual-toolbar"
      ref={ref}
    >
      <div id="clipboard-ui">
        <ToggleButton
          type="checkbox"
          id="clipboard"
          data-container-id="clipboard-actions"
        />
        <Label htmlFor="clipboard" title="Clipboard">
          <Icon iconName={'paste' as IconName} />
        </Label>
        <Separator />
      </div>
      <div id="node-ui">
        <ToggleButton
          type="checkbox"
          id="shape-picker"
          data-container-id="shape-picker-shapes"
        />
        <Label htmlFor="shape-picker" title="Shape Picker">
          <Icon iconName={'crop_square' as IconName} />
        </Label>
        <ToggleButton
          type="checkbox"
          id="color-picker"
          data-container-id="color-picker-colors"
        />
        <Label htmlFor="color-picker" title="Color Picker">
          <Icon iconName={IconName.PALETTE} />
        </Label>
        <Separator />
        <ToggleButton type="checkbox" id="quick-element-creation" />
        <Label htmlFor="quick-element-creation" title="Quick Element Creation">
          <Icon iconName={IconName.ADD} />
        </Label>
      </div>
      <div id="label-ui">
        <Separator />
        <ToggleButton
          type="checkbox"
          id="font-color-picker"
          data-container-id="font-color-picker-colors"
        />
        <Label htmlFor="font-color-picker" title="Font Color Picker">
          <Icon iconName={'font_download' as IconName} />
        </Label>
        <button title="Decrease Font Size" id="decrease-font-size">
          <Icon iconName={IconName.REMOVE} />
        </button>
        <button title="Increase Font Size" id="increase-font-size">
          <Icon iconName={IconName.ADD} />
        </button>
        <ToggleButton type="checkbox" id="font-bold" data-fontweight="bold" />
        <Label htmlFor="font-bold" title="Bold">
          <Icon iconName={'format_bold' as IconName} />
        </Label>
        <ToggleButton
          type="checkbox"
          id="font-italic"
          data-fontstyle="italic"
        />
        <Label htmlFor="font-italic" title="Italic">
          <Icon iconName={'format_italic' as IconName} />
        </Label>
        <ToggleButton
          type="checkbox"
          id="font-underline"
          data-textdecoration="underline"
        />
        <Label htmlFor="font-underline" title="Underline">
          <Icon iconName={'format_underline' as IconName} />
        </Label>
      </div>
      <div id="edge-ui">
        <Separator />
        <ToggleButton
          type="checkbox"
          id="source-arrow-picker"
          data-container-id="arrow-picker-types"
        />
        <Label htmlFor="source-arrow-picker" title="Source Arrow Picker">
          <Icon iconName={'arrow_left' as IconName} />
        </Label>
        <ToggleButton
          type="checkbox"
          id="edge-color-picker"
          data-container-id="edge-colors"
        />
        <Label htmlFor="edge-color-picker" title="Edge Color Picker">
          <Icon iconName={IconName.PALETTE} />
        </Label>
        <ToggleButton
          type="checkbox"
          id="target-arrow-picker"
          data-container-id="arrow-picker-types"
        />
        <Label htmlFor="target-arrow-picker" title="Target Arrow Picker">
          <Icon iconName={'arrow_right' as IconName} />
        </Label>
      </div>

      <PickerContainer id="color-picker-colors" className="color-picker">
        <Button
          data-color="#DC143C"
          style={{ backgroundColor: '#dc143c' }}
          title="#DC143C"
        />
        <Button
          data-color="#336699"
          style={{ backgroundColor: '336699' }}
          title="#336699"
        />
        <Button
          data-color="#32CD32"
          style={{ backgroundColor: '#32cd32' }}
          title="#32CD32"
        />
        <Button
          data-color="#228B22"
          style={{ backgroundColor: '#228b22' }}
          title="#228B22"
        />
        <Button
          data-color="#FF8C00"
          style={{ backgroundColor: '#ff8c00' }}
          title="#FF8C00"
        />
        <Button
          data-color="#7B68EE"
          style={{ backgroundColor: '#7b68ee' }}
          title="#7B68EE"
        />
      </PickerContainer>

      <PickerContainer id="edge-colors" className="color-picker">
        <Button
          data-color="#DC143C"
          style={{ backgroundColor: '#dc143c' }}
          title="#DC143C"
        />
        <Button
          data-color="#336699"
          style={{ backgroundColor: '#336699' }}
          title="#336699"
        />
        <Button
          data-color="#32CD32"
          style={{ backgroundColor: '#32cd32' }}
          title="#32CD32"
        />
        <Button
          data-color="#228B22"
          style={{ backgroundColor: '#228b22' }}
          title="#228B22"
        />
        <Button
          data-color="#FF8C00"
          style={{ backgroundColor: '#ff8c00' }}
          title="#FF8C00"
        />
        <Button
          data-color="#333333"
          style={{ backgroundColor: '#333333' }}
          title="#333333"
        />
      </PickerContainer>

      <PickerContainer id="font-color-picker-colors" className="color-picker">
        <Button
          data-color="#000000"
          style={{ backgroundColor: '#000000' }}
          title="#000000"
        />
        <Button
          data-color="#FFFFFF"
          style={{ backgroundColor: '#ffffff' }}
          title="#FFFFFF"
        />
        <Button
          data-color="#336699"
          style={{ backgroundColor: '#336699' }}
          title="#336699"
        />
        <Button
          data-color="#DC143C"
          style={{ backgroundColor: '#dc143c' }}
          title="#DC143C"
        />
        <Button
          data-color="#32CD32"
          style={{ backgroundColor: '#32cd32' }}
          title="#32CD32"
        />
        <Button
          data-color="#7B68EE"
          style={{ backgroundColor: '#7b68ee' }}
          title="#7B68EE"
        />
      </PickerContainer>

      <PickerContainer id="shape-picker-shapes">
        <Button title="Rectangle" data-shape="RECTANGLE">
          <Icon iconName={'crop_square' as IconName} />
        </Button>
        <Button title="Ellipse" data-shape="ELLIPSE">
          <Icon iconName={'radio_button_unchecked' as IconName} />
        </Button>
        <Button title="Star-6" data-shape="STAR6">
          <Icon iconName={'star' as IconName} />
        </Button>
        <Button title="Hexagon" data-shape="HEXAGON">
          <Icon iconName={'looks_6' as IconName} />
        </Button>
        <Button title="Triangle" data-shape="TRIANGLE">
          <Icon iconName={'change_history' as IconName} />
        </Button>
        <Button title="Trapez" data-shape="TRAPEZ">
          <Icon iconName={'eject' as IconName} />
        </Button>
      </PickerContainer>

      <PickerContainer id="arrow-picker-types">
        <Button title="Circle" data-type="CIRCLE">
          <Icon iconName={'radio_button_unchecked' as IconName} />
        </Button>
        <Button title="Default" data-type="DEFAULT">
          <Icon iconName={'face' as IconName} />
        </Button>
        <Button title="Diamond" data-type="DIAMOND">
          <Icon iconName={'diamond' as IconName} />
        </Button>
        <Button title="None" data-type="NONE">
          <Icon iconName={'do_not_disturb' as IconName} />
        </Button>
        <Button title="Short" data-type="SHORT">
          <Icon iconName={'show_chart' as IconName} />
        </Button>
        <Button title="Triangle" data-type="TRIANGLE">
          <Icon iconName={'change_history' as IconName} />
        </Button>
      </PickerContainer>

      <ClipboardActions id="clipboard-actions">
        <ClipboardAction data-command="Cut">
          <Icon iconName={'cut' as IconName} />
          <span>Cut</span>
        </ClipboardAction>
        <ClipboardAction data-command="Copy">
          <Icon iconName={'copy' as IconName} />
          <span>Copy</span>
        </ClipboardAction>
        <ClipboardAction data-command="Paste">
          <Icon iconName={'paste' as IconName} />
          <span>Paste</span>
        </ClipboardAction>
        <ClipboardAction data-command="Duplicate">
          <Icon iconName={'file_copy' as IconName} />
          <span>Duplicate</span>
        </ClipboardAction>
        <ClipboardAction data-command="Delete">
          <Icon iconName={'delete' as IconName} />
          <span>Delete</span>
        </ClipboardAction>
      </ClipboardActions>
    </ContextualToolbarContainer>
  );
});
export default ContextualToolbarComponent;
