import { componentInterface } from '@ardoq/component-interface';
import { RepresentationData } from '@ardoq/data-model';
import { getIcons } from '@ardoq/icons';
import type { ModernizedBlockDiagramViewSettings } from 'tabview/blockDiagram/types';

const VOID_REPRESENTATION_DATA: RepresentationData = {
  isImage: false,
  value: null,
};

const resolve = (
  image: string | null,
  icon: string | null,
  shape: string | null,
  componentStyle: ModernizedBlockDiagramViewSettings['componentStyle']
) => {
  if (image) {
    return { isImage: true, value: image };
  }
  if (icon && componentStyle === 'icon') {
    return { isImage: false, value: icon, icon: getIcons()[icon] };
  }
  if (shape && componentStyle === 'shape') {
    return { isImage: false, value: null, shapeName: shape };
  }
  if (icon || shape) {
    return {
      isImage: false,
      value: icon,
      shapeName: shape,
      icon: icon ? getIcons()[icon] : undefined,
    };
  }
  return null;
};

const modernizedBlockDiagramRepresentationData =
  (componentStyle: ModernizedBlockDiagramViewSettings['componentStyle']) =>
  (modelId: string): RepresentationData => {
    const componentImage = componentInterface.getFieldValue(
      modelId,
      'image'
    ) as string | null;
    const componentIcon = componentInterface.getFieldValue(modelId, 'icon') as
      | string
      | null;
    const componentShape = componentInterface.getFieldValue(
      modelId,
      'shape'
    ) as string | null;

    const componentRepresentationData = resolve(
      componentImage,
      componentIcon,
      componentShape,
      componentStyle
    );
    if (componentRepresentationData) {
      return componentRepresentationData;
    }
    const componentType = componentInterface.getType(modelId);
    if (!componentType) {
      return VOID_REPRESENTATION_DATA;
    }
    const { image, icon, shape } = componentType;
    return (
      resolve(image, icon, shape, componentStyle) ?? VOID_REPRESENTATION_DATA
    );
  };
export default modernizedBlockDiagramRepresentationData;
