import { BaseClass, INode, INodeInsetsProvider, Insets } from '@ardoq/yfiles';
import { groupBounds } from './GroupBoundsCalculator';
import {
  BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
  GROUP_PADDING,
} from '../consts';

/** Group Insets Provider for Classic Block Diagram. */
export default class GroupInsetsProvider extends BaseClass(
  INodeInsetsProvider
) {
  /** The Singleton Instance of {@link GroupInsetsProvider}. */
  static Instance = new GroupInsetsProvider();
  private constructor() {
    super();
  }
  override getInsets(node: INode): Insets {
    const bounds = groupBounds(node);
    const headerHeight = bounds.height;
    return new Insets(
      BLOCK_DIAGRAM_GROUP_HEADER_AFTER_EXPANDER_X,
      headerHeight,
      GROUP_PADDING,
      GROUP_PADDING
    );
  }
}
