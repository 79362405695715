import { Observable, combineLatest } from 'rxjs';
import defaultState from 'views/defaultState';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import {
  DependencyMatrixViewModel,
  DependencyMatrixViewSettings,
} from '../types';
import {
  action$,
  combineReducers,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { EMPTY_LIST_DATA } from '../consts';
import { bypassLimitAction } from './actions';
import { isPresentationMode } from 'appConfig';
import { buildViewModel } from './buildViewModel';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { ExtractStreamShape } from 'tabview/types';
import { getFocusedStream } from 'tabview/streams';
import { focusedComponentChanged } from 'tabview/actions';

const VIEW_ID = ViewIds.DEPMATRIX;

const emptyState: DependencyMatrixViewModel = {
  viewSettings: defaultState.get(
    ViewIds.DEPMATRIX
  ) as DependencyMatrixViewSettings,
  workspaceId: '',
  workspacesIds: [],
  componentId: '',
  focusedComponentId: null,
  targetData: EMPTY_LIST_DATA,
  sourceData: EMPTY_LIST_DATA,
  references: {},
  legendReferenceTypes: [],
  noConnectedComponents: false,
  bypassLimit: false,
  errors: [],
  permissionContext: permissionsOperations.createEmptyPermissionContext(),
};

const reset = ({
  viewSettings,
  componentId,
  workspaceId,
  workspacesIds,
  focusedComponentId,
  targetData,
  sourceData,
  references,
  legendReferenceTypes,
  noConnectedComponents,
  bypassLimit,
  errors,
  permissionContext,
}: DependencyMatrixViewModel): DependencyMatrixViewModel => {
  if (!workspaceId) {
    return emptyState;
  }

  return {
    viewSettings,
    workspaceId,
    workspacesIds,
    componentId,
    focusedComponentId,
    targetData,
    sourceData,
    references,
    legendReferenceTypes,
    noConnectedComponents,
    bypassLimit,
    errors,
    permissionContext,
  };
};

const handleFocusedComponentChanged = (
  state: DependencyMatrixViewModel,
  focusedComponentId: ArdoqId | null
): DependencyMatrixViewModel => ({
  ...state,
  focusedComponentId:
    focusedComponentId !== state.focusedComponentId ? focusedComponentId : null,
});

export const getViewModel$ = (
  viewState$: Observable<DependencyMatrixViewSettings>
) => {
  const reset$ = combineLatest([
    viewState$,
    context$,
    getFocusedStream(VIEW_ID),
    modelUpdateNotification$,
  ]);
  const resetReducer = (
    state: DependencyMatrixViewModel,
    [viewSettings, context]: ExtractStreamShape<typeof reset$>
  ) =>
    reset(
      buildViewModel({
        viewSettings,
        workspaceId: context.workspaceId,
        workspacesIds: context.workspacesIds,
        componentId: context.componentId,
        focusedComponentId: state.focusedComponentId,
        bypassLimit: isPresentationMode(),
        permissionContext: state.permissionContext,
      })
    );

  const permissionContextReducer = (
    state: DependencyMatrixViewModel,
    permissionContext: PermissionContext
  ) => ({
    ...state,
    permissionContext,
  });

  const bypassLimitReducer = (state: DependencyMatrixViewModel) => ({
    ...state,
    ...buildViewModel({
      viewSettings: state.viewSettings,
      workspaceId: state.workspaceId,
      workspacesIds: state.workspacesIds,
      componentId: state.componentId,
      focusedComponentId: state.focusedComponentId,
      bypassLimit: true,
      permissionContext: state.permissionContext,
    }),
  });

  return action$.pipe(
    combineReducers<DependencyMatrixViewModel>(emptyState, [
      streamReducer(reset$, resetReducer),
      reducer(focusedComponentChanged, handleFocusedComponentChanged),
      reducer(bypassLimitAction, bypassLimitReducer),
      streamReducer(currentUserPermissionContext$, permissionContextReducer),
    ])
  );
};
