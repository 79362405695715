import groupByCollection from 'collections/groupByCollection';
import { buildGraph } from 'modelInterface/graph/buildGraphForWorkspaceMode';
import {
  componentInterface,
  componentOrDescendantsAreIncludedInContextByFilter,
} from '@ardoq/component-interface';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import type { GraphModelShape, ContextShape } from '@ardoq/data-model';
import { ViewIds } from '@ardoq/api-types';
import {
  getGraphInterfaceWithModelInterfaces,
  getRelevantComponentIds,
  GraphItem,
} from '@ardoq/graph';
import { getGraphData, componentToNode } from '@ardoq/timeline';
import { CollectionView } from 'collections/consts';
import { type GraphViewModel } from '@ardoq/graph';
import { Features, hasFeature } from '@ardoq/features';

interface GetDataResult extends Pick<GraphViewModel, 'errors' | 'hasClones'> {
  nodes: GraphItem[];
  childrenMap: Map<string, GraphItem[]>;
  hasComponentsAvailable: boolean;
}
export const getData = (
  context: ContextShape,
  graph: GraphModelShape,
  useNewGrouping: boolean
): GetDataResult => {
  // if context component has child nodes, show it and its children. otherwise, show context component siblings or workspace root components.
  const contextComponentHasChildNodes =
    context.componentId &&
    componentInterface.getChildren(context.componentId, true).length;

  const componentIds = contextComponentHasChildNodes
    ? [context.componentId]
    : getRelevantComponentIds(context);

  const startSet = contextComponentHasChildNodes
    ? componentIds
    : componentIds.filter(componentOrDescendantsAreIncludedInContextByFilter);

  /**
   * remove interface calls from viewModel scope and pass GraphContext
   * as argument to use scopeData utils.
   * Currently, the graphInterface is used to ensure that the graph can
   * be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();

  if (groupByCollection.length > 0) {
    const { rootGroups, componentMap, groupMap, errors, hasClones } =
      buildGraph({
        graphInterface,
        viewId: ViewIds.TIMELINE,
        graph,
        startSet,
        groupingRules: groupingRuleInterface.getAll(),
        traverseOptions: {
          maxDegreesIncoming: 0,
          maxDegreesOutgoing: 0,
          maxDegrees: 10,
          isParentRelationAsReference: true,
          outgoingReferenceTypes: null,
          incomingReferenceTypes: null,
          nodeCollectionView: CollectionView.DEFAULT_VIEW,
        },
        useNewGrouping,
      });

    const { nodes, childrenMap } = getGraphData({
      componentMap,
      groupMap,
      rootGroups,
      startSet,
      contextComponentId: context.componentId,
      isRootGroupsInContextOnly: hasFeature(
        Features.ROOT_GROUPS_IN_CONTEXT_ONLY
      ),
    });

    return {
      nodes,
      childrenMap,
      errors,
      hasClones,
      hasComponentsAvailable: !!componentIds.length,
    };
  }

  const childrenMap = new Map();
  return {
    nodes: startSet.map(modelId => componentToNode(modelId, childrenMap)),
    childrenMap,
    errors: [],
    hasClones: false,
    hasComponentsAvailable: !!componentIds.length,
  };
};
