import { CSSProperties, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconName, InternalIconName } from '@ardoq/icons';
import { TooltipTemplate } from '@ardoq/tooltip';
import { colors } from '@ardoq/design-tokens';

const IconContainer = styled.div<{
  $display: string;
  $color: string;
  $hoverColor: string;
  $hasOnClick: boolean;
}>`
  display: ${props => props.$display};
  user-select: none;
  ${props => css`
    cursor: ${props.$hasOnClick ? 'pointer' : 'default'};
    color: ${props.$color};
    &:hover {
      color: ${props.$hoverColor};
    }
  `}
`;

type IconClickableProps = {
  iconName: IconName | InternalIconName;
  color?: string;
  hoverColor?: string;
  tooltipText?: string;
  display?: string;
  containerStyle?: CSSProperties;
  tooltipTemplate?: TooltipTemplate;
} & HTMLProps<HTMLElement>;

const IconClickable = ({
  iconName,
  onClick,
  color = colors.grey35,
  hoverColor = colors.grey25,
  tooltipText,
  tooltipTemplate,
  display = 'inline-block',
  containerStyle,
  ...rest
}: IconClickableProps) => (
  <IconContainer
    $color={color}
    $hoverColor={hoverColor}
    $hasOnClick={Boolean(onClick)}
    onClick={onClick}
    data-tooltip-text={tooltipText}
    data-tooltip-template={tooltipTemplate}
    $display={display}
    style={containerStyle}
  >
    <Icon iconName={iconName} {...rest} />
  </IconContainer>
);

export default IconClickable;
