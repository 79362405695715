import {
  Insets,
  InteriorLabelModel,
  InteriorLabelModelPosition,
} from '@ardoq/yfiles';
import { MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT } from 'yfilesExtensions/styles/modernized/consts';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT } from 'yfilesExtensions/styles/consts';
import { MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING } from '../consts';

const COLLAPSED_COMPONENT_GROUP_HEIGHT_UNDER_STRIPE = 120;

const collapsedComponentGroupLayoutParameter = (labelText: string) => {
  const { height } = measureLabelElement(
    labelText,
    MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
  );
  const availableHeight =
    COLLAPSED_COMPONENT_GROUP_HEIGHT_UNDER_STRIPE -
    MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_SHAPE_HEIGHT -
    3 * MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING;
  const bottomInset = Math.max(
    MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING,
    (availableHeight - height) / 2
  );

  return new InteriorLabelModel({
    insets: new Insets(0, 0, 0, bottomInset),
  }).createParameter(InteriorLabelModelPosition.SOUTH);
};
export default collapsedComponentGroupLayoutParameter;
