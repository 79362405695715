import {
  ObservableState,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { selectionToggle } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import {
  componentGraphSelectionChanged,
  componentTreeSelectionChanged,
} from './componentSelectionActions';
import { componentSelectionOperations } from './componentSelectionOperations';
import { contextSelection$ } from './contextSelection$';
import { mainNavigatorActionNamespace } from 'navigator2024/consts';
import { ComponentSelection } from './componentSelectionTypes';

/**
 * The main model for the component selection in our app. It can contain
 * workspace ids.
 * Spec: https://ardoqcom.atlassian.net/wiki/spaces/PRODUCT/pages/3163586586/Multi+Selection
 */
export const componentSelection$: ObservableState<ComponentSelection> =
  persistentReducedStream(
    'componentSelection$',
    {
      graphSelection: [],
      treeSelection: [],
      selectionStart: '',
    },
    [
      streamReducer(
        contextSelection$,
        componentSelectionOperations.contextSelectionChanged
      ),
      reducer(
        componentGraphSelectionChanged,
        componentSelectionOperations.componentGraphSelectionChanged
      ),
      reducer(
        componentTreeSelectionChanged,
        componentSelectionOperations.componentTreeSelectionChanged
      ),
      reducer(
        selectionToggle,
        componentSelectionOperations.toggleTreeSelection
      ),
    ],
    { namespace: mainNavigatorActionNamespace }
  );
