export const AXIS_LABEL_HEIGHT = 16;
export const LEGEND_X_OFFSET = 65;
export const LEGEND_ROW_HEIGHT = 20;
export const LEGEND_FONT_SIZE = 16;
export const NINETY_DEGREES = Math.PI / 2; // we subtract this from the angle before scaling so that the chart starts at 12 o'clock, which is what mortals prefer.
export const LABEL_PADDING = 2;
export const DATAPOINT_RADIUS = 5;
export const MIN_CHART_HEIGHT = 400;
export const EXTRA_CHART_HEIGHT = 100;
export const MIN_CHART_WIDTH = 600;
export const LABEL_MAX_LENGTH = 40;
export const OPACITY_AREA = 0.1;
export const ACTIVE_OPACITY_AREA = 0.4;

export const POLYGON_INDEX_ATTRIBUTE = 'data-polygon-index';
