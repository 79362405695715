import { VIEW_SCROLLBAR_WIDTH_CSS_VARIABLE } from './consts';

const setViewScrollbarWidthCssVariable = (
  element: HTMLElement,
  value: number
) => element.style.setProperty(VIEW_SCROLLBAR_WIDTH_CSS_VARIABLE, `${value}px`);

const calculateAndSetViewScrollbarWidth = (
  container: HTMLElement,
  scrollableContainer: HTMLElement
) =>
  setViewScrollbarWidthCssVariable(
    container,
    getScrollbarWidth(scrollableContainer)
  );

const getScrollbarWidth = (element: HTMLElement) =>
  element.offsetWidth - element.clientWidth;

const recordViewScrollbarWidth = (
  container: HTMLElement,
  scrollableContainer: HTMLElement
) => {
  calculateAndSetViewScrollbarWidth(container, scrollableContainer);
  const observer = new ResizeObserver(() =>
    calculateAndSetViewScrollbarWidth(container, scrollableContainer)
  );
  observer.observe(scrollableContainer);
  return observer;
};
export default recordViewScrollbarWidth;
