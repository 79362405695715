/**
 * When visual state is changing faster than the browser can animate, use the returned function for frame skipping.
 * @returns a function which can be used as a dispatcher queue, executing via requestAnimationFrame and filtering out repetitive calls.
 */
const createDispatcher = <THash>() => {
  const pendingActions = new Map<THash, VoidFunction>();
  /**
   * The dispatcher queue. Use this function for frame skipping.
   * @param action The function to invoke when the dispatcher queue is ready.
   * @param hashArgs The function to determine distinctness of arguments. If `hashArgs` returns the same result for subsequent calls to the dispatcher, then the dispatcher can skip frames, and will use the latest distinct arguments.
   * @returns a function which can be invoked with the same arguments as `action`. The function will be deferred via `requestAnimationFrame`, skipping frames which are determined to be redundant.
   */
  return <TFunc extends (...args: any[]) => void>(
    action: TFunc,
    hashArgs: (...args: Parameters<TFunc>) => THash
  ) => {
    /** this function was created via `createDispatcher`. Invocations will be deferred via `requestAnimationFrame` and can be skipped when frame skipping is happening and the invocation is deemed redundant by the `hashArgs` function. */
    return (...args: Parameters<TFunc>) => {
      const argsHash = hashArgs(...args);
      pendingActions.set(argsHash, () => action(...args));
      requestAnimationFrame(() => {
        pendingActions.forEach(pendingAction => pendingAction());
        pendingActions.clear();
      });
    };
  };
};

export default createDispatcher;
