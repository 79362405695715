import { HtmlCanvasVisual, IRenderContext } from '@ardoq/yfiles';
import { colors } from '@ardoq/design-tokens';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';

class CanvasLabelVisual extends HtmlCanvasVisual {
  constructor(
    private x: number,
    private y: number,
    private fontSize: number,
    private label: string
  ) {
    super();
  }
  paint(renderContext: IRenderContext, ctx: CanvasRenderingContext2D): void {
    ctx.font = `${this.fontSize}px ${ARDOQ_DEFAULT_FONT_FAMILY}`;
    ctx.fillStyle = colors.black;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(this.label, this.x, this.y);
  }
}
export default CanvasLabelVisual;
