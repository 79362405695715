import {
  type GraphInputMode,
  ModifierKeys,
  type ItemClickedEventArgs,
  type IModelItem,
  ILabel,
} from '@ardoq/yfiles';
import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import { isMac } from '@ardoq/common-helpers';
import { isLinking } from 'createReferences2024/createReferences$';

const graphItemClickedListener = (
  _: GraphInputMode,
  e: ItemClickedEventArgs<IModelItem>
) => {
  if (
    isLinking() ||
    (ILabel.isInstance(e.item) && e.item.style instanceof ArdoqURLLabelStyle)
  ) {
    e.handled = true;
    return;
  }
  const mac = isMac();
  if (mac && e.modifiers === ModifierKeys.CONTROL) {
    // this is a right-click. show the context menu - selection should not change.
    e.handled = true;
    return;
  }
};

export default graphItemClickedListener;
