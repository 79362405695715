import {
  APIEntityType,
  WorkspaceDigestStatTypes,
  WorkspaceDigestChange,
  WorkspaceDigestChanges,
  WorkspaceDigestUpdateChange,
} from '@ardoq/api-types';

const WORKSPACE_DIGEST_STAT_TYPES_TO_ENTITY_TYPE_LOOKUP: Record<
  WorkspaceDigestStatTypes,
  APIEntityType
> = {
  Pages: APIEntityType.COMPONENT,
  References: APIEntityType.REFERENCE,
  Tags: APIEntityType.TAG,
};

type TableData = { type: string; hasHistoy?: boolean } | WorkspaceDigestChange;

type WorkspaceDigestChangesKeys = keyof WorkspaceDigestChanges;

/**
 *
 * @param {WorkspaceDigestChanges} changes Render Table Data from WorkspaceDigestChanges
 * @param {WorkspaceDigestStatTypes} type
 * @returns
 */
export function getTableData(
  changes: WorkspaceDigestChanges,
  type: WorkspaceDigestStatTypes
) {
  const statsType: WorkspaceDigestChangesKeys = getStatsType(type);
  const data = changes[statsType];
  if (!data) {
    return [];
  }
  return [
    ...data.new.map(
      (change: WorkspaceDigestChange): TableData => ({ ...change, type: 'new' })
    ),
    ...data.updated.map(parseUpdateChanges),
  ].map(item => ({
    ...item,
    entityType: WORKSPACE_DIGEST_STAT_TYPES_TO_ENTITY_TYPE_LOOKUP[type],
  }));
}

/**
 * Return The correct display string for Stats Types
 * @param {WorkspaceDigestStatTypes} type
 * @returns {string}
 */
export const getStatsTypeText = (type: WorkspaceDigestStatTypes): string =>
  type !== 'Pages' ? type : 'Components';

/**
 * Helper Function to get the correct property from WorkspaceDigestChanges by StatType
 * @param type
 * @returns {WorkspaceDigestChangesKeys}
 */
function getStatsType(
  type: WorkspaceDigestStatTypes
): WorkspaceDigestChangesKeys {
  switch (type) {
    case 'Pages':
      return 'components';
    case 'Tags':
      return 'tags';
    case 'References':
      return 'references';
    default:
      return 'unknown';
  }
}

function parseUpdateChanges(change: WorkspaceDigestUpdateChange): TableData {
  return {
    ...change.current,
    type: 'update',
  };
}
