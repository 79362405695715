import { useRef, useState } from 'react';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { ComponentDataSourceItem, ComponentTreeViewSettings } from '../types';
import { ComponentTree } from './ComponentTree';
import { getViewModel$ } from '../models/viewModel$';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { isPresentationMode } from 'appConfig';
import { SettingsBar } from '@ardoq/settings-bar';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import getExportSettings from 'viewSettings/getExportSettings';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getLeftMenu } from '../utils/menuConfigParams';
import { connect } from '@ardoq/rxbeach';
import {
  ComponentTreeContainer,
  ContextMenuWrapper,
  ViewContainer,
} from '../atoms';
import {
  ViewError,
  getComponentTypesInfo,
  ViewLegendContainer,
} from '@ardoq/graph';
import GraphViewLegend from 'tabview/graphViews/graphViewLegend/GraphViewLegend';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { noop } from 'lodash';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { componentTreeCommands } from '../commands';

interface ComponentTreeViewProps {
  viewSettings: ComponentTreeViewSettings;
  sourceDataWithRenderingData: ComponentDataSourceItem[];
  collapsed: Record<ArdoqId, boolean>;
  collapsibleIds: ArdoqId[];
  focusedComponentId: ArdoqId | null;
  hoveredComponentId: ArdoqId | null;
  errors: ViewError[];
}

const VIEW_ID = ViewIds.COMPONENTTREE;

const viewState$ = getViewSettingsStream<ComponentTreeViewSettings>(VIEW_ID);

const ComponentTreeView = (props: ComponentTreeViewProps) => {
  const {
    viewSettings,
    sourceDataWithRenderingData,
    collapsed,
    collapsibleIds,
    errors,
  } = props;
  const { isLegendActive } = viewSettings;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [clearedErrors, setClearedErrors] = useState(false);

  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    VIEW_ID,
    NEVER_SHOW_AGAIN
  );

  return (
    <ContextMenuWrapper data-context-menu={OBJECT_CONTEXT_MENU_NAME}>
      {!isPresentationMode() && (
        <SettingsBar
          viewId={VIEW_ID}
          leftMenu={getLeftMenu({ collapsed, collapsibleIds })}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            withLegend: true,
            viewstate: viewSettings,
            exports: getExportSettings(VIEW_ID, containerRef),
            knowledgeBaseLink: KnowledgeBaseLink.COMPONENT_TREE,
            onViewSettingsUpdate,
          })}
        />
      )}

      <ViewContainer
        ref={containerRef}
        onClick={() => {
          componentTreeCommands.unfocusFocusedComponent();
        }}
      >
        <ComponentTreeContainer>
          <ComponentTree dataSource={sourceDataWithRenderingData} />
        </ComponentTreeContainer>

        <ErrorInfoBox
          errors={clearedErrors ? [] : errors}
          hasClones={false}
          clearHasClones={noop}
          isShowNeverAgainSet={neverShowAgain}
          isPresentationMode={isPresentationMode()}
          clearErrors={() => setClearedErrors(true)}
          toggleNeverShowAgain={toggleNeverShowAgain}
        />

        <ViewLegendContainer
          heightOffset={useViewLegendSubscription()}
          visible={isLegendActive}
          // setting this here because margin-left style set by className is ignored while exporting to PNG
          style={{ marginLeft: 'auto' }}
        >
          <GraphViewLegend
            componentTypes={
              getComponentTypesInfo(
                sourceDataWithRenderingData.map(({ id }) => id)
              ).legendComponentTypes
            }
            referenceTypes={[]}
            hasCollapsedNodes={false}
            hasReferenceParents={false}
            hasNonComponentNodes={false}
            isUserDefinedGrouping={false}
            showComponentSwatches={true}
            showReferenceConditionalFormatting={false}
            showComponentShapes={false}
            showReferencesAsLines={false}
            activeDiffMode={null}
          />
        </ViewLegendContainer>
      </ViewContainer>
    </ContextMenuWrapper>
  );
};

export default connect(ComponentTreeView, getViewModel$(viewState$));
