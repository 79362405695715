import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  loadQuery,
  loadQueryError,
  loadQuerySuccess,
  pageSelect,
  sortChange,
} from './actions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import searchResultApi$ from './searchResultApi$';
import { advancedSearchApi } from '@ardoq/api';
import { UnknownDocumentType } from '@ardoq/api-types';
import { getSearchParams } from 'search/AdvancedSearch/utils';
import { isArdoqError } from '@ardoq/common-helpers';

const handleLoadQuery = routine(
  ofType(loadQuery),
  withLatestFrom(searchResultApi$),
  switchMap(([, state]) =>
    advancedSearchApi.search<UnknownDocumentType>(
      state.query,
      getSearchParams(state)
    )
  ),
  tap(searchResults => {
    if (isArdoqError(searchResults)) {
      dispatchAction(
        loadQueryError({
          errorMsg: 'Loading data failed',
        })
      );
    } else {
      dispatchAction(loadQuerySuccess(searchResults));
    }
  })
);

const handleSelectAndSort = routine(
  ofType(pageSelect, sortChange),
  tap(() => {
    dispatchAction(loadQuery());
  })
);

export default collectRoutines(handleLoadQuery, handleSelectAndSort);
