import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { focusedComponentChanged } from 'tabview/actions';

const VIEW_ID = ViewIds.SPIDER;

const setFocusedItemState = (itemId: ArdoqId | null) => {
  dispatchAction(focusedComponentChanged(itemId), VIEW_ID);
};

export const spiderChartCommands = {
  setFocusedItemState,
};
