import { createFifoCache } from '@ardoq/common-helpers';
import {
  GraphComponent,
  HtmlCanvasVisual,
  INode,
  IRenderContext,
} from '@ardoq/yfiles';
import nodeBoundingShape from '../../nodeBoundingShape';
import type { Point } from '@ardoq/math';

class CanvasConvexHullGroupVisual extends HtmlCanvasVisual {
  constructor(
    private group: INode,
    private fill: string,
    private stroke: string
  ) {
    super();
  }
  paint(renderContext: IRenderContext, ctx: CanvasRenderingContext2D): void {
    const graph = (renderContext.canvasComponent as GraphComponent).graph;
    const nodeBounds: (node: INode) => Point[] = createFifoCache(
      Infinity,
      (node: INode) => nodeBoundingShape(node, graph, 10, nodeBounds)
    );
    const [firstPoint, ...points] = nodeBounds(this.group);
    if (!firstPoint || !points) {
      return;
    }
    ctx.beginPath();
    const [startX, endX] = firstPoint;
    ctx.moveTo(startX, endX);
    points.forEach(([x, y]) => ctx.lineTo(x, y));
    ctx.closePath();
    ctx.fillStyle = this.fill;
    ctx.fill();
    ctx.strokeStyle = this.stroke;
    ctx.stroke();
  }
}
export default CanvasConvexHullGroupVisual;
