import { ArdoqId } from '@ardoq/api-types';
import { returnPayload } from '@ardoq/common-helpers';
import {
  persistentReducedStream,
  reducer,
  actionCreator,
} from '@ardoq/rxbeach';

export type PayloadLastClickedCell = {
  sourceId?: ArdoqId;
  targetId?: ArdoqId;
  highlightAsLastClicked?: boolean;
};

export const dependencyMatrixCellClicked =
  actionCreator<PayloadLastClickedCell>(
    '[depmatrix] DEPENDENCY_MATRIX_CELL_CLICKED'
  );

const handleDependencyMatrixCellClicked = reducer(
  dependencyMatrixCellClicked,
  returnPayload<PayloadLastClickedCell>
);

const defaultState: PayloadLastClickedCell = {};

const reducers = [handleDependencyMatrixCellClicked];

export const dependencyMatrixLastClickedCell$ =
  persistentReducedStream<PayloadLastClickedCell>(
    'dependencyMatrixLastClickedCell$',
    defaultState,
    reducers
  );
