import { Rectangle, Vector } from '@ardoq/graph';
import { rectCenter, rectHeight, rectWidth } from './geometry';

export type Projection = {
  adjustToViewport: (vp: Rectangle, dpr: number) => void;
  adjustToRect: (wd: Rectangle, margin: number) => void;
  zoomToPoint: (wd: Vector, scale: number) => void;
  adjustToPoint: (wd: Vector, vp: Vector) => void;
  toViewport: (wd: Vector) => Vector;
  toWorld: (wd: Vector) => Vector;
  window: () => Rectangle;
  viewport: () => Rectangle;
  translateBy: (wd: Vector) => void;
  scale: () => number;
  matrix: () => DOMMatrix;
  resolution: () => number;
};

export const createProjection = (): Projection => {
  let viewport: Rectangle = [0, 0, 1, 1];
  let scale: number = 1;
  let translation: Vector = [0, 0];
  let dpr = 1;

  /**
   * set the viewport. The projection will adjust itself as best it can
   * @param rect the viewport rectangle.
   * @param res (UNUSED) the viewport resolution (likely determined by window.devicePixelRatio)
   */
  const adjustToViewport = (rect: Rectangle, _res: number = 1) => {
    dpr = 1;
    viewport = [rect[0] * dpr, rect[1] * dpr, rect[2] * dpr, rect[3] * dpr];
  };

  // adjust projection to best fit specified world rect
  const adjustToRect = (wd: Rectangle, margin: number) => {
    const vp = viewport;
    const wdSize = [rectWidth(wd), rectHeight(wd)];
    const vpSize = [rectWidth(vp) - 2 * margin, rectHeight(vp) - 2 * margin];

    scale = Math.min(vpSize[0] / wdSize[0], vpSize[1] / wdSize[1]);
    adjustToPoint(rectCenter(wd), rectCenter(vp));
  };

  // adjust projection to zoom about the specified world point
  const zoomToPoint = (center: Vector, value: number) => {
    const vp = toViewport(center);

    scale = value;
    adjustToPoint(center, vp);
  };

  // adjust projection to put the specified world point at the specified viewport point
  const adjustToPoint = (wd: Vector, vp: Vector) => {
    translation = [vp[0] - wd[0] * scale, vp[1] - wd[1] * scale];
  };

  // pan the projection by the specified amount. do not like
  const translateBy = (wd: Vector) => {
    translation = [
      translation[0] + wd[0] * scale,
      translation[1] + wd[1] * scale,
    ];
  };

  /* convert a world point to viewport coordinates */
  const toViewport = (wd: Vector): Vector => {
    return [wd[0] * scale + translation[0], wd[1] * scale + translation[1]];
  };

  /* convert a device point to world coordinates */
  const toWorld = (vp: Vector): Vector => {
    return [
      (vp[0] * dpr - translation[0]) / scale,
      (vp[1] * dpr - translation[1]) / scale,
    ];
  };

  /* get the derived window rectangle in world coordinates */
  const window = (): Rectangle => {
    return [
      (viewport[0] - translation[0]) / scale,
      (viewport[1] - translation[1]) / scale,
      (viewport[2] - translation[0]) / scale,
      (viewport[3] - translation[1]) / scale,
    ];
  };

  const matrix = () =>
    new DOMMatrix([scale, 0, 0, scale, translation[0], translation[1]]);

  return {
    adjustToViewport, // adjust the transform to best fit itself to the specified viewport
    adjustToRect, // adjust the transform to best fit itself to the current viewport
    adjustToPoint, // adjust the translation to place the specified world position at the specified viewport position
    zoomToPoint, // adjust the scale around the specified world position
    translateBy,
    matrix, // get a DOMMatrix which represents the projection
    window, // get the derived window rectangle in world coordinates
    viewport: () => viewport, // get the device rectangle in device coordinates
    scale: () => scale, // get the scale. this is useful for resolution dependent rendering

    toViewport,
    toWorld,

    resolution: () => 1 / scale,
  };
};
