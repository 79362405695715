import styled, { css } from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import {
  CONTENT_MAX_WIDTH,
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import { colors } from '@ardoq/design-tokens';

/* Chrome does not have a problem with generating content fragmentation boxes with block
   elements, but browsers can fragment content differently.
   I therefore would leave the page-break properties here for possible cross-browser issues.

   Multicolumn Flex layout does not generate those boxes in a way that allows clean page
   breaks in all browsers as of today.

   When using 'display:inline-block', the blocks are considered as lines when generating
   content boxes, therefore the 'orphans' property in can regulate the minimum amount
   of the HeaderAndTableContainer "lines", that are allowed to be separated from the
   rest of the block when inserting a page break.
  */

export const HeaderAndTableContainer = styled.div`
  /* avoids separation of the header from the table body */
  display: block;
  width: 100%;
  orphans: 2;
`;

export const Header = styled.div`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey60};
  text-transform: uppercase;
  margin-top: ${verticalSpacing.LARGE};
  padding-left: ${horizontalSpacing.SMALL};
  display: inline-block;
  width: 100%;
  break-inside: avoid;
  page-break-inside: avoid;
  max-width: ${CONTENT_MAX_WIDTH};
`;

export const TableRowContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const TableRow = styled.div<{
  $active?: boolean;
  $showCursor?: boolean;
}>`
  max-width: ${CONTENT_MAX_WIDTH};
  display: flex;
  width: 100%;
  margin-top: ${verticalSpacing.SMALL};
  ${props =>
    props.$showCursor &&
    css`
      cursor: pointer;
    `}
  ${props =>
    props.$active &&
    css`
      background: ${colors.grey95};
    `}
  
  .printable & {
    align-items: flex-start;
  }
  &:hover {
    background: ${colors.grey95};
  }
  break-inside: avoid;
  page-break-inside: avoid;
`;

export const DescriptionFormat = styled.span`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM};
  color: ${colors.grey15};
  display: flex;
  max-width: 400px;
  word-break: break-word;
  white-space: normal;
`;
