import { useState } from 'react';
import { getUniqueSearchId } from './utils';
import { QueryModel, SearchQuery, SearchType } from '@ardoq/api-types';
import { LazyLoadResults } from '@ardoq/pagination';
import {
  GremlinSearchSource,
  trackGremlinPaginationRequest,
  trackQueriedGremlinSearch,
} from 'search/tracking';
import { loadPaginatedGremlinResults } from './GremlinResults/utils';
import { INITIAL_LOAD_SIZE } from './GremlinResults/consts';

const searchTypeToTrackingSearchSource = new Map<
  SearchType,
  GremlinSearchSource
>([
  [SearchType.QUERY, GremlinSearchSource.GREMLIN_QUERY_EDITOR],
  [SearchType.METAMODEL_QUERY, GremlinSearchSource.METAMODEL],
  [SearchType.CALCULATED_FIELD_QUERY, GremlinSearchSource.CALC_SEARCH_TEST],
  [SearchType.DYNAMIC_FILTER_QUERY, GremlinSearchSource.DYNAMIC_SEARCH_TEST],
  [SearchType.PARAMETER_QUERY, GremlinSearchSource.PARAMETER_SEARCH_TEST],
  [SearchType.BROADCAST_AUDIENCE_QUERY, GremlinSearchSource.BROADCASTS],
]);

type UsePaginationGremlinSearchProps = {
  model: QueryModel;
  queryParams: Record<string, string> | Record<string, string[]>;
  searchType: SearchType;
};

export const usePaginationGremlinSearch = ({
  model,
  queryParams,
  searchType,
}: UsePaginationGremlinSearchProps) => {
  const [resultsId, setResultsId] = useState(getUniqueSearchId());
  const [lastExecutedQuery, setLastExecutedQuery] = useState<SearchQuery>(
    model.query
  );

  const loadPaginatedResults: LazyLoadResults = ({
    startIndex = 0,
    sortById,
    sortBy,
    pageSize,
  }) => {
    trackGremlinPaginationRequest({ startIndex });
    loadPaginatedGremlinResults({
      query: lastExecutedQuery,
      queryParams,
      startIndex,
      sortById,
      sortBy,
      pageSize,
      searchType,
    });
  };

  const doPaginatedSearch = () => {
    trackQueriedGremlinSearch({
      searchSource: searchTypeToTrackingSearchSource.get(searchType)!,
    });
    loadPaginatedGremlinResults({
      query: model.query,
      queryParams,
      startIndex: 0,
      pageSize: INITIAL_LOAD_SIZE,
      searchType,
    });
    // LazyPaginationController requires a unique results ID for each search,
    // this ensures that if a user initiates a search with the same query, it's recognized as new.
    setResultsId(getUniqueSearchId());
    // model.query is updated in stream state whenever editor changes, but with pagination,
    // multiple API requests are performed to load new pages. These requests should use the
    // last executed query, which is not necessarily the same as model.query if user has edited but not executed.
    setLastExecutedQuery(model.query);
  };

  return {
    resultsId,
    loadPaginatedResults,
    doPaginatedSearch,
  };
};
