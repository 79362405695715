import type { Line, Rectangle } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { CubicSpline } from './types';

const insideRect = ([x, y]: Point, [left, top, width, height]: Rectangle) =>
  x >= left && x <= left + width && y >= top && y <= top + height;

const splineBoundingBox = (spline: CubicSpline): Rectangle => {
  const xValues = spline.map(point => point[0]);
  const yValues = spline.map(point => point[1]);
  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);
  return [xMin, yMin, xMax - xMin, yMax - yMin];
};

const insidePathBoundingBox = (point: Point, splines: CubicSpline[]) =>
  splines.some(spline => insideRect(point, splineBoundingBox(spline)));

const insideLine = ([x, y]: Point, [[startX, startY], [endX, endY]]: Line) => {
  const minX = Math.min(startX, endX);
  const minY = Math.min(startY, endY);
  const maxX = Math.max(startX, endX);
  const maxY = Math.max(startY, endY);
  return x >= minX && y >= minY && x <= maxX && y <= maxY;
};
/**
 * This is a bogus implementation of CanvasRenderingContext2D.
 * It exists so we can intercept the spline points provided by d3.line, and use them to accelerate hit testing in the canvas.
 * Notably, isPointInStroke is implemented to quickly return false if the test point is outside the bounding rectangle of the path.
 */
class HitContext implements CanvasRenderingContext2D {
  private lastPathLine: Line = [
    [0, 0],
    [0, 0],
  ];
  private lastPathIsSpline = false;
  lastPathSplines: CubicSpline[] = [];
  private lastPath = new Path2D();
  globalAlpha = 1;
  globalCompositeOperation: GlobalCompositeOperation = 'source-over';
  canvas = document.createElement('canvas');
  private context = this.canvas.getContext('2d')!;
  fillStyle: string | CanvasGradient | CanvasPattern = 'black';
  strokeStyle: string | CanvasGradient | CanvasPattern = 'black';
  filter = '';
  imageSmoothingEnabled = true;
  imageSmoothingQuality: ImageSmoothingQuality = 'low';
  lineCap: CanvasLineCap = 'butt';
  lineDashOffset = 0;
  lineJoin: CanvasLineJoin = 'miter';
  get lineWidth() {
    return this.context.lineWidth;
  }
  set lineWidth(value: number) {
    this.context.lineWidth = value;
  }
  miterLimit = 10;
  shadowBlur = 0;
  shadowColor = 'rgba(0,0,0,0)';
  shadowOffsetX = 0;
  shadowOffsetY = 0;
  direction: CanvasDirection = 'ltr';
  font = '10px sans-serif';
  textAlign: CanvasTextAlign = 'start';
  textBaseline: CanvasTextBaseline = 'alphabetic';
  fontKerning: CanvasFontKerning = 'auto';

  fontStretch: CanvasFontStretch = 'normal';
  fontVariantCaps: CanvasFontVariantCaps = 'normal';
  letterSpacing: string = 'normal';
  textRendering: CanvasTextRendering = 'auto';
  wordSpacing: string = 'normal';
  roundRect(
    x: number,
    y: number,
    w: number,
    h: number,
    radii?: number | DOMPointInit | (number | DOMPointInit)[] | undefined
  ): void;
  roundRect(
    x: number,
    y: number,
    w: number,
    h: number,
    radii?: number | DOMPointInit | Iterable<number | DOMPointInit> | undefined
  ): void;
  roundRect(
    _x: unknown,
    _y: unknown,
    _w: unknown,
    _h: unknown,
    _radii?: unknown
  ): void {
    throw new Error('Method not implemented.');
  }
  getContextAttributes(): CanvasRenderingContext2DSettings {
    throw new Error('Method not implemented.');
  }
  drawImage(image: CanvasImageSource, dx: number, dy: number): void;
  drawImage(
    image: CanvasImageSource,
    dx: number,
    dy: number,
    dw: number,
    dh: number
  ): void;
  drawImage(
    image: CanvasImageSource,
    sx: number,
    sy: number,
    sw: number,
    sh: number,
    dx: number,
    dy: number,
    dw: number,
    dh: number
  ): void;
  drawImage(
    _image: any,
    _sx: any,
    _sy: any,
    _sw?: any,
    _sh?: any,
    _dx?: any,
    _dy?: any,
    _dw?: any,
    _dh?: any
  ): void {
    throw new Error('Method not implemented.');
  }
  beginPath(): void {
    this.lastPathIsSpline = false;
    this.lastPathSplines = [];
    this.lastPath = new Path2D();
  }
  clip(fillRule?: CanvasFillRule): void;
  clip(path: Path2D, fillRule?: CanvasFillRule): void;
  clip(_path?: any, _fillRule?: any): void {
    throw new Error('Method not implemented.');
  }
  fill(fillRule?: CanvasFillRule): void;
  fill(path: Path2D, fillRule?: CanvasFillRule): void;
  fill(_path?: any, _fillRule?: any): void {
    throw new Error('Method not implemented.');
  }
  isPointInPath(x: number, y: number, fillRule?: CanvasFillRule): boolean;
  isPointInPath(
    path: Path2D,
    x: number,
    y: number,
    fillRule?: CanvasFillRule
  ): boolean;
  isPointInPath(arg1: any, arg2: any, arg3?: any, arg4?: any): boolean {
    const [path, x, y, fillRule] = arg3
      ? [arg1, arg2, arg3, arg4]
      : [this.lastPath, arg1, arg2];
    return this.context.isPointInPath(path, x, y, fillRule);
  }
  isPointInStroke(x: number, y: number): boolean;
  isPointInStroke(path: Path2D, x: number, y: number): boolean;
  isPointInStroke(arg1: any, arg2: any, arg3?: any): boolean {
    const [path, x, y] = arg3
      ? [arg1, arg2, arg3]
      : [this.lastPath, arg1, arg2];

    const isOutside = this.lastPathIsSpline
      ? !insidePathBoundingBox([x, y], this.lastPathSplines)
      : !insideLine([x, y], this.lastPathLine);
    return !isOutside && this.context.isPointInStroke(path, x, y);
  }
  stroke(): void;
  stroke(path: Path2D): void;
  stroke(path?: any): void {
    this.context.stroke(path || this.lastPath);
  }
  createConicGradient(
    _startAngle: number,
    _x: number,
    _y: number
  ): CanvasGradient {
    throw new Error('Method not implemented.');
  }
  createLinearGradient(
    _x0: number,
    _y0: number,
    _x1: number,
    _y1: number
  ): CanvasGradient {
    throw new Error('Method not implemented.');
  }
  createPattern(
    _image: CanvasImageSource,
    _repetition: string | null
  ): CanvasPattern | null {
    throw new Error('Method not implemented.');
  }
  createRadialGradient(
    _x0: number,
    _y0: number,
    _r0: number,
    _x1: number,
    _y1: number,
    _r1: number
  ): CanvasGradient {
    throw new Error('Method not implemented.');
  }
  createImageData(
    sw: number,
    sh: number,
    settings?: ImageDataSettings
  ): ImageData;
  createImageData(imagedata: ImageData): ImageData;
  createImageData(_sw: any, _sh?: any, _settings?: any): ImageData {
    throw new Error('Method not implemented.');
  }
  getImageData(
    _sx: number,
    _sy: number,
    _sw: number,
    _sh: number,
    _settings?: ImageDataSettings
  ): ImageData {
    throw new Error('Method not implemented.');
  }
  putImageData(imagedata: ImageData, dx: number, dy: number): void;
  putImageData(
    imagedata: ImageData,
    dx: number,
    dy: number,
    dirtyX: number,
    dirtyY: number,
    dirtyWidth: number,
    dirtyHeight: number
  ): void;
  putImageData(
    _imagedata: any,
    _dx: any,
    _dy: any,
    _dirtyX?: any,
    _dirtyY?: any,
    _dirtyWidth?: any,
    _dirtyHeight?: any
  ): void {
    throw new Error('Method not implemented.');
  }
  arc(
    _x: number,
    _y: number,
    _radius: number,
    _startAngle: number,
    _endAngle: number,
    _counterclockwise?: boolean
  ): void {
    throw new Error('Method not implemented.');
  }
  arcTo(
    _x1: number,
    _y1: number,
    _x2: number,
    _y2: number,
    _radius: number
  ): void {
    throw new Error('Method not implemented.');
  }
  bezierCurveTo(
    cp1x: number,
    cp1y: number,
    cp2x: number,
    cp2y: number,
    x: number,
    y: number
  ): void {
    this.lastPathIsSpline = true;
    const startPoint = this.lastPathSplines.length
      ? this.lastPathSplines[this.lastPathSplines.length - 1][3]
      : this.lastPathLine[0];
    this.lastPathSplines.push([startPoint, [cp1x, cp1y], [cp2x, cp2y], [x, y]]);
    this.lastPath.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
  }
  closePath(): void {
    throw new Error('Method not implemented.');
  }
  ellipse(
    _x: number,
    _y: number,
    _radiusX: number,
    _radiusY: number,
    _rotation: number,
    _startAngle: number,
    _endAngle: number,
    _counterclockwise?: boolean
  ): void {
    throw new Error('Method not implemented.');
  }
  lineTo(x: number, y: number): void {
    this.lastPathLine[1] = [x, y];
    this.lastPath.lineTo(x, y);
  }
  moveTo(x: number, y: number): void {
    this.lastPathLine = [
      [x, y],
      [0, 0],
    ];
    this.lastPathSplines = [];
    this.lastPath.moveTo(x, y);
  }
  quadraticCurveTo(_cpx: number, _cpy: number, _x: number, _y: number): void {
    throw new Error('Method not implemented.');
  }
  rect(_x: number, _y: number, _w: number, _h: number): void {
    throw new Error('Method not implemented.');
  }
  getLineDash(): number[] {
    throw new Error('Method not implemented.');
  }
  setLineDash(segments: number[]): void;
  setLineDash(segments: Iterable<number>): void;
  setLineDash(_segments: any): void {
    // ignore
  }
  clearRect(_x: number, _y: number, _w: number, _h: number): void {
    throw new Error('Method not implemented.');
  }
  fillRect(_x: number, _y: number, _w: number, _h: number): void {
    throw new Error('Method not implemented.');
  }
  strokeRect(_x: number, _y: number, _w: number, _h: number): void {
    throw new Error('Method not implemented.');
  }
  restore(): void {
    throw new Error('Method not implemented.');
  }
  save(): void {
    throw new Error('Method not implemented.');
  }
  fillText(_text: string, _x: number, _y: number, _maxWidth?: number): void {
    throw new Error('Method not implemented.');
  }
  measureText(_text: string): TextMetrics {
    throw new Error('Method not implemented.');
  }
  strokeText(_text: string, _x: number, _y: number, _maxWidth?: number): void {
    throw new Error('Method not implemented.');
  }
  getTransform(): DOMMatrix {
    throw new Error('Method not implemented.');
  }
  resetTransform(): void {
    throw new Error('Method not implemented.');
  }
  rotate(_angle: number): void {
    throw new Error('Method not implemented.');
  }
  scale(_x: number, _y: number): void {
    throw new Error('Method not implemented.');
  }
  setTransform(
    a: number,
    b: number,
    c: number,
    d: number,
    e: number,
    f: number
  ): void;
  setTransform(transform?: DOMMatrix2DInit): void;
  setTransform(
    _a?: any,
    _b?: any,
    _c?: any,
    _d?: any,
    _e?: any,
    _f?: any
  ): void {
    throw new Error('Method not implemented.');
  }
  transform(
    _a: number,
    _b: number,
    _c: number,
    _d: number,
    _e: number,
    _f: number
  ): void {
    throw new Error('Method not implemented.');
  }
  translate(_x: number, _y: number): void {
    throw new Error('Method not implemented.');
  }
  drawFocusIfNeeded(element: Element): void;
  drawFocusIfNeeded(path: Path2D, element: Element): void;
  drawFocusIfNeeded(_path: any, _element?: any): void {
    throw new Error('Method not implemented.');
  }
  isContextLost(): boolean {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    throw new Error('Method not implemented.');
  }
}
export default HitContext;
