/**
 * just a set with two keys.
 * under the hood, it's a map of sets.
 */
const twoKeySet = <K1, K2>() => {
  const map1 = new Map<K1, Set<K2>>();
  return {
    has: (k1: K1, k2: K2) => {
      if (!map1.has(k1)) {
        map1.set(k1, new Set());
      }
      return map1.get(k1)!.has(k2);
    },
    add: (k1: K1, k2: K2) => {
      if (!map1.has(k1)) {
        map1.set(k1, new Set());
      }
      map1.get(k1)!.add(k2);
    },
  };
};
export default twoKeySet;
