import { markFatness } from './util';
import { AREOLA_GAP } from './consts';
import { RelationshipsNode } from '../types';
import { EXPANDER_RADIUS } from '../consts';

const bottomOffset = (showExpander: boolean, marked: boolean, r: number) =>
  !showExpander && marked ? markFatness(r) * 2 : 1;

/** @returns the y-position of the top of the label. */
const nodeLabelY = (
  { y, r, open, minimumEnclosingCircleForChildren }: RelationshipsNode,
  showExpander: boolean,
  marked: boolean
) => {
  if (showExpander) {
    if (open) {
      const availableLabelSpaceBottom = y + r - EXPANDER_RADIUS;
      const availableLabelSpaceTop =
        y +
        (minimumEnclosingCircleForChildren
          ? minimumEnclosingCircleForChildren.y +
            minimumEnclosingCircleForChildren.r
          : 0);

      const labelTop =
        availableLabelSpaceTop +
        (availableLabelSpaceBottom - availableLabelSpaceTop) / 3;
      return labelTop;
    }
    const labelY =
      bottomOffset(showExpander, marked, r) +
      y +
      r +
      EXPANDER_RADIUS +
      AREOLA_GAP;
    return labelY; // label below expander, no sublabel
  }

  const labelY = y + r + bottomOffset(showExpander, marked, r);
  return labelY; // label under circumference, no sublabel
};
export default nodeLabelY;
