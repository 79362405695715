import { DeleteAssetProps, DeleteType } from './types';
import {
  getModalContentProps,
  useCheckIfReportInUse,
  useCheckIfWsInUse,
} from './utils';
import {
  AlertDialog,
  ConfirmDeleteDialog,
  ConfirmDeleteWithYesInputDialog,
  ModalLoader,
  ModalSize,
  ModalTemplateProps,
} from '@ardoq/modal';
import { IconName } from '@ardoq/icons';
import { noop } from 'lodash';
import { pluralize } from '@ardoq/common-helpers';
import WorkspaceInUseDialog from './WorkspaceInUseDialog/WorkspaceInUseDialog';

type DeleteAssetDialogProps = {
  onConfirm?: () => any;
  onCancel?: () => any;
} & DeleteAssetProps &
  Pick<ModalTemplateProps, 'inputDataTestId'>;

const DeleteAssetDialog = ({
  onConfirm = noop,
  onCancel = noop,
  inputDataTestId,
  ...deleteAssetProps
}: DeleteAssetDialogProps) => {
  const {
    loading: wsUsageIsLoading,
    canShowDeleteDialog,
    workspaceNamesInUse,
    scenariosUsingSelectedWs,
  } = useCheckIfWsInUse(deleteAssetProps);

  const {
    loading: reportUsageIsLoading,
    dashboardsUsingSelectedReports,
    totalDashboardsUsingSelectedReports,
  } = useCheckIfReportInUse(deleteAssetProps);

  const {
    title,
    subtitle,
    listOptions,
    warningBlockMessage,
    confirmButtonTitle,
    text,
  } = getModalContentProps(deleteAssetProps);

  if (wsUsageIsLoading || reportUsageIsLoading) return <ModalLoader />;

  if (
    !canShowDeleteDialog &&
    deleteAssetProps.deleteType === DeleteType.MULTIPLE
  ) {
    return (
      <AlertDialog
        title={title || ''}
        subtitle="Can't delete assets"
        text="Components from the following workspaces are
        in use in one or more scenarios:"
        listOptions={workspaceNamesInUse.map(name => ({
          iconName: IconName.WORKSPACE,
          strongText: name,
        }))}
        text2="To delete these assets, open the scenarios and exclude all components from these workspaces."
        onClose={onCancel}
      />
    );
  }

  if (
    !canShowDeleteDialog &&
    deleteAssetProps.deleteType === DeleteType.WORKSPACE
  ) {
    return (
      <WorkspaceInUseDialog
        workspaceName={deleteAssetProps.name}
        scenariosUsingTheWorkspace={scenariosUsingSelectedWs}
        closeDialog={onCancel}
      />
    );
  }
  const commonDeleteProps = {
    title: title || '',
    onConfirm,
    onCancel,
    subtitle,
    listOptions,
    confirmButtonClickId: 'confirm-delete',
    inputDataTestId,
    warningBlockMessage,
    confirmButtonTitle,
    text,
  };
  if (
    deleteAssetProps.deleteType === DeleteType.REPORT &&
    dashboardsUsingSelectedReports.length
  ) {
    const numberOfAffectedDashboardsTheUserCantAccess =
      totalDashboardsUsingSelectedReports -
      dashboardsUsingSelectedReports.length;

    return (
      <ConfirmDeleteWithYesInputDialog
        {...commonDeleteProps}
        text={`This report is used in ${
          dashboardsUsingSelectedReports.length
        } ${pluralize('dashboard', dashboardsUsingSelectedReports.length)}:`}
        listOptions={dashboardsUsingSelectedReports.map(({ name }) => ({
          iconName: IconName.PIE_CHART_FILLED,
          strongText: name,
        }))}
        warningBlockMessage={
          numberOfAffectedDashboardsTheUserCantAccess
            ? `...and in ${numberOfAffectedDashboardsTheUserCantAccess} ${pluralize(
                'dashboard',
                numberOfAffectedDashboardsTheUserCantAccess
              )} you don't have access to.`
            : undefined
        }
      />
    );
  }
  if (
    deleteAssetProps.deleteType === DeleteType.VIEWPOINT ||
    deleteAssetProps.deleteType === DeleteType.WORKSPACE ||
    deleteAssetProps.deleteType === DeleteType.REFERENCE_TYPE
  ) {
    return (
      <ConfirmDeleteDialog
        {...commonDeleteProps}
        modalSize={text ? ModalSize.M : ModalSize.S}
      />
    );
  }
  return <ConfirmDeleteWithYesInputDialog {...commonDeleteProps} />;
};

export default DeleteAssetDialog;
