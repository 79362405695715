import { loadedScenarioData$ } from 'loadedScenarioData$';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  APIComponentAttributes,
  APIWorkspaceAttributes,
} from '@ardoq/api-types';
import { excludeNullAndUndefined } from 'streams/utils/streamOperators';
import { ByIdItem } from './types';
import { mainNode } from './consts';

const nameSorter =
  (byId: Record<string, { name: string }>) => (a: string, b: string) => {
    const nameA = byId[a].name.toUpperCase();
    const nameB = byId[b].name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

export const scenarioRelated$ = loadedScenarioData$.pipe(
  map(({ scenarioData }) => scenarioData?.connected),
  excludeNullAndUndefined(),
  distinctUntilChanged(),
  map(({ workspaces, components, connectedComponents }) => {
    const byComponentId = components.reduce<
      Record<string, APIComponentAttributes>
    >((acc, item) => ({ ...acc, [item._id]: item }), {});
    const byWorkspaceId = workspaces.reduce<
      Record<string, APIWorkspaceAttributes>
    >((acc, item) => ({ ...acc, [item._id]: item }), {});

    const flattenTree = components.reduce<Record<string, string[]>>(
      (acc, { _id, parent, rootWorkspace }) => {
        // if component has no parent, then the rootWorkspace will step in
        const parentId = parent || rootWorkspace;
        return {
          ...acc,
          [parentId]: [...(acc[parentId] || []), _id],
          [_id]: [...(acc[_id] || [])],
        };
      },
      {}
    );

    const sortedFlattenTree = Object.fromEntries(
      Object.entries(flattenTree).map(([parentId, children]) => [
        parentId,
        children.sort(nameSorter(byComponentId)),
      ])
    );

    const sortedRootIds = Object.keys(sortedFlattenTree)
      .filter(id => byWorkspaceId[id])
      .sort(nameSorter(byWorkspaceId));

    const connectedComponentsSet = new Set(connectedComponents);
    const byId = Object.entries({ ...byComponentId, ...byWorkspaceId }).reduce<
      Record<string, ByIdItem>
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key]: { ...value, ghosted: !connectedComponentsSet.has(value._id) },
      }),
      { mainNode }
    );

    return {
      flattenTree: {
        ...sortedFlattenTree,
        mainNode: sortedRootIds,
      } as Record<string, string[]>,
      rootIds: [mainNode._id],
      byId,
      connectedComponentsSet,
    };
  })
);
