const BASE_VERTICAL_SIZE = 1440;

const screenSizeModifier = () =>
  window.screen.height / BASE_VERTICAL_SIZE > 1
    ? Math.floor(window.screen.height / BASE_VERTICAL_SIZE)
    : 1;

export const getRenderLimit = (numberOfComponentsToRender: number) =>
  numberOfComponentsToRender * screenSizeModifier();

export const getRenderBounds = (
  index: number,
  upperBoundary: number,
  bypassLimit: boolean,
  numberOfComponentsToRender: number
): [number, number] => {
  if (bypassLimit) {
    return [0, upperBoundary];
  } else if (
    index <
    upperBoundary - getRenderLimit(numberOfComponentsToRender)
  ) {
    return [index, index + getRenderLimit(numberOfComponentsToRender)];
  }
  const lowerBoundary =
    upperBoundary - getRenderLimit(numberOfComponentsToRender) > 0
      ? upperBoundary - getRenderLimit(numberOfComponentsToRender)
      : 0;
  return [lowerBoundary, upperBoundary];
};
