import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { SizeBasedOn } from 'views/treemapConsts';
import { SizeBasedOnOptions, WorkspaceField } from './types';
import type { ViewSettings } from './types';
import { ArdoqId } from '@ardoq/api-types';

const isBy =
  (method: SizeBasedOnOptions) =>
  ({ sizeBasedOn }: ViewSettings) =>
    sizeBasedOn === method;

export const isByCustomField = (method: SizeBasedOnOptions) =>
  method && !(Object as any).values(SizeBasedOn).includes(method);
export const isByDescendants = isBy(SizeBasedOn.CHILD_COUNT);
export const isByIncomingReferences = isBy(SizeBasedOn.INCOMING_LINKS);
export const isByOutgoingReferences = isBy(SizeBasedOn.OUTGOING_LINKS);

export const getSelectedFieldSettingsName = (name: string) =>
  name === 'value' || name === 'targetValue' ? `reverse_${name}` : name;

type GetUnusedSelectedFieldLabelArgs = {
  sizeBasedOn: SizeBasedOnOptions;
  fieldsInUse: Set<string>;
  workspaceFields: WorkspaceField[];
};

export const getUnusedSelectedFieldLabel = ({
  sizeBasedOn,
  fieldsInUse,
  workspaceFields,
}: GetUnusedSelectedFieldLabelArgs) => {
  if (isByCustomField(sizeBasedOn)) {
    const isUnusedFieldSelected = sizeBasedOn && !fieldsInUse.has(sizeBasedOn);

    const selectedUnusedFieldId = isUnusedFieldSelected
      ? workspaceFields.find(field => field.name === sizeBasedOn)?._id
      : null;

    return selectedUnusedFieldId
      ? fieldInterface.getLabel(selectedUnusedFieldId)
      : null;
  }

  return null;
};

export const getIsHighlighted = (
  currentComponentId: ArdoqId,
  hoveredComponentId: string | null,
  focusedComponentId: string | null
) =>
  hoveredComponentId === currentComponentId ||
  focusedComponentId === currentComponentId;

type HasAvailableReferencesArgs = {
  sizeBasedOn: SizeBasedOnOptions;
  hasIncomingReferences: boolean;
  hasOutgoingReferences: boolean;
};

export const hasAvailableReferences = ({
  sizeBasedOn,
  hasIncomingReferences,
  hasOutgoingReferences,
}: HasAvailableReferencesArgs) => {
  if (sizeBasedOn === SizeBasedOn.INCOMING_LINKS) {
    return { hasReferences: hasIncomingReferences, referenceType: 'incoming' };
  }

  if (sizeBasedOn === SizeBasedOn.OUTGOING_LINKS) {
    return { hasReferences: hasOutgoingReferences, referenceType: 'outgoing' };
  }

  return {
    hasReferences: hasIncomingReferences || hasOutgoingReferences,
    referenceType: null,
  };
};
