import { GraphItem } from '@ardoq/graph';
import { LayoutConstraintRuleProps } from 'tabview/proteanDiagram/view/layoutRules/types';
import {
  ConstraintsMap,
  ConstraintSource,
  ResolvedLayoutConstraint,
} from 'tabview/proteanDiagram/view/types';
import { componentMatchesSubquery } from './componentMatchesSubquery';

const { PERSPECTIVE, GENERATED } = ConstraintSource;

const resolveTabularConstraintsReducer =
  (
    constraintsWithSourceMap: ConstraintsMap,
    constraintRules: LayoutConstraintRuleProps[]
  ) =>
  (
    resolvedConstraintsMap: Map<string, ResolvedLayoutConstraint>,
    graphNode: GraphItem
  ) => {
    const nodeId = graphNode.id;

    const constraintWithSource = constraintsWithSourceMap.get(nodeId);
    const ruleConstraint = graphNode.parent
      ? null // only root nodes respect layout rules.
      : constraintRules.find(rule =>
          componentMatchesSubquery(graphNode.modelId, rule.componentRules)
        );

    if (!constraintWithSource && !ruleConstraint) {
      return resolvedConstraintsMap;
    }

    const newConstraint: ResolvedLayoutConstraint | undefined =
      ruleConstraint &&
      (!constraintWithSource || constraintWithSource?.[3] === GENERATED)
        ? [nodeId, ruleConstraint.order, ruleConstraint.order, PERSPECTIVE]
        : constraintWithSource;

    if (newConstraint) {
      resolvedConstraintsMap.set(nodeId, newConstraint);
    }

    return resolvedConstraintsMap;
  };

export default resolveTabularConstraintsReducer;
