import {
  BaseClass,
  type INode,
  INodeInsetsProvider,
  Insets,
} from '@ardoq/yfiles';
import { PROTEAN_GROUP_MARGIN } from './consts';
import { BLOCK_DIAGRAM_NODE_LABEL_FONT } from 'yfilesExtensions/styles/consts';

class ProteanGroupInsetsProvider extends BaseClass(INodeInsetsProvider) {
  static Instance = new ProteanGroupInsetsProvider();
  private constructor() {
    super();
  }
  override getInsets(_: INode): Insets {
    return new Insets(
      PROTEAN_GROUP_MARGIN,
      PROTEAN_GROUP_MARGIN +
        BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize +
        PROTEAN_GROUP_MARGIN, // the margin is applied doubly on top of the group, so we get some space under the label.
      PROTEAN_GROUP_MARGIN,
      PROTEAN_GROUP_MARGIN
    );
  }
}

export default ProteanGroupInsetsProvider;
