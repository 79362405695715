import { componentInterface } from 'modelInterface/components/componentInterface';
import { ComponentsData, ReferencesMap } from '../types';
import { referenceInterface } from 'modelInterface/references/referenceInterface';

export const getReferences = (
  componentsData: ComponentsData,
  allTargetWorkspaceComponents: Set<string>
): ReferencesMap =>
  Object.fromEntries(
    componentsData.componentIds
      .map(id => [
        id,
        componentInterface
          .getTargets(id)
          .filter(
            ({ componentId, referenceId }) =>
              allTargetWorkspaceComponents.has(componentId) &&
              referenceInterface.isIncludedInContextByFilter(referenceId)
          ),
      ])
      .filter(([_, targetReferences]) => targetReferences.length > 0)
  );
