import { ArrowType } from '@ardoq/api-types';
import { HtmlCanvasVisual, IEdge, IRenderContext } from '@ardoq/yfiles';
import drawMarker from 'tabview/canvasRendering/drawMarker';

class CanvasEdgeVisual extends HtmlCanvasVisual {
  constructor(
    private edge: IEdge,
    private stroke: string,
    private dashArray: number[],
    private lineBeginning: ArrowType,
    private lineEnding: ArrowType
  ) {
    super();
  }
  paint(renderContext: IRenderContext, ctx: CanvasRenderingContext2D): void {
    ctx.beginPath();
    const startPoint = this.edge.sourcePort!.location;
    const { x: startX, y: startY } = startPoint;
    ctx.moveTo(startX, startY);
    this.edge.bends.forEach(({ location: { x, y } }) => {
      ctx.lineTo(x, y);
    });
    const endPoint = this.edge.targetPort!.location;
    const { x: endX, y: endY } = endPoint;
    ctx.lineTo(endX, endY);

    ctx.lineWidth = 1;
    ctx.strokeStyle = this.stroke;
    ctx.setLineDash(this.dashArray);
    ctx.stroke();
    ctx.setLineDash([]);

    const { x: secondPointX, y: secondPointY } =
      this.edge.bends.firstOrDefault()?.location ?? endPoint;
    const { x: penultimatePointX, y: penultimatePointY } =
      this.edge.bends.lastOrDefault()?.location ?? startPoint;

    const startAngle = Math.atan2(startY - secondPointY, startX - secondPointX);
    const endAngle = Math.atan2(
      endY - penultimatePointY,
      endX - penultimatePointX
    );
    drawMarker(ctx, this.lineBeginning, [startX, startY], startAngle, 0.5);
    drawMarker(ctx, this.lineEnding, [endX, endY], endAngle, 0.5);
  }
}
export default CanvasEdgeVisual;
