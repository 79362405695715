import * as d3 from 'd3';
import { TreemapData, TreemapEntity, TreemapLayoutOptions } from '../types';
import { ONE_REM } from '../constants';

const normalizePadding = (hasLabel: boolean, options: TreemapLayoutOptions) => {
  const { rem = ONE_REM, padding = 0 } = options;

  return {
    padding: hasLabel && padding === 0 ? rem / 2 : padding,
    paddingTop: hasLabel ? padding + 2.2 * rem : 0,
  };
};

export default function useTreemapLayout(
  data: TreemapData,
  options: TreemapLayoutOptions
): TreemapEntity[] {
  const { width, height, innerPadding = 0 } = options;
  const { children } = data;

  if (!children?.length) return [];
  if (!width || !height) return [];

  const { padding, paddingTop } = normalizePadding(
    !!data.label.length,
    options
  );

  const root = d3
    .hierarchy<TreemapData>({
      children,
      value: 0,
    } as TreemapData)
    .sum(d => d.value)
    .sort((a, b) => d3.descending(a.value, b.value));

  const layout = d3
    .treemap<TreemapData>()
    .size([width, height])
    .padding(padding)
    .paddingTop(paddingTop)
    .paddingInner(innerPadding);

  return layout(root).leaves();
}
