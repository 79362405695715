import Fields from 'collections/fields';
import Group from 'graph/group';
import {
  type APIReferenceType,
  ArrowType,
  LineType,
  isArrowType,
} from '@ardoq/api-types';
import { getReferenceTypeSvgStyle } from '@ardoq/renderers';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { DiffType } from '@ardoq/data-model';
import type { Reference } from 'aqTypes';
import type { Edge as IEdge, Node } from 'graph/types';
import { GraphItemModel } from './GraphItem';
import { referenceInterface } from 'modelInterface/references/referenceInterface';

const groupReferenceType: APIReferenceType = {
  id: 42,
  color: 'black',
  line: LineType.SOLID,
  lineEnding: ArrowType.BOTH,
  name: 'group',
  returnsValue: false,
  svgStyle: '',
};

let idCounter = 0;
function uniqueId() {
  return `e${idCounter++}`;
}
interface EdgeCreationOptions {
  reference: Reference | Group;
  sourceNode: Node;
  targetNode: Node;
  aggregatedSourceNode?: Node;
  aggregatedTargetNode?: Node;
  source?: string;
  target?: string;
  degree?: number;
}
/**
 * An edge can represent a reference or a group (of references)
 */
export class Edge implements IEdge {
  sourceNode: Node<GraphItemModel>;
  targetNode: Node<GraphItemModel>;
  source: string;
  target: string;
  aggregatedSource?: string;
  aggregatedTarget?: string;
  aggregatedSourceNode?: Node<GraphItemModel> | undefined;
  aggregatedTargetNode?: Node<GraphItemModel> | undefined;
  id: string;
  dataModel: Reference | Group;
  isTransparentized: boolean;

  constructor(options: EdgeCreationOptions) {
    this.id = uniqueId();
    this.dataModel = options.reference;
    this.sourceNode = options.sourceNode;
    this.targetNode = options.targetNode;
    this.aggregatedSourceNode = options.aggregatedSourceNode;
    this.aggregatedTargetNode = options.aggregatedTargetNode;
    this.source = this.sourceNode.id;
    this.target = this.targetNode.id;
    this.isTransparentized = false;

    this.aggregatedSource =
      this.aggregatedSourceNode && this.aggregatedSourceNode.id;
    this.aggregatedTarget =
      this.aggregatedTargetNode && this.aggregatedTargetNode.id;

    if (!this.isGroup()) {
      this.sourceNode.addOutgoingEdge(this);
      this.targetNode.addIncomingEdge(this);

      if (this.aggregatedTargetNode) {
        this.aggregatedTargetNode.addAggregatedIncomingEdge(this);
      }
      if (this.aggregatedSourceNode) {
        this.aggregatedSourceNode.addAggregatedOutgoingEdge(this);
      }
    }
  }

  getLineBeginning() {
    if (this.isReference()) {
      const modelType = (this.dataModel as Reference).getModelType();
      return (
        (isArrowType(modelType?.lineBeginning) && modelType.lineBeginning) ||
        ArrowType.NONE
      );
    }
    return ArrowType.NONE;
  }

  getLineEnding() {
    if (this.isReference()) {
      const modelType = (this.dataModel as Reference).getModelType();
      return (
        (isArrowType(modelType?.lineEnding) && modelType.lineEnding) ||
        ArrowType.NONE
      );
    }
    return ArrowType.NONE;
  }

  getStyle() {
    if (this.isReference()) {
      const refType = (
        this.dataModel as Reference
      ).getRefType() as APIReferenceType;
      return getReferenceTypeSvgStyle(refType.line, refType.color);
    }
    return '';
  }

  getType() {
    if (this.isReference()) {
      return (this.dataModel as Reference).getType();
    } else if (this.isGroup()) {
      return 'group';
    }
    return '';
  }

  getLabel() {
    const modelId = (this.dataModel as Reference).getId();
    return referenceInterface.getDisplayLabel(modelId) || '';
  }

  getCSS() {
    if (this.isReference()) {
      return (this.dataModel as Reference).getCSS();
    }
    if (this.isGroup()) {
      return 'edgeGroup';
    }
    return '';
  }

  getRefType() {
    return this.isGroup()
      ? groupReferenceType
      : ((this.dataModel as Reference).getRefType() as APIReferenceType);
  }

  isGroup() {
    return this.dataModel instanceof Group;
  }

  isReference() {
    return (
      this.dataModel.urlRoot &&
      this.dataModel.urlRoot.indexOf('/api/reference') !== -1
    );
  }

  isIncludedInContextByFilter() {
    if (this.isGroup()) {
      return (this.dataModel as Group).dataModels.every(ref =>
        ref.isIncludedInContextByFilter()
      );
    }
    return this.dataModel.isIncludedInContextByFilter();
  }

  hasURLFields() {
    if (this.isReference()) {
      return Fields.collection
        .getByReference(this.dataModel as Reference)
        .filter(field => field.getType() === 'Url')
        .map(field => this.dataModel.get(field.get('name')))
        .some(url => url);
    }
    return false;
  }

  getVisualDiffType() {
    return (
      (this.dataModel &&
        typeof this.dataModel.get === 'function' &&
        this.dataModel.get(DIFF_TYPE)) ||
      DiffType.NONE
    );
  }
  destroy({ ignoreNodeUpdates = false }) {
    if (this.isGroup()) {
      this.dataModel.destroy();
    }
    if (!ignoreNodeUpdates) {
      this.sourceNode.removeOutgoingEdge(this);
      this.targetNode.removeIncomingEdge(this);
    }
  }
}
