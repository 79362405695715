export const SWIM_STRIPE_LABEL_FONT_SIZE = 14;
export const SWIM_STRIPE_LABEL_INSET_SIZE = 5;

export const GRID_INSET_SIZE = 16;

export const PROTEAN_MAX_FONT_SIZE = 24;

export const CELL_ADDRESS_DATA_PROVIDER_KEY = 'cellAddress';
export const NODE_IDS_DATA_PROVIDER_KEY = 'nodeIds';
export const LAYOUT_GRIDS_DATA_PROVIDER_KEY = 'layoutGrids';
