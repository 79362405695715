import {
  MetamodelContextSettings,
  MetamodelReferenceTypeContext,
} from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import {
  ReferenceCompletionForReference,
  ReferenceTypeSubtitle,
  ReferenceTypeTitle,
} from './atoms';
import BreadCrumbNavigation from './breadCrumbNavigation/BreadCrumbNavigation';
import FieldCompletionWidget from './completion/FieldCompletionWidget';
import { getFieldCompletion } from './utils';
import { Box } from '@ardoq/layout';

const ReferenceTypeStats = ({
  context,
  metamodel,
}: {
  context: MetamodelReferenceTypeContext & MetamodelContextSettings;
  metamodel: Metamodel;
}) => {
  const { referenceTypes } = metamodel.metamodel;
  const referenceType = referenceTypes[context.id];
  const referenceFieldCompletion = getFieldCompletion(
    referenceType,
    metamodel.metamodel.dateRangeFieldMap
  );
  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <ReferenceTypeTitle metamodel={metamodel} context={context} />
      </Box>
      <ReferenceTypeSubtitle fieldCompletion={referenceFieldCompletion} />
      <ReferenceCompletionForReference
        metamodel={metamodel}
        context={context}
      />
      <FieldCompletionWidget
        fieldCompletion={referenceFieldCompletion}
        context={context}
        metamodel={metamodel}
      />
    </Box>
  );
};

export default ReferenceTypeStats;
