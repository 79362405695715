import { BASE_TRANSITION_MS } from 'consts';
import { presentationDescriptionToggled } from 'presentation/slideNavigator/actions';
import { presentationNavigatorHeightOffset } from 'presentation/slideNavigator/util';
import { FunctionComponent, useEffect, useState } from 'react';
import { action$, ofType } from '@ardoq/rxbeach';
import fabVisibility$ from '../mainApp/fab/fabVisibility$';
import { FAB_BOTTOM, FAB_SIZE } from '../mainApp/fab/consts';
import { isPresentationMode } from 'appConfig';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { EMPTY } from 'rxjs';
import { noop } from 'lodash';

type HeightOffsetChildren = (heightOffset: number) => any;

type HeightOffsetProps = {
  children: HeightOffsetChildren;
};

const SINGLE_ROW_VIEW_PANE_FOOTER_HEIGHT = 66; // 50px bar + 2 * 8px margins

export const useViewLegendSubscription = (): number => {
  const singleRowViewPaneHeader = loadedGraph$.state.isViewpointMode;

  const [heightOffset, setHeightOffset] = useState(
    presentationNavigatorHeightOffset() +
      (singleRowViewPaneHeader ? SINGLE_ROW_VIEW_PANE_FOOTER_HEIGHT : 0)
  );

  const [subscription] = useState(() => {
    const result = isPresentationMode()
      ? action$
          .pipe(ofType(presentationDescriptionToggled))
          .subscribe(() =>
            setTimeout(
              () => setHeightOffset(presentationNavigatorHeightOffset()),
              BASE_TRANSITION_MS
            )
          )
      : singleRowViewPaneHeader
        ? EMPTY.subscribe(noop)
        : fabVisibility$.subscribe(fabIsVisible =>
            setHeightOffset(fabIsVisible ? FAB_SIZE + FAB_BOTTOM : 0)
          );
    return result;
  });

  useEffect(() => () => subscription.unsubscribe(), [subscription]);
  return heightOffset;
};

const HeightOffset: FunctionComponent<HeightOffsetProps> = ({ children }) => {
  const heightOffset = useViewLegendSubscription();

  return children(heightOffset);
};

export default HeightOffset;
