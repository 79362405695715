import { actionCreator } from '@ardoq/rxbeach';
import { SearchParams } from '@ardoq/api';
import { QueryBuilderQuery } from '@ardoq/api-types';

export const pageWasSet = actionCreator<number>(
  '[advancedSearchTable] PAGE_WAS_SET'
);

export const searchParamsWereSet = actionCreator<SearchParams>(
  '[advancedSearchTable] SEARCH_PARAMS_WERE_SET'
);

export const selectedFieldsWereSet = actionCreator<Array<string>>(
  '[advancedSearchTable] SELECTED_FIELDS_WERE_SET'
);

export const queryWasSet = actionCreator<QueryBuilderQuery>(
  '[advancedSearchTable] QUERY_WAS_SET'
);
