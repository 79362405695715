export const COMPONENT_HIERARCHY_PADDING = '@@padding@@';
export const MARGIN_PER_LEVEL = 5;
export const NONE_HEADER_LABEL = 'none';
export const OUTGOING_SUFFIX = '_@@outgoing@@';
export const INCOMING_SUFFIX = '_@@incoming@@';
export const ROW_HEADER_HIERARCHY_INDENTATION = 20;
export const ROW_HEADER_LABEL_HEIGHT = 26;
export const HEADER_CELL_HORIZONTAL_PADDING = 5;
export const HEADER_CELL_VERTICAL_PADDING = 3;
export const LEVEL_HEADER_CELL_HORIZONTAL_PADDING = 5;
export const LEVEL_HEADER_CELL_VERTICAL_PADDING = 3;
export const HEADER_ROW_HEIGHT = 30;
export const COMPONENT_ITEM_HEIGHT = 26;
export const COMPONENT_ITEM_CONTAINER_BORDER = 1;
export const COMPONENT_CONTEXT_ITEM_CONTAINER_BORDER = 3;
export const COMPONENT_ITEM_CONTAINER_MARGIN = 2;
export const COMPONENT_ITEM_CONTAINER_PADDINGRIGHT = 4;
export const ICON_WIDTH = 18;
export const ICON_MARGIN = 2;
export const MAIN_GRID_LEFT_COLUMN_MAX_WIDTH_VIEWPORT_UNITS = 15;
export const COMPONENTS_LIMIT = 4000;
