import {
  APISlideAttributes,
  ArdoqId,
  LoadedStateParams,
  ViewIds,
  isDashboardSlide,
  isMetamodelSlide,
  hasLoadedState,
  isReportSlide,
  isVisualizationSlide,
  isLucidSlide,
} from '@ardoq/api-types';
import { PayloadLoadSlide } from '../../presentation/viewPane/actions';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { hasFeature, Features } from '@ardoq/features';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import slides$ from 'streams/slides/slides$';
import { getViewThumbnailSrc } from 'tabview/consts';
import { ContextSort } from '@ardoq/data-model';

const getSlideById = (slideId: ArdoqId): APISlideAttributes | undefined =>
  slides$.state.byId[slideId];

const getViewId = (slideId: ArdoqId): ViewIds | undefined => {
  const slide = getSlideById(slideId);
  if (!slide) {
    return undefined;
  }
  if (!isVisualizationSlide(slide)) {
    return undefined;
  }
  return slide.view;
};

const getConsideredWorkspaceIds = (slide: APISlideAttributes): ArdoqId[] =>
  isVisualizationSlide(slide)
    ? (slide.consideredWorkspaceIds ?? slide.workspaceIds ?? [])
    : [];

const determineFullAccess = (slideId: ArdoqId) => {
  const subdivisionIds = getSubdivisionIds(slideId);
  // 'old' slides (when subdivisions are enabled) and slides in orgs without subdivisions use the old access pattern
  if (!hasFeature(Features.PERMISSION_ZONES_INTERNAL) || !subdivisionIds) {
    const slide = getSlideById(slideId);
    if (!slide) {
      return false;
    }
    const workspaceIds = getConsideredWorkspaceIds(slide);
    return (
      workspaceIds?.every(workspaceId =>
        workspaceAccessControlInterface.canAccessWorkspace(
          currentUserPermissionContext$.state,
          workspaceId
        )
      ) ?? false
    );
  }
  return subdivisionIds.every(subdivisionId =>
    subdivisions$.state.sortedIds.includes(subdivisionId)
  );
};

type SlideInfo = {
  slideName: string;
  createdByName: string;
  createdByEmail: string;
  hasFullAccess: boolean;
};

const getSlideInfo = (slideId: ArdoqId): SlideInfo | null => {
  const slide = getSlideById(slideId);
  if (!slide) {
    return null;
  }
  return {
    slideName: slide.name,
    createdByName: slide.createdByName,
    createdByEmail: slide.createdByEmail,
    hasFullAccess: determineFullAccess(slideId),
  };
};

const getSlidesThatContainScenario = (scenarioId: ArdoqId): ArdoqId[] =>
  slides$.state.list
    .filter(
      slide => isVisualizationSlide(slide) && slide.scenarioId === scenarioId
    )
    .map(slide => slide._id);

const getSlideLoadPayload = (slideId: ArdoqId): PayloadLoadSlide | null => {
  const slide = getSlideById(slideId);

  if (!slide) {
    return null;
  }
  if (isMetamodelSlide(slide)) {
    return {
      id: slide._id,
      metamodelId: 'metamodelId' in slide ? slide.metamodelId : undefined,
      isScenario: false,
      type: slide.type,
      viewId: undefined,
      viewState: {},
      hasFullAccess: determineFullAccess(slideId),
    };
  }
  if (isVisualizationSlide(slide)) {
    return {
      id: slide._id,
      metamodelId: undefined,
      isScenario: Boolean(slide.scenarioId),
      type: slide.type,
      viewId: slide.view,
      viewState: slide.viewstate,
      hasFullAccess: determineFullAccess(slideId),
    };
  }

  return {
    id: slide._id,
    metamodelId: undefined,
    isScenario: false,
    type: slide.type,
    viewId: undefined,
    viewState: {},
    hasFullAccess: determineFullAccess(slideId),
  };
};

const exists = (slideId: ArdoqId): boolean => Boolean(getSlideById(slideId));

const getDescription = (slideId: ArdoqId): string | undefined =>
  getSlideById(slideId)?.description;

const getThumbnailSrc = (slideId: ArdoqId): string | undefined => {
  const slide = getSlideById(slideId);
  if (!slide) {
    return undefined;
  }
  if (isVisualizationSlide(slide)) {
    return `/${getViewThumbnailSrc(slide.view)}`;
  }
  if (isDashboardSlide(slide)) {
    return '/img/viewsExample/dashboard.jpg';
  }
  if (isReportSlide(slide)) {
    return '/img/viewsExample/report.png';
  }
  if (isLucidSlide(slide)) {
    return '/img/viewsExample/lucidchart_slide_thumbnail.jpg';
  }
  return '/img/viewsExample/metamodel.jpg';
};

const getSubdivisionIds = (slideId: ArdoqId): ArdoqId[] | null => {
  const slide = getSlideById(slideId);
  if (!slide) {
    return null;
  }
  if (!isVisualizationSlide(slide)) {
    return null;
  }
  return slide.subdivisionIds || null;
};

const getName = (slideId: ArdoqId): string | undefined =>
  getSlideById(slideId)?.name;

const getLoadedStates = (slideId: ArdoqId): LoadedStateParams[] => {
  return getSlideById(slideId)?.loadedStates ?? [];
};

// we have functions in @ardoq/api-types for these
// these slide type checks can be replaced once we remove slide BB models
const isViewpointSlide = (slideId: ArdoqId): boolean => {
  const slide = getSlideById(slideId);
  if (!slide || !isVisualizationSlide(slide) || slide.scenarioId) return false;
  const loadedStates = getLoadedStates(slideId);
  return hasLoadedState(loadedStates);
};

const getSort = (slideId: ArdoqId): ContextSort | undefined => {
  const slide = getSlideById(slideId);
  if (!slide || !isVisualizationSlide(slide)) return;
  const attr = slide.sortAttr;

  if (!attr) {
    return;
  }
  const order = slide.sortOrder || 0;

  return { order, attr };
};

const isVisualizationSlideProxy = (slideId: ArdoqId): boolean => {
  const slide = getSlideById(slideId);
  return Boolean(slide && isVisualizationSlide(slide));
};

const isDashboardSlideProxy = (slideId: ArdoqId): boolean => {
  const slide = getSlideById(slideId);
  return Boolean(slide && isDashboardSlide(slide));
};

const isReportSlideProxy = (slideId: ArdoqId): boolean => {
  const slide = getSlideById(slideId);
  return Boolean(slide && isReportSlide(slide));
};

const isMetamodelSlideProxy = (slideId: ArdoqId): boolean => {
  const slide = getSlideById(slideId);
  return Boolean(slide && isMetamodelSlide(slide));
};

export const slideInterface = {
  getSlideById,
  exists,
  getDescription,
  getName,
  getThumbnailSrc,
  getViewId,
  getSlideLoadPayload,
  getSlideInfo,
  getSlidesThatContainScenario,
  getSubdivisionIds,
  determineFullAccess,
  getLoadedStates,
  isVisualizationSlide: isVisualizationSlideProxy,
  isDashboardSlide: isDashboardSlideProxy,
  isReportSlide: isReportSlideProxy,
  isMetamodelSlide: isMetamodelSlideProxy,
  isViewpointSlide,
  getSort,
};
