import { GROUP_CHILDREN_LIMIT } from '../consts';
import { RelationshipsNode } from '../types';
import { SYNTHETIC_NODE_DEFAULTS } from './consts';

const sort = (node: RelationshipsNode) => {
  if (node.children) {
    node.children.sort(
      (a, b) => (a.links ? a.links.length : 0) - (b.links ? b.links.length : 0)
    );
    node.children.forEach(child => sort(child));
  }
};
const partition = (node: RelationshipsNode) => {
  if (node.children && node.children.length > GROUP_CHILDREN_LIMIT) {
    const grandchildren = node.children;
    const childrenCount = Math.ceil(
      grandchildren.length / GROUP_CHILDREN_LIMIT
    );

    node.children = [];

    for (let i = 0; i < childrenCount; ++i) {
      node.children.push({
        ...SYNTHETIC_NODE_DEFAULTS,
        id: `${node.id}-childContainer${i}`,
        parent: node,
        children: [],
        className: '',
        label: '',
        subLabel: null,
        representationData: null,
        modelId: null,
        links: null,
        isSynthetic: true,
        descendantCount: 0,
        isContext: false,
      });
    }

    for (let i = 0; i < grandchildren.length; ++i) {
      const child = node.children[i % childrenCount]; // one of the new synthetic children
      const grandchild = grandchildren[i]; // one of the original children

      grandchild.parent = child;
      child.children!.push(grandchild);
    }
  }

  if (node.children) {
    node.children.forEach(child => partition(child));
  }
};
const divideExcessiveChildren = (node: RelationshipsNode) => {
  sort(node);
  partition(node);
};

export default divideExcessiveChildren;
