import { Observable, distinctUntilChanged, map } from 'rxjs';
import { range, uniqBy } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import type { LinkedComponent, ContextShape } from '@ardoq/data-model';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { isUndefinedOrNull, isUniqueCombination } from 'utils/collectionUtil';
import {
  bypassLimitAction,
  componentMatrixToggleCollapseGroup,
} from '../actions';
import {
  COMPONENT_HIERARCHY_PADDING,
  COMPONENTS_LIMIT,
  INCOMING_SUFFIX,
  LEVEL_HEADER_CELL_HORIZONTAL_PADDING,
  OUTGOING_SUFFIX,
} from '../consts';
import {
  ComponentMatrixDimension,
  ComponentMatrixGroup,
  ComponentMatrixGroupOwnerType,
  ComponentMatrixMeasurements,
  ComponentMatrixViewModel,
  ComponentMatrixViewProperties,
  ComponentMatrixViewSettings,
  ComponentMatrixViewStreamState,
} from '../types';
import { addAddressToGroup } from './addressUtil';
import {
  extractReferenceTypeId,
  extractWorkspaceId,
  formatReferenceType,
  getLinkedComponentsAddresses,
  referenceTypeIdMaxHierarchyDepth,
} from './referenceAddressUtil';
import { getRelevantComponentIds } from '@ardoq/graph';
import {
  type ExtractPayload,
  action$,
  combineReducers,
  dispatchAction,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { measureLevelLabel } from './measureLabels';
import getRowModels from './getRowModels';
import {
  getHeaderColumnWidths,
  measureCells,
  measureRowHeaders,
  measureRowLevelHeaders,
} from './measureCells';
import * as profiling from '@ardoq/profiling';
import {
  type TraversedReferenceTypeInfo,
  matchSelectedReferenceType,
  getReferenceTypeInfo,
} from '@ardoq/settings-bar';
import { debouncedCombineLatest } from 'streams/utils/streamUtils';
import allDescendantsReducer from 'modelInterface/components/allDescendantsReducer';
import paddedAddressPermutations from './paddedAddressPermutations';
import { isEqual, pick } from 'lodash';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import getReferencesForSelectedContext from 'models/utils/getReferencesForSelectedContext';
import { ItemsType, nodeLimitError } from '@ardoq/error-info-box';
import { format } from 'utils/numberUtils';
import defaultState from 'views/defaultState';
import { EMPTY_CONTEXT_SHAPE } from 'streams/context/ContextShape';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';

type HalfAddress = (string | null)[];
interface Address {
  columnValues: HalfAddress;
  rowValues: HalfAddress;
}

const combineHalfAddresses = (
  fieldAddress: HalfAddress,
  referenceAddresses: HalfAddress[]
) =>
  referenceAddresses.length
    ? referenceAddresses.map(referenceAddress => [
        ...referenceAddress,
        ...fieldAddress,
      ])
    : [fieldAddress];

const initialStateMeasurements = () => ({
  mainContainerLeftColumnWidth: 0,
  columnHeaderWidths: [],
  columnHeaderAndColumnWidths: [],
  rowHeights: [],
});
const initialState: ComponentMatrixViewStreamState = {
  viewSettings: defaultState.get(ViewIds.COMPONENT_MATRIX),
  viewSettingsState: {
    listFields: [],
    traversedOutgoingReferenceTypes: [],
    traversedIncomingReferenceTypes: [],
  },
  viewModel: {
    columns: new Map(),
    rows: new Map(),
    items: [],
    columnsByDepth: [],
    rowsByDepth: [],
    columnLevelLabels: [],
    rowLevelLabels: [],
    measurements: initialStateMeasurements(),
    rowModels: [],
    hoveredComponentId: null,
    focusedComponentId: null,
  },
  hasReferences: false,
  hasFilteredNullItems: false,
  errors: [],
  context: EMPTY_CONTEXT_SHAPE,
};

const EMPTY_GROUP_MAP = new Map<string | null, ComponentMatrixGroup>([
  [
    null,
    {
      address: [null],
      ownerType: ComponentMatrixGroupOwnerType.EMPTY,
      label: null,
      isExpanded: false,
      isHidden: false,
      children: new Map(),
    },
  ],
]);

const getFieldAddress = (
  componentId: ArdoqId,
  fieldNames: string[]
): (string | null)[] =>
  Object.values<string | undefined>(
    componentInterface.getAttributes(componentId, fieldNames)
  ).map(value => value ?? null);

interface ViewModelAddresses {
  xFieldAddress: string[];
  xReferenceAddress: string | null;
  xReferenceTypeMaxHierarchyDepth: number;
  yFieldAddress: string[];
  yReferenceAddress: string | null;
  yReferenceTypeMaxHierarchyDepth: number;
  xReferenceIsOutgoing: boolean;
  yReferenceIsOutgoing: boolean;
}
interface ViewModelReducerState {
  viewModel: ComponentMatrixViewModel;
  addresses: ViewModelAddresses;
  addressFilter: (address: Address) => boolean;
}

// if address length is 0, we are showing everything in the "none" row or column.
const isEmptyOrHasNulls = (address: (string | null)[]) =>
  !address.length || address.some(isUndefinedOrNull);
const addressFilter =
  (filterNoneX: boolean, filterNoneY: boolean) => (address: Address) =>
    (!filterNoneX && !filterNoneY) ||
    ((!filterNoneX || !isEmptyOrHasNulls(address.columnValues)) &&
      (!filterNoneY || !isEmptyOrHasNulls(address.rowValues)));

const filterLinkedComponents =
  (getLinkedComponents: (componentId: string) => LinkedComponent[]) =>
  (componentId: string) =>
    getLinkedComponents(componentId).filter(linkedComponent =>
      referenceInterface.isIncludedInContextByFilter(
        linkedComponent.referenceId
      )
    );
const getSources = filterLinkedComponents(componentInterface.getSources);
const getTargets = filterLinkedComponents(componentInterface.getTargets);

const viewModelReducer = (
  state: ViewModelReducerState,
  componentId: ArdoqId
): ViewModelReducerState => {
  const xFieldAddress = getFieldAddress(
    componentId,
    state.addresses.xFieldAddress
  );
  const xReferencesAddresses = state.addresses.xReferenceAddress
    ? getLinkedComponentsAddresses(
        componentId,
        extractReferenceTypeId(state.addresses.xReferenceAddress),
        extractWorkspaceId(state.addresses.xReferenceAddress),
        state.addresses.xReferenceIsOutgoing ? getTargets : getSources,
        true,
        state.addresses.xReferenceTypeMaxHierarchyDepth
      )
    : [];
  const yFieldAddress = getFieldAddress(
    componentId,
    state.addresses.yFieldAddress
  );
  const yReferencesAddresses = state.addresses.yReferenceAddress
    ? getLinkedComponentsAddresses(
        componentId,
        extractReferenceTypeId(state.addresses.yReferenceAddress),
        extractWorkspaceId(state.addresses.yReferenceAddress),
        state.addresses.yReferenceIsOutgoing ? getTargets : getSources,
        true,
        state.addresses.yReferenceTypeMaxHierarchyDepth
      )
    : [];

  const xData = {
    referencesAddresses: xReferencesAddresses,
    fieldAddress: xFieldAddress,
  };
  const yData = {
    referencesAddresses: yReferencesAddresses,
    fieldAddress: yFieldAddress,
  };

  const [xAddresses, yAddresses] = [xData, yData].map(
    ({ fieldAddress, referencesAddresses }) =>
      combineHalfAddresses(fieldAddress, referencesAddresses)
  );
  const [headerXAddresses, headerYAddresses] = [xData, yData].map(
    ({ fieldAddress, referencesAddresses }) => {
      // permuted reference addresses shouldn't have actual list values appended, otherwise this will create column headers for data that does not exist.
      const nullFieldAddress = range(fieldAddress.length).map(
        () => COMPONENT_HIERARCHY_PADDING
      );
      const referenceAddressPermutations =
        paddedAddressPermutations(referencesAddresses);
      return [
        ...combineHalfAddresses(fieldAddress, referencesAddresses),
        ...combineHalfAddresses(nullFieldAddress, referenceAddressPermutations),
      ];
    }
  );

  const [addresses, headerAddresses] = [
    [xAddresses, yAddresses],
    [headerXAddresses, headerYAddresses],
  ].map(([xAddresses, yAddresses]) =>
    yAddresses
      .flatMap(rowValues =>
        xAddresses.map(columnValues => ({
          columnValues,
          rowValues,
        }))
      )
      .filter(state.addressFilter)
  );
  const addressesToUse = addresses.filter(state.addressFilter);

  // #region add addresses, including padded address permutations, to the row and column maps
  headerAddresses.forEach(address => {
    const xFieldWidth = xFieldAddress.length;
    const xReferenceWidth = address.columnValues.length - xFieldWidth;
    const yFieldWidth = yFieldAddress.length;
    const yReferenceWidth = address.rowValues.length - yFieldWidth;

    const [xAddressOwnerTypes, yAddressOwnerTypes] = [
      [xReferenceWidth, xFieldWidth],
      [yReferenceWidth, yFieldWidth],
    ].map(([referenceWidth, fieldWidth]) => [
      ...range(referenceWidth).map(
        () => ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT
      ),
      ...range(fieldWidth).map(
        () => ComponentMatrixGroupOwnerType.LIST_FIELD_VALUE
      ),
    ]);

    const xAddressInfo = {
      address: address.columnValues,
      ownerTypes: xAddressOwnerTypes,
      groupMap: state.viewModel.columns,
    };
    const yAddressInfo = {
      address: address.rowValues,
      ownerTypes: yAddressOwnerTypes,
      groupMap: state.viewModel.rows,
    };
    [xAddressInfo, yAddressInfo].forEach(({ address, ownerTypes, groupMap }) =>
      addAddressToGroup(address, ownerTypes, groupMap)
    );
  });
  // #endregion

  /** all the row+column addresses for this component id. in other words, all the body cell locations of the component. */
  const componentAddresses = addressesToUse.map(address => ({
    componentId,
    address,
  }));
  componentAddresses.forEach(address => {
    state.viewModel.items.push(address);
  });
  return state;
};

type GroupLevelsState = {
  groupsByDepth: ComponentMatrixGroup[][];
  currentDepth: number;
};

const groupLevelsReducer = (
  state: GroupLevelsState,
  currentGroup: ComponentMatrixGroup
): GroupLevelsState => {
  if (state.groupsByDepth.length <= state.currentDepth) {
    state.groupsByDepth.push([]);
  }
  state.groupsByDepth[state.currentDepth].push(currentGroup);
  if (currentGroup.children.size) {
    [...currentGroup.children.values()].reduce(groupLevelsReducer, {
      ...state,
      currentDepth: state.currentDepth + 1,
    });
  }
  return state;
};

const listFieldLabel = (
  fieldName: string,
  listFields: APIFieldAttributes[]
) => {
  const field =
    fieldName &&
    listFields &&
    listFields.find(field => field.name === fieldName);
  if (!field) {
    return 'Unknown';
  }
  return field.label;
};

const groupLevelLabels = (
  referenceTypeName: string,
  maxDepth: number,
  isOutgoing: boolean
) => {
  if (!referenceTypeName) {
    return [];
  }

  const levelLabel = `${referenceTypeName}${
    isOutgoing ? OUTGOING_SUFFIX : INCOMING_SUFFIX
  }`;
  return maxDepth <= 1
    ? [levelLabel]
    : [levelLabel].concat(
        Array(maxDepth - 1).fill(COMPONENT_HIERARCHY_PADDING)
      );
};

const isComponentHierarchyPadding = (group: ComponentMatrixGroup) =>
  group.address[group.address.length - 1] === COMPONENT_HIERARCHY_PADDING;
const toggleExpandedGroups = (
  groups: Map<string | null, ComponentMatrixGroup>,
  [address, ...restAddress]: (string | null)[]
) => {
  const group = groups.get(address);
  if (group === undefined) return;

  const shouldToggle = restAddress.length === 0;
  if (shouldToggle) {
    // padding is always expanded, in case there's a list field inside
    group.isExpanded = !group.isExpanded || isComponentHierarchyPadding(group);

    group.children.forEach(child => setHidden(child, !group.isExpanded));

    if (group.isExpanded) {
      // all padding must be expanded.
      expandComponentHierarchyPadding(group);
    }
  } else {
    toggleExpandedGroups(group.children, restAddress);
  }
};
const setHidden = (group: ComponentMatrixGroup, hidden: boolean) => {
  group.isHidden = hidden;
  const iterateChildren = hidden || (!hidden && group.isExpanded); // hide children of hidden groups. show children of expanded groups.
  if (iterateChildren) {
    group.children.forEach(child => setHidden(child, hidden));
  }
};

const expandComponentHierarchyPadding = (group: ComponentMatrixGroup) => {
  [...group.children.values()]
    .filter(child => isComponentHierarchyPadding(child))
    .forEach(child => {
      child.isHidden = false;
      child.isExpanded = true;
      child.children.forEach(child => (child.isHidden = false));
      expandComponentHierarchyPadding(child);
    });
};

const getViewModel = (
  startSet: ArdoqId[],
  viewSettings: ComponentMatrixViewSettings,
  listFields: APIFieldAttributes[],
  traversedReferenceTypes: TraversedReferenceTypeInfo[]
) => {
  const selectedFieldNamesX = viewSettings.selectedFieldNamesX.filter(
    fieldName => listFields.some(field => field.name === fieldName)
  );
  const selectedFieldNamesY = viewSettings.selectedFieldNamesY.filter(
    fieldName => listFields.some(field => field.name === fieldName)
  );

  const { referenceTypeX, referenceTypeY } = viewSettings;
  const [foundReferenceTypeX, foundReferenceTypeY] = [
    referenceTypeX,
    referenceTypeY,
  ].map(referenceType =>
    referenceType === null
      ? null
      : (traversedReferenceTypes.find(traversedReferenceType =>
          matchSelectedReferenceType(referenceType, traversedReferenceType)
        ) ?? null)
  );

  const xReferenceAddress =
    foundReferenceTypeX === null
      ? ''
      : formatReferenceType(foundReferenceTypeX);
  const yReferenceAddress =
    foundReferenceTypeY === null
      ? ''
      : formatReferenceType(foundReferenceTypeY);

  const xReferenceTypeId = extractReferenceTypeId(xReferenceAddress);
  const yReferenceTypeId = extractReferenceTypeId(yReferenceAddress);
  const xReferenceWorkspace = extractWorkspaceId(xReferenceAddress);
  const yReferenceWorkspace = extractWorkspaceId(yReferenceAddress);

  const xReferenceTypeMaxHierarchyDepth = referenceTypeIdMaxHierarchyDepth(
    startSet,
    xReferenceTypeId,
    xReferenceWorkspace,
    viewSettings.referenceTypeX?.isOutgoing
      ? componentInterface.getTargets
      : componentInterface.getSources
  );
  const yReferenceTypeMaxHierarchyDepth = referenceTypeIdMaxHierarchyDepth(
    startSet,
    yReferenceTypeId,
    yReferenceWorkspace,
    viewSettings.referenceTypeY?.isOutgoing
      ? componentInterface.getTargets
      : componentInterface.getSources
  );

  const noColumns =
    xReferenceAddress.length === 0 && selectedFieldNamesX.length === 0;
  const noRows =
    yReferenceAddress.length === 0 && selectedFieldNamesY.length === 0;

  const viewModel = fillEmptyRowsOrColumns(
    startSet.reduce(viewModelReducer, {
      viewModel: {
        columns: new Map(),
        rows: new Map(),
        items: [],
        columnsByDepth: [],
        rowsByDepth: [],
        columnLevelLabels: [
          ...groupLevelLabels(
            foundReferenceTypeX?.displayName || '',
            xReferenceTypeMaxHierarchyDepth,
            Boolean(foundReferenceTypeX?.isOutgoing)
          ),
          ...selectedFieldNamesX.map(fieldName =>
            listFieldLabel(fieldName, listFields)
          ),
        ],
        rowLevelLabels: [
          ...groupLevelLabels(
            foundReferenceTypeY?.displayName || '',
            yReferenceTypeMaxHierarchyDepth,
            Boolean(foundReferenceTypeY?.isOutgoing)
          ),
          ...selectedFieldNamesY.map(fieldName =>
            listFieldLabel(fieldName, listFields)
          ),
        ],
        rowModels: [],
        measurements: initialStateMeasurements(),
        hoveredComponentId: null,
        focusedComponentId: null,
      },
      addresses: {
        xFieldAddress: selectedFieldNamesX,
        xReferenceAddress: xReferenceAddress,
        xReferenceTypeMaxHierarchyDepth,
        yFieldAddress: selectedFieldNamesY,
        yReferenceAddress: yReferenceAddress,
        yReferenceTypeMaxHierarchyDepth,
        xReferenceIsOutgoing: Boolean(foundReferenceTypeX?.isOutgoing),
        yReferenceIsOutgoing: Boolean(foundReferenceTypeY?.isOutgoing),
      },
      addressFilter: addressFilter(
        viewSettings.filterNulls && !noColumns,
        viewSettings.filterNulls && !noRows
      ),
    }).viewModel
  );

  const fieldByName = (fieldName: string) =>
    listFields.find(listField => listField.name === fieldName);
  const xFields = selectedFieldNamesX.map(fieldByName).filter(ExcludeFalsy);
  const yFields = selectedFieldNamesY.map(fieldByName).filter(ExcludeFalsy);

  viewModel.columns = sortGroups(viewModel.columns, xFields);
  viewModel.rows = sortGroups(viewModel.rows, yFields);

  return viewModel;
};

const fillEmptyRowsOrColumns = (
  viewModel: ComponentMatrixViewModel
): ComponentMatrixViewModel => {
  const emptyRows = !viewModel.rows.size;
  const emptyColumns = !viewModel.columns.size;
  const rows = emptyRows ? EMPTY_GROUP_MAP : viewModel.rows;
  const columns = emptyColumns ? EMPTY_GROUP_MAP : viewModel.columns;
  const items =
    emptyRows && emptyColumns
      ? viewModel.items.map(item => ({
          ...item,
          address: {
            rowValues: [null],
            columnValues: [null],
          },
        }))
      : emptyRows
        ? viewModel.items.map(item => ({
            ...item,
            address: {
              rowValues: [null],
              columnValues: item.address.columnValues,
            },
          }))
        : emptyColumns
          ? viewModel.items.map(item => ({
              ...item,
              address: {
                rowValues: item.address.rowValues,
                columnValues: [null],
              },
            }))
          : viewModel.items;
  return { ...viewModel, rows, columns, items };
};

const groupKeyComparator =
  (listFieldOrder?: string[]) => (a: string | null, b: string | null) => {
    if (a === COMPONENT_HIERARCHY_PADDING) {
      return -1;
    }
    if (b === COMPONENT_HIERARCHY_PADDING) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    if (a === null) {
      return 1;
    }
    if (listFieldOrder) {
      return listFieldOrder.indexOf(a) - listFieldOrder.indexOf(b);
    }
    return componentInterface.compare(a, b);
  };

const sortGroups = (
  groups: Map<string | null, ComponentMatrixGroup>,
  listFields: APIFieldAttributes[]
): Map<string | null, ComponentMatrixGroup> => {
  const listField: APIFieldAttributes | undefined = listFields[0];
  const listFieldOrder = (listField?.defaultValue as string | undefined)?.split(
    ','
  );

  const sortedKeys = Array.from(groups.keys()).sort(
    groupKeyComparator(listFieldOrder)
  );

  const sortedNonNullKeys = groups.has(null)
    ? [...sortedKeys.filter(key => key !== null), null] // "none" is last.
    : sortedKeys;

  return new Map(
    sortedNonNullKeys.map(key => {
      const group = groups.get(key)!;
      return [
        key,
        {
          ...group,
          children: sortGroups(group.children, listFields.slice(1)),
        },
      ];
    })
  );
};

const getStartSet = (context: ContextShape, includeAllDescendants: boolean) => {
  const startSet = getRelevantComponentIds({
    componentId: context.componentId,
    workspaceId: context.workspaceId,
    discardFilteredChildren: true,
  });
  return includeAllDescendants
    ? startSet.reduce(allDescendantsReducer, [])
    : startSet;
};

const findDuplicates = <T>(
  collection: T[],
  isDuplicate: (itemA: T, itemB: T) => boolean
) =>
  collection.filter(
    (item, index, array) =>
      array.findIndex(itemB => isDuplicate(item, itemB)) !== index
  );

const getReferenceTypes = (
  startSet: ArdoqId[],
  getSourcesOrTargets: (componentId: ArdoqId) => LinkedComponent[]
) => {
  const referenceTypes = uniqBy(
    startSet.flatMap(getSourcesOrTargets),
    'referenceId'
  )
    .map(getReferenceTypeInfo)
    .filter(isUniqueCombination('referenceTypeName', 'linkedWorkspaceId'));

  const duplicateReferenceTypeNames = new Set(
    findDuplicates(
      referenceTypes,
      (linkedComponentA, linkedComponentB) =>
        linkedComponentA.referenceTypeName ===
        linkedComponentB.referenceTypeName
    ).map(({ referenceTypeName }) => referenceTypeName)
  );

  return referenceTypes.map(referenceType => ({
    ...referenceType,
    displayName: duplicateReferenceTypeNames.has(
      referenceType.referenceTypeName
    )
      ? `${
          referenceType.referenceTypeName
        } (${workspaceInterface.getWorkspaceName(
          referenceType.linkedWorkspaceId
        )})`
      : referenceType.referenceTypeName,
  }));
};

const getReferenceTypeOptions = (startSet: ArdoqId[]) => ({
  traversedIncomingReferenceTypes: getReferenceTypes(
    startSet,
    componentInterface.getSources
  ).map(referenceType => ({ ...referenceType, isOutgoing: false })),
  traversedOutgoingReferenceTypes: getReferenceTypes(
    startSet,
    componentInterface.getTargets
  ).map(referenceType => ({ ...referenceType, isOutgoing: true })),
});

const reset = (
  viewSettings: ComponentMatrixViewSettings,
  context: ContextShape,
  bypassLimit: boolean
): ComponentMatrixViewStreamState => {
  const transaction = profiling.startTransaction(
    'component matrix reset view model',
    500,
    profiling.Team.INSIGHT
  );
  const fullStartSet = getStartSet(
    context,
    viewSettings.includeAllDescendants
  ).filter(componentInterface.isIncludedInContextByFilter);

  const errors =
    fullStartSet.length > COMPONENTS_LIMIT && !bypassLimit
      ? [
          nodeLimitError({
            itemCount: fullStartSet.length,
            limit: COMPONENTS_LIMIT,
            itemsType: ItemsType.COMPONENTS,
            formatNumber: format,
            onProceedAnyway: () => dispatchAction(bypassLimitAction()),
          }),
        ]
      : [];

  const startSet = bypassLimit
    ? fullStartSet
    : fullStartSet.slice(0, COMPONENTS_LIMIT);

  const { traversedIncomingReferenceTypes, traversedOutgoingReferenceTypes } =
    getReferenceTypeOptions(startSet);

  const listFields = fieldInterface.getFieldsOfWorkspace(context.workspaceId, [
    APIFieldType.LIST,
  ]);

  const hasReferences = !!getReferencesForSelectedContext(context).length;

  const viewModel = augmentViewModel(
    getViewModel(startSet, viewSettings, listFields, [
      ...traversedIncomingReferenceTypes,
      ...traversedOutgoingReferenceTypes,
    ])
  );

  const hasFilteredNullItems =
    viewSettings.filterNulls && startSet.length !== viewModel.items.length;

  const result = {
    viewSettingsState: {
      listFields,
      traversedIncomingReferenceTypes,
      traversedOutgoingReferenceTypes,
    },
    viewSettings,
    context,
    viewModel,
    hasReferences,
    hasFilteredNullItems,
    errors,
  };
  profiling.endTransaction(transaction, {
    viewId: ViewIds.COMPONENT_MATRIX,
    metadata: { itemCount: result.viewModel.items.length },
  });
  return result;
};
const cloneGroup = (group: ComponentMatrixGroup): ComponentMatrixGroup => ({
  ...group,
  children: cloneMap(group.children, cloneGroup),
});
const cloneMap = <K, V>(
  target: Map<K, V>,
  cloneValue: (value: V) => V = value => Object.assign({}, value)
) =>
  new Map(
    [...target.entries()].map(([key, value]) => [key, cloneValue(value)])
  );

const reduceCollapseGroup = (
  state: ComponentMatrixViewStreamState,
  {
    address,
    dimension,
  }: ExtractPayload<typeof componentMatrixToggleCollapseGroup>
): ComponentMatrixViewStreamState => {
  const newViewModel = { ...state.viewModel };
  if (dimension === ComponentMatrixDimension.COLUMN) {
    const newColumns = cloneMap(newViewModel.columns, cloneGroup);
    toggleExpandedGroups(newColumns, address);
    newViewModel.columns = newColumns;
  } else if (dimension === ComponentMatrixDimension.ROW) {
    const newRows = cloneMap(newViewModel.rows, cloneGroup);
    toggleExpandedGroups(newRows, address);
    newViewModel.rows = newRows;
  }
  return { ...state, viewModel: augmentViewModel(newViewModel) };
};
const toggleCollapseGroup = reducer(
  componentMatrixToggleCollapseGroup,
  reduceCollapseGroup
);

const handleHoveredComponentChanged = (
  state: ComponentMatrixViewStreamState,
  hoveredComponentId: ArdoqId | null
): ComponentMatrixViewStreamState => ({
  ...state,
  viewModel: {
    ...state.viewModel,
    hoveredComponentId,
  },
});

const handleFocusedComponentChanged = (
  state: ComponentMatrixViewStreamState,
  focusedComponentId: ArdoqId | null
): ComponentMatrixViewStreamState => ({
  ...state,
  viewModel: {
    ...state.viewModel,
    focusedComponentId:
      focusedComponentId !== state.viewModel.focusedComponentId
        ? focusedComponentId
        : null,
    hoveredComponentId: null,
  },
});

const viewSettings$ = getViewSettingsStream<ComponentMatrixViewSettings>(
  ViewIds.COMPONENT_MATRIX
);
const measure = (
  viewModel: ComponentMatrixViewModel
): ComponentMatrixMeasurements => {
  const { rowModels } = viewModel;
  const columnLevelLabelWidths =
    viewModel.columnLevelLabels.map(measureLevelLabel);
  const widestColumnLevelLabel =
    columnLevelLabelWidths.length === 0
      ? 0
      : Math.max(...columnLevelLabelWidths);

  const cellSizes = measureCells(rowModels);
  const rowHeaderSizes = measureRowHeaders(rowModels);

  const columnWidths =
    cellSizes.length === 0
      ? []
      : range(cellSizes[0].length).map(columnIndex =>
          Math.max(...cellSizes.map(row => row[columnIndex].width))
        );

  const columnHeaderWidths = getHeaderColumnWidths(viewModel);
  const columnHeaderAndColumnWidths = columnHeaderWidths.map(
    (columnHeaderWidth, index) =>
      Math.max(columnHeaderWidth, columnWidths[index])
  );

  const rowBodyHeights = cellSizes.map(row =>
    Math.max(...row.map(cell => cell.height))
  );
  const rowHeaderHeights = rowHeaderSizes.map(row => row.height);

  const rowHeights = rowBodyHeights.map((rowBodyHeight, index) =>
    Math.max(rowBodyHeight, rowHeaderHeights[index])
  );

  const rowHeadersAreaWidth = Math.max(
    ...rowHeaderSizes.map(rowHeaderSize => rowHeaderSize.width)
  );

  const levelHeadersAreaLeftColumnWidth = measureRowLevelHeaders(
    viewModel.rowLevelLabels
  );

  const levelHeadersAreaRightColumnWidth =
    widestColumnLevelLabel === 0
      ? 0
      : widestColumnLevelLabel + 2 * LEVEL_HEADER_CELL_HORIZONTAL_PADDING;

  const mainContainerLeftColumnWidth = Math.max(
    levelHeadersAreaLeftColumnWidth,
    levelHeadersAreaRightColumnWidth,
    rowHeadersAreaWidth
  );

  return {
    mainContainerLeftColumnWidth,
    columnHeaderWidths,
    columnHeaderAndColumnWidths,
    rowHeights,
  };
};

const getGroupsByDepth = (groups: ComponentMatrixGroup[]) =>
  groups.reduce(groupLevelsReducer, {
    groupsByDepth: [],
    currentDepth: 0,
  }).groupsByDepth;
const augmentWithGroupsByDepth = (viewModel: ComponentMatrixViewModel) => ({
  ...viewModel,
  columnsByDepth: getGroupsByDepth([...viewModel.columns.values()]),
  rowsByDepth: getGroupsByDepth([...viewModel.rows.values()]),
});
const augmentViewModel = (viewModel: ComponentMatrixViewModel) =>
  augmentWithMeasurements(
    augmentWithRowModels(augmentWithGroupsByDepth(viewModel))
  );
const augmentWithRowModels = (viewModel: ComponentMatrixViewModel) => ({
  ...viewModel,
  rowModels: getRowModels(viewModel),
});
const augmentWithMeasurements = (viewModel: ComponentMatrixViewModel) => ({
  ...viewModel,
  measurements: measure(viewModel),
});
export const viewModel$ =
  (/* viewInstanceId: string */): Observable<ComponentMatrixViewProperties> => {
    const resetFromStream = (
      _: ComponentMatrixViewStreamState,
      [viewSettings, context]: [ComponentMatrixViewSettings, ContextShape, null]
    ) => reset(viewSettings, context, false);

    const resetReducer = streamReducer(
      debouncedCombineLatest([
        viewSettings$.pipe<
          ComponentMatrixViewSettings,
          ComponentMatrixViewSettings
        >(
          map(viewSettings =>
            pick(viewSettings, [
              'selectedFieldNamesX',
              'selectedFieldNamesY',
              'referenceTypeX',
              'referenceTypeY',
              'filterNulls',
              'includeAllDescendants',
            ])
          ),
          distinctUntilChanged(isEqual)
        ),
        context$,
        modelUpdateNotification$,
      ]),
      resetFromStream
    );

    const handleBypassLimit = (state: ComponentMatrixViewStreamState) =>
      reset(state.viewSettings, state.context, true);

    return action$.pipe(
      combineReducers<ComponentMatrixViewStreamState>(initialState, [
        toggleCollapseGroup,
        resetReducer,
        reducer(hoveredComponentChanged, handleHoveredComponentChanged),
        reducer(focusedComponentChanged, handleFocusedComponentChanged),
        reducer(bypassLimitAction, handleBypassLimit),
      ])
    );
  };
