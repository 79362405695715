import { ArdoqId, APISurveyAttributes } from '@ardoq/api-types';
import surveys$ from 'streams/surveys/surveys$';

const getSurveyAttributes = (
  surveyId?: ArdoqId | null
): APISurveyAttributes | null => {
  const { byId } = surveys$.state;
  if (!surveyId || !byId) {
    return null;
  }
  return byId[surveyId] || null;
};

const getName = (surveyId: ArdoqId | null) => {
  if (!surveyId) return '';
  const survey = getSurveyAttributes(surveyId);
  return survey ? survey.name : '';
};

const getWorkspace = (surveyId: ArdoqId | null) => {
  if (!surveyId) return '';
  const survey = getSurveyAttributes(surveyId);
  return survey ? survey.workspace : '';
};

const getComponentTypeName = (surveyId: ArdoqId | null) => {
  if (!surveyId) return '';
  const survey = getSurveyAttributes(surveyId);
  return survey ? survey.componentTypeName : '';
};

const getSurveysByWorkspaceId = (workspaceId: ArdoqId | null) => {
  if (!workspaceId) return [];
  const { list } = surveys$.state;
  return list ? list.filter(survey => survey.workspace === workspaceId) : [];
};

export const surveyInterface = {
  getName,
  getWorkspace,
  getComponentTypeName,
  getSurveyAttributes,
  getSurveysByWorkspaceId,
};
