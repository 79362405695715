import { ARDOQ_DEFAULT_FONT_FAMILY, text2 } from '@ardoq/typography';
import styled from 'styled-components';
import { NumberInput } from '@ardoq/forms';
import { IconSize } from '@ardoq/icons';
import { ComponentRepresentation } from '@ardoq/renderers';
import { LayoutRuleRowLabelProps, LayoutRuleRowProps } from './types';
import { LayoutRuleRowContainer } from './styles';
import { debounce } from 'lodash';
import { createFifoCache } from '@ardoq/common-helpers';
import SelfTruncatingComponentLabel from 'tabview/SelfTruncatingComponentLabel';
import { MeasureStyledText } from '@ardoq/dom-utils';

const RuleRowLabel = styled.div`
  ${text2}
  white-space: nowrap;
  overflow: hidden;
  width: 172px;
`;
const RepresentationDataContainer = styled.div`
  width: 16px;
  height: 16px;
`;
const RuleRowLabelContainer = styled.div`
  padding: 8px;
  display: flex;
  gap: 4px;
  height: 36px;
  width: 208px;
  border-radius: 4px;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
`;

const measureLabelText = createFifoCache(1000, (text: string) =>
  MeasureStyledText.Instance.getTextWidth({
    text,
    fontSize: '14px',
    fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
    fontWeight: '400',
  })
);

const LayoutRuleRowLabel = ({
  ruleRowLabel,
  ruleRowSublabel,
  representationData,
  layoutRuleRowLabelColor,
  layoutRuleRowLabelBackgroundColor,
}: LayoutRuleRowLabelProps) => {
  return (
    <RuleRowLabelContainer
      style={{
        backgroundColor: layoutRuleRowLabelBackgroundColor,
        border: `1px solid ${layoutRuleRowLabelColor}`,
      }}
    >
      <RepresentationDataContainer style={{ color: layoutRuleRowLabelColor }}>
        {representationData ? (
          <ComponentRepresentation
            iconSize={IconSize.SMALL}
            {...representationData}
          />
        ) : null}
      </RepresentationDataContainer>
      <RuleRowLabel>
        <SelfTruncatingComponentLabel
          label={ruleRowLabel}
          measure={measureLabelText}
          fieldLabel={null}
          fieldValue={ruleRowSublabel || null}
          isWithPopover={true}
        />
      </RuleRowLabel>
    </RuleRowLabelContainer>
  );
};

const LayoutRuleRow = ({
  ruleRowLabel,
  ruleRowSublabel,
  rowRule,
  representationData,
  layoutRuleRowLabelColor,
  layoutRuleRowLabelBackgroundColor,
  setLayoutRule,
}: LayoutRuleRowProps) => {
  // Thinking forward, this is a good place to compose layout constraints
  // according to the row type, that will be supplied as a prop.
  // Right now, there is only one type of rule - component types to rows

  if (!rowRule) {
    return null;
  }

  const onRowValueChange = debounce((inputValue: number) => {
    if (isNaN(Number(inputValue)) || inputValue < 0) {
      return;
    }

    setLayoutRule({ ...rowRule, order: inputValue });
  }, 750);

  return (
    <LayoutRuleRowContainer>
      <LayoutRuleRowLabel
        ruleRowLabel={ruleRowLabel}
        ruleRowSublabel={ruleRowSublabel}
        representationData={representationData}
        layoutRuleRowLabelColor={layoutRuleRowLabelColor}
        layoutRuleRowLabelBackgroundColor={layoutRuleRowLabelBackgroundColor}
      />
      <NumberInput
        onValueChange={onRowValueChange}
        min={0}
        value={rowRule.order || undefined}
      />
    </LayoutRuleRowContainer>
  );
};

export default LayoutRuleRow;
