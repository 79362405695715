// ^ Disable typechecking here because it's code taken from yfiles
import {
  BridgeManager,
  Class,
  DefaultGraph,
  Exception,
  GraphComponent,
  GraphModelManager,
  GraphObstacleProvider,
  HtmlCanvasVisual,
  IBoundsProvider,
  ICanvasObjectDescriptor,
  IContextLookupChainLink,
  IEdge,
  IEdgeHitTester,
  IHitTester,
  ILabel,
  ILabelHitTester,
  ILabelOwnerHitTester,
  IModelItem,
  INode,
  INodeHitTester,
  IPort,
  IPortHitTester,
  IVisibilityTestable,
  IVisualCreator,
  List,
  Matrix,
  Point,
  Rect,
  ShapeNodeShape,
  Size,
  SvgExport,
  SvgVisual,
  VoidEdgeStyle,
  VoidLabelStyle,
  VoidNodeStyle,
  VoidPortStyle,
  WebGLPolylineEdgeStyle,
  WebGLShapeNodeStyle,
  WebGLVisual,
  delegate,
} from '@ardoq/yfiles';
import * as typeCheck from 'utils/typeCheck';
import { createSvgElement } from '@ardoq/dom-utils';

/**
 * @typedef {import('graph/GraphItem').GraphItem} GraphItem
 */

export class FastGraphModelManager extends GraphModelManager {
  /** @private */
  $dirty = false;
  /**
   * Creates a new instance of this class.
   * @param {CanvasComponent} canvas The {@link CanvasComponent} that uses this instance.
   * @param {ICanvasObjectGroup} contentGroup The content group in which to render the graph items.
   */
  constructor(canvas, contentGroup) {
    super(canvas, contentGroup);
    this.initFastGraphModelManager();
    if (!(canvas instanceof GraphComponent)) {
      throw new Exception('Canvas must be a GraphComponent');
    }
    this.$graphComponent = canvas;
    this.$contentGroup = contentGroup;

    // set the graph for the component view
    this.graph = this.$graphComponent.graph;

    // create the custom descriptors for graph items
    this.fastNodeDescriptor = new AutoSwitchDescriptor(
      GraphModelManager.DEFAULT_NODE_DESCRIPTOR,
      this
    );
    this.fastEdgeDescriptor = new AutoSwitchDescriptor(
      GraphModelManager.DEFAULT_EDGE_DESCRIPTOR,
      this
    );
    this.fastLabelDescriptor = new AutoSwitchDescriptor(
      GraphModelManager.DEFAULT_LABEL_DESCRIPTOR,
      this
    );
    this.fastPortDescriptor = new AutoSwitchDescriptor(
      GraphModelManager.DEFAULT_PORT_DESCRIPTOR,
      this
    );

    this.nodeDescriptor = this.fastNodeDescriptor;
    this.edgeDescriptor = this.fastEdgeDescriptor;
    this.nodeLabelDescriptor = this.fastLabelDescriptor;
    this.edgeLabelDescriptor = this.fastLabelDescriptor;
    this.portDescriptor = this.fastPortDescriptor;

    // initialize the overview styles with default values
    this.$overviewNodeStyle =
      this.graph.nodeDefaults.style || VoidNodeStyle.INSTANCE;
    this.$overviewEdgeStyle =
      this.graph.edgeDefaults.style || VoidEdgeStyle.INSTANCE;
    this.$overviewLabelStyle = VoidLabelStyle.INSTANCE;
    this.$overviewPortStyle = VoidPortStyle.INSTANCE;

    // set default values
    this.$zoomThreshold = 5;
    this.$refreshImageZoomFactor = 0.5;
    this.$imageSizeFactor = 2;
    this.$maximumCanvasSize = new Size(3000, 2000);

    this.$drawNodeCallback = null;
    this.$drawEdgeCallback = null;
    this.$drawNodeLabelCallback = null;
    this.$drawEdgeLabelCallback = null;

    // create the image renderer
    this.$imageRendererCanvasObject = null;
    this.imageRenderer = new ImageGraphRenderer(this);
    if (this.$graphComponent.graph !== null) {
      // install the image renderer, if necessary
      this.updateImageRenderer();
    }

    // register to graphComponent events that could trigger a visualization change
    this.$graphComponent.addZoomChangedListener(
      delegate(this.onGraphComponentZoomChanged, this)
    );
    this.$graphComponent.addGraphChangedListener(
      delegate(this.onGraphComponentGraphChanged, this)
    );

    // add a chain link to the graphComponent's lookup that customizes item hit test
    this.initializeCustomHitTest();
  }

  /**
   * Gets or sets the optimization mode used to render the graph
   * if the zoom level is below {@link FastGraphModelManager#zoomThreshold}.
   */
  get graphOptimizationMode() {
    return this.$graphOptimizationMode;
  }
  set graphOptimizationMode(value) {
    this.$graphOptimizationMode = value;
    this.updateImageRenderer();
    this.updateGraph();
  }

  /**
   * Gets the GraphComponent.
   * @return {GraphComponent}
   */
  get graphComponent() {
    return this.$graphComponent;
  }

  /**
   * @private
   */
  get imageRendererCanvasObject() {
    return this.$imageRendererCanvasObject;
  }
  set imageRendererCanvasObject(value) {
    this.$imageRendererCanvasObject = value;
  }

  /**
   * Sets and gets the overview node style demo.
   * @param value
   */
  get overviewNodeStyle() {
    return this.$overviewNodeStyle;
  }
  set overviewNodeStyle(value) {
    this.$overviewNodeStyle = value;
  }

  /**
   * Sets and gets the overview edge style demo.
   * @param value
   */
  get overviewEdgeStyle() {
    return this.$overviewEdgeStyle;
  }
  set overviewEdgeStyle(value) {
    this.$overviewEdgeStyle = value;
  }

  /**
   * Sets and gets the overview label style demo.
   * @param value
   */
  get overviewLabelStyle() {
    return this.$overviewLabelStyle;
  }
  set overviewLabelStyle(value) {
    this.$overviewLabelStyle = value;
  }

  /**
   * Sets and gets the overview port style demo.
   * @param value
   */
  get overviewPortStyle() {
    return this.$overviewPortStyle;
  }
  set overviewPortStyle(value) {
    this.$overviewPortStyle = value;
  }

  /**
   * The threshold below which the rendering is switched from
   * default rendering to the optimized overview variant.
   */
  get zoomThreshold() {
    return this.$zoomThreshold;
  }
  set zoomThreshold(value) {
    this.$zoomThreshold = value;
  }

  /**
   * The factor by which the zoom factor has to change in order
   * to refresh the pre-rendered canvas image, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}
   * or {@link OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES}.
   */
  get refreshImageZoomFactor() {
    return this.$refreshImageZoomFactor;
  }
  set refreshImageZoomFactor(value) {
    this.$refreshImageZoomFactor = value;
  }

  /**
   * The factor that is used to calculate the size of
   * the pre-rendered image, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}
   * or {@link OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES}.
   */
  get imageSizeFactor() {
    return this.$imageSizeFactor;
  }
  set imageSizeFactor(value) {
    this.$imageSizeFactor = value;
  }

  /**
   * Gets or sets the maximum size of the canvas if
   * {@link FastGraphModelManager#graphOptimizationMode} is set to
   * {@link OptimizationMode.STATIC_CANVAS}. The
   * default is 3000, 2000.
   * <p>
   * Graphs that exceed this size result in a scaled down, lower quality
   * graph rendering.
   * </p>
   * <p>
   * Please note that setting this to a very high value may lead to browser freezes.
   * </p>
   */

  get maximumCanvasSize() {
    return this.$maximumCanvasSize;
  }
  set maximumCanvasSize(value) {
    this.$maximumCanvasSize = value;
  }

  /**
   * The callback to draw a node, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}.
   * <p>
   * The node must be drawn in the world coordinate system.
   * </p>
   * <p>If this callback is null, fallback code is used to draw the node.</p>
   */
  get drawNodeCallback() {
    return this.$drawNodeCallback;
  }
  set drawNodeCallback(value) {
    this.$drawNodeCallback = value;
  }

  /**
   * The callback to draw an edge, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}.
   * <p>
   * The edge must be drawn in the world coordinate system.
   * </p>
   * <p>If this callback is null, fallback code is used to draw the edge.</p>
   */
  get drawEdgeCallback() {
    return this.$drawEdgeCallback;
  }
  set drawEdgeCallback(value) {
    this.$drawEdgeCallback = value;
  }

  /**
   * The callback to draw a node label, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}.
   * The label must be drawn in the world coordinate system.
   */
  get drawNodeLabelCallback() {
    return this.$drawNodeLabelCallback;
  }
  set drawNodeLabelCallback(value) {
    this.$drawNodeLabelCallback = value;
  }

  /**
   * The callback to draw an edge label, if {@link FastGraphModelManager#graphOptimizationMode}
   * is set to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}.
   * The label must be drawn in the world coordinate system.
   * @type {function(ILabel, Object)}
   */
  get drawEdgeLabelCallback() {
    return this.$drawEdgeLabelCallback;
  }
  set drawEdgeLabelCallback(value) {
    this.$drawEdgeLabelCallback = value;
  }

  /**
   * Re-creates the graph visualization the next time the {@link FastGraphModelManager#graphComponent} is rendered.
   * @see {@link CanvasComponent#invalidate}
   */
  get dirty() {
    return this.$dirty;
  }
  set dirty(value) {
    this.$dirty = value;
  }

  /**
   * Whether to use image or default rendering.
   * @return {boolean}
   * true, if the image renderer should be used to render the graph.
   * False if the default rendering code should be used.
   */
  shouldUseImage() {
    return (
      this.graphComponent.zoom <= this.zoomThreshold &&
      (this.graphOptimizationMode === OptimizationMode.STATIC_CANVAS ||
        this.graphOptimizationMode === OptimizationMode.SVG_IMAGE ||
        this.graphOptimizationMode ===
          OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES ||
        this.graphOptimizationMode ===
          OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK ||
        this.graphOptimizationMode === OptimizationMode.WEBGL)
    );
  }

  /**
   * Sets either an empty graph or {@link GraphComponent#graph this.graphComponent.graph}
   * as the  to display in this instance.
   * <p>If {@link FastGraphModelManager#shouldUseImage} is true, an empty graph instance is assigned.</p>
   * <p>
   * This is an optimization to disable expensive graph traversal code if only a static image is rendered.
   * </p>
   * @private
   */
  updateGraph() {
    const shouldUseImage = this.shouldUseImage();
    if (shouldUseImage && this.graph === this.graphComponent.graph) {
      this.graph = new DefaultGraph();
    } else if (!shouldUseImage && this.graph !== this.graphComponent.graph) {
      this.graph = this.graphComponent.graph;
    }
  }

  /**
   * Installs the image renderer in the graphComponent if needed, or removes
   * it if not needed.
   * @private
   */
  updateImageRenderer() {
    const imageRendererNeeded = this.shouldUseImage();
    if (imageRendererNeeded && this.imageRendererCanvasObject === null) {
      // add image renderer to graphComponent
      this.imageRendererCanvasObject = this.$contentGroup.addChild(
        this.imageRenderer,
        ICanvasObjectDescriptor.ALWAYS_DIRTY_INSTANCE
      );
    } else if (
      !imageRendererNeeded &&
      this.imageRendererCanvasObject !== null
    ) {
      // remove image renderer from graphComponent
      this.imageRendererCanvasObject.remove();
      this.imageRendererCanvasObject = null;
    }
  }

  /**
   * Called when the {@link FastGraphModelManager#graphComponent}'s zoom factor changes.
   * @private
   */
  onGraphComponentZoomChanged() {
    this.updateImageRenderer();
    this.updateGraph();
  }

  /**
   * Called when the {@link FastGraphModelManager#graphComponent}'s graph instance changes.
   * @private
   */
  onGraphComponentGraphChanged() {
    this.updateImageRenderer();
  }

  /**
   * Decorates the lookup chain of the {@link FastGraphModelManager#graphComponent}s
   * {@link CanvasComponent#inputModeContextLookupChain}
   * with a chain link that provides {@link IHitTester}s for
   * the graph items.
   * This is needed to make hit test work when an empty graph is
   * assigned to this instance for optimization.
   * @private
   */
  initializeCustomHitTest() {
    this.graphComponent.inputModeContextLookupChain.add(
      new HitTestInputChainLink(this.graphComponent)
    );
  }

  /** @private */
  initFastGraphModelManager() {
    this.$graphOptimizationMode = OptimizationMode.DEFAULT;
  }
}

/**
 * The optimization used to render the graph.
 * <p>
 * Generally, the optimization modes can be categorized in two groups:
 * {@link OptimizationMode.DEFAULT}, {@link OptimizationMode.STATIC} and {@link OptimizationMode.LEVEL_OF_DETAIL}
 * render each graph item separately using graph item styles. {@link OptimizationMode.SVG_IMAGE},
 * {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}, {@link OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES}
 * and {@link OptimizationMode.STATIC_CANVAS} prepare a single canvas drawing that either contains the
 * complete graph, or the visible part of the graph. This static canvas image is rendered
 * as a whole, thus eliminating the need to render each item separately.
 * </p>
 * <p>
 * Each optimization mode has different advantages and disadvantages. The concrete
 * use-cases for each option depend on various factors, like interaction, graph size
 * and visual complexity. Every optimization comes at a cost that makes this strategy
 * unsuitable for general use. Please read the descriptions of the separate optimization
 * modes for detailed information.
 * </p>
 * @class OptimizationMode
 */
export class OptimizationMode {
  /**
   * Uses the default rendering code that delegates the rendering to the item styles.
   */
  static get DEFAULT() {
    return 0;
  }

  /**
   * With this option, the graph visualization is created using the default rendering code.
   * However, subsequent updates are ignored. This makes panning very fast. However,
   * structural changes to the graph, e.g. moving nodes or changing item styles do not result
   * in an updated visualization, but are ignored.
   * This option is best suited for viewer only applications with not too big graphs
   * that rely on the actual item style.
   */
  static get STATIC() {
    return 1;
  }

  /**
   * This option uses a level-of-detail approach to render graph items. In the overview level,
   * items are rendered with alternative styles. This can be used to assign simpler item styles
   * that improve the performance in overview level.
   * <p>
   * With this approach, the rendering still makes use of virtualization, i.e. visualizations are
   * removed if the item leaves the viewport and re-created if it enters the viewport again.
   * </p>
   * <p>
   * This approach can be applied for dynamic editor scenarios where the graph structure or
   * the item visualizations have to be changed often.
   * </p>
   * @see {@link FastGraphModelManager#overviewNodeStyle}
   * @see {@link FastGraphModelManager#overviewEdgeStyle}
   * @see {@link FastGraphModelManager#overviewLabelStyle}
   * @see {@link FastGraphModelManager#overviewPortStyle}
   */
  static get LEVEL_OF_DETAIL() {
    return 2;
  }

  /**
   * This option creates a static SVG image of the graph at zoom level 1 and uses this image
   * instead of calling the render code of the actual item styles.
   * <p>
   * In contrast to {@link OptimizationMode.STATIC}, this approach does not use virtualization,
   * i.e. the complete graph is part of the DOM at all times.
   * </p>
   * <p>
   * This approach is best suited for static scenarios, e.g. viewer only applications with not too
   * big graphs.
   * </p>
   */
  static get SVG_IMAGE() {
    return 3;
  }

  /**
   * This option creates a static image of the currently visible part of the graph, using HTML canvas.
   * <p>
   * The size of the pre-rendered image can be specified relative to the viewport size
   * using {@link FastGraphModelManager#imageSizeFactor}.
   * The image is re-created for quality if the bounds of the image are reached through panning,
   * or if the zoom level changes by a factor greater than {@link FastGraphModelManager#refreshImageZoomFactor}.
   * </p>
   * <p>
   * The items in the graph are drawn using {@link FastGraphModelManager#drawNodeCallback},
   * {@link FastGraphModelManager#drawEdgeCallback} etc.
   * </p>
   * <p>
   * This option is suited for scenarios with a large graph where the zoom level changes frequently.
   * Since the items are drawn with a (simple) canvas paint callback, the visualization can
   * be created very quickly, while still remaining a high-quality image. However, the item visualizations
   * are simplified compared to the actual item styles. A short freeze can occur when the
   * image is re-created.
   * </p>
   * @see {@link FastGraphModelManager#imageSizeFactor}
   * @see {@link FastGraphModelManager#refreshImageZoomFactor}
   * @see {@link FastGraphModelManager#drawNodeCallback}
   * @see {@link FastGraphModelManager#drawEdgeCallback}
   * @see {@link FastGraphModelManager#drawNodeLabelCallback}
   * @see {@link FastGraphModelManager#drawEdgeLabelCallback}
   */
  static get DYNAMIC_CANVAS_WITH_DRAW_CALLBACK() {
    return 4;
  }

  /**
   * This option creates a static image of the currently visible part of the graph, using HTML canvas.
   * The items are drawn using the item styles.
   * <p>
   * The size of the pre-rendered image can be specified relative to the viewport size
   * using {@link FastGraphModelManager#imageSizeFactor}.
   * The image is re-created for better quality if the bounds of the image are reached through panning,
   * or if the zoom level changes by a factor greater than {@link FastGraphModelManager#refreshImageZoomFactor}.
   * </p>
   * <p>
   * This option is suited for scenarios with a large graph where the zoom level changes frequently.
   * In contrast to {@link OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK}, the actual item styles are used
   * to draw the graph. Based on the style, this can be quite expensive and thus can result in
   * worse image creation performance. Thus, the hiccups during zooming and panning can be longer.
   * </p>
   * @see {@link FastGraphModelManager#imageSizeFactor}
   * @see {@link FastGraphModelManager#refreshImageZoomFactor}
   * @see {@link FastGraphModelManager#drawNodeCallback}
   * @see {@link FastGraphModelManager#drawEdgeCallback}
   * @see {@link FastGraphModelManager#drawNodeLabelCallback}
   * @see {@link FastGraphModelManager#drawEdgeLabelCallback}
   */
  static get DYNAMIC_CANVAS_WITH_ITEM_STYLES() {
    return 5;
  }

  /**
   * This option exports a HTML5 canvas image of the complete graph and draws it into the graphComponent.
   * The item styles are used to draw the image.
   * Since the image is not dynamically re-created if the zoom level changes, this option produces bad results
   * for higher zoom levels. It is only suitable for scenarios where the graph is drawn with very low zoom levels,
   * e.g. in a graph overview.
   */
  static get STATIC_CANVAS() {
    return 6;
  }

  static get WEBGL() {
    return 7;
  }
}

/**
 * An {@link ICanvasObjectDescriptor} implementation
 * that switches between default and optimized rendering.
 * @class AutoSwitchDescriptor
 * @augments ICanvasObjectDescriptor
 * @augments IVisualCreator
 * @private
 *
 */
class AutoSwitchDescriptor extends Class(
  ICanvasObjectDescriptor,
  IVisualCreator
) {
  /**
   * Creates a new instance of AutoSwitchDescriptor.
   * @param {ICanvasObjectDescriptor} backingInstance The ICanvasObjectDescriptor implementation
   * @param {FastGraphModelManager} outer The descriptor to be installed.
   */
  constructor(backingInstance, outer) {
    super();
    this.backingInstance = backingInstance;
    this.outer = outer;
    this.$item = null;
    this.$forceRepaint = false;
  }

  /**
   *
   * @private
   */
  get forceRepaint() {
    return this.$forceRepaint;
  }

  /**
   *
   * @private
   */
  set forceRepaint(value) {
    this.$forceRepaint = value;
  }

  /**
   * @type {INodeStyle}
   * @private
   */
  get overviewNodeStyle() {
    return this.outer.overviewNodeStyle;
  }

  /**
   * @type {IEdgeStyle}
   * @private
   */
  get overviewEdgeStyle() {
    return this.outer.overviewEdgeStyle;
  }

  /**
   * @type {ILabelStyle}
   * @private
   */
  get overviewLabelStyle() {
    return this.outer.overviewLabelStyle;
  }

  /**
   * @type {IPortStyle}
   * @private
   */
  get overviewPortStyle() {
    return this.outer.overviewPortStyle;
  }

  /** @return {IVisualCreator} */
  getVisualCreator(forUserObject) {
    this.item = IModelItem.isInstance(forUserObject) ? forUserObject : null;
    return this;
  }

  /** @return {boolean} */
  isDirty(context, canvasObject) {
    return this.outer.shouldUseImage()
      ? false
      : this.backingInstance.isDirty(context, canvasObject);
  }

  /** @return {IBoundsProvider} */
  getBoundsProvider(forUserObject) {
    return this.backingInstance.getBoundsProvider(forUserObject);
  }

  /** @return {IVisibilityTestable} */
  getVisibilityTestable(forUserObject) {
    return this.outer.shouldUseImage()
      ? IVisibilityTestable.NEVER
      : this.backingInstance.getVisibilityTestable(forUserObject);
  }

  /** @return {IHitTestable} */
  getHitTestable(forUserObject) {
    return this.backingInstance.getHitTestable(forUserObject);
  }

  /**
   * Creates the new visual.
   * @param {IRenderContext} context The context to be used
   * @return {Visual}
   */
  createVisual(context) {
    let /** Visual*/ visual = null;
    if (
      context.zoom >= this.outer.zoomThreshold ||
      this.outer.graphOptimizationMode === OptimizationMode.DEFAULT ||
      this.outer.graphOptimizationMode === OptimizationMode.STATIC
    ) {
      // fall back to backing instance
      const visualCreator = this.item.style.renderer.getVisualCreator(
        this.item,
        this.item.style
      );
      visual = visualCreator.createVisual(context);
      if (visual !== null) {
        // save the visual creator with which the visual was created for later update
        visual['data-gmm-cache'] = visualCreator;
      }
    } else if (
      this.outer.graphOptimizationMode === OptimizationMode.LEVEL_OF_DETAIL
    ) {
      // delegate rendering to overview styles
      let /** IVisualCreator*/ visualCreator = null;
      if (INode.isInstance(this.item)) {
        visualCreator = this.overviewNodeStyle.renderer.getVisualCreator(
          this.item,
          this.overviewNodeStyle
        );
      } else if (IEdge.isInstance(this.item)) {
        visualCreator = this.overviewEdgeStyle.renderer.getVisualCreator(
          this.item,
          this.overviewEdgeStyle
        );
      } else if (ILabel.isInstance(this.item)) {
        visualCreator = this.overviewLabelStyle.renderer.getVisualCreator(
          this.item,
          this.overviewLabelStyle
        );
      } else if (IPort.isInstance(this.item)) {
        visualCreator = this.overviewPortStyle.renderer.getVisualCreator(
          this.item,
          this.overviewPortStyle
        );
      }
      if (visualCreator !== null) {
        visual = visualCreator.createVisual(context);
        if (visual !== null) {
          // save the visual creator with which the visual was created for later update
          visual['data-gmm-cache'] = visualCreator;
        }
      }
    }
    return visual;
  }

  /**
   * Updates the current visual.
   * @param {IRenderContext} context The context to be used
   * @param {Visual} oldVisual The last visual that was returned
   * @return {Visual}
   */
  updateVisual(context, oldVisual) {
    if (oldVisual === null || this.forceRepaint) {
      return this.createVisual(context);
    }
    const oldVisualCreator = oldVisual['data-gmm-cache'];
    let /** Visual*/ visual = null;
    if (
      context.zoom >= this.outer.zoomThreshold ||
      this.outer.graphOptimizationMode === OptimizationMode.DEFAULT ||
      this.outer.graphOptimizationMode === OptimizationMode.STATIC
    ) {
      const visualCreator = this.item.style.renderer.getVisualCreator(
        this.item,
        this.item.style
      );
      if (oldVisualCreator !== visualCreator) {
        // the visual creator changed - re-create the visual
        visual = visualCreator.createVisual(context);
      } else if (this.outer.graphOptimizationMode === OptimizationMode.STATIC) {
        // static mode - return oldVisual
        visual = oldVisual;
      } else {
        this.outer.dirty = false;
        visual = visualCreator.updateVisual(context, oldVisual);
      }
      if (visual !== null) {
        visual['data-gmm-cache'] = visualCreator;
      }
    } else if (
      this.outer.graphOptimizationMode === OptimizationMode.LEVEL_OF_DETAIL
    ) {
      // delegate rendering to overview styles
      let /** IVisualCreator*/ visualCreator = null;
      if (INode.isInstance(this.item)) {
        visualCreator = this.overviewNodeStyle.renderer.getVisualCreator(
          this.item,
          this.overviewNodeStyle
        );
      } else if (IEdge.isInstance(this.item)) {
        visualCreator = this.overviewEdgeStyle.renderer.getVisualCreator(
          this.item,
          this.overviewEdgeStyle
        );
      } else if (ILabel.isInstance(this.item)) {
        visualCreator = this.overviewLabelStyle.renderer.getVisualCreator(
          this.item,
          this.overviewLabelStyle
        );
      } else if (IPort.isInstance(this.item)) {
        visualCreator = this.overviewPortStyle.renderer.getVisualCreator(
          this.item,
          this.overviewPortStyle
        );
      }
      if (visualCreator !== null) {
        if (oldVisualCreator === visualCreator) {
          visual = visualCreator.updateVisual(context, oldVisual);
        } else {
          visual = visualCreator.createVisual(context);
        }
        if (visual !== null) {
          visual['data-gmm-cache'] = visualCreator;
        }
      }
    }
    return visual;
  }
}
/** @param {GraphComponent} graphComponent */
const configureBridges = graphComponent => {
  const bridgeManager = new BridgeManager();
  const provider = new GraphObstacleProvider();
  bridgeManager.addObstacleProvider(provider);
  bridgeManager.canvasComponent = graphComponent;
};

/**
 * Draws a pre-rendered image of the graph in canvas.
 * @class ImageGraphRenderer
 * @augments IVisualCreator
 * @augments IBoundsProvider
 * @private
 */
class ImageGraphRenderer extends Class(IVisualCreator, IBoundsProvider) {
  /**
   * Creates a new instance of ImageGraphRenderer.
   * @param {FastGraphModelManager} outer The descriptor to be installed.
   */
  constructor(outer) {
    super();
    this.outer = outer;
    this.exportGraphComponent = new GraphComponent();
  }

  /**
   * Gets the graphComponent's graph.
   * @type {IGraph}
   * @private
   */
  get graph() {
    return this.outer.graphComponent.graph;
  }

  /**
   * Creates the new visual.
   * @param {IRenderContext} context The context to be used
   * @return {Visual}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createVisual(context) {
    this.outer.dirty = false;
    let visual = null;
    const optimizationMode = this.outer.graphOptimizationMode;
    switch (optimizationMode) {
      case OptimizationMode.SVG_IMAGE:
        visual = this.createStaticSvgVisual();
        break;
      case OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK:
        visual = this.createSimpleDynamicCanvasVisual();
        break;
      case OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES:
        visual = this.createComplexDynamicCanvasVisual();
        break;
      case OptimizationMode.STATIC_CANVAS:
        visual = this.createCompleteGraphCanvasVisual();
        break;
      case OptimizationMode.WEBGL:
        visual = this.createCompleteGraphWebglVisual();
        break;
      default:
        visual = this.createStaticSvgVisual();
        break;
    }
    if (visual !== null) {
      visual['data-GraphOptimizationMode'] = optimizationMode;
    }
    return visual;
  }

  /**
   * Updates the current visual.
   * @param {IRenderContext} context The context to be used
   * @param {Visual} oldVisual The last visual that was returned
   * @return {Visual}
   */
  updateVisual(context, oldVisual) {
    const oldMode = oldVisual['data-GraphOptimizationMode'];
    const optimizationMode = this.outer.graphOptimizationMode;
    // check if visual needs to be re-created
    if (
      this.outer.dirty ||
      oldVisual === null ||
      optimizationMode !== oldMode
    ) {
      return this.createVisual(context);
    }
    if (
      optimizationMode === OptimizationMode.DYNAMIC_CANVAS_WITH_DRAW_CALLBACK &&
      oldVisual.needsUpdate(
        context.zoom,
        context.canvasComponent.viewport,
        context.canvasComponent.contentRect
      )
    ) {
      // update simple dynamic canvas if necessary
      this.updateSimpleDynamicCanvasVisual(oldVisual);
      return oldVisual;
    }
    if (
      optimizationMode === OptimizationMode.DYNAMIC_CANVAS_WITH_ITEM_STYLES &&
      oldVisual.needsUpdate(
        context.zoom,
        context.canvasComponent.viewport,
        context.canvasComponent.contentRect
      )
    ) {
      // update complex dynamic canvas if necessary
      this.updateComplexDynamicCanvasVisual(oldVisual);
      return oldVisual;
    }
    return oldVisual;
  }

  /**
   * Custom bounds calculation for image object.
   * This is necessary because the default bounds calculation is
   * disabled by assigning an empty graph.
   * @see Specified by {@link IBoundsProvider#getBounds}.
   * @return {Rect}
   */
  getBounds(context) {
    const graph = this.graph;
    if (graph !== null) {
      let bounds = Rect.EMPTY;
      graph.nodes.forEach(node => {
        bounds = Rect.add(
          bounds,
          node.style.renderer
            .getBoundsProvider(node, node.style)
            .getBounds(context)
        );
      });
      graph.edges.forEach(edge => {
        bounds = Rect.add(
          bounds,
          edge.style.renderer
            .getBoundsProvider(edge, edge.style)
            .getBounds(context)
        );
      });
      graph.nodeLabels.forEach(label => {
        bounds = Rect.add(
          bounds,
          label.style.renderer
            .getBoundsProvider(label, label.style)
            .getBounds(context)
        );
      });
      graph.edgeLabels.forEach(label => {
        bounds = Rect.add(
          bounds,
          label.style.renderer
            .getBoundsProvider(label, label.style)
            .getBounds(context)
        );
      });
      graph.ports.forEach(port => {
        bounds = Rect.add(
          bounds,
          port.style.renderer
            .getBoundsProvider(port, port.style)
            .getBounds(context)
        );
      });
      return bounds.clone();
    }
    return Rect.EMPTY;
  }

  /**
   * Creates a visual that renders a static SVG image of the graph.
   * @return {Visual}
   * @private
   */
  createStaticSvgVisual() {
    const contentRect = this.outer.graphComponent.contentRect;
    const scale = this.getSuitableScale(this.outer.graphComponent.contentRect);
    const exportRect =
      contentRect.width > 0 && contentRect.height > 0
        ? contentRect.clone()
        : new Rect(0, 0, 0, 0);
    const svg = this.exportRectToSvg(exportRect, scale);
    const g = createSvgElement('g');
    new Matrix(
      1 / scale,
      0,
      0,
      1 / scale,
      contentRect.x,
      contentRect.y
    ).applyTo(g);
    g.appendChild(svg);
    return new SvgVisual(g);
  }

  /**
   * Creates a visual that renders the currently visible part of the graph into a canvas
   * using draw callbacks.
   * @return {Visual}
   * @private
   */
  createSimpleDynamicCanvasVisual() {
    const visual = new CanvasRenderVisual(
      this.outer.refreshImageZoomFactor,
      this.outer.graphComponent.zoom,
      this.outer.maximumCanvasSize
    );
    this.drawSimpleCanvasImage(visual);
    return visual;
  }

  /**
   * Updates the visual with the current viewport.
   * @private
   */
  updateSimpleDynamicCanvasVisual(visual) {
    this.drawSimpleCanvasImage(visual);
  }

  /**
   * Creates a Visual that renders the currently visible part of the graph into a canvas
   * using the actual item styles.
   * @return {CanvasRenderVisual}
   * @private
   */
  createComplexDynamicCanvasVisual() {
    const visual = new CanvasRenderVisual(
      this.outer.refreshImageZoomFactor,
      this.outer.graphComponent.zoom,
      this.outer.maximumCanvasSize
    );
    this.drawComplexCanvasImage(
      (image, targetWidth, targetHeight, exportRect) => {
        visual.update(
          image,
          targetWidth,
          targetHeight,
          exportRect.clone(),
          this.outer.graphComponent.zoom
        );
        this.outer.graphComponent.invalidate();
      }
    );
    return visual;
  }

  /**
   * Updates the visual with the current viewport.
   * @param {Visual} oldVisual The last visual that was returned.
   * @private
   */
  updateComplexDynamicCanvasVisual(oldVisual) {
    this.drawComplexCanvasImage((image, viewWidth, viewHeight, exportRect) => {
      oldVisual.update(
        image,
        viewWidth,
        viewHeight,
        exportRect.clone(),
        this.outer.graphComponent.zoom
      );
      this.outer.graphComponent.invalidate();
    });
  }

  /**
   * Creates a canvas image containing the complete graph.
   * @return {Visual}
   * @private
   */
  createCompleteGraphCanvasVisual() {
    const graphComponent = this.outer.graphComponent;
    const zoom = graphComponent.zoom;
    const exportRect = graphComponent.contentRect;

    const scale = this.getSuitableScale(graphComponent.contentRect);
    const svgElement = this.exportRectToSvg(exportRect.clone(), scale);
    const dataUrl = SvgExport.encodeSvgDataUrl(
      SvgExport.exportSvgString(svgElement)
    );

    const targetCanvasWidth = exportRect.width * scale;
    const targetCanvasHeight = exportRect.height * scale;

    const image = new Image();
    image.src = dataUrl;
    image.width = targetCanvasWidth;
    image.height = targetCanvasHeight;

    const visual = new CanvasRenderVisual(
      0,
      zoom,
      this.outer.maximumCanvasSize
    );

    image.onload = () => {
      visual.update(
        image,
        targetCanvasWidth,
        targetCanvasHeight,
        exportRect.clone(),
        graphComponent.zoom
      );
      this.outer.graphComponent.invalidate();
    };

    return visual;
  }
  createCompleteGraphWebglVisual() {
    return new GLVisual(this.outer.graphComponent.graph);
  }
  /**
   * Draws the graph into a canvas and updates the given visual with the newly created canvas.
   * @param {CanvasRenderVisual} visual The given visual
   * @private
   */
  drawSimpleCanvasImage(visual) {
    const graphComponent = this.outer.graphComponent;
    const viewport = graphComponent.viewport;
    const zoom = graphComponent.zoom;
    const factor = this.outer.imageSizeFactor;
    const contentRect = graphComponent.contentRect;

    // calculate the area to export in world coordinates
    let exportRect = new Rect(
      viewport.x - (factor - 1) * 0.5 * viewport.width,
      viewport.y - (factor - 1) * 0.5 * viewport.height,
      viewport.width * factor,
      viewport.height * factor
    );

    // export intersection of desired exportRect and contentRect
    exportRect = new Rect(
      new Point(
        Math.max(exportRect.x, contentRect.x),
        Math.max(exportRect.y, contentRect.y)
      ),
      new Point(
        Math.min(exportRect.bottomRight.x, contentRect.bottomRight.x),
        Math.min(exportRect.bottomRight.y, contentRect.bottomRight.y)
      )
    );

    // calculate the size of the target image in view coordinates
    const targetWidth = exportRect.width * zoom;
    const targetHeight = exportRect.height * zoom;

    // calculate the scale to draw the graph in world coordinates into the target image
    const scaleX = targetWidth / exportRect.width;
    const scaleY = targetHeight / exportRect.height;
    const scale = Math.min(scaleX, scaleY);

    // create the canvas element
    const canvas = window.document.createElement('canvas');

    // set the canvas size to the target size
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const canvasContext = canvas.getContext('2d');
    canvasContext.save();

    // set the scale and translate so we can draw in the world coordinate system
    canvasContext.scale(scale, scale);
    canvasContext.translate(-exportRect.x, -exportRect.y);

    // draw the graph items in world coordinates
    this.graph.edges.forEach(edge => {
      this.drawEdge(edge, canvasContext);
    });

    this.graph.nodes.forEach(node => {
      this.drawNode(node, canvasContext);
    });

    this.graph.nodeLabels.forEach(label => {
      this.drawNodeLabel(label, canvasContext);
    });

    this.graph.edgeLabels.forEach(label => {
      this.drawEdgeLabel(label, canvasContext);
    });

    canvasContext.restore();

    visual.update(canvas, targetWidth, targetHeight, exportRect, zoom);
  }

  /**
   * Draws a node using the given context.
   * @param {INode} node The node to be drawn
   * @param {ICanvasContext} canvasContext The given canvas context
   * @private
   */
  drawNode(node, canvasContext) {
    if (this.outer.drawNodeCallback !== null) {
      this.outer.drawNodeCallback(node, canvasContext);
    } else {
      canvasContext.fillStyle = '#aaaaaa';
      const layout = node.layout;
      canvasContext.fillRect(layout.x, layout.y, layout.width, layout.height);
    }
  }

  /**
   * Draws an edge using the given context.
   * @param {IEdge} edge The edge to be drawn
   * @param {ICanvasContext} canvasContext The given canvas context
   * @private
   */
  drawEdge(edge, canvasContext) {
    if (this.outer.drawEdgeCallback !== null) {
      this.outer.drawEdgeCallback(edge, canvasContext);
    } else {
      canvasContext.strokeStyle = '#000000';
      const sourceLocation =
        edge.sourcePort.locationParameter.model.getLocation(
          edge.sourcePort,
          edge.sourcePort.locationParameter
        );
      const targetLocation =
        edge.targetPort.locationParameter.model.getLocation(
          edge.targetPort,
          edge.targetPort.locationParameter
        );
      canvasContext.beginPath();
      canvasContext.moveTo(sourceLocation.x, sourceLocation.y);
      edge.bends.forEach(bend => {
        canvasContext.lineTo(bend.location.x, bend.location.y);
      });
      canvasContext.lineTo(targetLocation.x, targetLocation.y);
      canvasContext.stroke();
    }
  }

  /**
   * Draws a node label using the given context.
   * @param {ILabel} label The node label to be drawn
   * @param {ICanvasContext} canvasContext The given canvas context
   * @private
   */
  drawNodeLabel(label, canvasContext) {
    if (this.outer.drawNodeLabelCallback !== null) {
      this.outer.drawNodeLabelCallback(label, canvasContext);
    }
  }

  /**
   * Draws an edge label using the given context.
   * @param {ILabel} label The edge label to be drawn
   * @param {ICanvasContext} canvasContext The given canvas context
   * @private
   */
  drawEdgeLabel(label, canvasContext) {
    if (this.outer.drawEdgeLabelCallback !== null) {
      this.outer.drawEdgeLabelCallback(label, canvasContext);
    }
  }

  /**
   * Draws the graph into an image using the item styles. Calls the given callback if finished.
   * @param {function(Image, number, number, Rect)} callback The callback to call if the image has finished rendering.
   * @private
   */
  drawComplexCanvasImage(callback) {
    const graphComponent = this.outer.graphComponent;
    const viewport = graphComponent.viewport;
    const zoom = graphComponent.zoom;
    const factor = this.outer.imageSizeFactor;
    const contentRect = graphComponent.contentRect;

    // calculate the area to export in world coordinates
    let exportRect = new Rect(
      viewport.x - (factor - 1) * 0.5 * viewport.width,
      viewport.y - (factor - 1) * 0.5 * viewport.height,
      viewport.width * factor,
      viewport.height * factor
    );

    // export intersection of desired exportRect and contentRect
    exportRect = new Rect(
      new Point(
        Math.max(exportRect.x, contentRect.x),
        Math.max(exportRect.y, contentRect.y)
      ),
      new Point(
        Math.min(exportRect.bottomRight.x, contentRect.bottomRight.x),
        Math.min(exportRect.bottomRight.y, contentRect.bottomRight.y)
      )
    );

    // calculate the size of the target image in view coordinates
    const targetWidth = exportRect.width * zoom;
    const targetHeight = exportRect.height * zoom;

    // calculate the scale to draw the graph in world coordinates into the target image
    const scaleX = targetWidth / exportRect.width;
    const scaleY = targetHeight / exportRect.height;
    const scale = Math.min(scaleX, scaleY);

    // export the graph to svg
    const svgElement = this.exportRectToSvg(exportRect.clone(), scale);
    const dataUrl = SvgExport.encodeSvgDataUrl(
      SvgExport.exportSvgString(svgElement)
    );

    const image = new Image();
    image.src = dataUrl;
    image.width = targetWidth;
    image.height = targetHeight;

    // invoke the callback if the image is loaded
    image.onload = () => {
      callback(image, targetWidth, targetHeight, exportRect);
    };
  }

  /**
   * Exports the given area of the graphComponent into an SVG element with the given scale factor.
   * @param {Rect} exportRect The area to be exported
   * @param {number} scale The given scale factor
   * @return {Element}
   * @private
   */
  exportRectToSvg(exportRect, scale) {
    const exportComponent = this.exportGraphComponent;
    exportComponent.graph = this.graph;
    configureBridges(exportComponent);
    const exporter = new SvgExport(exportRect, scale);
    return exporter.exportSvg(exportComponent);
  }

  /**
   * Calculates a suitable scale for the given area, respecting {@link FastGraphModelManager#maximumCanvasSize}.
   * @param {Rect} exportRect The area to be exported
   * @see {@link ImageGraphRenderer#exportRectToSvg}
   * @return {number}
   * @private
   */
  getSuitableScale(exportRect) {
    const maxSize = this.outer.maximumCanvasSize;

    const scaleX =
      exportRect.width > maxSize.width ? maxSize.width / exportRect.width : 1;
    const scaleY =
      exportRect.height > maxSize.height
        ? maxSize.height / exportRect.height
        : 1;

    return Math.min(scaleX, scaleY);
  }
}

/**
 * @param {GraphItem} node
 * @param {HTMLDivElement} domTestNode
 */
const getColorFromNode = (node, domTestNode) => {
  domTestNode.setAttribute('class', node.getCSS());
  const style = window.getComputedStyle(domTestNode);
  const result =
    typeCheck.isComponent(node.dataModel) ||
    typeCheck.isWorkspace(node.dataModel)
      ? style.fill
      : style.stroke;
  return result;
};

/**
 * A render visual that draws a given canvas element in canvas.
 * @class CanvasRenderVisual
 * @augments HtmlCanvasVisual
 * @private
 */
class CanvasRenderVisual extends HtmlCanvasVisual {
  /**
   * Creates a new CanvasRenderVisual instance.
   * @param {number} refreshImageZoomFactor The factor by which the zoom factor has to change
   * @param {number} initialZoom The initial zoom factor
   * @param {Size} maxCanvasSize The maximum canvas size
   */
  constructor(refreshImageZoomFactor, initialZoom, maxCanvasSize) {
    super();
    this.$initialArea = new Rect(new Point(0, 0), new Size(0, 0));
    this.canvas = window.document.createElement('canvas');
    this.refreshImageZoomFactor = refreshImageZoomFactor;
    this.initialZoom = initialZoom;
    this.maxCanvasSize = maxCanvasSize.clone();
  }

  /**
   * The initial rectangular area.
   * @private
   */
  get initialArea() {
    return this.$initialArea;
  }
  set initialArea(value) {
    this.$initialArea = value;
  }

  /**
   * Paints onto the context using HTML5 Canvas operations.
   * @param {IRenderContext} context The context to paint on
   * @param {CanvasRenderingContext2D} htmlCanvasContext The given HtmlCanvasContext
   */
  paint(context, htmlCanvasContext) {
    htmlCanvasContext.drawImage(
      this.canvas,
      this.initialArea.topLeft.x,
      this.initialArea.topLeft.y,
      this.initialArea.width,
      this.initialArea.height
    );
  }

  /**
   * Returns whether the visual needs an update because the zoom level or the viewport has
   * changed beyond the thresholds defined in {@link FastGraphModelManager#refreshImageZoomFactor}
   * and {@link FastGraphModelManager#imageSizeFactor}.
   * @param {number} zoom The current zoom level.
   * @param {Rect} viewport The current viewport.
   * @param {Rect} contentRect
   * @return {boolean}
   */
  needsUpdate(zoom, viewport, contentRect) {
    if (
      zoom / this.initialZoom < this.refreshImageZoomFactor ||
      this.initialZoom / zoom < this.refreshImageZoomFactor
    ) {
      return true;
    }
    const x = Math.max(viewport.x, contentRect.x);
    const y = Math.max(viewport.y, contentRect.y);
    const brx = Math.min(viewport.bottomRight.x, contentRect.bottomRight.x);
    const bry = Math.min(viewport.bottomRight.y, contentRect.bottomRight.y);
    return (
      x < this.initialArea.x ||
      y < this.initialArea.y ||
      brx > this.initialArea.bottomRight.x ||
      bry > this.initialArea.bottomRight.y
    );
  }

  /**
   * Updates the visual with the new image data.
   * @param {Element} image The image or canvas to render in this visual.
   * @param {number} targetCanvasWidth The width of the canvas to render.
   * @param {number} targetCanvasHeight The height of the canvas to render
   * @param {Rect} initialArea The area in world coordinates the image should be rendered in.
   * @param {number} initialZoom The zoom value at the time of creation.
   */
  update(
    image,
    targetCanvasWidth,
    targetCanvasHeight,
    initialArea,
    initialZoom
  ) {
    // cast width and height to int because canvas.width and canvas.height do not support floating point numbers
    const w = Math.min(this.maxCanvasSize.width, targetCanvasWidth) | 0;
    const h = Math.min(this.maxCanvasSize.height, targetCanvasHeight) | 0;
    this.initialArea = initialArea;
    this.initialZoom = initialZoom;
    const canvasContext = this.canvas.getContext('2d');
    if (this.canvas.width !== w || this.canvas.height !== h) {
      this.canvas.width = w;
      this.canvas.height = h;
    } else {
      canvasContext.clearRect(0, 0, w, h);
    }
    canvasContext.drawImage(image, 0, 0, w, h);
  }
}
/**
 * A render visual that draws a given graph in a canvas using WebGL item styles.
 * @class GLVisual
 * @augments WebGLVisual
 */
class GLVisual extends WebGLVisual {
  /**
   * Creates a new GLVisual.
   * @param {IGraph} graph The graph to render.
   */
  constructor(graph) {
    super();
    this.graph = graph;
    this.edgeStyle = new WebGLPolylineEdgeStyle({
      thickness: 1,
    });
    this.nodeStyle = new WebGLShapeNodeStyle({
      shape: ShapeNodeShape.ELLIPSE,
      color: 'black',
    });
  }

  /**
   * Paints onto the context using WebGL item styles.
   * @param {IRenderContext} ctx The context to paint on
   * @param {WebGLRenderingContext} gl The given WebGLRenderingContext
   */
  render(ctx, gl) {
    if (!this.visuals) {
      this.visuals = [];
      const domTestNode = document.createElement('div');
      (document.querySelector('.domTester') || document.body).appendChild(
        domTestNode
      );
      this.graph.edges.forEach(edge => {
        const stroke = getColorFromNode(edge.tag, domTestNode);
        this.visuals.push(
          this.edgeStyle.renderer
            .getVisualCreator(
              edge,
              new WebGLPolylineEdgeStyle({
                thickness: 1,
                color: stroke,
              })
            )
            .createVisual(ctx)
        );
      });
      this.graph.nodes.forEach(node => {
        if (this.graph.isGroupNode(node)) {
          return;
        }
        const fill = getColorFromNode(node.tag, domTestNode);
        this.visuals.push(
          this.nodeStyle.renderer
            .getVisualCreator(
              node,
              new WebGLShapeNodeStyle({
                shape: ShapeNodeShape.ELLIPSE,
                color: fill,
              })
            )
            .createVisual(ctx)
        );
      });
      domTestNode.remove();
    }
    this.visuals.forEach(visual => {
      visual.render(ctx, gl);
    });
  }
}
/**
 * A custom lookup chain link to make hit test work.
 * @class HitTestInputChainLink
 * @augments IContextLookupChainLink
 * @private
 */
class HitTestInputChainLink extends Class(IContextLookupChainLink) {
  /**
   * Constructs a new instance of HitTestInputChainLink.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Retrieves an implementation of the given type for a given item.
   * @param {Object} item The item to lookup for
   * @param {Object} type The type to lookup for
   * @return {Object} the implementation found
   */
  contextLookup(item, type) {
    if (type === IHitTester.$class) {
      return new MyHitTestEnumerator(this.graphComponent);
    }
    if (type === INodeHitTester.$class) {
      return new MyNodeHitTestEnumerator(this.graphComponent);
    }
    if (type === IEdgeHitTester.$class) {
      return new MyEdgeHitTestEnumerator(this.graphComponent);
    }
    if (type === ILabelHitTester.$class) {
      return new MyLabelHitTestEnumerator(this.graphComponent);
    }
    if (type === IPortHitTester.$class) {
      return new MyPortHitTestEnumerator(this.graphComponent);
    }
    if (type === ILabelOwnerHitTester.$class) {
      return new MyLabelOwnerHitTestEnumerator(this.graphComponent);
    }

    if (this.next) {
      return this.next.contextLookup(item, type);
    }

    return null;
  }

  /**
   * Called to register the fallback lookup implementation that should be used.
   * @param {IContextLookup} next The context to use as a fallback
   */
  setNext(next) {
    this.next = next;
  }
}

/**
 * Enumerate hits of a given type for a certain position in world coordinates.
 * @class MyHitTestEnumerator
 * @augments IHitTester.<IModelItem>
 * @private
 */
class MyHitTestEnumerator extends Class(IHitTester) {
  /**
   * Constructs a new instance of HitTestInputChainLink.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<IModelItem>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.nodeLabels.forEach(label => {
      if (
        label.style.renderer
          .getHitTestable(label, label.style)
          .isHit(context, location.clone())
      ) {
        hits.add(label);
      }
    });
    this.graphComponent.graph.edgeLabels.forEach(label => {
      if (
        label.style.renderer
          .getHitTestable(label, label.style)
          .isHit(context, location.clone())
      ) {
        hits.add(label);
      }
    });
    // in hover and click operations that look for a single node,
    // the first hits in the collection are considered first.
    // therefore, we should iterate the nodes in reverse order,
    // to match the z-order of the rendering.
    // in other words, nodes in front should have hit priority over nodes in back.
    this.graphComponent.graph.nodes.toReversed().forEach(node => {
      if (
        node.style.renderer
          .getHitTestable(node, node.style)
          .isHit(context, location.clone())
      ) {
        hits.add(node);
      }
    });
    this.graphComponent.graph.edges.forEach(edge => {
      if (
        edge.style.renderer
          .getHitTestable(edge, edge.style)
          .isHit(context, location.clone())
      ) {
        hits.add(edge);
      }
    });
    return hits;
  }
}

/**
 * Enumerates over a collection of nodes.
 * @class MyNodeHitTestEnumerator
 * @augments INodeHitTester
 * @private
 */
class MyNodeHitTestEnumerator extends Class(INodeHitTester) {
  /**
   * Creates a new instance of MyNodeHitTestEnumerator.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<INode>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.nodes.forEach(node => {
      if (
        node.style.renderer
          .getHitTestable(node, node.style)
          .isHit(context, location.clone())
      ) {
        hits.add(node);
      }
    });
    return hits;
  }
}

/**
 * Enumerates over a collection of edges.
 * @class MyEdgeHitTestEnumerator
 * @augments IEdgeHitTester
 * @private
 */
class MyEdgeHitTestEnumerator extends Class(IEdgeHitTester) {
  /**
   * Creates a new instance of MyEdgeHitTestEnumerator.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<IEdge>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.edges.forEach(edge => {
      if (
        edge.style.renderer
          .getHitTestable(edge, edge.style)
          .isHit(context, location.clone())
      ) {
        hits.add(edge);
      }
    });
    return hits;
  }
}

/**
 * Enumerates over a collection of labels.
 * @class MyLabelHitTestEnumerator
 * @augments ILabelHitTester
 * @private
 */
class MyLabelHitTestEnumerator extends Class(ILabelHitTester) {
  /**
   * Creates a new instance of MyLabelHitTestEnumerator.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<ILabel>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.edgeLabels.forEach(label => {
      if (
        label.style.renderer
          .getHitTestable(label, label.style)
          .isHit(context, location.clone())
      ) {
        hits.add(label);
      }
    });
    this.graphComponent.graph.nodeLabels.forEach(label => {
      if (
        label.style.renderer
          .getHitTestable(label, label.style)
          .isHit(context, location.clone())
      ) {
        hits.add(label);
      }
    });
    return hits;
  }
}

/**
 * Enumerates over a collection of ports.
 * @class MyPortHitTestEnumerator
 * @augments IPortHitTester
 * @private
 */
class MyPortHitTestEnumerator extends Class(IPortHitTester) {
  /**
   * Creates a new instance of MyPortHitTestEnumerator.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<IPort>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.ports.forEach(port => {
      if (
        port.style.renderer
          .getHitTestable(port, port.style)
          .isHit(context, location.clone())
      ) {
        hits.add(port);
      }
    });
    return hits;
  }
}

/**
 * Enumerates over a collection of label owners.
 * @class MyLabeleditemHitTestEnumerator
 * @augments ILabelOwnerHitTester
 * @private
 */
class MyLabelOwnerHitTestEnumerator extends Class(ILabelOwnerHitTester) {
  /**
   * Creates a new instance of MyLabelOwnerHitTestEnumerator.
   * @param {GraphComponent} graphComponent The given graphComponent
   */
  constructor(graphComponent) {
    super();
    this.graphComponent = graphComponent;
  }

  /**
   * Enumerates the hits for the given location.
   * @param {IInputModeContext} context The context to perform the hit
   * @param {Point} location The location in world coordinates
   * @return {IEnumerable.<ILabelOwner>}
   */
  enumerateHits(context, location) {
    const hits = new List();
    this.graphComponent.graph.edges.forEach(edge => {
      if (
        edge.style.renderer
          .getHitTestable(edge, edge.style)
          .isHit(context, location.clone())
      ) {
        hits.add(edge);
      }
    });
    this.graphComponent.graph.nodes.forEach(node => {
      if (
        node.style.renderer
          .getHitTestable(node, node.style)
          .isHit(context, location.clone())
      ) {
        hits.add(node);
      }
    });
    return hits;
  }
}
