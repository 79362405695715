import {
  derivedStream,
  persistentReducedStream,
  streamReducer,
} from '@ardoq/rxbeach';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { scopeDiff$ } from 'scope/scopeDiff$';
import gridEditor2023$ from 'gridEditor2023/gridEditor$';
import { gridEditorStateOperations as gridEditor2023Operations } from 'gridEditor2023/gridEditorStateOperations';
import { ScopeDiffState } from 'scope/types';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { isViewSidePanelOpened$ } from '../viewSidePanel/viewSidePanel$';
import { isDetailsDrawerOpen$ } from 'appLayout/ardoqStudio/detailsDrawer/isDetailsDrawerOpen$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { context$ } from 'streams/context/context$';
import workspaces$ from 'streams/workspaces/workspaces$';
import { workspaceOperations } from 'streams/workspaces/workspaceOperations';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';

interface StreamShape {
  isDiffMode: boolean;
  isGridEditorVisible: boolean;
}

const mainAppGridEditorVisibility$ = gridEditor2023$.pipe(
  map(gridEditor2023 => {
    return (
      gridEditor2023Operations.isDockedGridEditorState(gridEditor2023) &&
      gridEditor2023Operations.isDockedGridEditorOpen(gridEditor2023)
    );
  })
);

const scopeDiffReducer = (
  state: StreamShape,
  { scopeDiff, isLoading }: ScopeDiffState
) => ({
  ...state,
  isDiffMode: Boolean(scopeDiff || isLoading),
});

const mainAppGridEditorVisibilityReducer = (
  state: StreamShape,
  isGridEditorVisible: boolean
) => ({
  ...state,
  isGridEditorVisible,
});

const state$ = persistentReducedStream(
  'componentFabVisibility$',
  {
    isDiffMode: false,
    isGridEditorVisible: false,
  },
  [
    streamReducer(scopeDiff$, scopeDiffReducer),
    streamReducer(
      mainAppGridEditorVisibility$,
      mainAppGridEditorVisibilityReducer
    ),
  ]
);

const fabVisibility$ = derivedStream(
  'fabVisibility$',
  state$,
  subdivisions$,
  currentUserPermissionContext$,
  isViewSidePanelOpened$,
  isDetailsDrawerOpen$,
  context$,
  workspaces$,
  activeScenario$
).pipe(
  map(
    ([
      { isGridEditorVisible, isDiffMode },
      subdivisionContext,
      currentUserPermissionContext,
      isViewSidePanelOpened,
      isDetailsDrawerOpen,
      context,
      workspaces,
      activeScenarioState,
    ]) => {
      const { workspaceId } = context;
      const isContextWorkspaceWritable =
        workspaceAccessControlInterface.canEditWorkspace(
          currentUserPermissionContext,
          workspaceId,
          activeScenarioState
        );

      const userCanEditWorkspace =
        workspaceOperations.hasWritableSubdivisionMembership(
          workspaces.byId[context.workspaceId],
          {
            permissionContext: currentUserPermissionContext,
            subdivisionContext,
          }
        );

      return (
        !isGridEditorVisible &&
        !isDiffMode &&
        (isContextWorkspaceWritable || userCanEditWorkspace) &&
        !isViewSidePanelOpened &&
        !isDetailsDrawerOpen
      );
    }
  ),
  startWith(false),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default fabVisibility$;
