import MultiLabelGroupStyle from '../labels/MultiLabelGroupStyle';
import modernizedBlockDiagramRepresentationData from './modernizedBlockDiagramRepresentationData';

class IconGroupLabelStyle extends MultiLabelGroupStyle {
  static Instance = new IconGroupLabelStyle(
    modernizedBlockDiagramRepresentationData('icon'),
    true
  );
}
export default IconGroupLabelStyle;
