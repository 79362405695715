import {
  IRenderContext,
  ShapeNodeStyleRenderer,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { disablePointerEvents } from './util';

export class NodeSelectionStyleRenderer extends ShapeNodeStyleRenderer {
  createVisual(context: IRenderContext): Visual | null {
    const result = super.createVisual(context) as SvgVisual;
    disablePointerEvents(result);
    return result;
  }
  updateVisual(context: IRenderContext, oldVisual: Visual): Visual | null {
    const result = super.updateVisual(context, oldVisual) as SvgVisual;
    disablePointerEvents(result);
    return result;
  }
}
