import {
  getDropdownDivider,
  getEditMetamodelMenuItem,
  getEditWorkspaceMenuItem,
  getExcludeWorkspaceMenuItem,
  getFilterWorkspaceMenuItem,
  getPermissionsMenuItem,
} from './menuItems';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TrackedContextMenus, getTrackingFunction } from './tracking';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { ArdoqId } from '@ardoq/api-types';
import { checkHasWriteAccess } from './utils';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import Context from 'context';
import { size } from 'lodash';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { Features, hasFeature } from '@ardoq/features';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const getWorkspacesInContextCount = (): number => {
  return size(Context.workspaces());
};
type GetWorkspaceMenuArgs = {
  workspaceIds: ArdoqId[];
  scenarioId: string | null;
  isViewpointMode: boolean;
} & GetContextMenuOptionsArguments;
export const getWorkspaceMenu = ({
  workspaceIds,
  scenarioId,
  target,
  isViewpointMode,
}: GetWorkspaceMenuArgs): DropdownItem[] | null => {
  const menuItemWorkspaceTrackingFunction = getTrackingFunction(
    target,
    TrackedContextMenus.WORKSPACE_CONTEXT_MENU
  );

  const workspacesInContextCount = getWorkspacesInContextCount();

  const hasAdminAccess =
    // Requires special handling. An admin has always write access, but the
    // meta model should never be editable.
    !workspaceIds.some(metaModelOperations.isMetaModelWorkspace) &&
    workspaceAccessControlInterface.canAdminWorkspace(
      currentUserInterface.getPermissionContext(),
      workspaceIds[0],
      // we ignore the scenario state here, because we want to show the edit options anyways
      // but we disabled them if it is a scenario mode.
      null
    );

  const hasWriteAccess = checkHasWriteAccess({ workspaceIds });
  const hasQuickStartFeature = hasFeature(Features.QUICK_START);

  const menu = [
    hasAdminAccess &&
      !hasQuickStartFeature &&
      getEditMetamodelMenuItem(
        workspaceIds[0],
        menuItemWorkspaceTrackingFunction
      ),
    hasAdminAccess &&
      getEditWorkspaceMenuItem(
        workspaceIds[0],
        menuItemWorkspaceTrackingFunction
      ),
    hasAdminAccess && getDropdownDivider(),
    hasWriteAccess &&
      getPermissionsMenuItem(
        workspaceIds[0],
        menuItemWorkspaceTrackingFunction
      ),
    hasWriteAccess && getDropdownDivider(),
    !isViewpointMode &&
      getFilterWorkspaceMenuItem(
        workspaceIds[0],
        menuItemWorkspaceTrackingFunction
      ),
    !!scenarioId &&
      workspaceAccessControlInterface.canAdminWorkspace(
        currentUserInterface.getPermissionContext(),
        workspaceIds[0],
        null
      ) &&
      getExcludeWorkspaceMenuItem(
        scenarioId,
        workspaceIds[0],
        workspacesInContextCount <= 1,
        menuItemWorkspaceTrackingFunction
      ),
  ].filter(ExcludeFalsy);

  return menu.length ? menu : null;
};
