import { ComponentBackboneModel, Reference } from 'aqTypes';
import { APIFieldType } from '@ardoq/api-types';
import * as modelUtils from 'models/utils/modelUtils';
import {
  CellTypes,
  ComponentProps,
  HeaderModel,
  ReferenceProps,
  TableViewModel,
} from './types';
import { ContextSort, urlUtils } from '@ardoq/common-helpers';
import logMissingModel from 'models/logMissingModel';
import { APIFieldAttributes } from '@ardoq/api-types';
import { fieldInterface } from '@ardoq/field-interface';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';

export const emptyState: TableViewModel = {
  allDefaultHeaders: [],
  allReferenceTypeHeaders: [],
  allFieldHeaders: [],
  defaultHeaders: [],
  referenceTypeHeaders: [],
  fieldHeaders: [],
  rows: null,
  expandedDescriptions: new Set(),
  hasComponentsAvailable: false,
};

export const componentProps = (
  component: ComponentBackboneModel
): ComponentProps => {
  const type = component.getMyType();
  if (!type?.id) {
    /**
     * This is rather temporal fix for the urgent issue:
     * https://ardoqcom.atlassian.net/browse/ARD-17504
     * We should check what's the condition when the type
     * is "null" and either allow this (remove this error log)
     * or FIX it.
     */
    logMissingModel({
      id: component.getId(),
      modelTypeName: 'component',
      rootWorkspace: component.get('rootWorkspace'),
    });
  }
  return {
    entityType: 'component',
    name: component.get('name'),
    id: component.getId(),
    cid: component.cid,
    filterColor: component.getCSSFilterColor(),
    typeId: type?.id || '',
    type: component.get('type'),
    'component-key': component.get('component-key'),
    created: component.get('created'),
    'last-updated': component.get('last-updated'),
  };
};

export const referenceProps = (reference: Reference): ReferenceProps => {
  const refType = reference.getRefType();
  return {
    entityType: 'reference',
    id: reference.getId(),
    cid: reference.cid,
    source: componentProps(reference.getSource()),
    target: componentProps(reference.getTarget()),
    displayText: reference.get('displayText'),
    type: refType !== 'null' ? refType.name : '',
    created: reference.get('created'),
    'last-updated': reference.get('last-updated'),
  };
};

const cellTypeMap: Record<APIFieldType | 'default', CellTypes> = {
  [APIFieldType.URL]: CellTypes.URI,
  [APIFieldType.EMAIL]: CellTypes.URI,
  [APIFieldType.USER]: CellTypes.URI,
  [APIFieldType.CHECKBOX]: CellTypes.CHECKBOX,
  [APIFieldType.NUMBER]: CellTypes.NUMBER,
  [APIFieldType.TEXT]: CellTypes.TEXT,
  [APIFieldType.DATE_ONLY]: CellTypes.DATE_ONLY,
  [APIFieldType.DATE_TIME]: CellTypes.DATE_TIME,
  [APIFieldType.LIST]: CellTypes.TEXT,
  [APIFieldType.SELECT_MULTIPLE_LIST]: CellTypes.TEXT,
  [APIFieldType.DATE_ONLY_RANGE]: CellTypes.TEXT,
  [APIFieldType.DATE_TIME_RANGE]: CellTypes.TEXT,
  [APIFieldType.TEXT_AREA]: CellTypes.TEXT_AREA,
  [APIFieldType.FILE]: CellTypes.URI,
  default: CellTypes.TEXT,
};

const fieldProps = (field: APIFieldAttributes) => ({
  key: field.name,
  label: field.label,
  cellType: cellTypeMap[field.type] || cellTypeMap.default,
  order: null,
  custom: true,
  global: field.global,
  componentTypes: field.componentType,
  numberFormatOptions: field.numberFormatOptions,
});

const coerceFieldValue = (field: APIFieldAttributes, value: any) => {
  switch (field.type) {
    case APIFieldType.URL:
      return {
        uri: urlUtils.processUrl(value),
        label: value,
      };
    case APIFieldType.EMAIL:
      return {
        uri: value && `mailto:${value}`,
        label: value,
      };
    case APIFieldType.SELECT_MULTIPLE_LIST:
      return Array.isArray(value) ? value.join(', ') : value;
    case APIFieldType.USER: {
      const user = orgUsersInterface.getUserById(value);
      const fallbackLabel = value ? 'User info missing' : '';
      const label = (user && user.name) || fallbackLabel;
      const uri = (user && `mailto:${user.email}`) || '';
      return { label, uri };
    }
    case APIFieldType.FILE: {
      const file = documentArchiveInterface.getAttachmentById(value);

      if (!file) {
        return { label: 'File info missing', url: '' };
      }

      return { label: file.filename, uri: file.uri };
    }
    case APIFieldType.DATE_ONLY_RANGE:
      return fieldInterface.format({
        value,
        fieldId: field._id,
        fieldType: field.type,
      });
    case APIFieldType.DATE_TIME_RANGE:
      return fieldInterface.format({
        value,
        fieldId: field._id,
        fieldType: field.type,
      });
    default:
      return value;
  }
};

export const updateSort =
  (attr: string, order: number) => (header: HeaderModel) => {
    if (header.key === attr) {
      header.order = order;
    } else {
      header.order = null;
    }
    return header;
  };

export const fieldHeaderProps = (field: APIFieldAttributes) => ({
  ...fieldProps(field),
  isExpandController: false,
});

export const setupHeaders = (sort: ContextSort, headers: HeaderModel[]) =>
  headers.map(updateSort(sort.attr, sort.order));

const isComponent = (
  model: ComponentBackboneModel | Reference
): model is ComponentBackboneModel =>
  (model as ComponentBackboneModel).getTypeId !== undefined;

const isDisabled = (
  field: APIFieldAttributes,
  model: ComponentBackboneModel | Reference
) =>
  isComponent(model)
    ? !fieldInterface.hasComponentType(field._id, model.getTypeId())
    : !fieldInterface.hasReferenceType(field._id, model.getType().toString());

/**
 * Extract custom fields from a model
 *
 * Takes an array of custom field definitions, and a model, and extracts
 * custom field objects with keys `isDisabled`, `value`, and other field props.
 *
 * @returns An object with mapping from field key to `{ isDisabled, value }`
 */
export const extractCustomFields = (
  fields: APIFieldAttributes[],
  model: ComponentBackboneModel | Reference
) => {
  const fieldMap: Record<string, { isDisabled: boolean; value: any }> = {};
  return fields.reduce((map, field) => {
    const fieldName = field.name;
    if (Object.prototype.hasOwnProperty.call(map, fieldName)) {
      // There might be several fields with the same name from different workspaces.
      // The value will be the same but they might have different disabled status.
      map[fieldName].isDisabled =
        map[fieldName].isDisabled && isDisabled(field, model);
    } else {
      map[fieldName] = {
        isDisabled: isDisabled(field, model),
        value: coerceFieldValue(
          field,
          modelUtils.fieldValueToPresent(field, model)
        ),
      };
    }
    return map;
  }, fieldMap);
};
export const getCellValue = (value: any) =>
  value instanceof Object && 'value' in value ? value.value : value;
