import {
  Metamodel,
  MetamodelAssetFolder,
  MetamodelComponentType,
  MetamodelReferenceType,
  MetamodelWorkspace,
} from '@ardoq/api-types';
import { MetamodelContext } from 'metamodel/types';
import {
  getAssetFolder,
  getComponentType,
  getContentType,
  getField,
  getReferenceCompletenessContextComponentType,
  getReferenceType,
  getWorkspace,
} from './utils';
import { BreadCrumbItem } from '../atoms';
import { trackClickBreadCrumbPath } from 'metamodel/tracking';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMetamodelContext } from 'metamodel/navigation/actions';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { Paragraph } from '@ardoq/typography';

const RightChevron = () => <span style={{ margin: '0 8px' }}>›</span>;

interface ContextPath {
  metamodel: Metamodel;
  assetFolder: MetamodelAssetFolder | null;
  workspace: MetamodelWorkspace | null;
  componentType: MetamodelComponentType | null;
  referenceType?: MetamodelReferenceType | null;
  field: { name: string; type: SimpleBlockDiagramContentType.FIELD } | null;
  referenceCompletenessContextComponentType: MetamodelComponentType | null;
}

const BreadCrumbNavigation = ({
  metamodel,
  context,
}: {
  metamodel: Metamodel;
  context?: MetamodelContext;
}) => {
  const path: ContextPath = {
    metamodel,
    assetFolder: getAssetFolder(metamodel, context),
    workspace: getWorkspace(metamodel, context),
    componentType: getComponentType(metamodel, context),
    referenceType: getReferenceType(metamodel, context),
    field: getField(metamodel, context),
    referenceCompletenessContextComponentType:
      getReferenceCompletenessContextComponentType(metamodel, context),
  };
  return (
    <Paragraph color="textSubtle">
      {Object.entries(path)
        .filter(([, v]) => Boolean(v))
        .flatMap(([key, pathStep], index) => {
          const type = getContentType(metamodel, pathStep);
          const newContext = { ...pathStep, type };
          const isField = pathStep.type === SimpleBlockDiagramContentType.FIELD;
          const isReference = key === 'referenceType';
          const onClick = isField
            ? undefined
            : () => {
                trackClickBreadCrumbPath();
                dispatchAction(setMetamodelContext(newContext));
              };
          return [
            <BreadCrumbItem
              key={index}
              $isReference={isReference}
              onClick={onClick}
            >
              {pathStep.name}
            </BreadCrumbItem>,
            <RightChevron key={`${index}chevron`} />,
          ];
        })
        .slice(0, -1)}
    </Paragraph>
  );
};

export default BreadCrumbNavigation;
