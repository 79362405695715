import { Link, Paragraph } from '@ardoq/typography';

const GenericErrorMessageWithSupportLink = () => (
  <Paragraph variant="text2">
    The error was logged for further investigation. If the problem persists,
    please reach out to{' '}
    <Link hideIcon typography="text2" href="mailto:support@ardoq.com">
      support@ardoq.com
    </Link>{' '}
    with a description of the error and some context to how and where the error
    occurred.
  </Paragraph>
);

export default GenericErrorMessageWithSupportLink;
