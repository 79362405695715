import { Observable } from 'rxjs';
import { ViewSettings } from 'viewSettings/viewSettingsStreams';
import { getRelationshipDiagramViewModel$ } from 'tabview/relationshipDiagrams/viewModel$/relationshipDiagramViewModel$';
import { map } from 'rxjs/operators';
import { ViewIds } from '@ardoq/api-types';
import { ALL_EXCEPT_PARENTS } from './consts';
import { viewSettingsConsts } from '@ardoq/view-settings';
import { trackEdgeCount, trackNodeCount } from 'tracking/events/visualizations';
import {
  type RelationshipDiagramViewModelStreamState,
  type RelationshipsViewSettings,
  relationshipsViewDistinctReferenceKey,
  resetRelationshipsView,
} from '@ardoq/graph';

const reset = (
  state: RelationshipDiagramViewModelStreamState<RelationshipsViewSettings>
) => {
  const result = resetRelationshipsView(state);
  const {
    viewModel: { graphLinks, graphNodes },
  } = result;
  trackNodeCount(ViewIds.NETWORK, graphNodes.length);
  trackEdgeCount(ViewIds.NETWORK, graphLinks.length);
  return result;
};

export const getViewModel$ =
  (viewSettings$: Observable<ViewSettings<RelationshipsViewSettings>>) =>
  (viewInstanceId: string) => {
    return getRelationshipDiagramViewModel$({
      viewId: ViewIds.NETWORK,
      viewSettings$,
      viewSettingsToIgnore: [
        'nodeDistance',
        viewSettingsConsts.IS_LEGEND_ACTIVE,
      ],
      overrideTraverseOptions: ({ menuConfig, referenceTypeMap }) => {
        const [outgoingReferenceTypes, incomingReferenceTypes] = [
          menuConfig.outgoingReferenceTypes,
          menuConfig.incomingReferenceTypes,
        ].map(referenceTypes =>
          referenceTypes
            ? new Set(
                [...referenceTypes].flatMap(referenceType =>
                  referenceType === ALL_EXCEPT_PARENTS
                    ? [...referenceTypeMap.values()].flat()
                    : referenceType
                )
              )
            : referenceTypes
        );
        return {
          ...menuConfig,
          maxDegreesIncoming: 0, // this view does not allow individual degrees. but if the user had access to the beta, they might have a non-zero value left over in their settings.
          maxDegreesOutgoing: 0, // this view does not allow individual degrees. but if the user had access to the beta, they might have a non-zero value left over in their settings.
          outgoingReferenceTypes,
          incomingReferenceTypes,
        };
      },
      getDistinctReferenceKey: relationshipsViewDistinctReferenceKey,
      viewInstanceId,
      shouldCollapseGraph: true,
    }).pipe(map(viewModel => ({ ...reset(viewModel), viewInstanceId })));
  };
