import { SetIsProteanEditModeEnabledPayload } from './actions';

export const initialProteanEditModeState = {
  isEditModeEnabled: false,
};
type EditModeState = typeof initialProteanEditModeState;

export const isProteanEditModeEnabledReducer = (
  _: EditModeState,
  newState: SetIsProteanEditModeEnabledPayload
) => newState;
