import { reducer } from '@ardoq/rxbeach';
import {
  navigateToReportBuilder,
  NavigateToReportBuilderPayload,
  NavigateToReportModulePayload,
  navigateToReportOverview,
  navigateToReportReader,
} from 'router/navigationActions';
import { ReportModuleRoute } from 'router/appRouterTypes';
import { ArdoqId, Report, ReportColumn } from '@ardoq/api-types';
import { ReportModule } from '../types';
import {
  shareReportDialogLoadingFinished,
  shareReportSelected,
} from '../actions';

export type ReportNavigationState = ReportModuleRoute & {
  datasource?: Report['datasource'];
  query?: Report['query'];
  isShareDialogLoading?: boolean;
  workspaceIds?: ArdoqId[];
  columns?: ReportColumn[];
};
export const defaultState: ReportNavigationState = {
  reportModule: ReportModule.OVERVIEW,
};

const handleNavigateToReportReader = (
  _currentState: ReportNavigationState,
  { reportId, filters }: NavigateToReportModulePayload
): ReportNavigationState => ({
  reportModule: ReportModule.READER,
  reportId,
  filters,
});

const handleNavigateToReportBuilder = (
  _: ReportNavigationState,
  payload: NavigateToReportBuilderPayload
): ReportNavigationState => ({
  reportModule: ReportModule.BUILDER,
  ...payload,
});

const handleNavigateToReportOverview = ({
  reportId,
}: ReportNavigationState): ReportNavigationState => ({
  reportModule: ReportModule.OVERVIEW,
  reportId,
});

const showShareDialogLoader = (
  state: ReportNavigationState
): ReportNavigationState => ({ ...state, isShareDialogLoading: true });

const hideShareDialogLoader = (
  state: ReportNavigationState
): ReportNavigationState => ({ ...state, isShareDialogLoading: false });

export const reducers = [
  reducer(navigateToReportBuilder, handleNavigateToReportBuilder),
  reducer(navigateToReportOverview, handleNavigateToReportOverview),
  reducer(navigateToReportReader, handleNavigateToReportReader),
  reducer(shareReportSelected, showShareDialogLoader),
  reducer(shareReportDialogLoadingFinished, hideShareDialogLoader),
];
