import { Rectangle } from '@ardoq/graph';
import { BlocksViewNode, BlocksViewVisualState, NodeSelection } from '../types';

const createNodeSelection = (): NodeSelection => {
  const set = new Set<BlocksViewNode>();

  const group = () => {
    const parent = set.size !== 0 && set.values().next().value?.parent;
    return parent ? parent : null;
  };

  const nodes = () => set;

  const clear = () => {
    set.forEach(node => {
      node.visualState = node.visualState & ~BlocksViewVisualState.Select;
    });

    set.clear();
  };

  const add = (node: BlocksViewNode) => {
    set.add(node);
    node.visualState = node.visualState | BlocksViewVisualState.Select;
    // that probably boosts the bounds
    return true;
  };

  const remove = (node: BlocksViewNode) => {
    node.visualState = node.visualState & ~BlocksViewVisualState.Select;
    set.delete(node);
    // that probably boosts the bounds
  };

  const empty = () => set.size === 0;

  const has = (node: BlocksViewNode) => {
    return set.has(node);
  };

  const forEach = (callbackfn: (node: BlocksViewNode) => void) =>
    set.forEach(node => callbackfn(node));

  const bounds = () => {
    const ret: Rectangle = [
      Number.POSITIVE_INFINITY,
      Number.POSITIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
    ];

    set.forEach(child => {
      ret[0] = Math.min(ret[0], child.bounds[0]);
      ret[1] = Math.min(ret[1], child.bounds[1]);
      ret[2] = Math.max(ret[2], child.bounds[2]);
      ret[3] = Math.max(ret[3], child.bounds[3]);
    });

    return ret;
  };

  return {
    empty,
    has,
    forEach,
    bounds,
    nodes,
    group,
    clear,
    add,
    remove,
  };
};

export default createNodeSelection;
