import { ReactNode, MutableRefObject, useCallback, useRef } from 'react';
import { ComponentRepresentation } from '@ardoq/renderers';
import { WithPopover } from '@ardoq/popovers';
import {
  HorizontalIconContainer,
  HorizontalLabelContainer,
  Text,
  VerticalIconContainer,
  VerticalLabelContainer,
} from '../atoms';
import { ComponentItemData } from '../types';
import { classes } from '@ardoq/common-helpers';
import {
  CONDITIONAL_IMAGE_COLORING_CLASS_NAME,
  COMPONENT_ID_ATTRIBUTE,
} from '@ardoq/global-consts';
import { dependencyMatrixCommands } from '../commands';

type ComponentViewProps = {
  type: 'SOURCE' | 'TARGET';
  component: ComponentItemData;
};

export const ComponentLabel = (props: ComponentViewProps) => {
  const { type, component } = props;
  const { id, label, fullLabel, representationData, cssClassNames, iconStyle } =
    component;

  const LabelComponent = type === 'TARGET' ? HorizontalLabel : VerticalLabel;

  const ref = useRef<HTMLSpanElement>(null);

  const onComponentDoubleClick = useCallback(() => {
    dependencyMatrixCommands.setFocusedItemState(null);
    dependencyMatrixCommands.setSelectedItemState(id);
  }, [id]);

  const isTextOverflowed = label !== fullLabel;
  const popoverContent = isTextOverflowed ? fullLabel : null;

  const contextMenuAttribute = COMPONENT_ID_ATTRIBUTE;

  return (
    <LabelComponent
      id={id}
      label={label}
      onDoubleClick={onComponentDoubleClick}
      contextMenuAttribute={contextMenuAttribute}
      cssClassNames={classes(
        cssClassNames,
        CONDITIONAL_IMAGE_COLORING_CLASS_NAME
      )}
      color={iconStyle?.color}
      textRef={ref}
      popoverContent={popoverContent}
      iconSlot={
        <ComponentRepresentation
          isImage={representationData.isImage}
          value={representationData.value}
          icon={representationData.icon}
        />
      }
    />
  );
};

type LabelProps = {
  label: string | null;
  textRef?: MutableRefObject<HTMLSpanElement | null>;
  popoverContent?: string | null;
  cssClassNames?: string;
  iconSlot?: ReactNode;
  id?: string;
  color?: string;
  contextMenuAttribute?: string;
  onDoubleClick?: () => void;
};

export const HorizontalLabel = (props: LabelProps) => (
  <HorizontalLabelContainer
    className={props.cssClassNames}
    onDoubleClick={props.onDoubleClick}
    {...(props.contextMenuAttribute
      ? { [props.contextMenuAttribute]: props.id }
      : {})}
  >
    <WithPopover content={props.popoverContent}>
      <HorizontalIconContainer
        $isTextOverflowed={Boolean(props.popoverContent)}
        style={{ color: props.color }}
      >
        {props.iconSlot}
      </HorizontalIconContainer>
      <Text ref={props.textRef}>{props.label}</Text>
    </WithPopover>
  </HorizontalLabelContainer>
);

export const VerticalLabel = (props: LabelProps) => (
  <VerticalLabelContainer
    className={props.cssClassNames}
    onDoubleClick={props.onDoubleClick}
    {...(props.contextMenuAttribute
      ? { [props.contextMenuAttribute]: props.id }
      : {})}
  >
    <Text ref={props.textRef}>{props.label}</Text>
    <WithPopover content={props.popoverContent}>
      <VerticalIconContainer
        $isTextOverflowed={Boolean(props.popoverContent)}
        style={{ color: props.color }}
      >
        {props.iconSlot}
      </VerticalIconContainer>
    </WithPopover>
  </VerticalLabelContainer>
);
