import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { DropdownConfig, SettingsType } from '@ardoq/view-settings';
import { handleStoreSetting } from 'viewSettings/settingsHelper';
import { onViewSettingsUpdate } from '../onViewSettingsUpdate';
import { APIFieldAttributes, ArdoqId, ViewIds } from '@ardoq/api-types';
import { ChildAggregationFunction, SpiderChartViewSettings } from './types';
import { IconName } from '@ardoq/icons';
import includeAllDescendantsMenuItem from '../includeAllDescendantsMenuItem';
import { popoverRegistry } from '@ardoq/popovers';
import { openSelectFieldsViewSetting } from './actions';

const NOT_ENOUGH_FIELDS = 'notEnoughFields';
const NO_NUMBER_FIELDS = 'noNumberFields';

popoverRegistry.set(
  NOT_ENOUGH_FIELDS,
  () => 'Select 3 or more fields to show component series.'
);
popoverRegistry.set(
  NO_NUMBER_FIELDS,
  () => 'This workspace doesn’t have any number fields.'
);

const getOptionOnClick =
  (viewId: ViewIds, viewstate: SpiderChartViewSettings, name: string) => () => {
    const { [name]: currentNameValue, ...selectedFieldNames } =
      viewstate.selectedFieldNames;

    handleStoreSetting(viewId, {
      selectedFieldNames: {
        ...selectedFieldNames,
        ...(!currentNameValue ? { [name]: true } : {}),
      },
    });
  };

const getFieldOptions = (
  viewId: ViewIds,
  viewstate: SpiderChartViewSettings,
  fields: APIFieldAttributes[]
) =>
  fields.map(field => {
    const name = field.name;
    const isActive = Boolean(viewstate.selectedFieldNames[name]);

    return {
      label: field.label || name,
      name,
      isActive,
      onClick: getOptionOnClick(viewId, viewstate, name),
      type: DropdownOptionType.OPTION,
    };
  });

export const getLeftMenuConfig = (
  viewId: ViewIds,
  viewstate: SpiderChartViewSettings,
  sideways: boolean,
  workspaceId: ArdoqId,
  numericFields: APIFieldAttributes[],
  selectedFields: APIFieldAttributes[]
) => {
  const isSidewaysEnabled = selectedFields.length > 2;
  const hasNumberFields = numericFields.length > 0;

  const getChildAggregatedOptionOnClick =
    (aggregateChildValues: ChildAggregationFunction) => () =>
      onViewSettingsUpdate(ViewIds.SPIDER, { aggregateChildValues }, true);
  const childAggregationDropdown: DropdownConfig = {
    id: 'aggregateChildValues',
    label: 'Aggregate child values',
    type: SettingsType.DROPDOWN,
    iconName: IconName.SIGMA,
    isActive:
      viewstate.aggregateChildValues &&
      viewstate.aggregateChildValues !== 'zero',
    options: [
      {
        name: 'sum',
        label: 'Sum',
        type: DropdownOptionType.OPTION,
        isActive:
          viewstate.aggregateChildValues === 'sum' ||
          // @ts-expect-error TS2367 this view settings property used to be a boolean, so this line is here for legacy support.
          viewstate.aggregateChildValues === true,
        onClick: getChildAggregatedOptionOnClick('sum'),
      },
      {
        name: 'average',
        label: 'Average',
        type: DropdownOptionType.OPTION,
        isActive: viewstate.aggregateChildValues === 'average',
        onClick: getChildAggregatedOptionOnClick('average'),
      },
      {
        name: 'zero',
        label: 'None',
        type: DropdownOptionType.OPTION,
        isActive:
          viewstate.aggregateChildValues === 'zero' ||
          !viewstate.aggregateChildValues,
        onClick: getChildAggregatedOptionOnClick('zero'),
      },
    ],
  };

  return [
    {
      id: 'fieldDropdown',
      label: hasNumberFields ? 'Select fields' : '',
      popoverId: !hasNumberFields && NO_NUMBER_FIELDS,
      iconName: IconName.FORMAT_LIST_BULLETED,
      options: getFieldOptions(viewId, viewstate, numericFields),
      isDisabled: !hasNumberFields,
      type: SettingsType.DROPDOWN,
      openDropdownActionCreator: openSelectFieldsViewSetting,
      isKeepOpen: true,
    },
    childAggregationDropdown,
    {
      id: 'sideways',
      label: isSidewaysEnabled ? 'Component series' : '',
      popoverId: !isSidewaysEnabled && NOT_ENOUGH_FIELDS,
      iconName: IconName.ROTATE_90_DEG,
      isActive: sideways,
      isDisabled: !isSidewaysEnabled,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate,
    },
    includeAllDescendantsMenuItem(viewstate.includeAllDescendants),
  ];
};
