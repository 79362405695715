import { isPresentationMode } from 'appConfig';
import {
  EventRecognizers,
  GraphComponent,
  GraphInputMode,
  GraphItemTypes,
  IModelItem,
  KeyEventRecognizers,
  MouseEventRecognizers,
} from '@ardoq/yfiles';
import * as GraphSelection from 'yfilesExtensions/graphSelection';
import { isMac } from '@ardoq/common-helpers';
import { AggregatedGraphEdge } from '@ardoq/graph';
import { treeSelectionChanged } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { ViewIds } from '@ardoq/api-types';
import graphItemClickedListener from './graphEvents/graphItemClickedListener';
import graphItemDoubleClickedListener from './graphEvents/graphItemDoubleClickedListener';
import graphViewpointModeItemDoubleClickedListener from './graphEvents/graphViewpointModeItemDoubleClickedListener';
import graphViewpointModeItemRightClickedListener from './graphEvents/graphViewpointModeItemRightClickedListener';

const multiSelectModifier = isMac()
  ? KeyEventRecognizers.META_IS_DOWN
  : KeyEventRecognizers.CTRL_IS_DOWN;

const pressedRecognizer = EventRecognizers.createAndRecognizer(
  MouseEventRecognizers.LEFT_DOWN,
  multiSelectModifier
);
const isSelectable = (item: IModelItem): boolean =>
  item &&
  item.tag &&
  (item.tag.isComponent?.() ||
    item.tag.isReference?.() ||
    item.tag instanceof AggregatedGraphEdge);

export const applySelectionBehavior = (
  graphComponent: GraphComponent,
  args: {
    selectableItems: GraphItemTypes;
    focusableItems: GraphItemTypes;
    isViewpointMode: () => boolean;
    viewId: ViewIds | null;
  }
) => {
  const inputMode = graphComponent.inputMode as GraphInputMode;
  const { selectableItems, focusableItems, isViewpointMode, viewId } = args;
  inputMode.selectableItems =
    inputMode.clickSelectableItems =
    inputMode.marqueeSelectableItems =
      selectableItems;
  // the focus rectangle is mildly annoying, so i'm turning it off.
  // this also disables keyboard navigation.
  inputMode.focusableItems = focusableItems;
  const isPresentation = isPresentationMode();

  inputMode.marqueeSelectionInputMode.enabled = !isPresentation;

  inputMode.marqueeSelectionInputMode.pressedRecognizer = pressedRecognizer;

  inputMode.selectablePredicate = isPresentation ? () => false : isSelectable;
  inputMode.addMultiSelectionFinishedListener(() =>
    GraphSelection.handleSelectionChanged(
      graphComponent,
      viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM
    )
  );

  inputMode.addItemClickedListener(graphItemClickedListener);
  inputMode.addItemDoubleClickedListener(graphItemDoubleClickedListener);
  if (isViewpointMode() && viewId) {
    inputMode.addItemDoubleClickedListener(
      graphViewpointModeItemDoubleClickedListener
    );
    inputMode.addItemRightClickedListener(
      graphViewpointModeItemRightClickedListener({
        graphComponent,
        isViewpointMode,
        viewId,
      })
    );
  }

  subscribeToAction(treeSelectionChanged, ({ selection }) =>
    // using setTimeout so that the Context is current when we call handleTreeSelectionChanged.
    setTimeout(() =>
      GraphSelection.handleTreeSelectionChanged(
        graphComponent,
        selection,
        false
      )
    )
  );
};
