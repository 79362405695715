import styled from 'styled-components';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { colors, s32, s4, s8 } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { ExcludeFalsy, urlUtils } from '@ardoq/common-helpers';
import { HasUrlFieldValuesById } from './types';
import trackURLlinkClicked from 'tabview/graphViews/tracking';
import { AsLink } from '@ardoq/typography';

const UrlFieldValueList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 304px;
`;

const UrlFieldValueContainer = styled.li`
  height: ${s32};
  display: flex;
  align-items: center;
  margin: ${s4} 0;
  &:not(:last-child) {
    margin-bottom: ${s8};
  }
`;
const UrlFieldValueTypeIcon = styled(Icon)`
  color: ${colors.grey50};
  margin-right: ${s8};
  vertical-align: middle;
`;
const UrlFieldValueLink = styled(AsLink).attrs({ small: true })`
  vertical-align: middle;
  display: flex;
  overflow: hidden;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const urlFieldValuesPopover =
  ({
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
  }: HasUrlFieldValuesById) =>
  (element: Element | null) => {
    const componentId = element
      ?.closest(`.component[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`)
      ?.getAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);

    const componentUrlFieldValues =
      componentId &&
      urlFieldValuesByComponentId
        .get(componentId)
        ?.map(urlFieldValue => ({ ...urlFieldValue, isReference: false }));

    const referenceIds =
      element
        ?.closest('[data-reference-model-ids]')
        ?.getAttribute('data-reference-model-ids')
        ?.split(',') ??
      [
        element
          ?.closest(`.integration[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`)
          ?.getAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE),
      ].filter(ExcludeFalsy);

    const referenceUrlFieldValues = referenceIds?.flatMap(
      referenceId =>
        urlFieldValuesByReferenceId
          .get(referenceId)
          ?.map(urlFieldValue => ({ ...urlFieldValue, isReference: true })) ??
        []
    );

    const urlFieldValues = [
      ...(componentUrlFieldValues || []),
      ...(referenceUrlFieldValues || []),
    ];
    if (!urlFieldValues.length) {
      return null;
    }
    return (
      <UrlFieldValueList>
        {urlFieldValues.map(({ label, url, isReference }, index) => (
          <UrlFieldValueContainer key={`${label}_${index}`}>
            <UrlFieldValueTypeIcon
              iconName={isReference ? IconName.REFERENCE : IconName.COMPONENT}
            />
            <UrlFieldValueLink
              href={urlUtils.processUrl(url)}
              target="_blank"
              rel="noreferrer"
              onClick={() => trackURLlinkClicked(element)}
            >
              {label}
            </UrlFieldValueLink>
          </UrlFieldValueContainer>
        ))}
      </UrlFieldValueList>
    );
  };
export default urlFieldValuesPopover;
