import { ArdoqId } from '@ardoq/api-types';
import { settingsBarConsts } from '@ardoq/settings-bar';

export interface WorkspaceProps {
  type: 'workspace';
  name: string;
}
export interface ComponentProps {
  entityType: 'component';
  name: string;
  id: ArdoqId;
  cid: string;
  filterColor: string;
  typeId: string;
  type: string;
  'component-key': string;
  created: string;
  'last-updated': string;
}
export type LinkedComponents = {
  isDisabled: boolean;
  value: ComponentProps[];
  referencedComponentsAsString: string;
};
export interface ReferenceProps {
  entityType: 'reference';
  id: ArdoqId;
  cid: string;
  source: ComponentProps;
  target: ComponentProps;
  displayText: string;
  type: string;
  created: string;
  'last-updated': string;
}

export enum HeaderType {
  SOURCE = 'source',
  TARGET = 'target',
}
interface HeaderModelBase {
  key: string;
  order?: number | null;
  type?: HeaderType;
  cellType: CellTypes;
  isExpandController: boolean;
  id?: string;
  custom?: boolean;
  numberFormatOptions?: string;
}
export interface TextHeaderModel extends HeaderModelBase {
  label: string;
}
interface ReferenceHeaderModel extends HeaderModelBase {
  label: JSX.Element;
  text: string;
}
export type HeaderModel = TextHeaderModel | ReferenceHeaderModel;
export interface TableViewRow {
  // Custom fields
  [key: string]: any;
  entityType: 'component' | 'reference';
  id: string;
  cid: string;
  markdownDescription: string;
  hasDescription: boolean;
  parent?: ComponentProps;
  path?: unknown;
  expandDescription: boolean;
}
export interface TableViewModel {
  allDefaultHeaders: HeaderModel[];
  allReferenceTypeHeaders: HeaderModel[];
  allFieldHeaders: HeaderModel[];
  defaultHeaders: HeaderModel[];
  referenceTypeHeaders: HeaderModel[];
  fieldHeaders: HeaderModel[];
  rows: TableViewRow[] | null;
  expandedDescriptions: Set<string>;
  hasComponentsAvailable: boolean;
  contextComponentId?: ArdoqId;
}
export interface TableViewSettings {
  visibleColumns?: string[];
  hideAllColumns: boolean;
  expandDescription: boolean;
  fullScreen: false;
  [settingsBarConsts.IS_LEGEND_ACTIVE]: boolean;
}

export enum CellTypes {
  NAME,
  NUMBER,
  TEXT,
  PARENT,
  PATH,
  URI,
  EMAIL,
  CHECKBOX,
  REFERENCE_LIST,
  DATE_ONLY,
  DATE_TIME,
  ARDOQ_OID,
  TEXT_AREA,
}

export interface UriData {
  uri: string;
  label: string;
}

export type TableViewProps = {
  viewState: TableViewSettings;
  viewModel: TableViewModel;
};
