import {
  BaseClass,
  INode,
  INodeSizeConstraintProvider,
  Rect,
  Size,
  TextRenderSupport,
  TextWrapping,
} from '@ardoq/yfiles';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH,
} from './consts';
import {
  getGroupHeaderWidthWithoutLabelModernized,
  GROUP_SUBLABEL_FONT,
} from '../../utils';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { measureExpandedGroupNodeLabelWidthModernized } from '../labels/legacyLabelUtils';
import GroupDescendantCountLabelStyle from './GroupDescendantCountLabelStyle';

class GroupSizeConstraintProvider extends BaseClass(
  INodeSizeConstraintProvider
) {
  static Instance = new GroupSizeConstraintProvider();
  private constructor() {
    super();
  }
  override getMaximumSize(_node: INode): Size {
    return Size.INFINITE;
  }
  override getMinimumEnclosedArea(_node: INode): Rect {
    return Rect.EMPTY;
  }
  override getMinimumSize(node: INode): Size {
    const graphGroup = node.tag as CollapsibleGraphGroup | null;
    if (!graphGroup) {
      return Size.EMPTY;
    }
    const { collapsed } = graphGroup;
    if (collapsed && graphGroup.isComponent()) {
      return new Size(158, 152);
    }
    const itemLabels = graphGroup.getItemLabels();
    if (!itemLabels?.mainLabel) {
      return Size.EMPTY;
    }
    const { mainLabel } = itemLabels;
    const groupLabelWidth =
      measureExpandedGroupNodeLabelWidthModernized(mainLabel);
    const groupHeaderWidth =
      groupLabelWidth + getGroupHeaderWidthWithoutLabelModernized(graphGroup);
    const groupSubLabelWidth = graphGroup.subLabel
      ? TextRenderSupport.measureText(
          graphGroup.subLabel,
          GROUP_SUBLABEL_FONT,
          Size.INFINITE,
          TextWrapping.NONE
        ).width
      : 0;
    const descendantCountLabel =
      collapsed &&
      node.labels.find(
        label => label.style instanceof GroupDescendantCountLabelStyle
      );
    const descendantCountLabelWidth =
      (descendantCountLabel &&
        descendantCountLabel.layout.width +
          MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING) ||
      0;

    return new Size(
      Math.min(
        MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH,
        Math.max(
          groupHeaderWidth + descendantCountLabelWidth,
          groupSubLabelWidth + 2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING
        )
      ),
      MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT
    );
  }
}
export default GroupSizeConstraintProvider;
