import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ViewIds } from '@ardoq/api-types';
import { RelationshipsViewOwnProps } from './types';
import { getLeftMenu } from './menus';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { getExportsForSvgView } from '@ardoq/export';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { getViewModel$ } from './viewModel$';
import ZoomControls from 'atomicComponents/Zoomable/ZoomControls';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import {
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend,
  viewLegendCommands,
} from '@ardoq/view-legend';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import {
  ParentChildGraphEdge,
  type RelationshipsLink,
  type Zoom,
  fitWindow,
  fitWindowMargin,
  selectSvgTransition,
  type SetNumeric,
  RELATIONSHIPS_2_ANIMATION_THRESHOLD,
  RELATIONSHIPS_2_MAX_ITERATIONS,
  type RelationshipsViewStreamedProps,
  type RelationshipsViewSettings,
  initializeForce,
  RelationshipsVisualization,
} from '@ardoq/graph';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import RelationshipsViewLegend from './RelationshipsViewLegend';
import RelationshipsViewSettingsBar from './RelationshipsViewSettingsBar';
import { toggleLegend } from 'presentation/viewPane/actions';
import { notifyViewLoading } from 'tabview/actions';
import { noop, uniqWith, isEqual } from 'lodash';
import { ZoomContainer } from 'tabview/relationshipDiagrams/atoms';
import { onViewSettingsUpdate as ardoqFrontOnViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import ViewAndLegendContainer from 'tabview/ViewAndLegendContainer';
import navigateToComponent from 'tabview/navigateToComponent';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import { isPresentationMode } from 'appConfig';
import { setIsCurrentlyHoveredImageBroken } from 'tabview/graphComponent/isCurrentlyHoveredImageBroken$';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import EmptyState from './hierarchical/EmptyState';

const VIEW_ID = ViewIds.NETWORK;

const Container = styled.div`
  user-select: none;
  & text {
    pointer-events: none;
  }
`;

const isParentChildLink = (link: RelationshipsLink) =>
  link.edge instanceof ParentChildGraphEdge;
const getReferenceTypesForLegend = (links: RelationshipsLink[]) => {
  const hasParentChildReferences = links.some(isParentChildLink);

  const referenceModelIds = links
    .filter(link => !isParentChildLink(link))
    .map(link => link.edge.modelId);
  return [
    ...uniqWith(
      referenceModelIds
        .map(modelId => referenceInterface.getModelType(modelId))
        .filter(ExcludeFalsy),
      isEqual
    ),
    ...(hasParentChildReferences ? [ParentChildGraphEdge.getType()] : []),
  ];
};

const iterationsByGraphItems = new Map([
  [RELATIONSHIPS_2_ANIMATION_THRESHOLD, 0],
  [500, 200],
  [250, 250],
  [0, RELATIONSHIPS_2_MAX_ITERATIONS],
]);
const graphItemsThresholds = [...iterationsByGraphItems.keys()];
const getIterations = (graphItemsCount: number) =>
  iterationsByGraphItems.get(
    graphItemsThresholds.find(key => key < graphItemsCount) ?? 0
  ) ?? RELATIONSHIPS_2_MAX_ITERATIONS;

type StreamedProps = RelationshipsViewStreamedProps<RelationshipsViewSettings>;
const RelationshipsView = ({
  viewSettings,
  viewModel: {
    graphNodes,
    graphGroups,
    groupChildren,
    graphLinks,
    forceLinks,
    maxGroupDepth,
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
    errors,
    hasClones,
    noConnectedComponents,
  },
  viewInstanceId,
  onViewLoading = noop,
  onToggleLegend = noop,
  onViewSettingsUpdate = noop,
  toggleNeverShowAgain = noop,
  neverShowAgain = false,
  activeDiffMode,
  heightOffset,
  selectedComponentId,
}: StreamedProps & RelationshipsViewOwnProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [zoom, setZoom] = useState<Zoom | null>(null);
  const [updateNodeDistance, setUpdateNodeDistance] = useState<SetNumeric>(
    () => {}
  );

  const { nodeDistance, animate } = viewSettings;
  // let isLegendActive holds the "active" value of the legend and is temorarely detached from
  // the state due to getFilteredViewSettings$.
  // This value & the viewstate are synchronized as soon an update passes the filter.
  let isLegendActive = viewSettings.isLegendActive ?? false;
  const graphItemsCount = graphNodes.length + graphLinks.length;
  const idealIterations = getIterations(graphItemsCount);
  const iterations = animate ? idealIterations : 0;
  const isViewEmpty = !graphNodes.length || noConnectedComponents;

  useEffect(() => {
    const {
      zoom: newZoom,
      setNodeDistance,
      cleanupForce,
    } = initializeForce({
      container: containerRef.current,
      nodeDistance,
      maxGroupDepth,
      graphNodes,
      forceLinks,
      graphLinks,
      graphGroups,
      groupChildren,
      iterations,
      viewInstanceId,
      onViewLoading,
      selectedEntityId: selectedComponentId,
    });
    setZoom(() => newZoom);
    setUpdateNodeDistance(() => setNodeDistance);

    const toggleLegendSubscription = subscribeToAction(
      toggleLegend,
      ({ legendActive }) => {
        legend.current?.setIsVisible(legendActive);
        settingsBar.current?.setIsLegendActive(legendActive);
      },
      VIEW_ID
    );
    return () => {
      cleanupForce();
      toggleLegendSubscription.unsubscribe();
    };
  }, [
    graphNodes,
    graphLinks,
    graphGroups,
    groupChildren,
    forceLinks,
    maxGroupDepth,
    nodeDistance,
    iterations,
    viewInstanceId,
    onViewLoading,
    selectedComponentId,
  ]);

  const [clearedErrors, setClearedErrors] = useState(false);

  const [clearedHasClones, setClearedHasClones] = useState(false);
  const settingsBar =
    useRef<React.ElementRef<typeof RelationshipsViewSettingsBar>>(null);
  const legend = useRef<React.ElementRef<typeof RelationshipsViewLegend>>(null);
  return (
    <Container
      className={`tab-pane ${VIEW_ID}Tab active`}
      onContextMenu={e => e.preventDefault()}
      data-context-menu={OBJECT_CONTEXT_MENU_NAME}
    >
      <div className="menuContainer">
        <RelationshipsViewSettingsBar
          ref={settingsBar}
          viewId={VIEW_ID}
          leftMenu={getLeftMenu({
            viewSettings,
            traversedOutgoingReferenceTypes,
            traversedIncomingReferenceTypes,
            referenceTypes,
            canAnimate: idealIterations > 0,
            onNodeDistanceUpdated: updateNodeDistance,
            onViewSettingsUpdate,
            viewId: VIEW_ID,
          })}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            viewstate: viewSettings,
            exports: getExportsForSvgView({
              container: () => containerRef.current,
              exportedViewMetadata: {
                name: VIEW_ID,
              },
              ...getSharedExportFunctions(),
            }),
            knowledgeBaseLink: KnowledgeBaseLink.RELATIONSHIPS,
            legendOnClick: () => {
              isLegendActive = !isLegendActive;
              viewLegendCommands.updateViewLegendSettings({
                viewId: VIEW_ID,
                isActive: isLegendActive,
              });
              onToggleLegend(isLegendActive);
            },
            onViewSettingsUpdate,
          })}
          isLegendInitiallyActive={isLegendActive}
        />
      </div>
      <ViewAndLegendContainer ref={containerRef}>
        {isViewEmpty ? (
          <EmptyState
            noConnectedComponents={noConnectedComponents}
            viewId={ViewIds.NETWORK}
          />
        ) : (
          <>
            <ZoomContainer>
              <ZoomControls
                zoomIn={() =>
                  selectSvgTransition(containerRef.current).call(
                    zoom!.scaleBy,
                    1.2
                  )
                }
                zoomOut={() =>
                  selectSvgTransition(containerRef.current).call(
                    zoom!.scaleBy,
                    Math.pow(1.2, -1)
                  )
                }
                zoomCenter={() =>
                  fitWindow({
                    container: containerRef.current!,
                    margin: fitWindowMargin(maxGroupDepth),
                    zoom: zoom!,
                  })
                }
              />
            </ZoomContainer>
            <RelationshipsVisualization
              graphNodes={graphNodes}
              graphGroups={graphGroups}
              graphLinks={graphLinks}
              onComponentDoubleClick={navigateToComponent}
              selectedEntityId={selectedComponentId}
              onBrokenImageMouseEnter={imageElement =>
                dispatchAction(
                  setIsCurrentlyHoveredImageBroken({
                    isCurrentlyHoveredImageBroken: true,
                    imageElement: imageElement,
                  })
                )
              }
              onBrokenImageMouseLeave={imageElement =>
                dispatchAction(
                  setIsCurrentlyHoveredImageBroken({
                    isCurrentlyHoveredImageBroken: false,
                    imageElement: imageElement,
                  })
                )
              }
            />
            <ErrorInfoBox
              errors={clearedErrors ? [] : errors}
              hasClones={!neverShowAgain && !clearedHasClones && hasClones}
              clearHasClones={() => setClearedHasClones(true)}
              clearErrors={() => setClearedErrors(true)}
              isShowNeverAgainSet={neverShowAgain}
              isPresentationMode={isPresentationMode()}
              toggleNeverShowAgain={toggleNeverShowAgain}
            />
            <RelationshipsViewLegend
              initiallyVisible={isLegendActive}
              ref={legend}
              componentTypes={getComponentTypesForLegend(
                [...graphNodes, ...graphGroups].map(node => node.modelId)
              )}
              referenceTypes={getReferenceTypesForLegend(graphLinks)}
              activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
              activeDiffMode={activeDiffMode}
              heightOffset={heightOffset}
            />
          </>
        )}
      </ViewAndLegendContainer>
    </Container>
  );
};

const RelationshipsViewWithSideEffects = (props: StreamedProps) => {
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    ViewIds.NETWORK,
    NEVER_SHOW_AGAIN
  );

  const activeDiffMode = getActiveDiffMode();
  const isScopeDiffMode = isInScopeDiffMode();

  return (
    <RelationshipsView
      {...props}
      toggleNeverShowAgain={toggleNeverShowAgain}
      neverShowAgain={neverShowAgain}
      onViewLoading={(isBusy, viewInstanceId) => {
        dispatchAction(notifyViewLoading({ isBusy, viewInstanceId }));
      }}
      onToggleLegend={isLegendActive => {
        dispatchAction(
          toggleLegend({ viewId: VIEW_ID, legendActive: isLegendActive }),
          VIEW_ID
        );
      }}
      onViewSettingsUpdate={(_, settings, persistent) =>
        ardoqFrontOnViewSettingsUpdate(VIEW_ID, settings, persistent)
      }
      activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
      heightOffset={useViewLegendSubscription()}
    />
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<RelationshipsViewSettings>(VIEW_ID);

const PerformanceTrackedRelationshipsView = (props: StreamedProps) =>
  WithPerformanceTracking('relationships view render', 2000, {
    WrappedComponent: RelationshipsViewWithSideEffects,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: {
      graphNodesCount: props.viewModel.graphNodes.length,
      graphGroupsCount: props.viewModel.graphGroups.length,
      graphLinks: props.viewModel.graphLinks.length,
      maxGroupDepth: props.viewModel.maxGroupDepth,
    },
  });

const RelationshipsViewWithLoadingIndicator = (props: StreamedProps) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedRelationshipsView,
    wrappedProps: props,
    showContentWhileLoading: true,
  });

export default connectInstance(
  RelationshipsViewWithLoadingIndicator,
  getViewModel$(viewSettings$)
);
