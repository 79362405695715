import { BlocksViewNode } from '../types';

export enum Direction {
  None = 0,
  Left = 1,
  Up = 2,
  Right = 4,
  Down = 8,
}

export type BlockGrid = {
  set: (col: number, row: number, value: BlocksViewNode | null) => void;
  get: (col: number, row: number) => BlocksViewNode | null;
  getBlock: (col: number, row: number, direction: Direction) => number;
  isColEmpty: (col: number) => boolean;
  isRowEmpty: (col: number) => boolean;
  isInside: (col: number, row: number) => boolean;
};

export function getBlockGrid(grid: BlocksViewNode): BlockGrid {
  const rows = new Map<number, Map<number, BlocksViewNode | null>>();
  let cacheKey: number | undefined; //  = undefined cached last row index
  let cacheRow: Map<number, BlocksViewNode | null> | undefined; //  = undefined cached last row

  const refreshCacheRow = (row: number) => {
    if (row !== cacheKey) {
      cacheKey = row;
      cacheRow = rows.get(cacheKey);
    }

    return cacheRow;
  };

  const testBounds = (col: number, row: number) => {
    if (
      !grid.colSize ||
      col < 0 ||
      col >= grid.colSize.length ||
      !grid.rowSize ||
      row < 0 ||
      row >= grid.rowSize.length
    ) {
      return false;
    }

    return true;
  };

  const set = (col: number, row: number, value: BlocksViewNode | null) => {
    if (!testBounds(col, row)) {
      return;
    }

    refreshCacheRow(row);

    if (!value) {
      if (cacheRow) {
        if (cacheRow.size === 1) {
          rows.delete(row);
          cacheKey = undefined;
        } else {
          cacheRow.delete(col);
        }
      }

      return;
    }

    if (!cacheRow && !value) {
      /* the whole row is nul so setting one of its entries to nul isn't going to make any difference */
      return;
    }

    if (!cacheRow) {
      cacheRow = new Map<number, BlocksViewNode | null>();
      rows.set(row, cacheRow);
    }

    if (!value) {
      return;
    }

    cacheRow.set(col, value);
  };

  const get = (col: number, row: number) => {
    if (!testBounds(col, row)) {
      return null;
    }

    refreshCacheRow(row);

    return cacheRow?.get(col) ?? null;
  };

  const getBlock = (col: number, row: number, direction: Direction) => {
    if (!testBounds(col, row)) {
      switch (direction) {
        case Direction.Left:
        case Direction.Right:
          return -Infinity;

        case Direction.Up:
        case Direction.Down:
          return +Infinity;
      }

      return 0;
    }

    if (direction === Direction.Left) {
      refreshCacheRow(row);

      if (cacheRow) {
        let c = col;

        while (c > 0) {
          if (cacheRow.has(c - 1)) {
            return c;
          }

          --c;
        }
      }

      return -Infinity;
    }

    if (direction === Direction.Up) {
      let r = row;

      while (r > 0) {
        if (get(col, r - 1)) {
          return r;
        }

        --r;
      }

      return -Infinity;
    }

    if (direction === Direction.Right) {
      refreshCacheRow(row);

      if (cacheRow) {
        let c = col;

        while (c < grid.colSize!.length - 1) {
          if (cacheRow.has(c + 1)) {
            return c;
          }

          ++c;
        }
      }

      return +Infinity;
    }

    if (direction === Direction.Down) {
      let r = row;

      while (r < grid.rowSize!.length - 1) {
        if (get(col, r + 1)) {
          return r;
        }

        ++r;
      }

      return +Infinity;
    }

    return 0;
  };

  const isColEmpty = (col: number) => {
    const rowSize = grid.rowSize?.length;

    if (!rowSize) {
      return false;
    }

    for (let row = 0; row < rowSize; ++row) {
      if (get(col, row)) {
        return false;
      }
    }

    return true;
  };

  const isRowEmpty = (row: number) => {
    const colSize = grid.colSize?.length;

    if (!colSize) {
      return false;
    }

    refreshCacheRow(row);

    if (cacheRow) {
      for (let col = 0; col < colSize; ++col) {
        if (cacheRow.has(col)) {
          return false;
        }
      }
    }

    return true;
  };

  grid.children!.forEach(child => {
    for (let i = child.rowSpan - 1; i >= 0; --i) {
      for (let j = child.colSpan - 1; j >= 0; --j) {
        set(child.col + j, child.row + i, child);
      }
    }
  });

  return {
    set: set,
    get: get,
    getBlock: getBlock,
    isColEmpty: isColEmpty,
    isRowEmpty: isRowEmpty,
    isInside: testBounds,
  };
}
