import { IconSize } from '@ardoq/icons';

export const ONE_REM = 14;

export const MISSING_FIELD_WARN_MESSAGE =
  'Missing field name from the settings.';
export const NOT_IN_USE_TAG_MESSAGE = 'Not in use';

export const EMPTY_DISPLAY_OPTIONS = {
  componentDecoratorImageProps: {},
};

export const ICON_MARGIN = 8;
export const ICON_SIZE = IconSize.MEDIUM;
export const ICON_SPACE = ICON_MARGIN + ICON_SIZE;
