import React from 'react';
import {
  getLabelTextAnchor,
  getLabelX,
  getLabelY,
  getScaleRadialX,
  getScaleRadialY,
  indexToRadians,
} from './utils';
import { AXIS_LABEL_HEIGHT, LABEL_MAX_LENGTH, LEGEND_X_OFFSET } from './consts';
import { colors } from '@ardoq/design-tokens';
import { ChartConfiguration } from './types';
import { WithPopover } from '@ardoq/popovers';
import { returnZero } from '@ardoq/common-helpers';
import { ComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import { MeasureStyledText } from '@ardoq/dom-utils';
import styled from 'styled-components';
import { spiderChartCommands } from '../commands';
import { ArdoqId } from '@ardoq/api-types';

const TEXT_OFFSET = 20;
const FONT_SIZE = 14;

const AxisLabel = styled.text<{ $isUnfocused: boolean }>`
  cursor: pointer;
  opacity: ${props => (props.$isUnfocused ? 0.32 : 1)};
`;

const measureLabel = (text: string) =>
  MeasureStyledText.Instance.getTextWidth({ text, fontSize: `${FONT_SIZE}px` });

type ChartAxisProps = {
  config: ChartConfiguration;
  index: number;
  componentId: ArdoqId;
  labelInfo: ComponentLabelParts;
  isUnfocused: boolean;
};

export const ChartAxis = ({
  index,
  componentId,
  labelInfo,
  config,
  isUnfocused,
}: ChartAxisProps) => {
  const { total, radius, centerX, centerY, legendHeight, width, extraWidth } =
    config;

  const x = getLabelX(centerX, radius, index, total);
  const y = getLabelY(centerY, radius, index, total);
  const textAnchor = getLabelTextAnchor(index, total);
  const isBelowLegend = y + AXIS_LABEL_HEIGHT / 2 > legendHeight;
  const availableWidth =
    textAnchor === 'start'
      ? isBelowLegend
        ? width + extraWidth / 2 - x - TEXT_OFFSET // the label is on the right side, clear of the legend. available width is from x to the viewport right.
        : width - LEGEND_X_OFFSET - x - TEXT_OFFSET // the label is on the right side, but not clear of the legend. available width is from x to the legend left.
      : textAnchor === 'end'
        ? x + extraWidth / 2 - TEXT_OFFSET // the label is on the left side. available width is from x to the left side of the viewport.
        : (width - LEGEND_X_OFFSET - x) * 2 - TEXT_OFFSET; // the label is centered. available width is up to the legend left-- but for a centered label, there is space on the left too. so we use the formula for right-side labels and double it.

  const fullLabel = truncateComponentLabel({
    ...labelInfo,
    measure: returnZero,
    width: Infinity,
  });

  const maxWidth =
    fullLabel.length > LABEL_MAX_LENGTH
      ? Math.min(
          availableWidth,
          measureLabel(`${fullLabel.slice(0, LABEL_MAX_LENGTH)}...`)
        )
      : availableWidth;

  const label = truncateComponentLabel({
    ...labelInfo,
    measure: measureLabel,
    width: maxWidth,
  });

  const isLabelTruncated = label !== fullLabel;
  const textProps = {
    width: maxWidth,
    x,
    y,
    textAnchor,
  };

  const handleAxisClick = (e: React.MouseEvent<SVGTextElement, MouseEvent>) => {
    e.stopPropagation();
    spiderChartCommands.setFocusedItemState(componentId);
  };

  return (
    <g key={fullLabel} className="axis">
      <line
        x1={centerX}
        y1={centerY}
        x2={getScaleRadialX(centerX, 1, radius, indexToRadians(index, total))}
        y2={getScaleRadialY(centerY, 1, radius, indexToRadians(index, total))}
        className="line"
        stroke={colors.grey60}
        opacity={isUnfocused ? 0.32 : 1}
        strokeWidth="1px"
      />
      {isLabelTruncated ? (
        <WithPopover content={fullLabel} followsMouse>
          <AxisLabel
            $isUnfocused={isUnfocused}
            onClick={handleAxisClick}
            {...textProps}
          >
            {label}
          </AxisLabel>
        </WithPopover>
      ) : (
        <AxisLabel
          $isUnfocused={isUnfocused}
          onClick={handleAxisClick}
          {...textProps}
        >
          {fullLabel}
        </AxisLabel>
      )}
    </g>
  );
};
