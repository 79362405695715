import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  clearDashboardData,
  fetchDashboardData,
  persistLoadedDashboardData,
  setDashboardApiError,
  setDashboardBranchId,
  setDashboardLoadingState,
  setLoadedDashboard,
} from './DashboardBuilder/actions';
import { LoadingState } from './DashboardBuilder/types';
import { switchMap, tap } from 'rxjs';
import {
  DashboardTrackingEventsNames,
  transformDashboardAPIData,
} from '@ardoq/dashboard';
import { navigateToDashboardModule } from '../router/navigationActions';
import { ArdoqId } from '@ardoq/api-types';
import { DashboardModule } from 'dashboard/types';
import { withLatestFrom } from 'rxjs/operators';
import loadedDashboards$ from './DashboardBuilder/loadedDashboards$';
import { trackEvent } from 'tracking/tracking';
import {
  fetchUsageMetricsDashboardData,
  persistMetricsDashboardData,
  setMetricsDashboardApiError,
} from './UsageMetrics/actions';
import { METRICS_DSB_ID } from './consts';
import { surveyAccessControlInterface } from 'resourcePermissions/accessControlHelpers/surveys';
import {
  api,
  dashboardApi,
  getErrorStatusCode,
  handleError,
  presentationApi,
} from '@ardoq/api';
import {
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
} from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';
import { SelectedBranch } from 'utils/searchBranchSelection';

const getDashboard = (
  selectedDashboardId: ArdoqId,
  presentationId?: ArdoqId,
  branchId?: SelectedBranch
) =>
  presentationId
    ? presentationApi.fetchDashboard(presentationId, selectedDashboardId)
    : dashboardApi.fetch(selectedDashboardId, branchId);

// Builder and reader
const handleFetchDashboardData = routine(
  ofType(fetchDashboardData),
  extractPayload(),
  withLatestFrom(loadedDashboards$),
  tap(([{ selectedDashboardId, loadFromCache }, { loadedDashboards }]) => {
    // If we support branches, we cannot rely on the cache, as the branchId is not part of the cache key
    const supportsBranching = hasFeature(
      Features.DEMO_ADVANCED_SEARCH_IN_SCENARIOS
    );
    if (
      loadedDashboards[selectedDashboardId] &&
      loadFromCache &&
      !supportsBranching
    ) {
      dispatchAction(setLoadedDashboard(loadedDashboards[selectedDashboardId]));
    } else {
      dispatchAction(setDashboardLoadingState(LoadingState.LOADING));
    }
  }),
  switchMap(([{ selectedDashboardId, presentationId, branchId }]) =>
    getDashboard(selectedDashboardId!, presentationId, branchId)
  ),
  handleError(error => {
    // Trigger builder error state
    trackEvent(DashboardTrackingEventsNames.DASHBOARD_ERROR);
    api.logErrorIfNeeded(error);

    dispatchAction(
      setDashboardApiError({
        apiError: {
          message: getArdoqErrorMessage(error),
          traceId: getArdoqErrorTraceId(error),
          code: getErrorStatusCode(error),
        },
        apiLoadingState: LoadingState.LOADING_ERROR,
      })
    );
  }),
  tap(response => {
    // NOTE: If you make any changes here, make sure to also check if it's needed in usage metrics dashboard or discover!
    const dashboardData = transformDashboardAPIData(
      response,
      surveyAccessControlInterface.canAccessSurvey
    );

    dispatchAction(persistLoadedDashboardData(dashboardData));
    dispatchAction(setLoadedDashboard(dashboardData));
  })
);

const handleNavigateToDashboardModule = routine(
  ofType(navigateToDashboardModule),
  extractPayload(),
  tap(
    ({
      selectedDashboardId,
      loadFromCache,
      presentationId,
      dashboardModule,
    }) => {
      if (dashboardModule === DashboardModule.USAGE_METRICS) {
        dispatchAction(fetchUsageMetricsDashboardData());
      } else if (!selectedDashboardId) {
        dispatchAction(clearDashboardData());
      } else
        dispatchAction(
          fetchDashboardData({
            selectedDashboardId,
            loadFromCache,
            presentationId,
          })
        );
    }
  )
);

const handleSetDashboardBranchId = routine(
  ofType(setDashboardBranchId),
  extractPayload(),
  tap(({ branchId, dashboardId }) => {
    dispatchAction(
      fetchDashboardData({
        selectedDashboardId: dashboardId,
        branchId,
      })
    );
  })
);

// Usage metrics dashboard (reader)
const handleFetchMetricsDashboardData = routine(
  ofType(fetchUsageMetricsDashboardData),
  switchMap(() => presentationApi.fetchMetrics(METRICS_DSB_ID)),
  handleError(error => {
    api.logErrorIfNeeded(error);

    dispatchAction(
      setMetricsDashboardApiError({
        message: getArdoqErrorMessage(error),
        traceId: getArdoqErrorTraceId(error),
        code: getErrorStatusCode(error),
      })
    );
  }),
  tap(response => {
    dispatchAction(persistMetricsDashboardData(response));
  })
);

export default collectRoutines(
  handleNavigateToDashboardModule,
  handleFetchDashboardData,
  handleFetchMetricsDashboardData,
  handleSetDashboardBranchId
);
