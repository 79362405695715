import { action$, ofType } from '@ardoq/rxbeach';
import { mainPaneCreated } from './actions';
import { MAIN_PANE_LEFT, PaneInterface } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const createViewInterface = () => {
  let leftPane: PaneInterface | null = null;
  let rightPane: PaneInterface | null = null;

  action$
    .pipe(ofType(mainPaneCreated))
    .subscribe(({ payload: { location, mainPane } }) => {
      if (location === MAIN_PANE_LEFT) leftPane = mainPane;
      else rightPane = mainPane;
    });

  const getMainView = () => (leftPane ? leftPane.getActiveView() : null);
  const getSecondView = () => (rightPane ? rightPane.getActiveView() : null);
  const getViewById = (viewId: string) =>
    leftPane ? leftPane.getViewById(viewId) : null;
  const getActiveViewIds = () =>
    [getMainView(), getSecondView()].filter(ExcludeFalsy).map(view => view.id);

  return {
    getMainView,
    getSecondView,
    getViewById,
    getActiveViewIds,
  };
};

export default createViewInterface;
