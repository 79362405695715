import { useState } from 'react';
import { ModalTemplate, ModalSize } from '@ardoq/modal';
import { Select, SelectOption } from '@ardoq/select';
import { IconName } from '@ardoq/icons';
import { ArdoqId } from '@ardoq/api-types';
import { noop } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { openScenario } from 'scope/actions';
import { ScenarioBasicDetails } from '../types';
import { STRINGS } from './consts';
import { interpolate } from '@ardoq/common-helpers';
import { HowTo } from './HowTo';

type WorkspaceInUseDialogProps = {
  workspaceName: string;
  scenariosUsingTheWorkspace: ScenarioBasicDetails[];
  closeDialog?: () => void;
};

const WorkspaceInUseDialog = ({
  workspaceName,
  scenariosUsingTheWorkspace,
  closeDialog = noop,
}: WorkspaceInUseDialogProps) => {
  const [scenarioIdToOpen, setScenarioIdToOpen] = useState<
    ArdoqId | undefined
  >();

  const handlePrimaryButtonClick = () => {
    closeDialog();
    if (scenarioIdToOpen)
      dispatchAction(
        openScenario({
          scenarioId: scenarioIdToOpen,
          skipLoadingBar: true,
          trackingClickSource: 'assetManager',
        })
      );
  };

  const scenariosList = scenariosUsingTheWorkspace.map(({ name }) => ({
    iconName: IconName.SCENARIO,
    strongText: name,
  }));

  const scenarioOptions = scenariosUsingTheWorkspace.map<SelectOption<string>>(
    ({ name, _id }) => ({
      label: name,
      value: _id,
    })
  );

  const primaryButton = {
    primaryButtonClickId: 'workspace-in-use-dialog--open-scenario',
    primaryButtonText: STRINGS.PRIMARY_BUTTON_LABEL,
    onPrimaryButtonClick: handlePrimaryButtonClick,
    isPrimaryButtonDisabled: !scenarioIdToOpen,
  };

  const secondaryButton = {
    secondaryButtonClickId: 'workspace-in-use-dialog--cancel',
    secondaryButtonText: STRINGS.SECONDARY_BUTTON_LABEL,
    onSecondaryButtonClick: closeDialog,
  };

  return (
    <ModalTemplate
      headerText={STRINGS.TITLE}
      subtitle={STRINGS.SUBTITLE}
      text={interpolate(STRINGS.MESSAGE.WHY, { workspaceName })}
      listOptions={scenariosList}
      text2={
        <>
          <HowTo workspaceName={workspaceName} />
          <Select
            label={STRINGS.SELECT.LABEL}
            options={scenarioOptions}
            placeholder={STRINGS.SELECT.PLACEHOLDER}
            value={scenarioIdToOpen}
            onChange={selected => {
              if (selected) setScenarioIdToOpen(selected.value);
            }}
          />
        </>
      }
      {...primaryButton}
      {...secondaryButton}
      modalSize={ModalSize.S}
    />
  );
};

export default WorkspaceInUseDialog;
