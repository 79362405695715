import { createGlobalStyle, css } from 'styled-components';
import { CssEntry } from './types';
import { connect } from '@ardoq/rxbeach';
import modelCss$ from './modelCss$';
import { map } from 'rxjs/operators';
import {
  REFERENCE_PATH_STROKE_WIDTH,
  LIBACKGROUND_STROKE_WIDTH,
} from '@ardoq/graph';

const baseStyles = css`
  .component,
  .integration,
  .workspace {
    cursor: pointer;
  }

  text.integration {
    opacity: 0.8;
    stroke-dasharray: unset !important;
  }

  line.integration,
  path.integration,
  path.parent-child-reference {
    fill: none;
    stroke-width: ${REFERENCE_PATH_STROKE_WIDTH}px;
    pointer-events: stroke;
    color: black;
    stroke: black;
  }

  .parent-child-reference:not(.hoverPath) {
    stroke-dasharray: 2;
  }

  .libackground {
    stroke-width: ${LIBACKGROUND_STROKE_WIDTH};
  }
`;
const ModelCssManagerGlobalStyle = createGlobalStyle<{
  entries: CssEntry[];
}>`
${baseStyles}
${({ entries }) =>
  entries
    .map(([selector, definition]) => `${selector} { ${definition} }`)
    .join('\n')}`;

export default connect(
  ModelCssManagerGlobalStyle,
  modelCss$.pipe(map(entries => ({ entries })))
);
