import * as fonts from 'app/designTokens/fonts';

// DEPRECATED FONTS DEFINITIONS
// use definitions from design tokens instead
export const fontSizeDefinitions_DEPRECATED = {
  LARGE: fonts.pageViewLargeTemp,
  MEDIUM: fonts.pageViewMediumTemp,
  SMALL: fonts.pageViewSmallTemp,
  EXTRA_SMALL: fonts.pageViewExtraSmallTemp,
};
