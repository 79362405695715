import { useState } from 'react';
import { uniq } from 'lodash';
import { LoadingWrapper } from '@ardoq/status-ui';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { LazyPaginationController, Pagination } from '@ardoq/pagination';
import GremlinResultsTable from './GremlinResultsTable/GremlinResultsTable';
import GremlinResultsColumnsSelect from './GremlinResultsColumnsSelect';
import { getResultType } from './utils';
import { GremlinResult, GremlinResultType } from './types';
import { RESULTS_PER_PAGE, SURROUNDING_PAGES_TO_CACHE } from './consts';
import { LazyLoadResults } from '@ardoq/pagination';
import { getUniqueSearchId } from '../utils';
import { logError } from '@ardoq/logging';
import { InlineText } from '@ardoq/typography';
import { FlexBox } from '@ardoq/layout';
import { spacing } from '@ardoq/design-tokens';

const getResultKeys = (results: GremlinResult[]) => {
  try {
    return uniq(results.flatMap(result => Object.keys(result)));
  } catch (e) {
    return [];
  }
};

type GremlinResultsProps = {
  result?: GremlinResult[];
  printable?: boolean;
  totalResults?: number | null;
  loadResults?: LazyLoadResults;
  resultsId?: string;
  showRaw?: boolean;
  style?: React.CSSProperties;
  usePagination?: boolean;
};

const GremlinResults = ({
  result,
  printable,
  totalResults,
  loadResults,
  resultsId,
  showRaw,
  style,
  usePagination,
}: GremlinResultsProps) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const updateSelectedColumns = (input: string[] | null) => {
    if (input === null) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(input);
    }
  };

  const resultKeys =
    result && getResultType(result) === GremlinResultType.MAP
      ? getResultKeys(result)
      : [];

  return (
    <>
      {!printable && (
        <FlexBox justify="space-between" paddingBottom="small" align="end">
          <FlexBox>
            <InlineText variant="text1">Number of hits: </InlineText>
            <InlineText variant="text1Bold">&nbsp;{totalResults}</InlineText>
          </FlexBox>
          {!showRaw && (
            <FlexBox>
              <GremlinResultsColumnsSelect
                results={result || []}
                selectedColumns={selectedColumns}
                onSelectedColumnsChange={updateSelectedColumns}
              />
            </FlexBox>
          )}
        </FlexBox>
      )}
      <ErrorBoundary logError={logError}>
        <LazyPaginationController
          perPage={RESULTS_PER_PAGE}
          dataSource={result || []}
          totalResults={totalResults || 0}
          loadResults={loadResults || (() => {})}
          resultsId={resultsId || getUniqueSearchId()}
          surroundingPagesToCache={SURROUNDING_PAGES_TO_CACHE}
          render={({
            currentPageDataSource,
            currentPageNumber,
            perPage,
            totalResults,
            sortById,
            sortOrder,
            sortBy,
            onPageSelect,
            isLoading,
          }) => (
            <>
              <LoadingWrapper loading={isLoading} style={style}>
                <GremlinResultsTable
                  results={
                    !printable || usePagination
                      ? currentPageDataSource
                      : result || []
                  }
                  selectedColumns={selectedColumns}
                  resultKeys={resultKeys}
                  showRaw={showRaw}
                  printable={printable}
                  sortBy={sortBy}
                  sortById={sortById}
                  sortOrder={sortOrder}
                />
              </LoadingWrapper>
              {(!printable || usePagination) && totalResults > perPage && (
                <Pagination
                  style={{
                    justifyContent: 'center',
                    padding: `${spacing.s8} 0 ${spacing.s4}`,
                  }}
                  currentPageNumber={currentPageNumber}
                  perPage={perPage}
                  totalResults={totalResults}
                  onPageSelect={onPageSelect}
                />
              )}
            </>
          )}
        />
      </ErrorBoundary>
    </>
  );
};

export default GremlinResults;
