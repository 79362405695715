import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

const reportNavigation$ = persistentReducedStream(
  'reportNavigation$',
  defaultState,
  reducers
);

export default reportNavigation$;
