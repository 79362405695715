import { DiffType } from '@ardoq/data-model';
import {
  MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH,
} from 'yfilesExtensions/styles/modernized/consts';

export const visualDiffModes = new Set([
  DiffType.ADDED,
  DiffType.REMOVED,
  DiffType.CHANGED,
  DiffType.UNCHANGED,
]);

export const MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING = 8;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT = 24;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_ICON_HEIGHT = 20;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_HORIZONTAL_MARGIN = 4;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_ONE_LINE_HEIGHT = 15;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_TWO_LINES_HEIGHT = 33;
export const MODERNIZED_BLOCK_DIAGRAM_EXPANDER_SIZE = 16;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_WIDTH =
  MODERNIZED_BLOCK_DIAGRAM_NODE_WIDTH -
  2 * MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_PADDING;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_MAX_HEIGHT = 51;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_LABEL_MARGIN_TOP = 5;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER = 55;
export const MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_ICON_SIZE = 24;
/** the height of a collapsed non-component group. also the height of the group header stripe in a collapsed component group or an expanded group. */
export const MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT =
  MODERNIZED_BLOCK_DIAGRAM_GROUP_HEADER_HEIGHT +
  2 * MODERNIZED_BLOCK_DIAGRAM_GROUP_PADDING;
export const MODERNIZED_BLOCK_DIAGRAM_GROUP_SUBLABEL_HEIGHT = 20;
export const MODERNIZED_BLOCK_DIAGRAM_IMAGE_CIRCLE_STROKE_WIDTH = 4;
export const MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_VERTICAL_PADDING = 4;
export const MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_HORIZONTAL_PADDING = 8;
export const MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING = 6;
export const MODERNIZED_BLOCK_DIAGRAM_PREFERRED_MINIMUM_GROUP_WIDTH = 340;
