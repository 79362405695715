import { COMPONENT_HIERARCHY_PADDING } from '../consts';

const DIVIDER = '@@divider@@';
const NULL = '@@null@@';
const EMPTY = '@@empty@@';

// #region address serialization/deserialization. this exists in order to take advantage of Set<string>, eliminating duplicates while avoiding performance-intensive array comparisons
const serializeAddress = (address: (string | null)[]) =>
  address.length === 0
    ? EMPTY
    : address.map(token => (token === null ? NULL : token)).join(DIVIDER);

const deserializeAddress = (serializedAddress: string) =>
  serializedAddress === EMPTY
    ? []
    : serializedAddress
        .split(DIVIDER)
        .map(token => (token === NULL ? null : token));

const deserializeAddresses = (serializedAddresses: Set<string>) =>
  Array.from(serializedAddresses).map(deserializeAddress);
// #endregion

/**
 * reducer function to generate a set with all padding permutations for a given list of addresses.
 * note the result will include alternate permutations only-- it will not include the original address.
 */
const permuteAndSerializeAddress = (
  result: Set<string>,
  currentAddress: (string | null)[]
) => {
  if (!currentAddress.length) {
    result.add(EMPTY);
    return result;
  }
  // start with the first token as the partial address. end before the full address.
  for (
    let addressIndex = 1;
    addressIndex < currentAddress.length;
    addressIndex++
  ) {
    const partialAddress = currentAddress.slice(0, addressIndex);
    const paddingLength = currentAddress.length - addressIndex;
    const paddedAddress = partialAddress.concat(
      Array(paddingLength).fill(COMPONENT_HIERARCHY_PADDING)
    );
    result.add(serializeAddress(paddedAddress));
  }
  return result;
};

/**
 * permutes an array of Component Matrix row or column addresses with the padding entries necessary to generate a complete view model.
 *
 * results will not include the original addresses passed in-- only alternate permutations will be returned.
 *
 * example input:
 * ```
 * [
 *  ['94e64335f3e88a25769afc5c', '7270898a5457db006f513d72', '3c7ae8d478b1792e829626f9']
 * ]
 * ```
 *
 * example output:
 * ```
 * [
 *  ['94e64335f3e88a25769afc5c', '@@padding@@', '@@padding@@']
 *  ['94e64335f3e88a25769afc5c', '7270898a5457db006f513d72', '@@padding@@']
 * ]
 * ```
 */
const paddedAddressPermutations = (addresses: (string | null)[][]) =>
  deserializeAddresses(
    addresses.reduce(permuteAndSerializeAddress, new Set<string>())
  );

export default paddedAddressPermutations;
