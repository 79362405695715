/**
 * @final
 */
export enum ChildGraphInclusionPolicy {
  /**
   * An inclusion policy that ensures that no child graphs of the root nodes are
   * evaluated.
   */
  NONE = 'NONE',
  /**
   * An inclusion policy that evaluates and adds an one-level deep child graph
   * of the root nodes.
   */
  ONE_LEVEL = 'ONE_LEVEL',
  /**
   * An inclusion policy that evaluates and adds child graphs recursively so
   * that it covers the full depth of the graph.
   */
  RECURSIVE = 'RECURSIVE',
}
