import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { SearchParams } from '@ardoq/api';
import { SEARCH_RESULTS_PER_PAGE } from '../../../search/types';
import { QueryBuilderQuery } from '@ardoq/api-types';
import {
  pageWasSet,
  queryWasSet,
  searchParamsWereSet,
  selectedFieldsWereSet,
} from './actions';
import advancedSearch$, {
  AdvancedSearchShape,
} from '../../../search/AdvancedSearch/advancedSearch$';
import { isEqual, pick } from 'lodash';
import {
  queryAdvancedSearch,
  queryAdvancedSearchSuccess,
} from '../../../search/AdvancedSearch/actions';
import { PayloadQueryAdvancedSearch } from '../../../search/AdvancedSearch/types';
import {
  AdvancedSearchStreamData,
  AdvancedSearchTableStreamShape,
} from './types';

const INITIAL_PAGE = 1;

const pickAdvancedSearchStreamData = (
  streamData: AdvancedSearchShape
): AdvancedSearchStreamData =>
  pick(streamData, [
    'fields',
    'results',
    'ruleErrorMessages',
    'searchError',
    'total',
    'selectedFieldNames',
  ]);

const initialState: AdvancedSearchTableStreamShape = {
  isSearching: false,
  page: INITIAL_PAGE,
  searchParams: { size: SEARCH_RESULTS_PER_PAGE, from: 0 },
  selectedFields: [],
  queryBuilderRules: null,
  advancedSearchStreamData: {
    fields: [],
    results: [],
    searchError: null,
    ruleErrorMessages: [],
    total: 0,
    selectedFieldNames: [],
  },
};

const setPageAndUpdateSearchParams = (
  state: AdvancedSearchTableStreamShape,
  payload: number
): AdvancedSearchTableStreamShape => {
  if (payload === state.page) return state;
  const pageSize = state.searchParams.size ?? SEARCH_RESULTS_PER_PAGE;
  const from = payload === 1 ? 0 : (payload - 1) * pageSize;
  return {
    ...state,
    page: payload,
    searchParams: { ...state.searchParams, from },
  };
};

const startSearchingAndMaybeResetPagination = (
  state: AdvancedSearchTableStreamShape,
  payload: PayloadQueryAdvancedSearch
): AdvancedSearchTableStreamShape => {
  const shouldResetPage =
    !isEqual(state.queryBuilderRules, payload.queryBuilderRules) ||
    !isEqual(state.searchParams.sortBy, payload.sortBy) ||
    !isEqual(state.searchParams.sortOrder, payload.sortOrder);
  return {
    ...state,
    isSearching: true,
    queryBuilderRules: payload.queryBuilderRules,
    page: shouldResetPage ? INITIAL_PAGE : state.page,
    searchParams: shouldResetPage
      ? { ...state.searchParams, from: 0 }
      : state.searchParams,
  };
};

const stopSearching = (
  state: AdvancedSearchTableStreamShape
): AdvancedSearchTableStreamShape => ({ ...state, isSearching: false });

const handleSetSearchParams = (
  state: AdvancedSearchTableStreamShape,
  payload: SearchParams
): AdvancedSearchTableStreamShape => ({
  ...state,
  searchParams: { ...state.searchParams, ...payload },
});

const selectFields = (
  state: AdvancedSearchTableStreamShape,
  payload: Array<string>
): AdvancedSearchTableStreamShape => ({ ...state, selectedFields: payload });

const setQueryAndResetSearchParams = (
  state: AdvancedSearchTableStreamShape,
  payload: QueryBuilderQuery
): AdvancedSearchTableStreamShape => ({
  ...state,
  queryBuilderRules: payload,
  page: INITIAL_PAGE,
  searchParams: initialState.searchParams,
});

const getAdvancedSearchStreamData = (
  state: AdvancedSearchTableStreamShape,
  streamData: AdvancedSearchStreamData
): AdvancedSearchTableStreamShape => ({
  ...state,
  advancedSearchStreamData: streamData,
  selectedFields: state.selectedFields.length
    ? state.selectedFields
    : streamData.selectedFieldNames,
});

const advancedSearchStreamData$ = advancedSearch$.pipe(
  map(pickAdvancedSearchStreamData)
);

export const advancedSearchTable$ = reducedStream(
  'advancedSearchTable-metaModelAnalytics',
  initialState,
  [
    reducer(pageWasSet, setPageAndUpdateSearchParams),
    reducer(searchParamsWereSet, handleSetSearchParams),
    reducer(selectedFieldsWereSet, selectFields),
    reducer(queryWasSet, setQueryAndResetSearchParams),
    reducer(queryAdvancedSearch, startSearchingAndMaybeResetPagination),
    reducer(queryAdvancedSearchSuccess, stopSearching),
    streamReducer(advancedSearchStreamData$, getAdvancedSearchStreamData),
  ]
);
