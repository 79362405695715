import { useRef } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { connect } from '@ardoq/rxbeach';
import { SettingsBar } from '@ardoq/settings-bar';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import TreemapLayout from './Components/TreemapLayout';
import { ExportContainer, ViewWrapper } from './Components/atoms';
import { getViewModel$ } from './viewModel$';
import { ViewModel, ViewSettings } from './types';
import { getLeftMenu } from './leftMenu';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import getExportSettings from 'viewSettings/getExportSettings';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import GraphViewLegend from 'tabview/graphViews/graphViewLegend/GraphViewLegend';
import { ViewLegendContainer } from '@ardoq/graph';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { isPresentationMode } from 'appConfig';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import EmptyState from './Components/EmptyState';
import { treemapCommands } from './commands';

const VIEW_ID = ViewIds.TREEMAP;

const viewSettings$ = getViewSettingsStream<ViewSettings>(VIEW_ID);

function TreeMapView({
  data,
  settings,
  legend,
  workspaceFields,
  fieldsInUse,
  hasIncomingReferences,
  hasOutgoingReferences,
  hasComponentsAvailable,
  activeFieldLabel,
}: ViewModel) {
  const containerElement = useRef<HTMLDivElement>(null);

  const isEmptyView = !data.children?.length;

  return (
    <>
      {!isPresentationMode() && (
        <SettingsBar
          viewId={VIEW_ID}
          leftMenu={getLeftMenu(
            settings,
            workspaceFields,
            fieldsInUse,
            hasIncomingReferences,
            hasOutgoingReferences
          )}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            viewstate: settings,
            exports: getExportSettings(VIEW_ID, containerElement),
            knowledgeBaseLink: KnowledgeBaseLink.TREEMAP,
            isLegendDisabled: isEmptyView,
            isExportsDisabled: isEmptyView,
            onViewSettingsUpdate,
          })}
        />
      )}
      <ExportContainer
        ref={containerElement}
        onClick={() => treemapCommands.setFocusedItemState(null)}
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
      >
        <ViewWrapper $hasData={!isEmptyView}>
          {isEmptyView ? (
            <EmptyState
              hasComponentsAvailable={hasComponentsAvailable}
              sizeBasedOn={settings.sizeBasedOn}
              hasIncomingReferences={hasIncomingReferences}
              hasOutgoingReferences={hasOutgoingReferences}
              fieldsInUse={fieldsInUse}
              workspaceFields={workspaceFields}
              activeFieldLabel={activeFieldLabel}
            />
          ) : (
            <TreemapLayout data={data} />
          )}
        </ViewWrapper>

        <ViewLegendContainer
          heightOffset={useViewLegendSubscription()}
          visible={legend.isActive}
        >
          <GraphViewLegend
            componentTypes={legend.componentTypes}
            referenceTypes={[]}
            showComponentShapes={false}
            hasCollapsedNodes={false}
            hasReferenceParents={false}
            hasNonComponentNodes={false}
            isUserDefinedGrouping={false}
            showReferencesAsLines={false}
            showComponentSwatches={true}
            showReferenceConditionalFormatting={false}
            activeDiffMode={null}
          />
        </ViewLegendContainer>
      </ExportContainer>
    </>
  );
}

export default connect(TreeMapView, getViewModel$(viewSettings$));
