import {
  AdjacencyTypes,
  GraphComponent,
  IEdge,
  IGraph,
  INode,
  ISelectionModel,
} from '@ardoq/yfiles';
import Context from 'context';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateSelectedReferences } from 'streams/selection/actions';
import { edgeModelIds } from './edgeUtil';
import { NodeModel } from 'components/WorkspaceHierarchies/models/types';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { componentGraphSelectionChanged } from 'componentSelection/componentSelectionActions';
import { updateDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';

type GraphComponentExtension = GraphComponent & {
  handlingTreeSelection?: boolean;
  handlingItemSelection?: boolean;
};

const onlyContextNodeIsSelected = (
  graphComponent: GraphComponent,
  selectedNodeIds: string[]
) => {
  const contextNode = Context.component();
  return (
    selectedNodeIds.length === 1 &&
    contextNode &&
    contextNode.cid === selectedNodeIds[0] &&
    graphComponent.selection.selectedEdges.size === 0
  );
};

export const resetNodesAndEdgesConnectedStyles = (graph: IGraph) => {
  graph.nodes.forEach(node => {
    if (node.tag) {
      node.tag.isTransparentized = false;
    }
  });
  graph.edges.forEach(edge => {
    edge.tag.isTransparentized = false;
  });
};

const markUnconnectedNodesAndEdges = (
  graph: IGraph,
  connectedNodes: Set<INode>,
  connectedEdges: Set<IEdge>
) => {
  graph.nodes.forEach(node => {
    if (!connectedNodes.has(node)) {
      if (node.tag) {
        node.tag.isTransparentized = true;
      }
    }
  });
  graph.edges.forEach(edge => {
    if (!connectedEdges.has(edge)) {
      edge.tag.isTransparentized = true;
    }
  });
};

export const getConnectedNodesAndEdges = (
  graph: IGraph,
  selectedNodes: ISelectionModel<INode> | INode[],
  selectedEdges: ISelectionModel<IEdge> | IEdge[]
) => {
  const connectedNodes = new Set<INode>();
  const connectedEdges = new Set<IEdge>();

  selectedNodes.forEach(currentNode => {
    connectedNodes.add(currentNode);

    // Add outgoing edges and their target nodes
    graph.edgesAt(currentNode, AdjacencyTypes.OUTGOING).forEach(edge => {
      const targetNode = edge.targetNode;
      connectedEdges.add(edge);
      if (targetNode) {
        connectedNodes.add(targetNode);
      }
    });

    // Add incoming edges and their source nodes
    graph.edgesAt(currentNode, AdjacencyTypes.INCOMING).forEach(edge => {
      const sourceNode = edge.sourceNode;
      connectedEdges.add(edge);
      if (sourceNode) {
        connectedNodes.add(sourceNode);
      }
    });
  });

  selectedEdges.forEach(edge => {
    const sourceNode = edge.sourceNode;
    const targetNode = edge.targetNode;
    if (sourceNode) {
      connectedNodes.add(sourceNode);
    }
    if (targetNode) {
      connectedNodes.add(targetNode);
    }
    connectedEdges.add(edge);
  });

  return { connectedNodes, connectedEdges };
};

export const updateNodesAndEdgesConnectednessToSelection = (
  graphComponent: GraphComponentExtension
) => {
  const selectedNodes = graphComponent.selection.selectedNodes;
  const selectedEdges = graphComponent.selection.selectedEdges;
  const graph = graphComponent.graph;

  resetNodesAndEdgesConnectedStyles(graph);

  if (!(selectedNodes.size || selectedEdges.size)) {
    graphComponent.invalidate();
    return;
  }

  const { connectedNodes, connectedEdges } = getConnectedNodesAndEdges(
    graph,
    selectedNodes,
    selectedEdges
  );

  markUnconnectedNodesAndEdges(graph, connectedNodes, connectedEdges);

  graphComponent.invalidate();
};

export const handleSelectionChanged = (
  graphComponent: GraphComponentExtension,
  /** true if the details drawer should update according to the new component selection. */
  shouldUpdateDetailsDrawer: boolean
) => {
  if (graphComponent.handlingTreeSelection) return;
  graphComponent.handlingItemSelection = true;
  const selectedNodeIds = graphComponent.selection.selectedNodes
    .map(node => dataModelId(node.tag))
    .toArray();
  const selectedReferenceIds = graphComponent.selection.selectedEdges
    .toArray()
    .flatMap(edgeModelIds);
  dispatchAction(
    updateSelectedReferences({ referenceIds: selectedReferenceIds })
  );
  if (onlyContextNodeIsSelected(graphComponent, selectedNodeIds)) {
    graphComponent.selection.clear();
    return;
  }
  dispatchAction(
    componentGraphSelectionChanged({ graphSelection: selectedNodeIds })
  );
  if (shouldUpdateDetailsDrawer && selectedNodeIds.length === 1) {
    dispatchAction(updateDetailsDrawer(selectedNodeIds));
  }
  updateNodesAndEdgesConnectednessToSelection(graphComponent);
};

export const handleTreeSelectionChanged = (
  graphComponent: GraphComponentExtension,
  selection: NodeModel[],
  groupNodesAreSelectable: boolean
) => {
  if (graphComponent.handlingItemSelection) {
    graphComponent.handlingItemSelection = false;
    return;
  }
  graphComponent.handlingTreeSelection = true;
  const selectedNodeIds = selection.map(treeNode => treeNode.id);
  if (onlyContextNodeIsSelected(graphComponent, selectedNodeIds)) {
    // no need to apply the selection indicator here.
    graphComponent.selection.clear();
  } else {
    graphComponent.graph.nodes.forEach(node =>
      graphComponent.selection.setSelected(
        node,
        Boolean(
          node.tag &&
            node.tag.isComponent &&
            node.tag.isComponent() &&
            node.tag.dataModel &&
            selectedNodeIds.includes(node.tag.dataModel.cid) &&
            (groupNodesAreSelectable || !graphComponent.graph.isGroupNode(node))
        ) // group nodes are invisible in explorer view, so they can't be selected.
      )
    );
  }
  graphComponent.handlingTreeSelection = false;
};
