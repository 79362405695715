import { RESOURCE_BASE_URL } from 'appConfig';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { addToPresentation } from 'viewSettings/exportHandlers';

export const getStyleSheets = () =>
  Array.from(document.styleSheets).filter(
    sheet =>
      (!sheet.href || sheet.href.startsWith(RESOURCE_BASE_URL)) &&
      sheet instanceof CSSStyleSheet
  );

export const getSharedExportFunctions = () => ({
  isInScopeDiffMode: isInScopeDiffMode(),
  trackingFunction: trackExportingVisualization,
  addToPresentation,
  getStyleSheets,
});
