import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { action$, reducer, reduceState, streamReducer } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import defaultState from 'views/defaultState';
import type { ViewIds } from '@ardoq/api-types';
import type { Observable } from 'rxjs';
import {
  composeNewSettings,
  extractChangedSettings,
  throwDefaultViewStateError,
} from './utils';
import {
  getLoadSavedViewSettingsStream,
  saveAndTrackViewSettingsChange,
} from './userSettingsStream';
import type { ExtractPayload } from '@ardoq/rxbeach';
import type UserSettings from 'models/UserSettings';

const viewSettingsStreams = new Map<ViewIds, Observable<ViewSettings>>();
const viewSettingsCurrentSettingsStreams = new Map<ViewIds, Observable<any>>();

export type ViewSettings<T extends Record<string, any> = Record<string, any>> =
  {
    currentSettings: T;
    changedSettings: [name: keyof T, value: T[keyof T]][];
    persistent: boolean;
  };

/**
 * The purpose of SavedViewSettingsStream is to populate the state with saved settings once
 * upon creation.
 *
 * TODO:
 * To improve the clarity of expressing this, we should call getLoadSavedViewSettingsStream
 * as a side-effect of ensureViewSettingsStreams, and update the state via updateViewSettings
 * action.
 */
const viewSettingsReducer = (
  { currentSettings }: ViewSettings,
  savedSettings: UserSettings
): ViewSettings => ({
  currentSettings: {
    ...currentSettings,
    ...savedSettings,
  },
  changedSettings: [],
  persistent: false,
});
const getSaveSettingsHandler = (viewId: ViewIds) =>
  streamReducer(getLoadSavedViewSettingsStream(viewId), viewSettingsReducer);

const updateViewSettingsReducer = (
  viewSettings: ViewSettings,
  {
    settings,
    persistent,
    settingsPath,
  }: ExtractPayload<typeof updateViewSettings>
) => {
  /**
   * TODO: confirm the faith of the SEQUENTIAL_TRAVERSAL and the usage of settingsPath.
   * Simplify the state update calculation if settingsPath is deprecated.
   */
  const nextSettings = composeNewSettings(
    viewSettings.currentSettings,
    settings,
    settingsPath
  );

  const changedSettings = extractChangedSettings({
    settingsPath,
    nextSettings,
    settings,
  });

  return {
    currentSettings: nextSettings,
    changedSettings,
    persistent,
  };
};
const createViewSettingsStream = <T extends Record<string, any>>(
  viewId: ViewIds,
  defaultSettings: T
) => {
  const initialState = {
    currentSettings: defaultSettings,
    changedSettings: [],
    persistent: false,
  };

  return action$.pipe(
    filter(
      action => (action.payload as { viewId?: string })?.viewId === viewId
    ),
    reduceState<ViewSettings>(`viewSettings-${viewId}`, initialState, [
      reducer(updateViewSettings, updateViewSettingsReducer),
      getSaveSettingsHandler(viewId),
    ]),
    tap(({ persistent, changedSettings }) => {
      if (persistent && changedSettings.length > 0) {
        saveAndTrackViewSettingsChange({ changedSettings, viewId });
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
};

const ensureViewSettingsStreams = (viewId: ViewIds) => {
  if (!viewSettingsStreams.has(viewId)) {
    const state = defaultState.get(viewId);
    if (!state) {
      throwDefaultViewStateError(viewId);
    }

    const settingsStream = createViewSettingsStream(viewId, state);
    viewSettingsStreams.set(viewId, settingsStream);
    viewSettingsCurrentSettingsStreams.set(
      viewId,
      settingsStream.pipe(map(({ currentSettings }) => currentSettings))
    );
  }
};
export const getViewSettingsStreamWithChanges = <
  T extends Record<string, any> = Record<string, any>,
>(
  viewId: ViewIds
): Observable<ViewSettings<T>> => {
  ensureViewSettingsStreams(viewId);
  return viewSettingsStreams.get(viewId) as Observable<ViewSettings<T>>;
};

export const getViewSettingsStream = <T = any>(
  viewId: ViewIds
): Observable<T> => {
  ensureViewSettingsStreams(viewId);
  return viewSettingsCurrentSettingsStreams.get(viewId) as Observable<T>;
};
