import { getCurrentLocale, localeCompare } from '@ardoq/locale';

type Primitive = string | number | undefined | boolean | null;

type RecursiveList<T> = Array<T | RecursiveList<T>>;

type RecursiveListOfOrValue<T> = RecursiveList<T> | T;

interface RecursiveDict {
  [key: string]: RecursiveListOfOrValue<RecursiveDict | Primitive>;
}

export const flattenDict = (
  value: RecursiveListOfOrValue<Primitive | RecursiveDict>
): (string | number | boolean)[] => {
  const locale = getCurrentLocale();

  if (!(value instanceof Object)) {
    return [value ?? ''];
  }

  if (Array.isArray(value)) {
    return value.flatMap(flattenDict);
  }

  return Object.entries(value)
    .sort(([a], [b]) => localeCompare(a, b, locale))
    .flatMap(([key, value]) => [key, flattenDict(value)])
    .flat();
};
