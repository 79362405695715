import styled from 'styled-components';
import Person1 from './Person1.svg';

export const ImageWrapper = styled.div`
  flex: 0 0 300px;
  height: 300px;
  width: 300px;
`;

export const PersonImage = styled.img.attrs({ src: Person1 })``;
