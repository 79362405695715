import { ArdoqId, PersistedExternalDocument } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { IconName, Icon } from '@ardoq/icons';
import { Stack } from '@ardoq/layout';
import { WithPopover } from '@ardoq/popovers';
import { dispatchAction } from '@ardoq/rxbeach';
import { Tag } from '@ardoq/status-ui';
import { Header5 } from '@ardoq/typography';
import {
  deleteExternalDocument,
  openExternalDocument,
} from 'externalDocuments/actions';
import styled from 'styled-components';

const deleteDocument = (document: PersistedExternalDocument) => {
  dispatchAction(deleteExternalDocument(document));
};

const ExternalDocumentTag = ({
  document,
  editable,
}: {
  document: PersistedExternalDocument;
  editable: boolean;
}) => {
  return (
    <Tag
      key={document.componentId}
      leftIcon={{
        name: IconName.LUCIDCHART,
      }}
      label={document.displayText}
      onClick={() => {
        dispatchAction(openExternalDocument(document));
      }}
      onCloseClick={
        editable
          ? () => {
              deleteDocument(document);
            }
          : undefined
      }
    />
  );
};

const LucidchartIcon = styled(Icon).attrs({ iconName: IconName.LUCIDCHART })`
  cursor: pointer;
`;

const ExternalDocumentActions = ({
  componentId,
  externalDocuments,
}: {
  componentId: ArdoqId;
  externalDocuments: PersistedExternalDocument[];
}) => {
  const hasWriteAccess = componentInterface.hasWriteAccess(componentId);

  const lucidchartDocuments = externalDocuments.filter(
    doc => doc.provider === 'lucidchart'
  );

  if (!lucidchartDocuments.length) return null;

  return (
    <WithPopover
      key={`${componentId}-lucidchart-external-documents`}
      content={() => {
        return (
          <Stack gap="medium">
            <Header5 color="textSubtle">Linked documents</Header5>
            <Stack align="start" gap="xsmall">
              {lucidchartDocuments.map(document => (
                <ExternalDocumentTag
                  key={`${document.externalId}-lucidchart-document-tag`}
                  document={document}
                  editable={hasWriteAccess}
                />
              ))}
            </Stack>
          </Stack>
        );
      }}
    >
      <LucidchartIcon />
    </WithPopover>
  );
};

export default ExternalDocumentActions;
