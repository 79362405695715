import { APIEntityType } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { IconName } from '@ardoq/icons';
import {
  getEditPropertiesMenuItem,
  getDeleteReferenceMenuItem,
  getHistoryMenuItem,
} from 'contextMenus/menuItems';
import { rectCenter, rectEncloseRects } from '../misc/geometry';
import { BlocksViewLink, BlocksViewNode, Controller } from '../types';
import { getComponentMenu } from 'contextMenus/componentMenu';

export const linkContextMenuOptions = (
  view: Controller,
  link: BlocksViewLink
) => {
  const options: DropdownOption[] = [];

  options.push({
    iconName: IconName.ZOOM_TO_FIT,
    label: 'Zoom to fit',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      const fit = rectEncloseRects([
        link.bounds!,
        link.sourceProxy!.bounds,
        link.targetProxy!.bounds,
      ]);
      view?.fit(fit);
    },
  });

  options.push({ type: DropdownOptionType.DIVIDER, label: null });

  options.push(getEditPropertiesMenuItem([], [link.modelId], () => {})!);
  options.push(getDeleteReferenceMenuItem([link.modelId], () => {}));
  options.push(
    getHistoryMenuItem(link.modelId, APIEntityType.REFERENCE, () => {})
  );

  return options;
};

export const nodeContextMenuOptions = (
  view: Controller,
  node: BlocksViewNode,
  selection: Set<BlocksViewNode>
) => {
  const ids = selection.has(node)
    ? Array.from(selection).map(({ modelId }) => modelId!)
    : [node.modelId!];
  const componentIds = ids.filter(componentInterface.isComponent);
  if (!componentIds.length) {
    return [];
  }
  const [viewportNodeX, viewportNodeY] = view.projection.toViewport(
    rectCenter(node.bounds)
  );
  const { x: canvasLeft, y: canvasTop } = view.canvas.getBoundingClientRect();
  const x = canvasLeft + viewportNodeX;
  const y = canvasTop + viewportNodeY;
  return (
    getComponentMenu({
      componentIds,
      x,
      y,
      isViewpointMode: true,
      eventTargetModelId: node.modelId!,
      target: null,
      event: null,
    }) ?? []
  );
};

export const rootContextMenuOptions = (_view: Controller) => {
  const options: DropdownOption[] = [];

  // perhaps
  //  fit all
  //  expand all
  //  collapse all

  return options;
};
