import { filter, map, withLatestFrom } from 'rxjs';
import {
  ArdoqId,
  ResourceType,
  ComponentBatchDeleteResponse,
  WebSocketDeletePayload,
} from '@ardoq/api-types';
import { websocket$ } from 'sync/websocket$';
import {
  ArdoqEvent,
  isWebSocketBulkDelete,
  isWebSocketDelete,
} from 'sync/types';
import { allocateToBuffer } from '@ardoq/rxbeach';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';

const isWebSocketDeleteOrBulkDeleteComponentOrReference = (
  event: ArdoqEvent<unknown>
): event is
  | ArdoqEvent<ComponentBatchDeleteResponse>
  | ArdoqEvent<WebSocketDeletePayload> =>
  (isWebSocketDelete(event) || isWebSocketBulkDelete(event)) &&
  (ardoqEventOperations.isOfResourceType(event, ResourceType.COMPONENT) ||
    ardoqEventOperations.isOfResourceType(event, ResourceType.REFERENCE));

const toIdSet = (ids: ArdoqId[][]): Set<ArdoqId> => new Set(ids.flat());

const deleteOrBulkDeleteToIdList = (
  event:
    | ArdoqEvent<WebSocketDeletePayload>
    | ArdoqEvent<ComponentBatchDeleteResponse>
) => {
  if (isWebSocketDelete(event)) {
    return [event.data._id];
  }

  return [...event.data.componentIds, ...event.data.referenceIds];
};

export const removedComponentAndReferenceIds$ = websocket$.pipe(
  withLatestFrom(loadedGraph$),
  filter(([, { isViewpointMode }]) => isViewpointMode),
  map(([websocket]) => websocket),
  filter(isWebSocketDeleteOrBulkDeleteComponentOrReference),
  map(deleteOrBulkDeleteToIdList),
  allocateToBuffer(),
  map(toIdSet)
);
