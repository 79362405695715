import * as d3 from 'd3';
import { NODE_MARGIN, NODE_PADDING } from '../consts';
import { forceLayout } from '../forceLayout';
import { packLayout } from '../packLayout';
import type { LayoutType, RelationshipsNode } from '../types';
import { simpleHash } from './util';

/** number of cycles for the biggest groups */
const CYCLES_MIN = 5;
/** number of cycles for the smallest groups */
const CYCLES_MAX = 20;

const layoutNodeChildren = (
  layoutType: LayoutType,
  node: RelationshipsNode,
  getNodeRadius: (node: RelationshipsNode) => number
) => {
  const isExpandedGroup = node.children?.length && node.open;
  if (!isExpandedGroup) {
    return {
      entropy: 0,
      minimumEnclosingCircle: { x: 0, y: 0, r: getNodeRadius(node) },
      minimumEnclosingCircleForChildren: undefined,
    };
  }

  const children = node.children!;
  /* run simulation on open group's children */

  const p = 1.0 - Math.min(1, children.length / 50);

  const cycles = Math.ceil(CYCLES_MIN * (1 - p) + CYCLES_MAX * p);
  const seed = simpleHash(node.id);

  const entropy =
    layoutType === 'packLayout'
      ? 0
      : forceLayout(node.children!, NODE_MARGIN, d3.randomLcg(seed), cycles);

  const minimumEnclosingCircle =
    layoutType === 'packLayout'
      ? packLayout(children, NODE_MARGIN, d3.randomLcg(seed))
      : d3.packEnclose(children);

  // clone initial minimumEnclosingCircle before it is mutated
  const minimumEnclosingCircleForChildren = { ...minimumEnclosingCircle };

  // nodes should not shrink to smaller than their collapsed size when expanded.
  minimumEnclosingCircle.r = Math.max(
    minimumEnclosingCircle.r + (node.isSynthetic ? 0 : NODE_PADDING),
    getNodeRadius(node)
  );
  // #region position the group's children with respect to the group
  children.forEach(child => {
    const cx = Math.round(child.x - minimumEnclosingCircle.x);
    const cy = Math.round(child.y - minimumEnclosingCircle.y);

    if (child.x !== cx || child.y !== cy) {
      child.x = cx;
      child.y = cy;
    }
  });

  return {
    entropy,
    minimumEnclosingCircle,
    minimumEnclosingCircleForChildren,
  };
  // #endregion
};
export default layoutNodeChildren;
