import { ArdoqId } from '@ardoq/api-types';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { checkHasWriteAccess } from './utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TrackedContextMenus, trackContextMenuOptionClick } from './tracking';
import {
  addComponentToScenario,
  getAddChildComponentMenuItem,
  getComponentNavigateToMenuItem,
  getFilterMenuItem,
} from './menuItems';

type GetScenarioContextComponentContextMenuArgs = {
  componentIds: ArdoqId[];
  isViewpointMode: boolean;
} & Pick<GetContextMenuOptionsArguments, 'target'>;
export const getScenarioContextComponentContextMenu = ({
  componentIds,
  target,
  isViewpointMode,
}: GetScenarioContextComponentContextMenuArgs): DropdownItem[] | null => {
  const isSingleComponent = componentIds.length === 1;

  if (!isSingleComponent) {
    return null;
  }

  const scenarioContextComponentContextTrackingFn = (optionTitle: string) =>
    trackContextMenuOptionClick({
      clickedElement: target,
      contextMenuNamespace:
        TrackedContextMenus.SCENARIO_CONTEXT_COMPONENT_CONTEXT_MENU,
      selectedOptionTitle: optionTitle,
    });

  const workspaceId = componentInterface.getWorkspaceId(componentIds[0]);

  const hasWriteAccess = checkHasWriteAccess({
    workspaceIds: [workspaceId || ''],
    componentIds,
  });
  const isComponentLocked = componentInterface.isLocked(componentIds[0]);
  const canHaveChildren = componentInterface.canComponentHaveChildren(
    componentIds[0]
  );
  const showEditingMenuItems =
    hasWriteAccess && canHaveChildren && !isComponentLocked;

  return [
    ...(showEditingMenuItems
      ? [
          getAddChildComponentMenuItem(
            componentIds[0],
            scenarioContextComponentContextTrackingFn,
            isViewpointMode
          ),
          addComponentToScenario(
            componentIds,
            scenarioContextComponentContextTrackingFn
          ),
        ]
      : []),

    getComponentNavigateToMenuItem(
      componentIds[0],
      scenarioContextComponentContextTrackingFn
    ),
    isViewpointMode
      ? null
      : getFilterMenuItem({
          componentId: componentIds[0],
          trackingFunction: scenarioContextComponentContextTrackingFn,
          isScenarioContextComponent: true,
        }),
  ].filter(ExcludeFalsy);
};
