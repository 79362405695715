import { createElement } from 'react';
import { INode, IRenderContext, Size, SvgVisual } from '@ardoq/yfiles';
import {
  isContextNode,
  isSelectedRelatedNode,
} from 'yfilesExtensions/styles/nodeDecorator';
import { Node } from 'graph/node';
import { CompElementExtension } from 'aqTypes';
import { DiffType } from '@ardoq/data-model';

import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { GraphNode, ensureRoot, disposeCallback } from '@ardoq/graph';
import { Node as OldGraphNode } from 'graph/node';
import ArdoqNode from './ArdoqNode';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import SparkMD5 from 'spark-md5';
import { CONTEXT_HIGHLIGHT_PADDING } from './consts';
import { determineStyleSize } from './util';
import { classes } from '@ardoq/common-helpers';
import LegacyArdoqNodeStyleBase from './LegacyArdoqNodeStyleBase';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';

const getCSSArgs = { useAsBackgroundStyle: false, partialLoadIndicator: true };
const getCSS = (node: INode) =>
  node.tag instanceof GraphNode
    ? node.tag.getCSS(getCSSArgs)
    : (node.tag as OldGraphNode).getCSS(getCSSArgs);
class ArdoqNodeStyle extends LegacyArdoqNodeStyleBase {
  private shapeTemplateHref: string;
  private _size: Size | null = null;
  constructor(shapeTemplateId: string) {
    super();
    this.shapeTemplateHref = `#${shapeTemplateId}`;
  }

  override get size() {
    if (this._size === null) {
      this._size = determineStyleSize(this.shapeTemplateHref);
    }
    return this._size;
  }

  override getHash(node: INode) {
    return SparkMD5.hash(
      [
        node.tag ? (node.tag as Node).getCSS() : '',
        node.tag.getVisualDiffType(),
        node.tag ? node.tag.isGhost : false,
        node.tag ? node.tag.isTransparentized : '',
        isContextNode(node),
        isSelectedRelatedNode(node),
        node.layout.width,
        node.layout.height,
      ].join('-')
    );
  }
  override createVisual(context: IRenderContext, node: INode): SvgVisual {
    const result = super.createVisual(context, node);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }
  protected override render(container: SVGElement, node: INode) {
    (container as SVGElement & CompElementExtension).comp =
      node.tag && node.tag.dataModel;

    const graphNode = node.tag as GraphNode | OldGraphNode;
    container.setAttribute('class', classes(getCSS(node), 'skipContextUpdate'));

    const visualDiffType = graphNode?.getVisualDiffType();
    const visualDiffClass =
      getCssClassFromDiffType(visualDiffType) ||
      (graphNode instanceof GraphNode && graphNode.isGhost
        ? 'visual-diff-unchanged'
        : '');

    const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
    const isContext = isContextNode(node);
    const isSelectedRelated = isSelectedRelatedNode(node);
    const { width, height } = node.layout;
    const id = dataModelId(graphNode);
    const tooltipAttributes = getChangedPopover(id, visualDiffType);
    if (id) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, id);
    }
    ensureRoot(container).render(
      createElement(ArdoqNode, {
        templateHref: this.shapeTemplateHref,
        isPlaceholder,
        isContext,
        isSelectedRelated,
        width,
        height,
        visualDiffClass,
        tooltipAttributes,
        contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
        isTransparentized: graphNode.isTransparentized,
      })
    );
  }
}

export default ArdoqNodeStyle;
