import { SelectionToggle } from 'components/WorkspaceHierarchies/actions/navigatorActions';
import { ComponentSelection } from './componentSelectionTypes';
import {
  ComponentGraphSelectionChanged,
  ComponentTreeSelectionChanged,
  ComponentTreeSelectionStartSelected,
} from './componentSelectionActions';

// Only relevant for the tree selection, graph selection doesn't care about the
// selected context.
const contextSelectionChanged = (
  state: ComponentSelection,
  contextSelection: string
): ComponentSelection => ({
  ...state,
  treeSelection: contextSelection ? [contextSelection] : [],
  selectionStart: contextSelection,
});

const componentTreeSelectionStartSelected = (
  state: ComponentSelection,
  { selectionStart }: ComponentTreeSelectionStartSelected
): ComponentSelection => ({
  ...state,
  treeSelection: selectionStart ? [selectionStart] : [],
  selectionStart,
});

const componentGraphSelectionChanged = (
  state: ComponentSelection,
  { graphSelection }: ComponentGraphSelectionChanged
): ComponentSelection => {
  return {
    ...state,
    graphSelection,
  };
};

const componentTreeSelectionChanged = (
  state: ComponentSelection,
  { treeSelection }: ComponentTreeSelectionChanged
): ComponentSelection => ({
  ...state,
  treeSelection,
});

const toggleTreeSelection = (
  state: ComponentSelection,
  { nodeId }: SelectionToggle
): ComponentSelection => {
  if (nodeId === state.selectionStart) {
    return state;
  }
  const newTreeSelection = getNewSelection(state.treeSelection, nodeId);
  return {
    ...state,
    treeSelection: newTreeSelection.sort(),
  };
};

const getNewSelection = (selection: string[], nodeId: string) => {
  if (selection.includes(nodeId)) {
    return selection.filter(id => id !== nodeId);
  }
  return [...selection, nodeId];
};

export const componentSelectionOperations = {
  componentTreeSelectionStartSelected,
  contextSelectionChanged,
  componentGraphSelectionChanged,
  componentTreeSelectionChanged,
  toggleTreeSelection,
};
