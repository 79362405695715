import Backbone from 'backbone';
import BasicModel from 'models/basicmodel';
import Components from 'collections/components';
import Workspaces from 'collections/workspaces';
import Filters from 'collections/filters';
import Fields from 'collections/fields';
import Context from 'context';
import * as encodingUtils from '@ardoq/html';
import { getIn } from 'utils/collectionUtil';
import { getSanitizedRgbaClassName } from '@ardoq/color-helpers';
import { APIReferenceType, ArdoqId } from '@ardoq/api-types';
import logMissingModel from './logMissingModel';
import { DEFAULT_REFERENCE_TYPES, IMPLICIT_REFTYPE_ID } from './model';
import { logError } from '@ardoq/logging';
import { getApiUrl } from 'backboneExtensions';
import { getModelUrl } from './utils/scenarioUtils';
import { isValidRefType } from 'models/utils/refTypeUtils';
import { NO_FILTER_CLASS_NAME } from '@ardoq/global-consts';
import { ReferenceLabelSource } from '@ardoq/data-model';
import type {
  ComponentBackboneModel,
  Model,
  Reference as ReferenceBackboneModel,
  ReferenceTypeInfo,
} from 'aqTypes';
import type { ModelType } from './ModelType';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyReferenceLocked,
  notifyReferenceUnlocked,
} from 'streams/references/ReferenceActions';
import { currentFilterColor } from '../collections/filters';
import { isString, sortBy } from 'lodash';
import { currentTimestamp } from '@ardoq/date-time';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const Reference = BasicModel.extend({
  idAttribute: '_id',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/reference`,
  url: function (this: ReferenceBackboneModel) {
    return getModelUrl(this.urlRoot, this.isNew(), this.id);
  },
  mustBeSaved: false,
  validationErrors: null,
  getPossibleParents: function (
    this: ReferenceBackboneModel,
    callback?: (possibleParents: ReferenceTypeInfo[]) => void,
    limitToCurrentWs = false
  ) {
    const components = limitToCurrentWs
      ? Components.collection.filter(
          comp => comp.get('rootWorkspace') === this.get('rootWorkspace')
        )
      : Components.collection.toArray();
    const values: ReferenceTypeInfo[] = components.map(comp => {
      return {
        val: comp.getId(),
        label: `${comp.getFullPathName()}  [${
          (comp.getMyType() as ModelType).name
        }]`,
      };
    });
    const possibleParents = sortBy(values, function (el) {
      return el.label;
    });
    callback?.(possibleParents);
    return possibleParents;
  },
  defaults: {
    type: IMPLICIT_REFTYPE_ID,
    source: null,
    target: null,
    order: 0,
    description: '',
    sourceCardinality: null,
    targetCardinality: null,
  },
  initialize: function (this: ReferenceBackboneModel) {
    this.on(
      'change',
      (model: ComponentBackboneModel) => {
        model.lastChangeTime = currentTimestamp();
        model.validationErrors = null;
        this.mustBeSaved = true;
      },
      this
    );

    this.on(
      'change:order',
      function (
        this: ReferenceBackboneModel,
        a: ReferenceBackboneModel,
        b: any
      ) {
        let n = Number.parseInt(b, 10);
        n = !Number.isInteger(n) ? 0 : n;
        this.set('order', n);
      }
    );
    this.on(
      'change:type',
      (reference: ReferenceBackboneModel, rawType: any) => {
        const parsedType = Number.parseInt(rawType, 10);
        if (Number.isInteger(parsedType)) {
          this.set('type', parsedType);
          const sourceComponentType = reference.getSource().getMyType();
          const targetComponentType = reference.getTarget().getMyType();
          currentUserInterface.setLastUsedReferenceTypeTriple(
            sourceComponentType!,
            targetComponentType!,
            (this.getRefType(parsedType) as APIReferenceType).name
          );
        } else {
          logError(
            Error('Tried to change reference type using non-number'),
            null,
            { nonNumberUsedToTryToChangeReference: rawType }
          );
        }
      }
    );
    this.mustBeSaved = this.isNew();

    this.on('merged', function (this: ReferenceBackboneModel) {
      this.changedDescription = false;
    });

    this.on(
      'sync',
      function (this: ReferenceBackboneModel) {
        this.changedDescription = false;
        this.mustBeSaved = false;
      },
      this
    );

    if (this.attributes.source && this.attributes.target) {
      this.initializeComponent('source');
      this.initializeComponent('target');
    } else {
      this.once('sync', () => {
        this.initializeComponent('source');
        this.initializeComponent('target');
      });
    }

    if (this.isNew() && Context) {
      if (!this.get('model')) {
        this.set('model', this.getModelId());
      }
      if (!this.get('rootWorkspace')) {
        this.set('rootWorkspace', this.getSource().get('rootWorkspace'));
      }
    }

    this.on('change:target', () => {
      if (this.attributes.target === '') {
        this.attributes.target = null;
      }
    });
  },
  getReferenceTypes: function (
    this: ReferenceBackboneModel,
    model: Model,
    callback: (values: ReferenceTypeInfo[]) => void
  ) {
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'reference',
      });
      const result: ReferenceTypeInfo[] = [];
      callback?.(result);
      return result;
    }
    const values = Object.entries(model.getReferenceTypes()).map(
      ([key, val]) => {
        return {
          val: Number.parseInt(key, 10),
          label: val.name,
        };
      }
    );
    if (callback) {
      callback(values);
    }
    return values;
  },
  initializeComponent: function (
    this: ReferenceBackboneModel,
    type: 'source' | 'target'
  ) {
    const comp = this.get(type);
    if (typeof comp === 'object') {
      const id = comp.getId();
      if (id) {
        this.set(type, id);
      } else {
        comp.once('sync', () => {
          this.initializeComponent(type);
        });
      }
    }
  },
  getType: function (this: ReferenceBackboneModel) {
    return this.get('type');
  },
  getCSS: function (this: ReferenceBackboneModel) {
    let filterColor = currentFilterColor(this);
    if (filterColor) {
      if (filterColor.startsWith('#'))
        filterColor = filterColor.replace('#', 'filter');
      // Provide valid CSS class name
      if (filterColor.toLowerCase().startsWith('rgba'))
        filterColor = getSanitizedRgbaClassName(filterColor);
    } else {
      filterColor = NO_FILTER_CLASS_NAME;
    }
    const model = this.getModel();
    const type = this.get('type');
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'reference',
        additionalInfo: { type },
      });
    }
    const modelId = model ? model.id : '';

    return `integration m${modelId} nr${type} ${this.id} ${this.cid} ${filterColor}`;
  },
  getReferenceSyntax: function () {
    return '->';
  },
  getModelType: function (this: ReferenceBackboneModel) {
    const model = this.getModel();
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'reference',
        additionalInfo: { type: this.attributes.type },
      });
      return DEFAULT_REFERENCE_TYPES[2];
    }
    const types = model.getReferenceTypes();
    return types[this.attributes.type] || types[2];
  },
  getLinkTypeDescription: function (this: ReferenceBackboneModel) {
    const type = this.getModelType();
    return type ? encodingUtils.escapeHTML(type.name) : 'Implicit';
  },
  getTarget: function (this: ReferenceBackboneModel) {
    return Components.collection.get(this.get('target'));
  },
  getSource: function (this: ReferenceBackboneModel) {
    return Components.collection.get(this.get('source'));
  },
  getTargetId: function (this: ReferenceBackboneModel): string {
    return this.get('target');
  },
  getSourceId: function (this: ReferenceBackboneModel): string {
    return this.get('source');
  },
  getCompId: function (
    this: ReferenceBackboneModel,
    comp: ComponentBackboneModel
  ) {
    let id = null;
    if (comp !== null) {
      if (isString(comp)) {
        id = comp;
      } else {
        id = comp.getId();
      }
    }
    return id;
  },
  addTarget: function (
    this: ReferenceBackboneModel,
    comp: ComponentBackboneModel
  ) {
    const targetId = this.getCompId(comp);
    this.set('target', targetId);
  },
  addSource: function (
    this: ReferenceBackboneModel,
    comp: ComponentBackboneModel
  ) {
    const sourceId = this.getCompId(comp);
    this.set('source', sourceId);
  },
  flipSourceTarget: function (
    this: ReferenceBackboneModel,
    wsFlipRefType?: string | number
  ) {
    const source = this.getSourceId();
    const target = this.getTargetId();

    const sourceWsId = Components.collection
      .get(source)!
      .getWorkspace()!
      .getId();
    const targetWsId = Components.collection
      .get(target)!
      .getWorkspace()!
      .getId();
    const targetModelId: ArdoqId = Components.collection
      .get(target)!
      .get('model');

    const options: {
      source: string;
      target: string;
      rootWorkspace?: string;
      targetWorkspace?: string;
      type?: string | number;
      model?: string;
    } = {
      source: target,
      target: source,
    };

    if (sourceWsId !== targetWsId) {
      const isWsFlipRefTypeValid = isValidRefType(wsFlipRefType);
      options.rootWorkspace = targetWsId;
      options.targetWorkspace = sourceWsId;
      options.type = isWsFlipRefTypeValid ? wsFlipRefType : IMPLICIT_REFTYPE_ID;
      options.model = targetModelId;
    }

    this.set(options);
    this.trigger('flipSourceAndTarget', this);
  },
  getFields: function (this: ReferenceBackboneModel) {
    return Fields.collection.getByReference(this);
  },
  getDescription: function (this: ReferenceBackboneModel) {
    return this.get('description');
  },
  /**
   * @deprecated use the referenceInterface.getDisplayLabel() instead.
   *
   * @param escapeFieldHTML I suspect this should always be false, but to avoid a risky change I'm initially defaulting it to true.
   */
  getRawLabel: function (this: ReferenceBackboneModel, escapeFieldHTML = true) {
    const firstActiveRefLabelFilter = Filters.getRefLabelFilter()[0];
    const currentSetting = !firstActiveRefLabelFilter
      ? ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE
      : getIn<ReferenceLabelSource>(
          firstActiveRefLabelFilter,
          ['value'],
          ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE
        );
    if (currentSetting === ReferenceLabelSource.NONE) {
      return '';
    }

    const displayText = this.attributes.displayText;

    const needsRefType =
      currentSetting === ReferenceLabelSource.REFERENCE_TYPE ||
      (currentSetting === ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE &&
        !displayText);
    const refType = needsRefType ? getIn(this.getRefType(), ['name']) : null;

    switch (currentSetting) {
      case ReferenceLabelSource.REFERENCE_TYPE:
        return refType;
      case ReferenceLabelSource.DISPLAY_TEXT:
        return displayText;
      case ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE:
        return displayText || refType;
      default: {
        const field = this.getFields().find(
          field => field.name() === currentSetting
        );

        if (!field) {
          return '';
        }

        const fieldLabel = field.getRawLabel();

        const fieldValue = this.attributes[currentSetting];

        const excludeTime = !firstActiveRefLabelFilter?.attributes?.includeTime;

        const formattedValue = field
          ? field.format(fieldValue, undefined, escapeFieldHTML, excludeTime)
          : fieldValue;

        if (!formattedValue) {
          return '';
        }

        const displayedFieldValue = field.isFormatHtmlEscaped()
          ? encodingUtils.unescapeHTML(formattedValue) || ''
          : formattedValue;

        return `${fieldLabel}: ${displayedFieldValue}`;
      }
    }
  },
  getLabel: function (this: ReferenceBackboneModel) {
    return encodingUtils.escapeHTML(this.getRawLabel());
  },
  getShortName: function (this: ReferenceBackboneModel) {
    const source = this.getSource();
    const target = this.getTarget();
    return `${
      source ? source.get('name') : 'Unknown'
    } ${this.getReferenceSyntax()} ${target ? target.get('name') : 'Unknown'}`;
  },
  name: function (this: ReferenceBackboneModel) {
    return this.getName();
  },
  getName: function (this: ReferenceBackboneModel) {
    const source = this.getSource();
    const target = this.getTarget();
    return `${
      source ? encodingUtils.escapeHTML(source.getFullPathName()) : 'Unknown'
    } ${this.getReferenceSyntax()} ${
      target ? encodingUtils.escapeHTML(target.getFullPathName()) : 'Unknown'
    }`;
  },
  getRawName: function (this: ReferenceBackboneModel) {
    const source = this.getSource();
    const target = this.getTarget();
    return `${
      source ? source.getFullPathName() : 'Unknown'
    } ${this.getReferenceSyntax()} ${
      target ? target.getFullPathName() : 'Unknown'
    }`;
  },
  validate: function (
    this: ReferenceBackboneModel,
    attrs: Record<string, any>
  ) {
    if (attrs.order && !Number.isInteger(Number.parseInt(attrs.order, 10))) {
      attrs.order = 0;
      return 'Order is not a number';
    }

    if (attrs.type && !Number.isInteger(Number.parseInt(attrs.type, 10))) {
      attrs.type = 0;
      return 'Type is not a number';
    }
    return false;
  },
  getModel: function (this: ReferenceBackboneModel) {
    const workspace = Workspaces.collection.get(this.get('rootWorkspace'));
    return workspace ? workspace.getModel() : null;
  },
  getModelId: function (this: ReferenceBackboneModel) {
    const workspace = Workspaces.collection.get(this.get('rootWorkspace'));
    return getIn(workspace, ['componentModel'], null);
  },
  getRefType: function (this: ReferenceBackboneModel, id?: string | number) {
    const model = this.getModel();
    if (model) {
      const referenceTypes = model.getReferenceTypes();
      return referenceTypes[id || this.get('type')] || referenceTypes[2];
    }
    return 'null';
  },
  hasReturnValue: function (this: ReferenceBackboneModel) {
    const type = this.getRefType() as APIReferenceType;
    const target = this.getTarget();
    return type && type.returnsValue && target && target.hasReturnValue();
  },
  hasWriteAccess: function (this: ReferenceBackboneModel) {
    const source = this.getSource();
    const sourceWs = source && source.getWorkspace();
    const target = this.getTarget();
    const targetWS = target && target.getWorkspace();
    return (
      (!sourceWs || sourceWs.hasWriteAccess()) &&
      (!targetWS || targetWS.hasWriteAccess())
    );
  },
  isIncludedInContextByFilter: function (this: ReferenceBackboneModel) {
    return Filters.isIncludedInContextByFilter(this);
  },
  changedAndMustBeSaved: function () {
    return (
      this.validationErrors === null &&
      !this.getTargetId().attributes &&
      !this.getSourceId().attributes &&
      BasicModel.prototype.changedAndMustBeSaved.apply(this)
    );
  },
  lock: function () {
    BasicModel.prototype.lock.call(this);
    dispatchAction(notifyReferenceLocked(this.id));
  },
  unlock: function () {
    BasicModel.prototype.unlock.call(this);
    dispatchAction(notifyReferenceUnlocked(this.id));
  },
});

function createReference(props: any, options: any): ReferenceBackboneModel {
  return new Reference(props, options);
}

type ReferenceClass = typeof BasicModel &
  ReferenceBackboneModel & {
    new (): ReferenceBackboneModel;
  };

export default {
  model: Reference as ReferenceClass,
  create: createReference,
};
