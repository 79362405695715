import styled from 'styled-components';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { Icon, IconName } from '@ardoq/icons';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import EditDescriptionButton from './EditDescriptionButton';
import Description from 'tabview/pagesView/Description';
import {
  CONTENT_MAX_WIDTH,
  ICON_SIZE,
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import { setContextToWorkspace } from 'tabview/pagesView/utils';
import { colors, spacing } from '@ardoq/design-tokens';
import { ArdoqId } from '@ardoq/api-types';
import { WORKSPACE_ID_ATTRIBUTE } from '@ardoq/global-consts';

const WorkspaceName = styled.div`
  ${fontSizeDefinitions_DEPRECATED.LARGE}
  color: ${colors.grey15};
  cursor: pointer;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const WorkspaceIcon = styled(Icon)`
  height: ${ICON_SIZE};
  width: ${ICON_SIZE};
  margin-right: ${horizontalSpacing.MEDIUM};
  margin-left: ${horizontalSpacing.SMALL};
`;

const DescriptionContainer = styled.div`
  margin: 0 ${horizontalSpacing.MEDIUM} 0 ${horizontalSpacing.LARGE};
  display: flex;
  max-width: ${CONTENT_MAX_WIDTH};
`;

const StickyHeader = styled.div`
  top: -${verticalSpacing.LARGE};
  background: white;
  position: sticky;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: ${verticalSpacing.LARGE} ${horizontalSpacing.MEDIUM} 0
    ${horizontalSpacing.LARGE};
`;

const StickyBreak = styled.hr`
  position: sticky;
  border: 0;
  border-top: 1px solid ${colors.borderSubtle00};
  margin: ${spacing.s16} 0;
  top: 48px;
`;

interface WorkspacePageProps {
  workspaceId: ArdoqId;
  bypassRenderLimit: boolean;
  expandDescription: boolean;
  shouldShowDescription: boolean;
  canEditDescription: boolean;
}
const WorkspacePage = ({
  shouldShowDescription,
  workspaceId,
  expandDescription,
  bypassRenderLimit,
  canEditDescription,
}: WorkspacePageProps) => {
  const descriptionText = workspaceInterface.getDescription(workspaceId);

  return (
    <>
      <StickyHeader
        data-click-namespace="pages view workspace header"
        {...{ [WORKSPACE_ID_ATTRIBUTE]: workspaceId }}
      >
        <WorkspaceIcon iconName={IconName.WORKSPACE} />
        <WorkspaceName onClick={() => setContextToWorkspace(workspaceId)}>
          {workspaceInterface.getWorkspaceName(workspaceId)}
        </WorkspaceName>
      </StickyHeader>
      {shouldShowDescription && (
        <DescriptionContainer>
          <div>
            <Description
              text={workspaceInterface.getDescription(workspaceId) ?? ''}
              bypassRenderLimit={bypassRenderLimit}
              expandDescription={expandDescription}
            />
          </div>
          <EditDescriptionButton
            modelId={workspaceId}
            descriptionIsEmpty={!descriptionText}
            canEditDescription={canEditDescription}
          />
        </DescriptionContainer>
      )}
      <StickyBreak />
    </>
  );
};
export default WorkspacePage;
