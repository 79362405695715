import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import EmptyWorkspace from 'components/EmptyWorkspace/EmptyWorkspace';
import { connect } from '@ardoq/rxbeach';
import { isContextWorkspaceEmpty$ } from './reducers';
import { classes } from '@ardoq/common-helpers';

class ContentView extends Component {
  static get propTypes() {
    return {
      menuContainerElement: PropTypes.object,
      containerElement: PropTypes.object,
      view: PropTypes.object,
      viewId: PropTypes.string,
      isContextWorkspaceEmpty: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.element = createRef();
  }

  componentDidMount() {
    if (!this.props.isContextWorkspaceEmpty) {
      this.renderVisualization();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isContextWorkspaceEmpty &&
      !this.props.isContextWorkspaceEmpty
    ) {
      this.renderVisualization();
    }
  }

  componentWillUnmount() {
    if (this.props.view.deselected) {
      this.props.view.deselected();
    }
  }

  renderVisualization() {
    if (this.element.current) {
      this.element.current.appendChild(this.props.menuContainerElement);
      this.element.current.appendChild(this.props.containerElement);
      this.props.view.debouncedRender();
    }
  }

  render() {
    const classNames = classes(
      'tab-pane',
      `tab${this.props.viewId}`,
      `${this.props.viewId}Tab`,
      'active'
    );
    if (this.props.isContextWorkspaceEmpty) {
      return <EmptyWorkspace className={classNames} />;
    }
    return <div ref={this.element} className={classNames} />;
  }
}

export default connect(ContentView, isContextWorkspaceEmpty$);
