import { ArrowType } from '@ardoq/api-types';
import { DiffType } from '@ardoq/data-model';
import { classes } from '@ardoq/common-helpers';
import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import { EdgePath, HoverPath } from '@ardoq/graph';
import { FILTER_DIMENSION_WORKAROUND_CSS_CLASS } from 'tabview/graphComponent/atoms';
import { getElementOpacityIfTransparentized } from 'tabview/blockDiagram/view/utils';

interface ArdoqEdgeProperties {
  pathData: string;
  hoverPathData: string;
  lineBeginning: ArrowType;
  lineEnding: ArrowType;
  className: string;
  diffType: DiffType;
  isParentChildReference: boolean;
  isGroup: boolean;
  sourcePortX: number;
  sourcePortY: number;
  isTransparentized: boolean;
}
const ArdoqEdge = ({
  pathData,
  hoverPathData,
  lineBeginning,
  lineEnding,
  className,
  diffType,
  isParentChildReference,
  isGroup,
  sourcePortX,
  sourcePortY,
  isTransparentized,
}: ArdoqEdgeProperties) => (
  <>
    {
      // Workaround an issue with svg filters.
      // If the path is a straight line, meaning the path has no area, applying
      // a filter will make the path disapper.
      diffType !== DiffType.NONE && (
        <rect
          x={sourcePortX - 15}
          y={sourcePortY - 15}
          width={30}
          height={30}
          className={FILTER_DIMENSION_WORKAROUND_CSS_CLASS}
        />
      )
    }
    {(!isParentChildReference || diffType !== DiffType.NONE) && (
      <HoverPath
        d={hoverPathData}
        $diffType={diffType}
        className={classes(
          isGroup ? 'edgeGroup' : 'integration',
          className,
          'hoverPath',
          NO_EXPORT_CLASS_NAME,
          isParentChildReference && 'parent-child-reference'
        )}
      />
    )}
    <EdgePath
      d={pathData}
      $diffType={diffType}
      className={className}
      markerStart={`url(#${lineBeginning})`}
      markerEnd={`url(#${lineEnding})`}
      style={{
        strokeOpacity: getElementOpacityIfTransparentized(
          isTransparentized,
          0.3
        ),
      }}
    />
  </>
);

export default ArdoqEdge;
