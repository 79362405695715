import { ArdoqId } from '@ardoq/api-types';
import { contrastEnsuredComponentFill } from 'utils/modelCssManager/getCssColors';
import { VERTICAL_LINE_WIDTH } from 'tabview/pagesView/constants';
import styled from 'styled-components';

const VerticalLineContainer = styled.svg`
  max-width: ${VERTICAL_LINE_WIDTH};
  min-width: ${VERTICAL_LINE_WIDTH};
  rect.component {
    cursor: default;
  }
  position: absolute;
  height: 100%;
`;

type VerticalLineContainerProperties = { componentId: ArdoqId };

const VerticalLineContainerComponent = ({
  componentId,
}: VerticalLineContainerProperties) => (
  <VerticalLineContainer height="100%" width={VERTICAL_LINE_WIDTH}>
    <rect
      height="100%"
      width="100%"
      rx="2px"
      fill={contrastEnsuredComponentFill(componentId)}
    />
  </VerticalLineContainer>
);

export default VerticalLineContainerComponent;
