import { Rectangle, Vector } from '@ardoq/graph';
import { BlocksViewNode, DragOperation } from '../types';
import { rectCenter } from '../misc/geometry';
import { getColPositions, getRowPositions } from '../layout/util';

/** create a transform that maps the specified world rectangle to the specified viewport rectangle */
export const projectionTransform = (vp: Rectangle, world: Rectangle) => {
  const sx = (vp[2] - vp[0]) / (world[2] - world[0]);
  const sy = (vp[3] - vp[1]) / (world[3] - world[1]);
  const tx = -world[0] * sx + vp[0];
  const ty = -world[1] * sy + vp[1];

  return new DOMMatrix([sx, 0, 0, sy, tx, ty]);
};

/** work out how far (col/row offsets) to move a node according to a mouse delta */
export const getDragOffset = (node: BlocksViewNode, delta: Vector): Vector => {
  const grid = node.parent!;
  const colPos = getColPositions(grid);
  const rowPos = getRowPositions(grid);

  const getNodeColIndex = (grid: BlocksViewNode, x: number) => {
    for (let i = 1; i < colPos.length; i += 2) {
      const xi = (colPos[i - 1] + colPos[i]) / 2;

      if (x < xi) {
        return i - 2;
      }
    }

    return colPos.length - 1;
  };

  const getNodeRowIndex = (grid: BlocksViewNode, y: number) => {
    for (let i = 1; i < rowPos.length; i += 2) {
      const yi = (rowPos[i - 1] + rowPos[i]) / 2;

      if (y < yi) {
        return i - 2;
      }
    }

    return rowPos.length - 1;
  };

  const c = rectCenter(node.bounds!);
  const c0 = [getNodeColIndex(grid, c[0]), getNodeRowIndex(grid, c[1])]; // convert to col/row

  c[0] += delta[0];
  c[1] += delta[1];
  const c1 = [getNodeColIndex(grid, c[0]), getNodeRowIndex(grid, c[1])]; // convert to col/row

  return [c1[0] - c0[0], c1[1] - c0[1]];
};

export const getColIndex = (gridNode: BlocksViewNode, pos: number) => {
  let colPos = gridNode.content![0]; // TODO initialise from content rect

  if (colPos > pos) {
    return -1;
  }

  // this can never return less than
  for (let ret = 0; ret < gridNode.colSize!.length; ++ret) {
    colPos += gridNode.colSize![ret];

    if (colPos > pos) {
      return ret;
    }
  }

  return gridNode.colSize!.length;
};

export const getRowIndex = (gridNode: BlocksViewNode, pos: number) => {
  let rowPos = gridNode.content![1]; // TODO initialise from content rect

  if (rowPos > pos) {
    return -1;
  }

  for (let ret = 0; ret < gridNode.rowSize!.length; ++ret) {
    rowPos += gridNode.rowSize![ret];

    if (rowPos > pos) {
      return ret;
    }
  }

  return gridNode.rowSize!.length;
};

/** calculates the resized indices for the specified node in terms of the dragOperation and mouse delta */
export const getResizedIndices = (
  node: BlocksViewNode,
  dragOperation: DragOperation,
  delta: Vector
): Rectangle => {
  /* fairly sure that this should be using the getNodeColIndex and getNodeRowIndex defined below */
  const grid = node.parent!;
  const ret: Rectangle = [
    node.col,
    node.row,
    node.col + node.colSpan,
    node.row + node.rowSpan,
  ];

  if (dragOperation & DragOperation.Left) {
    const colIndex = getColIndex(grid, node.cell![0] + delta[0]) - 1;

    ret[0] = 1 + 2 * Math.round((colIndex - 1) * 0.5); // round to odd number (node channel)
    ret[0] = Math.min(ret[0], ret[2] - 1); // clamp to left of right edge
  }

  if (dragOperation & DragOperation.Top) {
    // round to odd number ( node channel)
    ret[1] =
      1 +
      2 * Math.round((getRowIndex(grid, node.cell![1] + delta[1]) - 1) * 0.5);
    ret[1] = Math.min(ret[1], ret[3] - 1);
  }

  if (dragOperation & DragOperation.Right) {
    // round to even number ( routing channel)
    ret[2] = 2 * Math.round(getColIndex(grid, node.cell![2] + delta[0]) * 0.5);
    ret[2] = Math.max(ret[0] + 1, ret[2]);
  }

  if (dragOperation & DragOperation.Bottom) {
    // round to even number ( routing channel)
    ret[3] = 2 * Math.round(getRowIndex(grid, node.cell![3] + delta[1]) * 0.5);
    ret[3] = Math.max(ret[1] + 1, ret[3]);
  }

  return ret;
};

export const cursor = (dragOperation: DragOperation) => {
  switch (dragOperation) {
    case DragOperation.None:
      return 'default';
    case DragOperation.Pan:
      return 'grabbing';
    case DragOperation.AreaSelect:
      return 'default';
    case DragOperation.Expand:
      return 'default';
    case DragOperation.Left:
      return 'ew-resize';
    case DragOperation.LeftTop:
      return 'nwse-resize';
    case DragOperation.Top:
      return 'ns-resize';
    case DragOperation.RightTop:
      return 'nesw-resize';
    case DragOperation.Right:
      return 'ew-resize';
    case DragOperation.RightBottom:
      return 'nwse-resize';
    case DragOperation.Bottom:
      return 'ns-resize';
    case DragOperation.LeftBottom:
      return 'nesw-resize';
    case DragOperation.Move:
      return 'all-scroll';
  }

  return 'default';
};
