import { scan } from 'rxjs/operators';
import Components from 'collections/components';
import References from 'collections/references';
import { getLoadedScenarioData } from 'loadedScenarioData';
import { Observable, combineLatest } from 'rxjs';
import { PayloadUpdateScopeDiff } from './types';
import {
  APIComponentAttributes,
  APIReferenceAttributes,
  DiffMode,
} from '@ardoq/api-types';
import { clearComponentHierarchies, setDefaultCollectionView } from './utils';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { DiffType } from '@ardoq/data-model';
import { notifyComponentsAdded } from 'streams/components/ComponentActions';
import { dispatchAction } from '@ardoq/rxbeach';
import { notifyReferencesAdded } from 'streams/references/ReferenceActions';
import { ExcludedSet } from 'collections/consts';
import { activeDiffMode$ } from './activeDiffMode$';
import { diffModeUpdated } from './actions';
import Context from 'context';

export const setDiffRoutine = (
  scopeDiff$: Observable<PayloadUpdateScopeDiff>
) => {
  return combineLatest([scopeDiff$, activeDiffMode$]).pipe(
    scan((wasInScopeDiffMode, [{ scopeDiff }, activeDiffMode]) => {
      if (scopeDiff) {
        const components =
          activeDiffMode === DiffMode.SCENARIO
            ? scopeDiff.branchComponents
            : activeDiffMode === DiffMode.DIFF
              ? scopeDiff.diffComponents
              : scopeDiff.masterComponents;

        const references =
          activeDiffMode === DiffMode.SCENARIO
            ? scopeDiff.branchReferences
            : activeDiffMode === DiffMode.DIFF
              ? scopeDiff.diffReferences
              : scopeDiff.masterReferences;

        Components.collection.set<APIComponentAttributes>(components, {
          silent: true,
          remove: false,
        });
        References.collection.set<APIReferenceAttributes>(references, {
          silent: true,
          remove: false,
        });

        const placeholderComponents = components
          .filter(attribute => attribute[DIFF_TYPE] === DiffType.PLACEHOLDER)
          .map(({ _id }) => _id);
        Components.collection.clearExcludedFromView(
          ExcludedSet.PLACEHOLDER_SET
        );
        Components.collection.addExcludedFromView(
          placeholderComponents,
          ExcludedSet.PLACEHOLDER_SET
        );
        clearComponentHierarchies();

        // Bit unclear which event to use here, eventhough it just updates
        // the collections, it loads very different data, either from a
        // different branch or a special merged set.
        // Navigator already listens to to the added notification, so that
        // is an other erason added makes sense.
        dispatchAction(
          notifyComponentsAdded({
            componentIds: components.map(({ _id }) => _id),
          })
        );
        dispatchAction(
          notifyReferencesAdded({
            referenceIds: references.map(({ _id }) => _id),
          })
        );

        dispatchAction(diffModeUpdated({ activeDiffMode }));
        return true;
      }

      if (wasInScopeDiffMode) {
        References.collection.reset();
        Components.collection.reset();
        const loadedScenarioData = getLoadedScenarioData();
        if (loadedScenarioData) {
          const { components, references, connected } = loadedScenarioData;
          const addOptions = {
            silent: true,
            sort: false,
          };

          Components.collection.add(
            [...components, ...connected.components],
            addOptions
          );

          References.collection.add(
            [...references, ...connected.references],
            addOptions
          );

          References.collection.buildCacheForSourceAndTargetMaps();

          setDefaultCollectionView(
            components,
            connected.components,
            Components.collection
          );

          setDefaultCollectionView(
            references,
            connected.references,
            References.collection
          );

          clearComponentHierarchies();

          if (components.length) {
            dispatchAction(
              notifyComponentsAdded({
                componentIds: components.map(component => component._id),
              })
            );
          }
          if (references.length) {
            dispatchAction(
              notifyReferencesAdded({
                referenceIds: references.map(reference => reference._id),
              })
            );
          }

          const selectedComponentId = Context.componentId();
          if (selectedComponentId) {
            // This is not necessarly the same object as `Context.component()`.
            // In visual diff mode we maintain sets of three branches,
            // mainline, branch and branch-off, it could be an object of any
            // of them.
            const component = Components.collection.get(selectedComponentId);
            Context.setComponent(component ?? null);
          }
        }
      }

      dispatchAction(diffModeUpdated({ activeDiffMode }));
      return false;
    }, false)
  );
};
