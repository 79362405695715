import { ArdoqId, ReportFilterQuery } from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import {
  EnhancedSearchResponse,
  getLoadPresentationReportColumnSuggestionsAsync,
  getLoadReportColumnSuggestionsAsync,
  ReportPagination,
  ReportReaderCommands,
  ReportSort,
} from '@ardoq/report-reader';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  filterQueryChanged,
  pageChanged,
  paginationChanged,
  sortingChanged,
} from '../actions';
import { omit } from 'lodash';
import { getExportButtonCommands } from './exportButtonCommands';

const setPagination = (pagination: ReportPagination) =>
  dispatchAction(paginationChanged(pagination));
const setFilterQuery = (filterQuery: ReportFilterQuery) =>
  dispatchAction(filterQueryChanged(filterQuery));
const setPage = (page: number) => dispatchAction(pageChanged(page));
const setSort = (sort: ReportSort) => dispatchAction(sortingChanged(sort));
const clearAllFilters = () => {
  dispatchAction(filterQueryChanged({}));
};
export const getReportReaderCommands = (
  filterQuery: ReportFilterQuery,
  reportId: string,
  searchResults: Maybe<EnhancedSearchResponse>,
  presentationId?: ArdoqId,
  slideId?: Maybe<ArdoqId>
): ReportReaderCommands => {
  return {
    setPage,
    setPagination,
    setSort,
    clearAllFilters,
    removeBarFilter: type =>
      dispatchAction(filterQueryChanged(omit(filterQuery, type))),
    setFilterQuery,
    loadComponentSuggestions:
      presentationId && slideId
        ? getLoadPresentationReportColumnSuggestionsAsync(
            reportId,
            slideId,
            presentationId
          )
        : getLoadReportColumnSuggestionsAsync(reportId),
    ...getExportButtonCommands(reportId, searchResults, filterQuery),
  };
};
