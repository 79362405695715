import React, { useEffect } from 'react';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { QueryBuilderQuery, SortOrder } from '@ardoq/api-types';
import SearchResultTable from '../../../components/SearchResultTable/SearchResultTable';
import { findRelevantFieldsFromQuery } from '../../../components/SearchResultTable/utils';
import { SEARCH_RESULTS_PER_PAGE } from '../../../search/types';
import { advancedSearchTable$ } from './advancedSearchTable$';
import {
  pageWasSet,
  queryWasSet,
  searchParamsWereSet,
  selectedFieldsWereSet,
} from './actions';
import { queryAdvancedSearch } from '../../../search/AdvancedSearch/actions';
import AdvancedSearchErrorMessage from '../../../search/AdvancedSearch/AdvancedSearchErrorMessage';
import {
  AdvancedSearchTableCommands,
  AdvancedSearchTableStreamShape,
} from './types';
import { combineLatest, distinctUntilChanged, map, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { PayloadQueryAdvancedSearch } from '../../../search/AdvancedSearch/types';

type OwnProps = {
  query: QueryBuilderQuery;
};

type ContainerProps = OwnProps & AdvancedSearchTableStreamShape;

const commands: AdvancedSearchTableCommands = {
  selectPage: (page: number) => dispatchAction(pageWasSet(page)),
  onSortChange: (
    sortBy: string | undefined,
    sortOrder: SortOrder | undefined
  ) => dispatchAction(searchParamsWereSet({ sortBy, sortOrder })),
  selectFields: (fields: Array<string>) =>
    dispatchAction(selectedFieldsWereSet(fields)),
};

const AdvancedSearchTableWithLoader = ({
  advancedSearchStreamData: {
    fields,
    results,
    total,
    searchError,
    ruleErrorMessages,
  },
  query,
  page,
  isSearching,
  searchParams,
  selectedFields,
}: ContainerProps) => {
  const rules = query?.rules[1];

  useEffect(() => {
    dispatchAction(queryWasSet(query));
  }, [query]);

  if (searchError) {
    return (
      <AdvancedSearchErrorMessage
        searchError={searchError}
        ruleErrorMessages={ruleErrorMessages}
      />
    );
  }

  return (
    <SearchResultTable
      queryRelevantFieldsNames={rules ? findRelevantFieldsFromQuery(rules) : []}
      selectedFieldNames={selectedFields}
      onFieldSelect={commands.selectFields}
      onPageSelect={commands.selectPage}
      onSortChange={commands.onSortChange}
      sortBy={searchParams?.sortBy}
      sortOrder={searchParams?.sortOrder}
      fields={fields}
      results={results}
      page={page}
      perPage={searchParams.size ?? SEARCH_RESULTS_PER_PAGE}
      total={total}
      isLoading={isSearching}
      intercomTarget="advanced search results"
    />
  );
};

export const doQuerySearch$ = advancedSearchTable$.pipe(
  map(({ queryBuilderRules, searchParams }) => ({
    queryBuilderRules,
    ...searchParams,
  })),
  distinctUntilChanged<PayloadQueryAdvancedSearch>(isEqual),
  tap(advancedSearchPayload => {
    if (advancedSearchPayload.queryBuilderRules) {
      dispatchAction(queryAdvancedSearch(advancedSearchPayload));
    }
  })
);

const viewModel$ = combineLatest({
  advancedSearchTable: advancedSearchTable$,
  doQuerySearch$,
}).pipe(map(({ advancedSearchTable }) => advancedSearchTable));

export default connect<ContainerProps, AdvancedSearchTableStreamShape>(
  AdvancedSearchTableWithLoader,
  viewModel$
);
