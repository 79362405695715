import { connect } from '@ardoq/rxbeach';
import { SettingsBar } from '@ardoq/settings-bar';
import { ViewIds } from '@ardoq/api-types';
import getSettingsBarInstanceStateForViewId from './viewPaneSettingsBar$';
import { ConnectedSettingsBar } from './types';
import { Features, hasFeature } from '@ardoq/features';

type HasViewPaneSettingsBarArgs = { viewId: ViewIds; isViewpointMode: boolean };
/**
 * The external settings bar should not be rendered. For it to be usable in its current state,
 * the Timeline2024 refactoring needs to be finished and support workspace mode.
 *
 * The objective is to have a pattern of how to deal with the settings in Workspace Mode.
 */
export const hasViewPaneSettingsBar = ({
  isViewpointMode,
  viewId,
}: HasViewPaneSettingsBarArgs) =>
  hasFeature(Features.VIEW_EXTERNAL_SETTINGS_BAR) &&
  !isViewpointMode &&
  viewId === ViewIds.NONE;

export const getConnectedViewPaneSettingsBar = (
  viewId: ViewIds,
  settingsBarId: string
): ConnectedSettingsBar =>
  connect(
    SettingsBar,
    getSettingsBarInstanceStateForViewId(viewId, settingsBarId)
  );
