import { GroupType, GroupingRule } from '@ardoq/api-types';
import GroupByCollection from 'collections/groupByCollection';

type GetAll = () => GroupingRule[];

const getAll: GetAll = () =>
  GroupByCollection.getAll().map(groupBy =>
    toInterfaceType(groupBy.attributes)
  );

const toInterfaceType = (groupingRule: GroupingRule) => {
  switch (groupingRule.type) {
    case GroupType.REFERENCE:
    case GroupType.REFERENCE_CONTEXTUAL: {
      const targetId = `${groupingRule.workspaceId}-${groupingRule.targetId}`;
      return {
        ...groupingRule,
        targetId,
      };
    }
  }
  return { ...groupingRule };
};

const count = () => GroupByCollection.length;

const applyNewRules = (groupingRules: GroupingRule[]) => {
  GroupByCollection.removeAll();
  const models = GroupByCollection.add(groupingRules);
  // GroupBy constructor overrides attributes for invalid grouping rule (i.e. Tag GroupingRule with no tag selected when no tag is defined for a certain workspace).
  // Previous implementation of Perspective Editor did not use this overriding, we want to stick to this behaviour.
  models.forEach((model, index) => model.set(groupingRules[index]));
};

const getActiveGroupingAttributes = (): GroupingRule[] =>
  structuredClone(
    GroupByCollection.filter(groupBy => groupBy.isValid()).map(
      groupBy => groupBy.attributes as GroupingRule
    )
  );

export const groupingRuleInterface = {
  getAll,
  toInterfaceType,
  count,
  applyNewRules,
  getActiveGroupingAttributes,
};
