import { SVGAttributes } from 'react';
import styled, { css } from 'styled-components';
import { EXPANDER_RADIUS } from './consts';
import { dispatchAction } from '@ardoq/rxbeach';
import { relationshipDiagramToggleCollapseGroup } from 'tabview/relationshipDiagrams/actions';
import { ViewIds } from '@ardoq/api-types';
import { GRAPH_SKIP_CLICK_CLASSNAME } from 'tabview/graphComponent/yfilesHelper';
import { GroupExpanderVisual } from '@ardoq/graph';

const ExpanderCircleGroup = styled.g<{ $center: number }>`
  cursor: pointer;
  ${({ $center }) => css`
    > g > text {
      font-size: 29px;
      transform: translate(${$center - 19}px, ${$center - 14}px);
    }
    > g > circle {
      cx: ${$center}px;
      cy: ${$center}px;
      r: ${EXPANDER_RADIUS}px;
    }
  `}
`;

type GroupExpanderProperties = {
  groupId: string;
  collapsed: boolean;
  boundsSize: number;
  viewId: ViewIds;
};
const GroupExpander = ({
  groupId,
  collapsed,
  boundsSize,
  viewId,
  ...rest
}: GroupExpanderProperties & SVGAttributes<SVGGElement>) => {
  const center = boundsSize / 2;

  return (
    <ExpanderCircleGroup
      $center={center}
      onClick={() =>
        dispatchAction(
          relationshipDiagramToggleCollapseGroup({ viewId, groupId }),
          viewId
        )
      }
      className={GRAPH_SKIP_CLICK_CLASSNAME}
      {...rest}
    >
      <GroupExpanderVisual collapsed={collapsed} />
    </ExpanderCircleGroup>
  );
};
export default GroupExpander;
