import { createFifoCache } from '@ardoq/common-helpers';
import {
  Font,
  Size,
  TextMeasurePolicy,
  TextRenderSupport,
  TextWrapping,
} from '@ardoq/yfiles';
import { GRAPH_ITEM_LABEL_CACHE_SIZE } from '@ardoq/graph';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
  MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
} from './consts';
import { logWarn } from '@ardoq/logging';

const otherLabelMeasurementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      BLOCK_DIAGRAM_OTHER_LABEL_FONT,
      BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
      TextWrapping.WORD_ELLIPSIS,
      TextMeasurePolicy.SVG
    )
);
const modernizedOtherLabelMeasurementCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT,
      MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_MAX_SIZE,
      TextWrapping.WORD_ELLIPSIS,
      TextMeasurePolicy.SVG
    )
);

const mainNodeLabelSizeCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      BLOCK_DIAGRAM_NODE_LABEL_FONT,
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize,
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.wrapping,
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.measurePolicy
    )
);
const modernizedNodeLabelSizeCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
      MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize,
      MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.wrapping,
      MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.measurePolicy
    )
);
const mainEdgeLabelSizeCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      BLOCK_DIAGRAM_EDGE_LABEL_FONT,
      BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.wrapping,
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.measurePolicy
    )
);
const modernizedEdgeLabelSizeCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    TextRenderSupport.measureText(
      text,
      MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
      MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_MAX_SIZE,
      MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.wrapping,
      MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.measurePolicy
    )
);

export const measureLabelElement = (text: string, font: Font) => {
  switch (font) {
    case BLOCK_DIAGRAM_EDGE_LABEL_FONT:
      return mainEdgeLabelSizeCache(text);
    case MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT:
      return modernizedEdgeLabelSizeCache(text);
    case BLOCK_DIAGRAM_NODE_LABEL_FONT:
      return mainNodeLabelSizeCache(text);
    case MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT:
      return modernizedNodeLabelSizeCache(text);
    case BLOCK_DIAGRAM_OTHER_LABEL_FONT:
      // otherLabels exist only in multi-labels
      return otherLabelMeasurementCache(text);
    case MODERNIZED_BLOCK_DIAGRAM_OTHER_LABEL_FONT:
      // modernized otherLabels exist only in modernized multi-labels
      return modernizedOtherLabelMeasurementCache(text);

    default:
      logWarn(new Error('Unexpected font in label measurement'), null, {
        font,
      });
      return Size.EMPTY;
  }
};

export const getLabelPreferredSize = (
  text: string,
  font: Font,
  indent: number = 0
) => {
  if (!text) {
    return Size.EMPTY;
  }
  const { width, height } = measureLabelElement(text, font);
  return new Size(
    Math.ceil(width) + LABEL_HORIZONTAL_PADDING + indent * 2,
    Math.ceil(height) + LABEL_VERTICAL_PADDING + indent * 2
  );
};
