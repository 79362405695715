import { Metamodel } from '@ardoq/api-types';
import {
  MetamodelContextSettings,
  MetamodelReferenceCompletenessContext,
} from 'metamodel/types';
import { DisplayPreference } from './types';
import {
  getContextComponentType,
  getDirectionDescription,
  getDirectionPreposition,
  getDisplayPreferenceDescription,
  getOtherComponentType,
} from './utils';
import WorkspaceNavigationTitle from '../workspaceNavigationTitle/WorkspaceNavigationTitle';
import { InlineText, Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

export const ReferenceCompletionTitle = ({
  metamodel,
  context,
}: {
  metamodel: Metamodel;
  context: MetamodelReferenceCompletenessContext & MetamodelContextSettings;
}) => {
  const contextComponentTypeName = getContextComponentType(
    metamodel,
    context
  ).name;
  const otherComponentTypeName = getOtherComponentType(metamodel, context).name;
  const referenceTypeName = context.name;
  const { contextComponentTypeIsSource } = context;
  return (
    <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
      {contextComponentTypeIsSource ? (
        <>
          <InlineText>{contextComponentTypeName}</InlineText> ›{' '}
          {referenceTypeName} › {otherComponentTypeName}
        </>
      ) : (
        <>
          {otherComponentTypeName} › {referenceTypeName} ›{' '}
          <InlineText>{contextComponentTypeName}</InlineText>
        </>
      )}
    </WorkspaceNavigationTitle>
  );
};

interface ReferenceCompletionExplanationProps {
  metamodel: Metamodel;
  context: MetamodelReferenceCompletenessContext;
  displayPreference: DisplayPreference;
}
export const ReferenceCompletionExplanation = ({
  metamodel,
  context,
  displayPreference,
}: ReferenceCompletionExplanationProps) => {
  const contextComponentTypeName = getContextComponentType(
    metamodel,
    context
  ).name;
  const otherComponentTypeName = getOtherComponentType(metamodel, context).name;
  const directionDescription = getDirectionDescription(context);
  const directionPreposition = getDirectionPreposition(context);
  const displayPreferenceDescription =
    getDisplayPreferenceDescription(displayPreference);
  const referenceTypeName = context.name;
  return (
    <Box marginTop="medium">
      <Paragraph variant="text1">
        The following components of type {contextComponentTypeName}{' '}
        {displayPreferenceDescription} an {directionDescription} reference of
        type <InlineText variant="text1Italic">{referenceTypeName}</InlineText>{' '}
        {directionPreposition} {otherComponentTypeName}
      </Paragraph>
    </Box>
  );
};
