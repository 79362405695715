import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import { ILabelModelParameter, ILabelOwner } from '@ardoq/yfiles';
import type { Edge, Node } from 'graph/types';

export abstract class LabelCreator {
  createLabel(owner: ILabelOwner, model: Edge | Node) {
    return {
      owner,
      text: '',
      layoutParameter: this.getLayoutParameter(),
      style: ArdoqURLLabelStyle.Classic,
      tag: model,
    };
  }
  abstract getLayoutParameter(): ILabelModelParameter;
}
