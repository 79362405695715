import { sum } from 'lodash';
import { BlocksViewNode } from '../types';

export const getOpenAscendant = (node: BlocksViewNode) => {
  let ret = node;
  let currentNode = node;
  while (currentNode.parent) {
    if (!currentNode.parent.open) {
      ret = currentNode.parent;
    }

    currentNode = currentNode.parent!;
  }

  return ret;
};

/* get the lowest common parent/child ancestor of a and b */
export const getLCA = (a: BlocksViewNode | null, b: BlocksViewNode | null) => {
  const depth = (n: BlocksViewNode | null) => {
    let ret = 0;

    let nn = n;
    for (ret = 0; nn !== null; nn = nn.parent) {
      ++ret;
    }

    return ret;
  };

  const da = depth(a);
  const db = depth(b);
  let aa = a;
  let bb = b;
  for (let i = 0; i < da - db; ++i) {
    aa = aa!.parent;
  }

  for (let i = 0; i < db - da; ++i) {
    bb = bb!.parent;
  }

  while (aa!.parent !== bb!.parent) {
    aa = aa!.parent;
    bb = bb!.parent;
  }

  return aa!.parent;
};

/* verify that ascendant is an ascendant of node */
export const isAscendant = (
  ascendant: BlocksViewNode,
  node: BlocksViewNode
) => {
  let n: BlocksViewNode | null = node;

  while (n) {
    if (n === ascendant) {
      return true;
    }

    n = n.parent;
  }

  return false;
};

/** verify that entire ascendency (not including the node itself) is open */
export const isAscendancyOpen = (node: BlocksViewNode) => {
  let currentNode = node;
  while (currentNode.parent) {
    if (!currentNode.parent!.open) {
      return false;
    }

    currentNode = currentNode.parent;
  }

  return true;
};

export const isGroup = (node: BlocksViewNode) => node.children;

export const getDescendantCount = (node: BlocksViewNode): number =>
  node.children
    ? sum(node.children.map(child => 1 + getDescendantCount(child)))
    : 0;
