import styled from 'styled-components';
import { text2 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';

export const CompletionBarHeaderText = styled.span<{
  $color?: string;
}>`
  ${text2};
  color: ${({ $color }) => $color};
  text-transform: uppercase;
  user-select: none;
`;

export const CompletionBarContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const CompletionBarBackground = styled.span`
  position: absolute;
  z-index: -1;
  height: 20px;
  margin: 2px 0;
  background-color: ${colors.grey90};
  border-radius: 0 10px 10px 0;
  width: 100%;
`;

export const CompletionBarFill = styled.span<{
  $color?: string;
  $completeness: number;
}>`
  position: absolute;
  z-index: -1;
  height: 20px;
  margin: 2px 0;
  background-color: ${({ $color }) => $color || colors.green60};
  border-radius: 0 10px 10px 0;
  width: ${({ $completeness }) => `${$completeness.toFixed(1)}%`};
`;
