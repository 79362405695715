import { MappedAdvancedSearchResult, resultMapper } from 'search/resultUtils';

import {
  AdvancedSearchStateShape,
  BranchedAdvancedSearchStateShape,
  PayloadAdvancedSearchError,
  PayloadQueryAdvancedSearch,
  PayloadQueryAdvancedSearchSuccess,
  PayloadSelectCondition,
  PayloadSelectFields,
  PayloadSelectFilterType,
  PayloadSelectPage,
  PayloadUpdateAdvancedSearch,
} from './types';
import { defaultState } from './consts';
import {
  extractConditionFromCombinedRules,
  extractFilterTypeFromCombinedRules,
  extractQueryBuilderRulesFromCombinedRules,
} from './utils';
import { logError } from '@ardoq/logging';
import {
  UnknownDocumentType,
  SearchBackend,
  QueryBuilderQuery,
} from '@ardoq/api-types';
import { PayloadStoredQuery } from 'search/actions';
import { setStateProperty } from '@ardoq/common-helpers';

const updateAdvancedSearchReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { queryBuilderRules }: PayloadUpdateAdvancedSearch
): T => ({
  ...currentState,
  searchError: null,
  queryBuilderRules,
});

const selectConditionReducer = <
  T extends {
    queryBuilderRules: AdvancedSearchStateShape['queryBuilderRules'];
  } = AdvancedSearchStateShape,
>(
  currentState: T,
  { condition }: PayloadSelectCondition
): T => ({
  ...currentState,
  selectedCondition: condition,
  queryBuilderRules: currentState.queryBuilderRules
    ? { ...currentState.queryBuilderRules, condition }
    : null,
});

const selectFilterTypeReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { filterType }: PayloadSelectFilterType
): T => ({
  ...currentState,
  queryBuilderRules: null,
  selectedFilterType: filterType,
  selectedCondition: defaultState.selectedCondition,
});

const queryAdvancedSearchSuccessReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { total, results, fields }: PayloadQueryAdvancedSearchSuccess
): T => ({
  ...currentState,
  total,
  fields: fields.map(({ name, label, type }) => ({ name, label, type })),
  results: results.map(
    resultMapper<PayloadQueryAdvancedSearchSuccess['results'][0]['doc']>
  ),
  showResults: true,
});

const advancedSearchErrorReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { searchError }: PayloadAdvancedSearchError
): T => ({
  ...currentState,
  results: [],
  searchError,
  isExporting: false,
  showResults: false,
});

const queryAdvancedSearchReducer = <
  T extends {
    selectedPage: AdvancedSearchStateShape['selectedPage'];
  } = AdvancedSearchStateShape,
>(
  currentState: T,
  { sortOrder, sortBy, from }: PayloadQueryAdvancedSearch
): T => ({
  ...currentState,
  searchError: null,
  sortBy,
  sortOrder,
  selectedPage: from === undefined ? 1 : currentState.selectedPage,
});

const resetAllSearchStateReducer = (): AdvancedSearchStateShape => ({
  ...defaultState,
});

const resetAllBranchedSearchStateReducer =
  (): BranchedAdvancedSearchStateShape => ({
    ...defaultState,
  });

const selectPageReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { pageNumber }: PayloadSelectPage
): T => ({
  ...currentState,
  selectedPage: pageNumber,
});

const selectFieldsReducer = <T = AdvancedSearchStateShape>(
  currentState: T,
  { selectedFieldNames }: PayloadSelectFields
): T => ({
  ...currentState,
  selectedFieldNames,
});

const exportAdvancedSearchReducer = (
  currentState: BranchedAdvancedSearchStateShape
): BranchedAdvancedSearchStateShape => ({
  ...currentState,
  isExporting: true,
  results: [] as MappedAdvancedSearchResult<UnknownDocumentType>[],
});

const exportAdvancedSearchSuccessReducer = (
  currentState: BranchedAdvancedSearchStateShape
): BranchedAdvancedSearchStateShape => ({
  ...currentState,
  isExporting: false,
});

const loadStoredQueryReducer = (
  currentState: BranchedAdvancedSearchStateShape,
  { storedQuery }: PayloadStoredQuery
) => {
  if (storedQuery.backend === SearchBackend.ADVANCED_SEARCH) {
    const combinedRules = storedQuery.query as QueryBuilderQuery;
    const queryBuilderRules = combinedRules
      ? extractQueryBuilderRulesFromCombinedRules(combinedRules)
      : defaultState.queryBuilderRules;
    const selectedFilterType = combinedRules
      ? extractFilterTypeFromCombinedRules(combinedRules)
      : defaultState.selectedFilterType;
    const isMissingRules = combinedRules.rules === undefined;
    if (isMissingRules) {
      logError(Error('Unrecognized stored query.'), null, {
        info:
          typeof combinedRules === 'string'
            ? combinedRules
            : Object.keys(combinedRules),
      });
    }
    const selectedCondition = isMissingRules
      ? defaultState.selectedCondition
      : (extractConditionFromCombinedRules(combinedRules) ??
        defaultState.selectedCondition);

    return {
      ...defaultState,
      selectedQueryId: storedQuery._id,
      queryBuilderRules,
      selectedFilterType,
      selectedCondition,
    };
  }
  return currentState;
};

export default {
  updateAdvancedSearchReducer,
  selectConditionReducer,
  selectFilterTypeReducer,
  queryAdvancedSearchSuccessReducer,
  advancedSearchErrorReducer,
  queryAdvancedSearchReducer,
  resetAllSearchStateReducer,
  resetAllBranchedSearchStateReducer,
  selectPageReducer,
  selectFieldsReducer,
  exportAdvancedSearchReducer,
  exportAdvancedSearchSuccessReducer,
  loadStoredQueryReducer,
  setBranchReducer: setStateProperty('branchId'),
};
