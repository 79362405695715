import { GeneralPath, INode, Rect } from '@ardoq/yfiles';
import ArdoqNodeStyleBase from '../ArdoqNodeStyleBase';
import { GraphNode } from '@ardoq/graph';
import {
  getOtherLabelsHeightModernized,
  getOtherLabelSizes,
} from 'tabview/blockDiagram/view/yFilesExtensions/labels/labelUtils';
import SparkMD5 from 'spark-md5';
import { componentInterface } from '@ardoq/component-interface';
import { isContextGraphNode } from '../nodeDecorator';
import { MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_OUTER } from './consts';

/** @returns a GeneralPath with one rectangle. */
const rectanglePath = (x: number, y: number, width: number, height: number) => {
  const result = new GeneralPath(1);
  result.appendRectangle(new Rect(x, y, width, height), false);
  return result;
};

abstract class ModernizedNodeStyle extends ArdoqNodeStyleBase {
  constructor() {
    super(true);
  }
  override getHash(node: INode) {
    const { labels, layout, tag } = node;
    const graphNode = tag as GraphNode | null;
    if (!graphNode) {
      return '';
    }
    const { modelId } = graphNode;
    return SparkMD5.hash(
      [
        componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
          useAsBackgroundStyle: false,
        }).fill,
        graphNode.getVisualDiffType(),
        graphNode.isGhost,
        graphNode.isTransparentized,
        isContextGraphNode(graphNode),
        layout.width,
        layout.height,
        labels.at(0)?.layout.height ?? 0,
      ].join('-')
    );
  }
  protected override getOutline(node: INode): GeneralPath | null {
    const {
      tag,
      layout: {
        x: nodeLayoutX,
        y: nodeLayoutY,
        width: nodeLayoutWidth,
        height: nodeLayoutHeight,
      },
    } = node;
    const graphNode = tag as GraphNode;
    const isContext = isContextGraphNode(graphNode);
    const itemLabels = graphNode.getItemLabels();

    const borderWidth = isContext
      ? MODERNIZED_BLOCK_DIAGRAM_CONTEXT_STROKE_OUTER
      : 0;
    const otherLabelsHeight = itemLabels?.otherLabels?.length
      ? getOtherLabelsHeightModernized(
          getOtherLabelSizes(itemLabels.otherLabels, true)
        )
      : 0;
    return rectanglePath(
      nodeLayoutX - borderWidth,
      nodeLayoutY - borderWidth,
      nodeLayoutWidth + 2 * borderWidth,
      nodeLayoutHeight + 2 * borderWidth + otherLabelsHeight
    );
  }
}
export default ModernizedNodeStyle;
