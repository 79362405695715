import { isSameSet } from 'utils/isSameSet';
import type { RelationshipsNode } from '../types';
import type { Point } from '@ardoq/math';
import { NODE_MARGIN, NODE_RADIUS, ROOT_LEAF_GROUP_ID } from '../consts';
import { packEnclose } from 'd3';

/** @returns true if listB is a subset of listA */
const isSubset = <T>(listA: Iterable<T>, listB: Iterable<T>) => {
  const setA = new Set(listA);
  for (const current of listB) {
    if (!setA.has(current)) {
      return false;
    }
  }
  return true;
};
const hasNotableIntersection = (
  listA: Iterable<string>,
  listB: Iterable<string>
) => {
  const setA = new Set(listA);
  for (const current of listB) {
    if (current !== ROOT_LEAF_GROUP_ID && setA.has(current)) {
      return true;
    }
  }
  return false;
};

const getAngularIncrement = (radius: number) => {
  const circumferenceAtCurrentRadius = 2 * Math.PI * radius;
  const spacingAsPortionOfCircumference =
    (2 * NODE_MARGIN + 2 * NODE_RADIUS) / circumferenceAtCurrentRadius;
  return 2 * Math.PI * spacingAsPortionOfCircumference;
};
const newNodeLocationFactory = ([centerX, centerY]: Point, radius: number) => {
  let currentRadius = radius + 2 * NODE_RADIUS + NODE_MARGIN;
  let angularIncrement = getAngularIncrement(currentRadius);
  let currentAngle = -angularIncrement;
  return (): Point => {
    currentAngle += angularIncrement;
    if (currentAngle > 2 * Math.PI) {
      currentAngle = 0;
      currentRadius += 2 * NODE_RADIUS + NODE_MARGIN;
      angularIncrement = getAngularIncrement(currentRadius);
    }
    return [
      centerX + currentRadius * Math.cos(currentAngle),
      centerY + currentRadius * Math.sin(currentAngle),
    ];
  };
};
const buildNodesById = (
  map: Map<string, RelationshipsNode>,
  current: RelationshipsNode
) => {
  map.set(current.id, current);
  current.children?.reduce(buildNodesById, map);
  return map;
};

const mergePreviousNodes = (
  node: RelationshipsNode,
  previousNodesById: Map<string, RelationshipsNode>,
  applyRadius: boolean
) => {
  const previousNode = previousNodesById.get(node.id);
  const newNodes: RelationshipsNode[] = [];
  if (previousNode) {
    node.x = previousNode.x;
    node.y = previousNode.y;
    if (applyRadius) {
      node.r = previousNode.r;
      node.minimumEnclosingCircleForChildren =
        previousNode.minimumEnclosingCircleForChildren;
    }
  } else {
    newNodes.push(node);
  }
  node.children?.forEach(child => {
    const { newNodes: childNewNodes } = mergePreviousNodes(
      child,
      previousNodesById,
      applyRadius
    );
    newNodes.push(...childNewNodes);
  });
  return {
    newNodes,
  };
};

const applyPreviousPositions = (
  rootNode: RelationshipsNode,
  previousRootNode: RelationshipsNode
) => {
  if (!previousRootNode.children) {
    return {
      anyPreviousNodesFound: false,
      allPreviousNodesFound: false,
      isSameSet: false,
    };
  }
  const previousNodesById = [previousRootNode].reduce(
    buildNodesById,
    new Map()
  );

  const newNodesById = [rootNode].reduce(buildNodesById, new Map());
  const onlyPreviousNodesFound =
    Boolean(newNodesById.size) &&
    isSubset(previousNodesById.keys(), newNodesById.keys());
  const sameSet =
    onlyPreviousNodesFound &&
    isSameSet(previousNodesById.keys(), newNodesById.keys());
  const anyPreviousNodesFound =
    sameSet ||
    (onlyPreviousNodesFound && previousNodesById.size) ||
    hasNotableIntersection(previousNodesById.keys(), newNodesById.keys());

  const { newNodes } = mergePreviousNodes(
    rootNode,
    previousNodesById,
    onlyPreviousNodesFound
  );

  if (anyPreviousNodesFound) {
    const newNodeLocationsByParent = new Map<RelationshipsNode, () => Point>();
    newNodes.forEach(node => {
      if (!node.parent) {
        return;
      }
      if (!newNodeLocationsByParent.has(node.parent)) {
        const { x, y, r } = node.parent.children?.length
          ? packEnclose(node.parent.children)
          : node.parent;
        newNodeLocationsByParent.set(
          node.parent,
          newNodeLocationFactory([x, y], r)
        );
      }

      const newNodeLocation = newNodeLocationsByParent.get(node.parent)!;
      const [x, y] = newNodeLocation();
      node.x = x;
      node.y = y;
    });
  }

  const result = {
    onlyPreviousNodesFound,
    anyPreviousNodesFound,
    isSameSet: sameSet,
  };
  return result;
};
export default applyPreviousPositions;
