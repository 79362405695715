import { ArdoqId } from '@ardoq/api-types';
import { LinkedComponent } from '@ardoq/data-model';
import { getComponentItemData } from '../utils';
import { DependencyMatrixCell, Row, RowHeader } from '../atoms';
import { DependencyMatrixViewSettings } from '../types';
import { ComponentLabel } from './ComponentLabel';
import ReferenceCell from './ReferenceCell';
import { useCallback } from 'react';
import { dependencyMatrixCommands } from '../commands';

type DependencyMatrixRowProps = {
  componentId: ArdoqId;
  workspaceId: ArdoqId;
  selectedComponentId: ArdoqId;
  targetIds: ArdoqId[];
  readOnly: boolean;
  rowReferences: LinkedComponent[];
  viewSettings: DependencyMatrixViewSettings;
  highlightLastClickedTargetId?: ArdoqId;
  focusedComponentId: ArdoqId | null;
  isExporting: boolean;
};

const TYPE = 'SOURCE';
export const DependencyMatrixRow = ({
  componentId,
  targetIds,
  readOnly,
  rowReferences,
  viewSettings,
  highlightLastClickedTargetId,
  isExporting,
  workspaceId,
  selectedComponentId,
  focusedComponentId,
}: DependencyMatrixRowProps) => {
  const component = getComponentItemData(componentId, TYPE);
  const isComponentFocused = focusedComponentId === componentId;
  const isComponentSelected = selectedComponentId === componentId;

  const targetReferencesMap: Record<ArdoqId, LinkedComponent[]> =
    Object.fromEntries(
      targetIds.map(targetId => [
        targetId,
        rowReferences.filter(
          ({ componentId: referencedComponentId }) =>
            targetId === referencedComponentId
        ),
      ])
    );

  const onComponentClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();
      dependencyMatrixCommands.setFocusedItemState(component.id);
    },
    [component.id]
  );

  return (
    <Row
      key={component.id}
      $isLastClicked={isComponentFocused}
      $isSelected={isComponentSelected}
      onClick={onComponentClick}
    >
      <RowHeader $isSelected={isComponentSelected}>
        <ComponentLabel type={TYPE} component={component} key={component.id} />
      </RowHeader>

      {!isExporting ? <DependencyMatrixCell /> : null}
      <DependencyMatrixCell />

      {targetIds.map(targetId => (
        <ReferenceCell
          key={targetId}
          readOnly={readOnly}
          sourceId={componentId}
          targetId={targetId}
          references={targetReferencesMap[targetId]}
          viewSettings={viewSettings}
          workspaceId={workspaceId}
          highlightAsLastClicked={
            highlightLastClickedTargetId === targetId && !isExporting
          }
        />
      ))}
    </Row>
  );
};
