import {
  BooleanOperator,
  ViewIds,
  Operator,
  QueryBuilderRuleValue,
  QueryBuilderSubquery,
  APIComponentType,
} from '@ardoq/api-types';
import {
  LayoutConstraintRuleProps,
  LayoutOrderingDimension,
  LayoutRuleRowData,
} from './types';
import { componentInterface } from '@ardoq/component-interface';
import { isEqual } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getComponentTypeRepresentationDataFromComponentType } from 'componentRepresentationData';
import { getDefaultCSSColor, getLightenedColor } from '@ardoq/color-helpers';
import { GraphGroup, GraphNode } from '@ardoq/graph';
import {
  ConstraintSource,
  LayoutConstraintWithSource,
  ProteanGraphState,
  ResolvedLayoutConstraint,
} from '../types';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { notifyLayoutConstraintsUpdated } from 'streams/layoutConstraints/LayoutConstraintActions';
import { ProteanLayoutType } from 'tabview/proteanDiagram/types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { componentMatchesSubquery } from 'tabview/graphViews/layoutConstraints/componentMatchesSubquery';
import { INode } from '@ardoq/yfiles';

type ComposeLayoutConstraintsRuleArgs = {
  existingRules: LayoutConstraintRuleProps[];
  layoutType: ProteanLayoutType;
  isHorizontalConstraint: boolean;
  value: QueryBuilderRuleValue;
  field: string;
  workspaceId: string | null;
};
const composeLayoutConstraintsRule = ({
  existingRules,
  layoutType,
  isHorizontalConstraint,
  value,
  field,
  workspaceId,
}: ComposeLayoutConstraintsRuleArgs) => {
  const dimension: LayoutOrderingDimension | null =
    layoutType === ProteanLayoutType.Tabular
      ? isHorizontalConstraint
        ? 'column'
        : 'row'
      : layoutType === ProteanLayoutType.Hierarchic
        ? isHorizontalConstraint
          ? 'sequence'
          : 'layer'
        : null;

  if (!dimension) {
    return null;
  }

  const componentRules = {
    condition: BooleanOperator.AND,
    rules: [
      {
        id: field,
        field,
        // input is irrelevant in this case, but required by query builder row type
        input: 'select',
        type: 'string',
        operator: Operator.EQUAL,
        value,
      },
      workspaceId
        ? {
            id: 'rootWorkspace',
            field: 'rootWorkspace',
            input: 'text',
            operator: Operator.EQUAL,
            type: 'string',
            value: workspaceId,
          }
        : null,
    ].filter(ExcludeFalsy),
  };

  const exustingRule = existingRules.find(
    rule =>
      isEqual(rule.componentRules.rules, componentRules.rules) &&
      rule.dimension === dimension
  );

  return {
    order: exustingRule?.order || 0,
    dimension,
    componentRules,
  };
};

const alignConstraintsAffectedByRule = (
  componentRules: QueryBuilderSubquery,
  columnConstraintsMap: Map<string, ResolvedLayoutConstraint>,
  nodeMap: Map<string, INode> | null
): LayoutConstraintWithSource[] => {
  const columnConstraints = [...columnConstraintsMap.values()];

  return columnConstraints.map(([nodeId, , value, source]) => {
    const modelId = nodeMap?.get(nodeId)?.tag?.modelId;
    const isNodeMovedByDnD = source === ConstraintSource.MOVED_BY_DND;
    const isAffectedByRule = componentMatchesSubquery(modelId, componentRules);

    const shouldUpdatePosition = !isNodeMovedByDnD && isAffectedByRule;

    return shouldUpdatePosition
      ? [nodeId, 0, ConstraintSource.GENERATED]
      : [nodeId, value, source];
  });
};

export const addOrUpdateSingleLayoutRule = (
  state: ProteanGraphState,
  layoutRule: LayoutConstraintRuleProps
) => {
  const { viewId, layoutState } = state;

  const layoutKey =
    viewId === ViewIds.HIERARCHIC_IN_GRID ? 'hierarchicInGrid' : 'hierarchic';
  const layoutOptions = state.viewSettings.layoutOptions[layoutKey];
  const lastLayoutRules = layoutOptions?.layoutRules ?? [];

  const existingRulesWithoutCurrentRule = lastLayoutRules.filter(
    existingRule =>
      !isEqual(
        existingRule.componentRules.rules,
        layoutRule.componentRules.rules
      )
  );
  const newRules = [...existingRulesWithoutCurrentRule, layoutRule];

  const columnConstraintsMap =
    viewId === ViewIds.HIERARCHIC_IN_GRID
      ? layoutState.hierarchicInGrid?.columnConstraintsMap
      : null;
  const columnConstraintsWithSource = columnConstraintsMap
    ? alignConstraintsAffectedByRule(
        layoutRule.componentRules,
        columnConstraintsMap,
        state.nodeMap
      )
    : null;

  updateLayoutRules(state, newRules, columnConstraintsWithSource);
};

type ComponentTypeWithWorkpaceNames = Record<
  string,
  {
    componentType: APIComponentType;
    workspaceId: string | null;
    workspaceName: string | null;
  }
>;

const getAllComponentTypesInScopeReducer = (
  accumulator: {
    componentTypesWithWorkspaceNames: ComponentTypeWithWorkpaceNames;
    workspacesInScope: Set<string>;
  },
  componentId: string
) => {
  const componentType = componentInterface.getType(componentId);
  const workspaceId = componentInterface.getWorkspaceId(componentId);
  if (!componentType || !workspaceId) {
    return accumulator;
  }
  accumulator.workspacesInScope.add(workspaceId);
  accumulator.componentTypesWithWorkspaceNames[componentType.id] = {
    componentType,
    workspaceId,
    workspaceName: workspaceInterface.getWorkspaceName(workspaceId),
  };
  return accumulator;
};

type GetComponentTypeToRowLayoutRulesArgs = {
  graphNodes: (GraphNode | GraphGroup)[];
  currentLayoutRules?: LayoutConstraintRuleProps[];
  layoutType: ProteanLayoutType;
};
export const getComponentTypeToRowLayoutRules = ({
  graphNodes,
  layoutType,
  currentLayoutRules,
}: GetComponentTypeToRowLayoutRulesArgs): LayoutRuleRowData[] => {
  const componentIds = graphNodes.map(graphNode => graphNode.modelId);

  const { componentTypesWithWorkspaceNames, workspacesInScope } =
    componentIds.reduce(getAllComponentTypesInScopeReducer, {
      componentTypesWithWorkspaceNames: {},
      workspacesInScope: new Set<string>(),
    });

  const hasMultipleWorkspacesInScope = workspacesInScope.size > 1;

  return Object.values(componentTypesWithWorkspaceNames).map(
    ({ componentType, workspaceName, workspaceId }): LayoutRuleRowData => {
      const componentColor =
        componentType.color || getDefaultCSSColor(componentType.level);

      const rowRule = composeLayoutConstraintsRule({
        existingRules: currentLayoutRules || [],
        layoutType,
        isHorizontalConstraint: false,
        value: componentType.name,
        field: 'type',
        workspaceId,
      });

      return {
        rowRule,
        ruleRowLabel: componentType.name,
        ruleRowSublabel: hasMultipleWorkspacesInScope ? workspaceName : '',
        representationData:
          getComponentTypeRepresentationDataFromComponentType(componentType),
        layoutRuleRowLabelColor: componentColor,
        layoutRuleRowLabelBackgroundColor: getLightenedColor(componentColor),
      };
    }
  );
};

export const updateLayoutRules = (
  state: ProteanGraphState,
  layoutRules: LayoutConstraintRuleProps[],
  columnConstraintsWithSource: LayoutConstraintWithSource[] | null
) => {
  const viewId = state.viewId;

  const key = viewId === ViewIds.HIERARCHIC_IN_GRID ? 'hierarchicInGrid' : null;

  if (!key) {
    return;
  }

  const lastViewLayoutOptions =
    viewId === ViewIds.HIERARCHIC_IN_GRID
      ? state.viewSettings.layoutOptions[key]
      : undefined;

  if (!lastViewLayoutOptions) {
    return;
  }

  const newViewLayoutOptions = {
    ...lastViewLayoutOptions,
    layoutRules,
  };

  const newCommonLayoutOptions = {
    ...state.viewSettings.layoutOptions,
    [key]: {
      ...newViewLayoutOptions,
      ...(columnConstraintsWithSource ? { columnConstraintsWithSource } : {}),
    },
  };

  dispatchAction(
    updateViewSettings({
      viewId,
      settings: { layoutOptions: newCommonLayoutOptions },
      persistent: true,
    })
  );

  dispatchAction(notifyLayoutConstraintsUpdated(layoutRules));
};
