import { APIFieldAttributes, APIFieldType, ArdoqId } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { uniq } from 'lodash';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';

export const getComponentNumericFieldsOfWorkspace = (workspaceId: ArdoqId) => {
  const modelId = workspaceInterface.getWorkspaceModelId(workspaceId) || '';

  return uniq(
    workspaceInterface
      .getComponentTypes(workspaceId)
      .flatMap(typeId =>
        fieldInterface.getFieldIdsByComponentType(typeId.id, modelId)
      )
  )
    .map(fieldInterface.getFieldData)
    .filter(
      (field): field is APIFieldAttributes =>
        field?.type === APIFieldType.NUMBER
    );
};
