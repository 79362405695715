import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

interface ScrollToViewComponentPayload {
  componentId: ArdoqId;
}

export const scrollToViewComponent =
  actionCreator<ScrollToViewComponentPayload>(
    '[pagesView] SCROLL_TO_VIEW_COMPONENT'
  );
