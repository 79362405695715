import styled from 'styled-components';
import { Column, DatasourceTable } from '@ardoq/table';
import { GremlinReferenceResult, GremlinResultType } from '../types';
import { formatValue, navigateToEntity, showDetails } from '../utils';
import { SortByFn, SortById } from '@ardoq/pagination';
import { ChevronIcon } from '@ardoq/icons';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { searchFieldLabels$ } from 'streams/fields/advancedSearchFields$';
import { connect } from '@ardoq/rxbeach';
import { SortOrder } from '@ardoq/api-types';

const StyledChevronIcon = styled(ChevronIcon)`
  transform: translateY(7px);
  line-height: 0;
`;
const ArrowSign = (props: { printable?: boolean }) =>
  props.printable ? <>{' -> '}</> : <StyledChevronIcon direction="right" />;

type StreamedProps = {
  fieldsLabels: { [fieldName: string]: string };
};
interface OwnProps {
  results: GremlinReferenceResult[];
  printable?: boolean;
  sortBy: SortByFn;
  sortById: SortById;
  sortOrder?: SortOrder;
  selectedColumns: string[];
}

type GremlinResultsTableReferenceProps = StreamedProps & OwnProps;

const GremlinResultsTableReference = (
  props: GremlinResultsTableReferenceProps
) => (
  <DatasourceTable
    dataSource={props.results}
    data-intercom-target="gremlin results table"
    columns={(
      [
        {
          title: 'Id',
          dataIndex: 'id',
          headerStyle: { width: '1%' },
        },
        {
          title: 'Reference',
          valueRender: (_, result) => (
            <a onClick={() => navigateToEntity(result)}>
              {result.outVLabel}
              <ArrowSign printable={props.printable} />
              {result.inVLabel}
            </a>
          ),
          onHeaderClick: () =>
            props.sortBy(['outVLabel', 'inVLabel'], 'outVLabel/inVLabel'),
          sortOrder: props.sortById === 'outVLabel/inVLabel' && props.sortOrder,
        },
        {
          title: 'Type',
          dataIndex: 'label',
          onHeaderClick: () => props.sortBy('label'),
          sortOrder: props.sortById === 'label' && props.sortOrder,
        },
        ...props.selectedColumns.map(columnName => ({
          title: props.fieldsLabels[columnName] || columnName,
          dataIndex: `properties.${columnName}`,
          valueRender: formatValue,
          onHeaderClick: () => props.sortBy(columnName),
          sortOrder: props.sortById === columnName && props.sortOrder,
        })),
        !props.printable && {
          headerStyle: { width: '150px' },
          valueRender: (_, result) => (
            <a onClick={() => showDetails(result, GremlinResultType.REFERENCE)}>
              Show details
            </a>
          ),
        },
      ] as Column<GremlinReferenceResult>[]
    ).filter(ExcludeFalsy)}
    renderEmptyTable={{ title: 'No results found' }}
  />
);

export default connect(GremlinResultsTableReference, searchFieldLabels$);
