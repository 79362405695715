import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';
import {
  CleanNode,
  DependencyMapNode,
  DependencyMapReference,
} from '@ardoq/dependency-map';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const cleanReferenceChild = (
  node: DependencyMapNode,
  depth: number,
  referenceChildMaxDepth: number
): DependencyMapNode => ({
  ...node,
  degreeOfChildhood: depth,
  incomingReferences: null,
  outgoingReferences: null,
  children:
    (depth < referenceChildMaxDepth &&
      node.children?.map(child =>
        cleanReferenceChild(child, depth + 1, referenceChildMaxDepth)
      )) ||
    null,
});

interface CleanReferenceArgs {
  reference: DependencyMapReference;
  usedReferences: Set<ArdoqId>;
  usedNodes: Set<string>;
  currentDepth: number;
  maxDepth: number;
  referenceChildMaxDepth: number;
  incomingDegreesOfRelationship: number;
  outgoingDegreesOfRelationship: number;
  direction: ReferenceDirection;
}
/** Returns a copy of the reference with the node cleaned of children and cyclic/redundant references */
const cleanReference = ({
  reference: {
    node: {
      children,
      incomingReferences: nodeIncomingReferences,
      outgoingReferences: nodeOutgoingReferences,
      ...node
    },
    ...reference
  },
  usedReferences,
  usedNodes,
  currentDepth,
  maxDepth,
  referenceChildMaxDepth,
  incomingDegreesOfRelationship,
  outgoingDegreesOfRelationship,
  direction,
}: CleanReferenceArgs): DependencyMapReference | null => {
  const [referenceId] = reference.referenceIds;
  const isUsedReference = usedReferences.has(referenceId);

  if (isUsedReference) {
    return null;
  }
  const currentUsedReferences = new Set([...usedReferences, referenceId]);
  const currentUsedNodes = new Set([...usedNodes, node.id]);
  const isUsedNode = usedNodes.has(node.id);
  const [incomingReferences, outgoingReferences] =
    isUsedNode || currentDepth + 1 > maxDepth
      ? [null, null]
      : [
          {
            references:
              direction === ReferenceDirection.INCOMING
                ? nodeIncomingReferences
                : null, // reference chains are directional! only show incoming references in incoming reference chains.
            maxDepth: incomingDegreesOfRelationship,
          },
          {
            references:
              direction === ReferenceDirection.OUTGOING
                ? nodeOutgoingReferences
                : null, // reference chains are directional! only show outgoing references in outgoing reference chains.
            maxDepth: outgoingDegreesOfRelationship,
          },
        ].map(
          ({ references, maxDepth }) =>
            references
              ?.map(reference =>
                cleanReference({
                  reference,
                  usedReferences: currentUsedReferences,
                  usedNodes: currentUsedNodes,
                  currentDepth: currentDepth + 1,
                  maxDepth,
                  referenceChildMaxDepth,
                  incomingDegreesOfRelationship,
                  outgoingDegreesOfRelationship,
                  direction,
                })
              )
              .filter(ExcludeFalsy) ?? null
        );
  const cleanedNode = {
    ...node,
    incomingReferences,
    outgoingReferences,
    children:
      (referenceChildMaxDepth &&
        children?.map(child =>
          cleanReferenceChild(child, 1, referenceChildMaxDepth)
        )) ||
      null,
    referencesOmittedDueToRecursion: isUsedNode,
  };
  return {
    ...reference,
    node: cleanedNode,
  };
};

/**
 * takes the master node and converts it to a display node...
 * - eliminating redundancy
 * - limiting depth in its reference trees
 * - enforcing singular direction (in or out) in its reference trees
 */
const cleanNode: CleanNode = ({
  node: { children, incomingReferences, outgoingReferences, ...node },
  incomingDegreesOfRelationship,
  outgoingDegreesOfRelationship,
  usedNodes,
  referenceChildMaxDepth,
}) => {
  const currentUsedNodes = new Set([...usedNodes, node.id]);
  const [cleanedIncomingReferences, cleanedOutgoingReferences] = [
    {
      references: incomingReferences,
      maxDepth: incomingDegreesOfRelationship,
      direction: ReferenceDirection.INCOMING,
    },
    {
      references: outgoingReferences,
      maxDepth: outgoingDegreesOfRelationship,
      direction: ReferenceDirection.OUTGOING,
    },
  ].map(
    ({ references, maxDepth, direction }) =>
      references
        ?.map(reference =>
          cleanReference({
            reference,
            usedReferences: new Set(),
            usedNodes: currentUsedNodes,
            currentDepth: 1,
            maxDepth,
            referenceChildMaxDepth,
            incomingDegreesOfRelationship,
            outgoingDegreesOfRelationship,
            direction,
          })
        )
        .filter(ExcludeFalsy) ?? null
  );
  return {
    ...node,
    children:
      children?.map(child =>
        cleanNode({
          node: child,
          incomingDegreesOfRelationship,
          outgoingDegreesOfRelationship,
          usedNodes: currentUsedNodes,
          referenceChildMaxDepth,
        })
      ) ?? null,
    incomingReferences: cleanedIncomingReferences,
    outgoingReferences: cleanedOutgoingReferences,
  };
};
export default cleanNode;
