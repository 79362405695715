import { MetamodelContext } from 'metamodel/types';
import { Metamodel } from '@ardoq/api-types';
import {
  assetFolderIsEmpty,
  getAssetFolderComponentCompletion,
  getAssetFolderReferenceCompletion,
} from './utils';
import { AssetFolderSubtitle } from './atoms';
import BreadCrumbNavigation from './breadCrumbNavigation/BreadCrumbNavigation';
import ComponentFieldCompletionWidget from './completion/ComponentFieldCompletionWidget';
import ReferenceFieldCompletionWidget from './completion/ReferenceFieldCompletionWidget';
import WorkspaceNavigationTitle from './workspaceNavigationTitle/WorkspaceNavigationTitle';
import { DefaultNotification } from '@ardoq/status-ui';
import { Box } from '@ardoq/layout';

const AssetFolderStats = ({
  context,
  metamodel,
}: {
  context: MetamodelContext;
  metamodel: Metamodel;
}) => {
  const content = metamodel.metamodel;

  const componentCompletion = getAssetFolderComponentCompletion(
    context,
    content
  );
  const referenceCompletion = getAssetFolderReferenceCompletion(
    context,
    content
  );

  return (
    <Box position="relative">
      <BreadCrumbNavigation metamodel={metamodel} context={context} />
      <Box marginTop="small">
        <WorkspaceNavigationTitle metamodel={metamodel} context={context}>
          {context.name}
        </WorkspaceNavigationTitle>
      </Box>
      <AssetFolderSubtitle
        componentCompletion={componentCompletion}
        referenceCompletion={referenceCompletion}
      />
      {assetFolderIsEmpty(context, content) ? (
        <DefaultNotification>
          The workspaces in this folder have no components or references
        </DefaultNotification>
      ) : (
        <>
          <ComponentFieldCompletionWidget
            componentTypeFieldCompletion={componentCompletion}
            content={content}
            context={context}
            notification="The workspaces in this folder have no components"
          />
          <ReferenceFieldCompletionWidget
            referenceTypeFieldCompletion={referenceCompletion}
            content={content}
            context={context}
            notification="The workspaces in this folder have no references"
          />
        </>
      )}
    </Box>
  );
};

export default AssetFolderStats;
