import { createRef, Component } from 'react';
import styled, { css } from 'styled-components';
import Markdown from 'atomicComponents/Markdown';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import ExpandButton from 'tabview/pagesView/ExpandButton';
import { markdownToTextPreview } from '@ardoq/markdown';
import {
  horizontalSpacing,
  verticalSpacing,
} from 'tabview/pagesView/constants';
import measureComponent from './measureComponent';
import { colors } from '@ardoq/design-tokens';

const fontColorAndMargin = css`
  margin-top: ${verticalSpacing.SMALL};
  color: ${colors.grey15};
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
`;

const DescriptionPreviewWithoutMarkdown = styled.div`
  ${fontColorAndMargin}
  width: 100%;
  text-overflow: ellipsis;
`;

const DescriptionMarkdown = styled(Markdown)`
  ${fontColorAndMargin}
  overflow-wrap: break-word;
`;
const UNEXPANDED_CONTAINER_HEIGHT = 60;

const DescriptionContainer = styled.div<{ $showMarkdown: boolean }>`
  margin-right: ${horizontalSpacing.MEDIUM};
  max-width: 800px;
  padding-left: ${horizontalSpacing.SMALL};
  ${props =>
    !props.$showMarkdown &&
    css`
      max-height: ${UNEXPANDED_CONTAINER_HEIGHT}px;
      overflow: hidden;
    `}
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface DescriptionProps {
  text: string;
  bypassRenderLimit: boolean;
  expandDescription: boolean;
}
interface DescriptionState {
  isExpanded: boolean;
  containerIsOverflowing: boolean;
  measured: boolean;
}

const measuredDescriptionCache = new Map<string, number>();

class Description extends Component<DescriptionProps, DescriptionState> {
  state = { isExpanded: false, containerIsOverflowing: false, measured: false };
  textPreviewCache = '';
  lastParsedText = '';
  private descriptionContainer = createRef<HTMLDivElement>();
  getDescriptionPreviewText = () => {
    const { text } = this.props;
    if (text !== this.lastParsedText) {
      this.textPreviewCache = markdownToTextPreview(text);
      this.lastParsedText = text;
    }
    return this.textPreviewCache;
  };

  private checkContainerOverflow = () => {
    if (!this.props.text) {
      return;
    }
    if (measuredDescriptionCache.has(this.props.text)) {
      this.setState({
        measured: true,
        containerIsOverflowing:
          measuredDescriptionCache.get(this.props.text)! >
          UNEXPANDED_CONTAINER_HEIGHT,
      });
      return;
    }
    if (!this.descriptionContainer.current) {
      return;
    }
    measureComponent(
      DescriptionMarkdown,
      { children: this.props.text },
      this.descriptionContainer.current,
      ({ height }) => {
        measuredDescriptionCache.set(this.props.text, height);
        if (measuredDescriptionCache.size > 500) {
          measuredDescriptionCache.delete(
            [...measuredDescriptionCache.keys()][0]
          );
        }
        const containerIsOverflowing = height > UNEXPANDED_CONTAINER_HEIGHT;
        this.setState({ measured: true, containerIsOverflowing });
      }
    );
  };
  render() {
    const { text, bypassRenderLimit } = this.props;
    const { isExpanded, containerIsOverflowing, measured } = this.state;
    if (!text) {
      return null;
    }

    const shouldShowExpanded =
      bypassRenderLimit || isExpanded || (!containerIsOverflowing && measured);

    if (!measured && !shouldShowExpanded) {
      // let's wait until the thing is measured instead of showing it without markdown.
      return (
        <DescriptionWrapper>
          <DescriptionContainer
            $showMarkdown={true}
            ref={this.descriptionContainer}
          />
        </DescriptionWrapper>
      );
    }

    return (
      <DescriptionWrapper>
        <DescriptionContainer
          $showMarkdown={shouldShowExpanded}
          ref={this.descriptionContainer}
        >
          {shouldShowExpanded ? (
            <DescriptionMarkdown>{text}</DescriptionMarkdown>
          ) : (
            <DescriptionPreviewWithoutMarkdown>
              {this.getDescriptionPreviewText()}
            </DescriptionPreviewWithoutMarkdown>
          )}
        </DescriptionContainer>
        {containerIsOverflowing && (
          <ExpandButton
            marginTop={false}
            isExpanded={shouldShowExpanded}
            expand={expand => this.setState({ isExpanded: expand })}
          />
        )}
      </DescriptionWrapper>
    );
  }

  componentDidMount() {
    this.checkContainerOverflow();
    if (this.props.expandDescription) this.setState({ isExpanded: true });
  }
  componentDidUpdate(prevProps: Readonly<DescriptionProps>) {
    if (
      this.props.expandDescription !== prevProps.expandDescription &&
      this.state.isExpanded !== this.props.expandDescription
    ) {
      this.setState({ isExpanded: this.props.expandDescription });
    }
    if (prevProps.text !== this.props.text) this.checkContainerOverflow();
  }
}

export default Description;
