import styled from 'styled-components';
import { CUSTOM_SVG_ICON_SELECTOR } from '@ardoq/icons';

export const TreeRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  && > ${CUSTOM_SVG_ICON_SELECTOR} {
    max-height: 18px;
  }
`;
