import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoNumericValuesInFieldsDoqProps = { doqType: DoqType };

const NoNumericValuesInFieldsDoq = ({
  doqType,
}: NoNumericValuesInFieldsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message="No data in the current context has numeric values for the selected X and Y fields."
    />
  </EmptyStatesDoqContainer>
);
export default NoNumericValuesInFieldsDoq;
