import {
  ComponentMatrixViewSettings,
  ComponentMatrixViewSettingsState,
} from './types';
import { ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import {
  MenuMetaOptions,
  type SelectedReferenceTypeInfo,
  type TraversedReferenceTypeInfo,
  matchSelectedReferenceType,
  formatReferenceType,
  getFieldDropdownOptions,
  getReferenceTypeDropdownOptions,
} from '@ardoq/settings-bar';
import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { isEqual } from 'lodash';
import includeAllDescendantsMenuItem from 'tabview/includeAllDescendantsMenuItem';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { popoverRegistry } from '@ardoq/popovers';
import { HIDE_EMPTY_VALUES } from 'tabview/consts';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { filterInterface } from 'modelInterface/filters/filterInterface';

const VIEW_ID = ViewIds.COMPONENT_MATRIX;

const AXIS_FIELDS_MAXIMUM_SELECTION = 2;

const NO_OPTIONS = 'noOptions';
popoverRegistry.set(
  NO_OPTIONS,
  () => 'No list fields or references found in this context.'
);

interface ItemClickArgs {
  viewSettings: ComponentMatrixViewSettings;
  isHorizontalAxis: boolean;
}

const processListFieldClick = (
  value: string,
  currentListFields: string[],
  currentReferenceType: SelectedReferenceTypeInfo | null
) => {
  const isUntoggle = currentListFields.includes(value);
  const updatedListFields = isUntoggle
    ? currentListFields.filter(field => field !== value)
    : [...currentListFields, value];
  const newReferenceType =
    updatedListFields.length >= AXIS_FIELDS_MAXIMUM_SELECTION
      ? null
      : currentReferenceType;
  return {
    updatedListFields,
    newReferenceType,
  };
};
const processReferenceClick = (
  value: SelectedReferenceTypeInfo,
  currentListFields: string[],
  currentReferenceType: SelectedReferenceTypeInfo | null
) => {
  const isUntoggle =
    currentReferenceType !== null &&
    matchSelectedReferenceType(currentReferenceType, value);
  return {
    updatedListFields: currentListFields,
    newReferenceType: isUntoggle ? null : value,
  };
};

const itemClick =
  ({ viewSettings, isHorizontalAxis }: ItemClickArgs) =>
  (value: string | SelectedReferenceTypeInfo) => {
    const listFieldsKey = isHorizontalAxis
      ? 'selectedFieldNamesX'
      : 'selectedFieldNamesY';
    const currentListFields = viewSettings[listFieldsKey];

    const referenceTypesKey = isHorizontalAxis
      ? 'referenceTypeX'
      : 'referenceTypeY';
    const currentReferenceType = viewSettings[referenceTypesKey];

    // limit selection to 1 ref + 1 list field, or 0 refs and 2 list fields
    const { updatedListFields, newReferenceType } =
      typeof value === 'string'
        ? processListFieldClick(value, currentListFields, currentReferenceType)
        : processReferenceClick(value, currentListFields, currentReferenceType);

    const newListFields = updatedListFields.slice(
      (newReferenceType ? 1 : 0) - AXIS_FIELDS_MAXIMUM_SELECTION
    );

    const listFieldsUpdated = !isEqual(currentListFields, newListFields);
    const referenceTypeUpdated = newReferenceType !== currentReferenceType;

    const settings: Partial<ComponentMatrixViewSettings> = {
      ...(listFieldsUpdated && { [listFieldsKey]: newListFields }),
      ...(referenceTypeUpdated && {
        [referenceTypesKey]: newReferenceType,
      }),
    };

    dispatchAction(
      updateViewSettings({
        viewId: VIEW_ID,
        settings,
        persistent: true,
      })
    );
  };

const compareReferenceTypes = (
  a: TraversedReferenceTypeInfo,
  b: TraversedReferenceTypeInfo
) => localeCompare(a.displayName, b.displayName, getCurrentLocale());

const componentMatrixLeftMenu = (
  viewSettings: ComponentMatrixViewSettings,
  {
    listFields,
    traversedIncomingReferenceTypes,
    traversedOutgoingReferenceTypes,
  }: ComponentMatrixViewSettingsState
): SettingsConfig[] => {
  const xReferenceClick = (value: SelectedReferenceTypeInfo) =>
    itemClick({ viewSettings, isHorizontalAxis: true })(value);

  const sortedOutgoingReferenceTypes = traversedOutgoingReferenceTypes.sort(
    compareReferenceTypes
  );
  const sortedIncomingReferenceTypes = traversedIncomingReferenceTypes.sort(
    compareReferenceTypes
  );

  const referenceTypeOptionsX = getReferenceTypeDropdownOptions({
    viewId: VIEW_ID,
    availableOutgoingRefTypes: sortedOutgoingReferenceTypes,
    availableIncomingRefTypes: sortedIncomingReferenceTypes,
    viewState: viewSettings,
    isParentRelationAsReference: false,
    metaOptions: MenuMetaOptions.NO_OPTIONS,
    isActive: (item: TraversedReferenceTypeInfo) =>
      isEqual(item, viewSettings.referenceTypeX),
    onClick: xReferenceClick,
    formatValue: formatReferenceType,
    quickPerspectiveReferenceTypeFilters:
      filterInterface.getActiveQuickReferenceTypeFilters(),
  });
  const yReferenceClick = (value: SelectedReferenceTypeInfo) =>
    itemClick({
      viewSettings,
      isHorizontalAxis: false,
    })(value);

  const referenceTypeOptionsY = getReferenceTypeDropdownOptions({
    viewId: VIEW_ID,
    availableOutgoingRefTypes: sortedOutgoingReferenceTypes,
    availableIncomingRefTypes: sortedIncomingReferenceTypes,
    viewState: viewSettings,
    isParentRelationAsReference: false,
    metaOptions: MenuMetaOptions.NO_OPTIONS,
    isActive: (item: TraversedReferenceTypeInfo) =>
      isEqual(item, viewSettings.referenceTypeY),
    onClick: yReferenceClick,
    formatValue: formatReferenceType,
    quickPerspectiveReferenceTypeFilters:
      filterInterface.getActiveQuickReferenceTypeFilters(),
  });
  const xFieldClick = itemClick({
    viewSettings,
    isHorizontalAxis: true,
  });
  const xOptions: DropdownItem[] = [
    ...getFieldDropdownOptions(
      VIEW_ID,
      listFields.map(listField => ({
        ...listField,
        onClick: () => xFieldClick(listField.name),
      })),
      'selectedFieldNamesX',
      name => viewSettings.selectedFieldNamesX.includes(name),
      onViewSettingsUpdate
    ),
    ...referenceTypeOptionsX,
  ];
  const yFieldClick = itemClick({
    viewSettings,
    isHorizontalAxis: false,
  });
  const yOptions: DropdownItem[] = [
    ...getFieldDropdownOptions(
      VIEW_ID,
      listFields.map(listField => ({
        ...listField,
        onClick: () => yFieldClick(listField.name),
      })),
      'selectedFieldNamesY',
      name => viewSettings.selectedFieldNamesY.includes(name),
      onViewSettingsUpdate
    ),
    ...referenceTypeOptionsY,
  ];
  if (listFields.length) {
    const listFieldsDivider = {
      label: 'List fields',
      type: DropdownOptionType.HEADER,
    };
    xOptions.unshift(listFieldsDivider);
    yOptions.unshift(listFieldsDivider);
  }
  return [
    {
      id: 'yFields',
      label: 'Select Row Groupings',
      iconName: IconName.ARROW_RIGHT_ALT,
      className: 'rotate-90',
      options: yOptions,
      type: SettingsType.DROPDOWN,
      isDisabled: !yOptions.length,
      popoverId: yOptions.length ? undefined : NO_OPTIONS,
    },
    {
      id: 'xFields',
      label: 'Select Column Groupings',
      iconName: IconName.ARROW_RIGHT_ALT,
      options: xOptions,
      type: SettingsType.DROPDOWN,
      isDisabled: !xOptions.length,
      popoverId: xOptions.length ? undefined : NO_OPTIONS,
    },
    {
      id: 'filterNulls',
      label: HIDE_EMPTY_VALUES,
      iconName: IconName.NOT_INTERESTED,
      isActive: viewSettings.filterNulls,
      type: SettingsType.TOGGLE,
      onViewSettingsUpdate,
    },
    includeAllDescendantsMenuItem(viewSettings.includeAllDescendants),
  ];
};
export default componentMatrixLeftMenu;
