import {
  Rect,
  getRectCenter,
  inside,
  intersects,
  offset,
  shiftInto,
  someIntersections,
} from '@ardoq/graph';
import { type Point, geometry } from '@ardoq/math';
import { BubbleLayoutInfo } from './types';
import { ZERO_BUBBLE_SIZE } from './consts';

export const MIN_MARGIN = 2;
const MAX_ATTEMPTS = 16;

interface CandidateRectStrategy {
  center?: Point;
  options: CandidateRectStrategyOptions;
}
enum CandidateRectStrategyOptions {
  None = 0,
  MoveRight = 1,
  MoveDown = 2,
}
export interface BubbleChartDeCollisionParameters {
  input: BubbleLayoutInfo[];
  center: Point;
  bounds: Rect;
}

const layoutBox = (
  rect: Rect,
  arrangedRects: Rect[],
  bounds: Rect,
  bubbleCenter: Point,
  center?: Point,
  strategy: CandidateRectStrategyOptions = CandidateRectStrategyOptions.None
): Rect => {
  let result = rect;
  const [centerX, centerY] = center ?? [0, 0];
  arrangedRects.forEach(arrangedRect => {
    if (intersects(result, arrangedRect)) {
      const [resultCenterX, resultCenterY] = getRectCenter(result);
      const moveRight = center
        ? resultCenterX > centerX
        : (strategy & CandidateRectStrategyOptions.MoveRight) > 0;
      const deltaLeft = moveRight
        ? arrangedRect.right - result.left
        : arrangedRect.left - result.right;
      const moveDown = center
        ? resultCenterY > centerY
        : (strategy & CandidateRectStrategyOptions.MoveDown) > 0;
      const deltaTop = moveDown
        ? arrangedRect.bottom - result.top
        : arrangedRect.top - result.bottom;
      const horizontallyShifted = offset(result, [deltaLeft, 0]);
      const verticallyShifted = offset(result, [0, deltaTop]);

      const horizontallyShiftedIsValid = inside(horizontallyShifted, bounds);
      const verticallyShiftedIsValid = inside(verticallyShifted, bounds);
      if (!horizontallyShiftedIsValid && !verticallyShiftedIsValid) {
        // out of bounds, this strategy will fail and we can return
        return Rect.IMPOSSIBLE;
      }

      const collides = (rect: Rect) =>
        arrangedRects.some(arrangedRect => intersects(arrangedRect, rect));
      const horizontallyShiftedCollides =
        !horizontallyShiftedIsValid || collides(horizontallyShifted);
      const verticallyShiftedCollides =
        !verticallyShiftedIsValid || collides(verticallyShifted);

      if (verticallyShiftedCollides && !horizontallyShiftedCollides) {
        result = horizontallyShifted;
      } else if (!verticallyShiftedCollides && horizontallyShiftedCollides) {
        result = verticallyShifted;
      } else {
        result = getBestCandidate(bubbleCenter, [
          horizontallyShifted,
          verticallyShifted,
        ]);
      }
    }
  });
  return result;
};
const getCandidateRect = (
  item: BubbleLayoutInfo,
  arrangedRects: Rect[],
  strategy: CandidateRectStrategy,
  bounds: Rect
): Rect => {
  let attemptsRemaining = MAX_ATTEMPTS;
  let candidateRect = shiftInto(item.labelRect, bounds);
  while (
    someIntersections(candidateRect, arrangedRects) &&
    --attemptsRemaining > 0
  ) {
    candidateRect = layoutBox(
      candidateRect,
      arrangedRects,
      bounds,
      getRectCenter(item.labelRect),
      strategy.center,
      strategy.options
    );
  }
  if (
    attemptsRemaining === 0 &&
    someIntersections(candidateRect, arrangedRects)
  ) {
    candidateRect = Rect.IMPOSSIBLE;
  }
  return candidateRect;
};

const yOffset = (radius: number, fontSize: number) =>
  radius ? 0 : Math.max(fontSize, ZERO_BUBBLE_SIZE);

export const decollide = (
  params: BubbleChartDeCollisionParameters
): BubbleLayoutInfo[] => {
  const { input, center, bounds } = params;
  const result = input.map(item => ({
    cid: item.cid,
    labelRect: new Rect(
      item.labelRect.left - MIN_MARGIN,
      item.labelRect.top - MIN_MARGIN + yOffset(item.radius, item.fontSize),
      item.labelRect.width + 2 * MIN_MARGIN,
      item.labelRect.height + 2 * MIN_MARGIN
    ),
    index: item.index,
    center: item.center,
    radius: item.radius,
    fontSize: item.fontSize,
  }));

  const arrangedRects: Rect[] = [];
  result.forEach(layoutInfo => {
    const strategies: CandidateRectStrategy[] = [
      { center, options: CandidateRectStrategyOptions.None },
      { options: CandidateRectStrategyOptions.None },
      { options: CandidateRectStrategyOptions.MoveRight },
      { options: CandidateRectStrategyOptions.MoveDown },
      {
        options:
          CandidateRectStrategyOptions.MoveRight |
          CandidateRectStrategyOptions.MoveDown,
      },
    ];
    const candidateRects: Rect[] = strategies.map(strategy =>
      getCandidateRect(layoutInfo, arrangedRects, strategy, bounds)
    );
    layoutInfo.labelRect = getBestCandidate(layoutInfo.center, candidateRects);

    arrangedRects.push(layoutInfo.labelRect);
  });
  return result;
};
const getBestCandidate = (startPoint: Point, candidates: Rect[]): Rect => {
  const withDistance = candidates
    .map(candidate => ({
      candidate,
      physicalDistance: getPhysicalDistance(startPoint, candidate),
      distance: geometry.distanceTwoArgs(startPoint, getRectCenter(candidate)),
    }))
    .sort((a, b) =>
      a.physicalDistance === 0 && b.physicalDistance === 0
        ? a.distance - b.distance
        : a.physicalDistance - b.physicalDistance
    );
  const [{ candidate }] = withDistance;
  return candidate;
};
const getPhysicalDistance = ([centerX, centerY]: Point, rect: Rect): number => {
  const {
    left: rectLeft,
    top: rectTop,
    right: rectRight,
    bottom: rectBottom,
  } = rect;

  const horizontalDistance =
    rectLeft <= centerX && rectRight >= centerX
      ? 0
      : rectLeft > centerX
        ? rectLeft - centerX
        : centerX - rectRight;
  const verticalDistance =
    rectTop <= centerY && rectBottom >= centerY
      ? 0
      : rectTop > centerY
        ? rectTop - centerY
        : centerY - rectBottom;

  return (horizontalDistance ** 2 + verticalDistance ** 2) ** 0.5;
};
