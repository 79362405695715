import { FieldCompletion } from './types';
import {
  MetamodelComponentTypeContext,
  MetamodelContextSettings,
  MetamodelReferenceTypeContext,
} from 'metamodel/types';
import CompletionBarHeader from './completionBar/CompletionBarHeader';
import CompletionBar from './completionBar/CompletionBar';
import { Metamodel } from '@ardoq/api-types';
import {
  MetamodelItemWithField,
  trackClickFieldInMetamodelSidebar,
} from 'metamodel/tracking';
import GremlinSearchTableWithLoader from '../GremlinSearchTableWithLoader';
import {
  getComponentsOfTypeQuery,
  getReferencesOfTypeQuery,
} from '../fieldStats/utils';
import { dispatchAction } from '@ardoq/rxbeach';
import { setMetamodelContext } from 'metamodel/navigation/actions';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { Paragraph } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

interface FieldCompletionWidgetProps {
  fieldCompletion?: FieldCompletion[];
  context: (MetamodelComponentTypeContext | MetamodelReferenceTypeContext) &
    MetamodelContextSettings;
  metamodel: Metamodel;
}

const FieldCompletionWidget = ({
  fieldCompletion,
  context,
  metamodel,
}: FieldCompletionWidgetProps) => {
  if (fieldCompletion && fieldCompletion.length)
    return (
      <Box marginTop="xlarge">
        <Paragraph color="textModerate">
          Field completion for {context.name}
        </Paragraph>
        <Box marginTop="small">
          <CompletionBarHeader left="Field" right="% of fields with value" />
        </Box>
        {fieldCompletion.map(
          ({ id, name, completeness, filledFieldCount, totalFieldCount }) => {
            const label = `${Math.floor(completeness)}%`;
            const hoverLabel = `${filledFieldCount}/${totalFieldCount}`;
            const onClick = context.settings.isolatedNavigation
              ? undefined
              : () => {
                  if (
                    context.type === SimpleBlockDiagramContentType.COMPONENT
                  ) {
                    trackClickFieldInMetamodelSidebar(
                      MetamodelItemWithField.COMPONENT_TYPE
                    );
                  } else if (
                    (context.type = SimpleBlockDiagramContentType.REFERENCE)
                  ) {
                    trackClickFieldInMetamodelSidebar(
                      MetamodelItemWithField.REFERENCE_TYPE
                    );
                  }
                  dispatchAction(
                    setMetamodelContext({ ...context, field: id })
                  );
                };
            return (
              <CompletionBar
                key={id}
                name={name}
                completeness={completeness}
                label={label}
                hoverLabel={hoverLabel}
                onClick={onClick}
              />
            );
          }
        )}
      </Box>
    );
  else if (!context.settings.isolatedNavigation) {
    const query =
      context.type === SimpleBlockDiagramContentType.COMPONENT
        ? getComponentsOfTypeQuery(context)
        : getReferencesOfTypeQuery(context, metamodel);
    return <GremlinSearchTableWithLoader query={query} />;
  }
  return null;
};

export default FieldCompletionWidget;
