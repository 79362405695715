import { updateViewSettings } from '@ardoq/view-settings';
import { toggleComponentDescription } from 'streams/components/ComponentActions';
import { toggleReferenceDescription } from 'streams/references/ReferenceActions';
import { TableViewModel } from './types';
import { multiActionReducer, reducer } from '@ardoq/rxbeach';
import { ExtractPayload } from '@ardoq/rxbeach';
import { CidArgs } from '@ardoq/common-helpers';

// TODO we should not care about viewId here. This should be handled by namespaces
export const resetExpandedDescriptionsReducer = (targetViewId: string) => {
  const handleUpdateViewSettings = (
    state: TableViewModel,
    { viewId, settings }: ExtractPayload<typeof updateViewSettings>
  ) => {
    if (viewId !== targetViewId) {
      return state;
    }
    if (!Object.prototype.hasOwnProperty.call(settings, 'expandDescription')) {
      return state;
    }
    return { ...state, expandedDescriptions: new Set<string>() };
  };
  return reducer(updateViewSettings, handleUpdateViewSettings);
};

const handleToggleDescription = (state: TableViewModel, { cid }: CidArgs) => {
  const expanded = new Set(state.expandedDescriptions);
  if (expanded.has(cid)) {
    expanded.delete(cid);
  } else {
    expanded.add(cid);
  }
  return { ...state, expandedDescriptions: expanded };
};

export const toggleDescriptionReducer = multiActionReducer(
  [toggleComponentDescription, toggleReferenceDescription],
  handleToggleDescription
);
