import { action$, ofType } from '@ardoq/rxbeach';
import { RoutineMap } from 'streams/types';
import { filter, tap } from 'rxjs/operators';
import {
  zoomCenter as zoomCenterAction,
  zoomIn as zoomInAction,
  zoomOut as zoomOutAction,
} from './actions';
import Ghost from './Ghost';
import { CSSProperties } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { ViewIds } from '@ardoq/api-types';
import ZoomControls from './ZoomControls';
import { type Point, geometry } from '@ardoq/math';
import { MouseButton } from '@ardoq/common-helpers';
import { ZoomContainer } from 'tabview/relationshipDiagrams/atoms';
import { ONLY_EXPORT_CHILDREN_CLASS_NAME } from '@ardoq/export';
import { DisabledZoomControls } from '@ardoq/view-settings';

const boxProps = ['top', 'left', 'right', 'bottom', 'width', 'height'] as const;
const scaleBox = (box: DOMRect, scale: number) =>
  boxProps.reduce(
    (
      newBox: Partial<Record<(typeof boxProps)[number], number>>,
      prop: (typeof boxProps)[number]
    ) => ((newBox[prop] = box[prop] * scale), newBox),
    {}
  ) as DOMRect;

const DELTA = 0.1;
const DRAG_START_DELTA = 15;
const DELTA_Y_THRESHOLD = 30;
const ZOOM_LIMIT_FONTSIZE_PX = 70;

const OuterContainer = styled.div.attrs({
  className: ONLY_EXPORT_CHILDREN_CLASS_NAME,
})`
  position: relative;
  height: 100%;
  width: 100%;
`;

const InnerContainer = styled.div.attrs({
  className: ONLY_EXPORT_CHILDREN_CLASS_NAME,
})`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
`;

type ZoomableProps = {
  viewId?: ViewIds;
  children?: React.ReactNode;
  innerContainerStyle?: CSSProperties;
};

type ZoomableState = {
  scale: number;
  translateX: number;
  translateY: number;
  transformStyle: any;
  isGhostSelect: boolean;
  ghostLeft: number;
  ghostTop: number;
  zoomControlsDisabledState: DisabledZoomControls;
};

const createZoomActionRoutines = (
  viewId: ViewIds,
  zoomIn: () => void,
  zoomOut: () => void,
  zoomCenter: (duration: number) => void
) => {
  const routines = new Map([
    [zoomInAction.type, zoomIn],
    [zoomOutAction.type, zoomOut],
    [zoomCenterAction.type, zoomCenter],
  ]) as RoutineMap;
  return action$.pipe(
    ofType(zoomInAction, zoomOutAction, zoomCenterAction),
    filter(action => viewId === action.payload.viewId),
    tap(({ type, payload: { duration } }) => {
      const routine = routines.get(type)!;
      routine(duration);
    })
  );
};

class Zoomable extends React.Component<ZoomableProps, ZoomableState> {
  state = {
    scale: 1,
    translateX: 0,
    translateY: 0,
    transformStyle: {},
    isGhostSelect: false,
    ghostLeft: 0,
    ghostTop: 0,
    zoomControlsDisabledState: DisabledZoomControls.NONE,
  };

  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  contentRef: React.RefObject<HTMLDivElement> = React.createRef();
  deltaY = 0;
  dragStart = {
    deltaX: 0,
    deltaY: 0,
    x: 0,
    y: 0,
  };
  isDrag = false;
  zoomRoutines: any = null;
  zoomSubscription: any = null;
  constructor(props: ZoomableProps) {
    super(props);
    if (props.viewId) {
      this.zoomRoutines = createZoomActionRoutines(
        props.viewId,
        this.zoomIn,
        this.zoomOut,
        this.zoomCenter
      );
    }
  }
  onWheel = (event: React.WheelEvent<HTMLElement>) => {
    // TODO delta type
    this.deltaY += event.deltaY;
    if (Math.abs(this.deltaY) > DELTA_Y_THRESHOLD) {
      const direction = this.deltaY < 0 ? 1 : -1;
      this.deltaY = 0;
      const scale = this.state.scale + direction * DELTA * this.state.scale;
      this.scaleAt(scale, [event.clientX, event.clientY]);
    }
  };

  onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (event.button !== MouseButton.PRIMARY) {
      return;
    }
    // TODO add a way to cancel DnD
    const { clientX: x, clientY: y } = event;
    event.preventDefault();
    event.stopPropagation();
    if (event.shiftKey) {
      this.setState({ isGhostSelect: true, ghostLeft: x, ghostTop: y });
    } else {
      this.setDragStartPoint([x, y]);
    }
  };

  onMouseMove = (event: MouseEvent) => {
    const { clientX: x, clientY: y } = event;
    if (
      !this.isDrag &&
      geometry.distanceTwoArgs([x, y], [this.dragStart.x, this.dragStart.y]) <
        DRAG_START_DELTA
    ) {
      return;
    }
    this.isDrag = true;
    this.move([x, y]);
  };

  onMouseUp = () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.isDrag = false;
  };

  onSelection = (clientRect: DOMRect) => {
    if (!(this.containerRef.current && this.contentRef.current)) {
      this.setState({ isGhostSelect: false });
      return;
    }

    const containerBox = this.containerRef.current.getBoundingClientRect();
    const contentBox = this.contentRef.current.getBoundingClientRect();
    const deltaTranslateX = clientRect.left - contentBox.left;
    const deltaTranslateY = clientRect.top - contentBox.top;
    const selectedBox = scaleBox(clientRect, 1 / this.state.scale);
    const scale = Math.min(
      containerBox.width / selectedBox.width,
      containerBox.height / selectedBox.height
    );
    const scaledBox = scaleBox(selectedBox, scale);
    const translateX =
      (containerBox.width - scaledBox.width) / 2 -
      (deltaTranslateX / this.state.scale) * scale;
    const translateY =
      (containerBox.height - scaledBox.height) / 2 -
      (deltaTranslateY / this.state.scale) * scale;
    const transformStyle = {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      transition: 'transform 250ms linear',
    };
    this.setState({
      isGhostSelect: false,
      scale,
      translateX,
      translateY,
      transformStyle,
    });
  };

  setDragStartPoint = ([x, y]: Point) => {
    this.dragStart = {
      x,
      y,
      deltaX: this.state.translateX - x,
      deltaY: this.state.translateY - y,
    };
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  move = ([x, y]: Point) => {
    const translateX = this.dragStart.deltaX + x;
    const translateY = this.dragStart.deltaY + y;
    const scale = this.state.scale;
    const transformStyle = {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      transition: '',
    };
    this.setState({
      scale,
      translateX,
      translateY,
      transformStyle,
    });
  };

  center = ({ duration = 0 } = {}) => {
    if (!(this.containerRef.current && this.contentRef.current)) {
      return;
    }

    this.setState({ zoomControlsDisabledState: DisabledZoomControls.NONE });

    const containerBox = this.containerRef.current.getBoundingClientRect();
    const contentBox = scaleBox(
      this.contentRef.current.getBoundingClientRect(),
      1 / this.state.scale
    );
    if (contentBox.width === 0 || contentBox.height === 0) {
      return;
    }
    const scale = Math.min(
      containerBox.width / contentBox.width,
      containerBox.height / contentBox.height
    );
    const scaledBox = scaleBox(contentBox, scale);
    const translateX = (containerBox.width - scaledBox.width) / 2;
    const translateY = (containerBox.height - scaledBox.height) / 2;
    const transformStyle = {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      transition: duration ? `transform ${duration}ms linear` : '',
    };
    this.setState({
      scale,
      translateX,
      translateY,
      transformStyle,
    });
  };

  scaleAt = (scale: number, [x, y]: Point, duration = 200) => {
    if (!(this.containerRef.current && this.contentRef.current)) {
      return;
    }

    // a "normal" font size is assumed to be 14px;
    const zoomLimitIsReached = ZOOM_LIMIT_FONTSIZE_PX / 14 < scale;

    if (
      Boolean(
        this.state.zoomControlsDisabledState & DisabledZoomControls.ZOOM_IN
      ) !== zoomLimitIsReached
    ) {
      this.setState({
        zoomControlsDisabledState:
          this.state.zoomControlsDisabledState ^ DisabledZoomControls.ZOOM_IN,
      });
    }

    if (zoomLimitIsReached) {
      return;
    }

    const containerBox = this.containerRef.current.getBoundingClientRect();
    const deltaX = x - containerBox.left;
    const deltaY = y - containerBox.top;
    const contentX = deltaX - this.state.translateX;
    const contentY = deltaY - this.state.translateY;
    const translateX = deltaX - (contentX / this.state.scale) * scale;
    const translateY = deltaY - (contentY / this.state.scale) * scale;
    const transformStyle = {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      transition: `transform ${duration}ms linear`,
    };
    this.setState({
      scale,
      translateX,
      translateY,
      transformStyle,
    });
  };

  zoomCenter = (duration = 300) => this.center({ duration });

  zoomIn = () => this.zoomAtCenter({ delta: 3 });
  zoomOut = () => this.zoomAtCenter({ delta: -3 });

  componentDidMount() {
    if (this.zoomRoutines) {
      this.zoomSubscription = this.zoomRoutines.subscribe();
    }
  }

  componentWillUnmount() {
    if (this.zoomSubscription) {
      this.zoomSubscription.unsubscribe();
      this.zoomSubscription = null;
    }
  }

  render() {
    const { innerContainerStyle = {} } = this.props;
    const { transformStyle } = this.state;
    return (
      <OuterContainer
        ref={this.containerRef}
        onWheel={this.onWheel}
        onMouseDown={this.onMouseDown}
      >
        <ZoomContainer>
          <ZoomControls
            zoomIn={this.zoomIn}
            zoomCenter={this.zoomCenter}
            zoomOut={this.zoomOut}
            disabledState={this.state.zoomControlsDisabledState}
          />
        </ZoomContainer>
        {this.state.isGhostSelect && (
          <Ghost
            left={this.state.ghostLeft}
            top={this.state.ghostTop}
            onSelection={this.onSelection}
          />
        )}
        <InnerContainer
          ref={this.contentRef}
          style={{ ...innerContainerStyle, ...transformStyle }}
        >
          {this.props.children}
        </InnerContainer>
      </OuterContainer>
    );
  }

  zoomAtCenter({ delta }: { delta: number }) {
    if (!(this.containerRef.current && this.contentRef.current)) {
      return;
    }
    const containerBox = this.containerRef.current.getBoundingClientRect();

    // to end up on the same zoom level while scaling back and forth with zoom controls,
    // we must calculate the last scale, instead of using the changed state
    const scale =
      delta > 0
        ? this.state.scale + delta * DELTA * this.state.scale
        : this.state.scale / (1 + Math.abs(delta) * DELTA);

    this.scaleAt(scale, [
      containerBox.left + containerBox.width / 2,
      containerBox.top + containerBox.height / 2,
    ]);
  }
}

export default Zoomable;
