import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { ModalSize, info } from '@ardoq/modal';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { Link, Text } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';

const StandardInfoDialogContentContainerStyles = styled.div`
  padding: ${s32};
`;

const cantManageUsersDialogBody = (noInternalUserManagement?: boolean) => (
  <StandardInfoDialogContentContainerStyles>
    {noInternalUserManagement ? (
      <p>
        Your organization is using a SSO/identity provider. Please refer to this
        user management to give users access to Ardoq.
      </p>
    ) : (
      <Stack gap="xsmall">
        {orgUsersInterface.getOrgAdmins().map(admin => (
          <Text key={admin.email} variant="text1">
            {admin.name} (
            <Link href={`mailto:${admin.email}`} hideIcon>
              {admin.email}
            </Link>
            )
          </Text>
        ))}
      </Stack>
    )}
  </StandardInfoDialogContentContainerStyles>
);

const cantManageUsers = (noInternalUserManagement?: boolean) =>
  info({
    title: noInternalUserManagement
      ? 'Add user'
      : 'Organization administrators',
    body: cantManageUsersDialogBody(noInternalUserManagement),
    modalSize: ModalSize.S,
  });

export default cantManageUsers;
