import { LayoutConstraint } from 'tabview/graphViews/types';

const incrementConstraint = (
  currentConstraints: LayoutConstraint[],
  index: number,
  direction: 1 | -1,
  itemId: string
) => {
  const newConstraints = [...currentConstraints];
  const currentConstraint = currentConstraints[index];
  const currentValue = currentConstraint?.[1];
  if (index !== -1) {
    newConstraints.splice(index, 1);
  }
  const newValue = (currentValue ?? 0) + direction;
  newConstraints.push([itemId, newValue]);
  return newConstraints;
};
export default incrementConstraint;
