import {
  BaseClass,
  IRenderContext,
  IVisualCreator,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { ProteanTabularLayoutGridLayoutInfo } from './types';
import { colors } from '@ardoq/design-tokens';
import { createSvgElement } from '@ardoq/dom-utils';

const borders = (lengths: number[]) =>
  lengths.reduce(
    (ends: number[], length) => [
      ...ends,
      (ends[ends.length - 1] || 0) + length,
    ],
    [0]
  );
const straightLinePathData = (x0: number, y0: number, x1: number, y1: number) =>
  `M${x0},${y0} L${x1},${y1}`;

class GridlinesVisualCreator extends BaseClass(IVisualCreator) {
  constructor(public grids: ProteanTabularLayoutGridLayoutInfo[]) {
    super();
  }
  override createVisual(context: IRenderContext): Visual | null {
    const { canvasComponent } = context;
    if (!canvasComponent) {
      return null;
    }
    const {
      viewport: {
        x: viewportX,
        y: viewportY,
        width: viewportWidth,
        height: viewportHeight,
      },
    } = canvasComponent;

    const pathData = this.grids
      .map(grid => {
        const { x, y, columnWidths, rowHeights, groupWidth, groupHeight } =
          grid;
        // the root grid will be at [-∞, -∞, ∞, ∞]. sub-grids will have their position and size.
        const rowGridlinesLeft = isFinite(x) ? x : viewportX;
        const columnGridlinesTop = isFinite(y) ? y : viewportY;
        const columnGridlinesStartX = isFinite(x) ? x : 0;
        const rowGridlinesStartY = isFinite(y) ? y : 0;
        const [rowGridlinePositions, columnGridlinePositions] = [
          rowHeights,
          columnWidths,
        ].map(borders);

        const gridWidth = isFinite(groupWidth) ? groupWidth : viewportWidth;
        const gridHeight = isFinite(groupHeight) ? groupHeight : viewportHeight;

        const rowBordersPathData = rowGridlinePositions.map(rowBorder =>
          straightLinePathData(
            rowGridlinesLeft,
            rowGridlinesStartY + rowBorder,
            rowGridlinesLeft + gridWidth,
            rowGridlinesStartY + rowBorder
          )
        );
        const columnBordersPathData = columnGridlinePositions.map(
          columnBorder =>
            straightLinePathData(
              columnGridlinesStartX + columnBorder,
              columnGridlinesTop,
              columnGridlinesStartX + columnBorder,
              columnGridlinesTop + gridHeight
            )
        );

        return rowBordersPathData.concat(columnBordersPathData).join(' ');
      })
      .join(' ');

    const gridlines = createSvgElement('path', {
      d: pathData,
      stroke: colors.purple35,
      'stroke-width': '0.5',
      opacity: '0.8',
      'stroke-dasharray': '4',
    });
    return new SvgVisual(gridlines);
  }
  override updateVisual(
    context: IRenderContext,
    _oldVisual: Visual | null
  ): Visual | null {
    return this.createVisual(context);
  }
}
export default GridlinesVisualCreator;
