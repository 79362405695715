import type { RepresentationData } from '@ardoq/data-model';
import {
  ArdoqIconCategory,
  CUSTOM_SVG_ICON_CLASS,
  fontAwesomeIcons,
  getIcon,
} from '@ardoq/icons';
import type { Rectangle } from '@ardoq/graph';
import { createSvgElement } from '@ardoq/dom-utils';

type SvgComponentRepresentationArgs = {
  bounds: Rectangle;
  representationData: RepresentationData;
  clipPath?: React.SVGAttributes<SVGElement>['clipPath'];
};

const svgComponentRepresentation = ({
  representationData: { isImage, icon = getIcon(''), value },
  bounds: [left, top, width, height],
  clipPath,
}: SvgComponentRepresentationArgs) => {
  if (isImage && value !== null) {
    const image = createSvgElement('image', {
      href: value,
      x: `${left}`,
      y: `${top}`,
      width: `${width}`,
      height: `${height}`,
    });
    if (clipPath) {
      image.setAttribute('clipPath', clipPath);
    }
    return image;
  } else if (icon.isSVG) {
    const { scale, id: iconId } = icon;
    const scaleString = scale ? `scale(${scale.x}, ${scale.x})` : '';
    const svg = createSvgElement('svg', {
      class: CUSTOM_SVG_ICON_CLASS,
      viewBox: '0 0 80 80',
      x: `${left}`,
      y: `${top}`,
      width: `${width}`,
      height: `${height}`,
    });
    const use = createSvgElement('use', {
      transform: scaleString,
      href: `#${iconId}`,
    });
    svg.appendChild(use);
    return svg;
  } else if (icon.category === ArdoqIconCategory.FontAwesome) {
    const text = createSvgElement('text', {
      'font-size': `${height}`,
      'font-family': 'FontAwesome',
      'dominant-baseline': 'central',
      'text-anchor': 'middle',
      x: `${left + height / 2}`,
      y: `${top + height / 2}`,
    });
    if (clipPath) {
      text.setAttribute('clipPath', clipPath);
    }
    text.textContent = fontAwesomeIcons[icon.id];
    return text;
  }
  return null;
};

export default svgComponentRepresentation;
