import {
  type CanvasComponent,
  GraphModelManager,
  HierarchicNestingPolicy,
  WebGL2GraphModelManager,
} from '@ardoq/yfiles';
import { ProteanLayoutType, ProteanRenderingMode } from '../types';
import type { RepresentationData } from '@ardoq/data-model';
import type { APIReferenceType } from '@ardoq/api-types';
import updateStyles from './updateStyles';
import { ProteanGraphState } from './types';

export type ChangeRenderingModeArgs = {
  renderingMode: ProteanRenderingMode;
  nodeRepresentationData: Map<string, RepresentationData>;
  referenceTypes: Map<string, APIReferenceType>;
  layoutType: ProteanLayoutType;
  state: ProteanGraphState;
};

const newGraphModelManager = (graphComponent: CanvasComponent) => {
  const result = new GraphModelManager(graphComponent);
  result.hierarchicNestingPolicy = HierarchicNestingPolicy.GROUP_NODES;
  return result;
};

const changeRenderingMode = async (args: ChangeRenderingModeArgs) => {
  const {
    state: { graphComponent },
    renderingMode,
  } = args;
  updateStyles(args);

  if (!graphComponent.current) {
    return;
  }
  graphComponent.current.graphModelManager =
    renderingMode === ProteanRenderingMode.WebGL2
      ? new WebGL2GraphModelManager()
      : newGraphModelManager(graphComponent.current);
};

export default changeRenderingMode;
