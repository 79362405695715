import type { ArdoqId } from '@ardoq/api-types';
import type { Reference } from 'aqTypes';
import type { ContextShape } from '@ardoq/data-model';
import isCompInParentScope from './isCompInParentScope';
import References from 'collections/references';

const getReferencesByComponentId = (componentId: ArdoqId | null) => {
  const included: Reference[] = [];

  References.collection.each(ref => {
    if (ref && ref.isIncludedInContextByFilter()) {
      if (componentId) {
        const targetId = ref.getTargetId();
        const sourceId = ref.getSourceId();
        if (
          targetId === componentId ||
          sourceId === componentId ||
          isCompInParentScope(sourceId, componentId) ||
          isCompInParentScope(targetId, componentId)
        ) {
          included.push(ref);
        }
      } else {
        included.push(ref);
      }
    }
  });
  return included;
};

const getReferencesForSelectedContext = (context: ContextShape) => {
  if (context.componentId) {
    return getReferencesByComponentId(context.componentId);
  }

  if (context.workspaceId) {
    return References.collection.filter(
      ref =>
        ref.get('rootWorkspace') === context.workspaceId ||
        ref.get('targetWorkspace') === context.workspaceId
    );
  }

  return [];
};

export default getReferencesForSelectedContext;
