import { ElementType, createRef, Component } from 'react';
import {
  InfiniteScrollProps,
  InfiniteScrollState,
  scrollDirection,
} from 'atomicComponents/InfiniteScroll/types';
import {
  getRenderBounds,
  getRenderLimit,
} from 'atomicComponents/InfiniteScroll/utils';
import styled from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import { topVisibleItemChanged } from 'atomicComponents/InfiniteScroll/actions';
import { debounce } from 'lodash';

const OFFSET_BOTTOM = 10;
const OFFSET_TOP = 10;

const Layout = styled.div`
  overflow: auto;
  height: inherit;
  position: relative;
  scroll-behavior: smooth;
`;

const getRenderedItems = (
  itemsContainer: HTMLDivElement,
  getRowElement: InfiniteScrollProps['getRowElement']
) =>
  Array.from(itemsContainer.querySelectorAll(':not(.blankRow)') || [])
    .map(getRowElement)
    .filter((rowElement): rowElement is HTMLElement =>
      Boolean(rowElement && !rowElement.classList.contains('blankRow'))
    );

const getItemTopPosition = (item: HTMLElement) =>
  item.getBoundingClientRect().top;
const getItemBottomPosition = (item: HTMLElement) =>
  item.getBoundingClientRect().bottom;
const getTotalHeightOfItems = (
  renderedItems: HTMLElement[],
  start: number,
  end: number
): number => {
  const result = renderedItems
    .slice(start, end)
    .reduce((totalHeight, item) => totalHeight + item.offsetHeight, 0);
  return result;
};
const shouldReset = <
  TContainer extends ElementType = 'div',
  TRow extends ElementType = 'div',
>(
  props: Readonly<InfiniteScrollProps<TContainer, TRow>>,
  prevProps: Readonly<InfiniteScrollProps<TContainer, TRow>>
) =>
  props.items.length !== prevProps.items.length ||
  props.workspaceId !== prevProps.workspaceId ||
  props.contextComponentId !== prevProps.contextComponentId;

export class InfiniteScroll<
  TContainer extends ElementType = 'div',
  TRow extends ElementType = 'div',
> extends Component<
  InfiniteScrollProps<TContainer, TRow>,
  InfiniteScrollState
> {
  state = {
    scrollIndex: 0,
    scrolledHeightTop: 0,
    scrolledHeightBottom: 0,
    isAppendingToTop: false,
    isAppendingToBottom: false,
  };
  bottomBuffer = createRef<HTMLDivElement>();
  topBuffer = createRef<HTMLDivElement>();
  firstItemPosition: number | null = null;
  itemsContainer = createRef<HTMLDivElement>();
  itemsToRender: string[] = [];
  scrollToItem: string | null = null;
  scrollContainer = createRef<HTMLDivElement>();
  scrollDirection = scrollDirection.DOWN;
  topVisibleItemId = '';
  scrollingConsistency = 0;
  isScrollingToSelectedItem = false;

  setScrollingEndDebounced = debounce(() => {
    this.setTopVisibleItemId();
    this.isScrollingToSelectedItem = false;
  }, 150);

  getFirstItem = (): HTMLElement | null =>
    this.getItemWithID(this.itemsToRender[0]);
  getLastItem = (): HTMLElement | null =>
    this.getItemWithID(this.itemsToRender[this.itemsToRender.length - 1]);

  loadAmount = () =>
    Math.round(getRenderLimit(this.props.numberOfComponentsToRender) * 0.4);

  itemIsAtTheBottomOfThePage = (item: HTMLElement | null): boolean => {
    const container = this.scrollContainer.current;
    return Boolean(
      item &&
        container &&
        getItemBottomPosition(item) - OFFSET_BOTTOM <
          getItemBottomPosition(container)
    );
  };
  itemIsAtTheTopOfThePage = (item: HTMLElement | null): boolean => {
    const container = this.scrollContainer.current;
    return Boolean(
      item &&
        container &&
        getItemTopPosition(item) > getItemTopPosition(container) - OFFSET_TOP
    );
  };

  getTopVisibleItemId = () =>
    this.itemsContainer.current
      ? (getRenderedItems(
          this.itemsContainer.current,
          this.props.getRowElement
        ).find(
          item =>
            item.id &&
            this.itemsToRender.includes(item.id) &&
            this.itemIsAtTheTopOfThePage(item as HTMLElement)
        )?.id ?? '')
      : '';

  getHeightOfFirstItems = () =>
    this.itemsContainer.current
      ? getTotalHeightOfItems(
          getRenderedItems(
            this.itemsContainer.current,
            this.props.getRowElement
          ),
          0,
          this.loadAmount()
        )
      : 0;

  getHeightOfLastItems = () => {
    if (!this.itemsContainer.current) {
      return 0;
    }
    const renderedItems = getRenderedItems(
      this.itemsContainer.current,
      this.props.getRowElement
    );
    return getTotalHeightOfItems(
      renderedItems,
      renderedItems.length - this.loadAmount(),
      renderedItems.length
    );
  };
  /** Gets the height of the sticky header. */
  getHeightOfFirstChild = () => {
    if (!this.itemsContainer.current) {
      return 0;
    }
    if (this.props.children) {
      const firstChild = this.itemsContainer.current.firstElementChild;
      return firstChild instanceof HTMLDivElement ? firstChild.offsetHeight : 0;
    }
    return 0;
  };

  appendToBottom = () => {
    // To prevent appending to bottom and rebuild the list layout when scrolling to a selected item
    if (this.isScrollingToSelectedItem) {
      return;
    }

    const { scrollIndex } = this.state;
    const renderLimit = getRenderLimit(this.props.numberOfComponentsToRender);
    const newIndex =
      scrollIndex + this.loadAmount() < this.props.items.length - renderLimit
        ? scrollIndex + this.loadAmount()
        : this.props.items.length - renderLimit;
    if (newIndex === scrollIndex) return; // All items have been rendered

    const heightToAppend =
      this.state.scrolledHeightTop + this.getHeightOfFirstItems();

    this.setState({
      scrolledHeightTop: heightToAppend,
      scrollIndex: newIndex,
      isAppendingToBottom: true,
    });
  };

  appendToTop = () => {
    // to prevent appending to top and rebuild the list layout when scrolling to a selected item
    if (this.isScrollingToSelectedItem) {
      return;
    }

    const { scrollIndex } = this.state;
    const newIndex =
      scrollIndex - this.loadAmount() >= 0
        ? scrollIndex - this.loadAmount()
        : 0;

    if (newIndex === scrollIndex) return; // All items have been rendered

    const heightToAppend =
      this.state.scrolledHeightBottom + this.getHeightOfLastItems();

    this.setState({
      scrolledHeightBottom: heightToAppend,
      scrollIndex: newIndex,
      isAppendingToTop: true,
    });
  };

  setScrollPosition = () => {
    const firstItem = this.getFirstItem();
    if (firstItem === null) return;
    this.firstItemPosition = firstItem.getBoundingClientRect().top;
  };

  setTopVisibleItemId = () => {
    if (!this.props.shouldDispatchTopVisibleItem) {
      return;
    }

    const currentTopVisibleItemId = this.getTopVisibleItemId();
    if (
      currentTopVisibleItemId &&
      currentTopVisibleItemId !== this.topVisibleItemId
    ) {
      this.topVisibleItemId = currentTopVisibleItemId;
      dispatchAction(
        topVisibleItemChanged({ itemId: currentTopVisibleItemId })
      );
    }
  };

  /**
   * Appending to top and scrolling to index sets the opposite scroll direction and appends to bottom.
   * To avoid that, we check for consistency of action before changing the scroll direction.
   */
  setScrollingConsistency = (direction: scrollDirection) => {
    if (this.scrollDirection === direction && this.scrollingConsistency < 1) {
      this.scrollingConsistency++;
    } else if (
      this.scrollDirection !== direction &&
      this.scrollingConsistency > -1
    ) {
      this.scrollingConsistency--;
    }
  };

  setScrollDirectionIfConsistent = (direction: scrollDirection) => {
    this.setScrollingConsistency(direction);
    if (this.scrollingConsistency !== 0) {
      this.scrollDirection = direction;
    }
  };

  /**
   * Appends height of the newwly rendered items to the blank row which is opposite
   * to the current scrolling direction and moves the scrollIndex
   */
  append() {
    if (
      this.itemIsAtTheBottomOfThePage(this.getLastItem()) &&
      this.scrollDirection === scrollDirection.DOWN
    ) {
      this.appendToBottom();
    } else if (
      this.state.scrollIndex > 0 &&
      this.itemIsAtTheTopOfThePage(this.getFirstItem()) &&
      this.scrollDirection === scrollDirection.UP
    ) {
      this.appendToTop();
    }
  }

  setScrollDirection() {
    const firstItem = this.getFirstItem();
    const firstItemTopPosition = firstItem && getItemTopPosition(firstItem);
    if (this.firstItemPosition && firstItemTopPosition !== null) {
      if (firstItemTopPosition < this.firstItemPosition) {
        this.setScrollDirectionIfConsistent(scrollDirection.DOWN);
      } else if (firstItemTopPosition > this.firstItemPosition) {
        this.setScrollDirectionIfConsistent(scrollDirection.UP);
      }
    }
  }

  infiniteScrollChecker = () => {
    this.setScrollDirection();

    this.setScrollPosition();

    this.append();

    if (this.isScrollingToSelectedItem) {
      this.setScrollingEndDebounced();
    } else {
      this.setTopVisibleItemId();
    }
  };

  scrollToNewItem = (scrollToElement: HTMLElement) => {
    const scrollContainer = this.scrollContainer.current;
    if (scrollContainer && scrollToElement) {
      this.scrollToItem = null;
      // wait for accurate element size and scroll position
      requestAnimationFrame(() => {
        const scrollTarget = this.props.getScrollTarget
          ? this.props.getScrollTarget(scrollToElement)
          : scrollToElement;

        const stickyHeaderHeight =
          this.props.getStickyHeaderHeight?.(this.itemsContainer.current) ??
          this.getHeightOfFirstChild();

        scrollContainer.scrollTop = scrollTarget.offsetTop - stickyHeaderHeight; // Height of first child is the height of the sticky header
      });
    }
  };

  resetScroll = () => {
    this.scrollDirection = scrollDirection.DOWN;
    this.scrollContainer.current!.scrollTop = 0;
    this.firstItemPosition = 0;
    this.setState({
      scrollIndex: 0,
      scrolledHeightBottom: 0,
      scrolledHeightTop: 0,
    });
  };

  getItemWithID = (id: string) => {
    return this.itemsContainer.current!.querySelector(
      `#${CSS.escape(id)}`
    ) as HTMLElement;
  };

  setScrollIndexToItem = (itemId: string) => {
    // used in presentations when you select a component in the navigator
    const { items } = this.props;
    const { scrollIndex } = this.state;
    const index = items.findIndex(item => itemId === item);
    const isScrollingDown = index >= scrollIndex;
    if (index !== undefined || itemId === '') {
      this.scrollToItem = itemId;

      this.setState({
        scrollIndex: Math.max(index - this.loadAmount() / 2, 0),
      });

      const isItemRendered = this.itemsToRender.includes(itemId);

      if (this.scrollContainer.current && !isItemRendered) {
        // set the scroll position to edge of the container to ensure scroll animation
        this.scrollContainer.current.scrollTo({
          behavior: 'instant',
          top: isScrollingDown ? 0 : this.scrollContainer.current.scrollHeight,
        });
      }
    }
  };

  isTopBlankRowVisible() {
    const topBuffer = this.topBuffer.current;
    const container = this.scrollContainer.current;

    if (topBuffer && container) {
      if (
        this.scrollDirection === scrollDirection.UP &&
        this.state.scrolledHeightTop > 0 &&
        topBuffer.getBoundingClientRect().bottom - OFFSET_TOP >
          container.getBoundingClientRect().top
      ) {
        return true;
      }
    }
  }
  isBottomBlankRowVisible() {
    const bottomBuffer = this.bottomBuffer.current;
    const container = this.scrollContainer.current;

    if (bottomBuffer && container) {
      if (
        this.scrollDirection === scrollDirection.DOWN &&
        this.state.scrolledHeightBottom > 0 &&
        bottomBuffer.getBoundingClientRect().top + OFFSET_BOTTOM <
          container.getBoundingClientRect().bottom
      ) {
        return true;
      }
    }
  }

  /**
   * If user scrolls fast and sets a "disruptive" scrollTop, they would end up looking at
   * the respective blank row. To fix that we break/discard our blank row height logic and
   * set its height to zero. When scrolling up we also set the bottom to zero, so users
   * don't see the bottom buffer. It looks crazy in the scrollbar but "normalizes"
   * scrolling behavior and hides the blank rows.
   */
  handleBlankRowsIfVisible() {
    if (this.isBottomBlankRowVisible()) {
      this.setState({ scrolledHeightBottom: 0 });
    } else if (this.isTopBlankRowVisible()) {
      if (this.state.scrolledHeightTop > 0) {
        this.setState({
          scrolledHeightTop: 0,
          scrolledHeightBottom: 0,
        });
      }
    }
  }

  /**
   * Removes height of newly rendered items, if scrolling organically. If scrolling happens too fast,
   * the buffer height is set to zero and this function is of no use.
   */
  removeHeightFromBottom = () => {
    if (this.state.scrolledHeightBottom > 0) {
      const newBottomBlankRowHeight =
        this.state.scrolledHeightBottom - this.getHeightOfLastItems();
      this.setState({
        scrolledHeightBottom:
          newBottomBlankRowHeight > 0 ? newBottomBlankRowHeight : 0,
        isAppendingToBottom: false,
      });
    }
  };
  /**
   * Removes height of newly rendered items, if scrolling organically. If scrolling happens too fast,
   * the buffer height is set to zero and this function is of no use.
   */
  removeHeightFromTop = () => {
    if (
      this.state.scrolledHeightTop > 0 ||
      this.isLowestScrollTopReachedPrematurely()
    ) {
      const newHeight =
        this.state.scrolledHeightTop - this.getHeightOfFirstItems();

      const newTopBlankRowHeight = newHeight > 0 ? newHeight : 0;

      this.setState({
        scrolledHeightTop: newTopBlankRowHeight,
        isAppendingToTop: false,
      });
    }
  };

  /**
   * We set a scrollToItem when scrolling up to prevent immediate scrolling to the top,
   * or it can be set from props
   */
  scrollToItemIfSet(
    prevProps: Readonly<InfiniteScrollProps<TContainer, TRow>>
  ) {
    if (
      this.props.scrollToItem &&
      this.props.scrollToItem !== prevProps.scrollToItem &&
      !this.scrollToItem
    ) {
      this.isScrollingToSelectedItem = true;
      this.setScrollIndexToItem(this.props.scrollToItem);
    }
  }

  /**
   * When we reach the lowest possible scrollTop prematurely and there are still items to render,
   * there will be no further scroll events. So we keep calling this method and append to top once
   * in 100 ms so it looks to user like they are still scrolling up
   */
  appendToTopWithOrganicFrequency = () => {
    let isAppending = false;
    if (!isAppending) {
      isAppending = true;
      setTimeout(() => {
        isAppending = false;
        this.appendToTop();
      }, 100);
    }
  };

  /**
   * This means we have scrolled all the way to the top, not all items are rendered,
   * and we cannot scroll up further.
   */
  isLowestScrollTopReachedPrematurely = () =>
    this.scrollDirection === scrollDirection.UP &&
    this.scrollContainer.current?.scrollTop === 0 &&
    (this.state.scrolledHeightTop === 0 || this.isTopBlankRowVisible()) &&
    this.state.scrollIndex > 0;

  componentDidUpdate(
    prevProps: Readonly<InfiniteScrollProps<TContainer, TRow>>
  ): void {
    if (shouldReset(this.props, prevProps)) {
      this.resetScroll();
    }

    if (
      !this.state.isAppendingToTop &&
      this.isLowestScrollTopReachedPrematurely()
    ) {
      // !this.state.isAppendingToTop means it is a final update after appending
      this.appendToTopWithOrganicFrequency();
    }

    /**
     * Until we discard the buffer height, we should remove it gradually:
     * 1. when appending
     * 2. after itemsToRender are updated
     * 3. only once per append
     */
    if (this.state.isAppendingToBottom) {
      this.removeHeightFromBottom();
    } else if (this.state.isAppendingToTop) {
      this.removeHeightFromTop();
    }

    if (!this.isScrollingToSelectedItem) {
      this.scrollToItemIfSet(prevProps);
    } else if (this.scrollToItem) {
      const targetItem = this.getItemWithID(this.scrollToItem);
      if (targetItem) {
        this.scrollToNewItem(targetItem);
      }
    }

    this.handleBlankRowsIfVisible();
  }

  render() {
    const {
      bypassRenderLimit,
      items,
      itemRenderFunction,
      numberOfComponentsToRender,
      Container = 'div',
      BlankRow = 'div',
    } = this.props;
    const { scrolledHeightTop, scrolledHeightBottom, scrollIndex } = this.state;
    const [lowerBoundary, upperBoundary] = getRenderBounds(
      scrollIndex,
      items.length,
      bypassRenderLimit,
      numberOfComponentsToRender
    );

    this.itemsToRender = items.slice(lowerBoundary, upperBoundary);
    return (
      <Layout onScroll={this.infiniteScrollChecker} ref={this.scrollContainer}>
        <Container ref={this.itemsContainer}>
          {this.props.children}
          {lowerBoundary > 0 && !bypassRenderLimit && (
            <BlankRow
              ref={this.topBuffer}
              className="blankRow"
              style={{ height: scrolledHeightTop }}
            />
          )}
          {this.itemsToRender.map(itemRenderFunction)}
          {upperBoundary < items.length && !bypassRenderLimit && (
            <BlankRow
              ref={this.bottomBuffer}
              className="blankRow"
              style={{
                height: scrolledHeightBottom,
              }}
            />
          )}
        </Container>
      </Layout>
    );
  }
}
