import { actionCreator } from '@ardoq/rxbeach';
import { LegendComponentType, LegendReferenceType } from '@ardoq/graph';

type GraphViewLegendComponentTypePayload = {
  componentType: LegendComponentType | null;
};
type GraphViewLegendComponentTypesPayload = {
  componentTypes: LegendComponentType[];
};
export const graphViewLegendHighlightComponentType =
  actionCreator<GraphViewLegendComponentTypePayload>(
    '[GraphViewLegend] HIGHLIGHT_COMPONENT_TYPES'
  );
export const graphViewLegendSetSelectedComponentTypes =
  actionCreator<GraphViewLegendComponentTypesPayload>(
    '[GraphViewLegend] SET_SELECTED_COMPONENT_TYPES'
  );

type GraphViewLegendReferenceTypePayload = {
  referenceType: LegendReferenceType | null;
};
type GraphViewLegendReferenceTypesPayload = {
  referenceTypes: LegendReferenceType[];
};
export const graphViewLegendHighlightReferenceType =
  actionCreator<GraphViewLegendReferenceTypePayload>(
    '[GraphViewLegend] HIGHLIGHT_REFERENCE_TYPES'
  );
export const graphViewLegendSetSelectedReferenceTypes =
  actionCreator<GraphViewLegendReferenceTypesPayload>(
    '[GraphViewLegend] SET_SELECTED_REFERENCE_TYPES'
  );

type GraphViewLegendConditionalFormattingPayload = { id: string | null };
type GraphViewLegendConditionalFormattingsPayload = { ids: string[] };
export const graphViewLegendHighlightConditionalFormatting =
  actionCreator<GraphViewLegendConditionalFormattingPayload>(
    '[GraphViewLegend] HIGHLIGHT_CONDITIONAL_FORMATTING'
  );
export const graphViewLegendSetSelectedConditionalFormatting =
  actionCreator<GraphViewLegendConditionalFormattingsPayload>(
    '[GRAPH_VIEW_LEGEND] SET_SELECTED_CONDITIONAL_FORMATTING'
  );
