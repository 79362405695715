import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  focusedComponentChanged,
  hoveredComponentChanged,
} from 'tabview/actions';

const VIEW_ID = ViewIds.TAGSCAPE;

const setHoveredItemState = (itemId: ArdoqId | null) => {
  dispatchAction(hoveredComponentChanged(itemId), VIEW_ID);
};

const setFocusedItemState = (
  itemId: ArdoqId | null,
  isCurrentlyFocused?: boolean
) => {
  dispatchAction(
    focusedComponentChanged(!isCurrentlyFocused ? itemId : null),
    VIEW_ID
  );
};

export const tagscapeCommands = {
  setHoveredItemState,
  setFocusedItemState,
};
