import { normal14 } from '@ardoq/typography';
import styled from 'styled-components';

const SettingsBarAndViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  user-select: none;
  ${normal14}
`;

export default SettingsBarAndViewContainer;
