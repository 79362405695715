import { useEffect } from 'react';
import HumanPlaceholder from 'atomicComponents/HumanPlaceholder/HumanPlaceholder';
import { DataLimitProps } from './types';
import { getWarningSubtitle, getWarningTitle } from './utils';
import { trackShowingDataLimitWarning } from './trackings';
import { ViewIds } from '@ardoq/api-types';
import { DoqWarning } from './DoqWarning';

const DataLimit = (props: DataLimitProps) => {
  useEffect(() => {
    trackShowingDataLimitWarning(props);
  }, [props]);

  if (
    props.isAboveLimit &&
    (props.viewId === ViewIds.BLOCK_DIAGRAM ||
      props.viewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM ||
      props.viewId === ViewIds.SWIMLANE)
  ) {
    return (
      <DoqWarning
        viewId={props.viewId}
        canBypass={props.canBypass}
        onContinueClick={props.onBypassClick}
      />
    );
  }

  return (
    <HumanPlaceholder
      title={getWarningTitle(props)}
      subtitle={getWarningSubtitle(props)}
      actions={
        props.canBypass && props.onBypassClick
          ? [
              {
                label: 'PROCEED ANYWAY',
                isPrimary: true,
                onClick: props.onBypassClick,
              },
            ]
          : []
      }
    />
  );
};

export default DataLimit;
