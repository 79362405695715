import { QuadrantLabelText, TimeLine } from './atoms';
import { BubbleChartQuadrantSettings } from './types';
import type { NumericRange, HasViewInstanceId } from '@ardoq/graph';

type ScalingFunction = (value: number) => number;
interface Margin {
  left: number;
  right: number;
  top: number;
  bottom: number;
}
interface QuadrantOverlayArgs extends HasViewInstanceId {
  width: number;
  height: number;
  scaleX: ScalingFunction;
  scaleY: ScalingFunction;
  margin: Margin;
  xRange: NumericRange;
  yRange: NumericRange;
  quadrantSettings: BubbleChartQuadrantSettings;
  clipPathUrl: string;
}
const QuadrantOverlay = ({
  width,
  height,
  scaleX,
  scaleY,
  margin,
  viewInstanceId,
  xRange,
  yRange,
  quadrantSettings,
  clipPathUrl,
}: QuadrantOverlayArgs) => {
  const timeCenterX = scaleX(xRange[0] + (xRange[1] - xRange[0]) / 2);
  const timeCenterY = scaleY(yRange[0] + (yRange[1] - yRange[0]) / 2);
  const leftQuadrantsLabelX = Math.min(width, timeCenterX) / 2;
  const rightQuadrantsLabelX = width - (width - Math.max(0, timeCenterX)) / 2;
  const topQuadrantsLabelY = Math.min(height, timeCenterY) / 2;
  const bottomQuadrantsLabelY =
    height - (height - Math.max(0, timeCenterY)) / 2;

  const topRightQuadrantClipPath = `topRightQuadrantClipPath-${viewInstanceId}`;
  const bottomRightQuadrantClipPath = `bottomRightQuadrantClipPath-${viewInstanceId}`;
  const bottomLeftQuadrantClipPath = `bottomLeftQuadrantClipPath-${viewInstanceId}`;
  const topLeftQuadrantClipPath = `topLeftQuadrantClipPath-${viewInstanceId}`;
  return (
    <>
      <clipPath id={topRightQuadrantClipPath}>
        <rect
          x={timeCenterX}
          height={Math.max(0, timeCenterY)}
          width={Math.max(0, width - timeCenterX)}
        />
      </clipPath>
      <clipPath id={bottomRightQuadrantClipPath}>
        <rect
          x={timeCenterX}
          y={timeCenterY}
          height={Math.max(0, height - timeCenterY)}
          width={Math.max(0, width - timeCenterX)}
        />
      </clipPath>
      <clipPath id={bottomLeftQuadrantClipPath}>
        <rect
          y={timeCenterY}
          height={Math.max(0, height - timeCenterY)}
          width={Math.max(0, timeCenterX)}
        />
      </clipPath>
      <clipPath id={topLeftQuadrantClipPath}>
        <rect
          height={Math.max(0, timeCenterY)}
          width={Math.max(0, timeCenterX)}
        />
      </clipPath>
      <g
        transform={`translate(${margin.left} ${margin.top})`}
        clipPath={`url(#${clipPathUrl})`}
      >
        <TimeLine x1="0" x2={width} y1={timeCenterY} y2={timeCenterY} />
        <TimeLine x1={timeCenterX} x2={timeCenterX} y1="0" y2={height} />

        <QuadrantLabelText
          x={leftQuadrantsLabelX}
          y={topQuadrantsLabelY}
          style={{ clipPath: `url(#${topLeftQuadrantClipPath})` }}
        >
          {quadrantSettings.labels[0]}
        </QuadrantLabelText>
        <QuadrantLabelText
          x={rightQuadrantsLabelX}
          y={topQuadrantsLabelY}
          style={{ clipPath: `url(#${topRightQuadrantClipPath})` }}
        >
          {quadrantSettings.labels[1]}
        </QuadrantLabelText>
        <QuadrantLabelText
          x={rightQuadrantsLabelX}
          y={bottomQuadrantsLabelY}
          style={{ clipPath: `url(#${bottomRightQuadrantClipPath})` }}
        >
          {quadrantSettings.labels[3]}
        </QuadrantLabelText>
        <QuadrantLabelText
          x={leftQuadrantsLabelX}
          y={bottomQuadrantsLabelY}
          style={{ clipPath: `url(#${bottomLeftQuadrantClipPath})` }}
        >
          {quadrantSettings.labels[2]}
        </QuadrantLabelText>
      </g>
    </>
  );
};
export default QuadrantOverlay;
