import { actionCreator } from '@ardoq/rxbeach';
import { ComponentMatrixDimension } from './types';

interface ComponentMatrixCollapseGroupPayload {
  dimension: ComponentMatrixDimension;
  address: (string | null)[];
}
export const componentMatrixToggleCollapseGroup =
  actionCreator<ComponentMatrixCollapseGroupPayload>(
    '[componentMatrix] COMPONENT_MATRIX_COLLAPSE_GROUP'
  );

export const bypassLimitAction = actionCreator(
  '[componentMatrix] BYPASS_LIMIT'
);
