import { ViewIds } from '@ardoq/api-types';
import FileSaver from 'file-saverjs';
import { ViewLegendContainerElement } from '@ardoq/graph';
import {
  composeExportMetadata,
  canvasToBlob,
  EXPORT_FORMAT,
  getCanvasWithBackgroundColor,
  getExportProfilingTransaction,
  viewExportCommands,
} from '@ardoq/export';
import { logError } from '@ardoq/logging';
import { colors } from '@ardoq/design-tokens';
import { getStyleSheets } from 'tabview/getSharedExportFunctions';
import * as profiling from '@ardoq/profiling';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const pngExporter =
  (
    getExportContainer: () => HTMLElement | null,
    getExportCanvas: () => HTMLCanvasElement | OffscreenCanvas | null,
    viewId: ViewIds
  ) =>
  async () => {
    const transaction = getExportProfilingTransaction();
    const mutableExportMetaData = composeExportMetadata(
      {
        name: viewId,
        isViewpointMode: loadedGraph$.state.isViewpointMode,
      },
      EXPORT_FORMAT.PNG
    );

    viewExportCommands.notifyExportStarted(viewId);
    const container = getExportContainer();
    if (!container) {
      return;
    }

    const canvas = getExportCanvas();
    if (!canvas) {
      logError(Error('export canvas not found'));
      return;
    }

    const viewLegend = container.querySelector(
      ViewLegendContainerElement.toString()
    ) as HTMLElement | null;

    const exportCanvas = await getCanvasWithBackgroundColor(
      transaction,
      mutableExportMetaData,
      canvas,
      colors.white,
      getStyleSheets,
      viewLegend
    );

    const blob = await canvasToBlob(exportCanvas);
    await FileSaver(blob, `${viewId}.png`);

    profiling.endTransaction(transaction, {
      metadata: mutableExportMetaData,
    });
    viewExportCommands.notifyExportFinished(viewId);
  };

export default pngExporter;
