import {
  PropsWithChildren,
  ComponentType,
  forwardRef,
  createRef,
  useRef,
  memo,
} from 'react';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { connectInstance } from '@ardoq/rxbeach';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { SettingsBar, settingsBarConsts } from '@ardoq/settings-bar';
import { getLeftMenu, getRightMenu } from './menus';
import { HeaderRow, Table } from './table';
import { emptyState } from './utils';
import Row from './Row';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import styled from 'styled-components';
import EmptyWorkspace from 'components/EmptyWorkspace/EmptyWorkspace';
import { ViewIds } from '@ardoq/api-types';
import {
  type HeaderModel,
  HeaderType,
  type TableViewModel,
  TableViewProps,
  type TableViewRow,
  type TableViewSettings,
  type TextHeaderModel,
} from './types';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { InfiniteScroll } from 'atomicComponents/InfiniteScroll/InfiniteScroll';
import { getColumnBodyWidth } from './measureCells';
import { returnZero } from '@ardoq/common-helpers';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import EmptyState from './EmptyState';
import { simpleNotifier } from '@ardoq/dropdown-menu';
import ViewAndLegendContainer from 'tabview/ViewAndLegendContainer';
import {
  getActiveConditionalFormattingForLegend,
  ViewLegend,
} from '@ardoq/view-legend';
import { ViewLegendContainer } from '@ardoq/graph';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import {
  HEADER_CELL_ICON_MARGIN_LEFT,
  HEADER_CELL_TEXT_MAX_WIDTH,
} from './consts';
import { TextOverflow } from '@ardoq/popovers';
import { MeasureStyledText } from '@ardoq/dom-utils';
import { isEqual } from 'lodash';

const DEFAULT_COLUMNS_SHOWING_COMPONENTS = new Set([
  'name',
  'source',
  'target',
]);

const HeaderWithIcon = styled.span`
  display: inline-flex;
  align-items: flex-end;
  i {
    margin-left: ${HEADER_CELL_ICON_MARGIN_LEFT}px;
    vertical-align: sub;
  }
`;

const getReferenceIcon = (name: string, type: HeaderType) => (
  <HeaderWithIcon>
    <TextOverflow lineClamp={3}>{name}</TextOverflow>
    <Icon
      size={IconSize.SMALL}
      style={{ fontSize: IconSize.SMALL }}
      iconName={
        type === HeaderType.SOURCE
          ? IconName.ARROW_BACK
          : IconName.ARROW_FORWARD
      }
    />
  </HeaderWithIcon>
);

type TableElementPropsType = PropsWithChildren<{
  viewModel: TableViewModel;
  viewId: ViewIds;
  sortable: boolean;
  columnWidths: number[];
}>;

const SORT_INDICATOR_MARGIN = 4;
const SORT_INDICATOR_WIDTH = 12 + SORT_INDICATOR_MARGIN;
const HEADER_CELL_PADDING = 2 * 8;
const HEADER_CELL_BORDER = 1 * 2;
const HEADER_CELL_ICON = IconSize.SMALL;
const textMeasurer = new MeasureStyledText();
const measureText = (text: string) =>
  textMeasurer.getTextWidth({ text, fontSize: '12px', fontWeight: 'normal' });

const isTextHeaderModel = (header: HeaderModel): header is TextHeaderModel =>
  typeof header.label === 'string';
const getHeaderMeasureInfo = (header: HeaderModel) =>
  isTextHeaderModel(header)
    ? { label: header.label, sortIndicatorWidth: 0 }
    : { label: header.text, sortIndicatorWidth: SORT_INDICATOR_WIDTH };
const getHeaderWidth = (header: HeaderModel) => {
  const { label, sortIndicatorWidth } = getHeaderMeasureInfo(header);

  return (
    Math.min(measureText(label.toUpperCase()), HEADER_CELL_TEXT_MAX_WIDTH) +
    HEADER_CELL_PADDING +
    HEADER_CELL_BORDER +
    HEADER_CELL_ICON +
    HEADER_CELL_ICON_MARGIN_LEFT +
    sortIndicatorWidth
  );
};
const TableElement = forwardRef<HTMLTableElement, TableElementPropsType>(
  ({ viewModel, viewId, sortable, columnWidths, children }, ref) => {
    const flatHeaders = [
      ...viewModel.defaultHeaders,
      ...viewModel.referenceTypeHeaders,
      ...viewModel.fieldHeaders,
    ];
    return (
      <Table ref={ref} columnWidths={columnWidths}>
        <HeaderRow viewId={viewId} headers={flatHeaders} sortable={sortable} />
        {children}
      </Table>
    );
  }
);

type MainViewProperties = {
  viewModel: TableViewModel;
  viewId: ViewIds;
  sortable: boolean;
  columnWidths: number[];
};
const MainView = ({
  viewModel,
  viewId,
  sortable,
  columnWidths,
}: MainViewProperties) => {
  const { rows, contextComponentId } = viewModel;
  if (rows === null) {
    return null;
  }

  if (rows.length) {
    const tableElement = forwardRef<
      HTMLTableElement,
      React.PropsWithChildren<unknown>
    >(({ children }, ref) => (
      <TableElement
        ref={ref}
        viewModel={viewModel}
        viewId={viewId}
        sortable={sortable}
        columnWidths={columnWidths}
      >
        {children}
      </TableElement>
    ));
    const modelsByCid = new Map<string, TableViewRow>(
      rows.map(row => [row.cid, row])
    );
    const flatHeaders = [
      ...viewModel.defaultHeaders,
      ...viewModel.referenceTypeHeaders,
      ...viewModel.fieldHeaders,
    ];
    const renderRow = (cid: string, rowIndex: number) => {
      const model = modelsByCid.get(cid)!;
      return (
        <Row
          key={model.cid}
          model={model}
          headers={flatHeaders}
          isHighlighted={model.id === contextComponentId}
          rowIndex={rowIndex}
        />
      );
    };
    return (
      <div
        id={viewId}
        className="tableview"
        style={{ overflow: 'hidden', height: '100%' }}
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
      >
        <InfiniteScroll
          Container={tableElement}
          BlankRow="tr"
          items={Array.from(modelsByCid.keys())}
          itemRenderFunction={renderRow}
          bypassRenderLimit={false}
          getRowElement={el => (el instanceof HTMLTableRowElement ? el : null)}
          getStickyHeaderHeight={el =>
            el instanceof HTMLTableElement ? el.tHead?.offsetHeight || 0 : 0
          }
          numberOfComponentsToRender={50}
        ></InfiniteScroll>
      </div>
    );
  }

  return <EmptyWorkspace />;
};
const addReferenceIcon = (header: HeaderModel) =>
  typeof header.label === 'string' &&
  (header.type === HeaderType.SOURCE || header.type === HeaderType.TARGET)
    ? {
        ...header,
        text: header.label,
        label: getReferenceIcon(header.label, header.type),
      }
    : header;

interface TableviewWrapperProperties extends TableViewProps {
  viewId: ViewIds.TABLEVIEW | ViewIds.REFERENCETABLE;
  name: string;
  sortable?: boolean;
  knowledgeBaseLink?: string;
}

export const TableviewWrapper = ({
  viewId,
  sortable = false,
  viewState,
  viewModel,
  knowledgeBaseLink = '',
}: TableviewWrapperProperties) => {
  const contentPaneRef = createRef<HTMLDivElement>();

  const [
    categorizedHeadersWithReferenceIcons,
    categorizedAllHeadersWithReferenceIcons,
  ] = [
    [
      viewModel.defaultHeaders,
      viewModel.referenceTypeHeaders,
      viewModel.fieldHeaders,
    ],
    [
      viewModel.allDefaultHeaders,
      viewModel.allReferenceTypeHeaders,
      viewModel.allFieldHeaders,
    ],
  ].map(headersSet => headersSet.map(headers => headers.map(addReferenceIcon)));

  const [defaultHeaders, referenceTypeHeaders, fieldHeaders] =
    categorizedHeadersWithReferenceIcons;
  const [allDefaultHeaders, allReferenceTypeHeaders, allFieldHeaders] =
    categorizedAllHeadersWithReferenceIcons;
  const flattenedCategorizedHeaders =
    categorizedHeadersWithReferenceIcons.flat();
  const columnHeaderWidths = flattenedCategorizedHeaders.map(getHeaderWidth);
  const { rows } = viewModel;
  const columnBodyWidths = rows
    ? flattenedCategorizedHeaders.map(header =>
        getColumnBodyWidth(header, rows)
      )
    : flattenedCategorizedHeaders.map(returnZero);

  const columnWidths = columnHeaderWidths.map((headerWidth, columnIndex) =>
    Math.max(headerWidth, columnBodyWidths[columnIndex])
  );
  const flattenedCategorizedAllHeadersWithReferenceIcons =
    categorizedAllHeadersWithReferenceIcons.flat();
  const hasNoSelectedColumn =
    flattenedCategorizedAllHeadersWithReferenceIcons.length &&
    viewState.hideAllColumns;

  const isEmptyView = Boolean(
    viewModel.rows && (!viewModel.rows.length || hasNoSelectedColumn)
  );

  const openShowColumnsViewSettingTable = useRef(simpleNotifier());

  const activeConditionalFormatting = getActiveConditionalFormattingForLegend();
  const hasComponentColumn =
    viewModel.referenceTypeHeaders.length ||
    viewModel.defaultHeaders.find(({ key }) =>
      DEFAULT_COLUMNS_SHOWING_COMPONENTS.has(key)
    );
  const isLegendPossible = Boolean(
    (activeConditionalFormatting.components.length ||
      activeConditionalFormatting.references.length ||
      activeConditionalFormatting.tags.length) &&
      hasComponentColumn
  );

  const isLegendVisible =
    viewState[settingsBarConsts.IS_LEGEND_ACTIVE] &&
    isLegendPossible &&
    !isEmptyView;

  return (
    <div className="tab-pane tabtableview tableviewTab active">
      <div className="menuContainer">
        <SettingsBar
          viewId={viewId}
          leftMenu={getLeftMenu(
            viewId,
            viewState,
            viewModel.allDefaultHeaders,
            viewModel.allReferenceTypeHeaders,
            viewModel.allFieldHeaders,
            onViewSettingsUpdate,
            openShowColumnsViewSettingTable.current
          )}
          rightMenu={getRightMenu({
            viewState,
            knowledgeBaseLink,
            viewId,
            onViewSettingsUpdate,
            isEmptyView,
            isLegendPossible,
          })}
        />
      </div>
      {isEmptyView ? (
        <EmptyState
          hasComponentsAvailable={viewModel.hasComponentsAvailable}
          hasNoSelectedColumn={viewState.hideAllColumns}
          viewId={viewId}
          hasNoRows={!viewModel.rows?.length}
          openShowColumnsViewSettingTable={
            openShowColumnsViewSettingTable.current
          }
        />
      ) : (
        <ViewAndLegendContainer className="contentPane" ref={contentPaneRef}>
          <MainView
            viewModel={{
              ...viewModel,
              defaultHeaders,
              referenceTypeHeaders,
              fieldHeaders,
              allDefaultHeaders,
              allReferenceTypeHeaders,
              allFieldHeaders,
            }}
            viewId={viewId}
            sortable={sortable}
            columnWidths={columnWidths}
          />
          <HeightOffset>
            {heightOffset => (
              <ViewLegendContainer
                visible={isLegendVisible}
                heightOffset={heightOffset}
              >
                <ViewLegend
                  componentTypes={[]}
                  activeDiffMode={null}
                  activeConditionalFormatting={activeConditionalFormatting}
                />
              </ViewLegendContainer>
            )}
          </HeightOffset>
        </ViewAndLegendContainer>
      )}
    </div>
  );
};

/**
 * Adds the expandDescription field to rows
 *
 * The expandDescription field is an indicator of whether the row description
 * should be expanded.
 *
 * Expand/collapse is controlled by `tableModel.expandedDescription`. This
 * normally works as a whitelist for expanded descriptions, except for when
 * the global expand description toggle is enabled
 * (`viewState.expandDescription`) - in that case `tableModel.expandDescription`
 * works as a blacklist of collapsed descriptions.
 *
 * @returns A function that associates the `expandDescription` to rows passed
 *          to it
 */
const assocExpandDescription =
  (viewState: TableViewSettings, tableModel: TableViewModel) =>
  (row: TableViewRow) => {
    const whitelisted = tableModel.expandedDescriptions.has(row.cid);
    const whitelistIsBlacklist = viewState.expandDescription;

    return {
      ...row,
      expandDescription:
        row.hasDescription &&
        (whitelistIsBlacklist ? !whitelisted : whitelisted),
    };
  };

const streamsToProps = ([tableModel, viewSettings]: [
  TableViewModel,
  TableViewSettings,
]): TableViewModel => {
  const visibleColumns = new Set(viewSettings.visibleColumns);

  const [defaultHeaders, referenceTypeHeaders, fieldHeaders] = [
    tableModel.defaultHeaders,
    tableModel.referenceTypeHeaders,
    tableModel.fieldHeaders,
  ].map(headers =>
    headers.filter(header =>
      visibleColumns.size
        ? // if there are no visible cols specified and we should not hide all columns - show them all
          visibleColumns.has(header.key)
        : !viewSettings.hideAllColumns
    )
  );
  const rows =
    tableModel.rows === null
      ? null
      : tableModel.rows.map(assocExpandDescription(viewSettings, tableModel));

  return {
    ...tableModel,
    rows,
    defaultHeaders,
    referenceTypeHeaders,
    fieldHeaders,
    allDefaultHeaders: tableModel.defaultHeaders,
    allReferenceTypeHeaders: tableModel.referenceTypeHeaders,
    allFieldHeaders: tableModel.fieldHeaders,
  };
};

export const setup = (
  viewId: ViewIds,
  reactComponent: ComponentType<TableViewProps>,
  createViewModel$: (viewInstanceId: string) => Observable<TableViewModel>
) => {
  const viewSettings$ = getViewSettingsStream<TableViewSettings>(viewId);

  const PerformanceTrackedTableView = memo(
    (props: TableViewProps) =>
      WithPerformanceTracking(
        'table view render',
        1000,
        {
          WrappedComponent: reactComponent,
          wrappedProps: props,
          viewId,
          metadata: {
            numberOfHeaders:
              props.viewModel.defaultHeaders.length +
              props.viewModel.referenceTypeHeaders.length +
              props.viewModel.fieldHeaders.length,
            numberOfRows: props.viewModel.rows
              ? props.viewModel.rows.length
              : -1,
          },
        },
        props.viewModel.rows !== null
      ),
    isEqual
  );
  return connectInstance(
    PerformanceTrackedTableView,
    (viewInstanceId: string) =>
      combineLatest({
        viewModel: combineLatest([
          createViewModel$(viewInstanceId),
          viewSettings$,
        ]).pipe(map(streamsToProps), startWith({ ...emptyState, rows: null })),
        viewState: viewSettings$,
      })
  );
};
