import { addToPresentation } from 'viewSettings/exportHandlers';
import { ViewIds } from '@ardoq/api-types';
import { getExportsForSvgView } from '@ardoq/export';
import getRightMenu from '../../viewSettings/getRightMenuConfig';
import { trackExportingVisualization } from '../../tracking/events/visualizations';
import { getStyleSheets } from '../getSharedExportFunctions';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import type { OnViewSettingsUpdate } from '@ardoq/data-model';
import { SpiderChartViewSettings } from './types';

type GetRightMenuArgs = {
  viewSettings: SpiderChartViewSettings;
  getContainer: () => Element | null;
  viewId: ViewIds;
  isEmptyView: boolean;
  onViewSettingsUpdate: OnViewSettingsUpdate;
};

export const getRightMenuConfig = ({
  viewSettings,
  getContainer,
  viewId,
  isEmptyView,
  onViewSettingsUpdate,
}: GetRightMenuArgs) => {
  const exports = getExportsForSvgView({
    container: getContainer,
    exportedViewMetadata: {
      name: viewId,
    },
    trackingFunction: trackExportingVisualization,
    addToPresentation,
    getStyleSheets,
  });

  return [
    ...getRightMenu({
      viewId,
      viewstate: viewSettings,
      exports: {
        exportToPng: exports.exportToPng,
        addToPresentation: exports.addToPresentation,
        isDisabled: isEmptyView,
      },
      knowledgeBaseLink: KnowledgeBaseLink.SPIDER_CHART,
      isLegendDisabled: isEmptyView,
      onViewSettingsUpdate,
    }),
  ];
};
