import styled from 'styled-components';
import { header1 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';

export const Title = styled.h3`
  ${header1};
  color: ${colors.green50};
  margin-top: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ onClick }) =>
    onClick &&
    `&:hover {
      text-decoration: underline;
    }
    cursor: pointer;`}
`;
