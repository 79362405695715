import { dispatchAction } from '@ardoq/rxbeach';
import { ArdoqId } from '@ardoq/api-types';
import Context from 'context';
import { requestShowAppModule } from 'appContainer/actions';
import { AppModules } from 'appContainer/types';
import Components from 'collections/components';
import { TrackingLocation } from 'tracking/types';
import { componentApi } from '@ardoq/api';

// TODO slipset investigate
const getComponent = async (componentId: ArdoqId) => {
  const maybeComponent = Components.collection.get(componentId);
  if (maybeComponent) {
    return maybeComponent;
  }
  const body = await componentApi.fetch(componentId);
  Components.collection.add(body);
  return Components.collection.get(componentId);
};

const openComponentInWorkspace = async (
  componentId: ArdoqId,
  trackingLocation?: TrackingLocation
) => {
  const component = await getComponent(componentId);
  await Context.setComponent(component, trackingLocation);
  dispatchAction(
    requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
  );
};

export default openComponentInWorkspace;
