import { useRef, useState } from 'react';
import styled from 'styled-components';
import { connectInstance } from '@ardoq/rxbeach';
import { ViewIds } from '@ardoq/api-types';
import { viewModel$ } from './viewModel$/viewModel$';
import { ComponentMatrixViewProperties } from './types';
import { ComponentMatrixViewContainer } from './view/atoms';
import ComponentMatrix from './view/componentMatrix';
import { getExportsForHtmlView } from '@ardoq/export';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import ComponentMatrixSettingsBar from './ComponentMatrixSettingsBar';
import ComponentMatrixViewLegend from './ComponentMatrixViewLegend';
import EmptyState from './EmptyState';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from '../consts';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { noop } from 'lodash';
import { isPresentationMode } from 'appConfig';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

const VIEW_ID = ViewIds.COMPONENT_MATRIX;

const ContentContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  &.forExport {
    overflow: visible;
    width: fit-content;
    flex: unset;
  }
`;

const ComponentMatrixView = ({
  viewModel,
  viewSettings,
  viewSettingsState,
  hasReferences,
  hasFilteredNullItems,
  errors,
}: ComponentMatrixViewProperties) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const getContainer = () => containerRef.current;

  const [clearedErrors, setClearedErrors] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    VIEW_ID,
    NEVER_SHOW_AGAIN
  );

  const noVisibleComponents = !viewModel.items.length;
  const noReferencesOrListFields = !(
    hasReferences || viewSettingsState.listFields.length
  );
  const isEmptyView = noVisibleComponents || noReferencesOrListFields;

  return (
    <ComponentMatrixViewContainer
      className={`tab-pane ${VIEW_ID}Tab active react-view`}
      data-context-menu={OBJECT_CONTEXT_MENU_NAME}
    >
      <ComponentMatrixSettingsBar
        viewSettingsState={viewSettingsState}
        exports={{
          ...getExportsForHtmlView({
            container: getContainer,
            exportedViewMetadata: {
              name: VIEW_ID,
            },
            ...getSharedExportFunctions(),
          }),
          isDisabled: isEmptyView,
        }}
      />
      {isEmptyView ? (
        <EmptyState
          noVisibleComponents={noVisibleComponents}
          noReferencesOrListFields={noReferencesOrListFields}
          hasFilteredNullItems={hasFilteredNullItems}
          allDescendantsIncluded={viewSettings.includeAllDescendants}
        />
      ) : (
        <ContentContainer ref={containerRef}>
          <ComponentMatrix viewModel={viewModel} />
          <ComponentMatrixViewLegend componentMatrixItems={viewModel.items} />
          <ErrorInfoBox
            errors={clearedErrors ? [] : errors}
            hasClones={false}
            clearHasClones={noop}
            isShowNeverAgainSet={neverShowAgain}
            isPresentationMode={isPresentationMode()}
            clearErrors={() => setClearedErrors(true)}
            toggleNeverShowAgain={toggleNeverShowAgain}
          />
        </ContentContainer>
      )}
    </ComponentMatrixViewContainer>
  );
};
const PerformanceTrackedComponentMatrix = (
  props: ComponentMatrixViewProperties
) =>
  WithPerformanceTracking('component matrix view render', 1000, {
    WrappedComponent: ComponentMatrixView,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: { itemCount: props.viewModel.items.length },
  });
export default connectInstance(PerformanceTrackedComponentMatrix, viewModel$);
