import {
  action$,
  persistentReducedStream,
  streamReducer,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { updateSelectedReferences } from './actions';
import { map, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { ArdoqId } from '@ardoq/api-types';
import { ExcludeFalsy, returnPayload } from '@ardoq/common-helpers';
import activeView$ from '../views/mainContent/activeView$';
import { trackMultiselect } from './tracking';
import { componentSelection$ } from 'componentSelection/componentSelection$';
import { componentInterface } from 'modelInterface/components/componentInterface';

type SelectedResources = {
  selectedComponentIds: ArdoqId[];
  selectedReferenceIds: ArdoqId[];
};

const getSelectionsCount = (selections: SelectedResources) =>
  selections.selectedComponentIds.length +
  selections.selectedReferenceIds.length;

const isMultiselectStarted = (
  previous: SelectedResources,
  current: SelectedResources
) => getSelectionsCount(previous) <= 1 && getSelectionsCount(current) > 1;

const selectedReferences$ = action$.pipe(
  ofType(updateSelectedReferences),
  extractPayload(),
  startWith({
    referenceIds: [],
  })
);

const selectionObservable$ = combineLatest([
  context$,
  componentSelection$,
  selectedReferences$,
]).pipe(
  map(([{ componentId }, { graphSelection: selection }, { referenceIds }]) => ({
    selectedComponentIds: selection.filter(componentInterface.isComponent),
    selectedReferenceIds: referenceIds,
    contextComponentId: componentId,
  })),
  startWith({
    selectedComponentIds: [],
    selectedReferenceIds: [],
    contextComponentId: '',
  }),
  pairwise(),
  withLatestFrom(activeView$),
  map(([[previous, current], activeViewState]) => {
    const { selectedComponentIds, selectedReferenceIds, contextComponentId } =
      current;

    if (isMultiselectStarted(previous, current)) {
      trackMultiselect(activeViewState);
    }

    return {
      componentIds: (selectedComponentIds.length || selectedReferenceIds.length
        ? selectedComponentIds
        : // only use the context component as the selection if nothing else is selected.
          [contextComponentId]
      ).filter(ExcludeFalsy),
      referenceIds: selectedReferenceIds,
    };
  })
);

export const selection$ = persistentReducedStream<{
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
}>(
  'selection$',
  {
    componentIds: [],
    referenceIds: [],
  },
  [streamReducer(selectionObservable$, returnPayload)]
);
