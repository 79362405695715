import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { DEGREES_OF_RELATIONSHIP } from '@ardoq/global-consts';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import { Notifier } from '@ardoq/dropdown-menu';

type NoDataWithZeroDegreesDoqProps = {
  doqType: DoqType;
  includeActions?: boolean;
  changeSettingAction?: Notifier;
};

const NoDataWithZeroDegreesDoq = ({
  doqType,
  includeActions = true,
  changeSettingAction,
}: NoDataWithZeroDegreesDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is nothing to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            No components to display when the degree of relationship is set to
            zero.
          </FlexBox>
          <FlexBox gap="medium">
            <span style={{ display: 'inline' }}>
              To show more data, change the view modifier &quot;
              {DEGREES_OF_RELATIONSHIP}&quot;{' '}
              <Icon
                iconSize={IconSize.SMALL}
                iconName={IconName.ACCOUNT_TREE}
              />
            </span>
          </FlexBox>
        </Stack>
      }
      buttonLabel={includeActions ? 'Change setting' : undefined}
      onButtonClick={
        includeActions && changeSettingAction
          ? () => changeSettingAction.notify()
          : undefined
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoDataWithZeroDegreesDoq;
