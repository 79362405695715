import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { ChevronIcon } from '@ardoq/icons';
import styled from 'styled-components';
import { selectComponent } from 'streams/components/ComponentActions';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { horizontalSpacing } from 'tabview/pagesView/constants';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { setContextToWorkspace } from 'tabview/pagesView/utils';
import { colors } from '@ardoq/design-tokens';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import {
  getComponentLabelParts,
  ComponentLabelParts,
  truncateComponentLabel,
} from '@ardoq/graph';
import { returnZero } from '@ardoq/common-helpers';

const SmallLayout = styled.div`
  display: flex;
  padding-left: ${horizontalSpacing.SMALL};
  div {
    ${fontSizeDefinitions_DEPRECATED.EXTRA_SMALL}
    color: ${colors.grey15};
    display: flex;
    align-items: center;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  div {
    color: ${colors.grey15};
    display: flex;
  }
`;

const SmallChevron = styled(ChevronIcon)`
  height: 12px;
  margin: 0 2px 0 2px;
`;
const ClickableName = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface ComponentPathProps {
  componentId: ArdoqId;
  small?: boolean;
}

const getComponentPath = (
  componentId: ArdoqId
): Array<[id: ArdoqId, name: string, labelInfo: ComponentLabelParts]> => {
  const getParentIds = (compId: ArdoqId): ArdoqId[] => {
    const parentId = componentInterface.getParentId(compId);
    return parentId ? [...getParentIds(parentId), parentId] : [];
  };
  return [...getParentIds(componentId), componentId].map(compId => [
    compId,
    componentInterface.getDisplayName(compId) ?? '',
    getComponentLabelParts(compId),
  ]);
};

const ComponentPath = ({ componentId, small }: ComponentPathProps) => {
  const workspaceId = componentInterface.getWorkspaceId(componentId) ?? '';
  const workspaceName = workspaceInterface.getWorkspaceName(workspaceId);
  const LayoutComponent = small ? SmallLayout : Layout;
  const Chevron = small ? SmallChevron : ChevronIcon;
  const contextMenuAttribute = COMPONENT_ID_ATTRIBUTE;

  return (
    <LayoutComponent>
      <ClickableName
        onClick={() =>
          setContextToWorkspace(workspaceId, 'pagesViewBreadcrumb')
        }
      >
        {workspaceName}
      </ClickableName>
      {getComponentPath(componentId).map(
        ([compId, componentName, componentLabel], i) => (
          <div key={`${compId}--${i}`}>
            <Chevron
              direction="right"
              style={small ? { fontSize: '12px' } : {}}
            />
            <ClickableName
              className="component"
              onClick={() => dispatchAction(selectComponent({ cid: compId }))}
              {...{ [contextMenuAttribute]: compId }}
            >
              {small
                ? componentName
                : truncateComponentLabel({
                    ...componentLabel,
                    measure: returnZero,
                    width: Infinity,
                  })}
            </ClickableName>
          </div>
        )
      )}
    </LayoutComponent>
  );
};

export const SmallComponentPath = ({ componentId }: ComponentPathProps) => (
  <ComponentPath componentId={componentId} small={true} />
);

export const NormalComponentPath = ({ componentId }: ComponentPathProps) => (
  <ComponentPath componentId={componentId} small={false} />
);
