import { ArdoqId } from '@ardoq/api-types';
import {
  GraphModelShape,
  HierarchyOfLinkedComponentsWithDirection,
} from '@ardoq/data-model';
import {
  GraphInterface,
  RawGraphItem,
  TraversalAndGroupingResult,
  LoadedGraphWithViewpointMode,
} from '@ardoq/graph';
import { uniq } from 'lodash';
import { getRootItems } from '@ardoq/graph';
import { getAllPathsAsHierarchy } from 'traversals/getAllPathsAsHierarchy';

type GetGraphShapeForTraversalHierarchy = (args: {
  graphInterface: GraphInterface;
  loadedGraph: LoadedGraphWithViewpointMode;
  graphModel: GraphModelShape;
  workspacesIds: ArdoqId[];
  traversalAndGroupingResult: TraversalAndGroupingResult;
}) => {
  rootItems: RawGraphItem[];
  startSet: ArdoqId[];
  graphAsHierarchy: Record<string, HierarchyOfLinkedComponentsWithDirection>;
};
const getGraphShapeForTraversalHierarchy: GetGraphShapeForTraversalHierarchy =
  ({
    loadedGraph,
    graphModel,
    workspacesIds,
    graphInterface,
    traversalAndGroupingResult,
  }) => {
    const graphAsHierarchy = getAllPathsAsHierarchy({
      graphInterface,
      hierarchyDefinition: loadedGraph.hierarchyDefinition,
      graphModel,
      workspacesIds,
    });

    const startSet = uniq(
      loadedGraph.hierarchyDefinition.flatMap(
        ({ startSetResult }) => startSetResult
      )
    );

    const { rootGroups, componentMap } = traversalAndGroupingResult;

    const rootItems = getRootItems({
      startSet,
      rootGroups,
      componentMap,
    });

    return {
      rootItems,
      startSet,
      graphAsHierarchy,
    };
  };

export default getGraphShapeForTraversalHierarchy;
