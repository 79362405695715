import {
  FreeEdgeLabelModel,
  type IEdge,
  type IGraph,
  type ILabelModelParameter,
  type ILabelOwner,
  type INode,
  Insets,
  InteriorLabelModel,
  InteriorLabelModelPosition,
  SmartEdgeLabelModel,
} from '@ardoq/yfiles';
import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import {
  type AggregatedGraphEdge,
  AggregatedEdgeLabelStyle,
  type CollapsibleGraphGroup,
  type GraphItem,
  type GraphNode,
  type RelationshipDiagramGraphEdgeType,
} from '@ardoq/graph';
import { getCssClassFromDiffType } from 'scope/modelUtil';
import {
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT,
} from '../consts';
import { MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE } from 'yfilesExtensions/styles/modernized/consts';

const URL_NODE_BUTTON_MARGIN = 8;
const COLLAPSED_COMPONENT_GROUP_HEADER_HEIGHT = 32;
/**
 * the layout parameter used for the URL Icon Button on nodes.
 * the URL Icon Button is displayed over components containing URL fields.
 *
 * a label with a font icon is used for the URL Icon Button. this parameter describes the placement of a label relative to the node bounds.
 */
const urlNodeButtonModelParameter = new InteriorLabelModel({
  insets: new Insets(0, URL_NODE_BUTTON_MARGIN, URL_NODE_BUTTON_MARGIN, 0),
}).createParameter(InteriorLabelModelPosition.NORTH_EAST);
const urlGroupNodeButtonModelParameter = new InteriorLabelModel({
  insets: new Insets(
    0,
    MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_GROUP_HEIGHT / 2 -
      MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE / 2,
    URL_NODE_BUTTON_MARGIN,
    0
  ),
}).createParameter(InteriorLabelModelPosition.NORTH_EAST);
const urlCollapsedComponentGroupNodeButtonModelParameter =
  new InteriorLabelModel({
    insets: new Insets(
      0,
      COLLAPSED_COMPONENT_GROUP_HEADER_HEIGHT +
        MODERNIZED_BLOCK_DIAGRAM_COLLAPSED_COMPONENT_GROUP_PADDING +
        MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE / 2,
      6,
      0
    ),
  }).createParameter(InteriorLabelModelPosition.NORTH_EAST);
const urlEdgeButtonModelParameter =
  FreeEdgeLabelModel.INSTANCE.createDefaultParameter();
const aggregatedEdgeCountLabelStyle = new AggregatedEdgeLabelStyle(
  getCssClassFromDiffType
);
const aggregatedEdgeCountLabelParameter =
  new SmartEdgeLabelModel().createDefaultParameter();

const isCollapsedComponentGroup = (group: CollapsibleGraphGroup) =>
  group.collapsed && group.isComponent();
const addURLLabel = (
  graph: IGraph,
  owner: ILabelOwner,
  graphNode: GraphItem,
  buttonModelParameter: ILabelModelParameter,
  isModern: boolean
) =>
  graph.addLabel({
    owner,
    text: '',
    layoutParameter: buttonModelParameter,
    style: isModern ? ArdoqURLLabelStyle.Modern : ArdoqURLLabelStyle.Classic,
    tag: graphNode,
  });
export const addURLEdgeLabel = (
  graph: IGraph,
  owner: IEdge,
  graphEdge: RelationshipDiagramGraphEdgeType,
  isModern: boolean
) =>
  addURLLabel(graph, owner, graphEdge, urlEdgeButtonModelParameter, isModern);

export const addURLNodeLabel = (
  graph: IGraph,
  owner: INode,
  graphNode: GraphNode
) => addURLLabel(graph, owner, graphNode, urlNodeButtonModelParameter, true);

export const addGroupURLNodeLabel = (
  graph: IGraph,
  owner: INode,
  graphNode: CollapsibleGraphGroup
) =>
  addURLLabel(
    graph,
    owner,
    graphNode,
    isCollapsedComponentGroup(graphNode)
      ? urlCollapsedComponentGroupNodeButtonModelParameter
      : urlGroupNodeButtonModelParameter,
    true
  );
export const addAggregatedCountLabel = (
  graph: IGraph,
  owner: IEdge,
  graphEdge: AggregatedGraphEdge
) =>
  graph.addLabel({
    owner,
    text: `${graphEdge.modelIds.length}`,
    style: aggregatedEdgeCountLabelStyle,
    layoutParameter: aggregatedEdgeCountLabelParameter,
    tag: graphEdge,
  });
