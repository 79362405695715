import { each, isString } from 'lodash';
import Context from 'context';
import Components from 'collections/components';
import Filters from 'collections/filters';
import Workspaces from 'collections/workspaces';
import {
  ensureContrast,
  getDefaultCSSColor,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { type APIReferenceType, ArdoqId, LineType } from '@ardoq/api-types';
import { ComponentBackboneModel, Workspace } from 'aqTypes';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { diffModeUpdated } from 'scope/actions';
import {
  activeScenario$,
  getActiveScenarioState,
} from 'streams/activeScenario/activeScenario$';
import { CollectionView } from 'collections/consts';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActionCreator, dispatchAction } from '@ardoq/rxbeach';
import {
  addToStyleSheet,
  addToTempStyleSheet,
  clearStyles,
  clearTempStyles,
} from './actions';
import { BACKGROUND_CLASS_NAME, FILTER_STYLES_KEY } from './consts';
import {
  CONDITIONAL_IMAGE_COLORING_CLASS_NAME,
  LI_BACKGROUND_CLASS_NAME,
  NO_FILTER_CLASS_NAME,
} from '@ardoq/global-consts';
import {
  filterClass,
  referenceModelClassName,
  referenceTypeClassName,
} from '@ardoq/color-helpers';
import { CssEntry } from './types';
import { notifyFilterColorChanged } from 'streams/filters/FilterActions';
import { debounce } from 'lodash';
import {
  getOnlyConnectedWorkspaceIds,
  linkedWorkspaces$,
} from 'streams/linkedWorkspaces$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { notifyModelChanged } from 'streams/models/actions';
import {
  applicationStarted,
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
} from 'streams/context/ContextActions';
import { getImageColorFilterValueForColor } from 'views/ConditionalFormattingImageColorFilters';
import { vizFilterGray, vizFilterLightGray } from '@ardoq/design-tokens';
import { modelInterface } from '../../modelInterface/models/modelInterface';
import { virtualModel } from 'models/virtualReference';
import Models from 'collections/models';
import { activeScenarioOperations } from '../../streams/activeScenario/activeScenarioOperations';

const CONTRASTED_LIGHT_GRAY_ON_GRAY = ensureContrast(
  vizFilterLightGray,
  vizFilterGray
);

const isScenarioMode = () => {
  const activeScenario = getActiveScenarioState();
  return activeScenarioOperations.isInScenarioMode(activeScenario);
};

/**
 * Filter will be applied to an image if
 * 1) .CONDITIONAL_IMAGE_COLORING_CLASS_NAME is an ancestor of .component, which is an ancestor of the img
 * 2) img is a descendant of a .component with .CONDITIONAL_IMAGE_COLORING_CLASS_NAME
 *
 * https://ardoqcom.atlassian.net/wiki/spaces/INSIGHT/pages/2532966456/Image+color+filters
 */
const conditionalFormattingImageColoringSelector = (fName: string) =>
  `.${CONDITIONAL_IMAGE_COLORING_CLASS_NAME} .component.${fName} img, .${CONDITIONAL_IMAGE_COLORING_CLASS_NAME}.component.${fName} img`;

/** checks if a record has any keys. this is faster than checking Object.keys(record).length - it's essentially a copy of lodash.isEmpty minus the overhead of checking if the argument is array-like. */
const isEmpty = (record: Record<string, unknown>) => {
  for (const key in record) {
    return false;
  }
  return true;
};
interface ReduceComponentsState {
  clearCids: string[];
  cssEntries: Record<string, CssEntry[]>;
}

export function startHandlingCss() {
  const resetDebounced = debounce(reset, 50);

  const actionCreators: ActionCreator<any>[] = [
    notifyWorkspaceClosed,
    notifyWorkspaceOpened,
    notifyFilterColorChanged,
    notifyModelChanged,
  ];

  actionCreators.forEach(actionCreator =>
    subscribeToAction(actionCreator, resetDebounced)
  );

  subscribeToAction(diffModeUpdated, () => {
    if (isScenarioMode()) {
      loadStylesForWorkspaces();
    }
  });

  linkedWorkspaces$.subscribe(loadStylesFromLinkedWorkspaces);

  activeScenario$
    .pipe(distinctUntilChanged())
    .subscribe(() => clearAllCidStyle());

  subscribeToAction(applicationStarted, loadStylesForWorkspaces);

  Components.collection.on('change:color', (c: ComponentBackboneModel) =>
    addCSSForComp(c)
  );
}

const reduceComponents = (
  state: ReduceComponentsState,
  component: ComponentBackboneModel
) => {
  const { cid } = component;
  const css = getCSSForComp(component);
  if (css) {
    state.cssEntries[cid] = css;
  } else {
    state.clearCids.push(cid);
  }
  return state;
};

function reset() {
  dispatchAction(clearStyles({ keys: [FILTER_STYLES_KEY] }));
  loadStylesForWorkspaces();
}

function getLoadedWorkspaces(): Workspace[] {
  return isScenarioMode()
    ? Workspaces.collection.views.get(CollectionView.BASE_VIEW)?.toArray() || []
    : Context.workspaces();
}

function loadStylesForWorkspaces() {
  const workspaces = getLoadedWorkspaces();

  workspaces.forEach(ws => {
    addCSSForModel(ws.getModelId());
  });
  if (!Models.collection.get(virtualModel.id)) {
    Models.collection.add(virtualModel, { silent: true });
  }
  addCSSForModel(virtualModel.id);
  loadCSSForWorkspaces(workspaces);
}

function loadCSSForWorkspaces(workspaces: Workspace[]) {
  workspaces.forEach(workspace => addCSSForWorkspace(workspace));
  // Also add coloring for backlinked/linked workspaces
  loadStylesFromLinkedWorkspaces();
  addCSSForFilters();
}

function loadStylesFromLinkedWorkspaces() {
  const workspaces = getLoadedWorkspaces();
  const nonContextWorkspaces = Array.from(
    getOnlyConnectedWorkspaceIds(workspaces.map(({ id }) => id))
  )
    .map(id => Workspaces.collection.get(id))
    .filter(ExcludeFalsy);

  nonContextWorkspaces.forEach(ws => {
    addCSSForModel(ws.getModelId());
    addCSSForWorkspace(ws);
  });
}

function addCSSForWorkspace(workspace: Workspace) {
  const components: ComponentBackboneModel[] =
    (isScenarioMode()
      ? Components.collection.views.get(CollectionView.BASE_VIEW)
      : Components.collection
    )?.toArray() || [];
  addCSSForComponents(
    components.filter(
      component => component.attributes.rootWorkspace === workspace.id
    )
  );
}

export function addCSSForComponents(components: ComponentBackboneModel[]) {
  const { clearCids, cssEntries } = components.reduce(reduceComponents, {
    clearCids: [],
    cssEntries: {},
  });
  if (clearCids.length) {
    dispatchAction(clearStyles({ keys: clearCids }));
  }
  if (!isEmpty(cssEntries)) {
    dispatchAction(addToStyleSheet(cssEntries));
  }
}

function addCSSForFilters() {
  const styleId = FILTER_STYLES_KEY;
  let filterColors = false;
  // add overriding filter styles
  const styles: CssEntry[] = [];

  Filters.getFilterColors().forEach(filterColor => {
    filterColors = true;
    const fName = filterClass(filterColor) || '';
    styles.push([
      `.component.${fName}`,
      [
        `color: ${filterColor} !important;`,
        `fill: ${filterColor} !important;`,
      ].join(' '),
    ]);
    styles.push([
      conditionalFormattingImageColoringSelector(fName),
      `filter: ${getImageColorFilterValueForColor(filterColor)}`,
    ]);
    styles.push([
      `.component.${fName} .component-name`,
      `color:${filterColor} !important;`,
    ]);
    const shadedFilterColor = getShadedColor(filterColor);
    styles.push([
      `.component-stroke.${fName}`,
      `stroke: ${shadedFilterColor};`,
    ]);
    styles.push([
      `.component.${BACKGROUND_CLASS_NAME}.${fName}`,
      `background-color:${filterColor} !important; fill:${filterColor}!important;`,
    ]);
    const lightenedFilterColor = getLightenedColor(filterColor);
    const contrastColor = ensureContrast(lightenedFilterColor, filterColor);
    styles.push([
      `.component.${LI_BACKGROUND_CLASS_NAME}.${fName}`,
      [
        `background-color: ${lightenedFilterColor} !important;`,
        `fill: ${lightenedFilterColor} !important;`,
        `border-color: ${contrastColor} !important;`,
        `stroke: ${contrastColor} !important`,
      ].join(' '),
    ]);
    styles.push([
      `.component.group.${fName}`,
      `background-color:${filterColor} !important;`,
    ]);
  });

  if (filterColors) {
    styles.push([
      `.component.${NO_FILTER_CLASS_NAME}`,
      `color:${vizFilterGray}; fill:${vizFilterGray}`,
    ]);
    styles.push([
      conditionalFormattingImageColoringSelector(NO_FILTER_CLASS_NAME),
      `filter: ${getImageColorFilterValueForColor(vizFilterGray)}`,
    ]);
    const shadedFilterGray = getShadedColor(vizFilterGray);
    styles.push([
      `.component-stroke.${NO_FILTER_CLASS_NAME}`,
      `stroke: ${shadedFilterGray};`,
    ]);
    styles.push([
      `.component.${BACKGROUND_CLASS_NAME}.${NO_FILTER_CLASS_NAME}`,
      `background-color:${vizFilterGray} !important; fill:${vizFilterGray}`,
    ]);
    styles.push([
      `.component.${LI_BACKGROUND_CLASS_NAME}.${NO_FILTER_CLASS_NAME}`,
      [
        `background-color: ${vizFilterLightGray} !important;`,
        `fill: ${vizFilterLightGray} !important;`,
        `stroke: ${CONTRASTED_LIGHT_GRAY_ON_GRAY} !important`,
      ].join(' '),
    ]);
  }

  filterColors = false;

  Filters.getFilterReferenceColors().forEach(filterReferenceColor => {
    filterColors = true;
    const fName = filterClass(filterReferenceColor);
    styles.push([
      `.integration.${fName}`,
      `color:${filterReferenceColor} !important;stroke:${filterReferenceColor} !important`,
    ]);
    const lightenedFilterReferenceColor =
      getLightenedColor(filterReferenceColor);
    const borderColor = ensureContrast(
      lightenedFilterReferenceColor,
      filterReferenceColor
    );
    styles.push([
      `.integration.${LI_BACKGROUND_CLASS_NAME}.${fName}`,
      [
        `background-color: ${lightenedFilterReferenceColor} !important;`,
        `fill: ${lightenedFilterReferenceColor} !important;`,
        `border-color: ${borderColor} !important;`,
      ].join(' '),
    ]);
  });

  if (filterColors) {
    styles.push([
      `.integration.${NO_FILTER_CLASS_NAME}`,
      `color:${vizFilterGray} !important;stroke:${vizFilterGray}!important`,
    ]);
    styles.push([
      `.integration.${LI_BACKGROUND_CLASS_NAME}.${NO_FILTER_CLASS_NAME}`,
      `background-color: ${vizFilterLightGray} !important;`,
    ]);
  }
  addEntriesToStyleSheet(styleId, styles);
}

function getCSSForComp(comp: ComponentBackboneModel): CssEntry[] | null {
  const color = comp.attributes.color;
  const { cid } = comp;
  if (!(isString(color) && color.length > 0)) {
    return null;
  }
  const shadedColor = getShadedColor(color);
  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);
  return [
    [`.component.${cid}`, `color:${color}; fill:${color}`],
    [`.component-stroke.${cid}`, `stroke: ${shadedColor};`],
    [
      `.component.${BACKGROUND_CLASS_NAME}.${cid}`,
      `background-color:${color} !important; fill:${color}`,
    ],
    [
      `.component.${LI_BACKGROUND_CLASS_NAME}.${cid}`,
      [
        `background-color: ${lightenedColor} !important;`,
        `fill: ${lightenedColor};`,
        `border-color: ${contrastColor} !important;`,
        `stroke: ${contrastColor} !important`,
      ].join(' '),
    ],
  ];
}

function addCSSForComp(comp: ComponentBackboneModel) {
  const { cid } = comp;
  const entries = getCSSForComp(comp);
  if (entries && entries.length) {
    dispatchAction(addToTempStyleSheet({ [cid]: entries }));
  } else {
    clearCidStyle(cid);
  }
}

const referenceTypeStyles = (
  modelId: string,
  { id, line, color: modelColor }: APIReferenceType
): [regularStyle: CssEntry, backgroundStyle: CssEntry] => {
  const color = modelColor || 'black';
  const lineTypeCss =
    line === LineType.DASHED
      ? 'stroke-dasharray: 10,5;'
      : line === LineType.DOTTED
        ? 'stroke-dasharray: 2;'
        : '';

  const referenceClassName = `${referenceModelClassName(
    modelId
  )}.${referenceTypeClassName(id)}`;

  const lightenedColor = getLightenedColor(color);
  const contrastColor = ensureContrast(lightenedColor, color);

  const referenceTypeSelector = `.integration.${referenceClassName}`;
  const referenceTypeBackgroundSelector = `.integration.${LI_BACKGROUND_CLASS_NAME}.${referenceClassName}`;
  const referenceTypeCss = `color: ${color}; stroke:${color};${lineTypeCss}`;
  const referenceTypeBackgroundCss = `border-color: ${contrastColor}; background-color: ${lightenedColor};`;
  return [
    [referenceTypeSelector, referenceTypeCss],
    [referenceTypeBackgroundSelector, referenceTypeBackgroundCss],
  ];
};

export function addCSSForModel(modelId: ArdoqId) {
  const styles: CssEntry[] = [];
  const componentTypes = modelInterface.getComponentTypes(modelId);

  each(componentTypes, type => {
    if (type.color) {
      styles.push([
        `.component.${type.id}`,
        `fill:${type.color};color:${type.color}`,
      ]);
      const shadedColor = getShadedColor(type.color);
      styles.push([`.component-stroke.${type.id}`, `stroke: ${shadedColor};`]);
      styles.push([
        `.component.${BACKGROUND_CLASS_NAME}.${type.id}`,
        `background-color:${type.color} !important;fill:${type.color}`,
      ]);
      const lightenedColor = getLightenedColor(type.color);
      const contrastColor = ensureContrast(lightenedColor, type.color);
      styles.push([
        `.component.${LI_BACKGROUND_CLASS_NAME}.${type.id}`,
        [
          `background-color: ${lightenedColor} !important;`,
          `fill: ${lightenedColor};`,
          `border-color: ${contrastColor} !important;`,
          `stroke: ${contrastColor} !important`,
        ].join(' '),
      ]);
    } else {
      const defaultColor = getDefaultCSSColor(type.level);
      styles.push([
        `.component.${type.id}`,
        `fill:${defaultColor};color:${defaultColor};`,
      ]);
      const shadedDefaultColor = getShadedColor(defaultColor);
      styles.push([
        `.component-stroke.${type.id}`,
        `stroke: ${shadedDefaultColor};`,
      ]);
      styles.push([
        `.component.${BACKGROUND_CLASS_NAME}.${type.id}`,
        `fill:${defaultColor};background-color:${defaultColor} !important;`,
      ]);
      const lightenedDefaultColor = getLightenedColor(defaultColor);
      const contrastColor = ensureContrast(lightenedDefaultColor, defaultColor);
      styles.push([
        `.component.${LI_BACKGROUND_CLASS_NAME}.${type.id}`,
        [
          `background-color: ${lightenedDefaultColor} !important;`,
          `fill: ${lightenedDefaultColor};`,
          `border-color: ${contrastColor} !important;`,
          `stroke: ${contrastColor} !important`,
        ].join(' '),
      ]);
    }
  });

  const modelReferenceTypes = modelInterface.getReferenceTypes(modelId);
  styles.push(
    ...modelReferenceTypes.flatMap(modelReferenceType =>
      referenceTypeStyles(modelId, modelReferenceType)
    )
  );

  addEntriesToStyleSheet(modelId, styles);
}

function addEntriesToStyleSheet(key: string, entries: CssEntry[]) {
  if (!entries.length) {
    return;
  }
  dispatchAction(addToStyleSheet({ [key]: entries }));
}

export function clearAllCidStyle() {
  dispatchAction(clearTempStyles());
}

function clearCidStyle(cid: string) {
  dispatchAction(clearStyles({ keys: [cid] }));
}
