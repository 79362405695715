import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { times } from 'lodash';
import {
  EXTRA_CHART_HEIGHT,
  LEGEND_FONT_SIZE,
  LEGEND_ROW_HEIGHT,
  MIN_CHART_HEIGHT,
  MIN_CHART_WIDTH,
  POLYGON_INDEX_ATTRIBUTE,
} from './consts';
import { ChartConfiguration, SpiderChartPoint } from './types';
import { ChartPolygon } from './ChartPolygon';
import { ChartCircularSegment } from './ChartCircularSegment';
import { ChartAxis } from './ChartAxis';
import { ChartAxisLevelLabel } from './ChartAxisLevelLabel';
import { ChartPoint } from './ChartPoint';
import { getRoundedRange } from './utils';
import { getRoundedInterval } from 'tabview/ticks';
import { NumericRange } from '@ardoq/graph';
import { getScrollbarWidth } from '@ardoq/common-helpers';
import { ArdoqId } from '@ardoq/api-types';
import { spiderChartCommands } from '../commands';

const defaultConfig = {
  width: 600,
  height: 600,
  factorLegend: 0.85,
  levels: 3,
  maxValue: 5,
  radians: 2 * Math.PI,
  toRight: 5,
  extraWidth: 0,
};

type SpiderChartProps = {
  chartPoints: SpiderChartPoint[][];
  containerWidth: number;
  containerHeight: number;
  focusedComponentId: ArdoqId | null;
};

export const SpiderChart = ({
  chartPoints,
  containerWidth,
  containerHeight,
  focusedComponentId,
}: SpiderChartProps) => {
  const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);

  const [activePolygonIndex, setActivePolygonIndex] = useState<number | null>(
    null
  );
  const polygonOnMouseEnter = useCallback(
    (
      event: React.MouseEvent<SVGPolygonElement | SVGCircleElement, MouseEvent>
    ) => {
      const polygonIndex = event.currentTarget.getAttribute(
        POLYGON_INDEX_ATTRIBUTE
      );

      if (polygonIndex) {
        setActivePolygonIndex(parseInt(polygonIndex, 10));
      }
    },
    []
  );

  const polygonOnMouseLeave = useCallback(
    () => setActivePolygonIndex(null),
    []
  );

  const chartHeight = Math.max(
    containerHeight - EXTRA_CHART_HEIGHT,
    MIN_CHART_HEIGHT
  );

  const extraHeight = EXTRA_CHART_HEIGHT - scrollbarWidth * 2;
  const extraWidth =
    Math.max(chartHeight - (MIN_CHART_WIDTH - MIN_CHART_HEIGHT), 200) -
    scrollbarWidth;

  const chartWidth = Math.max(containerWidth - extraWidth, MIN_CHART_WIDTH);
  const pointsFlatten = chartPoints.flat(1);
  const chartPointsList = chartPoints[0] || [];
  const labelsInfo = chartPointsList.map(point => point.labelInfo);

  const radius = Math.min(chartWidth / 2, chartHeight / 2);
  const maxFieldValue = Math.max(...pointsFlatten.map(point => point.value));
  const safeMaxRangeValue = maxFieldValue > 0 ? maxFieldValue : 1;
  const visibleRange: NumericRange = [0, safeMaxRangeValue];
  const roundedInterval = getRoundedInterval(visibleRange, radius, 20);
  const roundedRange = getRoundedRange(visibleRange, roundedInterval);
  const maxValue = roundedRange[1];
  const levels = maxValue / roundedInterval;

  const fullWidth = chartWidth + extraWidth;
  const fullHeight = chartHeight + extraHeight;

  const translateX = Math.trunc(extraWidth / 2);
  const translateY = Math.trunc(extraHeight / 2);

  const chartConfig: ChartConfiguration = {
    ...defaultConfig,
    width: chartWidth,
    height: chartHeight,
    labelsInfo,
    extraWidth,
    radius,
    levels,
    maxValue,
    roundedInterval,
    total: labelsInfo.length,
    centerX: chartWidth / 2,
    centerY: chartHeight / 2,
    legendHeight:
      chartPoints.length * LEGEND_ROW_HEIGHT +
      LEGEND_FONT_SIZE +
      LEGEND_FONT_SIZE / 2,
  };

  return (
    <svg
      width={fullWidth}
      height={fullHeight}
      onClick={() => spiderChartCommands.setFocusedItemState(null)}
    >
      <g transform={`translate(${translateX}, ${translateY})`}>
        {times(chartConfig.levels - 1, levelIndex =>
          labelsInfo.map((_, axisIndex) => (
            <ChartCircularSegment
              key={axisIndex}
              config={chartConfig}
              levelIndex={levelIndex}
              axisIndex={axisIndex}
            />
          ))
        )}
        {times(chartConfig.levels, levelIndex => (
          <ChartAxisLevelLabel
            key={levelIndex}
            config={chartConfig}
            levelIndex={levelIndex}
          />
        ))}
        {chartPointsList.map(({ id, labelInfo, componentId }, index) => (
          <ChartAxis
            key={id}
            componentId={componentId}
            labelInfo={labelInfo}
            isUnfocused={
              !!focusedComponentId && componentId !== focusedComponentId
            }
            index={index}
            config={chartConfig}
          />
        ))}
        {chartPoints.map((currentChartPoints, chartPointsIndex) => (
          <ChartPolygon
            key={chartPointsIndex}
            config={chartConfig}
            chartPoints={currentChartPoints}
            chartPointsIndex={chartPointsIndex}
            isActive={chartPointsIndex === activePolygonIndex}
            onMouseEnter={polygonOnMouseEnter}
            onMouseLeave={polygonOnMouseLeave}
          />
        ))}
        {chartPoints.map((currentChartPoints, chartPointsIndex) =>
          currentChartPoints.map((chartPoint, chartPointIndex) => (
            <ChartPoint
              key={chartPointIndex}
              config={chartConfig}
              chartPoint={chartPoint}
              chartPointIndex={chartPointIndex}
              chartPointsIndex={chartPointsIndex}
              onMouseEnter={polygonOnMouseEnter}
              onMouseLeave={polygonOnMouseLeave}
            />
          ))
        )}
      </g>
    </svg>
  );
};
