import { ArdoqId, WorkspaceDigestType } from '@ardoq/api-types';
import { actionCreator, dispatchAction } from '@ardoq/rxbeach';
import { initialWorkspaceDigestType } from './types';

interface WorkSpaceDigestPayload {
  ws: ArdoqId;
  daysAgo: number;
}
export interface WorkspaceDigestState {
  workspaceDigest: WorkspaceDigestType;
  isLoading: boolean;
  hasError: boolean;
}
export const startState: WorkspaceDigestState = {
  workspaceDigest: initialWorkspaceDigestType(),
  isLoading: false,
  hasError: false,
};

interface ViewDigestArgs {
  workspaceId: string;
  days: number;
}
/** Triggered when we want to view a digest. */
export const viewWorkspaceDigest = actionCreator<ViewDigestArgs>(
  '[DashboardContainer] VIEW_WORKSPACE_DIGEST'
);

export const fetchWorkspaceDigest = actionCreator<WorkSpaceDigestPayload>(
  '[workspaceDigest] FETCH_WORKSPACE_DIGEST'
);
export const fetchWorkspaceDigestPending = actionCreator<boolean>(
  '[workspaceDigest] FETCH_WORKSPACE_DIGEST_PENDEING'
);
export const fetchWorkspaceDigestHasError = actionCreator<boolean>(
  '[workspaceDigest] FETCH_WORKSPACE_DIGEST_HAS_ERROR'
);

export const workspaceDigestAction = actionCreator<WorkspaceDigestState>(
  '[workspaceDigest] WORKSPACE_DIGEST_STATE'
);

export function initWorkspaceDigest(ws: ArdoqId, daysAgo: number) {
  dispatchAction(
    fetchWorkspaceDigest({
      daysAgo,
      ws,
    })
  );
}
