import { DependencyMapNode } from '@ardoq/dependency-map';
import { getDirectReferenceNodes } from './util';

interface ApplyUniqueIdsReducerState {
  usedIds: Map<string, string>;
  duplicateCounts: Map<string, number>;
}
const applyUniqueIdsReducer = (
  state: ApplyUniqueIdsReducerState,
  currentNode: DependencyMapNode
): ApplyUniqueIdsReducerState => {
  const originalId = currentNode.id;
  if (state.usedIds.has(originalId)) {
    const duplicateCount = (state.duplicateCounts.get(originalId) ?? 0) + 1;
    state.duplicateCounts.set(originalId, duplicateCount);
    const newId = `${originalId}_${duplicateCount}`;
    currentNode.id = newId;
  }
  state.usedIds.set(currentNode.id, originalId);
  getDirectReferenceNodes(currentNode).reduce(applyUniqueIdsReducer, state);
  if (currentNode.children) {
    currentNode.children.reduce(applyUniqueIdsReducer, state);
  }
  return state;
};
/** recurses the view model and ensures ids are unique. they are not initially unique as they are derived from the graph and then potentially duplicated in the view model. */
const applyUniqueIds = (nodes: DependencyMapNode[]) =>
  nodes.reduce(applyUniqueIdsReducer, {
    usedIds: new Map(),
    duplicateCounts: new Map(),
  }).usedIds;

export default applyUniqueIds;
