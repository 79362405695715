import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  getViewModel$,
  isOptimizeLayoutDisabled$,
} from './viewModel$/viewModel$';
import { RelationshipsNode, RelationshipsViewProperties, Zoom } from './types';
import RelationshipsViewSettingsBar from './RelationshipsViewSettingsBar';
import { initializeZoom } from './zoom';
import { createLayout } from './initialiseLayout';
import { noop, throttle } from 'lodash';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { WILDCARD, returnNull } from '@ardoq/common-helpers';
import { ZoomTransform, select } from 'd3';
import {
  layoutComplete,
  relationshipsViewSetCollapsedGroupIds,
  relationshipsViewZoomToFit,
  zoomChanged,
} from './actions';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import { ViewIds } from '@ardoq/api-types';
import { notifyViewLoading } from 'tabview/actions';
import RelationshipsViewLegend from './RelationshipsViewLegend';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import { toggleLegend } from 'presentation/viewPane/actions';
import {
  DisabledZoomControls,
  viewSettingsConsts,
  updateViewSettings,
  updateViewContext,
} from '@ardoq/view-settings';
import RelationshipsViewDecorationsSvg from './RelationshipsViewDecorationsSvg';
import * as canvasHitTestRegistry from './canvasHitTestRegistry';
import {
  getFitNodeTransform,
  getNaturalZoomControlsDisabledState,
  getZoomScaleExtent,
  zoomScaleExtent,
  transformViewCanvas,
} from './util';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { addToPresentation } from 'viewSettings/exportHandlers';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import RelationshipsViewZoomControls from './view/RelationshipsViewZoomControls';
import { useResizeObserver } from '@ardoq/hooks';
import { xScaler } from '@ardoq/graph';
import EmptyState from './EmptyState';
import { isPresentationMode } from 'appConfig';
import { BUTTON_CLICK_ZOOM_FACTOR } from './consts';
import { Features, hasFeature } from '@ardoq/features';
import { currentTimestamp } from '@ardoq/date-time';
import { ViewCanvas } from 'tabview/relationshipDiagrams/atoms';
import { debounce } from 'lodash';
import { viewLegendCommands } from '@ardoq/view-legend';

type HandleZoomChangedArgs = {
  transform: ZoomTransform;
  rootNode: RelationshipsNode;
  canvas: HTMLCanvasElement;
  layout: ReturnType<typeof createLayout> | null;
  setZoomControlsDisabledState: React.Dispatch<
    React.SetStateAction<DisabledZoomControls>
  >;
  isOptimizeLayoutDisabled: boolean;
};

const handleZoomChanged = debounce(
  ({
    transform,
    rootNode,
    canvas,
    layout,
    setZoomControlsDisabledState,
    isOptimizeLayoutDisabled,
  }: HandleZoomChangedArgs) => {
    const rootNodeFitTransform = getFitNodeTransform(canvas, rootNode);
    const currentZoomScaleExtent = zoomScaleExtent(
      {
        width: canvas.offsetWidth,
        height: canvas.offsetHeight,
      },
      rootNodeFitTransform.k
    );
    const currentTransform = layout?.getViewTransform();

    const isFitWindowAvailable =
      !currentTransform ||
      rootNodeFitTransform.k !== currentTransform.k ||
      rootNodeFitTransform.x !== currentTransform.x ||
      rootNodeFitTransform.y !== currentTransform.y;

    const isFitWindowDisabled =
      !isFitWindowAvailable && isOptimizeLayoutDisabled;

    const currentDisabledState =
      getNaturalZoomControlsDisabledState(transform.k, currentZoomScaleExtent) |
      (Number(isFitWindowDisabled) && DisabledZoomControls.FIT_WINDOW);
    setZoomControlsDisabledState(currentDisabledState);
  },
  40
);

const VIEW_ID = ViewIds.RELATIONSHIPS_3;

const closestTo = (target: number, ...values: number[]) =>
  values.reduce((result, current) => {
    const resultDistance = Math.abs(target - result);
    const currentDistance = Math.abs(target - current);
    return currentDistance < resultDistance ? current : result;
  });

const scaleLocation = (
  dimension: number,
  previousDimension: number,
  location: number
) => xScaler([0, dimension], [0, previousDimension])(location);

const fitNode = (
  canvas: HTMLCanvasElement,
  node: RelationshipsNode,
  zoom: Zoom,
  viewInstanceId: string,
  duration = 500
) => {
  const xform = getFitNodeTransform(canvas, node);
  if (!duration) {
    // if duration is 0, the caller expects the view to be redrawn immediately. therefore, we call transformViewCanvas which will apply the devicePixelRatio and dispatch the zoomChanged action for the canvas implementation.
    transformViewCanvas(xform, viewInstanceId);
  }
  requestAnimationFrame(() =>
    select(canvas).transition().duration(duration).call(zoom.transform, xform)
  );
};

const RelationshipsViewCanvas = styled(ViewCanvas)`
  transition-property: opacity;
  transition-timing-function: ease-in;
`;
const ViewContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;
const RelationshipsView = ({
  rootNode,
  links,
  traversedIncomingReferenceTypes,
  traversedOutgoingReferenceTypes,
  referenceTypes,
  viewSettings,
  viewInstanceId,
  componentTypes,
  referenceModelTypes,
  disconnectedChildren,
  errors,
  hasClones,
  noConnectedComponents,
  groups,
  isViewpointMode,
}: RelationshipsViewProperties) => {
  const transformRef = useRef<ZoomTransform>();
  const zoom = useRef<Zoom | null>(null);
  const [zoomControlsDisabledState, setZoomControlsDisabledState] = useState(
    DisabledZoomControls.NONE
  );
  const layout = useRef<ReturnType<typeof createLayout> | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const previousCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const hasCanvasChanged = canvasRef.current !== previousCanvasRef.current;

  const {
    collapsedGroupIds,
    lastClickedNodeId,
    bundleRelationships,
    highlightDisconnectedComponents,
  } = viewSettings;
  useEffect(() => {
    if (!canvasRef.current || !rootNode) {
      dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: false }));
      dispatchAction(layoutComplete({ needsFit: false }));
      return;
    }

    const highlightDisconnectedNodes =
      highlightDisconnectedComponents && !isViewpointMode;
    const layoutArgs = {
      canvas: canvasRef.current,
      rootNode,
      links,
      bundleRelationships,
      highlightDisconnectedNodes,
      viewInstanceId,
      collapsedGroupIds,
      lastClickedNodeId,
    };
    layout.current?.initialize(layoutArgs);
    const currentLayout = layout.current ?? createLayout(layoutArgs);

    const theZoom =
      zoom.current && !hasCanvasChanged
        ? zoom.current
        : initializeZoom(canvasRef.current, viewInstanceId);

    if (theZoom !== zoom.current) {
      zoom.current = theZoom;
    }
    canvasHitTestRegistry.register(
      viewInstanceId,
      e => currentLayout.findNode(e)?.modelId ?? null
    );

    const fitRootNode = () =>
      requestAnimationFrame(() => {
        if (!canvasRef.current) {
          return;
        }
        fitNode(canvasRef.current, rootNode, theZoom, viewInstanceId);
      });

    const expandOrCollapseAll = (open: boolean) => {
      if (!rootNode) {
        return [];
      }
      currentLayout?.setBranchOpen(rootNode, open);
    };

    const subscriptions = [
      isOptimizeLayoutDisabled$.subscribe(isOptimizeLayoutDisabled => {
        if (rootNode && canvasRef.current && transformRef.current) {
          handleZoomChanged({
            transform: transformRef.current,
            rootNode,
            canvas: canvasRef.current,
            layout: currentLayout,
            setZoomControlsDisabledState,
            isOptimizeLayoutDisabled,
          });
        }
      }),
      subscribeToAction(
        layoutComplete,
        ({ needsFit }) => {
          theZoom.scaleExtent(getZoomScaleExtent(canvasRef.current, rootNode));
          if (!needsFit) {
            return;
          }
          fitRootNode();
        },
        viewInstanceId
      ),
      subscribeToAction(
        zoomChanged,
        ({ transform }) => {
          transformRef.current = transform;

          if (rootNode && canvasRef.current) {
            handleZoomChanged({
              transform,
              rootNode,
              canvas: canvasRef.current,
              layout: currentLayout,
              setZoomControlsDisabledState,
              isOptimizeLayoutDisabled:
                !layout.current?.isOptimizeLayoutAvailable(),
            });
          }
        },
        viewInstanceId
      ),
      subscribeToAction(
        toggleLegend,
        ({ legendActive }) => {
          legend.current?.setIsVisible(legendActive);
        },
        VIEW_ID
      ),
      subscribeToAction(relationshipsViewZoomToFit, ({ node, duration }) => {
        if (!canvasRef.current) {
          return;
        }
        fitNode(
          canvasRef.current,
          node || rootNode,
          theZoom,
          viewInstanceId,
          duration
        );
        if (!duration) {
          // some callers, such as the exporter, will dispatch this action with a duration of 0. in this case, we must immediately redraw the canvas, which normally sends all drawing through requestAnimationFrame.
          currentLayout?.redrawImmediate();
        }
      }),
      subscribeToAction(
        relationshipsViewSetCollapsedGroupIds,
        ({ collapsedGroupIds: newCollapsedGroupIds }) => {
          if (!newCollapsedGroupIds.length) {
            expandOrCollapseAll(true);
          } else if (newCollapsedGroupIds?.[0] === WILDCARD) {
            expandOrCollapseAll(false);
          }
        },
        VIEW_ID
      ),
    ];

    if (currentLayout !== layout.current) {
      layout.current = currentLayout;
    }

    currentLayout.dispatcher(
      () =>
        dispatchAction(notifyViewLoading({ viewInstanceId, isBusy: false })),
      () => 'notifyViewLoading'
    )();

    return () => {
      clearSubscriptions(subscriptions);
      canvasHitTestRegistry.unregister(viewInstanceId);
    };
  }, [
    links,
    rootNode,
    bundleRelationships,
    highlightDisconnectedComponents,
    viewInstanceId,
    collapsedGroupIds,
    lastClickedNodeId,
    isViewpointMode,
    hasCanvasChanged,
  ]);
  useEffect(() => {
    return () => {
      layout.current?.cleanup();
      zoom.current?.on('zoom', null);
    };
  }, []);

  const previousWidth = useRef(canvasRef.current?.offsetWidth);
  const previousHeight = useRef(canvasRef.current?.offsetHeight);

  useResizeObserver(canvasRef, () => {
    const width = canvasRef.current?.offsetWidth;
    const height = canvasRef.current?.offsetHeight;
    if (
      previousWidth.current === undefined ||
      previousHeight.current === undefined ||
      (width === previousWidth.current && height === previousHeight.current) ||
      !layout.current ||
      !zoom.current ||
      !canvasRef.current
    ) {
      previousWidth.current = width;
      previousHeight.current = height;
      return;
    }

    if (previousWidth.current === 0 || previousHeight.current === 0) {
      if (rootNode) {
        requestAnimationFrame(() => {
          if (canvasRef.current && zoom.current) {
            fitNode(
              canvasRef.current,
              rootNode,
              zoom.current,
              viewInstanceId,
              0
            );
          }
        });
      }
      previousWidth.current = width;
      previousHeight.current = height;
      return;
    }

    const viewTransform = layout.current.getViewTransform();
    const previousCanvasWidth = previousWidth.current * devicePixelRatio;
    const previousCanvasHeight = previousHeight.current * devicePixelRatio;
    const canvasWidth = (width ?? 0) * devicePixelRatio;
    const canvasHeight = (height ?? 0) * devicePixelRatio;

    // #region scale current view transform translate coords from the previous size to the new size.
    const transformX = scaleLocation(
      canvasWidth,
      previousCanvasWidth,
      viewTransform.x
    );
    const transformY = scaleLocation(
      canvasHeight,
      previousCanvasHeight,
      viewTransform.y
    );
    // #endregion

    const widthChange = canvasWidth / previousCanvasWidth;
    const heightChange = canvasHeight / previousCanvasHeight;
    /** true if the width is increasing while the height is decreasing, or vice-versa. */
    const irregularResize = widthChange >= 1 !== heightChange >= 1;
    /**
     * The ratio of size increase or decrease. Should be a number between 0 and Infinity.
     *
     * This will be the width change or height change with a ratio closest to 1.
     *
     * In the case of a one-dimensional resize, it will be 1, representing the unchanged dimension. Therefore, scale will not adjust unless both dimensions are resizing.
     *
     * In the case of an irregular resize, e.g. when width is growing while height is shrinking, sizeChange will be 1, and scale will not adjust.
     */
    const sizeChange = irregularResize
      ? 1
      : closestTo(1, widthChange, heightChange);
    const scale = viewTransform.k * sizeChange;
    zoom.current.transform(
      select(canvasRef.current),
      new ZoomTransform( // the zoom handler is going to call transformViewCanvas, which will apply the devicePixelRatio. so we need to divide all transform parameters by devicePixelRatio.
        scale / devicePixelRatio,
        transformX / devicePixelRatio,
        transformY / devicePixelRatio
      )
    );
    previousWidth.current = width;
    previousHeight.current = height;
  });

  const onMouseMove = layout.current
    ? throttle(layout.current.mouseMove, 100, {
        leading: false,
        trailing: true,
      })
    : undefined;
  const legend = useRef<React.ElementRef<typeof RelationshipsViewLegend>>(null);
  const [clearedErrors, setClearedErrors] = useState(false);
  const [clearedHasClones, setClearedHasClones] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    ViewIds.RELATIONSHIPS_3,
    NEVER_SHOW_AGAIN
  );
  const legendHeightOffset = useViewLegendSubscription();
  const activeGroups = groups.add.concat(groups.update);

  const noComponents = !componentTypes.length;
  const isEmptyView = noComponents || noConnectedComponents;
  const isInView = !!referenceTypes.length;

  useEffect(() => {
    if (isEmptyView && isInView && layout.current) {
      layout.current.unsetLayout();
      zoom.current = null;
    }
    dispatchAction(layoutComplete({ needsFit: true }));
  }, [isEmptyView, isInView]);

  const zoomIn = () =>
    select(canvasRef.current!)
      .transition()
      .duration(500)
      .call(zoom.current!.scaleBy, BUTTON_CLICK_ZOOM_FACTOR);

  const zoomOut = () =>
    select(canvasRef.current!)
      .transition()
      .duration(500)
      .call(zoom.current!.scaleBy, BUTTON_CLICK_ZOOM_FACTOR ** -1);
  const onOptimizeLayout = layout.current?.optimizeLayout ?? noop;

  useEffect(() => {
    const viewContext = {
      getContainer: () => containerRef.current,
      getGraphComponent: returnNull,
      zoomIn,
      zoomOut,
      optimizeLayout: onOptimizeLayout,
      toggleLegend: () => {
        const isLegendActive = !legend.current?.getIsVisible();
        legend.current?.setIsVisible(isLegendActive);

        viewLegendCommands.updateViewLegendSettings({
          viewId: VIEW_ID,
          isActive: isLegendActive,
        });
        const newViewContext = {
          ...viewContext,
          isLegendActive,
        };

        dispatchAction(updateViewContext(newViewContext));
      },
      isLegendActive: Boolean(legend.current?.getIsVisible()),
      zoomControls: null,
      zoomControlsDisabledState,
    };
    dispatchAction(updateViewContext(viewContext));
  });
  return (
    <SettingsBarAndViewContainer
      data-canvas-hit-test={viewInstanceId}
      className={`tab-pane relationships3Tab active`}
      onContextMenu={e => e.preventDefault()}
    >
      {isViewpointMode ? null : (
        <div className="menuContainer">
          <RelationshipsViewSettingsBar
            activeGroups={activeGroups}
            getExportContainer={() => containerRef.current}
            traversedIncomingReferenceTypes={traversedIncomingReferenceTypes}
            traversedOutgoingReferenceTypes={traversedOutgoingReferenceTypes}
            referenceTypes={referenceTypes}
            addToPresentation={() => {
              // we want to store the last clicked node view setting in the presentation view settings, but we do not want it to persist in any way. e.g. it should be forgotten when you change the context.
              // so we update the view setting, save the presentation, and immediately clear the view setting.
              dispatchAction(
                updateViewSettings({
                  viewId: ViewIds.RELATIONSHIPS_3,
                  settings: {
                    lastClickedNodeId:
                      layout.current?.getLastClickedNode()?.id ?? null,
                  },
                  persistent: false,
                })
              );
              addToPresentation(ViewIds.RELATIONSHIPS_3);
              dispatchAction(
                updateViewSettings({
                  viewId: ViewIds.RELATIONSHIPS_3,
                  settings: {
                    lastClickedNodeId: null,
                  },
                  persistent: false,
                })
              );
            }}
            rootNode={rootNode}
            isEmptyView={isEmptyView}
            isViewpointMode={isViewpointMode}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            onOptimizeLayout={onOptimizeLayout}
          />
        </div>
      )}
      <ViewContainer ref={containerRef}>
        {isEmptyView ? (
          <EmptyState
            noConnectedComponents={noConnectedComponents}
            viewId={ViewIds.RELATIONSHIPS_3}
          />
        ) : (
          <>
            {!isViewpointMode && (
              <RelationshipsViewZoomControls
                key={viewInstanceId + currentTimestamp()} // date is appended to ensure uniqueness each time, so the zoom controls will be re-rendered with a fresh state and the new properties.
                disabledState={zoomControlsDisabledState}
                onZoomFit={() => {
                  if (!rootNode) {
                    return;
                  }
                  fitNode(
                    canvasRef.current!,
                    rootNode,
                    zoom.current!,
                    viewInstanceId
                  );
                }}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                onOptimizeLayout={onOptimizeLayout}
              />
            )}
            <RelationshipsViewCanvas
              ref={canvasRef}
              onClick={layout.current?.click ?? undefined}
              onDoubleClick={layout.current?.doubleClick ?? undefined}
              onMouseMove={onMouseMove}
              onMouseOut={() => {
                onMouseMove?.cancel();
                layout.current?.mouseOut();
              }}
              onContextMenu={event => layout.current?.contextMenu(event)}
            />
            <RelationshipsViewDecorationsSvg
              viewInstanceId={viewInstanceId}
              disconnectedChildren={disconnectedChildren}
              isViewpointMode={isViewpointMode}
              hasAdaptive={hasFeature(Features.ADAPTIVE_RELATIONSHIPSVIEW)}
            />
            <ErrorInfoBox
              errors={clearedErrors ? [] : errors}
              hasClones={!neverShowAgain && !clearedHasClones && hasClones}
              clearHasClones={() => setClearedHasClones(true)}
              clearErrors={() => setClearedErrors(true)}
              isShowNeverAgainSet={neverShowAgain}
              isPresentationMode={isPresentationMode()}
              toggleNeverShowAgain={toggleNeverShowAgain}
            />
            <RelationshipsViewLegend
              initiallyVisible={
                viewSettings[viewSettingsConsts.IS_LEGEND_ACTIVE]
              }
              ref={legend}
              componentTypes={componentTypes}
              referenceTypes={referenceModelTypes}
              hasCollapsedNodes={false}
              hasNonComponentNodes={false}
              hasReferenceParents={false}
              isUserDefinedGrouping={false}
              showComponentSwatches={true}
              showReferenceConditionalFormatting={true}
              showComponentShapes={false}
              showReferencesAsLines={true}
              activeDiffMode={null}
              heightOffset={legendHeightOffset}
              selectableItems={true}
            />
          </>
        )}
      </ViewContainer>
    </SettingsBarAndViewContainer>
  );
};

const PerformanceTrackedRelationshipsView = (
  props: RelationshipsViewProperties
) =>
  WithPerformanceTracking('hierarchical relationships view render', 5000, {
    WrappedComponent: RelationshipsView,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: {
      linkCount: props.links.length,
    },
  });

const RelationshipsViewWithLoadingIndicator = (
  props: RelationshipsViewProperties
) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedRelationshipsView,
    wrappedProps: props,
    showContentWhileLoading: true,
  });
export default connectInstance(
  RelationshipsViewWithLoadingIndicator,
  getViewModel$()
);
