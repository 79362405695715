import { createElement } from 'react';
import {
  Class,
  EdgeStyleBase,
  IEdge,
  IInputModeContext,
  IObstacleProvider,
  IRenderContext,
  Point,
  SmoothingPolicy,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { DiffType } from '@ardoq/data-model';
import {
  createPath,
  createPathWithBridges,
  edgeModelIds,
} from 'yfilesExtensions/edgeUtil';
import BasicEdgeObstacleProvider from 'yfilesExtensions/BasicEdgeObstacleProvider';
import SparkMD5 from 'spark-md5';
import { DATA_RENDER_HASH } from './consts';
import {
  GraphEdge,
  ParentChildGraphEdge,
  ensureRoot,
  disposeCallback,
} from '@ardoq/graph';
import { Edge as OldGraphEdge } from 'graph/edge';
import ArdoqEdge from './ArdoqEdge';
import { EDGE_SMOOTHING_LENGTH } from 'tabview/relationshipDiagrams/consts';
import { createSvgElement } from '@ardoq/dom-utils';

class ArdoqEdgeStyle extends EdgeStyleBase {
  private enableBridges: boolean;
  constructor({ enableBridges = true } = {}) {
    super();
    this.enableBridges = enableBridges;
  }

  createVisual(context: IRenderContext, edge: IEdge) {
    const g = createSvgElement('g');
    const hash = this.getHash(context, edge);
    this.render(context, edge, g, hash);
    const result = new SvgVisual(g);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }

  updateVisual(context: IRenderContext, oldVisual: Visual, edge: IEdge) {
    const container = (oldVisual as SvgVisual).svgElement;
    const oldHash = container.getAttribute(DATA_RENDER_HASH);
    const newHash = this.getHash(context, edge);

    if (oldHash && oldHash === newHash) {
      return oldVisual;
    }

    this.render(context, edge, container, newHash);
    return oldVisual;
  }

  isHit(canvasContext: IInputModeContext, location: Point, edge: IEdge) {
    const hitTestRadius = 13; // the hover path is 25px wide, set in CSS.  if the hover path is showing, this should be a hit.
    return this.getPath(edge).pathContains(location, hitTestRadius);
  }

  getHash(context: IRenderContext, edge: IEdge) {
    const hoverPath = this.getPath(edge);
    const path = createPath(edge, false);

    return SparkMD5.hash(
      [
        edge.tag.getLineBeginning(),
        edge.tag.getLineEnding(),
        edge.tag.getCSS(),
        edge.tag.isTransparentized,
        hoverPath,
        path,
        edge.tag.getVisualDiffType(),
        String(edge.targetNode?.tag?.isGhost || edge.sourceNode?.tag?.isGhost),
      ].join('-')
    );
  }

  render(
    context: IRenderContext,
    edge: IEdge,
    container: SVGElement,
    hash: string
  ) {
    container.setAttribute(DATA_RENDER_HASH, hash);

    const isGhostReference =
      edge?.targetNode?.tag?.isGhost || edge?.sourceNode?.tag?.isGhost;

    const graphEdge = edge.tag as GraphEdge | OldGraphEdge;

    const { x: sourcePortX, y: sourcePortY } = edge.sourcePort!.location;
    const hoverPath = this.getPath(edge);
    const path = createPath(edge, false);
    const lineBeginning = graphEdge.getLineBeginning();
    const lineEnding = graphEdge.getLineEnding();
    const className = graphEdge.getCSS();
    const isParentChildReference = graphEdge instanceof ParentChildGraphEdge;

    const hoverPathData = hoverPath
      .createSmoothedPath({
        smoothingLength: EDGE_SMOOTHING_LENGTH,
        smoothingPolicy: SmoothingPolicy.SYMMETRIC,
      })
      .createSvgPathData();
    const pathData = this.enableBridges
      ? createPathWithBridges(path, context)
          .createSmoothedPath({
            smoothingLength: EDGE_SMOOTHING_LENGTH,
            smoothingPolicy: SmoothingPolicy.SYMMETRIC,
          })
          .createSvgPathData()
      : hoverPathData;

    const modelIds = edgeModelIds(edge);
    container.setAttribute('data-reference-model-ids', modelIds.join(','));
    container.classList.add('integration');
    ensureRoot(container).render(
      createElement(ArdoqEdge, {
        pathData,
        hoverPathData,
        lineBeginning,
        lineEnding,
        className,
        diffType: isGhostReference
          ? DiffType.UNCHANGED
          : graphEdge?.getVisualDiffType(),
        isParentChildReference,
        isGroup: graphEdge.isGroup(),
        sourcePortX,
        sourcePortY,
        isTransparentized: graphEdge.isTransparentized,
      })
    );
  }

  override getPath(edge: IEdge) {
    return createPath(edge, true);
  }

  lookup(edge: IEdge, type: Class) {
    if (type === IObstacleProvider.$class) {
      return new BasicEdgeObstacleProvider(edge);
    }
    return super.lookup.call(this, edge, type);
  }
}

export default ArdoqEdgeStyle;
