import { useEffect, useRef, useState } from 'react';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { SettingsBar } from '@ardoq/settings-bar';
import { isPresentationMode } from 'appConfig';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import {
  DependencyWheel,
  DependencyWheelViewModel,
  DependencyWheelViewSettings,
  NotificationWrapper,
  ViewContainer,
  useHighlightState,
} from '@ardoq/dependency-wheel';
import { getViewModel$ } from '../viewModel$/viewModel$';
import {
  NO_EXPORT_CLASS_NAME,
  REFERENCE_ID_ATTRIBUTE,
  COMPONENT_ID_ATTRIBUTE,
} from '@ardoq/global-consts';
import {
  ExportableViewContentContainer,
  ViewLegendContainer,
} from '@ardoq/graph';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
  getComponentTypesForLegend,
} from '@ardoq/view-legend';
import Zoomable from 'atomicComponents/Zoomable/Zoomable';
import { WarningNotification } from '@ardoq/status-ui';
import { zoomCenter } from 'atomicComponents/Zoomable/actions';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { selectComponent } from 'streams/components/ComponentActions';
import { getExportsForHtmlView } from '@ardoq/export';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import { selectReference } from 'streams/references/ReferenceActions';
import { useResizeObserver } from '@ardoq/hooks';
import { getWarningMessage } from '../utils/getWarningMessage';
import EmptyState from './EmptyState';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

type DependencyWheelViewProps = DependencyWheelViewModel;

const VIEW_ID = ViewIds.DEPWHEEL;

const viewSettings$ =
  getViewSettingsStream<DependencyWheelViewSettings>(VIEW_ID);

const centerDependencyWheelInView = () =>
  dispatchAction(zoomCenter({ viewId: VIEW_ID, duration: 0 }));

const ZOOM_CONTROLS_HEIGHT = 36;

const DependencyWheelView = ({
  viewSettings,
  references,
  components,
  totalComponentsCount,
  referenceTypesForLegend,
  isComponentsCountLimitExceeded,
  selectedComponentId,
  isExploreMode,
}: DependencyWheelViewProps) => {
  const { isLegendActive } = viewSettings;

  const containerRef = useRef<HTMLDivElement>(null);
  const heightOffset = useViewLegendSubscription();

  useEffect(centerDependencyWheelInView, [selectedComponentId]);

  const [size, setSize] = useState({
    width: containerRef.current?.offsetWidth ?? 0,
    height: containerRef.current?.offsetWidth ?? 0,
  });
  const { width, height } = useResizeObserver(
    containerRef,
    ({ width: newWidth, height: newHeight }) => {
      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
      centerDependencyWheelInView();
    }
  );

  const setComponentInContext = (componentId: ArdoqId) => {
    dispatchAction(selectComponent({ cid: componentId }));
  };

  const setReferenceInContextPath = (referenceId: ArdoqId) => {
    dispatchAction(selectReference({ cid: referenceId }));
  };

  const {
    highlightedComponentId,
    highlightedReferenceId,
    highlightedBy,
    highlightComponent,
    highlightReference,
    onCancelHighlight,
  } = useHighlightState(selectedComponentId);

  const warningMessage = getWarningMessage(
    totalComponentsCount,
    isExploreMode,
    isPresentationMode()
  );

  const isEmptyView = !components.length;

  return (
    <>
      {!isPresentationMode() && (
        <SettingsBar
          viewId={VIEW_ID}
          rightMenu={getRightMenuConfig({
            viewId: VIEW_ID,
            withLegend: true,
            legendOnClick: () => {
              onViewSettingsUpdate(
                VIEW_ID,
                {
                  ...viewSettings,
                  isLegendActive: !viewSettings.isLegendActive,
                },
                true
              );
            },
            viewstate: viewSettings,
            exports: {
              ...getExportsForHtmlView({
                container: () => containerRef.current,
                exportedViewMetadata: {
                  name: VIEW_ID,
                },
                ...getSharedExportFunctions(),
              }),
              isDisabled: isEmptyView,
            },
            knowledgeBaseLink: KnowledgeBaseLink.DEPENDENCY_WHEEL,
            onViewSettingsUpdate,
          })}
          leftMenu={[]}
        />
      )}

      {isEmptyView ? (
        <EmptyState noConnectedComponents={!components.length} />
      ) : (
        <ViewContainer ref={containerRef} onClick={onCancelHighlight}>
          {isComponentsCountLimitExceeded && (
            <NotificationWrapper className={NO_EXPORT_CLASS_NAME}>
              <WarningNotification>{warningMessage}</WarningNotification>
            </NotificationWrapper>
          )}

          <Zoomable
            viewId={VIEW_ID}
            innerContainerStyle={{ width: '100%', height: '100%' }}
          >
            <ExportableViewContentContainer
              data-context-menu={OBJECT_CONTEXT_MENU_NAME}
            >
              <DependencyWheel
                viewBounds={[0, 0, width ?? 0, height ?? 0]}
                components={components}
                references={references}
                componentIdInContext={selectedComponentId}
                highlightedComponentId={highlightedComponentId}
                highlightedReferenceId={highlightedReferenceId}
                highlightedBy={highlightedBy}
                onHighlight={highlightComponent}
                onCancelHighlight={onCancelHighlight}
                setComponentInContext={setComponentInContext}
                onSetReferenceInContextPath={setReferenceInContextPath}
                onReferenceClick={highlightReference}
                componentContextMenuAttr={COMPONENT_ID_ATTRIBUTE}
                referenceContextMenuAttr={REFERENCE_ID_ATTRIBUTE}
              />
            </ExportableViewContentContainer>
          </Zoomable>

          <ViewLegendContainer
            heightOffset={heightOffset}
            visible={isLegendActive}
            style={{ paddingTop: ZOOM_CONTROLS_HEIGHT }}
          >
            <ViewLegend
              activeDiffMode={null}
              componentTypes={getComponentTypesForLegend(
                components.map(component => component.id)
              )}
              referenceTypes={referenceTypesForLegend}
              activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
            />
          </ViewLegendContainer>
        </ViewContainer>
      )}
    </>
  );
};

export default connect(DependencyWheelView, getViewModel$(viewSettings$));
