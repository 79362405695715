import { showAppModule } from './actions';
import { AppModules, type PayloadShowAppModule } from './types';
import {
  action$,
  persistentReducedStream,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { notifyWorkspaceChanged } from 'streams/context/ContextActions';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { returnPayload } from '@ardoq/common-helpers';
import { trackEvent } from 'tracking/tracking';

type State = {
  selectedModule: AppModules;
  options: {
    forceLandingPage?: boolean;
  };
};

const handleShowAppModule = (
  _: State,
  { selectedModule, options = {} }: PayloadShowAppModule
) => ({
  selectedModule,
  options,
});

const handleNotifyWorkspaceChanged = () => ({
  selectedModule: AppModules.WORKSPACES,
  options: {},
});

const defaultState = {
  selectedModule: AppModules.HOME,
  options: {},
};

// We will soon get better RxBeach tools to create a persitent stream not
// just with some reducers, but also with some operators.
const _selectedModule$ = action$.pipe(
  reduceState('_selectedModule$', defaultState, [
    reducer(showAppModule, handleShowAppModule),
    reducer(notifyWorkspaceChanged, handleNotifyWorkspaceChanged),
  ]),
  distinctUntilChanged((previous, current) => isEqual(previous, current)),
  tap(({ selectedModule }) => {
    if (selectedModule === AppModules.HOME) {
      trackEvent('homepage_loaded');
    }
  })
);

const selectedModule$ = persistentReducedStream<PayloadShowAppModule>(
  'selectedModule$',
  defaultState,
  [streamReducer(_selectedModule$, returnPayload)]
);

export default selectedModule$;
