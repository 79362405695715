import { GraphItem } from 'graph/GraphItem';
import { useCallback, useEffect, useRef } from 'react';
import { context$ } from 'streams/context/context$';
import { connectInstance } from '@ardoq/rxbeach';
import {
  ArdoqGraphComponent,
  ArdoqGraphComponentForwardRefType,
  PerformanceTrackedGraphView,
  canBypassLimit,
  isAboveItemLimit,
} from 'tabview/graphComponent/ArdoqGraphComponent';
import { GraphItemsModel } from 'tabview/graphComponent/types';
import { getExportsForYFilesView, notifyExportFailure } from '@ardoq/export';
import { defaultLegendOnClick } from 'viewSettings/getRightMenuConfig';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { ViewIds } from '@ardoq/api-types';
import {
  EdgePathLabelModel,
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  GraphComponent,
  HierarchicLayoutConfig,
  HierarchicLayoutData,
  Insets,
  LayoutDescriptor,
  PortAdjustmentPolicy,
} from '@ardoq/yfiles';
import ArdoqLabelStyle from 'yfilesExtensions/styles/ArdoqLabelStyle';
import ArdoqEdgeStyle from 'yfilesExtensions/styles/ArdoqEdgeStyle';
import TableGraphBuilder from 'yfilesExtensions/TableGraphBuilder';
import { getViewModel$ } from './viewModel$';
import {
  SwimlaneViewModel,
  SwimlaneViewProperties,
  SwimlaneViewSettings,
} from './types';
import { onLayoutGraphFactory } from 'tabview/graphComponent/onLayoutGraph';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import ViewAndLegendContainer from 'tabview/ViewAndLegendContainer';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import SwimlaneSettingsBar from './SwimlaneSettingsBar';
import EmptyState from './EmptyState';
import { isEmptyView } from './utils';
import { simpleNotifier } from '@ardoq/dropdown-menu';

const VIEW_ID = ViewIds.SWIMLANE;

const configureRendering = (graphComponent: GraphComponent) => {
  graphComponent.maximumZoom = 1.6;
  graphComponent.minimumZoom = 0.01;
  graphComponent.mouseWheelZoomFactor = 1.1;
  graphComponent.graph.edgeDefaults.style = new ArdoqEdgeStyle();
  // Style and labels
  const labelModel = new ExteriorLabelModel();
  labelModel.insets = new Insets(15);
  graphComponent.graph.nodeDefaults.labels.layoutParameter =
    labelModel.createParameter(ExteriorLabelModelPosition.SOUTH);

  const labelStyle = ArdoqLabelStyle.Instance;
  graphComponent.graph.edgeDefaults.labels.style = labelStyle;
  graphComponent.graph.nodeDefaults.labels.style = labelStyle;

  const edgeLabelModel = new EdgePathLabelModel({
    autoRotation: false,
  });
  edgeLabelModel.distance = 5;
  graphComponent.graph.edgeDefaults.labels.layoutParameter =
    edgeLabelModel.createDefaultParameter();
};

const viewModelToArray = <TItem extends GraphItem>(
  viewModel: GraphItemsModel<TItem>
) => viewModel.add.map(id => viewModel.byId.get(id)!);

const SwimlaneView = (props: SwimlaneViewProperties) => {
  const ardoqGraphComponentRef =
    useRef<ArdoqGraphComponentForwardRefType>(null);
  const graphBuilder = useRef<TableGraphBuilder | null>(null);

  const getContainer = useCallback(
    () =>
      ardoqGraphComponentRef.current?.graphComponentContainer.current ?? null,
    [ardoqGraphComponentRef]
  );

  const getGraphComponent = useCallback(
    () => ardoqGraphComponentRef.current?.graphComponent ?? null,
    [ardoqGraphComponentRef]
  );

  const buildGraph = useCallback(
    (
      graphComponent: GraphComponent,
      viewModel: SwimlaneViewModel,
      bypassLimit: boolean,
      clearEdges: boolean,
      isVertical: boolean
    ) => {
      graphBuilder.current =
        graphBuilder.current ||
        new TableGraphBuilder({ graph: graphComponent.graph });

      graphBuilder.current.setVertical(isVertical);
      if (clearEdges) {
        graphBuilder.current.clearEdges();
      }
      const rows = viewModelToArray(viewModel.rows);
      const children = viewModelToArray(viewModel.nodes);
      const edges = viewModelToArray(viewModel.edges);

      const canBypass = canBypassLimit(
        rows.length + children.length,
        edges.length
      );
      const isEmpty = !rows.length;
      const hasLayoutUpdate = graphBuilder.current.hasLayoutUpdate({
        rows,
        nodes: children,
        edges,
      });
      const aboveLimit = isAboveItemLimit(
        rows.length + children.length,
        edges.length
      );
      if ((!bypassLimit && aboveLimit) || isEmpty) {
        graphBuilder.current.nodesSource = [];
        graphBuilder.current.edgesSource = [];
        graphBuilder.current.rowsSource = [];
        graphBuilder.current.clear();
      } else {
        const isReordered = graphBuilder.current.rowsSource.some((row, i) => {
          return (
            rows.findIndex(otherRow => otherRow.dataModel === row.dataModel) !==
            i
          );
        });
        graphBuilder.current.nodesSource = children;
        graphBuilder.current.edgesSource = edges;
        graphBuilder.current.rowsSource = rows;
        graphBuilder.current.updateGraph({ isReordered });
      }

      return {
        isAboveLimit: aboveLimit,
        isEmpty,
        canBypass,
        hasLayoutUpdate,
      };
    },
    [graphBuilder]
  );

  const configureLayout = useCallback(() => {
    const layoutConfig: HierarchicLayoutConfig = {
      orthogonalRouting: true,
      considerNodeLabels: true,
      backLoopRouting: false,
      integratedEdgeLabeling: true,
      recursiveGroupLayering: false,
      layoutOrientation: props.viewSettings.isVertical
        ? 'top-to-bottom'
        : 'left-to-right',
      fromScratchLayeringStrategy: 'hierarchical-optimal',
    };

    const layoutDescriptor: LayoutDescriptor = {
      name: 'HierarchicLayout',
      properties: layoutConfig,
    };
    return {
      layoutDescriptor,
      layoutData: new HierarchicLayoutData(),
      updateContentRect: true,
      portAdjustmentPolicy: PortAdjustmentPolicy.ALWAYS, // this causes the edges to respect NodeStyle.getOutline when deciding where to land on the node's outline.
    };
  }, [props.viewSettings]);

  useEffect(() => {
    if (!getGraphComponent()) {
      // clearing graphBuilder when no graphComponent available
      // required to redraw the graph when coming from an empty state
      graphBuilder.current = null;
    }
  });

  const {
    viewSettings,
    viewModel,
    zoomComponentId,
    preserveViewport,
    viewInstanceId,
  } = props;
  // let isLegendActive holds the "active" value of the legend and is temorarely detached from
  // the state due to getFilteredViewSettings$.
  // This value & the viewstate are synchronized as soon an update passes the filter.
  let isLegendActive = viewSettings.isLegendActive ?? false;

  const isEmpty = isEmptyView(viewModel);

  const openDegreesViewSetting = useRef(simpleNotifier());
  return (
    <div className={`tab-pane swimlane-view active`}>
      <div className="menuContainer">
        <SwimlaneSettingsBar
          legendOnClick={() => {
            isLegendActive = !isLegendActive;
            defaultLegendOnClick({
              isLegendActive: isLegendActive,
              viewId: VIEW_ID,
            });
          }}
          exports={{
            ...getExportsForYFilesView({
              container: getContainer,
              graphComponent: getGraphComponent()?.current ?? null,
              exportedViewMetadata: {
                name: VIEW_ID,
              },
              showExportFailureModal: notifyExportFailure,
              ...getSharedExportFunctions(),
            }),
            isDisabled: isEmpty,
          }}
          openDegreesViewSetting={openDegreesViewSetting.current}
        />
      </div>

      {isEmpty ? (
        <EmptyState
          noConnectedComponents={viewModel.noConnectedComponents}
          isZeroDegrees={
            !viewSettings.incomingDegreesOfRelationship &&
            !viewSettings.outgoingDegreesOfRelationship
          }
          openDegreesViewSetting={openDegreesViewSetting.current}
        />
      ) : (
        <ViewAndLegendContainer>
          <ArdoqGraphComponent
            ref={ardoqGraphComponentRef}
            viewId={VIEW_ID}
            viewModel={viewModel}
            isLegendActive={viewSettings.isLegendActive}
            buildGraph={(graphComponent, bypassLimit) =>
              buildGraph(
                graphComponent,
                viewModel,
                bypassLimit,
                props.clearEdges,
                props.viewSettings.isVertical
              )
            }
            enableStyles={true}
            configureLayout={configureLayout}
            configureRendering={configureRendering}
            onLayoutGraph={onLayoutGraphFactory({
              zoomComponentId,
              preserveViewport,
            })}
            useHoverDecorator={false}
            viewInstanceId={viewInstanceId}
          />
        </ViewAndLegendContainer>
      )}
    </div>
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<SwimlaneViewSettings>(VIEW_ID);

const PerformanceTrackedSwimlaneView = (props: SwimlaneViewProperties) =>
  PerformanceTrackedGraphView(SwimlaneView, props, VIEW_ID);

const SwimlaneViewWithLoadingIndicator = (props: SwimlaneViewProperties) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedSwimlaneView,
    wrappedProps: props,
    showContentWhileLoading: false,
  });
export default connectInstance(
  SwimlaneViewWithLoadingIndicator,
  getViewModel$(viewSettings$, context$)
);
