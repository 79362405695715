import { map } from 'rxjs/operators';
import { ObservableState } from '@ardoq/rxbeach';
import { Observable, combineLatest } from 'rxjs';
import { ByIdItem } from '../types';

type PrepareDataSource = {
  flattenTree: Record<string, string[]>;
  collapsed: Record<string, boolean>;
  ids: string[];
  depth?: number;
};

export type HierarchicalDataSource = {
  id: string;
  depth: number;
  isCollapsed: boolean;
  hasChildren: boolean;
};

const composeDataSource = ({
  flattenTree,
  collapsed,
  ids,
  depth = 0,
}: PrepareDataSource): HierarchicalDataSource[] => {
  return ids.reduce<HierarchicalDataSource[]>((acc, id) => {
    const isCollapsed = !collapsed[id];
    const childrenIds = flattenTree[id];
    const hasChildren = childrenIds && childrenIds.length > 0;

    const data = { id, depth, isCollapsed, hasChildren };
    const dataSource = [...acc, data];

    if (hasChildren && !isCollapsed) {
      return [
        ...dataSource,
        ...composeDataSource({
          flattenTree,
          collapsed,
          ids: childrenIds,
          depth: depth + 1,
        }),
      ];
    }
    return dataSource;
  }, []);
};

type FlattenTree$<Data> = Observable<{
  flattenTree: Record<string, string[]>;
  rootIds: string[];
  byId: Record<string, Data>;
}>;
type Collapsed$ = ObservableState<Record<string, boolean>>;

export const withHierarchical$ = <Data extends ByIdItem>(
  flattenTree$: FlattenTree$<Data>,
  collapsed$: Collapsed$
) =>
  combineLatest([flattenTree$, collapsed$]).pipe(
    map(([{ flattenTree, rootIds, byId }, collapsed]) => ({
      dataSource: composeDataSource({
        flattenTree,
        collapsed,
        ids: rootIds,
      }),
      byId,
    }))
  );
