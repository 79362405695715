import { componentInterface } from '@ardoq/component-interface';
import { HORIZONTAL_ELLIPSIS } from '@ardoq/global-consts';
import {
  getComponentLabelParts,
  truncateComponentLabel,
  truncateSvgText,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
} from '@ardoq/graph';
import { referenceInterface } from '@ardoq/reference-interface';
import {
  getReferenceLabelFormattingFieldLabel,
  getReferenceLabelFormattingFieldValue,
} from 'modelInterface/util';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
} from 'yfilesExtensions/styles/consts';
import {
  BLOCK_DIAGRAM_GROUP_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT,
} from '../../consts';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';
import { createFifoCache } from '@ardoq/common-helpers';
import { MeasureStyledSvgText } from '@ardoq/dom-utils';
import { Font, TextRenderSupport } from '@ardoq/yfiles';
import { ArdoqId } from '@ardoq/api-types';

const MAX_NUMBER_OF_TSPANS_IN_NODE_LABEL = 5;
const MAX_NUMBER_OF_TSPANS_IN_EDGE_LABEL = 5;

// yfiles label tspan height is 1.5em
// legacy node font size is 32 px
export const LEGACY_FORMATTING_VALUE_HEIGHT_NODE = 32 * 1.5;

const EDGE_ELLIPSIS_WIDTH = measureLabelElement(
  HORIZONTAL_ELLIPSIS,
  BLOCK_DIAGRAM_EDGE_LABEL_FONT
).width;

const NODE_ELLIPSIS_WIDTH = measureLabelElement(
  HORIZONTAL_ELLIPSIS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT
).width;

const EDGE_COLON_WIDTH = measureLabelElement(
  ':',
  BLOCK_DIAGRAM_EDGE_LABEL_FONT
).width;

const NODE_COLON_WIDTH = measureLabelElement(
  ':',
  BLOCK_DIAGRAM_NODE_LABEL_FONT
).width;

export const addFieldLabelAndValue = (
  textElement: SVGTextElement,
  modelId: ArdoqId
) => {
  const isReference = referenceInterface.isReference(modelId);
  const isComponent = componentInterface.isComponent(modelId);

  const formattingValue = isReference
    ? getReferenceLabelFormattingFieldLabel()
    : isComponent
      ? componentInterface.getFieldLabelAndValue(modelId, true)
      : null;

  if (!formattingValue) {
    return;
  }

  const tspanElements = textElement.getElementsByTagName('tspan');
  const tspanCount = tspanElements.length;

  if (isComponent) {
    // Max number of tspans displayed with the given width, height and font
    if (tspanCount < MAX_NUMBER_OF_TSPANS_IN_NODE_LABEL) {
      return;
    }

    const nameHeight = getTextHeightWithinBlock(
      componentInterface.getDisplayName(modelId) || '',
      BLOCK_DIAGRAM_NODE_LABEL_FONT,
      false
    );

    if (nameHeight < ONE_FEWER_THAN_MAX_LINES_TEXT_HEIGHT_NODE) {
      return;
    }

    // replace last line with truncated field label and value
    const lastTspan = tspanElements[MAX_NUMBER_OF_TSPANS_IN_NODE_LABEL - 1];
    const { fieldLabel, fieldValue } = getComponentLabelParts(modelId);

    if (!fieldLabel || !fieldValue) {
      return;
    }

    lastTspan.textContent = truncateComponentLabel({
      fieldLabel,
      fieldValue,
      label: null,
      width: BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width,
      measure: measureNodeLabelWidth,
    });

    if (nameHeight === ONE_FEWER_THAN_MAX_LINES_TEXT_HEIGHT_NODE) {
      return;
    }

    const beforeLastSpan =
      tspanElements[MAX_NUMBER_OF_TSPANS_IN_NODE_LABEL - 2];
    addEllipsisToTspan(beforeLastSpan, true);
  } else if (isReference) {
    // Max number of tspans displayed with the given width, height and font
    if (tspanCount < MAX_NUMBER_OF_TSPANS_IN_EDGE_LABEL) {
      return;
    }

    const fieldLabelHeight = getTextHeightWithinBlock(
      formattingValue,
      BLOCK_DIAGRAM_EDGE_LABEL_FONT,
      false
    );

    if (fieldLabelHeight <= ONE_FEWER_THAN_MAX_LINES_TEXT_HEIGHT_EDGE) {
      return;
    }

    // replace last line with truncated field value
    const lastTspan = tspanElements[MAX_NUMBER_OF_TSPANS_IN_EDGE_LABEL - 1];
    const fieldValueActive = getReferenceLabelFormattingFieldValue(modelId);

    if (!fieldValueActive) {
      return;
    }

    lastTspan.textContent = `${truncateSvgText(
      fieldValueActive || '',
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width,
      BLOCK_DIAGRAM_EDGE_LABEL_FONT
    )}`;

    // add ellipsis to fourth line text to show that there's more
    const fourthTspan = tspanElements[MAX_NUMBER_OF_TSPANS_IN_EDGE_LABEL - 2];
    addEllipsisToTspan(fourthTspan, false, true);
  }
};

export const measureNodeLabelWidth = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize}px`,
    })
);
export const measureNodeLabelWidthModernized = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize}px`,
    })
);
export const measureExpandedGroupNodeLabelWidth = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${BLOCK_DIAGRAM_GROUP_LABEL_FONT.fontSize}px`,
    })
);
export const measureExpandedGroupNodeLabelWidthModernized = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${MODERNIZED_BLOCK_DIAGRAM_GROUP_LABEL_FONT.fontSize}px`,
    })
);

const measureEdgeLabelWidth = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (text: string) =>
    MeasureStyledSvgText.Instance.getTextWidth({
      text,
      fontSize: `${BLOCK_DIAGRAM_EDGE_LABEL_FONT.fontSize}px`,
    })
);

const getTextHeightFromCache = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (key: string) => {
    const { text, font } = JSON.parse(key);
    return TextRenderSupport.measureText({
      text: text,
      font: new Font(font),
      ...BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT,
    });
  }
);
const getTextHeightFromCacheModernized = createFifoCache(
  GRAPH_ITEM_LABEL_CACHE_SIZE,
  (key: string) => {
    const { text, font } = JSON.parse(key);
    return TextRenderSupport.measureText({
      text: text,
      font: new Font(font),
      ...MODERNIZED_BLOCK_DIAGRAM_LABEL_TEXT_PARAMS_INFINITE_HEIGHT,
    });
  }
);

export const getTextHeightWithinBlock = (
  text: string,
  font: Font,
  isModern: boolean
) => {
  if (!text) {
    return 0;
  }

  const cacheKey = JSON.stringify({
    text,
    font: {
      fontFamily: font.fontFamily,
      fontSize: font.fontSize,
      fontWeight: font.fontWeight,
    },
  });
  const { height } = isModern
    ? getTextHeightFromCacheModernized(cacheKey)
    : getTextHeightFromCache(cacheKey);

  return height!;
};

const ONE_FEWER_THAN_MAX_LINES_TEXT_HEIGHT_NODE = getTextHeightWithinBlock(
  Array(MAX_NUMBER_OF_TSPANS_IN_NODE_LABEL - 2)
    .fill('1\r\n')
    .join(''),
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  false
);

const ONE_FEWER_THAN_MAX_LINES_TEXT_HEIGHT_EDGE = getTextHeightWithinBlock(
  Array(MAX_NUMBER_OF_TSPANS_IN_EDGE_LABEL - 2)
    .fill('1\r\n')
    .join(''),
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  false
);

const addEllipsisToTspan = (
  tspan: SVGTSpanElement,
  isNode: boolean,
  withColon?: boolean
) => {
  const tspanText = tspan.textContent;
  const measureWidth = isNode ? measureNodeLabelWidth : measureEdgeLabelWidth;

  const tspanWidth = measureWidth(tspanText || '');

  const ellipsisWidth = isNode ? NODE_ELLIPSIS_WIDTH : EDGE_ELLIPSIS_WIDTH;
  const colonWidth = isNode ? NODE_COLON_WIDTH : EDGE_COLON_WIDTH;

  const horizontalEllipsisPlace = withColon
    ? tspanText?.substring(tspanText.length - 3, tspanText.length - 2)
    : tspanText?.substring(tspanText.length - 1);
  const isEllipsisPresent = horizontalEllipsisPlace === HORIZONTAL_ELLIPSIS;

  if (tspanText && !isEllipsisPresent) {
    if (withColon) {
      tspan.textContent =
        (tspanWidth >
        BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width -
          ellipsisWidth -
          colonWidth
          ? tspanText?.substring(0, tspanText.length - 3)
          : tspanText) || '';
      tspan.textContent += `${HORIZONTAL_ELLIPSIS} :`;
      return;
    }

    tspan.textContent =
      tspanWidth >
      BLOCK_DIAGRAM_LABEL_TEXT_PARAMS.maximumSize.width - ellipsisWidth
        ? tspanText?.substring(0, tspanText.length - 1) + HORIZONTAL_ELLIPSIS
        : tspanText + HORIZONTAL_ELLIPSIS;
  }
};
