import GraphCollection from 'graph/GraphCollection';
import { Node } from './types';
function flattenNodesIntoArray(
  nodes: Node[],
  array: Node[],
  includedNodes = new Set<string>()
) {
  nodes.forEach(node => {
    if (!includedNodes.has(node.id)) {
      array.push(node);
      flattenNodesIntoArray([...node.children], array, includedNodes);
      includedNodes.add(node.id);
    }
  });
  return array;
}

export default class NodeCollection extends GraphCollection<Node> {
  flatten() {
    return flattenNodesIntoArray(Array.from(this.values()), []);
  }
}
