import { Rectangle, Vector } from '@ardoq/graph';
import {
  BLOCKS_VIEW_HANDLE_FILLCOLOR,
  BLOCKS_VIEW_HANDLE_RENDERSIZE,
  BLOCKS_VIEW_HANDLE_GRABSIZE,
  BLOCKS_VIEW_HANDLE_COLOR,
} from '../consts';
import { CanvasRenderingContext } from './visual';

import { DragOperation } from '../types';
import { rectContains, rectHeight, rectWidth } from '../misc/geometry';

export const renderHandles = (rc: Rectangle, ctx: CanvasRenderingContext) => {
  const sz = (0.5 * BLOCKS_VIEW_HANDLE_RENDERSIZE) / ctx.getTransform().a;
  const lx = Math.round(rc[0]);
  const cx = Math.round((rc[0] + rc[2]) / 2);
  const rx = Math.round(rc[2]);
  const ty = Math.round(rc[1]);
  const cy = Math.round((rc[1] + rc[3]) / 2);
  const by = Math.round(rc[3]);

  const drawHandle = (cx: number, cy: number) => {
    ctx.fillRect(cx - sz, cy - sz, 2 * sz, 2 * sz);
    ctx.strokeRect(cx - sz, cy - sz, 2 * sz, 2 * sz);
  };

  ctx.globalAlpha = 1;
  ctx.fillStyle = BLOCKS_VIEW_HANDLE_FILLCOLOR;
  ctx.strokeStyle = BLOCKS_VIEW_HANDLE_COLOR;
  ctx.lineWidth = 1 / ctx.getTransform().a;
  ctx.strokeRect(rc[0], rc[1], rectWidth(rc), rectHeight(rc));
  drawHandle(lx, ty);
  drawHandle(cx, ty);
  drawHandle(rx, ty);
  drawHandle(lx, cy);
  drawHandle(rx, cy);
  drawHandle(lx, by);
  drawHandle(cx, by);
  drawHandle(rx, by);
};

export const hitTestHandles = (
  rc: Rectangle,
  pos: Vector,
  ctx: CanvasRenderingContext
) => {
  const sz = BLOCKS_VIEW_HANDLE_GRABSIZE / ctx.getTransform().a - 4;
  const lx = Math.round(rc[0] - sz / 2);
  const cx = Math.round((rc[0] + rc[2]) / 2 - sz / 2);
  const rx = Math.round(rc[2] - sz / 2);
  const ty = Math.round(rc[1] - sz / 2);
  const cy = Math.round((rc[1] + rc[3]) / 2 - sz / 2);
  const by = Math.round(rc[3] - sz / 2);

  if (rectContains([lx, cy, lx + sz, cy + sz], pos)) {
    return DragOperation.Left;
  }
  if (rectContains([lx, ty, lx + sz, ty + sz], pos)) {
    return DragOperation.LeftTop;
  }
  if (rectContains([cx, ty, cx + sz, ty + sz], pos)) {
    return DragOperation.Top;
  }
  if (rectContains([rx, ty, rx + sz, ty + sz], pos)) {
    return DragOperation.RightTop;
  }
  if (rectContains([rx, cy, rx + sz, cy + sz], pos)) {
    return DragOperation.Right;
  }
  if (rectContains([rx, by, rx + sz, by + sz], pos)) {
    return DragOperation.RightBottom;
  }
  if (rectContains([cx, by, cx + sz, by + sz], pos)) {
    return DragOperation.Bottom;
  }
  if (rectContains([lx, by, lx + sz, by + sz], pos)) {
    return DragOperation.LeftBottom;
  }

  return DragOperation.None;
};
