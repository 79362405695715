import { Font, FontWeight } from '@ardoq/yfiles';

const fontFamily = 'Helvetica';
const fontWeight = FontWeight.ITEM300;
export const nodeLabelFont = new Font({
  fontFamily,
  fontSize: 24,
  fontWeight,
});
export const edgeLabelFont = new Font({
  fontFamily,
  fontSize: 16,
  fontWeight,
});

export const showLabels = (zoom: number) => zoom * nodeLabelFont.fontSize > 10; // for labels to be shown, they should be more than 10px high on the screen
