import { IconName } from '@ardoq/icons';
import { isPresentationMode } from 'appConfig';
import ZoomControls, {
  ZoomControl,
} from 'atomicComponents/Zoomable/ZoomControls';
import { ZoomContainer } from 'tabview/relationshipDiagrams/atoms';
import { HIGHLIGHT_DISCONNECTED_COMPONENTS } from '../consts';
import { RelationshipsViewSettings } from '../types';
import { combineLatest, map } from 'rxjs';
import { notifyHighlightDisconnectedComponentsChanged } from '../RelationshipsViewSettingsBar';
import {
  unfilteredRelationshipsViewSettings$,
  isOptimizeLayoutDisabled$,
} from '../viewModel$/viewModel$';
import { connect } from '@ardoq/rxbeach';
import { DisabledZoomControls } from '@ardoq/view-settings';

type RelationshipsViewZoomControlsProperties = {
  viewSettings: RelationshipsViewSettings;
  zoomIn: VoidFunction;
  zoomOut: VoidFunction;
  onZoomFit: VoidFunction;
  onOptimizeLayout: VoidFunction;
  isOptimizeLayoutDisabled: boolean;
  disabledState: DisabledZoomControls;
};
const RelationshipsViewZoomControls = ({
  viewSettings,
  zoomIn,
  zoomOut,
  onZoomFit,
  onOptimizeLayout,
  isOptimizeLayoutDisabled,
  disabledState,
}: RelationshipsViewZoomControlsProperties) => {
  const { highlightDisconnectedComponents } = viewSettings;
  return (
    <ZoomContainer>
      {isPresentationMode() && (
        <ZoomControl
          label={HIGHLIGHT_DISCONNECTED_COMPONENTS}
          iconName={IconName.BULLSEYE}
          isActive={highlightDisconnectedComponents}
          onClick={() => {
            notifyHighlightDisconnectedComponentsChanged({
              highlightDisconnectedComponents,
              persistent: false,
            });
          }}
        />
      )}
      <ZoomControls
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomCenter={onZoomFit}
        disabledState={disabledState}
      />
      <ZoomControl
        label="Optimize layout"
        iconName={IconName.OPTIMIZE_LAYOUT}
        onClick={onOptimizeLayout}
        disabled={isOptimizeLayoutDisabled}
      />
    </ZoomContainer>
  );
};

const zoomControls$ = combineLatest([
  unfilteredRelationshipsViewSettings$,
  isOptimizeLayoutDisabled$,
]).pipe(
  map(([{ currentSettings }, isOptimizeLayoutDisabled]) => ({
    viewSettings: currentSettings,
    isOptimizeLayoutDisabled,
  }))
);

export default connect(RelationshipsViewZoomControls, zoomControls$);
