import { MouseEvent, useCallback } from 'react';
import { CheckboxCheckedIcon } from '@ardoq/icons';
import { CheckedWrapper, ReferenceCellElement } from '../atoms';
import { LinkedComponent } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import {
  deleteReferencesByIds,
  getDialogConfigByIds,
} from 'components/Dialogs/confirmDeletion/helpers';
import { isArdoqError, pluralize } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { DoqType } from '@ardoq/doq';
import { ConfirmResult, ModalSize, alert, confirm } from '@ardoq/modal';
import { logError } from '@ardoq/logging';
import { DependencyMatrixViewSettings } from '../types';
import { showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { dependencyMatrixCellClicked } from '../viewModel$/dependencyMatrixLastClickedCell$';
import { REFERENCE_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { v4 as uuidv4 } from 'uuid';
import { IMPLICIT_REFTYPE_ID } from 'models/model';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { INTEGRATION_CLASS } from 'contextMenus/consts';

const SESSION_KEY_DEPMATRIX_DELETE = '[dependency matrix] Delete reference';

const LockedReferenceDialogText = ({
  referenceNames,
}: {
  referenceNames: string[];
}) => (
  <div>
    <span>
      The following {pluralize('reference', referenceNames.length)} is locked:
    </span>
    <ul>
      {referenceNames.map((name: string, index) => (
        <li key={name + index}>{name}</li>
      ))}
    </ul>
  </div>
);

type ReferenceCellProps = {
  viewSettings: DependencyMatrixViewSettings;
  references: LinkedComponent[];
  sourceId: ArdoqId;
  targetId: ArdoqId;
  workspaceId: ArdoqId;
  readOnly: boolean;
  highlightAsLastClicked: boolean;
};

const ReferenceCell = (props: ReferenceCellProps) => {
  const {
    viewSettings,
    references,
    targetId,
    sourceId,
    readOnly,
    workspaceId,
    highlightAsLastClicked = false,
  } = props;

  const isValidSourceAndTargetComponent =
    componentInterface.isComponent(sourceId) &&
    componentInterface.isComponent(targetId);

  const referenceCellClick = useCallback(() => {
    // toggle cell highlight
    dispatchAction(
      dependencyMatrixCellClicked({
        sourceId,
        targetId,
        highlightAsLastClicked: !highlightAsLastClicked,
      })
    );
    if (!isValidSourceAndTargetComponent || readOnly) {
      return;
    }

    const creationTypeSettings = viewSettings.referenceCreationType;
    const currentReferenceCreationType = creationTypeSettings[workspaceId];

    if (currentReferenceCreationType !== undefined) {
      referenceInterface.createReference(
        sourceId,
        targetId,
        currentReferenceCreationType
      );
    } else {
      const newReferenceAttributes = {
        _id: uuidv4(),
        type: IMPLICIT_REFTYPE_ID,
        rootWorkspace: workspaceId,
        model: workspaceInterface.getWorkspaceModelId(workspaceId)!,
        source: sourceId,
        target: targetId,
      };
      dispatchAction(
        showRightPane({
          newReferenceAttributes: [newReferenceAttributes],
          type: GetContentOptionsType.CREATE_REFERENCE,
          referenceIds: [newReferenceAttributes._id],
        })
      );
    }
  }, [
    isValidSourceAndTargetComponent,
    readOnly,
    sourceId,
    targetId,
    viewSettings.referenceCreationType,
    highlightAsLastClicked,
    workspaceId,
  ]);

  const referenceClick = useCallback(
    async (event: MouseEvent, id: ArdoqId) => {
      event.stopPropagation();
      const isSuppressConfirmDialog =
        window.sessionStorage.getItem(SESSION_KEY_DEPMATRIX_DELETE) === 'true';
      const isValidReference = referenceInterface.isReference(id);
      const isReferenceLocked = referenceInterface.isLocked(id);

      // toggle cell highlight
      dispatchAction(
        dependencyMatrixCellClicked({
          sourceId,
          targetId,
          highlightAsLastClicked: !highlightAsLastClicked,
        })
      );

      if (isReferenceLocked) {
        await alert({
          title: 'Reference is locked',
          subtitle: 'Unlock the reference to be able to delete it.',
          text: (
            <LockedReferenceDialogText
              referenceNames={[referenceInterface.getRawName(id) || '']}
            />
          ),
          doqType: DoqType.LOCKED,
          modalSize: ModalSize.S,
        });

        return;
      }

      if (readOnly) return;

      if (!isSuppressConfirmDialog && isValidReference) {
        const { rememberAction, confirmed } = (await confirm({
          ...(await getDialogConfigByIds([], [id])),
          showRememberActionCheckbox: true,
          rememberActionCustomCheckboxText: `Don't ask again in this session.`,
          confirmButtonTitle: 'Delete',
          isConfirmButtonDanger: true,
          modalSize: ModalSize.S,
        })) as ConfirmResult;

        if (rememberAction) {
          window.sessionStorage.setItem(SESSION_KEY_DEPMATRIX_DELETE, 'true');
        }

        if (!confirmed) return;
      }

      if (isValidReference) {
        const result = await deleteReferencesByIds([id]);
        if (isArdoqError(result)) {
          alert({
            title: 'Could not delete reference',
            text:
              'An error occurred while deleting the reference. ' +
              'The error has been reported, but please contact support ' +
              'if the error persists.',
          });
          logError(result, 'dependencyMatrixDeleteReference error');
        }
      }
    },
    [readOnly, sourceId, targetId, highlightAsLastClicked]
  );

  return (
    <ReferenceCellElement
      $isValidReference={isValidSourceAndTargetComponent}
      onClick={referenceCellClick}
      $highlightAsLastClicked={highlightAsLastClicked}
    >
      {references.map(({ referenceId }) => (
        <CheckedWrapper
          key={referenceId}
          $readOnly={readOnly}
          {...{ [REFERENCE_ID_ATTRIBUTE]: referenceId }}
          onClick={event => referenceClick(event, referenceId)}
          className={INTEGRATION_CLASS}
        >
          <CheckboxCheckedIcon
            className={referenceInterface.getCssClassNames(referenceId) || ''}
            style={{ strokeDasharray: 'none' }}
          />
        </CheckedWrapper>
      ))}
    </ReferenceCellElement>
  );
};

export default ReferenceCell;
