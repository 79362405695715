import { BlockDiagramViewSettings } from '../types';
import {
  GraphComponent,
  HierarchicLayoutConfig,
  HierarchicLayoutData,
  HierarchicLayoutEdgeLayoutDescriptor,
  HierarchicLayoutEdgeRoutingStyle,
  HierarchicLayoutNodeLayoutDescriptor,
  HierarchicLayoutRoutingStyle,
  IIncrementalHintsFactory,
  ILabel,
  IModelItem,
  Insets,
  ItemMapping,
  LayerConstraintData,
  LayoutDescriptor,
  LayoutOrientation,
  NodeLabelMode,
  PortAdjustmentPolicy,
  PreferredPlacementDescriptor,
  TimeSpan,
} from '@ardoq/yfiles';
import {
  CollapsibleGraphGroup,
  GraphItem,
  Rect,
  inside,
  processDuplicateLabels,
  sourceGroupIds,
  targetGroupIds,
  type RelationshipDiagramViewModel,
  GRAPH_INSETS,
} from '@ardoq/graph';
import { WILDCARD } from '@ardoq/common-helpers';
import getCriticalPaths from './criticalPaths';
import { isContextNode } from 'yfilesExtensions/styles/nodeDecorator';
import { maximumMarkerWidth } from 'svg/definitions/consts';
import createSequenceConstraints from 'tabview/graphViews/layoutConstraints/createSequenceConstraints';
import maximumGraphLayoutDuration from 'tabview/relationshipDiagrams/maximumGraphLayoutDuration';

const ANIMATION_DURATION = 500;

const standardHierarchicLayoutOptions: HierarchicLayoutConfig = {
  considerNodeLabels: true,
  integratedEdgeLabeling: true,
  backLoopRouting: false,
  orthogonalRouting: true,
  minimumLayerDistance: 35,
  recursiveGroupLayering: true,
  compactGroups: false,
  componentLayoutEnabled: false,
  fromScratchLayeringStrategy: 'hierarchical-optimal',
};

const layoutOrientationString = (layoutOrientation: LayoutOrientation) => {
  switch (layoutOrientation) {
    case LayoutOrientation.TOP_TO_BOTTOM:
      return 'top-to-bottom';
    case LayoutOrientation.BOTTOM_TO_TOP:
      return 'bottom-to-top';
    case LayoutOrientation.LEFT_TO_RIGHT:
      return 'left-to-right';
    case LayoutOrientation.RIGHT_TO_LEFT:
      return 'right-to-left';
  }
};
const getLayoutInfo = (
  nodeCount: number,
  edgeCount: number,
  layoutOrientation: LayoutOrientation
) => {
  const layoutConfig: HierarchicLayoutConfig = {
    ...standardHierarchicLayoutOptions,
    maximumDuration: maximumGraphLayoutDuration(nodeCount, edgeCount),
    layoutOrientation: layoutOrientationString(layoutOrientation),
    nodeLayoutDescriptor: new HierarchicLayoutNodeLayoutDescriptor({
      nodeLabelMode: NodeLabelMode.CONSIDER_FOR_SELF_LOOPS,
    }),
    edgeLayoutDescriptor: new HierarchicLayoutEdgeLayoutDescriptor({
      routingStyle: new HierarchicLayoutRoutingStyle({
        routingStyle: HierarchicLayoutEdgeRoutingStyle.ORTHOGONAL,
      }),
      minimumFirstSegmentLength: maximumMarkerWidth,
      minimumLastSegmentLength: maximumMarkerWidth,
      minimumLength: 2 * maximumMarkerWidth,
    }),
  };
  const layoutDescriptor: LayoutDescriptor = {
    name: 'HierarchicLayout',
    properties: layoutConfig,
  };

  return layoutDescriptor;
};

const DEFAULT_PLACEMENT_DESCRIPTOR = new PreferredPlacementDescriptor();
const configureLayout = (
  graphComponent: GraphComponent,
  {
    nodes,
    groups,
    edges,
    expandedStateChangingGroupId,
  }: RelationshipDiagramViewModel,
  {
    layoutOrientation,
    sequenceConstraints,
    layerConstraints,
    separateReferences,
  }: BlockDiagramViewSettings
) => {
  const nodeCount = nodes.add.length + nodes.update.length;
  const edgeCount = edges.add.length + edges.update.length;

  const layoutDescriptor = getLayoutInfo(
    nodeCount,
    edgeCount,
    layoutOrientation
  );

  const isUpdate =
    nodes.update.length + groups.update.length + edges.update.length > 0;

  // when expanding or collapsing a group, avoid a distracting re-layout-- the graph layout should be stable, so the user's eye can follow the group as it grows or shrinks.
  // for other updates, especially toggling "separate references," a from-scratch layout is preferred, so that edge crossings can be re-evaluated given the newly grouped or ungrouped edges.
  layoutDescriptor.properties!.layoutMode = expandedStateChangingGroupId
    ? 'incremental'
    : 'from-scratch';

  const { graph, contentRect, viewport } = graphComponent;
  let layoutData: HierarchicLayoutData;

  if (!separateReferences) {
    layoutData = new HierarchicLayoutData({
      edgeDirectedness: 1,
      sourceGroupIds: sourceGroupIds(graph),
      targetGroupIds: targetGroupIds(graph),
    });

    const edgeLabelPreferredPlacement = processDuplicateLabels(graph);
    layoutData.edgeLabelPreferredPlacement.delegate = (label: ILabel) =>
      edgeLabelPreferredPlacement.get(label) ?? DEFAULT_PLACEMENT_DESCRIPTOR;
  } else {
    layoutData = new HierarchicLayoutData({ edgeDirectedness: 1 });
  }
  const expandingOrCollapsingAll = expandedStateChangingGroupId === WILDCARD;
  if (expandingOrCollapsingAll) {
    graph.nodes.forEach(node => {
      if (graph.isGroupNode(node)) {
        graph.adjustGroupNodeLayout(node);
      }
    });
  } else {
    const expandedStateChangingNode =
      expandedStateChangingGroupId &&
      graph.nodes.find(
        node =>
          node.tag instanceof CollapsibleGraphGroup &&
          node.tag.id === expandedStateChangingGroupId
      );
    if (expandedStateChangingNode) {
      graph.adjustGroupNodeLayout(expandedStateChangingNode);
      graph.groupingSupport
        .getDescendants(expandedStateChangingNode)
        .filter(node => graph.isGroupNode(node))
        .forEach(groupNode => graph.adjustGroupNodeLayout(groupNode));
    }
  }

  const wasFullGraphDisplayed = inside(
    Rect.fromRectLike(contentRect),
    Rect.fromRectLike(viewport)
  );
  const animateViewport =
    expandedStateChangingGroupId !== null &&
    (wasFullGraphDisplayed || expandingOrCollapsingAll);

  const layerConstraintMap = new Map(layerConstraints);
  const layerConstraintsMapping = new ItemMapping<IModelItem, number>(
    item => layerConstraintMap.get(item.tag.id) ?? 0
  );
  layoutData.sequenceConstraints = createSequenceConstraints(
    sequenceConstraints,
    graph
  );
  layoutData.layerConstraints = new LayerConstraintData({
    nodeComparables: layerConstraintsMapping,
  });

  const newIds = new Set([...nodes.add, ...groups.add, ...edges.add]);
  layoutData.incrementalHints.contextDelegate = (
    item: IModelItem,
    hintsFactory: IIncrementalHintsFactory
  ) => {
    const graphItem = item.tag;
    if (!(graphItem instanceof GraphItem)) {
      return null;
    }
    const isNew = newIds.has(graphItem.id);
    if (!isNew) {
      return null;
    }
    return graphItem.isGroup()
      ? hintsFactory.createIncrementalGroupHint(item)
      : graphItem.isComponent()
        ? hintsFactory.createLayerIncrementallyHint(item)
        : hintsFactory.createSequenceIncrementallyHint(item);
  };
  const criticalPaths = getCriticalPaths({
    graph,
    contextNode: graph.nodes.find(isContextNode),
  });
  if (criticalPaths.size) {
    layoutData.criticalEdgePriorities = edge => criticalPaths.get(edge) ?? null;
  }

  return {
    layoutDescriptor,
    layoutData,
    duration: isUpdate
      ? TimeSpan.fromMilliseconds(ANIMATION_DURATION)
      : TimeSpan.ZERO,
    animateViewport,
    updateContentRect: true,
    targetBoundsInsets: wasFullGraphDisplayed
      ? new Insets(GRAPH_INSETS)
      : Insets.EMPTY,
    portAdjustmentPolicy: PortAdjustmentPolicy.ALWAYS, // shorten or elongate edges according to the outline as defined by ArdoqNodeStyle/IsometricNodeStyle. this prevents the edges from appearing "on top of" the faces of the isometric blocks.
  };
};
export default configureLayout;
