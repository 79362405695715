import { ViewIds } from '@ardoq/api-types';
import { TableviewWrapper, setup } from './common/tableviewWrapper';
import { TableViewProps } from './common/types';
import { createViewModel$ } from './referenceReducers';

const ReferenceTableView = ({ viewState, viewModel }: TableViewProps) => (
  <TableviewWrapper
    viewId={ViewIds.REFERENCETABLE}
    name="ReferenceTableview"
    sortable
    viewState={viewState}
    viewModel={viewModel}
  />
);

export default setup(
  ViewIds.REFERENCETABLE,
  ReferenceTableView,
  createViewModel$
);
