import { useEffect, useRef, useState } from 'react';
import getViewModel$ from './viewModel$';
import DependencyMapSettingsBar from './view/DependencyMapSettingsBar';
import styled from 'styled-components';
import GraphViewLegend from 'tabview/graphViews/graphViewLegend/GraphViewLegend';
import {
  COLLAPSED_REFERENCE_ADDRESS_POPOVER_ID,
  DependencyMapVisualization,
  NodesContainer,
  URL_FIELD_VALUES_POPOVER_ID,
  INFINITE_DEGREES,
  collapsedReferenceAddressPopover,
  DependencyMapViewStreamedProps,
  dependencyMapCommands,
} from '@ardoq/dependency-map';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { ViewIds } from '@ardoq/api-types';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import { notifyViewLoading } from 'tabview/actions';
import { normal14 } from '@ardoq/typography';
import { popoverRegistry } from '@ardoq/popovers';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { useOnMount } from '@ardoq/hooks';
import recordViewScrollbarWidth from 'tabview/recordViewScrollbarWidth';
import { noop } from 'lodash';
import urlFieldValuesPopover from 'tabview/graphViews/urlFieldValuesPopover';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { addToPresentation as ardoqFrontAddToPresentation } from 'viewSettings/exportHandlers';
import { onViewSettingsUpdate as ardoqFrontOnViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { ViewLegendContainer } from '@ardoq/graph';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { useViewLegendSubscription } from 'views/viewLegend/useViewLegendSubscription';
import navigateToComponent from 'tabview/navigateToComponent';
import SettingsBarAndViewContainer from 'tabview/SettingsBarAndViewContainer';
import EmptyState from './EmptyState';
import { isPresentationMode } from 'appConfig';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { DependencyMapViewProps } from './types';
import { returnNull } from '@ardoq/common-helpers';
import { updateViewContext, DisabledZoomControls } from '@ardoq/view-settings';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { viewLegendCommands } from '@ardoq/view-legend';

const VIEW_ID = ViewIds.DEPENDENCY_MAP_2;

const Container = styled.div`
  ${normal14};
  line-height: inherit;
  font-size: inherit;
  position: relative;
  flex: 1;
  overflow: hidden;
  &.forExport {
    overflow: visible;
  }
`;

const DependencyMapView = ({
  viewSettings,
  viewModel,
  viewInstanceId,
  hoveredComponentId,
  focusedComponentId,
  onViewSettingsUpdate = noop,
  onComponentClick = noop,
  onComponentDoubleClick = noop,
  onViewLoading = noop,
  toggleNeverShowAgain = noop,
  neverShowAgain = false,
  addToPresentation,
  activeDiffMode,
  heightOffset,
  selectedComponentId = null,
  isViewpointMode,
}: DependencyMapViewProps) => {
  useEffect(() => {
    setTimeout(() => onViewLoading(false, viewInstanceId));
  });
  const {
    isLegendActive,
    showVertical,
    colorByReference,
    treeDepth,
    degreesOfParenthood,
  } = viewSettings;
  const {
    nodes,
    componentTypes,
    legendReferenceTypes,
    errors,
    hasClones,
    noConnectedComponents,
    nodeAddressMap,
    maxTreeDepth,
    referenceParentMaxDepth,
    hasNonComponentNodes,
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
  } = viewModel;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const nodesContainerRef = useRef<HTMLDivElement | null>(null);
  const [clearedErrors, setClearedErrors] = useState(false);
  const [clearedHasClones, setClearedHasClones] = useState(false);
  const isUserDefinedGrouping = groupingRuleInterface.getAll().length > 0;
  useEffect(() => {
    popoverRegistry.set(
      COLLAPSED_REFERENCE_ADDRESS_POPOVER_ID,
      collapsedReferenceAddressPopover({
        nodeAddressMap,
        isUserDefinedGrouping,
      })
    );
    popoverRegistry.set(
      URL_FIELD_VALUES_POPOVER_ID,
      urlFieldValuesPopover({
        urlFieldValuesByComponentId,
        urlFieldValuesByReferenceId,
      })
    );
    return () => {
      [
        COLLAPSED_REFERENCE_ADDRESS_POPOVER_ID,
        URL_FIELD_VALUES_POPOVER_ID,
      ].forEach(popoverId => popoverRegistry.delete(popoverId));
    };
  });

  useOnMount(() => {
    if (!containerRef.current || !nodesContainerRef.current) {
      return;
    }
    const observer = recordViewScrollbarWidth(
      containerRef.current,
      nodesContainerRef.current
    );
    return () => observer.disconnect();
  });

  const isEmptyView = !viewModel.nodes.length || noConnectedComponents;

  useEffect(() => {
    dispatchAction(
      updateViewContext({
        getContainer: () => containerRef.current,
        getGraphComponent: returnNull,
        optimizeLayout: null,
        zoomIn: null,
        zoomOut: null,
        toggleLegend: () =>
          viewLegendCommands.updateViewLegendSettings({
            viewId: VIEW_ID,
            isActive: !isLegendActive,
          }),
        isLegendActive: Boolean(isLegendActive),
        zoomControls: null,
        zoomControlsDisabledState: DisabledZoomControls.NONE,
      })
    );
  });

  const setHoveredComponentId = (itemId: string | null) => {
    dependencyMapCommands.setHoveredItemState(itemId, viewInstanceId);
  };

  return (
    <SettingsBarAndViewContainer>
      {isViewpointMode ? null : (
        <div className="menuContainer">
          <DependencyMapSettingsBar
            containerRef={containerRef}
            viewSettings={viewSettings}
            viewModel={viewModel}
            onViewSettingsUpdate={onViewSettingsUpdate}
            addToPresentation={addToPresentation}
            isEmptyView={isEmptyView}
          />
        </div>
      )}

      <Container
        ref={containerRef}
        style={{ display: noConnectedComponents ? 'flex' : undefined }}
        data-context-menu={OBJECT_CONTEXT_MENU_NAME}
      >
        {isEmptyView ? (
          <EmptyState noConnectedComponents={noConnectedComponents} />
        ) : (
          <NodesContainer
            ref={nodesContainerRef}
            $showVertical={showVertical}
            $noConnectedComponents={noConnectedComponents}
            onClick={() =>
              dependencyMapCommands.setFocusedItemState(null, viewInstanceId)
            }
          >
            <DependencyMapVisualization
              viewInstanceId={viewInstanceId}
              nodes={nodes}
              colorByReference={colorByReference}
              selectedComponentId={selectedComponentId}
              hoveredComponentId={hoveredComponentId}
              focusedComponentId={focusedComponentId}
              setHoveredComponentId={setHoveredComponentId}
              showVertical={showVertical}
              onComponentClick={onComponentClick}
              onComponentDoubleClick={onComponentDoubleClick}
              urlFieldValuesByComponentId={urlFieldValuesByComponentId}
              urlFieldValuesByReferenceId={urlFieldValuesByReferenceId}
              contextMenuDataAttribute={COMPONENT_ID_ATTRIBUTE}
            />
          </NodesContainer>
        )}
        <ErrorInfoBox
          errors={clearedErrors ? [] : errors}
          hasClones={!neverShowAgain && !clearedHasClones && hasClones}
          clearHasClones={() => setClearedHasClones(true)}
          clearErrors={() => setClearedErrors(true)}
          isShowNeverAgainSet={neverShowAgain}
          isPresentationMode={isPresentationMode()}
          toggleNeverShowAgain={toggleNeverShowAgain}
        />
        <ViewLegendContainer
          heightOffset={heightOffset}
          visible={isLegendActive}
        >
          <GraphViewLegend
            componentTypes={componentTypes}
            referenceTypes={legendReferenceTypes}
            hasCollapsedNodes={maxTreeDepth > 0 && treeDepth < maxTreeDepth}
            hasReferenceParents={
              (degreesOfParenthood > 0 ||
                degreesOfParenthood === INFINITE_DEGREES) &&
              referenceParentMaxDepth > 0
            }
            hasNonComponentNodes={hasNonComponentNodes}
            isUserDefinedGrouping={isUserDefinedGrouping}
            showComponentSwatches={!colorByReference}
            showReferenceConditionalFormatting={colorByReference}
            showComponentShapes={true}
            showReferencesAsLines={false}
            activeDiffMode={activeDiffMode}
          />
        </ViewLegendContainer>
      </Container>
    </SettingsBarAndViewContainer>
  );
};

const DependencyMapViewWithSideEffects = (
  props: DependencyMapViewStreamedProps
) => {
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    VIEW_ID,
    NEVER_SHOW_AGAIN
  );

  const activeDiffMode = getActiveDiffMode();
  const isScopeDiffMode = isInScopeDiffMode();
  return (
    <DependencyMapView
      {...props}
      toggleNeverShowAgain={toggleNeverShowAgain}
      neverShowAgain={neverShowAgain}
      onViewLoading={(isBusy, viewInstanceId) => {
        dispatchAction(notifyViewLoading({ isBusy, viewInstanceId }));
      }}
      onComponentDoubleClick={componentId =>
        props.viewModel.isViewpointMode
          ? dispatchAction(openDetailsDrawer([componentId]))
          : navigateToComponent(componentId)
      }
      onViewSettingsUpdate={(viewId, settings, persistent, settingsPath) => {
        ardoqFrontOnViewSettingsUpdate(
          viewId,
          settings,
          persistent,
          settingsPath
        );
      }}
      trackingFunction={trackExportingVisualization}
      addToPresentation={ardoqFrontAddToPresentation}
      activeDiffMode={isScopeDiffMode ? activeDiffMode : null}
      heightOffset={useViewLegendSubscription()}
      isViewpointMode={loadedGraph$.state.isViewpointMode}
    />
  );
};

const PerformanceTrackedDependencyMapView = (
  props: DependencyMapViewStreamedProps
) =>
  WithPerformanceTracking('dependency map view render', 5000, {
    WrappedComponent: DependencyMapViewWithSideEffects,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: {
      itemCount: props.viewModel.itemCount,
    },
  });

const DependencyMapViewWithLoadingIndicator = (
  props: DependencyMapViewStreamedProps
) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedDependencyMapView,
    wrappedProps: props,
    showContentWhileLoading: true,
  });

export default connectInstance(
  DependencyMapViewWithLoadingIndicator,
  getViewModel$()
);
