import styled from 'styled-components';
import { HasDegrees } from './types';
import { colors, s24, s4, vizPink40 } from '@ardoq/design-tokens';
import { HasHoveredComponentId, SetsHoveredComponentId } from '@ardoq/graph';
import { CONTEXT_ID } from './consts';
import Node from './TraversalInfoMapNode';

const Container = styled.div<{ $isActive: boolean }>`
  flex: 1;
  margin: 0 ${s4} 0 ${s24};
  background-color: ${({ $isActive }) =>
    $isActive ? vizPink40 : colors.pink95};
  border: 1px solid ${colors.pink60};
  min-height: 24px;
`;
type TraversalInfoGridProperties = HasDegrees &
  SetsHoveredComponentId &
  HasHoveredComponentId;

const TraversalInfoMap = ({
  inDegrees,
  outDegrees,
  hoveredComponentId,
  setHoveredComponentId,
}: TraversalInfoGridProperties) => (
  <Container
    $isActive={hoveredComponentId === CONTEXT_ID}
    onMouseOver={() => setHoveredComponentId(CONTEXT_ID)}
  >
    {inDegrees > 0 && (
      <>
        <Node
          isIncoming={true}
          componentId="8"
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
        >
          {inDegrees > 1 && (
            <Node
              isIncoming={true}
              componentId="7"
              hoveredComponentId={hoveredComponentId}
              setHoveredComponentId={setHoveredComponentId}
            >
              {inDegrees > 2 && (
                <>
                  <Node
                    componentId="4"
                    hoveredComponentId={hoveredComponentId}
                    setHoveredComponentId={setHoveredComponentId}
                    isIncoming={true}
                  />
                  <Node
                    componentId="12"
                    hoveredComponentId={hoveredComponentId}
                    setHoveredComponentId={setHoveredComponentId}
                    isIncoming={true}
                  />
                </>
              )}
            </Node>
          )}
        </Node>
        <Node
          componentId="5"
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
          isIncoming={true}
        />
      </>
    )}
    {outDegrees > 0 && (
      <>
        <Node
          componentId="13"
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
          isIncoming={false}
        />
        <Node
          componentId="10"
          hoveredComponentId={hoveredComponentId}
          setHoveredComponentId={setHoveredComponentId}
          isIncoming={false}
        >
          {outDegrees > 1 && (
            <Node
              componentId="11"
              hoveredComponentId={hoveredComponentId}
              setHoveredComponentId={setHoveredComponentId}
              isIncoming={false}
            >
              {outDegrees > 2 && (
                <>
                  <Node
                    componentId="6"
                    hoveredComponentId={hoveredComponentId}
                    setHoveredComponentId={setHoveredComponentId}
                    isIncoming={false}
                  />
                  <Node
                    componentId="14"
                    hoveredComponentId={hoveredComponentId}
                    setHoveredComponentId={setHoveredComponentId}
                    isIncoming={false}
                  />
                </>
              )}
            </Node>
          )}
        </Node>
      </>
    )}
  </Container>
);

export default TraversalInfoMap;
