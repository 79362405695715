import {
  CategoryCollection,
  ModelCategory,
} from 'workspaceWizard/fromTemplate/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { IconName } from '@ardoq/icons';

const getCategory = ({
  name,
  icon,
  id = name,
  displayCategoryWhenTemplatesEmpty = false,
}: Pick<ModelCategory, 'name' | 'icon'> &
  Pick<
    Partial<ModelCategory>,
    'id' | 'displayCategoryWhenTemplatesEmpty'
  >): ModelCategory => ({
  name,
  icon,
  id,
  displayCategoryWhenTemplatesEmpty,
});

const USE_CASE_TEMPLATES: CategoryCollection = {
  label: 'Use Case Workspace Templates',
  categories: [
    getCategory({ name: 'Strategy and Objectives', icon: IconName.BULLSEYE }),
    getCategory({
      name: 'Customers, Partners and Markets',
      icon: IconName.BULLSEYE,
    }),
    getCategory({
      name: 'Products, Services and Channels',
      icon: IconName.BULLSEYE,
    }),
    getCategory({
      name: 'Business and Technical Capabilities',
      icon: IconName.CONDITIONAL,
    }),
    getCategory({ name: 'Business Processes', icon: IconName.BULLSEYE }),
    getCategory({
      name: 'People, Organization, and Roles',
      icon: IconName.PEOPLE,
    }),
    getCategory({ name: 'Data and Information', icon: IconName.SITEMAP }),
    getCategory({
      name: 'Applications and Technologies',
      icon: IconName.COMPONENT,
    }),
    getCategory({ name: 'Locations and Networks', icon: IconName.NAVIGATE }),
    getCategory({
      name: 'Innovation and Change',
      icon: IconName.COLLECTIONS_BOOKMARK,
    }),
    getCategory({ name: 'Risks and Controls', icon: IconName.WORKSPACE }),
    getCategory({ name: 'Other', icon: IconName.MORE_VERT }),
  ],
};

const getOtherFrameworksAndStandards = () => ({
  label: 'Other Frameworks & Standards',
  categories: [
    getCategory({ name: 'BIZBOK', icon: IconName.BULLSEYE }),
    getCategory({ name: 'ArchiMate', icon: IconName.BULLSEYE }),
    getCategory({ name: 'BIAN', icon: IconName.BULLSEYE }),
    getCategory({ name: 'BPMN', icon: IconName.BULLSEYE }),
    getCategory({ name: 'GDPR', icon: IconName.BULLSEYE }),
  ].filter(ExcludeFalsy),
});

export const getWorkspaceTemplateCategories = () => [
  USE_CASE_TEMPLATES,
  getOtherFrameworksAndStandards(),
];
