import {
  type SettingsConfig,
  SettingsType,
  DropdownConfig,
} from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { type DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { GetLeftMenuArgs, ModernizedBlockDiagramViewSettings } from '../types';
import {
  BLOCK_DIAGRAM_LAYOUT_OPTIONS,
  COMPONENT_STYLE_VIEW_SETTING_POPOVER,
} from '../consts';
import { getLayoutDirectionOption, separateReferencesDropdown } from '../menus';
import { ViewIds } from '@ardoq/api-types';

const getLeftMenu = ({
  viewSettings,
  viewId,
  onViewSettingsUpdate,
}: GetLeftMenuArgs<ModernizedBlockDiagramViewSettings>): SettingsConfig[] => {
  const layoutDirectionOptions = BLOCK_DIAGRAM_LAYOUT_OPTIONS.map(
    getLayoutDirectionOption(viewSettings, viewId)
  );

  return [
    {
      id: 'layoutDirection',
      type: SettingsType.DROPDOWN,
      iconName: IconName.SORT_ASC,
      label: 'Layout direction',
      options: layoutDirectionOptions,
      dropdownSize: DropdownSize.S,
    },
    separateReferencesDropdown(
      ViewIds.MODERNIZED_BLOCK_DIAGRAM,
      viewSettings.separateReferences,
      onViewSettingsUpdate
    ),
    {
      id: 'componentStyle',
      type: SettingsType.DROPDOWN,
      label: 'Component style',
      iconName: IconName.PAINT_BRUSH,
      options: [
        {
          label: 'Shape',
          type: DropdownOptionType.OPTION,
          isActive: viewSettings.componentStyle === 'shape',
          onClick: () =>
            onViewSettingsUpdate(viewId, { componentStyle: 'shape' }, true),
        } satisfies DropdownOption,
        {
          label: 'Icon',
          type: DropdownOptionType.OPTION,
          isActive: viewSettings.componentStyle === 'icon',
          onClick: () =>
            onViewSettingsUpdate(viewId, { componentStyle: 'icon' }, true),
        } satisfies DropdownOption,
      ],
      popoverId: COMPONENT_STYLE_VIEW_SETTING_POPOVER,
    } satisfies DropdownConfig,
  ];
};
export default getLeftMenu;
