import styled from 'styled-components';

import { ViewIds } from '@ardoq/api-types';
import ArdoqNode, {
  ArdoqNodeProperties,
} from 'yfilesExtensions/styles/ArdoqNode';
import GroupExpander from './GroupExpander';
import {
  CollapsibleGraphGroup,
  CountLabel,
  NO_LINK_TARGET_DECORATION,
} from '@ardoq/graph';
import { EXPANDER_RADIUS } from './consts';
import { HALO_PADDING } from 'yfilesExtensions/styles/consts';
import { classes } from '@ardoq/common-helpers';
import { getElementOpacityIfTransparentized } from './utils';

const NodeHalo = styled.rect`
  rx: 5;
  ry: 5;
  stroke-opacity: 0.32;
`;

type ComponentGroupProperties = ArdoqNodeProperties & {
  group: CollapsibleGraphGroup;
  viewId: ViewIds;
};

const ComponentGroup = ({
  group,
  templateHref,
  imageHref,
  isPlaceholder,
  isContext,
  isSelectedRelated,
  width,
  height,
  visualDiffClass,
  viewId,
  contextHighlightPadding,
}: ComponentGroupProperties) => {
  const groupElementsOpacityStyle = {
    opacity: getElementOpacityIfTransparentized(group.isTransparentized),
  };

  return (
    <>
      <NodeHalo
        x={isContext ? contextHighlightPadding - HALO_PADDING : 0}
        y={isContext ? contextHighlightPadding - HALO_PADDING : 0}
        width={
          isContext
            ? width - 2 * (contextHighlightPadding - HALO_PADDING)
            : width
        }
        height={
          isContext
            ? height - 2 * (contextHighlightPadding - HALO_PADDING)
            : height
        }
        className={classes(
          group.getCSS({ useAsBackgroundStyle: true }),
          NO_LINK_TARGET_DECORATION
        )}
      />
      <ArdoqNode
        className={group.getCSS({ useAsBackgroundStyle: false })}
        templateHref={templateHref}
        imageHref={imageHref}
        isPlaceholder={isPlaceholder}
        isContext={isContext}
        isSelectedRelated={isSelectedRelated}
        width={isContext ? width : width - contextHighlightPadding / 2 - 1}
        height={isContext ? height : height - contextHighlightPadding / 2 - 1}
        visualDiffClass={visualDiffClass}
        contextHighlightPadding={contextHighlightPadding}
        isTransparentized={group.isTransparentized}
        transform={
          isContext ? undefined : `translate(${HALO_PADDING}, ${HALO_PADDING})`
        }
      />
      <GroupExpander
        viewId={viewId}
        groupId={group.id}
        collapsed={group.collapsed}
        boundsSize={2 * EXPANDER_RADIUS}
        transform={`translate(
            ${
              isContext
                ? contextHighlightPadding - EXPANDER_RADIUS - HALO_PADDING
                : -EXPANDER_RADIUS
            },
            ${height / 2 - EXPANDER_RADIUS}
          )`}
        style={groupElementsOpacityStyle}
      />
      <CountLabel
        count={group.descendantCount}
        transform={`translate(
              ${
                isContext
                  ? width - contextHighlightPadding + HALO_PADDING
                  : width
              },
              ${isContext ? contextHighlightPadding - HALO_PADDING : 0}
            )`}
        style={groupElementsOpacityStyle}
      />
    </>
  );
};
export default ComponentGroup;
