import { PropsWithChildren, ComponentType, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const ProvideCallback = ({
  callback,
  children,
}: PropsWithChildren<{ callback: VoidFunction }>) => {
  useEffect(() => {
    callback();
  }, [callback]);
  return <>{children}</>;
};

const measureComponent = <P = unknown,>(
  Component: ComponentType<P>,
  props: P,
  container: HTMLDivElement,
  callback: (rect: DOMRect) => void
) => {
  const testContainer = document.createElement('div');
  testContainer.style.display = 'hidden';
  container.appendChild(testContainer);
  const root = createRoot(testContainer);
  root.render(
    <ProvideCallback
      callback={() => {
        callback(testContainer.getBoundingClientRect() ?? new DOMRect());
        testContainer.remove();
        setTimeout(() => root.unmount());
      }}
    >
      <Component {...(props as JSX.IntrinsicAttributes & P)} />
    </ProvideCallback>
  );
};
export default measureComponent;
