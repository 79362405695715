import { classes } from '@ardoq/common-helpers';
import { BubbleChartDataPointArgs } from './types';
import {
  DataPointCircleElement,
  DataPointContextHighlight,
  DataPointGElement,
} from './atoms';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import {
  CONTEXT_HIGHLIGHT_STROKE_WIDTH,
  ZERO_BUBBLE,
  ZERO_BUBBLE_SIZE,
} from './consts';

const HIGHLIGHT_GAP = 4;
const highlightRadius = (regularRadius: number) =>
  regularRadius + HIGHLIGHT_GAP + CONTEXT_HIGHLIGHT_STROKE_WIDTH / 2;

export const DataPoint = ({
  data: { isBlurred, cid, class: className, x, y, radius, isContext },
}: BubbleChartDataPointArgs) => {
  return (
    <DataPointGElement className="dataPoint">
      {radius ? (
        <DataPointCircleElement
          $isBlurred={isBlurred}
          data-global-handler-id={cid}
          className={classes(className, 'component-stroke', 'dataPoint')}
          cx={x}
          cy={y}
          r={Math.max(0, radius)}
          {...{ [COMPONENT_ID_ATTRIBUTE]: cid }}
        />
      ) : (
        <use
          href={`#${ZERO_BUBBLE}`}
          x={x}
          y={y}
          style={{ transform: `translate(${-ZERO_BUBBLE_SIZE / 2}px)` }}
        />
      )}
      {isContext && (
        <DataPointContextHighlight r={highlightRadius(radius)} cx={x} cy={y} />
      )}
    </DataPointGElement>
  );
};
