import { ArrowType } from '@ardoq/api-types';
import { classes } from '@ardoq/common-helpers';
import { DiffType } from '@ardoq/data-model';
import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import { EdgePath, HoverPath } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { FILTER_DIMENSION_WORKAROUND_CSS_CLASS } from 'tabview/graphComponent/atoms';
import { getElementOpacityIfTransparentized } from './utils';

interface AggregatedEdgeProperties {
  edgeSourceLocation: Point;
  className: string;
  diffType: DiffType;
  pathData: string;
  hoverPathData: string;
  isParentChildReference: boolean;
  lineBeginning: ArrowType;
  lineEnding: ArrowType;
  isTransparentized: boolean;
}
const AggregatedEdge = ({
  edgeSourceLocation: [edgeSourceX, edgeSourceY],
  className,
  diffType,
  pathData,
  hoverPathData,
  isParentChildReference,
  lineBeginning,
  lineEnding,
  isTransparentized,
}: AggregatedEdgeProperties) => {
  return (
    <>
      {diffType !== DiffType.NONE && (
        // Workaround an issue with svg filters.
        // If the path is a straight line, meaning the path has no area, applying
        // a filter will make the path disapper.
        <rect
          className={FILTER_DIMENSION_WORKAROUND_CSS_CLASS}
          x={edgeSourceX - 15}
          y={edgeSourceY - 15}
          width={30}
          height={30}
        />
      )}
      <HoverPath
        d={hoverPathData}
        $diffType={diffType}
        className={classes(
          className,
          'hoverPath',
          NO_EXPORT_CLASS_NAME,
          'skipContextUpdate',
          isParentChildReference && 'parent-child-reference'
        )}
      />
      <EdgePath
        d={pathData}
        $diffType={diffType}
        className={className}
        markerStart={`url(#${lineBeginning})`}
        markerEnd={`url(#${lineEnding})`}
        style={{
          strokeOpacity: getElementOpacityIfTransparentized(
            isTransparentized,
            0.3
          ),
        }}
      />
    </>
  );
};
export default AggregatedEdge;
