import { APIComponentType, ArdoqId } from '@ardoq/api-types';
import { getDefaultCSSColor } from '@ardoq/color-helpers';
import { getIcon } from '@ardoq/icons';
import { graphics } from '@ardoq/renderers';
import type { ComponentRepresentationData } from '@ardoq/graph';
import { componentInterface } from 'modelInterface/components/componentInterface';

export const getComponentTypeRepresentationDataFromComponentType = (
  componentType: Pick<APIComponentType, 'color' | 'level' | 'image' | 'icon'>
): ComponentRepresentationData | null => {
  const color =
    (componentType &&
      (componentType.color || getDefaultCSSColor(componentType.level))) ||
    'black';

  if (componentType.image) {
    return {
      icon: graphics.icons.none,
      isImage: true,
      value: componentType.image,
      color,
    };
  }
  if (componentType.icon) {
    return {
      icon: getIcon(componentType.icon),
      isImage: false,
      value: componentType.icon,
      color,
    };
  }
  return null;
};

export const componentTypeRepresentationData = (componentId?: ArdoqId) => {
  if (!componentId) {
    return null;
  }
  const componentType = componentInterface.getType(componentId);
  if (!componentType) {
    return null;
  }
  return getComponentTypeRepresentationDataFromComponentType(componentType);
};
