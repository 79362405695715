import { type DependencyMapNode } from '@ardoq/dependency-map';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { GroupType } from '@ardoq/api-types';
import { getGroupingRules } from 'tabview/graphViews/traversalViewModel';
import { LoadedScenarioData } from 'loadedScenarioData$';
import { ShowRelatedComponentsShape } from '@ardoq/data-model';
import { partition } from 'lodash';
import { DependencyMapGroupingData } from './types';
import { getAppliedGroups } from '@ardoq/graph';

export const getDirectReferenceNodes = ({
  incomingReferences,
  outgoingReferences,
}: DependencyMapNode) =>
  [incomingReferences, outgoingReferences]
    .filter(ExcludeFalsy)
    .flat()
    .map(({ node }) => node);

type GetDependencyMapGroupingDataArgs = {
  loadedScenarioData: LoadedScenarioData;
  showScopeRelated: boolean;
  scenarioRelatedComponents: ShowRelatedComponentsShape;
  isViewpointMode: boolean;
};
export const getDependencyMapGroupingData = ({
  loadedScenarioData,
  showScopeRelated,
  scenarioRelatedComponents,
  isViewpointMode,
}: GetDependencyMapGroupingDataArgs): DependencyMapGroupingData => {
  const userDefinedGroupingRules = getGroupingRules({
    loadedScenarioData,
    showScopeRelated,
    scenarioRelatedComponentIds: scenarioRelatedComponents.componentIds,
  });

  const hasUserDefinedReferenceTypeGroups = userDefinedGroupingRules.some(
    rule => rule.type === 'REFERENCE'
  );

  if (hasUserDefinedReferenceTypeGroups || !isViewpointMode) {
    /**
     * We currently don't support grouping by reference types in traversal hierarchy.
     * Normally, we check for reference type groups before calling this function, but
     * we leave this here for safety.
     */
    return {
      unsupportedUserDefinedGroupingRules: [],
      appliedGroupingRules: getAppliedGroups(userDefinedGroupingRules),
      supportedUserDefinedGroupingRules: userDefinedGroupingRules,
      hasUserDefinedReferenceTypeGroups,
    };
  }

  const [supportedUserDefinedGroupingRules, referenceTypeGroups] = partition(
    userDefinedGroupingRules,
    ({ type }) => type !== GroupType.REFERENCE
  );
  const unsupportedUserDefinedGroupingRules = referenceTypeGroups.map(rule => ({
    message: `Reference type groups are not supported in the Dependency Map.`,
    rule,
    index: userDefinedGroupingRules.indexOf(rule),
  }));

  return {
    unsupportedUserDefinedGroupingRules,
    appliedGroupingRules: getAppliedGroups(supportedUserDefinedGroupingRules),
    supportedUserDefinedGroupingRules,
    hasUserDefinedReferenceTypeGroups,
  };
};
