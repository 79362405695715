import { ErrorNotification } from '@ardoq/status-ui';
import styled from 'styled-components';

type GremlinSearchErrorProps = {
  syntaxError: string | null;
};

const SyntaxError = styled(ErrorNotification)`
  font-family: monospace;
  white-space: pre-wrap;
`;

const GremlinSearchError = ({ syntaxError }: GremlinSearchErrorProps) => {
  if (syntaxError) return <SyntaxError>{syntaxError}</SyntaxError>;
  return (
    <ErrorNotification>
      <p>
        An error occurred while searching. We have been notified and are sorry
        about the inconvenience.
      </p>
      If the error persists, please contact support.
    </ErrorNotification>
  );
};

export default GremlinSearchError;
