import { DoqType } from '@ardoq/doq';
import { connect } from '@ardoq/rxbeach';
import emptyView$ from 'views/streams/emptyView$';
import NoDataAvailableDoq from 'tabview/graphViews/emptyStates/NoDataAvailableDoq';
import NoDataWithFiltersDoq from 'tabview/graphViews/emptyStates/NoDataWithFiltersDoq';
import NoDateRangeTimeFieldsDoq from 'tabview/graphViews/emptyStates/NoDateRangeTimeFieldsDoq';
import NoDateRangeTimeFieldsSelectedDoq from 'tabview/graphViews/emptyStates/NoDateRangeTimeFieldsSelectedDoq';
import type { Notifier } from '@ardoq/dropdown-menu';
import { FieldsRegistryItem } from '@ardoq/timeline2024';

type TimeFields = {
  milestoneFields: FieldsRegistryItem[];
  timeSpanFields: FieldsRegistryItem[];
};

type SelectedTimeFields = {
  selectedMilestoneFields: string[];
  selectedPhaseFields: string[];
};

const hasSelectedFields = (
  selectedFields: string[],
  settingFields: FieldsRegistryItem[]
) => {
  const fieldNames = new Set(settingFields.map(({ name }) => name));

  return selectedFields.some(fieldName => fieldNames.has(fieldName));
};

const getSelectFieldAction = ({
  milestoneFields,
  timeSpanFields,
  selectedMilestoneFields,
  selectedPhaseFields,
  openDateRangeViewSetting,
  openMilestonesViewSetting,
}: TimeFields & SelectedTimeFields & TimeNotifiers) => {
  if (
    timeSpanFields.length &&
    !hasSelectedFields(selectedPhaseFields, timeSpanFields)
  ) {
    return openDateRangeViewSetting;
  }

  if (
    milestoneFields.length &&
    !hasSelectedFields(selectedMilestoneFields, milestoneFields)
  ) {
    return openMilestonesViewSetting;
  }

  // default to opening date range view setting
  return openDateRangeViewSetting;
};

type TimeNotifiers = {
  openDateRangeViewSetting?: Notifier;
  openMilestonesViewSetting?: Notifier;
};
type EmptyStateProps = TimeFields &
  SelectedTimeFields &
  TimeNotifiers & {
    isWorkspaceEmpty: boolean;
    hasActiveFilter: boolean;
    isPresentationMode: boolean;
  };

const EmptyState = ({
  isWorkspaceEmpty,
  hasActiveFilter,
  milestoneFields,
  timeSpanFields,
  selectedMilestoneFields,
  selectedPhaseFields,
  isPresentationMode,
  openDateRangeViewSetting,
  openMilestonesViewSetting,
}: EmptyStateProps) => {
  const noDateRangeTimeFields =
    !milestoneFields.length && !timeSpanFields.length;

  const noValidFieldsSelected =
    !hasSelectedFields(selectedMilestoneFields, milestoneFields) &&
    !hasSelectedFields(selectedPhaseFields, timeSpanFields);

  if (isWorkspaceEmpty) {
    return (
      <NoDataAvailableDoq
        doqType={DoqType.TIMELINE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (hasActiveFilter) {
    return (
      <NoDataWithFiltersDoq
        doqType={DoqType.TIMELINE_ERROR}
        includeActions={!isPresentationMode}
      />
    );
  }

  if (noDateRangeTimeFields) {
    return <NoDateRangeTimeFieldsDoq doqType={DoqType.TIMELINE_ERROR} />;
  }

  if (noValidFieldsSelected) {
    const selectFieldAction = getSelectFieldAction({
      milestoneFields,
      timeSpanFields,
      selectedMilestoneFields,
      selectedPhaseFields,
      openDateRangeViewSetting,
      openMilestonesViewSetting,
    });

    return (
      <NoDateRangeTimeFieldsSelectedDoq
        doqType={DoqType.TIMELINE_ERROR}
        includeActions={!isPresentationMode}
        selectFieldAction={selectFieldAction}
      />
    );
  }

  return null;
};

export default connect(EmptyState, emptyView$);
