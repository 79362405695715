import styled, { css } from 'styled-components';
import {
  COMPONENT_CONTEXT_ITEM_CONTAINER_BORDER,
  COMPONENT_ITEM_CONTAINER_BORDER,
  COMPONENT_ITEM_CONTAINER_MARGIN,
  COMPONENT_ITEM_HEIGHT,
  HEADER_CELL_HORIZONTAL_PADDING,
  HEADER_CELL_VERTICAL_PADDING,
  ICON_MARGIN,
  LEVEL_HEADER_CELL_HORIZONTAL_PADDING,
  LEVEL_HEADER_CELL_VERTICAL_PADDING,
} from '../consts';
import {
  ACTIVE_BOX_SHADOW,
  ACTIVE_BRIGHTNESS,
  LI_BACKGROUND_CLASS_NAME,
} from '@ardoq/global-consts';
import { colors } from '@ardoq/design-tokens';
import { CUSTOM_SVG_ICON_SELECTOR } from '@ardoq/icons';

export const ComponentMatrixViewContainer = styled.div`
  user-select: none;
  & .rotate-90 {
    transform: rotate(90deg);
  }
`;

const levelHeaderCellCss = css`
  background-color: transparent;
  border: none;
  padding: ${LEVEL_HEADER_CELL_VERTICAL_PADDING}px
    ${LEVEL_HEADER_CELL_HORIZONTAL_PADDING}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const LevelHeaderCell = styled.div`
  ${levelHeaderCellCss}
`;
export const ColumnLevelHeaderCell = styled.div`
  ${levelHeaderCellCss}
  text-align: right;
  grid-column: 1;
  z-index: 1;
`;

export const HeaderCell = styled.div`
  background-color: transparent;
  vertical-align: top;
  white-space: nowrap;
`;

export const BodyCell = styled.div`
  vertical-align: top;
  border-bottom: 1px solid ${colors.grey80};
  border-right: 1px solid ${colors.grey80};
  overflow: hidden;
  .forExport & {
    border-bottom: 0px;
    border-right: 0px;
  }
`;

export const ComponentLabel = styled.span`
  color: black;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: fit-content;
`;
export const ComponentMatrixListValueHeaderCellContainer = styled.div<{
  $level: number;
}>`
  background-color: ${colors.grey95};
  padding: ${HEADER_CELL_VERTICAL_PADDING}px ${HEADER_CELL_HORIZONTAL_PADDING}px;
  box-shadow: 0px 0px 1px 0px;
  ${({ $level }) =>
    $level === 0
      ? css`
          height: 100%;
        `
      : css`
          filter: brightness(${1 + $level * 0.05});
        `}
`;
export const ComponentItemContainer = styled.div`
  border: ${COMPONENT_ITEM_CONTAINER_BORDER}px solid;
  margin: ${COMPONENT_ITEM_CONTAINER_MARGIN}px;
  white-space: nowrap;
  height: ${COMPONENT_ITEM_HEIGHT}px;
  max-height: ${COMPONENT_ITEM_HEIGHT}px;
  line-height: 24px;
  display: flex;
  &.active {
    filter: brightness(${ACTIVE_BRIGHTNESS});
    box-shadow: ${ACTIVE_BOX_SHADOW};
  }
  &.component.${LI_BACKGROUND_CLASS_NAME}.context {
    border-width: ${COMPONENT_CONTEXT_ITEM_CONTAINER_BORDER}px;
    border-color: ${colors.blue50} !important;
  }
`;
export const IconContainer = styled.span<{ $color: string }>`
  margin: 0 ${ICON_MARGIN}px 0 ${ICON_MARGIN}px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${props => props.$color};

  > ${CUSTOM_SVG_ICON_SELECTOR} {
    max-height: 18px;
    min-height: 18px;
    line-height: 1.2;
  }
`;
