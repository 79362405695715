import { IGraph, SequenceConstraintData } from '@ardoq/yfiles';
import { LayoutConstraint } from 'tabview/graphViews/types';

const createSequenceConstraints = (
  sequenceConstraints: LayoutConstraint[],
  graph: IGraph
) => {
  const sequenceConstraintData = new SequenceConstraintData();

  const sequenceConstraintValues = sequenceConstraints.map(
    ([, value]) => value
  );
  const maxSequenceConstraint = Math.max(0, ...sequenceConstraintValues);
  const minSequenceConstraint = Math.min(0, ...sequenceConstraintValues);
  graph.nodes.forEach(node => {
    const constraint = sequenceConstraints.find(
      ([constraintId]) => constraintId === node.tag?.id
    );
    if (constraint) {
      const [, constraintValue] = constraint;
      if (constraintValue === maxSequenceConstraint) {
        sequenceConstraintData.placeAtHead(node);
      } else if (constraintValue === minSequenceConstraint) {
        sequenceConstraintData.placeAtTail(node);
      } else {
        sequenceConstraintData.itemComparables.mapper.set(
          node,
          -constraintValue
        );
      }
    } else {
      sequenceConstraintData.itemComparables.mapper.set(node, 0);
    }
  });
  return sequenceConstraintData;
};

export default createSequenceConstraints;
