import {
  Font,
  FontWeight,
  Size,
  TextMeasurePolicy,
  TextWrapping,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { createSvgElement } from '@ardoq/dom-utils';

/**
 * Ellipsis truncate a single line of text, given the available width.
 * @returns the text of a single-line label, ellipsis truncated as necessary.
 */
export const truncateSvgLabel = (
  text: string,
  availableWidth: number,
  fontFamily: string,
  fontSize: number,
  fontWeight = FontWeight.NORMAL
) => {
  const targetElement = createSvgElement('text');
  addText({
    targetElement,
    text,
    font: new Font({
      fontFamily,
      fontSize,
      fontWeight,
    }),
    maximumSize: new Size(availableWidth, fontSize * 1.5),
    wrapping: TextWrapping.CHARACTER_ELLIPSIS,
    measurePolicy: TextMeasurePolicy.SVG,
  });
  const result = targetElement.textContent;
  targetElement.remove();
  return result;
};
