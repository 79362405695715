import { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from '@ardoq/hooks';
import styled, { keyframes } from 'styled-components';
import { type HasViewInstanceId, truncateSvgText, spline } from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import {
  RelationshipsLinkVisual,
  RelationshipsNode,
  RelationshipsViewProperties,
  RelationshipsViewSettings,
  WindowRect,
} from './types';
import {
  relationshipsViewLayoutUpdated,
  relationshipsViewSetHighlight,
  relationshipsViewUpdateWindowRect,
  zoomChanged,
} from './actions';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import { Line, ZoomTransform, line, zoomIdentity } from 'd3';
import { colors } from '@ardoq/design-tokens';
import HitContext from './HitContext';
import { ViewIds } from '@ardoq/api-types';
import {
  everyAncestor,
  findPathAndViewportBoundaryIntersection,
  isPointInRect,
} from './util';
import {
  getBundlingFactory,
  getLinkPoints,
  getReferenceColors,
  linkWidth,
} from './visuals/link';
import { absolutePosition } from './visuals/node';
import { connect } from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { unfilteredRelationshipsViewSettings$ } from './viewModel$/viewModel$';
import { currentTimestamp } from '@ardoq/date-time';

const FADE_IN_DURATION = '500ms';
const LINK_LABEL_PADDING_FACTOR = 0.8;
const fadeIn = keyframes`from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`;

const HoverPath = styled.path`
  opacity: 0.32;
  fill: none;
`;

const FadeInContainer = styled.g`
  animation: ${fadeIn} ${FADE_IN_DURATION};
`;

const DecorationsSvg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const pulse = keyframes`from {
  stroke-opacity: 1;
  transform: scale(1);
}
to {
  stroke-opacity: 0;
  transform: scale(2);
}`;
const DisconnectedNode = styled.circle`
  stroke: ${colors.red50};
  stroke-width: 4;
  fill: none;
  transform-origin: center center;
  transform-box: fill-box;
  animation: ${pulse} 1s infinite;
`;

const getCurve = (lineGenerator: Line<Point>, bundleRelationships: boolean) =>
  lineGenerator.curve(getBundlingFactory(bundleRelationships ? 1 : 0));

const hitContext = new HitContext();
const bezierLoc = (a: number, b: number, c: number, d: number, t: number) =>
  (1 - t) ** 3 * a +
  3 * (1 - t) ** 2 * t * b +
  3 * (1 - t) * t ** 2 * c +
  t ** 3 * d;
const needsFlip = (linkPoints: Point[], bundleRelationships: boolean) => {
  if (!bundleRelationships || linkPoints.length === 2) {
    // there are two points in this line. we need to flip if startX > endX.
    return linkPoints[0][0] > linkPoints[linkPoints.length - 1][0];
  }
  const testLine = line();
  testLine.context(hitContext);
  getCurve(testLine, bundleRelationships)(linkPoints);

  const firstSpline = hitContext.lastPathSplines[0];
  const lastSpline =
    hitContext.lastPathSplines[hitContext.lastPathSplines.length - 1];

  const [[ax], [bx]] = firstSpline;
  const [cx] = lastSpline[2];
  const [dx] = lastSpline[3];

  // calculate the X of a point on the spline a little before and a little after the midpoint. we need to flip if x1 > x2
  const preMidX = bezierLoc(ax, bx, cx, dx, 0.4);
  const postMidX = bezierLoc(ax, bx, cx, dx, 0.6);
  return preMidX > postMidX;
};

const getLinkPathData = (
  highlightedLink: RelationshipsLinkVisual,
  bundleRelationships: boolean,
  hasAdaptive: boolean
) => {
  const bundling = bundleRelationships ? 1 : 0;
  const linkPoints = getLinkPoints(
    highlightedLink,
    null,
    bundling,
    hasAdaptive
  );
  if (linkPoints && needsFlip(linkPoints, bundleRelationships)) {
    linkPoints.reverse();
  }
  if (!linkPoints) {
    return null;
  }
  if (linkPoints.length === 2) {
    if (highlightedLink.similarLinksCount > 1) {
      const { source, target, similarLinksIndex, similarLinksCount } =
        highlightedLink;
      const [startX, startY, cpX, cpY, endX, endY] = spline(
        absolutePosition(source),
        source.r,
        absolutePosition(target),
        target.r,
        similarLinksIndex,
        similarLinksCount
      );
      return `M${startX} ${startY} C${cpX} ${cpY} ${cpX} ${cpY} ${endX} ${endY}`;
    }
    const [startX, startY] = linkPoints[0];
    const [endX, endY] = linkPoints[1];
    return `M${startX} ${startY} L${endX} ${endY}`;
  }
  return getCurve(line(), bundleRelationships)(linkPoints);
};

const getVisibleDisconnectedNodes = (
  disconnectedNodes: RelationshipsNode[] | null
) =>
  disconnectedNodes
    ?.filter(node =>
      everyAncestor(node, ancestor => ancestor === node || ancestor.open)
    )
    .slice(0, 1000);

interface RelationshipsViewDecorationsSvgProps extends HasViewInstanceId {
  viewSettings: RelationshipsViewSettings;
  disconnectedChildren: RelationshipsViewProperties['disconnectedChildren'];
  isViewpointMode: boolean;
  hasAdaptive: boolean;
}
const RelationshipsViewDecorationsSvg = ({
  viewInstanceId,
  viewSettings,
  disconnectedChildren,
  isViewpointMode,
  hasAdaptive,
}: RelationshipsViewDecorationsSvgProps) => {
  const [viewTransform, setViewTransform] =
    useState<ZoomTransform>(zoomIdentity);
  const [highlightedLink, setHighlightedLink] =
    useState<RelationshipsLinkVisual | null>(null);
  const [currentWindowRect, setCurrentWindowRect] = useState<WindowRect | null>(
    null
  );

  const { highlightDisconnectedComponents, bundleRelationships } = viewSettings;
  const highlightDisconnectedNodes =
    highlightDisconnectedComponents && !isViewpointMode;
  const [visibleDisconnectedNodes, setVisibleDisconnectedNodes] = useState(
    getVisibleDisconnectedNodes(disconnectedChildren)
  );
  useEffectOnce(() => {
    const subscriptions = [
      subscribeToAction(
        zoomChanged,
        ({ transform }) =>
          setViewTransform(
            new ZoomTransform(
              transform.k / devicePixelRatio,
              transform.x / devicePixelRatio,
              transform.y / devicePixelRatio
            )
          ),
        viewInstanceId
      ),
      subscribeToAction(
        relationshipsViewSetHighlight,
        ({ highlightedLink: newHighlightedLink }) => {
          setHighlightedLink(newHighlightedLink);
        },
        viewInstanceId
      ),
      subscribeToAction(
        relationshipsViewUpdateWindowRect,
        ({ currentWindowRect: newWindowRect }) => {
          setCurrentWindowRect(newWindowRect);
        },
        viewInstanceId
      ),
    ];
    return () => {
      clearSubscriptions(subscriptions);
    };
  });

  useEffect(() => {
    const layoutCompleteSubscription = subscribeToAction(
      relationshipsViewLayoutUpdated,
      () => {
        setVisibleDisconnectedNodes(
          getVisibleDisconnectedNodes(disconnectedChildren)
        );
      },
      ViewIds.RELATIONSHIPS_3
    );
    return () => layoutCompleteSubscription.unsubscribe();
  }, [disconnectedChildren]);

  const highlightPathRef = useRef<SVGPathElement | null>(null);

  const hoverPathId = `${viewInstanceId}-hoverPath`;
  const fontSize = 14 / viewTransform.k;
  const linkPathData =
    highlightedLink &&
    getLinkPathData(highlightedLink, bundleRelationships, hasAdaptive);

  const [visibleLinkLabel, setVisibleLinkLabel] = useState(
    highlightedLink?.label
  );
  const [highlightedLabelTextStartOffset, setHighlightedLabelTextStartOffset] =
    useState('50%');

  useEffect(() => {
    const fullLinkLabel = highlightedLink?.label;

    if (highlightPathRef.current && fullLinkLabel && currentWindowRect) {
      const unscaledPathLength = highlightPathRef.current.getTotalLength();
      // 0.8 for padding

      let allowedTextWidth = unscaledPathLength * LINK_LABEL_PADDING_FACTOR;

      const highlightedLinkPoints = getLinkPoints(
        highlightedLink,
        currentWindowRect,
        Number(bundleRelationships),
        hasAdaptive
      );

      // when links are bundled, we have multiple link points, in any case we need the
      // first and the last
      const highlightedLinkEdgePoints = highlightedLinkPoints
        ? [
            highlightedLinkPoints[0],
            highlightedLinkPoints[highlightedLinkPoints.length - 1],
          ]
        : null;

      // if only one of the link points is inside of the viewport, we need to calculate
      const linkPointsInViewport = highlightedLinkEdgePoints?.filter(point =>
        isPointInRect(point, currentWindowRect)
      );

      if (linkPointsInViewport?.length === 1) {
        // we have a partially displayed link => center the label within the visible part
        const pathElement = highlightPathRef.current;
        const pathStartingPoint = pathElement.getPointAtLength(0);
        const isStartingPointInRect = isPointInRect(
          [pathStartingPoint.x, pathStartingPoint.y],
          currentWindowRect
        );

        // depending on the distance (pathLength value) of the visible link point, we
        // should start searching from the start or the end of the path
        const distanceOfLinkPointInViewport = isStartingPointInRect
          ? 1
          : unscaledPathLength;

        const intersectionDistance = findPathAndViewportBoundaryIntersection({
          currentDistance: distanceOfLinkPointInViewport,
          startDistanceLimit: distanceOfLinkPointInViewport - 1,
          endDistanceLimit: isStartingPointInRect ? unscaledPathLength : 0,
          pathElement,
          currentWindowRect,
        })?.intersectionPointDistance;

        if (intersectionDistance) {
          // get the middle between the link start or end, and the viewport intersection
          const startOffsetForVisibleLabel =
            (distanceOfLinkPointInViewport + intersectionDistance) / 2;

          // set the startOffset
          setHighlightedLabelTextStartOffset(`${startOffsetForVisibleLabel}px`);
          allowedTextWidth =
            Math.abs(distanceOfLinkPointInViewport - intersectionDistance) *
            LINK_LABEL_PADDING_FACTOR;
        } else {
          // safeguard: if for some reaon we will have only one link point, but no inter-
          // section with a viewport boundary - set default value
          setHighlightedLabelTextStartOffset('50%');
        }
      } else {
        setHighlightedLabelTextStartOffset('50%');
      }

      const truncatedLabel = truncateSvgText(fullLinkLabel, allowedTextWidth, {
        fontSize,
      });
      setVisibleLinkLabel(truncatedLabel);
    } else {
      setHighlightedLabelTextStartOffset('50%');
      setVisibleLinkLabel(fullLinkLabel);
    }
  }, [
    fontSize,
    linkPathData,
    highlightedLink?.label,
    highlightedLink,
    viewTransform.k,
    currentWindowRect,
    bundleRelationships,
    hasAdaptive,
  ]);

  return (
    <DecorationsSvg>
      <g
        transform={
          isNaN(viewTransform.k) ? undefined : viewTransform.toString()
        }
      >
        {linkPathData && (
          <FadeInContainer
            key={highlightedLink.modelId} // the key is there to ensure the element re-renders and replays its fade in animation.
          >
            <HoverPath
              ref={highlightPathRef}
              id={hoverPathId}
              d={linkPathData}
              stroke={
                getReferenceColors(highlightedLink)?.stroke ?? colors.black
              } // use black if it's a parent-child reference
              strokeWidth={linkWidth(highlightedLink) + 8}
            />
            {visibleLinkLabel && (
              <text>
                <textPath
                  fontSize={fontSize}
                  startOffset={highlightedLabelTextStartOffset}
                  textAnchor="middle"
                  href={`#${hoverPathId}`}
                >
                  {visibleLinkLabel}
                </textPath>
              </text>
            )}
          </FadeInContainer>
        )}
        {highlightDisconnectedNodes &&
          visibleDisconnectedNodes &&
          visibleDisconnectedNodes.map(disconnectedNode => {
            const [x, y] = absolutePosition(disconnectedNode);
            return (
              <DisconnectedNode
                key={disconnectedNode.id + currentTimestamp()} // date is appended to ensure uniqueness every time, so the DOM node is always re-created. this is to avoid desynchronization of the pulsating visual which occurs when you create these nodes at different times.
                cx={x}
                cy={y}
                r={disconnectedNode.r}
              />
            );
          })}
      </g>
    </DecorationsSvg>
  );
};

export default connect(
  RelationshipsViewDecorationsSvg,
  unfilteredRelationshipsViewSettings$.pipe(
    map(({ currentSettings }) => ({
      viewSettings: currentSettings,
    }))
  )
);
