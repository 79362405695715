import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { handleStoreSetting } from 'viewSettings/settingsHelper';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { ViewSettings, WorkspaceField } from './types';
import {
  getSelectedFieldSettingsName,
  isByCustomField,
  isByDescendants,
  isByIncomingReferences,
  isByOutgoingReferences,
} from './helpers';
import {
  MAX_LIMIT_VALUES,
  SHOW_ALL_COMPONENTS,
  SizeBasedOn,
} from 'views/treemapConsts';
import { DropdownMenuOption } from './Components/DropdownMenuOption';
import { DropdownMenuSectionTitle } from './Components/DropdownMenuSectionTitle';

const storeSetting = (newSettings: Record<string, unknown>) => () => {
  handleStoreSetting(ViewIds.TREEMAP, newSettings);
};

const getSizeByFieldOptions = (
  settings: ViewSettings,
  fields: WorkspaceField[],
  fieldsInUse: Set<ArdoqId>
) =>
  fields
    .sort(
      (fieldA, fieldB) =>
        Number(fieldsInUse.has(fieldB.name)) -
        Number(fieldsInUse.has(fieldA.name))
    )
    .map(field => {
      const name = field.name;
      const label = fieldInterface.getLabel(field._id) || '';
      const selectedField = getSelectedFieldSettingsName(name);
      const isActive = Boolean(
        isByCustomField(settings.sizeBasedOn) &&
          settings.sizeBasedOn === selectedField
      );
      const isUnused = !fieldsInUse.has(name);

      return {
        content: DropdownMenuOption({
          label,
          isActive,
          isUnused,
          onClick: !isUnused
            ? storeSetting({
                sizeBasedOn: selectedField,
              })
            : undefined,
        }),
        type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
      };
    });

type GetSelectSizeAttributes = (args: {
  settings: ViewSettings;
  fields: WorkspaceField[];
  fieldsInUse: Set<ArdoqId>;
  hasIncomingReferences: boolean;
  hasOutgoingReferences: boolean;
}) => DropdownItem[];

const getSelectSizeAttributes: GetSelectSizeAttributes = ({
  settings,
  fields,
  fieldsInUse,
  hasIncomingReferences,
  hasOutgoingReferences,
}) => {
  const sizeByFieldOptions: DropdownItem[] = getSizeByFieldOptions(
    settings,
    fields,
    fieldsInUse
  );

  return [
    ...(sizeByFieldOptions.length
      ? [
          {
            content: DropdownMenuSectionTitle({ label: 'Size by field' }),
            type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
          },
          ...sizeByFieldOptions,
          { type: DropdownOptionType.DIVIDER },
        ]
      : []),
    {
      content: DropdownMenuSectionTitle({
        label: 'Size by descendant count',
      }),
      type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
    },
    {
      content: DropdownMenuOption({
        label: 'Descendants',
        isActive: isByDescendants(settings),
        onClick: storeSetting({ sizeBasedOn: SizeBasedOn.CHILD_COUNT }),
      }),
      type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
    },
    { type: DropdownOptionType.DIVIDER },

    {
      content: DropdownMenuSectionTitle({
        label: 'Size by reference count',
      }),
      type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
    },
    {
      content: DropdownMenuOption({
        label: 'Incoming reference',
        isActive: isByIncomingReferences(settings),
        isUnused: !hasIncomingReferences,
        onClick: storeSetting({
          sizeBasedOn: SizeBasedOn.INCOMING_LINKS,
        }),
      }),
      type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
    },
    {
      content: DropdownMenuOption({
        label: 'Outgoing reference',
        isActive: isByOutgoingReferences(settings),
        isUnused: !hasOutgoingReferences,
        onClick: storeSetting({
          sizeBasedOn: SizeBasedOn.OUTGOING_LINKS,
        }),
      }),
      type: DropdownOptionType.CUSTOM_CONTENT_OPTION,
    },
  ];
};

const getMaxLimitOptions = (settings: ViewSettings) => [
  {
    name: 'all',
    label: 'All',
    isActive: settings.maxLimitValue === SHOW_ALL_COMPONENTS,
    onClick: storeSetting({ maxLimitValue: SHOW_ALL_COMPONENTS }),
    type: DropdownOptionType.OPTION,
  },
  ...MAX_LIMIT_VALUES.map(limitValue => ({
    name: String(limitValue),
    label: String(limitValue),
    isActive: settings.maxLimitValue === limitValue,
    onClick: storeSetting({ maxLimitValue: limitValue }),
    type: DropdownOptionType.OPTION,
  })),
];

export const getLeftMenu = (
  settings: ViewSettings,
  fields: WorkspaceField[],
  fieldsInUse: Set<ArdoqId>,
  hasIncomingReferences: boolean,
  hasOutgoingReferences: boolean
): SettingsConfig[] => {
  const { maxLimitValue } = settings;
  const selectedSizeAttributes = getSelectSizeAttributes({
    settings,
    fields,
    fieldsInUse,
    hasIncomingReferences,
    hasOutgoingReferences,
  });
  const isMaxFilterOn =
    !!maxLimitValue && maxLimitValue !== SHOW_ALL_COMPONENTS;
  return [
    {
      id: 'selectedSizeAttribute',
      label: 'Select size attribute',
      options: selectedSizeAttributes,
      type: SettingsType.DROPDOWN,
      iconName: IconName.FORMAT_LIST_BULLETED,
    },
    {
      id: 'maxLimit',
      label: !isMaxFilterOn
        ? 'Showing all components'
        : `Showing top ${maxLimitValue}`,
      options: getMaxLimitOptions(settings),
      type: SettingsType.DROPDOWN,
      iconName: IconName.LEVEL_DOWN,
      isActive: isMaxFilterOn,
    },
  ];
};
