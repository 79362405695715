import { createSvgElement } from '@ardoq/dom-utils';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import {
  ILabel,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  TextMeasurePolicy,
  TextRenderSupport,
  TextWrapping,
  Visual,
} from '@ardoq/yfiles';
import { MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT } from 'yfilesExtensions/styles/consts';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';

class CollapsedComponentGroupLabelStyle extends LabelStyleBase {
  static Instance = new CollapsedComponentGroupLabelStyle();
  protected override createVisual(
    context: IRenderContext,
    label: ILabel
  ): Visual | null {
    const { text, layout } = label;
    const { width, height } = layout;
    const textElement = createSvgElement('text');
    LabelStyleBase.createLayoutTransform(context, layout, false).applyTo(
      textElement
    );
    TextRenderSupport.addText({
      targetElement: textElement,
      text,
      font: MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
      maximumSize: new Size(width, height),
      wrapping: TextWrapping.WORD_ELLIPSIS,
      measurePolicy: TextMeasurePolicy.SVG,
    });
    return new SvgVisual(textElement);
  }
  protected override getPreferredSize(label: ILabel): Size {
    const graphGroup = label.owner?.tag as CollapsibleGraphGroup;
    const itemLabels = graphGroup.getItemLabels();
    if (!itemLabels?.mainLabel) {
      return Size.EMPTY;
    }
    const { mainLabel } = itemLabels;
    const mainLabelSize = measureLabelElement(
      mainLabel,
      MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
    );
    return mainLabelSize;
  }
}
export default CollapsedComponentGroupLabelStyle;
