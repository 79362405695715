import Backbone from 'backbone';
import { logError } from '@ardoq/logging';
import { throttle, defer } from 'lodash';
import { aggregatedGraphInstance } from 'graph/aggregatedGraph';
import { ContextShape } from '@ardoq/data-model';
import { DropdownOptionType } from '@ardoq/dropdown-menu';
import { SettingsBar } from '@ardoq/settings-bar';
import { SettingsType } from '@ardoq/view-settings';
import { getExportsForHtmlView } from '@ardoq/export';
import { getViewSettingsStream } from 'viewSettings/viewSettingsStreams';
import { handleStoreSetting } from 'viewSettings/settingsHelper';
import { hierarchicGraphInstance } from 'graph/hierarchicGraph';
import * as encodingUtils from '@ardoq/html';
import { IconName } from '@ardoq/icons';
import Components from 'collections/components';
import Context from 'context';
import Fields from 'collections/fields';
import Filters from 'collections/filters';
import getRightMenuConfig from 'viewSettings/getRightMenuConfig';
import GroupByCollection from 'collections/groupByCollection';
import { createElement } from 'react';
import References from 'collections/references';
import { ViewIds } from '@ardoq/api-types';
import { parseConditionalFormatting } from '@ardoq/view-legend';
import { viewHasLegend } from 'views/metaInfoTabs';
import { getSanitizedRgbaClassName } from '@ardoq/color-helpers';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import * as profiling from '@ardoq/profiling';
import { action$, ActionCreator, dispatchAction, ofType } from '@ardoq/rxbeach';
import { notifyViewRenderDone } from '../actions';
import { onViewSettingsUpdate } from '../onViewSettingsUpdate';
import RoadMapViewItem from './RoadMapViewItem';
import type { GraphItemModel } from 'graph/GraphItem';
import type FieldValue from 'graph/FieldValue';
import {
  clearSubscriptions,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import {
  RoadmapViewSettings,
  RoadmapViewType,
  VisualBaseViewProperties,
} from './legacyTypes';
import type { Node } from 'graph/node';
import type { ComponentBackboneModel, FieldBackboneModel } from 'aqTypes';
import type { ModelType } from 'models/ModelType';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import { createRoot } from 'react-dom/client';
import { getViewThumbnailSrc } from 'tabview/consts';
import {
  activeSlideIsOutdated,
  loadVisualizationSlideSuccess,
} from 'presentation/viewPane/actions';
import {
  notifyComponentChanged,
  notifyReferenceContextChanged,
  notifyWorkspaceChanged,
  notifyWorkspaceClosed,
} from 'streams/context/ContextActions';
import { isPresentationMode } from 'appConfig';
import UserSettings from 'models/UserSettings';
import { context$ } from 'streams/context/context$';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
} from 'streams/components/ComponentActions';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
} from 'streams/references/ReferenceActions';
import {
  notifyFiltersChanged,
  setActivePerspective,
} from 'streams/filters/FilterActions';
import { ViewInterface } from 'streams/views/mainContent/types';
import { throttleTime } from 'rxjs/operators';
import _ from 'lodash';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

const VIEW_ID = ViewIds.ROADMAP;

interface RenderRowArgs {
  tbody: HTMLElement;
  node: Node<GraphItemModel>;
  options: string[];
  field: FieldBackboneModel;
}

const THROTTLE_TIME = 100;

const getThrottledDebouncedRender = (view: RoadmapViewType) =>
  throttle(() => {
    if (view.isActive()) {
      try {
        view.debouncedRender();
      } catch (error) {
        logError(error as Error);
      }
    }
  }, THROTTLE_TIME);

const hasDimensions = (element: Element) => {
  if (!element) {
    return false;
  }
  const box = element.getBoundingClientRect();
  return box.width > 0 && box.height > 0;
};

const regExpFormattingFilter = /filter([^ ]*)/;
const FADE_CLASS_NAME = 'faded';

const fadeOut = (elements: NodeListOf<HTMLElement> | null) => {
  if (!elements?.length) return;

  elements.forEach(element => {
    element.addEventListener(
      'transitionend',
      e => {
        const target = e.currentTarget as HTMLElement;
        target.style.display = 'none';
      },
      {
        once: true,
      }
    );
    element.classList.add(FADE_CLASS_NAME);
  });
};

const fadeIn = (elements: NodeListOf<HTMLElement> | null) => {
  if (!elements?.length) return;

  elements.forEach(element => {
    element.style.display = 'block';
    setTimeout(() => element.classList.remove(FADE_CLASS_NAME), 0);
  });
};

const isFieldValue = (dataModel: GraphItemModel): dataModel is FieldValue =>
  Boolean((dataModel as FieldValue).field);

const getOptionOnClick =
  (viewId: ViewIds.ROADMAP, isActive: boolean, name: string) => () => {
    const selectedFieldName = isActive ? undefined : name;
    handleStoreSetting(viewId, { selectedFieldName });
  };

const getFieldOptions = (
  viewId: ViewIds.ROADMAP,
  viewstate: RoadmapViewSettings,
  { modelId }: ContextShape
) => {
  return Fields.collection
    .filter(
      field =>
        field.get('model') === modelId &&
        field.isListType() &&
        field.isComponentField()
    )
    .map(field => {
      const name = field.get('name');
      const isActive = viewstate.selectedFieldName === name;

      return {
        label: field.get('label') || name,
        name,
        isActive,
        onClick: getOptionOnClick(viewId, isActive, name),
        type: DropdownOptionType.OPTION,
      };
    });
};

const getLeftMenuConfig = (
  viewId: ViewIds.ROADMAP,
  viewstate: RoadmapViewSettings,
  context: ContextShape
) => [
  {
    id: 'fieldDropdown',
    label: 'Select field',
    iconName: IconName.FORMAT_LIST_BULLETED,
    options: getFieldOptions(viewId, viewstate, context),
    type: SettingsType.DROPDOWN,
  },
];

const RoadmapView = Backbone.ViewDEPRECATED.extend({
  EVENT_PRINT: 'print',
  tagName: 'div',
  MAX_PATH_LENGTH: 99,
  suppressBypassLimit: false,
  slideIsLoading: false,
  hasZoomControls: false,
  className: 'view-plugin',
  viewstate: null,
  getViewstate: function (this: RoadmapViewType) {
    return this.viewstate;
  },
  _setViewstate: function (
    this: RoadmapViewType,
    viewstate: RoadmapViewSettings
  ) {
    this.viewstate = viewstate;
    this.render();
  },
  id: VIEW_ID,
  description: 'Roadmap',
  name: 'Roadmap view',
  exampleImage: getViewThumbnailSrc(VIEW_ID),
  HTMLToPNGExport: true,
  SVGExport: true,
  SVGToPNGExport: true,
  PresentationExport: true,
  canExport: true,
  inactiveLegends: new Map(),
  events: {
    'click .legend .item': 'toggleRoadmapLegend',
  },
  toggleRoadmapLegend: function (this: RoadmapViewType, el: Event) {
    const currentTarget = el.currentTarget as HTMLElement;
    const cssClass = currentTarget.className;
    const cssSelector =
      cssClass && cssClass.indexOf('filter') > -1
        ? cssClass.replace(/.* (filter\w+).*/gi, '$1')
        : cssClass?.replace(/.* (p\d+).*/gi, '$1');

    if (!cssSelector) {
      return;
    }
    const wasInactive = this.inactiveLegends.get(cssSelector) ?? false;
    const isInactive = !wasInactive;
    this.inactiveLegends.set(cssSelector, isInactive);

    const rowContent = this.el.querySelectorAll<HTMLElement>(
      `table .${cssSelector}`
    );
    if (isInactive) {
      currentTarget.style.opacity = '0.4';
      fadeOut(rowContent);
    } else {
      currentTarget.style.opacity = '1';
      fadeIn(rowContent);
    }
  },
  pluginInit: function (
    this: RoadmapViewType,
    props: VisualBaseViewProperties
  ) {
    this.containerElement = props.containerElement;
    this.menuContainerElement = props.menuContainerElement;

    this.exports = getExportsForHtmlView({
      container: this.containerElement,
      exportedViewMetadata: {
        name: VIEW_ID,
      },
      ...getSharedExportFunctions(),
    });

    getViewSettingsStream(this.id).subscribe(viewstate =>
      this._setViewstate(viewstate)
    );

    this.listenTo(GroupByCollection, 'add change sync remove', this.render);
    this.listenTo(Components.collection, 'change', this.render);
    this.listenTo(References.collection, 'change', this.render);
  },
  getFieldByName: function (this: RoadmapViewType, name?: string) {
    const { modelId } = this.context;

    if (!name || !modelId) {
      return null;
    }
    return Fields.collection
      .filter(field => field.get('model') === modelId)
      .find(field => field.name() === name);
  },
  getNodes: function () {
    let graph, nodes;
    if (GroupByCollection.length === 0) {
      const component = Context.component();
      if (component) {
        graph = hierarchicGraphInstance.getGraphByComponent({
          component,
          maxDegreesIncoming: 0,
          maxDegreesOutgoing: 0,
          includeParents: true,
        });
      } else {
        graph = hierarchicGraphInstance.getGraphByWorkspace({
          workspaceId: Context.activeWorkspaceId() ?? '',
          maxDegreesIncoming: 0,
          maxDegreesOutgoing: 0,
        });
      }

      nodes = Array.from(graph.nodes.values());
    } else {
      graph = aggregatedGraphInstance.getFullGraph();
      /* DEFAULTING TO ENTIRE GRAPH, AS THE GROUP SHOULD OVERRIDE ANY CONTEXT.
        if (Context.component()) {
          graph = aggregatedGraphInstance.getGraphByComponent({
            component: Context.component(),
            maxDegrees: 0
          });
        } else {
          graph = aggregatedGraphInstance.getFullGraph();
        }
        */
      nodes = graph.rootNodes.flatten();
    }

    return nodes;
  },
  renderRow: function (
    this: RoadmapViewType,
    { tbody, node, options, field }: RenderRowArgs
  ) {
    if (
      this.duplicateNodeCheck![(node.dataModel as Backbone.Model).cid] ||
      !node.hasChildren() ||
      !node.isIncludedInContextByFilter()
    ) {
      return;
    }

    this.duplicateNodeCheck![(node.dataModel as Backbone.Model).cid] = true;
    const optionNodes: Record<string, Node<GraphItemModel>[]> = {};
    options.forEach(opt => {
      optionNodes[opt] = [];
    });

    let hasChildWithMatchingOption = false;
    node.children.forEach(child => {
      const fieldName = field.get('name');
      const rawValue = child.dataModel.get(fieldName);
      const fieldValues = rawValue instanceof Array ? rawValue : [rawValue];

      fieldValues.forEach(fieldValue => {
        if (fieldValue && optionNodes[fieldValue]) {
          hasChildWithMatchingOption = true;
          optionNodes[fieldValue].push(child);
        }
      });
    });

    if (hasChildWithMatchingOption) {
      const tr = document.createElement('tr');
      tbody.append(tr);

      const th = document.createElement('th');
      th.innerText = isFieldValue(node.dataModel)
        ? node.dataModel.value
        : encodingUtils.unescapeHTML(node.name());
      tr.append(th);

      options.forEach(opt => {
        const td = document.createElement('td');
        tr.append(td);
        createRoot(td).render(
          <>
            {optionNodes[opt].map(optionNode => {
              const name = optionNode.name();
              return (
                <RoadMapViewItem
                  key={name}
                  node={optionNode}
                  label={name}
                  isLegendItem={false}
                />
              );
            })}
          </>
        );
        optionNodes[opt].forEach(optionNode => {
          const laneSpanClass = optionNode.getCSS({
            useAsBackgroundStyle: true,
          });
          this.addLegendForMap(optionNode, laneSpanClass);
        });
      });
    }

    node.children.forEach(child =>
      this.renderRow({ tbody, node: child, options, field })
    );
  },
  addLegendForMap: function (
    this: RoadmapViewType,
    node: Node<GraphItemModel>,
    cssString: string
  ) {
    const [, filterColor] = regExpFormattingFilter.exec(cssString) || [
      null,
      null,
    ];
    if (filterColor && !this.legendMap![filterColor]) {
      const filter = Filters.getFormattingFilters().find(currentFilter => {
        const color = currentFilter.get('color');
        return (
          typeof color === 'string' &&
          (color === `#${filterColor}` ||
            getSanitizedRgbaClassName(color) === `filter${filterColor}`)
        );
      });
      if (filter) {
        const { filterDescription = '' } = parseConditionalFormatting(
          filter.toJSON()
        );
        this.legendMap![filterColor] = {
          node,
          isLegendItem: true,
          label: filterDescription,
        };
      }
    } else if (
      !this.legendMap![(node.dataModel as ComponentBackboneModel).getTypeId()]
    ) {
      this.legendMap![(node.dataModel as ComponentBackboneModel).getTypeId()] =
        {
          node,
          isLegendItem: true,
          label: (
            (node.dataModel as ComponentBackboneModel).getMyType() as ModelType
          ).name,
        };
    }
  },
  localRender: function (this: RoadmapViewType) {
    const transaction = profiling.startTransaction(
      'roadmap view render',
      1000,
      profiling.Team.INSIGHT
    );
    this.el.replaceChildren();
    const workspaceId = Context.activeWorkspaceId();
    this.duplicateNodeCheck = {};
    this.legendMap = {};
    this.renderSettingsBar();
    if (!workspaceId) {
      dispatchAction(notifyViewRenderDone(VIEW_ID));
      return;
    }

    const field = this.getFieldByName(this.viewstate.selectedFieldName);
    if (!field) {
      dispatchAction(notifyViewRenderDone(VIEW_ID));
      return;
    }

    const nodes = this.getNodes();
    let options: string[] = [];
    if (field.isListType()) {
      options = field.getAllowedValues();
    } else {
      return;
    }

    const legendContainer = document.createElement('div');
    legendContainer.classList.add('legend');
    this.getContainerElement().append(legendContainer);

    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');
    const tr = document.createElement('tr');

    this.getContainerElement().append(table);
    table.append(thead, tbody);
    table.setAttribute('data-context-menu', OBJECT_CONTEXT_MENU_NAME);
    thead.append(tr);
    tr.append(document.createElement('th'));
    options.forEach(option => {
      const th = document.createElement('th');
      th.classList.add('header');
      th.textContent = option;
      tr.append(th);
    });

    nodes.forEach(node => {
      if (!node.isIncludedInContextByFilter()) {
        return;
      }

      this.renderRow({ node, tbody, options, field });
    });
    createRoot(legendContainer).render(
      <>
        {Object.values(this.legendMap).map(props =>
          createElement(RoadMapViewItem, props)
        )}
      </>
    );

    profiling.endTransaction(transaction, {
      viewId: VIEW_ID,
      metadata: {
        nodes: nodes.length,
      },
    });
    dispatchAction(notifyViewRenderDone(VIEW_ID));
  },
  renderSettingsBar: function (this: RoadmapViewType) {
    createRoot(this.menuContainerElement).render(
      createElement(SettingsBar, {
        viewId: this.id,
        leftMenu: getLeftMenuConfig(this.id, this.viewstate, this.context),
        rightMenu: getRightMenuConfig({
          viewId: this.id,
          viewstate: this.viewstate,
          exports: this.exports,
          withLegend: viewHasLegend(this.id),
          knowledgeBaseLink: KnowledgeBaseLink.ROADMAP,
          onViewSettingsUpdate,
        }),
      })
    );
  },

  cleanUp: function () {
    // removes all event listeners from element(s)
    // https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
    this.el
      .querySelectorAll('div.group, div.ref')
      .forEach((element: HTMLElement) => {
        element.replaceWith(element.cloneNode(true));
      });
  },

  remove: function (this: RoadmapViewType) {
    window.removeEventListener('resize', this.boundOnResize);
    this._userSettings = null;
    this.streamSubscriptions = clearSubscriptions(this.streamSubscriptions);
    return Backbone.ViewDEPRECATED.prototype.remove.call(this);
  },
  getContainerElement: function (this: RoadmapViewType) {
    if (!this.pluginContainerElement) {
      this.el.id = `_plugin${this.id}`;
      this.el.classList.add('roadmap-view');
      this.containerElement.append(this.el);
      this.pluginContainerElement = this.el;
    }
    return this.pluginContainerElement;
  },
  autoZoomToFit: function () {
    this.zoomFit?.();
  },
  localInit: function (this: RoadmapViewType, props: VisualBaseViewProperties) {
    // Plugin menus are added in the plugin itself
    this.styles = {};
    this.streamSubscriptions.push(
      context$.subscribe(updatedContext => (this.context = updatedContext))
    );
    this.streamSubscriptions.push(
      subscribeToAction(notifyWorkspaceClosed, () => {
        this.getContainerElement()
          .querySelector('ul.incoming')
          ?.replaceChildren();
        this.cleanUp();
      })
    );
    const tdr = getThrottledDebouncedRender(this);
    const rerenderActions: ActionCreator<any>[] = [
      notifyReferencesRemoved,
      notifyWorkspaceChanged,
      notifyReferenceContextChanged,
      notifyComponentChanged,
      notifyComponentsRemoved,
      notifyComponentsRemoved,
      notifyComponentsAdded,
      notifyReferencesAdded,
    ];
    this.streamSubscriptions.push(
      ...rerenderActions.map(action => subscribeToAction(action, tdr))
    );
    this.streamSubscriptions.push(
      action$
        .pipe(
          ofType(notifyFiltersChanged, setActivePerspective),
          throttleTime(THROTTLE_TIME)
        )
        .subscribe(() => this.debouncedRender())
    );

    this.pluginInit(props);
  },
  render: function (
    this: RoadmapViewType,
    model: unknown,
    event: unknown,
    callback: unknown,
    options: unknown
  ) {
    if (!this.context.workspaceId) {
      return;
    }

    if (
      this.isActive() &&
      this.isVisible() &&
      (!this.plugin || this.hasLoadedPlugin)
    ) {
      this._rendering = true;
      try {
        defer(() => {
          this.localRender(model, event, callback, options);
          this._rendering = false;
        });
      } catch (error) {
        logError(error as Error, 'Error while redrawing view plugin', {
          viewPluginName: this.name,
        });
      }
    }
  },

  isActive: function () {
    const containerElement = this.containerElement;
    return (
      !this.slideIsLoading &&
      document.documentElement.contains(containerElement) &&
      hasDimensions(containerElement)
    );
  },
  isVisible: function (this: RoadmapViewType) {
    const containerElementParent = this.containerElement.parentElement;
    return Boolean(
      containerElementParent &&
        (containerElementParent.offsetWidth ||
          containerElementParent.offsetHeight ||
          containerElementParent.getClientRects().length)
    );
  },
  initialize: function (
    this: RoadmapViewType,
    props: VisualBaseViewProperties
  ) {
    this._userSettings = new UserSettings(this.id);
    this.streamSubscriptions = [];
    if (props) {
      this.embeddableViewConfig = props.embeddableViewConfig;
      this.containerElement = props.containerElement;
      this.menuContainerElement = props.menuContainerElement;
      this.allowOverflow = props.allowOverflow || false;
      if (this.allowOverflow) {
        this.containerElement.style.overflow = 'scroll';
      }
    }
    this.getViewContainer = () =>
      this.containerElement ? this.containerElement.closest('.tab-pane') : null;
    this.debouncedRender = _.debounce(this.render, 150);

    this.localInit(props);

    this.boundOnResize = this.onResize.bind(this);
    window.addEventListener('resize', this.boundOnResize);

    if (isPresentationMode()) {
      this.streamSubscriptions.push(
        action$.subscribe(({ type }) => {
          if (type === activeSlideIsOutdated.type) {
            this.slideIsLoading = true;
          } else if (type === loadVisualizationSlideSuccess.type) {
            this.slideIsLoading = false;
          }
        })
      );
    }
  },
  onResize: function () {
    if (this.isActive()) {
      this.debouncedRender();
    }
  },
  deselected: function () {
    if (this.stop) {
      this.stop();
    }

    this.containerElement.querySelector('ul.incoming')?.replaceChildren();

    if (!this._rendering) {
      this.cleanUp();
    }
  },
});

export const id = VIEW_ID;
export const view = RoadmapView;
export const create = (props: VisualBaseViewProperties): ViewInterface =>
  new RoadmapView(props);
