import { Controller, InteractionMode } from '../types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  DEFAULT_VIEW_CONTEXT,
  DisabledZoomControls,
  updateViewContext,
} from '@ardoq/view-settings';
import { viewLegendCommands } from '@ardoq/view-legend';
import { ViewIds } from '@ardoq/api-types';
import { createEditInteractions } from './editInteractions';
import { createViewInteractions } from './viewInteractions';

export type RelationshipsViewLegendRef = {
  setIsVisible: (isVisible: boolean) => void;
  getIsVisible: () => boolean;
};
type ViewContextDependencies = {
  controllerRef: React.MutableRefObject<Controller | null>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  isLegendActive?: boolean;
};
const createViewContext = ({
  controllerRef,
  containerRef,
  isLegendActive = false,
}: ViewContextDependencies) => ({
  ...DEFAULT_VIEW_CONTEXT,
  getContainer: () => containerRef.current,
  createExportCanvas: () => controllerRef.current?.createExportCanvas() ?? null,
  zoomControls: controllerRef.current?.viewActionsConfigs() ?? [],
  zoomControlsDisabledState: DisabledZoomControls.NONE,
  toggleLegend: () => {
    viewLegendCommands.updateViewLegendSettings({
      viewId: ViewIds.BLOCKS,
      isActive: !isLegendActive,
    });
  },
  isLegendActive: isLegendActive,
});

export const updateController = ({
  controllerRef,
  containerRef,
  isLegendActive,
}: ViewContextDependencies) => {
  /* this is called by the controller when it wants to persuade the app to refresh the view context */
  /* But it seems to do more than that? Not sure what exactly though */

  const controller = controllerRef.current;

  if (!controller) {
    return;
  }

  const interactionMode = controller.getInteractionMode();
  const interactions =
    interactionMode === InteractionMode.Edit
      ? createEditInteractions(controller, controller.graph)
      : createViewInteractions(controller, controller.graph);

  controller.attach(interactions);
  controller.renderCanvas();

  createAndUpdateViewContext({
    controllerRef,
    containerRef,
    isLegendActive,
  });
};

export const createAndUpdateViewContext = (
  viewContextDependencies: ViewContextDependencies
) =>
  dispatchAction(updateViewContext(createViewContext(viewContextDependencies)));
