import * as d3 from 'd3';
import { throttle } from 'lodash';
import { transformViewCanvas } from './util';

const handleZoom = (viewInstanceId: string) =>
  throttle(
    ({ transform }: d3.D3ZoomEvent<Element, unknown>) => {
      transformViewCanvas(transform, viewInstanceId);
    },
    10,
    { leading: true, trailing: true }
  );
const createZoom = (viewInstanceId: string) =>
  d3.zoom<HTMLCanvasElement, unknown>().on('zoom', handleZoom(viewInstanceId));

export const initializeZoom = (
  canvas: HTMLCanvasElement,
  viewInstanceId: string
) => {
  const zoom = createZoom(viewInstanceId);
  d3.select(canvas).call(zoom).on('dblclick.zoom', null);
  return zoom;
};
