import {
  queryGremlinSearch,
  queryGremlinSearchError,
  queryGremlinSearchSuccess,
  queryGremlinSearchWarning,
  resetGremlinResults,
  toggleRawResults,
  updateResultsReturnLocation,
} from './actions';
import { GremlinSearchStateShape } from './types';
import { ExtractPayload, reducer } from '@ardoq/rxbeach';

export const defaultState: GremlinSearchStateShape = {
  results: null,
  showRawResults: false,
  syntaxError: null,
  searchError: false,
  searchWarning: null,
  returnLocation: null,
  totalResults: null,
};

const handleToggleRawResults = (
  currentState: GremlinSearchStateShape,
  { showRawResults }: ExtractPayload<typeof toggleRawResults>
) => ({
  ...currentState,
  showRawResults,
});

const handleSearch = (
  currentState: GremlinSearchStateShape
): GremlinSearchStateShape => ({
  ...currentState,
  syntaxError: null,
  searchError: false,
  searchWarning: null,
});

const handleSearchSuccess = (
  currentState: GremlinSearchStateShape,
  { results, totalResults }: ExtractPayload<typeof queryGremlinSearchSuccess>
): GremlinSearchStateShape => ({
  ...currentState,
  syntaxError: null,
  searchError: false,
  results,
  totalResults,
});

const handleSearchError = (
  currentState: GremlinSearchStateShape,
  { syntaxError }: ExtractPayload<typeof queryGremlinSearchError>
): GremlinSearchStateShape => ({
  ...currentState,
  searchError: true,
  syntaxError,
  results: null,
});

const handleSearchWarning = (
  currentState: GremlinSearchStateShape,
  { searchWarning }: ExtractPayload<typeof queryGremlinSearchWarning>
) => ({
  ...currentState,
  searchWarning,
});

const handleReturnLocation = (
  currentState: GremlinSearchStateShape,
  { returnLocation }: ExtractPayload<typeof updateResultsReturnLocation>
) => ({
  ...currentState,
  returnLocation,
});

const handleResetGremlinResults = () => ({
  ...defaultState,
});

const reducers = [
  reducer(updateResultsReturnLocation, handleReturnLocation),
  reducer(queryGremlinSearch, handleSearch),
  reducer(queryGremlinSearchSuccess, handleSearchSuccess),
  reducer(queryGremlinSearchError, handleSearchError),
  reducer(queryGremlinSearchWarning, handleSearchWarning),
  reducer(toggleRawResults, handleToggleRawResults),
  reducer(resetGremlinResults, handleResetGremlinResults),
];

export default reducers;
