import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoReferencesDoqProps = { doqType: DoqType };

const NoReferencesDoq = ({ doqType }: NoReferencesDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No reference to show"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            This context does not have any references.
          </FlexBox>
          <FlexBox gap="medium">
            To use this view, select a context or workspace with at least one
            component referencing another.
          </FlexBox>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoReferencesDoq;
