import { type DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import { type ButtonConfig, SettingsType } from '@ardoq/view-settings';
import { trackEvent } from 'tracking/tracking';
import { ViewIds } from '@ardoq/api-types';

type JsonSerializable =
  | string
  | number
  | boolean
  | null
  | JsonSerializable[]
  | { [key: string]: JsonSerializable };

type ViewActionDropdownSettings = Pick<DropdownOption, 'label' | 'onClick'> &
  Pick<ButtonConfig, 'label' | 'onClick'>;
type ViewActionArgs = ViewActionDropdownSettings & {
  viewActionName: string;
  viewActionValue?: JsonSerializable;
  settingsCategory: string;
};

const viewAction = ({
  onClick,
  viewActionName,
  viewActionValue,
  settingsCategory,
  ...args
}: ViewActionArgs) => ({
  ...args,
  onClick: (event: React.MouseEvent<HTMLDivElement>) => {
    trackEvent('View action', {
      viewId: ViewIds.INTERACTIVE_BLOCKS,
      viewActionName,
      viewActionValue,
      settingsCategory,
    });
    onClick?.(event);
  },
});

type ViewActionOptionArgs = ViewActionArgs & Omit<DropdownOption, 'type'>;
const viewActionOption = ({
  leftIcon,
  ...args
}: ViewActionOptionArgs): DropdownOption => ({
  type: DropdownOptionType.OPTION,
  leftIcon,
  ...viewAction(args),
});

type ViewActionButtonArgs = ViewActionArgs & Omit<ButtonConfig, 'type'>;
const viewActionButton = ({
  id,
  iconName,
  ...args
}: ViewActionButtonArgs): ButtonConfig => ({
  type: SettingsType.BUTTON,
  id,
  iconName,
  ...viewAction(args),
});

type ApplyLayoutViewActionOptionArgs = Omit<
  ViewActionOptionArgs,
  'settingsCategory'
>;
export const layoutSettingViewActionOption = (
  args: ApplyLayoutViewActionOptionArgs
) =>
  viewActionOption({
    ...args,
    settingsCategory: 'layoutSetting',
  });

type ApplyLayoutViewActionButtonArgs = Omit<
  ViewActionButtonArgs,
  'settingsCategory'
>;
export const layoutSettingViewActionButton = (
  args: ApplyLayoutViewActionButtonArgs
) =>
  viewActionButton({
    ...args,
    settingsCategory: 'layoutSetting',
  });
