import { LabelCreator } from './LabelCreator';
import { FreeEdgeLabelModel } from '@ardoq/yfiles';

export class EdgeLabelCreator extends LabelCreator {
  static instance = new EdgeLabelCreator();
  static urlEdgeButtonModelParameter =
    new FreeEdgeLabelModel().createDefaultParameter();
  private constructor() {
    super();
  }
  override getLayoutParameter() {
    return EdgeLabelCreator.urlEdgeButtonModelParameter;
  }
}
