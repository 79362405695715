import { dispatchAction } from '@ardoq/rxbeach';
import {
  CircularLayoutStyle,
  HierarchicLayoutOrientation,
  ProteanLayoutOptions,
  ProteanLayoutType,
  RadialLayoutLayeringStrategy,
  SpatialMapLayoutOptions,
} from 'tabview/proteanDiagram/types';
import { getFieldDropdownOptions } from '@ardoq/settings-bar';
import { type DropdownConfig, SettingsType } from '@ardoq/view-settings';
import { updateViewSettings } from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { LeftMenuConfigProps } from './types';
import { LAYOUT_TYPE_LABELS } from './consts';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionType,
  DropdownSliderOption,
  DropdownSubmenu,
} from '@ardoq/dropdown-menu';
import { debounce, merge } from 'lodash';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';

const LAYOUT_TYPE_ICONS: Record<ProteanLayoutType, IconName> = {
  [ProteanLayoutType.Circular]: 'directions_boat' as IconName,
  [ProteanLayoutType.Hierarchic]: 'directions_bike' as IconName,
  [ProteanLayoutType.Organic]: 'directions_railway' as IconName,
  [ProteanLayoutType.Orthogonal]: 'directions_bus' as IconName,
  [ProteanLayoutType.Radial]: 'directions_walk' as IconName,
  [ProteanLayoutType.CompactDisk]: 'album' as IconName,
  [ProteanLayoutType.Swimlanes]: 'directions_car' as IconName,
  [ProteanLayoutType.SpatialMap]: 'map' as IconName,
  [ProteanLayoutType.GeographicMap]: 'public' as IconName,
  [ProteanLayoutType.Cactus]: 'eco' as IconName,
  [ProteanLayoutType.Tree]: IconName.ACCOUNT_TREE,
  [ProteanLayoutType.TreeMap]: IconName.SQUARE,
  [ProteanLayoutType.HierarchicInGrid]: IconName.TABLE_CHART,
  [ProteanLayoutType.Tabular]: IconName.TABLE_CHART,
};

export const HIERARCHIC_LAYOUT_ORIENTATIONS: Record<
  HierarchicLayoutOrientation,
  string
> = {
  'top-to-bottom': 'Top to bottom',
  'bottom-to-top': 'Bottom to top',
  'left-to-right': 'Left to right',
  'right-to-left': 'Right to left',
};
const RADIAL_LAYERING_STRATEGIES: Record<RadialLayoutLayeringStrategy, string> =
  {
    bfs: 'BFS',
    dendrogram: 'Dendrogram',
    hierarchical: 'Hierarchical',
  };
const CIRCULAR_LAYOUT_STYLES: Record<CircularLayoutStyle, string> = {
  'bcc-compact': 'BCC Compact',
  'bcc-isolated': 'BCC Isolated',
  'custom-groups': 'Custom groups',
  'single-cycle': 'Single cycle',
};
const getLayoutOptionsOptions = ({
  layoutType,
  numericFields,
  changeLayout,
  referenceTypes,
  nodeRepresentationData,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'layoutType'
  | 'numericFields'
  | 'changeLayout'
  | 'referenceTypes'
  | 'nodeRepresentationData'
  | 'state'
>): DropdownItem[] => {
  const { viewId, viewSettings } = state;
  const getOnClick =
    (newLayoutOptions: Partial<ProteanLayoutOptions>) => () => {
      state.viewSettings.layoutOptions = merge(
        {},
        state.viewSettings.layoutOptions,
        newLayoutOptions
      );
      changeLayout({
        referenceTypes,
        nodeRepresentationData,
        state,
      });
    };

  const getToggleOption = <
    L extends keyof ProteanLayoutOptions,
    P extends keyof ProteanLayoutOptions[L],
  >(
    layoutKey: L,
    propertyKey: P,
    label: string
  ): DropdownOption => {
    const layoutOptions: Partial<ProteanLayoutOptions[L]> | undefined =
      state.viewSettings.layoutOptions[layoutKey];
    const propertyValue = Boolean(layoutOptions?.[propertyKey]);

    return {
      name: String(propertyKey),
      label,
      type: DropdownOptionType.OPTION,
      isActive: propertyValue,
      onClick: getOnClick({
        [layoutKey]: {
          [propertyKey]: !propertyValue,
        },
      }),
    };
  };
  const getSliderOption = (
    name: string,
    label: string,
    changedSettings: (value: number) => Partial<ProteanLayoutOptions>,
    initialValue: number,
    min: number,
    max: number,
    step: number
  ): DropdownSliderOption => ({
    name,
    label,
    type: DropdownOptionType.SLIDER,
    onValueChange: debounce(
      (value: number) => {
        state.viewSettings.layoutOptions = merge(
          {},
          state.viewSettings.layoutOptions,
          changedSettings(value)
        );
        changeLayout({
          referenceTypes,
          nodeRepresentationData,
          state,
        });
      },
      50,
      { leading: false, trailing: true }
    ),
    value: initialValue,
    min,
    max,
    step,
  });
  const getSliderOptionForProperty = <
    L extends keyof ProteanLayoutOptions,
    P extends keyof ProteanLayoutOptions[L],
  >(
    layoutKey: L,
    propertyKey: P,
    label: string,
    minimum: number,
    maximum: number,
    increment: number
  ) => {
    const layoutOptions: Partial<ProteanLayoutOptions[L]> | undefined =
      state.viewSettings.layoutOptions[layoutKey];
    const defaultOptions: ProteanLayoutOptions[L] =
      PROTEAN_DEFAULT_LAYOUT_OPTIONS[layoutKey];
    const currentValue = Number(layoutOptions?.[propertyKey] ?? 0);
    return getSliderOption(
      String(propertyKey),
      label,
      value => ({
        [layoutKey]: {
          ...defaultOptions,
          ...layoutOptions,
          [propertyKey]: value,
        },
      }),
      currentValue,
      minimum,
      maximum,
      increment
    );
  };
  const getListOption = <
    L extends keyof ProteanLayoutOptions,
    P extends keyof ProteanLayoutOptions[L],
    PT extends string & ProteanLayoutOptions[L][P],
    O extends Record<PT, string>,
  >(
    layoutKey: L,
    propertyKey: P,
    label: string,
    options: O
  ): DropdownSubmenu => {
    const layoutOptions: Partial<ProteanLayoutOptions[L]> | undefined =
      state.viewSettings.layoutOptions[layoutKey];
    const propertyValue = layoutOptions?.[propertyKey];

    return {
      name: String(propertyKey),
      label,
      type: DropdownOptionType.SUBMENU,
      options: Object.entries(options).map(([option, optionLabel]) => {
        const isActive = propertyValue === option;
        return {
          name,
          label: optionLabel,
          type: DropdownOptionType.OPTION,
          isActive,
          onClick: getOnClick({
            [layoutKey]: {
              [propertyKey]: option,
            },
          }),
        };
      }),
    };
  };
  switch (layoutType) {
    case ProteanLayoutType.Hierarchic: {
      const orientationOption = getListOption(
        'hierarchic',
        'orientation',
        'Orientation',
        HIERARCHIC_LAYOUT_ORIENTATIONS
      );

      const options: DropdownItem[] = [
        getToggleOption(
          'hierarchic',
          'orthogonalRouting',
          'Orthogonal routing'
        ),
        getToggleOption('hierarchic', 'compactGroups', 'Compact groups'),
        orientationOption,
        getToggleOption(
          'hierarchic',
          'separateReferences',
          'Separate references'
        ),
        getToggleOption('hierarchic', 'directedEdges', 'Directed edges'),
        getToggleOption(
          'hierarchic',
          'recursiveGroupLayering',
          'Recursive group layering'
        ),
        getToggleOption('hierarchic', 'separateLayers', 'Separate layers'),
        getSliderOptionForProperty(
          'hierarchic',
          'minimumLayerDistance',
          'Minimum layer distance',
          0,
          1000,
          10
        ),
        getSliderOptionForProperty(
          'hierarchic',
          'nodeToNodeDistance',
          'Node to node distance',
          0,
          1000,
          10
        ),
      ];

      return options;
    }
    case ProteanLayoutType.Organic: {
      const options: DropdownItem[] = [
        getToggleOption(
          'organic',
          'nodeEdgeOverlapAvoided',
          'Avoid node/edge overlap'
        ),
        getSliderOptionForProperty(
          'organic',
          'compactnessFactor',
          'Compactness',
          0,
          1,
          0.1
        ),
      ];
      return options;
    }
    case ProteanLayoutType.Circular: {
      const edgeBundlingSlider = getSliderOptionForProperty(
        'circular',
        'edgeBundling',
        'Edge bundling',
        0,
        1,
        0.1
      );
      const layoutStyleOption = getListOption(
        'circular',
        'layoutStyle',
        'Layout style',
        CIRCULAR_LAYOUT_STYLES
      );
      const options: DropdownItem[] = [edgeBundlingSlider, layoutStyleOption];
      return options;
    }
    case ProteanLayoutType.Radial: {
      const layeringStrategyOption = getListOption(
        'radial',
        'layeringStrategy',
        'Layering strategy',
        RADIAL_LAYERING_STRATEGIES
      );
      const edgeBundlingSlider = getSliderOptionForProperty(
        'radial',
        'edgeBundling',
        'Edge bundling',
        0,
        1,
        0.1
      );
      const options: DropdownItem[] = [
        layeringStrategyOption,
        edgeBundlingSlider,
      ];
      return options;
    }
    case ProteanLayoutType.Orthogonal: {
      const gridSpacingSlider = getSliderOptionForProperty(
        'orthogonal',
        'gridSpacing',
        'Grid spacing',
        1,
        100,
        1
      );
      const options: DropdownItem[] = [
        gridSpacingSlider,
        getToggleOption(
          'orthogonal',
          'crossingReduction',
          'Crossing reduction'
        ),
        getToggleOption(
          'orthogonal',
          'edgeLengthReduction',
          'Edge length reduction'
        ),
        getToggleOption(
          'orthogonal',
          'optimizePerceivedBends',
          'Optimize perceived bends'
        ),
        getToggleOption(
          'orthogonal',
          'uniformPortAssignment',
          'Uniform port assignment'
        ),
      ];
      return options;
    }
    case ProteanLayoutType.GeographicMap:
    case ProteanLayoutType.SpatialMap: {
      const selectedFieldNameProperties: (keyof SpatialMapLayoutOptions)[] = [
        'selectedFieldNameX',
        'selectedFieldNameY',
      ];
      const [selectedFieldNameXIsActive, selectedFieldNameYIsActive] =
        selectedFieldNameProperties.map(
          key => (fieldName: string) =>
            (viewSettings.layoutOptions.spatialMap ??
              ({} as Record<string, undefined>))[key] === fieldName
        );

      const options: DropdownItem[] = [
        {
          name: 'xField',
          label: 'Select X field',
          iconName: IconName.ARROW_RIGHT_ALT,
          options: getFieldDropdownOptions(
            viewId,
            numericFields.map(field => ({
              ...field,
              onClick: () => {
                dispatchAction(
                  updateViewSettings({
                    viewId,
                    settings: {
                      layoutOptions: {
                        ...viewSettings.layoutOptions,
                        spatialMap: {
                          ...viewSettings.layoutOptions.spatialMap,
                          selectedFieldNameX: selectedFieldNameXIsActive(
                            field.name
                          )
                            ? ''
                            : field.name,
                        },
                      },
                    },
                    persistent: true,
                  })
                );
                dispatchAction(
                  updateViewSettings({
                    viewId,
                    // eslint-disable-next-line camelcase
                    settings: { dont_ignore_me: 'thanks' },
                    persistent: false,
                  })
                );
              },
            })),
            'layoutOptions.spatialMap.selectedFieldNameX',
            selectedFieldNameXIsActive,
            onViewSettingsUpdate
          ),
          type: DropdownOptionType.SUBMENU,
        },
        {
          name: 'yField',
          label: 'Select Y field',
          iconName: IconName.ARROW_UP,
          options: getFieldDropdownOptions(
            viewId,
            numericFields.map(field => ({
              ...field,
              onClick: () => {
                dispatchAction(
                  updateViewSettings({
                    viewId,
                    settings: {
                      layoutOptions: {
                        ...viewSettings.layoutOptions,
                        spatialMap: {
                          ...viewSettings.layoutOptions.spatialMap,
                          selectedFieldNameY: selectedFieldNameYIsActive(
                            field.name
                          )
                            ? ''
                            : field.name,
                        },
                      },
                    },
                    persistent: true,
                  })
                );
                dispatchAction(
                  updateViewSettings({
                    viewId,
                    // eslint-disable-next-line camelcase
                    settings: { dont_ignore_me: 'thanks' },
                    persistent: false,
                  })
                );
              },
            })),
            'layoutOptions.spatialMap.selectedFieldNameY',
            selectedFieldNameYIsActive,
            onViewSettingsUpdate
          ),
          type: DropdownOptionType.SUBMENU,
        },
      ];
      return options;
    }
    case ProteanLayoutType.Swimlanes:
      return [getToggleOption('swimlanes', 'isVertical', 'Vertical')];
    case ProteanLayoutType.Tabular:
    case ProteanLayoutType.HierarchicInGrid:
      return [
        getSliderOptionForProperty(
          'tabular',
          'columnInsets',
          'Column insets',
          0,
          500,
          10
        ),
        getSliderOptionForProperty(
          'tabular',
          'rowInsets',
          'Row insets',
          0,
          500,
          10
        ),
        getSliderOptionForProperty(
          'tabular',
          'minColumnSize',
          'Minimum column size',
          0,
          500,
          10
        ),
        getSliderOptionForProperty(
          'tabular',
          'minRowSize',
          'Minimum row size',
          0,
          500,
          10
        ),
        getToggleOption('tabular', 'separateReferences', 'Separate references'),
      ] satisfies DropdownItem[];
    default:
      return [];
  }
};
const getLayoutOptionsDropdown = ({
  layoutType,
  numericFields,
  changeLayout,
  referenceTypes,
  nodeRepresentationData,
  state,
}: Pick<
  LeftMenuConfigProps,
  | 'layoutType'
  | 'numericFields'
  | 'changeLayout'
  | 'referenceTypes'
  | 'nodeRepresentationData'
  | 'state'
>): DropdownConfig => ({
  id: 'layoutOptions',
  type: SettingsType.DROPDOWN,
  label: `${LAYOUT_TYPE_LABELS[layoutType]} layout options`,
  iconName: LAYOUT_TYPE_ICONS[layoutType],
  options: getLayoutOptionsOptions({
    layoutType,
    numericFields,
    changeLayout,
    referenceTypes,
    nodeRepresentationData,
    state,
  }),
});

export default getLayoutOptionsDropdown;
