import type { INode } from '@ardoq/yfiles';
import { isContextGraphNode } from '../nodeDecorator';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { type GraphNode, ensureRoot } from '@ardoq/graph';
import ModernizedNodeStyle from './ModernizedNodeStyle';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import ArdoqNode from './ArdoqNode';
import { createElement } from 'react';
import { DiffType } from '@ardoq/data-model';
import { componentInterface } from '@ardoq/component-interface';
import { MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER } from 'tabview/blockDiagram/view/yFilesExtensions/modernized/consts';
import leafNodeShapeY from './leafNodeShapeY';

export default class ArdoqImageStyle extends ModernizedNodeStyle {
  private imageUrl: string;

  constructor(imageUrl: string) {
    super();
    this.imageUrl = imageUrl;
  }

  protected override render(container: SVGElement, node: INode) {
    const { tag } = node;
    const graphNode = tag as GraphNode;
    const visualDiffType = graphNode.getVisualDiffType();
    const { isGhost, modelId } = graphNode;
    const visualDiffClass =
      getCssClassFromDiffType(visualDiffType) ||
      (isGhost ? 'visual-diff-unchanged' : '');

    const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
    const isContext = isContextGraphNode(graphNode);
    const tooltipAttributes = getChangedPopover(modelId, visualDiffType);
    if (modelId) {
      container.setAttribute(COMPONENT_ID_ATTRIBUTE, modelId);
      container.setAttribute(
        'class',
        graphNode.getCSS({ useAsBackgroundStyle: false })
      );
    }
    const { fill } = componentInterface.getComponentDisplayColorAsSVGAttributes(
      modelId,
      { useAsBackgroundStyle: false }
    );
    ensureRoot(container).render(
      createElement(ArdoqNode, {
        imageHref: this.imageUrl,
        isPlaceholder,
        isContext,
        isTransparentized: node.tag.isTransparentized,
        visualDiffClass,
        tooltipAttributes,
        color: fill || 'transparent',
        shapeY: leafNodeShapeY(
          node,
          MODERNIZED_BLOCK_DIAGRAM_LEAF_NODE_CIRCLE_DIAMETER,
          false
        ),
      })
    );
  }
}
