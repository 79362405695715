import { APISurveyAttributes, ArdoqId, AssetType } from '@ardoq/api-types';
import { formatDateNew } from '@ardoq/date-time';
import { TextOverflow, WithPopover } from '@ardoq/popovers';
import { Column, DatasourceTable, newTableTheme, RowData } from '@ardoq/table';
import { Link } from '@ardoq/typography';
import styled from 'styled-components';
import { trackOpenExistingSurvey } from '../../surveyAdmin/tracking';
import { surveyAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/surveys';
import { getCurrentLocale } from '@ardoq/locale';
import {
  getContactEmailRender,
  getSurveyStatusRender,
} from '../../surveyAdmin/SurveyOverview/columns';

const StyledTextOverflow = styled(TextOverflow)`
  width: 190px;
`;

const SURVEY_LINK = '/app/survey-admin/edit/';

type AffectedEntityType = AssetType.SURVEY;

type AffectedEntities = APISurveyAttributes[];

const getSurveyLink = (id: ArdoqId) => {
  const hasEditAccess = surveyAccessControlInterface.canEditSurvey(id);
  return (
    <WithPopover
      content={!hasEditAccess && 'You do not have access to edit this survey'}
    >
      <Link
        onClick={() => {
          if (hasEditAccess) {
            trackOpenExistingSurvey(id, 'deleteAssetModal');
            window.open(`${SURVEY_LINK}${id}`, '_blank');
          }
        }}
        type={hasEditAccess ? 'primary' : 'secondary'}
      >
        Open
      </Link>
    </WithPopover>
  );
};

const getSurveyColumns = () => [
  {
    title: `Survey name`,
    dataIndex: 'name',
    valueRender: (name: string) => (
      <StyledTextOverflow renderIn="popover">{name}</StyledTextOverflow>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'published',
    valueRender: getSurveyStatusRender,
  },
  {
    title: 'Contact email',
    dataIndex: 'contactEmail',
    valueRender: getContactEmailRender,
  },
  {
    title: 'Date modified',
    dataIndex: 'lastUpdated',
    valueRender: (value: string) => formatDateNew(value, getCurrentLocale()),
  },
  {
    title: '',
    dataIndex: '_id',
    valueRender: (id: string) => getSurveyLink(id),
  },
];

const getEntityColumns = (
  entityType: AffectedEntityType
): Column<RowData>[] => {
  switch (entityType) {
    case AssetType.SURVEY:
      return getSurveyColumns();
  }
};

type AffectedEntitiesTableProps = {
  affectedEntities: AffectedEntities;
  affectedEntityType: AffectedEntityType;
};

/**
 * Currently only supports surveys
 */
const AffectedEntitiesTable = ({
  affectedEntities,
  affectedEntityType,
}: AffectedEntitiesTableProps) => {
  return (
    <DatasourceTable
      components={newTableTheme}
      dataSource={affectedEntities}
      columns={getEntityColumns(affectedEntityType)}
    />
  );
};

export default AffectedEntitiesTable;
