import {
  ComponentProps,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { SettingsBar, replaceLegendButton } from '@ardoq/settings-bar';

type RelationshipsViewSettingsBarProperties = Omit<
  ComponentProps<typeof SettingsBar>,
  'isLegendActive'
> & { isLegendInitiallyActive: boolean };
interface RelationshipsViewSettingsBarRef {
  setIsLegendActive: (isLegendActive: boolean) => void;
}
const RelationshipsViewSettingsBar = forwardRef<
  RelationshipsViewSettingsBarRef,
  RelationshipsViewSettingsBarProperties
>((props, ref) => {
  const { isLegendInitiallyActive, rightMenu } = props;
  const [isLegendActive, setIsLegendActive] = useState(isLegendInitiallyActive);

  useImperativeHandle(ref, () => ({ setIsLegendActive }));
  return (
    <SettingsBar
      {...props}
      rightMenu={replaceLegendButton(isLegendActive, rightMenu)}
    />
  );
});
export default RelationshipsViewSettingsBar;
