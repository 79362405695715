import { connectInstance, dispatchAction } from '@ardoq/rxbeach';
import {
  addDismisssedEphemeralNotification,
  ephemeralNotificationRendered,
} from './actions';
import { useCallback, useEffect } from 'react';
import { trackEphemeralNotificationButtonClick } from './tracking';
import { CarouselFeatures } from '@ardoq/onboarding-ui';
import {
  EphemeralFeaturesCarouselConfig,
  EphemeralNotificationKeys,
  EphemeralNotificationStreamedProps,
} from './types';
import { isCarouselFeaturesConfig } from './predicates';
import { ephemeralNotification$ } from './ephemeralNotification$';
import { modal } from '@ardoq/modal';
import { returnFalse } from '@ardoq/common-helpers';

const registerDismissed = (
  notificationId: EphemeralNotificationKeys,
  instanceId: string
) =>
  dispatchAction(
    addDismisssedEphemeralNotification(instanceId, notificationId)
  );
const onActionButtonClick = (
  notificationConfig: EphemeralFeaturesCarouselConfig,
  instanceId: string
) => {
  registerDismissed(notificationConfig.id, instanceId);
  trackEphemeralNotificationButtonClick({
    button: 'action',
    notificationId: notificationConfig.id,
  });

  notificationConfig.onActionButtonClick?.();
};

/**
 * Ephemeral modals are modals that are relevant for only a finite period of time,
 * for a subset of users, and are shown only once per lifetime for every relevant user.
 *
 * After an ephemeral modal is expired, a warning shall be logged, hopefully reminding us
 * about removing the outdated modals. This is needed because there is no standard place
 * for all modals, they can be rendered in different places => we need to add the config
 * and the JSX manually.
 *
 * The modal shall be registered as "dismissed" on any interaction and never be shown again.
 */

type EphemeralCarouselFeaturesProps = EphemeralNotificationStreamedProps & {
  ephemeralNotificationKey: EphemeralNotificationKeys;
};
const EphemeralCarouselFeatures = ({
  notificationConfig,
  isVisible,
  ephemeralNotificationKey,
  instanceId,
}: EphemeralCarouselFeaturesProps) => {
  const isCarouselConfig = isCarouselFeaturesConfig(notificationConfig);

  useEffect(() => {
    if (!instanceId) {
      return;
    }
    // the stream needs ephemeralNotificationKey to know what to do
    dispatchAction(
      ephemeralNotificationRendered(instanceId, ephemeralNotificationKey)
    );
  }, [ephemeralNotificationKey, instanceId]);

  const onActionClick = useCallback(() => {
    if (isCarouselConfig) {
      onActionButtonClick(notificationConfig, instanceId);
    }
  }, [notificationConfig, instanceId, isCarouselConfig]);

  useEffect(() => {
    if (!(isVisible && notificationConfig) || !isCarouselConfig) {
      return;
    }

    modal(
      closeModal => (
        <CarouselFeatures
          {...notificationConfig}
          onClose={() => {
            closeModal(returnFalse);
            onActionClick();
          }}
        />
      ),
      {
        shouldCloseOnBackdropClick: false,
      }
    );
  }, [isVisible, notificationConfig, isCarouselConfig, onActionClick]);

  return <></>;
};

export default connectInstance(
  EphemeralCarouselFeatures,
  ephemeralNotification$
);
