import { RepresentationData } from '@ardoq/data-model';
import { HtmlCanvasVisual, IRectangle, IRenderContext } from '@ardoq/yfiles';
import { GraphNode } from '@ardoq/graph';
import {
  canvasResolvedImages,
  svgImage,
} from 'tabview/canvasRendering/canvasResolvedImages';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';

class CanvasNodeVisual extends HtmlCanvasVisual {
  constructor(
    private layout: IRectangle,
    private graphNode: GraphNode,
    private representationData: RepresentationData | null
  ) {
    super();
  }
  paint(renderContext: IRenderContext, ctx: CanvasRenderingContext2D): void {
    const { x, y, width, height } = this.layout;
    if (this.representationData?.isImage) {
      const image = canvasResolvedImages.get(this.graphNode.getImage());
      if (!image) {
        return;
      }
      ctx.drawImage(image, x, y, width, height);
      return;
    }

    const { fill, stroke } = getComponentCssColors(this.graphNode.modelId, {
      useAsBackgroundStyle: false,
    }) ?? { fill: 'transparent', stroke: 'black' };
    const shapeId = this.graphNode.getShape();
    if (shapeId) {
      const img = svgImage(
        // args interpolation: ${iconId}~~${color}~~${fill}~~${stroke}~~${width}~~${height}
        `${shapeId}~~${stroke}~~${fill}~~${stroke}~~${width}~~${height}`
      );
      ctx.drawImage(img, x, y, width, height);
    }
  }
}
export default CanvasNodeVisual;
