import { StyledDropdownMenuSectionTitle } from './atoms';

type SectionTitleOptionProps = {
  label: string;
};

export const DropdownMenuSectionTitle = (props: SectionTitleOptionProps) => (
  <StyledDropdownMenuSectionTitle $shouldTruncate key={props.label}>
    {props.label}
  </StyledDropdownMenuSectionTitle>
);
