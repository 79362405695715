import { ViewIds } from '@ardoq/api-types';
import {
  ICanvasObjectDescriptor,
  Insets,
  Point,
  Rect,
  TimeSpan,
} from '@ardoq/yfiles';
import { dispatchAction } from '@ardoq/rxbeach';
import { notifyViewLoading } from 'tabview/actions';
import runWebWorkerLayout from 'tabview/graphComponent/runWebWorkerLayout';
import {
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  SPACE_FACTOR,
} from 'yfilesExtensions/styles/consts';
import { ProteanLayoutType } from '../types';
import rebuildGraph from './buildGraph/rebuildGraph';
import { createLayoutData, createLayoutDescriptor } from './layoutInfo';
import resolveHierarchicalConstraints from '../../graphViews/layoutConstraints/resolveHierarchicalConstraints';
import { ProteanGraphState, RenderGraphFlags } from './types';
import resolveTabularConstraints from 'tabview/graphViews/layoutConstraints/resolveTabularConstraints';
import mercator from './mercator';
import GridlinesVisualCreator from '../intentionalLayout/layoutStage/GridlinesVisualCreator';
import resolveHierarchicInGridConstraints from '../../graphViews/layoutConstraints/resolveHierarchicInGridConstraints';

const mercatorNorthwestMax: [x: number, y: number] = [-180, 85];
const mercatorSoutheastMax: [x: number, y: number] = [180, -85];

const renderGraph = async (state: ProteanGraphState) => {
  const {
    viewInstanceId,
    graphComponent,
    viewSettings: { layoutType },
    renderFlags,
  } = state;
  if (!graphComponent.current) {
    return;
  }
  rebuildGraph(state);
  const relayout = (renderFlags & RenderGraphFlags.LAYOUT_GRAPH) !== 0;
  if (!relayout) {
    // spatial map doesn't use any layout because nodes are positioned manually.

    if (layoutType === ProteanLayoutType.SpatialMap) {
      graphComponent.current.updateContentRect();
    } else if (layoutType === ProteanLayoutType.GeographicMap) {
      const [topLeft, bottomRight] = [
        mercatorNorthwestMax,
        mercatorSoutheastMax,
      ].map(mercator);
      if (!topLeft || !bottomRight) {
        return;
      }
      graphComponent.current.contentRect = new Rect(
        new Point(topLeft[0], topLeft[1]).multiply(SPACE_FACTOR),
        new Point(bottomRight[0], bottomRight[1]).multiply(SPACE_FACTOR)
      );
    }
    graphComponent.current.fitContent(true);
    dispatchAction(notifyViewLoading({ isBusy: false, viewInstanceId }));
    return;
  }
  const layoutDescriptor = createLayoutDescriptor(state);
  state.lastViewSettings = { ...state.viewSettings };

  state.lastLayoutState = state.layoutState;
  switch (layoutType) {
    case ProteanLayoutType.Hierarchic:
      state.layoutState = {
        hierarchic: state.viewSettings.layoutOptions.hierarchic
          ? resolveHierarchicalConstraints(
              state.viewSettings.layoutOptions.hierarchic,
              state.viewModel
            )
          : null,
        tabular: null,
        hierarchicInGrid: null,
      };
      break;
    case ProteanLayoutType.Tabular:
      state.layoutState = {
        tabular: state.viewSettings.layoutOptions.tabular
          ? resolveTabularConstraints(
              state.viewSettings.layoutOptions.tabular,
              state.viewModel
            )
          : null,
        hierarchic: null,
        hierarchicInGrid: null,
      };
      break;
    case ProteanLayoutType.HierarchicInGrid:
      state.layoutState = {
        hierarchicInGrid: state.viewSettings.layoutOptions.hierarchicInGrid
          ? resolveHierarchicInGridConstraints(
              state.viewSettings.layoutOptions.hierarchicInGrid,
              state.viewModel
            )
          : null,
        hierarchic: null,
        tabular: null,
      };
  }

  dispatchAction(notifyViewLoading({ isBusy: true, viewInstanceId }));
  await runWebWorkerLayout({
    graphComponent: graphComponent.current,
    layoutDescriptor,
    viewId: ViewIds.PROTEAN_DIAGRAM,
    layoutData: createLayoutData(layoutType, state.viewModel, state),
    updateContentRect: true,
    animateViewport: true,
    duration: TimeSpan.fromSeconds(0.5),
    configureTableLayout: layoutType === ProteanLayoutType.Swimlanes,
    targetBoundsInsets: new Insets(BLOCK_DIAGRAM_NODE_LABEL_FONT.fontSize / 2), // this is working around what I suspect is a yFiles bug. layouts with bottom labels are getting the bottom half of the labels clipped by the viewport. that's fine, because a 12px margin around the graph is nice to have anyway.
    onLayoutResult: result => {
      graphComponent.current?.rootGroup
        .find(e => e.userObject instanceof GridlinesVisualCreator)
        ?.remove();
      if (!result.grids) {
        return;
      }
      if (state.isEditModeEnabled) {
        graphComponent.current?.rootGroup.addChild(
          new GridlinesVisualCreator(result.grids),
          ICanvasObjectDescriptor.ALWAYS_DIRTY_INSTANCE
        );
      }
      graphComponent.current?.updateVisual();
    },
  });
  state.lastViewModel = state.viewModel;
  dispatchAction(notifyViewLoading({ isBusy: false, viewInstanceId }));
};

export default renderGraph;
