import { MouseEventHandler } from 'react';
import { getAreaPoints, getRangeColor } from './utils';
import { ChartConfiguration, SpiderChartPoint } from './types';
import {
  ACTIVE_OPACITY_AREA,
  OPACITY_AREA,
  POLYGON_INDEX_ATTRIBUTE,
} from './consts';

type ChartPolygonProps = {
  config: ChartConfiguration;
  chartPoints: SpiderChartPoint[];
  chartPointsIndex: number;
  isActive: boolean;
  onMouseLeave?: MouseEventHandler<SVGPolygonElement>;
  onMouseEnter?: MouseEventHandler<SVGPolygonElement>;
};

export const ChartPolygon = ({
  chartPoints,
  chartPointsIndex,
  onMouseEnter,
  onMouseLeave,
  isActive,
  config: { maxValue, centerX, centerY, radius, total },
}: ChartPolygonProps) => {
  const data = getAreaPoints(
    chartPoints,
    centerX,
    centerY,
    total,
    radius,
    maxValue
  );

  return (
    <polygon
      style={{
        stroke: getRangeColor(chartPointsIndex),
        strokeWidth: '2px',
        fill: getRangeColor(chartPointsIndex),
        fillOpacity: !isActive ? OPACITY_AREA : ACTIVE_OPACITY_AREA,
        transition: 'all 0.2s',
      }}
      points={data
        .filter(Boolean)
        .map(point => point.join(','))
        .join(' ')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...{ [POLYGON_INDEX_ATTRIBUTE]: chartPointsIndex }}
    />
  );
};
