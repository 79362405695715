import { logWarn } from '@ardoq/logging';
import { MouseEvent } from 'react';

type GetPopoutUrlArgs = {
  focusPerspectiveName: boolean;
};
const getPopoutUrl = ({ focusPerspectiveName }: GetPopoutUrlArgs) =>
  `/perspectiveEditor.html${
    focusPerspectiveName ? '?focusPerspectiveName' : ''
  }`;

interface OpenArgs {
  event: MouseEvent;
  focusPerspectiveName: boolean;
}
class PerspectiveWindowManager {
  private _window: Window | null | undefined;

  open({ event, focusPerspectiveName = false }: OpenArgs) {
    if (this._window) {
      this._window.focus();
      if (focusPerspectiveName) {
        this._window.postMessage({ focusPerspectiveName: true }, '*');
      }
    } else {
      this._openWindow({ event, focusPerspectiveName });
    }
  }

  private _openWindow({ focusPerspectiveName, event }: OpenArgs) {
    const { screenY, clientY } = event;
    const browserTopUiHeight = Math.max(
      0,
      screenY - clientY - window.screenTop
    );
    const windowLeft = window.screenLeft;
    const windowTop = window.screenTop + browserTopUiHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight - browserTopUiHeight;
    const left = Math.round(windowLeft + windowWidth * 0.1);
    const top = Math.round(windowTop + windowHeight * 0.1);
    const width = Math.round(windowWidth * 0.8);
    const height = Math.round(windowHeight * 0.8 - 60);
    const options = `width=${width}, height=${height}, top=${top}, left=${left}`;
    this._window = window.open(
      getPopoutUrl({ focusPerspectiveName }),
      'perspective-editor-window',
      options
    );
    if (!this._window) {
      logWarn(Error('Perspective window failed to open.'));
      return;
    }
    this._window.onbeforeunload = () => (this._window = undefined);
    // onbeforeunload event handler is not being called in Chrome 86
    this._window.addEventListener('unload', () => (this._window = undefined));
    window.addEventListener(
      'beforeunload',
      () => this._window && this._window.close()
    );
    window.setTimeout(() => this._window && this._window.focus());
  }
}

export default new PerspectiveWindowManager();
