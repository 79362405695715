import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { dispatchAction } from '@ardoq/rxbeach';
import { EmptyStatesDoqContainer } from '@ardoq/graph';
import * as gridEditor2023Actions from 'gridEditor2023/actions';

const dispatchShowGridEditor = () => {
  dispatchAction(gridEditor2023Actions.showGridEditor());
};

type NoDataAvailableDoqProps = { doqType: DoqType; includeActions?: boolean };

const NoDataAvailableDoq = ({
  doqType,
  includeActions = true,
}: NoDataAvailableDoqProps) => (
  <EmptyStatesDoqContainer
    data-testid={`${doqType}-emptystate__no-data-available`}
  >
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="There is no data to show"
      message="Data can be easily added through the Grid Editor."
      buttonLabel={includeActions ? 'Open Grid Editor' : undefined}
      onButtonClick={includeActions ? dispatchShowGridEditor : undefined}
    />
  </EmptyStatesDoqContainer>
);
export default NoDataAvailableDoq;
