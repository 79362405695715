import {
  COMPONENT_HIERARCHY_PADDING,
  INCOMING_SUFFIX,
  LEVEL_HEADER_CELL_HORIZONTAL_PADDING,
  OUTGOING_SUFFIX,
} from '../consts';
import { MeasureStyledText } from '@ardoq/dom-utils';
const REFERENCE_INDICATOR_WIDTH = 16;

const textMeasurer = new MeasureStyledText();
const measureLabel = (text: string) => textMeasurer.getTextWidth({ text });
export const measureLevelLabel = (text: string) => {
  if (text === COMPONENT_HIERARCHY_PADDING) {
    return 0;
  }
  const isReference =
    text.endsWith(OUTGOING_SUFFIX) || text.endsWith(INCOMING_SUFFIX);
  const textToMeasure = isReference
    ? text.slice(0, -OUTGOING_SUFFIX.length)
    : text;
  const width = measureLabel(textToMeasure);
  if (width === 0) {
    return 0;
  }
  return (
    width +
    2 * LEVEL_HEADER_CELL_HORIZONTAL_PADDING +
    (isReference ? REFERENCE_INDICATOR_WIDTH : 0)
  );
};
