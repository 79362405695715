import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import { SVGAttributes } from 'react';
import styled from 'styled-components';
import { CSS_CLASS_IMAGE_DIFF_BACKGROUND } from 'scope/consts';
import { classes } from '@ardoq/common-helpers';
import { CONTEXT_HIGHLIGHT_PADDING, PLACEHOLDER_RADIUS } from './consts';
import { ArdoqSvgImageElement } from '@ardoq/icons';
import { dispatchAction } from '@ardoq/rxbeach';
import { setIsCurrentlyHoveredImageBroken } from 'tabview/graphComponent/isCurrentlyHoveredImageBroken$';
import { colors } from '@ardoq/design-tokens';
import { getElementOpacityIfTransparentized } from 'tabview/blockDiagram/view/utils';

const ContextHighlight = styled.ellipse`
  stroke-width: 2px;
  stroke: ${colors.blue60};
  fill: ${colors.blue60};
  fill-opacity: 0.32;
`;

export interface ArdoqNodeProperties {
  templateHref?: string;
  conditionalFormattingColorFilterValue?: string;
  imageHref?: string;
  isPlaceholder: boolean;
  isContext: boolean;
  isSelectedRelated: boolean;
  width: number;
  height: number;
  visualDiffClass: string;
  tooltipAttributes?: Record<string, string>;
  contextHighlightPadding: typeof CONTEXT_HIGHLIGHT_PADDING | 0;
  isTransparentized?: boolean;
}
const ArdoqNode = ({
  templateHref,
  conditionalFormattingColorFilterValue,
  imageHref,
  isPlaceholder,
  isContext,
  isSelectedRelated,
  width,
  height,
  visualDiffClass,
  tooltipAttributes = {},
  contextHighlightPadding,
  isTransparentized,
  transform,
  ...rest
}: ArdoqNodeProperties & SVGAttributes<SVGGElement>) => (
  <>
    {isPlaceholder && (
      <circle
        cx={width / 2}
        cy={height / 2}
        r={PLACEHOLDER_RADIUS}
        className={classes(CSS_CLASS_IMAGE_DIFF_BACKGROUND, visualDiffClass)}
        transform={transform}
      />
    )}
    {isContext && (
      <ContextHighlight
        className={NO_EXPORT_CLASS_NAME}
        cx={width / 2}
        cy={height / 2}
        rx={width / 2}
        ry={height / 2}
      />
    )}
    {isSelectedRelated && (
      <ellipse
        className={classes(
          'yfiles-scenario-related-highlight',
          NO_EXPORT_CLASS_NAME,
          visualDiffClass
        )}
        cx={width / 2}
        cy={height / 2}
        rx={width / 2}
        ry={height / 2}
      />
    )}
    <g
      transform={transform}
      style={{
        opacity: getElementOpacityIfTransparentized(!!isTransparentized),
      }}
      {...rest}
    >
      {templateHref && !isPlaceholder && (
        <use
          href={templateHref}
          className={visualDiffClass}
          y={isContext ? contextHighlightPadding : 0}
          x={isContext ? contextHighlightPadding : 0}
          height={isContext ? height - 2 * contextHighlightPadding : height}
          width={isContext ? width - 2 * contextHighlightPadding : width}
          {...tooltipAttributes}
        />
      )}
      {imageHref && !isPlaceholder && (
        <ArdoqSvgImageElement
          filter={conditionalFormattingColorFilterValue}
          href={imageHref}
          className={visualDiffClass}
          y={isContext ? contextHighlightPadding : 0}
          x={isContext ? contextHighlightPadding : 0}
          height={isContext ? height - 2 * contextHighlightPadding : height}
          width={isContext ? width - 2 * contextHighlightPadding : width}
          onBrokenImageMouseEnter={(imageElement?: SVGImageElement) => {
            dispatchAction(
              setIsCurrentlyHoveredImageBroken({
                isCurrentlyHoveredImageBroken: true,
                imageElement: imageElement,
              })
            );
          }}
          onBrokenImageMouseLeave={(imageElement?: SVGImageElement) => {
            dispatchAction(
              setIsCurrentlyHoveredImageBroken({
                isCurrentlyHoveredImageBroken: false,
                imageElement: imageElement,
              })
            );
          }}
          {...tooltipAttributes}
        />
      )}
    </g>
  </>
);

export default ArdoqNode;
