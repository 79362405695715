import { createSvgElement } from '@ardoq/dom-utils';
import { IconName } from '@ardoq/icons';

const materialIconsText = (
  iconName: IconName,
  attributes: Record<string, string>
) => {
  const result = createSvgElement('text', {
    'font-family': 'Material Icons Round',
    'dominant-baseline': 'central',
    'text-anchor': 'middle',
    'pointer-events': 'none',
    ...attributes,
  });
  result.textContent = iconName;
  return result;
};
export default materialIconsText;
