import { dispatchAction } from '@ardoq/rxbeach';
import { createVisualizationSlide } from 'presentationEditor/presentationChooser/actions';
import { trackExportingVisualization } from 'tracking/events/visualizations';
import { TrackingExportFormat } from '@ardoq/export';
import { ViewIds } from '@ardoq/api-types';

if (module.hot) {
  module.hot.accept();
}

export const addToPresentation = (viewId: ViewIds) => {
  trackExportingVisualization({
    name: viewId,
    format: TrackingExportFormat.PRESENTATION,
  });
  dispatchAction(createVisualizationSlide(viewId));
};
