import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoDateRangeTimeFieldsDoqProps = { doqType: DoqType };

const NoDateRangeTimeFieldsDoq = ({
  doqType,
}: NoDateRangeTimeFieldsDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No date range or time fields"
      message={
        <Stack gap="xlarge">
          <FlexBox gap="medium">
            This workspace doesn’t have any date range or time fields applied.
          </FlexBox>
          <FlexBox gap="medium">
            To use this view, add a date range or time field to your components.
          </FlexBox>
        </Stack>
      }
    />
  </EmptyStatesDoqContainer>
);
export default NoDateRangeTimeFieldsDoq;
