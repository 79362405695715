import {
  ArdoqId,
  DateRangeFieldMapType,
  MetamodelComponentType,
  MetamodelContent,
  MetamodelReferenceType,
} from '@ardoq/api-types';
import { MetamodelContext, MetamodelFieldContext } from 'metamodel/types';
import { FieldCompletion } from './completion/types';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { dateRangeOperations } from '@ardoq/date-range';

const compareCompleteness = (
  {
    metadata: { fieldCompleteness: a },
  }: MetamodelReferenceType | MetamodelComponentType,
  {
    metadata: { fieldCompleteness: b },
  }: MetamodelReferenceType | MetamodelComponentType
) => b - a;

const getAggregatedCompletion = (completion: FieldCompletion[]) =>
  completion.reduce(
    (
      acc: { filledFieldCount: number; totalFieldCount: number },
      {
        filledFieldCount,
        totalFieldCount,
      }: { filledFieldCount: number; totalFieldCount: number }
    ) => ({
      filledFieldCount: acc.filledFieldCount + filledFieldCount,
      totalFieldCount: acc.totalFieldCount + totalFieldCount,
    }),
    { filledFieldCount: 0, totalFieldCount: 0 }
  );

export const getAggregatedPercentageCompletion = (
  componentCompletion: FieldCompletion[] = [],
  referenceCompletion: FieldCompletion[] = []
) => {
  const aggregatedComponentCompletion =
    getAggregatedCompletion(componentCompletion);
  const aggregatedReferenceCompletion =
    getAggregatedCompletion(referenceCompletion);
  if (
    aggregatedComponentCompletion.totalFieldCount +
      aggregatedReferenceCompletion.totalFieldCount ===
    0
  ) {
    return '100%';
  }
  const completeness =
    ((aggregatedComponentCompletion.filledFieldCount +
      aggregatedReferenceCompletion.filledFieldCount) /
      (aggregatedComponentCompletion.totalFieldCount +
        aggregatedReferenceCompletion.totalFieldCount)) *
    100;
  return `${completeness.toFixed(0)}%`;
};

export const getPercentageCompletion = (context: MetamodelFieldContext) => {
  const { fieldUsage, instanceCount } = context.metadata;
  const completion = (fieldUsage[context.field] / instanceCount) * 100;
  return `${Math.floor(completion)}%`;
};

const hasFields = ({
  metadata: { fieldUsage },
}: MetamodelComponentType | MetamodelReferenceType) =>
  !!Object.keys(fieldUsage).length;

export const getMetamodelComponentCompletion = ({
  componentTypes,
}: MetamodelContent) => {
  const componentTypesWithFields = Object.values(componentTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const componentTypesWithoutFields = Object.values(componentTypes).filter(
    compType => !hasFields(compType)
  );

  return [...componentTypesWithFields, ...componentTypesWithoutFields].map(
    ({
      id,
      name,
      metadata: { fieldCompleteness, fieldUsage, instanceCount },
    }) => ({
      id,
      name,
      completeness: fieldCompleteness * 100,
      totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
      filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
    })
  );
};

export const getMetamodelReferenceCompletion = ({
  componentTypes,
  referenceTypes,
}: MetamodelContent) => {
  const referenceTypesWithFields = Object.values(referenceTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const referenceTypesWithoutFields = Object.values(referenceTypes).filter(
    refType => !hasFields(refType)
  );

  return [...referenceTypesWithFields, ...referenceTypesWithoutFields]
    .filter(
      (
        ref
      ): ref is MetamodelReferenceType & { source: string; target: string } =>
        Boolean(ref.source) && Boolean(ref.target)
    )
    .map(
      ({
        id,
        source,
        name,
        target,
        metadata: { fieldCompleteness, fieldUsage, instanceCount },
      }) => ({
        id,
        name: `${componentTypes[source].name} › ${name} › ${componentTypes[target].name}`,
        completeness: fieldCompleteness * 100,
        totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
        filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
      })
    );
};

export const getAssetFolderComponentCompletion = (
  { id }: MetamodelContext,
  { componentTypes, workspaces }: MetamodelContent
) => {
  const componentTypesWithFields = Object.values(componentTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const componentTypesWithoutFields = Object.values(componentTypes).filter(
    compType => !hasFields(compType)
  );

  return [...componentTypesWithFields, ...componentTypesWithoutFields]
    .filter(
      ({ workspaceId }) =>
        workspaces[workspaceId] && workspaces[workspaceId].folder === id
    )
    .map(
      ({
        id,
        name,
        metadata: { fieldCompleteness, fieldUsage, instanceCount },
      }) => ({
        id,
        name,
        completeness: fieldCompleteness * 100,
        totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
        filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
      })
    );
};

export const getAssetFolderReferenceCompletion = (
  { id }: MetamodelContext,
  { componentTypes, referenceTypes, workspaces }: MetamodelContent
) => {
  const referenceTypesWithFields = Object.values(referenceTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const referenceTypesWithoutFields = Object.values(referenceTypes).filter(
    refType => !hasFields(refType)
  );

  return [...referenceTypesWithFields, ...referenceTypesWithoutFields]
    .filter(
      ({ workspaceId }) =>
        workspaces[workspaceId] && workspaces[workspaceId].folder === id
    )
    .filter(
      (
        referenceType
      ): referenceType is MetamodelReferenceType & {
        source: string;
        target: string;
      } => Boolean(referenceType.source) && Boolean(referenceType.target)
    )
    .map(
      ({
        id,
        source,
        name,
        target,
        metadata: { fieldCompleteness, fieldUsage, instanceCount },
      }) => ({
        id,
        name: `${componentTypes[source].name} › ${name} › ${componentTypes[target].name}`,
        completeness: fieldCompleteness * 100,
        totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
        filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
      })
    );
};

export const getWorkspaceComponentCompletion = (
  { id }: MetamodelContext,
  { componentTypes }: MetamodelContent
) => {
  const componentTypesWithFields = Object.values(componentTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const componentTypesWithoutFields = Object.values(componentTypes).filter(
    compType => !hasFields(compType)
  );

  return [...componentTypesWithFields, ...componentTypesWithoutFields]
    .filter(({ workspaceId }) => workspaceId === id)
    .map(
      ({
        id,
        name,
        metadata: { fieldCompleteness, fieldUsage, instanceCount },
      }) => ({
        id,
        name,
        completeness: fieldCompleteness * 100,
        totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
        filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
      })
    );
};

export const getWorkspaceReferenceCompletion = (
  { id }: MetamodelContext,
  { componentTypes, referenceTypes }: MetamodelContent
) => {
  const referenceTypesWithFields = Object.values(referenceTypes)
    .filter(hasFields)
    .sort(compareCompleteness);

  const referenceTypesWithoutFields = Object.values(referenceTypes).filter(
    refType => !hasFields(refType)
  );

  return [...referenceTypesWithFields, ...referenceTypesWithoutFields]
    .filter(({ workspaceId }) => workspaceId === id)
    .filter(
      (
        referenceType
      ): referenceType is MetamodelReferenceType & {
        source: string;
        target: string;
      } => Boolean(referenceType.source) && Boolean(referenceType.target)
    )
    .map(
      ({
        id,
        source,
        name,
        target,
        metadata: { fieldCompleteness, fieldUsage, instanceCount },
      }) => ({
        id,
        name: `${componentTypes[source].name} › ${name} › ${componentTypes[target].name}`,
        completeness: fieldCompleteness * 100,
        totalFieldCount: Object.keys(fieldUsage).length * instanceCount,
        filledFieldCount: Object.values(fieldUsage).reduce((a, b) => a + b, 0),
      })
    );
};
export const getFieldLabelFromDateRangeFieldMapOrFieldCollection = (
  dateRangeFieldMap: DateRangeFieldMapType | undefined,
  workspaceId: ArdoqId,
  fieldName: string
) => {
  const dateRangeFieldsForWorkspace = dateRangeFieldMap?.get(workspaceId);
  return dateRangeFieldsForWorkspace?.[fieldName]?.start?.label
    ? dateRangeOperations.extractDateRangeFieldLabel(
        dateRangeFieldsForWorkspace[fieldName].start.label
      )
    : getFieldLabel(fieldName);
};

export const getFieldCompletion = (
  metamodelItem: MetamodelComponentType | MetamodelReferenceType,
  dateRangeFieldMap: DateRangeFieldMapType | undefined
) =>
  Object.keys(metamodelItem.metadata.fieldUsage)
    .sort(
      (fieldKeyA, fieldKeyB) =>
        metamodelItem.metadata.fieldUsage[fieldKeyB] -
        metamodelItem.metadata.fieldUsage[fieldKeyA]
    )
    .map(fieldKey => ({
      id: fieldKey,
      name: getFieldLabelFromDateRangeFieldMapOrFieldCollection(
        dateRangeFieldMap,
        metamodelItem.workspaceId,
        fieldKey
      ),
      completeness:
        (metamodelItem.metadata.fieldUsage[fieldKey] /
          metamodelItem.metadata.instanceCount) *
        100,
      filledFieldCount: metamodelItem.metadata.fieldUsage[fieldKey],
      totalFieldCount: metamodelItem.metadata.instanceCount,
    }));

export const metamodelIsEmpty = ({
  componentTypes,
  referenceTypes,
}: MetamodelContent) =>
  Object.keys(componentTypes).length + Object.keys(referenceTypes).length === 0;

export const assetFolderIsEmpty = (
  { id }: MetamodelContext,
  { componentTypes, referenceTypes, workspaces }: MetamodelContent
) =>
  [...Object.values(componentTypes), ...Object.values(referenceTypes)].filter(
    ({ workspaceId }) =>
      workspaces[workspaceId] && workspaces[workspaceId].folder === id
  ).length === 0;

export const workspaceIsEmpty = (
  { id }: MetamodelContext,
  { componentTypes, referenceTypes }: MetamodelContent
) =>
  [...Object.values(componentTypes), ...Object.values(referenceTypes)].filter(
    ({ workspaceId }) => workspaceId === id
  ).length === 0;

export const getFieldLabel = (fieldName: string) => {
  const field = fieldInterface.getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: false,
  });
  if (!field) return fieldName;
  return fieldInterface.getLabel(field._id) ?? fieldName;
};

export const escapeSingleQuotes = (label: string) => label.replace(/'/g, "\\'");
