import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { TrackingLocation } from 'tracking/types';

interface WorkspacePayload {
  workspaceId: ArdoqId;
}

export const notifyWorkspaceAggregatedLoaded = actionCreator<WorkspacePayload>(
  '[workspaces] NOTIFY_WORKSPACE_AGGREGATED_LOADED'
);

export const notifyWorkspacesDeleted = actionCreator<{
  workspaceIds: ArdoqId[];
}>('[workspaces] NOTIFY_WORKSPACES_DELETED');

/** This action is dispatched when a property of the workspace, such as name, description, start view, default perspective, or default sort, changes. */
export const notifyWorkspaceModelChanged = actionCreator(
  '[workspaces] NOTIFY_WORKSPACE_MODEL_CHANGED'
);

export const notifyWorkspaceNameChanged = actionCreator(
  '[workspaces] NOTIFY_WORKSPACE_NAME_CHANGED'
);

type OpenWorkspacePayload = {
  workspaceId: ArdoqId;
  keepCurrentContext: boolean;
  trackingLocation?: TrackingLocation;
};
export const openWorkspace = actionCreator<OpenWorkspacePayload>(
  '[workspaces] OPEN_WORKSPACE'
);

type ComponentInWorkspaceNavigationRequestedPayload = {
  componentId: ArdoqId;
  trackingLocation?: TrackingLocation;
};
export const componentInWorkspaceNavigationRequested =
  actionCreator<ComponentInWorkspaceNavigationRequestedPayload>(
    '[workspaces] COMPONENT_IN_WORKSPACE_NAVIGATION_REQUESTED'
  );

/**
 * Dispatching this action removes workspaces from the workspace
 * collection, typically in response to:
 * - a delete workspace api call
 * - a workspace deleted websocket event
 *
 * If you want to do cleanup in response to workspaces being deleted
 * listen to `notifyWorkspacesDeleted` instead
 */
export const removeWorkspaces = actionCreator<ArdoqId[]>(
  '[workspace] REMOVE_WORKSPACES'
);

/**
 * This action is dispatched when user clicks the delete button in the workspace options menu.
 * This will open a modal that asks the user to confirm the deletion and make a http call
 * for deleting the workspace.
 */
export const confirmDeleteWorkspace = actionCreator<ArdoqId>(
  '[workspace] WORKSPACE_OPTIONS_CONFIRM_DELETE_WORKSPACE'
);

export const closeAllWorkspaces = actionCreator(
  '[workspace] CLOSE_ALL_WORKSPACES'
);
