import { MetamodelItem } from '@ardoq/metamodel-visualization';
import { MetamodelContext, MetamodelFieldContext } from 'metamodel/types';
import { getFieldLabelFromDateRangeFieldMapOrFieldCollection } from 'metamodel/metamodelStats/utils';
import { SimpleBlockDiagramContentType } from '@ardoq/simple-block-diagram';
import { Metamodel } from '@ardoq/api-types';

export const getField = (
  metamodel: Metamodel,
  context?: MetamodelContext
): {
  name: string;
  type: SimpleBlockDiagramContentType.FIELD;
} | null =>
  context && (context as MetamodelFieldContext).field
    ? {
        name:
          getFieldLabelFromDateRangeFieldMapOrFieldCollection(
            metamodel.metamodel.dateRangeFieldMap,
            (context as MetamodelFieldContext).workspaceId,
            (context as MetamodelFieldContext).field
          ) || '',
        type: SimpleBlockDiagramContentType.FIELD,
      }
    : null;

export const getReferenceCompletenessContextComponentType = (
  metamodel: Metamodel,
  context?: MetamodelContext
) =>
  context &&
  context.type === SimpleBlockDiagramContentType.REFERENCE_COMPLETENESS
    ? metamodel.metamodel.componentTypes[context.componentId]
    : null;

const isReferenceContext = (context: MetamodelContext) =>
  [
    SimpleBlockDiagramContentType.REFERENCE,
    SimpleBlockDiagramContentType.REFERENCE_COMPLETENESS,
  ].includes(context.type);

export const getReferenceType = (
  metamodel: Metamodel,
  context?: MetamodelContext
) =>
  context && isReferenceContext(context)
    ? metamodel.metamodel.referenceTypes[context.id]
    : null;

export const getComponentType = (
  metamodel: Metamodel,
  context?: MetamodelContext
) =>
  context && context.type === SimpleBlockDiagramContentType.COMPONENT
    ? metamodel.metamodel.componentTypes[context.id]
    : null;

export const getWorkspace = (
  metamodel: Metamodel,
  context?: MetamodelContext
) => {
  if (context) {
    if (context.type === SimpleBlockDiagramContentType.COMPONENT) {
      const componentType = getComponentType(metamodel, context);
      return componentType
        ? metamodel.metamodel.workspaces[componentType.workspaceId]
        : null;
    }
    if (isReferenceContext(context)) {
      const referenceType = getReferenceType(metamodel, context);
      return referenceType
        ? metamodel.metamodel.workspaces[referenceType.workspaceId]
        : null;
    }
    if (context.type === SimpleBlockDiagramContentType.WORKSPACE) {
      return metamodel.metamodel.workspaces[context.id];
    }
  }
  return null;
};

export const getAssetFolder = (
  metamodel: Metamodel,
  context?: MetamodelContext
) => {
  if (!context) {
    return null;
  }
  if (context.type === SimpleBlockDiagramContentType.WORKSPACE_FOLDER) {
    return metamodel.metamodel.workspaceFolders[context.id];
  }
  const workspace = getWorkspace(metamodel, context);
  return workspace && workspace.folder
    ? metamodel.metamodel.workspaceFolders[workspace.folder]
    : null;
};

export const getContentType = (
  metamodel: Metamodel,
  metamodelItem: MetamodelItem
) => {
  const { componentTypes, referenceTypes, workspaces, workspaceFolders } =
    metamodel.metamodel;
  const { id } = metamodelItem;
  if (Object.keys(componentTypes).includes(id)) {
    return SimpleBlockDiagramContentType.COMPONENT;
  } else if (Object.keys(referenceTypes).includes(id)) {
    return SimpleBlockDiagramContentType.REFERENCE;
  } else if (Object.keys(workspaces).includes(id)) {
    return SimpleBlockDiagramContentType.WORKSPACE;
  } else if (Object.keys(workspaceFolders).includes(id)) {
    return SimpleBlockDiagramContentType.WORKSPACE_FOLDER;
  }
};
