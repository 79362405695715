import { dispatchAction } from '@ardoq/rxbeach';
import { updateViewSettings } from '@ardoq/view-settings';
import { ProteanLayoutOptions } from '../types';
import renderGraph from './renderGraph';
import { ProteanGraphState, RenderGraphFlags } from './types';

const onNewLayoutOptions = (
  layoutOptions: ProteanLayoutOptions,
  state: ProteanGraphState
) => {
  const { viewId } = state;
  dispatchAction(
    updateViewSettings({
      viewId,
      settings: { layoutOptions },
      persistent: true,
    })
  );
  state.viewSettings = { ...state.viewSettings, layoutOptions };
  state.renderFlags =
    RenderGraphFlags.LAYOUT_GRAPH | RenderGraphFlags.FROM_SCRATCH;
  renderGraph(state);
};
export default onNewLayoutOptions;
