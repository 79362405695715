import {
  BaseClass,
  GraphModelManager,
  ICanvasObjectDescriptor,
  ICanvasObjectGroup,
  IComparer,
  INode,
} from '@ardoq/yfiles';

class IsometricGraphModelManager extends GraphModelManager {
  createNodeModelManager(
    descriptor: ICanvasObjectDescriptor,
    callback: (arg: INode) => ICanvasObjectGroup | null
  ) {
    const result = super.createNodeModelManager(descriptor, callback);
    result.comparer = IsometricNodeComparer.Instance;
    return result;
  }
}
class IsometricNodeComparer extends BaseClass(IComparer) {
  static Instance = new IsometricNodeComparer();

  override compare(node1: INode, node2: INode): number {
    return node2.layout.maxY - node1.layout.maxY;
  }
}
export default IsometricGraphModelManager;
