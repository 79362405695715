import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EmptyStatesDoqContainer } from '@ardoq/graph';

type NoFieldsSelectedDoqProps = { doqType: DoqType };

const NoFieldsSelectedDoq = ({ doqType }: NoFieldsSelectedDoqProps) => (
  <EmptyStatesDoqContainer>
    <ResponsiveDoqWithSpeechBubble
      doqType={doqType}
      title="No X and Y values selected"
      message="Please use the view modifier to select values for X and Y axis."
    />
  </EmptyStatesDoqContainer>
);
export default NoFieldsSelectedDoq;
