import { CommonDropdownOptions } from '@ardoq/global-consts';

export const TRAVERSE_PARENTS_REFTYPES = new Set([
  CommonDropdownOptions.PARENT_AS_REFERENCE,
]);

export const TRAVERSE_ALL_BASE = {
  maxDegreesIncoming: 99,
  maxDegreesOutgoing: 99,
  maxDegrees: 0,
  isParentRelationAsReference: true,
};
