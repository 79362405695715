import { LIMIT_OF_DISPLAYED_COMPONENTS_ON_DEPENDENCY_WHEEL } from '@ardoq/dependency-wheel';

export const getWarningMessage = (
  totalComponentsCount: number,
  isExplorerMode: boolean,
  isPresentationMode: boolean
) => {
  const stringEnding = `Reduce the scope by ${
    isPresentationMode
      ? `${
          !isExplorerMode ? 'enabling explore mode and ' : ''
        }double clicking a specific component.`
      : 'selecting a specific component or applying filters.'
  }`;

  return `There are too many components to show (${totalComponentsCount}). We have displayed the first ${LIMIT_OF_DISPLAYED_COMPONENTS_ON_DEPENDENCY_WHEEL}. ${stringEnding}`;
};
