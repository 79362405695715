import {
  IColumn,
  IRenderContext,
  IStripe,
  StripeStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { CollapsibleGraphGroup } from '@ardoq/graph';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { colors } from '@ardoq/design-tokens';
import {
  SWIM_STRIPE_LABEL_FONT_SIZE,
  SWIM_STRIPE_LABEL_INSET_SIZE,
} from '../consts';
import { createSvgElement } from '@ardoq/dom-utils';

const DEFAULT_COLORS = { fill: colors.grey95, stroke: colors.black };
class SwimStripeStyle extends StripeStyleBase {
  protected override createVisual(
    context: IRenderContext,
    stripe: IStripe
  ): Visual | null {
    const isVertical = stripe instanceof IColumn;
    const group =
      stripe.tag instanceof CollapsibleGraphGroup ? stripe.tag : null;
    const { fill = DEFAULT_COLORS.fill, stroke = DEFAULT_COLORS.stroke } =
      (group?.isComponent()
        ? getComponentCssColors(group.modelId, { useAsBackgroundStyle: true })
        : DEFAULT_COLORS) ?? DEFAULT_COLORS;

    const container = createSvgElement('g');
    const rect = createSvgElement('rect', {
      fill: colors.white,
      stroke: colors.black,
      width: `${stripe.layout.width}`,
      height: `${stripe.layout.height}`,
      x: `${stripe.layout.x}`,
      y: `${stripe.layout.y}`,
    });

    const labelRectSpan = stripe.childStripes.size
      ? isVertical
        ? stripe.layout.height
        : stripe.layout.width
      : SWIM_STRIPE_LABEL_FONT_SIZE + 2 * SWIM_STRIPE_LABEL_INSET_SIZE;
    const labelRect = createSvgElement('rect', {
      fill,
      stroke,
      width: isVertical ? `${stripe.layout.width}` : `${labelRectSpan}`,
      height: isVertical ? `${labelRectSpan}` : `${stripe.layout.height}`,
      x: `${stripe.layout.x}`,
      y: `${stripe.layout.y}`,
    });
    container.append(rect, labelRect);
    return new SvgVisual(container);
  }
}
export default SwimStripeStyle;
