import { Rectangle, Vector } from '@ardoq/graph';
import { BlocksViewNode, LayoutData } from '../types';
import { ROOT_NODE_ID } from '../consts';

export const getColPositions = (group: BlocksViewNode) => {
  const ret: number[] = [];

  ret.push(group.content![0]);
  group.colSize!.forEach(size => ret.push(ret[ret.length - 1] + size));

  return ret;
};

export const getRowPositions = (group: BlocksViewNode) => {
  const ret: number[] = [];

  ret.push(group.content![1]);
  group.rowSize!.forEach(size => ret.push(ret[ret.length - 1] + size));

  return ret;
};

export const getGridPosition = (
  gridNode: BlocksViewNode,
  index: Vector
): Vector => {
  const ret: Vector = [gridNode.content![0], gridNode.content![1]];
  const colSize = gridNode.colSize!;
  const rowSize = gridNode.rowSize!;

  for (let i = 0; i < index[0] && i < colSize.length; ++i) {
    ret[0] += colSize[i];
  }

  for (let i = 0; i < index[1] && i < rowSize.length; ++i) {
    ret[1] += rowSize[i];
  }

  return ret;
};

export const getNodeLayoutIndex = (node: BlocksViewNode) => {
  const nodeIndex = node.id;
  const parent = node.parent;

  const parentIdPrefix =
    !parent || parent.id === ROOT_NODE_ID ? '' : `${parent.id}-`;

  return `${parentIdPrefix}${nodeIndex}`;
};

const addTreeLayoutData = (
  layout: LayoutData,
  node: BlocksViewNode
): LayoutData => {
  layout[getNodeLayoutIndex(node)] = {
    col: node.col,
    colSpan: node.colSpan,
    row: node.row,
    rowSpan: node.rowSpan,
  };

  return node.children?.reduce(addTreeLayoutData, layout) || layout;
};
/**
 * We avoid saving the root node in the layout data, as it is not really part of the layout.
 */
export const getLayoutData = (nodes: BlocksViewNode[] | null): LayoutData =>
  nodes?.length ? nodes.reduce(addTreeLayoutData, {}) : {};

export const isLayoutSet = (node: BlocksViewNode) =>
  !isNaN(node.col) &&
  !isNaN(node.colSpan) &&
  !isNaN(node.row) &&
  !isNaN(node.rowSpan);

export const getBounds = (nodes: Iterable<BlocksViewNode>) => {
  const bounds: Rectangle = [+Infinity, +Infinity, -Infinity, -Infinity];

  for (const node of nodes) {
    const rc = node.bounds;

    bounds[0] = Math.min(bounds[0], rc[0], rc[2]);
    bounds[1] = Math.min(bounds[1], rc[1], rc[3]);
    bounds[2] = Math.max(bounds[2], rc[0], rc[2]);
    bounds[3] = Math.max(bounds[3], rc[1], rc[3]);
  }

  return bounds;
};
