import { Node } from 'graph/node';
import { Edge } from 'graph/edge';
import Tags from 'collections/tags';
import {
  formatDateOnly,
  formatDateTime,
  parseDate,
  startOfDay,
} from '@ardoq/date-time';
import Components from 'collections/components';
import References from 'collections/references';
import Fields from 'collections/fields';
import Workspaces from 'collections/workspaces';
import FieldValue from 'graph/FieldValue';
import ComponentTypeValue from 'graph/ComponentTypeValue';
import * as encodingUtils from '@ardoq/html';
import logMissingModel from 'models/logMissingModel';
import { getCurrentLocale } from '@ardoq/locale';

/**
 * @typedef {import('graph/NodeCollection').default} NodeCollection
 * @typedef {import('./types').Edge} EdgeType
 * @typedef {import('graph/GraphCollection').default<EdgeType>} EdgeCollection
 * @typedef {import('aqTypes').GroupByBackboneModelCollection} GroupByBackboneModelCollection
 * @typedef {import('aqTypes').ComponentBackboneModel} ComponentBackboneModel
 * @typedef {import('./GraphItem').GraphItemModel} GraphItemModel
 * @typedef {import('./types').AggregatedGraphBuilderNode} AggregatedGraphBuilderNode
 * @typedef {import('./types').Node<GraphItemModel>} GraphNode
 */

/**
 * @param {NodeCollection} rootNodeCollection
 * @param {EdgeCollection} edgeCollection
 * @param {GroupByBackboneModelCollection} groupByCollection
 */
function buildAggregatedGraph(
  rootNodeCollection,
  edgeCollection,
  groupByCollection
) {
  /** @type {Map<string, AggregatedGraphBuilderNode>} */
  const compMap = new Map();
  /** @type {Edge[]} */
  const edges = [];
  /** @type {GraphNode[]} */
  const rootNodes = [];

  // clean up old nodes/edges
  rootNodeCollection.clear({ ignoreNodeUpdates: true });
  edgeCollection.clear({ ignoreNodeUpdates: true });
  /**
   * @param {ComponentBackboneModel} comp
   * @param {string} [id]
   */
  function aggNode(comp, id) {
    /** @type {string} */
    const actualId = id || comp.id;
    let node = compMap.get(actualId);
    if (!node) {
      node = {
        component: comp,
        aggregated: [],
        aggregators: [],
        incoming: [],
        outgoing: [],
        rootNodes: [],
        nodes: [],
      };
      compMap.set(actualId, node);
    }
    return node;
  }

  function aggregate(aggregator, aggregatee, ref) {
    aggregator.aggregated.push({
      agg: aggregatee,
      ref: ref,
    });
    aggregatee.aggregators.push({
      agg: aggregator,
      ref: ref,
    });
  }

  const componentTypesValues = new Map();
  Components.collection.each(function (comp) {
    if (!comp.getWorkspace()) {
      return;
    }

    const parent = comp.getParent();
    const wsId = comp.getWorkspace();

    const aggregateParentChild =
      parent &&
      (groupByCollection.hasGroupByParentAll() ||
        groupByCollection.hasGroupByParent(parent) ||
        groupByCollection.hasGroupByChild(comp));
    if (aggregateParentChild) {
      aggregate(aggNode(parent), aggNode(comp));
    }

    if (groupByCollection.hasGroupByComponentType()) {
      let ct = componentTypesValues.get(comp.getTypeId());
      if (!ct) {
        ct = new ComponentTypeValue({ componentType: comp.getMyType() });
        componentTypesValues.set(comp.getTypeId(), ct);
      }
      aggregate(aggNode(ct, comp.getTypeId()), aggNode(comp));
    }

    if (groupByCollection.hasGroupByWorkspace(wsId)) {
      const ws = Workspaces.collection.get(wsId);
      const aggComp = aggNode(comp);
      // don't aggregate already aggregated nodes
      if (aggComp.aggregators.length === 0) {
        aggregate(aggNode(ws), aggComp);
      }
    }
  });

  Tags.collection.each(function (tag) {
    if (groupByCollection.hasGroupByTag(tag)) {
      tag.getIdsOfComponentsWithTag().forEach(function (compId) {
        const tagNode = aggNode(tag);
        const compNode = aggNode(Components.collection.get(compId));
        aggregate(tagNode, compNode);
      });
    }
  });

  Fields.collection.each(function (field) {
    if (groupByCollection.hasGroupByField(field)) {
      const fieldValueCache = new Map();
      /*
       * Ensure correct order of field values by inserting them based on the order
       * specified in the fields allowed values array.
       */
      if (field.isListType()) {
        field.getAllowedValues().forEach(fieldValue => {
          const value = String(fieldValue).toLowerCase();
          if (value) compMap.set(value, undefined);
        });
      }
      const fieldModel = field.getModel();
      if (fieldModel) {
        const isGroupByDateWithoutTime = field.getType() === 'DateTime';

        Components.collection.each(function (comp) {
          const componentModel = comp.getMyModel();
          if (!componentModel) {
            logMissingModel({
              id: comp.getId(),
              rootWorkspace: comp.get('rootWorkspace'),
              modelTypeName: 'component',
            });
            return;
          }
          if (fieldModel.id !== componentModel.id) {
            return;
          }
          if (!field.hasComponentType(comp.getTypeId())) {
            return;
          }

          const fieldName = field.name();
          let valueKey = getComponentFieldValue(comp, fieldName);
          if (isGroupByDateWithoutTime && valueKey) {
            // group by date excluding hours and minutes
            valueKey = startOfDay(parseDate(valueKey)).toString();
          }
          const mapKey = valueKey.toLowerCase
            ? valueKey.toLowerCase()
            : valueKey;
          let value = valueKey;
          let fv = fieldValueCache.get(mapKey);
          if (!fv) {
            if (field.getType() === 'DateTime') {
              const locale = getCurrentLocale();
              const fieldValue = getComponentFieldValue(comp, fieldName);

              value = isGroupByDateWithoutTime
                ? formatDateOnly(fieldValue, locale)
                : formatDateTime(fieldValue, locale);
            } else {
              value = encodingUtils.unescapeHTML(
                field.format(value, comp.attributes)
              );
            }
            fv = new FieldValue({
              field,
              value,
              cid: mapKey,
            });
            fieldValueCache.set(mapKey, fv);
          }

          const fieldNode = aggNode(fv, fv.cid);
          const compNode = aggNode(comp);
          aggregate(fieldNode, compNode);
        });
      } else {
        logMissingModel({
          id: field.id,
          rootWorkspace: field.get('rootWorkspace'),
          modelTypeName: 'field',
        });
      }

      /* Remove unused field values from compMap to ensure aggregation works */
      if (field.isListType()) {
        const unusedFieldValues = field
          .getAllowedValues()
          .map(String)
          .map(fieldValue => fieldValue.toLowerCase())
          .filter(fieldValue => !fieldValueCache.has(fieldValue));

        unusedFieldValues.forEach(fieldValue => compMap.delete(fieldValue));
      }
    }
  });

  References.collection
    .filter(ref => {
      return ref.isIncludedInContextByFilter();
    })
    .forEach(ref => {
      const source = aggNode(ref.getSource()),
        target = aggNode(ref.getTarget());

      if (groupByCollection.hasGroupByOutgoingReference(ref)) {
        aggregate(source, target, ref);
      } else if (groupByCollection.hasGroupByIncomingReference(ref)) {
        aggregate(target, source, ref);
      } else {
        source.outgoing.push(ref);
        target.incoming.push(ref);
      }
    });

  function isCircularParentDependency(componentId, parentNode) {
    if (componentId === parentNode.dataModel.id) {
      return true;
    }
    if (parentNode.parent) {
      return isCircularParentDependency(componentId, parentNode.parent);
    }
    return false;
  }

  const MAX_DEPTH = 10;
  /**
   * @param {AggregatedGraphBuilderNode} rootAgg
   * @param {Node<GraphItemModel>} parentNode
   * @param {unknown} relation
   * @param {number} [depth]
   */
  function createAggregatedNodes(rootAgg, parentNode, relation, depth) {
    const actualDepth = depth || 1;
    if (actualDepth > MAX_DEPTH) {
      return;
    }
    if (isCircularParentDependency(relation.agg.component.id, parentNode)) {
      return;
    }

    const aggregateeNode = new Node({
      dataModel: relation.agg.component,
    });

    if (!aggregateeNode.isIncludedInContextByFilter()) {
      aggregateeNode.destroy();
      return;
    }
    parentNode.children.add(aggregateeNode);

    relation.agg.rootNodes.push(rootAgg.rootNodes[0]);
    relation.agg.nodes.push(aggregateeNode);

    relation.agg.aggregated.forEach(function (nestedAggregatee) {
      createAggregatedNodes(
        rootAgg,
        aggregateeNode,
        nestedAggregatee,
        actualDepth + 1
      );
    });
  }

  compMap.forEach(rootAgg => {
    if (rootAgg.aggregators.length === 0) {
      const rootNode = new Node({
        dataModel: rootAgg.component,
      });
      if (!rootNode.isIncludedInContextByFilter()) {
        rootNode.destroy();
        return;
      }
      rootAgg.rootNodes.push(rootNode);
      rootAgg.nodes.push(rootNode);
      rootNodes.push(rootNode);
      rootAgg.aggregated.forEach(function (aggregatee) {
        createAggregatedNodes(rootAgg, rootNode, aggregatee);
      });
    }
  });

  compMap.forEach(agg => {
    agg.nodes.forEach(target => {
      const aggregatedTarget = target.getRootParent();

      agg.incoming.forEach(reference => {
        const aggNode = compMap.get(reference.get('source'));
        const sources = (aggNode && aggNode.nodes) || [];

        sources.forEach(source => {
          if (!reference.isIncludedInContextByFilter()) {
            return;
          }
          const aggregatedSourceNode = source.getRootParent(),
            aggregatedTargetNode = aggregatedTarget,
            sourceNode = source,
            targetNode = target;

          edges.push(
            new Edge({
              reference,
              sourceNode,
              targetNode,
              aggregatedSourceNode,
              aggregatedTargetNode,
            })
          );
        });
      });
    });
  });

  rootNodes.forEach(node => rootNodeCollection.add(node));
  edges.forEach(edge => edgeCollection.add(edge));

  return compMap;
}

/**
 * @param {ComponentBackboneModel} component
 * @param {string} fieldName
 * @returns {*|string}
 */
function getComponentFieldValue(component, fieldName) {
  const value = component.get(fieldName) || '';
  if (Array.isArray(value)) {
    return value.sort().join(',');
  }
  return value;
}

export default buildAggregatedGraph;
