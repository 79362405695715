import { chartLayoutReset, chartSetWindow } from './actions';
import { DEFAULT_WINDOW_CENTER } from '@ardoq/timeline';
import { reducer } from '@ardoq/rxbeach';
import { ChartWindowState } from '@ardoq/graph';
import { ExtractPayload } from '@ardoq/rxbeach';
import { spreadPayloadOverState } from '@ardoq/common-helpers';

const windowChange = <T extends ChartWindowState>() =>
  reducer(chartSetWindow, spreadPayloadOverState<T, ChartWindowState>);

const layoutReset = <T extends ChartWindowState>() => {
  const handleChartLayoutReset = (
    state: T,
    viewState: ExtractPayload<typeof chartLayoutReset>
  ) => ({
    ...state,
    ...viewState,
    windowCenter: DEFAULT_WINDOW_CENTER,
    windowScale: 1.0,
  });
  return reducer(chartLayoutReset, handleChartLayoutReset);
};
export const chartComponentReducers = <T extends ChartWindowState>() => [
  windowChange<T>(),
  layoutReset<T>(),
];
