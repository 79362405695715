import { flatten, omit } from 'lodash';
import {
  AdvancedSearchField,
  AdvancedSearchResultType,
  APIFieldType,
  QueryBuilderRule,
  QueryBuilderSubquery,
  SortOrder,
  UnknownDocumentType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { CheckboxIcon } from '@ardoq/icons';
import { AdvancedSearchFieldLiteral } from 'search/types';
import { setContextToEntity } from 'search/searchUtil/actions';
import { getIn } from 'utils/collectionUtil';
import { format } from 'utils/numberUtils';
import * as defaultFields from 'streams/fields/defaultFields';
import { formatDateOnly, formatDateTime } from '@ardoq/date-time';
import { ValueRenderType } from '@ardoq/table';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { MarkdownViewer } from '@ardoq/markdown';
import { MappedAdvancedSearchResult } from 'search/resultUtils';
import { SearchResultApiConnectorState } from './types';
import { getCurrentLocale } from '@ardoq/locale';

const navigateToResult = ({
  type,
  id,
  rootWorkspace,
}: MappedAdvancedSearchResult<UnknownDocumentType>) => {
  if (type === AdvancedSearchResultType.WORKSPACE) {
    dispatchAction(setContextToEntity({ id, workspaceId: id }));
  } else {
    dispatchAction(setContextToEntity({ id, workspaceId: rootWorkspace! }));
  }
};

interface FieldPartial {
  type: APIFieldType;
  name: string;
}

export const getFieldValueRenderer = (
  field: FieldPartial
):
  | ValueRenderType<
      // AdvancedSearchResult is horribly typed. It's been discovered when
      // DatasourceTable received better TS notation
      MappedAdvancedSearchResult<UnknownDocumentType> & { [key: string]: any }
    >
  | undefined => {
  const locale = getCurrentLocale();
  const { type, name } = field;

  if (name === 'name') {
    return (value: string, result) => (
      <a onClick={() => navigateToResult(result)}>{value}</a>
    );
  }
  if (type === APIFieldType.DATE_ONLY) {
    return (value, _res) => formatDateOnly(value, locale);
  }
  if (type === APIFieldType.DATE_TIME) {
    return (value, _res) => formatDateTime(value, locale);
  }
  if (type === APIFieldType.SELECT_MULTIPLE_LIST) {
    return (_val, result) => {
      return (result[name] || []).join(', ');
    };
  }
  if (type === APIFieldType.CHECKBOX) {
    return (_val, result) => {
      const checked = result[name];
      // A checkbox field value is undefined if the search result contains
      // a component for which the field does not apply
      if (checked === undefined) {
        return '';
      }
      // A checkbox field value is null if the field is set to apply to a
      // component type, but the user has not set a value yet
      return <CheckboxIcon checked={checked === null ? false : checked} />;
    };
  }
  if (type === APIFieldType.NUMBER) {
    return (value, _res) =>
      format(
        value,
        fieldInterface.getNumberFormatByName(field.name) ?? undefined
      );
  }
  if (type === APIFieldType.DATE_ONLY_RANGE) {
    return (_val, result) =>
      name
        .split(',')
        .map(token => formatDateOnly(result[token], locale))
        .join(' - ');
  }
  if (type === APIFieldType.DATE_TIME_RANGE) {
    return (_val, result) =>
      name
        .split(',')
        .map(token => formatDateTime(result[token], locale))
        .join(' - ');
  }
  if (type === APIFieldType.TEXT_AREA) {
    return value => (
      <MarkdownViewer content={value} sanitationLevel="permissive" />
    );
  }
  if (name === 'created-by') {
    return (_val, result) => result.ardoq.createdByName;
  }
  if (name === 'last-modified-by') {
    return (_val, result) => result.ardoq.lastModifiedByName;
  }
  if (name === 'description') {
    return (_val, result) => result.description;
  }
  if (type === APIFieldType.USER) {
    return (_val, result) =>
      getIn<string>(result, ['ardoq', 'usernamesByField', name], '');
  }
  if (name === 'rootWorkspace') {
    return (_val, result) => result.ardoq.rootWorkspaceName;
  }
  if (name === 'parent') {
    return (_val, result) => result.ardoq.parentComponentName;
  }
  if (name === 'typeName') {
    return (cellValue, result) => {
      return result.entityType || cellValue;
    };
  }
  if (name === 'source') {
    return (_val, result) => result.ardoq.sourceComponentName;
  }
  if (name === 'target') {
    return (_val, result) => result.ardoq.targetComponentName;
  }
};

export const getSortByString = (field: AdvancedSearchField) => {
  if (field.type === APIFieldType.USER) {
    // With user field types, tell backend to sort on the actual username instead of the id.
    // Derived usernames are nested in usernamesByField inside the ardoq attribute
    return `ardoq.usernamesByField.${field.name}`;
  }
  return field.name;
};

export const findSelectedFields = (
  selectedFieldNames: string[],
  fields: { [key: string]: AdvancedSearchField }
): AdvancedSearchField[] => {
  return selectedFieldNames.reduce((acc: AdvancedSearchField[], fieldName) => {
    const field = fields[fieldName];
    if (field) acc.push(field);
    return acc;
  }, []);
};

const { name, created, lastUpdated } = defaultFields;
export const getDefaultFields = (
  resultType: AdvancedSearchResultType | null
): AdvancedSearchFieldLiteral =>
  resultType
    ? {
        // Note: tags and childCount are not returned by search anymore
        [AdvancedSearchResultType.COMPONENT]: omit(
          defaultFields.component,
          'tags',
          'childCount',
          'child-count'
        ),
        [AdvancedSearchResultType.REFERENCE]: defaultFields.reference,
        [AdvancedSearchResultType.WORKSPACE]: defaultFields.workspace,
      }[resultType]
    : { name, created, 'last-updated': lastUpdated };

export const fieldsToFieldsLiteral = (
  fields: AdvancedSearchField[]
): AdvancedSearchFieldLiteral =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field,
    }),
    {}
  );

export const getNextSortOrder = (sortOrder?: SortOrder) => {
  if (sortOrder === undefined) return SortOrder.ASC;
  if (sortOrder === SortOrder.ASC) return SortOrder.DESC;
  if (sortOrder === SortOrder.DESC) return undefined;
};

export const findRelevantFieldsFromQuery = (
  advancedSearchQuery: QueryBuilderSubquery
): string[] => {
  const reducer = (
    fields: string[],
    rule: QueryBuilderSubquery | QueryBuilderRule
  ): string[] => {
    // https://stackoverflow.com/questions/1894792/how-to-determine-whether-an-object-has-a-given-property-in-javascript
    if ('field' in rule) {
      return [...fields, rule.field];
    }
    return flatten(rule.rules.reduce(reducer, fields));
  };

  // https://stackoverflow.com/questions/1894792/how-to-determine-whether-an-object-has-a-given-property-in-javascript
  return 'rules' in advancedSearchQuery
    ? advancedSearchQuery.rules.reduce(reducer, [])
    : [];
};

const DISABLE_SORT_FOR_WORKSPACES: string[] = ['description'];
const DISABLE_SORT_FOR_COMPONENTS: string[] = ['description'];
const DISABLE_SORT_FOR_REFERENCES: string[] = ['description', 'name'];

const defaultFieldNameSet: Set<string> = new Set(
  [defaultFields.component, defaultFields.workspace, defaultFields.reference]
    .map(Object.keys)
    .reduce((acc, arr) => acc.concat(arr))
);

export const isFieldSortable = (
  field: AdvancedSearchField,
  resultType: AdvancedSearchResultType
) => {
  // Text fields and text area fields are not sortable, unless they are
  // one of the default fields
  if (
    [APIFieldType.TEXT, APIFieldType.TEXT_AREA].includes(field.type) &&
    !defaultFieldNameSet.has(field.name)
  )
    return false;
  if (resultType === AdvancedSearchResultType.WORKSPACE)
    return !DISABLE_SORT_FOR_WORKSPACES.includes(field.name);
  if (resultType === AdvancedSearchResultType.REFERENCE)
    return !DISABLE_SORT_FOR_REFERENCES.includes(field.name);
  if (resultType === AdvancedSearchResultType.COMPONENT)
    return !DISABLE_SORT_FOR_COMPONENTS.includes(field.name);
};

export const prependWithDefaults = (
  values: string[] = [],
  defaultValues: string[] = [],
  length = 2
) => {
  const oputValues = [...values];
  if (!values[0] && defaultValues[1]) {
    oputValues.unshift(defaultValues[1]);
  }
  if (!values[1] && defaultValues[0]) {
    oputValues.unshift(defaultValues[0]);
  }
  return oputValues.slice(0, length);
};

export const findSortOrder = (
  state: SearchResultApiConnectorState,
  sortBy?: string
): [string | undefined, SortOrder] => {
  const currentSortBy = state.sortBy;
  const currentSortOrder = state.sortOrder;

  if (currentSortBy !== sortBy) return [sortBy, SortOrder.ASC];
  if (currentSortOrder === SortOrder.ASC) return [sortBy, SortOrder.DESC];
  return [undefined, SortOrder.DEFAULT];
};
