import {
  Cursor,
  GraphComponent,
  GraphEditorInputMode,
  GraphItemTypes,
  INode,
  KeyEventRecognizers,
  Point,
} from '@ardoq/yfiles';
import { ProteanGraphState } from '../view/types';
import { NodeDragInputMode } from './NodeDragInputMode';
import GridlinesVisualCreator from '../intentionalLayout/layoutStage/GridlinesVisualCreator';
import { getUpdatedConstraints } from './getUpdatedConstraints';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import onNewLayoutOptions from '../view/onNewLayoutOptions';
import { ProteanLayoutOptions } from '../types';

export const getTabularInputMode = (
  graphComponent: GraphComponent,
  state: ProteanGraphState,
  rightClickedListener: Parameters<
    GraphEditorInputMode['addItemRightClickedListener']
  >[0]
) => {
  const editorInputMode = new GraphEditorInputMode({
    allowCreateNode: false,
    allowCreateEdge: false,
    allowCreateBend: false,
  });

  editorInputMode.clickInputMode.enabled = false;
  editorInputMode.moveUnselectedInputMode.enabled = true;
  editorInputMode.moveViewportInputMode.enabled = true;
  editorInputMode.moveInputMode.enabled = false;
  editorInputMode.allowGroupSelection = false;
  editorInputMode.marqueeSelectionInputMode.enabled = false;
  editorInputMode.createBendInputMode.enabled = false;
  editorInputMode.movableItems = GraphItemTypes.NODE;

  const dragMode = new NodeDragInputMode(editorInputMode.waitInputMode);

  editorInputMode.moveViewportInputMode.validBeginRecognizer =
    KeyEventRecognizers.CTRL_IS_DOWN;
  editorInputMode.moveViewportInputMode.validBeginCursor = Cursor.GRAB;
  editorInputMode.moveViewportInputMode.dragCursor = Cursor.GRABBING;
  editorInputMode.moveUnselectedInputMode.validBeginCursor = Cursor.GRAB;
  editorInputMode.moveUnselectedInputMode.moveCursor = Cursor.GRABBING;

  const layoutKey = 'hierarchicInGrid';
  const layoutState = state.layoutState[layoutKey];

  dragMode.addDropHandler((node: INode, location: Point) => {
    const gridlinesVisual = graphComponent.rootGroup.find(
      e => e.userObject instanceof GridlinesVisualCreator
    );

    if (!gridlinesVisual) {
      return;
    }

    const grids = (gridlinesVisual.userObject as GridlinesVisualCreator).grids;

    const parentId = node.tag?.parent?.id;
    const parentGrid = parentId && grids.find(grid => grid.nodeId === parentId);
    const grid = parentGrid ?? grids[0];

    const { newColumnConstraints, newRowConstraints } = getUpdatedConstraints({
      columnWidths: grid.columnWidths,
      rowHeights: grid.rowHeights,
      nodeDropLocationX: location.x - (isFinite(grid.x) ? grid.x : 0),
      nodeDropLocationY: location.y - (isFinite(grid.y) ? grid.y : 0),
      nodeId: node.tag.id,
      nodesById: state.viewModel.nodes.byId,
      groupsById: state.viewModel.groups.byId,
      columnConstraintsMap: layoutState?.columnConstraintsMap ?? new Map(),
      rowConstraintsMap: layoutState?.rowConstraintsMap ?? new Map(),
    });

    const newLayoutOptions: Partial<ProteanLayoutOptions> = {
      [layoutKey]: {
        ...PROTEAN_DEFAULT_LAYOUT_OPTIONS[layoutKey],
        ...state.viewSettings.layoutOptions[layoutKey],
        columnConstraintsWithSource: newColumnConstraints,
        rowConstraintsWithSource: newRowConstraints,
      },
    };

    onNewLayoutOptions(
      {
        ...PROTEAN_DEFAULT_LAYOUT_OPTIONS,
        ...state.viewSettings.layoutOptions,
        ...newLayoutOptions,
      },
      state
    );
  });

  editorInputMode.add(dragMode);
  editorInputMode.addItemRightClickedListener(rightClickedListener);

  return editorInputMode;
};
