import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';

export const VisualizationContainer = styled.div`
  position: relative;
  flex: 1;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding-top: 0px;
  flex: 1;
  overflow: auto;
  position: relative;

  .tab-pane {
    padding: 0;
    height: 100%;
    width: 100%;
    display: none !important;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;

    &.active {
      display: flex !important;
    }
  }
  @media print {
    clear: both;
    position: relative !important;
    overflow: visible;
    min-width: 580px;
    height: 100% !important;
    height: initial !important;
  }
`;

export default ViewContainer;
