import { classes } from '@ardoq/common-helpers';
import { LabelTextElement } from './atoms';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { BubbleChartDataPointArgs } from './types';

type BubbleChartLabelProps = { key?: string } & BubbleChartDataPointArgs;

export const DataPointLabel = ({
  data: { cid, label, fontSize, fontOpacity, x, y },
}: BubbleChartLabelProps) => (
  <LabelTextElement
    data-global-handler-id={cid}
    className={classes('component', 'dataPointLabel')}
    style={{
      fontSize: `${fontSize}px`,
      opacity: fontOpacity,
    }}
    alignmentBaseline="central"
    textAnchor="middle"
    x={x}
    y={y}
    {...{ [COMPONENT_ID_ATTRIBUTE]: cid }}
  >
    {label}
  </LabelTextElement>
);
