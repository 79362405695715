import {
  ComponentMatrixListValueHeaderCellContainer,
  HeaderCell,
} from './atoms';
import {
  ComponentMatrixDimension,
  ComponentMatrixGroup,
  ComponentMatrixGroupOwnerType,
} from '../types';
import { hasChildren } from '../util';
import IconClickable from 'atomicComponents/IconClickable';
import { dispatchAction } from '@ardoq/rxbeach';
import { componentMatrixToggleCollapseGroup } from '../actions';
import { ChevronIconName } from '@ardoq/icons';
import {
  COMPONENT_HIERARCHY_PADDING,
  MARGIN_PER_LEVEL,
  NONE_HEADER_LABEL,
} from '../consts';
import ColumnHeaderComponent from './columnHeaderComponent';
import { colors } from '@ardoq/design-tokens';
import { ArdoqId } from '@ardoq/api-types';
import { componentMatrixCommands } from '../commands';

interface ComponentMatrixHeaderCellArgs {
  column: ComponentMatrixGroup;
  columnDepth: number;
  gridColumn: number;
  colSpan: number;
  hoveredComponentId: ArdoqId | null;
  focusedComponentId: ArdoqId | null;
}
const toggleCollapsed = (column: ComponentMatrixGroup) => {
  dispatchAction(
    componentMatrixToggleCollapseGroup({
      dimension: ComponentMatrixDimension.COLUMN,
      address: column.address,
    })
  );
};

const ComponentMatrixHeaderCell = ({
  column,
  columnDepth,
  gridColumn,
  colSpan,
  hoveredComponentId,
  focusedComponentId,
}: ComponentMatrixHeaderCellArgs) => {
  if (column.isHidden) {
    return null;
  }

  const isReference =
    column.ownerType === ComponentMatrixGroupOwnerType.REFERENCED_COMPONENT;
  const isPadding =
    column.label === COMPONENT_HIERARCHY_PADDING ||
    column.ownerType === ComponentMatrixGroupOwnerType.EMPTY;
  if (isPadding) {
    return null;
  }
  const level = column.address.length - 1;
  const rowLevel = columnDepth - level;
  const rowSpan = rowLevel + level < columnDepth ? rowLevel : rowLevel + 1; // the bottom row of column headers spans 1 extra row.
  const rootLevel = level === 0;
  const isRealReference = Boolean();
  return (
    <HeaderCell
      style={{
        gridColumn: `${gridColumn} / ${gridColumn + colSpan}`,
        gridRow: `${level + 1} / ${level + 1 + rowSpan}`,
      }}
      onMouseEnter={() =>
        componentMatrixCommands.setHoveredItemState(
          isRealReference ? column.label : null
        )
      }
      onMouseLeave={() => componentMatrixCommands.setHoveredItemState(null)}
      onClick={e => {
        e.stopPropagation();
        componentMatrixCommands.setFocusedItemState(column.label);
      }}
    >
      {isReference && column.label ? (
        <ColumnHeaderComponent
          componentId={column.address.at(-1)!}
          address={column.address}
          showExpander={hasChildren(column) && !isPadding}
          isExpanded={column.isExpanded}
          expanderClicked={() => toggleCollapsed(column)}
          isHighlighted={
            hoveredComponentId === column.address.at(-1)! ||
            focusedComponentId === column.address.at(-1)!
          }
        />
      ) : (
        <ComponentMatrixListValueHeaderCellContainer
          $level={level}
          style={{
            margin: rootLevel ? undefined : `${level * MARGIN_PER_LEVEL}px`,
            borderRight: level === 0 ? `1px solid ${colors.grey80}` : undefined,
          }}
        >
          {column.label || NONE_HEADER_LABEL}
          {hasChildren(column) && !isPadding ? (
            <IconClickable
              display="inline"
              onClick={() => toggleCollapsed(column)}
              iconName={
                column.isExpanded ? ChevronIconName.UP : ChevronIconName.DOWN
              }
              containerStyle={{ verticalAlign: 'middle' }}
            />
          ) : null}
        </ComponentMatrixListValueHeaderCellContainer>
      )}
    </HeaderCell>
  );
};
export default ComponentMatrixHeaderCell;
