import { v4 as uuidv4 } from 'uuid';
import {
  dispatchAction,
  type ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  applyColorsToFields,
  DASHBOARD_NAME_ISLAND_ID,
  DashboardApiError,
  DashboardBuilderChartWidget,
  DashboardBuilderHeaderWidget,
  DashboardErrorType,
  DashboardTrackingEventsNames,
  EditModeDashboardAttributes,
  getColSpanForViewType,
  type LoadedDashboard,
  WidgetError,
  widgetOperations,
  widgetTypes,
  WidgetWarning,
} from '@ardoq/dashboard';
import {
  applyDashboardWidgetFiltersRequested,
  clearDashboardData,
  clearWidgetSelection,
  copyWidget,
  CopyWidgetPayload,
  createWidget,
  CreateWidgetPayload,
  DashboardWidgetFilterPayload,
  deleteWidget,
  getWidgetPreview,
  removeManuallyAssignedColorForDataPoint,
  selectBarChartColorSelection,
  selectChartDataOrder,
  selectDataSource,
  selectField,
  selectNumberSlices,
  selectNumberTrend,
  selectWidget,
  selectWidgetType,
  setAvailableDataSources,
  setColorForDataPoint,
  setDashboardApiError,
  SetDashboardApiErrorPayload,
  setDashboardDescription,
  setDashboardLoadingState,
  setDashboardName,
  setDashboardWarnings,
  setLoadedDashboard,
  setLoadingDataSources,
  setValidationErrors,
  setWidgetError,
  setWidgetPreview,
  setWidgets,
  updateSelectedWidget,
  widgetFilterDrawerClosed,
  widgetFilterDrawerOpened,
} from './actions';
import {
  Aggregate,
  APIBarChartAttributes,
  APIFieldType,
  APIPieChartAttributes,
  type APIWidgetAttributes,
  ArdoqId,
  type BarChartColorOptions,
  ChartOrderOptions,
  DataSourceField,
  NumberWidgetTrend,
  NumberWidgetTrendConfiguration,
  WidgetDataSourceEntity,
  WidgetDataSourceTypes,
  WidgetTypes,
} from '@ardoq/api-types';
import { LoadingState } from './types';
import {
  getAvailableFieldsForDataSource,
  getNewNumberWidgetConfiguration,
  getNewNumberWidgetTrend,
  getSelectedChartWidget,
  getSelectedFields,
  isDashboardNameValid,
  isDashboardValid,
  isSortableColumInTableWidget,
  isSubset,
  shouldGetPreview,
} from './utils';
import { trackEvent } from '../../tracking/tracking';
import { getSelectedDashboardColorPalette } from '../DashboardColorThemeSettings/utils';
import { getValidAggregates } from './aggregateValidation';
import { isWidgetTypeValidForSelectedFieldsAndDatasource } from './widgetTypeValidation';
import { constant, isEmpty } from 'lodash';

export type DashboardBuilder$State = {
  _id?: ArdoqId;
  _version?: number;
  name: string;
  description?: string;
  widgets: Array<DashboardBuilderChartWidget | DashboardBuilderHeaderWidget>;
  selectedWidgetId?: ArdoqId;
  isWidgetRecentlyCreated?: boolean;
  availableDataSources: WidgetDataSourceEntity[];
  loadingDataSources: boolean;
  loadingState: LoadingState | null;
  settings: EditModeDashboardAttributes['settings'];
  validationErrors: Set<DashboardErrorType>;
  apiError?: DashboardApiError;
  isFilterDrawerOpened: boolean;
};

const defaultState: DashboardBuilder$State = {
  name: '',
  description: '',
  widgets: [],
  availableDataSources: [],
  loadingDataSources: false,
  loadingState: null,
  settings: { colors: [] },
  validationErrors: new Set(),
  selectedWidgetId: DASHBOARD_NAME_ISLAND_ID,
  isFilterDrawerOpened: false,
};

const getWidgetTypeLayoutFieldAggregateAndSort = (
  selectedWidget: DashboardBuilderChartWidget,
  availableFieldsForDataSource: DataSourceField[],
  shouldResetWidgetSize?: boolean
) => {
  // When the selected data source, field or widget type changes, some other properties might have to change as well.
  // This function should make sure those properties that depend on each other are changed consistently
  const selectedFields = getSelectedFields(
    selectedWidget,
    availableFieldsForDataSource
  );

  const validWidgetTypes = widgetTypes.filter(widgetType =>
    isWidgetTypeValidForSelectedFieldsAndDatasource({
      widgetType,
      datasource: selectedWidget.datasource,
      availableFieldsForDataSource: availableFieldsForDataSource,
      selectedFields,
    })
  );

  const widgetType = validWidgetTypes.some(
    widgetType => widgetType === selectedWidget.viewType
  )
    ? selectedWidget.viewType
    : validWidgetTypes[0];

  const aggregate = changeAggregateIfNotValid({
    widgetType,
    fieldTypes: selectedFields.map(({ type }) => type),
    selectedAggregate: selectedWidget.aggregate,
  });

  const orderSelection = widgetOperations.isOrderSelectionValid(
    (selectedWidget as APIBarChartAttributes | APIPieChartAttributes)
      .orderSelection,
    selectedFields[0] && selectedFields[0].type
  )
    ? (selectedWidget as APIBarChartAttributes | APIPieChartAttributes)
        .orderSelection
    : ChartOrderOptions.SIZE;

  // dimensions should reset only when changing widget type (programmatically or manually)
  const shouldResetDimensions =
    shouldResetWidgetSize || widgetType !== selectedWidget.viewType;

  const layout = {
    ...selectedWidget.layout,
    w: shouldResetDimensions
      ? getColSpanForViewType(widgetType)
      : selectedWidget.layout.w,
    h: shouldResetDimensions ? 1 : selectedWidget.layout.h,
  };

  const newTrend = widgetOperations.isNumberWidgetData(selectedWidget)
    ? getNewNumberWidgetTrend(selectedWidget)
    : undefined;

  return {
    layout,
    viewType: widgetType!,
    aggregate,
    orderSelection,
    filters: selectedWidget.filters,
    fields: selectedFields.map(({ name }) => name),
    ...(widgetOperations.isTableWidgetData(selectedWidget)
      ? { sort: selectedWidget.sort, order: selectedWidget.order }
      : {}),
    trend: newTrend,
  };
};

/**
 * Handles the creation of a new widget
 * @param newWidgetType type of the widget to be created
 */
const handleCreateWidget = (
  state: DashboardBuilder$State,
  { viewType, layout }: CreateWidgetPayload
): DashboardBuilder$State => {
  const newWidgetId = uuidv4();
  return {
    ...state,
    widgets: [
      ...state.widgets,
      {
        isUnsaved: true,
        _id: newWidgetId,
        layout: { ...layout, i: newWidgetId },
        ...(viewType === WidgetTypes.HEADER
          ? { content: '', viewType }
          : { name: '', aggregate: undefined, fields: [] }),
      },
    ],
    selectedWidgetId: newWidgetId,
    isWidgetRecentlyCreated: true,
  };
};

const handleCopyWidget = (
  state: DashboardBuilder$State,
  { widgetId, layout }: CopyWidgetPayload
): DashboardBuilder$State => {
  const newWidgetId = uuidv4();
  const copiedWidget = state.widgets.find(widget => widget._id === widgetId)!;
  const colors = state.settings.colors.map(color => ({
    ...color,
    widgetsUsingThis: [
      ...color.widgetsUsingThis,
      ...(color.widgetsUsingThis.includes(widgetId) ? [newWidgetId] : []),
    ],
  }));
  const isChartWidget = widgetOperations.isChartWidgetData(copiedWidget);

  return {
    ...state,
    widgets: [
      ...state.widgets,
      {
        ...copiedWidget,
        _id: newWidgetId,
        isUnsaved: true,
        filters: isChartWidget ? copiedWidget.filters : undefined,
        layout: { ...layout, i: newWidgetId },
        ...(isChartWidget
          ? { name: `${copiedWidget.name ?? ''} (copy)` }
          : {
              content: `${copiedWidget.content} (copy)`,
            }),
      },
    ],
    selectedWidgetId: newWidgetId,
    settings: {
      ...state.settings,
      colors,
    },
    isWidgetRecentlyCreated: false,
  };
};

const getUpdatedWidgets = (
  state: DashboardBuilder$State,
  updatedAttributes:
    | Partial<DashboardBuilderChartWidget>
    | Partial<DashboardBuilderHeaderWidget>,
  widgetId: ArdoqId
): Array<DashboardBuilderChartWidget | DashboardBuilderHeaderWidget> =>
  state.widgets.map(widget => {
    if (widget._id === widgetId) {
      if (widgetOperations.isHeaderWidgetData(widget)) {
        return {
          ...widget,
          ...updatedAttributes,
        } as DashboardBuilderHeaderWidget;
      }

      const newWidgetState = {
        ...widget,
        validationError: undefined,
        ...updatedAttributes,
      } as DashboardBuilderChartWidget;

      // If explicitly passed => new value
      // Else if previously true => true
      // Else if a new preview is required => true
      const isLoading =
        updatedAttributes.isLoading !== undefined
          ? updatedAttributes.isLoading
          : widget.isLoading || shouldGetPreview(newWidgetState, widget);

      return {
        ...newWidgetState,
        isLoading,
      };
    }
    return widget;
  });

const handleUpdateWidgetState = (
  state: DashboardBuilder$State,
  updatedAttributes: ExtractPayload<typeof updateSelectedWidget>
) => ({
  ...state,
  widgets: getUpdatedWidgets(state, updatedAttributes, state.selectedWidgetId!),
});

const changeAggregateIfNotValid = ({
  widgetType,
  fieldTypes,
  selectedAggregate,
}: {
  widgetType?: WidgetTypes;
  fieldTypes: APIFieldType[];
  selectedAggregate: Aggregate | undefined;
}) => {
  const validAggregates = getValidAggregates({
    fieldTypes,
    widgetType,
  });
  // If there are no valid aggregates, we deselect the aggregate. If there is only one valid aggregate we preselect that one. Else use the user selected aggregate
  return !validAggregates.length
    ? undefined
    : validAggregates.some(aggregate => aggregate === selectedAggregate)
      ? selectedAggregate
      : validAggregates[0];
};

const handleSelectField = (
  state: DashboardBuilder$State,
  selectedFields: DataSourceField[]
): DashboardBuilder$State => {
  const selectedWidget = getSelectedChartWidget(state);
  const availableFieldsForDataSource = getAvailableFieldsForDataSource(
    state.availableDataSources,
    selectedWidget?.datasource
  );
  const newWidgetData = {
    datasource: selectedWidget.datasource,
    _id: selectedWidget._id,
    ...getWidgetTypeLayoutFieldAggregateAndSort(
      {
        ...selectedWidget,
        fields: selectedFields.map(field => field.name),
      },
      availableFieldsForDataSource
    ),
  };

  const isTableWidgetData = widgetOperations.isTableWidgetData(newWidgetData);

  const shouldClearTableWidgetData = // if selected fields are cleared we can't get a preview for the table widget so we have to manually remove the preview data
    isTableWidgetData && !selectedFields.length && selectedWidget.fields.length;

  const sort = isTableWidgetData
    ? {
        sort: widgetOperations.maybeResetSortingIfPossible(
          newWidgetData,
          availableFieldsForDataSource.filter(isSortableColumInTableWidget)
        ),
      }
    : {};

  return {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      {
        ...newWidgetData,
        ...(shouldClearTableWidgetData
          ? {
              data: undefined,
              tableFields: undefined,
              scopeData: undefined,
            }
          : {}),
        ...sort,
      },
      state.selectedWidgetId!
    ),
  };
};

const handleDeleteWidget = (
  state: DashboardBuilder$State,
  deletedWidgetId: string
): DashboardBuilder$State => {
  return {
    ...state,
    widgets: state.widgets.filter(widget => widget._id !== deletedWidgetId),
    selectedWidgetId:
      state.selectedWidgetId === deletedWidgetId
        ? undefined
        : state.selectedWidgetId,
  };
};

const handleSetAvailableDataSources = (
  state: DashboardBuilder$State,
  availableDataSources: WidgetDataSourceEntity[]
): DashboardBuilder$State => ({
  ...state,
  availableDataSources,
});

const handleSetLoadingDataSources = (
  state: DashboardBuilder$State,
  loadingDataSources: boolean
): DashboardBuilder$State => ({
  ...state,
  loadingDataSources,
});

/**
 * Handles the selection of a widget
 * @param selectedWidgetId ID of the widget
 */
const handleSelectWidget = (
  state: DashboardBuilder$State,
  selectedWidgetId: string
): DashboardBuilder$State => ({
  ...state,
  selectedWidgetId,
  isWidgetRecentlyCreated: false,
});

const handleSetWidgets = (
  state: DashboardBuilder$State,
  widgets: ExtractPayload<typeof setWidgets>
): DashboardBuilder$State => ({
  ...state,
  widgets,
});
/**
 * Handles the de-selection of widgets
 */
const handleClearWidgetSelection = (
  state: DashboardBuilder$State
): DashboardBuilder$State => ({
  ...state,
  selectedWidgetId: undefined,
});

const handleSelectDataSource = (
  state: DashboardBuilder$State,
  selectedDataSource: WidgetDataSourceEntity
): DashboardBuilder$State => {
  const selectedWidget = getSelectedChartWidget(state);
  const availableFieldsForDataSource = getAvailableFieldsForDataSource(
    state.availableDataSources,
    selectedDataSource
  );
  const datasource = {
    sourceType: selectedDataSource.sourceType,
    sourceId: selectedDataSource.sourceId,
  };

  return {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      {
        datasource,
        _id: selectedWidget._id,
        ...getWidgetTypeLayoutFieldAggregateAndSort(
          {
            ...selectedWidget,
            fields:
              selectedDataSource.sourceType === WidgetDataSourceTypes.SURVEY &&
              selectedWidget.fields.length > 1
                ? selectedWidget.fields.slice(0, 1)
                : selectedWidget.fields,
            datasource,
            filters: undefined,
          },
          availableFieldsForDataSource
        ),
      },
      state.selectedWidgetId!
    ),
  };
};

const handleSelectWidgetType = (
  state: DashboardBuilder$State,
  widgetType: WidgetTypes
) => {
  const selectedWidget = getSelectedChartWidget(state);
  const availableFieldsForDataSource = getAvailableFieldsForDataSource(
    state.availableDataSources,
    selectedWidget?.datasource
  );

  const newWidgetData = {
    datasource: selectedWidget.datasource!,
    _id: selectedWidget._id,
    ...getWidgetTypeLayoutFieldAggregateAndSort(
      {
        ...selectedWidget,
        viewType: widgetType,
        filters:
          selectedWidget.viewType !== widgetType &&
          [WidgetTypes.LINE_CHART, WidgetTypes.STACKED_BAR_CHART].includes(
            widgetType // timeline widgets don't support filtering
          )
            ? undefined
            : selectedWidget.filters,
      },
      availableFieldsForDataSource,
      true
    ),
    data: null,
  };

  return {
    ...state,
    widgets: getUpdatedWidgets(state, newWidgetData, state.selectedWidgetId!),
  };
};

const handleSetWidgetPreview = (
  state: DashboardBuilder$State,
  previewData: APIWidgetAttributes
): DashboardBuilder$State => {
  const widgetPreviewData = widgetOperations.isReadTableWidgetData(previewData)
    ? {
        data: previewData.data,
        scopeData: previewData.scopeData,
        tableFields: previewData.tableFields,
        aggregates: previewData.aggregates,
      }
    : widgetOperations.isReadNumberWidgetData(previewData)
      ? {
          data: previewData.data,
          numberFormatOptions: previewData.numberFormatOptions,
          trend: previewData.trend,
        }
      : { data: previewData.data };

  const newDashboardState = {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      {
        ...widgetPreviewData,
        error: undefined,
        isLoading: false,
      },
      previewData._id
    ),
  };

  return {
    ...newDashboardState,
    settings: {
      ...newDashboardState.settings,
      colors: applyColorsToFields(
        newDashboardState.widgets,
        newDashboardState.settings.colors,
        getSelectedDashboardColorPalette()
      ),
    },
  };
};

const handleSetWidgetError = (
  state: DashboardBuilder$State,
  previewData: ExtractPayload<typeof setWidgetError>
): DashboardBuilder$State => ({
  ...state,
  widgets: getUpdatedWidgets(
    state,
    {
      error: previewData.error,
      isLoading: false,
    },
    previewData._id
  ),
});

/**
 * Handles the dashboard name change
 * @param newDashboardName updated dashboard name
 */
const handleSetDashboardName = (
  state: DashboardBuilder$State,
  newDashboardName: string
): DashboardBuilder$State => ({
  ...state,
  name: newDashboardName,
});

/**
 * Handles the dashboard description change
 * @param newDashboardName updated dashboard name
 */
const handleSetDashboardDescription = (
  state: DashboardBuilder$State,
  newDashboardDescription: string
): DashboardBuilder$State => {
  if (!state.description && newDashboardDescription) {
    trackEvent(DashboardTrackingEventsNames.ADDED_DESCRIPTION_TO_DASHBOARD);
  }
  return {
    ...state,
    description: newDashboardDescription,
  };
};

const handleSetLoadedDashboard = (
  state: DashboardBuilder$State,
  loadedDashboard: LoadedDashboard
): DashboardBuilder$State => {
  const mappedDashboardState = {
    ...state,
    apiError: undefined,
    loadingState: null,
    selectedWidgetId: undefined,
    ...loadedDashboard,
  };

  if (!isDashboardValid(mappedDashboardState)) {
    return setValidationErrorsOnState(mappedDashboardState);
  }

  return mappedDashboardState;
};

const handleSetIsDashboardLoading = (
  state: DashboardBuilder$State,
  loadingState: ExtractPayload<typeof setDashboardLoadingState>
): DashboardBuilder$State => ({
  ...state,
  apiError: undefined,
  loadingState:
    state.loadingState === LoadingState.SAVING &&
    loadingState === LoadingState.LOADING
      ? state.loadingState
      : loadingState,
});

const handleSetColorForField = (
  state: DashboardBuilder$State,
  { dataPoint, color }: ExtractPayload<typeof setColorForDataPoint>
): DashboardBuilder$State => ({
  ...state,
  settings: {
    ...state.settings,
    colors: state.settings.colors.map(dataColor =>
      dataColor.dataPoint === dataPoint
        ? {
            ...dataColor,
            color,
            isManuallyAssigned: true,
          }
        : dataColor
    ),
  },
});

const handleRemoveManuallyAssignedColorForDataPoint = (
  state: DashboardBuilder$State,
  dataPoint: string
): DashboardBuilder$State => ({
  ...state,
  settings: {
    ...state.settings,
    colors: applyColorsToFields(
      state.widgets,
      state.settings.colors.filter(
        colorData => colorData.dataPoint !== dataPoint
      ),
      getSelectedDashboardColorPalette()
    ),
  },
});

const setWarningsOnState = (
  state: DashboardBuilder$State
): DashboardBuilder$State => {
  const widgetsWithWarnings = state.widgets.map(w => {
    const availableFieldsForDataSource = widgetOperations.isChartWidgetData(w)
      ? getAvailableFieldsForDataSource(
          state.availableDataSources,
          w.datasource
        )
      : [];
    if (
      widgetOperations.isChartWidgetData(w) &&
      w.viewType &&
      !w.isUnsaved &&
      !isWidgetTypeValidForSelectedFieldsAndDatasource({
        widgetType: w.viewType,
        datasource: w.datasource,
        availableFieldsForDataSource: availableFieldsForDataSource,
        selectedFields: availableFieldsForDataSource.filter(field =>
          w.fields.includes(field.name)
        ),
      })
    ) {
      return {
        ...w,
        warning: WidgetWarning.INCOMPATIBLE_WIDGET_TYPE_WITH_DATA_SOURCE,
      };
    }
    return w;
  });

  return {
    ...state,
    widgets: widgetsWithWarnings,
  };
};

const setValidationErrorsOnState = (
  state: DashboardBuilder$State
): DashboardBuilder$State => {
  const dashboardErrors = new Set<DashboardErrorType>();

  // Set errors on dashboard
  if (!isDashboardNameValid(state.name)) {
    dashboardErrors.add(DashboardErrorType.MISSING_NAME);
  }

  // Set errors on widgets
  const validatedWidgets = state.widgets.map(w => {
    if (widgetOperations.isChartWidgetData(w)) {
      if (widgetOperations.isValidChartWidgetData(w)) {
        return w;
      }
      let validationError: WidgetError = WidgetError.DEFAULT_ERROR;

      const isMissingDataSource =
        w.error === 'Failed to fetch data: Datasource missing' || !w.datasource;

      const isMissingField =
        (widgetOperations.isTableWidgetData(w) ||
          w.datasource?.sourceType === WidgetDataSourceTypes.SURVEY) &&
        !w.fields.length;

      const isSubsetOfAvailableFields = !isSubset(
        w.fields,
        getAvailableFieldsForDataSource(
          state.availableDataSources,
          w.datasource
        ).map(x => x.name)
      );

      if (isMissingField) {
        validationError = WidgetError.MISSING_FIELDS;
      }

      if (isSubsetOfAvailableFields) {
        validationError = WidgetError.FIELD_REMOVED_FROM_SOURCE;
      }

      if (isMissingDataSource) {
        validationError = WidgetError.MISSING_CHART_SOURCE;
      }

      return {
        ...w,
        validationError: validationError,
      };
    } else if (
      widgetOperations.isHeaderWidgetData(w) &&
      !widgetOperations.isValidHeaderWidgetData(w)
    ) {
      return {
        ...w,
        validationError: WidgetError.MISSING_HEADER_TITLE,
      };
    }
    return w;
  });

  return {
    ...state,
    widgets: validatedWidgets,
    validationErrors: dashboardErrors,
    ...(dashboardErrors.has(DashboardErrorType.MISSING_NAME)
      ? { selectedWidgetId: DASHBOARD_NAME_ISLAND_ID }
      : {}),
  };
};

const handleSetApiError = (
  state: DashboardBuilder$State,
  {
    apiError,
    apiLoadingState = LoadingState.LOADING_ERROR,
  }: SetDashboardApiErrorPayload
): DashboardBuilder$State => ({
  ...state,
  loadingState: apiLoadingState,
  apiError,
});

const handleSelectBarChartColorSelection = (
  state: DashboardBuilder$State,
  colorSelection: BarChartColorOptions
): DashboardBuilder$State => {
  const widgets = getUpdatedWidgets(
    state,
    { colorSelection },
    state.selectedWidgetId!
  );
  return {
    ...state,
    widgets,
    settings: {
      ...state.settings,
      colors: applyColorsToFields(
        widgets,
        state.settings.colors,
        getSelectedDashboardColorPalette()
      ),
    },
  };
};

const handleSelectNumberSlices = (
  state: DashboardBuilder$State,
  numberSlices: number
): DashboardBuilder$State => {
  const newDashboardState = {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      { numberSlices },
      state.selectedWidgetId!
    ),
  };

  return {
    ...newDashboardState,
    settings: {
      ...newDashboardState.settings,
      colors: applyColorsToFields(
        newDashboardState.widgets,
        newDashboardState.settings.colors,
        getSelectedDashboardColorPalette()
      ),
    },
  };
};

const handleSelectChartDataOrder = (
  state: DashboardBuilder$State,
  orderSelection: ChartOrderOptions
): DashboardBuilder$State => {
  const newDashboardState = {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      { orderSelection },
      state.selectedWidgetId!
    ),
  };

  return {
    ...newDashboardState,
  };
};

const handleSelectNumberTrend = (
  state: DashboardBuilder$State,
  trend: boolean | NumberWidgetTrend | NumberWidgetTrendConfiguration
): DashboardBuilder$State => {
  const newTrend = getNewNumberWidgetConfiguration(trend);
  return {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      { trend: newTrend as NumberWidgetTrend }, // todo try to remove this cast
      state.selectedWidgetId!
    ),
  };
};

const applyDashboardWidgetFilters = (
  state: DashboardBuilder$State,
  { filterQuery }: DashboardWidgetFilterPayload
): DashboardBuilder$State => {
  const selectedWidget = getSelectedChartWidget(state);
  const availableFieldsForDataSource = getAvailableFieldsForDataSource(
    state.availableDataSources,
    selectedWidget?.datasource
  );

  const newWidgetData = {
    datasource: selectedWidget.datasource,
    _id: selectedWidget._id,
    ...getWidgetTypeLayoutFieldAggregateAndSort(
      {
        ...selectedWidget,
        filters: !isEmpty(filterQuery) ? filterQuery : undefined,
      },
      availableFieldsForDataSource
    ),
  };

  return {
    ...state,
    widgets: getUpdatedWidgets(
      state,
      {
        ...newWidgetData,
      },
      state.selectedWidgetId!
    ),
    isFilterDrawerOpened: false,
  };
};

const handleOpenFilterDrawer = (
  state: DashboardBuilder$State
): DashboardBuilder$State => ({ ...state, isFilterDrawerOpened: true });

const handleCloseFilterDrawer = (
  state: DashboardBuilder$State
): DashboardBuilder$State => ({ ...state, isFilterDrawerOpened: false });

const dashboardBuilder$ = persistentReducedStream<DashboardBuilder$State>(
  'dashboardBuilder$',
  defaultState,
  [
    reducer(clearWidgetSelection, handleClearWidgetSelection),
    reducer(createWidget, handleCreateWidget),
    reducer(deleteWidget, handleDeleteWidget),
    reducer(selectDataSource, handleSelectDataSource),
    reducer(selectField, handleSelectField),
    reducer(selectWidget, handleSelectWidget),
    reducer(selectWidgetType, handleSelectWidgetType),
    reducer(setAvailableDataSources, handleSetAvailableDataSources),
    reducer(setLoadingDataSources, handleSetLoadingDataSources),
    reducer(setDashboardDescription, handleSetDashboardDescription),
    reducer(setDashboardName, handleSetDashboardName),
    reducer(setDashboardLoadingState, handleSetIsDashboardLoading),
    reducer(copyWidget, handleCopyWidget),
    reducer(setLoadedDashboard, handleSetLoadedDashboard),
    reducer(setWidgetPreview, handleSetWidgetPreview),
    reducer(setWidgets, handleSetWidgets),
    reducer(updateSelectedWidget, handleUpdateWidgetState),
    reducer(setColorForDataPoint, handleSetColorForField),
    reducer(
      removeManuallyAssignedColorForDataPoint,
      handleRemoveManuallyAssignedColorForDataPoint
    ),
    reducer(setValidationErrors, setValidationErrorsOnState),
    reducer(setDashboardWarnings, setWarningsOnState),
    reducer(clearDashboardData, constant(defaultState)),
    reducer(setDashboardApiError, handleSetApiError),
    reducer(selectBarChartColorSelection, handleSelectBarChartColorSelection),
    reducer(setWidgetError, handleSetWidgetError),
    reducer(selectNumberSlices, handleSelectNumberSlices),
    reducer(selectChartDataOrder, handleSelectChartDataOrder),
    reducer(selectNumberTrend, handleSelectNumberTrend),
    reducer(applyDashboardWidgetFiltersRequested, applyDashboardWidgetFilters),
    reducer(widgetFilterDrawerOpened, handleOpenFilterDrawer),
    reducer(widgetFilterDrawerClosed, handleCloseFilterDrawer),
  ]
);

let previousState: DashboardBuilder$State;
dashboardBuilder$.subscribe(newState => {
  if (newState._id === previousState?._id) {
    for (const widget of newState.widgets) {
      const previousWidget = previousState.widgets.find(
        item => item._id === widget._id
      );

      if (shouldGetPreview(widget, previousWidget)) {
        dispatchAction(
          getWidgetPreview({
            widgetData: widget,
            availableFields: getAvailableFieldsForDataSource(
              newState.availableDataSources,
              widget.datasource
            ),
          })
        );
      }
    }
  }

  previousState = newState;
});

export default dashboardBuilder$;
