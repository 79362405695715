import { RepresentationData } from '@ardoq/data-model';
import { INode, IRenderContext, NodeStyleBase, Visual } from '@ardoq/yfiles';
import { GraphNode } from '@ardoq/graph';
import CanvasNodeVisual from './CanvasNodeVisual';

class CanvasNodeStyle extends NodeStyleBase {
  constructor(private nodeRepresentationData: Map<string, RepresentationData>) {
    super();
  }
  protected createVisual(context: IRenderContext, node: INode): Visual | null {
    const graphNode = node.tag as GraphNode;
    const representationData =
      this.nodeRepresentationData.get(graphNode.id) ?? null;
    return new CanvasNodeVisual(node.layout, graphNode, representationData);
  }
  protected override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    _node: INode
  ): Visual | null {
    return oldVisual;
  }
}
export default CanvasNodeStyle;
