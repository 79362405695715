import {
  actionCreator,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';

export const setIsMainViewLoading = actionCreator(
  '[mainViewLoadingIndicator$] SET_IS_LOADING'
);
export const setIsMainViewNotLoading = actionCreator(
  '[mainViewLoadingIndicator$] SET_IS_NOT_LOADING'
);

export type MainViewLoadingIndicatorState = {
  isLoading: boolean;
};

const setIsMainViewLoadingReducer = () => ({
  isLoading: true,
});
const handleSetIsMainViewLoading = reducer<MainViewLoadingIndicatorState>(
  setIsMainViewLoading,
  setIsMainViewLoadingReducer
);

const setIsMainViewNotLoadingReducer = () => ({
  isLoading: false,
});
const handleIsMainViewNotLoading = reducer<MainViewLoadingIndicatorState>(
  setIsMainViewNotLoading,
  setIsMainViewNotLoadingReducer
);

const reducers = [handleSetIsMainViewLoading, handleIsMainViewNotLoading];

const defaultState: MainViewLoadingIndicatorState = {
  isLoading: false,
};

const mainViewLoadingIndicator$ = persistentReducedStream(
  'mainViewLoadingIndicator$',
  defaultState,
  reducers
);

export default mainViewLoadingIndicator$;
